import React, { useCallback, useEffect, useState } from "react"
import { ActionsButtonsComponent, Tables } from "../../../../../../Components"
import { ButtonBase } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { SelectContactDialog } from "./UnitProfileTenantUtilities/Dialogs/SelectContactDialog"
import { GetAllUnitTenants } from "../../../../../../Services"
import { GetParams } from "../../../../../../Helper"
import { UnitProfileTenantTable } from "./UnitProfileTenantUtilities/UnitProfileTenantTable/UnitProfileTenantTable"
import { ActionsButtonsEnum } from "../../../../../../Enums"
import { UnitsImportDialog } from "./UnitProfileTenantUtilities/Dialogs/UnitsImportDialog"
export const UnitProfileTenantComponent = ({
  parentTranslationPath,
  translationPath,
}
) => {
  const { t } = useTranslation(parentTranslationPath);
  const [openSelectContact, setOpenSelectContact] = useState(false);
  const [activeSelectedAction, setActiveSelectedAction] = useState("");

  const [dataTeanat, setDataTenant] = useState({
    values: [],
    totalCount: 0
  });
  const [isOpenImportDialog, setIsOpenImportDialog] = useState(false);
  const [checkedCards, setCheckedCards] = useState([]);
  const [checkedCardsIds, setCheckedCardsIds] = useState([]);
  const [filter, setFilter] = useState({
    pageSize: 25,
    pageIndex: 0,
    unitId: +GetParams('id')
  })

  const getAllUnitTenants = useCallback(async () => {
    const res = await GetAllUnitTenants(filter)
    if (!(res && res.status && res.status !== 200)) {
      setDataTenant({
        values: res.result,
        totalCount: res.totalCount
      })
    } else {
      setDataTenant({
        values: [],
        totalCount: 0
      })
    }
  })

  useEffect(() => {
    getAllUnitTenants()
  }, [])

  return (
    <>
      <div className="d-flex-column">
        <div className="header-section">
          <div className="filter-section">
            <div className="section">
              <div className="d-flex mt-2 ml-4">
                <ButtonBase
                  className='btns theme-solid'
                  onClick={() => { setOpenSelectContact(true) }}
                >
                  <span>{t(`${translationPath}add-Tenant`)}</span>
                </ButtonBase>
                <ButtonBase
                  className='btns theme-solid'
                  onClick={() => { setIsOpenImportDialog(true); }}
                >
                  <span>{t(`${translationPath}Import`)}</span>
                </ButtonBase>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="mainContainerTenantTab d-flex-column ml-5 mr-5 mt-2">

        <UnitProfileTenantTable
          data={dataTeanat.values}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          filter={filter}
          totalItems={dataTeanat.totalCount}
          onSave={
            () => { getAllUnitTenants() }
          }
        />

      </div>
      {
        openSelectContact && (
          <SelectContactDialog
            isOpen={openSelectContact}
            onClose={() => {
              setOpenSelectContact(false)
            }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onSave={
              () => { getAllUnitTenants() }
            }
          />
        )
      }
      <UnitsImportDialog
        isOpen={isOpenImportDialog}
        isOpenChanged={() => setIsOpenImportDialog(false)}
        unitId={filter.unitId}
      />
    </>
  )
}