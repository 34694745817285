import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";

// styles
import useStyles from "./styles";

function ContactItemList({ labelIcon, label, values }) {
  const styles = useStyles();

  return (
    <Box className={styles.contactItemListWrapper}>
      <Box className={styles.labelContainer}>
        {labelIcon}
        <Typography className={styles.label}>{label}</Typography>
      </Box>
      <Box className={styles.valuesContainer}>
        {!values?.length && (
          <Typography className={styles.value}>N/A</Typography>
        )}
        {values.map((value, index) => (
          <Typography key={index} className={styles.value}>
            {value}
          </Typography>
        ))}
      </Box>
    </Box>
  );
}

ContactItemList.propTypes = {
  labelIcon: PropTypes.node,
  label: PropTypes.string,
  values: PropTypes.array,
};

ContactItemList.defaultProps = {
  labelIcon: null,
  label: "",
  values: [],
};

export default ContactItemList;
