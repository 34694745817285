import React, { useState, useEffect, useCallback } from "react";
import { Tables } from "../../../../../../Components";
import { SchoolFeesDialogHeaderData } from "./SchoolFeesDialogHeaderData";

export function SchoolFeesDialog({ schoolFeesTableData }) {

  return (
    <div className="my-4">
      <Tables
        data={schoolFeesTableData || []}
        headerData={SchoolFeesDialogHeaderData || []}
      />
    </div>
  );
}
