import React from 'react';
import { ButtonBase } from '@material-ui/core';
import { UnitsOperationTypeEnum } from '../../../../Enums';

const PushTOlink = (item) => {
  if (item.operationTypeId === UnitsOperationTypeEnum.sale.key) {
    window.open(
      `/home/units-sales/unit-profile-edit?formType=1&id=${item.unitId}&operationType=${item.operationTypeId}`
    );
  } else if (
    item.operationTypeId === UnitsOperationTypeEnum.rent.key ||
    item.operationTypeId === UnitsOperationTypeEnum.rentAndSale.key
  ) {
    window.open(
      `/home/units-lease/unit-profile-edit?formType=1&id=${item.unitId}&operationType=${item.operationTypeId}`
    );
  }
};

export const DuplicatedUnitsTableHeaderData = [
  {
    id: 1,
    label: 'Unit RefNo',
    input: 'unitRefNo',
  },
  {
    id: 2,
    label: 'Listing Agent',
    input: 'listingAgent',
  },
  {
    id: 3,
    label: 'Lease Listing Agent',
    input: 'leaseListingAgent',
  },
  {
    id: 4,
    label: 'Unit ID',
    component: (item) => (
      <>
        <ButtonBase
          onClick={() => {
            PushTOlink(item);
          }}
        >
          <div class='icon-container mx-1'>
            <span class='mdi mdi-open-in-new c-primary'></span>
          </div>
          {item.unitId}
        </ButtonBase>
      </>
    ),
  },
  {
    id: 5,
    label: 'Owner',
    input: 'owner',
  },
  {
    id: 6,
    label: 'lease Owner',
    input: 'leaseOwner',
  },
  {
    id: 7,
    label: 'Number of Bedrooms',
    input: 'noOfBedrooms',
  },
  {
    id: 8,
    label: 'Number of Bathrooms',
    input: 'noOfBathrooms',
  },
  {
    id: 9,
    label: 'Created on',
    input: 'createdOn',
  },
  {
    id: 10,
    label: 'Created By',
    input: 'createdByName',
  },
  {
    id: 11,
    label: 'Status',
    input: 'status',
  },
  {
    id: 12,
    label: 'Lease Status',
    input: 'leaseStatus',
  },
  {
    id: 13,
    label: 'Unit Number',
    input: 'unitNumber',
  },
];
