import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import { DialogComponent, Spinner } from "../../../../../../Components";
import { SchoolFeesDialog } from "../SchoolFeesView/SchoolFeesDialog";
import { SchoolNationalitiesDialog } from "../SchoolNationalitiesView/SchoolNationalitiesDialog";
import { SchoolDetails } from "../SchoolDetailsView/SchoolDetails";
import { Tabs, Tab, ButtonBase } from "@material-ui/core";
import { GetSchoolDetailByLandMarkId } from "../../../../../../Services";
import { GetParams } from "../../../../../../Helper";
import "./SchoolDetailsDialogView.scss";
export const SchoolDetailsDialogView = ({ open, close }) => {
  const [view, setview] = useState(0);
  const [schoolDetailsTableData, setSchoolDetailsTableData] = useState([]);
  const [schoolFeesTableData, setSchoolFeesTableData] = useState([]);
  const [schoolNationalities, setSchoolNationalitiesTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const parentTranslationPath = "LocationView";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);

  const getSchoolDetailByLandMarkIdCallAPI = useCallback(async () => {
    setIsLoading(true);
    const landmarkId = +GetParams("id");
    try {
      let res = await GetSchoolDetailByLandMarkId(landmarkId);
      setSchoolDetailsTableData([res]);
      setSchoolNationalitiesTableData(res.studentNationalities);
      setSchoolFeesTableData(res.bookFees);
    } catch (error) {}
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getSchoolDetailByLandMarkIdCallAPI();
  }, []);

  return (
    <div>
      <Spinner isActive={isLoading} isAbsolute />
      <div className="">
        <DialogComponent
          titleText="school-details"
          titleClasses="DialogComponent-sc"
          wrapperClasses="h-80vh"
          maxWidth="lg"
          SmothMove
          fullWidth
          onCloseClicked={close}
          dialogContent={
            <div className="dialog-height">
              <Tabs
                value={view}
                onChange={(event, newValue) => setview(newValue)}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                className="tabs-wrapper"
              >
                <Tab label={t(`${translationPath}school-details`)} />
                <Tab label={t(`${translationPath}school-fees`)} />
                <Tab label={t(`${translationPath}school-nationalities`)} />
              </Tabs>
              <div className="dialog-views">
                {view === 0 && (
                  <SchoolDetails
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    schoolDetailsTableData={schoolDetailsTableData}
                    isClose={close}
                  />
                )}
                {view === 1 && (
                  <SchoolFeesDialog
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    schoolFeesTableData={schoolFeesTableData}
                    isClose={close}
                  />
                )}
                {view === 2 && (
                  <SchoolNationalitiesDialog
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    schoolNationalities={schoolNationalities}
                    isClose={close}
                  />
                )}
              </div>
            </div>
          }
          isOpen={open}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      </div>
    </div>
  );
};
SchoolDetailsDialogView.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.bool.isRequired,
};
