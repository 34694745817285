import React, {  useCallback, useReducer, useEffect, useRef } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { useSelector } from 'react-redux';
import './CampaignTeam.scss';
import { DialogActions, DialogContent, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  showError,
  showSuccess,
  getDownloadableLink,
  getFirstLastNameLetters
} from '../../../../../../../../Helper';
import {
  AutocompleteComponent,
  DialogComponent,
  SelectComponet,
} from '../../../../../../../../Components';
import { AddCampaignTeamMembers, GetCampaignUserTypes } from '../../../../../../../../Services';
import { ApplicationUserSearch } from '../../../../../../../../Services/userServices';

export const AddTeamMemberDialog = ({ isDialogOpen, onClose, onSave, teamMembersData }) => {
  const parentTranslationPath = 'Campaign';
  const translationPath = 'campaign-team.';

  const searchTimer = useRef(null);
  const { t } = useTranslation(parentTranslationPath);
  const marketingCampaignsInfo = useSelector((state) => state.MarketingCampaignsReducer);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [state, setState] = useReducer(reducer, {
    teamMembers: [],
  });
  const [selected, setSelected] = useReducer(reducer, {
    user: null,
    campaignTaskUserTypeId: 0,
    teamMembers: [],
  });
  const [isLoading, setIsLoading] = useReducer(reducer, {
    users: false,
    userTypes: false,
    saving: false,
  });
  const [data, setData] = useReducer(reducer, {
    users: [],
    userTypes: [],
  });

  const getAllUsers = async (searchValue) => {
    setIsLoading({ id: 'users', value: true });
    const res = await ApplicationUserSearch({
      pageIndex: 0,
      pageSize: 25,
      name: searchValue || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        setData({ id: 'users', value: localValue });
      } else {
        setData({ id: 'users', value: [] });
      }
      setIsLoading({ id: 'users', value: false });
    }
  };

  const addCampaignTeamMembers = async () => {
    setIsLoading({ id: 'saving', value: true });
    const body = {
      id: marketingCampaignsInfo?.campaignRequestId,
      teamMembers: state.teamMembers || [],
    };
    const res = await AddCampaignTeamMembers(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}team-members-added-successfully`));
      if (onSave) onSave();
    } else showError(t(`${translationPath}team-members-add-failed`));
    setIsLoading({ id: 'saving', value: false });
  };

  const getCampaignUserTypes = async (searchValue) => {
    setIsLoading({ id: 'userTypes', value: true });
    const res = await GetCampaignUserTypes({
      search: searchValue || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'userTypes', value: res || [] });
    } else setData({ id: 'userTypes', value: [] });

    setIsLoading({ id: 'userTypes', value: false });
  };

  const saveHandler = async (event) => {
    event.preventDefault();
    if (state.teamMembers && state.teamMembers.length == 0) {
      showError(t(`${translationPath}No-Team-Members-To-Add`));
      return;
    }
    if(isLoading.saving) return;

    addCampaignTeamMembers();
  };

  const findUserTypeName = () => {
    const userTypeItem =
      data.userTypes &&
      data.userTypes.find(
        (item) => item.campaignTaskUserTypeId === selected.campaignTaskUserTypeId
      );
    return userTypeItem ? userTypeItem.campaignTaskUserTitle : '-';
  };

  const checkIfTeamHasManager = () => {
   const isTeamHasManager = (teamMembersData && teamMembersData.find(item => item.campaignTaskUserTypeId == 1))? true : false;
   const isManagerRoleSelected = (selected.teamMembers && selected.teamMembers.find(item => item.userTypeName == "Campaign Manager"))? true : false;

   return (isTeamHasManager || isManagerRoleSelected);
  }


  const addTeamMemberHandler = () => {
    const isManagerRole = selected.campaignTaskUserTypeId === 1;

    if (isManagerRole && checkIfTeamHasManager()) {
      showError(t(`${translationPath}team-must-have-one-campaign-manager`));
      return;
    }
    const selectedTeamMemberItem = {
      user: selected.user,
      userTypeName: findUserTypeName(),
    };
    const stateTeamMemberItem = {
      userId: selected.user.applicationUserId,
      campaignTaskUserTypeId: selected.campaignTaskUserTypeId,
    };
    setState({ id: 'teamMembers', value: [...(state.teamMembers || []), stateTeamMemberItem] });
    setSelected({
      id: 'edit',
      value: {
        user: null,
        campaignTaskUserTypeId: 0,
        teamMembers: [...(selected.teamMembers || []), selectedTeamMemberItem],
      },
    });
  };

  const checkIsAddBtnDisabled = () => {
    let isDisabled = false;
    if (!selected.user || !selected.campaignTaskUserTypeId) isDisabled = true;
    return isDisabled;
  };

  const teamMemberRemoveHandler = (removeItemIndex) => {
    const localSelectedTeamMembers = [...(selected.teamMembers || [])];
    localSelectedTeamMembers.splice(removeItemIndex, 1);

    const localStateTeamMembers = [...(state.teamMembers || [])];
    localStateTeamMembers.splice(removeItemIndex, 1);

    setState({ id: 'teamMembers', value: localStateTeamMembers });
    setSelected({ id: 'teamMembers', value: localSelectedTeamMembers });
  };

  
  useEffect(() => {
    getAllUsers();
    getCampaignUserTypes();
  }, []);

  return (
    <div>
      <DialogComponent
        isOpen={isDialogOpen}
        maxWidth='sm'
        titleText={t(`${translationPath}add-team-members`)}
        dialogContent={
          <>
            <form noValidate onSubmit={saveHandler}>
              <DialogContent>
                <div className='camp-req-f1 campaign-team-f1'>
                  <div className='m-auto d-flex-v-center-h-between my-2'>
                    <div className='d-flex w-100'>
                      <div className='mr-2 w-50'>
                        <AutocompleteComponent
                          idRef='teamMemberRef'
                          inputPlaceholder='select-team-member'
                          selectedValues={selected.user}
                          data={data.users || []}
                          multiple={false}
                          isLoading={isLoading.users}
                          displayLabel={(option) => option.fullName || ''}
                          withoutSearchButton
                          onChange={(event, newValue) =>
                            setSelected({ id: 'user', value: newValue })
                          }
                          onInputKeyUp={(e) => {
                            const { value } = e.target;
                            if (searchTimer.current) clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              getAllUsers(value);
                            }, 1200);
                          }}
                          onKeyDown={() => setSelected({ id: 'user', value: null })}
                          translationPath={translationPath}
                          parentTranslationPath={parentTranslationPath}
                          wrapperClasses='team-member-field'
                        />
                      </div>
                      <div className='mr-2 w-50'>
                        <SelectComponet
                          data={data.userTypes || []}
                          defaultValue={0}
                          value={selected.campaignTaskUserTypeId || 0}
                          emptyItem={{ value: 0, text: 'select-role', isHiddenOnOpen: true }}
                          valueInput='campaignTaskUserTypeId'
                          textInput='campaignTaskUserTitle'
                          onSelectChanged={(newValue) =>
                            setSelected({ id: 'campaignTaskUserTypeId', value: newValue || null })
                          }
                          idRef='TeamMemberRoleRef'
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                        />
                      </div>
                    </div>
                    <ButtonBase
                      onClick={addTeamMemberHandler}
                      disabled={checkIsAddBtnDisabled()}
                      className='btns theme-solid add-team-btn'>
                      <span className='mdi mdi-plus'></span>
                    </ButtonBase>
                  </div>
                  <div className='my-4'>
                    {selected.teamMembers &&
                      selected.teamMembers.map((item, index) => (
                        <div
                          className='team-member-record p-2 mb-2 d-flex-v-center-h-between'
                          key={item.user?.username}>
                          <div className='d-flex-v-center'>
                            <div className='info-container'>
                              <Avatar className='avatars-wrapper team-avatar sm-theme'
                                src={
                                  item.user?.profileImg
                                    ? getDownloadableLink(item.user?.profileImg)
                                    : ''
                                }
                              >
                                {getFirstLastNameLetters(item.user?.fullName || '')}
                              </Avatar>
                              <div className='top-label d-flex-column-center'>
                                <p>{item.user?.fullName || '-'}</p>
                                <p>{item.user?.email || ''}</p>
                              </div>
                            </div>
                          </div>
                          <div className='d-flex-v-center-h-end'>
                            <p className='role-capsule'>{item.userTypeName}</p>
                            <span
                              className='team-remove-btn mdi mdi-plus-circle'
                              onClick={() => teamMemberRemoveHandler(index)}></span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <ButtonBase onClick={onClose} className='btns theme-solid bg-cancel'>
                  {t(`cancel`)}
                </ButtonBase>
                <ButtonBase className='btns theme-solid' disabled={isLoading.saving} onClick={saveHandler} type='submit'>
                  {t(`save`)}
                </ButtonBase>
              </DialogActions>
            </form>
          </>
        }
      />
    </div>
  );
};
