import React, { useEffect, useState } from 'react';
import { Spinner, Tables } from '../../../Components';
import { GetParams } from '../../../Helper';
import { GetInquiryAssignmentHistory } from '../../../Services';

export const InquiryAssignmentTrack = ({ parentTranslationPath }) => {
  const id = GetParams('id');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const headerData = [
    { label: 'assign_to', input: 'assignTo' },
    {
      label: 'assign_date',
      input: 'assignDate',
      isDate: true,
    },
    {
      label: 'assigned_type',
      input: 'assignedType',
    },
    {
      label: 'assigned_by',
      input: 'assignedBy',
    },
    {
      label: 'inquiry_created_date',
      input: 'inquiryCreatedDate',
      isDate: true,
    },
    {
      label: 'inquiry_created_by',
      input: 'inquiryCreatedBy',
    },
  ];
  const getInquiryAssignmentHistory = async () => {
    setLoading(true);
    // API call to get data
    const result = await GetInquiryAssignmentHistory(id);
    if (!(result?.status && result.status !== 200)) {
      setData(result);
    }
    setLoading(false);
  };
  useEffect(() => {
    getInquiryAssignmentHistory();
  }, []);
  return (
    <div className=' py-5'>
      <Spinner isActive={loading} isAbsolute />
      <Tables
        headerData={headerData}
        data={data}
        parentTranslationPath={parentTranslationPath}
        translationPath={''}
        defaultActions={[]}
        itemsPerPage={data?.length}
      />
    </div>
  );
};

export default InquiryAssignmentTrack;
