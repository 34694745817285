import moment from 'moment';
import React, { useState, useCallback   ,  useEffect  } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';
import {  GetUserIdByApplicationUserId  } from '../../../../Services' ; 
import {Tables} from '../../../../Components'; 

export const ActivitiesCommunicationLogsTable = ({
    translationPath,
    parentTranslationPath,
    filter,
    data,  
    onPageIndexChanged,
    onPageSizeChanged,
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const dispatch = useDispatch();


    const GetUserIdByApplicationUserIdAPI  = useCallback(async (agentId) => {
      const res =  await GetUserIdByApplicationUserId(agentId);
       if (!(res && res.status && res.status !== 200))
           return res ; 
        else return null   ;   
      });


      const headerData = [
         {
          id: 1,
          label: 'activityTypeName',
          input: 'activityTypeName',

        },
        {
          id: 2,
          label: 'agentName',
          input: 'agentName',
          component: (item) => <a className='c-primary newTabActivites'
          onClick={
            (async() => {
            const agentId = (item && item.agentId);
             const userId =  agentId ?  await GetUserIdByApplicationUserIdAPI(agentId)  : null ; 
             if(userId)
             {
               dispatch(ActiveItemActions.activeItemRequest({ name: (item.agentName), id: userId }));
               window.open( `/home/Users/edit?id=${userId}`, '_blank');
             }
            })}> {(item && item.agentName)} </a>
           
        },
        {
        id: 3,
        label: 'agentId',
        input: 'agentId',
        component: (item) => <a className='c-primary newTabActivites' 
        onClick={
          (async() => {
          const agentId = (item && item.agentId);
           const userId =  agentId ?  await GetUserIdByApplicationUserIdAPI(agentId)  : null ; 
           if(userId)
           {
             dispatch(ActiveItemActions.activeItemRequest({ name: (item.agentName), id: userId }));
             window.open( `/home/Users/edit?id=${userId}`, '_blank');
           }
          })}> {(item && item.agentId)} </a>

       },
       {
        id: 4,
        label: 'leadId',
        input: 'leadId',
        component: (item) => <a className='c-primary newTabActivites'
        onClick={() => {
          const leadId = ( item && item.leadId);
          if(leadId)
          {
            dispatch(ActiveItemActions.activeItemRequest({ name: (item.leadName), id: leadId }));
            window.open( `/home/leads/lead-profile-edit?id=${leadId}&formType=${item.leadType}`, '_blank');
          }
        }}> {(item && item.leadId)} </a> },
      {
          id: 5,
          label: 'leadName',
          input: 'leadName',
          component: (item) => <a className='c-primary newTabActivites' 
          onClick={() => {
            const leadName = ( item && item.leadName);
            if(leadName)
            {
              dispatch(ActiveItemActions.activeItemRequest({ name: (leadName), id: item.leadId }));
              window.open( `/home/leads/lead-profile-edit?id=${item.leadId}&formType=${item.leadType}`, '_blank');

            }
          }}> {(item && item.leadName)} </a>
         },
       {
        id: 6,
        label: 'phoneNumber',
        input: 'phoneNumber',
        component: (item) => <span>{(item && item.phoneNumber)}</span> || '' , 

      },
      {
          id: 7,
          label: 'email',
          input: 'email',

      },
      {
          id: 8,
          label: 'contactName',
          input: 'contactName',
          component: (item) => <a className='c-primary newTabActivites' onClick={() => {
            const contactName = ( item && item.contactName);
            if(contactName)
            {
              dispatch(ActiveItemActions.activeItemRequest({ name: (contactName), id: item.contactId }));
              window.open( `/home/Contacts-CRM/contact-profile-edit?id=${item.contactId}&formType=${item.contactType}`, '_blank');
            }
          }}> {(item && item.contactName)} </a>
         },
        {
          id: 9,
          label: 'contactId',
          input: 'contactId',
          component: (item) => <a className='c-primary newTabActivites'
          onClick={() => {
            const contactId = (item && item.contactId);
            if(contactId)
            {
              dispatch(ActiveItemActions.activeItemRequest({ name: (item.contactName), id: contactId }));
              window.open( `/home/Contacts-CRM/contact-profile-edit?formType=${item.contactType}&id=${contactId}`, '_blank');
            }
          }}
          > {(item && item.contactId)} </a>
      },
      {
          id: 10,
          label: 'activityDate',
          input: 'activityDate',
          isDate: true,
          dateFormat: 'YYYY-MM-DD',
          component: (item) => <span>{(item && item.activityDate && moment(item.activityDate).format('hh:mm A'))}</span> || ''

      },
      {
        id: 11,
        label: 'time',
        input: 'activityDate',
        isDate: true,
        dateFormat: 'hh:mm A',
        component: (item) => <span>{(item && item.activityDate && moment(item.activityDate).format('hh:mm A'))}</span> || ''
    },
  ];
   
    return (
      <>
        <Tables
          data={(data && data.result) || []}
          headerData={headerData || []}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          totalItems={(data && data.totalCount)}
          defaultActions={[]}
          FilterDisabledButton
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      </>
    );
};
