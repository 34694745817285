export const SaleTeamLeadDashboardPermissions = {
  SaleTeamLeadDashboard: 
    {
      permissionsId:'0072a673-2da3-4829-682e-08da421d6e99',
      permissionsName :'Sale Team Lead Dashboard',
      componentsId:null,
      components:null , 
    } , 
    SalesTeamLeadPlusDashboard : {
      permissionsId: "32491d6d-095f-4010-7f33-08dbbb5bf6df",
      permissionsName: "Sales Team Lead + Dashboard",
      description: null,
      componentsId: null,
      components: null
  }
} ;
