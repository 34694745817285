import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    root: (props) => ({
      padding: props.mainContainerPadding ?? "32px",
      marginInline: 'auto',
      // maxInlineSize: '1440px',
      flex: '1 1 auto',
      inlineSize: '100%',
      [theme.breakpoints.down('md')]: {
        padding: "32px 16px",
      },
    }),
  }
});
