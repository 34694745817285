import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'; // useDispatch
// import moment from 'moment';
import {
  PaginationComponent, Spinner, PermissionsComponent, ViewTypes,
} from '../../../Components';
import { ActionsEnum, ViewTypesEnum, TableActions } from '../../../Enums';
import {
 GetParams, GlobalHistory, bottomBoxComponentUpdate, returnPropsByPermissions
} from '../../../Helper';
import { // GetAllSystemTemplates,
  GetSystemTemplateFolderById
} from '../../../Services';
import { TemplatesPermissions } from '../../../Permissions';
import { TemplateDeleteDialog, TemplatesManagementDialog } from './Dialogs';
import { TemplateListView } from './TeamplateListView';
import { useTitle } from '../../../Hooks';
import { TemplateBuilderComponentPreviewDialog } from './Dialogs/TemplatesPreviewDialog/TemplatesTemplatebuilderComponentPreviewDialog';
// import { GlobalOrderFilterActions } from '../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
// import { DateRangePickerComponent } from '../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import { TemplatesCardsComponent } from './Sections/TemplatesCardsComponent/TemplatesCardsComponent';
import { MoveToFolderDialog } from './Dialogs/MoveToFolderDialog/MoveToFolderDialog';

const parentTranslationPath = 'TemplatesView';
const translationPath = '';
export const TemplateOpenFolder = () => {
  const { t } = useTranslation(parentTranslationPath);

  useTitle(t(`${translationPath}templates-builder`));
  const searchTimer = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [viewTypes, setViewTypes] = useState('');
  // const dispatch = useDispatch();
  const [isOpenManagementDialog, setIsOpenManagementDialog] = useState(false);
  const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false);
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const [isOpenMoveToFolde, setisOpenMoveToFolde] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [DeleteDialogTYPE, SetDeleteDialogTYPE] = useState('item');
  // const [selectedOrderBy, setSelectedOrderBy] = useState({
  //   filterBy: (orderFilter && orderFilter.TemplatesBuilder && orderFilter.TemplatesBuilder.filterBy) || 'CreatedOn',
  //   orderBy: (orderFilter && orderFilter.TemplatesBuilder && orderFilter.TemplatesBuilder.orderBy) || 2,
  // });
  // const dateRangeDefault = {
  //   startDate: null,
  //   endDate: null,
  //   key: 'selection',
  // };
  // const [dateFilter, setDateFilter] = useState(
  //   (orderFilter && orderFilter.TemplatesBuilder && orderFilter.TemplatesBuilder.startDate && orderFilter.TemplatesBuilder.endDat !== null) &&
  //   (
  //     {
  //       startDate: new Date(orderFilter && orderFilter.TemplatesBuilder && orderFilter.TemplatesBuilder.startDate || null),
  //       endDate: new Date(orderFilter && orderFilter.TemplatesBuilder && orderFilter.TemplatesBuilder.endDate || null),
  //       key: new Date(orderFilter && orderFilter.TemplatesBuilder && orderFilter.TemplatesBuilder.key || null),
  //     }) || dateRangeDefault
  // );
  // const [orderBy, setOrderBy] = useState(selectedOrderBy.filterBy ? selectedOrderBy : { filterBy: 'CreatedOn', orderBy: 2 });
  const [filter, setFilter] = useState(
    {
      search: (orderFilter && orderFilter.TemplatesBuilder && orderFilter.TemplatesBuilder.search) || '',
      pageIndex: 1,
      pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
      // filterBy: orderBy.filterBy,
      // orderBy: orderBy.orderBy,
      // fromDate: dateFilter.startDate || null,
      // toDate: dateFilter.endDate || null,
    }
  );
  // const filterByChanged = (value) => {
  //   setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  // };
  // const orderByChanged = (value) => {
  //   setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  // };
  // const orderBySubmitted = (event) => {
  //   event.preventDefault();
  //   if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
  //     if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
  //     return;
  //   }
  //   dispatch(
  //     GlobalOrderFilterActions.globalOrderFilterRequest({
  //       ...orderFilter,
  //       TemplatesBuilder: {
  //         ...orderFilter.TemplatesBuilder,
  //         filterBy: selectedOrderBy.filterBy,
  //         orderBy: selectedOrderBy.orderBy,
  //       },
  //     })
  //   );
  //   setOrderBy({
  //     filterBy: selectedOrderBy.filterBy,
  //     orderBy: selectedOrderBy.orderBy,
  //   });
  //   setFilter((item) => ({ ...item, orderBy: selectedOrderBy.orderBy, filterBy: selectedOrderBy.filterBy }));
  // };
  const [templates, setTemplates] = useState({
    result: [],
    totalCount: 0,
  });
  const [searchInput, setSearchInput] = useState((orderFilter && orderFilter.TemplatesBuilder && orderFilter.TemplatesBuilder.search) || '');
  // const searchHandler = (search) => {
  //   if (searchTimer.current) clearTimeout(searchTimer.current);
  //   searchTimer.current = setTimeout(() => {
  //     setFilter((item) => ({ ...item, pageIndex: 1, search }));
  //   }, 700);
  //   setSearchInput(search);
  //   dispatch(
  //     GlobalOrderFilterActions.globalOrderFilterRequest({
  //       ...orderFilter,
  //       TemplatesBuilder: {
  //         ...orderFilter.TemplatesBuilder,
  //         search,
  //       },
  //     })
  //   );
  // };
  const onTypeChanged = (activeType) => {
    localStorage.setItem('UserViewType', JSON.stringify(activeType));
    setViewTypes(activeType);
    setFilter((item) => ({ ...item, pageIndex: 1 }));
  };
  const getAllTemplates = useCallback(async () => {
    if (returnPropsByPermissions(TemplatesPermissions.ViewTemplates.permissionsId)) {
      setIsLoading(true);
      const res = await GetSystemTemplateFolderById(+GetParams('id'));
      if (!((res && res.data && res.data.ErrorId) || !res)) {
        setTemplates({
          result: res.systemTemplateDtos || [],
          totalCount: res.totalCount || 0,
        });
      } else {
        setTemplates({
          result: [],
          totalCount: 0,
        });
      }
      setIsLoading(false);
    }
  }, [filter]);
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex: pageIndex + 1, search: searchInput }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({
      ...item, pageIndex: 1, pageSize, search: ''
    }));
    setSearchInput('');
  };
  const onFooterActionsClickedTabel = useCallback(
    (actionEnum, activeData) => async (event) => {
      event.stopPropagation();
      if (actionEnum === TableActions.edit.key || actionEnum === ActionsEnum.reportEdit.key) {
        setActiveItem(activeData);
        setIsOpenManagementDialog(true);
      } else if (actionEnum === TableActions.view.key || actionEnum === ActionsEnum.preview.key) {
        setActiveItem(activeData);
        setIsOpenPreviewDialog(true);
      } else if (actionEnum === TableActions.delete.key || actionEnum === ActionsEnum.delete.key) {
        setActiveItem(activeData);
        setIsOpenDeleteDialog(true);
      }
    },
    []
  );

  const onFooterActionsClicked = (actionEnum, activeData) => {
    if (actionEnum === TableActions.edit.key || actionEnum === ActionsEnum.reportEdit.key) {
      setIsOpenManagementDialog(true);
      setActiveItem(activeData);
    } else if (actionEnum === TableActions.view.key || actionEnum === ActionsEnum.preview.key) {
      setIsOpenPreviewDialog(true);
      setActiveItem(activeData);
    } else if (actionEnum === TableActions.check.key) {
      setisOpenMoveToFolde(true);
      setActiveItem(activeData);
    } else if (actionEnum === TableActions.delete.key || actionEnum === ActionsEnum.delete.key) {
      setIsOpenDeleteDialog(true);
      setActiveItem(activeData);
      SetDeleteDialogTYPE('item');
    }
  };

  useEffect(() => {
    if (returnPropsByPermissions(TemplatesPermissions.ViewTemplates.permissionsId)) {
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={filter.pageIndex - 1}
          pageSize={filter.pageSize}
          totalCount={templates.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
    }
  });
  useEffect(() => {
    getAllTemplates();
  }, [filter, getAllTemplates]);
  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
    },
    []
  );
  return (
    <>
      <div className='templates-wrapper view-wrapper '>
        <Spinner isActive={isLoading} />
        <div className='d-flex-column templates-scation-search'>
          <div className='header-section'>
            <div className='filter-section'>
              <div className='section px-2'>
                <ButtonBase
                  onClick={() => GlobalHistory.push('/home/templates/view')}
                  className='btns theme-solid bg-cancel'
                >
                  <span className='mdi mdi-keyboard-backspace   mx-1 ' />
                  {t(`${translationPath}BacktoMain`)}
                </ButtonBase>
                <PermissionsComponent
                  permissionsList={Object.values(TemplatesPermissions)}
                  permissionsId={TemplatesPermissions.AddNewTemplate.permissionsId}
                >
                  <ButtonBase
                    onClick={() => setIsOpenManagementDialog(true)}
                    className='btns theme-solid bg-primary'
                  >
                    <span className='mdi mdi-plus   mx-1 ' />
                    {t(`${translationPath}add-new-template`)}
                  </ButtonBase>
                </PermissionsComponent>
              </div>
              <div className='section autocomplete-section'>
                <div className='d-flex-column px-2 w-100 p-relative'>
                  <div className='w-100 p-relative d-flex  ' />
                  <div className='sc-search d-flex px-2'>
                    <div className='' />

                  </div>
                </div>
                <ViewTypes
                  onTypeChanged={onTypeChanged}
                  initialActiveType={viewTypes}
                  activeTypes={[ViewTypesEnum.cards.key, ViewTypesEnum.tableView.key]}
                  className='mb-3'
                />
              </div>
            </div>

          </div>
          <PermissionsComponent
            permissionsList={Object.values(TemplatesPermissions)}
            permissionsId={TemplatesPermissions.ViewTemplates.permissionsId}
          >
            {viewTypes === ViewTypesEnum.tableView.key && (
              <div>
                <TemplateListView
                  data={templates}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  filter={filter}
                  onPageIndexChanged={onPageIndexChanged}
                  onPageSizeChanged={onPageSizeChanged}
                  onFooterActionsClicked={onFooterActionsClickedTabel}
                />
              </div>
            )}
          </PermissionsComponent>
          <PermissionsComponent
            permissionsList={Object.values(TemplatesPermissions)}
            permissionsId={TemplatesPermissions.ViewTemplates.permissionsId}
          >
            {viewTypes !== ViewTypesEnum.tableView.key && (
              <TemplatesCardsComponent
                data={templates.result}
                onFooterActionsClicked={(actionEnum, activeData) => onFooterActionsClicked(actionEnum, activeData)}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />

            )}
          </PermissionsComponent>
        </div>
        {isOpenManagementDialog && isOpenManagementDialog && (
          <TemplatesManagementDialog
            activeItem={activeItem}
            isOpen={isOpenManagementDialog}
            onSave={() => {
              setFilter((item) => ({ ...item, pageIndex: 0 }));
              setIsOpenManagementDialog(false);
              setActiveItem(null);
            }}
            isOpenChanged={() => {
              setIsOpenManagementDialog(false);
              setActiveItem(null);
            }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}
        {isOpenPreviewDialog && (
          <TemplateBuilderComponentPreviewDialog
            activeItem={activeItem}
            isOpen={isOpenPreviewDialog}
            isOpenChanged={() => {
              setIsOpenPreviewDialog(false);
              setActiveItem(null);
            }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}
        {isOpenDeleteDialog && (
          <TemplateDeleteDialog
            activeItem={activeItem}
            isOpen={isOpenDeleteDialog}
            DeleteDialogTYPE={DeleteDialogTYPE}
            isOpenChanged={() => {
              setIsOpenDeleteDialog(false);
              setActiveItem(null);
            }}
            reloadData={() => {
              setFilter((item) => ({ ...item, pageIndex: 0 }));
              setActiveItem(null);
              setIsOpenDeleteDialog(false);
            }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}
        <MoveToFolderDialog
          isOpen={isOpenMoveToFolde}
          activeItem={activeItem}
          reloadData={() => {
            setFilter((item) => ({ ...item, pageIndex: 0 }));
            setActiveItem(null);
            setIsOpenDeleteDialog(false);
            getAllTemplates();
          }}
          isOpenChanged={() => { setisOpenMoveToFolde(false); }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
    </>
  );
};
