import { HttpServices } from "../../Helper";
import { config } from "../../config";

export const GetAllFacilitiesAndAmenitiesScoresAPI = async () => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/PropertyRating/GetAllPropertyRatingFacilitiesAndAmenitiesScores`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdatePropertyRatingFacilitiesAndAmenitiesScoresAPI = async (
  body
) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/PropertyRating/UpdatePropertyRatingFacilitiesAndAmenitiesScores`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response.data);
  return result;
};
