import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { Menu, Icon, makeStyles, ButtonBase, Badge } from "@material-ui/core";
import { PropTypes } from "prop-types";

import { NotificationTabs } from "../../NotificationTabs";
import { useIsAr, useIsDesktop } from "../../../../Hooks";
import NotificationSound from "./CRM-notification-sound.mp3";

function Notifications({ setMobileTabValue }) {
  const [alertItems, setAlertItems] = useState([]);
  const [importantItems, setImportantItems] = useState([]);
  const [actionItems, setActionItems] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const theme = useSelector((store) => store.theme);
  const { isDesktop } = useIsDesktop();

  const [NewNotification, setNewNotification] = useState(
    JSON.parse(
      localStorage.getItem("NotificationNumber")
        ? localStorage.getItem("NotificationNumber")
        : 0
    )
  );

  const audioPlayer = useRef(null);
  const { isAr } = useIsAr(theme.themeDirection);

  const playAudio = () => {
    audioPlayer.current.play();
  };

  useEffect(() => {
    if (NewNotification !== null && isDesktop) playAudio();
  }, [NewNotification]);

  const handleMenuOpen = (event) => {
    localStorage.setItem("NotificationNumber", "0");
    setNewNotification(0);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    localStorage.setItem("NotificationNumber", "0");
    setNewNotification(0);
    setAnchorEl(null);

    if (setMobileTabValue) {
      setMobileTabValue(-1);
    }
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const anchorOrigin = isAr
    ? { vertical: "bottom", horizontal: "left" }
    : { vertical: "bottom", horizontal: "right" };

  const transformOrigin = isAr
    ? { vertical: "top", horizontal: "left" }
    : { vertical: "top", horizontal: "right" };

  const useStyles = makeStyles((theme) => ({
    customMenu: {
      top: "60px !important",
      borderRadius: "12px !important",
      border: `1px solid ${theme.palette.border.secondary}`,
      overflowY: "hidden",
      boxShadow:
        "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03) !important",
    },
    notificationsBadge: {
      "& .MuiBadge-badge": {
        width: "12px",
        height: "12px",
        color: "#FFF",
        fontSize: "13px",
        fontweight: 500,
        minWidth: "10px !important",
        backgroundColor: "#EA5455",
      },
    },
    notificationsIcon: {
      color: theme.palette.text.tertiary,
      fontSize: "20px",
    },

    notificationsButton: (props) => ({
      color: theme.palette.text.tertiary,
      padding: "8px 10px",
      borderRadius: "6px",
      "&:hover": {
        background: theme.palette.background.primary_hover,
      },
      boxShadow: props.isMenuExpanded
        ? "0px 0px 0px 4px rgba(152, 162, 179, 0.14)"
        : "none",
    }),
    customMenuList: {
      padding: 0,
    },
  }));

  const styles = useStyles({ isMenuExpanded: !!anchorEl });

  return !isDesktop ? (
    <NotificationTabs
      tabValue={tabValue}
      handleChange={handleChange}
      onClose={handleMenuClose}
      alertItems={alertItems}
      setAlertItems={setAlertItems}
      importantItems={importantItems}
      setImportantItems={setImportantItems}
      actionItems={actionItems}
      setActionItems={setActionItems}
    />
  ) : (
    <>
      <ButtonBase
        aria-haspopup="true"
        onClick={handleMenuOpen}
        aria-controls="notifications-menu"
        className={styles.notificationsButton}
      >
        <Badge
          badgeContent={NewNotification > 0 ? "" : undefined}
          className={clsx(styles.notificationsBadge)}
          max={99}
        >
          <Icon
            className={clsx(
              "icon-header-notifications",
              styles.notificationsIcon
            )}
          />
        </Badge>

        <audio ref={audioPlayer} src={NotificationSound} />
      </ButtonBase>

      <Menu
        id="notifications-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        getContentAnchorEl={null}
        PaperProps={{
          className: styles.customMenu,
        }}
        MenuListProps={{
          className: styles.customMenuList,
        }}
      >
        <NotificationTabs
          tabValue={tabValue}
          handleChange={handleChange}
          onClose={handleMenuClose}
          alertItems={alertItems}
          setAlertItems={setAlertItems}
          importantItems={importantItems}
          setImportantItems={setImportantItems}
          actionItems={actionItems}
          setActionItems={setActionItems}
        />
      </Menu>
    </>
  );
};

Notifications.propTypes = {
  setMobileTabValue: PropTypes.func,
};

Notifications.defaultProps = {
  setMobileTabValue: () => {},
};

export default Notifications;
