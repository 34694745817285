import React from 'react';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  unitCardDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    paddingTop: '16px',
  },
  unitCardDetails: {
    display: 'flex',
    gap: '12px',
    width: '100%',
    justifyContent: 'space-between',
  },
  unitNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    width: '100%',
  },
  nameAndPriceWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '4px',
  },
  UnitName: {
    maxWidth: '218px',
    minHeight: '20px',
  },
  badgesContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  subDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    flexWrap: 'wrap',
  },
  iconDetailsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
}));
function ContactUnitCardSkeleton() {
  const styles = useStyles();

  return (
    <Box className={styles.unitCardDetailsContainer}>
      <Box className={styles.unitCardDetails}>
        <Box>
          <Skeleton
            variant='rectangular'
            height={40}
            width={50}
            style={{ borderRadius: '6px' }}
          />
        </Box>
        <Box className={styles.unitNameContainer}>
          <div className={styles.nameAndPriceWrapper}>
            <Skeleton variant='text' width='55%' height={32} />
            <Skeleton variant='text' width='30%' height={32} />
          </div>
          <Skeleton variant='text' width='50%' height={20} />
          <div className={styles.badgesContainer}>
            <Skeleton variant='rectangular' width={100} height={20} />
            <Skeleton
              variant='text'
              width={30}
              height={20}
              style={{ marginLeft: '8px' }}
            />
          </div>
          <div className={styles.subDetails}>
            <Box className={styles.iconDetailsWrapper}>
              <Skeleton variant='rectangular' width={20} height={20} />
              <Skeleton
                variant='text'
                width={20}
                height={20}
                style={{ marginLeft: '8px' }}
              />
            </Box>
            <Box className={styles.iconDetailsWrapper}>
              <Skeleton variant='rectangular' width={20} height={20} />
              <Skeleton
                variant='text'
                width={20}
                height={20}
                style={{ marginLeft: '8px' }}
              />
            </Box>
            <Box className={styles.iconDetailsWrapper}>
              <Skeleton variant='rectangular' width={20} height={20} />
              <Skeleton
                variant='text'
                width={20}
                height={20}
                style={{ marginLeft: '8px' }}
              />
            </Box>
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default ContactUnitCardSkeleton;
