

import { PropertiesCreatedBy , PropertiesAddress } from '../PropertiesPolicies' ; 

export const PropertiesPoliciesTab = [
    {   
      label: 'createdBy',
      component: PropertiesCreatedBy  ,
    } , 
    {   
        label: 'address',
        component: PropertiesAddress  ,
    } ,
]
  

