import React, {
  useEffect,
  useState,
  useReducer,
  useCallback,
  useRef,
} from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { showError, showSuccess } from "../../../../Helper";
import { MicrosoftToken, lookupItemsGetId } from "../../../../Services";
import { SpinnerDashboards } from "../SpinnerDashboards/SpinnerDashboards";
import { DateRangePickerComponent } from "../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import moment from "moment";
import {
  AutocompleteComponent,
  Inputs,
  SelectComponet,
} from "../../../../Components";
import { ActionsButtonsEnum, MediaEnum, Status } from "../../../../Enums";
import { useTranslation } from "react-i18next";
import { OrganizationUserSearch } from "../../../../Services/userServices";
import { useSelector, useDispatch } from "react-redux";
import { GlobalOrderFilterActions } from "../../../../store/GlobalOrderFilter/GlobalOrderFilterActions";
import {
  Button,
  ButtonBase,
  ButtonGroup,
  CircularProgress,
  Switch,
} from "@material-ui/core";
import { CellsSpinner } from "../../../../SharedComponents/CellSpinnerComponent/CellsSpinner";
import { CellSpinnerBi } from "../CellSpinnerBi/CellSpinnerBi";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import "../AdminMarketing/Navbar.scss";
const parentTranslationPath = "LeadsView";
const translationPath = "";

export const AdminCallCenter = ({  id, embedUrl }) => {
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);

  const { t } = useTranslation(parentTranslationPath);
  const dispatch = useDispatch();

  const [report, setReport] = useState();
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);
  const [DateAppaer, setDateAppaer] = useState(false);
  const [Datetime, setDatetime] = useState(30);
  const [Leadtype, setLeadtype] = useState();
  const [Leadtypestatus, setLeadtypestatus] = useState(true);
  const [OperatorLeadType, setOperatorLeadType] = useState("All");
  const [LeadClass, setLeadClass] = useState();
  const [LeadClassstatus, setLeadClassstatus] = useState(true);
  const [OperatorLeadClass, setOperatorLeadClass] = useState("All");
  const [Leadstatus, setLeadstatus] = useState();
  const [Leadstatusstatus, setLeadstatusstatus] = useState(true);
  const [OperatorLeadstatus, setOperatorLeadstatus] = useState("All");
  const [datefilters, setdatefilter] = useState(true);
  const [activeButton, setActiveButton] = useState("One");
  const [activeGroupButton, setactiveGroupButton] = useState(true);
  const [vlueapper, setvlueapper] = useState(false);
  const [datefiltersActivity, setdatefilteractivity] = useState(false);
  const [ShowReport2, setShowReport2] = useState(false);
  const [datepicker, setdatepicker] = useState();

  const [salestransactions, setsalestransactions] = useState(false);
  const [Activity, setActivity] = useState(false);
  const [Leads, setLeads] = useState(false);
  const [leasetransaction, setleasetransaction] = useState(false);
  const [Page, setpage] = useState();

  const [monthsAgo, setMonthsAgo] = useState(1);

  const currentDate = new Date();
  const countmonth = new Date(
    currentDate.setMonth(currentDate.getMonth() - monthsAgo)
  );

  const today = new Date();

  const count = new Date(new Date().setDate(today.getDate() - 7));
  const count2 = new Date(new Date().setDate(today.getDate()));

  const datetimes = moment(count).startOf("day").format("YYYY-MM-DDTHH:mm:ss");
  const datetimes2 = moment(count2).format("YYYY-MM-DDTHH:mm:ss");

  const startDate = datetimes ? new Date(datetimes) : null;
  const endDate = datetimes2 ? new Date(datetimes2) : null;

  const dateRangeDefault = {
    startDate: startDate || null,
    endDate: endDate || null,
    key: "selection",
  };

  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [data, setdata] = useState([]);
  const [isDatePickerChanged, setIsDatePickerChanged] = useState(false);

  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [state, setState] = useReducer(reducer, {
    fromDate: null,
    toDate: null,
  });

  useEffect(() => {
    Microsoft(false);
  }, []);

  useEffect(() => {
    const currentState = {
      ...state,
      fromDate:
        (dateFilter.startDate &&
          moment(dateFilter.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        datetimes,
      toDate:
        (dateFilter.endDate &&
          moment(dateFilter.endDate)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        datetimes2,
    };
    setState({ value: currentState });
    setdata(currentState);
  }, [dateFilter]);

  const advancedFilter = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "LeadsView",
      column: datefilters ? "CreatedOn" : "LeadAssignDate",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };
  const advancedFilterActivity = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "LM_QA_Activity_Category_Leads",
      column: "ActivityUpdateonDate",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };
  const advancedFilterLeaseTransaction = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "View_LeaseTransactionsTenantLandLord",
      column: "LeaseTransactionDate",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };
  const advancedFilterSalesTransaction = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "View_SalesTransactionsSellerBuyer",
      column: "SalesTransactionDate",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };

  const FilterLeadType = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "LeadsView",
      column: "LeadsType",
    },
    operator: OperatorLeadType,
    values: [Leadtype],
    filterType: models.FilterType.BasicFilter,
  };

  let selectedFilter;

  switch (true) {
    case Leads:
      selectedFilter = advancedFilter;
      break;
    case Activity:
      selectedFilter = advancedFilterActivity;
      break;
    case leasetransaction:
      selectedFilter = advancedFilterLeaseTransaction;
      break;
    case salestransactions:
      selectedFilter = advancedFilterSalesTransaction;
      break;
    default:
      selectedFilter = advancedFilter;
  }

  const FilterLeadClass = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "LeadsView",
      column: "LeadClass",
    },
    operator: OperatorLeadClass,
    values: [LeadClass],
    filterType: models.FilterType.BasicFilter,
  };
  const FilterLeadStatus = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "LeadsView",
      column: "StatusId",
    },
    operator: OperatorLeadstatus,
    values: [Leadstatus],
    filterType: models.FilterType.BasicFilter,
  };

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setRender(true);
      setShowReport(true);
    } else {
      setReport("");
      showError("Contact Your Bi Admin For Help");
    }
  };

  const onClearedAllFiltersClick = useCallback(() => {
    setDateFilter(dateRangeDefault);
    applyBookmark("Bookmark5b52d9aa7c75d4e88fa1");
    setLeadtype(0);
    setLeadtypestatus(true);
    setLeadstatus(0);
    setLeadstatusstatus(true);
    setLeadClass(0);
    setLeadClassstatus(true);
    setdatepicker(0);
    setdatefilter(true);
  }, []);

  const [bookmarks, setBookmarks] = useState([]);
  const [error, setError] = useState(null);

  const loadBookmarks = async () => {
    const report = window.report;

    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        const fetchedBookmarks = await bookmarksManager.getBookmarks();
        setBookmarks(fetchedBookmarks);
      } catch (error) {
        console.error("Error loading bookmarks:", error);
        setError(error);
      }
    }
  };
  const applyBookmark = async (bookmarkName) => {
    const report = window.report;
    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        bookmarksManager.apply(bookmarkName);
        // console.log("Bookmark applied successfully:", bookmarkName);
      } catch (error) {
        console.error("Error applying bookmark:", error);
        setError(error);
      }
    }
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const ChartView = () => {
    applyBookmark("Bookmarkf623b3e01f3ba3e56a23");
    handleButtonClick("One");
    setShowReport2(false);
  };
  const TableView = () => {
    applyBookmark("Bookmark7901e45cf8f0a3712730");
    handleButtonClick("Two");
    setShowReport2(false);
  };



  const [activeIndex, setActiveIndex] = useState(0);
  const horiSelectorRef = useRef(null);
  const navbarRef = useRef(null);



  useEffect(() => {
    const updateSelector = () => {
      if (navbarRef.current && horiSelectorRef.current) {
        const tabs = navbarRef.current.querySelectorAll("li");
        if (tabs.length > 0) {
          const activeItem = tabs[activeIndex];
          const activeWidth = activeItem.offsetWidth;
          const itemPosLeft = activeItem.offsetLeft;
          horiSelectorRef.current.style.left = `${itemPosLeft}px`;
          horiSelectorRef.current.style.width = `${activeWidth}px`;
        }
      }
    };

 
    updateSelector();
  }, [activeIndex]);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  const [filterButton, setfilterButton] = useState(false);

  const FilterHide = () => {
    applyBookmark("Bookmarke8bdfc92671bb77c202b");
    setfilterButton(false);
  };
  const FilterView = () => {
    applyBookmark("Bookmark4a47b9d51da010b8d401");
    setfilterButton(true);
  };

  const [MenuButton, setMenuButton] = useState("One");

  const handleMenuButtonClick = (buttonName) => {
    setMenuButton(buttonName);
  };

  const GeneralPage = () => {
    handleMenuButtonClick("One");
    Pagenavi("ReportSection6eaf615e56ee0fe885da");
  };
  const SalesTransactionPage = () => {
    handleMenuButtonClick("Two");
    Pagenavi("ReportSectionfabb05623e665c4320c0");
  };
  const LeaseTransactionPage = () => {
    handleMenuButtonClick("Three");
    Pagenavi("ReportSection595ce0429d70656db1d3");
  };
  const LeadActivityPage = () => {
    handleMenuButtonClick("Four");
    Pagenavi("ReportSectionfe30ed5ed4895de9506a");
  };

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const Pagenavi = async (pagestate) => {
    const report = window.report;
    let pages = await report.getPages();

    if (report) {
      try {
        report.setPage(pagestate);
        console.log(pages);
      } catch (error) {
        console.error("Error applying:", error);
      }
    }
  };

  const handleRefreshClick = useCallback(() => {
    window.report
      .refresh()
      .then(() => {
        setShowReport2(false);
      })
      .catch((error) => {});
  }, []);

  const [activeThemeButton, setActiveThemeButton] = useState("blue");
  const handleButtonClick2 = (buttonName) => {
    setActiveThemeButton(buttonName);
  };

  const applytheme = async () => {
    const report = window.report;
    handleButtonClick2("gold");
    setThemeicon(true);
    if (report) {
      const themeJsonObject = require("../CRMFilterDashboards/Theme_1.json");

      try {
        // Apply the theme
        report.applyTheme({ themeJson: themeJsonObject });

        console.log("Theme applied successfully!");
      } catch (error) {
        console.error("Error applying theme:", error);
      }
    }
  };
  const applytheme2 = async () => {
    const report = window.report;
    handleButtonClick2("blue");
    setThemeicon(false);
    if (report) {
      const themeJsonObject = require("../CRMFilterDashboards/Theme_2.json");

      try {
        // Apply the theme
        report.applyTheme({ themeJson: themeJsonObject });

        console.log("Theme applied successfully!");
      } catch (error) {
        console.error("Error applying theme:", error);
      }
    }
  };

  const gettheme = async () => {
    const report = window.report;

    if (report) {
      try {
        report
          .getTheme()
          .then((theme) => {
            console.log("theme", theme);
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        console.error("Error  theme:", error);
      }
    }
  };
  const [checkedstate, setcheckedState] = useState({
    checkedA: false,
  });

  const [Themeicon, setThemeicon] = useState(false);

  const handleChange = (event) => {
    setcheckedState({
      ...checkedstate,
      [event.target.name]: event.target.checked,
    });
    if (event.target.checked) {
      applytheme();
    } else {
      applytheme2();
    }
  };

  useEffect(() => {
    if (Leadtypestatus) setOperatorLeadType("All");
    else setOperatorLeadType("In");
    if (LeadClassstatus) setOperatorLeadClass("All");
    else setOperatorLeadClass("In");
    if (Leadstatusstatus) setOperatorLeadstatus("All");
    else setOperatorLeadstatus("In");
  }, [Leadtype, Leadtypestatus, LeadClassstatus, LeadClass, Leadstatusstatus]);

  return (
    <div className="dashboardMain-PowerBIEmbed">
        <CellSpinnerBi
        isActive={!ShowReport}
        blue={activeThemeButton === "blue"}
      />
           <div>
        <div id="navbar-animmenu" ref={navbarRef}>
          <ul className="show-dropdown main-navbar">
            <div className="hori-selector" ref={horiSelectorRef}>
              <div className="left"></div>
              <div className="right"></div>
            </div>
            <li
              className={activeIndex === 0 ? "active" : ""}
              onClick={() => {
                handleItemClick(0);
                GeneralPage();
              }}
            >
              <a>
                <i className={`mdi mdi-account-details-outline mdi-24px `}></i>
                {t(`${translationPath}general-menu`)}
              </a>
            </li>
            <li
              className={activeIndex === 1 ? "active" : ""}
              onClick={() => {
                handleItemClick(1);
                SalesTransactionPage();
              }}
            >
              <a>
                <i className={`mdi mdi-book-account-outline mdi-24px `}></i> 
                {t(`${translationPath}salestransaction-menu`)}
              </a>
            </li>
            <li
              className={activeIndex === 2 ? "active" : ""}
              onClick={() => {
                handleItemClick(2);
                LeaseTransactionPage();
              }}
            >
              <a>
                <i className={`mdi mdi-book-account-outline mdi-24px `}></i> 
                {t(`${translationPath}leasestransaction-menu`)}
              </a>
            </li>
            <li
              className={activeIndex === 3 ? "active" : ""}
              onClick={() => {
                handleItemClick(3);
                LeadActivityPage();
              }}
            >
              <a>
                <i className={`mdi mdi-alert-rhombus mdi-24px  `}></i> 
                {t(`${translationPath}activity-menu`)}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <br />
      {render && (
        <div className="mainCardDashboards">
          <div className="dashboardMain cardDashboard">
            {DateAppaer && (
          
        <div>
          <div className="MainFiltersBi">
            <CellSpinnerBi
              isActive={!ShowReport2}
              blue={activeThemeButton === "blue"}
            />
            <div className="selecterbi">
          
              <div>
                <SelectComponet
                  data={[
                    { id: 1, Leadtype: "owner" },
                    { id: 2, Leadtype: "seeker" },
                  ]}
                  emptyItem={{
                    value: 0,
                    text: "select-category",
                    isDisabled: false,
                  }}
                  value={Leadtype || 0}
                  defaultValue={0}
                  valueInput="id"
                  textInput="Leadtype"
                  onSelectChanged={(value) => {
                    switch (value) {
                      case 0:
                        setLeadtypestatus(true);
                        setLeadtype(0);
                        break;
                      default:
                        setLeadtype(value);
                        setLeadtypestatus(false);
                        setShowReport2(false);
                        break;
                    }
                  }}
                  wrapperClasses="w-auto"
                  themeClass="theme-transparent"
                  idRef="Select_Category"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
              <div>
                <SelectComponet
                  data={[
                    { id: 457, Leadstatus: "open" },
                    { id: 458, Leadstatus: "closed" },
                  ]}
                  emptyItem={{
                    value: 0,
                    text: "select-status",
                    isDisabled: false,
                  }}
                  value={Leadstatus || 0}
                  defaultValue={0}
                  valueInput="id"
                  textInput="Leadstatus"
                  onSelectChanged={(value) => {
                    switch (value) {
                      case 0:
                        setLeadstatusstatus(true);
                        setLeadstatus(0);
                        break;
                      default:
                        setLeadstatus(value);
                        setLeadstatusstatus(false);
                        setShowReport2(false);
                        break;
                    }
                  }}
                  wrapperClasses="w-auto"
                  themeClass="theme-transparent"
                  idRef="Lead_Status"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
              <div>
                <SelectComponet
                  data={[
                    { id: 1, LeadClass: "seller" },
                    { id: 2, LeadClass: "landlord" },
                    { id: 3, LeadClass: "buyer" },
                    { id: 4, LeadClass: "tenant" },
                  ]}
                  emptyItem={{
                    value: 0,
                    text: "select-lead-type",
                    isDisabled: false,
                  }}
                  value={LeadClass || 0}
                  defaultValue={0}
                  valueInput="id"
                  textInput="LeadClass"
                  onSelectChanged={(value) => {
                    switch (value) {
                      case 0:
                        setLeadClassstatus(true);
                        setLeadClass(0);
                        break;
                      default:
                        setLeadClass(value);
                        setLeadClassstatus(false);
                        setShowReport2(false);
                        break;
                    }
                  }}
                  wrapperClasses="w-auto"
                  themeClass="theme-transparent"
                  idRef="Lead_Type"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
            </div>

            <div className="selecterbi">
              {!Leads ? (
                <div>
                  {salestransactions || leasetransaction ? (
                    <SelectComponet
                      emptyItem={{
                        text: salestransactions
                          ? "Sales Transactions"
                          : "Lease Transactions",
                        isDisabled: true,
                      }}
                      valueInput="key"
                      textInput="value"
                      themeClass="theme-transparent"
                      wrapperClasses="w-auto"
                      idRef="Date_Select"
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                    />
                  ) : (
                    <SelectComponet
                      emptyItem={{
                        text: "Activity update on",
                        isDisabled: true,
                      }}
                      valueInput="key"
                      textInput="value"
                      themeClass="theme-transparent"
                      wrapperClasses="w-auto"
                      idRef="Date_Select"
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                    />
                  )}
                </div>
              ) : (
                <div>
                  <SelectComponet
                    data={[
                      {
                        id: 1,
                        datepicker: `${translationPath}last-assign-date`,
                      },
                    ]}
                    emptyItem={{
                      value: 0,
                      text: `${translationPath}created-date`,
                      isDisabled: false,
                    }}
                    value={datepicker || 0}
                    defaultValue={0}
                    valueInput="id"
                    textInput="datepicker"
                    onSelectChanged={(value) => {
                      switch (value) {
                        case 0:
                          setdatepicker(0);
                          setdatefilter(true);
                          setShowReport2(false);
                          break;
                        default:
                          setdatepicker(1);
                          setdatefilter(false);
                          setShowReport2(false);
                          break;
                      }
                    }}
                    themeClass="theme-transparent"
                    wrapperClasses="w-auto"
                    idRef="Date_Select"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
              )}

              <div>
                <DateRangePickerComponent
                  onClearClicked={() => {
                    setDateFilter({
                      startDate: startDate,
                      endDate: endDate,
                      key: "selection",
                    });
                  }}
                  ranges={[dateFilter]}
                  onDateChanged={(selectedDate) => {
                    const newStartDate =
                      selectedDate.selection &&
                      selectedDate.selection.startDate;
                    const newEndDate = new Date(
                      moment(
                        selectedDate.selection && selectedDate.selection.endDate
                      ).endOf("day")
                    );

                    if (
                      newStartDate.getTime() !==
                        dateFilter.startDate.getTime() ||
                      newEndDate.getTime() !== dateFilter.endDate.getTime()
                    ) {
                      setShowReport2(false);
                    } else {
                      setShowReport2(true);
                    }

                    setDateFilter({
                      startDate: newStartDate,
                      endDate: newEndDate,
                      key: "selection",
                    });
                  }}
                />
              </div>
            </div>
            {!filterButton ? (
              <div className="DashboardFilter">
                <ButtonBase
                  variant="contained"
                  className="btns theme-solid bg-primary-dark Dashboardbtn"
                  style={{ color: "white" }}
                  onClick={FilterView}
                  disabled={activeButton == "Two" || activeGroupButton == false}
                >
                  <span className="mdi mdi-filter-menu m-1" />
                </ButtonBase>
              </div>
            ) : (
              <div className="DashboardFilter">
                <ButtonBase
                  variant="contained"
                  className="btns theme-solid bg-danger Dashboardbtn"
                  style={{ color: "white" }}
                  onClick={FilterHide}
                >
                  <span className="mdi mdi-filter-minus-outline m-1" />
                </ButtonBase>
              </div>
            )}
            <div className="DashboardFilter">
              <ButtonBase
                onClick={onClearedAllFiltersClick}
                id="onClearedAllFiltersref"
                className="btns theme-solid bg-primary-dark clear-all-btn Dashboardbtn"
                disabled={activeGroupButton == false}
              >
                <span className="mdi mdi-filter-remove m-1" />
              </ButtonBase>
            </div>
            <div className="DashboardFilter">
              <ButtonBase
                onClick={handleRefreshClick}
                id="onClearedAllFiltersref"
                className="btns theme-solid bg-primary-dark clear-all-btn Dashboardbtn"
              >
                <span className="mdi mdi-refresh m-1" />
              </ButtonBase>
            </div>
            {activeGroupButton ? (
            <div className="DashboardFilter">
            <ButtonGroup>
              <ButtonBase
                onClick={ChartView}
                className={`btns theme-solid  clear-all-btn Dashboardbtn ${
                  activeButton === "One"
                    ? "chartActive"
                    : "chartNotActive"
                }`}
              >
                <span className="mdi mdi-chart-pie m-1" />
              </ButtonBase>

              <ButtonBase
                onClick={TableView}
                className={`btns theme-solid  clear-all-btn Dashboardbtn ${
                  activeButton === "Two"
                    ? "chartActive"
                    : "chartNotActive"
                }`}
              >
                <span className="mdi mdi-format-list-bulleted-square m-1" />
              </ButtonBase>
            </ButtonGroup>
          </div>
            ) : (
              <div></div>
            )}
          </div>

      
        </div>
      )}
      <br />

            <PowerBIEmbed
              embedConfig={{
                type: "report",
                id,
                embedUrl,
                accessToken: report,
                filters: [
                  selectedFilter,
                  FilterLeadType,
                  FilterLeadClass,
                  FilterLeadStatus,
                ],
                settings: {
                  customLayout: {
                    displayOption: models.DisplayOption.FitToWidth,
                  },
                  filterPaneEnabled: false,
                  navContentPaneEnabled: false,
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false,
                    },
                  },
                  background: models.BackgroundType.Transparent,
                },
              }}
              eventHandlers={
                new Map([
                  [
                    "loaded",
                    (event) => {
                 
                      setDateAppaer(true);
                    },
                  ],
                  [
                    "dataSelected",

                    (event) => {
                      setShowReport2(false);
                    },
                  ],

                  [
                    "rendered",
                    (event) => {
                      setShowReport2(true);
                      setRender(true);
                    },
                  ],

                  [
                    "pageChanged",

                    (event) => {
                      const newPageName = event.detail.newPage.displayName;

                      console.log(newPageName);
                      switch (newPageName) {
                        case "Sale Transaction ":
                          setsalestransactions(true);
                          setActivity(false);
                          setLeads(false);
                          setactiveGroupButton(false);
                          setleasetransaction(false);
                          setShowReport2(false);
                          setTimeout(() => {
                            scrollToTop();
                          }, 5);

                          break;
                        case "Lease Transaction ":
                          setsalestransactions(false);
                          setactiveGroupButton(false);
                          setActivity(false);
                          setLeads(false);
                          setleasetransaction(true);
                          setShowReport2(false);
                          setTimeout(() => {
                            scrollToTop();
                          }, 5);

                          break;
                        case "Lead Activity":
                          setsalestransactions(false);
                          setactiveGroupButton(false);
                          setActivity(true);
                          setLeads(false);
                          setleasetransaction(false);
                          setShowReport2(false);
                          setTimeout(() => {
                            scrollToTop();
                          }, 5);
                          break;

                        default:
                          setsalestransactions(false);
                          setactiveGroupButton(true);
                          setActivity(false);
                          setLeads(true);
                          setleasetransaction(false);
                          setShowReport2(false);
                          setTimeout(() => {
                            scrollToTop();
                          }, 5);
                          break;
                      }
                    },
                  ],

                  [
                    "error",
                    (event) => {
                      const error = event.detail;
                      if (
                        error.detailedMessage === "Get report failed" ||
                        error.detailedMessage ===
                          "Access token has expired, resubmit with a new access token" ||
                        error.detailedMessage ===
                          "Fail to initialize - Could not resolve cluster"
                      ) {
                        Microsoft(true);
                        setRender(false);
                      } else console.log(error.detailedMessage);
                      showError(error.detailedMessage);
                    },
                  ],
                ])
              }
              cssClassName={
                ShowReport
                  ? "report-style-class-Show"
                  : "report-style-class-hide"
              }
              getEmbeddedComponent={(embeddedReport) => {
                window.report = embeddedReport;
              }}
            />
          </div>
          </div>
   
        )}
   
    </div>
  );
};
