import moment from 'moment';
import React, { useCallback, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import {
    PaginationComponent, Spinner, SwitchComponent, Tables
} from '../../../../Components';
import { TableFilterTypesEnum } from '../../../../Enums';
import { bottomBoxComponentUpdate } from '../../../../Helper';
import { GetAgentsInRotationLog } from '../../../../Services';

export const AgentRotationTableView = ({
    translationPath, parentTranslationPath, filter, setFilter, setSortBy
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState({
        result: [],
        totalCount: 0,
    });

    const [tableFilterData, setTableFilterData] = useState([]);
    const [agentRotationTableFilter, setAgentRotationTableFilter] = useState(null);

    const HeaderAgentRotationTable = [
        {
            id: 1,
            label: t(`${translationPath}agent-name`),
            input: 'agentName',
            isSortable: true,
            isHiddenFilter: true,
            withSelectFilter: false
        },
        {
            id: 2,
            label: t(`${translationPath}StaffID`),
            input: 'staffId',
            isSortable: true,
            isHiddenFilter: true,
            isDate: false

        },
        {
            id: 3,
            label: t(`${translationPath}change-status-date`),
            input: 'changeStatusDate',
            isSortable: true,
            isHiddenFilter: true,
            isDate: true
        },
        {
            id: 4,
            label: t(`${translationPath}change-status-time`),
            input: 'changeStatusDate',
            component: (item) => {
                const time = item && item.changeStatusDate;
                return moment(time).format('LTS');
            },
            isSortable: true,
            isHiddenFilter: true,
            isDate: false
        },
        {
            id: 5,
            label: t(`${translationPath}in-rotation-now`),
            input: 'isOn',
            component: (item) => (
              <div>
                {(item && item.isOn === true && (
                <div id='rotation-ON/Off-LOG' className='ROTATION-ON-LOG'>{t(`${translationPath}ON`)}</div>
                    )) || <div id='rotation-ON/Off-bbt' className='ROTATION-Off-LOG'>{t(`${translationPath}Off`)}</div> ||
                        'N/A'}
              </div>
            ),
            isSortable: true,
            isHiddenFilter: true
        } ,
        {
            id: 6,
            label: t(`${translationPath}updated-by`),
            input: 'createdBy',
            isSortable: true,
            isHiddenFilter: true,
            isDate: false

        },
        {
            id: 7,
            label: t(`${translationPath}reason`),
            input: 'changeReasonName',
            isSortable: true,
            isHiddenFilter: true,
            isDate: false

        },
        {
            id: 8,
            label: t(`${translationPath}remarks`),
            input: 'notes',
            isSortable: true,
            isHiddenFilter: true,
            isDate: false

        },
     ];

    const getAgentsInRotationLog = useCallback(async (f) => {
        setIsLoading(true);

        const localFilterDto = f || filter || {};
        if (agentRotationTableFilter) {
            Object.values(agentRotationTableFilter)
                .map((item) => {
                    if (localFilterDto[item.displayPath])
                        localFilterDto[item.displayPath] = item.value;
                    else
                        localFilterDto[item.displayPath] = item.value;

                    return undefined;
                });
        }

        const res = await GetAgentsInRotationLog({ ...filter, ...localFilterDto, pageIndex: filter.pageIndex + 1 });
        if (!(res && res.status && res.status !== 200)) {
            setResponse({
                result: (res && res.result),
                totalCount: (res && res.totalCount)
            });
        } else {
            setResponse({
                result: [],
                totalCount: 0,
            });
        }
        setIsLoading(false);
    }, [filter]);

    useEffect(() => {
        getAgentsInRotationLog();
    }, [getAgentsInRotationLog]);

    const onPageIndexChanged = (pageIndex) => {
        setFilter((item) => ({ ...item, pageIndex }));
    };

    const onPageSizeChanged = (pageSize) => {
        setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    };

    useEffect(() => {
        bottomBoxComponentUpdate(
          <PaginationComponent
            pageIndex={filter.pageIndex}
            pageSize={filter.pageSize}
            totalCount={(response && response.totalCount) || 0}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
          />
        );
    });

    useEffect(() => () => { bottomBoxComponentUpdate(null); }, []);

    useEffect(() => {
        setTableFilterData(
            HeaderAgentRotationTable.map((column) => ({
                key: column.key || column.fieldKey || column.id,
                filterType: (column.isDate && TableFilterTypesEnum.datePicker.key) || (column.withGroupButton && TableFilterTypesEnum.selectOption.key) || TableFilterTypesEnum.textInput.key,
                isHiddenFilter: column.isHiddenFilter,
                textInputType: column.textInputType,
                textInputMax: column.textInputMax,
                textInputMin: column.textInputMin,
                displayPath: column.input
            }))
        );
    }, []);
    const onFilterValuesChanged = (newValue) => setAgentRotationTableFilter(newValue);
    return (
      <>
        <Spinner isActive={isLoading} isAbsolute />
        <Tables
          data={(response && response.result) || []}
          headerData={HeaderAgentRotationTable || []}
          filterValues={agentRotationTableFilter}
          onFilterValuesChanged={onFilterValuesChanged}
          filterData={tableFilterData || []}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          totalItems={(response && response.totalCount) || 0}
                //  actionsOptions={{ onActionClicked: tableActionClicked,
                //  }}
          defaultActions={[
                    //  {
                    // enum: TableActions.viewDetails.key,
                    //  }
                ]}
          isWithFilter
          FilterDisabledButton
          setSortBy={setSortBy}
        />
      </>
    );
};
