import React, { useState, useCallback, useReducer, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ButtonBase, Checkbox, DialogActions } from "@material-ui/core";
import { Inputs, DialogComponent, Spinner } from "../../../Components";
import {
  CreateFavoriteFolders,
  DeleteFavoriteFolder,
  GetAllFavoriteFolders,
  StoreFavoriteLeads,
  StoreFavoriteUnits,
} from "../../../Services/FavoriteFoldersServices";
import { showError, showSuccess } from "../../../Helper";

export const FavoriteDFM = ({
  isOpen,
  closeHandler,
  parentTranslationPath,
  translationPath,
  FavoriteType,
  AddTOlistView,
  checkedDetailed,
  Rest,
  checkDesabled,
  GetAllDataInSearch,
  setFilterfolderId,
  setfolderIdValue,
  reloadData
}) => {
  const { t } = useTranslation([parentTranslationPath, "Shared"]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const FavoriteFolderEnum = FavoriteType === "UNIT" ? 1 : 2;
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [activeItem, setActiveItem] = useState(null);
  const [CreateFavoriteFolder, setCreateFavoriteFolder] = useReducer(reducer, {
    name: "",
    tags: "",
    favoriteFolderType: FavoriteFolderEnum,
  });

  const [isLoading, setIsLoading] = useReducer(reducer, {
    sending: false,
    users: false,
    contacts: false,
    Create: false,
  });

  const [data, setData] = useReducer(reducer, {
    ListSavedShareUnitCriteria: [],
    contacts: [],
  });

  const GetAllFavoriteFoldersAPI = useCallback(
    async (FavoriteFolderEnum, searchValue) => {
      setIsLoading({ id: "users", value: true });
      const res = await GetAllFavoriteFolders({
        favoriteFolderType: FavoriteFolderEnum,
        search: searchValue || "",
      });
      if (!(res && res.status && res.status !== 200)) {
        const localValue = (res && res) || [];
        if (localValue) {
          setData({
            id: "ListSavedShareUnitCriteria",
            value: localValue,
          });
        } else {
          setData({
            id: "ListSavedShareUnitCriteria",
            value: [],
          });
        }

        setIsLoading({ id: "users", value: false });
      }
    },
    []
  );
  const CreateFavoriteFolderService = useCallback(
    async (CreateFavoriteFolder) => {
      setIsLoading({ id: "sending", value: true });
      const res = await CreateFavoriteFolders(CreateFavoriteFolder);
      if (!(res && res.status && res.status !== 200)) {
        showSuccess(t("Create-successfully"));
        GetAllFavoriteFoldersAPI(FavoriteFolderEnum);
        setCreateFavoriteFolder({ id: "name", value: "" });
        setCreateFavoriteFolder({ id: "tags", value: "" });
      } else {
        showError(t("Create-failed"));
      }

      setIsLoading({ id: "sending", value: false });
    },
    []
  );
  const deleteHandler = async () => {
    const Id = activeItem?.favoriteFolderId;
    const res = await DeleteFavoriteFolder(Id);
    if (!(res && res.status && res.status !== 200)) {
      GetAllFavoriteFoldersAPI(FavoriteFolderEnum);
      showSuccess(t(`${translationPath}config-deleted-successfully`));
    } else showError(t(`${translationPath}config-delete-failed`));
    setIsDeleteDialogOpen(false);
  };

  const StoreFavoriteAPI = useCallback(
    async (shareIds, folderId) => {
      setIsLoading({ id: "Create", value: true });
      const body = (FavoriteType === "UNIT"
        ? {
            folderId: folderId,
            favoriteUnitIds: shareIds.map((item) => item.id),
          }
        : {
            folderId: folderId,
            favoriteLeadIds: shareIds.map((item) => item.id),
          });

      const res = (FavoriteType === "UNIT"
        ? await StoreFavoriteUnits(body)
        : await StoreFavoriteLeads(body));

      if (!(res && res.status && res.status !== 200)) {
        showSuccess(t("Create-sent-successfully"));
        Rest();
        closeHandler();
        reloadData();
      } else {
        showError(t("Create-failed"));
      }

      setIsLoading({ id: "Create", value: false });
    },
    [checkedDetailed, closeHandler, t]
  );

  useEffect(() => {
    GetAllFavoriteFoldersAPI(FavoriteFolderEnum);
  }, []);
  return (
    <DialogComponent
      titleText="List-of-Favourite"
      saveText="send"
      maxWidth="sm"
      dialogContent={
        <div className="share-template-dialog">
          <Spinner isActive={isLoading.sending} isAbsolute />
          <div>
            {" "}
            <div className="d-flex w-100 mr-1-reversed">
              <Inputs
                idRef="leadIdFilterRef"
                fieldClasses="inputs theme-solid"
                parentTranslationPath={parentTranslationPath}
                value={CreateFavoriteFolder.name}
                onInputChanged={(event) => {
                  const { value } = event.target;
                  setCreateFavoriteFolder({ id: "name", value });
                }}
                inputPlaceholder={t(`${translationPath}name`)}
              />
              <div className="m-2"></div>
              <Inputs
                idRef="leadIdFilterRef"
                fieldClasses="inputs theme-solid"
                parentTranslationPath={parentTranslationPath}
                value={CreateFavoriteFolder.tags}
                onInputChanged={(event) => {
                  const { value } = event.target;
                  setCreateFavoriteFolder({ id: "tags", value });
                }}
                inputPlaceholder={t(`${translationPath}tags`)}
              />
              <div className="m-2">
                <ButtonBase
                  className="MuiButtonBase-root btns theme-solid "
                  disabled={
                    CreateFavoriteFolder.tags === "" ||
                    CreateFavoriteFolder.name === ""
                  }
                  onClick={() =>
                    CreateFavoriteFolderService(CreateFavoriteFolder)
                  }
                >
                  {t(`${translationPath}save`)}
                  <span className="mdi mdi-content-save-all ml-2"></span>
                </ButtonBase>
              </div>
            </div>
            <div class="data-container Favourite">
              {data &&
                data.ListSavedShareUnitCriteria.map((item) => (
                  <div class="data-item vara d-inline-flex flex-h-between w-100">
                    <span className="heart">
                      <span className="heart mdi mdi-heart"></span>
                    </span>
                    <div class="w-50">
                      {" "}
                      <strong>
                        {" "}
                        {t(`${translationPath}name`)} &nbsp;:&nbsp;
                      </strong>
                      &nbsp;{item?.name || "N/A"}
                    </div>
                    <div class="w-50">
                      {" "}
                      <strong>
                        {t(`${translationPath}tags`)}&nbsp;:&nbsp;
                      </strong>
                      &nbsp;{item?.tags || "N/A"}{" "}
                    </div>
                    {AddTOlistView && (
                      <div className="w-10 cuostam-check">
                        <Checkbox
                          className="checkbox-wrapper"
                          checkedIcon={<span className="mdi mdi-check" />}
                          indeterminateIcon={<span className="mdi mdi-minus" />}
                          checked={activeItem === item.favoriteFolderId}
                          onClick={(event) => {
                            setActiveItem(item.favoriteFolderId);
                          }}
                        />
                      </div>
                    )}
                    {!AddTOlistView && (
                      <div
                        class="table-actions-wrapper "
                        id="table-actions-wrapper "
                      >
                        {
                          <button
                            class="MuiButtonBase-root MuiButton-root MuiButton-text table-action-btn btns theme-transparent miw-0"
                            type="button"
                            onClick={() => {
                              setfolderIdValue(item && item.favoriteFolderId);
                              GetAllDataInSearch(item && item.favoriteFolderId);
                              setFilterfolderId(
                                (item && item) || {}
                              );
                              closeHandler();
                            }}
                          >
                            <span class="MuiButton-label">
                              <span class="table-action-icon mdi mdi-eye-outline c-white"></span>
                            </span>
                            <span class="MuiTouchRipple-root"></span>
                          </button>
                        }
                        <button
                          class="MuiButtonBase-root MuiButton-root MuiButton-text table-action-btn btns-icon theme-solid bg-danger"
                          type="button"
                          onClick={() => {
                            setIsDeleteDialogOpen(true);
                            setActiveItem(item);
                          }}
                          id="Actiondelete_null_/home/Reports-Builder/view"
                        >
                          <span class="MuiButton-label">
                            <span class="table-action-icon mdi mdi-trash-can"></span>
                          </span>
                          <span class="MuiTouchRipple-root"></span>
                        </button>
                      </div>
                    )}
                  </div>
                ))}
            </div>
            <DialogComponent
              titleText="confirm-message"
              saveText="confirm"
              saveType="button"
              maxWidth="sm"
              dialogContent={
                <div className="d-flex-column-center">
                  <span className="mdi mdi-close-octagon c-danger mdi-48px" />
                  <span>{`${t(
                    `${translationPath}config-delete-description`
                  )} "${activeItem?.name}"?`}</span>
                </div>
              }
              saveClasses="btns theme-solid bg-danger w-100 mx-2 mb-2"
              isOpen={isDeleteDialogOpen}
              onCancelClicked={() => setIsDeleteDialogOpen(false)}
              onCloseClicked={() => setIsDeleteDialogOpen(false)}
              onSaveClicked={deleteHandler}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          </div>
          <DialogActions>
            <div className="unit-template-action-btns">
              <ButtonBase
                className="MuiButtonBase-root btns bg-cancel"
                onClick={() => closeHandler()}
              >
                {t(`${translationPath}cancel`)}
              </ButtonBase>
              {AddTOlistView && (
                <ButtonBase
                  className="MuiButtonBase-root btns theme-solid"
                  disabled={!checkDesabled}
                  onClick={() => StoreFavoriteAPI(checkedDetailed, activeItem)}
                >
                  {t(`${translationPath}Add-to-List`)}
                  <span className="heart mdi mdi-heart ml-2"></span>
                </ButtonBase>
              )}
            </div>
          </DialogActions>
        </div>
      }
      isOpen={isOpen}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};
