



import React , { useState , useCallback , useEffect , useRef    } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { AutocompleteComponent  , Spinner  } from '../../../../../../../Components' ; 
import {  lookupItemsGet  } from  '../../../../../../../Services' ; 
import { showError } from '../../../../../../../Helper' ; 


export const LeadsMediaDetails  = ({
  parentTranslationPath,
  translationPath,
  state,
  setState
 
}) => {
    const { t } = useTranslation(parentTranslationPath);

    const [allMediaDetails , setAllMediaDetails] = useState([]) ;
    const [isLoading , setIsLoading ] = useState(false) ; 
 

    const searchTimer = useRef(null);

    const getAllLookups = useCallback(async () => {
      setIsLoading(true) ; 
        const res = await lookupItemsGet({ pageIndex : 1, pageSize : 9999999, lookupTypeId : 1240 });
        if (!(res && res.status && res.status !== 200)) 
          setAllMediaDetails(res.result) ; 
  
        else 
          setAllMediaDetails([]) ; 

      setIsLoading(false) ; 

      }, []);
    
      useEffect(() => {
            getAllLookups();
      }, []);

  return (
   <div className='content'>
    <div className='w-100 px-2 mt-2 centerItem'>
            <Spinner isActive={isLoading} isAbsolute />
            <div className='w-50 mb-2'>
            <AutocompleteComponent
              idRef='LeadsMediaDetailsRef'
              labelValue={t(`${translationPath}leadsMediaDetails`)}
              data={ allMediaDetails  ||  []} 
              selectedValues={state.leads.mediaDetails}
              multiple
             displayLabel={(option) =>
              (option && option.lookupItemName) || ''}
             chipsLabel={(option) => (option && option.lookupItemName) || ''}
            
          isLoading={isLoading}
          withLoader
          withoutSearchButton
          getOptionSelected={(option) =>
            state.leads.mediaDetails.findIndex((item) => item.lookupItemId === option.lookupItemId) !== -1}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          value={state.leads.mediaDetails}
          onChange={(e, newValues) => {
                let list = [] ; 
                const sumAll =  (newValues.length) ;
                if(sumAll > 5 )
                {
                  showError(t(`${translationPath}please-Select-five-Item-Only`));
                  return ; 
                }
                newValues.map((item)=> {
                  list.push({ lookupItemId:  item.lookupItemId ,   lookupItemName :  item.lookupItemName})
                });
                setState({ id: 'leads', value: { ...state.leads, mediaDetails: list } })
          }}
    
        />       
       </div>    
      </div>
     </div>
  );
};

LeadsMediaDetails.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state:PropTypes.instanceOf(Object).isRequired,
  setState : PropTypes.func.isRequired,
};

