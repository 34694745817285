
import React  from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';

  export const ParkingDistributionPerUnit = ({
    parentTranslationPath ,
    translationPath ,
    onChangeSelectedItem , 
    selectedValues ,
    setSelectValues ,
   } ) => { 

    const { t } = useTranslation(parentTranslationPath);

    return (
      <>
        <div className="">
        <span className="fw-bold">  {t(`${translationPath}parking-distribution-per-unit`)}</span>
        </div> 
        <div className='w-75 parking-distribution'>
        <div className="d-flex d-flex-v-center parking-distribution-card mt-2 mb-2">
            <div> 
            </div>
            <div>
            <Rating  
                size="large"
                name={`RatingParkingDistributionPerUnit`}
                value={(selectedValues &&  selectedValues.standardParkingDistributionperUnits && selectedValues.standardParkingDistributionperUnits.score) || 0}
                max={10}
                precision={1}
                onChange={(event, newValue) => 
                {
                  onChangeSelectedItem((selectedValues.standardParkingDistributionperUnits?.lookupItemName) || 'Standard Parking Distribution per Units' , newValue || 0) ;
                  const newUpdateObj = selectedValues.standardParkingDistributionperUnits ? {...selectedValues.standardParkingDistributionperUnits , score : (newValue || 0 ) } : null
                  setSelectValues((item)=> ({...item , standardParkingDistributionperUnits : newUpdateObj })) 
                }}
                />     
            </div>
         </div>  
        </div>
      </>
    );
  } ; 
  
