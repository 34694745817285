export const DevelopersPermissions = 
{
    ViewDevelopers : {
        permissionsId: "0a55114c-fcb7-4e64-0061-08dd03ecea2b",
         permissionsName: "View Developers",
         description: null,
         componentsId: null,
        components: null
    },
    ViewDeveloperProfile :  {
        permissionsId: "9356c626-a280-416f-0069-08dd03ecea2b",
         permissionsName: "View Developer Profile",
         description: null,
         componentsId: null,
        components: null
    },
    ViewAgreementDetails :{
        permissionsId: "87db3c11-73e6-412d-006a-08dd03ecea2b",
         permissionsName: "View Agreement Details",
         description: null,
         componentsId: null,
        components: null
    },
    ViewProjectOverview :  {
        permissionsId: "061cf876-a948-441a-006b-08dd03ecea2b",
         permissionsName: "View Project Overview",
         description: null,
         componentsId: null,
        components: null
    },
    ViewSocialMediaLinks : {
        permissionsId: "74baf760-583c-4f4f-006c-08dd03ecea2b",
         permissionsName: "View Social Media Links",
         description: null,
         componentsId: null,
        components: null
    },
    ViewAuditAndComplianceDetails : {
        permissionsId: "de126918-3dcc-4f9a-006d-08dd03ecea2b",
         permissionsName: "View Audit and Compliance Details",
         description: null,
         componentsId: null,
        components: null
    },
    GeneralInformation : {
        permissionsId: "f1bbde42-e082-469d-006e-08dd03ecea2b",
         permissionsName: "General information",
         description: null,
         componentsId: null,
        components: null
    },
    LocationDetails : {
        permissionsId: "f3f8a5a0-2127-4af8-006f-08dd03ecea2b",
         permissionsName: "Location details",
         description: null,
         componentsId: null,
        components: null
    },
    NewBranchesLocation : {
        permissionsId: "ef913c19-8f09-458b-0070-08dd03ecea2b",
         permissionsName: "New Branches Location",
         description: null,
         componentsId: null,
        components: null
    },
    DevelopersEmployees : {
        permissionsId: "1244d1ea-1a0f-4268-0071-08dd03ecea2b",
         permissionsName: "Developers employees",
         description: null,
         componentsId: null,
        components: null
    },
    AgentOverview  :{
        permissionsId: "a84d3011-4916-4520-0072-08dd03ecea2b",
         permissionsName: "Agent Overview",
         description: null,
         componentsId: null,
        components: null
    },
    Documents :  {
        permissionsId: "b83f3bf6-4174-487d-0073-08dd03ecea2b",
         permissionsName: "Documents",
         description: null,
         componentsId: null,
        components: null
    },
    TransactionHistory : {
        permissionsId: "81f602da-0aad-443f-0074-08dd03ecea2b",
         permissionsName: "Transaction history",
         description: null,
         componentsId: null,
        components: null
    },
    CreateNewDeveloper :{
        permissionsId: "38d2ba14-2103-42eb-0075-08dd03ecea2b",
         permissionsName: "Create New Developer",
         description: null,
         componentsId: null,
        components: null
    },
    EditDeveloperGeneralInformation :{
        permissionsId: "870e0ab3-738b-4051-0076-08dd03ecea2b",
         permissionsName: "Edit Developer General information",
         description: null,
         componentsId: null,
        components: null
    },
    EditKeyContact :{
        permissionsId: "22993c70-b1a5-4d32-0077-08dd03ecea2b",
         permissionsName: "Edit Key Contact",
         description: null,
         componentsId: null,
        components: null
    },
    EditAgreementDetails :{
        permissionsId: "b66cc525-8026-413a-0078-08dd03ecea2b",
         permissionsName: "Edit Agreement Details",
         description: null,
         componentsId: null,
        components: null
    },
    EditPrimaryLocation :{
        permissionsId: "992d4d80-3572-4f98-0079-08dd03ecea2b",
         permissionsName: "Edit Primary location",
         description: null,
         componentsId: null,
        components: null
    },
    EditAuditComplianceDetails : {
        permissionsId: "9713c2fe-e075-419c-007a-08dd03ecea2b",
         permissionsName: "Edit Audit & Compliance Details",
         description: null,
         componentsId: null,
        components: null
    },
   EditBranchesLocation : {
        permissionsId: "f9aa5a9a-b40c-49df-007b-08dd03ecea2b",
         permissionsName: "Edit Branches Location",
         description: null,
         componentsId: null,
        components: null
    },
    EditSocialMediaLinks :{
        permissionsId: "dac6968d-3fb7-4024-007c-08dd03ecea2b",
         permissionsName: "Edit Social Media Links",
         description: null,
         componentsId: null,
        components: null
    },
    EditDeveloperEmployees :{
        permissionsId: "96db1111-ab54-41fd-007d-08dd03ecea2b",
         permissionsName: "Edit Developer Employees",
         description: null,
         componentsId: null,
        components: null
    },
    UploadDocuments :{
        permissionsId: "0acea116-a743-48f2-007e-08dd03ecea2b",
         permissionsName: "Upload Documents",
         description: null,
         componentsId: null,
        components: null
    },
    DownloadDocuments : {
        permissionsId: "6d359284-20b7-44e5-007f-08dd03ecea2b",
         permissionsName: "Download Documents",
         description: null,
         componentsId: null,
        components: null
    },
    DeletDocuments : {
        permissionsId: "306285e4-2fe8-4159-0080-08dd03ecea2b",
         permissionsName: "Delete Documents",
         description: null,
         componentsId: null,
        components: null
    },
    ShareDocuments : {
        permissionsId: "b156bc41-519a-4b1c-0081-08dd03ecea2b",
         permissionsName: "Share Documents",
         description: null,
         componentsId: null,
        components: null
    },
    EditDocuments :{
        permissionsId: "affde233-7e41-458f-0082-08dd03ecea2b",
         permissionsName: "Edit Documents",
         description: null,
         componentsId: null,
        components: null
    }
  
};
