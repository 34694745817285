

import { HttpServices } from '../../Helper';
import { config } from '../../config';


export const GetAllPortalAPI = async ({ pageIndex, pageSize }) => {

    const queryList = [];
    if (pageIndex) queryList.push(`pageIndex=${pageIndex}`);
    if (pageSize) queryList.push(`pageSize=${pageSize}`);

    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PortalAccount/GetAllPortal?${queryList.join('&')}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};


export const CreatePortalAPI = async (body) => {
    const result = await HttpServices.post(
        `${config.server_address}/CrmDfm/PortalAccount/CreatePortal`, body
    )
        .then((data) => data)
        .catch((error) => error.response.data);
    return result;
};



export const GetPortalByIdAPI = async (portalId) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PortalAccount/GetPortalById/${portalId}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

export const UpdatePortalAPI = async (portalId, body) => {

    const result = await HttpServices.put(
        `${config.server_address}/CrmDfm/PortalAccount/UpdatePortal/${portalId}`, body
    )
        .then((data) => data)
        .catch((error) => error.response.data);
    return result;
};


export const GetAllPortalAccountsAPI = async ({ pageIndex, pageSize }) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PortalAccount/GetAllPortalAccounts/${pageIndex}/${pageSize}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};


export const CreatePortalAccountAPI = async (body) => {
    const result = await HttpServices.post(
        `${config.server_address}/CrmDfm/PortalAccount/CreatePortalAccount`, body
    )
        .then((data) => data)
        .catch((error) => error.response.data);
    return result;
};


export const GetPortalAccountAPI = async (portalAccountId) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PortalAccount/GetPortalAccountByPortalAccountId/${portalAccountId}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

export const UpdatePortalAccountAPI = async (portalAccountId, body) => {

    const result = await HttpServices.put(
        `${config.server_address}/CrmDfm/PortalAccount/UpdatePortalAccount`, body
    )
        .then((data) => data)
        .catch((error) => error.response.data);
    return result;
};



export const GetPortalAccountDetailsAPI = async (portalAccountId) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PortalAccount/GetPortalAccountDetailsByPortalAccountId/${portalAccountId}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};


export const GetPortalAccountByPortalIdAPI = async (portalId) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PortalAccount/GetPortalAccountByPortalId/${portalId}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};


export const GetPortalsbyUserIdAPI = async (usersId) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PortalAccount/GetportalsbyUserId/${usersId}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};


export const GetBranchByUserIdAPI = async (usersId) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PortalAccount/GetBranchByUserId/${usersId}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};



export const GetAllPublishedPortalsAPI = async (body) => {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/PortalAccount/GetAllPublishedPortals` ,body)  
      .then((data) => data)
      .catch((error) => error.response);
   return result;
};

export const CreatePublishPortalUnitAPI = async (body) => {
    const result = await HttpServices.post(
        `${config.server_address}/CrmDfm/PortalAccount/CreatePublishPortalUnit`, body
    )
        .then((data) => data)
        .catch((error) => error.response.data);
    return result;
};

export const GetAllPublishedPortalsByIdAPI = async (publishedPortalId) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PortalAccount/GetAllPublishedPortalsById/${publishedPortalId}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};


export const UpdatePublishPortalUnitAPI = async (portalAccountId, body) => {

    const result = await HttpServices.put(
        `${config.server_address}/CrmDfm/PortalAccount/UpdatePublishPortalUnit`, body
    )
        .then((data) => data)
        .catch((error) => error.response.data);
    return result;
};

export const GetPortalAccountByBranchId = async (branchId, operationType) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PortalAccount/GetPortalAccountByBranchId/${branchId}/${operationType}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};


export const ValidatePortalUnitForPublishing = async (body) => {
    const result = await HttpServices.post(
        `${config.server_address}/CrmDfm/PortalAccount/ValidatePortalUnitForPublishing`, body
    )
        .then((data) => data)
        .catch((error) => error.response.data);
    return result;
};


export const DeletePublishedPortalByPortalAccountId = async (portalAccountId, branchId) => {
    const result = await HttpServices.delete(
        `${config.server_address}/CrmDfm/PortalAccount/DeletePublishedPortalByPortalAccountId/${portalAccountId}/${branchId}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};
export const DeletePublishedPortalByPublishedPortalId = async (publishedPortalId ) => {
    const result = await HttpServices.delete(
        `${config.server_address}/CrmDfm/PortalAccount/DeletePublishedPortalByPublishedPortalId/${publishedPortalId}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

export const GetFinderCitiesAPI = async (search) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PortalAccount/GetFinderCities?search=${search}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

export const GetFinderCommunitiesAPI = async (search) => {
    const queryList = [];
    if (search) queryList.push(`search=${search}`);

    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PortalAccount/GetFinderCommunities?search=${search}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

export const GetFinderSubCommunities = async (search) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PortalAccount/GetFinderSubCommunities?search=${search}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};


export const GetFinderPropertyNames = async (search) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PortalAccount/GetFinderPropertyNames?search=${search}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};






