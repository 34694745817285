
import { GetAllActivityTypesAPI } from '../../../Services';

export const ActivityTypeRule = async (item,value , setItems , setIsLoading ) => {
  if (!item.data.searchKey) return;
  if (item.data.searchKey !== 'activtyType') return;
 
  const filter = {
    pageSize: 25,
    pageIndex: 0,
    search: value,
 
  };
    setIsLoading(true);
    const rs = await GetAllActivityTypesAPI({ ...filter });
    item.data.enum = [];
    if (!rs || !rs.result) return;
    rs.result.map((element) => {
      item.data.enum.push({
        ...element , 
        name :(element && element.activityTypeName)
      });
    });
    setItems(item.data.enum);
    setIsLoading(false);
};
