import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalHistory, showError } from "../../../../Helper";
import {
  GetAllLeadsPoolDetails,
  GetAllLeadsPoolSummary,
} from "../../../../Services";
import { LeadsPoolContext } from "../LeadsPoolContext";
import { DialogComponent, Spinner, Tables } from "../../../../Components";

import "./LeadsPoolSummaryCardsComponent.scss";
import { Button, DialogActions, withStyles } from "@material-ui/core";
import { CopyToClipboardComponents } from "../../../../ReusableComponents/UtilityComponents";

const ConfirmButton = withStyles(() => ({
  root: {
    color: "white",
    backgroundColor: "#84693f",
    border: "2px solid #84693f",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#6d5734",
    },
  },
}))(Button);
function LeadsPoolSummaryCardsComponent({
  translationPath,
  parentTranslationPath,
}) {
  const {
    totalLeadsInPool,
    fetchLeadPoolSummary,
    filter,
    remainingLeadCapacityForCurrentUser,
    GetRemainingLeadAssignmentCapacityForLead,
  } = useContext(LeadsPoolContext);

  const { t } = useTranslation(parentTranslationPath);
  const [data, setData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [leadsPoolDetailsSearchArray, setLeadsPoolDetailsSearchArray] =
    useState([]);
  const userId = JSON.parse(localStorage.getItem("session"))?.userId;

  async function GetLeadPoolSummary() {
    const res = await GetAllLeadsPoolSummary(userId);
    if (!(res && res.status && res.status !== 200)) {
      setData(res);
    } else {
      showError(t("error-fetching-data"));
    }
  }

  async function GetAllLeadsDetails() {
    setIsLoading(true);
    const res = await GetAllLeadsPoolDetails(leadsPoolDetailsSearchArray);
    if (!(res && res.status && res.status !== 200)) {
      setTableData(res);
    } else {
      showError(t("error-fetching-data"));
    }
    setIsLoading(false);
  }

  const onRedirectClickd = (item) => {
    GlobalHistory.push(
      `/home/leads/lead-profile-edit?formType=${item.formTypeId}&id=${item.leadId}&leadClass=${item.leadCategory}`
    );
  };

  useEffect(() => {
    GetLeadPoolSummary();
    GetRemainingLeadAssignmentCapacityForLead(userId);
  }, [fetchLeadPoolSummary]);

  useEffect(() => {
    if (isOpen === true) {
      GetAllLeadsDetails();
    }
  }, [isOpen]);

  return (
    <React.Fragment>
      <div className="cards-container">
        <div
          className="card-wrapper-custom"
          onClick={() => {
            setLeadsPoolDetailsSearchArray(data?.dailyCapacityIds || []);
            setIsOpen(true);
          }}
        >
          <div className="text-container">
            <span className="upper-text">{t("daily-capacity")}</span>
            <span className="middle-text">{`${
              remainingLeadCapacityForCurrentUser || 0
            }/${data?.totalCapacityCount || 0}`}</span>
            <span className="bottom-text">
              {t("remaining-leads-for-today")}
            </span>
          </div>
        </div>
        <div
          className="card-wrapper-custom"
          onClick={() => {
            setLeadsPoolDetailsSearchArray(data?.updatedLeadsIds || []);
            setIsOpen(true);
          }}
        >
          <div className="text-container">
            <span className="upper-text">{t("updated-leads")}</span>
            <span className="middle-text">{`${
              data?.updatedLeadsCount || 0
            }`}</span>
            <span className="bottom-text">
              {t("leads-with-recent-activities")}
            </span>
          </div>
        </div>
        <div
          className="card-wrapper-custom"
          onClick={() => {
            setLeadsPoolDetailsSearchArray(
              data?.leadsRequiringUpdatesIds || []
            );
            setIsOpen(true);
          }}
        >
          <div className="text-container">
            <span className="upper-text">{t("leads-needing-updates")}</span>
            <span className="middle-text">{`${
              data?.leadsRequiringUpdatesCount || 0
            }`}</span>
            <span className="bottom-text">{t("leads-without-updates")}</span>
          </div>
        </div>
        <div className="card-wrapper-custom">
          <div className="text-container">
            <span className="upper-text">{t("total-leads-in-pool")}</span>
            <span className="middle-text">{`${totalLeadsInPool || 0}`}</span>
            <span className="bottom-text">{t("all-available-leads")}</span>
          </div>
        </div>
      </div>
      {isOpen && (
        <DialogComponent
          titleText={"leads-information"}
          isOpen={isOpen}
          dialogContent={
            <React.Fragment>
              <Spinner isAbsolute isActive={isLoading} />
              <Tables
                data={tableData}
                headerData={[
                  {
                    id: 1,
                    label: "lead-id",
                    component: (item) => (
                      <CopyToClipboardComponents
                        data={item.leadId}
                        childrenData={item.leadId}
                        CustomizationClassName="id"
                      />
                    ),
                  },
                  {
                    id: 2,
                    label: "lead-name",
                    input: "leadName",
                    component: (item) => (
                      <span
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() => {
                          onRedirectClickd(item);
                        }}
                      >
                        {item?.leadName || ""}
                      </span>
                    ),
                  },
                  {
                    id: 3,
                    label: "lead-type",
                    input: "leadType",
                  },
                  {
                    id: 4,
                    label: "lead-type",
                    input: "leadCategory",
                  },
                  {
                    id: 5,
                    label: "nationality",
                    input: "nationality",
                  },
                  {
                    id: 6,
                    label: "language",
                    input: "language",
                  },
                  {
                    id: 7,
                    label: "bedrooms",
                    input: "bedrooms",
                  },
                  {
                    id: 8,
                    label: "size",
                    input: "areaSize",
                  },
                  {
                    id: 9,
                    label: "size-from",
                    input: "areaSizeFrom",
                  },
                  {
                    id: 10,
                    label: "size-to",
                    input: "areaSizeTo",
                  },
                  {
                    id: 11,
                    label: "price-from",
                    input: "priceFrom",
                  },
                  {
                    id: 12,
                    label: "price-to",
                    input: "priceTo",
                  },
                  {
                    id: 13,
                    label: "property-name",
                    input: "propertyName",
                  },
                  {
                    id: 14,
                    label: "remarks",
                    input: "remarks",
                  },
                ]}
                itemsPerPage={totalLeadsInPool || 150}
                totalItems={totalLeadsInPool || 150}
                defaultActions={[]}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
              <DialogActions>
                <ConfirmButton
                  className="dialog-action-btn"
                  onClick={() => {
                    setLeadsPoolDetailsSearchArray([]);
                    setIsOpen(false);
                  }}
                >
                  {t("confirm")}
                </ConfirmButton>
              </DialogActions>
            </React.Fragment>
          }
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </React.Fragment>
  );
}

export default LeadsPoolSummaryCardsComponent;
