import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ButtonBase, DialogActions } from "@material-ui/core";
import "./UnitsBulkStatusUpdateDialog.scss";
import {
  BulkSetUnitsAsAvailableOrDraft,
  BulkUpdateUnitMarketingFieldsData,
  BulkUpdateUnitsAsAvailable,
  UpdateBulkUnitPublishStatus,
  UpdateUnitPricesFroBulkUnit,
} from "../../../../../../Services";
import { showError, showSuccess } from "../../../../../../Helper";
import { Spinner } from "../../../../../../Components";

export const ConfirmBulkStatusUpdateDialog = ({
  viewType,
  onCloseClicked,
  unitsIdUpdateArray,
  setUnitsIdUpdateArray,
  isLease,
  selected,
  data,
  publishedImages,
  operationTypeForDialog,
  loading,
  setLoading,
  onUnitsBulkCloseDialogHandler,
  reloadData,
  onClearedAllFiltersClick,
  isPublishedUnits,
  unitData,
  setUnitData,
  isNeedApprovalMessageInMarketing,
  setTypeOfRequest,
  setNeedsApprovalDialog,
  fieldsNeedsApproval,
  setFieldsNeedsApprovalDialog
}) => {
  const parentTranslationPath = "UnitsView";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [opertation, setOperation] = useState(operationTypeForDialog);

  const needsApproval = fieldsNeedsApproval?.some(field => 
    field.toLowerCase() === 'status'
  );
  

  const BulkUpdateUnitsAsAvailablePUTAPI = useCallback(
    async (updateOption) => {
      setIsButtonDisabled(true);
      setLoading({ id: "isLoading", value: true });
  
      const body = {
        UnitIds: unitsIdUpdateArray,
        TitleEn: data.marketingTitle,
        DescriptionEn: data.marketingDescription,
        Images: [
          {
            CategoryId: selected.category.lookupItemId,
            Files: publishedImages,
          },
        ],
        rentListingAgentId: isLease ? data.agent : undefined,
        ListingAgentId: !isLease ? data.agent : undefined,
        IsLease: isLease,
        UpdateOption: updateOption,
      };
  
      if (body.Images[0].Files.length >= 3) {

        await BulkUpdateUnitMarketingFieldsData(body);
  
        if (needsApproval) {
          setFieldsNeedsApprovalDialog(true);
          setIsButtonDisabled(false);
          setLoading({ id: "isLoading", value: false });
          return;
        }
  
        const result = await BulkSetUnitsAsAvailableOrDraft(body);
        if (!(result.status && result?.status !== 200)) {
          onClearedAllFiltersClick();
          setUnitsIdUpdateArray([]);
          onUnitsBulkCloseDialogHandler();
          setUnitData([]);
          reloadData();
          showSuccess(t(`${translationPath}updated-successfully`));
        } else {
          showError(t(`${translationPath}failed-to-update`));
        }
      } else {
        showError(t(`${translationPath}please-fill-the-required-data`));
      }
  
      setIsButtonDisabled(false);
      setLoading({ id: "isLoading", value: false });
    },
    [publishedImages, selected, isLease, needsApproval]
  );
  

  const BulkUpdateUnitPublishedStatusPUTAPI = useCallback(
    async (updateOption) => {
      setLoading({ id: "isLoading", value: true });
      setIsButtonDisabled(true);

      const body = isLease
        ? {
            unitData,
            isPublishedForLease: isLease,
            isForLease: isLease,
            isPublished: isPublishedUnits,
            UpdateOption: updateOption,
          }
        : {
            unitData,
            isPublishedForLease: isLease,
            isForLease: isLease,
            isPublished: isPublishedUnits,
            UpdateOption: updateOption,
          };

      try {
        await UpdateUnitPricesFroBulkUnit(body);
        const needsApproval = await isNeedApprovalMessageInMarketing(true);
        if (needsApproval === true) {
          setTypeOfRequest(1);
          setNeedsApprovalDialog(true);
          setIsButtonDisabled(false);
          setLoading({ id: "isLoading", value: false });
          return;
        }
        const result = await UpdateBulkUnitPublishStatus(body);
        if (result?.status !== 200) {
          onClearedAllFiltersClick();
          setUnitsIdUpdateArray([]);
          setUnitData([]);
          reloadData();
          showSuccess(t(`${translationPath}updated-successfully`));
        } else {
          showError(t(`${translationPath}failed-to-update`));
        }
      } catch (error) {
        
        showError(t(`${translationPath}failed-to-update`));
      }
      setIsButtonDisabled(false);
      setLoading({ id: "isLoading", value: false });
      onUnitsBulkCloseDialogHandler();
    },
    [unitsIdUpdateArray, selected, isLease, isPublishedUnits]
  );

  const onConfirmClicked = (opertation) => {
    if (opertation.viewType === "mark-as-available") {
      BulkUpdateUnitsAsAvailablePUTAPI(opertation.OperationType);
    } else if (opertation.viewType === "publish-to-website") {
      BulkUpdateUnitPublishedStatusPUTAPI(opertation.OperationType);
    }
  };

  return (
    <>
      <Spinner isActive={loading.isLoading} />
      {viewType === "mark-as-available" && (
        <>
          <h3>{t("confirm-message")}</h3>
        </>
      )}
      {viewType === "publish-to-website" && (
        <>
          <h3>{t("publish-message")}</h3>
        </>
      )}

      <DialogActions>
        <div className="buttons-container">
          <ButtonBase
            className="MuiButtonBase-root btns theme-solid bg-warning"
            onClick={onCloseClicked}
            disabled={isButtonDisabled}
          >
            <>{t(`${translationPath}cancel`)}</>
          </ButtonBase>

          <ButtonBase
            className="MuiButtonBase-root btns  theme-solid bg-save"
            onClick={() => onConfirmClicked(opertation)}
            disabled={isButtonDisabled}
          >
            <>{t(`${translationPath}confirm`)}</>
          </ButtonBase>
        </div>
      </DialogActions>
    </>
  );
};
