import React from 'react'
import { ActivityFilesUploader } from '../../../../../../../../Components/UploaderActivitieFileComponent/ActivityFilesUploader';
import { LinkUploader } from '../../../../../../../../SharedComponents/LinkUploader/LinkUploader';

export function ArtworkSuggestions({
  selected,
  setSelected,
  isReadOnly
}) {

  return (
    <div className='camp-req-art-suggest-f1'>
      <div className='w-100 p-4'>
        <ActivityFilesUploader
          state={selected}
          setState={setSelected}
          isDisabled={isReadOnly}
          chipsDisabled={() => isReadOnly}
        />
      </div>
      <div className='uploader-type w-50 '>
        <LinkUploader
          initUploaded={selected.links||[]}
          uploadedChanged={(links) => {
            setSelected({ id: 'links', value: links });
          }}
          isDisabled={isReadOnly}
        />
      </div>
    </div>
  )
}
