/* eslint-disable max-len */
/* eslint-disable space-infix-ops */
import { Button, Tooltip } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import { Inputs } from '../../../../Components';
import dragiconvector from '../../../../assets/images/defaults/dragiconvector.png';

export const TableReportsView = ({
  parentTranslationPath,
  translationPath,
  setdrageisnide,
  dragItem,
  drageisnide,
  Data,
  ReastColumus,
  setReastColumus,
  setTableReportsCloums,
  DATAGetSystemReportsResult,
  AcationStateIsEdit,
  SetOrder
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [formFields, setFormFields] = useState();
  const ActiveItemReducer = useSelector((state) => state.ActiveItemReducer);
  const [fromindex, setfromindex] = useState('');
  const [transactedBy, setTransactedBy] = useState([]);
  const [orderTypeBy, setorderTypeBy] = useState({});
  const [itemforedit, setitemforedit] = useState(false);

  const slider = document.getElementById('TheScrollFun');
  let mouseDown = false;
  let startX;
  let scrollLeft;
  if (slider) {
    const startDragging = (e) => {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };
    const stopDragging = () => {
      mouseDown = false;
    };

    slider.addEventListener('mousemove', (e) => {
      e.preventDefault();
      if (!mouseDown) return;
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    });

    slider.addEventListener('mousedown', startDragging, false);
    slider.addEventListener('mouseup', stopDragging, false);
    slider.addEventListener('mouseleave', stopDragging, false);
  }

  const ByChanged2 = (index) => {
    // eslint-disable-next-line consistent-return
    setTransactedBy((items) => {
      items.splice(fromindex, 1);
      items.splice(index, 0, formFields);
      if (fromindex !== -1)
        return [...items];
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getsystemReportFormsValue = useCallback(
    (keys) =>
      // eslint-disable-next-line max-len
      (Data && Data.systemReportForms && Data.systemReportForms.find((element) => element.formId === keys) &&
        // eslint-disable-next-line max-len
        Data && Data.systemReportForms && Data.systemReportForms.find((element) => element.formId === keys).form) ||
      ''
  );

  const onDropHandler = () => {
    if (drageisnide === false) {
      setTransactedBy((items) => {
        items.splice(fromindex, 0, {
          name: dragItem.formFieldTitle,
          searchKey: (dragItem.searchableKey !== '' && dragItem.searchableKey !== null) ? dragItem.searchableKey : dragItem.displayPath,
          categoryType: dragItem.formsId,
          displayPath: dragItem.displayPath,
          category: dragItem.tableName
        });
        return [...items];
      });
    }
  };

  const onInputChanged = (value, index) => {
    setTransactedBy((items) => {
      items.splice(index, 1, {
        name: value,
        searchKey: transactedBy[index].searchKey,
        categoryType: transactedBy[index].categoryType,
        displayPath: transactedBy[index].displayPath,
        category: transactedBy[index].category,
      });
      return [...items];
    });
  };

  const onRemoveHandler = (index) => {
    setTransactedBy((items) => {
      items.splice(index, 1,);
      return [...items];
    });
  };

  const SortHandler = (item, index) => {
    setorderTypeBy(
      {
        index,
        order: orderTypeBy.order === 2 ? 1 : 2,
        searchKey: item.searchKey || '',
        orderByCategory: item.category || '',
      }
    );
  };

  const getdis = (item, index) => {
    if (index === itemforedit.index)
      return false;
    return true;
  };

  // eslint-disable-next-line consistent-return
  const getVlaue = (object, values) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(object)) {
      if (key === values)
        return ((value !== null) ? String(value) : '');
    }
  };

  useEffect(() => {
    if (itemforedit === false) {
      setTableReportsCloums(
        transactedBy
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactedBy, itemforedit]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { SetOrder(orderTypeBy); }, [orderTypeBy]);
  useEffect(() => {
    try {
      if (Data && Data && !AcationStateIsEdit) {
        setTransactedBy(
          [
            JSON.parse(Data && Data.defaultColumn1) || {},
            JSON.parse(Data && Data.defaultColumn2) || {},
            JSON.parse(Data && Data.defaultColumn3) || {},
          ]
        );
      }
    } catch (error) {
      setTransactedBy([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Data]);

  useEffect(() => {
    try {
      if (AcationStateIsEdit) {
        setTransactedBy(
          // eslint-disable-next-line max-len
          ActiveItemReducer && ActiveItemReducer.systemReportFields.map((data) => (JSON.parse(data.systemReportFieldJson)))
        );

        setReastColumus();
      }
    } catch (error) {
      setReastColumus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AcationStateIsEdit]);

  useEffect(() => {
    try {
      if (AcationStateIsEdit) {
        setorderTypeBy(
          {
            // index,
            order: JSON.parse(ActiveItemReducer && ActiveItemReducer.systemReportFilters).orderType,
            // eslint-disable-next-line max-len
            searchKey: JSON.parse(ActiveItemReducer && ActiveItemReducer.systemReportFilters).orderBy,
            // eslint-disable-next-line max-len
            orderByCategory: JSON.parse(ActiveItemReducer && ActiveItemReducer.systemReportFilters).orderByCategory,
          }
        );
      }
    } catch (error) {
      setorderTypeBy({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AcationStateIsEdit]);

  useEffect(() => {
    try {
      if (ReastColumus && !AcationStateIsEdit) {
        setTransactedBy(
          [
            JSON.parse(Data && Data.defaultColumn1) || {},
            JSON.parse(Data && Data.defaultColumn2) || {},
            JSON.parse(Data && Data.defaultColumn3) || {},
          ]
        );
        setReastColumus();
      } else if (AcationStateIsEdit) {
        setTransactedBy(
          ActiveItemReducer && ActiveItemReducer.systemReportFields.map((data) => (
            JSON.parse(data.systemReportFieldJson)))
        );
        setReastColumus();
      }
    } catch (error) {
      setReastColumus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ReastColumus, AcationStateIsEdit]);
  return (
    <div className='TableReportsView-view-wrapers mt-3 TheScrollFunParent'>
      <table
        className='table fixed_headers'
        onDragOver={(event) => {
          event.preventDefault();
        }}
        onDrop={(drageisnide === false && onDropHandler) || ''}
        onDragStart={() => setdrageisnide(true)}
      >
        {transactedBy.length === 0 && (
          <div className='TableReports-no-data'>
            {' '}
            <div>
              {' '}
              <div className='img-div-container'>
                {' '}
                <img
                  src={dragiconvector}
                  alt={t(`${translationPath}No-Result-Found`)}
                  className='img-drag'
                />
                {' '}

              </div>
              <div className='Text-fileds'>{t(`${translationPath}Dragtext`)}</div>
            </div>
            {' '}

          </div>
        )}
        <thead>
          <tr>
            {transactedBy && transactedBy.map((item, index) => (
              <th
                index={index + 1000}
                onDragOver={(event) => {
                  event.preventDefault();
                  if (drageisnide !== true)
                    setfromindex(index);
                }}
                draggable={itemforedit === false}
                onDrop={() => (drageisnide === true && ByChanged2(index, item)) || ''}
                onDragCapture={() => { setfromindex(index); setFormFields(item); }}
              >
                <div className={`input  ${index === itemforedit.index ? 'input-selected' : ''} is-red`}>
                  <Inputs
                    idRef={`items${index + 1}`}
                    value={item.name || ''}
                    isDisabled={getdis(item, index)}
                    parentTranslationPath={parentTranslationPath}
                    label={item.category || getsystemReportFormsValue(item.categoryType) || ''}
                    translationPath={translationPath}
                    onInputChanged={(event) => {
                      onInputChanged(event.target.value, index);
                    }}
                    endAdornment={(
                      <>
                        <Tooltip
                          size='small'
                          title={t(`${translationPath}Rename`)}
                        >
                          <Button
                            onClick={() => {
                              if (index === itemforedit.index)
                                setitemforedit(false);
                              else
                                setitemforedit({ item, index });
                            }}
                            className={`trash-bbt  ${index === itemforedit.index ? 'is-selectedcheck' : 'is-rename'} is-red`}
                          >
                            <span className='p-1 youtube-wraper'>
                              <span className={`  ${index === itemforedit.index ? 'mdi mdi-check mdi-24px' : 'mdi mdi-rename-box mdi-24px'} `} />
                            </span>
                          </Button>
                        </Tooltip>
                        <Tooltip
                          size='small'
                          title={t(`${translationPath}sort`)}
                        >
                          <Button
                            onClick={() => {
                              SortHandler(item, index);
                            }}
                            className={`trash-bbt  ${item && item.searchKey === orderTypeBy.searchKey ? 'is-selected' : ''} is-red`}
                          >
                            <span className='p-1 youtube-wraper'>
                              <span className='mdi mdi-swap-vertical-bold mdi-24px' />
                            </span>
                          </Button>
                        </Tooltip>

                        <Tooltip
                          size='small'
                          title={t(`${translationPath}Deletecolumn`)}
                        >
                          <Button
                            onClick={() => {
                              onRemoveHandler(index);
                              setorderTypeBy({});
                            }}
                            className='trash-bbt'
                          >
                            <span className='p-1 youtube-wraper'><span className='mdi mdi-trash-can mdi-24px' /></span>
                          </Button>
                        </Tooltip>
                      </>
                    )}
                  />

                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {(DATAGetSystemReportsResult && DATAGetSystemReportsResult).map((item, index) => (
            <tr index={index}>
              {transactedBy && transactedBy.map((FieldItem, indexNumber) => (
                <td index={indexNumber * Math.random()}>
                  {getVlaue(item, FieldItem.name) || itemforedit !== false && (<div className='Skeleton-TableReportsView'><Skeleton /></div>) || ''}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <div className='m-2'>
          <span className='TotalCount-title'>
            {(DATAGetSystemReportsResult && DATAGetSystemReportsResult[0] && DATAGetSystemReportsResult[0] &&
              t(`${translationPath}TotalCount-`)) ||
              ' '}
          </span>
          {(DATAGetSystemReportsResult && DATAGetSystemReportsResult[0] && DATAGetSystemReportsResult[0] &&
            (DATAGetSystemReportsResult[0] && DATAGetSystemReportsResult[0].TotalCount)) ||
            ' '}
        </div>
      </table>

    </div>
  );
};
TableReportsView.propTypes = {
  translationPath: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  setdrageisnide: PropTypes.func,
  ReastColumus: PropTypes.func,
  AcationStateIsEdit: PropTypes.bool,
  DATAGetSystemReportsResult: PropTypes.instanceOf(Array),
  Data: PropTypes.instanceOf(Array),
  SetOrder: PropTypes.func,
  setReastColumus: PropTypes.func,
  setTableReportsCloums: PropTypes.func,
  dragItem: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(Object),
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  drageisnide: PropTypes.bool,
};

TableReportsView.defaultProps = {
  translationPath: '',
  parentTranslationPath: '',
  setdrageisnide: () => { },
  ReastColumus: () => { },
  SetOrder: () => { },
  setReastColumus: () => { },
  setTableReportsCloums: () => { },
  AcationStateIsEdit: false,
  drageisnide: false,
  DATAGetSystemReportsResult: [],
  Data: [],
  dragItem: {},
};
  /// id='TheScrollFun'
