import React, { useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CheckboxesComponent } from '../../../../../../../Components';

export const MyActivities = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
}) => {
  const { t } = useTranslation(parentTranslationPath);


  return (
    <div className='content'>
      <div className='w-100 px-2 mt-2 checkBoxsPart'>
        <div className='checkboxesItem'>
          <CheckboxesComponent
            idRef='MyActivitiesMyLeadRef'
            label={t(`${translationPath}my-activities-mylead`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={state.activities.myActivities.myLead}
            onSelectedCheckboxClicked={(e) => {

              const activitiesMyActivities = {
                ...state.activities.myActivities,
                myLead: e.target.checked,
              };
              setState({
                id: 'activities',
                value: { ...state.activities, myActivities: activitiesMyActivities },
              });
            }}
          />
        </div>
        <div className='checkboxesItem'>
          <CheckboxesComponent
            idRef='MyActivitiesLeadMyLeadRef'
            label={t(`${translationPath}my-activities-leadMyLead`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={state.activities.myActivities.leadMyLead}
            onSelectedCheckboxClicked={(e) => {

              const activitiesMyActivities = {
                ...state.activities.myActivities,
                leadMyLead: e.target.checked,
              };
              setState({
                id: 'activities',
                value: { ...state.activities, myActivities: activitiesMyActivities },
              });
            }}
          />
        </div>
        <div className='checkboxesItem'>
          <CheckboxesComponent
            idRef='MyActivitiesManagerMyLeadRef'
            label={t(`${translationPath}my-activities-managerMylead`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={state.activities.myActivities.managerMyLead}
            onSelectedCheckboxClicked={(e) => {

              const activitiesMyActivities = {
                ...state.activities.myActivities,
                managerMyLead: e.target.checked,
              };
              setState({
                id: 'activities',
                value: { ...state.activities, myActivities: activitiesMyActivities },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

MyActivities.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
};
