import { StaticLookupsIds } from '../assets/json/StaticLookupsIds';

export const PropertyStatusEnum = {
  'Off-Plan': {
    value: 'off-plan',
    class: 'bg-warning',
    id: StaticLookupsIds.OffPlan,
  },
  Completed: {
    value: 'completed',
    class: 'bg-success',
    id: StaticLookupsIds.Completed,
  },
  'Pre Launch': {
    value: 'Pre-Launch',
    class: 'bg-primary',
    id: StaticLookupsIds.PreLaunch,
  },
};
