import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CheckboxesComponent, Inputs } from '../../../../../Components';

export const BulkSelectedActivitiesTypesFieldsSection = ({
  activitiesTypesCards,
  activitiesTypesStatus,
  bulkedActivitiesTypes,
  translationPath,
  onActivitiesTypesChange,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [searchedItemId, setSearchedItemId] = useState('');
  const searchTimer = useRef(null);
  const searchedItemRef = useRef(null);


  const cardCheckboxClicked = useCallback(
    (element) => {
      onActivitiesTypesChange((items) => {
        const index = items.findIndex((item) => ((item && item.activityTypeId) === (element && element.activityTypeId)));
        if (index !== -1) {
          items.splice(index, 1);
          setSearchedItemId('');
        } else items.push(element);
        return [...items];
      });
    },
    [onActivitiesTypesChange]
  );
  const executeScroll = () =>
    searchedItemRef &&
    searchedItemRef.current &&
    searchedItemRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });

  const searchHandler = (value) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setSearchedItemId(value);
      executeScroll();
    }, 500);
  };
  const getIsChecked = useCallback(
    (item) => {
      if (activitiesTypesCards.findIndex((el) => (el.activityTypeId === item.activityTypeId)) !== -1) return true;
      return false;
    },
    [activitiesTypesCards]
  );

  return (
    <div>
      <div className='bulk-header-section'>{t(`${translationPath}selected-activity-type`)}</div>
      <div className='bulk-sub-header-section'>
        {t(`${translationPath}you-can-deselect-activity-type-before-continue`)}
      </div>
      <div className='mt-2'>
        <Inputs
          idRef='activityTypeSearchRef'
          inputPlaceholder='search-activity-type'
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          onKeyUp={(e) => searchHandler(e.target.value)}
          startAdornment={<span className='mdi mdi-magnify mdi-24px c-gray' />}
        />
      </div>

      <div className='bulked-units-section mt-2'>
        {bulkedActivitiesTypes &&
          bulkedActivitiesTypes.map((item, index) => (
            <div
              onClick={(event) => {
                event.preventDefault();
                if (activitiesTypesStatus.success.findIndex((el) => el === item.activityTypeId) === -1)
                  cardCheckboxClicked(item);
              }}
              ref={
                searchedItemId.length > 0 &&
                item.activityTypeName &&  item.activityTypeName.includes(searchedItemId.toLowerCase()) ?
                  searchedItemRef :
                  null
              }
              className={`bulked-unit-item ${
                activitiesTypesStatus.success.findIndex((el) => el === item.activityTypeId) !== -1 &&
                'success-bulked-unit-item'
              } 
              ${
                activitiesTypesStatus.failure.findIndex((el) => el === item.activityTypeId) !== -1 &&
                'failed-bulked-unit-item'
              }
              mb-3 ${
                searchedItemId && searchedItemId.length > 0 &&
                 (item &&  item.activityTypeName &&  item.activityTypeName.toLowerCase().includes(searchedItemId.toLowerCase())) ?
                  'is-search' :
                  ''
              } `}
              
              key={`ItemRef${index + 1+ item &&  (item && item.activityTypeId)}`}
            >
              <div className='card-checkbox-wrapper'>
                <CheckboxesComponent
                  isDisabled={activitiesTypesStatus.success.findIndex((el) => el === item.activityTypeId) !== -1}
                  singleChecked={getIsChecked(item)}
                  idRef={`activityTypeCheckboxItemRef${index + 3}`}
                />
              </div>
              <div className='body-item-wrapper'>
                <div className='body-image-item'>
                </div>
              </div>
              <div>
                <div className='item-ref-no pl-2 pr-2 mt-1'>{(item && item.activityTypeName)}</div>
                <div className='item-name pl-2 pr-2 mt-2'>
                  {t(`${translationPath}id`)}
                  {( item && item.activityTypeId)}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
BulkSelectedActivitiesTypesFieldsSection.propTypes = {
  onActivitiesTypesChange: PropTypes.func.isRequired,
  translationPath: PropTypes.string.isRequired,
  activitiesTypesCards: PropTypes.instanceOf(Array).isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  activitiesTypesStatus: PropTypes.instanceOf(Object).isRequired,
  bulkedActivitiesTypes: PropTypes.instanceOf(Array).isRequired,
};
