import { ActivitiesTypeView } from '../ActivitiesTypeView';
import { ActivityStageView } from './ActivityBuilderTabsViews';

export const ActivityBuilderTabsData = [
  // {
  //   label: 'activity-engine',
  //   component: ActivitiesTypeView,
  //   permissionsList: [],
  //   permissionsId: null,
  // },
  {
    label: 'ActivityStage',
    component: ActivityStageView,
    permissionsList: [],
    permissionsId: null,
  },
];
