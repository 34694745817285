
import { GetAllCampaigns } from '../../../Services';

export const CampaignsRule = async (item,value , setItems , setIsLoading ) => {
  if (item && item.data && item.data.searchKey && item.data.searchKey !== 'campaign_name') return;
 
    setIsLoading(true);
    const rs = await GetAllCampaigns({
      pageIndex: 1,
      pageSize: 50,
      propertyCampaignName: value || '',
    });
    item.data.enum = [];
    if (!rs || !rs.result) return;
    rs.result.map((element) => {
      item.data.enum.push({
        ...element , 
      });
    });
    setItems(item.data.enum);
    setIsLoading(false);
};
