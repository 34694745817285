
import { OrganizationUserSearch } from '../../../../../Services';

export const UserRule = async (item,value , setItems , setIsLoading ) => {
  if (!item.data.searchKey) return;
  if (item.data.searchKey !== 'User') return;
 
  let userTypeId = null;
  const filter = {
    pageSize: 25,
    pageIndex: 1,
    name: value,
    userName: null,
    phoneNumber: null,
    email: null,
    userStatusId: 2,
    userTypeId
  };
    setIsLoading(true);
    const rs = await OrganizationUserSearch({ ...filter });
    item.data.enum = [];
    if (!rs || !rs.result) return;
    rs.result.map((element) => {
      item.data.enum.push({
        id: element.id,
        name: element.fullName,
        phone: element.phoneNumber,
        email: element.email,
        userName: element.userName,
        branch:element.branch , 
      });
    });
    setItems(item.data.enum);
    setIsLoading(false);
};