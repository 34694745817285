import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Divider, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  AssociatedContactCard,
  AssociatedContactCardSkeleton,
  BasicTable,
  DeleteConfirmationDialog,
  NoDataFound,
  TableEditDeleteActions,
  TableSkeleton,
  VIEW_MODES,
  ViewModeSwitcher,
  CustomDialog,
} from "../../../../../../Components";
import { useVerticalNav } from "../../../../../../Contexts/VerticalNavContext";
import {
  CreateAssociatedContact,
  DeleteAssociatedContactV2,
  GetAllContactRelationships,
  GetAssociatedContactByContactId,
  GetContacts,
  UpdateAssociatedContact,
} from "../../../../../../Services";
import { associatedContactTableHeaderData } from "./associatedContactTableHeaderData";
import { useQuery, useTranslate } from "../../../../../../Hooks";

// Styles
import useStyles from "./styles";


const AssociatedContacts = forwardRef(({ contactId }, ref) => {
  const styles = useStyles();

  const query = useQuery();
  const formTypeId = query.get("formType");

  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedTableContact, setSelectedTableContact] = useState(null);

  const [isAssociatedContactsDialogOpen, setIsAssociatedContactsDialogOpen] = useState(false);

  const { isDarkMode } = useSelector((state) => state.theme);

  const { translate } = useTranslate("NewContactsView");

  const [viewMode, setViewMode] = useState(VIEW_MODES.GRID);

  const [isAssociatedContactsLoading, setIsAssociatedContactsLoading] = useState(true);
  const [contacts, setContacts] = useState([]);

  const [isContactRelationshipsLoading, setIsContactRelationshipsLoading] =
    useState(true);
  const [contactRelationships, setContactRelationships] = useState([]);

  const [isAllAssociatedContactLoading, setIsAllAssociatedContactLoading] = useState(false);
  
  const [associatedContactData, setAssociatedContactData] = useState([]);
  const [selectedAssociatedContact, setSelectedAssociatedContact] =
    useState(null);
  const [inputValue, setInputValue] = useState("");

  const [filter, setFilter] = useState({
    id: contactId,
    pageSize: 25,
    pageIndex: 0,
    filterBy: "RelatedContactName",
    orderBy: 1,
    search: "",
  });

  const [contactToEdit, setContactToEdit] = useState(null);

  const searchTimer = useRef(null);

  const { setAlertBoxContent } = useVerticalNav();

  const getAssociatedContacts = async () => {
    setIsAssociatedContactsLoading(true);

    try {
      const res = await GetAssociatedContactByContactId({
        id: contactId,
        filterBy: "RelatedContactName",
        orderBy: 1,
      });

      if (!(res?.status && res.status !== 200)) setContacts(res);
      else setContacts([]);
    } catch (error) {
      console.error("Failed to fetch transactions:", error);
    } finally {
      setIsAssociatedContactsLoading(false);
    }
  };

  const getAllAssociatedContact = async () => {
    setIsAllAssociatedContactLoading(true);
    try {
      const res = await GetContacts({
        ...filter,
        isAdvance: false,
        contactsType: formTypeId,
      });
      if (!(res && res.status && res.status !== 200)) {
        const filteredContacts = res.result.filter(
          (el) =>
            parseInt(contactId) !== el.contactsId &&
            !contacts.some(
              (item) => item.relatedAssociatedContactsId === el.contactsId
            )
        );
        setAssociatedContactData(filteredContacts);
      } else {
        setAssociatedContactData([]);
      }
    } catch (error) {
      console.error("Failed to fetch associated contacts:", error);
      setAssociatedContactData([]);
    } finally {
      setIsAllAssociatedContactLoading(false);
    }
  };

  const getAllContactRelationships = async () => {
    setIsContactRelationshipsLoading(true);
    try {
      const res = await GetAllContactRelationships(formTypeId);

      if (!(res && res.status && res.status !== 200))
        setContactRelationships(res);
      else setContactRelationships([]);
    } catch (error) {
      console.error(error);
      setContactRelationships([]);
    } finally {
      setIsContactRelationshipsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await DeleteAssociatedContactV2(selectedContact?.associatedContactId);

      setAlertBoxContent({
        display: true,
        variant: "success",
        title: "Associated Contact Deleted Successfully!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
      // Filter out the deleted contact from the contacts array
      setContacts((prevContacts) =>
        prevContacts.filter(
          (contact) =>
            contact.associatedContactId !== selectedContact.associatedContactId
        )
      );
    } catch (error) {
      console.error("Failed to Delete Associated Contact:", error);
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "Failed to Delete Associated Contact!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    } finally {
      setSelectedContact(null);
      setSelectedTableContact(null);
    }
  };

  const handleAddAssociatedContacts = (contact) => {
    setContactToEdit(contact ?? null);
    setIsAssociatedContactsDialogOpen(true);
  };
  
  useImperativeHandle(ref, () => ({
    handleAddAssociatedContacts,
  }));

  const handleClose = () => {
    setIsAssociatedContactsDialogOpen(false);
  };

  const handleSubmit = async () => {
    if (!selectedAssociatedContact || !selectedRelationship) return;

    setIsAllAssociatedContactLoading(true);
    try {
      const payload = {
        contactId: contactId,
        contactRelationshipId: selectedRelationship.contactRelationshipId,
        relatedAssociatedContactsId: selectedAssociatedContact.contactsId,
      };
      let res;
      if (contactToEdit) {
        // Update existing associated contact
        res = await UpdateAssociatedContact(payload, contactToEdit.associatedContactId);
        if (!(res && res.status && res.status !== 200)) {
          setAlertBoxContent({
            display: true,
            variant: "success",
            title: "Associated Contact Updated Successfully!",
            onClose: () => {
              setAlertBoxContent(null);
            },
          });
        } else {
          setAlertBoxContent({
            display: true,
            variant: "error",
            title: "Failed to Update Associated Contact!",
            onClose: () => {
              setAlertBoxContent(null);
            },
          });
        }
      } else {
        // Create new associated contact
        res = await CreateAssociatedContact(payload);
        if (!(res && res.status && res.status !== 200)) {
          setAlertBoxContent({
            display: true,
            variant: "success",
            title: "Associated Contact Added Successfully!",
            onClose: () => {
              setAlertBoxContent(null);
            },
          });
        } else {
          setAlertBoxContent({
            display: true,
            variant: "error",
            title: "Failed to Add Associated Contact!",
            onClose: () => {
              setAlertBoxContent(null);
            },
          });
        }
      }
      if (!(res && res.status && res.status !== 200)) {
        // Refresh the associated contacts
        getAssociatedContacts();
        handleClose();
      }
    } catch (error) {
      console.error("Failed to add/update associated contact:", error);
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "Failed to Add/Update Associated Contact!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    } finally {
      setIsAllAssociatedContactLoading(false);
    }
  };

  const [selectedRelationship, setSelectedRelationship] = useState(null);
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    if (contactId) {
      getAssociatedContacts();
    }
    getAllContactRelationships();
  }, [contactId]);

  useEffect(() => {
    getAllAssociatedContact();
  }, [filter]);

  const searchHandler = (value) => {
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
    }
    searchTimer.current = setTimeout(() => {
      setFilter((f) => ({ ...f, search: value }));
    }, 1000);
  };

  return (
    <>
      <Box>
        <Box className={styles.modeWrapper}>
          <ViewModeSwitcher
            viewMode={viewMode}
            onChangeViewMode={(value) => setViewMode(value)}
            isLoading={isAssociatedContactsLoading}
          />
        </Box>
        {viewMode === VIEW_MODES.GRID && (
          <Box className={styles.gridContainer}>
            {isAssociatedContactsLoading ? (
              <AssociatedContactCardSkeleton numberOfCardsSkeleton={8} />
            ) : contacts?.length > 0 ? (
              contacts?.map((contact) => (
                <AssociatedContactCard
                  key={contact?.associatedContactId}
                  contact={contact}
                  onDelete={() => setSelectedContact(contact)}
                  onEdit={() => handleAddAssociatedContacts(contact)}
                />
              ))
            ) : (
              <NoDataFound title="associated contacts" />
            )}
          </Box>
        )}
        {viewMode === VIEW_MODES.TABLE && (
          <Box>
            {isAssociatedContactsLoading ? (
              <TableSkeleton rowsNum={25} />
            ) : contacts?.length > 0 ? (
              <BasicTable
                tableActions={({ anchorPosition }) => {
                  if (Boolean(selectedTableContact))
                    return (
                      <TableEditDeleteActions
                        anchorPosition={anchorPosition}
                        onDelete={() => setSelectedContact(selectedTableContact)}
                        onEdit={() => handleAddAssociatedContacts(selectedTableContact)}
                      />
                    );
                }}
                rowsData={
                  contacts?.map((contact) => ({
                    ...contact,
                    id: contact.relatedAssociatedContactsId,
                  })) ?? []
                }
                setActiveItemOnRowClick={(contact) => {
                  setSelectedTableContact(contact);
                }}
                rowHeight={84}
                columns={associatedContactTableHeaderData(isDarkMode)}
              />
            ) : (
              <NoDataFound title="associated contacts" />
            )}
          </Box>
        )}
      </Box>

      <CustomDialog
        open={isAssociatedContactsDialogOpen}
        onClose={handleClose}
        onConfirm={handleSubmit}
        title={contactToEdit ? "Edit associated" : "Add associated"}
        subtitle="Choose a username and write a brief intro."
        confirmText={contactToEdit ? "Edit Associated" : "Add"}
        cancelText="Cancel"
        width="688px"
        isDisabled={!selectedAssociatedContact || !selectedRelationship}
        content={
          <Box className={styles.fieldWrapper}>
            <Box className={styles.fieldItem}>
              <Typography variant="body2" className={styles.label}>
                Associated contact
              </Typography>
              <Autocomplete
                classes={{ root: styles.inputField }}
                id="associated-contact-autocomplete"
                options={associatedContactData ?? []}
                getOptionLabel={(option) => {
                  if (!option || !option.contact) return "";
                  if (option.contact.contact_type_id === 2) {
                    return option.contact.company_name || "";
                  } else {
                    return `${option.contact.first_name || ""} ${
                      option.contact.last_name || ""
                    }`.trim();
                  }
                }}
                getOptionSelected={(option, value) =>
                  option.contactsId === value.contactsId
                }
                filterOptions={(options) => {
                  return options.filter(
                    (el) =>
                      parseInt(contactId) !== el.contactsId &&
                      !contacts.some(
                        (item) =>
                          item.relatedAssociatedContactsId === el.contactsId
                      )
                  );
                }}
                inputValue={inputValue}
                onInputChange={(event, value) => {
                  setInputValue(value);
                  searchHandler(value);
                }}
                onChange={(event, newValue) => {
                  setSelectedAssociatedContact(newValue);
                }}
                loading={isAllAssociatedContactLoading}
                renderOption={(option) => {
                  if (!option || !option.contact) return null;
                  return (
                    <div>
                      <small style={{ fontWeight: 500 }}>
                        {option.contact.contact_type_id === 2
                          ? option.contact.company_name
                          : `${option.contact.first_name || ""} ${
                              option.contact.last_name || ""
                            }`.trim()}
                      </small>
                      <br />
                      <small>{option.contact.mobile?.phone || ""}</small>
                    </div>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select associated contact"
                    margin="none"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isAllAssociatedContactLoading ? <span>loading...</span> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Box>
            <Divider className={styles.divider} light />
            <Box className={styles.fieldItem}>
              <Typography variant="body2" className={styles.label}>
                Relationship
              </Typography>
              <Autocomplete
                classes={{ root: styles.inputField }}
                id="add-associated-contact"
                options={contactRelationships ?? []}
                getOptionLabel={(option) => option.contactRelationshipName}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedRelationship(newValue);
                  } else {
                    setSelectedRelationship(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select..."
                    onChange={(e) => {
                      setFilterText(e.target.value);
                    }}
                    margin="none"
                    variant="outlined"
                  />
                )}
                loading={isContactRelationshipsLoading}
              />
            </Box>
          </Box>
        }
      />

      {Boolean(selectedContact) && (
        <DeleteConfirmationDialog
          title={translate("associated-contacts-delete-confirmation-title")}
          subTitle={translate(
            "associated-contacts-delete-confirmation-sub-title"
          )}
          onClose={() => {
            setSelectedContact(null);
            setSelectedTableContact(null);
          }}
          onConfirm={() => {
            handleDelete();
          }}
        />
      )}
    </>
  );
});

export default AssociatedContacts;
