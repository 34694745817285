import { HttpServices } from '../../Helper';
import { config } from '../../config/config';

export const CreateUnitTenant = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/UnitTenant/CreateUnitTenant`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const GetAllUnitTenants = async ({ pageSize, pageIndex, unitId }) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/UnitTenant/GetAllUnitTenants/${pageIndex + 1}/${pageSize}/${unitId}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const UpdateUnitTenant = async (unitTenantId, body) => {
  try {
    const result = await HttpServices.put(
      `${config.server_address}/CrmDfm/UnitTenant/UpdateUnitTenant/${unitTenantId}`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const DeleteUnitTenant = async (unitTenantId) => {
  try {
    const result = await HttpServices.delete(
      `${config.server_address}/CrmDfm/UnitTenant/DeleteUnitTenant/${unitTenantId}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};