import React from "react";
import Rating from "@material-ui/lab/Rating";
import { useTranslation } from "react-i18next";

export const KeysNearbyScore = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  return (
    <div className="px-4 w-75">
      <div className="key-nearby-section">
        <div>
          <span className="fw-bold">{t(`${translationPath}keys-nearby-score`)}</span>
        </div>
        <div className="w-100 key-nearby-landmarks mt-2 mb-2">
          {state.listOfPropertyRatingKeysNearbyScoreDto &&
            state.listOfPropertyRatingKeysNearbyScoreDto.map((item, index) => (
              <div
                className="d-flex d-flex-v-center-h-between b-bottom key-nearby-landmarks"
                key={`KeysNearbyScoreCardItemRef${item?.lookupItemId}-${index}`}
              >
                <div>
                  {" "}
                  <span className="fw-bold">{t(`${translationPath}${item.lookupItemName}`)}</span>
                </div>
                <div className="mb-2">
                  <Rating
                    size="large"
                    name={`KeysNearbyScore${index}`}
                    max={10}
                    value={item.score}
                    precision={1}
                    onChange={(event, newValue) => {
                      let updatelist = [
                        ...state.listOfPropertyRatingKeysNearbyScoreDto,
                      ];

                      updatelist[index] = {
                        ...updatelist[index],
                        score: newValue || 0 ,
                      };

                      setState({
                        id: "listOfPropertyRatingKeysNearbyScoreDto",
                        value: [...updatelist],
                      });
                    }}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
