import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GetAllImagesByTypeId, GetFilteredVideoAlbums } from '../../../../../Services';
import { ImagesGalleryFilterEnum, UnitProfileImagesCardActionEnum } from '../../../../../Enums';
import { bottomBoxComponentUpdate } from '../../../../../Helper';
import { PaginationComponent, Spinner } from '../../../../../Components';
import { ImagesGalleryFilterComponent } from '../ImagesGalleryFilterComponent/ImagesGalleryFilterComponent';
import { VideosGalleryCardComponent } from './VideosGalleryCardComponent';

export const VideosGalleryComponent = ({
  fromPage,
  filter,
  filterBy,
  onFilterByChanged,
  onPageIndexChanged,
  onPageSizeChanged,
  parentTranslationPath,
  translationPath,
  activeTab,
  setFilter
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [allVideosAlbums, setAllVideosAlbums] = useState({
    result: [],
    totalCount: 0
  });
  const [cityPhotos, setCityPhotos] = useState({
    result: [],
    totalCount: 0,
  });
  const getlookupItemByType = (lookupItems, lookupType, key) =>
    (lookupItems.findIndex((item) => item.lookupType === lookupType) !== -1 &&
      lookupItems.find((item) => item.lookupType === lookupType)[key]) ||
    null;
  const getFilteredVideoAlbums = useCallback(async () => {
    setIsLoading(true);
    const searchLookupItemId = Object.entries(filterBy)
      .reverse()
      .find((item) => item && item[1]);
    const body = {
      ...filter,
      pageIndex: 1,
      lookupItemId: (searchLookupItemId && searchLookupItemId[1]),
      videoGalleryTypeId: fromPage,

    }
    const res = await GetFilteredVideoAlbums(body);
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      setAllVideosAlbums({
        result:
          (res.result &&
            res.result.map((item) => ({
              ...item,
              countryId: getlookupItemByType(
                item.lookupItems || [],
                ImagesGalleryFilterEnum.Country.lookupType,
                'lookupItemNameId'
              ),
              countryName: getlookupItemByType(
                item.lookupItems || [],
                ImagesGalleryFilterEnum.Country.lookupType,
                'lookupItemName'
              ),
              cityId: getlookupItemByType(
                item.lookupItems || [],
                ImagesGalleryFilterEnum.City.lookupType,
                'lookupItemNameId'
              ),
              cityName: getlookupItemByType(
                item.lookupItems || [],
                ImagesGalleryFilterEnum.City.lookupType,
                'lookupItemName'
              ),
              districtId: getlookupItemByType(
                item.lookupItems || [],
                ImagesGalleryFilterEnum.District.lookupType,
                'lookupItemNameId'
              ),
              districtName: getlookupItemByType(
                item.lookupItems || [],
                ImagesGalleryFilterEnum.District.lookupType,
                'lookupItemName'
              ),
              communityId: getlookupItemByType(
                item.lookupItems || [],
                ImagesGalleryFilterEnum.Community.lookupType,
                'lookupItemNameId'
              ),
              communityName: getlookupItemByType(
                item.lookupItems || [],
                ImagesGalleryFilterEnum.Community.lookupType,
                'lookupItemName'
              ),
              subCommunityId: getlookupItemByType(
                item.lookupItems || [],
                ImagesGalleryFilterEnum.Subcommunity.lookupType,
                'lookupItemNameId'
              ),
              subCommunityName: getlookupItemByType(
                item.lookupItems || [],
                ImagesGalleryFilterEnum.Subcommunity.lookupType,
                'lookupItemName'
              ),
            }))) ||
          [],
        totalCount: res.totalCount || 0,
      });
    } else {
      setAllVideosAlbums({ result: [], totalCount: 0 });
    }
    setIsLoading(false);

  }, [filter, filterBy, fromPage])

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={cityPhotos.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });

  useEffect(() => {
    getFilteredVideoAlbums();
  }, [filter, filterBy, getFilteredVideoAlbums])
  return (
    <div className='gallery-city-photos-wrapper childs-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <ImagesGalleryFilterComponent
        filterBy={filterBy}
        fromPage={fromPage}
        onFilterByChanged={onFilterByChanged}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
      <VideosGalleryCardComponent
        data={allVideosAlbums.result || []}
        fromPage={fromPage}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        getFilteredVideoAlbums={getFilteredVideoAlbums}
        activeTab={activeTab}
        onPageIndexChanged={onPageIndexChanged}
        setFilter={setFilter}
        Display={UnitProfileImagesCardActionEnum.Show}
        setActiveItem={setActiveItem}
        activeItem={activeItem}
        Gallery={true}
      />
    </div>
  );
};
VideosGalleryComponent.propTypes = {
  fromPage: PropTypes.oneOf(Object.values(ImagesGalleryFilterEnum).map((item) => item.key)),
  filter: PropTypes.instanceOf(Object).isRequired,
  filterBy: PropTypes.instanceOf(Object).isRequired,
  onFilterByChanged: PropTypes.func.isRequired,
  onPageIndexChanged: PropTypes.func.isRequired,
  onPageSizeChanged: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
VideosGalleryComponent.defaultProps = {
  fromPage: ImagesGalleryFilterEnum.City.key,
};
