export const ShareTourEnum = [
  {
    key: 1,
    value: 'whatsapp',
  },
  {
    key: 2,
    value: 'email',
  },
];
