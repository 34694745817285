import React, { Children, useRef } from "react";
import { PageHeading } from "../../../../../../SharedComponents";
import { useTranslation } from "react-i18next";
import { ActivityTypeCard } from "./ActivityTypeCard";
import { Inputs, Spinner } from "../../../../../../Components";
import { RemoveLeadStageActivityType } from "../../../../../../Services";

export const StageActivitiesContainer = ({
  activeLeadStage,
  parentTranslationPath,
  translationPath,
  selectedActivities,
  isLoading,
  reload,
  activeCard,
  setActiveCard,
  setSearchByName
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const removeHandler = async (i) => {
    const res = await RemoveLeadStageActivityType(i.leadStageActivityTypeId);
    if (!(res?.status && res.status !== 200)) {
      reload();
    }
  };
  return (
    <div className="stage-view-container utility-gray-50 p-3 my-3 w-100">
      {isLoading && <Spinner isActive />}
      <PageHeading
        parentTranslationPath={parentTranslationPath}
        translationPath={""}
        headerTitle={`${
          activeLeadStage?.lookupItemName
            ? `${activeLeadStage.lookupItemName} stage`
            : ""
        }`}
        subTitle={t(`${translationPath}StageActivitiesContainer-heading`)}
        wrapperClasses={"w-50"}
        headerTitleClasses={"fz-16 mb-1"}
        subTitleClasses={"fz-14 m-0 ml-0"}
      />
      <Inputs
        idRef="stageActivitiesSearchRef"
        inputPlaceholder="activity-name"
        wrapperClasses="mb-3"
        onKeyUp={(e) => {
          const newValue = e.target.value;
          if (searchTimer.current) clearTimeout(searchTimer.current);
          searchTimer.current = setTimeout(() => {
            setSearchByName(newValue);
          }, 700);
        }}
        startAdornment={
          <span className='icons b-icon i-search-lg i-20 mb-1 ml-2 opacity-5'></span>
        }
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
      <div className=" activity-card-container w-100">
        {Children.toArray(
          selectedActivities.map((i) => (
            <ActivityTypeCard
              isActiveCard={i.activityTypeId === activeCard}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              selected
              item={i}
              onRemove={(item) => removeHandler(item)}
              activeCard={activeCard}
              setActiveCard={setActiveCard}
            />
          ))
        )}
      </div>
    </div>
  );
};
