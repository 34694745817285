import moment from "moment";
import {
  LeadsTypesEnum,
  LeadsClassTypesEnum,
  ContactTypeEnum,
} from "../../../../../../../Enums";
import { GlobalTranslate } from "../../../../../../../Helper";

export const LeadMapper = (item, lead) => {

  const leadTypeIndex = Object.values(LeadsClassTypesEnum).findIndex(
    (element) => element.key === item.leadClass
  );
  const leadClass =
    (leadTypeIndex !== -1 &&
      Object.values(LeadsClassTypesEnum)[leadTypeIndex].name) ||
    null;
  item.country = { lookupItemName: item.country };
  item.city = { lookupItemName: item.city };
  item.district = { lookupItemName: item.district };
  item.community = { lookupItemName: item.community };
  item.subCommunity = { lookupItemName: item.subCommunity };
  if (lead) {
    return {
      ...lead,
      leadClass,
      id: item.leadId,
      bulkUpload: item.bulkUpload,
      leadTypeId: lead.lead_type_id,
      imagePath: null,
      lastActivtyTypeName: item.lastActivtyTypeName,
      lastActivityComment: item.lastActivityComment,
      unitType: item.leadUnitType,
      country: item.country,
      city: item.city,
      isFavorite: item.isFavorite,
      district: item.district,
      community: item.community,
      subCommunity: item.subCommunity,
      createdBy: item.createdBy,
      updatedBy: item.updatedBy,
      budgetTo: lead.budgetTo || "",
      budgetFrom: lead.budgetFrom || "",
      sizeAreaTo: item.sizeAreaTo,
      sizeAreaFrom: item.sizeAreaFrom,
      lastActivityCreatedByName: item.lastActivityCreatedByName,
      assignDate:
        (moment(item.assignDate).isValid() && item.assignDate) || null,
      name: `${(lead.contact_name && lead.contact_name.name) || ""}`,
      contactPreferences:
        item.contactPreferences && item.contactPreferences.split(","),
      whatsAppMobile: item.whatsAppMobile,
      matchingUnits: lead.matching_units || [],
      matchingUnitsNumber: item.matchUnit || 0,
      createdOn: (moment(item.createdOn).isValid() && item.createdOn) || null,
      lastActivityDate:
        (moment(item.lastActivityDate).isValid() && item.lastActivityDate) ||
        null,
      updateDate: item.updateOn,
      type: ContactTypeEnum.man.value,
      leadType:
        (lead.lead_type_id === 1 &&
          ((LeadsTypesEnum.Owner && LeadsTypesEnum.Owner.value) || "N/A")) ||
        (lead.lead_type_id === 2 &&
          ((LeadsTypesEnum.Seeker && LeadsTypesEnum.Seeker.value) || "N/A")) ||
        "N/A",
      rating: lead.rating || "",
      fitting_and_fixtures:
        lead.fitting_and_fixtures && lead.fitting_and_fixtures.length > 0
          ? lead.fitting_and_fixtures.map((el) => `${el.lookupItemName}, `)
          : [],
      size_sqft: (lead.size_sqft && lead.size_sqft.join(" * ")) || "",

      budget:
        (lead.budgetTo &&
          lead.budgetFrom &&
          `${lead.budgetFrom} - ${lead.budgetTo}`) ||
        "",

      view:
        (lead.view && lead.view.map((el) => `${el.lookupItemName}, `)) || "",
      developer:
        (lead.developer && lead.developer.map((el) => `${el.name}, `)) || "",

      progress:
        lead.data_completed &&
        typeof lead.data_completed === "string" &&
        lead.data_completed.includes("%")
          ? +lead.data_completed.substr(0, lead.data_completed.length - 1)
          : +lead.data_completed,
      progressWithPercentage:
        lead.data_completed && typeof lead.data_completed !== "string"
          ? `${lead.data_completed}%`
          : lead.data_completed,
      status: (lead && lead.status) || "N/A",
      flatContent: lead.lead_type_id === 2 && [
        {
          iconClasses: "mdi mdi-cash-multiple",
          title: null,
          value: lead.budget
            ? lead.budget.map(
                (element, index) =>
                  `${element}${(index < lead.budget.length - 1 && ",") || ""} `
              )
            : "N/A",
        },
        {
          iconClasses: "mdi mdi-bed",
          title: null,
          value:
            lead && lead.bedrooms && lead.bedrooms.length === 0
              ? GlobalTranslate.t("Shared:any")
              : (lead.bedrooms &&
                  lead.bedrooms.map(
                    (element, index) =>
                      `${element}${
                        (index < lead.bedrooms.length - 1 && ",") || ""
                      } `
                  )) ||
                GlobalTranslate.t("Shared:any"),
        },
        {
          iconClasses: "mdi mdi-shower",
          title: null,
          value:
            lead && lead.bathrooms && lead.bathrooms.length === 0
              ? GlobalTranslate.t("Shared:any")
              : (lead.bathrooms &&
                  lead.bathrooms.map(
                    (element, index) =>
                      `${element}${
                        (index < lead.bathrooms.length - 1 && ",") || ""
                      } `
                  )) ||
                GlobalTranslate.t("Shared:any"),
        },
        {
          iconClasses: "mdi mdi-ruler-square",
          title: "sqf",
          value: lead.size_sqft
            ? lead.size_sqft.map(
                (element, index) =>
                  `${element}${
                    (index < lead.size_sqft.length - 1 && ",") || ""
                  } `
              )
            : "N/A",
        },
      ],
      details: [
        {
          iconClasses: "mdi mdi-clipboard-account-outline",
          title: "lead-type",
          value:
            lead.lead_type_id === 1
              ? GlobalTranslate.t(`Shared:owner`)
              : GlobalTranslate.t(`Shared:seeker`),
        },
        {
          iconClasses: "mdi mdi-account-circle",
          title: "stage",
          value: lead.lead_stage ? lead.lead_stage.lookupItemName : "N/A",
        },
        {
          iconClasses: "mdi mdi-account-box",
          title: "contact-name",
          value: lead.contact_name ? lead.contact_name.name : "N/A",
        },

        {
          iconClasses: "mdi mdi-table-furniture",
          title: "equipments-and-fixtures",
          value:
            (lead.fitting_and_fixtures &&
              lead.fitting_and_fixtures.map(
                (element, index) =>
                  `${element.lookupItemName}${
                    (index < lead.fitting_and_fixtures.length - 1 && ",") || ""
                  } `
              )) ||
            "N/A",
        },
        {
          iconClasses: "mdi mdi-window-open-variant",
          title: "views",
          value:
            (lead.view &&
              ((Array.isArray(lead.view) &&
                lead.view.map(
                  (element, index) =>
                    `${element.lookupItemName}${
                      (index < lead.view.length - 1 && ",") || ""
                    } `
                )) ||
                (typeof lead.view === "object" && lead.view.lookupItemName) ||
                "N/A")) ||
            "N/A",
        },
        {
          iconClasses: "mdi mdi-laptop-windows",
          title: "developers",
          value:
            (lead.developers &&
              lead.developers.map(
                (element, index) =>
                  `${element.lookupItemName}${
                    (index < lead.developers.length - 1 && ",") || ""
                  } `
              )) ||
            "N/A",
        },
      ],
      psi_opportunity: item.psi_opportunity || "",
    };
  }
};
