export const AutoCorrespondenceTypesEnum = {
  Contact: 1,
  WlcBackClosedWon: 2,
  WlcBackClosedLost: 3,
  SellerLeadAssigned: 4,
  BuyerLeadAssigned: 5,
  LandlordLeadAssigned: 6,
  TenantLeadAssigned: 7,
  ClosedWon: 8,
};
