import { config } from '../../config/config';
import { GlobalTranslate, showError, HttpServices } from '../../Helper';


export const GetAllLeadAuditors = async (body, pageIndex, pageSize) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/LeadAuditor/GetAllLeadAuditors?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetAllLeadAuditorAgents = async (body, pageIndex, pageSize) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/LeadAuditor/GetAllLeadAuditorAgents?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const AssignLeadAuditorToAgents = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/LeadAuditor/AssignLeadAuditorToAgents`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const RemoveAgentsFromLeadAuditor = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/LeadAuditor/RemoveAgentsFromLeadAuditor`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const UpdateLeadAuditorCapacity = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/LeadAuditor/UpdateLeadAuditorCapacity`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};


export const GetLeadAuditorByUserIdService = async (userId) => {

  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/LeadAuditor/GetLeadAuditorByUsersId?userId=${userId}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetAllLeadAuditorsByName = async (search, pageIndex, pageSize) => {

  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/LeadAuditor/GetAllLeadAuditorsByName?search=${search}&pageIndex=${pageIndex}&pageSize=${pageSize}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAgentLeadAuditor = async (userId) => {

  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/LeadAuditor/GetAgentLeadAuditor?userId=${userId}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

