import React, { useEffect, useState, useReducer, useCallback } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { showError } from "../../../../Helper";
import { GetUserId, GetUserManagedById, MicrosoftToken } from "../../../../Services";
import { DateRangePickerComponent } from "../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ButtonBase, ButtonGroup } from "@material-ui/core";
import { CellSpinnerBi } from "../CellSpinnerBi/CellSpinnerBi";
import { PowerBiEnum } from "../../../../Enums";
import { GetUserTeamsInfo } from "../../../../Services/userServices";

const parentTranslationPath = "LeadsView";

export const LeadPoolFilter = () => {
  const userid = JSON.parse(localStorage.getItem("session")).userId;

  const { t } = useTranslation(parentTranslationPath);
  const [ShowReport2, setShowReport2] = useState(false);
  const [report, setReport] = useState();
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);
  const [DateAppaer, setDateAppaer] = useState(false);
  const [teamid, setTeamid] = useState([]);
  const [mangerid, setmangerid] = useState([]);
  const [Appuser, setAppuser] = useState();
  const [datefilters, setdatefilter] = useState(true);

  const [datepicker, setdatepicker] = useState();
  const [activeButton, setActiveButton] = useState("One");
  const [activeGroupButton, setactiveGroupButton] = useState(true);
  const [monthsAgo, setMonthsAgo] = useState(1);
  const [MangerFilter, setMangerFilter] = useState(false);
  const [TeamsFilter, setTeamsFilter] = useState(false);
  const [UsersFilter, setUsersFilter] = useState(true);

  const currentDate = new Date();
  const countmonth = new Date(
    currentDate.setMonth(currentDate.getMonth() - monthsAgo)
  );

  const today = new Date();

  const count = new Date(new Date().setDate(today.getDate()));
  const count2 = new Date(new Date().setDate(today.getDate()));
  const datetimes = moment(count)
    .startOf("month")
    .format("YYYY-MM-DDTHH:mm:ss");
  const datetimes2 = moment(count2).format("YYYY-MM-DDTHH:mm:ss");

  const startDate = datetimes ? new Date(datetimes) : null;
  const endDate = datetimes2 ? new Date(datetimes2) : null;

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: "selection",
  };

  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [data, setdata] = useState([]);
  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [state, setState] = useReducer(reducer, {
    fromDate: null,
    toDate: null,
  });

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const currentState = {
      ...state,
      fromDate:
        (dateFilter.startDate &&
          moment(dateFilter.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        null,
      toDate:
        (dateFilter.endDate &&
          moment(dateFilter.endDate)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        null,
    };
    setState({ value: currentState });
    setdata(currentState);
  }, [dateFilter]);

  const onClearedAllFiltersClick = useCallback(() => {
    setDateFilter(dateRangeDefault);
    applyBookmark("Bookmark58dccb4e19e3f49ada74");
    setdatepicker(0);
    setdatefilter(true);
  }, []);

  const [bookmarks, setBookmarks] = useState([]);
  const [error, setError] = useState(null);

  const loadBookmarks = async () => {
    const report = window.report;

    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        const fetchedBookmarks = await bookmarksManager.getBookmarks();
        setBookmarks(fetchedBookmarks);
        // console.log("Bookmarks:", fetchedBookmarks);
      } catch (error) {
        console.error("Error loading bookmarks:", error);
        setError(error);
      }
    }
  };
  const applyBookmark = async (bookmarkName) => {
    const report = window.report;
    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        bookmarksManager.apply(bookmarkName);
        // console.log("Bookmark applied successfully:", bookmarkName);
      } catch (error) {
        console.error("Error applying bookmark:", error);
        setError(error);
      }
    }
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const ChartView = () => {
    applyBookmark("Bookmark90591d45b15c0086919a");
    handleButtonClick("One");
    setShowReport2(false);
  };
  const TableView = () => {
    applyBookmark("Bookmarkc1361ccd558c2d7419d2");
    handleButtonClick("Two");
    setShowReport2(false);
  };

  const [filterButton, setfilterButton] = useState(false);

  const FilterHide = () => {
    applyBookmark("Bookmark4c3de8eb5c52742fcc38");
    setfilterButton(false);
  };
  const FilterView = () => {
    applyBookmark("Bookmarka5745a4a838f60fc75cf");
    setfilterButton(true);
  };

  const Pagenavi = async (pagestate) => {
    const report = window.report;
    let pages = await report.getPages();

    if (report) {
      try {
        report.setPage(pagestate);
      } catch (error) {
        console.error("Error applying:", error);
      }
    }
  };

  const handleRefreshClick = useCallback(() => {
    window.report
      .refresh()
      .then(() => {
        setShowReport2(false);
     
      })
      .catch((error) => {
     
      });
  }, []);



  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setAppuser(userid);
      setTimeout(() => {
        setRender(true);
      }, 500);
    } else {
      setReport("");
      showError("Contact Your Bi Admin For Help");
    }
  };
  const advancedFilter = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "LeadsFromPoolBi",
      column: "CreatedOn",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };
  const FilterManger = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "View_TeamUsersTree",
      column: "TeamsId",
    },
    operator: "In",
    values: [...mangerid, ...teamid],
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };
  const FilterTeam = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "View_TeamUsersTree",
      column: "TeamsId",
    },
    operator: "In",
    values: teamid,
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };
  const FilterUser = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "View_Users",
      column: "UsersIds",
    },
    operator: "In",
    values: [Appuser],
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };

  const getUserManagedById = useCallback(async () => {
    const res = await GetUserManagedById(userid);
    if (!(res && res.status && res.status !== 200)) {
      setmangerid(res && res.map((List) => List.teamsId || null));
      setMangerFilter(true);
      setUsersFilter(false);
    } else {
      setmangerid([]);
    }
  }, [userid]);

  const getUserTemasById = useCallback(async () => {
    const res = await GetUserTeamsInfo(userid, true);
    if (!(res && res.status && res.status !== 200) && res.length > 0) {
      setTeamid(res && res.map((List) => List.teamsId || null));
      setTeamsFilter(true);
      setUsersFilter(false);
    } else {
      setTeamid([]);
    }
  }, [userid]);

  const getAppUserId = useCallback(async () => {
    const res = await GetUserId(userid);
    if (!(res && res.status && res.status !== 200)) {
      setAppuser(res);
    } else {
      setAppuser([]);
    }
  }, [userid]);

  useEffect(() => {
    getUserManagedById(userid);

    if (!MangerFilter) {
      getUserTemasById(userid);
    } else if (TeamsFilter) {
      getUserTemasById(userid);
    } else if (MangerFilter && TeamsFilter) {
      getUserManagedById(userid);
      getUserTemasById(userid);
    } else if (!MangerFilter && !TeamsFilter) {
      setAppuser(userid);
    }
  }, [
    MangerFilter,
    TeamsFilter,
    getUserManagedById,
    getUserTemasById,
    UsersFilter,
    userid,
    Appuser,
  ]);

  let selectedFilter;
  switch (true) {
    case MangerFilter:
      selectedFilter = FilterManger;
      break;

    case TeamsFilter:
      selectedFilter = FilterTeam;
      break;
    case UsersFilter:
      selectedFilter = FilterUser;
      break;
  }




  useEffect(() => {
    Microsoft(false);
  }, []);

  return (
    <div className="dashboardMain-PowerBIEmbed">
      <CellSpinnerBi isActive={!ShowReport} blue />
      {DateAppaer && (
        <div>
          <div className="MainFiltersBi">
            <CellSpinnerBi isActive={!ShowReport2} isAbsolute blue />

            <div className="selecterbi">
              <div>
                <DateRangePickerComponent
                  onClearClicked={() => {
                    setDateFilter({
                      startDate: null,
                      endDate: null,
                      key: "selection",
                    });
                  }}
                  ranges={[dateFilter]}
                  onDateChanged={(selectedDate) => {
                    const newStartDate =
                      selectedDate.selection &&
                      selectedDate.selection.startDate;
                    const newEndDate = new Date(
                      moment(
                        selectedDate.selection && selectedDate.selection.endDate
                      ).endOf("day")
                    );

                    setDateFilter({
                      startDate: newStartDate,
                      endDate: newEndDate,
                      key: "selection",
                    });
                  }}
                />
              </div>
            </div>
            {!filterButton ? (
              <div className="DashboardFilter">
                <ButtonBase
                  variant="contained"
                  className="btns theme-solid bg-primary-dark Dashboardbtn"
                  style={{ color: "white" }}
                  onClick={FilterView}
                  disabled={activeButton == "Two" || activeGroupButton == false}
                >
                  <span className="mdi mdi-filter-menu m-1" />
                </ButtonBase>
              </div>
            ) : (
              <div className="DashboardFilter">
                <ButtonBase
                  variant="contained"
                  className="btns theme-solid bg-danger Dashboardbtn"
                  style={{ color: "white" }}
                  onClick={FilterHide}
                >
                  <span className="mdi mdi-filter-minus-outline m-1" />
                </ButtonBase>
              </div>
            )}
            <div className="DashboardFilter">
              <ButtonBase
                onClick={onClearedAllFiltersClick}
                id="onClearedAllFiltersref"
                className="btns theme-solid bg-primary-dark clear-all-btn Dashboardbtn"
                disabled={activeGroupButton == false}
              >
                <span className="mdi mdi-filter-remove m-1" />
              </ButtonBase>
            </div>
            <div className="DashboardFilter">
              <ButtonBase
                onClick={handleRefreshClick}
                id="onClearedAllFiltersref"
                className="btns theme-solid bg-primary-dark clear-all-btn Dashboardbtn"
              >
                <span className="mdi mdi-refresh m-1" />
              </ButtonBase>
            </div>
            {activeGroupButton ? (
              <div className="DashboardFilter">
                <ButtonGroup>
                  <ButtonBase
                    onClick={ChartView}
                    className={`btns theme-solid  clear-all-btn Dashboardbtn ${
                      activeButton === "One" ? "chartActive" : "chartNotActive"
                    }`}
                  >
                    <span className="mdi mdi-chart-pie m-1" />
                  </ButtonBase>

                  <ButtonBase
                    onClick={TableView}
                    className={`btns theme-solid  clear-all-btn Dashboardbtn ${
                      activeButton === "Two" ? "chartActive" : "chartNotActive"
                    }`}
                  >
                    <span className="mdi mdi-format-list-bulleted-square m-1" />
                  </ButtonBase>
                </ButtonGroup>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      )}

      <br />
      <div className="mainCardDashboards">
        {render && (
          <div className="dashboardMain cardDashboard">
            <PowerBIEmbed
              embedConfig={{
                type: "report",
                id: PowerBiEnum.LeadFromPool.reportid,
                embedUrl: PowerBiEnum.LeadFromPool.url,
                accessToken: report,
                filters: [advancedFilter, selectedFilter],
                settings: {
                  customLayout: {
                    displayOption: models.DisplayOption.FitToWidth,
                  },
                  filterPaneEnabled: false,
                  navContentPaneEnabled: false,
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false,
                    },
                  },
                  background: models.BackgroundType.Transparent,
                },
              }}
              eventHandlers={
                new Map([
                  [
                    "loaded",
                    (event) => {
                      setShowReport(true);
                      setDateAppaer(true);
                    },
                  ],
                  [
                    "dataSelected",

                    (event) => {
                      setShowReport2(false);
                    },
                  ],

                  [
                    "rendered",
                    (event) => {
                      setShowReport2(true);
                      setRender(true);
                      loadBookmarks();
                    },
                  ],

                  [
                    "pageChanged",
                    (event) => {
                      const newPageName = event.detail.newPage.displayName;
                    },
                  ],

                  [
                    "error",
                    (event) => {
                      const error = event.detail;
                      if (
                        error.detailedMessage === "Get report failed" ||
                        error.detailedMessage ===
                          "Access token has expired, resubmit with a new access token" ||
                        error.detailedMessage ===
                          "Fail to initialize - Could not resolve cluster"
                      ) {
                        Microsoft(true);
                        setRender(false);
                      } else
                      showError(error.detailedMessage);
                    },
                  ],
                ])
              }
              cssClassName={
                ShowReport
                  ? "report-style-class-Show"
                  : "report-style-class-hide"
              }
              getEmbeddedComponent={(embeddedReport) => {
                window.report = embeddedReport;
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
