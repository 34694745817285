import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import PermissionDenied from '../../assets/images/defaults/Illustration.png';
import arrowleft from '../../assets/images/defaults/arrow-left.png';
import { GlobalHistory } from '../../Helper';

import './PermissionDeniedLayout.scss';

export const PermissionDeniedLayout = () => {
  const { t } = useTranslation('Shared');
  const translationPath = 'PermissionDeniedViews.';

  return (

    <div className='no-permission'>
      <div className='permission-denied'>
        <Grid container className='permission-denied-area'>
          <Grid item>
            <img
              src={PermissionDenied}
              alt={t(`${translationPath}permission-denied`)}
              className='no-data-img'
            />
          </Grid>
          <div className='PermissionDeniedText1'>
            {t(`${translationPath}no-permission`)}
          </div>
          <div className='PermissionDeniedText2'>
            {t(`${translationPath}necessary_permissions_to_access`)}
          </div>
          {/* <div className='backButtonArea'>
          <img
          src={arrowleft}
           alt={t(`${translationPath}permission-denied`)}

          />
           <Button onClick={() => {
            GlobalHistory.goBack();
          }}>
            <span>{t(`${translationPath}go-back`)}</span>
          </Button>

        </div> */}

        </Grid>
      </div>
    </div>
  );
};
