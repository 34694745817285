export const ContactsMergingHistoryMapperDto = [
  {
    formFieldName: "title",
    displayPath: "title.lookupItemName",
  },
  {
    formFieldName: "first_name",
    displayPath: "first_name",
  },
  {
    formFieldName: "last_name",
    displayPath: "last_name",
  },
  {
    formFieldName: "gender",
    displayPath: "gender.lookupItemName",
  },
  {
    formFieldName: "mobile",
    displayPath: "mobile.phone",
  },
  {
    formFieldName: "whatsapp_mobile",
    displayPath: "whatsapp_mobile.phone",
  },
  {
    formFieldName: "other_contact_mobile_no",
    displayPath: "other_contact_mobile_no.phone",
  },
  {
    formFieldName: "home_phone",
    displayPath: "home_phone.phone",
  },
  {
    formFieldName: "work_phone",
    displayPath: "work_phone.phone",
  },
  {
    formFieldName: "fax",
    displayPath: "fax.phone",
  },
  {
    formFieldName: "email_address",
    displayPath: "email_address.email",
  },
  {
    formFieldName: "nationality",
    displayPath: "nationality.lookupItemName",
  },
  {
    formFieldName: "landline_number",
    displayPath: "landline_number.phone",
  },
  {
    formFieldName: "language",
    displayPath: "language.lookupItemName",
  },
  {
    formFieldName: "contact_class",
    displayPath: "contact_class.lookupItemName",
  },
  {
    formFieldName: "multi_property_owner",
    displayPath: "multi_property_owner",
  },
  {
    formFieldName: "promo_by_email",
    displayPath: "promo_by_email",
  },
  {
    formFieldName: "promo_by_sms",
    displayPath: "promo_by_sms",
  },
  {
    formFieldName: "promo_by_whatsapp",
    displayPath: "promo_by_whatsapp",
  },
  {
    formFieldName: "contact_preference",
    displayPath: "contact_preference",
  },
  {
    formFieldName: "contact_classifications",
    displayPath: "contact_classifications",
  },
  {
    formFieldName: "contact_remarks",
    displayPath: "contact_remarks",
  },
  {
    formFieldName: "country",
    displayPath: "country.lookupItemName",
  },
  {
    formFieldName: "city",
    displayPath: "city.lookupItemName",
  },
  {
    formFieldName: "district",
    displayPath: "district.lookupItemName",
  },
  {
    formFieldName: "community",
    displayPath: "community.lookupItemName",
  },
  {
    formFieldName: "sub_community",
    displayPath: "sub_community.lookupItemName",
  },
  {
    formFieldName: "street",
    displayPath: "street.value",
  },
  {
    formFieldName: "building_name",
    displayPath: "building_name.value",
  },
  {
    formFieldName: "building_no",
    displayPath: "building_no.value",
  },
  {
    formFieldName: "floor_no",
    displayPath: "floor_no.value",
  },
  {
    formFieldName: "pobox",
    displayPath: "pobox",
  },
  {
    formFieldName: "map",
    displayPath: "map",
  },
  {
    formFieldName: "address_line1",
    displayPath: "address_line1",
  },
  {
    formFieldName: "address_line2",
    displayPath: "address_line2",
  },
  {
    formFieldName: "postalzip_code",
    displayPath: "postalzip_code.value",
  },
  {
    formFieldName: "near_by",
    displayPath: "near_by.value",
  },
  {
    formFieldName: "date_of_birth",
    displayPath: "date_of_birth",
  },
  {
    formFieldName: "marital_status",
    displayPath: "marital_status.lookupItemName",
  },
  {
    formFieldName: "qualification",
    displayPath: "qualification.lookupItemName",
  },
  {
    formFieldName: "works_company_name",
    displayPath: "works_company_name.name",
  },
  {
    formFieldName: "contact_profession",
    displayPath: "contact_profession.lookupItemName",
  },
  {
    formFieldName: "job_title",
    displayPath: "job_title",
  },
  {
    formFieldName: "nationality_no",
    displayPath: "nationality_no",
  },
  {
    formFieldName: "number_of_kids",
    displayPath: "number_of_kids",
  },
  {
    formFieldName: "media_name",
    displayPath: "media_name.lookupItemName",
  },
  {
    formFieldName: "owner_in_multiple_areas",
    displayPath: "owner_in_multiple_areas",
  },
  {
    formFieldName: "high_net_worth",
    displayPath: "high_net_worth",
  },
  {
    formFieldName: "owner_for_one_unit",
    displayPath: "owner_for_one_unit",
  },
  {
    formFieldName: "investor",
    displayPath: "investor",
  },
  {
    formFieldName: "nationality_category",
    displayPath: "nationality_category.lookupItemName",
  },
  {
    formFieldName: "representative_contact",
    displayPath: "representative_contact.name",
  },
  {
    formFieldName: "spouse_contact",
    displayPath: "spouse_contact.name",
  },
  {
    formFieldName: "id_card_no",
    displayPath: "id_card_no",
  },
  {
    formFieldName: "id_card_expiry_date",
    displayPath: "id_card_expiry_date",
  },
  {
    formFieldName: "passport_no",
    displayPath: "passport_no",
  },
  {
    formFieldName: "passport_issue_date",
    displayPath: "passport_issue_date",
  },
  {
    formFieldName: "passport_expiry_date",
    displayPath: "passport_expiry_date",
  },
  {
    formFieldName: "visa_type",
    displayPath: "visa_type.lookupItemName",
  },
  {
    formFieldName: "visa_issuer_country",
    displayPath: "visa_issuer_country.lookupItemName",
  },
  {
    formFieldName: "visa_no",
    displayPath: "visa_no",
  },
  {
    formFieldName: "visa_issue_date",
    displayPath: "visa_issue_date",
  },
  {
    formFieldName: "visa_expiry_date",
    displayPath: "visa_expiry_date",
  },
  {
    formFieldName: "tax_registration_no",
    displayPath: "tax_registration_no",
  },
  {
    formFieldName: "linkedin_link",
    displayPath: "linkedin_link.link",
  },
  {
    formFieldName: "facebook_link",
    displayPath: "facebook_link.link",
  },
  {
    formFieldName: "twitter_link",
    displayPath: "twitter_link.link",
  },
  {
    formFieldName: "instagram_link",
    displayPath: "instagram_link.link",
  },
  {
    formFieldName: "instagram_account_number",
    displayPath: "instagram_account_number",
  },
  {
    formFieldName: "snapchat_link",
    displayPath: "snapchat_link",
  },
  {
    formFieldName: "snapchat_account_number",
    displayPath: "snapchat_account_number",
  },
  {
    formFieldName: "tiktok_link",
    displayPath: "tiktok_link",
  },
  {
    formFieldName: "tiktok_account_number",
    displayPath: "tiktok_account_number",
  },
  {
    formFieldName: "contact_image",
    displayPath: null,
  },
  {
    formFieldName: "flagged_contact",
    displayPath: "flagged_contact",
  },
  {
    formFieldName: "high_profile_management",
    displayPath: "high_profile_management",
  },
  {
    formFieldName: "angry_bird",
    displayPath: "angry_bird",
  },
  {
    formFieldName: "psi_opportunity",
    displayPath: "psi_opportunity",
  },
  {
    formFieldName: "company_logoimage",
    searchableKey: null,
    displayPath: null,
  },
  {
    formFieldName: "company_name",

    searchableKey: "company_name",

    displayPath: "company_name",
  },
  {
    formFieldName: "landline_number",

    searchableKey: "landline_number.phone",

    displayPath: "landline_number.phone",
  },
  {
    formFieldName: "established_date",

    searchableKey: "established_date",

    displayPath: "established_date",
  },
  {
    formFieldName: "industry",

    searchableKey: "industry.lookupItemName",

    displayPath: "industry.lookupItemName",
  },
  {
    formFieldName: "retail_category",

    searchableKey: "retail_category",

    displayPath: "retail_category.lookupItemName",
  },
  {
    formFieldName: "company_nationality",

    searchableKey: "company_nationality.lookupItemName",

    displayPath: "company_nationality.lookupItemName",
  },
  {
    formFieldName: "nationality_category",

    searchableKey: "nationality_category",

    displayPath: "nationality_category",
  },
  {
    formFieldName: "no_of_local_branches",

    searchableKey: "no_of_local_branches",

    displayPath: "no_of_local_branches",
  },
  {
    formFieldName: "no_of_remote_branches",

    searchableKey: "no_of_remote_branches",

    displayPath: "no_of_remote_branches",
  },
  {
    formFieldName: "no_of_employees",

    searchableKey: "no_of_employees",

    displayPath: "no_of_employees",
  },
  {
    formFieldName: "business_region",

    searchableKey: "business_region",

    displayPath: "business_region",
  },
  {
    formFieldName: "company_website",

    searchableKey: "company_website",

    displayPath: "company_website",
  },
  {
    formFieldName: "general_email",

    searchableKey: "general_email",

    displayPath: "general_email.email",
  },
  {
    formFieldName: "language",

    searchableKey: "language.lookupItemName",

    displayPath: "language.lookupItemName",
  },
  {
    formFieldName: "company_type",

    searchableKey: "company_type.lookupItemName",

    displayPath: "company_type.lookupItemName",
  },
  {
    formFieldName: "media_name",

    searchableKey: "media_name.lookupItemName",

    displayPath: "media_name.lookupItemName",
  },
  {
    formFieldName: "multi_property_owner",

    searchableKey: "multi_property_owner",

    displayPath: "multi_property_owner",
  },
  {
    formFieldName: "company_class",

    searchableKey: "company_class.lookupItemName",

    displayPath: "company_class.lookupItemName",
  },
  {
    formFieldName: "promo_smsemail",

    searchableKey: "promo_smsemail",

    displayPath: "promo_smsemail",
  },
  {
    formFieldName: "exclusive",

    searchableKey: "exclusive",

    displayPath: "exclusive",
  },
  {
    formFieldName: "contact_rate",

    searchableKey: "contact_rate",

    displayPath: "contact_rate",
  },
  {
    formFieldName: "special_requirements",

    searchableKey: "special_requirements",

    displayPath: "special_requirements",
  },
  {
    formFieldName: "flagged_contact",

    searchableKey: "flagged_contact",

    displayPath: "flagged_contact",
  },
  {
    formFieldName: "psi_opportunity",

    searchableKey: "psi_opportunity",

    displayPath: "",
  },
  {
    formFieldName: "country",

    searchableKey: "country.lookupItemName",

    displayPath: "country.lookupItemName",
  },
  {
    formFieldName: "city",

    searchableKey: "city.lookupItemName",

    displayPath: "city.lookupItemName",
  },
  {
    formFieldName: "district",

    searchableKey: "district.lookupItemName",

    displayPath: "district.lookupItemName",
  },
  {
    formFieldName: "community",

    searchableKey: "community.lookupItemName",

    displayPath: "community.lookupItemName",
  },
  {
    formFieldName: "street",

    searchableKey: "street.value",

    displayPath: "street.value",
  },
  {
    formFieldName: "building_name",

    searchableKey: "building_name.value",

    displayPath: "building_name.value",
  },
  {
    formFieldName: "building_no",

    searchableKey: "building_no.value",

    displayPath: "building_no.value",
  },
  {
    formFieldName: "floor_no",

    searchableKey: "floor_no.value",

    displayPath: "floor_no.value",
  },
  {
    formFieldName: "office_no",

    searchableKey: "office_no.value",

    displayPath: "office_no.value",
  },
  {
    formFieldName: "map",

    searchableKey: "",

    displayPath: "",
  },
  {
    formFieldName: "address_line_1",

    searchableKey: "address_line_1",

    displayPath: "address_line_1",
  },
  {
    formFieldName: "address_line2",

    searchableKey: "address_line2",

    displayPath: "address_line2",
  },
  {
    formFieldName: "pobox",

    searchableKey: "pobox",

    displayPath: "pobox",
  },
  {
    formFieldName: "postalzip_code",

    searchableKey: "postalzip_code.value",

    displayPath: "postalzip_code.value",
  },
  {
    formFieldName: "near_by",

    searchableKey: "near_by.value",

    displayPath: "near_by.value",
  },
  {
    formFieldName: "contacts_person",

    searchableKey: "contacts_person.name",

    displayPath: "contacts_person.name",
  },
  {
    formFieldName: "contact_classifications",

    searchableKey: "contact_classifications",

    displayPath: "contact_classifications",
  },
  {
    formFieldName: "contact_preference",

    searchableKey: "contact_preference",

    displayPath: "contact_preference[0].lookupItemName",
  },
  {
    formFieldName: "contact_remarks",

    searchableKey: "contact_remarks",

    displayPath: "contact_remarks",
  },
  {
    formFieldName: "facebook_link",

    searchableKey: "facebook_link.link",

    displayPath: "facebook_link.link",
  },
  {
    formFieldName: "twitter_link",

    searchableKey: "twitter_link.link",

    displayPath: "twitter_link.link",
  },
  {
    formFieldName: "instagram_link",

    searchableKey: "instagram_link.link",

    displayPath: "instagram_link.link",
  },
  {
    formFieldName: "linkedin_link",

    searchableKey: "linkedin_link.link",

    displayPath: "linkedin_link.link",
  },
  {
    formFieldName: "instagram_account_number",

    searchableKey: "instagram_account_number",

    displayPath: "instagram_account_number",
  },
  {
    formFieldName: "snapchat_link",

    searchableKey: "snapchat_link",

    displayPath: "snapchat_link",
  },
  {
    formFieldName: "tiktok_link",

    searchableKey: "tiktok_link",

    displayPath: "tiktok_link",
  },
  {
    formFieldName: "snapchat_account_number",

    searchableKey: "snapchat_account_number",

    displayPath: "snapchat_account_number",
  },
  {
    formFieldName: "tiktok_account_number",

    searchableKey: "tiktok_account_number",

    displayPath: "tiktok_account_number",
  },
  {
    formFieldName: "licence_no",

    searchableKey: "licence_no",

    displayPath: "licence_no",
  },
  {
    formFieldName: "license_expiry",

    searchableKey: "license_expiry",

    displayPath: "license_expiry",
  },
  {
    formFieldName: "license_issuer",

    searchableKey: "license_issuer",

    displayPath: "license_issuer",
  },
];
