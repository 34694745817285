export const CommunicationsIds = {
    email : { 
        key : 'email' , 
        value : 18037 ,
        title  : 'Email' 
    } , 
    mobile : { 
        key : 'mobile' , 
        value : 18027 ,
        title  : 'Mobile' 
    } , 
    
  };
  