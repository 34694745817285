

export const  ActiveUserLogPermissions  = {
  GetActiveUserLog: {
     permissionsId: "c59f8d3b-5d21-4e44-c4d2-08dc683b0c43",
     permissionsName: "Get Active User Log",
     description: null,
     componentsId: null,
     components: null
    }
  };
  