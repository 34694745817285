
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Spinner, Tables  } from '../../../../../Components';
import { TableActions } from '../../../../../Enums';
import { PortalConfigurationManagementDialog } from '../PortalConfigurationManagement' ; 

export const PortalConfigurationTableView = ({
   parentTranslationPath,
   translationPath,
   filter,
   data,
   reloadData,
   activeItem ,
   setActiveItem ,
   onSave , 
   setFilter , 
   onPageIndexChanged,
   onPageSizeChanged,
   setIsReloadPortalData , 
   isReloadPortalData , 
   editPortalConfiguration ,
   isLoading ,  

}) => {

  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);


  const { t } = useTranslation([parentTranslationPath, 'Shared']);

  const tableActionClicked = useCallback((actionEnum, item) => {
    setActiveItem(item);
        if (actionEnum === TableActions.editText.key)
         setIsOpenEditDialog(true) ; 
  }, []);

  const getActionTableWithPermissions = () => {
    const list = [];
      list.push({ enum: TableActions.editText.key , title: t(`${translationPath}Shared:edit`)});
    return list;
  };


  return (
  <div className='PortalConfigurationTableView'> 
      <div className='filter-section-item' />
      <div className='w-100 px-2'>
        <Tables
          data={(data && data.result) || []}
          headerData={[
            {
              id: 1,
              isSortable: false,
              label: t(`${translationPath}portalName`) , 
              component: (item) => <span>{(item && item.portalName)}</span>
            },
            {
              id: 1,
              isSortable: false,
              label: t(`${translationPath}portal`) , 
              component: (item) => <span>{(item && item.portalConfigrationDto && item.portalConfigrationDto.lookupItemName)}</span>
            },

            {
              id: 2,
              isSortable: false,
              input: 'operationTypeName',
              label: t(`${translationPath}operationTypeName`) , 
              component: (item) => <span>{(item && item.operationType && item.operationType.lookupItemName)}</span>
            },
            {
              id: 3,
              isSortable: false,
              input: 'apiKey',
              label: t(`${translationPath}apiKey`)
            },
            {
              id: 4,
              isSortable: false,
              input: 'apiUrl',
              label: t(`${translationPath}apiUrl`)
            },  
          ]}
          defaultActions={getActionTableWithPermissions()}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          totalItems={(data && data.totalCount) || 0}
          onPageIndexChanged ={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      </div>

      {
        isOpenEditDialog && 
        ( 
          <PortalConfigurationManagementDialog 
             parentTranslationPath={parentTranslationPath}
             translationPath={translationPath}
             isOpen={isOpenEditDialog}
             onClose={()=> setIsOpenEditDialog(false)} 
             onSave={()=> 
             {

              setIsOpenEditDialog(false) ; 
              setIsReloadPortalData(!isReloadPortalData); 
             }}
             editPortalConfiguration={editPortalConfiguration}
             isLoading={isLoading}
          />
        ) 
      }
    
    </div>
  );
};

PortalConfigurationTableView.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  data:PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.instanceOf(Object).isRequired,
  onPageIndexChanged :  PropTypes.func.isRequired, 
  onPageSizeChanged: PropTypes.func.isRequired,  

};
