
import React, {
  useReducer,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import PropTypes from "prop-types";
import Joi from "joi";
import { useTranslation } from "react-i18next";
import { isNaN } from "core-js/core/number";
import {
  LeadsAdvanceSearchTest,
  OrganizationUserSearch,
} from "../../../../../../Services";
import { showError, showSuccess, GlobalTranslate, getErrorByName } from "../../../../../../Helper";
import {
  AutocompleteComponent,
  DialogComponent,
  Spinner,
} from "../../../../../../Components";
import {
  AgentRoleEnum,
  LeadsClassTypesEnum,
  LeadsTypesEnum,
} from "../../../../../../Enums";
import { SentTemplateWithSignature } from "../../../../../../Services/UnitsServices";
import { ApplicationUserSearch } from '../../../../../../Services/userServices';

export const SendEmailContractDialog = ({
  isOpen,
  isOpenChanged,
  translationPath,
  parentTranslationPath,
  unitId,
  activeItem,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const [isLoadingApi, setIsLoadingApi] = useState();
  const [isLoading, setIsLoading] = useState({
    LeaseListingAgentLoading: false,
    ListingAgent: false,
    Tenant: false,
    Landlord: false
  });

  const [selectedValuesArray, setselectedValuesArray] = useState({
    Users: [],
    landlordelead: [],
    ListingAgent: [],
  });

  const [Users, setUsers] = useState([]);
  const [leaseUsers, setleaseUsers] = useState([]);
  const searchTimer = useRef(null);
  const [FinalDto, SetFinalDto] = useState({});
  const [operationType, setoperationType] = useState(2);
  const [DetailsLeadsTenantList, setDetailsLeadsTenantList] = useState(false);
  const [DetailsLeadsLandlordList, setDetailsLeadsLandlordList] =
    useState(false);
  const [filter, setfilter] = useState({
    pageSize: 100,
    pageIndex: 1,
    name: "",
    userName: null,
    phoneNumber: null,
    userStatusId: 2,
    email: null,
    userTypeId: null,
  });

  const getLeadsData = useCallback(async (searchItem, types) => {

    if (types === LeadsClassTypesEnum.tenant.value) {
      setIsLoading((item) => ({ ...item, Tenant: true }));
    } else {
      setIsLoading((item) => ({ ...item, Landlord: true }));
    }


    let criteria = null;
    if (!isNaN(+searchItem)) {
      criteria = {
        Ids: [{ searchType: 2, value: +searchItem }],

        lead_type_id: [
          { searchType: 2, value: types === LeadsClassTypesEnum.tenant.value ? 2 : 1 }
        ],
        "status.lookupItemName": [{ searchType: 1, value: "open" }],
      }
    } else {
      criteria = {
        "contact_name.name": [
          {
            searchType: 2,
            value: searchItem || "",
          },
        ],
        lead_type_id: [
          { searchType: 2, value: types === LeadsClassTypesEnum.tenant.value ? 2 : 1 }
        ],
        "status.lookupItemName": [{ searchType: 1, value: "open" }],
      };
    }

    const res = await LeadsAdvanceSearchTest(
      {
        pageSize: 25,
        pageIndex: 0,
      },
      {
        criteria: criteria,
        filterBy: "createdOn",
        orderBy: 2,
      }
    );
    if (!(res && res.status && res.status !== 200)) {
      let data = [];
      data = {
        result: ((res && res.result) || []).map((item) => {
          const lead = item.leadJson && JSON.parse(item.leadJson).lead;
          const leadTypeIndex = Object.values(LeadsClassTypesEnum).findIndex(
            (element) => element.key === item.leadClass
          );
          const leadClass =
            (leadTypeIndex !== -1 &&
              Object.values(LeadsClassTypesEnum)[leadTypeIndex].name) ||
            null;

          if (lead) {
            return {
              ...lead,
              leadClass,
              id: item.leadId,
              leadTypeId: lead.lead_type_id,
              imagePath: null,
              lastActivtyTypeName: item.lastActivtyTypeName,
              lastActivityComment: item.lastActivityComment,
              unitType: item.leadUnitType,
              country: item.country,
              city: item.city,
              district: item.district,
              isFavorite: item.isFavorite,
              community: item.community,
              subCommunity: item.subCommunity,
              createdBy: item.createdBy,
              updatedBy: item.updatedBy,
              budgetTo: item.budgetTo,
              budgetFrom: item.budgetFrom,
              sizeAreaTo: item.sizeAreaTo,
              sizeAreaFrom: item.sizeAreaFrom,
              lastActivityCreatedByName: item.lastActivityCreatedByName,
              name: `${(lead.contact_name && lead.contact_name.name) || ""}`,
              matchingUnits: lead.matching_units || [],
              matchingUnitsNumber: item.matchUnit || 0,
              updateDate: item.updateOn,
              leadType:
                (lead.lead_type_id === 1 &&
                  ((LeadsTypesEnum.Owner && LeadsTypesEnum.Owner.value) ||
                    "N/A")) ||
                (lead.lead_type_id === 2 &&
                  ((LeadsTypesEnum.Seeker && LeadsTypesEnum.Seeker.value) ||
                    "N/A")) ||
                "N/A",
              rating: lead.rating || "",
              fitting_and_fixtures:
                lead.fitting_and_fixtures &&
                  lead.fitting_and_fixtures.length > 0
                  ? lead.fitting_and_fixtures.map(
                    (el) => `${el.lookupItemName}, `
                  )
                  : [],
              size_sqft: (lead.size_sqft && lead.size_sqft.join(" * ")) || "",

              budget: (lead.budget && lead.budget.join(" - ")) || "",

              view:
                (lead.view &&
                  lead.view.map((el) => `${el.lookupItemName}, `)) ||
                "",
              developer:
                (lead.developer &&
                  lead.developer.map((el) => `${el.name}, `)) ||
                "",

              progress:
                lead.data_completed &&
                  typeof lead.data_completed === "string" &&
                  lead.data_completed.includes("%")
                  ? +lead.data_completed.substr(
                    0,
                    lead.data_completed.length - 1
                  )
                  : +lead.data_completed,
              progressWithPercentage:
                lead.data_completed && typeof lead.data_completed !== "string"
                  ? `${lead.data_completed}%`
                  : lead.data_completed,
              status: (lead && lead.status) || "N/A",
              flatContent: lead.lead_type_id === 2 && [
                {
                  iconClasses: "mdi mdi-cash-multiple",
                  title: null,
                  value: lead.budget
                    ? lead.budget.map(
                      (element, index) =>
                        `${element}${(index < lead.budget.length - 1 && ",") || ""
                        } `
                    )
                    : "N/A",
                },
                {
                  iconClasses: "mdi mdi-ruler-square",
                  title: "sqf",
                  value: lead.size_sqft
                    ? lead.size_sqft.map(
                      (element, index) =>
                        `${element}${(index < lead.size_sqft.length - 1 && ",") || ""
                        } `
                    )
                    : "N/A",
                },
              ],
              details: [
                {
                  iconClasses: "mdi mdi-clipboard-account-outline",
                  title: "lead-type",
                  value:
                    lead.lead_type_id === 1
                      ? t(`${translationPath}owner`)
                      : t(`${translationPath}seeker`),
                },
                {
                  iconClasses: "mdi mdi-account-circle",
                  title: "stage",
                  value: lead.lead_stage
                    ? lead.lead_stage.lookupItemName
                    : "N/A",
                },
                {
                  iconClasses: "mdi mdi-account-box",
                  title: "contact-name",
                  value: lead.contact_name ? lead.contact_name.name : "N/A",
                },

                {
                  iconClasses: "mdi mdi-table-furniture",
                  title: "equipments-and-fixtures",
                  value:
                    (lead.fitting_and_fixtures &&
                      lead.fitting_and_fixtures.map(
                        (element, index) =>
                          `${element.lookupItemName}${(index < lead.fitting_and_fixtures.length - 1 &&
                            ",") ||
                          ""
                          } `
                      )) ||
                    "N/A",
                },
                {
                  iconClasses: "mdi mdi-window-open-variant",
                  title: "views",
                  value:
                    (lead.view &&
                      ((Array.isArray(lead.view) &&
                        lead.view.map(
                          (element, index) =>
                            `${element.lookupItemName}${(index < lead.view.length - 1 && ",") || ""
                            } `
                        )) ||
                        (typeof lead.view === "object" &&
                          lead.view.lookupItemName) ||
                        "N/A")) ||
                    "N/A",
                },
                {
                  iconClasses: "mdi mdi-laptop-windows",
                  title: "developers",
                  value:
                    (lead.developers &&
                      lead.developers.map(
                        (element, index) =>
                          `${element.lookupItemName}${(index < lead.developers.length - 1 && ",") || ""
                          } `
                      )) ||
                    "N/A",
                },
              ],
            };
          }
        }),
        totalCount: (res && res.totalCount) || 0,
      };
      if (types === LeadsClassTypesEnum.tenant.value) {
        setDetailsLeadsTenantList(data);
        setIsLoading((item) => ({ ...item, Tenant: false }));
      }
      else if (types === LeadsClassTypesEnum.landlord.value) {
        setDetailsLeadsLandlordList(data);
        setIsLoading((item) => ({ ...item, Landlord: false }));
      }



      setIsLoading((item) => ({ ...item, Landlord: false, Tenant: false }));
    }
  }, []);

  const getUsersAPI = useCallback(async () => {
    if (filter.userTypeId === AgentRoleEnum.LeaseListingAgent.value)
      setIsLoading((item) => ({ ...item, LeaseListingAgentLoading: true }));
    else {
      setIsLoading((item) => ({ ...item, ListingAgent: true }));
    }


    const res = await ApplicationUserSearch({ ...filter });
    if (res && res.totalCount === 0) {
      if (filter.userTypeId === AgentRoleEnum.SaleListingAgent.value) {
        setUsers({
          result: [],
          totalCount: 0,
        });
      } else {
        setleaseUsers({
          result: [],
          totalCount: 0,
        });
      }
    } else if (filter.userTypeId === AgentRoleEnum.LeaseListingAgent.value) {
      setUsers({
        result: res.result,
        totalCount: res.totalCount,
      });
      setIsLoading((item) => ({ ...item, LeaseListingAgentLoading: false }));



    } else {
      setIsLoading((item) => ({ ...item, ListingAgent: false }));
      setleaseUsers({
        result: res.result,
        totalCount: res.totalCount,
      });
    }
    setIsLoading((item) => ({ ...item, LeaseListingAgentLoading: false, ListingAgent: false }));

  }, [filter, operationType]);


  const searchHandler = (e, sss) => {
    setoperationType();
    const { value } = e.target;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setfilter((item) => ({ ...item, name: value, userTypeId: sss }));
    }, 700);
  };

  const SentTemplateWithSignatureAPI = useCallback(
    async (FinalDto) => {
      setIsLoadingApi(true);
      const body = [
        {
          unitId,
          systemTemplateId: 1191,
          recipients: [
            {
              id: FinalDto?.tenantlead?.id,
              email: FinalDto?.tenantlead?.contact_name?.email_address ,
              orderNumber: 1,
              type: 1,
            },
            {
              id: FinalDto?.ListingAgent?.applicationUserId,
              email: FinalDto?.ListingAgent?.email,
              orderNumber: 2,
              type: 2
            },
            {
              id: FinalDto?.landlordelead?.id,
              email: FinalDto?.landlordelead?.contact_name?.email_address || FinalDto?.landlordelead?.email_address ||'' ,
              orderNumber: 3,
              type: 1,
            },
            {
              id: FinalDto?.LeaseListingAgent?.applicationUserId,
              email: FinalDto?.LeaseListingAgent?.email,
              orderNumber: 4,
              type: 2,
            },
          ],
        },
      ];

      const res = await SentTemplateWithSignature(body);
      if (!(res && res.status && res.status !== 200)) {
        showSuccess(t(`${translationPath}template-email-sent-successfully`));  
        isOpenChanged();
      } else showError(t(`${translationPath}template-email-sent-failed`)); 
      setIsLoadingApi(false);
    },
    [FinalDto]
  );

  const schema = Joi.object({
    tenantlead: Joi.object()
      .required()
      .messages({
        "object.base": t(`${translationPath}tenantlead-is-required`),
      }),
    landlordelead: Joi.object()
      .required()
      .messages({
        "object.base": t(`${translationPath}landlordelead-is-required`),
      }),
    ListingAgent: Joi.object()
      .required()
      .messages({
        "object.base": t(`${translationPath}ListingAgent-is-required`),
      }),
    LeaseListingAgent: Joi.object()
      .required()
      .messages({
        "object.base": t(`${translationPath}LeaseListingAgent-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(FinalDto);

  useEffect(() => {
    getUsersAPI();
  }, [filter]);

  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
    },
    []
  );

  useEffect(() => {
    getLeadsData("", LeadsClassTypesEnum.tenant.value);
  }, []);

  useEffect(() => {
    if (activeItem) {
      setselectedValuesArray((item) => ({
        ...item,
        Users: {
          email: activeItem?.unitItem?.rent_listing_agent?.email || "",
          id: activeItem?.unitItem?.rent_listing_agent?.id,
          applicationUserId: activeItem?.unitItem?.rent_listing_agent?.applicationUserId,
          applicationUserId: activeItem?.unitItem?.rent_listing_agent?.id,
          fullName: activeItem?.unitItem?.rent_listing_agent?.name,
          phone: activeItem?.unitItem?.rent_listing_agent?.phone || "",
          userName: activeItem?.unitItem?.rent_listing_agent?.userName,
        },
        landlordelead: activeItem?.unitItem?.lease_lead_owner,
      }));
      SetFinalDto((item) => ({
        ...item,
        landlordelead: activeItem?.unitItem?.lease_lead_owner,
        LeaseListingAgent: {
          email: activeItem?.unitItem?.rent_listing_agent?.email || "",
          applicationUserId: activeItem?.id,
          fullName: activeItem?.unitItem?.rent_listing_agent?.name,
          phone: activeItem?.unitItem?.rent_listing_agent?.phone || "",
          userName: activeItem?.unitItem?.rent_listing_agent?.userName,
        }
      }));
    }
  }, [activeItem]);

  const saveHandler = () =>{

    if (schema.error) {
      showError(GlobalTranslate.t("Shared:please-fix-all-errors"));
      return;
    }
    if(!isLoadingApi) SentTemplateWithSignatureAPI(FinalDto)
  }


  return (
    <DialogComponent
      titleText="send-tenancy-contract"
      saveText="confirm"
      saveType="button"
      maxWidth="sm"
      dialogContent={
        <div className="d-flex-column-center">
          <Spinner isActive={isLoadingApi} isAbsolute />
          <div className="w-100 p-relative mb-2">
            <AutocompleteComponent
              data={
                (DetailsLeadsTenantList && DetailsLeadsTenantList.result) || []
              }
              wrapperClasses="autocomplete-with-btn"
              labelValue="tenant-lead"
              labelClasses='Requierd-Color'
              isLoading={isLoading.Tenant}
              withLoader
              withoutSearchButton
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              translationPathForData={translationPath}
              renderOption={(option) => (
                <div className="d-flex-column">
                  <div className="d-flex-v-center-h-between w-100 texts-truncate">
                  
                    {`${option.name} (${option.id})`}
                  </div>
                  <span className="c-gray-secondary"> {option.refNo} </span>
                </div>
              )}
              multiple={false}
              displayLabel={(option) =>
                ((option.name || option.fullName) &&
                  `${option.name} (${option.id})`) ||
                ''}

              chipsLabel={(option) =>
                ((option.name || option.fullName) &&
                  `${option.name} (${option.id})`) ||
                ''}
              inputPlaceholder="search-in-tenant-lead"
              onInputKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  getLeadsData(value, LeadsClassTypesEnum.tenant.value);
                }, 700);
              }}
              onChange={(event, newValue) => {
                SetFinalDto((item) => ({ ...item, tenantlead: newValue }));
                setselectedValuesArray((item) => ({
                  ...item,
                  ListingAgent: newValue && newValue.referredto || [],
                }));
              }}
            />

            <AutocompleteComponent
              idRef="ListingAgentRef"
              labelValue="ListingAgent"
              multiple={false}
              labelClasses='Requierd-Color'
              inputPlaceholder="search-in-ListingAgent"
              data={leaseUsers.result || []}
              displayLabel={(option) => (option && option.fullName || option.name) || ""}
              chipsLabel={(option) => (option && option.fullName || option.name) || ""}
              getOptionSelected={(option) => option.applicationUserId === selectedValuesArray?.ListingAgent?.id}
              selectedValues={selectedValuesArray.ListingAgent}
              withoutSearchButton
              onInputKeyUp={(e) => {
                searchHandler(e, AgentRoleEnum.SaleListingAgent.value);
              }}
              isWithError
              isLoading={isLoading.ListingAgent}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onChange={(event, newValue) => {
                SetFinalDto((item) => ({ ...item, ListingAgent: newValue }));
                setselectedValuesArray((item) => ({
                  ...item,
                  ListingAgent: newValue,
                }));
              }}
            />
          </div>
          <AutocompleteComponent
            data={
              (DetailsLeadsLandlordList && DetailsLeadsLandlordList.result) ||
              []
            }
            wrapperClasses="autocomplete-with-btn"
            labelValue="landlorde-lead"
            inputPlaceholder="search-in-landlorde-lead"
            isLoading={isLoading.Landlord}
            labelClasses='Requierd-Color'
            withLoader
            withoutSearchButton
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            translationPathForData={translationPath}
            selectedValues={selectedValuesArray.landlordelead}
            renderOption={(option) => (
              <div className="d-flex-column">
                <div className="d-flex-v-center-h-between w-100 texts-truncate">
                  {`${option.name} (${option.id})`}
                </div>
                <span className="c-gray-secondary"> {option.refNo} </span>
              </div>
            )}
            displayLabel={(option) =>
              ((option.name || option.fullName) &&
                `${option.name} (${option.id})`) ||
              ''}

            chipsLabel={(option) =>
              ((option.name || option.fullName) &&
                `${option.name} (${option.id})`) ||
              ''}
            multiple={false}
            onInputKeyUp={(e) => {
              const { value } = e.target;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                getLeadsData(value, LeadsClassTypesEnum.landlord.value);
              }, 700);
            }}
            onChange={(event, newValue) => {
              SetFinalDto((item) => ({ ...item, landlordelead: newValue }));
              setselectedValuesArray((item) => ({
                ...item,
                landlordelead: newValue,
              }));
            }}
          />

          <AutocompleteComponent
            idRef="LeaseListingAgentRef"
            labelValue="LeaseListingAgent"
            withoutSearchButton
            labelClasses='Requierd-Color'
            multiple={false}
            data={Users.result || []}
            inputPlaceholder="search-in-LeaseListingAgent"
            selectedValues={selectedValuesArray.Users}
            getOptionSelected={(option) => option.applicationUserId === selectedValuesArray.id}
            displayLabel={(option) => option.fullName || ""}
            onInputKeyUp={(e) => {
              searchHandler(e, AgentRoleEnum.LeaseListingAgent.value);
            }}
            isWithError
            isLoading={isLoading.LeaseListingAgentLoading}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onChange={(event, newValue) => {
              SetFinalDto((item) => ({ ...item, LeaseListingAgent: newValue }));
              setselectedValuesArray((item) => ({
                ...item,
                Users: newValue,
              }));
            }}
          />
        </div>
      }
      saveClasses="btns theme-solid  w-100 mx-2 mb-2"
      isOpen={isOpen}
      IS
      onSaveClicked={saveHandler}
      saveIsDisabled={isLoadingApi}
      onCloseClicked={isOpenChanged}
      onCancelClicked={isOpenChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};


