import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../Hooks';
import {
  GlobalHistory,
  bottomBoxComponentUpdate,
  showError,
  showSuccess,
} from '../../../Helper';
import { Button, ButtonBase, DialogContentText } from '@material-ui/core';
import {
  Tables,
  DialogComponent,
  SelectComponet,
  AutocompleteComponent,
  Spinner,
  PermissionsComponent 
} from '../../../Components';
import {
  InquiryTypeIdEnum,
  TableActions,
  UserOperationTypeEnum,
  ViewTypesEnum,
} from '../../../Enums';
import {
  DeleteInquiryRotationScheme,
  GetAllBranches,
  GetInquiryRotationSchemes,
} from '../../../Services';
import { Add } from '@material-ui/icons';
import IOSSwitch from './InquirySchemeManagement/components/IOSSwitch';
import './Styles/InquiryRotationViewStyle.scss';
import { StaticLookupsIds } from '../../../assets/json/StaticLookupsIds';
import { InquiryRotationPermissions } from '../../../Permissions' ; 
const parentTranslationPath = 'InquiryRotation';

const translationPath = '';

export const InquiryRotationView = () => {
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t(`${translationPath}inquiry-rotation`));

  const [filter, setFilter] = useState({
    pageSize: +localStorage.getItem('Pagination') || 25,
    pageIndex: 1,
    inquiryType: '',
    operationType: '',
    isActive: undefined,
    isSLAApplied: undefined,
  });

  const [isLoading, setIsLoading] = useState(false);
  const searchTimer = useRef(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);

  const [rotationData, setRotationData] = useState({
    result: [],
    totalCount: 0,
  });

  const [branches, setBranches] = useState([]);

  const headerData = [
    {
      label: 'name',
      input: 'name',
    },
    {
      label: 'status',
      component: (row) => <IOSSwitch checked={row.isActive} />,
    },
    {
      label: 'SLA',
      component: (row) =>
        row.slaExpirationPeriod && row.slaExpirationPeriodFreqency ? (
          <span>{`${row.slaExpirationPeriod} ${row.slaExpirationPeriodFreqency}`}</span>
        ) : (
          '-'
        ),
    },
    {
      label: 'inquiryRotationInquiryTypes',
      input: 'inquiryRotationInquiryTypes',
    },
    {
      label: 'inquiryRotationOperationTypes',
      input: 'inquiryRotationOperationTypes',
    },
    {
      label: 'inquiryRotationRequesterBranches',
      input: 'inquiryRotationRequesterBranches',
    },
    {
      label: 'numberOfInquiries',
      input: 'numberOfInquiries',
    },
    {
      label: 'createdOn',
      input: 'createdOn',
      isDate: true,
    },
    {
      label: 'createdBy',
      input: 'createdBy',
    },
  ];
  const searchHandler = (search) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      getAllBranches(search);
    }, 700);
  };

  const getInquiryRotationSchemes = async (filter) => {
    setIsLoading(true);
    const result = await GetInquiryRotationSchemes(filter);
    if (!(result?.status && result?.status !== 200)) {
      setRotationData(result);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getInquiryRotationSchemes(filter);
  }, [filter]);

  const getAllBranches = async (branchName) => {
    try {
      const res = await GetAllBranches({
        pageIndex: 0,
        pageSize: 50,
        branchName,
      });
      if (!(res?.status && res.status !== 200)) {
        setBranches(res.result);
      } else throw res;
    } catch (error) {
      showError('something went wrong');
    }
  };

  const actionClickHandler = (action, row) => {
    if (action === TableActions.edit.key) {
      GlobalHistory.push(`/home/inquiry-rotation/edit/${row.id}`);
    } else if (action === TableActions.delete.key) {
      setDeleteItem(row.id);
      setDeleteDialogOpen(true);
    }
  };

  const deleteHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await DeleteInquiryRotationScheme(deleteItem);
    if (res === true) {
      getInquiryRotationSchemes(filter);
      setDeleteDialogOpen(false);
      setDeleteItem(null);
      showSuccess(t('delete_successfully'));
    } else {
      showError(t('something_went_wrong'));
    }
    setIsLoading(false);
  };

  const closeDialogHandler = () => {
    setDeleteDialogOpen(false);
    setDeleteItem(null);
  };

  const onClearClick = () => {
    setFilter({
      pageSize: +parseInt(localStorage.getItem('Pagination')) || 25,
      pageIndex: 1,
      inquiryType: '',
      operationType: '',
      isActive: undefined,
      isSLAApplied: undefined,
    });
  };

  useEffect(() => {
    getAllBranches();
  }, []);

  return (
    <>
      <div className='py-5 inquiry-rotation-view'>
        <Spinner isActive={isLoading} isAbsolute />
        <div className=' d-flex flex-v-end gap-10 w-100 fj-between'>
          <div>
          
            <Button
              className='btns theme-solid'
              onClick={() => GlobalHistory.push('/home/inquiry-rotation/add')}
            >
              <Add />
              {t('new_rotation')}
            </Button>
          </div>
          <PermissionsComponent
             permissionsList={Object.values(InquiryRotationPermissions)}
             permissionsId={InquiryRotationPermissions.ViewInquiryRotation.permissionsId}
           >
          <div className='d-flex fj-center gap-10'>
            <SelectComponet
              labelValue={'inquiryRotationInquiryTypes'}
              data={[
                { id: 1, title: 'Zero Matching' },
                { id: 2, title: 'Listing Shortage' },
              ]}
              valueInput={'id'}
              textInput={'title'}
              value={filter.inquiryType}
              onSelectChanged={(e) =>
                setFilter((prev) => ({ ...prev, inquiryType: e }))
              }
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              wrapperClasses={'w-50'}
            />
            <SelectComponet
              labelValue={'inquiryRotationOperationTypes'}
              data={[
                { id: StaticLookupsIds.Sales, title: 'Sale' },
                { id: StaticLookupsIds.Rent, title: 'Lease' },
              ]}
              valueInput={'id'}
              textInput={'title'}
              value={filter.operationType}
              onSelectChanged={(e) =>
                setFilter((prev) => ({ ...prev, operationType: e }))
              }
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              wrapperClasses={'w-50'}
            />
            <AutocompleteComponent
              idRef='branch2Ref'
              labelValue={t(
                `${translationPath}inquiryRotationRequesterBranches`
              )}
              data={branches}
              chipsLabel={(option) => option?.branchName || ''}
              displayLabel={(option) => option?.branchName || ''}
              multiple={false}
              withoutSearchButton
              onInputKeyUp={(e) => searchHandler(e.target?.value)}
              onChange={(_event, newValue) => {
                setFilter((prev) => ({
                  ...prev,
                  branchId: newValue?.branchId,
                }));
              }}
              wrapperClasses={'w-50'}
            />
            <SelectComponet
              labelValue={'isActive'}
              data={[
                { id: true, title: 'Active' },
                { id: false, title: 'Inactive' },
              ]}
              valueInput={'id'}
              textInput={'title'}
              value={filter.isActive}
              onSelectChanged={(e) =>
                setFilter((prev) => ({ ...prev, isActive: e }))
              }
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              wrapperClasses={'w-50'}
            />
            <SelectComponet
              labelValue={'isSLAApplied'}
              data={[
                { id: true, title: 'Applied' },
                { id: false, title: 'Not Applied' },
              ]}
              valueInput={'id'}
              textInput={'title'}
              value={filter.isActive}
              onSelectChanged={(e) =>
                setFilter((prev) => ({ ...prev, isSLAApplied: e }))
              }
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              wrapperClasses={'w-50'}
            />
          </div>
       
          </PermissionsComponent>
          <PermissionsComponent
             permissionsList={Object.values(InquiryRotationPermissions)}
             permissionsId={InquiryRotationPermissions.ViewInquiryRotation.permissionsId}
           >
          <div>
            <ButtonBase
              onClick={onClearClick}
              id='onClearedAllFiltersref'
              disabled={isLoading}
              className='btns theme-solid bg-danger clear-all-btn'
            >
              <span className='mdi mdi-filter-remove m-1' />
              {t(`${translationPath}clear_filters`)}
            </ButtonBase>
          </div>
          </PermissionsComponent>
        </div>
      </div>
      <PermissionsComponent
          permissionsList={Object.values(InquiryRotationPermissions)}
          permissionsId={InquiryRotationPermissions.ViewInquiryRotation.permissionsId}
      >

      {headerData.length > 0 && (
        <Tables
          data={rotationData.result}
          headerData={headerData}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          activePage={filter.pageIndex - 1}
          totalItems={rotationData.totalCount}
          itemsPerPage={filter.pageSize}
          onPageSizeChanged={(size) =>
            setFilter((prev) => ({ ...prev, pageSize: size }))
          }
          onPageIndexChanged={(index) =>
            setFilter((prev) => ({ ...prev, pageIndex: index + 1 }))
          }
          defaultActions={[
            { enum: TableActions.edit.key },
            { enum: TableActions.delete.key },
          ]}
          actionsOptions={{
            onActionClicked: actionClickHandler,
          }}
        />
      )}
     </PermissionsComponent>
      <DialogComponent
        titleText={'delete_scheme'}
        isOpen={deleteDialogOpen}
        onCloseClicked={closeDialogHandler}
        onCancelClicked={closeDialogHandler}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        dialogContent={
          <DialogContentText>{t('confirm_message')}</DialogContentText>
        }
        maxWidth={'sm'}
        saveText={t('confirm')}
        onSaveClicked={deleteHandler}
      />
    </>
  );
};
