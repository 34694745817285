import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import "../../LeaseTransactionJourney.scss";
import xCloseIcon from "../../../../../assets/images/icons/x-close-dialog.svg";
import { DialogActions, DialogContent, ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { showWarn, GlobalHistory } from "../../../../../Helper";
import { UnitsStatusEnum } from "../../../../../Enums";
import {
  AutocompleteComponent,
  DialogComponent,
} from "../../../../../Components";
import { PageHeading, AboutUnitCard, MessageConfirmDialog } from "../../../../../SharedComponents";
import { UnitsAdvanceSearchTest, CanSetUnitAsReservedOrLeasedOrSale } from "../../../../../Services";
import { UnitMapper } from "../../../UnitsSalesView/UnitMapper/UnitMapper.jsx";
import { TransactionStepsData } from '../TransactionStepsData.jsx'
import MissingInformationErrorMSG from "./MissingInformationErrorMSG.jsx";

export const TransactionUnitSelectionDialog = ({
  isDialogOpen,
  onClose,
  activeUnitId,
}) => {
  const parentTranslationPath = "LeaseTransactionJourney";
  const translationPath = "UnitSelection.";

  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [selected, setSelected] = useReducer(reducer, {
    unitId: null,
    RefNoUnit: null,
    UnitNumberUnit: null,
  });
  const [data, setData] = useReducer(reducer, {
    RefNoUnits: [],
    UnitNumberUnits: [],
    initUnit: null,
  });
  const [isLoading, setIsLoading] = useState({
    RefNoUnits: false,
    UnitNumberUnits: false,
    initUnit: false,
  });
  const [isMissingInfoMSGOpen, setIsMissingInfoMSGOpen] = useState(false);

  const getUnits = async ({ unitRefNo, unitNumber }) => {
    const stateKey = (activeUnitId && 'initUnit') ||
      (unitRefNo && "RefNoUnits") ||
      (unitNumber && "UnitNumberUnits");

    setIsLoading((prevState) => ({ ...prevState, [stateKey]: true }));

    const criteria = {};

    if (unitRefNo)
      criteria["unit_ref_no"] = [
        {
          searchType: 2,
          value: unitRefNo,
        },
      ];
    if (unitNumber)
      criteria["unit_number"] = [
        {
          searchType: 2,
          value: unitNumber,
        },
      ];
    if (activeUnitId)
      criteria["unitId"] = [
        {
          searchType: 2,
          value: activeUnitId,
        },
      ];

    criteria["status"] = [
      {
        searchType: 1,
        value: "Available",
      },
    ];

    const body = {
      criteria,
      filterBy: "createdOn",
      orderBy: 2,
      OperationType: "Lease",
    };

    const res = await UnitsAdvanceSearchTest(
      { pageIndex: 0, pageSize: 10 },
      body
    );
    if (!(res && res.status && res.status !== 200)) {
      const mappedResult = ((res && res.result) || []).map(
        (item) =>
          item.unitJson && UnitMapper(item, JSON.parse(item.unitJson).unit)
      );

      const units = (activeUnitId && mappedResult?.length > 0)? mappedResult[0] : mappedResult;

      setData({ id: stateKey, value: units || [] });
    } else setData({ id: stateKey, value: [] });

    setIsLoading((prevState) => ({ ...prevState, [stateKey]: false }));
  };

  const checkIsTransactionValid = async () => {
    const newStatus = UnitsStatusEnum.UnderOffer.key;
    const res = await CanSetUnitAsReservedOrLeasedOrSale(selected.unitId, newStatus);
    if (res) {
      const nextStep = TransactionStepsData.TenantSelection;
      GlobalHistory.push(
        `/home/lease-transaction-journey?id=${selected.unitId}&step=${nextStep.key}`
      );
    } else setIsMissingInfoMSGOpen(true)
  };


  const submitHandler = async () => {
    if (!selected.unitId) {
      showWarn(t(`${translationPath}please-select-unit-error`));
      return;
    } 
    
     await checkIsTransactionValid();
  };

  const confirmMissingInfoDialog = () => {
    const activeUnit = data.initUnit || selected.UnitNumberUnit || selected.RefNoUnit;
    setIsMissingInfoMSGOpen(false)
    onClose();
    const URLPath = `/home/units-lease/unit-profile-edit?formType=${activeUnit.unitTypeId}&id=${activeUnit.id}`;

    const isUnitLeaseView = !activeUnitId;
    if (isUnitLeaseView) window.open(URLPath, '_blank');
    else window.location.replace(URLPath);
  }

  const cancelMissingInfoDialog = () => {
    setIsMissingInfoMSGOpen(false)
  }


  useEffect(() => {
    if (activeUnitId) {
      getUnits({});
      setSelected({ id: "unitId", value: activeUnitId });
    }
  }, [activeUnitId])

  return (
    <div>
      <DialogComponent
        isOpen={isDialogOpen}
        maxWidth="sm"
        dialogTitle={
          <div className="user-groups-view add-task-dialog w-100">
            <div className="d-flex-v-center-h-between fa-start">
              <PageHeading
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                headerTitle={"Start-transaction"}
                subTitle={"Start-transaction-heading"}
                wrapperClasses={"m-0"}
                headerTitleClasses={"m-0 fz-16"}
                subTitleClasses={"m-0 fz-12"}
              />
              <div className="xCloseIcon pointer" onClick={onClose}>
                <img src={xCloseIcon} />
              </div>
            </div>
          </div>
        }
        dialogContent={
          <div className="Transaction-Layout ">
            <DialogContent>
              <>
                <div className="d-flex fj-start mb-3">
                  <div className="w-33 mt-1">
                    <span className="fw-simi-bold">
                      {t(`${translationPath}UnitNumber`)}
                    </span>
                  </div>
                  <div className="w-75">
                    <AutocompleteComponent
                      idRef="unitNumberRef"
                      wrapperClasses="w-min-unset mt-1"
                      selectedValues={selected.UnitNumberUnit}
                      inputPlaceholder={t(`select`)}
                      multiple={false}
                      data={data.UnitNumberUnits || []}
                      displayLabel={(option) =>
                        option?.refNo || option?.unit_ref_no || ""
                      }
                      getOptionSelected={(option, value)=> option.id === value.id}
                      isDisabled={activeUnitId}
                      withoutSearchButton
                      isLoading={isLoading.UnitNumberUnits}
                      onChange={(_, newValue) => {
                        setSelected({
                          id: "edit",
                          value: {
                            unitId: newValue?.id || null,
                            RefNoUnit: null,
                            UnitNumberUnit: newValue,
                          },
                        });
                      }}
                      onInputChange={(_, value) => {
                        if (searchTimer.current)
                          clearTimeout(searchTimer.current);
                        searchTimer.current = setTimeout(() => {
                          if (value) getUnits({ unitNumber: value });
                        }, 1400);
                      }}
                    />
                  </div>
                </div>

                <div className="OR-seperator d-flex-center">
                  <span className="line w-100"></span>
                  <span className="fw-simi-bold light-text m-1">
                    {t(`${translationPath}or`)}
                  </span>
                  <span className="line w-100"></span>
                </div>
                <div className="d-flex fj-start my-3">
                  <div className="w-33 mt-1">
                    <span className="fw-simi-bold">
                      {t(`${translationPath}UnitRefNo`)}
                    </span>
                  </div>
                  <div className="w-75">
                    <AutocompleteComponent
                      idRef="UnitRefNoRef"
                      wrapperClasses="w-min-unset mt-1"
                      selectedValues={selected.RefNoUnit}
                      inputPlaceholder={t(`select`)}
                      multiple={false}
                      data={data.RefNoUnits || []}
                      displayLabel={(option) =>
                        option?.refNo || option?.unit_ref_no || ""
                      }
                      getOptionSelected={(option, value)=> option.id === value.id}
                      isDisabled={activeUnitId}
                      withoutSearchButton
                      isLoading={isLoading.RefNoUnits}
                      onChange={(_, newValue) => {
                        setSelected({
                          id: "edit",
                          value: {
                            unitId: newValue?.id || null,
                            RefNoUnit: newValue,
                            UnitNumberUnit: null,
                          },
                        });
                      }}
                      onInputChange={(_, value) => {
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            if (value) getUnits({ unitRefNo: value });
                          }, 1400);
                      }}
                    />
                  </div>
                </div>

                {selected.unitId ? (
                  <>
                    <span className="line w-100"></span>

                    <div className="d-flex fj-start my-3">
                      <div className="w-33 mt-1">
                        <span className="fw-simi-bold">
                          {t(`${translationPath}unit-details`)}
                        </span>
                      </div>
                      <div className="w-75">
                        <AboutUnitCard
                          unitData={data.initUnit || selected.RefNoUnit || selected.UnitNumberUnit}
                          orientation="horizantal"
                        />
                      </div>
                    </div>
                  </>
                ) : null} 
              </>
            </DialogContent>
            <DialogActions>
              <div className="d-flex-center fj-end py-0 pt-3">
                <ButtonBase
                  onClick={onClose}
                  className="btns theme-propx outlined"
                >
                  {t(`cancel`)}
                </ButtonBase>

                <ButtonBase
                  className="btns theme-propx solid mr-0"
                  onClick={submitHandler}
                >
                  {t(`start`)}
                </ButtonBase>
              </div>
            </DialogActions>
          </div>
        }
      />
      {isMissingInfoMSGOpen &&
        <MessageConfirmDialog
          isDialogOpen={isMissingInfoMSGOpen}
          theme="warning"
          content={{
            heading: 'Missing-Information',
            body: <MissingInformationErrorMSG/>
          }}
          confirmButton={{
            label: 'Update-unit-details',
            handler: confirmMissingInfoDialog,
          }}
          cancelButton={{
            label: 'discard',
            handler: cancelMissingInfoDialog,
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      }
    </div>
  );
};

