import React, {
  useRef, useState, useEffect, useCallback
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { uploadFile } from '../../Services';
import { InputThemeComponent } from '../../Components/UploaderComponent/UploaderThemes/InputThemeComponent/InputThemeComponent';
import { LoadableDocumentComponent } from '../../Components';
import { getMimeTypeHandler } from '../../Utils';
import {  showError, showSuccess } from '../../Helper';

export const DocFilesUploader = ({
  wrapperClasses,
  uploaderClasses,
  counterClasses,
  inputClasses,
  translationPath,
  parentTranslationPath,
  translationPathShared,
  multiple,
  initUploadedFiles,
  uploadedChanged,
  isDisabled,
  chipsDisabled,
  chipHandler,
  viewUploadedFilesCount,
  accept,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const uploadRef = useRef(null);
  const [allDocFiles, setAllDocFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);

  const uploadHandler = (files, index) => {
    files.map((item) => {
      uploadFile({ file: item.file }).then((response) => {
        uploadedFiles.push(response);
        const localUploadedFiles = uploadedFiles || [{ ...response }];
        setUploadedFiles(localUploadedFiles);
        uploadedChanged(localUploadedFiles);
        setAllDocFiles((items) => {
          const fileIndex = items.findIndex((element) => element.id === item.id);
          if (fileIndex !== -1) {
            items[fileIndex].uuid = response.uuid;
            items[fileIndex].status = 'success';
          }
          return [...items];
        });
      })
        .catch(() => {
          setAllDocFiles((items) => {
            const fileIndex = items.findIndex((element) => element.id === item.id);
            items[fileIndex].status = 'failed';
            return [...items];
          });
        })
    });
  };

  const dropHandler = (event) => {
    event.preventDefault();
    if (isDisabled) return;
    if (!event.dataTransfer.files) return;

    setIsDragOver(false);
    let files = [];

    Object.values(event.dataTransfer.files).forEach(async (file) => {
      const isDocFile = file && file.name && getMimeTypeHandler(file.name).isFile === true;

      if (isDocFile) {
        files.push({
          id: allDocFiles.length + files.length,
          uuid: null,
          fileName: file.name,
          size: file.size,
          type: file.type,
          file,
          status: 'uploading',
        });

      } else showError(t(`${translationPathShared}files-that-are-not-doc-cannot-be-uploaded`));
    });
    setAllDocFiles((items) => [...items, ...files]);
    uploadHandler(files);

  };

  const fileDeleted = useCallback(
    (item, index) => () => {
      const uploadedFilesIndex = uploadedFiles.findIndex((element) => element.uuid === item.uuid);
      if (uploadedFilesIndex !== -1) {
        const localFiles = [...uploadedFiles];
        localFiles.splice(uploadedFilesIndex, 1);
        uploadedChanged(localFiles);
        setUploadedFiles(localFiles);
      }
      const localFiles = [...uploadedFiles];
      localFiles.splice(uploadedFilesIndex, 1);
      setUploadedFiles(localFiles);
      setAllDocFiles((items) => {
        items.splice(index, 1);
        return [...items];
      });
    },
    [uploadedChanged, uploadedFiles]
  );

  const inputChanged = (event) => {
    if (!event.target.value) return;
    let files = [];

    Object.values(event.target.files).forEach((file) => {
      const isDocFile = file && file.name && getMimeTypeHandler(file.name).isFile === true;
      if (isDocFile) {
        files.push({
          id: allDocFiles.length + files.length,
          uuid: null,
          fileName: file.name,
          size: file.size,
          type: file.type,
          file,
          status: 'uploading',
        });

      } else showError(t(`${translationPathShared}files-that-are-not-doc-cannot-be-uploaded`));
    });
    uploadHandler(files);
    setAllDocFiles((items) => [...items, ...files]);
    event.target.value = null;
  };

  const removeDocClicked = (item) => {

    const removedFileIndex = uploadedFiles.findIndex((element) => element.uuid === item.uuid);
    if (removedFileIndex !== -1) {
      const localUploadedFiles = [...uploadedFiles];
      localUploadedFiles.splice(removedFileIndex, 1);
      uploadedChanged(localUploadedFiles);
      setUploadedFiles(localUploadedFiles);
    }
    const localUploadedFiles = [...uploadedFiles];
    localUploadedFiles.splice(removedFileIndex, 1);
    setUploadedFiles(localUploadedFiles);

    const localAllDocFiles = allDocFiles.filter((element) => element.uuid !== item.uuid);
    setAllDocFiles(localAllDocFiles);
  };



  useEffect(() => {
    if (initUploadedFiles && initUploadedFiles.length > 0 && allDocFiles&& allDocFiles.length == 0) {
      setUploadedFiles(initUploadedFiles);
      const initUploadedDocFiles = initUploadedFiles.filter(item => {
        const isDocFile = item.fileName && getMimeTypeHandler(item.fileName).isFile === true
        return isDocFile
      })
      setAllDocFiles(initUploadedDocFiles);
    }
  }, [initUploadedFiles]);


  return (
    <div className={wrapperClasses}>
      <input
        ref={uploadRef}
        type='file'
        className={inputClasses}
        multiple={multiple}
        accept={accept}
        onChange={inputChanged}
        disabled={isDisabled}
      />
      <div
        className={uploaderClasses}
        onDragOver={(event) => {
          event.preventDefault();
          if (isDisabled) return;
          if (!isDragOver) setIsDragOver(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setIsDragOver(false);
        }}
        onDrop={dropHandler}
      >
        <InputThemeComponent
          allFiles={allDocFiles}
          isDragOver={isDragOver}
          translationPathShared={translationPathShared}
          fileDeleted={fileDeleted}
          chipClicked={chipHandler}
          uploadRef={uploadRef}
          multiple={multiple}
          accept={accept}
          isDisabled={isDisabled}
          chipsDisabled={chipsDisabled}
        />

        {viewUploadedFilesCount && (
          <span className={counterClasses}>
            {`${allDocFiles.length} ${t(
              `${translationPathShared}files-uploaded`)
              }`}
          </span>
        )}
      </div>
      <div className='GalleryComponent'>
        {allDocFiles.length > 0 &&
          allDocFiles.map((item) => (
            <div className='doc-file-card document-card'>
              <div className='d-flex'>
                <LoadableDocumentComponent
                  classes='doc-image'
                  fileName={item.fileName}
                  alt={t(`${translationPath}cover-image`)}
                />
                <div className='doc-info'>
                  <span>{item.fileName}</span>
                  <div className='file-icons-container'>
                    <span
                      className='mdi mdi-delete-circle-outline c-warning delete-button'
                      onClick={() => removeDocClicked(item)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}

      </div>
    </div>
  );
};
DocFilesUploader.propTypes = {
  initUploadedFiles: PropTypes.instanceOf(Array),
  wrapperClasses: PropTypes.string,
  uploaderClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  translationPath: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  translationPathShared: PropTypes.string,
  multiple: PropTypes.bool,
  chipsDisabled: PropTypes.func,
  chipHandler: PropTypes.func,
  Disabledimg: PropTypes.func,
  uploadedChanged: PropTypes.func,
  isDisabled: PropTypes.bool,
  dropHereText: PropTypes.string,
  accept: PropTypes.string,
};
DocFilesUploader.defaultProps = {
  initUploadedFiles: [],
  counterClasses: 'counter-text',
  wrapperClasses: 'uploader-wrapper',
  uploaderClasses: 'uploader-container',
  inputClasses: 'file-input',
  translationPath: '',
  parentTranslationPath: '',
  translationPathShared: 'Shared:uploaderComponent.',
  multiple: false,
  chipsDisabled: () => false,
  chipHandler: undefined,
  Disabledimg: () => false,
  uploadedChanged: undefined,
  isDisabled: false,
  dropHereText: 'drop-here',
  accept: "application/pdf,application/doc,application/ms-doc,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
};

