import React, {
    useCallback, useEffect, useRef, useState
} from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Spinner, Tables, Inputs, AutocompleteComponent, SelectComponet } from '../../../Components';
import { ProcessReportBuilderLogStatusEnum, TableActions } from '../../../Enums';
import { GetAllSystemReportBuilderLogs, GetAllSystemReportCategoryType } from '../../../Services/ReportsBuilderServices';
import { useTitle } from '../../../Hooks';
import { ReportProcessDetailsDialog } from './ReportProcessDetailsDialog'
import ReportRunDialog from './ReportRunDialog';
import { DateRangePickerComponent } from '../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';

export const ReportBuilderLogView = () => {
    const parentTranslationPath = 'ReportBuilderLogView';
    const translationPath = '';
    const { t } = useTranslation(parentTranslationPath);
    useTitle(t(`${translationPath}report-builder-log`));
    const searchTimer = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [sortBy, setSortBy] = useState(null);
    const dateRangeDefault = {
        startDate: null,
        endDate: null,
        key: 'selection',
    };
    const [dateFilter, setDateFilter] = useState(dateRangeDefault);
    const [reportBuilderLogData, setReportBuilderLogData] = useState({
        result: [],
        totalCount: 0,
    });
    const [reportCategoryType, setReportCategoryType] = useState([]);
    const [openProcessDialog, setOpenProcessDialog] = useState(false);
    const [openReportRunDialog, setOpenReportRunDialog] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const [tableActions, setTableActions] = useState([]);

    const [orderBy, setOrderBy] = useState({ filterBy: null, orderBy: null });
    const [state, setState] = useState({
        staffID: null,
        reportCategoryTypeId: null,
        userName: null,
    });
    const [filter, setFilter] = useState({
        pageIndex: 0,
        pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
        orderBy: ((orderBy && orderBy.orderBy) || 2),
        filterBy: ((orderBy && orderBy.filterBy) || null),
        staffID: null,
        reportCategoryTypeId: null,
        userName: null,
    });

    const headerData = [
        {
            id: 1,
            label: 'Id',
            input: 'id',
        },
        {
            id: 2,
            isSortable: true,
            label: 'ReportName',
            input: 'reportName',
        },
        {
            id: 3,
            isSortable: true,
            label: 'CategoryType',
            input: 'reportCategoryTypeId',
            component: (item) => (<span>{item?.reportCategoryTypeName || ''}</span>),
        },
        {
            id: 4,
            isSortable: true,
            label: 'CreatedBy',
            input: 'createdByName',
        },
        {
            id: 5,
            isSortable: true,
            label: 'Date',
            input: 'createdOn',
            isDate: true,
            dateFormat: 'DD/MM/YYYY',
        },
        {
            id: 6,
            label: 'Time',
            input: 'createdOn',
            isDate: true,
            dateFormat: 'hh:mm A',
        },
        {
            id: 7,
            isSortable: true,
            label: 'StaffID',
            input: 'staffID',
        },
        {
            id: 8,
            isSortable: true,
            label: 'Process',
            input: 'process',
            component: (item) => (<span>{ProcessReportBuilderLogStatusEnum[item.process].name || item?.processName || ''}</span>),
        },
    ];

    const focusedRowChanged = (rowIndex, item) => {

        const localTableActions = [];
        if (item && item.isHasCriteria)
        localTableActions.push({ enum: TableActions.viewProcess.key });

        if(item && item.reportId) 
        localTableActions.push({ enum: TableActions.RunText.key });

        setTableActions(localTableActions)
    };

    const tableActionClicked = useCallback(
        (actionEnum, item, focusedRow, event) => {
            event.stopPropagation();
            event.preventDefault();
            if (actionEnum === TableActions.viewProcess.key) {
                setOpenProcessDialog(true);
                setActiveItem(item)
            }
            if (actionEnum === TableActions.RunText.key) {
                setOpenReportRunDialog(true);
                setActiveItem(item)
            }
        },
        []
    );

    const getReportBuilderLog = useCallback(async () => {
        setIsLoading(true);
        const res = await GetAllSystemReportBuilderLogs({ ...filter, pageIndex: filter.pageIndex + 1 });
        if (!(res && res.status && res.status !== 200)) {
            setReportBuilderLogData({
                result: (res?.result || []),
                totalCount: (res?.totalCount || 0),
            });
        } else {
            setReportBuilderLogData({
                result: [],
                totalCount: 0,
            });
        }
        setIsLoading(false);

    }, [filter, orderBy]);

    const getReportCategoryType = useCallback(async () => {
        const res = await GetAllSystemReportCategoryType({ ...filter, pageIndex: filter.pageIndex + 1 });
        if (!(res && res.status && res.status !== 200)) {
            setReportCategoryType(res || []);
        } else {
            setReportCategoryType([]);
        }

    }, [filter, orderBy]);


    const onPageIndexChanged = (pageIndex) => {
        setFilter((item) => ({ ...item, pageIndex }));
    };

    const onPageSizeChanged = (pageSize) => {
        setFilter((item) => ({
            ...item, pageIndex: 0, pageSize
        }));
    };

    useEffect(() => {
        getReportBuilderLog();
    }, [filter, getReportBuilderLog]);

    useEffect(() => {
        getReportCategoryType();
    }, []);


    useEffect(() => {
        if (sortBy)
            setFilter((item) => ({ ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy }));
    }, [sortBy]);


    return (
        <div className='view-wrapper'>
            <Spinner isActive={isLoading} />

            <div className='flex-v-end d-flex-column mx-5 mb-5'>

                <div className='d-flex w-50'>
                    <div className='w-50 mr-1-reversed'>
                        <Inputs
                            idRef='userNameFilterRef'
                            fieldClasses='inputs theme-solid'
                            parentTranslationPath={parentTranslationPath}
                            value={state.userName}
                            onInputChanged={(event) => {
                                const { value } = event.target;
                                setState({
                                    ...state,
                                    userName: value || null,
                                });
                            }}
                            onKeyUp={(event) => {
                                if (searchTimer.current) clearTimeout(searchTimer.current);
                                const newValue = event.target.value;
                                searchTimer.current = setTimeout(() => {
                                    setFilter({
                                        ...filter,
                                        userName: newValue || null,
                                    });
                                }, 700);
                            }}
                            inputPlaceholder={'createdBy'}
                        />
                    </div>

                    <div className='w-50 mr-2'>
                        <SelectComponet
                            data={reportCategoryType}
                            value={filter.reportCategoryTypeId || 0}
                            emptyItem={{
                                value: 0,
                                text: 'Report Category',
                                isDisabled: false,
                            }}
                            valueInput='systemReportCategoryTypeId'
                            textInput='systemReportCategoryTypeName'
                            onSelectChanged={(newValue) => {
                                setState({
                                    ...state,
                                    reportCategoryTypeId: newValue || null,
                                });
                                setFilter({
                                    ...filter,
                                    reportCategoryTypeId: newValue || null,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className='d-flex w-50 '>
                    <div className='w-50 mr-1-reversed'>
                        <Inputs
                            idRef='staffIDRef'
                            fieldClasses='inputs theme-solid'
                            label={'staffID'}
                            parentTranslationPath={parentTranslationPath}
                            value={state.staffID}
                            translationPath={translationPath}
                            onInputChanged={(event) => {
                                const value = event.target.value;
                                setState({
                                    ...state,
                                    staffID: value || null,
                                });
                            }}
                            onKeyUp={(event) => {
                                if (searchTimer.current) clearTimeout(searchTimer.current);
                                const newValue = event.target.value;
                                searchTimer.current = setTimeout(() => {
                                    setFilter({
                                        ...filter,
                                        staffID: newValue || null,
                                    });
                                }, 700);
                            }}
                        />
                    </div>
                    <div className='w-100 filter-section-date-picker'>

                        <DateRangePickerComponent
                            onClearClicked={() => {
                                setDateFilter(dateRangeDefault);
                                setFilter((item) => ({
                                    ...item,
                                    fromDate: null,
                                    toDate: null
                                }));
                            }}
                            ranges={[dateFilter]}
                            parentTranslationPath={parentTranslationPath}
                            translationPath={translationPath}
                            onDateChanged={(selectedDate) => {
                                setDateFilter({
                                    startDate: selectedDate.selection && selectedDate.selection.startDate,
                                    endDate: (selectedDate.selection && selectedDate.selection.endDate),
                                    key: 'selection',
                                });
                                setFilter((item) => ({
                                    ...item,
                                    fromDate: (moment(selectedDate.selection && selectedDate.selection.startDate).add(24, 'hours')),
                                    toDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                                }));
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='d-flex-column'>

                <div className='w-100 px-3'>
                    <Tables
                        data={reportBuilderLogData.result || []}
                        headerData={headerData}
                        defaultActions={tableActions||[]}
                        focusedRowChanged={focusedRowChanged}
                        onPageIndexChanged={onPageIndexChanged}
                        onPageSizeChanged={onPageSizeChanged}
                        actionsOptions={{
                            onActionClicked: tableActionClicked,
                        }}
                        FilterDisabledButton
                        itemsPerPage={filter.pageSize}
                        activePage={filter.pageIndex}
                        translationPath={translationPath}
                        parentTranslationPath={parentTranslationPath}
                        totalItems={reportBuilderLogData.totalCount}
                        setSortBy={setSortBy}
                    />
                </div>
            </div>
            {
                openProcessDialog && (
                    <ReportProcessDetailsDialog
                        open={openProcessDialog}
                        logId={activeItem?.id}
                        close={() => {
                            setOpenProcessDialog(false);
                            setActiveItem(null);
                        }}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                    />
                )}
           {openReportRunDialog && (
            <ReportRunDialog
            isOpen={openReportRunDialog}
            close={() => {
                setOpenReportRunDialog(false);
                setActiveItem(null);
            }}
            reportId={activeItem?.reportId}
            onCloseClicked={()=> setOpenReportRunDialog(false)}
            />
                )}
        </div>
    );
};
