import React, { useEffect } from 'react';
import { SwitchRoute } from '../../../Components/Route/SwitchRoute';
import { LeadOwnerUnitSaleRoutes  } from '../../../routes';

export const LeadOwnerUnitSaleLayout = () => {
  useEffect(() => {
    return () => {
      localStorage.removeItem("leadOwnerUnitsaleCurrentPageIndex");
    }
  }, []);

  return (
    <SwitchRoute routes={LeadOwnerUnitSaleRoutes} />
  );
};

