import moment from 'moment';

export function ConstructionDateRule(id, item, itemList, value, setData, formType) {
  if (id === 'construction_date') {
    const launchDateIndex = itemList.indexOf(
      itemList.find((f) => f.field.id.toLowerCase() === 'launch_date')
    );
    const handoverDateIndex = itemList.indexOf(
      itemList.find((f) => f.field.id.toLowerCase() === 'handover_date')
    );
    if (formType === 1) {
      if (launchDateIndex !== -1) {
        itemList[launchDateIndex].data.minDate = moment(value);
        setData('launch_date', {});
      }
      if (handoverDateIndex !== -1) {
        itemList[handoverDateIndex].data.minDate = moment(value);
        setData('handover_date', {});
      }


    } else if (formType === 2) {
      if (launchDateIndex !== -1) {
        itemList[launchDateIndex].data.minDate = moment(value);
        setData(null, 0, 'launch_date');
      }
      if (handoverDateIndex !== -1) {
        itemList[handoverDateIndex].data.minDate = moment(value);
        setData(null, 0, 'handover_date');
      }
    }

  }

}

export const ConstructionDateDefault = (id, itemList, value, onValueChanged, allItemsValues) => {
  if (id === 'construction_date') {

    const launchDateIndex = itemList.indexOf(
      itemList.find((f) => f.field.id.toLowerCase() === 'launch_date')
    );
    const handoverDate = itemList.indexOf(
      itemList.find((f) => f.field.id.toLowerCase() === 'handover_date')
    );

    if (launchDateIndex !== -1) {
      itemList[launchDateIndex].data.minDate = moment(value);
      // onValueChanged(moment(allItemsValues&&allItemsValues.launch_date),0, 'launch_date') ; 
    }
    if (handoverDate !== -1) {
      itemList[handoverDate].data.minDate = moment(value);
      // onValueChanged(moment(allItemsValues&&allItemsValues.handover_date),0, 'handover_date') ; 
    }
  }
}





