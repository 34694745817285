import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    popup: {
      padding: "64px 0 80px",
      zIndex: 10,
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    popupContent: {
      backgroundColor: theme.palette.background.primary,
      width: "100%",
      height: "100%",
      padding: "16px 24px 0",
      overflowY: "auto",
    },
  };
});
