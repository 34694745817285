import {  ZeroMatchingLeaseInformationComponent  } from './ZeroMatchingLeaseInformationComponent' ; 
import { AssignInquiryToUnitComponent  } from '../../../ListingShortage/ListingShortageUtilities/Sections' ; 
import { ZeroMatchingLeasePermissions } from '../../../../../../Permissions' ; 
import { InquiryAssignmentTrack } from '../../../../../../Layouts';



export const ZeroMatchingLeaseVerticalTabsData = [
    {
      label: 'Inquiries-information',
      component: ZeroMatchingLeaseInformationComponent,
      permissionsList: Object.values(ZeroMatchingLeasePermissions),
      permissionsId: ZeroMatchingLeasePermissions.ViewInquiry.permissionsId,
      
    },
    {
      label: 'Assigned-Units',
      component: AssignInquiryToUnitComponent ,
      permissionsList: Object.values(ZeroMatchingLeasePermissions),
      permissionsId: ZeroMatchingLeasePermissions.ViewInquiry.permissionsId,
    },
    {
      label: 'inquiry_assignment',
      component: InquiryAssignmentTrack,
      permissionsList: Object.values(ZeroMatchingLeasePermissions),
      permissionsId: ZeroMatchingLeasePermissions.ViewInquiryAssignmentTracker.permissionsId,
    }
  
  ];