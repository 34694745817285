import {
  AgentInfoSaleRelatedComponent,
  ContactInfoSaleRelatedComponent,
  DetailsSaleRelatedComponent,
  InvoiceSaleRelatedComponent,
  SummarySaleRelatedComponent,
  UnitInformationComponent,
  UnitDocumentsComponent
} from '../Sections';

export const UnitsSaleRelatedSteps = [
  { label: 'unit-info', component: UnitInformationComponent },
  { label: 'unit-documents', component: UnitDocumentsComponent },
  { label: 'agent-info', component: AgentInfoSaleRelatedComponent },
  { label: 'contact-info', component: ContactInfoSaleRelatedComponent },
  { label: 'details', component: DetailsSaleRelatedComponent },
  { label: 'invoice-description', component: InvoiceSaleRelatedComponent },
  { label: 'summary', component: SummarySaleRelatedComponent },
];
