import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { ButtonBase } from '@material-ui/core';
import moment from 'moment';
import {
  Spinner,
  ViewTypes,
  NoContentComponent,
  SelectComponet,
  Inputs,
  AutocompleteComponent,
} from '../../../Components';
import {
  ViewTypesEnum,
  LeadTypeIdEnum,
  LeadsStatusEnum,
  LeadsClassTypesEnum,
} from '../../../Enums';
import { UnqualifiedLeadCardsComponent } from './UnqualifiedLeadUtitities/UnqualifiedLeadCardsComponent';
import {
  ReassignLeads,
  GetAllUnqualifiedLeads,
  CloseListOfLeads,
  lookupItemsGetId,
  OrganizationUserSearch,
} from '../../../Services';
import { UnqualifiedLeadTableComponent } from './UnqualifiedLeadUtitities/UnqualifiedLeadTableComponent';
import { ActiveItemActions } from '../../../store/ActiveItem/ActiveItemActions';
import { ActionsEnum } from '../../../Enums/Actions.Enum';
import {
  GlobalHistory,
  sideMenuComponentUpdate,
  sideMenuIsOpenUpdate,
  showSuccess,
  showError,
  showWarn,
  getIsAllowedPermission,
} from '../../../Helper';
import { bottomBoxComponentUpdate } from '../../../Helper/Middleware.Helper';
import { PaginationComponent } from '../../../Components/PaginationComponent/PaginationComponent';
import { LeadsReassignDialog } from './UnqualifiedLeadUtitities/LeadsReassignDialog';
import { useTitle } from '../../../Hooks';
import { UnqualifiedDetailsTab } from './UnqualifiedLeadUtitities/UnqualifiedDetailsTab';
import { CloseLeadsDialog } from '../LeadsView/LeadsUtilities/Dialogs/CloseLeadsDialog/CloseLeadsDialog';
import { DateRangePickerComponent } from '../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import { GetAllActivityTypes } from '../../../Services/ActivitiesTypesServices';
import { config } from '../../../config';
import { object } from 'joi';
import { SendToLeadsPool } from '../LeadsView/LeadsUtilities/Dialogs/SendToLeadsPool/SendToLeadsPool';
import { LeadOwnerUnqualifiedLeadsPermissions } from '../../../Permissions';
import LeadsPoolProvider from '../LeadsPool/LeadsPoolContext';

const parentTranslationPath = 'UnqualifiedLeadView';
const translationPath = '';

export const UnqualifiedLeadView = () => {
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t(`${translationPath}UnqualifiedLeadView`));
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const pathName =
    window.location.pathname.split('/home/')[1] || 'undefined page 404';
  const { loginResponse } = useSelector((state) => state.login);

  const session = JSON.parse(localStorage.getItem('session'));
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const [Datee, setDatee] = useState({ result: [], totalCount: 0 });
  const [activeActionType, setActiveActionType] = useState(
    ViewTypesEnum.cards.key
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isDatePickerChanged, setIsDatePickerChanged] = useState(false);
  const [DatePickerType, setDatePickerType] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [isOpenReassign, setIsOpenReassign] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [SelectedAction, setSelectedAction] = useState({ action: null });
  const [leadStatus, setLeadStatus] = useState({
    open: LeadsStatusEnum.Open.status,
    closed: LeadsStatusEnum.Closed.status,
  });
  const [leadType, setLeadType] = useState({
    owner: LeadTypeIdEnum.Owner.leadTypeId,
    seeker: LeadTypeIdEnum.Seeker.leadTypeId,
  });

  const defaultState = {
    pageIndex: 0,
    // eslint-disable-next-line radix
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    leadOwnerId:
      pathName !== 'Unqualified-Lead' ? session && session.userId : null,
    leadId: null,
    leadStatusId: null,
    contactName: null,
    emailAddress: null,
    moblieNumber: null,
    mediaDetails: null,
    referredbyId: null,
    leadTypeId: null,
    filterBy: null,
    orderBy: 1,
    creationFromDate: null,
    creationToDate: null,
    updatedFromDate: null,
    updatedToDate: null,
    reasonId: null,
    numberOfUnqualifiedLead: null,
    lastActivityTypeId: null,
    leadClassId: null,
    unqualifiedLeadBy: null,
    contactPreference: null,
    remarks: null,
    reasonName: null,
    creationDate: null,
    unqualifiedDate: null,
  };
  const [status, setStatus] = useState(0);
  const [type, setType] = useState(0);
  const [leadId, setLeadId] = useState(null);

  const [activeFormType, setActiveFormType] = useState(0);
  const [leadTypesClass, setLeadTypesClass] = useState(0);
  const [DataMediaDetails, setDataMediaDetails] = useState([]);
  const [state, setState] = useState(defaultState);
  const [allReferred, setAllReferred] = useState([]);
  const [SelectedReferred, setSelectedReferred] = useState([]);
  const [UnqualifiedActivityName, setUnqualifiedActivityName] = useState([]);
  const [selectLeadsByLeadType, setSelectLeadsByLeadType] = useState(null);

  const [filter, setFilter] = useState({
    pageIndex: 0,
    // eslint-disable-next-line radix
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    leadOwnerId:
      pathName !== 'Unqualified-Lead' ? session && session.userId : null,
    leadId: null,
    leadStatusId: null,
    referredbyId: null,
    leadTypeId: null,
    contactName: null,
    emailAddress: null,
    moblieNumber: null,
    mediaDetails: null,
    orderBy: 1,
    filterBy: null,
    creationFromDate: null,
    creationToDate: null,
    updatedFromDate: null,
    updatedToDate: null,
    reasonId: null,
    numberOfUnqualifiedLead: null,
    lastActivityTypeId: null,
    leadClassId: null,
    unqualifiedLeadBy: null,
    contactPreference: null,
    remarks: null,
    reasonName: null,
    creationDate: null,
    unqualifiedDate: null,
  });
  const [selectedLeadIds, setselectedLeadIds] = useState([]);
  const [selectedLeadItem, setselectedLeadItem] = useState([]);
  const [sendToLeadsPoolDialog, setSendToLeadsPoolDialog] = useState(false);

  const GetAllUnqualifiedLeadsAPi = useCallback(async () => {
    setIsLoading(true);
    const result = await GetAllUnqualifiedLeads({
      ...filter,
      pageIndex: filter.pageIndex + 1,
      leadOwnerId: filter.leadOwnerId,
    });
    if (!(result && result.status && result.status !== 200))
      setDatee({ result: result.result, totalCount: result.totalCount || 0 });
    else setDatee({ result: [], totalCount: 0 });
    setIsLoading(false);
  });

  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    // dispatch(
    //   GlobalOrderFilterActions.globalOrderFilterRequest({
    //     ...orderFilter,
    //     listingShortage: {
    //       ...orderFilter.listingShortage,
    //       filterBy: selectedOrderBy.filterBy,
    //       orderBy: selectedOrderBy.orderBy,
    //     },
    //   })
    // );
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
    // setFilter((item) => ({ ...item, orderBy: selectedOrderBy.orderBy, filterBy  : selectedOrderBy.filterBy,}));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };

  const getAllMediaDetails = useCallback(async (mediaTypeId) => {
    const res = await lookupItemsGetId({
      lookupTypeId: 1240,
      lookupParentId: mediaTypeId,
    });
    if (!(res && res.status && res.status !== 200))
      setDataMediaDetails((item) => ({ ...item, mediaDetails: res }));
    else setDataMediaDetails((item) => ({ ...item, mediaDetails: [] }));
  }, []);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };

  const detailedCardSideActionClicked = useCallback(
    (actionEnum, activeData) => async (event) => {
      dispatch(ActiveItemActions.activeItemRequest(activeData));

      event.stopPropagation();
      if (actionEnum === ActionsEnum.reportEdit.key) {
        GlobalHistory.push(
          `/home/leads/lead-profile-edit?formType=${
            activeData.leadType === 'Buyer' || activeData.leadType === 'Tenant'
              ? leadType.seeker
              : activeData.leadType === 'Landlord' ||
                activeData.leadType === 'Seller'
              ? leadType.owner
              : 0
          }&id=${activeData.leadId}&leadClass=${activeData.leadType}`
        );
      } else if (actionEnum === ActionsEnum.reassignAgent.key) {
        setIsOpenReassign(true);
        setActiveItem(activeData);
        setselectedLeadItem([activeData]);
      }
    },
    [dispatch, pathName]
  );
  const getAllReferred = useCallback(async (searchValue) => {
    //  setIsLoading(true);
    //setIsLoading((item) => ({ ...item, referred: true }));
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchValue, //userStatusId: Status.Active.value
    });
    if (!(res && res.status && res.status !== 200)) {
      setAllReferred((item) => ({
        ...item,
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      }));
    } else {
      setAllReferred((item) => ({
        ...item,
        result: [],
        totalCount: 0,
      }));
    }
    // //setIsLoading(false);
    //setIsLoading((item) => ({ ...item, referred: false }));
  }, []);
  const changeStatusType = (value) => {
    setStatus(value);
    setFilter({
      ...filter,
      leadStatusId: value || null,
    });
    // dispatch(
    //   GlobalOrderFilterActions.globalOrderFilterRequest({
    //     ...orderFilter,
    //     StatusTypeLeadTypeLeadOwnerFilter: value
    //   })
    // );
  };
  const changeLeadType = (value) => {
    setLeadTypesClass(value);
    setFilter({
      ...filter,
      leadClassId: value || null,
    });
  };

  const changeActiveFormType = (value) => {
    setActiveFormType(value);
    setFilter({
      ...filter,
      leadTypeId: value || null,
    });
    // onFilterFormTypeSelectChanged(value);
    // dispatch(
    //   GlobalOrderFilterActions.globalOrderFilterRequest({
    //     ...orderFilter,
    //     StatusActiveFormTypeLeadTypeFilter: value
    //   })
    // );
  };

  const onTypeChanged = useCallback(
    (activeType) => {
      setActiveActionType(activeType);
    },
    [setActiveActionType]
  );

  const onCardClick = useCallback(
    (item, selectedIndex) => (event) => {
      setActiveItem(item);
      event.stopPropagation();
      event.preventDefault();
      setActiveCard(item);
      sideMenuComponentUpdate(
        <UnqualifiedDetailsTab
          activeData={item}
          cardDetailsActionClicked={detailedCardSideActionClicked}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      );
      sideMenuIsOpenUpdate(true);
    },
    [detailedCardSideActionClicked]
  );

  const cardCheckboxClicked = useCallback(
    (itemIndex, element) => {
      setActiveItem(element);
      if (
        SelectedAction &&
        SelectedAction.action !== 'Close' &&
        element &&
        selectLeadsByLeadType === null
      )
        setSelectLeadsByLeadType(element && element.leadType);
      else if (
        SelectedAction &&
        SelectedAction.action !== 'Close' &&
        selectLeadsByLeadType &&
        element &&
        element.leadType &&
        element.leadType !== selectLeadsByLeadType
      ) {
        showWarn(
          t(`${translationPath}please-select-same-leadType-for-all-leads`)
        );
        return;
      }
      setselectedLeadItem((items) => {
        const index = items.findIndex((item) => item.leadId === element.leadId);
        if (index !== -1) items.splice(index, 1);
        else items.push(element);
        return [...items];
      });
      setselectedLeadIds((items) => {
        const index = items.findIndex((item) => item === element.leadId);
        if (index !== -1) items.splice(index, 1);
        else items.push(element.leadId);
        return [...items];
      });
    },
    [selectLeadsByLeadType]
  );

  const getActivityType = useCallback(async () => {
    const res = await GetAllActivityTypes({
      pageIndex: 1,
      pageSize: 100000,
      categoryId: config.ActivityTypesCategoryId,
    });
    if (!(res && res.status && res.status !== 200)) {
      setUnqualifiedActivityName((res && res.result) || []);
    } else {
      setUnqualifiedActivityName([]);
    }
  });

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={Datee.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });
  useEffect(() => {
    GetAllUnqualifiedLeadsAPi();
  }, [filter]);

  useEffect(() => {
    getAllMediaDetails();
  }, []);

  useEffect(() => {
    if (selectedLeadIds && selectedLeadIds.length === 0)
      setSelectLeadsByLeadType(null);
  }, [selectedLeadIds]);

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );
  const [sortBy, setSortBy] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const searchTimer = useRef(null);

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
  };
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);

  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: (orderFilter.qaFilter && orderFilter.qaFilter.filterBy) || null,
    orderBy: (orderFilter.qaFilter && orderFilter.qaFilter.orderBy) || null,
  });

  /// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// leadOwnerId

  useMemo(() => {
    if (DatePickerType === 1) {
      setFilter((item) => ({
        ...item,
        creationFromDate: dateFilter.startDate,
        creationToDate: dateFilter.endDate,
      }));
    } else if (DatePickerType === 2) {
      setFilter((item) => ({
        ...item,
        updatedFromDate: dateFilter.startDate,
        updatedToDate: dateFilter.endDate,
      }));
    }
  }, [dateFilter]);

  const [isLoadingReassign, setIsLoadingReassign] = useState(false);

  const filterByChanged = (value) => {
    setSelectedAction((item) => ({ ...item, action: value }));
    setselectedLeadIds([]);
    setSelectLeadsByLeadType(null);
  };

  const selectfilterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };

  const reassignHandler = async (reassignItem) => {
    setFilter((item) => ({ ...item, pageIndex: 0, leadStatus: null }));
    setIsOpenReassign(false);
    showSuccess(t(`${translationPath}leads-reassign-notification`));
    const obj = {
      leadIds: [activeItem && activeItem.leadId],
      referredToId: reassignItem.referredToId,
      isCopyTo: reassignItem.isCopyTo,
    };
    await ReassignLeads(obj, pathName !== 'Unqualified-Lead' ? false : true);
  };
  useEffect(() => {
    getActivityType();
  }, []);

  useEffect(() => {
    if (orderBy)
      setFilter((item) => ({
        ...item,
        filterBy: orderBy.filterBy,
        orderBy: orderBy.orderBy,
      }));
  }, [orderBy]);
  useEffect(() => {
    if (sortBy)
      setFilter((item) => ({
        ...item,
        filterBy: sortBy.filterBy,
        orderBy: sortBy.orderBy,
      }));
  }, [sortBy]);

  useEffect(() => {
    if (SelectedAction.action === 'Close') {
      setStatus(leadStatus.open);
      setActiveFormType(0);
      setFilter({
        ...filter,
        leadStatusId: leadStatus.open || null,
        leadTypeId: null,
      });
    } else if (SelectedAction.action === 'Reassign-seeker') {
      setStatus(leadStatus.open);
      setActiveFormType(leadType.seeker);
      setFilter({
        ...filter,
        leadStatusId: leadStatus.open || null,
        leadTypeId: leadType.seeker,
      });
    } else if (SelectedAction.action === 'Reassign-owner') {
      setStatus(leadStatus.open);
      setActiveFormType(leadType.owner);
      setFilter({
        ...filter,
        leadStatusId: leadStatus.open || null,
        leadTypeId: leadType.owner,
      });
    } else if (SelectedAction.action === 'send-to-leads-pool') {
      setStatus(leadStatus.open);
      setFilter({
        ...filter,
        leadStatusId: leadStatus.open || null,
        leadTypeId: null,
      });
    }
  }, [SelectedAction]);

  const closeLeads = async (item) => {
    setIsLoading(true);
    const res = await CloseListOfLeads(item);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t('close-lead-notification'));
      setSelectedAction({ action: null });
      setselectedLeadIds([]);
      setSelectLeadsByLeadType(null);
      GetAllUnqualifiedLeadsAPi();
    } else showError(t('Shared:failed-add-Info'));
    setIsLoading(false);
  };

  const Ralode = () => {
    setSelectedAction({ action: null });
    setselectedLeadIds([]);
    setSelectLeadsByLeadType(null);
    setStatus(0);
    setActiveFormType(0);
    setFilter({
      ...filter,
      leadTypeId: null,
    });
    GetAllUnqualifiedLeadsAPi();
  };
  return (
    <div className='view-wrapper QA-view-wrapper'>
      <Spinner isActive={isLoading} />
      <div className='d-flex-column'>
        <div className='header-section'>
          <div className='filter-section'>
            <div className='section' />
            <div className='section autocomplete-section'>
              <div className='d-flex-column px-2 w-100 p-relative'>
                <div className='buttom-filters-section'>
                  <div className='d-flex'>
                    <div className='d-flex w-50 mr-1-reversed'>
                      <Inputs
                        idRef='leadIdFilterRef'
                        fieldClasses='inputs theme-solid'
                        parentTranslationPath={parentTranslationPath}
                        value={state.leadId}
                        onInputChanged={(event) => {
                          const { value } = event.target;
                          setState({
                            ...state,
                            leadId: value || null,
                          });
                        }}
                        onKeyUp={(event) => {
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          const newValue = event.target.value;
                          searchTimer.current = setTimeout(() => {
                            setFilter({
                              ...filter,
                              leadId: (newValue && +newValue) || null,
                            });
                          }, 700);
                        }}
                        inputPlaceholder={t(`${translationPath}leadId`)}
                      />

                      <Inputs
                        idRef='numberOfUnqualifiedLeadFilterRef'
                        fieldClasses='inputs theme-solid'
                        parentTranslationPath={parentTranslationPath}
                        value={state.numberOfUnqualifiedLead}
                        onInputChanged={(event) => {
                          const { value } = event.target;
                          setState({
                            ...state,
                            numberOfUnqualifiedLead: value || null,
                          });
                        }}
                        onKeyUp={(event) => {
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          const newValue = event.target.value;
                          searchTimer.current = setTimeout(() => {
                            setFilter({
                              ...filter,
                              numberOfUnqualifiedLead:
                                (newValue && +newValue) || null,
                            });
                          }, 700);
                        }}
                        inputPlaceholder={t(
                          `${translationPath}Numberofunqualified`
                        )}
                      />
                    </div>
                    <div className='w-50 mr-1-reversed'>
                      <Inputs
                        idRef='leadIdFilterRef'
                        fieldClasses='inputs theme-solid'
                        parentTranslationPath={parentTranslationPath}
                        value={state.contactName}
                        onInputChanged={(event) => {
                          const { value } = event.target;
                          setState({
                            ...state,
                            contactName: value || null,
                          });
                        }}
                        onKeyUp={(event) => {
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          const newValue = event.target.value;
                          searchTimer.current = setTimeout(() => {
                            setFilter({
                              ...filter,
                              contactName: (newValue && newValue) || null,
                            });
                          }, 700);
                        }}
                        inputPlaceholder={t(`${translationPath}contactName`)}
                      />
                    </div>
                    <div className='w-50 mr-1-reversed'>
                      <Inputs
                        idRef='leadIdFilterRef'
                        fieldClasses='inputs theme-solid'
                        parentTranslationPath={parentTranslationPath}
                        value={state.emailAddress}
                        onInputChanged={(event) => {
                          const { value } = event.target;
                          setState({
                            ...state,
                            emailAddress: value || null,
                          });
                        }}
                        onKeyUp={(event) => {
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          const newValue = event.target.value;
                          searchTimer.current = setTimeout(() => {
                            setFilter({
                              ...filter,
                              emailAddress: (newValue && newValue) || null,
                            });
                          }, 700);
                        }}
                        inputPlaceholder={t(`${translationPath}email`)}
                      />
                    </div>
                    <div className='w-50 mr-1-reversed'>
                      <Inputs
                        idRef='leadIdFilterRef'
                        fieldClasses='inputs theme-solid'
                        parentTranslationPath={parentTranslationPath}
                        value={state.moblieNumber}
                        onInputChanged={(event) => {
                          const { value } = event.target;
                          setState({
                            ...state,
                            moblieNumber: value || null,
                          });
                        }}
                        onKeyUp={(event) => {
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          const newValue = event.target.value;
                          searchTimer.current = setTimeout(() => {
                            setFilter({
                              ...filter,
                              moblieNumber: (newValue && newValue) || null,
                            });
                          }, 700);
                        }}
                        inputPlaceholder={t(`${translationPath}phoneNumber`)}
                      />
                    </div>
                    <div className='w-50 mr-1-reversed'>
                      <AutocompleteComponent
                        idRef='Last-Unqualified-Activity-NameRef'
                        inputPlaceholder={t(
                          `${translationPath}Last-Unqualified-Activity-Name`
                        )}
                        //  selectedValues={DataMediaDetails.selectedmediaDetails || []}
                        //  getOptionSelected={(option) => option.lookupItemId === DataMediaDetails?.selectedmediaDetails?.lookupItemId || ''}
                        data={UnqualifiedActivityName || []}
                        displayLabel={(option) =>
                          (option && option.activityTypeName) || ''
                        }
                        chipsLabel={(option) =>
                          (option && option.activityTypeName) || ''
                        }
                        multiple={false}
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          // setDataMediaDetails((item) => ({ ...item, selectedmediaDetails: newValue }));
                          setFilter((item) => ({
                            ...item,
                            lastActivityTypeId:
                              (newValue && newValue.activityTypeId) || '',
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className='d-flex '>
                    <div className='w-30 mr-1-reversed'>
                      <SelectComponet
                        data={Object.values(LeadsClassTypesEnum)}
                        emptyItem={{
                          value: 0,
                          text: 'Lead Type',
                          isDisabled: false,
                        }}
                        value={leadTypesClass}
                        valueInput='key'
                        textInput='name'
                        onSelectChanged={(value) => {
                          changeLeadType(value);
                        }}
                        wrapperClasses='w-auto'
                        themeClass='theme-default'
                        idRef='Lead_Type'
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                      />
                    </div>
                    <div className='w-30 mr-1-reversed'>
                      <SelectComponet
                        isDisabled={
                          SelectedAction.action == 'Reassign-seeker' ||
                          SelectedAction.action == 'Reassign-owner'
                        }
                        data={[
                          { id: leadType.owner, name: 'owner' },
                          { id: leadType.seeker, name: 'seeker' },
                        ]}
                        emptyItem={{
                          value: 0,
                          text: 'select-category',
                          isDisabled: false,
                        }}
                        value={activeFormType}
                        valueInput='id'
                        textInput='name'
                        onSelectChanged={(value) => {
                          changeActiveFormType(value);
                        }}
                        wrapperClasses='w-auto'
                        themeClass='theme-default'
                        idRef='Select_Category'
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                      />
                    </div>

                    <div className='w-40 mr-1-reversed'>
                      <AutocompleteComponent
                        idRef='MediaDetailsRef'
                        // isLoading={isLoading.mediaDetails}
                        inputPlaceholder={t(`${translationPath}mediaDetails`)}
                        selectedValues={
                          DataMediaDetails.selectedmediaDetails || []
                        }
                        getOptionSelected={(option) =>
                          option.lookupItemId ===
                            DataMediaDetails?.selectedmediaDetails
                              ?.lookupItemId || ''
                        }
                        data={DataMediaDetails.mediaDetails || []}
                        displayLabel={(option) =>
                          (option && option.lookupItemName) || ''
                        }
                        chipsLabel={(option) =>
                          (option && option.lookupItemName) || ''
                        }
                        multiple={false}
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          setDataMediaDetails((item) => ({
                            ...item,
                            selectedmediaDetails: newValue,
                          }));
                          setFilter((item) => ({
                            ...item,
                            mediaDetails:
                              (newValue && newValue.lookupItemName) || '',
                          }));
                        }}
                      />
                    </div>
                    <div className='w-30 mr-1-reversed '>
                      <AutocompleteComponent
                        idRef='ReferredByRef'
                        // isLoading={isLoading.referred}
                        inputPlaceholder={t(`${translationPath}ReferredBy`)}
                        selectedValues={SelectedReferred || []}
                        getOptionSelected={(option) =>
                          option.id === SelectedReferred.id || ''
                        }
                        data={(allReferred && allReferred.result) || []}
                        onInputChange={(e) => {
                          if (e && e.target && e.target.value)
                            getAllReferred(e.target.value || '');
                        }}
                        multiple={false}
                        displayLabel={(option) =>
                          (option && option.fullName) || ''
                        }
                        chipsLabel={(option) =>
                          (option && option.fullName) || ''
                        }
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          setSelectedReferred(newValue || []);
                          setFilter((item) => ({
                            ...item,
                            referredbyId: (newValue && newValue.id) || '',
                          }));
                        }}
                      />
                    </div>
                    <div className='w-50 customDatePicker d-flex'>
                      <div className='date-type-select'>
                        <SelectComponet
                          data={[
                            { key: 1, value: `${translationPath}created-date` },
                            {
                              key: 2,
                              value: `${translationPath}update-on-date`,
                            },
                          ]}
                          emptyItem={{
                            value: 0,
                            text: 'select-date',
                            isDisabled: false,
                          }}
                          value={DatePickerType || 0}
                          valueInput='key'
                          textInput='value'
                          isDisabled={isLoading}
                          onSelectChanged={(value) => {
                            setDatePickerType(value);
                            if (value !== 0) {
                              setIsDatePickerChanged(true);
                            }
                            // setDateFilter((f) => ({ ...f, selectedDateType: value }));
                          }}
                          wrapperClasses='w-auto'
                          themeClass='theme-transparent'
                          idRef='Date_Select'
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      </div>
                      <DateRangePickerComponent
                        isDisabled={!isDatePickerChanged}
                        onClearClicked={() => {
                          setDateFilter(dateRangeDefault);
                          setIsDatePickerChanged(true);
                        }}
                        onDialogClose={() => {
                          setIsDatePickerChanged(true);
                        }}
                        ranges={[dateFilter]}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onDateChanged={(selectedDate) => {
                          setDateFilter({
                            startDate:
                              (selectedDate &&
                                selectedDate?.selection &&
                                selectedDate?.selection?.startDate) ||
                              '',
                            endDate:
                              new Date(
                                moment(
                                  selectedDate &&
                                    selectedDate?.selection &&
                                    selectedDate?.selection?.endDate
                                ).endOf('day')
                              ) || '',
                            key: 'selection',
                          });
                        }}
                      />
                    </div>
                    {/* <div className='mr-2'>
                      <SelectComponet
                        data={[{ lookupItemId: true, lookupItemName: t('open') }, { lookupItemId: false, lookupItemName: t('close') }]}
                        defaultValue
                        emptyItem={{
                          value: null,
                          text: t('Activities-Status'),
                          isDisabled: false,
                          // isHiddenOnOpen: true,
                        }}
                        value={filter.isOpen}
                        className='px-2'
                        valueInput='lookupItemId'
                        textInput='lookupItemName'
                        wrapperClasses='w-auto'
                        idRef='Activities_Status'
                        onSelectChanged={changeActivitystatus}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                      />
                    </div>
                    <div className='mr-2'>
                      <SelectComponet
                        data={(activtiesRate && activtiesRate) || []}
                        defaultValue={null}
                        emptyItem={{
                          value: null,
                          text: t('Select-Activities-rate'),
                          isDisabled: false,
                          // isHiddenOnOpen: true,
                        }}
                        value={filter.activityRate || null}
                        className='px-2'
                        valueInput='lookupItemId'
                        textInput='lookupItemName'
                        wrapperClasses='w-auto'
                        idRef='Select_Activities_Rate'
                        onSelectChanged={changeActivityRate}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                      />
                    </div>

                    <div className='mr-2'>
                      <SelectComponet
                        data={(qaUsers && qaUsers) || []}
                        defaultValue={null}
                        emptyItem={{
                          value: null,
                          text: t('Select-QA-Users'),
                          isDisabled: false,
                          // isHiddenOnOpen: true,
                        }}
                        value={filter.userId || null}
                        onSelectChanged={changeQaUsers}
                        className='px-2'
                        valueInput='usersId'
                        textInput='fullName'
                        wrapperClasses='w-auto'
                        idRef='Select_QA_Users'
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                      />
                    </div> */}

                    <div className='buttons mt-1'>
                      {/*
                      <ButtonBase
                        className='btns theme-solid reset'
                        onClick={() => {
                          setDateFilter({
                            startDate: null,
                            endDate: null,
                            key: 'selection',
                          });
                          setFilter(() => ({
                            pageIndex: 0,
                            pageSize: 25,
                            filterBy: null,
                            orderBy: null,
                            activityRate: null,
                            fromDate: null,
                            toDate: null,
                            userId: null,
                            search: '',
                            isOpen: null,
                            leadId: null,
                            contactName: null,
                            contactId: null,
                          }));
                          setState({
                            leadId: '',
                            contactName: '',
                            contactId: '',
                          });
                        }}
                      >
                        <span>{t(`${translationPath}reset`)}</span>
                      </ButtonBase> */}
                    </div>
                  </div>
                </div>
              </div>
              <ViewTypes
                onTypeChanged={onTypeChanged}
                activeTypes={[
                  ViewTypesEnum.tableView.key,
                  ViewTypesEnum.cards.key,
                ]}
                className='mb-3'
              />
            </div>
          </div>
          {/* <div className='d-flex px-2'>
              <span className='mx-2 mt-1'>{t(`${translationPath}Inquires`)}</span>
              <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
              <span className='px-2 d-flex'>
                <span className='texts-large mt-1'>
                  {t(`${translationPath}order-by`)}
                  :
                </span>
                <div className='px-2'>
                  <SelectComponet
                    idRef='filterByRef'
                    data={[
                      { id: 'CreatedOn', filterBy: 'created-on' },
                      { id: 'UpdateOn', filterBy: 'last-updated' },
                    ]}
                    value={selectedOrderBy.filterBy}
                    onSelectChanged={selectfilterByChanged}
                    wrapperClasses='mb-3'
                    isRequired
                    valueInput='id'
                    textInput='filterBy'
                    emptyItem={{
                      value: null,
                      text: 'select-filter-by',
                      isDisabled: false,
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='px-2'>
                  <SelectComponet
                    idRef='orderByRef'
                    data={[
                      { id: 1, orderBy: 'ascending' },
                      { id: 2, orderBy: 'descending' },
                    ]}
                    value={selectedOrderBy.orderBy}
                    onSelectChanged={orderByChanged}
                    wrapperClasses='mb-3'
                    isRequired
                    valueInput='id'
                    textInput='orderBy'
                    emptyItem={{ value: null, text: 'select-sort-by', isDisabled: false }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='mt-1'>
                  <ButtonBase
                    className='btns theme-solid'
                    onClick={orderBySubmitted}
                    id='action_apply'
                    disabled={!selectedOrderBy.filterBy || !selectedOrderBy.orderBy}
                  >
                    <span>{t(`${translationPath}apply`)}</span>
                  </ButtonBase>
                </div>
              </span>
            </div> */}
          <div className='d-flex px-2'>
            <span className='mx-2 mt-1'>{t('action')}</span>
            <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
            <span className='px-2 d-flex'>
              <div className='px-2'>
                <SelectComponet
                  idRef='filterByRef'
                  data={[
                    { id: 'Close', action: t(`${translationPath}Close-Lead`) },
                    {
                      id: 'Reassign-seeker',
                      action: t(`${translationPath}Reassign-seeker`),
                    },
                    {
                      id: 'Reassign-owner',
                      action: t(`${translationPath}Reassign-owner`),
                    },
                    getIsAllowedPermission(
                      Object.values(LeadOwnerUnqualifiedLeadsPermissions),
                      loginResponse,
                      LeadOwnerUnqualifiedLeadsPermissions.SendToTheLeadPool
                        .permissionsId
                    ) && {
                      id: 'send-to-leads-pool',
                      action: t(`${translationPath}send-to-leads-pool`),
                    },
                  ].filter(item=> item)}
                  value={SelectedAction.action}
                  onSelectChanged={filterByChanged}
                  wrapperClasses='mb-3'
                  isRequired
                  valueInput='id'
                  textInput='action'
                  emptyItem={{
                    value: null,
                    text: t(`${translationPath}select-action`),
                    isDisabled: false,
                    isHiddenOnOpen: false,
                  }}
                  translationPathForData={translationPath}
                />
              </div>
              {(SelectedAction.action === 'Reassign-seeker' ||
                SelectedAction.action === 'Reassign-owner') && (
                <div className=''>
                  <ButtonBase
                    className='btns theme-solid'
                    id='replyText-ref'
                    disabled={selectedLeadIds && selectedLeadIds.length === 0}
                    onClick={() => {
                      setIsOpenReassign(true);
                    }}
                  >
                    <span className='mdi mdi-reply-outline' />
                    <span className='px-1'>
                      {t('Reassign')}{' '}
                      {(selectedLeadIds && selectedLeadIds.length) || ''}
                    </span>
                  </ButtonBase>
                </div>
              )}
              {SelectedAction.action === 'Close' && (
                <div className=''>
                  <ButtonBase
                    className='btns theme-solid'
                    id='replyText-ref'
                    disabled={selectedLeadIds && selectedLeadIds.length === 0}
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    <span className='mdi mdi mdi-animation-outline ' />
                    <span className='px-1'>
                      {t(`${translationPath}Close-Lead`)}{' '}
                      {(selectedLeadIds && selectedLeadIds.length) || ''}
                    </span>
                  </ButtonBase>
                </div>
              )}
              {SelectedAction.action === 'send-to-leads-pool' && (
                <div className=''>
                  <ButtonBase
                    className='btns theme-solid'
                    id='replyText-ref'
                    disabled={selectedLeadIds?.length === 0}
                    onClick={() => {
                      setSendToLeadsPoolDialog(true);
                    }}
                  >
                    <span className='mdi mdi mdi-animation-outline ' />
                    <span className='px-1'>
                      {t(`${translationPath}send-to-leads-pool`)}{' '}
                      {selectedLeadIds?.length || ''}
                    </span>
                  </ButtonBase>
                </div>
              )}
              {SelectedAction.action !== null && (
                <div className=''>
                  <ButtonBase
                    className='btns '
                    id='dds-ref'
                    onClick={() => {
                      setSelectedAction({ action: null });
                      setselectedLeadIds([]);
                      setSelectLeadsByLeadType(null);
                      setActiveFormType(0);
                      {
                        setStatus(0);
                        setFilter({
                          ...filter,
                          leadStatusId: null,
                          leadTypeId: null,
                        });
                      }
                    }}
                  >
                    <span className='mdi mdi mdi mdi-close-circle-outline ' />
                    <span className='px-1'>{t('remove')}</span>
                  </ButtonBase>
                </div>
              )}
            </span>
          </div>
        </div>
        <div className='w-100 px-3'>
          {activeActionType === ViewTypesEnum.tableView.key && (
            <UnqualifiedLeadTableComponent
              filter={filter}
              data={Datee.result}
              openFile={detailedCardSideActionClicked}
              parentTranslationPath={parentTranslationPath}
              selectedLeadIds={selectedLeadIds}
              setselectedLeadIds={setselectedLeadIds}
              setselectedLeadItem={setselectedLeadItem}
              setIsOpen={setIsOpen}
              defaultState={defaultState}
              setFilter={setFilter}
              SelectedAction={SelectedAction}
              translationPath={translationPath}
              setIsOpenReassign={setIsOpenReassign}
              setActiveItem={setActiveItem}
              activeItem={activeItem}
              setSelectLeadsByLeadType={setSelectLeadsByLeadType}
              selectLeadsByLeadType={selectLeadsByLeadType}
              onPageIndexChanged={onPageIndexChanged}
            />
          )}
          {Datee && Datee.result && Datee.result.length !== 0 ? (
            activeActionType !== ViewTypesEnum.tableView.key && (
              <UnqualifiedLeadCardsComponent
                data={Datee.result}
                QACardsReload={GetAllUnqualifiedLeadsAPi}
                onFooterActionsClicked={detailedCardSideActionClicked}
                selectedLeadIds={selectedLeadIds}
                setselectedLeadIds={setselectedLeadIds}
                setselectedLeadItem={setselectedLeadItem}
                SelectedAction={SelectedAction}
                setIsOpenC={setIsOpen}
                setIsOpenReassign={setIsOpenReassign}
                setActiveItemCard={setActiveItem}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onCardClicked={onCardClick}
                onCardCheckboxClick={cardCheckboxClicked}
                activeCard={activeCard}
                onPageIndexChanged={onPageIndexChanged}
              />
            )
          ) : (
            <NoContentComponent />
          )}
          {isOpenReassign && (
             <LeadsPoolProvider>
          
            <LeadsReassignDialog
              isOpen={isOpenReassign}
              isQualifiedByQa={pathName !== 'Unqualified-Lead' ? false : true}
              leadType={
                (selectedLeadItem &&
                  selectedLeadItem[0] &&
                  selectedLeadItem[0].leadType) ||
                  (activeItem && activeItem.leadClass) ||
                  (selectedLeadItem && selectedLeadItem.leadType) ||
                  null
                }
                activeItem={activeItem}
                selectedLeadIds={selectedLeadIds}
                Ralode={Ralode}
                setIsLoading={isLoadingReassign}
                setIsLoadingReassign={setIsLoadingReassign}
                onSave={(reassignItem) => {
                  reassignHandler(reassignItem);
                  setActiveItem(null);
                  setIsOpenReassign(false);
                  setActiveItem(null);
                  setselectedLeadItem([]);
                  setselectedLeadIds([]);
                  setSelectLeadsByLeadType(null);
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onClose={() => {
                  setIsOpenReassign(false);
                  setActiveItem(null);
                  setselectedLeadItem([]);
                  setselectedLeadIds([]);
                  setSelectLeadsByLeadType(null);
                }}
                />
                </LeadsPoolProvider>
          )}
          {isOpen && (
            <CloseLeadsDialog
              isOpen={isOpen}
              setIsLoading={setIsLoading}
              onSave={(item) => {
                const closeLeadsBody = {
                  leadsIds: selectedLeadIds,
                  closeReasonId: item.closeReasonId,
                  remarks: item.remarks,
                };
                closeLeads(closeLeadsBody);
                setIsOpen(false);
              }}
              onClose={() => {
                setIsOpen(false);
              }}
            />
          )}
          {sendToLeadsPoolDialog && (
            <SendToLeadsPool
              isOpen
              selectedLeads={selectedLeadIds}
              reloadData={Ralode}
              onClose={() => setSendToLeadsPoolDialog(false)}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          )}
        </div>
      </div>
    </div>
  );
};
