import React  , { useRef , useState , useEffect  }from "react";
import { useTranslation } from "react-i18next";
import Rating from "@material-ui/lab/Rating";
import {  Inputs } from "../../../../../Components";
import { ReactComponent as Minimize } from "../../../../../assets/images/defaults/minimize.svg";


export const SubCommunityGeographicCriteria = ({
  parentTranslationPath,
  translationPath,
  subCommunityGeographicCriteriaWithScores,
  setSubCommunityGeographicCriteriaWithScores,
  activeCommunity,
  setActiveSubCommunity,
}) => {

  const searchTimer = useRef(null);
  const { t } = useTranslation(parentTranslationPath);
  const [filterSubCommunitiesByName, setFilterSubCommunitiesByName] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (searchValue !== "") {
      const newList =
        subCommunityGeographicCriteriaWithScores &&
        subCommunityGeographicCriteriaWithScores.filter((a) =>
          a.lookupItemName
            .toLocaleLowerCase()
            .includes(searchValue && searchValue.toLocaleLowerCase())
        );
      setFilterSubCommunitiesByName(newList);
    } else if (searchValue === "") setFilterSubCommunitiesByName([]);
  }, [searchValue]);

  return (
    <>
    {activeCommunity && (
    <>

      <div>
        <span className="fw-bold"> {t(`${translationPath}SubCommunity`)} </span>
      </div>
      <div className="w-100 mt-2 mb-2 sores-part">
      <div className="b-bottom w-100">
        <div className="search-filter">
        <div className="">{""}</div>
          <div className="">
          <Inputs
            idRef="subCommunityOptionsFilterInputRef"
            wrapperClasses="mt-2"
            inputPlaceholder={`${t("search-for-sub-community")}`}
            onInputBlur={(event) => {
              event.stopPropagation();
            }}
            onKeyUp={(event) => {
              if (searchTimer.current) clearTimeout(searchTimer.current);
              const newValue = event.target.value;
              searchTimer.current = setTimeout(() => {
                setSearchValue(newValue);
              }, 300);
            }}
          />
          </div>
        </div>
        </div>
        <div className="w-100 mt-2 mb-2 sub-community">
        { searchValue === '' && subCommunityGeographicCriteriaWithScores &&
          subCommunityGeographicCriteriaWithScores
            .filter(
              (d) =>
                d.parentLookupItemId ===
                (activeCommunity && activeCommunity.lookupsId)
            )
            .map((item, index) => (
              <div
                className="b-bottom section"
                key={`SubCommunityCardItemKey${item.lookupsId}`}
                onClick={() => setActiveSubCommunity(item)}
              >
               
                  <div className="address">
                    <span className="fw-bold">{item.lookupItemName}</span>
                  </div>
                  <div className="address">
                  <Rating
                    size="small"
                    name={`SubCommunityCardItemId${item.lookupsId}`}
                    max={10}
                    value={item.score}
                    precision={1}
                    onChange={(event, newValue) => {
                      let updatelist = [
                        ...subCommunityGeographicCriteriaWithScores,
                      ];
                      const itemIndex = updatelist.indexOf(
                        updatelist.find((f) => f.lookupsId === item.lookupsId)
                      );
                      if (itemIndex !== -1)
                        updatelist[itemIndex] = {
                          ...updatelist[itemIndex],
                          score: newValue ||0,
                        };
                      setSubCommunityGeographicCriteriaWithScores([
                        ...updatelist,
                      ]);
                    }}
                  />

                  </div>
              </div>
            ))}
            { searchValue &&
            searchValue !== "" &&
            filterSubCommunitiesByName &&
            filterSubCommunitiesByName.map((item, index) => (
            <div
              className="b-bottom section"
              key={`filterSubCommunitiesByNameCardKey${item.lookupsId}`}
              onClick={() => setActiveSubCommunity(item)}
            >
              <div className="city-location">
                {" "}
                <div className="address">
                  <span className="fw-bold">{item.lookupItemName}</span>
                </div>
                <div className="address">
                  <Rating
                    size="small"
                    name={`filterSubCommunitiesByNameCardId${item.lookupTypeName}-${item.lookupsId}}`}
                    max={10}
                    value={item.score}
                    precision={1}
                    onChange={(event, newValue) => {
                      let updatelist = [...subCommunityGeographicCriteriaWithScores];
                      const itemIndex = updatelist.indexOf(
                        updatelist.find((f) => f.lookupsId === item.lookupsId)
                      );
                      if (itemIndex !== -1)
                        updatelist[itemIndex] = {
                          ...updatelist[itemIndex],
                          score: newValue || 0,
                        };
                      let updatelist2 = [...filterSubCommunitiesByName];
                      const itemIndex2 = updatelist2.indexOf(
                        updatelist2.find((f) => f.lookupsId === item.lookupsId)
                      );
                      if (itemIndex2 !== -1)
                        updatelist2[itemIndex2] = {
                          ...updatelist2[itemIndex2],
                          score: newValue || 0,
                       };
                      setFilterSubCommunitiesByName([...updatelist2]);
                      setSubCommunityGeographicCriteriaWithScores([...updatelist]);
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
          </div>
      </div>
     </>
    )}
    </>
  );
};
