import React from 'react'
import moment from 'moment';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types'
import { DatePickerComponent } from '../../../../../../../Components';

export const DatesSection = ({
  isReadOnly,
  selected,
  onSelectedChanged,
  parentTranslationPath,
  translationPath
}) => {
  const { t } = useTranslation(parentTranslationPath);

  return (
    <>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}contractDate`)}
          </span>
        </div>
        <div className="w-50 mt-2 mb-3 pt-1">
          <DatePickerComponent
            idRef='contractDateRef'
            placeholder='DD/MM/YYYY'
            value={selected.contractDate || ''}
            onDateChanged={(newValue) => {
              onSelectedChanged({ id: 'contractDate', value: (newValue && moment(newValue).format()) || null, });
            }}
          isDisabled={isReadOnly}
          />
        </div>
      </div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}transactionEntry`)}
          </span>
        </div>
        <div className="w-50 mt-2 mb-3 pt-1">
          <DatePickerComponent
            idRef='transactionEntryDateRef'
            placeholder='DD/MM/YYYY'
            value={selected.transactionEntryDate || ''}
            onDateChanged={(newValue) => {
              onSelectedChanged({ id: 'transactionEntryDate', value: (newValue && moment(newValue).format()) || null, });
            }}
          isDisabled={isReadOnly}
          />
        </div>
      </div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}Start-end-date`)}
          </span>
        </div>
        <div className="w-50 mt-2 mb-3 pt-1">

          <div className='d-flex'>
            <DatePickerComponent
              idRef='startDateRef'
              labelClasses='Requierd-Color'
              placeholder='DD/MM/YYYY'
              value={selected.startDate || ''}
              onDateChanged={(newValue) => {
                onSelectedChanged({ id: 'startDate', value: (newValue && moment(newValue).format()) || null, });
              }}
              isDisabled
            />

            <span className='from-to light-text'>-</span>
            <DatePickerComponent
              idRef='endDateRef'
              labelClasses='Requierd-Color'
              placeholder='DD/MM/YYYY'
              isDisabled
              //isDisabled={selected.startDate === null || isReadOnly}
              // minDate={selected.startDate || ''}
              value={selected.endDate}
              onDateChanged={(newValue) => {
                onSelectedChanged({ id: 'endDate', value: (newValue && moment(newValue).format()) || null, });
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

DatesSection.propTypes = {

}
