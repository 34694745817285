import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteComponent } from "../../../../Components";
import { PropertiesAdvanceSearchTest } from "../../../../Services";
import { PropertiesMapper } from "../../PropertiesView/PropertiesUtilities/PropertiesMapper/PropertiesMapper";

export const PropertiesFilter = ({
  resaleChangeFilters,
  parentTranslationPath,
  translationPath,
  selected , 
  setSelected ,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [allProperties, setaAllProperties] = useState({ result: [] });
  const searchTimer = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const getAllProperties = async (search) => {
    setIsLoading(true);
    const filter = { pageIndex: 0, pageSize: 50 };
    const body = {
      criteria: search
        ? {
          property_name: [{ searchType: 2, value: search || null }],
          }
        : {},
      filterBy: "createdOn",
      orderBy: 2,
    };
    const res = await PropertiesAdvanceSearchTest(filter, body);
    if (!(res && res.status && res.status !== 200)) {
      setaAllProperties({
        result: ((res && res.result) || []).map(
          (item) =>
            item.propertyJson &&
            PropertiesMapper(item, JSON.parse(item.propertyJson).property)
        ),
        totalCount: (res && res.totalCount) || 0,
      });
    } else setaAllProperties({ result: [] });
    setIsLoading(false);
  };

  return (
    <div className="w-50 m-1">
      <AutocompleteComponent
        idRef="propertyRef"
        multiple={false}
        wrapperClasses="w-min-unset m-2 mr-1"
        inputPlaceholder={t(`${translationPath}select-property-name`)}
        labelValue={t(`${translationPath}property`)}
        selectedValues={selected.propertyName}
        data={allProperties?.result || []}
        displayLabel={(option) => (option && option.property_name) || ""}
        chipsLabel={(option) => option.property_name || ""}
        withoutSearchButton
        renderOption={(option) => (
          <span>{`${option.property_name} -${option.id}`}</span>
        )}
        onChange={(e, newValue) => {
          resaleChangeFilters(
            "property_name",
            (newValue && newValue.property_name) || ""
          );
          setSelected({id :'propertyName'  , value : newValue })

        }}
        onInputKeyUp={(e) => {
          const { value } = e.target;
          if (searchTimer.current) clearTimeout(searchTimer.current);
          searchTimer.current = setTimeout(() => {
            getAllProperties(value);
          }, 700);
        }}
        onOpen={() => {
          if (allProperties.result && allProperties.result.length == 0)
            getAllProperties();
        }}
        filterOptions={(options) => options}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        isLoading={isLoading}
        withLoader
      />
    </div>
  );
};
