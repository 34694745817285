import React, { useRef, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  DialogComponent,
  AutocompleteComponent,
  CheckboxesComponent,
  Spinner
} from '../../../../../../Components';
import { BulkLeadsAutoRotation } from '../../../../../../Services';
import { GetAdvanceSearchRotationScheme } from '../../../../../../Services/RotaionSchemaService/RotationSchemaService';
import { showSuccess, showError } from '../../../../../../Helper';

const translationPath = 'LeadsView:utilities.sendToRoationDialog.';
export const SendToRoationDialog = ({ isOpen, onClose, checkedCardsIds,isForOwnerLeads, isFromPool ,isActive
}) => {
  const { t } = useTranslation('LeadsView');
  const searchTimer = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [selectedSchemes, setSelectedSchemes] = useState([]);
  const [selectedSchemesIdes, setSelectedSchemesIdes] = useState([]);
  const [allSchemas, setAllSchemas] = useState([]);
  const [isSendToAllChecked, setIsSendToAllChecked] = useState(false);
  const [isUpdating, setIsUpdating ] = useState(false)

  const onSubmit = useCallback(async () => {
    setIsUpdating(true);
    const body = {
      leadsIds: checkedCardsIds,
      recommendedSchemesIds: selectedSchemesIdes,
      isFromPool
    }
    const res = await BulkLeadsAutoRotation(body);
    if (!(res && res.status && res.status !== 200)) showSuccess(isForOwnerLeads
      ? t(`${translationPath}send-to-owner-rotation-succeeded`)
      : t(`${translationPath}send-to-seeker-rotation-succeeded`));
    else showError( isForOwnerLeads
      ? t(`${translationPath}send-to-owner-rotation-failed`)
      : t(`${translationPath}send-to-seeker-rotation-failed`));

    setIsUpdating(false);
    onClose();
  }, [selectedSchemesIdes]);

  const getAllSchemas = useCallback(async (search) => {
    setIsLoading(true);
    const res = await GetAdvanceSearchRotationScheme({ pageSize: 25, pageIndex: 1 , search: search, isForOwnerLeads , isActive :isActive});
    if (!(res && res.status && res.status !== 200)) setAllSchemas(res.result)
    else setAllSchemas([])

    setIsLoading(false);
  }, []);
  useEffect(() => {
    getAllSchemas();
  }, [getAllSchemas]);

  useEffect(() => {
    if (selectedSchemesIdes.length > 0 || isSendToAllChecked) setIsSaveDisabled(false);

    else setIsSaveDisabled(true);

  }, [selectedSchemesIdes, isSendToAllChecked])


  return (
    <DialogComponent
      titleText={isForOwnerLeads ? t(`${translationPath}send-to-owner-rotation`) : t(`${translationPath}send-to-seeker-rotation`)}
      saveText={t('confirm')}
      saveType='button'
      maxWidth='sm'
      saveIsDisabled={isSaveDisabled}
      dialogContent={(
        <>
          <div className='d-flex-column-center'>
        <Spinner isAbsolute isActive={isUpdating}/>
            <AutocompleteComponent
              idRef='recommendedSchemesRef'
              labelValue={t(`${translationPath}recommendedSchemes`)}
              data={allSchemas || []}
              selectedValues={selectedSchemes}
              multiple
              disableClearable={false}
              displayLabel={(option) =>
                (option && option.label) || ''}
              chipsLabel={(option) => (option && option.label) || ''}
              isLoading={isLoading}
              withLoader
              withoutSearchButton
              parentTranslationPath={translationPath}
              translationPath={''}
              isDisabled={isDisabled}
              onInputKeyUp={(event) => {
                const { value } = event.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  getAllSchemas(value);
                }, 700);
              }}
              onChange={(e, newValues) => {
                setSelectedSchemesIdes(newValues.map((e) => e.rotationSchemeId));
                setSelectedSchemes(newValues);
              }}

            />
          </div>
          <CheckboxesComponent
            checked={isSendToAllChecked}
            parentTranslationPath={translationPath}
            translationPath={'LeadsView:utilities.sendToRoationDialog.'}
            translationPathForData={translationPath}
            label="send-to-all-schemas"
            wrapperClasses="mt-3"
            onSelectedCheckboxChanged={() => {
              setIsSendToAllChecked(!isSendToAllChecked);
              if (!isSendToAllChecked) {
                setIsDisabled(true);
                setSelectedSchemes([]);
                setSelectedSchemesIdes([]);
              } else setIsDisabled(false);

            }}
          />
        </>
      )}
      saveClasses='btns theme-solid w-100 mx-2 mb-2'
      isOpen={isOpen}
      onSaveClicked={onSubmit}
      onCancelClicked={onClose}
      translationPath={translationPath.confirm}
    />
  );
};

SendToRoationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  checkedCardsIds: PropTypes.instanceOf(Array)
};
