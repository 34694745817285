import React from 'react';
import { Box, Divider } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { CustomCard } from '../../CustomCard';
// Styles
import useStyles from './styles';


function SourceFileSkeleton() {
  const styles = useStyles();

  return (
    <CustomCard
      borderRadius="xl"
      boxShadow="xs"
      borderColor="secondary"
      classes={styles.containerCard}
    >
      <Box classes={styles.headingstyle}>
        <Skeleton variant="text" width={110} height={50} />
      </Box>
      <Divider className={styles.divider}/>
      <Box className={styles.containerinfoItems}>
        <Box className={styles.InfoItem} >
          <Skeleton variant="text" width={140} height={25} className={styles.info_item} />
          <Skeleton variant="text" width={80} height={25} className={styles.info_item} />
        </Box>
        <Box className={styles.InfoItem} >
          <Skeleton variant="text" width={140} height={25} className={styles.info_item} />
          <Skeleton variant="text" width={80} height={25} className={styles.info_item} />
        </Box>
        <Box className={styles.InfoItem} >
          <Skeleton variant="text" width={140} height={25} className={styles.info_item} />
          <Skeleton variant="text" width={80} height={25} className={styles.info_item} />
        </Box>
        <Box className={styles.InfoItem} >
          <Skeleton variant="text" width={140} height={25} className={styles.info_item} />
          <Skeleton variant="text" width={80} height={25} className={styles.info_item} />
        </Box>
        <Box className={styles.InfoItem} >
          <Skeleton variant="text" width={140} height={25} className={styles.info_item} />
          <Skeleton variant="text" width={80} height={25} className={styles.info_item} />
        </Box>
        <Box className={styles.InfoItem} >
          <Skeleton variant="text" width={140} height={25} className={styles.info_item} />
          <Skeleton variant="text" width={80} height={25} className={styles.info_item} />
        </Box>
      </Box>
    </CustomCard>
  );
};

export default SourceFileSkeleton;
