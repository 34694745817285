import React from "react";

function ListIcon({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M3.477 4.073c-.671.176-1.239.75-1.417 1.435a2.07 2.07 0 0 0 .165 1.422c.136.269.576.709.845.845a2.047 2.047 0 0 0 1.86 0c.266-.135.708-.575.841-.838a2.05 2.05 0 0 0 .169-1.408 1.806 1.806 0 0 0-.475-.876 1.984 1.984 0 0 0-1.988-.58m5.218.984a1.04 1.04 0 0 0-.567.459c-.091.156-.108.23-.108.484 0 .256.016.328.111.489.125.213.318.375.539.454.222.079 12.438.079 12.66 0 .221-.079.414-.241.539-.454.095-.161.111-.233.111-.489 0-.254-.017-.328-.108-.484a1.02 1.02 0 0 0-.582-.461c-.262-.079-12.343-.077-12.595.002m-5.218 5.016a1.991 1.991 0 0 0-.942.58A1.915 1.915 0 0 0 2 12c0 .546.193 1.001.596 1.404.145.146.359.312.474.371a2.047 2.047 0 0 0 1.86 0c.266-.135.708-.575.841-.838.39-.769.272-1.662-.3-2.278A1.973 1.973 0 0 0 4 10.005c-.143 0-.378.031-.523.068m5.218.984a1.04 1.04 0 0 0-.567.459c-.091.156-.108.23-.108.484 0 .256.016.328.111.489.125.213.318.375.539.454.123.044 1.268.053 6.33.053s6.207-.009 6.33-.053c.221-.079.414-.241.539-.454.095-.161.111-.233.111-.489 0-.254-.017-.328-.108-.484a1.02 1.02 0 0 0-.582-.461c-.262-.079-12.343-.077-12.595.002m-5.218 5.016a1.991 1.991 0 0 0-.942.58A1.915 1.915 0 0 0 2 18c0 .546.193 1.001.596 1.404.403.403.858.596 1.404.596.546 0 1.001-.193 1.404-.596.516-.515.702-1.168.536-1.875a1.806 1.806 0 0 0-.475-.876A1.972 1.972 0 0 0 4 16.005c-.143 0-.378.031-.523.068m5.218.984a1.04 1.04 0 0 0-.567.459c-.091.156-.108.23-.108.484 0 .256.016.328.111.489.125.213.318.375.539.454.123.044 1.268.053 6.33.053s6.207-.009 6.33-.053c.221-.079.414-.241.539-.454.095-.161.111-.233.111-.489 0-.254-.017-.328-.108-.484a1.02 1.02 0 0 0-.582-.461c-.262-.079-12.343-.077-12.595.002"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default ListIcon;
