export const ReleaseNotePermissions = {
  AddReleaseNote : {
    permissionsId: "d2ff159e-a1ae-459c-2e4e-08db604be055",
    permissionsName: "Add Release Note",
    description: null,
    componentsId: null,
    components: null
},
EditReleaseNote : {
    permissionsId: "b72a2501-c26e-40ff-2e4f-08db604be055",
    permissionsName: "Edit Release Note",
    description: null,
    componentsId: null,
    components: null
  },
  ArchiveReleaseNote : {
    permissionsId: "06fccca2-93c3-433e-2e50-08db604be055",
    permissionsName: "Archive Release Note",
    description: null,
    componentsId: null,
    components: null
   }, 
   ExportReleaseNoteAsPDF :{
    permissionsId: "5b329599-0f2d-42ae-2b17-08dbca2fdea8",
    permissionsName: "Export Release Note As PDF",
    description: null,
    componentsId: null,
    components: null
}
   };
