import React, { useCallback, useEffect, useRef, useState } from 'react'
import { LoadableImageEnum } from '../../../Enums';
import { LoadableImageComponant, CheckboxesComponent, AutocompleteComponent, SelectComponet } from '../../../Components';
import { DefaultImagesEnum } from '../../../Enums';
import { useTranslation } from 'react-i18next';
import { getDownloadableLink } from '../../../Helper'

export const AgentsComponent = ({
    allLeadAuditorAgents,
    parentTranslationPath,
    translationPath,
    withCheckbox,
    checkedCardsIds,
    cardCheckboxClicked,
    filterByAgent,
    setFilterByAgent,
    filterByTeams,
    setFilterByTeams,
    searchHandler,
    allTeams,
    filterByTeamLead,
    setFilterByTeamLead,
    allTeamLeads,
    allAgents,
    allLeadAuditor,
    isAssignAgent,
    setIsAssignAgent,
    setLeadAuditorId,
    // leadAuditorId,
    isLoading
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const getIsSelectedCard = useCallback(
        (row) => checkedCardsIds && checkedCardsIds.findIndex((item) => item === row.agentID) !== -1,
        [checkedCardsIds]
    );

    return (
        <div className='agents-wrapper'>
            <div className='Lead-auditor-title'>
                <span>{t(`${translationPath}agents`)} </span>
                <span>{allLeadAuditorAgents && allLeadAuditorAgents.totalCount || 0} </span>
            </div>
            <div className='agents-search'>
                <AutocompleteComponent
                    idRef='agentFilterRef'
                    selectedValues={filterByAgent || null}
                    wrapperClasses={'admin-agent-search mx-2 my-2'}
                    multiple={false}
                    data={allAgents || []}
                    displayLabel={(option) => (option && option.agentName) || ''}
                    getOptionSelected={(option) => (option && option.agentName) || ''}
                    withoutSearchButton
                    inputPlaceholder={t(`${translationPath}filter-by-agent`)}
                    isWithError
                    isLoading={isLoading.getAllAgents}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onInputKeyUp={searchHandler}
                    onChange={(event, newValue) => {
                        setFilterByAgent(newValue);
                    }}
                />
                <AutocompleteComponent
                    idRef='TeamLeadFilterRef'
                    selectedValues={filterByTeamLead || null}
                    wrapperClasses={'admin-agent-search mx-2 my-2'}
                    multiple={false}
                    data={allTeamLeads || []}
                    displayLabel={(option) => (option && option.teamLeadName) || ''}
                    getOptionSelected={(option) => (option && option.teamLeadName) || ''}
                    withoutSearchButton
                    inputPlaceholder={t(`${translationPath}filter-by-team-lead`)}
                    isWithError
                    isLoading={isLoading.getAllTeamLeads}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onInputKeyUp={searchHandler}
                    onChange={(event, newValue) => {
                        setFilterByTeamLead(newValue);
                    }}
                />
                <AutocompleteComponent
                    idRef='TeamFilterRef'
                    selectedValues={filterByTeams || null}
                    wrapperClasses={'admin-agent-search mx-2 my-2'}
                    multiple={false}
                    data={allTeams || []}
                    displayLabel={(option) => (option && option.teamsName) || ''}
                    getOptionSelected={(option) => (option && option.teamsName) || ''}
                    withoutSearchButton
                    inputPlaceholder={t(`${translationPath}filter-team`)}
                    isWithError
                    isLoading={isLoading.getAllTeam}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onInputKeyUp={searchHandler}
                    onChange={(event, newValue) => {
                        setFilterByTeams(newValue);
                    }}
                />
                <SelectComponet
                    idRef='filterByRef'
                    data={[
                        { id: true, value: 'assign' },
                        { id: false, value: 'unAssign' },
                    ]}
                    wrapperClasses={'admin-agent-search mx-2 my-2'}
                    isRequired
                    value={isAssignAgent}
                    onSelectChanged={(value) => {
                        setIsAssignAgent(value);
                        if (!value) setLeadAuditorId(null)
                    }}
                    emptyItem={{
                        value: null,
                        text: 'assign-agent',
                        isDisabled: false,
                    }}
                    valueInput='id'
                    textInput='value'
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}

                />
            </div>
            <div className='agents-cards-wrapper'>
                { allLeadAuditorAgents && allLeadAuditorAgents.result.map((item, index) =>
                    <div className='leads-cards-wrapper '>
                        {withCheckbox && (
                            <div className='card-checkbox-wrapper'>
                                <CheckboxesComponent
                                    idRef={`unitsCheckboxItemRef${index + 1}`}
                                    singleChecked={getIsSelectedCard(item)}
                                    onSelectedCheckboxClicked={(event) => {
                                        event.preventDefault();
                                        cardCheckboxClicked(item);
                                    }}
                                />
                            </div>
                        )}
                        <div className='avatar w-100 d-flex'>
                            <LoadableImageComponant
                                type={LoadableImageEnum.image.key}
                                classes='agents-image'
                                alt={'user-image'}
                                src={item.profileImg && getDownloadableLink(item.profileImg) || DefaultImagesEnum.man.defaultImg}

                            />
                            <div className='w-100'>
                                <div className='title-wrapper'>
                                    <span>{item.agentName}</span>
                                    <span>{t(`${translationPath}lead-owner `)}{item.leadAduitorName || 'N/A'}</span>
                                </div>
                                <div className='leads-row'>
                                    <div className='leads-numbers'>
                                        {<span className='hot lead-number'>{item.numberOfHotLead && item.numberOfHotLead || '0'}</span>}
                                        {<span className='warm lead-number'>{item.numberOfWarmLead && item.numberOfWarmLead || '0'}</span>}
                                        {<span className='cold lead-number'>{item.numberOfColdLead && item.numberOfColdLead || '0'}</span>}

                                        {<span className='hot lead-number'>{item.percentageOfHotLead && `${item.percentageOfHotLead}%` || '0'}</span>}
                                        {<span className='warm lead-number'>{item.percentageOfWarmLead && `${item.percentageOfWarmLead}%` || '0'}</span>}
                                        {<span className='cold lead-number'>{item.percentageOfColdLead && `${item.percentageOfColdLead}%` || '0'}</span>}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='agent-row d-flex'>
                            <div className='mr-2'>
                                <span>{t(`${translationPath}team-lead`)}</span>
                                <span>{item.teamLeadName || 'N/A'}</span></div>
                            <div className='mr-2'>
                                <span>{t(`${translationPath}team`)}</span>
                                <span>{item.teamName || 'N/A'}</span></div>
                            <div className='mr-2'>
                                <span>{t(`${translationPath}active`)}</span>
                                <span>{item.numberOfOpenLead || 'N/A'}</span>
                            </div>
                            <div className='mr-2'>
                                <span>{t(`${translationPath}inactive`)}</span>
                                <span>{item.numberOfClosedLead || 'N/A'}</span>
                            </div>

                        </div>
                    </div>
                ) || null}
            </div>
        </div>
    )
}
