
export const ActivatedLeadsTeamLeadDashboardPermissions = {
  ActivatedLeadsTeamLeadDashboard: 
  {
    permissionsId: "5ef7f195-0f6a-43a4-2869-08dbaf79a1bb",
    permissionsName: "Activated Leads Team Lead Dashboard",
    description: null,
    componentsId: null,
    components: null
   }  
  } ;
  