import { config } from "../config/config";
import { HttpServices } from "../Helper";

const GetMainEntitiesComments = async (entityType, entityId) => {
  try {
    const url = `${config.server_address}/CrmDfm/MainEntitiesComments/GetMainEntitiesComments/${entityType}/${entityId}`;
    const result = await HttpServices.get(url);
    return result;
  } catch (error) {
    throw error.response;
  }
};

const CreateMainEntitiesComment = async (body) => {
  try {
    const url = `${config.server_address}/CrmDfm/MainEntitiesComments/CreateMainEntitiesComment`;
    const result = await HttpServices.post(url, body);
    return result;
  } catch (error) {
    throw error.response;
  }
}

const UpdateMainEntitiesComment = async (mainEntitiesCommentId, body) => {
  try {
    const url = `${config.server_address}/CrmDfm/MainEntitiesComments/UpdateMainEntitiesComment/${mainEntitiesCommentId}`;
    const result = await HttpServices.put(url, body);
    return result;
  } catch (error) {
    throw error.response;
  }
}

const DeleteMainEntitiesComment = async (commentId) => {
  try {
    const url = `${config.server_address}/CrmDfm/MainEntitiesComments/DeleteMainEntitiesComment/${commentId}`;
    const result = await HttpServices.delete(url);
    return result;
  } catch (error) {
    throw error.response;
  }
}

export { 
  GetMainEntitiesComments,
  CreateMainEntitiesComment,
  UpdateMainEntitiesComment,
  DeleteMainEntitiesComment,
};
