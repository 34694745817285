import { config } from '../../config';
import { HttpServices } from '../../Helper';

export const GetAllCampaignsRequests = async ({ pageSize, pageIndex, body }) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0) queryList.push(`pageIndex=${pageIndex + 1}`);
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Campaigns/GetAllCampaignsRequests/${pageIndex}/${pageSize}`,
    body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetCampaignRequestHistory = async ({ id }) => {

  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Campaigns/GetCampaignRequestHistory/${id}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const CreateCampaignRequest = async (body) => {

  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Campaigns/CreateCampaignRequest`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const ResubmitCampaignRequest = async (body) => {

  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Campaigns/ResubmitCampaignRequest`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetCampaignRequestDetails = async (id) => {

  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Campaigns/GetCampaignRequestDetails/${id}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const RejectCampaignRequest = async (body) => {

  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Campaigns/RejectCampaignRequest`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const ApproveCampaignRequest = async ({id}) => {

  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Campaigns/ApproveCampaignRequest/${id}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const MarkCampaignRequestAsComplete = async ({id}, body) => {

  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Campaigns/MarkCampaignRequestAsComplete/${id}`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllCampaignAttachments = async ({campaignId}) => {

  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Campaigns/GetAllCampaignAttachments/${campaignId}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
