import React, { useEffect } from 'react';
import { SwitchRoute } from '../../../Components/Route/SwitchRoute';
import { UnitsLeaseRoutes } from '../../../routes';

export const UnitsLeaseLayout = () => {
    useEffect(() => {
        return () => {
            localStorage.removeItem("unitsLeaseCurrentPageIndex");
        }
    }, []);
    return (
        <SwitchRoute routes={UnitsLeaseRoutes} />
    );
};
