import React, { Fragment, useState } from 'react';
import { Button, DialogContent } from '@material-ui/core';
import { DialogComponent } from '../../Components';

const ShowMore = ({ text, parentTranslationPath, title }) => {
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      {!text && '-'}
      {text?.length < 30 && text}
      {text?.length >= 30 && (
        <p>
          {text.slice(0, 15).padEnd(20, '.')}{' '}
          <Button
            onClick={() => setOpen(true)}
            color='primary'
            size='small'
            className='capitalize'
          >
            {' '}
            show more
          </Button>{' '}
        </p>
      )}
      <DialogComponent
        isOpen={open}
        titleText={title}
        dialogContent={
          <DialogContent style={{ fontSize: '14px' }}>{text}</DialogContent>
        }
        onCloseClicked={() => setOpen(false)}
        maxWidth={'sm'}
        parentTranslationPath={parentTranslationPath}
        translationPath={''}
      />
    </Fragment>
  );
};

export default ShowMore;
