import React from "react";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { PowerBiEnum } from "../../../../Enums";
import { MainDashboardFilterDate } from "../MainDashboardFilterDate/MainDashboardFilterDate";

export const CallCenterTeamlead = () => {
 return (
    <div>
   
        <MainDashboardFilterDate
          id={PowerBiEnum.callCenterDate.reportid}
          embedUrl={PowerBiEnum.callCenterDate.url}
        />
    
    </div>
  );
};
