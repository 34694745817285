import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { GetAllCampaignsConfig } from '../../../Services';
import { Tables, Spinner } from '../../../Components';
import { TableActions } from '../../../Enums';
import { ConfigurationHeaderData } from './ConfigurationHeaderData';
import { ConfigManagementDialog } from './ConfigManagementDialog';
import { useTitle } from '../../../Hooks';

const parentTranslationPath = 'Campaign';
const translationPath = '';

export const CampaignConfigurationView = () => {
    const { t } = useTranslation(parentTranslationPath);
    useTitle(t('campaign-configuration'));

    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState({
        pageSize: 25,
        pageIndex: 0,
    });
    const [campaigns, setCampaigns] = useState({
        data: [],
        totalCount: 0
    });
    const [activeItem, setActiveItem] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const tableActionClicked = useCallback((actionEnum, item) => {
        if (actionEnum === TableActions.editText.key) {
            setActiveItem(item);
            setIsDialogOpen(true);
        }
    }, []);

    const getTableActions = () => {
        const actions = [{
            enum: TableActions.editText.key,
            isDisabled: false,
            externalComponent: null,
        }]
        return actions;
    };

    const addNewHandler = () => {
        setIsDialogOpen(true);
    };
    const getAllCampaignsConfig = useCallback(async () => {
        setIsLoading(true);
        const res = await GetAllCampaignsConfig({
            pageSize: filter.pageSize,
            pageIndex: filter.pageIndex || (filter.pageIndex + 1),
        });
        if (!(res && res.status && res.status !== 200)) {
            setCampaigns({
                data: res.result || [],
                totalCount: res.totalCount || 0
            });
        } else setCampaigns({
            data: [],
            totalCount: 0
        });

        setIsLoading(false);
    }, [filter]);


    const onPageIndexChanged = (pageIndex) => {
        setFilter((item) => ({ ...item, pageIndex }));
    };
    const onPageSizeChanged = (pageSize) => {
        setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    };

    useEffect(() => {
        getAllCampaignsConfig();
    }, [getAllCampaignsConfig]);


    return (
        <div className='campain-wrapper'>
            <Spinner isActive={isLoading} isAbsolute />
            <div className='d-flex-v-center-h-between'>
                <Button className='btns mx-3 mb-2 theme-solid' onClick={addNewHandler} id='action_add-new_plus'>
                    <span className='mdi mdi-plus' />
                    {t(`${translationPath}add-new`)}
                </Button>
            </div>

            <div className='p-3 w-100'>
                <Tables
                    data={campaigns.data}
                    headerData={ConfigurationHeaderData}
                    actionsOptions={{
                        onActionClicked: tableActionClicked,
                    }}
                    itemsPerPage={filter.pageSize}
                    activePage={filter.pageIndex}
                    defaultActions={getTableActions()}
                    onPageIndexChanged={onPageIndexChanged}
                    onPageSizeChanged={onPageSizeChanged}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    totalItems={campaigns.totalCount || 0}
                />
            </div>


            {isDialogOpen && (
                <ConfigManagementDialog
                    isDialogOpen={isDialogOpen}
                    activeItem={activeItem}
                    onSave={() => {
                        getAllCampaignsConfig();
                        setIsDialogOpen(false);
                        setActiveItem(null);
                    }}
                    onClose={() => {
                        setActiveItem(null);
                        setIsDialogOpen(false);
                    }}
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                />
            )}
        </div>
    );
};
