

import { AdminAssignComponent } from '../../Views/Home/AdminAssignView/AdminAssignComponent';
import { AssignAgentComponent } from '../../Views/Home/AssignAgentView/AssignAgentComponent';
import { UnitsSaleLeadOwner } from '../../Views/Home/UnitsSaleLeadOwnerView/UnitsSaleLeadOwner';
import { UnitsLeaseLeadOwner } from '../../Views/Home/UnitsLeaseLeadOwnerView/UnitsLeaseLeadOwner';
import { LeadsLeadOwner } from '../../Views/Home/LeadsLeadOwnerView/LeadsLeadOwner';
import { ActivityLeadOwner } from '../../Views/Home/ActivityLeadOwnerView/ActivityLeadOwner';



export const LeadOwnerRoutes = [

  {
    path: '/view',
    name: 'leadOwnerView:leads',
    component: AdminAssignComponent,
    layout: '/home/AdminAssign',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'LeadsView:leads',
        isDisabled: true,
        route: '/home/AdminAssign',
        groupName: 'lead-owner',
      },
    ],
  },

  {
    id: 140,
    path: '/lead-owner-AdminAssign',
    name: 'leadOwnerView:AdminAssign',
    component: AdminAssignComponent,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 20,
    order: 1,
    icon: 'mdi mdi-account-outline c-white',
    iconActive: 'mdi mdi-account-outline c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'leadOwnerView:AdminAssign',
        isDisabled: false,
        route: '/home/lead-owner-AdminAssign',
        groupName: 'lead-owner'
      },

    ],
  },
  {
    id: 141,
    path: '/lead-owner-AssignAgent',
    name: 'leadOwnerView:AssignAgent',
    component: AssignAgentComponent,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 20,
    order: 1,
    icon: 'mdi mdi-account-outline c-white',
    iconActive: 'mdi mdi-account-outline c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'leadOwnerView:AssignAgent',
        isDisabled: false,
        route: '/home/lead-owner-AssignAgent',
        groupName: 'lead-owner'
      },

    ],
  },
  {
    id: 142,
    path: '/lead-owner-units-sale',
    name: 'leadOwnerView:units-sale',
    component: UnitsSaleLeadOwner,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 20,
    order: 1,
    icon: 'mdi mdi-account-outline c-white',
    iconActive: 'mdi mdi-account-outline c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'leadOwnerView:units-sale',
        isDisabled: false,
        route: '/home/lead-owner-units-sale',
        groupName: 'lead-owner'
      },

    ],
  },
  {
    id: 143,
    path: '/lead-owner-units-lease',
    name: 'leadOwnerView:units-lease',
    component: UnitsLeaseLeadOwner,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 20,
    order: 1,
    icon: 'mdi mdi-account-outline c-white',
    iconActive: 'mdi mdi-account-outline c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'leadOwnerView:units-lease',
        isDisabled: false,
        route: '/home/lead-owner-units-lease',
        groupName: 'lead-owner'
      },

    ],
  },
  {
    id: 144,
    path: '/lead-owner-leads',
    name: 'leadOwnerView:leads',
    component: LeadsLeadOwner,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 20,
    order: 1,
    icon: 'mdi mdi-account-outline c-white',
    iconActive: 'mdi mdi-account-outline c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'leadOwnerView:units',
        isDisabled: false,
        route: '/home/lead-owner-leads',
        groupName: 'lead-owner'
      },

    ],
  },
  {
    id: 145,
    path: '/lead-owner-activity',
    name: 'leadOwnerView:activity',
    component: ActivityLeadOwner,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 20,
    order: 1,
    icon: 'mdi mdi-account-outline c-white',
    iconActive: 'mdi mdi-account-outline c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'leadOwnerView:activity',
        isDisabled: false,
        route: '/home/lead-owner-activity',
        groupName: 'lead-owner'
      },

    ],
  },
];

