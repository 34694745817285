


import React , { useState , useCallback , useEffect , useRef  } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CheckboxesComponent  , AutocompleteComponent  } from '../../../../../../../Components' ; 
import { OrganizationUserSearch } from '../../../../../../../Services' ;  

    
export const PropertiesCreatedBy  = ({
  parentTranslationPath,
  translationPath,
  state,
  setState , 
  checkAtLeastOneSelected , 
  checkAtLeastOneSelected2 , 
  setDefaultSelected,
  
}) => {
    const { t } = useTranslation(parentTranslationPath);
    
    const [allUsers , setAllUsers] =  useState([]) ;

    const [isLoading ,   setIsLoading ] = useState(false) ;  
    const searchTimer = useRef(null);
    let timer = null; 

    const getAllOrganizationUserSearch = useCallback(async (searchValue) => {
      setIsLoading(true);
      const res = await OrganizationUserSearch({ pageIndex: 0, pageSize: 10, name: searchValue  , userStatusId : 2 });
      if (!(res && res.status && res.status !== 200)) 
      setAllUsers(res.result.map((item) => ({
        id :  item.id , 
        fullName : item.fullName  , 
        branch : item.branch || null , 
      })));
       else 
       setAllUsers([]);  

       setIsLoading(false);

    }, []); 


    useEffect(() => {
      getAllOrganizationUserSearch() ;
    }, []); 
 

 
  return (
    <div className='content'>
     <div className='w-100 part checkBoxsPart'>
        <div className='checkboxesItem'>
          <CheckboxesComponent
           idRef='PropertiesCreatedByViewAllPropertiesRef'
           label={t(`${translationPath}view-all-properties`)}
           parentTranslationPath={parentTranslationPath}
           translationPathForData={translationPath}
          singleChecked={state.properties.createdBy.allUsers}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.properties.createdBy ,  allUsers : e.target.checked } );  

            if(checkAtLeastOneSelected2(state.properties.createdBy.specificUsers));
            else if(!checkIfCanSelected)
               return ;

             const propertiesCreatedBy =  { ...state.properties.createdBy ,
                allUsers : (e.target.checked)   , 
                myUser  : false  , 
                myTeamUsers : false , 
                myBusinessGroupUsers : false , 
                myBranchUsers :false , 
                myManageUsers : false ,
                specificUsers :[]  , 
                myTeamLeadUsers :false , 

              } ;
           setState({id : 'properties' , value : { ...state.properties , createdBy :   propertiesCreatedBy } });
          }}
         />
         </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='PropertiesCreatedByViewMyPropertiesRef'
          label={t(`${translationPath}view-my-properties`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.properties.createdBy.myUser}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.properties.createdBy ,  myUser : e.target.checked } );
           
            if(checkAtLeastOneSelected2(state.properties.createdBy.specificUsers));
            else if(!checkIfCanSelected)
             return ;
           
            const propertiesCreatedBy =  { ...state.properties.createdBy ,
               allUsers :false    , 
               myUser  :  (e.target.checked)  , 
               myTeamUsers : false , 
               myBusinessGroupUsers : false , 

             } ;
             setState({id : 'properties' , value : { ...state.properties , createdBy :   propertiesCreatedBy } });
           }}
         />
        </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='PropertiesCreatedByViewMyTeamPropertiesRef'
          label={t(`${translationPath}view-my-team-properties`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.properties.createdBy.myTeamUsers}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.properties.createdBy ,  myTeamUsers : e.target.checked } );

            if(checkAtLeastOneSelected2(state.properties.createdBy.specificUsers));
            else if(!checkIfCanSelected)
             return ;
            
            const propertiesCreatedBy =  { ...state.properties.createdBy ,
               allUsers :false    , 
               myUser  :  false  , 
               myTeamUsers : (e.target.checked) , 
               myBusinessGroupUsers : false , 

             } ;
             setState({id : 'properties' , value : { ...state.properties , createdBy :   propertiesCreatedBy } });
           }}
          />
         </div>
         <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='PropertiesCreatedByViewMyBusinessGroupRef'
          label={t(`${translationPath}view-my-business-group-properties`)}
          singleChecked={state.properties.createdBy.myBusinessGroupUsers}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.properties.createdBy ,  myBusinessGroupUsers : e.target.checked } );
            
            if(checkAtLeastOneSelected2(state.properties.createdBy.specificUsers));
            else if(!checkIfCanSelected)
             return ;   

            const propertiesCreatedBy =  { ...state.properties.createdBy ,
               allUsers :false    , 
               myUser  :  false  , 
               myTeamUsers : false  , 
               myBusinessGroupUsers : (e.target.checked) , 

             } ;
             setState({id : 'properties' , value : { ...state.properties , createdBy :   propertiesCreatedBy } });
           }}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          />
         </div>

         <div className='checkboxesItem'>
          <CheckboxesComponent
           idRef='PropertiesCreatedByViewMyBranchPropertiesRef'
           label={t(`${translationPath}view-my-branch-properties`)}
           parentTranslationPath={parentTranslationPath}
           translationPathForData={translationPath}
           singleChecked={state.properties.createdBy.myBranchUsers}
           onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.properties.createdBy ,  myBranchUsers : e.target.checked } );
            
            if(checkAtLeastOneSelected2(state.properties.createdBy.specificUsers));
            else if(!checkIfCanSelected)
             return ;

            const propertiesCreatedBy =  { ...state.properties.createdBy ,
               allUsers :false    , 
               myBranchUsers: (e.target.checked)
             } ;
             setState({id : 'properties' , value : { ...state.properties , createdBy :   propertiesCreatedBy } });
           }}
         />
         </div>
         <div className='checkboxesItem'> 
          <CheckboxesComponent
            idRef='PropertiesCreatedByViewMyManagedTeamPropertiesRef'
            label={t(`${translationPath}view-my-managed-team-properties`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={state.properties.createdBy.myManageUsers}
            onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.properties.createdBy ,  myManageUsers : e.target.checked } );

              if(checkAtLeastOneSelected2(state.properties.createdBy.specificUsers));
              else if(!checkIfCanSelected)
                    return ;

              const propertiesCreatedBy =  { ...state.properties.createdBy ,
                 allUsers :false    , 
                 myManageUsers: (e.target.checked)
               } ;
               setState({id : 'properties' , value : { ...state.properties , createdBy :   propertiesCreatedBy } });
             }}
         />
        </div>
        <div className='checkboxesItem'> 
          <CheckboxesComponent
            idRef='PPropertiesCreatedByMyTeamLeadUsersRef'
            label={t(`${translationPath}view-my-team-lead-users`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={state.properties.createdBy.myTeamLeadUsers}
            onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.properties.createdBy ,  myTeamLeadUsers : e.target.checked } );

              if(checkAtLeastOneSelected2(state.properties.createdBy.specificUsers));
              else if(!checkIfCanSelected)
                    return ;

              const propertiesCreatedBy =  { ...state.properties.createdBy ,
                 allUsers :false    , 
                 myTeamLeadUsers: (e.target.checked)
               } ;
               setState({id : 'properties' , value : { ...state.properties , createdBy :   propertiesCreatedBy } });
             }}
         />
        </div>
        <div className='w-100 px-2 mt-2 mb-2'>
          <AutocompleteComponent
              idRef='contactSpecificsUsersRef'
              labelValue={t(`${translationPath}specific_users`)}
              data={ allUsers || []}
              selectedValues={state.properties.createdBy.specificUsers}
              multiple
              displayLabel={(option) =>
              (option && option.fullName) || ''}
              chipsLabel={(option) => (option && option.fullName) || ''}
              withoutSearchButton
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                 if (searchTimer.current) clearTimeout(searchTimer.current);
                   searchTimer.current = setTimeout(() => {
                    getAllOrganizationUserSearch(value) ;  
                }, 700); 
                }}
                renderOption={
                  ((option) =>
                  <div className='d-flex-column'>
                   <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                    {option.fullName}
                   </div>
                   <span className='c-gray-secondary'> {option.branch} </span>
                 </div>
               )}
              isDisabled={state.properties.createdBy.allUsers}
              value={state.properties.createdBy.specificUsers}
              onChange={(e, newValues) => 
              {
                let propertiesCreatedBy = { ...state.properties.createdBy  , specificUsers : newValues };
                if( !newValues.length && setDefaultSelected({ ...state.properties.createdBy , specificUsers :false }))
                  propertiesCreatedBy = { ...state.properties.createdBy  , specificUsers : newValues , allUsers : true }
                  
                setState({ id: 'properties', value: { ...state.properties , createdBy  : propertiesCreatedBy } })
              }}
             isLoading={isLoading} 
             withLoader
             filterOptions={(options) => {
              const isFind = (id) => state.properties.createdBy.specificUsers.findIndex((w) => w.id === id) === -1;
             return options.filter((w) => isFind(w.id));
            }}
         /> 
        </div>
    </div>
    </div>
  );
};

PropertiesCreatedBy.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state:PropTypes.instanceOf(Object).isRequired,
  setState : PropTypes.func.isRequired,
  checkAtLeastOneSelected : PropTypes.func.isRequired, 
  checkAtLeastOneSelected2 : PropTypes.func.isRequired, 
  setDefaultSelected:PropTypes.func.isRequired,  
};

