import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PropertiesProfileGalleryComponentTabsData } from './PropertiesProfileGalleryComponentTabsData';
import { PropertiesImage } from './PropertiesImage/PropertiesImage';
import { PropertiesVideos } from './PropertiesVideos/PropertiesVideos';
import { LocationVideo } from './PropertiesVideos/LocationVideo';
import { TabsComponent } from '../../../../../../Components';
import { Locationimages } from './PropertiesImage/Locationimages';
import { returnPropsByPermissions } from '../../../../../../Helper';
import { PropertiesPermissionsCRM } from '../../../../../../Permissions';

export const PropertiesProfileGalleryComponent = ({ parentTranslationPath, translationPath }) => {
  const [activeTab, setActiveTab] = useState(0);
  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };

  const [canEditImage, setCanEditImage] = useState(false);

  useEffect(() => {
    if (returnPropsByPermissions(PropertiesPermissionsCRM.EditPropertyImageCategory.permissionsId))
      setCanEditImage(true);
    else
      setCanEditImage(false);

  }, []);

  return (
    <div className='properties-images-tabs mx-2 mt-3'>
      <div>
        <TabsComponent
          data={PropertiesProfileGalleryComponentTabsData}
          labelInput='tab'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          themeClasses='theme-curved'
          currentTab={activeTab}
          onTabChanged={onTabChanged}
        />
      </div>
      {activeTab === 0 && (
        <PropertiesImage
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          canEditImage={canEditImage}
          activeTab={activeTab}
        />
      )}
      {activeTab === 1 && (
        <PropertiesVideos
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          canEditImage={canEditImage}
          activeTab={activeTab}
        />

      )}
      {activeTab === 2 && (
        <Locationimages
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          canEditImage={canEditImage}
        />

      )}

      {activeTab === 3 && (
        <LocationVideo
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          canEditImage={canEditImage}
        />

      )}
    </div>
  );
};

PropertiesProfileGalleryComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
