import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, List, ListItem, ListItemText } from '@material-ui/core';
import FormattedMessage from "../../../../SharedComponents/FormattedComponent/FormattedMessage";

const parentTranslationPath = "LearningAndDevelopmentView";

const useStyles = makeStyles({
  list: {
    listStyleType: 'disc',
    marginLeft: '20px',
    '& .MuiListItem-root': {
      paddingInlineStart: "0px",
      display: 'list-item',
    },
  },
});

export function InstructorDialog({ data }) {
  const classes = useStyles();

  if (!data?.length) {
    return (
      <Box style={{ minHeight: 50 }}>
        <Typography>
          <FormattedMessage id="no-instructors-message" path={parentTranslationPath} />
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <List aria-label="course details">
        <ListItem>
          <ListItemText
            primary={
              <Typography>
                <b>
                  <FormattedMessage id="course-name" path={parentTranslationPath} />
                </b> {data?.[0].courseName}
              </Typography>
            }
          />
        </ListItem>
        <ListItem style={{ paddingBottom: 0 }}>
          <ListItemText primary={
            <Typography>
              <b>
                <FormattedMessage id="instructors" path={parentTranslationPath} />
              </b>
              <List className={classes.list}>
                {data?.map((module, index) => (
                  <ListItem key={index}>
                    <ListItemText 
                      primary={
                        <>
                        <b>{module?.instructorName}: </b> {module?.about}
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Typography>
          } />
        </ListItem>
      </List>
    </Box>
  );
}
