
import {
    ResaleUnitsView
  } from '../../Views/Home';
  
  export const ResaleUnitsRoutes = [
    {
      path: '/view',
      name: 'UnitsView:resale-units',
      component: ResaleUnitsView,
      layout: '/home/resale-units',
      default: true,
      isRoute: true,
      authorize: true,
      roles: [],
      isDisabled: false,
      isExact: true,
      breadcrumbs: [
        {
          name: 'UnitsView:resale-units',
          isDisabled: false,
          route: '/home/resale-units/view',
          groupName: 'sales',

        },
      ],
    },
  ];
  