export const MaqsamTokenServices = async () => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
  myHeaders.append('Authorization', 'Basic aU9weFJ1YU5TajkyZjJRZ09nMno6Yld1TnFIWEpxRXdNYUFwZ3lDNWo=');

  const urlencoded = new URLSearchParams();
  urlencoded.append('UserEmail', 'ahmad.k@psinv.net');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow'
  };

  return fetch('https://api.maqsam.com/v2/token', requestOptions)
    .then(async (response) => {
      if (response.status === 200) {
        try {
          const data = await response.json();
          return data.result.token;
        } catch (error) {
          console.log(error);
          return null;
        }
      } else {
        console.log(response);
        return null;
      }
    })

    .catch((error) => console.log('error', error));
};
