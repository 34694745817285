import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";

// Styles
import useStyles from "./styles";

const NoDataFound = ({ title = "contacts" }) => {
  const { isDarkMode } = useSelector((state) => state.theme);
  const styles = useStyles({ isDarkMode });

  return (
    <Box className={styles.flexCenter}>
      <Box className={styles.container}>
        <span>No data available for this page at the moment</span>
        <h4>{`No ${title} found`}</h4>
      </Box>
    </Box>
  );
};

export default NoDataFound;
