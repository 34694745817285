import { useLocation } from 'react-router-dom';
import { useIsNewLayout } from './useIsNewLayout';
import { NEW_LAYOUT_LIST } from '../Enums/NewLayoutList';

export const useLayoutDetection = () => {
  const { pathname } = useLocation();
  const isNewLayout = useIsNewLayout();

  // Check if current path is included in the new layout list
  const hasNewLayout = NEW_LAYOUT_LIST.some(name => pathname.includes(name));

  return { isNewLayout, hasNewLayout };
};
