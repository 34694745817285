import React, { useCallback, useEffect, useReducer, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import {
  GetUserManagedById,
  MicrosoftToken,
} from "../../../../Services/DashboardService";
import { PowerBiEnum } from "../../../../Enums";
import { GetUserTeamsInfo } from "../../../../Services/userServices";
import { GlobalTranslate, showError } from "../../../../Helper";
import { useTitle } from "../../../../Hooks";
import { SpinnerDashboards } from "../SpinnerDashboards/SpinnerDashboards";
import { PermissionDeniedLayout } from "../../../../Layouts/PermissionDeniedLayout/PermissionDeniedLayout";
import { CellSpinnerBi } from "../CellSpinnerBi/CellSpinnerBi";
import { GetAllCampaignDepartments, GetAllCampaignsConfig, GetUserId } from "../../../../Services";
import { ButtonBase } from "@material-ui/core";

export const CampaignRequests = () => {
  useTitle(GlobalTranslate.t("Dashboard:campaign-request-dashboard"));
  const userid = JSON.parse(localStorage.getItem("session")).userId;
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);


  const [reporttoken, setReporttoken] = useState();
  const [Appuser, setAppuser] = useState();
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);
  const [ShowReport2, setShowReport2] = useState(false);
  const [data, setData] = useReducer(reducer, {
    departments: [],
 
  });
  const [filterButton, setfilterButton] = useState(false);
  const [clearfilterButton, setclearfilterButton] = useState(false);
  const [activeThemeButton, setActiveThemeButton] = useState("blue");

  const loadBookmarks = async () => {
    const report = window.report;

    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        const fetchedBookmarks = await bookmarksManager.getBookmarks();
        // console.log("Bookmarks:", fetchedBookmarks);
      } catch (error) {
        console.error("Error loading bookmarks:", error);
      }
    }
  };
  const applyBookmark = async (bookmarkName) => {
    const report = window.report;
    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        bookmarksManager.apply(bookmarkName);
        // console.log("Bookmark applied successfully:", bookmarkName);
      } catch (error) {
        console.error("Error applying bookmark:", error);
      }
    }
  };

  // const handleButtonClick = (buttonName) => {
  //   setActiveButton(buttonName);
  // };

  // const FilterHide = () => {
  //   applyBookmark("");
  //   setfilterButton(false);
  //   setclearfilterButton(false);
  // };
  // const FilterView = () => {
  //   applyBookmark("");
  //   setfilterButton(true);
  //   setclearfilterButton(true);
  // };

  const handleRefreshClick = useCallback(() => {
    window.report
      .refresh()
      .then(() => {
        setShowReport2(false);
      })
      .catch((error) => {});
  }, []);

  // const onClearedAllFiltersClick = useCallback(() => {
  //   applyBookmark("");

  // }, []);

  
  const getAllCampaignDepartments = async (searchValue) => {
 
    const res = await GetAllCampaignDepartments({
      search: searchValue || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'departments', value: res || [] });
    } else setData({ id: 'departments', value: [] });

  };

//   const getAllCampaignsConfig = useCallback(async () => {
//      const res = await GetAllCampaignsConfig({
//         pageSize: filter.pageSize,
//         pageIndex: filter.pageIndex || (filter.pageIndex + 1),
//     });
//     if (!(res && res.status && res.status !== 200)) {
//         setCampaigns({
//             data: res.result || [],
//             totalCount: res.totalCount || 0
//         });
//     } else setCampaigns({
//         data: [],
//         totalCount: 0
//     });

 
// }, [filter]);



const getAppUserId = useCallback(async () => {
  const res = await GetUserId(userid);
  if (!(res && res.status && res.status !== 200)) {
    setAppuser(res);
  } else {
    setAppuser([]);
  }
}, [userid]);



  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReporttoken(res);
      setTimeout(() => {
        setRender(true);
      }, 500);
    } else {
      setReporttoken("");
      showError("Contact Your Bi Admin For Help");
    }
  };
  



  useEffect(() => {
    getAppUserId(userid);
    getAllCampaignDepartments();

  }, [userid ]);

  const FilterUser = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "CampaignRequestBi",
      column: "AssignedToId",
    },
    operator: "In",
    values: [Appuser],
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };

  // console.log(Appuser);
  // console.log(data);
  // console.log(campaigns);



  useEffect(() => {
    Microsoft(false);
  }, []);

  return (
    <div className="dashboardMain-PowerBIEmbed">
      <CellSpinnerBi isActive={!ShowReport} isAbsolute blue />

      <div className="MainFiltersBi">
        <CellSpinnerBi
          isActive={!ShowReport2}
          blue={activeThemeButton === "blue"}
        />

        <div className="DashboardFilter">
          <ButtonBase
            onClick={handleRefreshClick}
            id="onClearedAllFiltersref"
            className="btns theme-solid bg-primary-dark clear-all-btn Dashboardbtn"
          >
            <span className="mdi mdi-refresh m-1" />
          </ButtonBase>
        </div>
      </div>

      <br />
      {render ? (
        <div className="mainCardDashboards">
          <div
            className={`dashboardMain  ${
              activeThemeButton === "gold"
                ? "cardDashboardgold"
                : "cardDashboard"
            }`}
          >
            <PowerBIEmbed
              embedConfig={{
                type: "report",
                id: PowerBiEnum.CampaignRequest.reportid,
                embedUrl: PowerBiEnum.CampaignRequest.url,
                accessToken: reporttoken,
                filters: [FilterUser],
                settings: {
                  customLayout: {
                    displayOption: models.DisplayOption.FitToWidth,
                  },
                  filterPaneEnabled: false,
                  navContentPaneEnabled: false,
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false,
                    },
                  },
                  background: models.BackgroundType.Transparent,
                },
              }}
              eventHandlers={
                new Map([
                  [
                    "loaded",
                    () => {
                      setShowReport(true);
                      setShowReport2(false);
                    },
                  ],
                  [
                    "dataSelected",

                    (event) => {
                      setShowReport2(false);
                    },
                  ],

                  [
                    "rendered",
                    () => {
                      setShowReport2(true);
                      loadBookmarks();
                    },
                  ],
                  [
                    "error",
                    (event) => {
                      const error = event.detail;

                      if (
                        error.detailedMessage === "Get report failed" ||
                        error.detailedMessage ===
                          "Access token has expired, resubmit with a new access token" ||
                        error.detailedMessage ===
                          "Fail to initialize - Could not resolve cluster"
                      ) {
                        Microsoft(true);
                        setRender(false);
                      } else console.log(error.detailedMessage);
                      showError(error.detailedMessage);
                    },
                  ],
                ])
              }
              cssClassName={
                ShowReport
                  ? "report-style-class-Show"
                  : "report-style-class-hide"
              }
              getEmbeddedComponent={(embeddedReport) => {
                window.report = embeddedReport;
              }}
            />
          </div>
        </div>
      ) : (
        <div>{render && <PermissionDeniedLayout />}</div>
      )}
    </div>
  );
};
