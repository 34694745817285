

export const LostLeadsLogPermissions = {
  GetAllLostLeadsLog: {
    "permissionsId": "6ecc295d-f7a5-465f-2e4d-08db604be055",
    "permissionsName": "Get All Lost Leads Log",
    "description": null,
    "componentsId": null,
    "components": null
  }
};
