

import { HttpServices } from '../../Helper';
import { config } from '../../config';


export const GetAllPropertyRatingSurroundingAPI = async () => {

    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PropertyRating/GetAllPropertyRatingSurrounding`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
  };

  export const UpdateSurroundingsScoreAPI = async (body) => {
    const result = await HttpServices.post(
        `${config.server_address}/CrmDfm/PropertyRating/UpdatePropertyRatingSurroundingScores`, body
    )
        .then((data) => data)
        .catch((error) => error.response.data);
    return result;
};
