import React, {
  useEffect,
  useState,
  useCallback,
  useReducer,
  useRef,
} from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { AutocompleteComponent } from "../../../../../../../Components";
import { AgentRoleEnum, AgentTypesEnum, UnitsStatusEnum } from "../../../../../../../Enums";
import { OrganizationUserSearch } from "../../../../../../../Services";
import { Button } from "@material-ui/core";

export const AgentsSection = ({
  isReadOnly,
  unitData,
  reservationData,
  selected,
  onSelectedChanged,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [isFieldShown, setIsFieldShown] = useState({
    otherTenantAgents: false,
    otherLandlordAgents: false,
  });
  const [data, setData] = useReducer(reducer, {
    systemUsers: {
      landlordAgents: [],
      tenantAgents: [],
    },
  });

  const getSystemUsers = async (userType, searchedItem) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      userTypeId: AgentRoleEnum.LeaseAgent.value,
      name: searchedItem || "",
      userStatusId: 2,
    });
    if (!(res && res.status && res.status !== 200))
      setData({
        id: "systemUsers",
        value: {
          ...(data.systemUsers || []),
          [userType]: res?.result,
        },
      });
    else setData({ id: "systemUsers", value: [] });
  };

  // const handleListingAgentInit = () => {
  //   // const stateLandlordAgent = unitData?.rent_listing_agent && ({
  //   //   userId: unitData.rent_listing_agent.id,
  //   //   listingAgentName: unitData.rent_listing_agent.name,
  //   //   isListingAgent: true,
  //   //   listingAgentType: AgentTypesEnum.Landlord.value,
  //   //   listingAgentCommission: 0,
  //   // });
  //   const selectedLandLordAgent = unitData?.rent_listing_agent && {
  //     id: unitData.rent_listing_agent.id,
  //     fullName: unitData.rent_listing_agent.name,
  //     userName: unitData.rent_listing_agent.userName,
  //     isListingAgent: true,
  //     listingAgentType: AgentTypesEnum.Landlord.value,
  //     listingAgentCommission: 0,
  //   };

  //   // if (stateLandlordAgent) setState({ id: 'listingAgents', value: [stateLandlordAgent] })
  //   if (selectedLandLordAgent) {
  //     onSelectedChanged({ id: "listingAgents", value: [selectedLandLordAgent] });
  //     onSelectedChanged({ id: "landlordAgent", value: selectedLandLordAgent });
  //     onSelectedChanged({ id: "unitHasListingAgents", value: true });
  //   }
  // };
  // const checkIsReserve = () => {
  //   const isReservedLeasedStatus = unitData?.leaseUnitStatus?.key === UnitsStatusEnum.ReservedLeased.key;
  //   const isPendingReservedLeaseStatus = unitData?.leaseUnitStatus?.key === UnitsStatusEnum.PendingReservedLease.key;
  //   return (isReservedLeasedStatus || isPendingReservedLeaseStatus);
  // }
  // const fillReservedDataForAgents = async () => {
  //   if (
  //     reservationData?.agents &&
  //     (!selected.agents || selected.agents?.length == 0)
  //   ) {
  //     const selectedAgents = reservationData?.agents.map((item) => ({
  //       id: item.userId,
  //       fullName: item.agentName,
  //       isOriginalAgent: item.isOriginalAgent,
  //       agentType: item.agentType,
  //     }));

  //     const tenantAgent =
  //       selectedAgents.find((item) => item.isOriginalAgent == true) || null;
  //     const otherTenantAgents =
  //       selectedAgents.filter((item) => item.isOriginalAgent == false) || [];

  //     onSelectedChanged({ id: "agents", value: selectedAgents });
  //     onSelectedChanged({ id: "tenantAgent", value: tenantAgent });
  //     onSelectedChanged({
  //       id: "otherTenantAgents",
  //       value: otherTenantAgents.length > 0 && otherTenantAgents,
  //     });
  //   }

  //   // if (reservationData?.listingAgents && (!selected.listingAgents || selected.listingAgents?.length == 0) && unitData?.rent_listing_agent) {

  //   let selectedListingAgents = [];

  //   if (
  //     reservationData?.listingAgents &&
  //     (!selected.listingAgents || selected.listingAgents?.length == 0)
  //   ) {
  //     selectedListingAgents = reservationData?.listingAgents.map((item) => ({
  //       id: item.userId,
  //       fullName: item.listingAgentName,
  //       isListingAgent: item.isListingAgent,
  //       listingAgentType: item.listingAgentType,
  //       listingAgentCommission: item.listingAgentCommission,
  //     }));

  //     // const stateListingAgents = selectedListingAgents.map(item => ({
  //     //   userId: item.id,
  //     //   listingAgentName: item.fullName,
  //     //   isListingAgent: item.isListingAgent,
  //     //   listingAgentType: item.listingAgentType,
  //     //   listingAgentCommission: item.listingAgentCommission,
  //     // }))
  //     const otherLandlordAgents =
  //       selectedListingAgents.filter((item) => item.isListingAgent == false) ||
  //       [];

  //     // onStateChanged({ id: 'listingAgents', value: stateListingAgents })
  //     onSelectedChanged({ id: "listingAgents", value: selectedListingAgents });
  //     // onSelectedChanged({ id: 'landlordAgent', value: unitLandlordAgent })
  //     onSelectedChanged({
  //       id: "otherLandlordAgents",
  //       value: otherLandlordAgents.length > 0 && otherLandlordAgents,
  //     });
  //   }
  //   if (unitData?.rent_listing_agent) {
  //     const landlordAgentCommission =
  //       selectedListingAgents.length > 0
  //         ? selectedListingAgents[0].listingAgentCommission
  //         : 0;
  //     const unitLandlordAgent = {
  //       id: unitData.rent_listing_agent.id,
  //       fullName: unitData.rent_listing_agent.name,
  //       isListingAgent: true,
  //       listingAgentType: AgentTypesEnum.Landlord.value,
  //       listingAgentCommission: landlordAgentCommission,
  //     };
  //     if (selectedListingAgents?.length > 0) {
  //       selectedListingAgents.shift();
  //       selectedListingAgents.unshift(unitLandlordAgent);
  //     } else selectedListingAgents.push(unitLandlordAgent);

  //     onSelectedChanged({ id: "landlordAgent", value: unitLandlordAgent });
  //     onSelectedChanged({ id: "unitHasListingAgents", value: true });
  //   }
  // };

  // useEffect(() => {
  //   if (checkIsReserve()) fillReservedDataForAgents();
  //   else handleListingAgentInit();
  // }, [reservationData, unitData]);

  return (
    <>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">{t(`${translationPath}Tenant-agent`)}</span>
        </div>
        <div className="w-50 mt-2 mb-3 pt-1">
          <div className="d-flex-column gap-12">
            <AutocompleteComponent
              idRef="tenant-agentIdRef"
              inputPlaceholder="select"
              selectedValues={selected.tenantAgent}
              multiple={false}
              data={data.systemUsers?.tenantAgents || []}
              displayLabel={(option) => option.fullName || ""}
              renderOption={(option) =>
                ((option.userName || option.fullName) &&
                  `${option.fullName} (${option.userName})`) ||
                ""
              }
              withoutSearchButton
              onOpen={() => {
                const userType = "tenantAgents";
                if (
                  data.systemUsers?.tenantAgents &&
                  data.systemUsers.tenantAgents.length == 0
                )
                  getSystemUsers(userType);
              }}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                const userType = "tenantAgents";

                if (searchTimer) clearTimeout(searchTimer.current);

                searchTimer.current = setTimeout(() => {
                  getSystemUsers(userType, value);
                }, 1200);
              }}
              isWithError
              // isSubmitted={isSubmitted}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onChange={(_, newValue) => {
                let newSelected = [];
                let newTenantAgent =
                  (newValue && {
                    id: newValue.id,
                    fullName: newValue.fullName,
                    isOriginalAgent: true,
                    agentType: AgentTypesEnum.Tenant.value,
                  }) ||
                  null;

                if (newTenantAgent && !selected.otherTenantAgents) {
                  newSelected.push(newTenantAgent);
                }
                if (
                  newTenantAgent &&
                  !selected.tenantAgent &&
                  selected.otherTenantAgents
                ) {
                  newSelected = [newTenantAgent, ...selected.agents];
                }
                if (
                  newTenantAgent &&
                  selected.tenantAgent &&
                  selected.otherTenantAgents
                ) {
                  const agentsList = [...selected.agents];
                  agentsList.shift();
                  newSelected = [newTenantAgent, ...agentsList];
                }
                if (
                  !newTenantAgent &&
                  selected.tenantAgent &&
                  selected.otherTenantAgents
                ) {
                  const agentsList = [...selected.agents];
                  agentsList.shift();
                  newSelected = agentsList;
                }
                // const mappedStateAgents = newSelected.length > 0 && (newSelected.map(item => ({
                //   userId: item.id,
                //   agentCommission: 0,
                //   isOriginalAgent: item.id == newTenantAgent?.id,
                //   agentType: AgentTypesEnum.Tenant.value,
                // }))) || null;
                // const mappedTenantAgent = newTenantAgent && {
                //   userId: newTenantAgent.id,
                //   agentCommission: 0,
                //   isOriginalAgent: true,
                //   agentType: AgentTypesEnum.Tenant.value,
                //   agentName: newTenantAgent.fullName,
                // };
                if (newSelected.length > 0)
                  onSelectedChanged({ id: "agents", value: newSelected });
                // onStateChange({ id: 'agents', value: mappedStateAgents });
                else onSelectedChanged({ id: "agents", value: [] });
                // onStateChange({ id: 'agents', value: null });

                onSelectedChanged({ id: "tenantAgent", value: newTenantAgent });
                // onStateChange({ id: 'tenantAgent', value: mappedTenantAgent });
              }}
              isDisabled
              //isDisabled={isReadOnly}

            />
            {!isFieldShown.otherTenantAgents && (
              <span
                className="d-flex fw-simi-bold pointer "
                onClick={() =>
                  setIsFieldShown((prevState) => ({
                    ...prevState,
                    otherTenantAgents: true,
                  }))
                }
              >
                <span className="icons b-icon i-plus i-20 mb-1 mr-1"></span>
                <span className="fz-14 light-text">
                  {t(`${translationPath}Add-another`)}
                </span>
              </span>
            )}

            {isFieldShown.otherTenantAgents && (
              <AutocompleteComponent
                idRef="other-tenant-agentsIdRef"
                labelValue="other-tenant-agents"
                labelClasses="px-0"
                inputPlaceholder="select"
                selectedValues={selected.otherTenantAgents || []}
                multiple
                data={data.systemUsers?.tenantAgents || []}
                displayLabel={(option) => option.fullName || ""}
                chipsLabel={(option) => option.fullName || ""}
                renderOption={(option) =>
                  ((option.userName || option.fullName) &&
                    `${option.fullName} (${option.userName})`) ||
                  ""
                }
                // getOptionSelected={(option) => selected.otherTenantAgents && selected.otherTenantAgents.findIndex((item) => item.id === option.id) !== -1 || ''}
                withoutSearchButton
                // isLoading={loadings.agents}
                onOpen={() => {
                  const userType = "tenantAgents";
                  if (
                    data.systemUsers?.tenantAgents &&
                    data.systemUsers.tenantAgents.length == 0
                  )
                    getSystemUsers(userType);
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  const userType = "tenantAgents";

                  if (searchTimer) clearTimeout(searchTimer.current);

                  searchTimer.current = setTimeout(() => {
                    getSystemUsers(userType, value);
                  }, 1200);
                }}
                // isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(_, newValue) => {
                  let newSelected = [];
                  let newOtherTenantAgents = newValue.map((item) => ({
                    id: item.id,
                    fullName: item.fullName,
                    isOriginalAgent: false,
                    agentType: AgentTypesEnum.Tenant.value,
                  }));

                  if (!selected.tenantAgent) newSelected = newOtherTenantAgents;
                  else
                    newSelected = [
                      selected.tenantAgent,
                      ...newOtherTenantAgents,
                    ];

                  // const mappedStateAgents = newSelected && newSelected.length > 0 && (newSelected.map(item => ({
                  //   userId: item.id,
                  //   agentCommission: 0,
                  //   isOriginalAgent: item.isOriginalAgent,
                  //   agentType: AgentTypesEnum.Tenant.value,
                  // }))) || null;

                  if (newSelected.length > 0) {
                    // onStateChange({ id: 'agents', value: mappedStateAgents });
                    onSelectedChanged({ id: "agents", value: newSelected });
                    onSelectedChanged({
                      id: "otherTenantAgents",
                      value: newOtherTenantAgents,
                    });
                  } else {
                    onSelectedChanged({ id: "agents", value: [] });
                    // onStateChange({ id: 'agents', value: null });
                    onSelectedChanged({ id: "otherTenantAgents", value: null });
                  }
                }}
                isDisabled={isReadOnly}
                chipsDisabled={()=> isReadOnly}
              />
            )}
          </div>
        </div>
      </div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <p className="fw-simi-bold m-0">{t(`${translationPath}Landlord-agent`)}</p>
          <p className="light-text">{t(`${translationPath}Listing-agent`)}</p>
        </div>
        <div className="w-50 mt-2 mb-3 pt-1">
          <div className="d-flex-column gap-12">
            <AutocompleteComponent
              idRef="landlord-agentRef"
              inputPlaceholder="select"
              selectedValues={selected.landlordAgent}
              multiple={false}
              data={data.systemUsers?.landlordAgents || []}
              displayLabel={(option) => option.fullName || ""}
              renderOption={(option) =>
                ((option.userName || option.fullName) &&
                  `${option.fullName} (${option.userName})`) ||
                ""
              }
              isDisabled={
                (selected.unitHasListingAgents && !isReadOnly) || isReadOnly
              }
              withoutSearchButton
              onOpen={() => {
                const userType = "landlordAgents";
                if (
                  data.systemUsers?.landlordAgents &&
                  data.systemUsers.landlordAgents.length == 0
                )
                  getSystemUsers(userType);
              }}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                const userType = "landlordAgents";

                if (searchTimer) clearTimeout(searchTimer.current);

                searchTimer.current = setTimeout(() => {
                  getSystemUsers(userType, value);
                }, 1200);
              }}
              isWithError
              // isSubmitted={isSubmitted}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onChange={(_, newValue) => {
                let newSelected = [];
                let newLandlordAgent =
                  (newValue && {
                    id: newValue.id,
                    fullName: newValue.fullName,
                    isListingAgent: true,
                    listingAgentType: AgentTypesEnum.Landlord.value,
                  }) ||
                  null;

                if (newLandlordAgent && !selected.otherLandlordAgents) {
                  newSelected.push(newLandlordAgent);
                }
                if (
                  newLandlordAgent &&
                  !selected.landlordAgent &&
                  selected.otherLandlordAgents
                ) {
                  newSelected = [newLandlordAgent, ...selected.listingAgents];
                }
                if (
                  newLandlordAgent &&
                  selected.landlordAgent &&
                  selected.otherLandlordAgents
                ) {
                  const agentsList = [...selected.listingAgents];
                  agentsList.shift();
                  newSelected = [newLandlordAgent, ...agentsList];
                }
                if (
                  !newLandlordAgent &&
                  selected.landlordAgent &&
                  selected.otherLandlordAgents
                ) {
                  const agentsList = [...selected.listingAgents];
                  agentsList.shift();
                  newSelected = agentsList;
                }
                // const mappedStateAgents = newSelected.length > 0 && (newSelected.map(item => ({
                //   userId: item.id,
                //   listingAgentCommission: 0,
                //   isListingAgent: item.id == newLandlordAgent?.id,
                //   listingAgentType: AgentTypesEnum.Landlord.value
                // }))) || null;
                if (newSelected.length > 0)
                  onSelectedChanged({ id: "listingAgents", value: newSelected });
                // onStateChange({ id: 'listingAgents', value: mappedStateAgents });
                else onSelectedChanged({ id: "listingAgents", value: [] });
                // onStateChange({ id: 'listingAgents', value: null });

                onSelectedChanged({
                  id: "landlordAgent",
                  value: newLandlordAgent,
                });
                // onStateChange({ id: 'landlordAgent', value: newLandlordAgent });
              }}
            />
            {!isFieldShown.otherLandlordAgents && (
              <span
                className="d-flex fw-simi-bold pointer "
                onClick={() =>
                  setIsFieldShown((prevState) => ({
                    ...prevState,
                    otherLandlordAgents: true,
                  }))
                }
              >
                <span className="icons b-icon i-plus i-20 mb-1 mr-1"></span>
                <span className="fz-14 light-text">
                  {t(`${translationPath}Add-another`)}
                </span>
              </span>
            )}

            {isFieldShown.otherLandlordAgents && (
              <AutocompleteComponent
                idRef="other-landlord-agentsIdRef"
                labelValue="other-landlord-agents"
                labelClasses="px-0"
                inputPlaceholder="select"
                selectedValues={selected.otherLandlordAgents || []}
                multiple
                data={data.systemUsers?.landlordAgents || []}
                displayLabel={(option) => option.fullName || ""}
                chipsLabel={(option) => option.fullName || ""}
                renderOption={(option) =>
                  ((option.userName || option.fullName) &&
                    `${option.fullName} (${option.userName})`) ||
                  ""
                }
                // getOptionSelected={(option) => selected.otherLandlordAgents && selected.otherLandlordAgents.findIndex((item) => item.id === option.id) !== -1 || ''}
                withoutSearchButton
                // isLoading={loadings.agents}
                onOpen={() => {
                  const userType = "landlordAgents";
                  if (
                    data.systemUsers?.landlordAgents &&
                    data.systemUsers.landlordAgents.length == 0
                  )
                    getSystemUsers(userType);
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  const userType = "landlordAgents";

                  if (searchTimer) clearTimeout(searchTimer.current);

                  searchTimer.current = setTimeout(() => {
                    getSystemUsers(userType, value);
                  }, 1200);
                }}
                // isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(_, newValue) => {
                  let newSelected = [];
                  let newOtherLandlordAgents = newValue.map((item) => ({
                    id: item.id,
                    fullName: item.fullName,
                    isListingAgent: false,
                    listingAgentType: AgentTypesEnum.Landlord.value,
                  }));
                  if (!selected.landlordAgent) {
                    newSelected = newOtherLandlordAgents;
                  } else {
                    newSelected = [
                      selected.landlordAgent,
                      ...newOtherLandlordAgents,
                    ];
                  }

                  // const mappedStateAgents = newSelected && newSelected.length > 0 && (newSelected.map(item => ({
                  //   userId: item.id,
                  //   listingAgentCommission: 0,
                  //   isListingAgent: item.isListingAgent,
                  //   listingAgentType: AgentTypesEnum.Landlord.value,
                  // }))) || null;

                  if (newSelected.length > 0) {
                    // onStateChange({ id: 'listingAgents', value: mappedStateAgents });
                    onSelectedChanged({
                      id: "listingAgents",
                      value: newSelected,
                    });
                    onSelectedChanged({
                      id: "otherLandlordAgents",
                      value: newOtherLandlordAgents,
                    });
                  } else {
                    onSelectedChanged({ id: "listingAgents", value: [] });
                    // onStateChange({ id: 'listingAgents', value: null });
                    onSelectedChanged({
                      id: "otherLandlordAgents",
                      value: null,
                    });
                  }
                }}
                isDisabled={isReadOnly}
                chipsDisabled={()=> isReadOnly}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

AgentsSection.propTypes = {};
