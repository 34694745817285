import React, { Children, useEffect, useRef, useState } from 'react';
import { PageHeading } from '../../../../../../SharedComponents';
import { Inputs, Spinner } from '../../../../../../Components';
import { ActivityTypeCard } from './ActivityTypeCard';
import { CreateLeadStageActivityType } from '../../../../../../Services';
import { showError } from '../../../../../../Helper';

export const ActivityExplorerComponent = ({
  parentTranslationPath,
  translationPath,
  activities,
  isLoading,
  currentStageId,
  reload,
  activeCard,
  setActiveCard,
}) => {
  const [activitiesToDisplay, setActivitiesToDisplay] = useState(activities);
  const selectHandler = async (i) => {
    const res = await CreateLeadStageActivityType({
      activityTypeId: i.activityTypeId,
      leadStageId: currentStageId,
      isRequired: !!i.isRequired,
    });
    if (res) {
      reload();
    } else showError('something went wrong, please try again');
  };
  useEffect(() => {
    setActivitiesToDisplay(activities);
  }, [activities]);
  return (
    <div className='stage-view-container utility-white p-3 my-3 w-100'>
      <PageHeading
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        headerTitle={'All-activities'}
        subTitle={'All-activities-heading'}
        wrapperClasses={'w-50'}
        headerTitleClasses={'fz-16 mb-2'}
        subTitleClasses={'fz-14 m-0 ml-0'}
      />
      <Inputs
        idRef='staffCodeRef'
        inputPlaceholder='activity-name'
        wrapperClasses='mb-0'
        onKeyUp={(event) => {
          const { value } = event?.target;
          if (!!value) {
            setActivitiesToDisplay(
              activities.filter((i) =>
                i.activityTypeName.toLowerCase().includes(value.toLowerCase())
              )
            );
          } else setActivitiesToDisplay(activities);
        }}
        startAdornment={
          <span className='icons b-icon i-search-lg i-20 mb-1 ml-2 opacity-5'></span>
        }
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
      <div className='activity-card-container w-100 mt-3'>
        {isLoading ? (
          <Spinner isActive />
        ) : (
          Children.toArray(
            activitiesToDisplay.map((item) => (
              <ActivityTypeCard
                isActiveCard={item.activityTypeId === activeCard}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                item={item}
                onSelect={selectHandler}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
              />
            ))
          )
        )}
      </div>
    </div>
  );
};
