import React , { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Inputs } from '../../../../../../Components';

export const Bedrooms = ({
  parentTranslationPath,
  translationPath,
  value,
  setvalue,
  helperText,
  isSubmitted,
  errors ,
  checkValues
}) => {
  const { t } = useTranslation(parentTranslationPath);
  return(
    <>
    <div className='room'>
    <Inputs
      idRef='BedroomsRef'
      labelValue='Bedrooms'
      value={value}
      type='string'
      isSubmitted={isSubmitted}
      helperText={(helperText  &&  helperText !=='' &&  t(`${translationPath}please-enter-values-two-digit-numbers-only`))}
      error={errors  && errors.bedrooms }  
      isWithError
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      onInputChanged={(event) => {
        const { value } =  event.target ; 
        checkValues('bedrooms' , value);
        setvalue(value);
      }}
    />
   </div>
   </>
    );
};

Bedrooms.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  isSubmitted: PropTypes.string.isRequired,
  setvalue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
