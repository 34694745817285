import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    root: (props) => {
      const isText = props.variant === 'text';
      const isContained = props.variant === 'contained';

      return {
        boxShadow: theme.shadows[props.elevation],
        padding: props.sizeStyles.padding,
        borderRadius: theme.borderRadius[props.borderRadius],

        ...(!isText ? {
          background: theme.palette.button?.[`${props.color}_bg`],
        } : {}),

        ...(!isText ? {
          border: `1px solid ${theme.palette.button?.[`${props.color}_border`]}`,
        } : {}),

        ...(isContained ? {
          border: `1px solid ${theme.palette.button?.[`${props.color}_border`]}`,
        } : {}),

        '&:hover': {
          ...(props.hideHoverBg ? {
            background: 'none',
          } : {
            background: `${theme.palette.button[`${props.color}_bg_hover`]}`,
          }),

          boxShadow: "none",

          ...(isContained ? {
            border: `1px solid ${theme.palette.button?.[`${props.color}_border_hover`]}`,
          } : {
            '& svg, & svg path': {
              fill: theme.palette.button?.[`${props.color}_fg_hover`],
            },
          }),
        },

        '&:focus': {
          ...(props.color === 'primary' || props.color === 'secondaryColor' ? {
            boxShadow: theme.shadows[12],
          } : {}),

          ...(props.color === 'secondary' || props.color === 'secondaryGray' || props.color === 'lightGray' ? {
            boxShadow: theme.shadows[14],
          } : {}),
          
          ...(isText ? {
            boxShadow: theme.shadows[0],
          } : {}),
        },

        '&:disabled': {
          ...(props.color === 'primary' ? {
            background: theme.palette.background.disabled,
          } : {}),

          ...(props.color === 'secondaryGray' || props.color === 'secondaryColor' ? {
            background: theme.palette.background.primary,
          } : {}),

          '& svg, & svg path': {
            fill: theme.palette.foreground.disabled,
          },

          ...(!isText ? {
            border: `1px solid ${theme.palette.border.disabled_subtle}`,
          } : {}),
        },

        ...(props?.isDropDown ? {
          '& .MuiIconButton-label': {
            justifyContent: 'space-between',
            padding: '0 4px',
            fontSize: '16px',
          },

          '&:hover, &:focus': {
              boxShadow: 'none'
            }
        } : {}),
      }
    },
  }
});
