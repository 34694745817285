import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

function UserLearningCard({ title, text, footer, hidden, isNumber }) {
  return (
    <div className='learning-card-item' hidden={hidden}>
      <h6 className='learning-card-title '>
        <strong>{title}</strong>
      </h6>
      <p className={clsx(['learning-card-text', isNumber && 'number'])}>
        {text}
      </p>
      {footer}
    </div>
  );
}

UserLearningCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  footer: PropTypes.node,
  hidden: PropTypes.bool,
  isNumber: PropTypes.bool,
};

export default UserLearningCard;
