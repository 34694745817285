import React, {
  useCallback, useEffect, useReducer, useRef, useState
} from 'react';
import {
  DialogActions, DialogContent, DialogTitle, Dialog, ButtonBase, Chip
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Joi from 'joi';
import moment from 'moment';
import {
  AutocompleteComponent,
  DataFileAutocompleteComponent,
  DatePickerComponent,
  Inputs,
  SelectComponet,
  Spinner,
  SwitchComponent,
  PermissionsComponent,
  UploaderActivitieFileComponent,
  DialogComponent,
} from '../../../../../Components';

import {
  floatHandler, getDownloadableLink, getErrorByName, GlobalTranslate, showError, showSuccess
} from '../../../../../Helper';
import {
  OrganizationUserSearch,
  GetActivityTypeBasedOnRelatedType,
  CreateActivity,
  EditActivity,
  GetLeads,
  getUnits,
  GetAllPortfolio,
  GetAllWorkOrders,
  GetAllMaintenanceContract,
} from '../../../../../Services';
import { UnitMapper } from '../../../UnitsView/UnitMapper';
import { ReminderTypesEnum, UnitsOperationTypeEnum, LeadsStatusEnum, ActivityTypeReminderToEnum,
   ActivitiesRelatedToActivitiesTypeEnum } from '../../../../../Enums';
import { ActivitiesSalesPermissions } from '../../../../../Permissions/Sales/ActivitiesSalesPermissions';
import { GetActivityTypeById } from '../../../../../Services/ActivitiesTypesServices';

export const ActivitiesManagementDialog = ({
  activeItem,
  onSave,
  parentTranslationPath,
  translationPath,
  open,
  close,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const searchTimer = useRef(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reminder, setReminder] = useState();
  const [withDateTime, setwithDateTime] = useState(true);
  const [AssignActivity, setAssignActivity] = useState(false);
  const [isOpenGallery, setIsOpenGallery] = useState(false);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [activityRelatedToList, setActivityRelatedToList] = useState([]);
  const [isRelatedToNotSelected, setIsRelatedToNotSelected] = useState(true);
  const [activityTypeReminders, setActivityTypeReminders] = useState(null)

  const [reminderAmountOfTimeTypes] = useState([
    {
      key: 1,
      value: 'minutes', 
      momentKey: 'minutes',
    },
    {
      key: 2,
      value: 'hours',
      momentKey: 'hours',
    },
    {
      key: 3,
      value: 'days',
      momentKey: 'days',
    },
  ]);


  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [filter] = useState({
    pageIndex: 0,
    pageSize: 25,
  });
  const [rols, setrols] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [loadings, setLoadings] = useReducer(reducer, {
    activityAssignments: false,
    activityTypes: false,
    units: false,
    relatedUnit: false,
    relatedLead: false,
    relatedPortfolio: false,
    relatedWorkOrder: false,
    relatedMaintenanceContract: false,
  });
  const [selected, setSelected] = useReducer(reducer, {
    activityAssign: null,
    activityType: null,
    unit: null,
    activeFormType: 1,
    relatedUnit: null,
    relatedLead: null,
    relatedPortfolio: null,
    relatedWorkOrder: null,
    relatedMaintenanceContract: null,
    reminderPersons: [],
  });
  const [data, setData] = useReducer(reducer, {
    activityAssignments: [],
    activityTypes: [],
    units: [],
    relatedLead: [],
    relatedUnit: [],
    relatedPortfolio: [],
    relatedWorkOrder: [],
    relatedMaintenanceContract: []
  });
  const [state, setState] = useReducer(reducer, {
    assignAgentId: null,
    activityTypeId: null,
    unitId: null,
    relatedUnitNumberId: null,
    relatedLeadNumberId: null,
    relatedPortfolioId: null,
    relatedWorkOrderId: null,
    relatedMaintenanceContractId: null,
    activityDate: moment().add(2, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
    subject: null,
    comments: null,
    isOpen: true,
    activityReminders: [],
    createdByName: (loginResponse && loginResponse.fullName) || null,
    fileId: null,
    fileName: null,
  });

  const defaultActivityReminderItem = useRef({
    reminderType: 1,
    contactId: null,
    usersId: null,
    reminderTime: state.activityDate,
    reminderAmountOfTimeType: 1,
  });
  const getTimeValue = (item) => {
    if (!state.activityDate) return 0;
    const currentTimeAmountType = reminderAmountOfTimeTypes.find(
      (items) => items.key === (item.reminderAmountOfTimeType || 1)
    );

    return moment(state.activityDate).diff(item.reminderTime, currentTimeAmountType.momentKey);
  };

  const schema = Joi.object({
    assignAgentId: Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}activity-assign-to-is-required`),
      }),
    activityTypeId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}activity-type-is-required`),
      }),
    relatedUnitNumberId: Joi.any()
      .custom((value, helpers) => {
        if (!value && isRelatedToNotSelected) return helpers.error('state.required');

        return value;
      })
      .messages({
        'state.required': t(`${translationPath}related-to-is-required-one-at-least`),
      }),
    relatedLeadNumberId: Joi.any()
      .custom((value, helpers) => {
        if (!value && isRelatedToNotSelected) return helpers.error('state.required');

        return value;
      })
      .messages({
        'state.required': t(`${translationPath}related-to-is-required-one-at-least`),
      }),
    relatedPortfolioId: Joi.any()
      .custom((value, helpers) => {
        if (!value && isRelatedToNotSelected) return helpers.error('state.required');

        return value;
      })
      .messages({
        'state.required': t(`${translationPath}related-to-is-required-one-at-least`),
      }),
    relatedWorkOrderId: Joi.any()
      .custom((value, helpers) => {
        if (!value && isRelatedToNotSelected) return helpers.error('state.required');

        return value;
      })
      .messages({
        'state.required': t(`${translationPath}related-to-is-required-one-at-least`),
      }),
    relatedMaintenanceContractId: Joi.any()
      .custom((value, helpers) => {
        if (!value && isRelatedToNotSelected) return helpers.error('state.required');

        return value;
      })
      .messages({
        'state.required': t(`${translationPath}related-to-is-required-one-at-least`),
      }),
    activityDate: ((!activeItem) && (
      (withDateTime && (Joi.date()
        .required()
        .greater(Date.now())
        .messages({
          'date.base': t(`${translationPath}activity-date-is-required`),
          'date.greater': t(`${translationPath}choose-time-after-now`),
        })) || Joi.any()))) || Joi.any(),

    activityReminders:
      (reminder &&
        Joi.array().items(
          Joi.object({
            reminderType: Joi.any(),
            contactId: Joi.any(),
            usersId: Joi.any(),
            reminderTime: Joi.any(),
          })
            .custom((value, helpers) => {
              if (
                !value.contactId &&
                !value.usersId &&
                ((value.reminderTime && getTimeValue(value)) || 0) > 0
              )
                return helpers.error('state.userNotSet');
              if (
                (value.contactId || value.usersId) &&
                ((value.reminderTime && getTimeValue(value)) || 0) === 0
              )
                return helpers.error('state.timeIsZero');
              return value;
            })
            .messages({
              'state.userNotSet': t(`${translationPath}reminder-for-is-required`),
              'state.timeIsZero': t(`${translationPath}time-must-be-greater-than-zero`),
            })
        )) ||
      Joi.any(),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);
  const getAllActivityAssignments = useCallback(
    async (value, selectedValue) => {
      setLoadings({ id: 'activityAssignments', value: true });
      const res = await OrganizationUserSearch({
        ...filter,
        rolesIds: rols,
        name: ((value && value.value) || ''),
      });
      if (!(res && res.status && res.status !== 200)) {
        const localValue = (res && res.result) || [];
        // if (selectedValue && localValue.findIndex((item) => item.id === selectedValue.id) === -1)
        //   value.push(selectedValue);
        if (localValue.length > 0) {
          setData({
            id: 'activityAssignments',
            value: localValue,
          });
        } else {
          setData({
            id: 'activityAssignments',
            value: [],
          });
        }

        setLoadings({ id: 'activityAssignments', value: false });
      }
    },
    [filter, rols]
  );

  const getAllRelatedUnits = useCallback(
    async (value) => {
      setLoadings({ id: 'relatedUnit', value: true });
      const response = await getUnits({ ...filter, search: value, operationType: UnitsOperationTypeEnum.sale.key });
      if (!(response && response.status && response.status !== 200)) {
        const unitMapped = (response && response.result).map((item) => UnitMapper(item)) || [];
        setData({
          id: 'relatedUnit',
          value: unitMapped || [],
        });
      } else setData({ id: 'relatedUnit', value: [] });

      setLoadings({ id: 'relatedUnit', value: false });
    },
    [filter]
  );

  const getAllRelatedLeads = useCallback(
    async (value) => {
      setLoadings({ id: 'relatedLead', value: true });
      const response = await GetLeads({ ...filter, search: value, leadStatus: LeadsStatusEnum.Open.status });
      if (!(response && response.status && response.status !== 200))
        setData({ id: 'relatedLead', value: (response && response.result) || [] });
      else setData({ id: 'relatedLead', value: [] });

      setLoadings({ id: 'relatedLead', value: false });
    },
    [filter]
  );

  const getAllRelatedPortfolios = useCallback(
    async (value) => {
      setLoadings({ id: 'relatedPortfolio', value: true });
      const response = await GetAllPortfolio({ ...filter, search: value });

      if (!(response && response.status && response.status !== 200))
        setData({ id: 'relatedPortfolio', value: (response && response.result) || [] });
      else setData({ id: 'relatedPortfolio', value: [] });

      setLoadings({ id: 'relatedPortfolio', value: false });
    },
    [filter]
  );
  const getAllRelatedWorkOrders = useCallback(
    async (value) => {
      setLoadings({ id: 'relatedWorkOrder', value: true });
      const response = await GetAllWorkOrders({ ...filter, search: value });

      if (!(response && response.status && response.status !== 200))
        setData({ id: 'relatedWorkOrder', value: (response && response.result) || [] });
      else setData({ id: 'relatedWorkOrder', value: [] });

      setLoadings({ id: 'relatedWorkOrder', value: false });
    },
    [filter]
  );
  const getAllRelatedMaintenanceContracts = useCallback(
    async (value) => {
      setLoadings({ id: 'relatedMaintenanceContract', value: true });
      const response = await GetAllMaintenanceContract({ ...filter, search: value });

      if (!(response && response.status && response.status !== 200))
        setData({ id: 'relatedMaintenanceContract', value: (response && response.result) || [] });
      else setData({ id: 'relatedMaintenanceContract', value: [] });

      setLoadings({ id: 'relatedMaintenanceContract', value: false });
    },
    [filter]
  );

  const getAllUnits = useCallback(
    async (value) => {
      setLoadings({ id: 'units', value: true });
      const response = await getUnits({ ...filter, search: value });
      if (!(response && response.status && response.status !== 200)) {
        const unitMapped = (response && response.result).map((item) => UnitMapper(item)) || [];
        setData({
          id: 'units',
          value: unitMapped || [],
        });
      } else setData({ id: 'units', value: [] });

      setLoadings({ id: 'units', value: false });
    },
    [filter]
  );
  const GetActivityType = useCallback(async () => {
    setLoadings({ id: 'activityTypes', value: true });
    if (selected.activityType !== null) {
      const res = await GetActivityTypeById(selected.activityType.activityTypeId);
      if (!(res && res.status && res.status !== 200)) {
        setReminder(res.withReminder);
        setwithDateTime(res.withDateTime);

        setActivityTypeReminders(res.activityTypeReminders);

        const relatedToResponse = res.relatedTo&&res.relatedTo.filter(item=>(item.relatedToName!=='Contact'));

        setActivityRelatedToList(relatedToResponse || [])
      } else setReminder(res.withReminder); setwithDateTime(res.withDateTime);
    }

    setLoadings({ id: 'activityTypes', value: false });
  }, [selected.activityType]);

  const getAllActivityTypes = useCallback(async () => {
    setLoadings({ id: 'activityTypes', value: true });

    const res = await GetActivityTypeBasedOnRelatedType(ActivitiesRelatedToActivitiesTypeEnum.unit.key);
    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: 'activityTypes',
        value: res || [],
      });
    } else {
      setData({
        id: 'activityTypes',
        value: [],
      });
    }
    setLoadings({ id: 'activityTypes', value: false });
  }, []);

  const changeActiveFormType = (newValue) => {
    setSelected({
      id: 'edit',
      value: {
        ...selected,
        activeFormType: +newValue,
        relatedUnit: null,
        relatedLead: null,
      },
    });
    if (state.relatedLeadNumberId) setState({ id: 'relatedLeadNumberId', value: null });
    if (state.relatedUnitNumberId) setState({ id: 'relatedUnitNumberId', value: null });
  };

  const saveHandler = async (event) => {
    setIsSubmitted(true);
    event.preventDefault();
    if (withDateTime === true && !activeItem) {
      if (!state.activityDate) {
        showError(t(`${translationPath}choose-time-after-now`));
        setIsLoading(false);
        return;
      }
    }
    if (schema.error) {
      showError(t('Shared:please-fix-all-errors'));
      return;
    }
    const saveState = { ...state };
    saveState.activityReminders = saveState.activityReminders.filter(
      (item) => item.reminderTime !== 0 && (item.usersId || item.contactId)
    );
    setIsLoading(true);
    const res =
      (activeItem &&
        activeItem.activityId &&
        (await EditActivity(activeItem.activityId, saveState))) ||
      (await CreateActivity(saveState));
    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) {
      if (activeItem && activeItem.activityId)
        showSuccess(t`${translationPath}activity-updated-successfully`);
      else showSuccess(t`${translationPath}activity-created-successfully`);
      if (onSave) onSave();
    } else if (activeItem && activeItem.activityId)
      showError(t(`${translationPath}activity-update-failed`));
    else showError(t`${translationPath}activity-create-failed`);
  };
  const reminderTimeCalculateHandler = useCallback(
    (item, value, type, activityDate) => {
      const currentTimeAmountType = reminderAmountOfTimeTypes.find(
        (items) => items.key === (type || item.reminderAmountOfTimeType || 1)
      );
      let newTimeValue = moment(activityDate || state.activityDate)
        .add(-value, currentTimeAmountType.momentKey)
        .format('YYYY-MM-DDTHH:mm:ss');

      if (moment(newTimeValue).isBefore(moment().format('YYYY-MM-DDTHH:mm:ss')))
        newTimeValue = moment().format('YYYY-MM-DDTHH:mm:ss');
      setState({
        id: 'activityReminders',
        value: state.activityReminders,
      });
      return newTimeValue;
    },
    [reminderAmountOfTimeTypes, state.activityDate, state.activityReminders]
  );

  const reminderTimeChangeHandler = useCallback(
    (item) => (event) => {
      const value = floatHandler(event.target.value, 0);
      item.reminderTime = reminderTimeCalculateHandler(item, value);
    },
    [reminderTimeCalculateHandler]
  );


  const activityTimeChangeHandler = (activityDate) => {
    if (state.activityReminders) {
      const mappedActiveItemReminders = state.activityReminders.map(item => {
        return {
          ...item,
          reminderTime: reminderTimeCalculateHandler(item,
            getTimeValue(item) || 0,
            item.reminderAmountOfTimeType,
            activityDate)
        }
      })
      setState({
        id: 'activityReminders',
        value: mappedActiveItemReminders,
      });

    }
  }
  const reminderDecrementHandler = useCallback(
    (index) => () => {
      const localActivityReminders = [...state.activityReminders];
      localActivityReminders.splice(index, 1);
      setState({
        id: 'activityReminders',
        value: localActivityReminders,
      });
    },
    [state.activityReminders]
  );
  const reminderIncrementHandler = useCallback(
    (index) => () => {
      const localActivityReminders = [...state.activityReminders];
      localActivityReminders.splice(index, 0, { ...defaultActivityReminderItem.current });
      setState({
        id: 'activityReminders',
        value: localActivityReminders,
      });
    },
    [state.activityReminders]
  );

  const relatedToChangeHandler = (item, newValue)=>{


    setState({
      id: (((item.relatedToName==='Unit') && 'relatedUnitNumberId' ) ||
      ((item.relatedToName==='Lead') && 'relatedLeadNumberId' ) ||
      ((item.relatedToName==='Portfolio') && 'relatedPortfolioId' ) ||
      ((item.relatedToName==='WorkOrder') && 'relatedWorkOrder' ) ||
      ((item.relatedToName==='MaintenanceContract') && 'relatedMaintenanceContractId' ) ||
      ''),
      value: (
        newValue && ((item.relatedToName === 'Unit' && newValue.id) ||
        (item.relatedToName === 'Lead' && newValue.leadId) ||
        (item.relatedToName === 'Portfolio' && newValue.portfolioId) ||
        (item.relatedToName === 'WorkOrder' && newValue.id) ||
        (item.relatedToName === 'MaintenanceContract' && newValue.maintenanceContractId)) ||
        null
        )
      });
      
      setSelected({
        id: (((item.relatedToName==='Unit') && 'relatedUnit' ) ||
        ((item.relatedToName==='Lead') && 'relatedLead' ) ||
        ((item.relatedToName==='Portfolio') && 'relatedPortfolio' ) ||
        ((item.relatedToName==='WorkOrder') && 'relatedWorkOrder' ) ||
        ((item.relatedToName==='MaintenanceContract') && { maintenanceContractId: state.relatedMaintenanceContractId } ) || ''),
        // ((item.relatedToName==='MaintenanceContract') && 'relatedMaintenanceContract' ) || ''),
        value: newValue
      });
  

    if(!reminder){
      const localReminderPersons = [...selected.reminderPersons];
      const localReminderPersonIndex = localReminderPersons.findIndex(
        (item) => item.type === 'contact'
      );

      if (newValue && item.relatedToName === 'Lead') {
        const itemToPush = {
          id: newValue.lead && newValue.lead.contact_name && newValue.lead.contact_name.id,
          value:
          (newValue.lead &&
            newValue.lead.contact_name &&
            newValue.lead.contact_name.name) ||
            '',
          type: 'contact',
        };
        if (localReminderPersonIndex !== -1)
          localReminderPersons[localReminderPersonIndex] = itemToPush;
        else localReminderPersons.push(itemToPush);
        setSelected({ id: 'reminderPersons', value: localReminderPersons });
      } else if (localReminderPersonIndex !== -1) {
        localReminderPersons.splice(localReminderPersonIndex, 1);
        setSelected({ id: 'reminderPersons', value: localReminderPersons });
      }
    }
  }


  useEffect(() => {
    getAllActivityTypes();

    getAllRelatedUnits();
    getAllRelatedLeads();
    getAllRelatedPortfolios();
    getAllRelatedWorkOrders();
    getAllRelatedMaintenanceContracts();

    getAllUnits();
    GetActivityType();
  }, [getAllActivityTypes, getAllUnits, GetActivityType, getAllRelatedUnits, getAllRelatedLeads, getAllRelatedPortfolios, getAllRelatedWorkOrders, getAllRelatedMaintenanceContracts]);
  useEffect(() => {
    getAllActivityAssignments();
  }, [getAllActivityAssignments, rols]);

  useEffect(() => {
    if (activeItem) {
      setState({
        id: 'edit',
        value: {
          assignAgentId: activeItem.agentUserId,
          activityTypeId: activeItem.activityTypeId,
          unitId: activeItem.unitId,
          relatedLeadNumberId: activeItem.relatedLeadNumberId,
          relatedUnitNumberId: activeItem.relatedUnitNumberId,
          relatedPortfolioId: activeItem.relatedPortfolioId,
          relatedWorkOrderId: activeItem.relatedWorkOrderId,
          relatedMaintenanceContractId: activeItem.relatedMaintenanceContractId,
          activityDate: activeItem.activityDate,
          comments: activeItem.comments,
          isOpen: activeItem.isOpen,
          activityReminders: activeItem.activityReminders || [],
          subject: activeItem.subject,
          createdByName: activeItem.createdByName,
          fileName: activeItem.fileName,
          fileId: activeItem.fileId,
          files: activeItem.activityFiles || []
        },
      });
      setEdit(true);
      displayActivityInitialFiles(activeItem.activityFiles);
    }
  }, [activeItem]);

  useEffect(() => {
    if (activeItem) {
      const activityAssign = {
        id: activeItem.agentUserId,
        userName: activeItem.agentUsername,
        fullName: activeItem.agentName,
      };
      const reminderPersons = [
        { id: activityAssign.id, value: activityAssign.fullName, type: 'user' },
      ];

      const relatedLeadData = data.relatedLead && data.relatedLead.find(item => item.leadId === activeItem.relatedLeadNumberId)

      const relatedLeadContactId = relatedLeadData && relatedLeadData.lead &&
        relatedLeadData.lead.contact_name && relatedLeadData.lead.contact_name.id;

      const relatedUnit =
        (activeItem.relatedUnitNumberId && activeItem.relatedUnitPropertyName && {
          id: activeItem.relatedUnitNumberId,
          propertyName: activeItem.relatedUnitPropertyName,
          unitRefNo: activeItem.relatedUnitNumber,
        }) ||
        null;

      const relatedLead =
        (activeItem.relatedLeadNumberId && {
          leadId: activeItem.relatedLeadNumberId,
          contactId: relatedLeadContactId,
          lead: {
            contact_name: {
              name: activeItem.contactName || 'N/A',
            }
          },
        }) ||
        null;
        if (relatedLead&& !activeItem) {
        reminderPersons.push({
          id: relatedLead.contactId,
          value: relatedLead.lead.contact_name.name,
          type: 'contact',
        });
      }

      const relatedPortfolio = (activeItem.relatedPortfolioId && activeItem.relatedPortfolioName && {
        portfolioId: activeItem.relatedPortfolioId,
        portfolioName: activeItem.relatedPortfolioName
      }) || '';
      const relatedMaintenanceContract = (activeItem.relatedMaintenanceContractId && {
        maintenanceContractId: activeItem.maintenanceContractId
      }) || '';

      setSelected({
        id: 'edit',
        value: {
          activityAssign,
          activityType: activeItem.activityType,
          activeFormType: activeItem.relatedUnitNumberId ? 1 : 2,
          unit:
            (activeItem.unitId && {
              id: activeItem.unitId,
              propertyName: null,
            }) ||
            null,
          relatedLead,
          relatedUnit,
          relatedPortfolio,
          relatedMaintenanceContract,
          reminderPersons,
        },
      });
    }
  }, [activeItem, data.relatedLead]);

  useEffect(() => {
    if (
      !activeItem && activityTypeReminders && activityTypeReminders.length > 0
    ) {
      const mappedActivityTypeReminders = activityTypeReminders.map(item => {
        return {
          reminderType: item.reminderType,
          contactId: null,
          usersId: null,
          reminderTime: reminderTimeCalculateHandler(item,
            (item.reminderTime || 0),
            1),
          reminderAmountOfTimeType: 1,
        }
      })
      setState({
        id: 'activityReminders',
        value: mappedActivityTypeReminders,
      });
    } else if (!activeItem && state.activityReminders && state.activityReminders.length === 0 && activityTypeReminders && activityTypeReminders.length === 0) {
      setState({
        id: 'activityReminders',
        value: [{ ...defaultActivityReminderItem.current }],
      });
    }
  }, [activityTypeReminders, state.activityDate]);
  useEffect(() => {
    if (activeItem) {
      const assignIndex = data.activityAssignments.findIndex(
        (item) => item.id === activeItem.agentUserId
      );
      const unitIndex = data.units.findIndex((item) => item.id === activeItem.unitId);

      const relatedUnitIndex = data.relatedUnit.findIndex(
        (item) => item.id === activeItem.relatedUnitNumberId
      );
      const relatedLeadIndex = data.relatedLead.findIndex(
        (item) => item.leadId === activeItem.relatedLeadNumberId
      );

      // const relatedWorkOrderIndex = data.relatedWorkOrder.findIndex(
      //   (item) => item.workOrderId === activeItem.relatedWorkOrderId
      // );
      const relatedPortfolioIndex = data.relatedPortfolio.findIndex(
        (item) => item.portfolioId === activeItem.relatedPortfolioId
      );
      const relatedMaintenanceContractIndex = data.relatedMaintenanceContract.findIndex(
        (item) => item.maintenanceContractId === activeItem.relatedMaintenanceContractId
      );

      if (assignIndex === -1) {
        const activityAssign = {
          id: activeItem.agentUserId,
          userName: activeItem.agentUsername,
          fullName: activeItem.agentName,
        };
        data.activityAssignments.push(activityAssign);
      }

      const unit =
        (activeItem.unitId && {
          id: activeItem.unitId,
          propertyName: null,
        }) ||
        null;
      if (unitIndex === -1 && unit) if (unit) data.units.push(unit);

      const relatedUnit =
        (activeItem.relatedUnitNumberId && activeItem.relatedUnitPropertyName && {
          id: activeItem.relatedUnitNumberId,
          propertyName: activeItem.relatedUnitPropertyName,
          unitRefNo: activeItem.relatedUnitNumber,
        }) ||
        null;
      if (relatedUnitIndex === -1 && relatedUnit) data.relatedUnit.push(relatedUnit);

      const relatedLead =
        (activeItem.relatedLeadNumberId && {
          leadId: activeItem.relatedLeadNumberId,
          lead: {
            contact_name: {
              name: activeItem.contactName || 'N/A',
            },
          }})||
        null;
      if (relatedLeadIndex === -1 && relatedLead) data.relatedLead.push(relatedLead);

      const relatedMaintenanceContract = (activeItem.relatedMaintenanceContractId && {
        maintenanceContractId: activeItem.relatedMaintenanceContractId,
      }) ||
        null;
      if (relatedMaintenanceContractIndex === -1 && relatedMaintenanceContract) data.relatedMaintenanceContract.push(relatedMaintenanceContract);

      const relatedPortfolio = (activeItem.relatedPortfolioId && activeItem.relatedPortfolioName && {
        portfolioId: activeItem.relatedPortfolioId,
        portfolioName: activeItem.relatedPortfolioName
      }) || '';
      if (relatedPortfolioIndex === -1 && relatedPortfolio) data.relatedPortfolio.push(relatedPortfolio);

      if (
        assignIndex === -1 ||
        (unitIndex === -1 && unit) ||
        (relatedUnitIndex === -1 && relatedUnit) ||
        (relatedLeadIndex === -1 && relatedLead) ||
        (relatedMaintenanceContractIndex === -1 && relatedMaintenanceContract) ||
        (relatedPortfolioIndex === -1 && relatedPortfolio)
      )
        setData({ id: 'edit', value: data });
    }
  }, [activeItem, data]);

  useEffect(() => {
    if (state.activityTypeId == null) {
      setAssignActivity(true);
      setState({
        id: 'assignAgentId',
        value: null,
      });
      setSelected({ id: 'activityAssign', value: null });
    } else setAssignActivity(false);
  }, [state.activityTypeId]);

  const convertToUploderReview = (item) => {
    if(item.length>0){
      const files = item.filter(item=>(item.uuid!==null));
      const uploadedFiles = files.map((element, index) => ({
        uuid: element.uuid,
        fileName: element.fileName,
        isNotUploaded: element.isNotUploaded,
      }));
      setUploadedFile(uploadedFiles);
      setState({ id: 'files', value: files })
    }
  };

  const download = (value) => () => {
    const link = document.createElement('a');
    link.setAttribute('download', value.fileName);
    link.href = getDownloadableLink(value.uuid);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const displayActivityInitialFiles = (initialFiles)=>{
    if(initialFiles && initialFiles.length>0){
      const files = initialFiles.map((element, index) => ({
        uuid: element.fileId,
        fileName: element.fileName,
        isNotUploaded: true,
        }));
      setUploadedFile(files);
    }
  }

  const fileDeleted = useCallback(
    (item, index) => () => {

     const selectedFileIndex = uploadedFile.findIndex((element) => element.uuid === (item.fileId||item.uuid));
      if (selectedFileIndex !== -1) {
        const localFiles = [...uploadedFile];
        localFiles.splice(selectedFileIndex, 1);
        setUploadedFile(localFiles);

        const stateLocalFiles = [...state.files];
        stateLocalFiles.splice(selectedFileIndex, 1);
        setState({ id: 'files', value: stateLocalFiles })
      }
    },
    [uploadedFile]
  );

  useEffect(() => {
    setIsRelatedToNotSelected(!(state.relatedLeadNumberId ||
    state.relatedUnitNumberId ||
    state.relatedPortfolioId ||
    state.relatedWorkOrderId ||
    state.relatedMaintenanceContractId));
  }, [state]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          close();
        }}
        className='activities-management-dialog-wrapper'
      >
        <Spinner isActive={isLoading} isAbsolute />
        <form noValidate onSubmit={saveHandler}>
          <DialogTitle id='alert-dialog-slide-title'>
            {t(`${translationPath}${(activeItem && 'edit-activity') || 'add-new-activity'}`)}
          </DialogTitle>
          <DialogContent>
            <div className='dialog-content-wrapper'>

              <div className='dialog-content-item'>
                <AutocompleteComponent
                  idRef='activityTypeIdRef'
                  labelValue='activity-type'
                  labelClasses='Requierd-Color'
                  selectedValues={selected.activityType}
                  multiple={false}
                  isDisabled={edit}
                  data={data.activityTypes}
                  displayLabel={(option) => option.activityTypeName || ''}
                  groupBy={(option) => option.categoryName || ''}
                  getOptionSelected={(option) => option.activityTypeId === state.activityTypeId}
                  withoutSearchButton
                  helperText={getErrorByName(schema, 'activityTypeId').message}
                  error={getErrorByName(schema, 'activityTypeId').error}
                  isLoading={loadings.activityTypes}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    setState({
                      id: 'subject',
                      value: (newValue && newValue.activityTypeName) || null,
                    });
                    setSelected({ id: 'activityType', value: newValue });
                    setState({
                      id: 'activityTypeId',
                      value: (newValue && newValue.activityTypeId) || null,
                    });

                    setState({ id: 'activityReminders', value: [] });
                    setState({ id: 'relatedUnitNumberId', value: null });
                    setState({ id: 'relatedLeadNumberId', value: null });
                    setState({ id: 'relatedPortfolioId', value: null });
                    setState({ id: 'relatedWorkOrderId', value: null });
                    setState({ id: 'relatedMaintenanceContractId', value: null });
                    setSelected({ id: 'relatedUnit', value: null });
                    setSelected({ id: 'relatedLead', value: null });
                    setSelected({ id: 'relatedPortfolio', value: null });
                    setSelected({ id: 'relatedWorkOrder', value: null });
                    setSelected({ id: 'relatedMaintenanceContract', value: null });

                    const assignedToActivityTypeRoles =
                      (newValue && newValue.assignedToActivityTypeRoles) || [];
                    const rolesIds =
                      assignedToActivityTypeRoles &&
                      assignedToActivityTypeRoles.map((item) => item.rolesId || []);
                    setrols(rolesIds);
                    setState({
                      id: 'assignAgentId',
                      value: (newValue && newValue.id) || null,
                    });
                    setSelected({ id: 'activityAssign', value: null });
                  }}
                />
              </div>
              <div className='dialog-content-item'>
                <AutocompleteComponent
                  idRef='assignAgentIdRef'
                  labelValue='activity-assign-to'
                  labelClasses='Requierd-Color'
                  selectedValues={selected.activityAssign}
                  multiple={false}
                  isDisabled={AssignActivity}
                  data={data.activityAssignments}
                  displayLabel={(option) => option.fullName || ''}
                  renderOption={(option) =>
                    ((option.userName || option.fullName) &&
                      `${option.fullName} (${option.userName})`) ||
                    ''}
                  getOptionSelected={(option) => option.id === state.assignAgentId}
                  withoutSearchButton
                  helperText={getErrorByName(schema, 'assignAgentId').message}
                  error={getErrorByName(schema, 'assignAgentId').error}
                  isLoading={loadings.activityAssignments}
                  onInputKeyUp={(e) => {
                    const onSearch = e.target;
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      getAllActivityAssignments(onSearch, selected.activityAssign);
                    }, 700);
                  }}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    setSelected({ id: 'activityAssign', value: newValue });
                    const localReminderPersons = [...selected.reminderPersons];
                    const localReminderPersonIndex = localReminderPersons.findIndex(
                      (item) => item.type === 'user'
                    );
                    if (newValue) {
                      const itemToPush = {
                        id: newValue.id,
                        value: newValue.fullName,
                        type: 'user',
                      };
                      if (localReminderPersonIndex !== -1)
                        localReminderPersons[localReminderPersonIndex] = itemToPush;
                      else localReminderPersons.push(itemToPush);
                      setSelected({ id: 'reminderPersons', value: localReminderPersons });
                    } else if (localReminderPersonIndex !== -1) {
                      localReminderPersons.splice(localReminderPersonIndex, 1);
                      setSelected({ id: 'reminderPersons', value: localReminderPersons });
                    }
                    setState({
                      id: 'assignAgentId',
                      value: (newValue && newValue.id) || null,
                    });
                  }}
                />
              </div>


 <div className='dialog-content-item related-to-wrapper'>

{
  (activityRelatedToList && activityRelatedToList.length) && (
    <fieldset className={(activityRelatedToList.length === 1) && 'full-width-wrapper'}>

      <legend>{t(`${translationPath}related-to`)}</legend>
      {

        activityRelatedToList.map((item) => (
          <DataFileAutocompleteComponent
            labelValue={t(`${translationPath}${item.relatedToName}`)}
            idRef='RelatedToRef'
            selectedValues={((item.relatedToName==='Unit') && selected.relatedUnit ) ||
            ((item.relatedToName==='Lead') && selected.relatedLead ) ||
            ((item.relatedToName==='Portfolio') && selected.relatedPortfolio ) ||
            ((item.relatedToName==='WorkOrder') && selected.relatedWorkOrder ) ||
            ((item.relatedToName==='MaintenanceContract') && selected.relatedMaintenanceContract ) || {}}



            multiple={false}

            data={((item.relatedToName==='Unit') && data.relatedUnit ) ||
            ((item.relatedToName==='Lead') && data.relatedLead ) ||
            ((item.relatedToName==='Portfolio') && data.relatedPortfolio ) ||
            ((item.relatedToName==='WorkOrder') && data.relatedWorkOrder ) ||
            ((item.relatedToName==='MaintenanceContract') && data.relatedMaintenanceContract ) || []}

            displayLabel={(option) => {
              switch (item.relatedToName) {
                case 'Unit':
                  return (option.unitRefNo || '');
                case 'Lead':
                  return (
                    (option.lead && option.lead.contact_name && option.lead.contact_name.name) ||
                    '');
                case 'Portfolio':
                  return (option.portfolioName || '');
                case 'MaintenanceContract':
                  return (option.maintenanceContractId || '').toString();
              }
            }}

            renderFor={item.relatedToName&&item.relatedToName.toLowerCase()}
            withoutSearchButton
            isSubmitted={isSubmitted}

            helperText={((item.relatedToName==='Unit') 
            && getErrorByName(schema, 'relatedUnitNumberId' ).message) ||
            ((item.relatedToName==='Lead') 
            && getErrorByName(schema, 'relatedLeadNumberId' ).message ) ||
            ((item.relatedToName==='Portfolio') 
            && getErrorByName(schema, 'relatedPortfolioId' ).message) ||
            ((item.relatedToName==='WorkOrder') 
            && getErrorByName(schema, 'relatedWorkOrderId' ).message) ||
            ((item.relatedToName==='MaintenanceContract') 
            && getErrorByName(schema, 'relatedMaintenanceContractId' ).message)|| ''}

            error={((item.relatedToName==='Unit') 
            && getErrorByName(schema, 'relatedUnitNumberId' ).error) ||
            ((item.relatedToName==='Lead') 
            && getErrorByName(schema, 'relatedLeadNumberId' ).error ) ||
            ((item.relatedToName==='Portfolio') 
            && getErrorByName(schema, 'relatedPortfolioId' ).error) ||
            ((item.relatedToName==='WorkOrder') 
            && getErrorByName(schema, 'relatedWorkOrderId' ).error) ||
            ((item.relatedToName==='MaintenanceContract') 
            && getErrorByName(schema, 'relatedMaintenanceContractId' ).error)|| ''}

            isLoading={
              ((item.relatedToName==='Unit') && loadings.relatedUnit ) ||
              ((item.relatedToName==='Lead') && loadings.relatedLead ) ||
              ((item.relatedToName==='Portfolio') && loadings.relatedPortfolio ) ||
              ((item.relatedToName==='WorkOrder') && loadings.relatedWorkOrder ) ||
              ((item.relatedToName==='MaintenanceContract') && loadings.relatedMaintenanceContract ) || false
            }
            onInputKeyUp={(e) => {
              const { value } = e.target;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                switch (item.relatedToName) {
                  case 'Unit':
                    getAllRelatedUnits(value);
                    break;
                  case 'Lead':
                    getAllRelatedLeads(value);
                    break;
                  case 'WorkOrder':
                    getAllRelatedWorkOrders(value);
                    break;
                  case 'Portfolio':
                    getAllRelatedPortfolios(value);
                    break;
                  case 'MaintenanceContract':
                    getAllRelatedMaintenanceContracts(value);
                    break;
                }
              }, 1200);
            }}

            isWithError
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}

            onChange={(event, newValue) => relatedToChangeHandler(item,newValue)}
          />
        ))
      }
    </fieldset>
  ) || null

}

</div> 
              {(withDateTime === true) && (
                <div className='dialog-content-item'>
                  <DatePickerComponent
                    idRef='activityDateRef'
                    labelClasses='Requierd-Color'
                    labelValue='activity-date'
                    placeholder='DD/MM/YYYY'
                    value={
                      state.activityDate ? state.activityDate : moment().format('YYYY-MM-DDTHH:mm:ss')
                    }
                    helperText={getErrorByName(schema, 'activityDate').message}
                    error={getErrorByName(schema, 'activityDate').error}
                    isSubmitted={isSubmitted}
                    minDate={!activeItem ? moment().format('YYYY-MM-DDTHH:mm:ss') : (activeItem && activeItem.createdOn && moment(activeItem.createdOn).format('YYYY-MM-DDTHH:mm:ss')) || ''}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onDateChanged={(newValue) => {
                      setState({
                        id: 'activityDate',
                        value: (newValue && moment(newValue).format('YYYY-MM-DDTHH:mm:ss')) || null,
                      });

                      if (activeItem) activityTimeChangeHandler((newValue && moment(newValue).format('YYYY-MM-DDTHH:mm:ss')) || null)
                    }}
                  />
                </div> ||
                '')}
              {(withDateTime === true) && (
                <div className='dialog-content-item'>
                  <DatePickerComponent
                    idRef='activityTimeRef'
                    labelValue='activity-time'
                    labelClasses='Requierd-Color'
                    isTimePicker
                    value={
                      state.activityDate ?
                        state.activityDate :
                        moment().add(2, 'minutes').format('YYYY-MM-DDTHH:mm:ss')
                    }
                    helperText={getErrorByName(schema, 'activityDate').message}
                    error={getErrorByName(schema, 'activityDate').error}
                    isSubmitted={isSubmitted}
                    minDate={moment().format('YYYY-MM-DDTHH:mm:ss')}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onDateChanged={(newValue) => {
                      setState({
                        id: 'activityDate',
                        value: (newValue && moment(newValue).format('YYYY-MM-DDTHH:mm:ss')) || null,
                      });

                      if (activeItem) activityTimeChangeHandler((newValue && moment(newValue).format('YYYY-MM-DDTHH:mm:ss')) || null)
                    }}
                  />
                </div> ||
                '')}

              <div className='dialog-content-item'>
                <Inputs
                  idRef='stageRef'
                  labelValue='stage'
                  value={
                    (selected.activityType && selected.activityType.leadStageName) ||
                    t(`${translationPath}not-contacted`)
                  }
                  isDisabled
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
              </div>
              <div className='dialog-content-item'>
                <Inputs
                  idRef='subjectRef'
                  labelValue='subject'
                  value={state.subject || ''}
                  helperText={getErrorByName(schema, 'subject').message}
                  error={getErrorByName(schema, 'subject').error}
                  isWithError
                  isDisabled
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(event) => {
                    setState({ id: 'subject', value: event.target.value });
                  }}
                />
              </div>
              <div className='dialog-content-item'>
                <SwitchComponent
                  idRef='isOpenStatusRef'
                  isChecked={state.isOpen}
                  themeClass='theme-line'
                  labelValue={(state.isOpen && 'open') || 'closed'}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChangeHandler={(event, isChecked) =>
                    setState({ id: 'isOpen', value: isChecked })}
                />
              </div>
              <div className='dialog-content-item'>
                <Inputs
                  idRef='created-byRef'
                  labelValue='created-by'
                  value={
                    (state.createdByName || '')
                  }
                  isDisabled
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
              </div>
              <div className='dialog-content-item w-100'>
                <Inputs
                  idRef='commentsRef'
                  labelValue='comments'
                  value={state.comments || ''}
                  helperText={getErrorByName(schema, 'comments').message}
                  error={getErrorByName(schema, 'comments').error}
                  isWithError
                  isSubmitted={isSubmitted}
                  multiline
                  rows={4}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(event) => {
                    setState({ id: 'comments', value: event.target.value });
                  }}
                />
              </div>
              {state.activityReminders && state.activityReminders.length > 0 && reminder && (
                <div className='title-wrapper'>
                  <span className='title-text Requierd-Color'>{t(`${translationPath}add-reminders`)}</span>
                </div>
              )}
              {reminder ? (
                <div className='reminder-wrapper'>
                  {state.activityReminders &&
                    state.activityReminders.map((item, index) => (
                      <div className='reminder-item-wrapper' key={`remindersRef${index + 1}`}>
                        <div className='mb-1 w-100 px-2'>
                          <span>{`${t(`${translationPath}reminder`)}# ${index + 1}`}</span>
                        </div>
                        <div className='reminder-section'>
                          <SelectComponet
                            idRef={`reminderWayRef${index + 1}`}
                            data={Object.values(ReminderTypesEnum)}
                            value={item.reminderType}
                            valueInput='key'
                            textInput='value'
                            onSelectChanged={(value) => {
                              item.reminderType = +(value || 1);
                              setState({ id: 'activityReminders', value: state.activityReminders });
                            }}
                            translationPathForData={translationPath}
                            parentTranslationPath={parentTranslationPath}
                            translationPath={translationPath}
                          />
                        </div>
                        <div className='reminder-section'>
                          <SelectComponet
                            idRef={`reminderPersonRef${index + 1}`}
                            data={selected.reminderPersons}
                            value={item.contactId || item.usersId || ''}
                            placeholder='reminder-for'
                            valueInput='id'
                            textInput='value'
                            helperText={
                              getErrorByName(
                                schema,
                                `activityReminders.${index}`,
                                'state.userNotSet'
                              ).message
                            }
                            error={
                              getErrorByName(
                                schema,
                                `activityReminders.${index}`,
                                'state.userNotSet'
                              ).error
                            }
                            isSubmitted={isSubmitted}
                            onSelectChanged={(value) => {
                              if (value) {
                                const localReminderPerson = selected.reminderPersons.find(
                                  (element) => element.id === value
                                );

                                if (localReminderPerson.type === 'contact') {
                                  item.contactId = value;
                                  item.usersId = null;
                                } else {
                                  item.usersId = value;
                                  item.contactId = null;
                                }
                              } else {
                                item.contactId = null;
                                item.usersId = null;
                              }
                              setState({ id: 'activityReminders', value: state.activityReminders });
                            }}
                            emptyItem={{
                              value: '',
                              text: 'select-reminder-for',
                              isDisabled: false,
                            }}
                            translationPathForData={translationPath}
                            parentTranslationPath={parentTranslationPath}
                            translationPath={translationPath}
                          />
                        </div>
                        <div className='reminder-section'>
                          <div className='d-flex w-100'>
                            <Inputs
                              idRef='reminderTimeRef'
                              value={(item.reminderTime && getTimeValue(item)) || 0}
                              helperText={
                                getErrorByName(
                                  schema,
                                  `activityReminders.${index}`,
                                  'state.timeIsZero'
                                ).message
                              }
                              error={
                                getErrorByName(
                                  schema,
                                  `activityReminders.${index}`,
                                  'state.timeIsZero'
                                ).error
                              }
                              isDisabled={!state.activityDate || !state.assignAgentId}
                              wrapperClasses='mb-0'
                              endAdornment={(
                                <SelectComponet
                                  data={reminderAmountOfTimeTypes}
                                  value={item.reminderAmountOfTimeType || 1}
                                  valueInput='key'
                                  textInput='value'
                                  onSelectChanged={(value) => {
                                    item.reminderAmountOfTimeType = +(value || 1);
                                    reminderTimeCalculateHandler(
                                      item,
                                      (item.reminderTime && getTimeValue(item)) || 0,
                                      +(value || 1)
                                    );
                                    setState({
                                      id: 'activityReminders',
                                      value: state.activityReminders,
                                    });
                                  }}
                                  wrapperClasses='over-input-select w-auto'
                                  idRef='timeAmountTypeRef'
                                  parentTranslationPath={parentTranslationPath}
                                  translationPath={translationPath}
                                  translationPathForData={translationPath}
                                />
                              )}
                              type='number'
                              min={0}
                              isWithError
                              isSubmitted={isSubmitted}
                              parentTranslationPath={parentTranslationPath}
                              translationPath={translationPath}
                              onInputChanged={reminderTimeChangeHandler(item)}
                            />
                            {index > 0 && (
                              <ButtonBase
                                className='btns-icon theme-solid bg-secondary-light mt-1 mx-2'
                                onClick={reminderDecrementHandler(index)}
                              >
                                <span className='mdi mdi-minus c-black-light' />
                              </ButtonBase>
                            )}
                            {index === 0 && (
                              <ButtonBase
                                className='btns-icon theme-solid bg-secondary-light mt-1 mx-2'
                                disabled={!state.activityDate || !state.assignAgentId}
                                onClick={reminderIncrementHandler(index)}
                              >
                                <span className='mdi mdi-plus c-black-light' />
                              </ButtonBase>
                            )}
                          </div>
                        </div>

                      </div>
                    ))}
                </div>
              ) : null}
              <ButtonBase onClick={() => { setIsOpenGallery(true); setOpenDialog(true); }} className='btns theme-solid bg-cancel '>
                <span className='mdi mdi-file-multiple c-warning px-2 ' />
                {' '}
                {GlobalTranslate.t('Shared:Files')}
              </ButtonBase>
              {uploadedFile&&uploadedFile.map((element, index) => (
                <Chip
                  className='uploader-chip'
                  label={element.fileName}
                  key={`importFileRefchip${index + 1}`}
                  onDelete={(!(element.isNotUploaded))&&(fileDeleted(element, index) || undefined)}
                  onClick={download(element)}
                  clickable
                />
              ))}
            </div>
          </DialogContent>
          <DialogActions>

            <ButtonBase onClick={() => close()} className='btns theme-solid bg-cancel'>
              {t(`${translationPath}cancel`)}
            </ButtonBase>
            {!activeItem && (
              <ButtonBase className='btns theme-solid' type='submit'>
                {t(`${translationPath}add-activity`)}
              </ButtonBase>
            )}

            {activeItem && (
              <PermissionsComponent
                permissionsList={Object.values(ActivitiesSalesPermissions)}
                permissionsId={ActivitiesSalesPermissions.EditActivity.permissionsId}
              >
                <ButtonBase className='btns theme-solid' type='submit'>
                  {t(`${translationPath}edit-activity`)}
                </ButtonBase>
              </PermissionsComponent>
            )}
          </DialogActions>
        </form>
        <DialogComponent
          disableBackdropClick
          isOpen={openDialog}
          translationPath={translationPath}
          parentTranslationPath='Shared'
          titleClasses='DialogComponent-WorkingHoursDialogView'
          wrapperClasses='wrapperClasses-WorkingHoursDialogView'
          titleText='Activities-Files'
          onCloseClicked={() => setOpenDialog(false)}
          maxWidth='sm'
          dialogContent={(
            <>
              <div className='dialog-content-item w-100'>
                <UploaderActivitieFileComponent
                  onCloseClicked={() => { setIsOpenGallery(true); setOpenDialog(false); }}
                  onUploaderActivitieChange={(item) => {
                    convertToUploderReview(item);
                    setState({ id: 'fileId', value: item[0].uuid });
                    setState({ id: 'fileName', value: item[0].fileName });
                  }}
                  uploadedFile={uploadedFile}
                  />
              </div>
            </>
          )}
        />
      </Dialog>
    </div>
  );
};
