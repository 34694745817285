import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { GetlookupTypeItems } from '../../../../../../../Services';
import { LanguageEnum } from '../../../../../../../Enums';
import { AutocompleteComponent, SelectComponet } from '../../../../../../../Components';
import { getErrorByName } from "../../../../../../../Helper";

export const LeadPreferredLanguageComponent = ({
  state,
  parentTranslationPath,
  translationPath,
  onStateChanged,
  setCurrentSelected,
  currentSelected
}) => {
  const searchTimer = useRef(null);
  const [searchItem, setSearchItem] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [lookups, setLookups] = useState({
    result: [],
    totalCount: 0
  });
  const searchHandler = (event) => {
    const { value } = event.target;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setSearchItem(value)

    }, 700);
  };
  const getAllLookups = useCallback(async () => {
    setIsLoading(true);
    const res = await GetlookupTypeItems({
      lookupTypeId: LanguageEnum.lookupTypeId,
      pageSize: 50,
      pageIndex: 0,
      search: searchItem
    });
    if (!(res && res.status && res.status !== 200)) {
      setLookups({
        result: res.result,
        totalCount: res.totalCount
      });
    }
    else setLookups({
      result: [],
      totalCount: 0
    });
    setIsLoading(false);

  }, [searchItem]);

  useEffect(() => {
    getAllLookups();
  }, [searchItem, getAllLookups]);

  return (
    <div className='dialog-content-item'>
      <AutocompleteComponent
        idRef='LeadPreferredLanguageRef'
        labelValue={t(`${translationPath}lead-preferred-language`)}
        selectedValues={currentSelected && currentSelected.rotationPreferredLanguages || []}
        data={lookups.result || []}
        multiple
        displayLabel={(option) => (option && option.lookupItemName) || ''}
        chipsLabel={(option) => (option && option.lookupItemName) || ''}
        withoutSearchButton
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onInputKeyUp={searchHandler}
        isLoading={isLoading}
        filterOptions={(options) => {
          const isFind = (id) => currentSelected && currentSelected.rotationPreferredLanguages.findIndex(w => (w.lookupsId=== id) || (w.lookupItemId === id)) === -1;
          return options.filter(w => isFind(w.lookupItemId));
        }}
        onChange={(event, newValue) => {
          const localNewValue = {
            id: 'rotationPreferredLanguages',
            value: newValue.map((el) => ({ languageId: el.lookupItemId || el.lookupsId })),
          };
          onStateChanged(localNewValue);
          setCurrentSelected((e) => ({ ...e, rotationPreferredLanguages: newValue }))
        }}
      />
    </div>
  );
};
LeadPreferredLanguageComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  onStateChanged: PropTypes.func.isRequired,
};
