import React from "react";
import { Tables } from "../../../../../../Components";
import { useTranslation } from "react-i18next";
import { TableActions } from "../../../../../../Enums";

export const VicinitiesTable = ({
  parentTranslationPath,
  vicinities,
  translationPath,
  tableActionClicked,
  onPageSizeChanged,
  onPageIndexChanged,
  filter,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const getActionTableWithPermissions = () => {
    const list = [];
    if (true)
      list.push({
        enum: TableActions.editText.key,
        isDisabled: false,
        externalComponent: null,
      });
    if (true)
      list.push({
        enum: TableActions.deleteText.key,
        isDisabled: false,
        externalComponent: null,
      });
    return list;
  };
  return (
    <div>
      <Tables
        data={vicinities.result ?? []}
        headerData={[
          {
            id: 1,
            label: "name",
            input: "vicinityName",
          },
          {
            id: 2,
            label: "vicinityCatName",
            input: "vicinityCatName",
          },
          {
            id: 3,
            label: "vicinityDistanceName",
            input: "vicinityDistanceName",
          },
          {
            id: 4,
            label: "vicinityDesc",
            input: "vicinityDesc",
          },
        ]}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        defaultActions={getActionTableWithPermissions()}
        itemsPerPage={filter.pageSize}
        activePage={filter.pageIndex}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        totalItems={vicinities.totalCount}
      />
    </div>
  );
};
