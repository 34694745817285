import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  CheckboxesComponent,
  Inputs,
  LoadableImageComponant,
} from '../../../../Components';
import { ContactTypeEnum, LoadableImageEnum } from '../../../../Enums';
import { getDownloadableLink } from '../../../../Helper';
import { useTranslation } from 'react-i18next';

export const BulkSelectedPropertiesSection = ({
  onPropertiesCardsChange,
  bulkedProperties,
  propertyStatus,
  propertyCards,
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [searchedItemId, setSearchedItemId] = useState('');
  const searchTimer = useRef(null);
  const searchedItemRef = useRef(null);

  const getDefaultPropertyImage = useCallback(
    (propertyType) => ContactTypeEnum[propertyType]?.defaultImg,
    []
  );

  const cardCheckboxClicked = useCallback(
    (element) => {
      onPropertiesCardsChange((items) => {
        const index = items.findIndex((item) => item.id === element.id);
        if (index !== -1) {
          items.splice(index, 1);
          setSearchedItemId('');
        } else items.push(element);
        return [...items];
      });
    },
    [onPropertiesCardsChange]
  );

  const executeScroll = () =>
    searchedItemRef?.current?.scrollIntoView({
      block: 'end',
      behavior: 'smooth',
    });
  const searchHandler = (value) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setSearchedItemId(value);
      executeScroll();
    }, 500);
  };

  const getIsChecked = useCallback(
    (item) => propertyCards.findIndex((el) => el.id === item.id) !== -1,

    [propertyCards]
  );
  return (
    <>
      <div className='bulk-header-section'>
        {t(`${translationPath}selected-properties`)}
      </div>

      <div className='bulk-sub-header-section'>
        {t(`${translationPath}you-can-deselect-property-before-continue`)}
      </div>
      <div className='mt-2'>
        <Inputs
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          idRef='unitsSearchRef'
          inputPlaceholder='search-property'
          onKeyUp={(e) => searchHandler(e.target.value)}
          startAdornment={<span className='mdi mdi-magnify mdi-24px c-gray' />}
        />
      </div>
      <div className='bulked-units-section mt-2'>
        {bulkedProperties?.map((item, index) => (
          <div
            onClick={(e) => {
              e.preventDefault();
              if (
                propertyStatus.success.findIndex((el) => el === item.id) === -1
              )
                cardCheckboxClicked(item);
            }}
            ref={
              searchedItemId.length > 0 &&
              item.property_name?.toLowerCase().includes(searchedItemId?.toLowerCase())
                ? searchedItemRef
                : null
            }
            className={`bulked-unit-item ${
              propertyStatus.success.findIndex((el) => el === item.id) !== -1 &&
              'success-bulked-unit-item'
            }
            ${
              propertyStatus.failure.findIndex((el) => el === item.id) !== -1 &&
              'failed-bulked-unit-item'
            }
            mb-3 ${
              searchedItemId.length > 0 &&
              item.property_name?.toLowerCase().includes(searchedItemId?.toLowerCase())
                ? 'is-search'
                : ''
            }`}
            key={`propertiesItemRef${index + 1}`}
          >
            <div className='card-checkbox-wrapper'>
              <CheckboxesComponent
                isDisabled={
                  propertyStatus.success.findIndex((el) => el === item.id) !==
                  -1
                }
                singleChecked={getIsChecked(item)}
                idRef={`propertiesCheckboxItemRef${index + 3}`}
              />
            </div>
            <div className='body-item-wrapper'>
              <div className='body-image-item'>
                <LoadableImageComponant
                  classes='cover-image'
                  type={LoadableImageEnum.div.key}
                  alt={t(`${translationPath}property-image`)}
                  src={
                    item.allpropertyImages[0] ?
                      getDownloadableLink(item.allpropertyImages[0].fileId) :
                    getDefaultPropertyImage(item.type)
                  }
                />
              </div>
            </div>
            <div>
              <div className='item-ref-no pl-2 pr-2 mt-1'>
                {item.property_name}
              </div>
              <div className='item-name pl-2 pr-2 mt-2'>{item.id}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
