import React, { useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { GlobalHistory, havePermissionToViewDetails } from '../../../../Helper';
import { Menu, Box, Typography, IconButton, MenuItem, ListItemIcon, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useIsAr, useTranslate } from "../../../../Hooks";
import { PermissionsComponent } from "../../..";
import { BillingPermissions, GroupsPermissions, UsersManagementPermissions } from '../../../../Permissions';

const PARENT_TRANSLATION_PATH = 'HeaderView';

const useStyles = makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    customMenu: {
      top: "60px !important",
      padding: "4px 6px",
      maxWidth: "240px",
      width: "240px",
      border: `1px solid ${theme.palette.border.secondary}`,
      boxShadow: "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    },
    menuContainer: {

    },
    settingsIcon: {
      color: theme.palette.text.tertiary,
      fontSize: "20px",
    },
    settingsButton: (props) => ({
      color: theme.palette.text.tertiary,
      padding: "8px 10px",
      borderRadius: "6px",
      '&:hover': {
        background: theme.palette.background.primary_hover,
      },
      boxShadow: props.isMenuExpanded ? "0px 0px 0px 4px rgba(152, 162, 179, 0.14)" : "none",
      ...(isAr ? { marginLeft: "4px" } : { marginRight: "4px" }),
      '&::after': {
        content: '""',
        position: "absolute",
        ...(isAr ? { left: "-4.5px" } : { right: "-4.5px" }),
        top: "50%",
        transform: "translateY(-50%)",
        height: "12px",
        width: "1px",
        backgroundColor: theme.palette.border.main,
        borderRadius: "6px",
      },
    }),
    customMenuList: {
      padding: 0,
    },
    menuItem: {
      padding: "9px 10px",
      display: "flex",
      alignItems: "center",
      borderRadius: "6px",
      '&:hover': {
        background: theme.palette.background.primary_hover,
      },
      marginTop: "1px",
      marginBottom: "1px",
    },
    listItemIconWrapper: {
      minWidth: "16px",
      ...(isAr ? { marginLeft: "8px" } : { marginRight: "8px" }),
    },
    menuItemText: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      color: theme.palette.text.secondary,
      whiteSpace: "normal",
      wordWrap: "break-word",
      textAlign: "start",
    },
    userMenuIcon: {
      fontSize: "16px",
      color: theme.palette.foreground.quarterary,
    },
  };
});

function Settings() {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useSelector((store) => store.theme);

  const { translate } = useTranslate(PARENT_TRANSLATION_PATH);

  const styles = useStyles({
    isMenuExpanded: !!anchorEl,
  });

  const { isAr } = useIsAr(theme.themeDirection);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const anchorOrigin = isAr
    ? { vertical: "bottom", horizontal: "left" }
    : { vertical: "bottom", horizontal: "right" };

  const transformOrigin = isAr
    ? { vertical: "top", horizontal: "left" }
    : { vertical: "top", horizontal: "right" };

  return (
    <>
      <IconButton
        aria-controls="settings-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        className={styles.settingsButton}
        disableRipple
      >
        <Icon className={clsx("icon-header-settings", styles.settingsIcon)}></Icon>
      </IconButton>
      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        getContentAnchorEl={null}
        PaperProps={{
          className: styles.customMenu,
        }}
        MenuListProps={{
          className: styles.customMenuList,
        }}
      >
        <Box className={styles.menuContainer}>
          <PermissionsComponent
            permissionsList={Object.values(BillingPermissions)}
            permissionsId={[ BillingPermissions.ViewPreview.permissionsId, BillingPermissions.ViewHistory.permissionsId ]}
          >
            <MenuItem onClick={() => GlobalHistory.push('/home/billing')} className={styles.menuItem} disableRipple>
              <ListItemIcon className={styles.listItemIconWrapper}>
                <Icon className={clsx("icon-settings-menu-billing", styles.userMenuIcon)} />
              </ListItemIcon>
              <Typography variant="inherit" className={styles.menuItemText}>
                {translate('billing')}
              </Typography>
            </MenuItem>
          </PermissionsComponent>

          <PermissionsComponent
            permissionsList={Object.values(GroupsPermissions)}
            permissionsId={[GroupsPermissions.ViewGroupsPage.permissionsId]}
          >
            <MenuItem onClick={() => GlobalHistory.push('/home/user-groups')} className={styles.menuItem} disableRipple>
              <ListItemIcon className={styles.listItemIconWrapper}>
                <Icon className={clsx("icon-settings-menu-groups", styles.userMenuIcon)} />
              </ListItemIcon>
              <Typography variant="inherit" className={styles.menuItemText}>
                {translate('groups')}
              </Typography>
            </MenuItem>
          </PermissionsComponent>

          {havePermissionToViewDetails(Object.values(UsersManagementPermissions)) ? (
            <MenuItem onClick={() => GlobalHistory.push('/home/users-management')} className={styles.menuItem} disableRipple>
              <ListItemIcon className={styles.listItemIconWrapper}>
                <Icon className={clsx("icon-settings-menu-user-management", styles.userMenuIcon)} />
              </ListItemIcon>
              <Typography management variant="inherit" className={styles.menuItemText}>
                {translate('users-management')}
              </Typography>
            </MenuItem>
          ) : null}
        </Box>
      </Menu>
    </>
  );
}

export default Settings;
