import React, { useState } from 'react';
import { Button} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { config } from '../../../../config';
import { Inputs } from '../../../../Components';
import arrowLeft from '../../../../assets/images/icons/arrow-left.png'
import { GetApplicationUserForgotPasswordOptions} from '../../../../Services/LoginService';
import propxLogin from '../../../../assets/images/pages/login/propx-login.svg';
import { LoginFormsEnum } from './LoginFormsEnum';

export const ResetPasswordRequestForm = ({
  setUserVerificationInfo,
  setActiveLoginForm,
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const [accountIdentity, setAccountIdentity] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleIdentitySubmit = async (event) => {
    event.preventDefault();
    const res = await GetApplicationUserForgotPasswordOptions(
      accountIdentity,
      config.applicationId,
      config.organizationsId
    );
    if (res) {
      setUserVerificationInfo({
        email: res.email,
        fullName: res.fullName,
        profileImg: res.profileImg,
        userId: res.userId,
      })
      setActiveLoginForm(LoginFormsEnum.UserVerification.key)
      setIsSubmitted(true);
    }
  };
  return (
    <div className="login-form-container">
      <div className="login-header">
        <img src={propxLogin} />
      </div>
      <h1 className="heading-1">{t(`${translationPath}Forgot-Password-heading`)}</h1>
      <h5 className="heading-2 mb-4">{t(`${translationPath}Forgot-Password-description`)}</h5>
      <div className="login-form">
        <form
          noValidate
          className="form-wrapper"
          onSubmit={handleIdentitySubmit}
        >
          <div className="mb-1">
            <Inputs
              isSubmitted={isSubmitted}
              labelClasses="login-label pb-1"
              labelValue={t(`${translationPath}username`)}
              inputPlaceholder={t(`${translationPath}Enter-your-username`)}
              name="identity"
              idRef="accountIdentity"
              fieldClasses="inputs theme-underline mb-4"
              value={accountIdentity}
              onInputChanged={(e) => setAccountIdentity(e.target.value)}
            />
          </div>
          <div className="d-flex-column-center">
            <Button className="login-btn" type="submit">
              {<span>{t(`${translationPath}Reset-password`)}</span>}
            </Button>
          </div>
          <div
            className="back-btn d-flex-center my-3P5"
            onClick={() => setActiveLoginForm(LoginFormsEnum.SignIn.key)}
          >
            <img src={arrowLeft} className="mr-1" />
            <span>{t(`${translationPath}back-to-login`)}</span>
          </div>
        </form>
      </div>
    </div>
  );
};