import { useState } from 'react';
import { showError } from '../Helper';

const copyToClipboard = async (copiedData) => {
  try {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(copiedData);
      return true;
    }
    return false;
  } catch (error) {
    showError('Error Copy to Clipboard');
    // eslint-disable-next-line no-console
    console.error('Error Copy to Clipboard Place Contact With CRM Support', error);
    return false;
  }
};

const useCopyToClipboard = (CopyDoneShowTime) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboardHandler = async (data) => {
    if (await copyToClipboard(data)) {
      setCopied(true);
      setTimeout(() => setCopied(false), (CopyDoneShowTime));
    }
  };

  return { copyToClipboardHandler, copied };
};

export { useCopyToClipboard };
