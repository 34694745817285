export const AMLStatusEnum = {
  1:
  {
    key: 1,
    name: 'Available',
    classes: 'c-success-light',

  },
  2: {
    key: 2,
    name: 'ReservedLeased',
    classes: 'c-primary',

  },
  3: {
    key: 3,
    name: 'Leased',
    classes: 'c-primary',

  },
  4: {
    key: 4,
    name: 'LeasedByThirdParty',
    classes: 'c-primary',
  },
  5:
  {
    key: 5,
    name: 'ReservedSale',
    classes: 'c-primary',

  },
  6: {
    key: 6,
    name: 'Sale',
    classes: 'c-primary',

  },
  7: {
    key: 7,
    name: 'SaleByThirdParty',
    classes: 'c-primary',

  },
  8: {
    key: 8,
    name: 'blocked',
    classes: 'c-black-light',

  },

  9:
  {
    key: 9,
    name: 'upcoming',
    classes: 'c-warning-light',

  },
  10: {
    key: 10,
    name: 'Draft',
    classes: 'globel-Gry-style',

  },
  11:
  {
    key: 11,
    name: 'Pendin_Reserve_Lease',
    classes: 'c-pending',

  },
  12: {
    key: 12,
    name: 'Pending_Lease',
    classes: 'c-pending',

  },
  13: {
    key: 13,
    name: 'Pending_Reserve_Sale',
    classes: 'c-pending',

  },
  14: {
    key: 14,
    name: 'Pending_Sale',
    classes: 'c-pending',

  },
  15: {
    key: 15,
    name: 'Under_Offer',
    classes: 'c-pending',

  },
  16: {
    key: 16,
    name: 'Offer_Signed',
    classes: 'c-pending',
  },
  17: {
    key: 17,
    name: "Under_Contract",
    classes: 'c-pending',
  },
  18 : {
    key: 18,
    name: "Contract_Signed",
    classes: 'c-pending',
  },
};
