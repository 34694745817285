import React from "react";
import moment from "moment";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { Box, Avatar, Typography } from "@material-ui/core";
import { CustomIconButton, CustomCopyToClipboard, CustomMenu } from "..";
import { CustomCard } from "../CustomCard";
import { getDownloadableLink } from "../../../Helper";
import { useSelectedTheme } from "../../../Hooks";

// Styles
import useStyles from "./styles";

// Icons
import {
  ArrowUpRight,
  MoreVertIcon,
  EditOne,
  TrashOne,
} from "../../../assets/icons";

function AssociatedContactCard({ contact, onDelete, onEdit }) {
  const styles = useStyles();

  const history = useHistory();

  const {
    theme: { palette },
  } = useSelectedTheme();

  function formatLanguageAndNationality(language, nationality) {
    if (language && nationality) {
      return `${language}, ${nationality}`;
    }
    return language || nationality || "";
  }

  const formattedDate = contact?.createdOn
    ? moment(contact?.createdOn).locale("en").format("D MMM YYYY")
    : null;

  const contactName = contact?.relatedContactName?.match(/\b(\w)/gm).join("")?.toUpperCase();

  return (
    <CustomCard
      borderRadius="xl"
      borderColor="secondary"
      classes={styles.cardWrapper}
    >
      <Box className={styles.headingContainer}>
        <Box className={styles.avatarWrapper}>
          <Avatar
            className={
              contact.relatedContactImageFileId
                ? styles.avatar
                : styles.defaultAvatar
            }
            src={getDownloadableLink(contact.relatedContactImageFileId) ?? null}
          >
            {contactName.length === 1 ? contactName : contactName.charAt(0) + contactName.charAt(contactName.length - 1)}
          </Avatar>
        </Box>
        <CustomMenu
          menuItems={[
            {
              icon: (
                <EditOne
                  width="16"
                  height="16"
                  fill={palette.button.secondaryGray_fg}
                />
              ),
              text: "Edit",
              handler: () => onEdit(contact),
            },
            {
              icon: (
                <TrashOne
                  width="16"
                  height="16"
                  fill={palette.button.secondaryGray_fg}
                />
              ),
              text: "Delete",
              handler: () => onDelete(contact),
            },
          ]}
          activeIconClass={styles.menuActiveIconClass}
          defaultIconClass={styles.menuDefaultIconClass}
        >
          <MoreVertIcon width="20" height="20" />
        </CustomMenu>
      </Box>
      <Box className={styles.contentContainer}>
        <Typography
          className={styles.relationType}
          variant="body1"
          align="center"
        >
          {contact?.contactRelationshipName}
        </Typography>
        <Box
          className={styles.contactNameWrapper}
          onClick={() => {
            history.push(
              `/home/Contacts-CRM/contact-profile-overview?formType=${
                contact?.contact_type_id ?? 1
              }&id=${contact?.relatedAssociatedContactsId}`
            );
          }}
        >
          <Typography
            className={styles.contactName}
            variant="h6"
            align="center"
          >
            {contact?.relatedContactName}
          </Typography>
          <CustomIconButton
            variant="text"
            size="xs"
            boxShadow="none"
            color="secondary"
          >
            <ArrowUpRight
              width="20"
              height="20"
              fill={palette.button.secondaryGray_fg}
            />
          </CustomIconButton>
        </Box>
        <CustomCopyToClipboard
          data={contact?.relatedAssociatedContactsId}
          childrenData={`#${contact?.relatedAssociatedContactsId}`}
        />
      </Box>
      <Box className={styles.infoContainer}>
        <Box className={styles.infoItem}>
          <Typography
            className={styles.infoContent}
            variant="body1"
            align="center"
          >
            {contact?.relatedContactMobile}
          </Typography>
          <Typography
            className={styles.infoContent}
            variant="body1"
            align="center"
          >
            -
          </Typography>
          <Typography
            className={clsx(styles.infoContent, styles.truncateString)}
            variant="body1"
            align="center"
          >
            {contact?.relatedContactEmail}
          </Typography>
        </Box>
        {(!!contact?.relatedContactLanguage ||
          !!contact?.relatedContactNationality) && (
          <Box className={styles.infoItem}>
            <Typography
              className={clsx(styles.infoContent, styles.fw400)}
              variant="body1"
              align="center"
            >
              {formatLanguageAndNationality(
                contact?.relatedContactLanguage,
                contact?.relatedContactNationality
              )}
            </Typography>
          </Box>
        )}
      </Box>
      <Box className={styles.footerContainer}>
        <Typography className={styles.createdOn} variant="body1" align="center">
          Creation On: <b className={styles.date}>{formattedDate}</b>
        </Typography>
      </Box>
    </CustomCard>
  );
}

export default AssociatedContactCard;
