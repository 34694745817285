export const PaymentModeId = {
  cash: 20693,
  draft: 20697,
  cheque: 20694,
  creditCard: 20696,
  depositBond: 20698,
  lookupTypeId: 1210,
  bankDeposit: 20699,
  bankTransfer: 20695,
};
