import { config } from "../config/config";
import { HttpServices } from "../Helper";

const CreateUserColumnGroup = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/UserFilterColumnGroup/CreateUserColumnGroup`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UpdateUserColumnGroups = async (payload) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/UserFilterColumnGroup/UpdateUserColumnGroup`,
    payload
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetUserColumnGroupById = async (userFilterGroupId) => {
  const result = await HttpServices.get(
    `${config.server_address}CrmDfm/UserFilterColumnGroup/GetUserColumnGroupById?userFilterGroupId =${userFilterGroupId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllUserColumnGroup = async () => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/UserFilterColumnGroup/GetAllUserColumnGroups?searchFiledsType=1`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const DeleteUserColumnGroups = async (deletedIds) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/UserFilterColumnGroup/DeleteUserColumnGroups`,
    {
      data: {
        userFilterGroupIds: deletedIds,
      },
    }
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export {
  CreateUserColumnGroup,
  UpdateUserColumnGroups,
  GetUserColumnGroupById,
  GetAllUserColumnGroup,
  DeleteUserColumnGroups,
};
