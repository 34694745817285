import React, {
  useState, useRef, useEffect, useCallback
} from 'react';
import { ButtonBase } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  Inputs, Spinner, Tables, AutocompleteComponent, DataFileAutocompleteComponent, SelectComponet
} from '../../../../../../Components';
import { DateRangePickerComponent } from '../../../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import { useTitle } from '../../../../../../Hooks';
import { TableActions } from '../../../../../../Enums';
import { ActivitiesManagementDialog } from '../../../../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog';
import {
  GetActivityById, GetAllActivities, OrganizationUserSearch, GetLeads
} from '../../../../../../Services';
import { ActivityDeleteDialog } from './ActivitiesViewUtilities';
import { GetParams } from '../../../../../../Helper';
import { ReplyActivityDialog } from '../../../../ActivitiesView/ReplyActivitesView/ReplyActivityDialog';

const parentTranslationPath = 'ActivitiesView';
const translationPath = '';

export const UnitProfileActivitiesComponent = ({unitWithPolicy}) => {
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
  };
  const { t } = useTranslation(parentTranslationPath);
  const location = useLocation();
  const [isLoadingActivities, setIsLoadingActivities] = useState(false);
  const [isLoading, setIsLoading] = useState({
    agents: false,
    units: false,
    leads: false
  });
  const [selectedRelatedLead, setSelectedRelatedLead] = useState(null);
  const [data, setData] = useState({
    relatedLeads: [],
  });
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [searchedItem, setSearchedItem] = useState('');
  const [activeItem, setActiveItem] = useState(null);
  const [activities, setActivities] = useState({
    result: [],
    totalCount: 0,
  });
  const [allAgents, setAllAgents] = useState({
    result: [],
    totalCount: 0,
  });
  const [openReplyActivityDialog, setOpenReplyActivityDialog] = useState(false);
  const [isReplay, setIsReplay] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const searchTimer = useRef(null);
  const [filter, setFilter] = useState({
    unitId: +GetParams('id'),
    filterBy: null,
    orderBy: null,
    pageSize: 25,
    pageIndex: 0,
    search: '',
    guidAgentId: null,
    relatedUnitNumberId: null,
    relatedLeadId: null
  });

  const [sortBy, setSortBy] = useState(null);
  const [isEdit, setisEdit] = useState(false);
  useTitle(t(`${translationPath}activities`));

  const getAllActivities = useCallback(async () => {
    let activitiesDate = { fromDate: null, toDate: null };
    if (dateFilter && dateFilter.startDate && dateFilter.endDate) {
      activitiesDate = {
        fromDate: moment(dateFilter.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        toDate: moment(dateFilter.endDate).format('YYYY-MM-DDTHH:mm:ss')
      };
    }
    setIsLoadingActivities(true);
    const body = {
      ...filter,
      pageIndex: filter.pageIndex + 1,
      fromDate: activitiesDate.fromDate,
      toDate: activitiesDate.toDate,
      relatedUnitId: filter.unitId
    };
    const res = await GetAllActivities(body);
    if (!(res && res.status && res.status !== 200)) {
      setActivities({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setActivities({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoadingActivities(false);
  }, [filter, dateFilter]);

  const getAllRelatedLeads = useCallback(
    async (value) => {
      setIsLoading((loading) => ({ ...loading, leads: true }));

      const response = await GetLeads({ search: value });
      if (!(response && response.status && response.status !== 200)) {
        setData({
          relatedLeads: (response && response.result) || []
        });
      } else {
        setData({
          relatedLeads: []
        });
      }
      setIsLoading((loading) => ({ ...loading, leads: false }));
    }, []
  );

  const GetActivityAPI = useCallback(async (activityId) => {
    const res = await GetActivityById(activityId);
    if (!(res && res.status && res.status !== 200)) setActiveItem(res);
  }, []);

  const searchHandler = (event) => {
    const { value } = event.target;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({ ...item, search: value }));
    }, 700);
  };
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({
      ...item,
      pageIndex,
      search: '',
      guidAgentId: null,
      relatedUnitNumberId: null,
      relatedLeadId: null
    }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({
      ...item,
      pageIndex: 0,
      pageSize,
      search: '',
      guidAgentId: null,
      relatedUnitNumberId: null,
      relatedLeadId: null,
    }));
  };
  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      if (actionEnum === TableActions.deleteText.key) {
        setOpenConfirmDialog(true);
        setActiveItem(item);
      } else if (actionEnum === TableActions.editText.key) {
        GetActivityAPI(item.activityId);
        setOpenDialog(true);
        setisEdit(true);
      } else if (actionEnum === TableActions.replyText.key) {
        setActiveItem(item);
        setOpenReplyActivityDialog(true);
      }
    },
    [GetActivityAPI]
  );

  const focusedRowChanged = (activeRow) => {
    const item = activities.result[activeRow];
    if (!item) return;
    setIsReplay(item.isReplyAble);
  };
  const getTableActionsWithPermissions = () => {
    if (isReplay)
      return ([{ enum: TableActions.editText.key }, { enum: TableActions.replyText.key, isDisabled: false }]);

    return ([{ enum: TableActions.editText.key }]);
  };

  const getAllAgents = useCallback(async () => {
    setIsLoading((loading) => ({ ...loading, agents: true }));

    const res = await OrganizationUserSearch({});
    if (!(res && res.status && res.status !== 200)) {
      setAllAgents({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setAllAgents({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((loading) => ({ ...loading, agents: false }));
  }, []);
  const addNewHandler = () => {
    setOpenDialog(true);
  };

  useEffect(() => {
    setFilter((item) => ({ ...item, unitId: +GetParams('id') }));
  }, [location]);

  useEffect(() => {
    getAllActivities();
  }, [getAllActivities, filter]);

  useEffect(() => {
    if (sortBy)
      setFilter((item) => ({ ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy }));
  }, [sortBy]);

  useEffect(() => {
    getAllAgents();
  }, []);

  useEffect(() => {
    getAllRelatedLeads();
  }, []);

  return (
    <div className='view-wrapper activities-view-wrapper'>
      <Spinner isActive={isLoadingActivities} />
      <div className='d-flex-column'>
        <div className='header-section'>
          <div className='filter-section px-2 mt-3'>
            <div className='section'>
              <ButtonBase className='btns theme-solid px-3' onClick={addNewHandler}>
                <span className='mdi mdi-plus' />
                {t(`${translationPath}add-new`)}
              </ButtonBase>
            </div>
            <div className='section autocomplete-section'>
              <div className='d-flex-column px-2 w-100 p-relative'>
                <div className='section px-2'>
                  <Inputs
                    value={searchedItem}
                    onKeyUp={searchHandler}
                    idRef='activitiesSearchRef'
                    label={t(`${translationPath}search-activity`)}
                    onInputChanged={(e) => setSearchedItem(e.target.value)}
                    inputPlaceholder={t(`${translationPath}search-activity`)}
                    beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                  />
                </div>

                <div className='d-inline-flex pl-5-reversed agentSection'>
                  <div className='agentSection'>
                    <div className='agentSection1'>
                      <AutocompleteComponent
                        idRef='referredToRef'
                        isLoading={isLoading.agents}
                        multiple={false}
                        data={allAgents.result || []}
                        value={filter.guidAgentId}
                        chipsLabel={(option) => option.fullName || ''}
                        displayLabel={(option) => option.fullName || ''}
                        withoutSearchButton
                        inputPlaceholder={t(`${translationPath}Agent`)}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          setFilter((item) => ({
                            ...item,
                            filterBy: null,
                            orderBy: null,
                            search: '',
                            fromDate: null,
                            toDate: null,
                            relatedUnitNumberId: null,
                            relatedLeadId: null,
                            pageIndex: 0,
                            guidAgentId: (newValue && newValue.id) || null
                          }));
                        }}
                      />

                    </div>
                    <div className='agentSection1'>
                      <DataFileAutocompleteComponent
                        idRef='RelatedToRef'
                        isLoading={(isLoading.leads)}
                        labelClasses='Requierd-Color'
                        multiple={false}
                        selectedValues={selectedRelatedLead}
                        data={data.relatedLeads}
                        displayLabel={
                          ((option) =>
                            (option.lead && option.lead.contact_name && option.lead.contact_name.name) ||
                            (option.lead && option.lead.company_name) ||
                            '')
                        }
                        renderFor='lead'
                        getOptionSelected={
                          ((option) => option.leadId === filter.relatedLeadId)
                        }
                        filterOptions={(options) => options}
                        onChange={(event, newValue) => {
                          if (!newValue) getAllRelatedLeads(newValue);
                          setSelectedRelatedLead(newValue);
                          setFilter((item) => ({
                            ...item,
                            relatedLeadId: (newValue && newValue.leadId) || null,
                            pageIndex: 0,
                            relatedUnitNumberId: null,
                            filterBy: null,
                            orderBy: null,
                            search: '',
                            fromDate: null,
                            toDate: null,
                            guidAgentId: null
                          }));
                        }}
                        onInputKeyUp={(e) => {
                          const { value } = e.target;
                          if (searchTimer.current) clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getAllRelatedLeads(value);
                          }, 700);
                        }}
                        withoutSearchButton
                        inputStartAdornment={(
                          <SelectComponet
                            data={[
                              {
                                key: 1,
                                value: 'lead',
                              },
                            ]}
                            value={1}
                            inputPlaceholder={t(`${translationPath}Agent`)}
                            valueInput='key'
                            textInput='value'
                            wrapperClasses='over-input-select w-auto'
                            idRef='relatedToTypeRef'
                            parentTranslationPath={parentTranslationPath}
                            translationPath={translationPath}
                            translationPathForData={translationPath}
                          />
                        )}
                        isWithError
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                      />

                    </div>
                    <div className='agentSection1'>
                      <DateRangePickerComponent
                        onClearClicked={() => setDateFilter(dateRangeDefault)}
                        ranges={[dateFilter]}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onDateChanged={(selectedDate) => {
                          setDateFilter({
                            startDate: selectedDate.selection && selectedDate.selection.startDate,
                            endDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                            key: 'selection',
                          });
                          setFilter((item) => ({
                            ...item,
                            pageIndex: 0,
                            search: '',
                            relatedLeadId: null,
                            relatedUnitNumberId: null,
                            filterBy: null,
                            orderBy: null
                          }));
                        }}
                      />

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className='w-100 px-3'>
          <Tables
            data={activities.result}
            headerData={[
              {
                id: 1,
                label: 'date',
                input: 'activityDate',
                isDate: true,
                isSortable: true,
              },
              {
                id: 2,
                isSortable: true,
                label: 'created-on',
                input: 'createdOn',
                isDate: true,
                dateFormat: 'DD/MM/YYYY',
              },
              {
                id: 3,
                label: 'created-By-Name',
                input: 'createdByName',
              },
              {
                id: 4,
                isSortable: true,
                label: 'created-time',
                input: 'createdOn',
                isDate: true,
                dateFormat: ' hh:mm A',
              },
              {
                id: 5,
                label: 'related-to',
                input: 'relatedTo',
                component: (item) => (
                  <span className='c-primary'>
                    {(item.relatedLeadNumberId && item.relatedUnitNumberId && t(`${translationPath}Lead/Unit`)) ||
                      (item.relatedLeadNumberId && t(`${translationPath}lead`)) ||
                      (item.relatedUnitNumberId && t(`${translationPath}unit`)) ||
                      (item.relatedMaintenanceContractId &&
                        t(`${translationPath}MaintenanceContract`)) ||
                      (item.relatedPortfolioName && t(`${translationPath}Portfolio`)) ||
                      (item.relatedWorkOrderRefNumber && t(`${translationPath}WorkOrder`)) ||
                      (item.relatedUnitPropertyName && t(`${translationPath}Property`)) ||
                      (item.relatedPortfolioId && t(`${translationPath}Portfolio`)) ||
                      (item.relatedWorkOrderId && t(`${translationPath}WorkOrder`)) ||
                      'N/A'}
                  </span>
                ),
              },
              {
                id: 6,
                label: 'related-to-number',
                input: 'relatedToName',
                component: (item) => (
                  <span className='c-primary'>
                    {item && item.relatedUnitName}
                    {/* {(((item.relatedLeadNumberId && item.relatedUnitNumberId) && (item.contactName || 'N/A'))) ||
                      (item.relatedLeadNumberId && item.contactName) ||
                      (item.relatedUnitNumberId && item.unitReferenceNo) ||
                      (item.relatedUnitPropertyName && item.relatedUnitNumber) ||
                      (item.relatedPortfolioId && item.relatedPortfolioName) ||
                      (item.relatedWorkOrderId && item.relatedWorkOrderRefNumber) ||
                      item.relatedMaintenanceContractId ||
                      'N/A'} */}
                  </span>
                ),
              },
              {
                id: 7,
                label: 'stage',
                input: 'LeadStageName',
                component: (item) => (
                  <span>
                    {item.leadStage || t(`${translationPath}not-contacted`)}
                  </span>
                ),
                isSortable: true,
              },
              {
                id: 8,
                label: 'assign-to',
                input: 'assignedTo',
                isSortable: true,
              },

              {
                id: 9,
                label: 'category',
                input: 'categoryName',
                component: (item) => (
                  <span>{item.categoryName || ''}</span>
                ),
                isSortable: true,
              },
              {
                id: 10,
                label: 'activity-type',
                input: 'ActivityTypeName',
                component: (item) => (
                  <span>{item.activityTypeName || ''}</span>
                ),
                isSortable: true,
              },
              {
                id: 11,
                label: 'subject',
                input: 'subject',
                component: (item) => <span>{(item.subject && item.subject) || 'N/A'}</span>,
                isSortable: true,
              },
              {
                id: 12,
                label: 'status',
                input: 'isOpen',
                cellClasses: 'py-0',
                component: (item, index) => (
                  (item.isOpen && <div className='globel-open-style' index={index}>{t(`${translationPath}open`)}</div>) || <div className='globel-closed-style' index={index}>{t(`${translationPath}closed`)}</div>
                ),
                isSortable: true,
              }, {
                id: 13,
                label: 'comments',
                input: 'comments',
                isDefaultFilterColumn: true,
                isSortable: true,
              },
              {
                id: 14,
                label: 'Copy-To',
                input: 'copyTo',
                isDefaultFilterColumn: true,
                isSortable: true,
              },
            ]}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            defaultActions={getTableActionsWithPermissions()}
            focusedRowChanged={focusedRowChanged}
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            totalItems={activities.totalCount}
            setSortBy={setSortBy}
          />
        </div>
      </div>
      {openDialog && (
        <ActivitiesManagementDialog
          open={openDialog}
          activeItem={activeItem}
          isEdit={isEdit}
          onSave={() => {
            setOpenDialog(false);
            setActiveItem(null);
            onPageIndexChanged(0);
            setisEdit(false);
          }}
          close={() => {
            setActiveItem(null);
            setOpenDialog(false);
            setisEdit(false);
          }}
          unitOperationId={unitWithPolicy?.unit?.operation_type?.lookupItemId}
          unitSaleTypeId={unitWithPolicy?.unit?.sale_type?.lookupItemId}
          unitTypeId={unitWithPolicy?.unit?.unit_type?.lookupItemId}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {openConfirmDialog && (
        <ActivityDeleteDialog
          isOpen={openConfirmDialog}
          activeItem={activeItem}
          reloadData={() => {
            setOpenDialog(false);
            setActiveItem(null);
            onPageIndexChanged(0);
          }}
          isOpenChanged={() => {
            setActiveItem(null);
            setOpenDialog(false);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {openReplyActivityDialog && (
        <ReplyActivityDialog
          open={openReplyActivityDialog}
          close={() => {
            setActiveItem(null);
            setOpenReplyActivityDialog(false);
          }}
          activeItem={activeItem}
          onSave={() => {
            setOpenReplyActivityDialog(false);
            setActiveItem(null);
            onPageIndexChanged(0);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}

    </div>
  );
};
