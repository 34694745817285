import React, { useEffect } from "react";
import { SwitchRoute } from "../../../Components/Route/SwitchRoute";
import { ContactsCrmRoutes } from "../../../routes";

export const ContactsCrmLayout = () => {
  useEffect(() => {
    return () => {
      localStorage.removeItem("contactsCrmCurrentPageIndex");
    };
  }, []);

  return <SwitchRoute routes={ContactsCrmRoutes} />;
};
