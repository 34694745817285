import React from "react";
import PropTypes from "prop-types";

export const ReelStepperPreview = ({ activeStep, dynamicComponentProps }) => {
  const Component = activeStep.component;
  const checkIsComponentExist = () => Component instanceof Function;

  return (
    <>
      {(checkIsComponentExist() && (
        <Component
          key={`dynamicComponentRef-${activeStep.key}`}
          {...(dynamicComponentProps || {})}
          {...(activeStep.props || {})}
        />
      )) ||
        null}
    </>
  );
};

ReelStepperPreview.propTypes = {
  dynamicComponentProps: PropTypes.objectOf(PropTypes.any),
  activeStep: PropTypes.objectOf(PropTypes.any),
  steps: PropTypes.array,
};
ReelStepperPreview.defaultProps = {
  dynamicComponentProps: {},
  activeStep: {},
  steps: [],
};
