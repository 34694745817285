import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  MarketingAgentAutocomplete,
  TitleDescriptionTemplateControls,
  UspAutocomplete,
} from "./controls";
import {
  CheckboxesComponent,
  RadiosGroupComponent,
  PermissionsComponent,
  Inputs,
} from "../../../../../../../../../Components";
import {
  CheckPendingRequestForUser,
  unitDetailsGet,
} from "../../../../../../../../../Services";
import {
  GetParams,
  returnPropsByPermissions,
} from "../../../../../../../../../Helper";
import { UnitsLeasePermissions } from "../../../../../../../../../Permissions";
import { StaticLookupsIds } from "../../../../../../../../../assets/json/StaticLookupsIds";
import { useSelector } from "react-redux";

export const Descriptions = ({
  state,
  schema,
  onStateChanged,
  isMarketAsADifferentAgent,
  onIsMarketAsADifferentAgent,
  isSubmitted,
  parentTranslationPath,
  translationPath,
  activeItem,
  approvalResponse,
  isPendingRequest,
  setIsPendingRequest,
  isNeedApprovalMessageInMarketing,
  setIsDialogOpen,
  setIsSubmitted,
  saveHandler,
}) => {
  const { t } = useTranslation([parentTranslationPath]);

  const loginResponse = useSelector((state) => state.login.loginResponse);
  const isNew = useMemo(() => !state.titleEn && !state.descriptionEn, []);

  const getFeaturedUnitWithPermissions = () => {
    // eslint-disable-next-line prefer-const
    let list = [];
    if (
      returnPropsByPermissions(
        UnitsLeasePermissions.EditFeaturedUnitMarketing.permissionsId
      )
    ) {
      list.push({
        key: "isFeatureUnit",
        value: "featured-unit",
      });
    }
    if (
      returnPropsByPermissions(
        UnitsLeasePermissions.EditHotDealMarketing.permissionsId
      )
    ) {
      list.push({
        key: "isHotDealUnit",
        value: "hot-deal-unit",
      });
    }
    list.push({
      key: "isDealOfTheDay",
      value: "deal-of-the-day",
    });
    return list;
  };

  const checkPendingRequestForUser = async () => {
    const userId = loginResponse.userId;
    const unitId = state.unitId;
    const res = await CheckPendingRequestForUser(userId, unitId);
    if (!(res && res.status && res.status !== 200)) {
      setIsPendingRequest(res);
      return res;
    }
  };
  useEffect(() => {
    checkPendingRequestForUser();
  }, []);

  return (
    <div className="marketing-description-wrapper presentational-wrapper">
      <div className="title-wrapper">
        <span className="title-text">{t(`${translationPath}description`)}</span>
      </div>
      <PermissionsComponent
        permissionsList={Object.values(UnitsLeasePermissions)}
        permissionsId={UnitsLeasePermissions.EditUspMarketing.permissionsId}
      >
        <UspAutocomplete
          state={state}
          schema={schema}
          onStateChanged={onStateChanged}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </PermissionsComponent>

      <div className="form-item">
        <RadiosGroupComponent
          idRef="isMarketAsADifferentAgentRef"
          labelValue="is-market-as-a-different-agent"
          data={[
            {
              key: true,
              value: "yes",
            },
            {
              key: false,
              value: "no",
            },
          ]}
          value={isMarketAsADifferentAgent}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          translationPath={translationPath}
          labelInput="value"
          valueInput="key"
          onSelectedRadioChanged={(e, newValue) => {
            if (state.agentsId && onStateChanged)
              onStateChanged({ id: "agentsId", value: null });
            if (onIsMarketAsADifferentAgent)
              onIsMarketAsADifferentAgent(newValue === "true");
          }}
        />
      </div>
      {isMarketAsADifferentAgent && (
        <MarketingAgentAutocomplete
          state={state}
          schema={schema}
          onStateChanged={onStateChanged}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}

      <TitleDescriptionTemplateControls
        state={state}
        schema={schema}
        onStateChanged={onStateChanged}
        isSubmitted={isSubmitted}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
      <div className="d-flex-column">
        <div className="w-100">
          <CheckboxesComponent
            idRef="printMediaRef"
            labelValue=""
            data={getFeaturedUnitWithPermissions()}
            isRow
            onSelectedCheckboxChanged={(item) => {
              const currentState = state?.[item.key] ?? false;
              if (onStateChanged) {
                onStateChanged({
                  id: item.key,
                  value: !currentState,
                });
              }
              if (item.key === "isDealOfTheDay" && !state?.["isDealOfTheDay"]) {
                onStateChanged({
                  id: "dealExpirationPeriod",
                  value: 1,
                });
              }
            }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            translationPathForData={translationPath}
            labelInput="value"
            checked={(selectedItem) => state[selectedItem.key]}
          />
        </div>
        <div className="w-25 mt-2">
          {state.isDealOfTheDay && (
            <Inputs
              idRef="dealExpirationPeriodRef"
              isDisabled={!returnPropsByPermissions(UnitsLeasePermissions.EditDealOfTheDay.permissionsId)}
              labelValue="deal-Expiration-Period"
              value={state.dealExpirationPeriod}
              onInputChanged={(e) => {
                if(e.target.value < 0)
                  return
                const dealExpirationPeriodValue = e.target.value;
                onStateChanged({
                  id: "dealExpirationPeriod",
                  value: dealExpirationPeriodValue,
                });
              }}
              type={"number"}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              endAdornment={
                <span className="title-text ">
                  {t(`${translationPath}days`)}
                </span>
              }
            />
          )}
        </div>
      </div>

      <PermissionsComponent
        permissionsList={Object.values(UnitsLeasePermissions)}
        permissionsId={
          UnitsLeasePermissions.EditPublishedToWebsiteMarketing.permissionsId
        }
      >
        {/* {activeItem.operation_type.lookupItemId === Sales || activeItem.operation_type.lookupItemId === SaleAndRent ? (
        <CheckboxesComponent
          idRef='printMediaRef'
          labelValue=''
          data={[
              {
                key: 'isPublishUnitSale',
                value: 'Publish-as-sale-listing-Unit',
              },
            ]}
          isRow
          onSelectedCheckboxChanged={(item) => {
              if (onStateChanged) {
                onStateChanged({
                  id: item.key,
                  value: !state[item.key],
                });
              }
            }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          translationPathForData={translationPath}
          labelInput='value'
          checked={(selectedItem) => state[selectedItem.key]}
        />
           ) : null } */}

        {/* {activeItem.operation_type.lookupItemId === Rent || activeItem.operation_type.lookupItemId === SaleAndRent ? (
          <CheckboxesComponent
            idRef='printMediaRef'
            labelValue=''
            data={[
              {
                key: 'isPublishUnitLease',
                value: 'Publish-as-lease-listing-unit',
              },
            ]}
            isRow
            onSelectedCheckboxChanged={(item) => {
              if (onStateChanged) {
                onStateChanged({
                  id: item.key,
                  value: !state[item.key],
                });
              }
            }}

            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            translationPathForData={translationPath}
            labelInput='value'
            checked={(selectedItem) => state[selectedItem.key]}
          />

        ) : null} */}

        {((activeItem &&
          activeItem.operation_type &&
          activeItem.operation_type.lookupItemId === StaticLookupsIds.Rent) ||
          (activeItem &&
            activeItem.operation_type &&
            activeItem.operation_type.lookupItemId ===
              StaticLookupsIds.SaleAndRent)) && (
          <>
            <div className="Publish-wrapper">
              <div className="Publish-title">
                <span>{t(`${translationPath}Publish-as`)}</span>
              </div>
              <CheckboxesComponent
                idRef="isPublishUnitLeaseRef"
                labelValue=""
                data={[
                  {
                    key: "isPublishUnitLease",
                    value: "lease-listing-Unit/psi-website",
                  },
                ]}
                isRow
                onSelectedCheckboxChanged={async (item) => {
                  if (!state.descriptionEn || !state.titleEn) {
                    setIsSubmitted(true);
                    return;
                  }
                  const needsApproval =
                    await isNeedApprovalMessageInMarketing();
                  if (needsApproval) {
                    if (isNew) {
                      saveHandler();
                    }
                    setIsDialogOpen(true);
                  } else {
                    if (onStateChanged) {
                      onStateChanged({
                        id: item.key,
                        value: !state[item.key],
                      });
                    }
                  }
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
                labelInput="value"
                checked={(selectedItem) => state[selectedItem.key]}
                isDisabled={
                  isPendingRequest?.hasPendingRequest === true ||
                  activeItem?.leaseUnitStatusDetails.key !== 1 ||
                  approvalResponse?.hasPendingRequest === true
                }
              />
              {/* <PermissionsComponent
                permissionsList={Object.values(UnitsLeasePermissions)}
                permissionsId={
                  UnitsLeasePermissions.LeaseListingUnitDubizzle.permissionsId
                }
              >
                <CheckboxesComponent
                  idRef='isPublishedForDubizzleLeaseRef'
                  labelValue=''
                  data={[
                    {
                      key: 'isPublishedForDubizzleLease',
                      value: 'lease-listing-Unit/dubizzle',
                    },
                  ]}
                  isRow
                  onSelectedCheckboxChanged={(item) => {
                    if (onStateChanged) {
                      onStateChanged({
                        id: item.key,
                        value: !state[item.key],
                      });
                    }
                  }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                  labelInput='value'
                  checked={(selectedItem) => state[selectedItem.key]}
                />
              </PermissionsComponent> */}
              {/* <PermissionsComponent
                permissionsList={Object.values(UnitsLeasePermissions)}
                permissionsId={
                  UnitsLeasePermissions.LeaseListingUnitBayut.permissionsId
                }
              >

                <CheckboxesComponent
                  idRef='bayutRef'
                  labelValue=''
                  data={[
                    {
                      key: 'isPublishedForBayoutLease',
                      value: 'lease-listing-Unit/bayut',
                    },
                  ]}
                  isRow
                  onSelectedCheckboxChanged={(item) => {
                    if (onStateChanged) {
                      onStateChanged({
                        id: item.key,
                        value: !state[item.key],
                      });
                    }
                  }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                  labelInput='value'
                  checked={(selectedItem) => state[selectedItem.key]}
                />
              </PermissionsComponent> */}
              {/* <PermissionsComponent
                permissionsList={Object.values(UnitsLeasePermissions)}
                permissionsId={
                  UnitsLeasePermissions.LeaseListingUnitPropertyFinder.permissionsId
                }
              >
                <CheckboxesComponent
                  idRef='PropertyFinderRef'
                  labelValue=''
                  data={[
                    {
                      key: 'isPublishedForPropertyFinderLease',
                      value: 'lease-listing-Unit/PropertyFinder',
                    },
                  ]}
                  isRow
                  onSelectedCheckboxChanged={(item) => {
                    if (onStateChanged) {
                      onStateChanged({
                        id: item.key,
                        value: !state[item.key],
                      });
                    }
                  }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                  labelInput='value'
                  checked={(selectedItem) => state[selectedItem.key]}
                />
              </PermissionsComponent> */}
              {/*
              <CheckboxesComponent
                idRef='Prian.ruRef'
                labelValue=''
                data={[
                  {
                    key: 'isPublishedForPrianLease',
                    value: 'lease-listing-Unit/Prianru',
                  },
                ]}
                isRow
                onSelectedCheckboxChanged={(item) => {
                  if (onStateChanged) {
                    onStateChanged({
                      id: item.key,
                      value: !state[item.key],
                    });
                  }
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
                labelInput='value'
                checked={(selectedItem) => state[selectedItem.key]}
              />
         */}
              {/* <PermissionsComponent
                permissionsList={Object.values(UnitsLeasePermissions)}
                permissionsId={
                  UnitsLeasePermissions.LeaseListingUnitHouza.permissionsId
                }
              >
                <CheckboxesComponent
                  idRef='Houza.comRef'
                  labelValue=''
                  data={[
                    {
                      key: 'isPublishedForHouzaLease',
                      value: 'lease-listing-Unit/Houzacom',
                    },
                  ]}
                  isRow
                  onSelectedCheckboxChanged={(item) => {
                    if (onStateChanged) {
                      onStateChanged({
                        id: item.key,
                        value: !state[item.key],
                      });
                    }
                  }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                  labelInput='value'
                  checked={(selectedItem) => state[selectedItem.key]}
                />
              </PermissionsComponent> */}
              <p className="approval-message">
                {approvalResponse?.hasPendingRequest === true &&
                  approvalResponse?.isPublishRequest === true &&
                  "Pending approval publish"}
                {approvalResponse?.hasPendingRequest === true &&
                  approvalResponse?.isPublishRequest === false &&
                  "Pending approval remove publish"}

                {isPendingRequest?.hasPendingRequest === true &&
                  isPendingRequest?.isPublishRequest === true &&
                  "Pending approval publish"}
                {isPendingRequest?.hasPendingRequest === true &&
                  isPendingRequest?.isPublishRequest === false &&
                  "Pending approval remove publish"}
              </p>
            </div>
          </>
        )}
      </PermissionsComponent>
    </div>
  );
};

Descriptions.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  onStateChanged: PropTypes.func.isRequired,
  isMarketAsADifferentAgent: PropTypes.bool.isRequired,
  onIsMarketAsADifferentAgent: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  activeItem: PropTypes.instanceOf(Object).isRequired,
};
