import React, { useCallback, useEffect, useState } from "react";
import {
  LoadableDocumentComponent,
  Tables,
} from "../../../../../../Components";
import { GetReceiptsByInvoiceId } from "../../../../../../Services";
import { MediaPreviewDialog } from "../../../../TemplatesView/Dialogs";
import { TableActions } from "../../../../../../Enums";

export const InvoiceReceiptsTable = ({
  invoiceId,
  parentTranslationPath,
  translationPath,
}) => {
  const [filter, setFilter] = useState({
    pageIndex: 1,
    pageSize: 1000,
  });
  const [allReceipts, setAllReceipts] = useState([]);
  const [activeReceipt, setActiveReceipt] = useState(null);
  const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false);

  const getAllReceipts = async () => {
    const res = await GetReceiptsByInvoiceId({
      invoiceId,
    });
    if (!(res && res.status && res.status !== 200)) {
      setAllReceipts(res);
    }
  };

  const tableActionClicked = useCallback((actionKey, item) => {
    if (actionKey === TableActions.preview.key) {

      const firstListDOC = item?.receiptDocuments?.length > 0 ? item?.receiptDocuments[0] : null;
      if (firstListDOC) {

        const templateFileId = firstListDOC.fileId;
        const templateFileName = firstListDOC.fileName;

        setActiveReceipt({
          ...item,
          templateFileId,
          templateFileName,
        })
        setIsOpenPreviewDialog(true);
      }
    }
  }, []);

  const geTableActions = () => {
    let tableActions = [];

    tableActions.push({
      enum: TableActions.preview.key,
      title: "View document",
    })
    return tableActions;
  }

  useEffect(() => {
    if (invoiceId) getAllReceipts();
  }, [invoiceId]);

  return (
    <div className="mt-3">
      <Tables
        data={allReceipts || []}
        headerData={[
          {
            id: 2,
            label: "net-amount",
            input: "netAmount",
            isNumber: true,
          },
          {
            id: 3,
            label: "contact-type",
            input: "receiptContactTypeName",
            component: (item) => (
              <span>{(item && item.receiptContactTypeName) || "-"}</span>
            ),
          },
          {
            id: 4,
            label: "date",
            input: "date",
            isDate: true,
          },
          {
            id: 5,
            label: "payment-mode",
            input: "paymentModeName",
            component: (item) => (
              <span>{(item && item.paymentModeName) || "-"}</span>
            ),
          },
          {
            id: 6,
            label: "bank",
            input: "bankName",
            component: (item) => <span>{(item && item.bankName) || "-"}</span>,
          },
          {
            id: 7,
            label: "branch",
            input: "branch",
            component: (item) => <span>{(item && item.branch) || "-"}</span>,
          },
          {
            id: 8,
            label: "bank-transfer-no",
            input: "bankTransferNo",
            component: (item) => (
              <span>{(item && item.bankTransferNo) || "-"}</span>
            ),
          },
          {
            id: 9,
            label: "receipt-documents",
            component: (item) => {
              return item?.receiptDocuments
                ? item.receiptDocuments.map((file) => (
                    <span className="file-icon-wrapper d-flex-center mb-1">
                      <span className="mr-1">
                        <LoadableDocumentComponent
                          classes="file-icon"
                          fileName={file.fileName}
                          alt={`cover-image`}
                        />
                      </span>
                      <span className="d-flex-column">
                        <span className="file-title">{file.fileName}</span>
                      </span>
                    </span>
                  ))
                : null;
            },
          },
        ]}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        defaultActions={geTableActions()}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        activePage={filter.pageIndex}
        itemsPerPage={allReceipts?.length || 0}
        totalItems={allReceipts?.length || 0}
      />
      {isOpenPreviewDialog && (
        <MediaPreviewDialog
          activeItem={activeReceipt}
          maintitleText={"Preview documents"}
          isOpen={isOpenPreviewDialog}
          isOpenChanged={() => {
            setIsOpenPreviewDialog(false);
            setActiveReceipt(null);
          }}
          parentTranslationPath={''}
          translationPath={''}
        />
      )}
    </div>
  );
};
