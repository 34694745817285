export const LeadsStatusEnum = {
  lookupTypeId: 33,
  Open: {
    key: 'Open',
    value: 'open',
    status: 457,
  },
  Opoen: {
    key: 'Opoen',
    value: 'open',
  },
  Closed: {
    key: 'Closed',
    value: 'closed',
    status: 458,
  },
};
