
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import { useTitle } from '../../../Hooks';
import {
  TabsComponent , 
  Spinner,
  Inputs , 
} from '../../../Components';
import { PortalConfigurationManagementDialog } from './PortalConfiguration/PortalConfigurationManagement' ;
import { PortalAccountManagementDialog  } from './PortalAccouts/PortalAccountManagement' ; 


import {  PortalTabsData } from './PortalTabsData' ; 


const parentTranslationPath = 'Portals';
const translationPath = '';

export const PortalView = () => {
  const { t } = useTranslation(parentTranslationPath);

  useTitle(t(`${translationPath}portals-configuration`));

  const [isOpenPortalAccountDialog , setIsOpenPortalAccountDialog] = useState(false);
  const [isOpenPortalConfigurationDialog , setIsOpenPortalConfigurationDialog] = useState(false);
  const [isReloadPortalData  ,  setIsReloadPortalData] = useState(false);
  const [isReloadPortalAccountData  ,  setIsReloadPortalAccountData] = useState(false);



  const [activeTab, setActiveTab] = useState(0);

  const [activeTabObj ,setActiveTabObj] = useState(PortalTabsData && PortalTabsData[0])

  const onTabChanged = (event ,index,activeTabItem) => {
    setActiveTab(index);
    setActiveTabObj(PortalTabsData[index]) ; 
  };



 
  return (
    <div className='portals-configuration-wrapper view-wrapper'>
      <div className='d-flex-column'>
        <div className='header-section'>
          <div className='filter-section'>
            <div className='section px-2'>

               { activeTabObj  && activeTabObj.value ===  'PortalConfiguration' && (

                 <ButtonBase
                  onClick={() => {
                    setIsOpenPortalConfigurationDialog(true);  
                  }}
                    className='btns theme-solid bg-primary'
                 >
                 <span className='mdi mdi-plus' />
                 <span className='px-1'>{t(`${translationPath}add-portal-configuration`)}</span>
                  </ButtonBase>

               )}

            { activeTabObj  && activeTabObj.value ===  'PortalAccounts' && (
              <ButtonBase
                 onClick={() => 
                  {
                    setIsOpenPortalAccountDialog(true) ;
                  }}
                 className='btns theme-solid bg-primary'
                  >
              <span className='mdi mdi-plus' />
              <span className='px-1'>{t(`${translationPath}add-portal-Accounts`)}</span>
               </ButtonBase>
               )}       
            </div>
            <div className='section autocomplete-section'>
              <div className='d-flex-column px-2 w-100 p-relative'>
                <div className='w-100 p-relative'>
                  {/* <Inputs
                    idRef='cityGallerySearchRef'
                    label='filter'
                    beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                    // value={searchInput}
                    onInputChanged={(e) => {
                    // searchHandler(e.target.value);
                    }}
                    inputPlaceholder='search-city-gallery'
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-100 px-3'>
          <TabsComponent
            data={PortalTabsData}
            labelInput='label'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            wrapperClasses='is-with-line'
            currentTab={activeTab}
            onTabChanged={onTabChanged}
            dynamicComponentProps={{
              parentTranslationPath,
              translationPath,
              activeTab,
              isReloadPortalData, 
              setIsReloadPortalData , 
              isReloadPortalAccountData , 
              setIsReloadPortalAccountData , 
            }}
          />
        </div>
      </div>
      {
        isOpenPortalConfigurationDialog && 
        ( 
          <PortalConfigurationManagementDialog 
             parentTranslationPath={parentTranslationPath}
             translationPath={translationPath}
             isOpen={isOpenPortalConfigurationDialog}
             onClose={()=> setIsOpenPortalConfigurationDialog(false)} 
             onSave={()=> 
             {

              setIsOpenPortalConfigurationDialog(false) ; 
              setIsReloadPortalData(!isReloadPortalData); 
             }}
          />
        ) 
      }
        {
        isOpenPortalAccountDialog && 
        ( 
          <PortalAccountManagementDialog 
             parentTranslationPath={parentTranslationPath}
             translationPath={translationPath}
             isOpen={isOpenPortalAccountDialog}
             onClose={()=> setIsOpenPortalAccountDialog(false)} 
             onSave={()=> 
             {
              setIsOpenPortalAccountDialog(false) ; 
              setIsReloadPortalAccountData(!isReloadPortalAccountData);

             }}
          />
        ) 
      }

    </div>
  );
};
