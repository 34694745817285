import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    gap: "12px",
    marginBottom: "24px",
    alignItems: "center",
    flexWrap: "wrap",
  },
  selectedCount: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    fontSize: "18px",
  },
}));
