import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    overviewContainer: {
      display: "flex",
      gap: "24px",
      [theme.breakpoints.down('md')]: {
        flexDirection: "column",
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: "column",
      },
    },
    overviewContainerExpanded: {
      [theme.breakpoints.down('lg')]: {
        [theme.breakpoints.up('md')]: {
          flexDirection: "column",
        },
      },
    },
    mainContentBox: {
      flex: 0.671,
      display: "flex",
      flexDirection: "column",
      gap: "24px",
    },
    sideContentBox: {
      flex: 0.329,
      display: "flex",
      flexDirection: "column",
      gap: "24px",
    },
  };
});
