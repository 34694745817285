import { CheckContactCommunication } from "../Services";


const checkContactCommunication = async ({ contactId, leadId }) => {
  const defaultCheckValue = {
    canCommunicate: false,
    canSendConsent: false,
    isWithinWorkingHours: false,
  };

  let localStorageConsent = {
    activeContactId: contactId || leadId || null,
    info: {},
  };

  localStorage.setItem(
    "Contact_Consent_Details",
    JSON.stringify(localStorageConsent)
  );
  const res = await CheckContactCommunication({ contactId, leadId });
  if (!(res && res.status && res.status !== 200)) {
    localStorageConsent.info = res;

    localStorage.setItem(
      "Contact_Consent_Details",
      JSON.stringify(localStorageConsent)
    );
    return res;
  } else {
    localStorageConsent.info = defaultCheckValue;
    return defaultCheckValue;
  }
};

const isCommunicationCheckedBefore = ({ contactId, leadId }) => {
  const cashedConsentInfo = JSON.parse(
    localStorage.getItem("Contact_Consent_Details") || null
  );

  return (
    (cashedConsentInfo?.activeContactId &&
      (contactId === cashedConsentInfo?.activeContactId ||
        leadId === cashedConsentInfo?.activeContactId)) ||
    false
  );
};





//:: HELPER Functions :://
export const CheckContactConsent = async ({ contactId, leadId }) => {
  const cashedConsentInfo = JSON.parse(
    localStorage.getItem("Contact_Consent_Details") || null
  );

  let contactCommunicateActionInfo = isCommunicationCheckedBefore({
    contactId,
    leadId,
  })
    ? cashedConsentInfo?.info
    : await checkContactCommunication({ contactId, leadId });

  return contactCommunicateActionInfo || null;
};

export const CheckIsCommunicateAllowed = async ({ contactId, leadId }) => {
  const consentInfo = await CheckContactConsent({ contactId, leadId });
  return consentInfo?.canCommunicate || false;
};

export const CheckIsSendConsentAllowed = async ({ contactId, leadId }) => {
  const consentInfo = await CheckContactConsent({ contactId, leadId });
  return consentInfo?.canSendConsent || false;
};

export const CheckIsWithinWorkingHours = async ({ contactId, leadId }) => {
  const consentInfo = await CheckContactConsent({ contactId, leadId });
  return consentInfo?.isWithinWorkingHours || false;
};

export const ReloadConsentDetails = async ({ contactId, leadId }) => {
  localStorage.setItem("Contact_Consent_Details", null);
  await CheckContactConsent({ contactId, leadId });
}
