import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    contactOverViewCard: {
      padding: "24px",
    },
    commentsCardTitleWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
      paddingBottom: "20px",
    },
    titleContainer: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      gap: "4px",
      alignItems: "flex-start",
      [theme.breakpoints.down("sm")]: {
        minWidth: "60px",
      },
    },
    textareaContainer: {
      marginBottom: "26px",
    },
    actionContainer: {
      display: "flex",
      justifyContent: "flex-end",
      paddingBottom: "20px",
      marginBottom: "20px",
      borderBottom: `1px solid ${theme.palette.border.secondary}`,
    },
    commentsContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
    },
  };
});
