import React from 'react';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { CustomCard } from '../../CustomCard';

// Styles
import useStyles from './styles';

function SummaryCardSkeleton() {
  const styles = useStyles();

  return (
    <CustomCard classes={styles.cardStyle} boxShadow="xs" borderRadius="xl" borderColor="secondary">
      <Box className={styles.titleHeading}>
        <Skeleton variant="text" width="60%" height={24} />
      </Box>
      <Box className={styles.contentWrapper} display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' bgcolor='background.paper'>
        <Skeleton variant="text" width="40%" height={55} />
        <Skeleton variant="rectangular" width={80} height={22} className={styles.buttonSkeleton} />
      </Box>
    </CustomCard>
  );
}

export default SummaryCardSkeleton;