
import React, { useCallback, useEffect, useState , useRef } from 'react';
import { useTranslation } from 'react-i18next'; 
import { AutocompleteComponent } from '../../Components' ; 
import {
  ContactRule, UserRule, UnitRule, PropertyRule , ActivityTypeRule  , CampaignsRule 
} from './Rules';


export const SearchItemComponent = ({
    translationPath,
    parentTranslationPath,
    item , 
    state,
    allSearchFieldWithValues,
    setAllSearchFieldWithValues , 
    index,
    filtersWithValues , 
    setFiltersWithValues , 
    changeOperator, 
}) => {

    const { t } = useTranslation('Shared');

    const searchTimer = useRef(null);
    const [isLoading ,setIsLoading ] = useState(false) ;

    const [items, setItems] = useState([]);
    

    // useEffect(() => {
    //   setItems([]);
    //   if(item && item.data && item.data.searchKey === 'User')
    //   UserRule(item , '' ,  setItems, setIsLoading);

    //   if(item && item.data && item.data.searchKey === 'unit')
    //   UnitRule(item , '' ,  setItems, setIsLoading , );

    //   if(item && item.data && item.data.searchKey === 'property')
    //   PropertyRule(item , '' ,  setItems, setIsLoading);    
    
    //   if(item && item.data && item.data.searchKey === 'activtyType')
    //   ActivityTypeRule(item , '' ,  setItems , setIsLoading)

    //   if(item && item.data && item.data.searchKey === 'campaign_name')
    //     CampaignsRule(item , '' ,  setItems , setIsLoading)
       
      
    // }, [item]);


  
    return (
        <>
        <AutocompleteComponent
                key={`filter${index + 1}-${item.key + index}-${item.input}`}
                selectedValues={filtersWithValues[index] && filtersWithValues[index].displayObj }
                idRef={`filter${index + 1}-${item.input}`}
                multiple={false}
                inputPlaceholder={t('search')}
                data={(item && item.data && item.data.enum && item.data.enum.length ) ? (item.data.enum) : items  }
                inputClasses='inputs theme-form-builder'
                displayLabel={
                  ((option) => (option  && option.name)  || '')
                }
                onChange={(e, v) => {
                  const updatelist = [...filtersWithValues];
                  updatelist[index] = {
                    ...updatelist[index],
                    displayValue :  (v && v.name ) || v  , 
                    displayObj: v,
                  };

                  setFiltersWithValues(updatelist) ; 
                  changeOperator((v && v.name ) || v);

                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    if(item && item.data && item.data.searchKey === 'contact')
                    ContactRule(item , value ,  setItems, setIsLoading);

                    if(item && item.data && item.data.searchKey === 'User')
                    UserRule(item , value ,  setItems, setIsLoading);

                    if(item && item.data && item.data.searchKey === 'unit')
                    UnitRule(item , value ,  setItems, setIsLoading);

                    if(item && item.data && item.data.searchKey === 'property')
                    PropertyRule(item , value ,  setItems, setIsLoading);

                    if(item && item.data && item.data.searchKey === 'activtyType')
                      ActivityTypeRule(item , value ,  setItems , setIsLoading); 

                    if(item && item.data && item.data.searchKey === 'campaign_name')
                         CampaignsRule(item , value ,  setItems , setIsLoading) ; 

                  }, 1000);
                }}
                isLoading={isLoading}
                withLoader
                withoutSearchButton
                labelValue={item.label}
                filterOptions={(options) => options}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
          
        </>
    );
};
