
export const MarketingDashboardPermissions = {
    MarketingDashboard: 
      {
        permissionsId:'94fd4f38-2b97-410d-a899-08da6e2b177d',
        permissionsName:'Marketing'  ,
        componentsId:null,
        components:null , 
      }
  } ;
  