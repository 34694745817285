import React, { useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { Menu, IconButton, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useIsAr } from "../../../../Hooks";
import EventsComponent from "../../EventsComponent/EventsComponent";

const useStyles = makeStyles((theme) => {
  return {
    customMenu: {
      top: "58px !important",
      borderRadius: "12px !important",
      border: `1px solid ${theme.palette.border.secondary}`,
      overflowY: "hidden",
      boxShadow:
        "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03) !important",
    },

    calendarIcon: {
      color: theme.palette.text.tertiary,
      fontSize: "20px",
    },
    calendarButton: (props) => ({
      color: theme.palette.text.tertiary,
      padding: "8px 10px",
      borderRadius: "6px",
      "&:hover": {
        background: theme.palette.background.primary_hover,
      },
      boxShadow: props.isMenuExpanded
        ? "0px 0px 0px 4px rgba(152, 162, 179, 0.14)"
        : "none",
      marginRight: "4px",
      marginLeft: "4px",
    }),
    customMenuList: {
      padding: 0,
    },
  };
});

function RenderCalendar() {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useSelector((store) => store.theme);
  const { isAr } = useIsAr(theme.themeDirection);

  const styles = useStyles({
    isMenuExpanded: !!anchorEl,
  });

  const handleMenuOpen = (event) => {
    setIsOpen(!isOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const anchorOrigin = isAr
    ? { vertical: "bottom", horizontal: "left" }
    : { vertical: "bottom", horizontal: "right" };

  const transformOrigin = isAr
    ? { vertical: "top", horizontal: "left" }
    : { vertical: "top", horizontal: "right" };

  return (
    <>
      <IconButton
        aria-controls="calendar-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        className={styles.calendarButton}
        disableRipple
      >
        <Icon
          className={clsx("icon-header-calendar", styles.calendarIcon)}
        ></Icon>
      </IconButton>

      <Menu
        id="calendar-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        getContentAnchorEl={null}
        PaperProps={{
          className: styles.customMenu,
        }}
        MenuListProps={{
          className: styles.customMenuList,
        }}
      >
        <EventsComponent isOpen={true} top={0} onClose={handleMenuClose} />
      </Menu>
    </>
  );
}

export default RenderCalendar;
