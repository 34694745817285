import React, { useRef } from "react";
import { Paper, Box, Typography } from "@material-ui/core";
import { useSelectedTheme, useOutsideClick } from "../../../Hooks";
import { CustomButton } from "../CustomButton";

// Styles
import useStyles from "./styles";

// Icons
import { CloseXIcon, AlertCircle } from "../../../assets/icons";

function DeleteConfirmationDialog({ title, subTitle, onClose, onConfirm }) {
  const styles = useStyles();

  const {
    theme: { palette },
  } = useSelectedTheme();

  const modalRef = useRef(null);
  const iconRef = useRef(null);

  useOutsideClick({
    ref: modalRef,
    handler: () => onClose,
    iconRef,
  });

  return (
    <Box className={styles.modalContainer}>
      <Paper ref={modalRef} className={styles.modalPaper}>
        <Box className={styles.dialogHeadingContainer}>
          <Box className={styles.alertIconWrapper}>
            <AlertCircle
              width="24"
              height="24"
              fill={palette.icons.lightFgError}
            />
          </Box>
          <Box className={styles.dialogHeading}>
            <Box className={styles.titleWithIcon}>
              <Typography className={styles.title} variant="h5">
                {title}
              </Typography>
              <CloseXIcon
                onClick={onClose}
                style={{ cursor: "pointer" }}
                width="20"
                height="20"
                fill={palette.foreground.quinary}
              />
            </Box>
            <Typography className={styles.subTitle} variant="body1">
              {subTitle}
            </Typography>
          </Box>
        </Box>
        <Box className={styles.dialogContent}>
          <Box className={styles.actions}>
            <CustomButton
              boxShadow="xs"
              size="lg"
              variant="outlined"
              color="secondaryGray"
              onClick={onClose}
            >
              Cancel
            </CustomButton>
            <CustomButton
              boxShadow="xs"
              size="lg"
              variant="contained"
              color="destructive"
              onClick={onConfirm}
            >
              Delete
            </CustomButton>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default DeleteConfirmationDialog;
