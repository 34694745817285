
export const CorporateKycFormMap = {
  "CompanyInformations": {
    "sectionLabel": "Company Informations",
    "sectionKey": "CompanyInformations",
    "sectionColNo": 4,
    "sectionFields": [
      {
        "label": "Company Name",
        "input": "companyName",
        "fieldType": "readOnly"
      },
      {
        "label": "Legal Structure*",
        "input": "legalStructureInfo",
        "fieldType": "selectInput",
        "optionsKey": "ResidenceStatus",
        "lookupName": "ResidenceStatus"
      },
      {
        "label": "Landline",
        "input": "landline",
        "fieldType": "readOnly"
      },
      {
        "label": "Physical Location",
        "input": "physicalLocation",
        "fieldType": "textInput"
      },
      {
        "label": "PO.Box",
        "input": "poBox",
        "fieldType": "readOnly"
      },
      {
        "label": "Emirates",
        "input": "emirates",
        "fieldType": "textInput"
      },
      {
        "label": "E-mail",
        "input": "email",
        "fieldType": "readOnly"
      },
      {
        "label": "Website",
        "input": "website",
        "fieldType": "readOnly"
      },
      {
        "label": "Trade License No",
        "input": "licenceNo",
        "fieldType": "readOnly"
      },
      {
        "label": "Chamber of Commerce",
        "input": "chamberOfCommerce",
        "fieldType": "textInput"
      },
      {
        "label": "Type of Business/ Business Activity",
        "input": "typeOfBusiness",
        "fieldType": "textInput"
      },
      {
        "label": "Payment method*",
        "input": "paymentMethodInfo",
        "fieldType": "selectInput",
        "optionsKey": "MethodOfPayment",
        "lookupName": "NatureOfTransaction(PaymentMethod)"
      }
    ]
  },
  "OwnerInformations": {
    "sectionLabel": "Name(s) and Nationality of Owner(s)/Partner",
    "sectionKey": "OwnerInformations",
    "canAddRecord": true,
    "tableDataKey": "companyPartnerNamesAndNationalities",
    "headerData": [
      {
        "id": 1,
        "label": "Name",
        "input": "contactName",
        "fieldType": "selectInput",
        "optionsKey": "Contacts"
      },
      {
        "id": 2,
        "label": "Nationality",
        "input": "nationality",
        "fieldType": "readOnly"
      },
      {
        "id": 3,
        "label": "EID/ Passport Number",
        "input": "passportNumber",
        "fieldType": "readOnly"
      },
      {
        "id": 4,
        "label": "Percentage",
        "input": "percentage",
        "fieldType": "textInput",
        "isNumber": true
      }
    ]
  },
  "SignOnBehalfOfCompany": {
    "sectionLabel": "Person Authorized to sign on behalf of the company",
    "sectionKey": "SignOnBehalfOfCompany",
    "canAddRecord": true,
    "tableDataKey": "companySignatories",
    "headerData": [
      {
        "id": 1,
        "label": "Name",
        "input": "contactName",
        "fieldType": "selectInput",
        "optionsKey": "Contacts"
      },
      {
        "id": 2,
        "label": "Nationality",
        "input": "nationality",
        "fieldType": "readOnly"
      },
      {
        "id": 3,
        "label": "Specimen Signature",
        "input": "specimenSignature",
        "fieldType": "textInput"
      }
    ]
  },
  "ShareholderInformation": {
    "sectionLabel": "Shareholder Information",
    "sectionKey": "ShareholderInformation",
    "sectionSubLabel": "Please provide details of three major companies with whom you are presently dealing with",
    "canAddRecord": true,
    "tableDataKey": "companyStakeholdersInformation",
    "headerData": [
      {
        "id": 1,
        "label": "Name of shareholder",
        "input": "contactName",
        "fieldType": "selectInput",
        "optionsKey": "Contacts"
      },
      {
        "id": 2,
        "label": "Nationality",
        "input": "nationality",
        "fieldType": "readOnly"
      },
      {
        "id": 3,
        "label": "Contact Mobile",
        "input": "contactMobile",
        "fieldType": "readOnly"
      },
      {
        "id": 4,
        "label": "Residence status",
        "input": "residenceStatus",
        "fieldType": "selectInput",
        "optionsKey": "ResidenceStatus",
        "lookupName": "ResidenceStatus"
      },
      {
        "id": 5,
        "label": "Share Percentage",
        "input": "sharePercentage",
        "fieldType": "textInput",
        "isNumber": true
      }
    ]
  },
  "MandatoryInformation": {
    "sectionLabel": "Mandatory Information",
    "sectionKey": "MandatoryInformation",
    "sectionColNo": 2,
    "sectionFields": [
      {
        "label": "Any of your Directors/ Principals/ Executive Management fall into any of the above Categories?",
        "input": "Directors",
        "fieldType": "radioButton",
        "optionsKey": "Yes_No",
        "forClientOnly": true,
      },
      {
        "label": "Any IMMEDIATE FAMILY of, or a CLOSE PERSONAL/PROFESSIONAL ASSOCIATE of your Directors/ Principals/ Executive Management fall into any of the above Categories?",
        "input": "IMMEDIATE_FAMILY",
        "fieldType": "radioButton",
        "optionsKey": "Yes_No",
        "forClientOnly": true
      },
      {
        "label": "The source of the funds that we shall be making to PSI or Developer as Payment are:",
        "input": null,
        "fieldType": "textInput",
        "forClientOnly": true
      }
    ],
    "beforeText":  true
  },
  "Confirmation": {
    "sectionLabel": "Confirmation",
    "sectionKey": "Confirmation",
    "sectionColNo": 2,
    "sectionFields": [
      {
        "label": "Declaration",
        "input": "agreement_checkbox",
        "fieldType": "singleCheckbox",
        "boxLabel": "I agree and understand that by signing the E-Signature, I confirm that electronic signature is legal, binding and equivalent of my manual / handwritten signature."
      },
      {
        "label": "Printed / Typed Name",
        "input": "Confirmation_Typed_Name",
        "fieldType": "textInput",
        "forClientOnly": true
      },
      {
        "label": "Title",
        "input": "Confirmation_Title",
        "fieldType": "textInput",
        "forClientOnly": true
      },
      {
        "label": "Date",
        "input": "Confirmation_Date",
        "fieldType": "textInput",
        "forClientOnly": true
      },
      {
        "label": "Signature of the Client",
        "input": "Confirmation_Client_Signature",
        "fieldType": "signature",
        "forClientOnly": true,
        "placeholder": "Client Signature"
      },
      {
        "label": "Company Seal",
        "input": "Confirmation_Company_Seal",
        "fieldType": "signature",
        "forClientOnly": true,
        "placeholder": "Company Seal"
      }
    ],
    "beforeText":  true
  },
  "ForOfficeUseOnly": {
    "sectionLabel": "FOR OFFICE USE ONLY",
    "sectionKey": "ForOfficeUseOnly",
    "sectionColNo": 2,
    "sectionFields": [
      {
        "label": "Name of the Agent/Representative.",
        "input": null,
        "fieldType": "none"
      },
      {
        "label": "Date",
        "input": null,
        "fieldType": "none"
      },
      {
        "label": "Signature of the Agent/Representative",
        "input": null,
        "fieldType": "signature",
        "placeholder": "Agent/Representative Signature"
      }
    ],
    "beforeText": true,
    "afterText": true
  }
}
;
