import React from 'react';
import psiNewLogo from '../../assets/images/defaults/psi-new-logohj.png'

export const SupportHeader = () => {
  return (
    <div className='support-header-wrapper'>
      <div className='header-content'>
        <p className='page-title'>
          <img className="header-logo" src={psiNewLogo}/>
          <span>Property Shop Investment</span>
          <span className='vertical-line'></span>
          <span>Support</span>
        </p>
        <div className='user-section-wrapper'>
          {/* <div className='header-user-img'>
            <img src='https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80' />
          </div>
          <div className='header-user-name'>
            <p>Loki Bright</p>
            <p>loki@psinv.net</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};
