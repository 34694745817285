import React from "react";

const Sidebar = ({ onDragStart, initialNodes, Triggers }) => {
  return (
    <aside className="sidebar">
      <div className="drag-sub-text">Drag Item To View</div>
      {Triggers.result && Triggers.result.length > 0 ? (
        Triggers.result.map((trigger, index) => (
          <div
            key={index}
            className={`dndnode-input ${
              initialNodes && initialNodes.length > 0 ? "disabled" : ""
            }`}
            onDragStart={(event) => onDragStart(event, trigger)}
            draggable={initialNodes && initialNodes.length === 0}
          >
            {trigger.trigerName || ""}
          </div>
        ))
      ) : (
        <div>No triggers available.</div>
      )}
    </aside>
  );
};

export default Sidebar;
