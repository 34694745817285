import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  progressBar: {
    backgroundColor: theme.palette.background.quarterary,
    borderRadius: "30px",
    height: "8px",
    width: "100%",
  },
  progressBarFill: {
    backgroundColor: theme.palette.utility.brand_primary,
    borderRadius: "30px",
    height: "8px",
  },
}));
