import { MainDashboard } from "../MainDashboard/MainDashboard";
import { PowerBiEnum } from "../../../../Enums";
import { MainDashboardPolicy } from "../MainDashboardPolicy/MainDashboardPolicy";
import { config } from "../../../../config";
import { ListingUnitMainPolicy } from "../ListingUnitMainPolicy/ListingUnitMainPolicy";
import { LeaseListingUnitMainPolicy } from "../LeaseListingUnitMainPolicy/LeaseListingUnitMainPolicy";
import { CRMFilterDashboards } from "../CRMFilterDashboards/CRMFilterDashboards";
import { CallCenterFilter } from "../CallCenterFilter";
import { MarketingFilter } from "../MarketingFilter";
import { ListingUnitsFilter } from "../ListingUnitsFilter";
import { LeadAutomationFilter } from "../LeadAutomationFilter/LeadAutomationFilter";
import { UnqualifiedLeadsFilter } from "../UnqualifiedLeadsFilter/UnqualifiedLeadsFilter";
import { InternationalSalesYTDMTD } from "../InternationalSalesYTDMTD";
import { UsersDetails } from "../UsersDetails";
import { ListingAllUnits } from "../ListingAllUnits";
import { AdminLeadPoolCmp } from "../AdminLeadPoolCmp/AdminLeadPoolCmp";

export const DashboardTabs = [
  {
    index: 0,
    label: "Dashboard:sales",
    component: CRMFilterDashboards,
    config: {
      reportId: PowerBiEnum.SalesTeamLeadDate.reportid,
      groupId: PowerBiEnum.SalesTeamLeadDate.groupid,
      Url: PowerBiEnum.SalesTeamLeadDate.url,
    },
    permissionsId: "2927e262-852d-434e-ee3c-08da65738802",
  },
  {
    index: 1,
    label: "Dashboard:lease",
    component: CRMFilterDashboards,
    config: {
      reportId: PowerBiEnum.LeaseTeamLeadDate.reportid,
      groupId: PowerBiEnum.LeaseTeamLeadDate.groupid,
      Url: PowerBiEnum.LeaseTeamLeadDate.url,
    },
    permissionsId: "fda7d11b-92e6-420b-ee3d-08da65738802",
  },
  {
    index: 2,
    label: "Dashboard:contact-center",
    component: CallCenterFilter,
    config: {
      reportId: PowerBiEnum.callCenterDate.reportid,
      groupId: PowerBiEnum.callCenterDate.groupid,
      Url: PowerBiEnum.callCenterDate.url,
    },
    permissionsId: "612750e1-f9ac-4055-ee3e-08da65738802",
  },
  {
    index: 3,
    label: "Dashboard:marketing",
    component: MarketingFilter,
    config: {
      reportId: PowerBiEnum.newmarketing.reportid,
      groupId: PowerBiEnum.newmarketing.groupid,
      Url: PowerBiEnum.newmarketing.url,
    },
    permissionsId: "87d3e2d8-95c3-48e4-ee3f-08da65738802",
  },
  {
    index: 4,
    label: "Dashboard:PSI-Daily",
    component: MainDashboardPolicy,
    config: {
      reportId: PowerBiEnum.Newpsidaily.reportid,
      groupId: PowerBiEnum.Newpsidaily.groupid,
      Url: PowerBiEnum.Newpsidaily.url,
    },
    permissionsId: "1218d591-95c3-4874-ee40-08da65738802",
  },
  {
    index: 5,
    label: "Dashboard:saleslistingmanager",
    component: ListingUnitsFilter,
    config: {
      reportId: PowerBiEnum.SalesListingUnitsDate.reportid,
      groupId: PowerBiEnum.SalesListingUnitsDate.groupid,
      Url: PowerBiEnum.SalesListingUnitsDate.url,
    },
    permissionsId: "103383c1-c489-465e-ee41-08da65738802",
  },
  {
    index: 6,
    label: "Dashboard:leaselistingmanager",
    component: ListingUnitsFilter,
    config: {
      reportId: PowerBiEnum.LeaseListingUnitsDate.reportid,
      groupId: PowerBiEnum.LeaseListingUnitsDate.groupid,
      Url: PowerBiEnum.LeaseListingUnitsDate.url,
    },
    permissionsId: "71b14c93-c3de-49b7-ee42-08da65738802",
  },
  {
    index: 7,
    label: "Dashboard:ListingUnits",
    component: ListingAllUnits,
    config: {
      reportId: PowerBiEnum.ListingAllUnits.reportid,
      groupId: PowerBiEnum.ListingAllUnits.groupid,
      Url: PowerBiEnum.ListingAllUnits.url,
    },
    permissionsId: "824ae3d9-29b2-450f-5a64-08dc32a402e7",
  },

  {
    index: 8,
    label: "Dashboard:intentionallead",
    component:  MainDashboardPolicy,
    config: {
      reportId: PowerBiEnum.intentionalsales.reportid,
      groupId: PowerBiEnum.intentionalsales.groupid,
      Url: PowerBiEnum.intentionalsales.url,
    },
    permissionsId: "ff9163cb-d061-4230-ee44-08da65738802",
  },
  {
    index: 9,
    label: "Dashboard:branches",
    component: MainDashboardPolicy,
    config: {
      reportId: PowerBiEnum.Branches.reportid,
      groupId: PowerBiEnum.Branches.groupid,
      Url: PowerBiEnum.Branches.url,
    },
    permissionsId: "b80495c5-45f2-4b4d-2456-08dac1673f1e",
  },
  {
    index: 10,
    label: "Dashboard:leasecontracts",
    component: MainDashboard,
    config: {
      reportId: PowerBiEnum.Leasedetails.reportid,
      groupId: PowerBiEnum.Leasedetails.groupid,
      Url: PowerBiEnum.Leasedetails.url,
    },
    permissionsId: "2bf1fdae-08ac-4dc1-0773-08dad1f91be5",
  },
  {
    index: 11,
    label: "Dashboard:customersRisk",
    component: MainDashboard,
    config: {
      reportId: PowerBiEnum.CustomersRisk.reportid,
      groupId: PowerBiEnum.CustomersRisk.groupid,
      Url: PowerBiEnum.CustomersRisk.url,
    },
    permissionsId: "1f2b1fe6-a4ab-4ac2-286b-08dbaf79a1bb",
  },
  {
    index: 12,
    label: "Dashboard:CampaignCost",
    component: MainDashboard,
    config: {
      reportId: PowerBiEnum.CampaignCost.reportid,
      groupId: PowerBiEnum.CampaignCost.groupid,
      Url: PowerBiEnum.CampaignCost.url,
    },
    permissionsId: "cc899969-ca78-44e5-7047-08dbf65badbd",
  },
  {
    index: 13,
    label: "Dashboard:leadower-dashboard",
    component: MainDashboard,
    config: {
      reportId: PowerBiEnum.LeadOwner.reportid,
      groupId: PowerBiEnum.LeadOwner.groupid,
      Url: PowerBiEnum.LeadOwner.url,
    },
    permissionsId: "d78ea1b1-559e-492d-8870-08dc0129f7d6",
  },
  {
    index: 14,
    label: "Dashboard:LeadAutomation",
    component: LeadAutomationFilter,
    config: {
      reportId: PowerBiEnum.LeadAutomation.reportid,
      groupId: PowerBiEnum.LeadAutomation.groupid,
      Url: PowerBiEnum.LeadAutomation.url,
    },
    permissionsId: "c0c19d0f-51cb-4102-f94b-08dc0f48c1d2",
  },
  {
    index: 15,
    label: "Dashboard:UnqualifiedLeads",
    component: UnqualifiedLeadsFilter,
    config: {
      reportId: PowerBiEnum.UnqualifiedLeads.reportid,
      groupId: PowerBiEnum.UnqualifiedLeads.groupid,
      Url: PowerBiEnum.UnqualifiedLeads.url,
    },
    permissionsId: "d0d9a9a7-7505-4e47-f94c-08dc0f48c1d2",
  },
  {
    index: 16,
    label: "Dashboard:InternationalSalesYTDMTD",
    component: InternationalSalesYTDMTD,
    config: {
      reportId: PowerBiEnum.InternationalSalesYTDMTD.reportid,
      groupId: PowerBiEnum.InternationalSalesYTDMTD.groupid,
      Url: PowerBiEnum.InternationalSalesYTDMTD.url,
    },
    permissionsId: "d481fbfe-43c1-4e98-f94e-08dc0f48c1d2",
  },
  {
    index: 17,
    label: "Dashboard:UsersDetails",
    component: UsersDetails,
    config: {
      reportId: PowerBiEnum.UsersDetails.reportid,
      groupId: PowerBiEnum.UsersDetails.groupid,
      Url: PowerBiEnum.UsersDetails.url,
    },
    permissionsId: "ed6e5644-eec3-4dcd-f94f-08dc0f48c1d2",
  },
  {
    index: 18,
    label: "Dashboard:accounts",
    component: MainDashboardPolicy,
    config: {
      reportId: PowerBiEnum.accounts.reportid,
      groupId: PowerBiEnum.accounts.groupid,
      Url: PowerBiEnum.accounts.url,
    },
    permissionsId: "9746b2f2-79b3-4119-ee43-08da65738802",
  },
  {
    index: 19,
    label: "Dashboard:campaign-request-dashboard",
    component: MainDashboard,
    config: {
      reportId: PowerBiEnum.CampaignRequest.reportid,
      groupId: PowerBiEnum.CampaignRequest.groupid,
      Url: PowerBiEnum.CampaignRequest.url,
    },
    permissionsId: "1a0e14bb-e304-42e4-c4ce-08dc683b0c43",
  },
  {
    index: 20,
    label: "Dashboard:leadpool",
    component: AdminLeadPoolCmp,
    // config: {
    //   reportId: PowerBiEnum.CampaignRequest.reportid,
    //   groupId: PowerBiEnum.CampaignRequest.groupid,
    //   Url: PowerBiEnum.CampaignRequest.url,
    // },
    permissionsId: "44184cc4-0fc6-49fb-5dc8-08dc7403ebc2",
  },
];
