export const ContactPreferenceEnum = {
  email: {
    key: 18169,
    value: 'Email',
    actionEnumKey: 'emailSolid',
    buttonClasses: 'btns-icon theme-solid',
    icon: 'mdi mdi-email-outline',
  },
  whatsapp: {
    key: 18171,
    value: 'WhatsApp',
    actionEnumKey: 'whatsappSolid',
    buttonClasses: 'btns-icon theme-solid bg-green-dark',
    icon: 'mdi mdi-whatsapp',
  },
  sms: {
    key: 18170,
    value: 'SMS',
    actionEnumKey: 'smsSolid',
    buttonClasses: 'btns-icon theme-solid',
    icon: 'mdi mdi-cellphone',

  },
  call: {
    key: 18172,
    value: 'Call',
    actionEnumKey: 'phoneSolid',
    buttonClasses: 'btns-icon theme-solid',
    icon: 'mdi mdi-phone',

  },
};
