import React  , {  useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogComponent, Spinner } from '../../../../Components';
import { DeleteConvoloRotationSchemeAPI  } from '../../../../Services' ; 
import { showSuccess  , showError  } from '../../../../Helper' ; 

export const DeleteConvoloAgent = ({
  isOpen, 
  parentTranslationPath,
  translationPath,
  onSave,
  onClose , 
  activeItem, 
}) => {

  const { t } = useTranslation(parentTranslationPath);
  const [isLoading , setIsLoading ] = useState(false) ; 

  const deleteHandler = async () => {
    setIsLoading(true);
    const res = await DeleteConvoloRotationSchemeAPI(activeItem && activeItem.convoloRotationSchemeId )
    if (!(res && res.status && res.status !== 200)) {
        showSuccess(t(`${translationPath}delete-convolo-rotation-scheme-succes`));
        onSave();
    } else showError(t(`${translationPath}delete-convolo-rotation-scheme-failed`));
    setIsLoading(false);
};

  return (
    <DialogComponent
        titleText='confirm-message'
        saveText={t(`${translationPath}confirm`)}
        SmothMove
        saveType='button'
        maxWidth='sm'
        dialogContent={(
          <div className='d-flex-column-center'>
            <Spinner isActive={isLoading} isAbsolute />
            <span className='mdi mdi-close-octagon c-danger mdi-48px' />
            <span className='fz-18px fw-bold'>
              {`${`${t(`${translationPath}are-you-sure-to-convolo-rotation-scheme`)}` || ''}`}
              <span className='d-flex-center  fz-22px fw-bold mt-2'>
                {' '}
                (
                {' '}
                {' '}
                {activeItem && activeItem.convoloRotationSchemeName}
                {' '}
                {' '}
                {' '}
                )
                {' '}
              </span>

            </span>
          </div>
        )}
        saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
        isOpen={isOpen}
        onSaveClicked={()=> deleteHandler()}
        onCloseClicked={() =>  onClose()}
        onCancelClicked={() => onClose() }
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />

  );
};
