export const SchoolFeesDialogHeaderData = [
  {
    id: 1,
    label: "Books Fees",
    input: "bookFees",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 2,
    label: "Bus Fees",
    input: "busFees",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 3,
    label: "Grade",
    input: "grade",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 4,
    label: "School Number",
    input: "schoolNumber",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 5,
    label: "Total Fees",
    input: "totalFees",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 6,
    label: "Tution Fees",
    input: "tuitionFees",
    isHiddenFilter: true,
    isSortable: false,
  },
];
