import React, { useContext } from "react";
import { Box, IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { ContactLayoutContext } from "../../../Layouts/Home/NewContactsCrmLayout/ContactLayoutContext";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";
import { useSelectedTheme, useIsDesktop } from "../../../Hooks";

import useStyles from "./styles";

import { ActivityIcon, EyeIcon, ListIcon } from "../../../assets/icons";

const TableActions = ({ anchorPosition }) => {
  const {
    theme: { palette },
  } = useSelectedTheme();
  const { setIsAddTaskDialogOpen, setAddActivity } =
    useContext(ContactLayoutContext);
  const { tablePopoverContent } = useVerticalNav();
  const history = useHistory();
  const { isDesktop } = useIsDesktop();
  const styles = useStyles({ isDesktop });

  return (
    <Box
      className={styles.actionsContainer}
      style={{
        top: anchorPosition.y,
      }}
    >
      <Box className={styles.flex}>
        <IconButton
          aria-controls="ActivityIcon"
          aria-haspopup="true"
          className={styles.button}
          onClick={() => setAddActivity(true)}
        >
          <ActivityIcon
            width="20"
            height="20"
            fill={palette.button.tertiaryGray_fg}
          />
        </IconButton>

        <IconButton
          aria-controls="ListIcon"
          aria-haspopup="true"
          className={styles.button}
          onClick={() => setIsAddTaskDialogOpen(true)}
        >
          <ListIcon
            width="20"
            height="20"
            fill={palette.button.tertiaryGray_fg}
          />
        </IconButton>

        <IconButton
          aria-controls="EyeIcon"
          aria-haspopup="true"
          className={styles.button}
          onClick={() => {
            history.push(
              `/home/Contacts-CRM/contact-profile-overview?formType=${
                tablePopoverContent?.contact_type_id
              }&id=${tablePopoverContent?.contactId || tablePopoverContent?.id}`
            );
          }}
        >
          <EyeIcon
            width="20"
            height="20"
            fill={palette.button.tertiaryGray_fg}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default TableActions;
