import React, { useCallback, useEffect, useState } from 'react';
import { ButtonBase } from '@material-ui/core';
import {
  Spinner,
  Tables,
  SwitchComponent
} from '../../../../Components';
import { GetAllConvoloRotationSchemeAPI , GetConvoloRotationSchemeByIdAPI  , UpdateConvoloRotationSchemeAPI } from '../../../../Services';
import { useTranslation } from 'react-i18next';
import {  TableActions } from '../../../../Enums';
import { showSuccess , showError  , GlobalHistory } from '../../../../Helper' ; 
import { ConvoloRotationSchemeDialog } from './ConvoloRotationSchemeDialog' ;
import { DeleteConvoloAgent } from './DeleteConvoloAgent' ; 
import "./ConvoloAgentsViewStyle.scss";

 export const ConvoloAgents = ({
}) => {
  const parentTranslationPath = 'Agents';
  const translationPath = '';
  const { t } = useTranslation(parentTranslationPath);

  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
  });
  const [convoloAgents, setConvoloAgents] = useState({result : [] , totalCount :0 });
  const [activeItem, setActiveItem] = useState(null);
  const [isOpenConvoloRotationSchemeDialog, setIsOpenConvoloRotationSchemeDialog] = useState(false);
  const [isOpenDeleteConvoloRotationSchemeDialog, setIsOpenDeleteConvoloRotationSchemeDialog] = useState(false);

  const ConvoloAgentsTableHeaderData = [
    {
      id: 1,
      label: 'convoloRotationSchemeName',
      input: 'convoloRotationSchemeName',
    },
    {
      id: 2,
      label: 'propertyCampaignName',
      input: 'propertyCampaignName',
    },
    {
      id: 5,
      label: 'status',
      input: 'isActive',
      component: (item, index) => (
        <SwitchComponent
          idRef={`isConvoloActive${index + 1}`}
          isChecked={item.isActive || false}
          labelClasses='px-0'
          onChangeHandler={() => 
          {
            GetConvoloRotationSchemeById(item)
          }}
          themeClass='theme-line'
          labelValue={(item.isActive && `${t('Active')}`) || `${t('Deactive')}`}
        />
      )
    },
  ];

  const GetAllConvoloRotationScheme =  useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllConvoloRotationSchemeAPI(filter.pageIndex +1 , filter.pageSize);
    if (!(res && res.status && res.status !== 200)) {
      setConvoloAgents({result : res.result  , totalCount : res.totalCount });
    } else {
      setConvoloAgents({ result: [], totalCount: 0 });
    }
    setIsLoading(false);
  }, [filter]);

  const getActionTableWithPermissions  = () => {
    // eslint-disable-next-line prefer-const
    let list = [];
      list.push({
        enum: TableActions.manageAgents.key,
        title: t(`${translationPath}Shared:manage-agents`),
      });
    
      list.push({
        enum: TableActions.editIconAndLabel.key,
        title: t(`${translationPath}Shared:edit`),
      });
      list.push({
        enum: TableActions.deleteIconAndLabel.key,
        title: t(`${translationPath}Shared:delete`),
      });
    return list;
  };
  
  const tableActionClicked = useCallback((actionEnum, item) => {
    setActiveItem(item);
    if (actionEnum === TableActions.manageAgents.key)
      GlobalHistory.push(`/home/convolo-agents/manage?id=${item.convoloRotationSchemeId}`);

    if (actionEnum === TableActions.editIconAndLabel.key) 
      setIsOpenConvoloRotationSchemeDialog(true);
     
    else if (actionEnum === TableActions.deleteIconAndLabel.key) {
      setIsOpenDeleteConvoloRotationSchemeDialog(true);

    }
  }, []);

  const GetConvoloRotationSchemeById = async (item) => {
    setIsLoading(true);
    const convoloRotationSchemeRes = await GetConvoloRotationSchemeByIdAPI(item?.convoloRotationSchemeId);
    if (!(convoloRotationSchemeRes && convoloRotationSchemeRes.status && convoloRotationSchemeRes.status !== 200)) 
    {
      const updateStatusObj = { ...convoloRotationSchemeRes , isActive : !(convoloRotationSchemeRes.isActive) }; 
      const result =   await UpdateConvoloRotationSchemeAPI(updateStatusObj && updateStatusObj?.convoloRotationSchemeId ,updateStatusObj)  ;
    if (!(result && result.status && result.status !== 200)) {
      {
        showSuccess(t('convolo-Rotation-scheme-update-sucessfully'));
        setFilter((item) => ({ ...item, pageIndex: 0 }));
      }
    } else {
      showError(t('convolo-rotation-scheme-create-failed'));
    }
    } 
    else 
    {
      showError(t('convolo-rotation-scheme-create-failed'));

    } 
    setIsLoading(false);
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  }; 
  

  useEffect(() => {
    GetAllConvoloRotationScheme();
  }, [filter]);

return (
  <div className='convolo-agents-wrapper  m-3'>
   <Spinner isActive={(isLoading) } isAbsolute />
        <div className='d-flex-h-between px-2 mb-3'>
            <ButtonBase
                className='MuiButtonBase-root btns theme-solid mb-2'
                  onClick={()=>setIsOpenConvoloRotationSchemeDialog(true)}
                  >
                 {t(`${translationPath}add-convolo-rotation-scheme`)}
                <span className='mdi mdi-plus' />
               </ButtonBase>
        </div>
        <div className='w-100 px-2'>
         <Tables
             data={convoloAgents.result}
             headerData={ConvoloAgentsTableHeaderData || []}
             translationPath={translationPath}
             parentTranslationPath={parentTranslationPath}
             defaultActions={getActionTableWithPermissions()}
             actionsOptions={{
             onActionClicked: tableActionClicked,
           }}
           itemsPerPage={filter.pageSize}
           activePage={filter.pageIndex}
           totalItems={(convoloAgents && convoloAgents.totalCount) || 0}
           onPageIndexChanged ={onPageIndexChanged}
           onPageSizeChanged={onPageSizeChanged}
           />
        </div>
      { isOpenConvoloRotationSchemeDialog && (
        <ConvoloRotationSchemeDialog
          isOpen={isOpenConvoloRotationSchemeDialog}
          onClose={()=> {
            setIsOpenConvoloRotationSchemeDialog(false) ; 
            setActiveItem(null) ; 
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onSave={()=> {
            setIsOpenConvoloRotationSchemeDialog(false) ; 
            GetAllConvoloRotationScheme();
            setActiveItem(null) ; 
          }}
          convoloRotationSchemeItem={activeItem}
          
        />
      )}
       {isOpenDeleteConvoloRotationSchemeDialog && (
        <DeleteConvoloAgent
          isOpen={isOpenDeleteConvoloRotationSchemeDialog}
          onClose={()=> {
            setIsOpenDeleteConvoloRotationSchemeDialog(false) ; 
            setActiveItem(null) ; 
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onSave={()=> {
            setIsOpenDeleteConvoloRotationSchemeDialog(false) ; 
            GetAllConvoloRotationScheme();
            setActiveItem(null) ; 
          }}
          activeItem={activeItem}
          
        />
      )}

    </div>
);
}
