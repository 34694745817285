export function isURL(str) {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return pattern.test(str);
}

export function matchYoutubeUrl(url) {
  const LINKYoutube = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  if (url.match(LINKYoutube))
    return url.match(LINKYoutube)[1];
  return false;
}

export function getIsValidURL(string) {
  const addressURLRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  const isValidAddressURL = string?.match(addressURLRegex);
  return (isValidAddressURL !== null);
}

export function getIsValidDataURL(string) {
  const dataURLRegex = /data:([-\w]+\/[-+\w.]+)?(;?\w+=[-\w]+)*(;base64)?,.*/gu;
  const isValidDataURL = string.match(dataURLRegex);
  return (isValidDataURL !== null);
}
export function matchFacebookUrl(url) {
  const linkFacebook = /^(https?:\/\/){0,1}(www\.){0,1}facebook\.com/ ;
  if (url.match(linkFacebook))
    return url.match(linkFacebook)[1];
  return false;
 
}

export function matchInstagramUrl(url) {
  const linkInstagram = /^(https?:\/\/){0,1}(www\.){0,1}instagram\.com/ ;
  if (url.match(linkInstagram))
    return url.match(linkInstagram)[1];
  return false;
}

export function matchLinkedinUrl(url) {
  const linkLinkedin = /^(https?:\/\/){0,1}(www\.){0,1}linkedin\.com/ ;
  if (url.match(linkLinkedin))
    return url.match(linkLinkedin)[1];
  return false;
}

export function matchSnapchatUrl(url) {
  const linkSnapchat = /^(https?:\/\/){0,1}(www\.){0,1}snapchat\.com/ ;
  if (url.match(linkSnapchat))
    return url.match(linkSnapchat)[1];
  return false;
}

export function matchTiktokLink(url) {
  const linkTiktok = /^(https?:\/\/){0,1}(www\.){0,1}tiktok\.com/ ;
  if (url.match(linkTiktok))
    return url.match(linkTiktok)[1];
  return false;
}

export function matchXLink(url) {
  const Xlink = /^(https?:\/\/){0,1}(www\.){0,1}x.com/ ;
  if (url.match(Xlink))
    return url.match(Xlink)[1];
  return false;
}






