import React from "react";

import useStyles from "./styles";

const MobileTabsWindow = ({ children }) => {
  const styles = useStyles();

  return (
    <div className={styles.popup}>
      <div className={styles.popupContent}>{children}</div>
    </div>
  );
};

export default MobileTabsWindow;
