
import React, { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useTitle } from '../../../Hooks';
import { GlobalOrderFilterActions } from '../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import { GlobalHistory, bottomBoxComponentUpdate, returnPropsByPermissions } from '../../../Helper';
import { ButtonBase } from '@material-ui/core';
import {
  AutocompleteComponent,
  Inputs,
  NoContentComponent,
  NoSearchResultComponent,
  PaginationComponent,
  SelectComponet,
  Spinner, ViewTypes, DialogComponent, PermissionsComponent
} from '../../../Components';
import { ViewTypesEnum } from '../../../Enums';
import { PolicyPermissions } from '../../../Permissions';
import { getAllPoliciesServices, GetTeam, GetAllBranches, GetPolicyByIdService } from '../../../Services';
import { PoliciesManagementDialog } from './PoliciesManagement/PoliciesManagementDialog'
import { PolicyTableView } from './PoliciesUtilities';

const parentTranslationPath = 'Policies';
const translationPath = '';

export const PoliciesView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const dispatch = useDispatch();
  useTitle(t(`${translationPath}Policies`));


  const [sortBy, setSortBy] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: (orderFilter && orderFilter.policyFilter && orderFilter.policyFilter.filterBy) || null,
    orderBy: (orderFilter && orderFilter.policyFilter && orderFilter.policyFilter.orderBy) || null,
  });

  const [filter, setFilter] = useState({
    branchId: null,
    teamId: null,
    search: "",
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
  });
  const [activeItem, setActiveItem] = useState(null);
  const [policy, setPolicy] = useState(null);
  const [isLoading, setIsLoading] = useState({
    policies: false,
    teams: false,
    branches: false,
    policyItemLoading: false,
  });

  const [filterSectionLists, setFilterSectionLists] = useState({
    allTeams: [],
    allBranches: [],
  });

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
  };
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);

  const [allPolicies, setAllPolicies] = useState({
    result: [],
    totalCount: 0,
  });


  const [timer, setTimer] = useState(null);
  const [searchedItem, setSearchedItem] = useState('');
  const searchTimer = useRef(null);
  const [isOpenPolicyDialog, setIsOpenPolicyDialog] = useState(false);

  const [viewTypes, setViewTypes] = useState(ViewTypesEnum.tableView.key);

  const searchHandler = (search) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({ ...item, pageIndex: 0, search }));
    }, 700);
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const reloadData = () => {
    setFilter((item) => ({ ...item, pageIndex: 0 }));
  };



  const getAllPolicies = useCallback(async () => {
    setIsLoading((item) => ({ ...item, policies: true }));
    if (returnPropsByPermissions(PolicyPermissions.ShowModule.permissionsId)) {
      const res = await getAllPoliciesServices({ ...filter, pageIndex: (filter.pageIndex + 1) });
      if (res) {
        setAllPolicies({
          result: (res && res.result) || [],
          totalCount: (res && res.totalCount) || 0,
        });
      } else {
        setAllPolicies({
          result: [],
          totalCount: 0,
        });
      }
    }
    setIsLoading((item) => ({ ...item, policies: false }));
  }, [filter, sortBy]);


  const GetTeams = async (searchItem) => {
    setIsLoading((item) => ({ ...item, teams: true }));

    const res = await GetTeam(filter.pageIndex + 1, filter.pageSize, (searchItem || filter.search));
    if (!(res && res.status && res.status !== 200))
      setFilterSectionLists((item) => ({ ...item, allTeams: (res.result) }));
    else
      setFilterSectionLists((item) => ({ ...item, allTeams: [] }));

    setIsLoading((item) => ({ ...item, teams: false }));
  };


  const getAllBranches = async (branchName) => {
    setIsLoading((item) => ({ ...item, branches: true }));
    const res = await GetAllBranches({ pageSize: 25, pageIndex: 1, branchName: branchName });
    if (!(res && res.status && res.status !== 200))
      setFilterSectionLists((item) => ({ ...item, allBranches: res.result }));
    else
      setFilterSectionLists((item) => ({ ...item, allBranches: [] }));

    setIsLoading((item) => ({ ...item, branches: false }));
  };

  const getPolicyById = useCallback(async () => {
    setIsLoading((item) => ({ ...item, GetPolicyByIdService: true }));
    const res = await GetPolicyByIdService(activeItem && activeItem.policyDataId);
    if (!(res && res.status && res.status !== 200))
      setPolicy(res);
    else setPolicy(null);
    setIsLoading((item) => ({ ...item, policyItemLoading: false }));

  }, [activeItem]);

  useEffect(() => {
    GetTeams();
    getAllBranches();
  }, []);

  useEffect(() => {
    getAllPolicies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (returnPropsByPermissions(PolicyPermissions.ShowModule.permissionsId)) {
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={(allPolicies && allPolicies.totalCount)}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          translationPath=''
          parentTranslationPath='Shared'
        />
      );
    }

  }, [filter]);

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  useEffect(() => {
    if (activeItem)
      getPolicyById();
    else setPolicy(null);
  }, [activeItem]);

  useEffect(() => {
    if (sortBy) {

      setFilter((item) => ({
        ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy, pageIndex: 0
      }));
    }
  }, [sortBy]);
  return (
    <>
      <div className='Policies'>
        <Spinner isActive={isLoading.policies} isAbsolute />
        <div className='view-wrapper d-flex-column'>
          <div className='header-section'>
            <div className='filter-section'>
              <div className='filter-ButtonBase'>
                <div className='section px-2'>
                  <PermissionsComponent
                    permissionsList={Object.values(PolicyPermissions)}
                    permissionsId={PolicyPermissions.AddPolicy.permissionsId}>
                    <ButtonBase
                      className='btns theme-solid'
                      idRef={`AddNewPolicy`}
                      onClick={() => {
                        setIsOpenPolicyDialog(true);
                      }}
                    >
                      <span>{t(`${translationPath}add-new-policy`)}</span>
                    </ButtonBase>
                  </PermissionsComponent>
                </div>
              </div>
              <PermissionsComponent
                permissionsList={Object.values(PolicyPermissions)}
                permissionsId={PolicyPermissions.ShowModule.permissionsId}>

                <div className='section autocomplete-section'>
                  <div className='Rep d-flex-column px-2 w-100 p-relative '>
                    <div className='w-100 p-relative'>
                      <div className='d-flex-column w-100'>
                        <Inputs
                          label={t(`${translationPath}searchPolicy`)}
                          idRef={`searchPolicy`}
                          value={searchedItem}
                          onInputChanged={(e) => setSearchedItem(e.target.value)}
                          inputPlaceholder={t(`${translationPath}searchPolicy`)}
                          onKeyUp={() => {
                            setTimer(
                              setTimeout(() => {
                                setFilter((f) => ({ ...f, search: searchedItem }));
                              }, 1000)
                            );
                          }}
                          onKeyDown={() => {
                            if (timer != null) clearTimeout(timer);
                          }}
                          beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                        />
                      </div>
                      <div className='sc-search d-flex px-2'>
                        <span className=' mt-1' />
                      </div>
                    </div>
                    <div className='w-100 p-relative policiesSearch pl-5-reverse'>
                      <div className='section'>
                        <AutocompleteComponent
                          idRef='BranchPoliciesRef'
                          data={filterSectionLists.allBranches || []}
                          multiple={false}
                          displayLabel={(option) =>
                            (option && option.branchName) || ''}
                          chipsLabel={(option) => (option && option.branchName) || ''}
                          withoutSearchButton
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          onInputKeyUp={(event) => {
                            const { value } = event.target;
                            if (searchTimer.current) clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              getAllBranches(value)
                            }, 700);
                          }}
                          onChange={(e, newValue) => {
                            setFilter((item) => ({ ...item, branchId: (newValue && newValue.branchId) || null }));
                          }}
                          inputPlaceholder={t(`${translationPath}branch`)}
                          isLoading={isLoading.branches}
                          withLoader
                        />
                      </div>
                      <div className='section'>
                        <AutocompleteComponent
                          idRef='TeamsPoliciesRef'
                          data={filterSectionLists.allTeams || []}
                          multiple={false}
                          displayLabel={(option) =>
                            (option && option.teamsName) || ''}
                          chipsLabel={(option) => (option && option.teamsName) || ''}
                          withoutSearchButton
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          onInputKeyUp={(e) => {
                            const { value } = e.target;
                            if (searchTimer.current) clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              GetTeams(value);
                            }, 700);
                          }}
                          onChange={(e, newValue) => {
                            setFilter((item) => ({ ...item, teamId: (newValue && newValue.teamsId) }));
                          }}
                          inputPlaceholder={t(`${translationPath}team`)}
                          isLoading={isLoading.teams}
                          withLoader
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </PermissionsComponent>
            </div>
          </div>
          {' '}
        </div>
      </div>
      <>
        <PermissionsComponent
          permissionsList={Object.values(PolicyPermissions)}
          permissionsId={PolicyPermissions.ShowModule.permissionsId}
        >
          {((allPolicies && allPolicies.result && allPolicies.result.length === 0) || (allPolicies && allPolicies.totalCount === 0)) && (filter.search !== '') ? (
            <NoSearchResultComponent />
          ) : (

            (allPolicies && allPolicies.totalCount === 0 && (
              <NoContentComponent />
            )) || (

              (viewTypes === ViewTypesEnum.tableView.key && (
                <>
                  <div className='view-wrapers'>
                    <PolicyTableView
                      translationPath={translationPath}
                      data={allPolicies}
                      filter={filter}
                      parentTranslationPath={parentTranslationPath}
                      setActiveItem={setActiveItem}
                      setFilter={() => setFilter((item) => ({ ...item, pageIndex: 0 }))}
                      activeItem={activeItem}
                      isLoading={isLoading}
                      editPolicy={policy}
                      onPageSizeChanged={onPageSizeChanged}
                      onPageIndexChanged={onPageIndexChanged}
                      setSortBy={setSortBy}
                    />
                  </div>
                </>
              )) || (
                < >
                </>
              )))}
        </PermissionsComponent>
      </>
      {isOpenPolicyDialog && (
        <PoliciesManagementDialog
          isOpen={isOpenPolicyDialog}
          onClose={() => {
            setIsOpenPolicyDialog(false);
          }}
          onSave={() => {
            setIsOpenPolicyDialog(false);
            setFilter((item) => ({ ...item, pageIndex: 0 }));
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isLoadingPolicy={isLoading.policyItemLoading}
        />
      )}
    </>
  );
};
