import React from "react";

// Styles
import useStyles from "./styles";
import { useTranslate } from "../../../Hooks";

function Footer() {
  const styles = useStyles();
  const currentYear = new Date().getFullYear();
  
  const { translate } = useTranslate("Shared");

  return (
    <footer className={styles.footer}>
      © {currentYear} {translate("footerText")}
    </footer>
  );
}

export default Footer;
