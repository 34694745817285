import React, { useState, useEffect, useCallback, useContext } from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import { unitTableHeaderData } from "./unitTableHeaderData";
import {
  UnitCardSkeleton,
  VIEW_MODES,
  CustomSelect,
  ViewModeSwitcher,
  UnitCard,
  BasicTable,
  TableSkeleton,
  CustomPagination,
  NoDataFound,
} from "../../../../../../Components";
import { UnitMapper } from "../../../../../../Views/Home/UnitsSalesView/UnitMapper/UnitMapper";
import { UnitsAdvanceSearchTest } from "../../../../../../Services";

// Styles
import useStyles from "./styles";

//
function ContactUnits({ contactId }) {
  const styles = useStyles();
  const [viewMode, setViewMode] = useState(VIEW_MODES.GRID);
  const [isLoading, setIsLoading] = useState(true);
  const [selectType, setSelectType] = useState("Sale");
  const [unitsData, setUnitsData] = useState({
    result: [],
    totalCount: 0,
  });
  const { isDarkMode } = useSelector((state) => state.theme);
  const [activeItem, setActiveItem] = useState({});
  const [pagination, setPagination] = useState({
    pageSize: 25,
    pageIndex: 1,
  });
  const [inputValue, setInputValue] = useState(pagination.pageIndex);

  const getUnitsData = async () => {
    setIsLoading(true);

    try {
      const localFilterDto = {
        contactOwnerId: [{ searchType: 1, value: contactId }],
      };

      const body = {
        criteria: localFilterDto,
        operationType: selectType,
        filterBy: "createdOn",
        orderBy: 2,
      };

      const res = await UnitsAdvanceSearchTest(
        {
          pageIndex: pagination.pageIndex - 1,
          pageSize: pagination.pageSize,
        },
        body
      );

      if (!(res && res.status && res.status !== 200)) {
        const result = ((res && res.result) || [])?.map(
          (item) =>
            item?.unitJson && UnitMapper(item, JSON.parse(item?.unitJson)?.unit)
        );
        setUnitsData({
          result: result,
          totalCount: (res && res.totalCount) || 0,
        });

        setIsLoading(false);
      }
    } catch (err) {
      setUnitsData({
        result: [],
        totalCount: 0,
      });
      setIsLoading(false);
      console.error("Failed to fetch contact:", err);
    }
  };

  const onChangeViewMode = (value) => {
    setViewMode(value); // Update view mode
  };

  const handleSelectTypeChange = async (newValue) => {
    setSelectType(newValue);
  };

  useEffect(() => {
    if (contactId) {
      getUnitsData();
    }
  }, [contactId, selectType, pagination]);

  return (
    <Box>
      <Box className={styles.flex}>
        <CustomSelect
          options={[
            { id: "Sale", name: "Sale" },
            { id: "Lease", name: "Lease" },
          ]}
          onValueChange={handleSelectTypeChange}
          variant="default"
          disabled={isLoading}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          defaultValue={"Sale"}
          customSelectClasses={styles.customSelect}
        />

        <ViewModeSwitcher
          viewMode={viewMode}
          onChangeViewMode={onChangeViewMode}
          isLoading={isLoading}
        />
      </Box>

      {viewMode === VIEW_MODES.GRID && (
        <Box className={styles.gridContainer}>
          {isLoading ? (
            Array.from({ length: 8 }).map((_, index) => (
              <UnitCardSkeleton index={index} />
            ))
          ) : unitsData?.result?.length > 0 ? (
            unitsData?.result?.map((item) => (
              <UnitCard item={item} selectType={selectType} />
            ))
          ) : (
            <NoDataFound title="units" />
          )}
        </Box>
      )}

      {viewMode === VIEW_MODES.TABLE && (
        <Box>
          {isLoading ? (
            <TableSkeleton rowsNum={12} />
          ) : unitsData?.result?.length > 0 ? (
            <BasicTable
              pageSize={25}
              rowsData={unitsData?.result || []}
              setActiveItem={setActiveItem}
              rowHeight={130}
              columns={unitTableHeaderData(isDarkMode, selectType)}
              viewMode={viewMode}
            />
          ) : (
            <NoDataFound title="units" />
          )}
        </Box>
      )}

      <CustomPagination
        hideInMobile
        totalItems={unitsData.totalCount}
        itemsPerPage={pagination.pageSize}
        currentPage={pagination.pageIndex}
        inputValue={inputValue}
        setInputValue={setInputValue}
        onPageChange={(page) =>
          setPagination((prev) => ({ ...prev, pageIndex: page }))
        }
        onItemsPerPageChange={(items) =>
          setPagination((prev) => ({ ...prev, pageSize: items }))
        }
        isLoading={isLoading}
      />
    </Box>
  );
}

export default ContactUnits;
