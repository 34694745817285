import React, { useEffect, useState, useRef } from 'react';
import SunEditor from 'suneditor-react';

export const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);
  const editor = useRef();
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  useEffect(() => {
    setIsActive(false);
  }, [title, content]);

  return (
    <div className='accordion-item'>
      <div className='accordion-title-container' onClick={() => setIsActive(!isActive)}>
        <div className='accordion-title'>{title}</div>
        <div>{isActive ? <span className='mdi mdi-minus-circle-outline minus-btn' /> : <span className='mdi mdi-plus-circle-outline plus-btn' />}</div>
      </div>
      {isActive && (
        <div className='accordion-content release-note-sunEditor accordion-sunEditor'>
          <div
            idRef='accion-sunEditorRef'
            dangerouslySetInnerHTML={{ __html: content || '' }}
          />

        </div>
      )}
    </div>
  );
};
