import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogTitle, DialogContent, Button
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import GenricStpeper from '../../../../Components/OLD/dfmAddEditAndDelete/typePicker/DfmAddEditAndDeleteStepper';
import { PROPERTIES, UNITS } from '../../../../config/pagesName';
import { SelectComponet } from '../../../../Components';

const translationPath = 'dialogs.contactsDialog.';
export const ContactsDialog = ({
  open,
  onSave,
  closeDialog,

  isViewContact,
  leadOperation,
  seekerLead,
  isSeekerLead,
  isOpenInsideForm,
  isWithUnitRef,
  unitRefSaveHandler,
  item , 
}) => {

  const { t } = useTranslation('FormBuilder');
  const [activeFormType, setActiveFormType] = useState(null);
  const [id, setId] = useState(null);
  const [pageName, setPageName] = useState(null);
  const [companyList , setCompanyList] = useState([
   {key :"project_manager_company" } , 
   {key : "project_manager-company"} , 
   {key : "consultant_company"} , 
   {key : "architect_company"}

  ]) ; 

  useEffect(() => {
    if (open) {
      const obj = JSON.parse(localStorage.getItem('current'));
      if((item && item.data && item.data.dependOn === 'company') || (item && item.field && item.field.id  && companyList.some((f)=> f.key === item.field.id)))
      setActiveFormType(2);
    else 
    setActiveFormType((obj?.type )||  1);

      setId(obj?.id);
      setPageName(obj?.itemId.toLowerCase());
    }
  }, [open]);

  const onFormTypeSelectChanged = (activeValue) => {
    setActiveFormType(null);
    setTimeout(() => {
      setActiveFormType(activeValue);
    });
  };

  const CompanyListDefaultValue = (() => {
    const isInCompanyList = companyList.some(f => f.key === item?.field?.id);
    const isDependentOnCompany = item?.data?.dependOn === 'company';
    
    return isInCompanyList || isDependentOnCompany ? 2 : 1;
  })();

  return (
    <Dialog className='dialog' open={open} maxWidth='lg'>
      <form
        noValidate
        style={{ position: 'relative' }}
        onSubmit={(event) => {
          event.preventDefault();
          onSave();
          closeDialog();
        }}
      >
        <DialogTitle>
          <>
            <Button
              className='btns-icon theme-solid dialog-btn-close'
              onClick={() => {
                setActiveFormType(null);
                closeDialog();
              }}
            >
              <span className='mdi mdi-close' />
            </Button>
            {!isViewContact ? t(`${translationPath}add-new-${pageName}`) : t(`${translationPath}view-${pageName}`)}

          </>
        </DialogTitle>
        <DialogContent>
          {!id && pageName !== PROPERTIES && pageName !== UNITS && (
            <SelectComponet
              data={[
                { id: '1', name: `${pageName}-option1` },
                { id: '2', name: `${pageName}-option2` },
              ]}
              defaultValue={CompanyListDefaultValue}
              emptyItem={{ value: -1, text: `select-${pageName}-type`, isHiddenOnOpen: true }}
              valueInput='id'
              parentTranslationPath='FormBuilder'
              translationPath={translationPath}
              onSelectChanged={onFormTypeSelectChanged}
              wrapperClasses='bg-secondary c-white mx-2'
              themeClass='theme-action-buttons'
              idRef='contactsActionsRef'
              keyValue='actionsbuttons'
              keyLoopBy='id'
              translationPathForData={translationPath}
              textInput='name'
            />
          )}
          {activeFormType && pageName && (
            <div className='view-wrapper pt-3'>
              <div className='d-flex-column'>
                <GenricStpeper
                  pageName={pageName}
                  id={(id && +id) || undefined}
                  type={activeFormType.toString()}
                  closeDialog={closeDialog}
                  withTotal
                  isDialog
                  setActiveFormType={setActiveFormType}
                  leadOperation={leadOperation}
                  seekerLead={isSeekerLead}
                  isContactsDialog
                  isWithUnitRef={isWithUnitRef}
                  unitRefSaveHandler={unitRefSaveHandler}
                  isOpenInsideForm={isOpenInsideForm}
                />
              </div>
            </div>
          )}
        </DialogContent>

        {/* <DialogActions>
          <Button
            className="btns theme-solid bg-cancel"
            onClick={() => {
              closeDialog();
            }}
          >
            {t('shared.cancel')}
          </Button>
          <Button className="btns theme-solid" type="submit">
            {t('shared.save')}
          </Button>
        </DialogActions> */}
      </form>
    </Dialog>
  );
};

ContactsDialog.propTypes = {
  open: PropTypes.bool,
  onSave: PropTypes.func,
  closeDialog: PropTypes.func,
  isViewContact: PropTypes.bool,
  leadOperation: PropTypes.string,
  seekerLead: PropTypes.array,
  isSeekerLead: PropTypes.bool,
  isSeekerLead: PropTypes.bool,
  isOpenInsideForm: PropTypes.bool,

};
ContactsDialog.defaultProps = {
  open: false,
  onSave: () => { },
  closeDialog: () => { },
  isViewContact: false,
  leadOperation: undefined,
  seekerLead: undefined,
  isSeekerLead: false,
  isSeekerLead: false,
  isOpenInsideForm: false,

};
