import { MarketingCampaignsStatus } from './Status';

const marketingCampaignsRequest = (payload) => {
  return ({ type: MarketingCampaignsStatus.REQUEST, payload })
};
const marketingCampaignsSuccess = (payload) => ({ type: MarketingCampaignsStatus.SUCCESS, payload });
const marketingCampaignsError = (payload) => ({ type: MarketingCampaignsStatus.ERROR, payload });
const marketingCampaignsReset = (payload) => ({ type: MarketingCampaignsStatus.RESET, payload });

export const MarketingCampaignsActions = {
  marketingCampaignsRequest,
  marketingCampaignsSuccess,
  marketingCampaignsError,
  marketingCampaignsReset,
};
