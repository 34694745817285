import React from "react";

const LabeledField = ({ component, label }) => {
  return (
    <>
      <hr className='hr' />
      <div className='labeled-field'>
        <label className='labeled-field__label'>{label}</label>
        <div className='labeled-field__component'>{component}</div>
      </div>
    </>
  );
};

export default LabeledField;
