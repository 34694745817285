export const RecipientTypeCoreEnum = {
  SuperAdmin: {
    key: 1,
    value: 'SuperAdmin',
  },
  AssignTo: {
    key: 7,
    value: 'AssignTo',
  },
  LineManager: {
    key: 8,
    value: 'LineManager',
  },
  CreatedBy: {
    key: 9,
    value: 'CreatedBy',
  },
  Client: {
    key: 10,
    value: 'Client',
  },
  TeamLead: {
    key: 11,
    value: 'TeamLead',
  },
  LeadAuditor: {
    key: 12,
    value: 'LeadAuditor',
  },
};
