/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogComponent, Inputs, Spinner, TabsComponent, UploaderComponent
} from '../../../../../Components';
import 'react-quill/dist/quill.snow.css';
import {
  GetParams, showError, showSuccess ,showWarn
} from '../../../../../Helper';
import { ImagesGalleryFilterEnum, UploaderThemesEnum } from '../../../../../Enums';
import { DetailsComponentTabsData } from '../DetailsComponentTabsData';
import { TabelSubCommunitiesRelated } from '../../LocationSharingUtilities';
import { TabelCommunitiesRelated } from '../../LocationSharingUtilities/TabelCommunitiesRelated';
import { ImagesGalleryPhotosCardComponent } from '../../LocationSharingUtilities/ImagesGalleryPhotosCardComponent/ImagesGalleryPhotosCardComponent';
import { CreateOrUpdateLocationLookupAlbum, DeleteAlbumImage, GetAllImagesByTypeId } from '../../../../../Services';
import { DescriptionComponents } from './DescriptionComponents';

export const EditFormDistrict = ({
  parentTranslationPath,
  translationPath,
  obejectDTO,
  details
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [filter] = useState({
    pageSize: 1000,
    pageIndex: 0,
    search: '',
  });
  const [state, setState] = useState({
    lookupItemId: 0,
    LocationName: '',
    files: '',
    districtAvgPriceSqFt: '',
    districtAvgPriceSqFtFor1Bedroom: '',
    districtAvgPriceSqFtFor2Bedroom: '',
    districtAvgPriceSqFtForStudio: '',
    districtAvgPriceSqFtForVilla: '' ,
    logImage : '' , 


  });
  const [DescriptionComponentstate, setDescriptionComponentstate] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [isOpen, setisOpen] = useState(false);
  const [item, setitem] = useState(null);
  const [cityPhotos, setCityPhotos] = useState({
    result: [],
    totalCount: 0,
  });
  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getAllCityPhotos = useCallback(async () => {
    const res = await GetAllImagesByTypeId({
      ...filter,
      lookupItemId: +GetParams('id'),
      typeId: ImagesGalleryFilterEnum.District.key
    });
    if (!(res && res.status && res.status !== 200)) {
      setCityPhotos({
        result: res.result,
        totalCount: res.totalCount,
      });
    } else
      showError(t(`${translationPath}permission`));
  });
  const deleteHandler = async (ImageId) => {
    setIsLoading(true);
    const res = ImageId && ImageId.albumImagesId ? await DeleteAlbumImage(ImageId?.albumImagesId) : null;
    if (!(res && res.status && res.status !== 200)) {
      getAllCityPhotos();
      const newList = state.logImage && state.logImage !=='' && Array.isArray(state.logImage) ? state.logImage.filter((f)=> f.fileId !== (res && res.fileId)) : [];
      setState((items) => ({
        ...items , 
        logImage: ([...newList]) || []
      }));
      setIsLoading(false);
      showSuccess(t(`${translationPath}image-delete-successfully`));
    } else showError(t(`${translationPath}image-delete-failed`));
    setisOpen(false);
    setIsLoading(false);
  };

  const saveHandler = async () => {
    setIsLoading(true);
    const statelogImage = state.logImage.length > 0  ? state.logImage[state.logImage.length - 1] : [] ; 
    const list =  (statelogImage && [{...statelogImage}]) || [] ;

    const toSaveState = {
      imageGalleryType: ImagesGalleryFilterEnum.District.lookupType,
      lookupItemId: +GetParams('id'),
      albumImages: [...state.files , ...list],
    };
    const res =
      await CreateOrUpdateLocationLookupAlbum(toSaveState);
    setIsLoading(false);
    if (!(res && res.data && res.data.ErrorId)) {
      if (res) {
        showSuccess(t(`${translationPath}image-uplode-successfully`));
        getAllCityPhotos();
      } else showError(t(`${translationPath}image-uplode-failed`));
    }
  };
  useEffect(() => {
    getAllCityPhotos();
  }, []);
  useEffect(() => {
    if (state.files !== '')
      saveHandler();
  }, [state.files]);

  useEffect(() => {
    if (state.logImage !== '')
      saveHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.logImage]);


  useEffect(() => {
    if (details) {
      setState({
        ...state,
        LocationName: GetParams('lookupItemName'),
        lookupItemId: +GetParams('id') || (details && details.lookupItemId),
        districtAvgPriceSqFt: details && details.districtAvgPriceSqFt,
        districtAvgPriceSqFtFor1Bedroom: details && details.districtAvgPriceSqFtFor1Bedroom,
        districtAvgPriceSqFtFor2Bedroom: details && details.districtAvgPriceSqFtFor2Bedroom,
        districtAvgPriceSqFtForStudio: details && details.districtAvgPriceSqFtForStudio,
        districtAvgPriceSqFtForVilla: details && details.districtAvgPriceSqFtForVilla,
      });
    } else
      setState({ ...state, LocationName: GetParams('lookupItemName'), lookupItemId: +GetParams('id') });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  useEffect(() => {
    obejectDTO((prevState) => ({ ...prevState, ...state, ...DescriptionComponentstate }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, DescriptionComponentstate]);

  return (
    <div className='view-wrapper-editFormCountry'>
      <div className='d-flex-column'>
      <div className='UploaderComponent-wrapered pt-4'>
        <div className='mt-2 mb-2'>
          <span className="fw-bold"> {`${t(`${translationPath}district-logo`)}`} </span>
          </div>
          <UploaderComponent
            idRef='profileLogoRef'
            uploaderTheme={UploaderThemesEnum.box.key}
            defaultImage='Uploadmark'
            WithoutDefaultImg
            multiple={false}
            dropHereText='Drag-and-drop-here-or-browse-File'
            uploadedChanged={(files) => {
              if(state.logImage !=='' && state.logImage && Array.isArray(state.logImage) && (state.logImage.length > 0 ))
                {
                  showWarn(t(`${translationPath}please-remove-logo-image-before-add-new-logo-image`));
                  return ; 
  
                }
              else if(cityPhotos?.result && cityPhotos.result.some((s)=> s.isLogo))
                  { 
                    showWarn(t(`${translationPath}please-remove-logo-image-before-add-new-logo-image`));
                    return ; 
                  }
              setState((items) => ({
                ...items , 
                logImage: [{
                  isLogo : true , 
                  fileId: (files.length > 0 && files[0].uuid) || null,
                  fileName: (files.length > 0 && files[0].fileName) || null,
                }]
              }));
            }}
          />
            <ImagesGalleryPhotosCardComponent
                data={(cityPhotos && cityPhotos.result && cityPhotos.result.filter((f)=> f.isLogo)) || []}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                WithCheckbox
                Selectedvalue={(items) => console.log(items)}
                deletevalue={(items) => { setitem(items); setisOpen(true); }}
              />
        </div>

        <div className='UploaderComponent-wrapered pt-4'>
          <div className='mt-2 mb-2'>
          <span className="fw-bold mt-2 mb-2"> {`${t(`${translationPath}district-images`)}`} </span>
          </div>
          <UploaderComponent
            idRef='profileImgRef'
            uploaderTheme={UploaderThemesEnum.box.key}
            defaultImage='Uploadmark'
            WithoutDefaultImg
            dropHereText='Drag-and-drop-here-or-browse-File'
            multiple={false}
            initUploadedFiles={
              (state.templateFileId && [
                {
                  uuid: state.templateFileId,
                  fileName: state.templateFileName,
                },
              ]) ||
              []
            }
            uploadedChanged={(files) => {
              setState((items) => ({
                ...items,
                files: [{
                  fileId: (files.length > 0 && files[0].uuid) || null,
                  fileName: (files.length > 0 && files[0].fileName) || null,
                }]
              }));
            }}
          />
        </div>
        <div>
          <div className='pt-4'>
            <>
              <ImagesGalleryPhotosCardComponent
                data={cityPhotos.result || []}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                WithCheckbox
                Selectedvalue={(items) => console.log(items)}
                deletevalue={(items) => { setitem(items); setisOpen(true); }}
              />
              <DialogComponent
                titleText='confirm-message'
                saveText='confirm'
                saveType='button'
                maxWidth='sm'
                dialogContent={(
                  <div className='d-flex-column-center'>
                    <Spinner isActive={isLoading} isAbsolute />
                    <span className='mdi mdi-close-octagon c-danger mdi-48px' />
                    <span>{`${t(`${translationPath}ConfirmText`)}`}</span>
                  </div>
                )}
                saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
                isOpen={isOpen}
                onSaveClicked={() => deleteHandler(item)}
                onCloseClicked={() => { setisOpen(false); }}
                onCancelClicked={() => { setisOpen(false); }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            </>
          </div>
        </div>
      </div>
      <div className='w-100 pt-4'>

        <TabsComponent
          data={DetailsComponentTabsData}
          labelInput='tab'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          themeClasses='theme-curved'
          currentTab={activeTab}
          onTabChanged={onTabChanged}
        />
        {activeTab === 0 && (
          <div>
            <TabelCommunitiesRelated
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          </div>
        )}
        {activeTab === 1 && (
          <div>
            <TabelSubCommunitiesRelated
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          </div>
        )}
      </div>
      <div>
        <DescriptionComponents
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          details={details}
          setDescription={(e) => setDescriptionComponentstate(e)}
        />
      </div>
      <div className="tabs-content-wrapper">
        <div className="tab-item-wrapper d-flex flex-wrap mt-4">

          <div className="form-item w-50">
            <Inputs
              type='number'
              labelValue="avgPriceSqFt"
              value={state.districtAvgPriceSqFt}
              idRef="districtAvgPriceSqFtRef"
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputChanged={(event) => {
                const avgPriceSqFt = event?.target?.value;
                setState({ ...state, districtAvgPriceSqFt: avgPriceSqFt });
              }}
            />
          </div>


          <div className="form-item w-50">
            <Inputs
              wrapperClasses="px-2"
              type='number'
              labelValue="avgpriceSqFtfor1bedroom"
              value={state.districtAvgPriceSqFtFor1Bedroom}
              idRef="avgpriceSqFtfor1BedroomRef"
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputChanged={(event) => {
                const avgPriceSqFtfor1bedroom = event?.target?.value;
                setState({ ...state, districtAvgPriceSqFtFor1Bedroom: avgPriceSqFtfor1bedroom });
              }}
            />
          </div>


          <div className="form-item w-50">
            <Inputs
              wrapperClasses="px-2"
              type='number'
              labelValue="avgPriceSqFtfor2bedroom"
              value={state.districtAvgPriceSqFtFor2Bedroom}
              idRef="avgpriceSqFtfor2bedroomRef"
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputChanged={(event) => {
                const avgPriceSqFtfor2bedroom = event?.target?.value;
                setState({ ...state, districtAvgPriceSqFtFor2Bedroom: avgPriceSqFtfor2bedroom });
              }}
            />
          </div>


          <div className="form-item w-50">
            <Inputs
              wrapperClasses="px-2"
              type='number'
              labelValue="avgpriceSqFtforstudio"
              value={state.districtAvgPriceSqFtForStudio}
              idRef="avgPriceSqFtforStudioRef"
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputChanged={(event) => {
                const avgpPriceSqFtforstudio = event?.target?.value;
                setState({ ...state, districtAvgPriceSqFtForStudio: avgpPriceSqFtforstudio });
              }}
            />
          </div>


          <div className="form-item w-50">
            <Inputs
              wrapperClasses="px-2"
              type='number'
              labelValue="avgPriceSqFtforvilla"
              value={state.districtAvgPriceSqFtForVilla}
              idRef="avgprice/Sq.FtforVillaRef"
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputChanged={(event) => {
                const avgPriceSqFtforvilla = event?.target?.value;
                setState({ ...state, districtAvgPriceSqFtForVilla: avgPriceSqFtforvilla });
              }}
            />
          </div>


        </div>
      </div>
    </div>


  );
};
// AddFormCountry.propTypes = {
//   parentTranslationPath: PropTypes.string.isRequired,
//   translationPath: PropTypes.string.isRequired,
//   activeItem: PropTypes.instanceOf(Object).isRequired,
//   Data: PropTypes.instanceOf(Object).isRequired,
//   edit: PropTypes.bool.isRequired,
//   onCancelClicked: PropTypes.func.isRequired,
//   GetAllActivityTypesAPI: PropTypes.func.isRequired,
//   setReloading: PropTypes.func,
// };
