import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "./PaymentMethodsBanner.scss";
import { PaymentMethodsData } from "./PaymentMethodsData";

export const PaymentMethodsBanner = ({
  isDisabled,
  activeCard,
  onActiveCardChange,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const getCardStatusClass = (cardInfo) =>{

    let cardClass = 'primary';

    if(isDisabled) cardClass = 'disabled';
    else if(cardInfo.id == activeCard?.id)  cardClass = 'active';
    return cardClass;

  }

  return (
    <div className="payment-Banner gap-16 py-3">
      {Object.values(PaymentMethodsData).map((item) => (
        <div className={`Method-card ${getCardStatusClass(item)}`} 
        key={item.key} id={`Method-card-${item.id}`}
        onClick={() => onActiveCardChange(item)} >
          <img src={item.icon} />
          <span className="fw-simi-bold fz-16">{t(`${translationPath}${item.title}`)}</span>
        </div>
      ))}
    </div>
  );
};

PaymentMethodsBanner.propTypes = {
  isDisabled: PropTypes.bool,
  activeCard: PropTypes.object,
  onActiveCardChange: PropTypes.func,
  parentTranslationPath: PropTypes.string,
  translationPath: PropTypes.string,
};
