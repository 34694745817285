import { makeStyles } from '@material-ui/core/styles';
import { useIsAr } from '../../../Hooks';

const useStyles = makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    mainContainer: {
      display: 'flex',
      height: '100vh',
      overflow: 'hidden',
    },
    layoutContainer: {
      display: 'flex',
      flex: '1 1 auto',
      position: 'relative',
      background: theme.palette.background.secondary_subtle,
    },
    contentWrapper: (props) => {
      return {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden',
        flexGrow: 1,
        position: 'relative',
        ...(isAr
          ? {
              marginRight: props.isExpanded ? props.sideNavWidth.expanded : props.sideNavWidth.collapsed,
              transition: 'margin-right 0.3s ease-in-out',
            }
          : {
              marginLeft: props.isExpanded ? props.sideNavWidth.expanded : props.sideNavWidth.collapsed,
              transition: 'margin-left 0.3s ease-in-out',
            }),
        [theme.breakpoints.down('sm')]: {
          margin: 0,
        },
        zIndex: 1,
      };
    },
  };
});

export default useStyles;
