import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import defaultVideoImage from '../../assets/images/defaults/defaultVideoImage.png';
import { LoadableImageEnum } from '../../Enums';
import './LoadableVideoComponant.scss'
import image from '../../assets/images/defaults/loader.gif';
import { is } from 'core-js/core/object';
export const LoadableVideoComponant = ({
  src,
  alt,
  type,
  classes,
  withOverlay,
  overlayText,
  overlayImage,
  onFinishLoading,
  hideVideoControls,
  width,
  height,
  fileId,
  preload,
  muted,
  isLink,
  isUploading,
  ...props
}) => {
  const [state, setState] = useState(false);
  return (
    <>
      {
        src && !isLink && (
          <video
            preload={preload}
            key={src}
            id='video-id-name'
            width={width}
            height={height}
            {...props}
            controls
            loop
            muted={muted}
            // ={!hideVideoControls}
            onLoad={() => {
              setState(true);
              if (onFinishLoading) onFinishLoading();
            }}
            class={`${classes}${(hideVideoControls && ' hide-controls') || ''}`}
          >
            <source src={`${src}`} type="video/mp4" />
            <source src={`${src}`} type="video/webm" />
            <source src={`${src}`} type="video/ogg" />
          </video>
        )
        ||
        <img
          src={isLink ? defaultVideoImage : image}
          alt={alt}
          className={`${classes} ${!state ? ' show' : ' hidden'}`}
          height={height}
          {...props}
        />
      }
    </>
  );
};
LoadableVideoComponant.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(LoadableImageEnum).map((item) => item.key)),
  classes: PropTypes.string,
  preload: PropTypes.string,
  onFinishLoading: PropTypes.func,
  withOverlay: PropTypes.bool,
  muted: PropTypes.bool,
  overlayText: PropTypes.string,
  isLink: PropTypes.string,
  overlayImage: PropTypes.string,
  props: PropTypes.instanceOf(Object),
};
LoadableVideoComponant.defaultProps = {
  type: LoadableImageEnum.video.key,
  withOverlay: false,
  overlayText: undefined,
  overlayImage: undefined,
  onFinishLoading: undefined,
  classes: undefined,
  props: undefined,
  preload: 'auto',
  muted: true,
  isLink: false
};
