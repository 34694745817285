import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modal: {
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    width: "688px",
    maxWidth: "688px",
    borderRadius: "12px",
    background: theme.palette.background.paper,
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
    "& p": {
      color: theme.palette.text.tertiary,
      fontWeight: 400,
      marginBottom: "16px",
      fontSize: "16px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
  },
  scrollDiv: {
    [theme.breakpoints.down("426")]: {
      maxHeight: "500px",
      overflowX: "auto",
      padding: "0 10px",
    },
  },
  fieldContainer: {
    display: "flex",
    gap: "32px",
    padding: "16px 0",
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
    "& label": {
      width: "228px",
      fontSize: "14px",
      fontWeight: 600,
      [theme.breakpoints.down("426")]: {
        width: "100px",
      },
    },

    [theme.breakpoints.down("426")]: {
      flexWrap: "wrap",
    },
  },
  errorMessageContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    width: "100%",
  },
  actionButtonsContainer: {
    display: "flex",
    gap: "16px",
    width: "100%",
  },
  errorMessage: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
    color: theme.palette.text.error_primary,
  },
  inputGroup: {
    display: "flex",
    gap: "16px",
    flexDirection: "column",
    width: "100%",
  },
  inputFullWidth: {
    width: "100% !important",
    maxWidth: "100% !important",
    [theme.breakpoints.down("426")]: {
      width: "100% !important",
    },
  },
  footerActions: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingTop: "24px",
  },
  textAreaInput: {
    width: "100%",
    minHeight: "130px",
    borderColor: theme.palette.border.primary,
    background: theme.palette.background.primary,
    borderRadius: "8px",
    padding: "12px 14px",
    fontSize: "16px",
    lineHeight: "24px",
    resize: "none",
    color: theme.palette.text.primary,
    fontWeight: 500,
    "&::placeholder": {
      color: theme.palette.text.placeholder,
      fontWeight: 400,
    },
  },
  modalHeader: {
    position: "relative",
    display: "flex",
    gap: "4px",
    flexDirection: "column",
    "& span": {
      fontSize: "14px",
      fontWeight: 400,
      color: theme.palette.text.tertiary,
    },
  },
  modalTitle: {
    fontSize: "18px",
    fontWeight: 600,
  },
  statusCard: {
    display: "flex",
    padding: "12px 16px",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "6px",
    flex: "1 0 0",
    textAlign: "center",
    borderRadius: "12px",
    border: `2px solid ${theme.palette.border.secondary}`,
    background: theme.palette.background.paper,
    cursor: "pointer",
    "& span": {
      fontSize: "14px",
      fontWeight: 500,
      color: theme.palette.text.primary,
      lineHeight: "20px",
    },
    "&:hover": {
      border: `2px solid ${theme.palette.border.brandSolid}`,
    },
  },
  activeStatusCard: {
    border: `2px solid ${theme.palette.border.brandSolid}`,
  },
  statusCardContent: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
  },
  closeIcon: {
    top: 0,
    position: "absolute",
    cursor: "pointer",
  },
}));
