import React, { useEffect } from 'react';
import { SwitchRoute } from '../../../Components/Route/SwitchRoute';
import { UnitsSalesRoutes } from '../../../routes';

export const UnitsSalesLayout = () => {
    useEffect(() => {
        return () => {
            localStorage.removeItem("unitsSalesCurrentPageIndex");
        }
    }, []);

    return (
        <SwitchRoute routes={UnitsSalesRoutes} />
    );
};
