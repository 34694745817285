import { makeStyles } from "@material-ui/core/styles";
import { useIsAr } from "../../../Hooks";

export default makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    viewModeBtnGroup: {
      "& .MuiButtonBase-root": {
        backgroundColor: theme.palette.background.paper,
        padding: "10px 12px",
        borderColor: theme.palette.border.primary,
        borderRadius: "8px",
        "&:hover": {
          backgroundColor: theme.palette.background.secondary,
        },
      },

      "& .MuiButton-label": {
        display: "flex",
        gap: "8px",
      },

      "& .MuiButtonBase-root:nth-of-type(1)": {
        ...(isAr
          ? {
              marginLeft: "-1px",
              borderRadius: "0 8px 8px 0",
            }
          : {
              borderRadius: "8px 0 0 8px",
            }),
      },

      "& .MuiButtonBase-root:nth-last-of-type(1)": {
        ...(isAr
          ? { borderRadius: "8px 0 0 8px" }
          : {
              borderRadius: "0 8px 8px 0",
            }),
      },
    },

    isActiveBg: {
      backgroundColor: `${theme.palette.background.secondary} !important`,
    },
  };
});
