import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  AutocompleteComponent, Inputs, PhonesComponent, DatePickerComponent
} from '../../../../../Components';
import {
  StaticLookupsIds
} from '../../../../../assets/json/StaticLookupsIds';
import { LeadTypeIdEnum } from '../../../../../Enums';
import { lookupItemsGet } from '../../../../../Services';
import {
  ContactRule, UserRule, UnitRule, PropertyRule, ActivitiesRule
} from '../Rules';
import { getErrorByName } from '../../../../../Helper';

export const FieldValue = ({
  parentTranslationPath, translationPath, state, setState, item, index, schema, setIfHaveAnyErrors
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [isLoading, setIsLoading] = useState(false);
  const [itemSearch, setItemSearch] = useState(null);
  const [helperText, setHelperText] = useState('');
  const [items, setItems] = useState([]);
  const [itemValue, setItemValue] = useState('');
  const [isHaveRegularExpression, setIsHaveRegularExpression] = useState(false);
  const [isBlankFilter, setIsBlankFilter] = useState(!!(item && (item.operator === 13 || item.operator === 14)));

  useEffect(() => {
    if (index !== -1 && state.conditions && state.conditions[index] && state.conditions[index].fieldName)
      setItemSearch(state.conditions[index].fieldName);
    else
      setItemSearch(null);
  }, [state]);

  useEffect(() => {
    setIsBlankFilter((!!(item && (item.operator === 13 || item.operator === 14))));
    setIsHaveRegularExpression(!!(item && (item.operator === 1)));
    setHelperText('');
  }, [item.operator]);

  const lookupItems = useCallback(async (lookup, search) => {
    setIsLoading(true);
    const result = await lookupItemsGet({
      lookupTypeId: lookup,
      pageIndex: 1,
      pageSize: 100,
      searchedItem: search,
    });
    if (!(result && result.status && result.status !== 200)) {
      setTimeout(() => {
        setItems(result.result);
      }, 500);
    } else
      setItems([]);
    setIsLoading(false);
  }, [itemSearch]);

  useEffect(() => {
    setHelperText('');
    setItems([]);
    if (itemSearch && itemSearch.data && itemSearch.data.lookup && itemSearch.data.lookup !== '')
      lookupItems(itemSearch.data.lookup);
    else if (itemSearch && itemSearch.data && itemSearch.data.searchKey && itemSearch.data.searchKey === 'contact')
      ContactRule(itemSearch, itemValue, setItems, setIsLoading);

    else if (itemSearch && itemSearch.data && itemSearch.data.searchKey && itemSearch.data.searchKey === 'unit') {
      const isOwnerLead = item.category && item.category === 'leads' && item.categoryType && item.categoryType === LeadTypeIdEnum.Owner.leadTypeId;
      UnitRule(itemSearch, itemValue, setItems, setIsLoading, isOwnerLead);
    } else if (itemSearch && itemSearch.data && itemSearch.data.searchKey && itemSearch.data.searchKey === 'User')
      UserRule(itemSearch, itemValue, setItems, setIsLoading);
    else if (itemSearch && itemSearch.data && itemSearch.data.searchKey && itemSearch.data.searchKey === 'property')
      PropertyRule(itemSearch, itemValue, setItems, setIsLoading);
    else if (itemSearch && itemSearch.data && itemSearch.data.searchKey && itemSearch.data.searchKey === 'activities')
      ActivitiesRule(itemSearch, itemValue, setItems, setIsLoading);
  }, [itemSearch]);

  useEffect(() => {
    if (helperText !== '')
      setIfHaveAnyErrors(true);
    else
      setIfHaveAnyErrors(false);
  }, [helperText]);

  useEffect(() => {
    if (state.conditions[index] && state.conditions[index].category && state.conditions[index].category === 'leads' && state.conditions[index].categoryType) {
      const isLeadClass = state.conditions[index] && state.conditions[index].fieldName && state.conditions[index] && state.conditions[index].fieldName.field.id === 'leadClass';

      if (state.conditions[index].categoryType === 6 && isLeadClass) {
        setTimeout(() => {
          setItems(['Seller', 'Landlord']);
        }, 500);
      } else if (state.conditions[index].categoryType === 7 && isLeadClass) {
        setTimeout(() => {
          setItems(['Buyer', 'Tenant']);
        }, 500);
      } else setItems([]);
    }
  }, [state.conditions]);

  return (
    <>
      {itemSearch && (
        <div>
          {
            (itemSearch.field.FieldType === 'select' || itemSearch.field.FieldType === 'searchField' || itemSearch.data.uiType === 'select') &&
            (
              <AutocompleteComponent
                value={item.value}
                isDisabled={isBlankFilter}
                key={`form${index + 1}-${item.searchKey + index}-${itemSearch.field.id}`}
                selectedValues={item.value}
                idRef={`form${index + 1}-${itemSearch.field.id}`}
                isLoading={isLoading}
                withLoader
                multiple={false}
                data={(itemSearch.data.enum && itemSearch.data.enum.length > 0 ? itemSearch.data.enum : items)}
                inputClasses='inputs theme-form-builder'
                displayLabel={
                  (itemSearch.field.FieldType === 'searchField' &&
                    ((option) => (option && `${option.name} ${option.barnch || ''}`) || '')) ||
                  ((option) => (itemSearch.data.lookup && itemSearch.data.lookup !== '' && option && option.lookupItemName) || option || '')

                }
                onChange={(e, v) => {
                  const updatelist = state.conditions;
                  updatelist[index] = {
                    ...updatelist[index],
                    value: v,
                  };
                  setState({ id: 'conditions', value: updatelist });
                }}
                onInputChange={
                  ((itemSearch.field.FieldType === 'searchField' || itemSearch.field.FieldType === 'select') &&
                    ((e, v) => {
                      setItemValue(v);
                      if (v !== '') {
                        if ((itemSearch && itemSearch.data && itemSearch.data.lookup && itemSearch.data.lookup !== '') && (itemSearch.data.lookup === StaticLookupsIds.Country || itemSearch.data.lookup === StaticLookupsIds.Cities || +itemSearch.data.lookup === +StaticLookupsIds.District || +itemSearch.data.lookup === +StaticLookupsIds.SubCommunity || +itemSearch.data.lookup === +StaticLookupsIds.Community)) {
                          lookupItems(itemSearch.data.lookup, v);
                          return;
                        }
                        ContactRule(itemSearch, v, setItems, setIsLoading);

                        const isOwnerLead = item.category && item.category === 'leads' && item.categoryType && item.categoryType === LeadTypeIdEnum.Owner.leadTypeId;
                        UnitRule(itemSearch, v, setItems, setIsLoading, isOwnerLead);
                        UserRule(itemSearch, v, setItems, setIsLoading);
                        PropertyRule(itemSearch, v, setItems, setIsLoading);
                      }
                    })) ||
                  undefined
                }
                withoutSearchButton
                labelValue={!index ? t(`${translationPath}value`) : ''}
                error={getErrorByName(schema, 'value').error}
              />
            )
          }
          {
            (itemSearch.field.FieldType === 'textField' || itemSearch.field.FieldType === 'textarea' || itemSearch.data.uiType === 'text') &&
            (
              (
                <Inputs
                  idRef={`form${index + 1}-${item.searchKey + index}${item.operator + index}-${itemSearch.field.id}`}
                  isDisabled={isBlankFilter}
                  labelClasses=''
                  key={`form${index + 1}-${item.searchKey + index}-${itemSearch.field.id}`}
                  labelValue={!index ? t(`${translationPath}value`) : ''}
                  value={(item.value) || ''}
                  helperText={(helperText)}
                  error={getErrorByName(schema, 'value').error || helperText !== ''}
                  onInputChanged={(e) => {
                    setHelperText('');
                    const itemRegex = new RegExp(itemSearch.data.regExp);
                    if (isHaveRegularExpression && !itemRegex.test(e.target.value))
                      setHelperText(itemSearch.data.errorMsg);

                    const updatelist = state.conditions;
                    updatelist[index] = {
                      ...updatelist[index],
                      value: e.target.value,
                    };
                    setState({ id: 'conditions', value: updatelist });
                  }}
                />
              )

            )
          }
          {(itemSearch.data.CommunicationType === 'Phone' || itemSearch.field.FieldType === 'phone') && (
            <div className='form-item'>
              <PhonesComponent
                idRef={itemSearch.field.id}
                isDisabled={isBlankFilter}
                labelValue={!index ? t(`${translationPath}value`) : ''}
                key={`form${index + 1}-${item.searchKey + index}-${itemSearch.field.id}`}
                value={item.value ? item.value : itemSearch.data.defaultCountryCode}
                onInputChanged={(value) => {
                  if (value.length > 14) return;
                  if (value.length < 7)
                    setHelperText('please insert correct phone number');
                  else setHelperText('');
                  const updatelist = state.conditions;
                  updatelist[index] = { ...updatelist[index], value, };
                  setState({ id: 'conditions', value: updatelist });
                }}
                error={getErrorByName(schema, 'value').error || helperText !== ''}
                helperText={helperText}
              />
            </div>
          )}

          {itemSearch.field.FieldType === 'communication' &&
            (itemSearch.data.CommunicationType === 'Email' ||
              itemSearch.data.CommunicationType === 'SocialMedia') && (
              <div className='form-item'>
                <Inputs
                  idRef={itemSearch.field.id}
                  labelValue={!index ? t(`${translationPath}value`) : ''}
                  value={item.value || ''}
                  key={`form${index + 1}-${item.searchKey + index}-${itemSearch.field.id}`}
                  isDisabled={isBlankFilter}
                  isWithError
                  error={getErrorByName(schema, 'value').error || helperText !== ''}
                  onInputChanged={(e) => {
                    setHelperText('');
                    const itemRegex = new RegExp(itemSearch.data.regExp);
                    if (isHaveRegularExpression && !itemRegex.test(e.target.value))
                      setHelperText(itemSearch.data.errorMsg);

                    const updatelist = state.conditions;
                    updatelist[index] = {
                      ...updatelist[index],
                      value: e.target.value,
                    };
                    setState({ id: 'conditions', value: updatelist });
                  }}

                />
              </div>
            )}

          {itemSearch.field.FieldType === 'alt-date' && (
            <div className='form-item'>
              <DatePickerComponent
                idRef={itemSearch.field.id}
                isDisabled={isBlankFilter}
                labelValue={!index ? t(`${translationPath}value`) : ''}
                value={(item.value && moment(item.value).isValid() && moment(item.value))}
                maxDate={
                  itemSearch.data.maxDate && moment(new Date(itemSearch.data.maxDate)).isValid() ?
                    moment(new Date(itemSearch.data.maxDate)) :
                    undefined
                }
                minDate={
                  itemSearch.data.minDate && moment(new Date(itemSearch.data.minDate)).isValid() ?
                    moment(new Date(itemSearch.data.minDate)) :
                    undefined
                }
                isWithError
                error={getErrorByName(schema, 'value').error}
                onDateChanged={(e) => {
                  const updatelist = state.conditions;
                  updatelist[index] = {
                    ...updatelist[index],
                    value: e,
                  };
                  setState({ id: 'conditions', value: updatelist });
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

FieldValue.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  translationPathForData: PropTypes.string.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  setIfHaveAnyErrors: PropTypes.func.isRequired,

};
