import React from "react";

const SMSIcon = ({ fill, ...restProps }) => {
  return (
    <svg
      viewBox="0 0 21 20"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M5.163 1.72a4.2 4.2 0 0 0-1.061.263c-.476.21-1.033.673-1.331 1.106-.334.486-.506 1.035-.564 1.8-.05.664-.05 7.53.001 7.981.104.935.572 1.739 1.345 2.312.398.296.99.536 1.435.583.115.012.276.031.358.041l.149.018.001.759c.001 1.138.066 1.432.387 1.754.411.411 1.031.464 1.55.134.094-.06.806-.615 1.583-1.234.777-.619 1.489-1.166 1.582-1.217.359-.196.412-.2 2.526-.2 1.979-.001 2.705-.029 3.147-.122 1.088-.229 2.012-1.051 2.342-2.083.104-.326.143-.554.182-1.05.046-.6.045-7.061-.002-7.677-.077-1.018-.376-1.706-1.004-2.304-.534-.51-1.15-.775-2.011-.868-.422-.045-10.206-.041-10.615.004m10.775 1.685c.535.13.968.532 1.123 1.042.103.338.132 1.643.113 5.003-.02 3.42-.024 3.488-.222 3.855-.127.236-.401.51-.636.637-.346.186-.538.2-3.156.231-2.322.026-2.406.03-2.658.104a3.568 3.568 0 0 0-1.009.481c-.127.091-.692.532-1.254.981-.563.449-1.032.816-1.041.816-.009 0-.022-.398-.029-.884-.013-.983-.021-1.022-.264-1.264-.193-.194-.327-.224-1.139-.26l-.726-.031-.265-.131a1.651 1.651 0 0 1-.768-.767c-.123-.248-.131-.286-.159-.746-.04-.657-.039-6.262.001-7.134.036-.773.077-.972.261-1.259.197-.307.538-.565.859-.65.086-.022.291-.053.455-.068.164-.015 2.543-.025 5.286-.021 4.52.006 5.01.012 5.228.065m-9.98 2.95c-.609.303-.594 1.142.027 1.472.116.062.246.066 2.433.066 2.186 0 2.316-.004 2.432-.066a.808.808 0 0 0 .454-.596.803.803 0 0 0-.425-.875l-.183-.091H6.139l-.181.09m.084 2.866c-.428.154-.657.724-.45 1.124.098.19.322.388.493.434.095.025 1.243.034 3.653.028l3.515-.009.171-.106a.9.9 0 0 0 .278-.278c.092-.149.106-.204.106-.421 0-.218-.014-.273-.106-.422a.9.9 0 0 0-.278-.278l-.171-.105-3.538-.008c-2.944-.006-3.56.001-3.673.041"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
};

export default SMSIcon;
