import React from "react";

import useStyles from "./styles";

const ProgressBar = ({ staticProgress = 0 }) => {
  const styles = useStyles();

  return (
    <div className={styles.progressBar}>
      <div
        className={styles.progressBarFill}
        style={{ width: `${staticProgress}%` }}
      />
    </div>
  );
};

export default ProgressBar;
