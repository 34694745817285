export const OpenFileEnum = {
  contacts: {
    key: 'contacts',
  },
  properties: {
    key: 'properties',
  },
  units: {
    key: 'units',
  },
  leads: {
    key: 'leads',
  },
  agents: {
    key: 'agents',
  },
};
