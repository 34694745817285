export const ReminderTypesEnum = {
  Email: {
    key: 1,
    value: 'email',
  },
  Sms: {
    key: 2,
    value: 'sms',
  },
  Websocket: {
    key: 3,
    value: 'Notification',
  },
};
