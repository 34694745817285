import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
  AutocompleteComponent,
  DatePickerComponent,
  DialogComponent,
  Inputs,
  Spinner,
} from '../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { DocumentsUploader } from '../../../../../../../SharedComponents/DocumentsUploader/DocumentsUploader';
import {
  GetLookupItemsByLookupTypeName,
  lookupItemsGetId,
} from '../../../../../../../Services';
import { StaticLookupsIds } from '../../../../../../../assets/json/StaticLookupsIds';
import Joi from 'joi';
import { getErrorByName, showError } from '../../../../../../../Helper';

export const BranchDocumentManagementDialog = ({
  parentTranslationPath,
  translationPath,
  isOpenChanged,
  isOpenManagementDialog,
  onSave,
  activeItem,
}) => {
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [lookups, setLookups] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [state, setState] = useReducer(reducer, {
    files: [],
    categoryId: null,
    expiryDate: null,
    branchDocumentId: null,
  });

  const { t } = useTranslation([parentTranslationPath, 'Shared']);

  const schema = Joi.object({
    categoryId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}category-is-required`),
        'number.empty': t(`${translationPath}category-is-required`),
      }),
    files: Joi.array()
      .required()
      .min(1)
      .messages({
        'array.base': t(
          `${translationPath}please-select-at-least-one-document`
        ),
        'array.empty': t(
          `${translationPath}please-select-at-least-one-document`
        ),
        'array.min': t(`${translationPath}please-select-at-least-one-document`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const getBranchDocumentCategories = async () => {
    setIsLoading(true);
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'BranchDocumentCategory',
      pageIndex: 1,
      pageSize: 1000,
    });
    if (!(res && res.status && res.status !== 200)) {
      setLookups(res.result || []);
    }
    setIsLoading(false);
  };

  const onSaveClicked = (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (schema.error) {
      showError('please-fill-all-required-fields');
      return;
    }
    const documents = state?.files.map((file) => {
      return {
        fileId: file.fileId,
        expiryDate: state.expiryDate,
        isActive: true,
        fileName: file.fileName,
        fileLink: file.documentLink,
        categoryId: state.categoryId,
        categoryName: selectedValue.lookupItemName,
        branchDocumentId: state.branchDocumentId,
      };
    });
    onSave(documents);

    setIsLoading(false);
  };
  useEffect(() => {
    if (activeItem) {
      setState({
        id: 'edit',
        value: {
          files: [activeItem],
          expiryDate: activeItem?.expiryDate,
          categoryId: activeItem?.categoryId,
        },
      });
      setSelectedValue({
        lookupItemName: activeItem?.categoryName,
        lookupItemsId: activeItem?.categoryId,
      });
    }
  }, [activeItem]);

  useEffect(() => {
    getBranchDocumentCategories();
  }, []);

  return (
    <>
      <DialogComponent
        titleText={t(`${translationPath}upload-documents`)}
        saveText={t(`${translationPath}upload`)}
        saveType='button'
        maxWidth='md'
        disableBackdropClick
        isOpen={isOpenManagementDialog}
        dialogContent={
          <div className='contact-profile-document-management-dialog d-flex-column-center'>
            <Spinner isActive={isLoading} isAbsolute />
            <div className='mb-3 ml-5 w-75'>
              <AutocompleteComponent
                idRef='categoryIdRef'
                isDisabled={activeItem}
                labelValue='category'
                data={lookups || []}
                selectedValues={selectedValue}
                multiple={false}
                displayLabel={(option) => option?.lookupItemName || ''}
                getOptionSelected={(option) =>
                  option.lookupItemId === selectedValue?.lookupItemId
                }
                withoutSearchButton
                helperText={getErrorByName(schema, 'categoryId').message}
                error={getErrorByName(schema, 'categoryId').error}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(e, newValue) => {
                  setSelectedValue(newValue);
                  setState({ id: 'categoryId', value: newValue.lookupItemId });
                }}
              />
            </div>
            <div className='w-75 mb-3 ml-5'>
              <DatePickerComponent
                type='date'
                labelValue='expiry-date'
                value={state.expiryDate}
                onDateChanged={(value) => {
                  setState({ id: 'expiryDate', value });
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            </div>

            <DocumentsUploader
              initUploaded={
                state?.files?.map((item) => ({
                  uuid: item.uuid || item.fileId,
                  fileName: item.fileName,
                  documentLink: item.documentLink,
                })) || []
              }
              docTypes={{
                images: 0,
                videos: 1,
                docs: 2,
                links: 3,
              }}
              open
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              uploadedChanged={(files) =>
                setState({
                  id: 'files',
                  value:
                    files?.map((file) => ({
                      fileId: file.uuid || file.fileId || null,
                      fileName: file.fileName,
                      documentLink: file.documentLink || null,
                    })) || [],
                })
              }
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
        }
        saveClasses='btns theme-solid  w-100 mx-2 mb-2'
        onSaveClicked={onSaveClicked}
        onCloseClicked={isOpenChanged}
        onCancelClicked={isOpenChanged}
      />
    </>
  );
};
