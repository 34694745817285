import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box } from "@material-ui/core";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@material-ui/lab";

// Styles
import useStyles from "./styles";

function CustomTimeline({
  timelineData,
  component: Component,
  timelineDotTopMargin,
  timelineContainerClasses,
  timelinePrefixComponent: TimelinePrefixComponent,
}) {
  const styles = useStyles({ timelineDotTopMargin });

  return (
    <Box className={clsx(styles.timelineContainer, { [timelineContainerClasses]: !!timelineContainerClasses })}>
      <Timeline align="left" className={styles.timeLine}>
        {timelineData.map((item, index) => (
          <TimelineItem key={index} className={styles.missingOppositeContent}>
            {TimelinePrefixComponent && (
              <TimelinePrefixComponent item={item} />
            )}
            <TimelineSeparator className={styles.timelineSeparator}>
              <TimelineDot className={styles.timelineDot} />
              <TimelineConnector className={styles.timelineConnector} />
            </TimelineSeparator>
            <TimelineContent className={styles.timelineContent}>
              {/* Render dynamic component */}
              <Component item={item} />
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  );
}

CustomTimeline.propTypes = {
  timelineData: PropTypes.array.isRequired,
  component: PropTypes.elementType.isRequired, // Expecting a component
};

export default CustomTimeline;
