import React from 'react'
import ReactQuill from 'react-quill';
import {DialogComponent,} from '../../../../../../../../../Components';
import {Chip} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {GlobalTranslate} from '../../../../../../../../../Helper';
export const ActivityNotificationsDialog = ({
    isOpen,
    notifications,
    setIsOpen,
    translationPath,
    parentTranslationPath
    })=>{

        const { t } = useTranslation([parentTranslationPath, 'Shared']);

    return (
        <DialogComponent
        disableBackdropClick
        isOpen={isOpen}
        translationPath={translationPath}
        parentTranslationPath='Shared'
        titleClasses='DialogComponent-WorkingHoursDialogView'
        wrapperClasses='wrapperClasses-WorkingHoursDialogView'
        titleText={t(`${translationPath}activities-notifications`)}
        onCloseClicked={() => setIsOpen(false)}
        maxWidth='sm'
        dialogContent={(
          <>
            <div className='dialog-content-item w-100'>
            <div className='message-header-wrapper'>
              <label>{t(`${translationPath}date`)}</label>
              <div className='message-header'>
                {notifications&&notifications.data&&notifications.data.date}
              </div>
              </div>

            <div className='message-header-wrapper'>
              <label>{t(`${translationPath}from`)}</label>
              <div className='message-header'>                
                <Chip
                  className='message-chip'
                  label={notifications&&notifications.fullName}
                />
              </div>
            </div>

              <div className='message-body'>
              <ReactQuill
          idRef='emailref'
          readOnly
          placeholder={t(`${translationPath}Message`)}
          value={notifications&&notifications.body || ''}
          />
          </div>
            </div>
          </>
        )}
      />
    )
}
