export function BuiltupAreaRule2(id, value, sellingPrice, setDate, formType, itemList , item ) {

  const fixedNumber =  (value  && value !== '' )  ? ( value.replace(/,/g, "") || value ) : 0 ;
  const builtupAreaSqFtValue = fixedNumber ? parseInt(fixedNumber) : 0;
  if (formType === 2 && id === 'builtup_area_sqft' && item && !(item.data.isReadonly)) {
    const builtupAreaSqmIndex = itemList.indexOf(
      itemList.find((f) => f.field.id.toLowerCase() === 'builtup_area_sqm')
    );
   
    if(builtupAreaSqmIndex !== -1 )
    {
      const builtupAreaSqm =  (builtupAreaSqFtValue ) ?  parseFloat((builtupAreaSqFtValue * 0.09290303997).toFixed(2)) :  0 ;
      if(setDate && builtupAreaSqmIndex !== -1)
      setDate(builtupAreaSqm, 0, 'builtup_area_sqm');
     
    }
    if (sellingPrice !== '********') {
      const priceSqm = sellingPrice && builtupAreaSqFtValue  !== 0 ? (Number(sellingPrice) / builtupAreaSqFtValue ) : 0;
      setDate(priceSqm, 0, 'pricesqm');
    }
    
  }
}

export function BuiltupAreaRule(id, value, sellingPrice, setDate, formType , itemList )  
{
  if (id === 'builtup_area-sqft' && formType === 1)
     { 

    const fixed =  value ? (value !== '' && value && value.replace(/,/g, "")) : 0 ;
    const builtupAreaSqFtValue = fixed ? parseInt(fixed) : 0;
    const priceSqm = sellingPrice && builtupAreaSqFtValue !== 0 ? (Number(sellingPrice) / builtupAreaSqFtValue): 0;
    if (formType === 1)
      setDate('pricesqm', priceSqm);

    const builtupAreaSqmIndex = itemList.indexOf(
      itemList.find((f) => f.field.id.toLowerCase() === 'builtup_area-sqm')
    );
    
    if(builtupAreaSqmIndex !== -1 )
    {
      const builtupAreaSqm =  (builtupAreaSqFtValue) ?  parseFloat((0.09290303997 * builtupAreaSqFtValue).toFixed(2))  : 0   ; 
      setDate('builtup_area-sqm' , builtupAreaSqm  || 0);
      setDate('builtup_area_sqm' , builtupAreaSqm  ||' ');
    }
  }
}
