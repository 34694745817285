import React, { useCallback, useReducer, useState } from "react";
import moment from "moment";
import Joi from "joi";
import btnLoader from "../../../../../../assets/images/GIF_images/btn-loader.gif";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@material-ui/core";
import {
  AutocompleteComponent,
  DatePickerComponent,
  Inputs,
} from "../../../../../../Components";
import {
  CreateInvoicesReceipt,
  lookupItemsGetId,
} from "../../../../../../Services";
import { GetParams, showError, showSuccess } from "../../../../../../Helper";
import { StaticLookupsIds } from "../../../../../../assets/json/StaticLookupsIds";
import { UnitsOperationTypeEnum } from "../../../../../../Enums";
import { PaymentMethodsData } from "../../../Utilities/PaymentMethodsBanner/PaymentMethodsData";
import { ActivityFilesUploader } from "../../../../../../Components/UploaderActivitieFileComponent/ActivityFilesUploader";
import { AdditionalPaymentInfoFields } from "./AdditionalPaymentInfoFields";
import { BankAccountDetailsCard } from "../../../Utilities";

export const CreateReceiptForm = ({
  invoiceId,
  unitTransactionId,
  setIsSubmitted,
  reloadInvoices,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const defaultSelected = {
    paymentMode: null,
    paymentAmount: "",
    dateOfPayment: null,
    contactType: null,
    remarks: "",
    bankTransferNo: "",
    chequeNo: "",
    creditCardNo: "",
    branch: "",
    bank: null,
    receiptDocuments: {
      files: [],
    },
  };

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [customAccountDetails, setCustomAccountDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useReducer(reducer, defaultSelected);
  const [data, setData] = useReducer(reducer, {
    paymentModes: [],
    contactTypes: [],
  });

  const schema = Joi.object({
    paymentMode: Joi.object()
      .required(),
    contactType: Joi.object()
      .required(),
    dateOfPayment: Joi.string()
      .required(),
    paymentAmount: Joi.number()
      .required(),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(selected);

  const getPaymentModes = async () => {
    const paymentModes = Object.values(PaymentMethodsData).map((item) => ({
      lookupItemId: item.id,
      lookupItemName: item.title.split("-").join(" "),
      key: item.key,
    }));
    setData({ id: "paymentModes", value: paymentModes });
  };

  const getContactTypes = async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.ContactTypes,
      lookupParentId: UnitsOperationTypeEnum.rent.key,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "contactTypes", value: res || [] });
    else setData({ id: "contactTypes", value: [] });
  };

  const createReceipt = async () => {
    setIsLoading(true);
    const body = convertSelectedToBody();

    const res = await CreateInvoicesReceipt(body);
    const isWithReceiptId = typeof res === "number";
    if (isWithReceiptId) {
      setIsSubmitted(true);
      reloadInvoices();
      showSuccess(t(`${translationPath}invoice-Receipt-created-successfully`));
      setSelected({ id: "edit", value: defaultSelected });
    } else showError(t(`${translationPath}invoice-Receipt-create-failed`));

    setIsLoading(false);
  };

  const convertSelectedToBody = () => {
    const receiptDocuments = selected.receiptDocuments?.files
      ? selected.receiptDocuments.files.map((item) => ({
        uuid: item.uuid,
        fileName: item.fileName,
      }))
      : [];

    return {
      netAmount: selected.paymentAmount || 0,
      receiptContactTypeId: selected.contactType?.lookupItemId || null,
      date: selected.dateOfPayment || null,
      paymentModeId: selected.paymentMode?.lookupItemId || null,
      bankId: selected.bank?.lookupItemId || null,
      branch: selected.branch,
      bankTransferNo: selected.bankTransferNo,
      chequeNo: selected.chequeNo,
      creditCardNo: selected.creditCardNo,
      createReceiptPaymentDetails: {
        accountName: customAccountDetails?.accountName || null,
        accountNumber: customAccountDetails?.accountNumber || 0,
        bankId: customAccountDetails?.bankId || null,
        currencyId: customAccountDetails?.currencyId || null,
        ibanCode: customAccountDetails?.ibanCode || null,
        swiftCode: customAccountDetails?.swiftCode || null,
      },
      invoicesIds: [invoiceId],
      receiptDocuments,
    };
  };

  const getIsCreateBtnDisabled = () => {
    const hasEmptyFields = schema?.error?.message;
    return isLoading || hasEmptyFields;
  };

  const saveHandler = () => {
    createReceipt();
  };

  return (
    <>
      <div>
        <div className="b-bottom">
          <div className="d-flex fj-start gap-16 w-75">
            <div className="w-25 mt-2 pt-1">
              <p className="fw-simi-bold m-0">{t(`${translationPath}Payment-mode*`)}</p>
            </div>
            <div className="w-50 mt-2 mb-3 pt-1">
              <AutocompleteComponent
                idRef="Payment-modeIdRef"
                inputPlaceholder="select"
                selectedValues={selected.paymentMode}
                multiple={false}
                data={data.paymentModes || []}
                displayLabel={(option) => option.lookupItemName || ""}
                withoutSearchButton
                isWithError
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onOpen={() => {
                  if (data.paymentModes && data.paymentModes.length == 0)
                    getPaymentModes();
                }}
                onChange={(_, newValue) => {
                  setSelected({ id: "paymentMode", value: newValue });
                }}
              />
            </div>
          </div>
        </div>
        {selected.paymentMode &&
          selected.paymentMode.key !== PaymentMethodsData.Cash.key ? (
          <AdditionalPaymentInfoFields
            setSelected={setSelected}
            selected={selected}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        ) : null}
        <div className="b-bottom">
          <div className="d-flex fj-start gap-16 w-75">
            <div className="w-25 mt-2 pt-1">
              <p className="fw-simi-bold m-0">{t(`${translationPath}Amount*`)}</p>
            </div>
            <div className="w-50 mt-2 mb-3 pt-1">
              <Inputs
                withNumberFormat
                idRef="paymentAmountRef"
                inputPlaceholder={`1,000,00`}
                value={selected.paymentAmount}
                endAdornment={<span className="px-2">AED</span>}
                type="number"
                min={0}
                onInputChanged={(event) => {
                  const paymentAmount = event.target.value;
                  setSelected({ id: "paymentAmount", value: paymentAmount });
                }}
              />
            </div>
          </div>
        </div>
        <div className="b-bottom">
          <div className="d-flex fj-start gap-16 w-75">
            <div className="w-25 mt-2 pt-1">
              <p className="fw-simi-bold m-0">
                {t(`${translationPath}Date-of-payment*`)}
              </p>
            </div>
            <div className="w-50 mt-2 mb-3 pt-1">
              <DatePickerComponent
                idRef="dateOfPaymentRef"
                labelClasses="Requierd-Color"
                placeholder="DD/MM/YYYY"
                value={selected.dateOfPayment}
                onDateChanged={(newValue) => {
                  setSelected({
                    id: "dateOfPayment",
                    value: newValue ? moment(newValue).format() : null,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="b-bottom">
          <div className="d-flex fj-start gap-16 w-75">
            <div className="w-25 mt-2 pt-1">
              <p className="fw-simi-bold m-0">{t(`${translationPath}Payment-from*`)}</p>
              <p className="light-text">
                {t(`${translationPath}Payment-from-desc`)}
              </p>
            </div>
            <div className="w-50 mt-2 mb-3 pt-1">
              <AutocompleteComponent
                idRef="Payment-typeIdRef"
                inputPlaceholder="select"
                selectedValues={selected.contactType}
                multiple={false}
                data={data.contactTypes || []}
                displayLabel={(option) => option.lookupItemName || ""}
                withoutSearchButton
                isWithError
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onOpen={() => {
                  if (data.contactTypes && data.contactTypes.length == 0)
                    getContactTypes();
                }}
                onChange={(_, newValue) => {
                  setSelected({ id: "contactType", value: newValue });
                }}
              />
            </div>
          </div>
        </div>
        <div className="b-bottom">
          <div className="d-flex fj-start gap-16 w-75">
            <div className="w-25 mt-2 pt-1">
              <p className="fw-simi-bold m-0">{t(`${translationPath}remarks`)}</p>
            </div>
            <div className="w-50 mt-2 mb-3 pt-1">
              <Inputs
                rows={4}
                multiline
                value={selected.remarks || ""}
                translationPath={translationPath}
                idRef="remarksRef"
                parentTranslationPath={parentTranslationPath}
                onInputChanged={(event) => {
                  const remarks = event.target.value;
                  setSelected({ id: "remarks", value: remarks });
                }}
              />
            </div>
          </div>
        </div>
        <div className="b-bottom">
          <div className="d-flex fj-start gap-16 w-75">
            <div className="w-25 mt-2 pt-1">
              <p className="fw-simi-bold m-0">{t(`${translationPath}documents`)}</p>
            </div>
            <div className="w-50 mt-2 pt-1">
              <ActivityFilesUploader
                state={selected.receiptDocuments}
                setState={(state) => {
                  const files = state?.value;
                  setSelected({
                    id: "receiptDocuments",
                    value: {
                      files,
                    },
                  });
                }}
                isDisabled={(selected.receiptDocuments?.files?.length > 0)}
              />
            </div>
          </div>
        </div>
        {selected.paymentMode?.lookupItemId ===
          PaymentMethodsData.BankTransfer.id ? (
          <div className="b-bottom">
            <div className="d-flex fj-start gap-16 w-75">
              <div className="w-25 mt-2 pt-1">
                <p className="fw-simi-bold m-0">
                  {t(`${translationPath}Bank-account`)}
                </p>
                <p className="light-text">
                  {t(`${translationPath}Bank-account-desc`)}
                </p>
              </div>
              <div className="w-75 mt-2 mb-3 pt-1">
                <BankAccountDetailsCard
                  invoiceId={invoiceId}
                  customAccountDetails={customAccountDetails}
                  setCustomAccountDetails={setCustomAccountDetails}
                  unitTransactionId={unitTransactionId}
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="d-flex-center my-3">
        <ButtonBase
          className="btns theme-propx solid mr-0"
          onClick={saveHandler}
          disabled={getIsCreateBtnDisabled()}
        >
          {t(`${translationPath}create-Receipt`)}
          {isLoading ? (
            <img src={btnLoader} className="ml-1" width="12" height="12" />
          ) : (
            false
          )}
        </ButtonBase>
      </div>
    </>
  );
};
