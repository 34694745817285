import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  modalContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  text: {
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "28px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      lineHeight: "24px",
    },
  },
  modalPaper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "688px",
    borderRadius: "12px",
    background: theme.palette.background.paper,
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",

    "& p": {
      color: theme.palette.text.tertiary,
      fontWeight: 400,
      marginBottom: "16px",
      fontSize: "16px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
  },
  contactTypeContainer: {
    display: "flex",
    gap: "16px",
    overflowY: "scroll",
    maxHeight: "440px",
    padding: "20px 24px",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  contactType: {
    display: "flex",
    gap: "16px",
    paddingBottom: "20px",
    borderBottom: `1px solid ${theme.palette.border.secondary}`,

    "& span": {
      color: theme.palette.text.secondary,
      fontWeight: 600,
      fontSize: "14px",
      marginRight: "16px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
        marginRight: "8px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  content: {
    padding: "24px 24px 20px 24px",
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  card: {
    display: "flex",
    padding: "24px",
    flexDirection: "column",
    alignItems: "center",
    gap: "6px",
    flex: "1 0 0",
    textAlign: "center",
    borderRadius: "12px",
    border: `2px solid ${theme.palette.border.secondary}`,
    background: theme.palette.background.paper,
    cursor: "pointer",

    "& span": {
      fontSize: "24px",
      fontWeight: 600,
      color: theme.palette.text.primary,
      lineHeight: "32px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px",
        lineHeight: "24px",
      },
    },

    "&:hover": {
      border: `2px solid ${theme.palette.border.brandSolid}`,
    },
  },
  activeCard: {
    border: `2px solid ${theme.palette.border.brandSolid}`,
  },
  uploadSection: {
    display: "flex",
    gap: "32px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      gap: "16px",
    },
  },
  uploadInstructions: {
    maxWidth: "160px",
    display: "flex",
    flexDirection: "column",
    fontSize: "14px",
    lineHeight: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },

    "& p": {
      margin: 0,
      fontWeight: 400,
      fontSize: "14px",
      color: theme.palette.text.tertiary,
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 600,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  downloadLink: {
    fontWeight: 600,
    textDecoration: "none",
    cursor: "pointer",
    color: theme.palette.button.tertiary_fg,

    "&:hover": {
      color: theme.palette.button.tertiary_fg_hover,
    },

    "&:visited": {
      color: theme.palette.button.tertiary_fg_hover,
    },

    "&:active": {
      color: theme.palette.button.tertiary_fg_hover,
    },
  },
  actions: {
    display: "flex",
    width: "100%",
    gap: "12px",
    justifyContent: "flex-end",
    borderTop: `1px solid ${theme.palette.border.secondary}`,
    padding: "24px",
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
      gap: "8px",
    },
  },
}));
