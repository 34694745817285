import { makeStyles } from "@material-ui/core/styles";
import { useIsAr } from "../../../../Hooks";

export default makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    container: {
      position: "relative",
      minHeight: "376px",
      [theme.breakpoints.up('md')]: {
        height: "942px",
      },
    },
    avatar: {
      top: "19px",
      position: "absolute",
      ...(isAr ? { right: "24px" } : { left: "24px" }),
    },
    detailsContent: {
      marginTop: "67px",
      padding: "0px 24px",
    },
    avatarWrapper: {
      marginTop: "26px",
      alignItems: "center",
      justifyContent: "flex-start",
      display: "flex",
      marginBottom: "16px",
    },

    nameWrapper: {
      display: "flex",
      gap: "8px",
      height: "32px",
      alignItems: "center",
    },

    rounded: {
      borderRadius: "20px",
    },

    contactDetails: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },

    specificationWrapper: {
      height: "24px",
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },

    classesWrapper: {
      display: "flex",
      gap: "8px",
      alignItems: "center",
    },

    actionsWrapper: {
      display: "flex",
      gap: "12px",
      marginTop: "24px",
      marginBottom: "24px",
      height: "40px",
    },

    actionBtn: {
      borderRadius: "8px",
    },

    infoSection: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      marginBottom: "24px",
    },

    infoHeading: {
      minHeight: "28px",
      paddingBottom: "20px",
      borderBottom: `1px solid ${theme.palette.border.secondary}`,
      maxWidth: "80%",
    },

    infoItem: {
      display: "flex",
      alignItems: "center",
      height: "24px",
      gap: "16px",
    },
    showMoreWrapper: {
      height: "24px",
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: "24px",
    },
  };
});
