import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteUnitApprovelSettings } from '../../../../Services/UnitApprovalServices/UnitApprovalServices';
import { DialogComponent, Spinner } from '../../../../Components';
import { showError, showSuccess } from '../../../../Helper';

export const DeleteDialogUnitPublishUnit = ({
  open,
  close,
  onSave,
  activeItem,
  reloadData,
  translationPath,
  parentTranslationPath
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [isLoading, setIsLoading] = useState(false);

  const deleteHandler = async () => {
    if (!activeItem.unitApprovalSettingId) {
      showSuccess(t(`${translationPath}delete-record-successfully`));
      onSave(activeItem?.fileId);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    const res = await DeleteUnitApprovelSettings(
      activeItem?.unitApprovalSettingId
    );
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}delete-record-successfully`));
      onSave(activeItem?.unitApprovalSettingId);
      reloadData();
    } else showError(t(`${translationPath}delete-record-failed`));
    setIsLoading(false);
  };

  return (
    <DialogComponent
      titleText={t(`${translationPath}delete-record`)}
      saveText={t('confirm')}
      saveType='button'
      maxWidth='sm'
      dialogContent={
        <div className='d-flex-column-center'>
          <Spinner isActive={isLoading} />
          <span className='mdi mdi-close-octagon c-danger mdi-48px' />
          <div className='w-100' style={{ display: 'block' }}>
            {' '}
            <span>{`${t('are-you-sure-you-want-to-Delete')}`} :</span>{' '}
            <div style={{ fontWeight: 600, width: '130px' }}>
              <p
                style={{
                  fontWeight: 600,
                  width: '30rem',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {' '}
                {`${activeItem && activeItem.branchName}`}{' '}
              </p>{' '}
            </div>
          </div>
        </div>
      }
      saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
      isOpen={open}
      onSaveClicked={deleteHandler}
      onCloseClicked={close}
      onCancelClicked={close}
      translationPath={translationPath.confirm}
    />
  );
};
