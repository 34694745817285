import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  rounded: {
    borderRadius: "20px",
  },
  cardWrapper: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: "12px",
    display: "flex",
    flex: "1 0 0",
    flexDirection: "column",
    maxWidth: "314px",
    minWidth: "314px",
    width: "314px",
  },
  cardContent: {
    padding: "20px 16px",
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerDetails: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  name: {
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
  },
  idInfo: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  additionalDetails: {
    display: "flex",
    gap: "8px",
  },
  actionContainer: {
    display: "flex",
    gap: "6px",
    alignItems: "center",
    margin: "4px 0",
  },
  iconContainer: {
    display: "flex",
    gap: "6px",
    alignItems: "center",
    margin: "8px 0",
  },
  featuresContainer: {
    display: "flex",
    gap: "6px",
    alignItems: "center",
    margin: "8px 0",
  },
  featureItem: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  divider: {
    marginTop: "16px",
    width: "100%",
    borderBottom: `1px solid ${theme.palette.background.secondary}`,
  },
  dateSection: {
    display: "flex",
    gap: "12px",
    marginTop: "12px",
  },
  dateInfo: {
    display: "flex",
    gap: "8px",
  },
}));
