import React, { useState , useRef } from "react";
import { useTranslation } from "react-i18next";
import Rating from "@material-ui/lab/Rating";
import { CheckboxesComponent, Inputs } from "../../../../../Components";
import FilterIcon from "../../../../../assets/images/temps/Filter.svg";
import ArrowDownIcon from "../../../../../assets/images/icons/arrow-down.svg";
import { ContactClassificationMenu } from "./ContactClassificationMenu";

export const DeveloperContent = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
  setContactClassificationFilter,
  setFilter,
  setSearch,
  search,
  filter,

}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [anchorEl, setAnchorEl] = useState(null);
  const [timer, setTimer] = useState(null);

  const menuRef = useRef();
  const searchTimer = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (e) => {
    if (!menuRef.current.contains(e.target)) {
      setAnchorEl(null);
    }
  };

  const onChangeScore = (value, key, item) => {

    let developersScores = [...state.listOfDeveloperScore];
    const itemIndex = developersScores.indexOf(
      developersScores.find((f) => f.contactId === item.contactId)
    );
    if (itemIndex !== -1)
      developersScores[itemIndex] = {
        ...developersScores[itemIndex],
        score: value || 0,
      };
    setState({ id: "listOfDeveloperScore", value: [...developersScores]});

     if (key === "realEstateDevelopers") {
      let realEstateDevelopersList = [...state.realEstateDevelopers];
      const realEstateDevelopersItemIndex = realEstateDevelopersList.indexOf(
        realEstateDevelopersList.find((f) => f.contactId === item.contactId)
      );
       if (realEstateDevelopersItemIndex !== -1)
        realEstateDevelopersList[realEstateDevelopersItemIndex] = {
          ...realEstateDevelopersList[realEstateDevelopersItemIndex],
          score: value || 0,
        };

      setState({ id: "realEstateDevelopers", value: [...realEstateDevelopersList]});
    } else if (key === "secondaryDevelopers") {
      let secondaryDevelopersList = [...state.secondaryDeveloperList];
      const secondaryDevelopersItemIndex = secondaryDevelopersList.indexOf(
        secondaryDevelopersList.find((f) => f.contactId === item.contactId)
      );
      if (secondaryDevelopersItemIndex !== -1)
        secondaryDevelopersList[secondaryDevelopersItemIndex] = {
          ...secondaryDevelopersList[secondaryDevelopersItemIndex],
          score: value || 0,
        };
        setState({ id: "secondaryDeveloperList", value: [...secondaryDevelopersList]});
    }
  };


  return (
    <div className="px-4 mt-2 w-100">
      <div className="developer-section">
        <div>
          <span> {t(`${translationPath}developer`)} </span>
        </div>
        <div className="w-100  mt-2 mb-2">
          <div className="w-100">
            <div className="developers w-100">
              <div
                className="w-100 d-flex d-flex-v-center-h-between b-bottom developer-rating"
                style={{ gap: ".5rem" }}
              >
                <div className="d-inline-flex-v-center-h-end pl-4-reversed">
                  <div
                    className="d-inline-flex-center"
                    style={{ gap: ".3rem" }}
                    ref={menuRef}
                  >
                    <CheckboxesComponent
                      label={filter.isSecondaryDeveloper ? t(`${translationPath}secondary-developer`) : t(`${translationPath}developer`)}
                      idRef={"classificationsId"}
                      singleChecked={
                        filter && filter.contactClassificationId ? true : false
                      }
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onSelectedCheckboxClicked={(e) => {
                        if (e.target.checked) handleClick(e);
                        else if (!e.target.checked) {
                          setContactClassificationFilter(false);
                          setFilter((item) => ({
                            ...item,
                            isSecondaryDeveloper: false,
                            contactClassificationId : null , 
                            pageIndex: 0,
                          }));
                        }
                      }}
                    />

                    <img
                      style={{ maxHeight: "14px", width: "16px" }}
                      className=""
                      alt="Filter"
                      src={FilterIcon}
                    />
                    <img
                      style={{ maxHeight: "14px", width: "16px" }}
                      className=""
                      alt="ArrowDown"
                      src={ArrowDownIcon}
                    />
                  </div>
                </div>
                <div className="d-inline-flex-v-center-h-end w-50">
                  <Inputs
                    inputPlaceholder={t(
                      `${translationPath}enter-developer-name`
                    )}
                    idRef="contactSearch"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    value={search}
                    onInputChanged={(e) => {
                      setSearch(e?.target?.value || "");
                    }}
                    onKeyUp={(event) => {
                      if (searchTimer.current)
                        clearTimeout(searchTimer.current);
                      const newValue = event.target.value;
                      searchTimer.current = setTimeout(() => {
                        setFilter((f) => ({
                          ...f,
                          search: newValue,
                          pageIndex: 0,
                        }));
                      }, 700);
                    }}
                    onKeyDown={() => {
                      if (timer != null) clearTimeout(timer);
                    }}
                  />
                </div>
                <ContactClassificationMenu
                  anchorEl={anchorEl}
                  onClose={handleCloseMenu}
                  parentTranslationPath={parentTranslationPath}
                  setContactClassificationFilter={
                    setContactClassificationFilter
                  }
                  setFilter={setFilter}
                  setAnchorEl={() => setAnchorEl(null)}
                />
              </div>
            </div>
          </div>
          <div className="developers w-100">
            {
              filter.isSecondaryDeveloper &&
              state &&
              state.secondaryDeveloperList &&
              state.secondaryDeveloperList.map((item, index) => (
                <div
                  className="w-100 d-flex d-flex-v-center-h-between b-bottom developer-rating"
                  key={`decondaryDevelopersItemRef${item.contactId}`}
                >
                  <div>
                    {" "}
                    <span className="fw-bold"> {item.contactName} </span>
                  </div>
                  <div className="mb-2">
                    <Rating
                      size="large"
                      name={`DcontactItem${item.contactId}-${item.contactName}}`}
                      max={10}
                      value={item.score}
                      precision={1}
                      onChange={(event, newValue) => {
                        onChangeScore(newValue, "secondaryDevelopers", item);
                      }}
                    />
                  </div>
                </div>
              ))}
            { 
              filter &&
              !filter.isSecondaryDeveloper &&
              state &&
              state.realEstateDevelopers &&
              state.realEstateDevelopers.map((item, index) => (
                <div
                  className="w-100 d-flex d-flex-v-center-h-between b-bottom developer-rating"
                  key={`realEstateDevelopersItemRef${item.contactId}`}
                >
                  <div>
                    {" "}
                    <span className="fw-bold"> {item.contactName} </span>
                  </div>
                  <div className="mb-2">
                    <Rating
                      size="large"
                      name={`contactItem${item.contactId}-${item.contactName}}`}
                      max={10}
                      value={item.score}
                      precision={1}
                      onChange={(event, newValue) => {
                        onChangeScore(newValue, "realEstateDevelopers", item);
                      }}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
