import moment from 'moment';
import React, {
  useCallback, useEffect, useMemo, useState, useRef
} from 'react';

import { useTranslation } from 'react-i18next';
import { AutocompleteComponent, Spinner } from '../../../Components';
import { DateRangePickerComponent } from '../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import { useTitle } from '../../../Hooks';
import { GetAllAgentsServices } from '../../../Services';
import { AgentRotationTableView } from './AgentRotationTableView/AgentRotationTableView';

const parentTranslationPath = 'AgentRotationLog';
const translationPath = '';
export const AgentRotationView = () => {
  const searchTimer = useRef(null);
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t('Shared:agent-rotation-log'));

  const [isLoading, setIsLoading] = useState(false);
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
  };
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [sortBy, setSortBy] = useState(null);
  const [filter, setFilter] = useState({
    fromDate: dateFilter.startDate,
    toDate: dateFilter.endDate,
    filterBy: null,
    orderBy: 1,
    isOn: null,
    agentId: null,
    userId: null,
    AgentName: null,
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25
  });

  const [allAgents, setAllAgents] = useState([]);
  const [searcAgenthValue, setSearcAgenthValue] = useState(null);

  useEffect(() => {
    if (sortBy)
      setFilter((item) => ({ ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy }));
  }, [sortBy]);

  useMemo(() => {
    setFilter((item) => ({
      ...item,
      fromDate: !dateFilter.startDate ? dateFilter.startDate : moment(dateFilter.startDate).format('YYYY-MM-DDTHH:mm:ss'),
      toDate: !dateFilter.endDate ? dateFilter.endDate : moment(dateFilter.endDate).format('YYYY-MM-DDTHH:mm:ss'),
    }));
  }, [dateFilter]);

  const getAgents = useCallback(async (searcAgenthValue) => {
    setIsLoading(true);
    const result = await GetAllAgentsServices({ pageSize: filter.pageSize, pageIndex: 0, agentName: searcAgenthValue ? searcAgenthValue : null });
    if (!(result && result.status && result.status !== 200)) {
      setAllAgents({
        result: (result && result.result) || [],
        totalCount: (result && result.totalCount) || 0,
      });
    } else {
      setAllAgents({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getAgents();
  }, []);



  return (
    <div className='SystemNotifications-wrapper view-wrapper'>
      <Spinner isLoading={isLoading} />
      <div className='w-100 px-2'>
        <div className='header-section-AgentRotation'>
          <div className='filter-section-AgentRotation'>
            <div className='section' />
            <div className='search-agentRotation'>
              <AutocompleteComponent
                idRef='referredToRef'
                multiple={false}
                data={(allAgents && allAgents.result) || []}
                value={filter.agentId}
                chipsLabel={(option) => option.agentName || ''}
                displayLabel={(option) => option.agentName || ''}
                withoutSearchButton
                inputPlaceholder={t(`${translationPath}search-agent-rotation`)}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                isLoading={isLoading}
                onInputKeyUp={(event) => {
                  const { value } = event.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getAgents(value)
                  }, 700);
                }}
                onChange={(event, newValue) => {
                  setFilter((item) => ({ ...item, agentId: (newValue && newValue.agentId) || null, pageIndex: 0 }));
                }}
              />
            </div>

            <div className='datePickerComponentAgentRotation'>
              <DateRangePickerComponent
                onClearClicked={() => setDateFilter(dateRangeDefault)}
                ranges={[dateFilter]}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onDateChanged={(selectedDate) => {
                  setDateFilter({
                    startDate: selectedDate.selection && selectedDate.selection.startDate,
                    endDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                    key: 'selection',
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className='w-100 px-2'>
          <AgentRotationTableView
            filter={filter}
            setFilter={setFilter}
            setSortBy={setSortBy}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        </div>
      </div>
    </div>
  );
};
