/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import exportFromJSON from 'export-from-json';
import { Tables, PermissionsComponent } from '../../../../Components';
import {
  getDownloadableLink,
  GetParams, GlobalHistory,
} from '../../../../Helper';
import {
  ExportReportServices, GetClientSigmantationById, GetSystemReportsResultClientsSegmentation
} from '../../../../Services';
import { CellsSpinner } from '../../../../SharedComponents/CellSpinnerComponent/CellsSpinner';
import { ClientsSegmentationPermissions } from '../../../../Permissions';

const parentTranslationPath = '';
const translationPath = '';
export const ClientSegmentationRunView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportDto, setReportDto] = useState([]);
  const [clientSegmentationObj, setClientSegmentationObj] = useState(null);
  const [state, setState] = useState({
    conditions: null,
    fields: null,
    systemReportFilters: null,
    clientSegmentationId: null,
    clientSegmentationName: null
  });

  const mediaprint = '@media print {footer {position: fixed !important;bottom: 0 !important;}strong, b {font-weight: bold !important;}@page {size:8.27in 11.69in !important;margin: 1cm 1cm 1cm 1cm !important;}}@media print {header {position: fixed !important;top: 0 !important;left: 0 !important;right: 0 !important;text-align:center !important;width: 100% !important;}strong, b {font-weight: bold !important;}@page {size:8.27in 11.69in !important;margin: .5cm !important;}}';
  const DateNow = Date();
  const printPartOfPage = async () => {
    window.print();
  };

  const maxHeight = '170px';
  const [reportSchema, setReportSchema] = useState([]);
  const { t } = useTranslation(parentTranslationPath);
  const unCamelCaseConvert = (name) => name.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()).replace('_', ' ').replace('.lookup Item Name', ' ')
    .replace('.value', ' ')
    .replace(/^((?:[^"\n]*"){5})(_*)(\..*)$/gm, ' ')
    .replace('-', ' ')
    .replace('-', ' ')
    .replace('-', ' ')
    .replace(')', '')
    .replace('(', '');

  const exportDataToFormats = async (Format, fileName, data) => {
    let exportType = null;
    if (Format !== 'pdf') {
      switch (Format) {
        case 'xls':
          exportType = exportFromJSON.types.xls;
          break;
        case 'csv':
          exportType = exportFromJSON.types.csv;
          break;
        case 'xml':
          exportType = exportFromJSON.types.xml;
          break;
        case 'json':
          exportType = exportFromJSON.types.json;
          break;
        default:
          exportType = exportFromJSON.types.xls;
          break;
      }
      exportFromJSON({ data, fileName, exportType });
      const ress = await ExportReportServices();
      if (ress)
        return;
    }
  };

  const returnFinalObject = () => {
    let conditions = [];
    let finalObj = null;
    state.conditions.map((element) => {
      conditions.push({
        category: element.category,
        categoryType: element.categoryType,
        formId: element.categoryType,
        searchKey: element.searchKey || element.displayPath,
        value: (element.value && (element.value.lookupItemName || element.value.name || element.value)),
        operator: element.operator,
        operand: element.operand,
      });
    });
    finalObj = {
      report:
      {
        conditions,
        fields: state.fields,
        systemReportFilters: (state.systemReportFilters),
      }
    };
    return finalObj;
  };

  const SystemReportsResultAPI = useCallback(async () => {
    setIsLoading(true);
    const reportJson = returnFinalObject();
    const searchObj = { spName: 'GetSegmentationContacts', reportJson };
    const res = await GetSystemReportsResultClientsSegmentation(1, 10000, searchObj);

    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) {
      setReportDto(res);
      const temp = res && res && res[0];
      const list = [];
      let i = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const key in temp) {
        // eslint-disable-next-line no-plusplus
        i++;
        if (key !== 'TotalCount') {
          list.push({
            id: i,
            label: unCamelCaseConvert(key),
            component: (item) => (
              <div>
                { ((item && item[key] !== null) && (String(item && item[key]) || 'N/A') || 'N/A')}
              </div>
            ),
            isDefaultFilterColumn: true,
            isSortable: true,
            input: key,
          });
        }
      }

      setReportSchema(list);
    }
    // else { setReportDto([]) }
  }, [state]);

  const SystemReportsResultAPIALLDATA = useCallback(async (type, ssss) => {
    setIsLoading(true);
    exportDataToFormats(type, `${GetParams('clientSegmentationyId') || 'ClientSegmentation'} ${DateNow.toString()}`, ssss);
    setIsLoading(false);
  }, []);

  const getItemdATA = (type) => { SystemReportsResultAPIALLDATA(type, reportDto); };

  const onExportClickedPDF = () => { getItemdATA('pdf'); };
  const onExportClicked = () => { getItemdATA('xls'); };
  const onExportClickedxml = () => { getItemdATA('xml'); };
  const onExportClickedjson = () => { getItemdATA('json'); };
  const onExportClickedcsv = () => { getItemdATA('csv'); };
  const [headerFooter, setHeaderFooter] = useState({
    imageHeaderId: 'd40968f9-09c3-4796-84ca-a798f4dba141',
    imageFooterId: 'f076cbc9-397e-43c4-a39d-a46a60b75a7a'
  });

  const getClientSegmentationyId = useCallback(async (id) => {
    const res = await GetClientSigmantationById(id);
    if (!(res && res.status && res.status !== 200))
      setClientSegmentationObj(res);
    else
      setClientSegmentationObj(null);
  }, []);

  useEffect(() => {
    getClientSegmentationyId(+GetParams('clientSegmentationId'));
  }, []);

  useEffect(() => {
    if (clientSegmentationObj) {
      if (clientSegmentationObj) {
        const checkValueIsString = !!((clientSegmentationObj && (clientSegmentationObj.fieldsJson === 'string' || clientSegmentationObj.conditionsJson === 'string')));

        const currentclientSegmentation = {
          clientSegmentationId: clientSegmentationObj && clientSegmentationObj.clientSegmentationId,
          conditions: !checkValueIsString ? (clientSegmentationObj && clientSegmentationObj.conditionsJson && JSON.parse(clientSegmentationObj.conditionsJson)) : [
            {
              category: null, categoryType: null, fieldName: null, operand: null, searchKey: null
            }],

          fields: !checkValueIsString ? (clientSegmentationObj && clientSegmentationObj.fieldsJson && JSON.parse(clientSegmentationObj.fieldsJson)) :
            [{
              name: 'Full Name (Contact)',
              displayPath: 'FullName',
              category: 'Contacts',
              searchKey: 'FullName',
              categoryType: 1
            },
            {
              name: 'Email (Contact)',
              displayPath: 'Email',
              category: 'Contacts',
              searchKey: 'Email',
              categoryType: 1
            },
            {
              name: 'Mobile (Contact)',
              displayPath: 'mobile.phone',
              category: 'Contacts',
              searchKey: 'mobile',
              categoryType: 1
            },
            {
              name: 'Created On (Contact)',
              displayPath: 'createdOn',
              category: 'Contacts',
              searchKey: 'createdOn',
              categoryType: 1
            },
            {
              name: 'Created By (Contact)',
              displayPath: 'createdBy.name',
              category: 'Contacts',
              searchKey: 'createdBy',
              categoryType: 1
            }],
          clientSegmantationName: clientSegmentationObj && clientSegmentationObj.clientSegmentationName,
          clientSegmentationFiles: !checkValueIsString ? clientSegmentationObj && clientSegmentationObj.clientSegmentationFiles : [],
          systemReportFilters: clientSegmentationObj.clientSegmentationFilters ? JSON.parse(clientSegmentationObj.clientSegmentationFilters) : null,

        };

        setState(currentclientSegmentation);
      }
    }
  }, [clientSegmentationObj]);

  useEffect(() => {
    if (clientSegmentationObj && state.clientSegmentationId)
      SystemReportsResultAPI();
  }, [state.systemReportFilters]);

  return (
    <div className='ReportRunView-Main'>
      <div className='navbar'>
        <div className='left'>
          <a onClick={() => GlobalHistory.push('/home')} className='logo'>
            <svg xmlns='http://www.w3.org/2000/svg' version='1.0' width='47' height='35' viewBox='0 0 1095.000000 969.000000' preserveAspectRatio='xMidYMid meet'>
              <metadata />
              <g transform='translate(0.000000,969.000000) scale(0.100000,-0.100000)' fill='#000000' stroke='none'>
                <path d='M0 9642 l0 -49 73 -36 c166 -82 216 -179 264 -512 36 -253 26 -5471 -11 -5755 -20 -152 -41 -250 -67 -311 -28 -64 -107 -136 -192 -174 l-66 -30 -1 -57 0 -58 856 0 855 0 -26 43 c-50 79 -133 262 -164 359 -38 122 -77 311 -91 447 -12 121 -13 956 -3 3768 l6 2002 86 20 c260 61 527 86 839 78 253 -6 367 -20 582 -73 331 -81 612 -232 811 -435 348 -354 458 -922 261 -1343 -46 -98 -83 -153 -164 -247 -32 -37 -58 -70 -58 -74 0 -9 181 -125 253 -161 132 -67 240 -97 368 -102 133 -5 195 5 286 49 196 94 336 329 401 674 26 139 23 427 -6 571 -68 344 -232 642 -482 877 -299 281 -651 435 -1205 526 -306 51 -322 51 -1915 51 l-1490 0 0 -48z' />
                <path d='M7040 7572 l0 -58 62 -29 c121 -57 197 -160 237 -318 45 -175 45 -159 46 -2927 0 -2463 1 -2629 17 -2688 64 -229 176 -405 343 -538 176 -142 408 -224 632 -224 l62 0 4 3048 c3 2206 7 3072 15 3137 38 296 109 430 272 507 l69 33 0 58 1 57 -880 0 -880 0 0 -58z' />
                <path d='M3950 6274 c-458 -66 -896 -279 -1201 -583 -198 -199 -335 -418 -419 -670 -157 -472 -123 -979 95 -1416 229 -460 566 -722 1735 -1350 817 -439 1260 -773 1414 -1065 82 -154 107 -368 66 -545 -51 -222 -205 -440 -415 -590 -36 -26 -65 -49 -65 -52 0 -13 312 7 424 27 515 89 859 397 959 860 28 129 29 374 3 510 -66 343 -234 636 -502 876 -253 227 -368 301 -1804 1161 -316 190 -600 363 -630 386 -30 22 -95 82 -145 132 -398 405 -474 895 -203 1311 239 366 738 569 1293 525 270 -22 497 -83 732 -196 394 -190 646 -439 802 -793 79 -178 161 -467 212 -744 l24 -128 113 0 112 0 0 548 c0 302 3 833 7 1180 l6 632 -116 0 -116 0 -6 -37 c-13 -76 -45 -169 -77 -223 -26 -42 -45 -61 -88 -83 -52 -28 -60 -29 -145 -24 -76 5 -147 22 -450 112 -720 212 -948 256 -1318 254 -113 -1 -244 -7 -297 -15z' />
                <path d='M9897 2315 c-151 -29 -295 -104 -404 -211 -155 -154 -233 -341 -233 -564 0 -309 163 -573 435 -705 124 -61 204 -79 350 -79 146 0 226 18 350 79 444 216 575 786 274 1187 -97 130 -272 245 -429 283 -96 23 -250 27 -343 10z' />
              </g>
            </svg>
          </a>
          <form action=''>
            <div className='search-box'>
              <input type='text' placeholder='Report  View' disabled />
              <span className='mdi mdi-format-color-highlight px-1 mdi-24px' />
            </div>
          </form>
        </div>
        <div className='middle'>
          <PermissionsComponent
            permissionsList={Object.values(ClientsSegmentationPermissions)}
            permissionsId={ClientsSegmentationPermissions.ExportClientSegmentationAsExcel.permissionsId}
          >
            <a
              onClick={onExportClicked}
              className='home'
            >
              <svg xmlns='http://www.w3.org/2000/svg' version='1.0' width='27' height='29' viewBox='0 0 200.000000 200.000000' preserveAspectRatio='xMidYMid meet'>

                <g transform='translate(0.000000,200.000000) scale(0.100000,-0.100000)' fill='#000000' stroke='none'>
                  <path d='M463 1873 c-23 -4 -23 -4 -23 -218 l0 -213 -79 -3 c-131 -6 -121 29 -121 -440 l0 -398 26 -20 c22 -17 41 -21 100 -21 l74 0 0 -214 0 -213 33 -7 c43 -9 1287 -7 1287 2 0 4 0 311 0 683 l1 676 -198 196 -197 196 -440 -1 c-243 0 -451 -3 -463 -5z m897 -213 l0 -190 190 0 190 0 0 -660 0 -660 -637 2 -638 3 0 200 0 200 303 5 304 5 19 24 c18 22 19 44 19 413 l0 389 -25 24 -24 25 -301 0 -300 0 0 198 c0 109 3 202 7 205 3 4 206 7 450 7 l443 0 0 -190z m195 0 l170 -170 -173 0 -172 0 0 170 c0 94 1 170 3 170 1 0 79 -76 172 -170z m-938 -478 c59 -107 54 -106 104 -19 l44 77 48 0 c26 0 47 -3 47 -6 0 -3 -29 -55 -65 -115 -36 -61 -65 -115 -65 -120 0 -5 29 -58 65 -119 89 -149 87 -142 30 -138 l-48 3 -47 79 c-25 43 -49 79 -53 80 -3 0 -28 -35 -54 -79 -46 -76 -50 -80 -87 -83 -21 -2 -42 0 -46 5 -5 4 23 60 61 123 37 63 69 120 69 127 0 7 -29 61 -65 122 -36 60 -65 111 -65 115 0 3 21 6 48 6 47 0 47 0 79 -58z' />
                  <path d='M1160 1165 l0 -45 105 0 105 0 0 45 0 45 -105 0 -105 0 0 -45z' />
                  <path d='M1420 1165 l0 -45 105 0 105 0 0 45 0 45 -105 0 -105 0 0 -45z' />
                  <path d='M1162 1023 l3 -48 103 -3 102 -3 0 51 0 50 -106 0 -105 0 3 -47z' />
                  <path d='M1420 1020 l0 -50 105 0 105 0 0 50 0 50 -105 0 -105 0 0 -50z' />
                  <path d='M1167 924 c-4 -4 -7 -27 -7 -51 l0 -43 106 0 105 0 -3 48 -3 47 -95 3 c-53 1 -99 0 -103 -4z' />
                  <path d='M1427 924 c-4 -4 -7 -27 -7 -51 l0 -43 106 0 105 0 -3 48 -3 47 -95 3 c-53 1 -99 0 -103 -4z' />
                </g>
              </svg>
            </a>
          </PermissionsComponent>
          <PermissionsComponent
            permissionsList={Object.values(ClientsSegmentationPermissions)}
            permissionsId={ClientsSegmentationPermissions.ExportClientSegmentationAsCSV.permissionsId}
          >
            <a onClick={onExportClickedcsv} className='market'>
              <svg xmlns='http://www.w3.org/2000/svg' width='33' height='28' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 548.29 548.291' xmlSpace='preserve'>
                <g>
                  <path d='M486.2,196.121h-13.164V132.59c0-0.399-0.064-0.795-0.116-1.2c-0.021-2.52-0.824-5-2.551-6.96L364.656,3.677   c-0.031-0.034-0.064-0.044-0.085-0.075c-0.629-0.707-1.364-1.292-2.141-1.796c-0.231-0.157-0.462-0.286-0.704-0.419   c-0.672-0.365-1.386-0.672-2.121-0.893c-0.199-0.052-0.377-0.134-0.576-0.188C358.229,0.118,357.4,0,356.562,0H96.757   C84.893,0,75.256,9.649,75.256,21.502v174.613H62.093c-16.972,0-30.733,13.756-30.733,30.73v159.81   c0,16.966,13.761,30.736,30.733,30.736h13.163V526.79c0,11.854,9.637,21.501,21.501,21.501h354.777   c11.853,0,21.502-9.647,21.502-21.501V417.392H486.2c16.966,0,30.729-13.764,30.729-30.731v-159.81   C516.93,209.872,503.166,196.121,486.2,196.121z M96.757,21.502h249.053v110.006c0,5.94,4.818,10.751,10.751,10.751h94.973v53.861   H96.757V21.502z M258.618,313.18c-26.68-9.291-44.063-24.053-44.063-47.389c0-27.404,22.861-48.368,60.733-48.368   c18.107,0,31.447,3.811,40.968,8.107l-8.09,29.3c-6.43-3.107-17.862-7.632-33.59-7.632c-15.717,0-23.339,7.149-23.339,15.485   c0,10.247,9.047,14.769,29.78,22.632c28.341,10.479,41.681,25.239,41.681,47.874c0,26.909-20.721,49.786-64.792,49.786   c-18.338,0-36.449-4.776-45.497-9.77l7.38-30.016c9.772,5.014,24.775,10.006,40.264,10.006c16.671,0,25.488-6.908,25.488-17.396   C285.536,325.789,277.909,320.078,258.618,313.18z M69.474,302.692c0-54.781,39.074-85.269,87.654-85.269   c18.822,0,33.113,3.811,39.549,7.149l-7.392,28.816c-7.38-3.084-17.632-5.939-30.491-5.939c-28.822,0-51.206,17.375-51.206,53.099   c0,32.158,19.051,52.4,51.456,52.4c10.947,0,23.097-2.378,30.241-5.238l5.483,28.346c-6.672,3.34-21.674,6.919-41.208,6.919   C98.06,382.976,69.474,348.424,69.474,302.692z M451.534,520.962H96.757v-103.57h354.777V520.962z M427.518,380.583h-42.399   l-51.45-160.536h39.787l19.526,67.894c5.479,19.046,10.479,37.386,14.299,57.397h0.709c4.048-19.298,9.045-38.352,14.526-56.693   l20.487-68.598h38.599L427.518,380.583z' />
                </g>
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
              </svg>
            </a>
          </PermissionsComponent>
          <PermissionsComponent
            permissionsList={Object.values(ClientsSegmentationPermissions)}
            permissionsId={ClientsSegmentationPermissions.ExportClientSegmentationAsPDF.permissionsId}
          >
            <a
              onClick={() => {
                printPartOfPage();
              }}
              className='tv'
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='27' height='29' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 58 58' xmlSpace='preserve'>
                <g>
                  <path d='M50.95,12.187l-0.771-0.771L40.084,1.321L39.313,0.55C38.964,0.201,38.48,0,37.985,0H8.963C7.777,0,6.5,0.916,6.5,2.926V39   v16.537V56c0,0.837,0.842,1.653,1.838,1.91c0.05,0.013,0.098,0.032,0.15,0.042C8.644,57.983,8.803,58,8.963,58h40.074   c0.16,0,0.319-0.017,0.475-0.048c0.052-0.01,0.1-0.029,0.15-0.042C50.658,57.653,51.5,56.837,51.5,56v-0.463V39V13.978   C51.5,13.211,51.408,12.645,50.95,12.187z M47.935,12H39.5V3.565L47.935,12z M8.963,56c-0.071,0-0.135-0.026-0.198-0.049   C8.609,55.877,8.5,55.721,8.5,55.537V41h41v14.537c0,0.184-0.109,0.339-0.265,0.414C49.172,55.974,49.108,56,49.037,56H8.963z    M8.5,39V2.926C8.5,2.709,8.533,2,8.963,2h28.595C37.525,2.126,37.5,2.256,37.5,2.391V14h11.609c0.135,0,0.264-0.025,0.39-0.058   c0,0.015,0.001,0.021,0.001,0.036V39H8.5z' />
                  <path d='M22.042,44.744c-0.333-0.273-0.709-0.479-1.128-0.615c-0.419-0.137-0.843-0.205-1.271-0.205h-2.898V54h1.641v-3.637h1.217   c0.528,0,1.012-0.077,1.449-0.232s0.811-0.374,1.121-0.656c0.31-0.282,0.551-0.631,0.725-1.046c0.173-0.415,0.26-0.877,0.26-1.388   c0-0.483-0.103-0.918-0.308-1.306S22.375,45.018,22.042,44.744z M21.42,48.073c-0.101,0.278-0.232,0.494-0.396,0.649   c-0.164,0.155-0.344,0.267-0.54,0.335c-0.196,0.068-0.395,0.103-0.595,0.103h-1.504v-3.992h1.23c0.419,0,0.756,0.066,1.012,0.198   c0.255,0.132,0.453,0.296,0.595,0.492c0.141,0.196,0.234,0.401,0.28,0.615c0.045,0.214,0.068,0.403,0.068,0.567   C21.57,47.451,21.52,47.795,21.42,48.073z' />
                  <path d='M31.954,45.4c-0.424-0.446-0.957-0.805-1.6-1.073s-1.388-0.403-2.235-0.403h-3.035V54h3.814   c0.127,0,0.323-0.016,0.588-0.048c0.264-0.032,0.556-0.104,0.875-0.219c0.319-0.114,0.649-0.285,0.991-0.513   s0.649-0.54,0.923-0.937s0.499-0.889,0.677-1.477s0.267-1.297,0.267-2.126c0-0.602-0.105-1.188-0.314-1.757   C32.694,46.355,32.378,45.847,31.954,45.4z M30.758,51.73c-0.492,0.711-1.294,1.066-2.406,1.066h-1.627v-7.629h0.957   c0.784,0,1.422,0.103,1.914,0.308s0.882,0.474,1.169,0.807s0.48,0.704,0.581,1.114c0.1,0.41,0.15,0.825,0.15,1.244   C31.496,49.989,31.25,51.02,30.758,51.73z' />
                  <polygon points='35.598,54 37.266,54 37.266,49.461 41.477,49.461 41.477,48.34 37.266,48.34 37.266,45.168 41.9,45.168    41.9,43.924 35.598,43.924  ' />
                  <path d='M38.428,22.961c-0.919,0-2.047,0.12-3.358,0.358c-1.83-1.942-3.74-4.778-5.088-7.562c1.337-5.629,0.668-6.426,0.373-6.802   c-0.314-0.4-0.757-1.049-1.261-1.049c-0.211,0-0.787,0.096-1.016,0.172c-0.576,0.192-0.886,0.636-1.134,1.215   c-0.707,1.653,0.263,4.471,1.261,6.643c-0.853,3.393-2.284,7.454-3.788,10.75c-3.79,1.736-5.803,3.441-5.985,5.068   c-0.066,0.592,0.074,1.461,1.115,2.242c0.285,0.213,0.619,0.326,0.967,0.326h0c0.875,0,1.759-0.67,2.782-2.107   c0.746-1.048,1.547-2.477,2.383-4.251c2.678-1.171,5.991-2.229,8.828-2.822c1.58,1.517,2.995,2.285,4.211,2.285   c0.896,0,1.664-0.412,2.22-1.191c0.579-0.811,0.711-1.537,0.39-2.16C40.943,23.327,39.994,22.961,38.428,22.961z M20.536,32.634   c-0.468-0.359-0.441-0.601-0.431-0.692c0.062-0.556,0.933-1.543,3.07-2.744C21.555,32.19,20.685,32.587,20.536,32.634z    M28.736,9.712c0.043-0.014,1.045,1.101,0.096,3.216C27.406,11.469,28.638,9.745,28.736,9.712z M26.669,25.738   c1.015-2.419,1.959-5.09,2.674-7.564c1.123,2.018,2.472,3.976,3.822,5.544C31.031,24.219,28.759,24.926,26.669,25.738z    M39.57,25.259C39.262,25.69,38.594,25.7,38.36,25.7c-0.533,0-0.732-0.317-1.547-0.944c0.672-0.086,1.306-0.108,1.811-0.108   c0.889,0,1.052,0.131,1.175,0.197C39.777,24.916,39.719,25.05,39.57,25.259z' />
                </g>
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
              </svg>
            </a>
          </PermissionsComponent>
          <PermissionsComponent
            permissionsList={Object.values(ClientsSegmentationPermissions)}
            permissionsId={ClientsSegmentationPermissions.ExportClientSegmentationAsJSON.permissionsId}
          >
            <a
              onClick={onExportClickedjson}
              className='groups'
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='27' height='29' id='Capa_1' x='0px' y='0px' viewBox='0 0 58 58'>
                <g>
                  <path d='M50.949,12.187l-1.361-1.361l-9.504-9.505c-0.001-0.001-0.001-0.001-0.002-0.001l-0.77-0.771   C38.957,0.195,38.486,0,37.985,0H8.963C7.776,0,6.5,0.916,6.5,2.926V39v16.537V56c0,0.837,0.841,1.652,1.836,1.909   c0.051,0.014,0.1,0.033,0.152,0.043C8.644,57.983,8.803,58,8.963,58h40.074c0.16,0,0.319-0.017,0.475-0.048   c0.052-0.01,0.101-0.029,0.152-0.043C50.659,57.652,51.5,56.837,51.5,56v-0.463V39V13.978C51.5,13.211,51.407,12.644,50.949,12.187   z M39.5,3.565L47.935,12H39.5V3.565z M8.963,56c-0.071,0-0.135-0.025-0.198-0.049C8.61,55.877,8.5,55.721,8.5,55.537V41h41v14.537   c0,0.184-0.11,0.34-0.265,0.414C49.172,55.975,49.108,56,49.037,56H8.963z M8.5,39V2.926C8.5,2.709,8.533,2,8.963,2h28.595   C37.525,2.126,37.5,2.256,37.5,2.391V13.78c-0.532-0.48-1.229-0.78-2-0.78c-0.553,0-1,0.448-1,1s0.447,1,1,1c0.552,0,1,0.449,1,1v4   c0,1.2,0.542,2.266,1.382,3c-0.84,0.734-1.382,1.8-1.382,3v4c0,0.551-0.448,1-1,1c-0.553,0-1,0.448-1,1s0.447,1,1,1   c1.654,0,3-1.346,3-3v-4c0-1.103,0.897-2,2-2c0.553,0,1-0.448,1-1s-0.447-1-1-1c-1.103,0-2-0.897-2-2v-4   c0-0.771-0.301-1.468-0.78-2h11.389c0.135,0,0.265-0.025,0.391-0.058c0,0.015,0.001,0.021,0.001,0.036V39H8.5z' />
                  <path d='M16.354,51.43c-0.019,0.446-0.171,0.764-0.458,0.95s-0.672,0.28-1.155,0.28c-0.191,0-0.396-0.022-0.615-0.068   s-0.429-0.098-0.629-0.157s-0.385-0.123-0.554-0.191s-0.299-0.135-0.39-0.198l-0.697,1.107c0.183,0.137,0.405,0.26,0.67,0.369   s0.54,0.207,0.827,0.294s0.565,0.15,0.834,0.191s0.504,0.062,0.704,0.062c0.401,0,0.791-0.039,1.169-0.116   c0.378-0.077,0.713-0.214,1.005-0.41s0.524-0.456,0.697-0.779s0.26-0.723,0.26-1.196v-7.848h-1.668V51.43z' />
                  <path d='M25.083,49.064c-0.314-0.228-0.654-0.422-1.019-0.581s-0.702-0.323-1.012-0.492s-0.569-0.364-0.779-0.588   s-0.314-0.518-0.314-0.882c0-0.146,0.036-0.299,0.109-0.458s0.173-0.303,0.301-0.431s0.273-0.234,0.438-0.321   s0.337-0.139,0.52-0.157c0.328-0.027,0.597-0.032,0.807-0.014s0.378,0.05,0.506,0.096s0.226,0.091,0.294,0.137   s0.13,0.082,0.185,0.109c0.009-0.009,0.036-0.055,0.082-0.137s0.101-0.185,0.164-0.308s0.132-0.255,0.205-0.396   s0.137-0.271,0.191-0.39c-0.265-0.173-0.61-0.299-1.039-0.376s-0.853-0.116-1.271-0.116c-0.41,0-0.8,0.063-1.169,0.191   s-0.692,0.313-0.971,0.554s-0.499,0.535-0.663,0.882S20.4,46.13,20.4,46.576c0,0.492,0.104,0.902,0.314,1.23   s0.474,0.613,0.793,0.854s0.661,0.451,1.025,0.629s0.704,0.355,1.019,0.533s0.576,0.376,0.786,0.595s0.314,0.483,0.314,0.793   c0,0.511-0.148,0.896-0.444,1.155s-0.723,0.39-1.278,0.39c-0.183,0-0.378-0.019-0.588-0.055s-0.419-0.084-0.629-0.144   s-0.412-0.123-0.608-0.191s-0.357-0.139-0.485-0.212l-0.287,1.176c0.155,0.137,0.34,0.253,0.554,0.349s0.439,0.171,0.677,0.226   c0.237,0.055,0.472,0.094,0.704,0.116s0.458,0.034,0.677,0.034c0.511,0,0.966-0.077,1.367-0.232s0.738-0.362,1.012-0.622   s0.485-0.561,0.636-0.902s0.226-0.695,0.226-1.06c0-0.538-0.104-0.978-0.314-1.319S25.397,49.292,25.083,49.064z' />
                  <path d='M34.872,45.072c-0.378-0.429-0.82-0.754-1.326-0.978s-1.06-0.335-1.661-0.335s-1.155,0.111-1.661,0.335   s-0.948,0.549-1.326,0.978s-0.675,0.964-0.889,1.606s-0.321,1.388-0.321,2.235s0.107,1.595,0.321,2.242s0.511,1.185,0.889,1.613   s0.82,0.752,1.326,0.971s1.06,0.328,1.661,0.328s1.155-0.109,1.661-0.328s0.948-0.542,1.326-0.971s0.675-0.966,0.889-1.613   s0.321-1.395,0.321-2.242s-0.107-1.593-0.321-2.235S35.25,45.501,34.872,45.072z M34.195,50.698   c-0.137,0.487-0.326,0.882-0.567,1.183s-0.515,0.518-0.82,0.649s-0.627,0.198-0.964,0.198c-0.328,0-0.641-0.07-0.937-0.212   s-0.561-0.364-0.793-0.67s-0.415-0.699-0.547-1.183s-0.203-1.066-0.212-1.75c0.009-0.702,0.082-1.294,0.219-1.777   c0.137-0.483,0.326-0.877,0.567-1.183s0.515-0.521,0.82-0.649s0.627-0.191,0.964-0.191c0.328,0,0.641,0.068,0.937,0.205   s0.561,0.36,0.793,0.67s0.415,0.704,0.547,1.183s0.203,1.06,0.212,1.743C34.405,49.616,34.332,50.211,34.195,50.698z' />
                  <polygon points='44.012,50.869 40.061,43.924 38.393,43.924 38.393,54 40.061,54 40.061,47.055 44.012,54 45.68,54 45.68,43.924    44.012,43.924  ' />
                  <path d='M20.5,20v-4c0-0.551,0.448-1,1-1c0.553,0,1-0.448,1-1s-0.447-1-1-1c-1.654,0-3,1.346-3,3v4c0,1.103-0.897,2-2,2   c-0.553,0-1,0.448-1,1s0.447,1,1,1c1.103,0,2,0.897,2,2v4c0,1.654,1.346,3,3,3c0.553,0,1-0.448,1-1s-0.447-1-1-1   c-0.552,0-1-0.449-1-1v-4c0-1.2-0.542-2.266-1.382-3C19.958,22.266,20.5,21.2,20.5,20z' />
                  <circle cx='28.5' cy='19.5' r='1.5' />
                  <path d='M28.5,25c-0.553,0-1,0.448-1,1v3c0,0.552,0.447,1,1,1s1-0.448,1-1v-3C29.5,25.448,29.053,25,28.5,25z' />
                </g>
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
              </svg>
            </a>
          </PermissionsComponent>
          <PermissionsComponent
            permissionsList={Object.values(ClientsSegmentationPermissions)}
            permissionsId={ClientsSegmentationPermissions.ExportClientSegmentationAsXML.permissionsId}
          >
            <a onClick={onExportClickedxml} className='games'>
              <svg xmlns='http://www.w3.org/2000/svg' width='27' height='29' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
                <g>
                  <g>
                    <path d='M501.333,384h-32v-74.667c0-5.888-4.779-10.667-10.667-10.667S448,303.445,448,309.333v85.333    c0,5.888,4.779,10.667,10.667,10.667h42.667c5.888,0,10.667-4.779,10.667-10.667S507.221,384,501.333,384z' />
                  </g>
                </g>
                <g>
                  <g>
                    <path d='M419.371,299.221c-4.352-1.472-9.152,0.043-11.904,3.712L384,334.208l-23.467-31.275    c-2.752-3.669-7.509-5.184-11.904-3.712c-4.352,1.451-7.296,5.525-7.296,10.112v85.333c0,5.888,4.779,10.667,10.667,10.667    c5.888,0,10.667-4.779,10.667-10.667v-53.333l12.8,17.067c4.032,5.376,13.056,5.376,17.067,0l12.8-17.067v53.333    c0,5.888,4.779,10.667,10.667,10.667c5.888,0,10.667-4.779,10.667-10.667v-85.333    C426.667,304.747,423.723,300.672,419.371,299.221z' />
                  </g>
                </g>
                <g>
                  <g>
                    <path d='M290.667,352.021l27.221-36.288c3.541-4.715,2.581-11.392-2.133-14.933c-4.715-3.52-11.371-2.581-14.933,2.133    l-23.467,31.296l-23.488-31.296c-3.563-4.736-10.219-5.696-14.933-2.133c-4.715,3.541-5.675,10.219-2.133,14.933l27.221,36.288    l-27.2,36.245c-3.541,4.715-2.581,11.392,2.133,14.933c1.92,1.429,4.16,2.133,6.379,2.133c3.243,0,6.443-1.472,8.555-4.267    l23.467-31.275l23.445,31.275c2.091,2.795,5.291,4.267,8.533,4.267c2.24,0,4.48-0.704,6.4-2.133    c4.715-3.541,5.675-10.219,2.133-14.933L290.667,352.021z' />
                  </g>
                </g>
                <g>
                  <g>
                    <path d='M373.333,277.333c5.888,0,10.667-4.779,10.667-10.667v-256C384,4.779,379.221,0,373.333,0H138.667    c-1.429,0-2.816,0.299-4.117,0.832c-0.619,0.256-1.088,0.725-1.643,1.088c-0.597,0.384-1.28,0.683-1.792,1.195l-128,128    c-0.491,0.491-0.768,1.152-1.152,1.728c-0.384,0.576-0.875,1.067-1.131,1.707C0.299,135.851,0,137.237,0,138.667v362.667    C0,507.221,4.779,512,10.667,512h362.667c5.888,0,10.667-4.779,10.667-10.667v-64c0-5.888-4.779-10.667-10.667-10.667    c-5.888,0-10.667,4.779-10.667,10.667v53.333H21.333V149.333h117.333c5.888,0,10.667-4.779,10.667-10.667V21.333h213.333v245.333    C362.667,272.555,367.445,277.333,373.333,277.333z M128,128H36.416L128,36.416V128z' />
                  </g>
                </g>
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
              </svg>
            </a>
          </PermissionsComponent>
        </div>

      </div>

      <div className='ReportPdfView-PAGE' />
      <div className='unit-card-view'>
        <CellsSpinner isActive={isLoading} isAbsolute />
        <div>
          <div className='users-card-component-wrapper' id='divcontents'>
            <header className='header'>
              <img alt='HeaderPSITemplet' src={'https://static.psi-crm.com/header.png' || null} style={{ width: '100%', maxHeight }} />
            </header>
            <style>
              {mediaprint}
            </style>
            <Tables
              data={reportDto || []}
              headerData={
                reportSchema || []
              }
              defaultActions={[]}
              activePage={1}
              itemsPerPage={100000}
              totalItems={(reportDto && reportDto.length) || 0}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
        </div>
      </div>
      <footer>
        <img alt='FooterPSITemplet' src={'https://static.psi-crm.com/footer.png' || null} style={{ width: '100%', maxHeight }} />
      </footer>
    </div>
  );
};
