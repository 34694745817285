import React, {
  useState, useEffect, useCallback, useRef
} from 'react';
import './ContactProfileActivitiesComponentStyle.scss';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Inputs, Spinner, Tables, AutocompleteComponent
} from '../../../../../../Components';
import {CopyToClipboardComponents} from '../../../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';

import { useTitle } from '../../../../../../Hooks';
import { TableActions, ActionsEnum, LeadsClassTypesEnum, LeadTypeIdEnum } from '../../../../../../Enums';
import { ActivitiesManagementDialog } from '../../../../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog';
import { GetAllActivities, GetAllLeadsByContactId, OrganizationUserSearch, GetActivityById } from '../../../../../../Services';
import { ActivityDeleteDialog } from './ActivitiesViewUtilities';
import { GetParams } from '../../../../../../Helper';
import { ReplyActivityDialog } from '../../../../ActivitiesView/ReplyActivitesView/ReplyActivityDialog';

export const ContactProfileActivitiesComponent = ({
  parentTranslationPath,
  translationPath,
  contactWithPolicy,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const location = useLocation();
  const contactId = +GetParams("id");

  const [isLoadings, setIsLoadings] = useState({
    allAgentsLoading: false,
    allLeadsLoading: false,
    allActivitiesLoading: false,
  });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [searchedItem, setSearchedItem] = useState('');
  const [agentName, setAgentName] = useState('');
  const searchTimer = useRef(null);
  const [isReplay, setIsReplay] = useState(false);
  const [activities, setActivities] = useState({
    result: [],
    totalCount: 0,
  });
  const [contactLeadsList, setContactLeadsList] = useState({
    result: [],
    totalCount: 0,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [filter, setFilter] = useState({
    contactId: +GetParams('id'),
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
    search: '',
    leadId: null,
    orderBy: 1,
    filterBy: 'ActivityDate',
    userId: ''
  });
  const [sortBy, setSortBy] = useState(null);
  const [openReplyActivityDialog, setOpenReplyActivityDialog] = useState(false);

  useTitle(t(`${translationPath}activities`));


  const detailedCardSideActionClicked = useCallback(
    (actionEnum, activeData, type) => async (event) => {
      event.stopPropagation();
      if (actionEnum === ActionsEnum.folder.key) {
        if (activeData.relatedLeadNumberId && type == 'lead') {
          const leadForm = activeData && (activeData.leadClass === LeadsClassTypesEnum.buyer.key || activeData.leadClass === LeadsClassTypesEnum.tenant.key) ? LeadTypeIdEnum.Seeker.leadTypeId : LeadTypeIdEnum.Owner.leadTypeId;
          const leadOprationType = activeData && (activeData.leadClass === LeadsClassTypesEnum.buyer.key || activeData.leadClass === LeadsClassTypesEnum.seller.key) ? 'Sale' : 'Lease';
          if (leadOprationType === 'Sale')
            window.open(`/home/lead-sales/lead-profile-edit?formType=${leadForm}&id=${activeData.relatedLeadNumberId}`, '_blank').focus();

          else
            window.open(`/home/lead-lease/lead-profile-edit?formType=${leadForm}&id=${activeData.relatedLeadNumberId}`, '_blank').focus();
        }
      }
    }, []);

  const getActivityAPI = useCallback(async (activityId) => {
    const res = await GetActivityById(activityId);
    if (!(res && res.status && res.status !== 200)) setActiveItem(res);
  }, []);

  const getAllActivities = useCallback(async () => {
    setIsLoadings((loading) => ({ ...loading, allActivitiesLoading: true }));
    const res = await GetAllActivities({
      contactId: filter.contactId,
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex + 1,
      orderBy: filter.orderBy,
      isWithoutRelatedActivities: false,
      filterBy: filter.filterBy,
      search: filter.search || '',
      guidAgentId: filter.userId,
      relatedLeadId: filter.leadId,
    })
    if (!(res && res.status && res.status !== 200)) {
      setActivities({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setActivities({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoadings((loading) => ({ ...loading, allActivitiesLoading: false }));
  }, [filter]);

  const getAllLeadsRelateWithContactId = useCallback(async () => {
    setIsLoadings((loading) => ({ ...loading, allLeadsLoading: true }));
    const res = await GetAllLeadsByContactId({ pageIndex: 0, pageSize: 1000 }, filter.contactId);
    if (!(res && res.status && res.status !== 200)) {
      setContactLeadsList({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setContactLeadsList({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoadings((loading) => ({ ...loading, allLeadsLoading: false }));
  }, [filter]);


  useEffect(() => {
    getAllActivities();
    getAllLeadsRelateWithContactId();
  }, [getAllActivities, getAllLeadsRelateWithContactId]);
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      if (actionEnum === TableActions.deleteText.key) {
        setActiveItem(item);
        setOpenConfirmDialog(true);
      } else if (actionEnum === TableActions.editText.key) {
        // setActiveItem(item);
        getActivityAPI(item.activityId);
        setOpenDialog(true);
      } else if (actionEnum === TableActions.replyText.key) {
        setActiveItem(item);
        setOpenReplyActivityDialog(true);
      }
    }, [getActivityAPI]);

  const focusedRowChanged = (activeRow) => {
    const item = activities.result[activeRow];
    if (!item) return;
    setIsReplay(item.isReplyAble);
  };
  const getTableActionsWithPermissions = () => {
    if (isReplay)
      return ([{ enum: TableActions.editText.key }, { enum: TableActions.replyText.key, isDisabled: false }]);

    return ([{ enum: TableActions.editText.key }]);
  };

  const getAllAgents = useCallback(async (searchValue) => {
    setIsLoadings((loading) => ({ ...loading, allAgentsLoading: true }));
    const res = await OrganizationUserSearch({ pageIndex: 0, pageSize: 10, name: searchValue });
    if (!(res && res.status && res.status !== 200)) {
      setAgentName({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setAgentName({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoadings((loading) => ({ ...loading, allAgentsLoading: false }));
  }, []);
  const searchHandler = (event) => {
    const { value } = event.target;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({ ...item, search: value }));
    }, 700);
  };

  const addNewHandler = () => {
    setOpenDialog(true);
  };

  useEffect(() => {
    if (sortBy)
      setFilter((item) => ({ ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy }));
  }, [sortBy]);

  useEffect(() => {
    getAllAgents();
  }, [getAllAgents]);

  useEffect(() => {
    setFilter((prevState) => ({
      ...prevState,
      contactId: +GetParams('id'),
    }));
  }, [location]);
  return (
    <div className='view-wrapper activities-view-wrapper'>
      <Spinner isActive={isLoadings.allActivitiesLoading} />
      <div className='d-flex-column'>
        <div className='header-section-Contact-Activity'>
          <div className='filter-section px-2 content'>
            <div className='sectionx'>
              <ButtonBase
              className='btns theme-solid px-3' onClick={addNewHandler}>
                <span className='mdi mdi-plus' />
                {t(`${translationPath}add-new`)}
              </ButtonBase>
            </div>
            <div className='fieldsSearchInContactActivity'>

              <div className='d-flex-v-center-h-between pl-5-reverse'>
                <Inputs
                  value={searchedItem}
                  onKeyUp={searchHandler}
                  idRef='activitiesSearchRef'
                  label={t(`${translationPath}search-activity`)}
                  onInputChanged={(e) => setSearchedItem(e.target.value)}
                  inputPlaceholder={t(`${translationPath}search-activity`)}
                  beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                />

              </div>
              <div className='w-100 p-relative'>
                <AutocompleteComponent
                  idRef='agentNameRef'
                  inputPlaceholder={t(`${translationPath}agent-name`)}
                  data={(agentName && agentName.result) || []}
                  multiple={false}
                  displayLabel={(option) =>
                    (option && option.fullName) || ''}
                  chipsLabel={(option) => (option && option.fullName) || ''}
                  withoutSearchButton
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    setFilter((item) => ({ ...item, userId: (newValue && newValue.id) }));
                  }}
                  onInputKeyUp={(e) => {
                    const { value } = e.target;
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      getAllAgents(value);
                    }, 500);
                  }}
                  isLoading={isLoadings.allAgentsLoading}
                />

              </div>
              <div className='w-100 p-relative'>
                <AutocompleteComponent
                  idRef='leadIdRef'
                  multiple={false}
                  inputPlaceholder={t(`${translationPath}Lead`)}
                  data={(contactLeadsList && contactLeadsList.result) || []}
                  displayLabel={(option) =>
                    (option && `${option.lead?.contact_name?.name} -${option.lead.leadClass} (${option.leadId})`) || ''}
                  withoutSearchButton
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    setFilter((item) => ({ ...item, leadId: newValue && newValue.leadId }));
                  }}
                  isLoading={isLoadings.allLeadsLoading}

                />
              </div>
            </div>
          </div>
        </div>

        <div className='w-100 px-3'>
          <Tables
            data={activities.result}
            headerData={[
              {
                id: 1,
                isSortable: true,
                label: 'date',
                input: 'activityDate',
                isDate: true,
              },
              {
                id: 2,
                isSortable: true,
                label: 'created-on',
                input: 'createdOn',
                isDate: true,
                dateFormat: 'DD/MM/YYYY',
              },
              {
                id: 3,
                isSortable: true,
                label: 'created-time',
                input: 'createdOn',
                isDate: true,
                dateFormat: ' hh:mm A',
              },
              // {
              //   id: 12,
              //   label: 'Size Area From',
              //   input: 'sizeAreaFrom',
              //   fieldKey: 'sizeAreaFrom',
              //   isDefaultFilterColumn: false,
              //   isHiddenFilter: false,
              // },
              {
                id: 4,
                isSortable: true,
                label: 'created-By-Name',
                input: 'createdByName',
              },
              {
                id: 5,
                label: 'related-to',
                component: (item) => (
                  <span className='c-primary'>
                    {(item.relatedLeadNumberId &&
                      t(`${translationPath}lead`)) ||
                      (item.relatedContactNumberId &&
                        t(`${translationPath}contact`)) ||
                      (item.relatedMaintenanceContractId &&
                        t(`${translationPath}MaintenanceContract`)) ||
                      (item.relatedUnitNumberId &&
                        t(`${translationPath}unit`)) ||
                      (item.relatedPortfolioName &&
                        t(`${translationPath}Portfolio`)) ||
                      (item.relatedPortfolioId &&
                        t(`${translationPath}Portfolio`)) ||
                      (item.relatedWorkOrderRefNumber &&
                        t(`${translationPath}WorkOrder`)) ||
                      (item.relatedUnitPropertyName &&
                        t(`${translationPath}Property`)) ||
                      (item.relatedWorkOrderId &&
                        t(`${translationPath}WorkOrder`)) ||
                      'N/A'}
                  </span>
                ),
                input: 'RelatedTo'
              },
              {
                id: 6,
                label: 'related-to-number',
                component: (item) => (item && (item.relatedLeadNumberId || item.relatedContactNumberId) && (
                  <a className='c-primary newTabActivites' onClick={detailedCardSideActionClicked(ActionsEnum.folder.key, item, 'lead')}>
                    {(
                      item && (item.relatedLeadNumberId || item.relatedContactNumberId) && (
                        <CopyToClipboardComponents
                          data={item.relatedLeadNumberId || item.relatedContactNumberId}
                          childrenData={item.relatedLeadNumberId || item.relatedContactNumberId}
                          CustomizationClassName='block-container'
                        />
                      )) ||
                      'N/A'}

                  </a>

                )),
              },
              {
                id: 7,
                label: 'related-to-name',
                component: (item) => (
                  <span className='c-primary'>
                    {(item.relatedLeadNumberId && item.relatedLeadFullName) ||
                      (item.relatedContactNumberId && item.relatedContactFullName) ||
                      (item.relatedUnitNumberId && item.relatedUnitName) ||
                      (item.relatedUnitPropertyName && item.relatedUnitNumber) ||
                      (item.relatedPortfolioId && item.relatedPortfolioName) ||
                      (item.relatedWorkOrderId && item.relatedWorkOrderRefNumber) ||
                      item.relatedMaintenanceContractId ||
                      'N/A'}
                  </span>
                )
              },
              {
                id: 8,
                isSortable: true,
                label: 'status',
                input: 'isOpen',
                component: (item, index) => (
                  (item.isOpen && <div className='globel-open-style' index={index}>{t(`${translationPath}open`)}</div>) || <div className='globel-closed-style' index={index}>{t(`${translationPath}closed`)}</div>
                ),
              },
              {
                id: 9,
                label: 'assigned-to',
                input: 'assignedTo',
                isSortable: true,
              },
              {
                id: 10,
                isSortable: true,
                label: 'contact-name',
                input: 'relatedContactFullName',
              },
              {
                id: 11,
                isSortable: true,
                label: 'stage',
                input: 'LeadStageName',
                component: (item) => (
                  <span>
                    {item.leadStage ||
                      t(`${translationPath}not-contacted`)}
                  </span>
                ),
              },
              {
                id: 12,
                isSortable: true,
                label: 'category',
                input: 'CategoryName',
                component: (item) => (
                  <span>
                    {item.categoryName ||
                      t(`${translationPath}not-contacted`)}
                  </span>
                ),
              },
              {
                id: 13,
                isSortable: true,
                label: 'activity-type',
                input: 'ActivityTypeName',
                component: (item) => (
                  <span>
                    {item.activityTypeName ||
                      t(`${translationPath}not-contacted`)}
                  </span>
                ),
              },
              {
                id: 14,
                isSortable: true,
                label: 'subject',
                input: 'subject',
              },
              {
                id: 15,
                isSortable: true,
                label: 'copy-to',
                input: 'copyTo',
              }, {
                id: 16,
                label: 'comments',
                input: 'comments',
                isDefaultFilterColumn: true,
                isSortable: true,
              },
            ]}
            // defaultActions={[
            //   {
            //     enum: TableActions.editText.key,
            //   },
            //   {
            //     enum: TableActions.replyText.key,
            //   },
            // ]}
            defaultActions={getTableActionsWithPermissions()}
            focusedRowChanged={focusedRowChanged}
            setSortBy={setSortBy}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            totalItems={activities.totalCount}
          />
        </div>
      </div>
      {
        openDialog && (
          <ActivitiesManagementDialog
            open={openDialog}
            activeItem={activeItem}
            onSave={() => {
              setOpenDialog(false);
              onPageIndexChanged(0);
              setActiveItem(null);
            }}
            contactId={+GetParams('id')}
            contactClassificationId={contactWithPolicy?.contact?.contact_class?.lookupItemId}
            close={() => {
              setOpenDialog(false);
              setActiveItem(null);
            }}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        )
      }
      {
        openConfirmDialog && (
          <ActivityDeleteDialog
            isOpen={openConfirmDialog}
            activeItem={activeItem}
            reloadData={() => {
              setOpenDialog(false);
              onPageIndexChanged(0);
              getAllActivities();
            }}
            isOpenChanged={() => setOpenDialog(false)}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        )
      }

      {openReplyActivityDialog && (
        <ReplyActivityDialog
          open={openReplyActivityDialog}
          close={() => {
            setActiveItem(null);
            setOpenReplyActivityDialog(false);
          }}
          activeItem={activeItem}
          onSave={() => {
            setOpenReplyActivityDialog(false);
            setActiveItem(null);
            onPageIndexChanged(0);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
};

ContactProfileActivitiesComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
