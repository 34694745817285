
import React, { useState , useCallback   , useEffect , useReducer}  from 'react';
import { useTranslation } from 'react-i18next';
import { GetAllGeneralPropertyCriteriaScores } from '../../../../../Services' ; 
import { OwnershipCriteria ,
         PropertyAgeCriteria , 
         ProjectUsageCriteria ,
         ServiceChargeCriteria , 
         HoursSecurityCriteria,
         NeighborhoodCriteria ,
         SelectionOfRetailsCriteria , 
         TowerEntranceVenisonCriteria ,
         TwentyFourReception , 
         Elevators 
    } from  './GeneralPropertyCriteriaSections'; 


  export const GeneralPropertyCriteriaContent = ({
    parentTranslationPath ,
    translationPath ,
    state ,
    setState ,
   } ) => { 

    const { t } = useTranslation(parentTranslationPath);

    return (
      <>
        <div className="general-property-criteria b-bottom mb-2 mt-2">
            <OwnershipCriteria
             parentTranslationPath={parentTranslationPath}
             translationPath={translationPath}
             state={state}
             setState={setState}
            />
        </div> 
        <div className="general-property-criteria b-bottom mb-2 mt-2">
            <ProjectUsageCriteria
             parentTranslationPath={parentTranslationPath}
             translationPath={translationPath}
             state={state}
             setState={setState}
            />
        </div> 
       <div className="general-property-criteria b-bottom mb-2 mt-2">
            <PropertyAgeCriteria
             parentTranslationPath={parentTranslationPath}
             translationPath={translationPath}
             state={state}
             setState={setState}
            />
        </div>  
        <div className="general-property-criteria b-bottom mb-2 mt-2">
            <ServiceChargeCriteria
             parentTranslationPath={parentTranslationPath}
             translationPath={translationPath}
             state={state}
             setState={setState}
            />
        </div> 
        <div className="general-property-criteria b-bottom mb-2 mt-2">
            <HoursSecurityCriteria
             parentTranslationPath={parentTranslationPath}
             translationPath={translationPath}
             state={state}
             setState={setState}
            />
        </div> 
        <div className="general-property-criteria b-bottom mb-2 mt-2">
            <TwentyFourReception
             parentTranslationPath={parentTranslationPath}
             translationPath={translationPath}
             state={state}
             setState={setState}
            />
        </div> 
        <div className="general-property-criteria b-bottom mb-2 mt-2">
            <NeighborhoodCriteria
             parentTranslationPath={parentTranslationPath}
             translationPath={translationPath}
             state={state}
             setState={setState}
            />
        </div> 
        <div className="general-property-criteria b-bottom mb-2 mt-2">
            <SelectionOfRetailsCriteria
             parentTranslationPath={parentTranslationPath}
             translationPath={translationPath}
             state={state}
             setState={setState}
            />
        </div> 
        <div className="general-property-criteria b-bottom mb-2 mt-2">
            <TowerEntranceVenisonCriteria
             parentTranslationPath={parentTranslationPath}
             translationPath={translationPath}
             state={state}
             setState={setState}
            />
        </div>
        <div className="general-property-criteria b-bottom mb-2 mt-2">
            <Elevators
             parentTranslationPath={parentTranslationPath}
             translationPath={translationPath}
             state={state}
             setState={setState}
            />
        </div>
      </>
    );
  } ; 
  