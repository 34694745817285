import React from "react";

function ChevronLeftDouble({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M10.66 6.066c-.186.056-.381.241-2.813 2.667-1.476 1.473-2.659 2.685-2.72 2.787-.091.153-.107.225-.106.48 0 .212.022.341.074.44.097.185 5.197 5.297 5.413 5.425.459.274 1.079.103 1.359-.373.097-.165.113-.234.112-.492 0-.212-.022-.341-.074-.44-.04-.077-1.066-1.135-2.279-2.35L7.421 12l2.205-2.21c1.213-1.216 2.239-2.273 2.279-2.35.052-.099.074-.228.074-.44.001-.255-.015-.328-.107-.484a1.02 1.02 0 0 0-.582-.461c-.228-.069-.375-.066-.63.011m7 0c-.186.056-.381.241-2.813 2.667-1.476 1.473-2.659 2.685-2.72 2.787-.091.153-.107.225-.106.48 0 .212.022.341.074.44.097.185 5.197 5.297 5.413 5.425.459.274 1.079.103 1.359-.373.097-.165.113-.234.112-.492 0-.212-.022-.341-.074-.44-.04-.077-1.066-1.135-2.279-2.35L14.421 12l2.205-2.21c1.213-1.216 2.239-2.273 2.279-2.35.052-.099.074-.228.074-.44.001-.255-.015-.328-.107-.484a1.02 1.02 0 0 0-.582-.461c-.228-.069-.375-.066-.63.011"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default ChevronLeftDouble;
