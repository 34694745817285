import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./WorkingHoursConfirmDialog.scss";
import {
  ButtonBase,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import xCloseIcon from "../../assets/images/icons/x-close-dialog.svg";
import { CheckIsCommunicateAllowed } from "../../Helper";
import { ConsentActionsDialog } from "../ConsentActionsDialog/ConsentActionsDialog";

export const WorkingHoursConfirmDialog = ({
  onClose,
  open,
  activeRecipient,
  recipientType,
  communicationActionHandler,
}) => {
  const parentTranslationPath = "Shared";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);
  const [isConsentActionDialogOpen, setIsConsentActionDialogOpen] = useState(false);

  const proceedCommunicateHandler = async () => {
    const isCommunicateAllowed = await CheckIsCommunicateAllowed({
      contactId: recipientType == "Contact" ? activeRecipient?.id : null,
      leadId: recipientType == "Lead" ? activeRecipient?.id : null,
    });
    if (isCommunicateAllowed) communicationActionHandler();
    else {
      setIsConsentActionDialogOpen(true);
      return;
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          onClose();
        }}
        className="WorkingHoursConfirm_Dialog"
      >
        <DialogTitle>
          <div className="w-100">
            <div className="d-flex-v-center-h-between py-2">
              <div className="fw-simi-bold">
                {t(`${translationPath}Outside-Working-Hours-Alert`)}
              </div>
              <div className="xCloseIcon pointer" onClick={onClose}>
                <img src={xCloseIcon} />
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="Dialog_Content d-flex-column-center">
            <p className="mt-4 fw-simi-bold fz-14 c-black-light">
              This call is outside of working hours{" "}
              <span className="pt-3 fw-bold">(9:00 AM – 6:00 PM) .</span>
            </p>
            <span className="fz-14 mb-4 fw-bold c-black-light" >What would you like to do?</span>
            <div className="d-flex-column-center gap-16 w-100 mb-5 pb-3">
              <ButtonBase
                className={"btns theme-propx solid fz-12 px-4 py-3 w-60"}
                onClick={proceedCommunicateHandler}
              >
                <span className="mdi mdi-alert  mx-2"></span>
                <span>{t(`${translationPath}Proceed-with-Communication`)}</span>
              </ButtonBase>
              <ButtonBase
                className={"btns theme-propx outlined-colored fz-12 px-4 py-3 w-60"}
                onClick={onClose}
              >
                <span>{t(`${translationPath}cancel`)}</span>
              </ButtonBase>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {isConsentActionDialogOpen &&
        <ConsentActionsDialog
          open={isConsentActionDialogOpen}
          communicationActionHandler={communicationActionHandler}
          onClose={() => {
            setIsConsentActionDialogOpen(false);
            onClose();
          }}
          activeRecipient={activeRecipient}
          recipientType={recipientType}
        />
      }
    </div>
  );
};
