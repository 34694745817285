import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import { PaginationComponent, Spinner, Tables, ViewTypes, TabsComponent } from '../../../../../../Components';
import { ActionsEnum, TableActions, ViewTypesEnum } from '../../../../../../Enums';
import { UnitsCardsComponent } from './../../../../UnitsLeaseView/UnitsLeaseUtilities/UnitsCardsComponent/UnitsCardsComponent.jsx';
import {
  UnitsAdvanceSearchTest,
} from '../../../../../../Services';
import { bottomBoxComponentUpdate, GetParams, GlobalHistory } from '../../../../../../Helper';
import { ActiveItemActions } from '../../../../../../store/ActiveItem/ActiveItemActions';
import { UnitMapper } from './../../../../UnitsLeaseView/UnitLeaseMapper/UnitMapper.jsx';

export const ContactUnitsLease = ({ 
  operationType,
  viewType,
  onTypeChanged,
  parentTranslationPath,
   translationPath }) => {
  const { t } = useTranslation(parentTranslationPath);
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [contactId, setContactId] = useState(+GetParams('id'));
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
  });
  const [unitsDetailes, setUnitsDetailes] = useState({});


  const pathName = window.location.pathname
    .split('/home/')[1]
    .split('/view')[0]
    .split('/contact-profile-edit')[0];
  useEffect(() => {
    setContactId(+GetParams('id'));
  }, [location]);


  const getAllUnitsByContactId = useCallback(async () => {
    setIsLoading(true);

    const localFilterDto = {
      contactOwnerId: [{ searchType: 1, value: contactId }],
    };

    const body = {
      criteria: localFilterDto,
      operationType,
      filterBy: "createdOn",
      orderBy: 2,
    };

    const res = await UnitsAdvanceSearchTest(filter, body);
    if (!(res && res.status && res.status !== 200)) {

    setUnitsDetailes({
      result: ((res && res.result) || []).map((item) => item.unitJson && UnitMapper(item, JSON.parse(item.unitJson).unit)),
      totalCount: (res && res.totalCount) || 0,
    });
  }
    setIsLoading(false);
  }, [filter, contactId, operationType]);

  const tableActionClicked = useCallback(
    (actionEnum, item) => {
      dispatch(ActiveItemActions.activeItemRequest(item));
      const unitOperationType = item && item.operation_type && item.operation_type.lookupItemName;
      const unitOperationTypeList = ['Sale', 'Rent', 'SaleAndRent'];
      if (actionEnum === TableActions.openFile.key) {
         if (unitOperationType === unitOperationTypeList[0]) {
          GlobalHistory.push(
            `/home/units-sales/unit-profile-edit?formType=${item.unitTypeId}&id=${item.id}`
          );
        } else if (unitOperationType === unitOperationTypeList[1]) {
          GlobalHistory.push(
            `/home/units-lease/unit-profile-edit?formType=${item.unitTypeId}&id=${item.id}`
          );
        } else if (unitOperationType === unitOperationTypeList[2]) {
          GlobalHistory.push(
            `/home/units-lease/unit-profile-edit?formType=${item.unitTypeId}&id=${item.id}`
          );
        } else {
          GlobalHistory.push(
            `/home/units/unit-profile-edit?formType=${item.unitTypeId}&id=${item.id}`
          );
        }
      }
    },
    [dispatch, pathName]
  );


  const detailedCardSideActionClicked = useCallback(
    (actionEnum, activeData) => async (event) => {
      dispatch(ActiveItemActions.activeItemRequest(activeData));
      event.stopPropagation();
      const unitOperationType = activeData && activeData.operation_type && activeData.operation_type.lookupItemName;
      const unitOperationTypeList = ['Sale', 'Rent', 'SaleAndRent'];
      if (actionEnum === ActionsEnum.folder.key) {
        if (unitOperationType === unitOperationTypeList[0]) {
          GlobalHistory.push(
            `/home/units-sales/unit-profile-edit?formType=${activeData.unitTypeId}&id=${activeData.id}`
          );
        } else if (unitOperationType === unitOperationTypeList[1]) {
          GlobalHistory.push(
            `/home/units-lease/unit-profile-edit?formType=${activeData.unitTypeId}&id=${activeData.id}`
          );
        } else if (unitOperationType === unitOperationTypeList[2]) {
          GlobalHistory.push(
            `/home/units-lease/unit-profile-edit?formType=${activeData.unitTypeId}&id=${activeData.id}`
          );
        } else {
          GlobalHistory.push(
            `/home/units/unit-profile-edit?formType=${activeData.unitTypeId}&id=${activeData.id}`
          );
        }
      } else if (actionEnum === ActionsEnum.matching.key) {
        GlobalHistory.push(
          `/home/units-lease/unit-profile-edit?formType=${activeData.unitTypeId
          }&id=${activeData.id}&operationType=${activeData.operationType
          }&matching=${true}`
        );
    }
    },
    [dispatch, pathName]
  );
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={unitsDetailes && unitsDetailes.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });
  const focusedRowChanged = useCallback(() => { }, []);

  useEffect(() => {
    if(operationType) getAllUnitsByContactId();
  }, [getAllUnitsByContactId]);

    return (
    <div className='associated-contacts-wrapper'>
      <Spinner isActive={isLoading} />
      <div className='title-section d-flex-v-center-h-between flex-wrap m-3'>
        <span>{t(`${translationPath}lease-units`)}</span>
        <ViewTypes
          initialActiveType={viewType}
          onTypeChanged={onTypeChanged}
          activeTypes={[ViewTypesEnum.tableView.key, ViewTypesEnum.cards.key]}
        />
      </div>

      {viewType === ViewTypesEnum.tableView.key && (
        <div className='w-100 px-2'>
          <Tables
            data={unitsDetailes.result}
            headerData={[
              { id: 1, label: 'ref-no', input: 'id' },
              { id: 2, label: 'property', input: 'name' },
              {
                id: 3,
                label: 'unit-no',
                input: 'unit_number',
              },
              {
                id: 4,
                label: 'unit-type',
                input: 'unitType',
              },
              {
                id: 5,
                label: 'bedrooms',
                input: 'bedrooms',
              },
            ]}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            defaultActions={[
              {
                enum: TableActions.openFile.key,
                isDisabled: pathName === 'contacts',
              },
            ]}
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            focusedRowChanged={focusedRowChanged}
            totalItems={unitsDetailes && unitsDetailes.totalCount ? unitsDetailes.totalCount : 0}
          />
        </div>
      )}
      {viewType === ViewTypesEnum.cards.key && unitsDetailes && unitsDetailes.result && (
        <UnitsCardsComponent
          data={unitsDetailes}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          displyMatchingButton
          displyOpenFileButton
          onFooterActionsClicked={detailedCardSideActionClicked}
        />
      )}
    </div>
      );
}
