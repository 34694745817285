import React from "react";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { CustomCard } from "../../../../Components";

// Styles
import useStyles from "./styles";

function ContactOverviewTransactionCardSkeleton({ numberOfCards }) {
  const styles = useStyles();

  const skeletonCards = Array.from({ length: numberOfCards }, (_, index) => (
    <Box className={styles.transactionWrapper}>
      <Box className={styles.infoSection}>
        <Box className={styles.infoItem}>
          <Skeleton variant="text" width={130} height={23} />
          <Skeleton variant="text" width={100} height={23} />
        </Box>
        <Box className={styles.infoItem}>
          <Skeleton variant="text" width={100} height={23} />
          <Skeleton variant="text" width={60} height={23} />
        </Box>

        <Box className={styles.propertyDetailsContainer}>
          <Box className={styles.propertyItemWrapper}>
            <Skeleton
              style={{ borderRadius: "8px", marginTop: "8px" }}
              animation="wave"
              width="24px"
              variant="rect"
              height="24px"
            />
            <Box className={styles.propertyInfoWrapper}>
              <Box className={styles.infoItem}>
                <Skeleton variant="text" width={100} height={24} />
                <Skeleton variant="text" width={60} height={24} />
              </Box>
              <Box className={styles.infoItem}>
                <Skeleton variant="text" width={100} height={24} />
                <Skeleton variant="text" width={60} height={24} />
              </Box>
            </Box>
          </Box>
          <Box className={styles.propertyItemWrapper}>
            <Skeleton
              style={{ borderRadius: "8px", marginTop: "8px" }}
              animation="wave"
              width="24px"
              variant="rect"
              height="24px"
            />
            <Box className={styles.propertyInfoWrapper}>
              <Box className={styles.infoItem}>
                <Skeleton variant="text" width={100} height={24} />
                <Skeleton variant="text" width={60} height={24} />
              </Box>
              <Box className={styles.infoItem}>
                <Skeleton variant="text" width={100} height={24} />
                <Skeleton variant="text" width={60} height={24} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={styles.dateSection}>
        <Box className={styles.infoItem}>
          <Skeleton variant="text" width={100} height={24} />
          <Skeleton variant="text" width={60} height={24} />
        </Box>
        <Box className={styles.infoItem}>
          <Skeleton variant="text" width={100} height={24} />
          <Skeleton variant="text" width={60} height={24} />
        </Box>
      </Box>
    </Box>
  ));

  return (
    <CustomCard
      boxShadow="xs"
      borderRadius="xl"
      borderColor="secondary"
      classes={styles.contactOverViewCard}
    >
      <Box className={styles.activityCardTitleWrapper}>
        <div className={styles.titleContainer}>
          <Skeleton
            variant="text"
            width={180}
            height={28}
            style={{ borderRadius: "4px" }}
            animation={false}
          />
          <Skeleton
            variant="circular"
            width={32}
            height={22}
            style={{ borderRadius: "0.75rem" }}
          />
        </div>

        <div>
          <Skeleton variant="rectangular" width={64} height={20} />
        </div>
      </Box>

      <Box className={styles.transactionContainer}>{skeletonCards}</Box>
    </CustomCard>
  );
}

export default ContactOverviewTransactionCardSkeleton;
