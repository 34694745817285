import { config } from '../../config/config';
import { HttpServices } from '../../Helper';

export const GetUnitLeaseHistory = async (unitId) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/Unit/GetUnitLeaseHistory/${unitId}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
