import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Inputs, SelectComponet } from '../../../../../Components';
import { showError } from '../../../../../Helper';

export const TextFieldComponent = ({
  item,
  parentTranslationPath,
  translationPath,
  activitiesTypesDetails,
  onValueChanged,
  setActivitiesTypesDetails,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const [helperText, setHelperText] = useState('');

  const timeTypes = [
    {
      key: 1,
      value: 'minutes',
    },
    {
      key: 2,
      value: 'hours',
    },
    {
      key: 3,
      value: 'days',
    },
  ];

  return (
    <>
      {(item && item.fieldType === 'text') ||
        (item.fieldType === 'number' && (
          <Inputs
            idRef={item.fieldKey}
            labelClasses={(item.isRequired && 'Requierd-Color') || ''}
            key={item.fieldKey}
            labelValue={t(`${translationPath}${item.fieldTitle}`)}
            isWithError
            helperText={helperText}
            error={helperText !== ''}
            value={
              activitiesTypesDetails && activitiesTypesDetails[item.fieldKey]
            }
            type={item.fieldType}
            onInputChanged={(e) => {
              if (item && item.isRequired && e.target.value === '') {
                showError(
                  t(`${translationPath}please-fill-all-required-field`)
                );
                setHelperText(
                  t(`${translationPath}please-fill-all-required-field`)
                );
              } else setHelperText('');
              onValueChanged(e.target.value);
            }}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        ))}
      {item.fieldKey === 'expiredPeriod' && (
        <Inputs
          idRef='ExpiredPeriodRef'
          labelValue={t(`${translationPath}${item.fieldTitle}`)}
          labelClasses='Requierd-Color'
          isWithError
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          min={0}
          value={
            activitiesTypesDetails && activitiesTypesDetails[item.fieldKey]
          }
          type={'number'}
          onInputChanged={(event) => {
            let { value } = event.target;
            onValueChanged(value);
          }}
          endAdornment={
            <SelectComponet
              idRef='ExpiredPeriodTimeTypeRef'
              data={timeTypes}
              labelValue={t(`${translationPath}${item.fieldTitle}`)}
              key={'sLAExpirationPeriodFreqency'}
              valueInput='key'
              textInput='value'
              value={
                (activitiesTypesDetails &&
                  activitiesTypesDetails.sLAExpirationPeriodFreqency) ||
                1
              }
              onSelectChanged={(value) => {
                setActivitiesTypesDetails((items) => {
                  items['sLAExpirationPeriodFreqency'] = value;
                  return { ...items };
                });
              }}
              wrapperClasses='over-input-select w-auto'
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              translationPathForData={translationPath}
            />
          }
        />
      )}
    </>
  );
};
