export const ReminderForEnum = {
  Agent: {
    key: 0,
    value: 'Agent',
  },
  Lead: {
    key: 1,
    value: 'Lead',
  },
  Creator: {
    key: 2,
    value: 'Creator',
  },
  TeamLead: {
    key: 3,
    value: 'TeamLead',
  },
};

