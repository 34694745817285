


import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { GetBranchInfoById, MicrosoftToken } from '../../../../Services/DashboardService';
import { PowerBiEnum } from '../../../../Enums';
import { GlobalTranslate, showError } from '../../../../Helper';
import { GetApplicationUserById } from '../../../../Services/userServices';
import { useTitle } from '../../../../Hooks';
import { SpinnerDashboards } from '../SpinnerDashboards/SpinnerDashboards';
import moment from 'moment';
import { SelectComponet } from '../../../../Components';
import { DateRangePickerComponent } from '../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
const parentTranslationPath = "LeadsView";
const translationPath = "";



export const SegmentationLeadBranch = ({ dynamicComponentSegmentationLeadBranchProps }) =>  {
  const [report, setReport] = useState();
  const [render, setRender] = useState(false);
  const [brancid, setBranchid] = useState(null);
  const [branchCityid, setBranchCityid] = useState(null);
  const [ShowReport, setShowReport] = useState(false);
  const [DateAppaer, setDateAppaer] = useState(false);
  const [datefilters, setdatefilter] = useState(true);
  const [monthsAgo, setMonthsAgo] = useState(1); 

const currentDate = new Date();
const countmonth = new Date(currentDate.setMonth(currentDate.getMonth() - monthsAgo));

  const today = new Date();

  const count = new Date(new Date().setDate(today.getDate() - 183));
  const count2 = new Date(new Date().setDate(today.getDate()));

  const datetimes = moment(count).startOf("day").format("YYYY-MM-DDTHH:mm:ss");
  const datetimes2 = moment(count2).format("YYYY-MM-DDTHH:mm:ss");

  const startDate = datetimes ? new Date(datetimes) : null;
  const endDate = datetimes2 ? new Date(datetimes2) : null;

  const dateRangeDefault = {
    startDate: startDate || null,
    endDate: endDate || null,
    key: "selection",
  };

  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [data, setdata] = useState([]);

  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [state, setState] = useReducer(reducer, {
    fromDate: null,
    toDate: null,
  });

  useEffect(() => {
    const currentState = {
      ...state,
      fromDate:
        (dateFilter.startDate &&
          moment(dateFilter.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        datetimes,
        toDate:
        (dateFilter.endDate &&
          moment(dateFilter.endDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss")) ||
        datetimes2,
    };
    setState({ value: currentState });
    setdata(currentState);
  }, [dateFilter]);


  useTitle(GlobalTranslate.t('Dashboard:marketing-dashboard'));
  const userid = JSON.parse(localStorage.getItem('session')).userId;

  
  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setRender(true);
    } else {
      setReport('');
      showError('Contact Your Bi Admin For Help');
    }
  };

  const getUserById = useCallback(async () => {
    const res = await GetApplicationUserById(userid);
    if (!(res && res.status && res.status !== 200))
      setBranchid(res.branchId); else setBranchid(null);
  }, [userid]);

  const getBranchInfoById = useCallback(
    async () => {
      const res = await GetBranchInfoById(brancid);
      if (!(res && res.status && res.status !== 200))
      setBranchCityid(res.branchCityId); else setBranchCityid(null);
     
    }, [brancid]);

  const Filter = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'Branches',
      column: 'BranchId',
    },
    operator: 'In',
    values: [brancid],
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };


  const advancedFilter = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "ContactView",
      column: "CreatedOn" ,
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };



  useEffect(() => {
    Microsoft(false);
    getUserById(userid);
    getBranchInfoById(brancid)
  }, [brancid ,branchCityid]);


  return (
    <div className='dashboardMain-PowerBIEmbed'>

     
                                  <SpinnerDashboards isActive={!ShowReport} isAbsolute />
                                  {DateAppaer && (
        <div className="MainFiltersBi">
          <div className="selecterbi">
          <div>
              <SelectComponet
                // data={[{ key: 1, value: `${translationPath}last-assign-date` }]}
                emptyItem={{
                  text: `${translationPath}contact-created-Date`,
                  isDisabled: false,
                }}
                valueInput="key"
                textInput="value"
                onSelectChanged={(value) => {
                  if (value == undefined) {
                    setdatefilter(true);
                  } else {
                    setdatefilter(false);
                  }

                }}
                themeClass="theme-transparent"
                wrapperClasses="w-auto"
                idRef="Date_Select"
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
              />
            </div>
            <div>
              <DateRangePickerComponent
                onClearClicked={() => {
                  setDateFilter({
                    startDate: startDate,
                    endDate: endDate,
                    key: "selection",
                  });
                }}
                ranges={[dateFilter]}
                onDateChanged={(selectedDate) => {
                  setDateFilter({
                    startDate:
                      selectedDate.selection &&
                      selectedDate.selection.startDate,
                    endDate: new Date(
                      moment(
                        selectedDate.selection && selectedDate.selection.endDate
                      ).endOf("day")
                    ),
                    key: "selection",
                  });
                }}
              />
            </div>
            </div>
            </div>
                  )}
             {render && brancid !== null && (
              <div className='dashboardMain'>
          <PowerBIEmbed
            embedConfig={{
              type: 'report',
              id: dynamicComponentSegmentationLeadBranchProps.config.reportId,
              embedUrl: dynamicComponentSegmentationLeadBranchProps.config.Url,
              accessToken: report,
              pageView: 'fitToWidth',
              filters: [
                
                
                Filter ,
                
                advancedFilter],
              settings: {
                customLayout: {
                  displayOption: models.DisplayOption.FitToWidth,
                },
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                background: models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={
              new Map([
                [
                  'loaded',
                  () => {
                    setShowReport(true);
                    setDateAppaer(true);

                    console.log('Report loaded');
                  },
                ],
                [
                  'rendered',
                  () => {
                    console.log('Report rendered');
                  },
                ],
                [
                  'error',
                  (event, embed) => {
                    const error = event.detail;

                    if (
                      error.detailedMessage === 'Get report failed' ||
                      error.detailedMessage ===
                      'Access token has expired, resubmit with a new access token' ||
                      error.detailedMessage ===
                      'Fail to initialize - Could not resolve cluster'
                    ) {
                      Microsoft(true);
                      setRender(false);
                    } else console.log(error.detailedMessage);
                    showError(error.detailedMessage);
                  },
                ],
              ])
            }
            cssClassName={
              ShowReport
                ? "report-style-class-Show"
                : "report-style-class-hide"
            }            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
          
        </div>      )}
  
    </div>
    
  );
};

