import React, { useCallback, useEffect, useState } from "react";
import { GetProjectTasks, GetTaskConfigById } from "../../../../../../Services";
import { Box } from "@material-ui/core";
import {
  ContactTaskCardSkeleton,
  CustomPagination,
  NoDataFound,
} from "../../../../../../Components";
import { ContactTaskCard } from "../ContactTaskCard";

// Styles
import useStyles from "./styles";

function ContactTasks({ contactId }) {
  const styles = useStyles();

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 25,
  });
  const [inputValue, setInputValue] = useState(pagination.pageIndex);
  const [tasks, setTasks] = useState({
    result: [],
    totalCount: 0,
  });
  const [isLoadingTask, setIsLoadingTask] = useState(true);
  const [isLoadingApprovalStatus, setIsLoadingApprovalStatus] = useState(true);
  const [approvalStatuses, setApprovalStatuses] = useState({});

  const getProjectTasks = useCallback(async () => {
    setIsLoadingTask(true);

    const body = {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      relatedToLeadId: null,
      relatedToUnitId: null,
      relatedToContactId: contactId,
      relatedToPropertyId: null,
    };

    const res = await GetProjectTasks(body);
    if (!(res && res.status && res.status !== 200)) {
      setTasks({
        result: res?.result,
        totalCount: res?.totalCount,
      });
    } else {
      setTasks({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoadingTask(false);
  });

  const taskConfiguration = useCallback(async () => {
    setIsLoadingApprovalStatus(true);
    const statusMap = {};

    for (const taskItem of tasks?.result || []) {
      const res = await GetTaskConfigById(taskItem?.taskConfigId);
      if (!(res && res.status && res.status !== 200)) {
        statusMap[taskItem?.taskConfigId] = res?.isTaskRequireApproval;
      }
    }

    setApprovalStatuses(statusMap);
    setIsLoadingApprovalStatus(false);
  }, [tasks?.result]);

  useEffect(() => {
    getProjectTasks();
  }, []);

  useEffect(() => {
    if (tasks?.result?.length > 0) {
      taskConfiguration();
    }
  }, [tasks?.result]);

  return (
    <Box className={styles.Task_Contact_Wrapper}>
      <Box className={styles.Task_Contact_Crads_Container}>
        {isLoadingTask && isLoadingApprovalStatus ? (
          <ContactTaskCardSkeleton NumberSkeletonCard={8} />
        ) : tasks?.result?.length > 0 ? (
          tasks?.result?.map((taskItem, index) => (
            <ContactTaskCard
              key={index}
              taskDetails={taskItem}
              isNeedApproval={approvalStatuses[taskItem?.taskConfigId]}
            />
          ))
        ) : (
          <NoDataFound title={"task"} />
        )}
      </Box>
      {tasks?.result?.length > 0 && tasks.totalCount > 0 && (
        <CustomPagination
          hideInMobile
          totalItems={tasks?.totalCount}
          itemsPerPage={pagination?.pageSize}
          currentPage={pagination?.pageIndex}
          inputValue={inputValue}
          setInputValue={setInputValue}
          onPageChange={(page) =>
            setPagination((prev) => ({ ...prev, pageIndex: page }))
          }
          onItemsPerPageChange={(items) =>
            setPagination((prev) => ({ ...prev, pageSize: items }))
          }
          isLoading={isLoadingTask}
        />
      )}
    </Box>
  );
}

export default ContactTasks;
