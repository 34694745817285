
import { PortalConfigurationView } from '../PortalConfiguration' ; 
import { PortalAccoutsView } from '../PortalAccouts' ; 

export const PortalTabsData = [
  {
    label: 'portals-configuration',
    component: PortalConfigurationView,
    value : 'PortalConfiguration'
  },

  {
     label: 'portals-accounts',
     component: PortalAccoutsView,
     value : 'PortalAccounts'
  },
];
