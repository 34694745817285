export const HeaderUnqualifiedTable = [
  {
    id: 1,
    label: 'leadName',
    input: 'leadName',
    isSortable: true,
  },
  {
    id: 2,
    label: 'leadId',
    input: 'leadId',
    isSortable: true,
  },
  {
    id: 3,
    label: 'leadType',
    input: 'leadType',
    isHiddenFilter: false,
    withSelectFilter: true,
  },
  {
    id: 4,
    label: 'unqualifiedBy',
    input: 'unqualifiedBy',
    isSortable: true,
  },
  {
    id: 5,
    label: 'contactPreferences',
    input: 'contactPreferences',
    isSortable: true,
  },
  {
    id: 6,
    label: 'countOfUnqualificationLead',
    input: 'numberOfUnqualified',
    isSortable: true,
  },
  {
    id: 7,
    label: 'remarks',
    input: 'remarks',
    isSortable: true,
  },
  {
    id: 8,
    label: 'reasonName',
    input: 'reasonName',
    isSortable: true,
  },
  {
    id: 9,
    label: 'creationDate',
    input: 'creationDate',
    isDate: true,
    isSortable: true,
    dateFormat: 'DD/MM/YYYY',
  },
  {
    id: 10,
    isSortable: true,
    label: 'unqualifiedDate',
    input: 'unqualifiedDate',
    isDate: true,
    dateFormat: 'DD/MM/YYYY',
  },
];
