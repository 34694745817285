import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    cardWrapper: {
      padding: "20px 16px",
      width: "314px",
    },
    headingContainer: {
      position: "relative",
      marginBottom: "18px",
    },
    avatarWrapper: {
      alignItems: "center",
      backgroundColor: "#FFF",
      borderRadius: "50%",
      boxShadow:
        "0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
      display: "flex",
      width: "80px",
      height: "80px",
      justifyContent: "center",
      margin: "0 auto",
    },
    defaultAvatar: {
      backgroundColor: "#F2F4F7", // avatar background
      border: `1px solid ${theme.palette.border.secondary}`,
      height: "72px",
      width: "72px",
      color: theme.palette.foreground.quarterary,
      lineHeight: "38px",
      fontSize: "30px",
      fontWeight: 600,
    },
    avatar: {
      backgroundColor: "#F2F4F7", // avatar background
      border: `1px solid ${theme.palette.border.secondary}`,
      height: "72px",
      width: "72px",
      '& .MuiAvatar-img': {
        objectFit: "contain",
      }
    },
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      alignItems: "center",
      marginBottom: "16px",
    },
    relationType: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 400,
      color: theme.palette.text.tertiary,
    },
    contactNameWrapper: {
      display: "flex",
      justifyContent: "center",
      gap: "6px",
      height: "60px",
      alignItems: "center",
      cursor: "pointer",
    },
    contactName: {
      fontSize: "20px",
      lineHeight: "30px",
      fontWeight: 600,
    },
    infoContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      alignItems: "center",
      paddingBottom: "8px",
      marginBottom: "8px",
      borderBottom: `1px solid ${theme.palette.background.secondary}`,
      minHeight: "53px",
    },
    infoItem: {
      display: "flex",
      gap: "8px",
      alignItems: "center",
      width: "100%",
      justifyContent: "center",
    },
    infoContent: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      color: theme.palette.text.primary,
    },
    truncateString: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    fw400: {
      fontWeight: 400,
    },
    createdOn: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 400,
      color: theme.palette.text.secondary,
    },
    date: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      color: theme.palette.text.primary,
    },
    menuActiveIconClass: {
      fill: theme.palette.foreground.quarterary,
      cursor: "pointer",
      position: "absolute",
      top: "0",
      right: "0",
    },
    menuDefaultIconClass: {
      fill: theme.palette.foreground.quinary,
      cursor: "pointer",
      position: "absolute",
      top: "0",
      right: "0",
    }
  }
});
