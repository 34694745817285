import React from 'react';
import { DialogComponent } from '../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { CreateUnitRequestToPublishOrUnPublish } from '../../../../../../Services';
import { showError, showSuccess } from '../../../../../../Helper';

export const FieldsNeedingApprovalDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  loginResponse,
  parentTranslationPath,
  translationPath,
  activeItem,
  changedValues,
  reloadData,
  isForLease,
  unitId,
  originalVales,
  setPendingFieldsUpdated
}) => {
  
  const { t } = useTranslation(parentTranslationPath, 'Shared');
  const handleCreateUnitRequestToChangeFields = async () => {
    const res = await CreateUnitRequestToPublishOrUnPublish({
      requesterUserId: loginResponse?.userId,
      userBranchId: loginResponse?.branchId,
      typeOfRequest: 3,
      unitIds: [activeItem?.id || unitId],
      comments: '',
      marketingData: {isForLease},
      updatedUnitFields: changedValues,
      originalUnitFields : originalVales
    });
    if (!(res && res.status && res.status !== 200)) {
      if (reloadData) {
        reloadData()
      }
      if(setPendingFieldsUpdated) setPendingFieldsUpdated(true)
      showSuccess(t(`${translationPath}create-request-massage`));
      setIsDialogOpen(false);
      return res;
    } else {
      showError(
        t(`${translationPath}cannot-set-unit-as-published-without-price`)
      );
      setIsDialogOpen(false);
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);

  };
  return (
    <>
      <DialogComponent
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        isOpen={isDialogOpen}
        titleText={'Approval Required'}
        maxWidth={'sm'}
        saveType='button'
        saveClasses='btns theme-solid w-100 mx-2 mb-2'
        onSaveClicked={handleCreateUnitRequestToChangeFields}
        onCancelClicked={handleCloseDialog}
        onCloseClicked={handleCloseDialog}
        dialogContent={
          <div>
            <p>{t(`${translationPath}approval-message`)}</p>
          </div>
        }
      />
    </>
  );
};
