import React from "react";
import { Box, IconButton } from "@material-ui/core";
import { useSelectedTheme, useIsDesktop } from "../../../Hooks";

// Styles
import useStyles from "./styles";

// Icons
import { TrashOne, EditOne } from "../../../assets/icons";

function TableEditDeleteActions({ anchorPosition, onDelete, onEdit }) {
  const {
    theme: { palette },
  } = useSelectedTheme();
  
  const { isDesktop } = useIsDesktop();
  
  const styles = useStyles({ isDesktop });

  return (
    <Box
      className={styles.actionsContainer}
      style={{
        top: anchorPosition.y,
      }}
    >
      <Box className={styles.flex}>
        <IconButton
          aria-controls="TrashOne"
          aria-haspopup="true"
          className={styles.button}
          onClick={() => onDelete()}
        >
          <TrashOne
            width="20"
            height="20"
            fill={palette.button.tertiaryGray_fg}
          />
        </IconButton>

        <IconButton
          aria-controls="EditOne"
          aria-haspopup="true"
          className={styles.button}
          onClick={() => onEdit()}
        >
          <EditOne
            width="20"
            height="20"
            fill={palette.button.tertiaryGray_fg}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default TableEditDeleteActions;
