import React from 'react';
import { checkIsColumnHiddenInConfig } from '../../../../../Helper';

export const LeadsLeaseTableHeaderData = [
  {
    id: 1,
    label: 'Name',
    input: 'contact_name.name',
    isHiddenFilter: true,
    isDefaultFilterColumn: true,
    isSortable: true,
    fieldType: 'text' ,
    component: (item) => <span>{item && item.name}</span> || <span />,
  },
  {
    id: 2,
    label: 'Type',
    input: 'leadClass',
    isHiddenFilter: true,
    isDefaultFilterColumn: true,
    isSortable: true,
    fieldType: 'select' , 
    data : { 
      enum : [
    { id: 'Seller', name: 'seller' },
    { id: 'Buyer', name: 'buyer' },
    { id: 'Landlord', name: 'landlord' },
    { id: 'Tenant', name: 'tenant' },

  ] } , 
  },
  {
    id: 4,
    label: 'Creation Date',
    input: 'createdOn',
    fieldKey: 'createdOn',
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
    fieldType: 'alt-date' , 
    isDate: true ,
    isSortable: true,
    component: (item) => <span>{item && item.creationDate}</span> || <sapn />
  },
  {
    id: 8,
    label: 'Creation Time',
    input: 'createdOn',
    fieldKey: 'creationTime',
    fieldType: 'alt-date' , 
    isDefaultFilterColumn: true,
    isHiddenFilter: true,
    isDate: false,
    isNumber: true,
    isSortable: true,
    component: (item) => <span>{item && item.creationTime}</span> || <sapn />

  },
  {
    id: 5,
    label: 'Progress',
    input: 'data_completed',
    textInputType: 'number',
    fieldKey: 'data_completed',
    textInputMax: 100,
    textInputMin: 0,
    isHiddenFilter: true,
    isDefaultFilterColumn: true,
    isNumber: true,
    fieldType: 'number' , 
    isSortable: true,
    component: (item) => <span>{item && item.progressWithPercentage}</span> || <span />
  },
  {
    id: 6,
    label: 'Last Activity Type Name',
    input: 'lastActivtyTypeName',
    fieldKey: 'lastActivityTypeName',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    fieldType: 'searchField' ,
    data: {
      type: "string",
      title: "Last Activty Type Name",
      description: "General Information",
      searchKey: "activtyType",
      iconField: "",
      dependOn: "",
      multi: "false",
      default: "",
      isSearchable: false,
      searchableKey: "lastActivtyTypeName",
      displayPath: "lastActivtyTypeName"
   } 
  },
  {
    id: 7,
    label: 'Activity Created By',
    input: 'lastActivityCreatedByName',
    fieldKey: 'activityCreatedBy',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true, 
    fieldType: 'searchField' , 
    data:  {
      type: "string",
      title: "Activity Created By",
      description: "General Information",
      searchKey: "User",
      iconField: "",
      dependOn: "",
      multi: "false",
      default: "",
      isSearchable: false,
      searchableKey: "activityCreatedBy",
      displayPath: "activityCreatedBy"
  }
  },
  {
    id: 34,
    label: 'Lead Assigned Date',
    input: 'assignDate',
    fieldKey: 'leadAssignedDate',
    fieldType: 'alt-date' , 
    isDefaultFilterColumn: false,
    isHiddenFilter: false,
    isDate: true,
    isSortable: true,
  },
  {
    id: 9,
    label: 'Activity Remarks',
    input: 'lastActivityComment',
    isHiddenFilter: true,
    fieldType: 'text' ,
    isDefaultFilterColumn: true,
    isSortable: true,
  },
  {
    id: 10,
    label: 'Last Activity Date',
    input: 'lastActivityDate',
    fieldKey: 'lastActivityDate',
    isDefaultFilterColumn: false,
    fieldType: 'alt-date' , 
    isHiddenFilter: false,
    isDate: true,
    isSortable: true,
    dateFormat: 'DD/MM/YYYY - hh:mm A',
  }, 
  {
    id: 11,
    label: 'Main Consent',
    input: 'mainConsent',
    component: (item)=> <span>{item?.mainConsent? item.mainConsent : '-'}</span> || '',
    isHiddenFilter: false,
    isDefaultFilterColumn: false,
    isSortable: true,
    fieldType: 'select' ,
    data : {
      lookupName : "ContactConsent",
    }
  },
  {
    id: 12,
    label: 'Assets Consent',
    input: 'assetsConsent',
    component: (item)=> <span>{item?.assetsConsent? item.assetsConsent : '-'}</span> || '',
    isHiddenFilter: false,
    isHidden: checkIsColumnHiddenInConfig({ columnName: 'assetsConsent' }),
    isDefaultFilterColumn: false,
    isSortable: true,
    fieldType: 'select' ,
    data : {
      lookupName : "ContactConsent",
    }
  },
  {
    id: 13,
    label: 'DNCR',
    input: 'dncr',
    component: (item)=> <span>{item?.dncr? item.dncr : '-'}</span> || '',
    isHiddenFilter: false,
    isDefaultFilterColumn: false,
    isSortable: true,
    fieldType: 'select' ,
    data : {
      lookupName : "ContactDNCR",
    }
  },
     {
    id: 14,
    label: 'angry Bird',
    input: 'angryBird',
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
    isSortable: false,
    fieldType: 'select' ,
    data : { enum :['Yes' , 'No'] } ,
    component: (item) => <span>{item && item.angryBird ? 'Yes' : 'No'}</span> || ''
  },
  {
    id: 15,
    label: 'critical contact class',
    input: 'highProfile',
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
    isSortable: false,
    fieldType: 'select' ,
    data : { enum :['Yes' , 'No'] } ,
    component: (item) => <span>{item && item.highProfile ? 'Yes' : 'No'}</span> || ''
  },
];
