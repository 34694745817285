/* eslint-disable prefer-const */
import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Editor } from "@tinymce/tinymce-react";
// import {
//   align,
//   font,
//   fontColor,
//   fontSize,
//   formatBlock,
//   hiliteColor,
//   horizontalRule,
//   lineHeight,
//   list,
//   paragraphStyle,
//   table,
//   template,
//   textStyle,
//   image,
//   link, blockquote,
//   video,
//   audio,
//   math,
//   imageGallery,
// } from 'suneditor/src/plugins';
import {
  Spinner,
  Tables,
  TabsComponent,
} from "../../../../../../../Components";
import { getPublicDownloadableLink } from "../../../../../../../Helper";
import { TemplateTreeFieldsView } from "./TemplateTreeFieldsView";
import { TableActions } from "../../../../../../../Enums";
import {
  GetLookupValuesForTemplates,
  uploadFile,
  UploadHtmlFile2,
} from "../../../../../../../Services";
import "suneditor/dist/css/suneditor.min.css";
import { config } from "../../../../../../../config";
import { LookupTreeFieldsView } from "./LookupTreeFieldsView";
import { useTranslation } from "react-i18next";

export const TemplatebuilderComponent = ({
  state,
  onStateChanged,
  onContentChanged,
  parentTranslationPath,
  Data,
  activeItem,
  translationPath,
  isTemplatesBody,
}) => {
  const [State, setState] = useState([]);
  const [DragItem, setDragItem] = useState(false);
  const [focsee, setfocsee] = useState(false);
  const [lodeFirstTime, setlodeFirstTime] = useState(true);
  const [DragItemValue, setDragItemDragItemValue] = useState(null);
  // const editor = useRef();
  const inputRef = useRef(null);
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState("");
  // const getSunEditorInstance = (sunEditor) => {
  //   editor.current = sunEditor;
  // };

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      if (actionEnum === TableActions.deleteText.key) {
        try {
          setState((items) => {
            items.splice(focusedRow, 1);
            return [...items];
          });
          setContent(content.replace(`{${item.keyName}}`, ""));
        } catch (error) {
          console.log("error: ", error);
        }
      }
    },
    [content]
  );

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    // Upload image to Server
    // eslint-disable-next-line no-unused-vars
    Object.values(files).map(async (image) => {
      const response = await uploadFile({
        file: image,
      });
      const responseeeee = {
        result: [
          {
            url: getPublicDownloadableLink(response.uuid),
            name: response.fileName,
            size: files[0].size,
          },
        ],
      };
      uploadHandler(responseeeee);
    });
  };

  const getAllworkOrderloadHtmlFis = useCallback(async (files) => {
    setIsLoading(true);
    const res = await UploadHtmlFile2(files);
    if (!(res && res.status && res.status !== 200)) {
      setContent(res);
      setIsLoading(false);
    } else {
      setContent("");
      setIsLoading(false);
    }
    setIsLoading(false);
  }, []);

  const handleUploadClick = () => {
    // 👇 We redirect the click event onto the hidden input element
    inputRef.current.click();
  };

  const handleFileChange = (e) => {
    if (!e.target.files) return;
    getAllworkOrderloadHtmlFis(e.target.files[0]);

    // 🚩 do the file upload here normally...
  };
  useEffect(() => {
    if (!isTemplatesBody) {
      if (state && state.templateKeys && state.templateKeys.length > 0)
        setState(state && state.templateKeys);
    } else if (
      state &&
      state.templateEmailKeys &&
      state.templateEmailKeys.length > 0
    )
      setState(state && state.templateEmailKeys);
  }, [isTemplatesBody, state, state.templateKeys]);

  useEffect(() => {
    if (!isTemplatesBody) {
      setContent(state && state.templateBody);
      setlodeFirstTime(false);
    } else setContent(state.templateEmailBody);
  }, [isTemplatesBody]);

  useEffect(() => {
    onStateChanged(State);
  }, [State]);

  useEffect(() => {
    if (DragItem && focsee) {
      const isItemPresent = State.some(
        (element) => element?.searchKey === DragItemValue?.searchKey
      );

      if (!isItemPresent) {
        setState((items) => [...items, DragItemValue]);
      }

      setfocsee(false);
    }
  }, [DragItemValue, focsee, State]);

  const DragDropMainfun = () => {
    const content = "";
    const AdaptiveCards = "";
    function toAdaptiveCard(name, definition) {
      definition.id = name;
      console.warn(definition);

      const card = {
        type: "Drag",
        version: "1.0",
        body: [definition],
      };

      const adaptiveCard = new AdaptiveCards.AdaptiveCard();
      adaptiveCard.parse(card);

      const element = adaptiveCard.render();
      return element.innerHTML;
    }
    const DragTree = () => null;
    function formatContentFromForm(unparsedContent) {
      const parsedContent = /---([{]*)---([}]*)/m.exec(unparsedContent);
      const frontMatter = DragTree.safeLoad(parsedContent[1]);
      const content4 = parsedContent[1];

      const form = document.querySelector(".js-content");
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(frontMatter + content4)) {
        const element = form.querySelector(`#${key}`);
        const input = element.querySelector("input, select");
        // eslint-disable-next-line no-const-assign
        content = content.replace(`%%${key}%%`, input.value);
      }

      document.getElementById("new_comment_field").value = content;
    }

    function formatContentAsForm(unparsedContent) {
      const parsedContent = /---([^]*)---([^]*)/m.exec(unparsedContent);
      const frontMatter = DragTree.safeLoad(parsedContent[1]);

      const content2 = parsedContent[2];
      const matchRegex = /%%(?<Fields>.*?)%%/g;
      let Fields = matchRegex.exec(content + content2);

      do {
        const defintionName = Fields.groups.name;
        const definition = frontMatter[defintionName];
        // eslint-disable-next-line no-const-assign
        content2 = content.replace(
          `{.${defintionName}}`,
          toAdaptiveCard(defintionName, definition)
        );
        // eslint-disable-next-line no-cond-assign
      } while ((Fields = matchRegex.exec(content)) !== null);

      document.querySelector(".js-content").innerHTML = content;
    }

    const button = document.querySelector(".js-button");
    button.addEventListener("click", () => {
      if (button.innerHTML === "Submit") {
        formatContentFromForm(content);
        document.querySelector(".js-content").innerHTML = "";
        button.innerHTML = "Insert Content";
      } else {
        formatContentAsForm(content);
        button.innerHTML = "Submit";
      }
    });
  };

  const editorRef = useRef(null);
  // const log = () => {
  //   if (editorRef.current)
  //     console.log(editorRef.current.getContent());
  // };

  const [activeTab, setActiveTab] = useState(0);
  const [TemplatesLookup, setTemplatesLookup] = useState([]);

  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };
  const TabsData = [
    { tab: t(`${translationPath}Key-details`) },
    { tab: t(`${translationPath}Lookup`) },
  ];

  const getAllTemplatesLookup = useCallback(async () => {
    //setIsLoading(true);
    const res = await GetLookupValuesForTemplates();
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      setTemplatesLookup(res && res.LookupData);
    } else {
      setTemplatesLookup([]);
    }
    //setIsLoading(false);
  }, []);

  useEffect(() => {
    getAllTemplatesLookup();
  }, []);

  return (
    <div
      className="sms-template-wrapper childs-wrapper"
      onMouseLeave={() => onContentChanged(content)}
    >
      <Spinner isActive={isLoading} />
      <input
        type="file"
        accept=".html ,.HTML "
        ref={inputRef}
        onChange={handleFileChange}
      />
      <div className="sms-template-contenar  d-flex    w-100 mb-3">
        <div className="ss w-100">
          {/*
          <div
            contentEditable='true'
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: content }}
          /> */}

          <Editor
            apiKey={config.TinyMCE_ApiKey} // You Must Change Index.Html Too
            idRef="refs"
            onDragStart={(e) => DragDropMainfun(e)}
            // onInit={(evt, editor) => editorRef.current = editor}
            value={content}
            onFocus={() => {
              setfocsee(true);
            }}
            onEditorChange={(event) => {
              setContent(event);
            }}
            // onFocus={() => { setfocsee(true); }}
            // initialValue={content || ''}
            scriptLoading={{ delay: 500 }}
            init={
              {
                extended_valid_elements: "style,link[href|rel]",
                height: 600,
                inline_styles: true,
                invalid_styles: true,
                keep_styles: true,
                valid_elements: "+*[*]",
                width: "100%",
                custom_elements: "style,link,~link",
                // custom_elements: '*',
                invalid_elements: "",
                tinycomments_mode: "embedded",
                tinycomments_author: "Author name",
                verify_html: false,
                content_css: "writer",
                // tinycomments_mode: '0m9n9euh0vwkbvh2rezjo7sca4u6rfavmk2kdkukeeo47zcg',
                // tinycomments_author: '0m9n9euh0vwkbvh2rezjo7sca4u6rfavmk2kdkukeeo47zcg',
                selector: "textarea#open-source-plugins",
                plugins:
                  "preview importcss searchreplace autolink autosave  directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons",
                editimage_cors_hosts: ["picsum.photos"],
                menubar: "file edit view insert format tools table ", // help
                toolbar:
                  "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview  print | insertfile image media template link anchor codesample | ltr rtl",
                toolbar_sticky: true,
                // toolbar_sticky_offset: isSmallScreen ? 102 : 108,
                autosave_ask_before_unload: true,
                autosave_interval: "30s",
                autosave_prefix: "{path}{query}-{id}-",
                autosave_restore_when_empty: false,
                autosave_retention: "2m",
                image_advtab: true,
                link_list: [
                  { title: "My page 1", value: "https://www.tiny.cloud" },
                  { title: "My page 2", value: "http://www.moxiecode.com" },
                ],
                image_list: [
                  { title: "My page 1", value: "https://www.tiny.cloud" },
                  { title: "My page 2", value: "http://www.moxiecode.com" },
                ],
                image_class_list: [
                  { title: "None", value: "" },
                  { title: "Some class", value: "class-name" },
                ],
                importcss_append: true,
                // file_picker_callback: (callback, value, meta) => {
                //   /* Provide file and text for the link dialog */
                //   if (meta.filetype === 'file')
                //     callback('https://www.google.com/logos/google.jpg', { text: 'My text' });

                //   /* Provide image and alt text for the image dialog */
                //   if (meta.filetype === 'image')
                //     callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });

                //   /* Provide alternative source and posted for the media dialog */
                //   if (meta.filetype === 'media')
                //     callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
                // },
                templates: [
                  {
                    title: "New Table",
                    description: "creates a new table",
                    content:
                      '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
                  },
                  {
                    title: "Starting my story",
                    description: "A cure for writers block",
                    content: "Once upon a time...",
                  },
                  {
                    title: "New list with dates",
                    description: "New List with dates",
                    content:
                      '<div class="mceTmpl"><span class="cdate">cdate</span><br><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
                  },
                ],
                template_cdate_format:
                  "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
                template_mdate_format:
                  "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
                image_caption: true,
                quickbars_selection_toolbar:
                  "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                noneditable_class: "mceNonEditable",
                toolbar_mode: "sliding",
                contextmenu: "link image table",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:16px;    max-width: 95%; }",
              }
              //   {

              //   plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss',
              //   toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
              //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              // }
            }
          />

          {/* <ReactQuill
            idRef='refs'

            placeholder={(`${translationPath}Message`)}
            value
            onChange={(event) =>
              setContent(event)}
          /> */}
        </div>

        <div style={{ marginTop: "-37px" }}>
          <div>
            <TabsComponent
              data={TabsData}
              labelInput="tab"
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              themeClasses="theme-curved"
              currentTab={activeTab}
              onTabChanged={onTabChanged}
            />
          </div>
          <div className="w-100">
            {(activeTab === 1 && (
              <LookupTreeFieldsView
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                Data={TemplatesLookup}
              />
            )) || (
              <TemplateTreeFieldsView
                setDragItem={(value) => {
                  setDragItem(value);
                }}
                setDragItemDragItemValue={(value) => {
                  setDragItemDragItemValue(value);
                }}
                Data={Data}
              />
            )}
          </div>
        </div>
      </div>
      <div className="templates-Tables-Search-Key">
        <Tables
          data={State || []}
          headerData={[
            {
              id: 1,
              label: "searchKey",
              input: "searchKey",
            },
            {
              id: 2,
              label: "keyName",
              input: "keyName",
            },
          ]}
          defaultActions={[
            {
              enum: TableActions.deleteText.key,
            },
          ]}
          activePageChanged={1}
          activePage={1}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          itemsPerPage={1000}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          totalItems={
            (state.templateTokens && state.templateTokens.length) || 0
          }
        />
      </div>
    </div>
  );
};
TemplatebuilderComponent.propTypes = {
  translationPath: PropTypes.string.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  onStateChanged: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
};
