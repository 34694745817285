import React, { useEffect, useReducer } from 'react'
import { AgentsSection, DatesSection, OtherDetailsSection, ReferralSection } from './Utilities';

export const ReservationDetailsTab = (props) => {


  // useEffect(() => {
  //   //todo:: this should be filled on next button clicked 
  //   onSelectedChanged(prevState => ({
  //     ...prevState, 
  //     ...localSelected,
  //   }))
  // }, [localSelected]);
  // useEffect(() => {
  //   //todo:: this should be filled on next button clicked 
  //   onLocalSelectedChange({id: 'edit' , value: selected})

  // }, [selected]);

  return (
    <div className='w-75'>
      <AgentsSection
        {...props}
      />
      <ReferralSection
        {...props}
      />
      <DatesSection
        {...props}
      />
      <OtherDetailsSection
        {...props}
      />
    </div>
  )
}
