import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  button: {
    marginRight: 8,
    marginLeft: 8,
    textTransform: "none",
    whiteSpace: "nowrap",
    color: theme.palette.text.tertiary,
  },
}));
