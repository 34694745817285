import { showError } from '../../../../Helper';

export const ClipboardHelper = async (CopiedData) => {
  try {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(CopiedData);
      return true;
    }
    return false;
  } catch (error) {
    showError('Error Copy to Clipboard');
    // eslint-disable-next-line no-console
    console.error('Error Copy to Clipboard Place Contact With CRM Support', error);
    return false;
  }
};
// Refactoring Done 2023
