import React from "react";
import { ActiveUserLogView } from "../../Views/Home/ActiveUserLog";

export const ActiveUserLogRoutes = [
  {
    path: "/view",
    name: "AgentRotation",
    component: ActiveUserLogView,
    layout: "/home/active-user",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "agent-rotation-log",
        isDisabled: true,
        route: "/home/active-user/view",
        groupName: "agent-management",
      },
    ],
  },
];
