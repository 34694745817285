import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next'; 
import { useLocation } from 'react-router-dom';
import Joi, { array } from 'joi';
import { DataFileAutocompleteComponent } from '../../Components';
import { getErrorByName, GetParams, showWarn } from '../../Helper';
import {
  GetLeads,
  getUnits,
  GetAllPortfolio,
  GetAllWorkOrders,
  GetAllMaintenanceContract,
  unitDetailsGet,
  leadDetailsGet,
  GetAllLeadsByContactId,
  contactsDetailsGet,
  GetAdvanceSearchContacts,
  GetAllLeads,
  GetAllUnits,
} from '../../Services';
import { UnitMapper } from '../../Views/Home/UnitsView/UnitMapper/UnitMapper';
import {
  ContactsMapper,
} from '../../Views/Home/ContactsView/ContactsUtilities/ContactsMapper';
import { UnitsOperationTypeEnum, LeadsStatusEnum } from '../../Enums';
export const ActivityRelatedToInput = ({
  schema,
  selected,
  setSelected,
  isSubmitted,
  state,
  setState,
  activityRelatedToList,
  setIsRelatedToNotSelected,
  data,
  setData,
  activeItem,
  parentTranslationPath,
  translationPath,
  isGeneralDialog,
  isReplyDialog,
  checkIsRelatedLeadClosed,
  actionItemId,
  classifications,
  leadId,
  setLeadId,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const searchTimer = useRef(null);
  const { pathname } = useLocation();
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [filter] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const validateEmptyArray = (array) => {
    if (array?.length === 0 || !Array.isArray(array)) return null;
    else return array;
  };
  const handleOperationTypeValue = (array) => {
    if(isSaleView){
      return UnitsOperationTypeEnum.sale.key;
    } 
    if(isLeaseView){
      return UnitsOperationTypeEnum.rent.key;
    } else if (array.length === 1){
      return array[0];
    }
    return null;
  }; 
  const [loadings, setLoadings] = useReducer(reducer, {
    relatedUnit: false,
    relatedLead: false,
    relatedContact: false,
    relatedPortfolio: false,
    relatedWorkOrder: false,
    relatedMaintenanceContract: false,
  });
  const isUnitActivitiesView = pathname.includes('unit');
  const isLeadActivitiesView = pathname.includes('lead-profile-edit') || pathname.includes('lead-sales')  || pathname.includes('lead-lease') || pathname.includes('leads') || pathname.includes('mortgage-leads') || pathname.includes('lead-owner-lead');
  const isContactActivitiesView = pathname.includes('contact-profile-edit') || pathname.includes('Contacts-CRM/view');
  const isLeaseView = pathname.includes('lease')
  const isSaleView = pathname.includes('sale')
  const getAllRelatedUnits = useCallback(
    async (value) => {
      setLoadings({ id: 'relatedUnit', value: true });

      const response = await GetAllUnits({
        ...filter,
        search: value,
        operationType: handleOperationTypeValue(classifications.operationTypes),
        saleTypes: validateEmptyArray(classifications.saleTypes),
        unitTypes: validateEmptyArray(classifications.unitTypes),
        excludeLeadOwnerInUnits: isContactActivitiesView ?  false : true,
      });
      if (!(response && response.status && response.status !== 200)) {
        const unitMapped = (response && response.result.map((item) => UnitMapper(item))) || [];
        const unitFilterd = unitMapped.filter(item=>item);
        setData({
          id: 'relatedUnit',
          value: unitFilterd || [],
        });
      } else setData({ id: 'relatedUnit', value: [] });

      setLoadings({ id: 'relatedUnit', value: false });
    },
    [filter]
  );

  const getAllRelatedLeads = useCallback(
    async (value) => {
      setLoadings({ id: 'relatedLead', value: true });
      const response = await GetAllLeads({
        ...filter,
        search: value,
        leadStatus: LeadsStatusEnum.Open.status,
        leadTypes: validateEmptyArray(classifications.leadCategories),
        activityTypeId: state.activityTypeId,
      });
      if (!(response && response.status && response.status !== 200))
        setData({ id: 'relatedLead', value: (response && response.result) || [] });
      else setData({ id: 'relatedLead', value: [] });

      setLoadings({ id: 'relatedLead', value: false });
    },
    [filter]
  );

  const getAllRelatedPortfolios = useCallback(
    async (value) => {
      setLoadings({ id: 'relatedPortfolio', value: true });
      const response = await GetAllPortfolio({ ...filter, search: value });

      if (!(response && response.status && response.status !== 200))
        setData({ id: 'relatedPortfolio', value: (response && response.result) || [] });
      else setData({ id: 'relatedPortfolio', value: [] });

      setLoadings({ id: 'relatedPortfolio', value: false });
    },
    [filter]
  );
  const getAllRelatedWorkOrders = useCallback(
    async (value) => {
      setLoadings({ id: 'relatedWorkOrder', value: true });
      const response = await GetAllWorkOrders({ ...filter, search: value });

      if (!(response && response.status && response.status !== 200))
        setData({ id: 'relatedWorkOrder', value: (response && response.result) || [] });
      else setData({ id: 'relatedWorkOrder', value: [] });

      setLoadings({ id: 'relatedWorkOrder', value: false });
    },
    [filter]
  );
  const getAllRelatedMaintenanceContracts = useCallback(
    async (value) => {
      setLoadings({ id: 'relatedMaintenanceContract', value: true });
      const response = await GetAllMaintenanceContract({ ...filter, search: value });

      if (!(response && response.status && response.status !== 200))
        setData({ id: 'relatedMaintenanceContract', value: (response && response.result) || [] });
      else setData({ id: 'relatedMaintenanceContract', value: [] });

      setLoadings({ id: 'relatedMaintenanceContract', value: false });
    },
    [filter]
  );

  const getUnitDetails = useCallback(async () => {
    setLoadings({ id: 'relatedUnit', value: true });
    const unitId = actionItemId || +GetParams('id');
    const res = await unitDetailsGet({ id: unitId });
    if (!(res && res.status && res.status !== 200)) {
      const unitMapped = UnitMapper(res);
      setData({
        id: 'relatedUnit',
        value: [unitMapped] || [],
      });
      if(!activeItem) handleInitialAutoFill(unitMapped);
    } else setData({ id: 'relatedUnit', value: [] });

    setLoadings({ id: 'relatedUnit', value: false });
  }, [filter, pathname, actionItemId]);

  const getLeadDetails = useCallback(async () => {
    setLoadings({ id: 'relatedLead', value: true });

    const leadId = actionItemId || +GetParams('id');
    const res = await leadDetailsGet({ id: leadId });

    if (!(res && res.status && res.status !== 200)) {
      if (res?.lead?.status?.lookupItemName === LeadsStatusEnum.Open.key) {
        if(!activeItem) handleInitialAutoFill(res);
        setData({ id: 'relatedLead', value: [res] });
      } else setData({ id: 'relatedLead', value: [] });

    } else setData({ id: 'relatedLead', value: [] });

    setLoadings({ id: 'relatedLead', value: false });
  }, [actionItemId]);

  const getAllLeadsByContactId = useCallback(async () => {
    setLoadings({ id: 'relatedLead', value: true });
    const contactId = actionItemId || +GetParams('id');

    const response = await GetAllLeadsByContactId(
      { ...filter, leadStatus: LeadsStatusEnum.Open.status },
      contactId
    );
    if (!(response && response.status && response.status !== 200))
      setData({ id: 'relatedLead', value: (response && response.result) || [] });
    else setData({ id: 'relatedLead', value: [] });

    setLoadings({ id: 'relatedLead', value: false });
  }, [actionItemId]);

  const getContactDetails = useCallback(
    async (contactId) => {
      setLoadings({ id: 'relatedContact', value: true });

      const id = actionItemId || contactId || +GetParams('id');
      const res = await contactsDetailsGet({ id });
      if (!(res && res.status && res.status !== 200)) {
        if(!activeItem) handleInitialAutoFill(res);
        setData({ id: 'relatedContact', value: (res && [res]) || [] });
      }
      else setData({ id: 'relatedContact', value: [] });
      setLoadings({ id: 'relatedContact', value: false });
    }, [actionItemId]);

  const getAllRelatedContacts = useCallback(
    async (searchValue) => {

      setLoadings({ id: 'relatedContact', value: true });
      
      var emailValidRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      
      let body = {
        criteria: {},
        filterBy: "createdOn",
        contactClasses: validateEmptyArray(classifications.contactClassNames) ?? undefined,
      }
      
      if(searchValue && searchValue.match(emailValidRegex)) 
      {
        body = {
          ...body , 
          criteria: {
           Email :[{searchType: 2, value: searchValue}]  
          },
          filterBy: "createdOn",
        }  
         
      } 
      else if(searchValue && !isNaN(searchValue)) 
      {
        body = {
          ...body , 
          criteria: {
            Ids :[{searchType: 2, value: searchValue}]  
          },
          filterBy: "createdOn",
        }  
      }  
      else if (searchValue) {
          body = {
            ...body,
            criteria: {
              FullName: [{
                searchType: 2,
                value: searchValue
              }]
            },
          }
        }
      const res = await GetAdvanceSearchContacts(filter, body);
      if (!(res && res.status && res.status !== 200)) {
        setData({ id: 'relatedContact', 
        value: ((res && res.result) || []).map((item) => item.contactJson && ContactsMapper(item, JSON.parse(item.contactJson).contact)),
        });
      } else {
        setData({
         id: 'relatedContact', 
         value: [] ,
        });
      }
      setLoadings({ id: 'relatedContact', value: false });
    }, []);


    const handleReminderForLeadOptions = (newValue) => {
      
      let itemToPush = {};
      const localReminderForOptions = [...selected.reminderForOptions];
      const leadReminderIndex = localReminderForOptions.findIndex(
        (item) => item.type === 'lead'
      );
      if (newValue) {
        itemToPush = {
          id: newValue.leadId,
          value: newValue?.lead?.contact_name?.name,
          type: 'lead',
        };
      }

      if (!newValue && leadReminderIndex !== -1) {
        localReminderForOptions.splice(leadReminderIndex, 1);
      }
  
      if (newValue && leadReminderIndex !== -1) {
        localReminderForOptions[leadReminderIndex] = itemToPush;
      }
  
      if (newValue && leadReminderIndex == -1) {
        localReminderForOptions.push(itemToPush);
      }

      setSelected({ id: 'reminderForOptions', value: localReminderForOptions });
    };


  const getContactDisplayLabel = (option) => {
    let displayLabel = ''

    if (option?.contact?.company_name){
      displayLabel = option.contact.company_name || '';
      return displayLabel;  
    }

    if (option?.contact?.first_name){
      displayLabel = `${option.contact.first_name || ''} ${option.contact.last_name || ''}`;
      return displayLabel;  
    }

    if (option?.company_name){
      displayLabel = option.company_name || '';
      return displayLabel;  
    }

    if (option?.first_name){
      displayLabel = `${option.first_name || ''} ${option.last_name || ''}`;
      return displayLabel;  
    }

    return displayLabel;
  } 

  const getRelatedToData = (relatedToItem, searchValue) => {
    if (isGeneralDialog) {
      if (relatedToItem.relatedToName === 'Unit') getAllRelatedUnits(searchValue);
      if (relatedToItem.relatedToName === 'Lead') getAllRelatedLeads(searchValue);
      if (relatedToItem.relatedToName === 'Contact' && !activeItem) getAllRelatedContacts(searchValue);
      if (relatedToItem.relatedToName === 'Contact' && activeItem) getContactDetails(activeItem.relatedContactNumberId);
      if (relatedToItem.relatedToName === 'Portfolio') getAllRelatedPortfolios(searchValue);
      if (relatedToItem.relatedToName === 'WorkOrder') getAllRelatedWorkOrders(searchValue);
      if (relatedToItem.relatedToName === 'MaintenanceContract') getAllRelatedMaintenanceContracts(searchValue);
      return;
    }

    if (relatedToItem.relatedToName === 'Unit' && isUnitActivitiesView) getUnitDetails();
    if (relatedToItem.relatedToName === 'Unit' && !isUnitActivitiesView) getAllRelatedUnits(searchValue);

    if (relatedToItem.relatedToName === 'Lead' && isLeadActivitiesView) getLeadDetails();
    if (relatedToItem.relatedToName === 'Lead' && isContactActivitiesView) getAllLeadsByContactId();
    if (relatedToItem.relatedToName === 'Lead' && !isLeadActivitiesView && !isContactActivitiesView)
      getAllRelatedLeads(searchValue);

    if (relatedToItem.relatedToName === 'Contact' && isContactActivitiesView) getContactDetails();
    if (relatedToItem.relatedToName === 'Contact' && !isContactActivitiesView && activeItem && activeItem.relatedContactNumberId ) getContactDetails(activeItem.relatedContactNumberId);
    if (relatedToItem.relatedToName === 'Contact' && !isContactActivitiesView && !activeItem) getAllRelatedContacts(searchValue);

    if (relatedToItem.relatedToName === 'Portfolio') getAllRelatedPortfolios(searchValue);
    if (relatedToItem.relatedToName === 'WorkOrder') getAllRelatedWorkOrders(searchValue);
    if (relatedToItem.relatedToName === 'MaintenanceContract') getAllRelatedMaintenanceContracts(searchValue);
  }

  const handleRelatedToChange = ({item, newValue, relatedToType}) => {
    setState({
      id:
        ((item?.relatedToName === 'Unit' || relatedToType === 'Unit') && 'relatedUnitNumberId') ||
        ((item?.relatedToName === 'Lead' || relatedToType === 'Lead') && 'relatedLeadNumberId') ||
        ((item?.relatedToName === 'Contact' || relatedToType === 'Contact') && 'relatedContactNumberId') ||
        ((item?.relatedToName === 'Portfolio' || relatedToType === 'Portfolio') && 'relatedPortfolioId') ||
        ((item?.relatedToName === 'WorkOrder' || relatedToType === 'WorkOrder') && 'relatedWorkOrder') ||
        ((item?.relatedToName === 'MaintenanceContract' || relatedToType === 'MaintenanceContract' ) && 'relatedMaintenanceContractId') ||
        '',
      value:
        (newValue &&
          (((item?.relatedToName === 'Unit' || relatedToType === 'Unit') && newValue.id) ||
            ((item?.relatedToName === 'Lead' || relatedToType === 'Lead') && newValue.leadId) ||
            ((item?.relatedToName === 'Contact' || relatedToType === 'Contact') && newValue.contactsId || newValue.id) ||
            ((item?.relatedToName === 'Portfolio' || relatedToType === 'Portfolio') && newValue.portfolioId) ||
            ((item?.relatedToName === 'WorkOrder' || relatedToType === 'WorkOrder') && newValue.id) ||
            ((item?.relatedToName === 'MaintenanceContract' || relatedToType === 'MaintenanceContract') && newValue.maintenanceContractId))) ||
        null,
    });

    setSelected({
      id:
        ((item?.relatedToName === 'Unit' || relatedToType === 'Unit') && 'relatedUnit') ||
        ((item?.relatedToName === 'Lead' || relatedToType === 'Lead') && 'relatedLead') ||
        ((item?.relatedToName === 'Contact' || relatedToType === 'Contact') && 'relatedContact') ||
        ((item?.relatedToName === 'Portfolio' || relatedToType === 'Portfolio') && 'relatedPortfolio') ||
        ((item?.relatedToName === 'WorkOrder' || relatedToType === 'WorkOrder') && 'relatedWorkOrder') ||
        '',
      value: newValue,
    });

    if (item?.relatedToName === 'Lead' || relatedToType === 'Lead') handleReminderForLeadOptions(newValue);
    if (item?.relatedToName === "Lead" || relatedToType === "Lead") {
      setLeadId(newValue.leadId);
    }
  };

  const handleInitialAutoFill = (newValue) => {

    if (isUnitActivitiesView)  handleRelatedToChange({ relatedToType: 'Unit', newValue, });
    if (isLeadActivitiesView) handleRelatedToChange({ relatedToType: 'Lead', newValue, });
    if (isContactActivitiesView) handleRelatedToChange({ relatedToType: 'Contact', newValue, });
  }

  useEffect(() => {
    activityRelatedToList.map((activity) => {
      if (activity.relatedToName === "Lead") {
        setLeadId(selected?.relatedLead?.leadId);
      }
      return activity;
    });
  }, [selected.relatedLead]);

  useEffect(() => {
    setIsRelatedToNotSelected(
      !(
        state.relatedLeadNumberId ||
        state.relatedContactNumberId ||
        state.relatedUnitNumberId ||
        state.relatedPortfolioId ||
        state.relatedWorkOrderId ||
        state.relatedMaintenanceContractId
      )
    );
  }, [state]);

  useEffect(() => {

    if (activityRelatedToList && activityRelatedToList.length && !isReplyDialog) {
      activityRelatedToList.forEach((item) => {
        getRelatedToData(item);
      });
    }
  }, [filter, activityRelatedToList, actionItemId]);
  return (
    <div className='dialog-content-item related-to-wrapper'>
      {(activityRelatedToList && activityRelatedToList.length && (
        <fieldset className={activityRelatedToList.length === 1 && 'full-width-wrapper'}>
          <legend>{t(`${translationPath}related-to`)}</legend>
          {activityRelatedToList.map((item, index) => (
            <DataFileAutocompleteComponent
              labelValue={t(`${translationPath}${item.relatedToName}`)}
              idRef={`relatedToRef-${index}`}
              isDisabled={isReplyDialog || 
                (item.relatedToName === 'Lead' && activeItem) ||
                (item.relatedToName === 'Lead' && (checkIsRelatedLeadClosed && checkIsRelatedLeadClosed()))
              }
              selectedValues={
                (item.relatedToName === 'Unit' && selected.relatedUnit) ||
                (item.relatedToName === 'Lead' && selected.relatedLead) ||
                (item.relatedToName === 'Contact' && selected.relatedContact) ||
                (item.relatedToName === 'Portfolio' && selected.relatedPortfolio) ||
                (item.relatedToName === 'WorkOrder' && selected.relatedWorkOrder) ||
                (item.relatedToName === 'MaintenanceContract' &&
                  selected.relatedMaintenanceContract)
              }
              multiple={false}
              data={
                (item.relatedToName === 'Unit' && data.relatedUnit) ||
                (item.relatedToName === 'Lead' && data.relatedLead) ||
                (item.relatedToName === 'Contact' && data.relatedContact) ||
                (item.relatedToName === 'Portfolio' && data.relatedPortfolio) ||
                (item.relatedToName === 'WorkOrder' && data.relatedWorkOrder) ||
                (item.relatedToName === 'MaintenanceContract' && data.relatedMaintenanceContract) ||
                []
              }
              filterOptions={(options) =>options}
              displayLabel={(option) => {
                switch (item.relatedToName) {
                  case 'Unit':
                    return option?.unitRefNo || '';
                  case 'Lead':
                    return (
                      (option.lead && option.lead.contact_name && option.lead.contact_name.name) ||
                      ''
                    );
                  case 'Contact':
                    return getContactDisplayLabel(option);
                  case 'Portfolio':
                    return option?.portfolioName || '';
                  case 'MaintenanceContract':
                    return (option?.maintenanceContractId || '').toString();
                }
              }}
              renderFor={item.relatedToName && item.relatedToName.toLowerCase()}
              withoutSearchButton
              isSubmitted={isSubmitted}
              helperText={
                (item.relatedToName === 'Unit' &&
                  getErrorByName(schema, 'relatedUnitNumberId').message) ||
                (item.relatedToName === 'Lead' &&
                  getErrorByName(schema, 'relatedLeadNumberId').message) ||
                (item.relatedToName==='Contact'
                  && getErrorByName(schema, 'relatedContactNumberId' ).message )||
                (item.relatedToName === 'Portfolio' &&
                  getErrorByName(schema, 'relatedPortfolioId').message) ||
                (item.relatedToName === 'WorkOrder' &&
                  getErrorByName(schema, 'relatedWorkOrderId').message) ||
                (item.relatedToName === 'MaintenanceContract' &&
                  getErrorByName(schema, 'relatedMaintenanceContractId').message) ||
                ''
              }
              error={
                (item.relatedToName === 'Unit' &&
                  getErrorByName(schema, 'relatedUnitNumberId').error) ||
                (item.relatedToName === 'Lead' &&
                  getErrorByName(schema, 'relatedLeadNumberId').error) ||
                  (item.relatedToName==='Contact'
                  && getErrorByName(schema, 'relatedContactNumberId' ).error )||
                (item.relatedToName === 'Portfolio' &&
                  getErrorByName(schema, 'relatedPortfolioId').error) ||
                (item.relatedToName === 'WorkOrder' &&
                  getErrorByName(schema, 'relatedWorkOrderId').error) ||
                (item.relatedToName === 'MaintenanceContract' &&
                  getErrorByName(schema, 'relatedMaintenanceContractId').error) ||
                ''
              }
              isLoading={
                (item.relatedToName === 'Unit' && loadings.relatedUnit) ||
                (item.relatedToName === 'Lead' && loadings.relatedLead) ||
                (item.relatedToName === 'Contact' && loadings.relatedContact) ||
                (item.relatedToName === 'Portfolio' && loadings.relatedPortfolio) ||
                (item.relatedToName === 'WorkOrder' && loadings.relatedWorkOrder) ||
                (item.relatedToName === 'MaintenanceContract' &&
                  loadings.relatedMaintenanceContract) ||
                false
              }
              onInputKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  getRelatedToData(item, value)
                }, 1200);
              }}
              onKeyDown={()=>{
                const newValue = null;
                handleRelatedToChange({item, newValue});
              }}
              isWithError
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onChange={async (_, newValue) => handleRelatedToChange({item, newValue})}
            />
          ))}
        </fieldset>
      )) ||
        null}
    </div>
  );
};
