export const QuickAddPermissions = {
  QuickAddContact:
   {
     permissionsId: '42d5eec5-0833-450d-9614-08da36560604',
     permissionsName: 'Quick Add Contact',
     componentsId: null,
     components: null
    },
  QuickAddProperty: {
    permissionsId: 'c071d27e-2b8b-4740-9615-08da36560604',
    permissionsName: 'Quick Add Property',
    componentsId: null,
    components: null
  },
  QuickAddUnit:
  {
   permissionsId: '6e68b036-1095-494e-9616-08da36560604',
   permissionsName: 'Quick Add Unit',
   componentsId: null,
   components: null
  },
  QuickAddLead:
  {
    permissionsId: 'e8917429-90e1-4919-9617-08da36560604',
    permissionsName: 'Quick Add Lead',
    componentsId: null,
    components: null
  },
  QuickAddActivity:
  {
    permissionsId: 'd6d74217-23f8-4f4a-9618-08da36560604',
    permissionsName: 'Quick Add Activity',
    componentsId: null,
    components: null
  },
  QuickAddTask:
  {
    permissionsId: 'e95f02e0-9f43-4888-19be-08dc313da57c',
    permissionsName: 'Quick Add Task',
    componentsId: null,
    components: null
  },

};
