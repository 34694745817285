
import { HttpServices } from '../../Helper';
import { config } from '../../config';

export const GetAllPropertyRatingTowerUnitScoresAPI = async () => {

    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PropertyRating/GetAllPropertyRatingTowerUnitScores`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
  };

  
  export const UpdatePropertyRatingTowerUnitScoresAPI = async (body) => {
    const result = await HttpServices.post(
        `${config.server_address}/CrmDfm/PropertyRating/UpdatePropertyRatingTowerUnitScores`, body
    )
        .then((data) => data)
        .catch((error) => error.response.data);
    return result;
};