import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Tabs, Tab } from "@material-ui/core";
import { GetAllActivities, GetMainEntitiesComments } from "../../../../../Services";
import { ContactProfileDetails, ContactProfileActivity, ContactProfileSchedule, ContactProfileComments } from "..";
import { useTranslate } from "../../../../../Hooks";
import { useVerticalNav } from "../../../../../Contexts/VerticalNavContext";

// styles
import useStyles from "./styles";

const a11yProps = (index) => ({
  "aria-controls": `simple-tabpanel-${index}`,
  id: `notification-tab-${index}`,
});

const TabPanel = (props) => {
  const { children, index, value, ...other } = props;
  return (
    <div
      aria-labelledby={`notification-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && children}
    </div>
  );
};

function ContactProfileTabs({ handleTabChange, tabValue, activeItem }) {
  const styles = useStyles();
  const { translate } = useTranslate("NewContactsView");
  const { setAlertBoxContent } = useVerticalNav();

  const [isActivityLoading, setIsActivityLoading] = useState(true);

  const [activityDate, setActivityDate] = useState(new Date());

  const [activities, setActivities] = useState({
    result: [],
    totalCount: 0,
  });

  const [activitiesDates, setActivitiesDates] = useState({
    selectedDates: [],
    events: [],
  });

  const [isCommentsLoading, setIsCommentsLoading] = useState(true);
  const [comments, setComments] = useState([]);

  const getAllActivities = useCallback(async () => {
    setIsActivityLoading(true);

    const body = {
      pageIndex: 1,
      pageSize: 25,
      orderBy: 2,
      filterBy: "CreatedOn",
      contactId: activeItem.id,
    };
    try {
      const response = await GetAllActivities(body);

      const dates =
        response.result.map((item) => +moment(item.activityDate).format("D")) ??
        [];
      const newEvents =
        response.result.map((item) => ({
          hour: moment(item.activityDate).format("hh:mm"),
          date: item.activityDate,
          subject: item.subject,
          activity: item,
          month: moment(item.activityDate).format("M"),
        })) ?? [];

      setActivitiesDates({
        selectedDates: dates,
        events: newEvents,
      });

      setActivities({
        result: response?.result ?? [],
        totalCount: response?.totalCount ?? 0,
      });
    } catch (error) {
      console.error("Failed to fetch activity:", error);
    } finally {
      setIsActivityLoading(false);
    }
  }, [activeItem.id]);

  const getContactComments = useCallback(async () => {
    setIsCommentsLoading(true);

    try {
      const response = await GetMainEntitiesComments(1, activeItem.id);

      setComments(response ?? []);
    } catch (error) {
      console.error("Failed to Fetch Contact Comments:", error);
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "Failed to Fetch Contact Comments!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    } finally {
      setIsCommentsLoading(false);
    }
  }, [activeItem.id]);

  useEffect(() => {
    if (activeItem.id) {
      getAllActivities();
      getContactComments();
    }
  }, [activeItem.id]);

  return (
    <>
      <Tabs
        TabIndicatorProps={{ style: { display: "none" } }}
        aria-label="basic tabs example"
        className={styles.tabs}
        onChange={handleTabChange}
        scrollButtons={false}
        value={tabValue}
        variant="standard"
        classes={{ flexContainer: styles.flexContainer }}
      >
        <Tab
          className={styles.tabButton}
          disableRipple
          classes={{ selected: styles.tab }}
          label={
            <div className={styles.tabButtonContainer}>
              <span className={clsx(styles.tabLabel, styles.font14)}>
                {translate("DETAILS")}
              </span>
            </div>
          }
          {...a11yProps(0)}
        />
        <Tab
          className={styles.tabButton}
          classes={{ selected: styles.tab }}
          disableRipple
          label={
            <div className={styles.tabButtonContainer}>
              <span className={clsx(styles.tabLabel, styles.font14)}>
                {translate("ACTIVITY")}
              </span>
              {!isActivityLoading && 
                <span
                  className={
                    tabValue === 1
                      ? styles.notificationCounter
                      : styles.inactiveNotificationCounter
                  }
                >
                  {activities.totalCount}
                </span>
              }
            </div>
          }
          {...a11yProps(1)}
        />
        <Tab
          className={styles.tabButton}
          classes={{ selected: styles.tab }}
          disableRipple
          label={
            <div className={styles.tabButtonContainer}>
              <span className={clsx(styles.tabLabel, styles.font14)}>
                {translate("SCHEDULE")}
              </span>
              {!isActivityLoading && 
                <span
                  className={
                    tabValue === 2
                      ? styles.notificationCounter
                      : styles.inactiveNotificationCounter
                  }
                >
                  {activitiesDates.events.length}
                </span>
              }
            </div>
          }
          {...a11yProps(2)}
        />
        <Tab
          className={styles.tabButton}
          classes={{ selected: styles.tab }}
          disableRipple
          label={
            <div className={styles.tabButtonContainer}>
              <span className={clsx(styles.tabLabel, styles.font14)}>
                {translate("comments")}
              </span>
              {!isActivityLoading && 
                <span
                  className={
                    tabValue === 3
                      ? styles.notificationCounter
                      : styles.inactiveNotificationCounter
                  }
                >
                  {comments.length}
                </span>
              }
            </div>
          }
          {...a11yProps(2)}
        />
      </Tabs>
      <TabPanel index={0} value={tabValue}>
        <ContactProfileDetails 
          activeItem={activeItem}
        />
      </TabPanel>
      <TabPanel index={1} value={tabValue}>
        <ContactProfileActivity 
          isActivityLoading={isActivityLoading}
          activities={activities}
          activeItem={activeItem}
        />
      </TabPanel>
      <TabPanel index={2} value={tabValue}>
        <ContactProfileSchedule
          isActivityLoading={isActivityLoading}
          activitiesDates={activitiesDates}
          activityDate={activityDate}
          setActivityDate={setActivityDate}
        />
      </TabPanel>
      <TabPanel index={3} value={tabValue}>
        <ContactProfileComments
          isCommentsLoading={isCommentsLoading}
          comments={comments}
          setComments={setComments}
          activeItem={activeItem}
        />
      </TabPanel>
    </>
  );
}

ContactProfileTabs.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  activeItem: PropTypes.objectOf(PropTypes.any),
  handleTabChange: PropTypes.func,
  tabValue: PropTypes.number,
};

ContactProfileTabs.defaultProps = {
  handleTabChange: () => {},
  tabValue: 0,
  activeItem: {},
};

export default ContactProfileTabs;
