

import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Inputs, AutocompleteComponent } from '../../../../../../../Components';
import { getErrorByName } from '../../../../../../../Helper';
import { UnitsOperationType, PortalsEnum, UnitsOperationTypeEnum, } from '../../../../../../../Enums';
import { GetLookupItemsByLookupTypeName, lookupItemsGetId } from '../../../../../../../Services';


export const PortalConfigurationContentDialog = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
  schema,
  isSubmitted,
  editPortalConfiguration
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const [allUnitsOperationType, setAllUnitsOperationType] = useState({
    result: [],
    totalCount: 0,
  });

  const [isLoadingUnitsOperationType, setIsLoadingUnitsOperationType] = useState(false);

  const [allPortalNames, setAllPortalNames] = useState({
    result: [],
    totalCount: 0,
  });

  const [isLoadingPortalName, setIsLoadingPortalName] = useState(false);
  const [isErrorInUrl, setIsErrorInUrl] = useState(false);

  function isValidUrl(str) {

    const pattern = new RegExp(
      '^([a-zA-Z]+:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    return pattern.test(str);
  }


  const GetAllUnitsOperationType = useCallback(async () => {
    setIsLoadingUnitsOperationType(true);
    const res = await lookupItemsGetId({ lookupTypeId: UnitsOperationType.lookupTypeId });
    if (!(res && res.status && res.status !== 200)) {
      setAllUnitsOperationType({
        result: (res && res.filter((item) => item.lookupItemId !== UnitsOperationTypeEnum.rentAndSale.key)) || [],
        totalCount: 0,
      });
    } else {
      setAllUnitsOperationType({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoadingUnitsOperationType(false);
  }, []);

  const GetAllPortalNames = useCallback(async () => {
    setIsLoadingPortalName(true);
    const res = await GetLookupItemsByLookupTypeName({ lookUpName: PortalsEnum.lookupTypeName, pageIndex: 0, pageSize: 10000 });
    if (!(res && res.status && res.status !== 200)) {
      setAllPortalNames({
        result: (res && res.result) || [],
        totalCount: 0,
      });
    } else {
      setAllPortalNames({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoadingPortalName(false);
  }, []);


  useEffect(() => {
    GetAllUnitsOperationType();
    GetAllPortalNames();
  }, [GetAllUnitsOperationType, GetAllPortalNames]);



  return (
    <div className='dialog-content-wrapper'>
      <div className='dialog-content-item px-2 mt-2 w-100'>
        <Inputs
          idRef='portalNameRef'
          labelValue={t(`${translationPath}portalName`)}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          value={state && state.portalName}
          onInputChanged={(e) => {
            setState({ id: 'portalName', value: e.target.value })
          }}
          helperText={getErrorByName(schema, 'portalName').message}
          error={getErrorByName(schema, 'portalName').error}
          isWithError
          isSubmitted={isSubmitted}

        />
      </div>
      <div className='dialog-content-item px-2 mt-2 w-100'>
        <AutocompleteComponent
          idRef='portaldRef'
          labelValue='portal'
          multiple={false}
          value={state.portalConfigrationDto}
          selectedValues={state.portalConfigrationDto}
          data={(allPortalNames && allPortalNames.result) || []}
          chipsLabel={(option) => (option && option.lookupItemName) || ''}
          displayLabel={(option) => (option && option.lookupItemName) || ''}
          withoutSearchButton
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          helperText={getErrorByName(schema, 'portalConfigrationDto').message}
          error={getErrorByName(schema, 'portalConfigrationDto').error}
          isWithError
          isSubmitted={isSubmitted}
          onChange={(event, newValue) => {
            setState({ id: 'portalConfigrationDto', value: (newValue) });
          }}
          isDisabled={editPortalConfiguration ? true : false}
          isLoading={isLoadingPortalName}
          withLoader
        />
      </div>
      <div className='dialog-content-item px-2 mt-2 w-100'>
        <AutocompleteComponent
          idRef='operationTypeIdRef'
          labelValue='operationType'
          multiple={false}
          value={state.operationType}
          selectedValues={state.operationType}
          data={(allUnitsOperationType && allUnitsOperationType.result) || []}
          chipsLabel={(option) => (option && option.lookupItemName) || ''}
          displayLabel={(option) => (option && option.lookupItemName) || ''}
          withoutSearchButton
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            setState({ id: 'operationType', value: (newValue) });
          }}
          isDisabled={editPortalConfiguration ? true : false}
          helperText={getErrorByName(schema, 'operationType').message}
          error={getErrorByName(schema, 'operationType').error}
          isWithError
          isSubmitted={isSubmitted}
          isLoading={isLoadingUnitsOperationType}
          withLoader
        />
      </div>

      <div className='dialog-content-item px-2 mt-2 w-100'>
        <Inputs
          idRef='apiUrlRef'
          labelValue={t(`${translationPath}apiUrl`)}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          value={state && state.apiUrl}
          onInputChanged={(e) => {
            setState({ id: 'apiUrl', value: e.target.value })
          }}
          onKeyUp={(e) => {
            const { value } = e.target;
            setTimeout(() => {
              if (value === '')
                setIsErrorInUrl(false);

              else if (!isValidUrl(value))
                setIsErrorInUrl(true);
              else
                setIsErrorInUrl(false);

            }, 700);
          }}
          helperText={t(`${translationPath}enter-correct-url`)}
          error={isErrorInUrl}
          isWithError

        />
      </div>
      <div className='dialog-content-item px-2 w-100'>
        <Inputs
          idRef='apiKeyRef'
          labelValue={t(`${translationPath}apiKey`)}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          value={state && state.apiKey}
          onInputChanged={(e) => {
            setState({ id: 'apiKey', value: e.target.value })
          }}
        />
      </div>
    </div>
  );
};

PortalConfigurationContentDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  isSubmitted: PropTypes.bool.isRequired,

};

