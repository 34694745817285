import React, { useState, useCallback, useReducer, useEffect, useRef } from 'react';
import Joi from 'joi';
import moment from 'moment';
import { DialogActions, DialogContent, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { showError, showSuccess, GetParams } from '../../../../../../../../Helper';
import {
  AutocompleteComponent,
  Inputs,
  DatePickerComponent,
  DialogComponent
} from '../../../../../../../../Components';
import {
  CreateCampaignTask,
  GetAllCampaignTeamMembers,
  UpdateCampaignTask,
  GetTaskById,
} from '../../../../../../../../Services';
import { ActivityFilesUploader } from '../../../../../../../../Components/UploaderActivitieFileComponent/ActivityFilesUploader';
import { LinkUploader } from '../../../../../../../../SharedComponents/LinkUploader/LinkUploader';
import { useSelector, useDispatch } from 'react-redux';
import { MarketingCampaignsActions } from '../../../../../../../../store/MarketingCampaigns/Actions';

export const TaskManagementDialog = ({ activeItem, isDialogOpen, onClose, onSave }) => {
  const parentTranslationPath = 'Campaign';
  const translationPath = 'campaign-task.';
  const { t } = useTranslation(parentTranslationPath);
  const marketingCampaignsInfo = useSelector((state) => state.MarketingCampaignsReducer);
  const dispatch = useDispatch();
  
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [state, setState] = useReducer(reducer, {
    title: null,
    description: null,
    deadline: moment().format('YYYY-MM-DDTHH:mm:ss'),
    assignedToId: null,
    campaignTaskAttachmentsDto: [],
  });
  const [selected, setSelected] = useReducer(reducer, {
    assignedTo: null,
    links: [],
    files: [],
  });
  const [isLoading, setIsLoading] = useReducer(reducer, {
    teamMembers: false,
    taskDetails: false,
    isSubmitted: false,
  });
  const [data, setData] = useReducer(reducer, {
    teamMembers: [],
    taskDetails: null,
  });

  const [isEditDataLoaded, setIsEditDataLoaded] = useState(false);
  const schema = Joi.object({
    assignedToId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}team-member-is-required`),
        'state.required': t(`${translationPath}team-member-is-required`),
      }),
    deadline:
      Joi.date()
        .required()
        .messages({
          'date.base': t(`${translationPath}deadline-is-required`),
          'state.required': t(`${translationPath}deadline-is-required`),
        }) || Joi.any(),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const mapSelectedFiles = (attachments) => {
    let selectedFiles = [];

    const localAttachmentFiles =
    attachments && attachments.filter((item) => item.fileId);

    selectedFiles =
      localAttachmentFiles &&
      localAttachmentFiles.map((item) => ({
        uuid: item.fileId,
        fileName: item.urlDescription || '-',
      }));

    return selectedFiles || [];
  }

  const mapSelectedLinks = (attachments) => {
    let selectedLinks = [];

    const localAttachmentLinks =
    attachments && attachments.filter((item) => item.url);

    selectedLinks =
      localAttachmentLinks &&
      localAttachmentLinks.map((item) => ({
        documentLink: item.url,
        fileName: item.urlDescription || '-',
      }));

    return selectedLinks || [];
  }

  const mapStateAttachements = () => {
    const allAttachments = [...(selected.files || []), ...(selected.links || [])];
    const localStateAttachments = allAttachments.map((item) => ({
      fileId: item.uuid,
      url: item.documentLink,
      urlDescription: item.fileName,
      }));

    return localStateAttachments || null;
  }
  const getAllCampaignTeamMembers = async () => {
    setIsLoading({ id: 'teamMembers', value: true });
    const res = await GetAllCampaignTeamMembers({
      requestId: marketingCampaignsInfo?.campaignRequestId,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'teamMembers', value: res || [] });
    } else {
      setData({ id: 'teamMembers', value: [] });
    }
    setIsLoading({ id: 'teamMembers', value: false });
  };

  const createCampaignTask = async () => {
    setIsLoading({ id: 'isSubmitted', value: true });
    const body = {
      ...state,
      id: marketingCampaignsInfo?.campaignRequestId,
      campaignTaskAttachmentsDto: mapStateAttachements(),
    };
    const res = await CreateCampaignTask(body);
    if (!(res && res.status && res.status !== 200)) {
      const campaignRequestId = res;
      if(campaignRequestId) await dispatch(MarketingCampaignsActions.marketingCampaignsRequest({
        campaignRequestId,
      }));

      showSuccess(t(`${translationPath}campaign-task-created-successfully`));
      if (onSave) await onSave(campaignRequestId);
    } else showError(t(`${translationPath}campaign-task-create-failed`));

    setIsLoading({ id: 'isSubmitted', value: false });
  };

  const updateCampaignTask = async () => {
    setIsLoading({ id: 'isSubmitted', value: true });
    const campaignTaskId = activeItem?.id;
    const body = {
      ...state,
      id: campaignTaskId,
      campaignTaskAttachmentsDto: mapStateAttachements(),
    };
    const res = await UpdateCampaignTask(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}campaign-task-updated-successfully`));
      if (onSave) onSave();
    } else showError(t(`${translationPath}campaign-task-update-failed`));

    setIsLoading({ id: 'isSubmitted', value: false });
  };

  const getTaskById = async () => {
    setIsLoading({ id: 'taskDetails', value: true });
    const campaignTaskId = activeItem?.id;

    const res = await GetTaskById({
      taskId: campaignTaskId,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'taskDetails', value: res || [] });
    } else setData({ id: 'taskDetails', value: [] });

    setIsLoading({ id: 'taskDetails', value: false });
  };

  const saveHandler = async (event) => {
    event.preventDefault();
    if (schema?.error?.message) {
      showError(schema.error.message);
      return;
    }

    if (activeItem) await updateCampaignTask();
    else await createCampaignTask();
  };

  useEffect(() => {
    if (data.taskDetails) {
      const assignedTo = data.taskDetails.assignedToId
        ? {
            id: data.taskDetails.assignedToId,
            name: data.taskDetails.assignedTo,
          }
        : null;


      setSelected({
        id: 'edit',
        value: {
          assignedTo,
          files: mapSelectedFiles(data.taskDetails.taskAttachmentsDto),
          links: mapSelectedLinks(data.taskDetails.taskAttachmentsDto),
        },
      });
      setState({
        id: 'edit',
        value: {
          title: data.taskDetails.title,
          description: data.taskDetails.description,
          deadline: data.taskDetails.deadline,
          assignedToId: data.taskDetails.assignedToId,
          campaignTaskAttachmentsDto: data.taskDetails.taskAttachmentsDto,
        },
      });
      setIsEditDataLoaded(true);
    }
  }, [data.taskDetails]);

  useEffect(() => {
    if (activeItem) getTaskById();
  }, [activeItem]);
  return (
    <div>
      <DialogComponent
        isOpen={isDialogOpen}
        maxWidth='md'
        titleText={t(`${translationPath}${(activeItem && 'edit-task') || 'add-new-task'}`)}
        dialogContent={
          <>
        <form noValidate onSubmit={saveHandler}>
          <DialogContent>
            <div className='camp-req-f1 camp-req-art-suggest-f1'>
              <div className='d-flex'>
                <div className='w-50 mx-2'>
                  <div className='my-3'>
                    <Inputs
                      idRef='titleRef'
                      labelValue='title'
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      value={state.title || ''}
                      onInputChanged={(event) =>
                        setState({ id: 'title', value: event?.target?.value })
                      }
                    />
                  </div>
                  <div className='my-3'>
                    <DatePickerComponent
                      idRef='deadlineRef'
                      labelValue='deadline'
                      labelClasses='Requierd-Color'
                      value={state.deadline}
                      minDate={activeItem? activeItem.date : moment().format('YYYY-MM-DDTHH:mm:ss')}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onDateChanged={(newValue) => {
                        const formattedDeadline =
                          newValue && moment(newValue).format('YYYY-MM-DDTHH:mm:ss');
                        setState({
                          id: 'deadline',
                          value: formattedDeadline,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className='w-50 mx-2'>
                  <div className='my-3'>
                    <AutocompleteComponent
                      idRef='campaignTeamMemberRef'
                      labelValue={t(`${translationPath}team-Member`)}
                      selectedValues={selected.assignedTo || []}
                      labelClasses='Requierd-Color'
                      isDisabled={isLoading.teamMembers}
                      data={data.teamMembers || []}
                      displayLabel={(option) => option.name || ''}
                      multiple={false}
                      withoutSearchButton
                      onChange={(event, newValue) => {
                        setState({
                          id: 'assignedToId',
                          value: newValue?.id || null,
                        });
                        setSelected({
                          id: 'assignedTo',
                          value: newValue,
                        });
                      }}
                      onOpen={() => {
                        if (data.teamMembers && data.teamMembers.length == 0)
                          getAllCampaignTeamMembers();
                      }}
                      isLoading={isLoading.teamMembers}
                    />
                  </div>

                  <div className='mt-3'>
                    <Inputs
                      idRef='descriptionRef'
                      labelValue='description'
                      value={state.description || ''}
                      multiline
                      rows={4}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onInputChanged={(event) =>
                        setState({ id: 'description', value: event?.target?.value })
                      }
                    />
                  </div>
                </div>
              </div>
              {(isEditDataLoaded || !activeItem) && 
              <div className='camp-task-f1'>
                <fieldset className='input-fieldset'>
                  <legend className='input-fieldset-legend'>
                    {t(`${translationPath}attachments`)}
                  </legend>
                  <div>
                    <div className='w-100'>
                      <ActivityFilesUploader state={selected} setState={setSelected} />
                    </div>
                    <div className='uploader-type w-50 '>
                      <LinkUploader
                        initUploaded={selected.links || []}
                        uploadedChanged={(links) => {
                          setSelected({ id: 'links', value: links });
                        }}
                      />
                    </div>
                  </div>
                </fieldset>
              </div>}
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonBase onClick={onClose} className='btns theme-solid bg-cancel'>
              {t(`cancel`)}
            </ButtonBase>
            <ButtonBase className='btns theme-solid' 
             disabled={isLoading.isSubmitted || isLoading.taskDetails}
             onClick={saveHandler} type='submit'>
              {t(`save`)}
            </ButtonBase>
          </DialogActions>
        </form>
        </>}
      />
    </div>
  );
};
