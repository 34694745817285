import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
  AutocompleteComponent,
  CheckboxesComponent,
  DialogComponent,
  Spinner,
  SwitchComponent,
} from '../../../../Components';
import '../style/UnitDetailsDialog.scss';
import { UnitApprovalsPeriodInputComponent } from './UnitApprovalsPeriodInputComponent';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import { getErrorByName, showError, showSuccess } from '../../../../Helper';
import {
  CreateSettingsForUnitApproval,
  UpdateSettingsForUnitApproval,
} from '../../../../Services/UnitApprovalServices/UnitApprovalServices';
import { GetUsersByBranchId } from '../../../../Services';

export const UnitDetailsDialog = ({
  isDialogOpen,
  onSave,
  isOpenChange,
  parentTranslationPath,
  translationPath,
  data,
  isLoading,
  reducer,
  activeItem,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const checkboxConfigs = [
    {
      idRef: 'changeUnitFromDraftToAvailableIdRef',
      label: t(`${translationPath}change-to-available`),
      stateKey: 'isChangeUnitFromDraftToAvailable',
      fieldKey: 'Status',
    },
    {
      idRef: 'unitNumberIdRef',
      label: t(`${translationPath}unit-number`),
      stateKey: 'isUnitNumber',
      fieldKey: 'unit_number',
    },
    {
      idRef: 'unitTypeIdRef',
      label: t(`${translationPath}unit-type`),
      stateKey: 'isUnitType',
      fieldKey: 'unit_type',
    },
    {
      idRef: 'propertyNameIdRef',
      label: t(`${translationPath}property-name`),
      stateKey: 'isPropertyName',
      fieldKey: 'property_name',
    },
    {
      idRef: 'salesTypeIdRef',
      label: t(`${translationPath}sales-type`),
      stateKey: 'isSalesType',
      fieldKey: 'sale_type',
    },
    {
      idRef: 'bedroomsIdRef',
      label: t(`${translationPath}bedrooms`),
      stateKey: 'isBedrooms',
      fieldKey: 'bedrooms',
    },
    {
      idRef: 'bathroomsIdRef',
      label: t(`${translationPath}bathrooms`),
      stateKey: 'isBathrooms',
      fieldKey: 'bathrooms',
    },
    {
      idRef: 'unitAreaSizeIdRef',
      label: t(`${translationPath}unit-area-size`),
      stateKey: 'isUnitAreaSize',
      fieldKey: ['builtup_area_sqft', 'builtup_area_sqm'],
    },
    {
      idRef: 'sellingPriceIdRef',
      label: t(`${translationPath}selling-price`),
      stateKey: 'isSellingPrice',
      fieldKey: ['selling_price_agency_fee', 'discount'],
    },
    {
      idRef: 'rentPerYearIdRef',
      label: t(`${translationPath}rent-per-year`),
      stateKey: 'isRentPerYear',
      fieldKey: 'rent_price_fees',
    },
  ];

  const initialState = {
    branchId: undefined,
    expirationPeriod: undefined,
    durationType: 1,
    isSendNotification: false,
    isSendEmail: false,
    excludedRoleIds: [],
    excludedUserIds: [],
    approverId: [],
    unitApprovalFields: [],
    approvalSettingType: undefined,
  };

  const [state, setState] = useReducer(reducer, initialState);

  const [selected, setSelected] = useReducer(reducer, {
    branch: {},
    roles: [],
    users: [],
    usersApproval: [],
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [userRelatedBranchData, setUserRelatedBranchData] = useState([]);

  const handleCreateUnitDetailsApproval = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (schema.error) {
      showError(t(`${translationPath}Please-fill-all-the-required-fields.`));
      return;
    }
    const body = { ...state, approvalSettingType: 2 };
    const res = activeItem
      ? await UpdateSettingsForUnitApproval(
          activeItem.unitApprovalSettingId,
          body
        )
      : await CreateSettingsForUnitApproval(body);
    if (!(res && res.status && res.status !== 200)) {
      {
        showSuccess(
          t(
            `${translationPath}${`setting-for-unit-approval-created-successfully`}`
          )
        );
        onSave();
      }
    } else if (res?.data?.Message) {
      const errorMessage =
        res?.data.Message.split(' : ')[1] || res.data.Message;
      showError(t(`${translationPath}${errorMessage}`));
    } else {
      showError(
        t(`${translationPath}${`setting-for-unit-approval-created-failed`}`)
      );
    }
  };

  const schema = Joi.object({
    branchId: Joi.number()
      .required()
      .messages({
        'number.empty': t(`${translationPath}branch-is-required`),
        'number.base': t(`${translationPath}branch-is-required`),
        'any.required': t(`${translationPath}branch-is-required`),
      }),
    approverId: Joi.array()
      .items(Joi.string())
      .min(1)
      .required()
      .messages({
        'number.empty': t(`${translationPath}user-approved-is-required`),
        'number.base': t(`${translationPath}user-approved-is-required`),
        'any.required': t(`${translationPath}user-approved-is-required`),
        'array.min': t(`${translationPath}user-approved-is-required`),
      }),
    expirationPeriod: Joi.number()
      .min(1)
      .required()
      .messages({
        'number.empty': t(`${translationPath}expiredPeriod-is-required`),
        'number.base': t(`${translationPath}expiredPeriod-is-required`),
        'any.required': t(`${translationPath}expiredPeriod-is-required`),
        'number.min': t(
          `${translationPath}expiredPeriod-must-be-greater-than-zero`
        ),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  useEffect(() => {
    if (activeItem) {
      setState({
        id: 'edit',
        value: {
          branchId: activeItem.branchId,
          expirationPeriod: activeItem.expirationPeriod,
          durationType: activeItem.durationTypeId,
          isSendNotification: activeItem.isSendNotification,
          isSendEmail: activeItem.isSendEmail,
          excludedRoleIds: activeItem.excludedRoleNames.map(
            (item) => item.roleId
          ),
          excludedUserIds: activeItem.excludedUserNames.map(
            (item) => item.excludedUserId
          ),
          approverId: activeItem.approverNames.map(
            (item) => item.approverUserId
          ),
          ...activeItem,
        },
      });
      setSelected({
        id: 'edit',
        value: {
          branch: {
            branchName: activeItem.branchName,
            branchId: activeItem.branchId,
          },
          roles: activeItem.excludedRoleNames.map((item) => ({
            rolesName: item.roleName,
            rolesId: item.roleId,
          })),
          users: activeItem.excludedUserNames.map((item) => ({
            userName: item.excludedUserName,
            userId: item.excludedUserId,
          })),
          usersApproval: activeItem.approverNames.map((item) => ({
            fullName: item.approverName,
            usersId: item.approverUserId,
          })),
        },
      });
    }
  }, [activeItem, data]);

  const getUsersByBranchId = useCallback(async ({ branchId }) => {
    if (!branchId) {
      return;
    }
    const res = await GetUsersByBranchId(branchId);
    if (!(res && res.status && res.status !== 200)) {
      setUserRelatedBranchData(res || []);
    } else setUserRelatedBranchData([]);
  }, []);
  const resetRelatedTo = () => {
    setState({ id: 'excludedUserIds', value: [] });
    setSelected({ id: 'users', value: [] });
  };
  useEffect(() => {
    getUsersByBranchId({ branchId: activeItem?.branchId });
  }, []);
  return (
    <>
      <Spinner
        isActive={isLoading && Object?.values(isLoading).some((load) => load)}
      />
      <DialogComponent
        isOpen={isDialogOpen}
        saveType='button'
        titleText={'unit-details'}
        saveClasses='btns theme-solid w-100 mx-2 mb-2'
        onCloseClicked={isOpenChange}
        onCancelClicked={isOpenChange}
        maxWidth={'sm'}
        onSaveClicked={handleCreateUnitDetailsApproval}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        dialogContent={
          <div className='unit-details-dialog-wrapper'>
            <div className='form-item w-100'>
              <div className='border-head'>
                <div className='section-1'>
                  <div className='action-box'>
                    {checkboxConfigs.map((config) => {
                      const isChecked = Array.isArray(config.fieldKey)
                        ? config.fieldKey.some((key) =>
                            state?.unitApprovalFields?.includes(key)
                          )
                        : state?.unitApprovalFields?.includes(config.fieldKey);

                      return (
                        <CheckboxesComponent
                          key={config.idRef}
                          idRef={config.idRef}
                          label={config.label}
                          singleChecked={isChecked}
                          onSelectedCheckboxChanged={(event) => {
                            if (event?.target?.checked) {
                              setState({
                                id: 'unitApprovalFields',
                                value: state.unitApprovalFields.concat(
                                  config.fieldKey
                                ),
                              });
                            } else
                              setState({
                                id: 'unitApprovalFields',
                                value: Array.isArray(config.fieldKey)
                                  ? state.unitApprovalFields.filter(
                                      (item) => !config.fieldKey.includes(item)
                                    )
                                  : state.unitApprovalFields.filter(
                                      (item) => item !== config.fieldKey
                                    ),
                              });
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className='line'></div>
                <div className='section-2'>
                  <div className='form-item w-25'>
                    <AutocompleteComponent
                      idRef='branchIdRef'
                      labelValue='branch'
                      data={data?.branches || []}
                      withoutSearchButton
                      multiple={false}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      displayLabel={(option) => option?.branchName || ''}
                      renderOption={(option) => option?.branchName || ''}
                      getOptionSelected={(option) =>
                        option.branchId === state.branchId
                      }
                      selectedValues={selected?.branch || {}}
                      isLoading={isLoading?.branches}
                      onChange={(e, newValue) => {
                        setState({ id: 'branchId', value: newValue?.branchId });
                        setSelected({ id: 'branch', value: newValue ?? {} });
                        resetRelatedTo();
                        getUsersByBranchId({
                          branchId: newValue?.branchId || null,
                        });
                      }}
                      isWithError
                      isSubmitted={isSubmitted}
                      helperText={getErrorByName(schema, 'branchId').message}
                      error={getErrorByName(schema, 'branchId').error}
                    />
                    <AutocompleteComponent
                      idRef='RoleIdRef'
                      labelValue='role-without-approval'
                      data={data?.roles || []}
                      displayLabel={(option) => option?.rolesName || ''}
                      withoutSearchButton
                      multiple
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      isLoading={isLoading?.roles}
                      chipsLabel={(option) => option.rolesName || ''}
                      selectedValues={selected?.roles || []}
                      onChange={(e, newValue) => {
                        setState({
                          id: 'excludedRoleIds',
                          value: newValue?.map((role) => role.rolesId),
                        });
                        setSelected({ id: 'roles', value: newValue });
                      }}
                      inputPlaceholder={t(`${translationPath}select-multiple`)}
                    />
                    <AutocompleteComponent
                      labelValue='user-without-approval'
                      idRef='userWithoutIdRef'
                      data={userRelatedBranchData || []}
                      withoutSearchButton
                      multiple
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      displayLabel={(option) => option?.userName || ''}
                      isLoading={isLoading?.users}
                      chipsLabel={(option) => option.userName || ''}
                      selectedValues={selected?.users || []}
                      onChange={(e, newValue) => {
                        setState({
                          id: 'excludedUserIds',
                          value: newValue?.map((user) => user.userId),
                        });
                        setSelected({ id: 'users', value: newValue });
                      }}
                      inputPlaceholder={t(`${translationPath}select-multiple`)}
                      isDisabled={!state.branchId}
                      
                    />
                    <AutocompleteComponent
                      labelValue='approved-by'
                      idRef='approvedByIdRef'
                      data={data?.usersApproval || []}
                      withoutSearchButton
                      displayLabel={(option) => option?.fullName || ''}
                      multiple
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      isLoading={isLoading?.users}
                      chipsLabel={(option) => option.fullName || ''}
                      selectedValues={selected?.usersApproval || []}
                      onChange={(e, newValue) => {
                        setState({
                          id: 'approverId',
                          value: newValue?.map((user) => user.usersId),
                        });
                        setSelected({ id: 'usersApproval', value: newValue });
                      }}
                      inputPlaceholder={t(`${translationPath}select-multiple`)}
                      helperText={getErrorByName(schema, 'approverId').message}
                      error={getErrorByName(schema, 'approverId').error}
                      isWithError
                      isSubmitted={isSubmitted}
                    />
                    <div className='period-input'>
                      <UnitApprovalsPeriodInputComponent
                        state={state}
                        setState={(item) => {
                          setState({
                            id: 'edit',
                            value: {
                              ...state,
                              ...item,
                            },
                          });
                        }}
                        isSubmitted={isSubmitted}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        schema={schema}
                        setSelected={setSelected}
                        selected={selected}
                      />
                    </div>
                    <div className='switch-component'>
                      <SwitchComponent
                        idRef='notificationRef'
                        label='notification'
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        isChecked={state?.isSendNotification}
                        onChangeHandler={(e, isChecked) =>
                          setState({
                            id: 'isSendNotification',
                            value: isChecked,
                          })
                        }
                      />
                      <SwitchComponent
                        idRef='emailRef'
                        label='email'
                        isChecked={state?.isSendEmail}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChangeHandler={(e, isChecked) =>
                          setState({ id: 'isSendEmail', value: isChecked })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};
