import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getProperties, lookupItemsGetId } from '../../Services';
import { StaticLookupsIds } from '../../assets/json/StaticLookupsIds.jsx';
import { AutocompleteComponent } from '../../Components';

export const LocationFieldsComponent = ({
  parentTranslationPath,
  translationPath,
  isWithoutCountry,
  isWithoutCity,
  isWithoutDistrict,
  isWithoutCommunity,
  isWithoutSubCommunity,
  onChangeHandlers,
  isClearFiltersClicked,
  filterCriteriaChangeHandler,
}) => {
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  const [selected, setSelected] = useReducer(reducer, {
    Country: null,
    City: null,
    District: null,
    Community: null,
    SubCommunity: null,
  });
  const [isLoading, setIsLoading] = useReducer(reducer, {
    Country: false,
    Cities: false,
    District: false,
    Community: false,
    SubCommunity: false,
  });
  const [data, setData] = useReducer(reducer, {
    Country: [],
    Cities: [],
    District: [],
    Community: [],
    SubCommunity: [],
  });

  const getAddressLookupsById = async (lookupTypeName, lookupParentId) => {
    setIsLoading({ id: lookupTypeName, value: true });
    const response = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds[lookupTypeName],
      lookupParentId,
    });
    if (!(response && response.status && response.status !== 200)) {
      setData({ id: lookupTypeName, value: response || [] });
    }
    setIsLoading({ id: lookupTypeName, value: false });
  };

  const autoFillLocationHandler = (locationKey, locationValue) => {
    const lookupItemParents = JSON.parse(locationValue.lookupItemParents);
    const autoFilledLocation = {};
    lookupItemParents.forEach(
      (item) =>
        (autoFilledLocation[item.LookupType] = {
          lookupItemId: item.LookupItemId,
          lookupItemName: item.LookupItemName,
          lookupItemCode: item?.LookupItemCode || null,
        })
    );

    setSelected({
      id: 'edit',
      value: {
        Country: null,
        City: null,
        District: null,
        Community: null,
        SubCommunity: null,
        ...autoFilledLocation,
        [locationKey]: locationValue,
      },
    });
  };
  const getPropertiesOptions = async (searchValue) => {
    const res = await getProperties({
      pageSize: 10,
      pageIndex: 0,
      search: searchValue || '',
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'properties', value: res?.result || [] });
    else setData({ id: 'properties', value: [] });
  };

  useEffect(() => {
    if (isClearFiltersClicked) {
      setSelected({
        id: 'edit',
        value: {
          Country: null,
          City: null,
          District: null,
          Community: null,
          SubCommunity: null,
        },
      });
    }
  }, [isClearFiltersClicked]);

  return (
    <div className='d-flex'>
      {!isWithoutCountry && (
        <AutocompleteComponent
          idRef='countryfilterRef'
          labelValue={t(`${translationPath}country`)}
          selectedValues={selected.Country || []}
          wrapperClasses='w-min-unset m-2'
          multiple={false}
          data={data.Country || []}
          displayLabel={(option) => option.lookupItemName || ''}
          withoutSearchButton
          isWithError
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isLoading={isLoading.Country}
          onOpen={() => {
            if (data.Country && data.Country.length === 0)
              getAddressLookupsById('Country');
          }}
          onChange={(_, newValue) => {
            setSelected({
              id: 'edit',
              value: {
                Country: newValue,
                City: null,
                District: null,
                Community: null,
                SubCommunity: null,
              },
            });
            onChangeHandlers?.countryHandler &&
              onChangeHandlers.countryHandler(newValue);
          }}
        />
      )}
      {!isWithoutCity && (
        <AutocompleteComponent
          idRef='cityfilterRef'
          labelValue={t(`${translationPath}city`)}
          selectedValues={selected.City || []}
          wrapperClasses='w-min-unset m-2'
          multiple={false}
          data={data.Cities || []}
          isLoading={isLoading.City}
          displayLabel={(option) => option.lookupItemName || ''}
          withoutSearchButton
          isWithError
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onOpen={() => {
            const countryId = selected.Country?.lookupItemId;
            if (countryId) getAddressLookupsById('Cities', countryId);
            else getAddressLookupsById('Cities');
          }}
          onChange={(_, newValue) => {
            if (newValue) autoFillLocationHandler('City', newValue);
            else
              setSelected({
                id: 'edit',
                value: {
                  ...selected,
                  City: newValue,
                  District: null,
                  Community: null,
                  SubCommunity: null,
                },
              });

            onChangeHandlers?.cityHandler &&
              onChangeHandlers.cityHandler(newValue);
          }}
        />
      )}
      {!isWithoutDistrict && (
        <AutocompleteComponent
          idRef='lfilterDistricteIdRef'
          labelValue={t(`${translationPath}district`)}
          wrapperClasses='w-min-unset m-2'
          selectedValues={selected.District || []}
          multiple={false}
          data={data.District || []}
          isLoading={isLoading.District}
          onOpen={() => {
            const cityId = selected.City?.lookupItemId;
            if (cityId) getAddressLookupsById('District', cityId);
            else getAddressLookupsById('District');
          }}
          displayLabel={(option) => option.lookupItemName || ''}
          withoutSearchButton
          isWithError
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(_, newValue) => {
            if (newValue) autoFillLocationHandler('District', newValue);
            else
              setSelected({
                id: 'edit',
                value: {
                  ...selected,
                  District: newValue,
                  Community: null,
                  SubCommunity: null,
                },
              });

            onChangeHandlers?.districtHandler &&
              onChangeHandlers.districtHandler(newValue);
          }}
        />
      )}
      {!isWithoutCommunity && (
        <AutocompleteComponent
          idRef='lfilterCommunitiesIdRef'
          labelValue={t(`${translationPath}community`)}
          wrapperClasses='w-min-unset m-2'
          selectedValues={selected.Community || null}
          multiple={false}
          data={data.Community || []}
          isLoading={isLoading.Community}
          onOpen={() => {
            const districtId = selected.District?.lookupItemId;
            if (districtId) getAddressLookupsById('Community', districtId);
            else getAddressLookupsById('Community');
          }}
          displayLabel={(option) => option.lookupItemName || ''}
          withoutSearchButton
          isWithError
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(_, newValue) => {
            if (newValue) autoFillLocationHandler('Community', newValue);
            else
              setSelected({
                id: 'edit',
                value: { ...selected, Community: newValue, SubCommunity: null },
              });

            onChangeHandlers?.communityHandler &&
              onChangeHandlers.communityHandler(newValue);
          }}
        />
      )}

      {!isWithoutSubCommunity && (
        <AutocompleteComponent
          idRef='lfilterSubCommunitiesIdRef'
          labelValue={t(`${translationPath}SubCommunity`)}
          wrapperClasses='w-min-unset m-2'
          selectedValues={selected.SubCommunity || null}
          multiple={false}
          data={data.SubCommunity || []}
          isLoading={isLoading.SubCommunity}
          onOpen={() => {
            const communityId = selected.Community?.lookupItemId;
            if (communityId) getAddressLookupsById('SubCommunity', communityId);
            else getAddressLookupsById('SubCommunity');
          }}
          displayLabel={(option) => option.lookupItemName || ''}
          withoutSearchButton
          isWithError
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(_, newValue) => {
            if (newValue) autoFillLocationHandler('SubCommunity', newValue);
            else
              setSelected({
                id: 'edit',
                value: { ...selected, SubCommunity: newValue },
              });

            onChangeHandlers?.subCommunityHandler &&
              onChangeHandlers.subCommunityHandler(newValue);
          }}
        />
      )}
      <AutocompleteComponent
        idRef='propertiesRef'
        inputPlaceholder={t(`${translationPath}property`)}
        labelValue={t(`${translationPath}property`)}
        wrapperClasses='w-min-unset m-2'
        selectedValues={selected.property || null}
        multiple={false}
        data={data.properties || []}
        displayLabel={(option) =>
          option?.property?.property_name || option?.name || ''
        }
        withoutSearchButton
        isWithError
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(_, newValue) => {
          setSelected({ id: 'property', value: newValue });

          const propertyKey = 'property_name';
          const propertyValue = newValue?.property?.property_name || null;
          const searchType = 1;

          filterCriteriaChangeHandler(propertyKey, propertyValue, searchType);
        }}
        onOpen={() => {
          if (data.properties && data.properties.length == 0)
            getPropertiesOptions();
        }}
        onInputKeyUp={(e) => {
          const { value } = e.target;
          if (searchTimer.current) clearTimeout(searchTimer.current);
          searchTimer.current = setTimeout(() => {
            getPropertiesOptions(value);
          }, 600);
        }}
      />
    </div>
  );
};
