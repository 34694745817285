import React, { useState } from "react";
import PropTypes from "prop-types";
import MUIButton from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip"; // Import Tooltip from Material-UI
import { useSelectedTheme } from "../../../Hooks";

// Icons
import { GetHelpIcon } from "../../../assets/icons";

// styles
import useStyles from "./styles";

function CustomButton({
  boxShadow,
  size,
  variant,
  children,
  startIcon,
  endIcon,
  color,
  hasToolTip, // New prop to handle tooltip visibility
  toolTipMessage, // New prop to display the tooltip message
  ...restProps
}) {
  const [hovered, setHovered] = useState(false);
  const {
    theme: { palette },
  } = useSelectedTheme();

  let elevation = 0;
  switch (boxShadow) {
    case "none":
      elevation = 0;
      break;
    case "xs":
      elevation = 1;
      break;
    case "sm":
      elevation = 2;
      break;
    case "md":
      elevation = 3;
      break;
    case "lg":
      elevation = 4;
      break;
    case "xl":
      elevation = 5;
      break;
    case "2xl":
      elevation = 6;
      break;
    case "3xl":
      elevation = 7;
      break;
    default:
      elevation = 0;
      break;
  }

  const sizeStyles = {
    sm: {
      padding: "8px 12px",
      fontSize: "14px",
      minHeight: "36px",
      gap: "4px",
      lineHeight: "20px",
    },
    md: {
      padding: "10px 14px",
      fontSize: "14px",
      minHeight: "40px",
      gap: "4px",
      lineHeight: "20px",
    },
    lg: {
      padding: "10px 16px",
      fontSize: "16px",
      minHeight: "44px",
      gap: "6px",
      lineHeight: "24px",
    },
    xl: {
      padding: "12px 18px",
      fontSize: "16px",
      minHeight: "48px",
      gap: "6px",
      lineHeight: "24px",
    },
    "2xl": {
      padding: "16px 22px",
      fontSize: "18px",
      minHeight: "60px",
      gap: "10px",
      lineHeight: "28px",
    },
    popupAction: {
      padding: "0px 16px",
      fontSize: "16px",
      minHeight: "40px",
      gap: "6px",
      lineHeight: "24px",
      width: "130px",
    },
  };

  const styles = useStyles({
    elevation,
    sizeStyles: sizeStyles[size],
    color,
    variant,
  });

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <MUIButton
      className={styles.root}
      disableRipple
      startIcon={startIcon}
      endIcon={
        hasToolTip ? (
          <Tooltip
            placement="top"
            title={hasToolTip && toolTipMessage ? toolTipMessage : ""}
            open={hovered}
            classes={{
              tooltip: styles.tooltip,
            }}
            disableHoverListener // Prevent Material-UI's default hover behavior
          >
            <span
              className={styles.endIconSpan}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <span
                className={styles.endIconSpan}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <GetHelpIcon
                  width="20"
                  height="20"
                  fill={
                    hovered
                      ? palette.foreground.quarterary
                      : palette.foreground.quinary
                  }
                />
              </span>
            </span>
          </Tooltip>
        ) : (
          endIcon
        )
      }
      variant={variant}
      {...restProps}
    >
      {children}
    </MUIButton>
  );
}

CustomButton.propTypes = {
  boxShadow: PropTypes.oneOf([
    "none",
    "xs",
    "sm",
    "md",
    "lg",
    "xl",
    "2xl",
    "3xl",
  ]),
  size: PropTypes.oneOf(["sm", "md", "lg", "xl", "2xl", "popupAction"]),
  variant: PropTypes.oneOf(["contained", "text", "outlined"]),
  hasToolTip: PropTypes.bool, // Prop to indicate if the button has a tooltip
  toolTipMessage: PropTypes.string, // Tooltip message
  ...MUIButton.propTypes,
};

CustomButton.defaultProps = {
  size: "sm",
  boxShadow: "none",
  variant: "contained",
  color: "primary",
  hasToolTip: false, // Default value for tooltip
  toolTipMessage: "", // Default tooltip message
};

export default CustomButton;
