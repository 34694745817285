


import React , { useState , useEffect  , useRef ,  useCallback  } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CheckboxesComponent , AutocompleteComponent  } from '../../../../../../../Components' ; 
import { OrganizationUserSearch } from '../../../../../../../Services' ; 


export const AgentsPolicies  = ({
  parentTranslationPath,
  translationPath,
  state ,
  setState ,
  checkAtLeastOneSelected , 
  checkAtLeastOneSelected2 , 
  setDefaultSelected , 
}) => {

    const { t } = useTranslation(parentTranslationPath);
    const [allUsers , setAllUsers] =  useState([]) ;
    const [isLoading ,   setIsLoading ] = useState(false) ; 

    const searchTimer = useRef(null);
    let timer = null; 

    const getAllOrganizationUserSearch = useCallback(async (searchValue) => {
      setIsLoading(true);
      const res = await OrganizationUserSearch({ pageIndex: 0, pageSize: 10, name: searchValue  , userStatusId : 2 });
      if (!(res && res.status && res.status !== 200)) 
      setAllUsers(res.result.map((item) => ({
        id :  item.id , 
        fullName : item.fullName  , 
        branch : item.branch  || null  , 
      })));
       else 
       setAllUsers([]);  

       setIsLoading(false);

    }, []); 

    useEffect(() => {
      getAllOrganizationUserSearch() ;
    }, []); 

  return (
        <div className='content'>
        <div className='w-100 px-2 mt-2 checkBoxsPart'>
          <div className='checkboxesItem'>
           <CheckboxesComponent
            idRef='view-all-agent-Ref'
            label={t(`${translationPath}view-all-agents`)}
            singleChecked={state.agents.agents.allUsers}
            onSelectedCheckboxClicked={(e)=> {
               const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.agents.agents ,  allUsers : e.target.checked } );
               if(checkAtLeastOneSelected2(state.agents.agents.specificUsers));
                else if(!checkIfCanSelected)
                  return ; 

               const agents =  { ...state.agents.agents ,
                  allUsers : (e.target.checked)  , 
                  myTeamUsers: false,
                  myBusinessGroupUsers: false,
                  myManageUsers: false,
                  myBranchUsers: false,
                  specificUsers: [],
                  myTeamLeadUsers: false
    
                } ;
             setState({id : 'agents' , value : { ...state.agents , agents :   agents} });
            }}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
           />
          </div>
        <div className='checkboxesItem'>
          <CheckboxesComponent
            idRef='agent-my-Team-Users-Ref'
            label={t(`${translationPath}myTeamUsers`)}
            singleChecked={state.agents.agents.myTeamUsers}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.agents.agents ,  myTeamUsers : e.target.checked } );
              if(checkAtLeastOneSelected2(state.agents.agents.specificUsers));
               else if(!checkIfCanSelected)
                return ; 
              
               const agents =  { ...state.agents.agents ,
                  allUsers : false  , 
                  myTeamUsers :(e.target.checked)  , 
                  myBusinessGroupUsers : false , 
                } ;

             setState({id : 'agents' , value : { ...state.agents , agents :   agents} })}}
         />
        </div>
        <div className='checkboxesItem'>
           <CheckboxesComponent
             idRef='agents-my-Business-Group-Users-Ref'
             label={t(`${translationPath}view-my-business-group-users`)}
             parentTranslationPath={parentTranslationPath}
             translationPathForData={translationPath}
             singleChecked={state.agents.agents.myBusinessGroupUsers}
             onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.agents.agents ,  myBusinessGroupUsers : e.target.checked } );

              if(checkAtLeastOneSelected2(state.agents.agents.specificUsers));
                else if(!checkIfCanSelected)
                     return ;
               
               const agents =  { ...state.agents.agents ,
                  allUsers : false  , 
                  myTeamUsers : false , 
                  myBusinessGroupUsers :  (e.target.checked) , 
                } ;
             setState({id : 'agents' , value : { ...state.agents , agents :   agents} }) ;
            }}
         />
         </div>
         <div className='checkboxesItem'>
           <CheckboxesComponent
            idRef='agent-view-my-branch-users-Ref'
            label={t(`${translationPath}view-my-branch-users`)}
            singleChecked={state.agents.agents.myBranchUsers}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.agents.agents ,  myBranchUsers : e.target.checked } );
            if(checkAtLeastOneSelected2(state.agents.agents.specificUsers));
                else if(!checkIfCanSelected)
                     return ; 
              
               const agents =  { ...state.agents.agents ,
                  allUsers : false  , 
                  myBranchUsers :  (e.target.checked)
                } ;
             setState({id : 'agents' , value : { ...state.agents , agents : agents} });

            }}
         />
         </div>
         <div className='checkboxesItem'>
           <CheckboxesComponent
            idRef='agents_view-my-managed-team-agentsRef'
            label={t(`${translationPath}view-my-managed-team-agents`)}
            singleChecked={state.agents.agents.myManageUsers}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.agents.agents ,  myManageUsers : e.target.checked } );
              
              if(checkAtLeastOneSelected2(state.agents.agents.specificUsers));
                else if(!checkIfCanSelected)
                     return ;
              
               const agents =  { ...state.agents.agents ,
                  allUsers : false  , 
                  myManageUsers : (e.target.checked) ,
                } ;
              setState({id : 'agents' , value : { ...state.agents , agents : agents} });
                }}
             />
         </div>
         <div className='checkboxesItem'>
           <CheckboxesComponent
            idRef='agents-my-team-lead-users-Ref'
            label={t(`${translationPath}view-my-team-lead-users`)}
            singleChecked={state.agents.agents.myTeamLeadUsers}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.agents.agents ,  myTeamLeadUsers : e.target.checked } );
              
              if(checkAtLeastOneSelected2(state.agents.agents.specificUsers));
                else if(!checkIfCanSelected)
                     return ;
              
               const agents =  { ...state.agents.agents ,
                  allUsers : false  , 
                  myTeamLeadUsers : (e.target.checked) ,
                } ;
                setState({id : 'agents' , value : { ...state.agents , agents : agents} });
                }}
             />
         </div>
         <div className='w-100 px-2 mt-2 mb-2'>
          <AutocompleteComponent
              idRef='agentSpecificsUsersRef'
              labelValue={t(`${translationPath}specific_users`)}
              data={ allUsers || []}
              selectedValues={state.agents.agents.specificUsers}
              multiple
              displayLabel={(option) =>
              (option && option.fullName) || ''}
              chipsLabel={(option) => (option && option.fullName) || ''}
              withoutSearchButton
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                 if (searchTimer.current) clearTimeout(searchTimer.current);
                   searchTimer.current = setTimeout(() => {
                    getAllOrganizationUserSearch(value) ;  
                }, 700); 
                }}
                renderOption={
                  ((option) =>
                  <div className='d-flex-column'>
                   <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                    {option.fullName}
                   </div>
                   <span className='c-gray-secondary'> {option.branch} </span>
                 </div>
               )}
              isDisabled={state.agents.agents.allUsers}
              value={state.agents.agents.specificUsers}
              onChange={(e, newValues) => 
              {
                let agents = { ...state.agents.agents  , specificUsers : newValues };
                if( !newValues.length && setDefaultSelected({ ...state.agents.agents , specificUsers :false }))
                agents = { ...state.agents.agents  , specificUsers : newValues , allUsers : true }
                
                setState({ id: 'agents', value: { ...state.agents , agents  : agents } })

              }}
             isLoading={isLoading} 
             withLoader
             filterOptions={(options) => {
              const isFind = (id) => state.agents.agents.specificUsers.findIndex((w) => w.id === id) === -1;
             return options.filter((w) => isFind(w.id));
            }}
         /> 
        </div>

       </div>
      </div>
  );
};

AgentsPolicies.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state:PropTypes.instanceOf(Object).isRequired,
  setState : PropTypes.func.isRequired,
};

