export const isAdminInquiries = () => {
  const user = JSON.parse(localStorage.getItem("session"));

  const userRoles = user.roles || [];
  const isAdmin = userRoles.some(
    (role) => role.rolesName.toLowerCase() === "admin"
  );

  return isAdmin;
};
