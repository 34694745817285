
export const InquiryStatusEnum = {
    Open: {
        id: 1,
        name: "Open"
    },
    Assigned: {
        id: 2,
        name: "Assigned"
    },
    Canceled: {
        id: 3,
        name: "Canceled"
    },
    Completed: {
        id: 4,
        name: "Completed"
    },
    NoUnitMatching: {
        id: 5,
        name: "No Unit Matching"
    },
    NotAllAgentsCompletedtheInquiry: {
        id: 6,
        name: "Not All Agents Completed the Inquiry"
    },
    NoSuitableAgent: {
        id: 7,
        name: "No Suitable Agent"
    },
    NoSuitableScheme: {
        id: 8,
        name: "No Suitable Scheme"
    },
}