import { UnitsSalesPermissions, LeadOwnerUnitLeasePermissions } from '../../../../../../Permissions';
import {
  UnitInformationComponent,
  UnitProfileGalleryComponent,
  UnitProfileMapComponent,
  UnitProfileDocumentsComponent,
  UnitProfileRemarksComponent,
  UnitProfileOpenHouseComponent,
  UnitProfileActivitiesComponent,
  UnitProfileSaleDetailsComponent,
  UnitProfileBuyerSummaryComponent,
  UnitProfilePaymentPlanComponent,
  OwnersUnit,
  UnitProfileMatchingComponent,
  UnitProfileProposalHistoryComponent,
  UnitProfileTenantComponent
} from '../../../../UnitsSalesView';
import { Marketing } from '../../../../UnitsSalesView/UnitsSalesProfileManagement/Sections/Marketing/Marketing';
import { Marketing as LeaseMarketing } from '../../../../UnitsLeaseView/UnitsLeaseProfileManagementView/Sections/Marketing/Marketing';
import { Marketing as LeadOwnerSaleUnitMarketing } from '../../../../UnitsSaleLeadOwnerView/UnitsSalesProfileManagement/Sections/Marketing/Marketing';
import { Marketing as LeadOwnerLeaseUnitMarketing } from '../../../../UnitsLeaseLeadOwnerView/UnitsLeaseProfileManagementView/Sections/Marketing/Marketing';

import {
  UnitProfileReferanceDetailsComponent,
  UnitParkingComponent,
} from '../../../../UnitsView/UnitsProfileManagementView/Sections';

import {
  UnitInformationComponent as LeaseUnitInformationComponent,
  UnitProfileGalleryComponent as LeaseUnitProfileImagesComponent,
  UnitProfileMapComponent as LeaseUnitProfileMapComponent,
  UnitProfileDocumentsComponent as LeaseUnitProfileDocumentsComponent,
  UnitProfileRemarksComponent as LeaseUnitProfileRemarksComponent,
  UnitProfileOpenHouseComponent as LeaseUnitProfileOpenHouseComponent,
  UnitProfileActivitiesComponent as LeaseUnitProfileActivitiesComponent,
  UnitProfilePaymentDetailsComponent as LeaseUnitProfilePaymentDetailsComponent,
  UnitProfilePayablesComponent as LeaseUnitProfilePayablesComponent,
  UnitProfileMatchingComponent as LeaseUnitProfileMatchingComponent,
  UnitProfileTenantComponent as LeaseUnitProfileTenantComponent,

} from '../../../../UnitsLeaseView/UnitsLeaseProfileManagementView/Sections';

import {
  UnitInformationComponent as LeadOwnerSaleUnitInformationComponent,
  UnitProfileGalleryComponent as LeadOwnerSaleUnitProfileGalleryComponent,
  UnitProfileMapComponent as LeadOwnerSaleUnitProfileMapComponent,
  UnitProfileDocumentsComponent as LeadOwnerSaleUnitProfileDocumentsComponent,
  UnitProfileRemarksComponent as LeadOwnerSaleUnitProfileRemarksComponent,
  UnitProfileOpenHouseComponent as LeadOwnerSaleUnitProfileOpenHouseComponent,
  UnitProfileActivitiesComponent as LeadOwnerSaleUnitProfileActivitiesComponent,
  UnitProfilePaymentDetailsComponent as LeadOwnerSaleUnitProfilePaymentDetailsComponent,
  UnitProfilePayablesComponent as LeadOwnerSaleUnitProfilePayablesComponent,
  OwnersUnit as LeadOwnerSaleUnitProfileOwnersComponent,
  UnitProfilePaymentPlanComponent as LeadOwnerSaleUnitProfilePaymentPlanComponent,
  UnitProfileBuyerSummaryComponent as LeadOwnerSaleUnitProfileBuyerSummaryComponent,

} from '../../../../UnitsSaleLeadOwnerView/UnitsSalesProfileManagement/Sections';

import { UnitProfileMatchingComponent as LeadOwnerSaleUnitProfileMatchingComponent, } from '../../../../UnitsSaleLeadOwnerView/UnitsSalesProfileManagement/Sections/UnitProfileMatchingComponent/UnitProfileMatchingComponent';
import { UnitProfileSaleDetailsComponent as LeadOwnerSaleUnitProfileSaleDetailsComponent } from '../../../../UnitsSaleLeadOwnerView';

import {
  UnitInformationComponent as LeadOwnerUnitLeaseInformationComponent,
  UnitProfileGalleryComponent as LeadOwnerUnitLeaseProfileImagesComponent,
  UnitProfileMapComponent as LeadOwnerUnitLeaseProfileMapComponent,
  UnitProfileDocumentsComponent as LeadOwnerUnitLeaseProfileDocumentsComponent,
  UnitProfileRemarksComponent as LeadOwnerUnitLeaseProfileRemarksComponent,
  UnitProfileOpenHouseComponent as LeadOwnerUnitLeaseProfileOpenHouseComponent,
  UnitProfileActivitiesComponent as LeadOwnerUnitLeaseProfileActivitiesComponent,
  UnitProfilePaymentDetailsComponent as LeadOwnerUnitLeaseProfilePaymentDetailsComponent,
  UnitProfilePayablesComponent as LeadOwnerUnitLeaseProfilePayablesComponent,
  UnitProfileMatchingComponent as LeadOwnerLeaseUnitProfileMatchingComponent

} from '../../../../UnitsLeaseLeadOwnerView/UnitsLeaseProfileManagementView/Sections';

import { UnitLeaseProfileManagementComponent as LeadOwnerUnitLeaseProfileManagementComponent } from '../../../../UnitsLeaseLeadOwnerView/UnitsLeaseProfileManagementView/Sections/UnitLeaseProfileManagementComponent/UnitLeaseProfileManagementComponent';

import { UnitsLeasePermissions } from '../../../../../../Permissions/Lease/UnitsLeasePermissions';
import { LeadOwnerUnitSalePermissions } from '../../../../../../Permissions/LeadOwnerPermissions/LeadOwnerUnitSalePermissions';
import { UnitLeaseProfileManagementComponent } from '../../../../UnitsLeaseView/UnitsLeaseProfileManagementView/Sections/UnitLeaseProfileManagementComponent/UnitLeaseProfileManagementComponent';
import { OwnersUnit as LeadOwnerLeaseUniOwnersUnit } from '../../../../UnitsLeaseLeadOwnerView/UnitsLeaseProfileManagementView/Sections/OwnersUnit/OwnersUnit';
import { OwnersUnit as LeaseUnitProfileOwnersComponent } from '../../../../UnitsLeaseView/UnitsLeaseProfileManagementView/Sections/OwnersUnit/OwnersUnit';
import {ProjectTasksView} from '../../../../TaskConfiguration/ProjectTasks/Utilities';
import { LeasingHistory } from '../../../../UnitsLeaseLeadOwnerView/UnitsLeaseProfileManagementView/Sections/LeasingHistory';

export const UnitsVerticalTabsData = {
  rent: [
    {
      label: 'unit-details',
      component: LeaseUnitInformationComponent,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId: UnitsLeasePermissions.ViewUnitDetails.permissionsId,
    },
    {
      label: 'parking',
      component: UnitParkingComponent,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId: UnitsLeasePermissions.ViewParkingInfoForUnit.permissionsId,
    },
    {
      label: 'Gallery',
      component: LeaseUnitProfileImagesComponent,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId: UnitsLeasePermissions.ViewUnitImages.permissionsId,
    },
    {
      label: 'map',
      component: LeaseUnitProfileMapComponent,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId: UnitsLeasePermissions.ViewUnitLocationOnMap.permissionsId,
    },
    {
      label: 'documents',
      component: LeaseUnitProfileDocumentsComponent,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId: UnitsLeasePermissions.ViewDocumentsForUnit.permissionsId,
    },
    {
      label: 'remarks',
      component: LeaseUnitProfileRemarksComponent,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId: UnitsLeasePermissions.ViewRemarksForUnit.permissionsId,
    },
    {
      label: 'open-house',
      component: LeaseUnitProfileOpenHouseComponent,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId: UnitsLeasePermissions.ViewOpenHouseForUnit.permissionsId,
    },
    {
      label: 'activities',
      component: LeaseUnitProfileActivitiesComponent,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId: UnitsLeasePermissions.ViewTheActivitiesForUnit.permissionsId,
    },
    {
      label: 'lease-details',
      component: LeaseUnitProfilePaymentDetailsComponent,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId: UnitsLeasePermissions.ViewUnitDetails.permissionsId,
    },
    {
      label: 'marketing',
      component: LeaseMarketing,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId: UnitsLeasePermissions.ViewUnitMarketingInfo.permissionsId,
    },
    {
      label: 'management',
      component: UnitLeaseProfileManagementComponent,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId: UnitsLeasePermissions.ViewManagemntForUnit.permissionsId,
    },
    {
      label: 'refernce-details',
      component: UnitProfileReferanceDetailsComponent,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId: UnitsLeasePermissions.ViewReferenceDetailsForUnit.permissionsId,
    },
    {
      label: 'payables',
      component: LeaseUnitProfilePayablesComponent,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId: UnitsLeasePermissions.ViewPayablesForUnit.permissionsId,
    },
    {
      label: 'owners',
      component: LeaseUnitProfileOwnersComponent,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId: UnitsLeasePermissions.GetAllOwnersByUnitId.permissionsId,
    },
    {
      label: 'matching',
      component: LeaseUnitProfileMatchingComponent,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId: UnitsLeasePermissions.ViewUnitMatching.permissionsId,
    },
    {
      label: 'Leasing History',
      component: LeasingHistory,
    },
    {
      label: 'tasks',
      component: ProjectTasksView,
      permissionsList: Object.values(UnitsLeasePermissions), 
      permissionsId: UnitsLeasePermissions.ViewTasksForUnit.permissionsId, 
    } ,
    {
      label: 'Proposal-history',
      component: UnitProfileProposalHistoryComponent,
      permissionsList: Object.values(UnitsLeasePermissions), 
      permissionsId:  UnitsLeasePermissions.ProposalHistory.permissionsId, 
    },
    {
      label: 'Tenant',
      component: UnitProfileTenantComponent,
      permissionsList: Object.values(UnitsLeasePermissions), 
      permissionsId:  UnitsLeasePermissions.ViewTenantTab.permissionsId, 
    }
  ],
  sale: [
    {
      label: 'unit-details',
      component: UnitInformationComponent,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId: UnitsSalesPermissions.ViewUnitDetails.permissionsId,
    },
    {
      label: 'parking',
      component: UnitParkingComponent,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId: UnitsSalesPermissions.ViewParkingInfoForUnit.permissionsId,
    },
    {
      label: 'Gallery',
      component: UnitProfileGalleryComponent,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId: UnitsSalesPermissions.ViewUnitImages.permissionsId,
    },
    {
      label: 'map',
      component: UnitProfileMapComponent,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId: UnitsSalesPermissions.ViewUnitLocationOnMap.permissionsId,
    },
    {
      label: 'documents',
      component: UnitProfileDocumentsComponent,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId: UnitsSalesPermissions.ViewDocumentsForUnit.permissionsId,
    },

    {
      label: 'remarks',
      component: UnitProfileRemarksComponent,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId: UnitsSalesPermissions.ViewRemarksForUnit.permissionsId,
    },
    {
      label: 'open-house',
      component: UnitProfileOpenHouseComponent,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId: UnitsSalesPermissions.ViewOpenHouseForUnit.permissionsId,
    },
    {
      label: 'activities',
      component: UnitProfileActivitiesComponent,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId: UnitsSalesPermissions.ViewTheActivitiesForUnit.permissionsId,
    },
    {
      label: 'sales-details',
      component: UnitProfileSaleDetailsComponent,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId: UnitsSalesPermissions.ViewSaleDetailsForUnit.permissionsId,
    },
    {
      label: 'buyer-summary',
      component: UnitProfileBuyerSummaryComponent,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId: UnitsSalesPermissions.ViewBuyerSummaryForUnit.permissionsId,
    },
    {
      label: 'refernce-details',
      component: UnitProfileReferanceDetailsComponent,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId: UnitsSalesPermissions.ViewReferenceDetailsForUnit.permissionsId,
    },
    {
      label: 'payment-plans',
      component: UnitProfilePaymentPlanComponent,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId: UnitsSalesPermissions.ViewPaymentPlansForUnit.permissionsId,
    },
    {
      label: 'marketing',
      component: Marketing,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId: UnitsSalesPermissions.ViewUnitMarketingInfo.permissionsId,
    },
    {
      label: 'owners',
      component: OwnersUnit,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId: UnitsSalesPermissions.GetAllOwnersByUnitId.permissionsId,
    },
    {
      label: 'matching',
      component: UnitProfileMatchingComponent,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId: UnitsSalesPermissions.ViewUnitMatching.permissionsId,
    },
    {
      label: 'tasks',
      component: ProjectTasksView,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId: UnitsSalesPermissions.ViewTasksForUnit.permissionsId ,
    },
    {
      label: 'Proposal-history',
      component: UnitProfileProposalHistoryComponent,
      permissionsList: Object.values(UnitsSalesPermissions), 
      permissionsId: UnitsSalesPermissions.ProposalHistory.permissionsId, 
    },
    {
      label: 'Tenant',
      component: UnitProfileTenantComponent,
      permissionsList: Object.values(UnitsSalesPermissions), 
      permissionsId: UnitsSalesPermissions.ViewTenantTab.permissionsId, 
    }
  ],
  unitSaleLeadOwner: [
    {
      label: 'unit-details',
      component: LeadOwnerSaleUnitInformationComponent,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId: LeadOwnerUnitSalePermissions.ViewUnitDetails.permissionsId,
    },
    {
      label: 'parking',
      component: UnitParkingComponent,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId: LeadOwnerUnitSalePermissions.ViewParkingInfoForUnit.permissionsId,
    },
    {
      label: 'Gallery',
      component: LeadOwnerUnitLeaseProfileImagesComponent,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId: LeadOwnerUnitSalePermissions.ViewUnitImages.permissionsId,
    },
    {
      label: 'map',
      component: LeadOwnerSaleUnitProfileMapComponent,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId: LeadOwnerUnitSalePermissions.ViewUnitLocationOnMap.permissionsId,
    },
    {
      label: 'documents',
      component: LeadOwnerSaleUnitProfileDocumentsComponent,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId: LeadOwnerUnitSalePermissions.ViewDocumentsForUnit.permissionsId,
    },

    {
      label: 'remarks',
      component: LeadOwnerSaleUnitProfileRemarksComponent,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId: LeadOwnerUnitSalePermissions.ViewRemarksForUnit.permissionsId,
    },
    {
      label: 'open-house',
      component: LeadOwnerSaleUnitProfileOpenHouseComponent,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId: LeadOwnerUnitSalePermissions.ViewOpenHouseForUnit.permissionsId,
    },
    {
      label: 'activities',
      component: LeadOwnerSaleUnitProfileActivitiesComponent,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId: LeadOwnerUnitSalePermissions.ViewTheActivitiesForUnit.permissionsId,
    },
    {
      label: 'sales-details',
      component: LeadOwnerSaleUnitProfileSaleDetailsComponent,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId: LeadOwnerUnitSalePermissions.ViewSaleDetailsForUnit.permissionsId,
    },
    {
      label: 'buyer-summary',
      component: LeadOwnerSaleUnitProfileBuyerSummaryComponent,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId: LeadOwnerUnitSalePermissions.ViewBuyerSummaryForUnit.permissionsId,
    },
    {
      label: 'refernce-details',
      component: UnitProfileReferanceDetailsComponent,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId: LeadOwnerUnitSalePermissions.ViewReferenceDetailsForUnit.permissionsId,
    },
    {
      label: 'payment-plans',
      component: LeadOwnerSaleUnitProfilePaymentPlanComponent,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId: LeadOwnerUnitSalePermissions.ViewPaymentPlansForUnit.permissionsId,
    },
    {
      label: 'marketing',
      component: LeadOwnerSaleUnitMarketing,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId: LeadOwnerUnitSalePermissions.ViewUnitMarketingInfo.permissionsId,
    },
    {
      label: 'owners',
      component: LeadOwnerSaleUnitProfileOwnersComponent,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId: LeadOwnerUnitSalePermissions.GetAllOwnersByUnitId.permissionsId,
    },
    {
      label: 'matching',
      component: LeadOwnerSaleUnitProfileMatchingComponent,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId: LeadOwnerUnitSalePermissions.ViewUnitMatching.permissionsId,
    },
    {
      label: 'tasks',
      component: ProjectTasksView,
      permissionsList: [],
      permissionsId: '',
    }
  ],
  unitLeaseLeadOwner: [
    {
      label: 'unit-details',
      component: LeadOwnerUnitLeaseInformationComponent,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId: LeadOwnerUnitLeasePermissions.ViewUnitDetails.permissionsId,
    },
    {
      label: 'parking',
      component: UnitParkingComponent,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId: LeadOwnerUnitLeasePermissions.ViewParkingInfoForUnit.permissionsId,
    },
    {
      label: 'Gallery',
      component: LeadOwnerUnitLeaseProfileImagesComponent,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId: LeadOwnerUnitLeasePermissions.ViewUnitImages.permissionsId,
    },
    {
      label: 'map',
      component: LeadOwnerUnitLeaseProfileMapComponent,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId: LeadOwnerUnitLeasePermissions.ViewUnitLocationOnMap.permissionsId,
    },
    {
      label: 'documents',
      component: LeadOwnerUnitLeaseProfileDocumentsComponent,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId: LeadOwnerUnitLeasePermissions.ViewDocumentsForUnit.permissionsId,
    },
    {
      label: 'remarks',
      component: LeadOwnerUnitLeaseProfileRemarksComponent,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId: LeadOwnerUnitLeasePermissions.ViewRemarksForUnit.permissionsId,
    },
    {
      label: 'open-house',
      component: LeadOwnerUnitLeaseProfileOpenHouseComponent,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId: LeadOwnerUnitLeasePermissions.ViewOpenHouseForUnit.permissionsId,
    },
    {
      label: 'activities',
      component: LeadOwnerUnitLeaseProfileActivitiesComponent,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId: LeadOwnerUnitLeasePermissions.ViewTheActivitiesForUnit.permissionsId,
    },
    {
      label: 'lease-details',
      component: LeadOwnerUnitLeaseProfilePaymentDetailsComponent,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId: LeadOwnerUnitLeasePermissions.ViewUnitDetails.permissionsId,
    },
    {
      label: 'marketing',
      component: LeadOwnerLeaseUnitMarketing,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId: LeadOwnerUnitLeasePermissions.ViewUnitMarketingInfo.permissionsId,
    },
    {
      label: 'management',
      component: LeadOwnerUnitLeaseProfileManagementComponent,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId: LeadOwnerUnitLeasePermissions.ViewManagemntForUnit.permissionsId,
    },
    {
      label: 'refernce-details',
      component: UnitProfileReferanceDetailsComponent,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId: LeadOwnerUnitLeasePermissions.ViewReferenceDetailsForUnit.permissionsId,
    },
    {
      label: 'payables',
      component: LeadOwnerUnitLeaseProfilePayablesComponent,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId: LeadOwnerUnitLeasePermissions.ViewPayablesForUnit.permissionsId,
    },
    {
      label: 'owners',
      component: LeadOwnerLeaseUniOwnersUnit,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId: LeadOwnerUnitLeasePermissions.GetAllOwnersByUnitId.permissionsId,
    },
    {
      label: 'matching',
      component: LeadOwnerLeaseUnitProfileMatchingComponent,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId: LeadOwnerUnitLeasePermissions.ViewUnitMatching.permissionsId,
    },
    {
      label: 'tasks',
      component: ProjectTasksView,
      permissionsList: [],
      permissionsId: '',
    }
  ],
};
