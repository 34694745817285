export const SchoolNationalitiesHeaderData = [
  {
    id: 1,
    label: "Students Nationality",
    input: "studentsNationality",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 2,
    label: "Students Percentage",
    input: "studentsPercentage",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 3,
    label: "Students Count",
    input: "studentsCount",
    isHiddenFilter: true,
    isSortable: false,
  }
];
