import React from "react";

function FilterGreaterThan({ fill, ...restProps }) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M6.266 2.023a.692.692 0 0 0-.33.221.586.586 0 0 0-.149.423c0 .282-.032.243 1.221 1.5l1.164 1.166L7.008 6.5C5.755 7.757 5.787 7.718 5.787 8c0 .179.042.297.149.423a.588.588 0 0 0 .504.229.64.64 0 0 0 .293-.05c.071-.033.588-.53 1.513-1.453 1.533-1.53 1.525-1.52 1.525-1.816 0-.295.007-.287-1.512-1.804-.965-.963-1.429-1.409-1.51-1.449-.127-.064-.374-.092-.483-.057M4.447 10.04a.718.718 0 0 0-.362.304c-.061.104-.072.153-.072.323 0 .171.011.218.075.327a.72.72 0 0 0 .394.313c.066.018 1.217.025 3.349.02l3.249-.007.11-.059c.377-.201.478-.7.207-1.017a.885.885 0 0 0-.207-.172l-.11-.059-3.267-.005c-2.725-.004-3.283.001-3.366.032m0 2.667a.718.718 0 0 0-.362.304c-.061.104-.072.153-.072.322 0 .172.011.219.075.328a.724.724 0 0 0 .394.313c.066.018 1.217.024 3.349.02l3.249-.007.11-.059c.377-.202.478-.701.207-1.017a.885.885 0 0 0-.207-.172l-.11-.059-3.267-.005c-2.725-.004-3.283.001-3.366.032"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default FilterGreaterThan;
