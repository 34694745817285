import { config } from '../config';
import { HttpServices } from '../Helper';

export const GetAllConvoloRotationSchemeAPI = async (pageIndex , pageSize) => {
  const result = await HttpServices.get(`${config.server_address}/CrmDfm/ConvoloAgents/GetAllConvoloRotationScheme/${pageIndex}/${pageSize}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetConvoloRotationSchemeByIdAPI  = async (convoloRotationSchemeId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/ConvoloAgents/GetConvoloRotationSchemeById/${convoloRotationSchemeId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const CreateConvoloRotationSchemeAPI = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/ConvoloAgents/CreateConvoloRotationScheme`,
    body) 
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateConvoloRotationSchemeAPI = async (convoloRotationSchemeId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/ConvoloAgents/UpdateConvoloRotationScheme/${convoloRotationSchemeId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const DeleteConvoloRotationSchemeAPI = async (convoloRotationSchemeId) => {
  const url = `${config.server_address}/CrmDfm/ConvoloAgents/DeleteConvoloRotationScheme/${convoloRotationSchemeId}`;
  const result = await HttpServices.delete(url)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetAgentRotationSchemes = async (agentId, rotationNameSearch, rotationStatusSearch) => {
  try {
    const response = await HttpServices.get(`${config.server_address}/CrmDfm/ConvoloAgents/GetAgentRotationSchemes/${agentId}`, {
      params: {
        rotationName: rotationNameSearch,
        rotationStatus: rotationStatusSearch,
      }
    });
    return response;
  } catch (err) {
    
  }
}; 
export const AssignAgentToConvoloRotationScheme = async (convoloRotationSchemeId , agentId ) => {
  const url = `${config.server_address}/CrmDfm/ConvoloAgents/AssignAgentToConvoloRotationScheme/${convoloRotationSchemeId}/${agentId}`;
  const result = await HttpServices.post(url)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const RemoveAgentFromRotationSchemeAPI = async (convoloAgentId) => {
  const url = `${config.server_address}/CrmDfm/ConvoloAgents/RemoveAgentFromRotationScheme/${convoloAgentId}`;
  const result = await HttpServices.delete(url)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetConvoloRotationAgentsAPI = async (schemeId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/ConvoloAgents/GetConvoloRotationAgents/${schemeId}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAgentsForConvoloRotationAPI = async ({pageIndex , pageSize , agentName}) => {
  const queryList = [];
  queryList.push(`pageIndex=${pageIndex}`);
  queryList.push(`pageSize=${pageSize}`);
  queryList.push(`agentName=${agentName}`);
  
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/ConvoloAgents/GetAgentsForConvoloRotation?${queryList.join('&')}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};


export const UpdateAgentStatusInConvoloRotationAPI = async (convoloRotationSchemeId , agentId ) => {
  const url = `${config.server_address}/CrmDfm/ConvoloAgents/UpdateAgentStatusInConvoloRotation/${convoloRotationSchemeId}/${agentId}`;
  const result = await HttpServices.put(url)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};



