import React from 'react';
import { DialogComponent } from '../../../../Components';
import Button from '@material-ui/core/Button';
import './MoreFiltersDialog.scss';

export const MoreFiltersDialog = ({
  isOpen,
  UnitNumberFilter,
  PriceFilter,
  SizeFilter,
  PropertiesFilter,
  parentTranslationPath,
  translationPath,
  setResaleFilters,
  resaleFilters,
  resaleChangeFilters,
  selected,
  setSelected,
  isClose,
  ListingAgentFilter,
  detailsUnitsList,
  PriceAndSizeChangeFilters
}) => {
  return (
    <DialogComponent
      titleText="More filters"
      maxWidth="sm"
      isOpen={isOpen}
      onCloseClicked={isClose}
      dialogContent={
        <div className="more-filters-dialog-container">
          <div className="filters-container">
            <ListingAgentFilter
              setResaleFilters={setResaleFilters}
              resaleFilters={resaleFilters}
              resaleChangeFilters={resaleChangeFilters}
              selected={selected}
              setSelected={setSelected}
            />

            <PropertiesFilter
              setResaleFilters={setResaleFilters}
              resaleFilters={resaleFilters}
              resaleChangeFilters={resaleChangeFilters}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              selected={selected}
              setSelected={setSelected}
            />

            <UnitNumberFilter
              setResaleFilters={setResaleFilters}
              resaleFilters={resaleFilters}
              resaleChangeFilters={resaleChangeFilters}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              selected={selected}
              setSelected={setSelected}
            />

            <div className="price-size-container">
              <PriceFilter
                setResaleFilters={setResaleFilters}
                resaleFilters={resaleFilters}
                resaleChangeFilters={resaleChangeFilters}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                selected={selected}
                setSelected={setSelected}
                PriceAndSizeChangeFilters={PriceAndSizeChangeFilters}
              />

              <SizeFilter
                setResaleFilters={setResaleFilters}
                resaleFilters={resaleFilters}
                resaleChangeFilters={resaleChangeFilters}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                selected={selected}
                setSelected={setSelected}
                PriceAndSizeChangeFilters={PriceAndSizeChangeFilters}
              />
            </div>
          </div>

          <div className="dialog-footer">
            <Button onClick={isClose} className="cancel-button">
              Cancel
            </Button>
            <Button variant="contained" className="show-button" disabled>
              {`Show ${detailsUnitsList.totalCount}`}
            </Button>
          </div>
        </div>
      }
    />
  );
};
