import React, { useCallback, useEffect, useReducer } from 'react';
import { ButtonBase, DialogActions } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import { DialogComponent, AutocompleteComponent } from '../../Components';
import { lookupItemsGetId } from '../../Services';
import { showError } from '../../Helper';

export const TransactionCreateLeadDialog = ({
  isOpen,
  onSaveHandler,
  onCloseHandler,
  setCreateLeadData,
}) => {
  const parentTranslationPath = 'UnitsStatusManagementView';
  const translationPath = '';
  const { t } = useTranslation(parentTranslationPath);

  const reducer = useCallback((itemsState, action) => {
    if (action.id !== 'edit') return { ...itemsState, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [data, setData] = useReducer(reducer, {
    mediaDetail: [],
    methodOfContact: [],
  });
  const [selected, setSelected] = useReducer(reducer, {
    mediaDetail: null,
    methodOfContact: null,
  });
  const [loadings, setLoadings] = useReducer(reducer, {
    mediaDetail: false,
    methodOfContact: false,
  });


  const schema = Joi.object({
    mediaDetail: Joi.required()
      .custom((value, helpers) => {
        if (!selected.mediaDetail) return helpers.error('media-detail-msg-value');
        return value;
      })
      .messages({
        'media-detail-msg-value': t(`${translationPath}media-detail-is-required`),
      }),
    methodOfContact: Joi.required()
      .custom((value, helpers) => {
        if (!selected.methodOfContact) return helpers.error('method-of-contact-msg-value');
        return value;
      })
      .messages({
        'method-of-contact-msg-value': t(`${translationPath}method-of-contact-is-required`),
      }),

  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(selected);


  const getAllMediaDetail = async () => {
    setLoadings({ id: 'mediaDetail', value: true });
    const res = await lookupItemsGetId({ lookupTypeId: 1240 });
    if (!(res && res.status && res.status !== 200)) setData({ id: 'mediaDetail', value: res });
    else setData({ id: 'mediaDetail', value: [] });
    setLoadings({ id: 'mediaDetail', value: false });
  };

  const getAllMethodOfContact = async () => {
    setLoadings({ id: 'methodOfContact', value: true });
    const res = await lookupItemsGetId({ lookupTypeId: 35 });
    if (!(res && res.status && res.status !== 200)) setData({ id: 'methodOfContact', value: res });
    else setData({ id: 'methodOfContact', value: [] });
    setLoadings({ id: 'methodOfContact', value: false });
  };

  useEffect(() => {
    getAllMediaDetail();
    getAllMethodOfContact();
  }, []);

  return (
    <DialogComponent
      titleText='create-new-lead'
      saveText='save'
      maxWidth='md'
      dialogContent={
        <div>
          <div className='d-flex'>
            <div className='w-50 my-2 mx-1'>
              <AutocompleteComponent
                idRef='mediaDetailRef'
                labelValue='media-detail'
                labelClasses='Requierd-Color'
                multiple={false}
                data={data.mediaDetail}
                displayLabel={(option) => option.lookupItemName}
                renderOption={(option) => option.lookupItemName || ''}
                withoutSearchButton
                isWithError
                selectedValues={selected.mediaDetail}
                isLoading={loadings.mediaDetail}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  setSelected({
                    id: 'mediaDetail',
                    value: newValue,
                  });
                  setCreateLeadData({
                    id: 'mediaDetail',
                    value: newValue,
                  });
                }}
              />
            </div>
            <div className='w-50 my-2 mx-1'>
              <AutocompleteComponent
                idRef='methodOfContactRef'
                labelValue='method-Of-Contact'
                multiple={false}
                labelClasses='Requierd-Color'
                data={data.methodOfContact}
                displayLabel={(option) => option.lookupItemName}
                renderOption={(option) => option.lookupItemName || ''}
                withoutSearchButton
                selectedValues={selected.methodOfContact}
                isWithError
                isLoading={loadings.methodOfContact}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  setSelected({
                    id: 'methodOfContact',
                    value: newValue,
                  });
                  setCreateLeadData({
                    id: 'methodOfContact',
                    value: newValue,
                  });
                }}
              />
            </div>
          </div>

          <DialogActions>
            <div className='unit-template-action-btns'>
              <ButtonBase
                className='MuiButtonBase-root btns bg-cancel'
                onClick={() => onCloseHandler()}
              >
                {t(`${translationPath}cancel`)}
              </ButtonBase>
              <ButtonBase
                className='MuiButtonBase-root btns theme-solid '
                onClick={() => {

                  if (schema?.error?.message) {
                    showError(schema.error.message);
                    return;
                  }
                  onSaveHandler()
                }}
              >
                {t(`${translationPath}save`)}
              </ButtonBase>
            </div>
          </DialogActions>
        </div>
      }
      isOpen={isOpen}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};
