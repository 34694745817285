export const calculateCapacities = (type, value) => {
  const calculateRegular = (daily, weekly, monthly, overall) => ({
    dailyCapacity: Math.floor(daily ?? 0),
    weeklyCapacity: Math.floor(weekly ?? 0),
    monthlyCapacity: Math.floor(monthly ?? 0),
    leadCapacity: Math.floor(overall ?? 0),
  });

  const calculatePool = (daily, weekly, monthly, overall) => ({
    dailyPoolCapacity: Math.floor(daily ?? 0),
    weeklyPoolCapacity: Math.floor(weekly ?? 0),
    monthlyPoolCapacity: Math.floor(monthly ?? 0),
    leadPoolCapacity: Math.floor(overall ?? 0),
  });
  const calculateInRotation = (daily, weekly, monthly, overall) => ({
    dailyCapacity: Math.floor(daily ?? 0),
    weeklyCapacity: Math.floor(weekly ?? 0),
    monthlyCapacity: Math.floor(monthly ?? 0),
    agentLeadCapacityInRotation: Math.floor(overall ?? 0),
  });
  const calculateInOwnerRotation = (daily, weekly, monthly, overall) => ({
    dailyCapacity: Math.floor(daily ?? 0),
    weeklyCapacity: Math.floor(weekly ?? 0),
    monthlyCapacity: Math.floor(monthly ?? 0),
    agentLeadCapacityInRotation: Math.floor(overall ?? 0),
  });

  switch (type) {
    case 'daily':
      return calculateRegular(value, value * 7, value * 30, value * 60);
    case 'weekly':
      return calculateRegular(value / 7, value, value * 4, value * 8);
    case 'monthly':
      return calculateRegular(value / 30, value / 4, value, value * 2);
    case 'lead':
      return calculateRegular(value / 60, value / 8, value / 2, value);
    case 'dailyPool':
      return calculatePool(value, value * 7, value * 30, value * 60);
    case 'weeklyPool':
      return calculatePool(value / 7, value, value * 4, value * 8);
    case 'monthlyPool':
      return calculatePool(value / 30, value / 4, value, value * 2);
    case 'leadPool':
      return calculatePool(value / 60, value / 8, value / 2, value);
    case 'agentLeadCapacityInRotation':
      return calculateInRotation(value / 60, value / 8, value / 2, value);
    case 'monthlyCapacity':
      return calculateInRotation(value / 30, value / 4, value, value * 2);
    case 'weeklyCapacity':
      return calculateInRotation(value / 7, value, value * 4, value * 8);
    case 'dailyCapacity':
      return calculateInRotation(value, value * 7, value * 30, value * 60);
    case 'agentLeadCapacityInOwnerRotation':
      return calculateInOwnerRotation(value / 60, value / 8, value / 2, value);
    case 'ownerMonthlyCapacity':
      return calculateInOwnerRotation(value / 30, value / 4, value, value * 2);
    case 'ownerWeeklyCapacity':
      return calculateInOwnerRotation(value / 7, value, value * 4, value * 8);
    case 'ownerDailyCapacity':
      return calculateInOwnerRotation(value, value * 7, value * 30, value * 60);
    default:
      return {};
  }
};
