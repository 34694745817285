import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { ButtonBase } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { RadiosGroupComponent, Spinner } from '../../../../../../Components';
import {
  bottomBoxComponentUpdate,
  GetParams,
  GlobalHistory,
  showError,
  showSuccess,
} from '../../../../../../Helper';
import {
  lookupItemsGetId,
  GetAllUnitPayablesByUnitId,
  UnitPayablesPost,
} from '../../../../../../Services';
import { PayablesEnum } from '../../../../../../Enums';
import { PermissionsComponent } from '../../../../../../Components/PermissionsComponent/PermissionsComponent';
import { UnitsLeasePermissions } from '../../../../../../Permissions/Lease/UnitsLeasePermissions';

export const UnitProfilePayablesComponent = ({ parentTranslationPath, translationPath }) => {
  const { t } = useTranslation(parentTranslationPath);
  const location = useLocation();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [radioValue, setRadioValue] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [payableResponse, setPayableResponse] = useState(null);
  const defaultState = {
    unitId: +GetParams('id'),
    createUnitPayables: [],
  };
  const [state, setState] = useState(defaultState);

  const getPayablesLookupItems = useCallback(async () => {
    setLoading(true);
    const result = await lookupItemsGetId({ lookupTypeId: PayablesEnum.lookupTypeId });
    if (!(result && result.status && result.status !== 200)) {
      setResponse(result);
      const arr = result.map((item) => `${item.lookupItemId}-owner`);
      setRadioValue(JSON.parse(JSON.stringify(arr)));
    } else setResponse(null);
    setLoading(false);
  }, []);

  const getAllUnitPayablesById = useCallback(async () => {
    setLoading(true);
    const result = await GetAllUnitPayablesByUnitId(+GetParams('id'));
    if (!(result && result.status && result.status !== 200)) setPayableResponse(result);
    setLoading(false);
  }, []);

  const radioValueChange = useCallback(() => {
    if (payableResponse) {
      setTimeout(() => {
        payableResponse.map((item) => {
          setRadioValue((items) => {
            const index = items.findIndex((el) => +item.payableId === +el.split('-')[0]);
            if (index !== -1) {
              items.splice(index, 1);
              items.push(item.isOwner ? `${item.payableId}-owner` : `${item.payableId}-tenant`);
            }
            return [...items];
          });
        });
      }, 50);
    }
  }, [payableResponse]);

  useEffect(() => {
    radioValueChange();
  }, [radioValueChange]);

  useEffect(() => {
    if (payableResponse) setSelectedRows(payableResponse.map((item) => `${item.payableId}`));
  }, [payableResponse]);

  useEffect(() => {
    getAllUnitPayablesById();
  }, [getAllUnitPayablesById, location]);

  useEffect(() => {
    getPayablesLookupItems();
  }, [getPayablesLookupItems]);

  useEffect(() => {
    setState((item) => ({ ...item, unitId: +GetParams('id')}));
  }, [location]);

  const cancelHandler = () => {
    GlobalHistory.push('/home/units-lease/view');
  };
  const saveHandler = async () => {
  
  const result = await UnitPayablesPost({ ...state, createUnitPayables: state.createUnitPayables });

  if (!(result && result.status && result.status !== 200)) {
    showSuccess(t(`${translationPath}payable-saved-successfully`));
    setState(defaultState);
  } else {
    showError(t(`${translationPath}payable-saved-failed`));
  }
  };

  useEffect(() => {
    bottomBoxComponentUpdate(
      <div className='d-flex-v-center-h-end flex-wrap'>
        <ButtonBase className='btns theme-transparent mb-2' onClick={cancelHandler}>
          <span>{t('Shared:cancel')}</span>
        </ButtonBase>
        <PermissionsComponent
          permissionsList={Object.values(UnitsLeasePermissions)}
          permissionsId={UnitsLeasePermissions.EditPayablesForUnit.permissionsId}
        >
          <ButtonBase className='btns theme-solid mb-2' onClick={saveHandler}>
            <span>{t('Shared:save')}</span>
          </ButtonBase>
        </PermissionsComponent>
      </div>
    );
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  const handleRadioValueChange = useCallback(
    (value, index) => {
      const payableId = radioValue[index].split('-')[0];
      const isOwner = value.split('-')[1] === 'owner';
  
      setState((prevState) => {
        const updatedPayables = prevState.createUnitPayables.filter(item => item.payableId !== +payableId);
        updatedPayables.push({
          payableId: +payableId,
          isOwner,
          isTenant: !isOwner,
        });
  
        return {
          ...prevState,
          createUnitPayables: updatedPayables,
        };
      });
  
      setRadioValue((items) => {
        items[index] = value;
        return [...items];
      });
    },
    [radioValue]
  );

  const handleChange = (value, index) => {
    const payableId = radioValue[index].split('-')[0];
  const isOwner = radioValue[index].split('-')[1] === 'owner';

  setSelectedRows((items) => {
    const updatedItems = items.includes(value) ? items.filter(item => item !== value) : [...items, value];
    return updatedItems;
  });

  setState((prevState) => {
    const updatedPayables = prevState.createUnitPayables.filter(item => item.payableId !== +payableId);
    if (selectedRows.includes(value)) {
      updatedPayables.push({
        payableId: +payableId,
        isOwner,
        isTenant: !isOwner,
      });
    }

    return {
      ...prevState,
      createUnitPayables: updatedPayables,
    };
  });
  };

  return (
    <div className='unit-profile-payables-wrapper childs-wrapper'>
      <Spinner isActive={loading} />
      <div className='w-100 payables-wrapper'>
        <div className='payable-header w-100'>
          <span>{t(`${translationPath}payables`)}</span>
          <span>{t(`${translationPath}owner`)}</span>
          <span>{t(`${translationPath}tenant`)}</span>
        </div>
        {response &&
          response.map((item, index) => (
            <div className={`payables-table ${index % 2 === 0 ? 'gray-index' : ''} w-100`}>
              <div className='payable-content w-100'>
                <div className='payable-name'>
                  <Checkbox
                    size='small'
                    key={`${index + 1}-payable`}
                    value={`${item.lookupItemId}`}
                    checked={selectedRows.findIndex((el) => +el === +item.lookupItemId) !== -1}
                    onChange={(e) => handleChange(e.target.value, index)}
                  />
                  <span>{item.lookupItemName}</span>
                </div>
                <div
                  className={
                    !selectedRows.map((el) => +el === item.lookupItemId).includes(true) ?
                      'payable-radio' :
                      'payable-disabled'
                  }
                >
                  <RadiosGroupComponent
                    isDisabled={!selectedRows.map((el) => +el === item.lookupItemId).includes(true)}
                    idRef='payables-actions'
                    data={[
                      { value: `${item.lookupItemId}-owner` },
                      { value: `${item.lookupItemId}-tenant` },
                    ]}
                    onSelectedRadioChanged={(e) => handleRadioValueChange(e.target.value, index)}
                    value={radioValue.find((el) => +el.split('-')[0] === +item.lookupItemId)}
                    valueInput='value'
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

UnitProfilePayablesComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
