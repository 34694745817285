import React from "react";

function FilterContainIcon({ fill, ...restProps }) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M3.187 2.042c-.685.128-1.341.67-1.598 1.32-.156.395-.156.389-.168 2.065l-.012 1.546-.314.32c-.173.176-.337.362-.364.414-.069.129-.069.457 0 .586.027.052.191.238.364.414l.314.32.012 1.546c.012 1.675.012 1.67.167 2.063.24.606.843 1.136 1.477 1.298.271.069.607.082.765.029a.66.66 0 0 0 .446-.63c0-.38-.242-.617-.683-.667-.358-.041-.588-.191-.745-.485l-.061-.114-.014-1.72c-.015-1.992.008-1.833-.31-2.162L2.285 8l.178-.185c.318-.329.295-.17.31-2.162l.014-1.72.079-.143a.856.856 0 0 1 .682-.446c.348-.032.543-.147.672-.396a.717.717 0 0 0 .001-.562c-.174-.334-.503-.444-1.034-.344m9.026-.017c-.305.093-.489.334-.489.642 0 .203.059.338.213.488.114.111.265.166.515.189a.856.856 0 0 1 .682.446l.079.143.014 1.72c.015 1.992-.008 1.833.31 2.162l.178.185-.178.185c-.318.329-.295.17-.31 2.162l-.014 1.72-.061.114c-.157.294-.387.444-.745.485-.441.05-.683.287-.683.667 0 .288.18.542.446.63.158.053.494.04.765-.029.633-.162 1.218-.676 1.473-1.294.159-.384.159-.386.171-2.067l.012-1.546.314-.32c.173-.176.337-.362.364-.414.036-.067.05-.149.05-.293s-.014-.226-.05-.293c-.027-.052-.191-.238-.364-.414l-.314-.32-.012-1.546c-.012-1.676-.012-1.67-.168-2.065-.2-.506-.656-.965-1.184-1.193-.308-.133-.814-.205-1.014-.144m-7.58 5.053c-.276.113-.509.358-.586.614a1.522 1.522 0 0 0 0 .616 1.006 1.006 0 0 0 1.789.236c.113-.17.149-.304.149-.544 0-.24-.036-.374-.149-.544a1.019 1.019 0 0 0-1.203-.378m3.044-.027c-.699.266-.899 1.134-.38 1.652.575.576 1.579.244 1.688-.557a.998.998 0 0 0-.553-1.042c-.132-.067-.186-.078-.405-.083a1.11 1.11 0 0 0-.35.03m2.956.027a1.046 1.046 0 0 0-.47.38c-.112.168-.148.302-.148.542 0 .11.014.249.032.308a1.006 1.006 0 0 0 1.789.236c.113-.17.149-.304.149-.544 0-.24-.036-.374-.149-.544a1.019 1.019 0 0 0-1.203-.378"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default FilterContainIcon;
