export const listTreeProperties = [{
    formFieldName: 'Property',
    formFieldKey: 'Property',
    searchableKey: 'propertyId',
    displayPath: 'propertyId',
    uiWidgetType: 'searchField',
    formFieldTitle: 'Property Id',
}, {
    formFieldName: 'UpdatedByName',
    formFieldKey: 'UpdatedByName',
    searchableKey: 'UpdatedByName',
    displayPath: 'UpdatedByName',
    uiWidgetType: 'searchField',
    formFieldTitle: 'Updated By Name',
}, {
    formFieldName: 'CreatedByName',
    formFieldKey: 'CreatedByName',
    searchableKey: 'CreatedByName',
    displayPath: 'CreatedByName',
    uiWidgetType: 'searchField',
    formFieldTitle: 'Created By Name',
},
];
export const listTreContacts = [
    {
        formFieldName: 'Contacts',
        formFieldKey: 'Contacts',
        searchableKey: 'contactId',
        displayPath: 'contactId',
        uiWidgetType: 'searchField',
        formFieldTitle: 'Contact Id',
    }
];
export const listTree = [{
    formFieldName: 'CreatedOn',
    formFieldKey: 'CreatedOn',
    searchableKey: 'CreatedOn',
    displayPath: 'CreatedOn',
    uiWidgetType: 'alt-date',
    formFieldTitle: 'Creation Date',
}, {
    formFieldName: 'CreatedByName',
    formFieldKey: 'CreatedByName',
    searchableKey: 'CreatedByName',
    displayPath: 'CreatedByName',
    uiWidgetType: 'searchField',
    formFieldTitle: 'Created By Name',
},
{
    formFieldName: 'UpdateOn',
    formFieldKey: 'UpdateOn',
    searchableKey: 'UpdateOn',
    displayPath: 'UpdateOn',
    uiWidgetType: 'alt-date',
    formFieldTitle: 'Updated On',
},
{
    formFieldName: 'UpdatedByName',
    formFieldKey: 'UpdatedByName',
    searchableKey: 'UpdatedByName',
    displayPath: 'UpdatedByName',
    uiWidgetType: 'searchField',
    formFieldTitle: 'Updated By Name',
},
];
export const listTree2 = [{
    formFieldName: 'AssignDate',
    formFieldKey: 'AssignDate',
    searchableKey: 'AssignDate',
    displayPath: 'AssignDate',
    uiWidgetType: 'alt-date',
    formFieldTitle: 'Last Assign Date',
}, {
    formFieldName: 'LastActivityTypeName',
    formFieldKey: 'LastActivityTypeName',
    searchableKey: 'LastActivityTypeName',
    displayPath: 'LastActivityTypeName',
    uiWidgetType: 'searchField',
    formFieldTitle: 'Last Activity Type Name',
},
{
    formFieldName: 'LastActivityCreatedByName',
    formFieldKey: 'LastActivityCreatedByName',
    searchableKey: 'LastActivityCreatedByName',
    displayPath: 'LastActivityCreatedByName',
    uiWidgetType: 'searchField',
    formFieldTitle: 'Last Activity Created By Name',
},
{
    formFieldName: 'Lead Id',
    formFieldKey: 'Leads',
    searchableKey: 'leadId',
    displayPath: 'leadId',
    uiWidgetType: 'leadId',
    formFieldTitle: 'Lead Id',
}
];
export const DateTableFilterOperatorsEnum = {
    greaterThanDate: {
        key: 5,
        value: 'is-greater-than',
        name: 'Is greater than date',
        filedType: 'date',
        icon: 'mdi mdi-chevron-double-up'
    },
    lessThanDate: {
        key: 6,
        value: 'is-less-than',
        name: 'Is less than date',
        filedType: 'date',
        icon: 'mdi mdi-chevron-double-down'
    },
    equalData: {
        key: 10,
        value: 'is-equal-date',
        name: 'Is equal date',
        filedType: 'date',
        icon: 'mdi mdi-equal'
    }
};
export const TextTableFilterOperatorsEnum = {
    equal: {
        key: 1,
        value: 'is-equal',
        name: 'Is equal',
        filedType: 'textFiled',
        icon: 'mdi  mdi-equal'
    },
    contains: {
        key: 2,
        value: 'is-contains',
        name: 'Is contains',
        filedType: 'textFiled',
        icon: 'mdi mdi-contain'
    },
    startWith: {
        key: 7,
        value: 'is-start-with',
        name: 'Is start with',
        filedType: 'textFiled',
        icon: 'mdi mdi-contain-start'
    },
    endWith: {
        key: 8,
        value: 'is-end-with',
        name: 'Is end with',
        filedType: 'textFiled',
        icon: 'mdi mdi-contain-end'
    },
};

export const NumberTableFilterOperatorsEnum = {
    equal: {
        key: 1,
        value: 'is-equal',
        name: 'Is equal',
        filedType: 'textFiled',
        icon: 'mdi  mdi-equal'
    },
    greaterThan: {
        key: 3,
        value: 'is-greater-than',
        name: 'Is greater than',
        filedType: 'textFiled',
        icon: 'mdi mdi-chevron-double-up'
    },
    lessThan: {
        key: 4,
        value: 'is-less-than',
        name: 'Is less than',
        filedType: 'textFiled',
        icon: 'mdi mdi-chevron-double-down'
    },
};
