import React, { useState, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import '../CampRequestFormType1.scss';
import {CampaignArtworkSuggestionEnum} from '../../../../../../../../Enums';
import { GlobalHistory } from '../../../../../../../../Helper';
import { ArtworkSuggestions, FinanceStep, MainInformationStep } from '../FormSteps';
import { ButtonBase } from '@material-ui/core';
import { StepperComponent } from '../../../../../../../../Components/Controls';
import { bottomBoxComponentUpdate } from '../../../../../../../../Helper/Middleware.Helper';

export function CreateRequestForm({ setState, isSubmitted, setIsSubmitted }) {
  const parentTranslationPath = 'Campaign';
  const translationPath = '';
  const { t } = useTranslation(parentTranslationPath);
  const defaultFinancialSourceItem = {
    type: null,
    branch: null,
    user: null,
    percent: null,
  };
  const steps = [{ key: 'Main Information' }, { key: 'Artwork Suggestions' }, { key: 'Finance' }];

  const reducer = (state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  };
  const [activeStep, setActiveStep] = useState(0);

  const [selected, setSelected] = useReducer(reducer, {
    campaignRequester: null,
    platformName: null,
    projectCategory: null,
    targetAudience: null,
    mediaRequirements: null,
    marketingMaterial: null,
    campaignReason: null,
    campaignUsp: null,
    campaignLanguage: null,
    relatedToType: null,
    relatedToValue: null,
    generalTypeText: null,
    campaignKeyMessages: null,
    campaignLength: null,
    files: [],
    links: [],
    financialSources: [defaultFinancialSourceItem],
  });
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const cancelHandler = () => {
    GlobalHistory.push(`/home/campaign-requests`);
  };

  const getStateRelatedTo = () => {
    let relatedToState = null;
    let localRelatedToValues = [];
    let mappedRelatedToNames = [];

    if (selected.relatedToValue) {

      localRelatedToValues =
        selected.relatedToType === 'Unit' ? selected.relatedToValue : [selected.relatedToValue];

      mappedRelatedToNames = localRelatedToValues.map(
        (item) =>
          item.lookupItemName ||
          item?.property?.property_name ||
          item.unitRefNo ||
          item?.contact?.company_name ||
          (item?.contact?.first_name &&
            `${item?.contact?.first_name} ${item?.contact?.last_name}`) ||
          ''
      );
    }

    if (selected.relatedToType === 'GeneralCampaign') {
      relatedToState = {
        type: selected.relatedToType,
        values: [],
        generalTypeText: selected.generalTypeText,
      };
    } else if (selected.relatedToType === 'Unit') {

      relatedToState = {
        type: selected.relatedToType,
        values: mappedRelatedToNames,
        generalTypeText: selected.generalTypeText,
      };
    } else {

      relatedToState = {
        type: selected.relatedToType,
        values: mappedRelatedToNames,
        generalTypeText: '',
      };
    }
    return relatedToState || null;
  };

  const getStateArtworkSuggestion = () => {
    const attachments = [...(selected.files || []), ...(selected.links || [])];
    const artworkSuggestionState = attachments.map((item) => ({
      type: item?.documentLink
        ? CampaignArtworkSuggestionEnum.Link.key
        : CampaignArtworkSuggestionEnum.File.key,
      value: item?.documentLink || item?.uuid,
      name: item?.fileName || null,
    }));

    return artworkSuggestionState || null;
  };

  const getStateFinancialSource = () => {

    const financialSourceState =
      selected.financialSources &&
      selected.financialSources.map((item) => ({
        type: item?.type,
        value: item?.user?.applicationUserId || item?.branch?.branchId,
        name: item?.user?.fullName || item?.branch?.branchName,
        percent: item?.percent,
      }));

    return financialSourceState || null;
  };

  const mapStateJSON = () => {
    const JSON = {
      CampaignRequesterId: selected.campaignRequester?.applicationUserId || null,
      PlatformName: selected.platformName || null,
      ProjectCategory: selected.projectCategory || null,
      TargetAudience: selected.targetAudience || null,
      CampaignLanguage: selected.campaignLanguage || null,
      MediaRequirements: selected.mediaRequirements || null,
      MarketingMaterial: selected.marketingMaterial || null,
      CampaignReason: selected.campaignReason || null,
      CampaignKeyMessages: selected.campaignKeyMessages || null,
      CampaignUsps: selected.campaignUsp || null,
      CampaignBudget: selected.campaignBudget || null,
      CampaignLength: selected.campaignLength || null,
      RelatedTo: getStateRelatedTo(),
      ArtworkSuggestion: getStateArtworkSuggestion(),
      FinancialSource: getStateFinancialSource(),
    };

    setState(JSON);
  };

  useEffect(() => {
    if (isSubmitted) mapStateJSON();
  }, [isSubmitted]);



  return (
    <div className='w-100'>
      {steps && (
        <StepperComponent
          steps={steps}
          labelInput='key'
          activeStep={activeStep}
        />
      )}
      <div className='px-2 m-2'>
        {activeStep === 0 && (
          <MainInformationStep
            selected={selected}
            setSelected={setSelected}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}
        {activeStep === 1 && (
          <ArtworkSuggestions
            selected={selected}
            setSelected={setSelected}
          />
        )}
        {activeStep === 2 && (
          <FinanceStep
            selected={selected}
            setSelected={setSelected}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}
      </div>
      
      <div className='form-actions-wrapper'>
        <div className='d-flex-v-center-h-between d-flex-row-reverse'>
          <div className='action-item-next-back'>
            {activeStep > 0 && (
              <ButtonBase className='btns theme-transparent mx-2' onClick={handleBack}>
                <span>{t(`${translationPath}back`)}</span>
              </ButtonBase>
            )}
            {activeStep <= 1 && (
              <ButtonBase
                className='btns theme-solid mx-2'
                onClick={handleNext}>
                <span>{t(`${translationPath}next`)}</span>
              </ButtonBase>
            )}
            {activeStep === 2 && (
              <>
                <ButtonBase
                  disabled={isSubmitted}
                  onClick={() => setIsSubmitted(true)}
                  className='btns theme-solid mx-2'>
                  <span>{t(`${translationPath}save`)}</span>
                </ButtonBase>
              </>
            )}
          </div>
          <div className='action-item'>
            <ButtonBase className='btns theme-transparent mx-2' onClick={cancelHandler}>
              <span>{t(`${translationPath}cancel`)}</span>
            </ButtonBase>
          </div>
        </div>
      </div>
   
    </div>
  );
}
