import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AutocompleteComponent } from '../../../../../../Components';
import { lookupItemsGetId } from '../../../../../../Services';

export const RatingComponent = ({
  parentTranslationPath,
  translationPath,
  helperText,
  setState,
  state,
  isSubmitted,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [ratings, setRatings] = useState([]);
  const [loadings, setLoadings] = useState(false);
  const [selected, setSelected] = useState(null);

  const lookupActivityRating = useCallback(async () => {
    setLoadings(true);
    const result = await lookupItemsGetId({
      lookupTypeId: 1212, // Lookups.,
    });
    setLoadings(false);
    setRatings(result);
  }, []);
  useEffect(() => {
    lookupActivityRating();
  }, []);

  useEffect(() => {
    if (state?.activityRate || state?.activityRateId) {
      setSelected({
        lookupItemId: state.activityRate ?? state.activityRateId,
        lookupItemName: state.activityRateName,
      });
    }
  }, [state.activityRate, state.activityRateId]);

  return (
    <div>
      <AutocompleteComponent
        idRef='RatingRef'
        labelValue='Rating'
        selectedValues={selected}
        multiple={false}
        data={ratings || []}
        labelClasses='Requierd-Color'
        displayLabel={(option) => t(`${option.lookupItemName || ''}`)}
        getOptionSelected={(option) =>
          option.lookupItemId === selected.lookupItemId
        }
        withoutSearchButton
        inputPlaceholder={t(`${translationPath}Rating`)}
        isLoading={loadings}
        helperText={helperText}
        error={!!helperText}
        isSubmitted={isSubmitted}
        isWithError
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(event, newValue) => {
          setState({
            activityRate: (newValue && +newValue.lookupItemId) || '',
            activityRateName: (newValue && newValue.lookupItemName) || '',
          });
          setSelected({
            lookupItemId: (newValue && +newValue.lookupItemId) || '',
            lookupItemName: (newValue && newValue.lookupItemName) || '',
          });
        }}
      />
    </div>
  );
};
RatingComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
};
