import { config } from '../config/config';
import { HttpServices } from '../Helper';

export const GetAllPropertyOffers = async (propertyId, pageIndex, pageSize) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/PropertyMarketing/GetAllPropertyOffers/${propertyId}?pageSize=${pageSize}&pageIndex=${pageIndex}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const CreatePropertyOffer = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/PropertyMarketing/CreatePropertyOffer`,
    body
  )
    .then((response) => response)
    .catch((error) => error.response);
  return result;
};

export const UpdatePropertyOffer = async (propertyOfferId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/PropertyMarketing/UpdatePropertyOffer/${propertyOfferId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const DeletePropertyCampaignAPI = async (propertyOfferId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/PropertyMarketing/DeletePropertyOffer/${propertyOfferId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
