
import React , {useRef} from 'react';
import { Inputs } from '../../../../Components' ; 
import { useTranslation } from 'react-i18next';


export const CommunicationFilter = ({
    translationPath,
    parentTranslationPath,
    filter,
    setFilter ,
    selected , 
    setSelected , 
    }) => {

    const { t } = useTranslation(parentTranslationPath);
    const searchTimer = useRef(null);

    return (
      <>
        <div className='d-inline-flex'>
          <div className='mr-2 ml-2'>
              <Inputs
               idRef='PhoneRef'
               inputPlaceholder={t('phoneNumber')}
               value={(selected && selected.phoneNumber)}
               parentTranslationPath={parentTranslationPath}
               translationPath={translationPath}
               onKeyUp={(e)=> {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                    setFilter((item) => ({ ...item, phoneNumber: value }));
                   }, 700);
                 }}
                 onInputChanged={(e)=> 
                  {
                    setSelected({ id: 'phoneNumber', value:  e.target.value }); 
  
                 }} 
               />  
            </div>
            <div className='mr-2 ml-2'>
             <Inputs
               idRef='EmailRef'
               inputPlaceholder={t('email')}
               value={(selected && selected.email)}
               parentTranslationPath={parentTranslationPath}
               translationPath={translationPath}
               onKeyUp={(e)=> {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                  setFilter((item) => ({ ...item, email: value || '' }));
                 }, 500);
               }}
               onInputChanged={(e)=> 
                {
                  setSelected({ id: 'email', value:  e.target.value }); 

               }} 
              /> 
             </div> 
            </div>
         </>
    );
};
