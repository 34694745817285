import React from "react";

function GetHelpIcon({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="m11.28 1.023-.56.06A10.685 10.685 0 0 0 7.18 2.12c-1.135.551-1.987 1.165-2.942 2.119-.961.959-1.595 1.841-2.141 2.981C.301 10.968.728 15.286 3.226 18.633c.475.636 1.527 1.683 2.174 2.164 3.964 2.948 9.266 2.937 13.237-.027.609-.454 1.679-1.524 2.133-2.133 2.974-3.985 2.974-9.289 0-13.274-.454-.608-1.523-1.677-2.13-2.128-1.595-1.186-3.275-1.875-5.217-2.139C13 1.038 11.574.99 11.28 1.023m2.04 2.078c2.563.387 4.804 1.83 6.24 4.019.303.463.743 1.359.932 1.9.346.993.485 1.845.485 2.98 0 1.493-.257 2.621-.897 3.94-.705 1.454-1.769 2.667-3.153 3.592-.789.528-2.051 1.056-3.019 1.265a9.053 9.053 0 0 1-7.538-1.778c-1.513-1.212-2.648-2.99-3.103-4.859-.186-.763-.244-1.272-.244-2.16 0-1.493.257-2.621.897-3.94a8.983 8.983 0 0 1 5.24-4.594c.705-.233 1.272-.348 2.18-.442.322-.033 1.571.015 1.98.077M11.3 6.042c-.524.081-1.143.332-1.66.673-.793.523-1.544 1.648-1.538 2.305a.98.98 0 0 0 1.387.885c.28-.125.391-.254.595-.685.28-.59.763-1.009 1.337-1.159 1.118-.291 2.285.478 2.462 1.623.054.347.025.571-.108.824-.268.51-1.099 1.122-2.011 1.481-.278.109-.429.196-.548.314a1.05 1.05 0 0 0-.296.697c0 .531.473 1 1.007 1 .423 0 1.638-.59 2.365-1.149 1.157-.89 1.678-1.88 1.616-3.07-.053-1.011-.439-1.874-1.166-2.606a3.905 3.905 0 0 0-1.853-1.058c-.359-.093-1.217-.133-1.589-.075m.395 10.013a1.05 1.05 0 0 0-.567.461c-.091.156-.108.23-.108.484 0 .257.016.327.113.492.518.882 1.865.526 1.865-.492a.994.994 0 0 0-.535-.888 1.17 1.17 0 0 0-.768-.057"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default GetHelpIcon;
