import React, { useCallback, useEffect, useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Fab } from '@material-ui/core';

import {
  AutocompleteComponent,
  DialogComponent,
  Spinner
} from '../../../../../../../Components';
import { DocumentsUploader } from '../../../../../../../SharedComponents/DocumentsUploader/DocumentsUploader'
import Joi from 'joi';
import { PropertyImagesType } from '../../../../../../../Enums';
import { GetParams, getErrorByName } from '../../../../../../../Helper';
import { GetAllPropertyImages, lookupItemsGetId } from '../../../../../../../Services';

export const ImageDialogsProperties = ({
  parentTranslationPath,
  translationPath,
  setData,
  activeItem,
  isCategoryExist,
  setIsCategoryExist,
  activeTab,
  setActiveItem,
  isOpenDialog,
  submitHandler,
  data,
  setIsOpenDialog
}) => {
  const activeTabName = activeTab === 0 ? 'images' : 'videos';
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const { t } = useTranslation(parentTranslationPath);
  const [lookuosData, setLookuosData] = useState([]);


  const initialStateImage = {
    categoryId: null,
    propertyId: +GetParams('id') || null,
    imageType: PropertyImagesType.Image,
    files: [],
  };
  const initialStateVideo = {
    categoryId: null,
    propertyId: +GetParams('id') || null,
    files: [],
  };
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [state, setState] = useReducer(reducer, activeTabName === 'images' ? initialStateImage : initialStateVideo);
  const [selected, setSelected] = useState({ lookupItemName: '', lookupItemId: '' });
  const schema = Joi.object({
    category: (state && state.categoryId === null && Joi.required()
      .messages({
        'any.required': t(`${translationPath}category-is-required`),
      }) || Joi.any()),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const getAllTitle = useCallback(async () => {
    setIsLoading(true);
    const res = await lookupItemsGetId({
      lookupTypeId: 1266,
    });
    if (!(res && res.status && res.status !== 200)) setLookuosData(res || []);
    else setLookuosData([]);
    setIsLoading(false);
  }, []);

  const getAllImageHandler = useCallback(async (categoryId) => {
    setIsLoading(true);
    const isCategoryUsed = data && data.find(el => el.categoryId === categoryId);
    const res = await GetAllPropertyImages({
      categoryId: categoryId,
      propertyId: +GetParams('id'),
      pageIndex: 1,
      PageSize: 50,
    });

    if (!(res && res.status && res.status !== 200)) {
      const lookupIndex = lookuosData.findIndex((item) => item.lookupItemId === categoryId);
      if (lookupIndex !== -1) setSelected(lookuosData[lookupIndex]);
      if (!activeItem) setIsCategoryExist(true);
      if (res.result.length) {
        setState({
          id: 'files',
          value:
            (res.result &&
              res.result.map((file) => ({
                fileId: (file.uuid || file.fileId || null),
                fileName: file.fileName,
              }))) ||
            [],
        })
        setState({
          id: 'categoryId',
          value: categoryId
        })

      }
      else if (isCategoryUsed) {
        setIsCategoryExist(true);

        setState({
          id: 'categoryId',
          value: isCategoryUsed && isCategoryUsed.categoryId
        })
      } else {
        setState({
          id: 'files',
          value: [],
        });
        setActiveItem(null)
        setIsCategoryExist(false);
      }
    } else {
      setState({
        id: 'files',
        value: [],
      });
      setActiveItem(null)
      setIsCategoryExist(false);
    }
    setIsLoading(false);
  },
    [activeItem, lookuosData]
  );

  useEffect(() => {
    getAllTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData(state);
  }, [state]);

  useEffect(() => {
    if (activeItem) getAllImageHandler(activeItem.categoryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllImageHandler]);


  return (
    <>
      <Spinner isActive={isLoading} isAbsolute />
      <DialogComponent
        titleText={activeItem || isCategoryExist ? 'edit-image' : 'Add-new-image'}
        saveText='save'
        saveType='button'
        saveIsDisabled={
          (state && state.categoryId === null || (!activeItem && !isCategoryExist && !state.files.length))
        }
        maxWidth='md'
        disableBackdropClick
        dialogContent={(
          <>
            <div
              className={
                state && state.files && state.files.length >= 1 ? 'inter-wraper' : 'inter-wraper-empty'
              }
            >
              <div className=''>
                <AutocompleteComponent
                  idRef='TitleRef'
                  labelValue='Title'
                  isLoading={isLoading}
                  selectedValues={selected || []}
                  getOptionSelected={(option) => option.lookupItemId === selected.lookupItemId}
                  helperText={
                    getErrorByName(schema, 'category').message
                  }
                  isDisabled={activeItem ? true : false}
                  error={getErrorByName(schema, 'category').error}
                  multiple={false}
                  data={lookuosData || []}
                  displayLabel={(option) => t(`${option.lookupItemName || ''}`)}
                  withoutSearchButton
                  isWithError
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    setSelected({
                      lookupItemName: newValue && newValue.lookupItemName,
                      lookupItemId: newValue && +newValue.lookupItemId,
                    });
                    setState({
                      id: 'categoryId',
                      value: newValue && +newValue.lookupItemId
                    })
                    getAllImageHandler(newValue && +newValue.lookupItemId);
                  }}
                />
              </div>
              <div className='UploaderComponent-wrapered pt-2'>
                <DocumentsUploader
                  initUploaded={(state && state.files &&
                    state.files.length > 0 &&
                    state.files.map((item) => ({
                      uuid: item.uuid || item.fileId,
                      fileName: item.fileName,
                    }))) ||
                    []}
                  docTypes={{ images: 0 }}
                  setIsLoading={setIsLoading}
                  isUploading={isUploading}
                  setIsUploading={setIsUploading}
                  uploadedChanged={(files) => {
                    setState({
                      id: 'files',
                      value:
                        (files &&
                          files.map((file) => ({
                            fileId: (file.uuid || file.fileId || null),
                            fileName: file.fileName,
                          }))) ||
                        [],
                    })
                  }}
                  open
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
            </div>
          </>
        )}
        saveClasses='btns theme-solid  w-100 mx-2 mb-2'
        isOpen={isOpenDialog}
        onSaveClicked={() => {
          submitHandler();
          setActiveItem(null);
          setIsCategoryExist(false);
          setIsOpenDialog(false);
          setState({
            id: 'files',
            value: [],
          })
          setState({
            id: 'categoryId',
            value: null
          })
          setSelected({
            lookupItemName: '',
            lookupItemId: '',
          });
        }}
        onCloseClicked={() => {
          setIsOpenDialog(false);
          setActiveItem(null);
          setIsCategoryExist(false);
          setState({
            id: 'files',
            value: [],
          });
          setState({
            id: 'categoryId',
            value: null
          });
          setSelected({
            lookupItemName: '',
            lookupItemId: '',
          });
        }}
        onCancelClicked={() => {
          setIsOpenDialog(false);
          setActiveItem(null);
          setIsCategoryExist(false);
          setState({
            id: 'files',
            value: [],
          });
          setState({
            id: 'categoryId',
            value: null
          });
          setSelected({
            lookupItemName: '',
            lookupItemId: '',
          });
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />

    </>
  );
};

ImageDialogsProperties.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  size: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  activeItem: PropTypes.instanceOf(Array).isRequired,
};
