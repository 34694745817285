import React  , {  useState } from 'react';
import { DialogComponent, Spinner } from '../../../../../Components';
import { CancelInquiryServices   } from '../../../../../Services' ; 
import { showSuccess  , showError  } from '../../../../../Helper' ; 
import { useTranslation } from 'react-i18next';

export const CompleteInquiryDialog = ({
  parentTranslationPath,
  translationPath,
  close,
  onSave , 
  activeItem, 
  isOpen , 
  isLoading  , 
  inquiry ,  
}) => {

  const { t } = useTranslation('Inquires');

  
  return (
    <DialogComponent
        titleText='confirm-message'
        saveText={t(`${translationPath}confirm`)}
        SmothMove
        saveType='button'
        maxWidth='sm'
        dialogContent={(
          <div className='d-flex-column-center'>
            <Spinner isActive={isLoading} isAbsolute />
            <span className='fz-18px fw-bold'>
              {`${`${t(`${translationPath}are-you-sure-to-complete-this-inquiry`)}` || ''}`}

              <span className='d-flex-center  fz-22px fw-bold mt-2'>
                {' '}
               {t(`${translationPath}inquiryId`)}
                {' '}
                {' '}
                {' '}
                (
                {' '}
                {' '}
                {' '}
                {' '}
                {' '}
                {( inquiry  && inquiry.inquiryId) }
                {' '}
                {' '}
                {' '}
                )
                {' '}
              </span>

            </span>
          </div>
        )}
        saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
        isOpen={isOpen}
        onSaveClicked={()=> {onSave()}}
        onCloseClicked={() =>  close()}
        onCancelClicked={() => close() }
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />

  );
};
