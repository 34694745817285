

import { HttpServices } from '../../Helper';
import { config } from '../../config';



export const GetAllPropertyViewScoresAPI = async () => {

    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PropertyRating/GetAllPropertyViewScores`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
  };

  export const UpdatePropertyRatingViewScoresAPI = async (body) => {
    const result = await HttpServices.post(
        `${config.server_address}/CrmDfm/PropertyRating/UpdatePropertyRatingViewScores`, body
    )
        .then((data) => data)
        .catch((error) => error.response.data);
    return result;
};
