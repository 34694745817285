import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { GetAllCampaignTeamMembers } from '../../../../../../../../Services';
import { Tables, Spinner } from '../../../../../../../../Components';
import { TableActions } from '../../../../../../../../Enums';
import { CampaignTeamHeaderData } from './CampaignTeamHeaderData';
import { AddTeamMemberDialog } from './AddTeamMemberDialog';
import { EditTeamMemberDialog } from './EditTeamMemberDialog';
import { DeleteDialog } from './DeleteDialog';


export function CampaignTeamView() {
  const parentTranslationPath = 'Campaign';
  const translationPath = 'campaign-team.';
  const marketingCampaignsInfo = useSelector((state) => state.MarketingCampaignsReducer);

  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
  });
  
  const tableActionClicked = useCallback((actionEnum, item) => {
    if (actionEnum === TableActions.editText.key) {
      setActiveItem(item);
      setIsEditDialogOpen(true);
    } else if (actionEnum === TableActions.deleteText.key) {
      setActiveItem(item);
      setIsDeleteDialogOpen(true);
    }
  }, []);

  const getTableActions = () => {
    const actions = [
      {
        enum: TableActions.editText.key,
        isDisabled: false,
        externalComponent: null,
      },
      {
        enum: TableActions.deleteText.key,
        isDisabled: false,
        externalComponent: null,
      }
    ];
    return actions;
  };

  const addNewHandler = () => {
    setIsAddDialogOpen(true);
  };

  const getAllCampaignTeamMembers = useCallback(async () => {
    setIsLoading(true);

    const res = await GetAllCampaignTeamMembers({
      requestId: marketingCampaignsInfo?.campaignRequestId,
    });
    if (!(res && res.status && res.status !== 200)) {
      setTeamMembers(res|| []);
    } else setTeamMembers([]);

    setIsLoading(false);
  }, [marketingCampaignsInfo?.campaignRequestId]);

  useEffect(() => {
    getAllCampaignTeamMembers();
  }, []);

  return (
    <div className='campaign-team-f1 p-2'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='d-flex-v-center-h-between'>
        <Button
          className='btns mx-3 mb-2 theme-solid'
          onClick={addNewHandler}
          id='action_add-new_plus'>
          <span className='mdi mdi-plus' />
          {t(`${translationPath}add-new`)}
        </Button>
      </div>

      <div className='p-2 w-100'>
        <Tables
          data={teamMembers}
          headerData={CampaignTeamHeaderData}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          activePage={filter.pageIndex}
          defaultActions={getTableActions()}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>

      {isAddDialogOpen && (
        <AddTeamMemberDialog
          isDialogOpen={isAddDialogOpen}
          activeItem={activeItem}
          teamMembersData={teamMembers}
          onSave={() => {
            getAllCampaignTeamMembers();
            setIsAddDialogOpen(false);
          }}
          onClose={() => {
            setIsAddDialogOpen(false);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {isEditDialogOpen && (
        <EditTeamMemberDialog
          isDialogOpen={isEditDialogOpen}
          activeItem={activeItem}
          teamMembersData={teamMembers}
          onSave={() => {
            getAllCampaignTeamMembers();
            setIsEditDialogOpen(false);
            setActiveItem(null);
          }}
          onClose={() => {
            setIsEditDialogOpen(false);
            setActiveItem(null);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {isDeleteDialogOpen && (
        <DeleteDialog
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          isDeleteDialogOpen={isDeleteDialogOpen}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          reloadData={() => { getAllCampaignTeamMembers(); }}
          onCloseClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          onCancelClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </div>
  );
}
