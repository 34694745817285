import { ClassificationsContactEnum, ContactsFieldsId } from '../Enums';
import { GetContacts, GetCorporateContacts } from '../Services';

let oldvalue = '';
let timer = null;
let isAdvance = null;
// eslint-disable-next-line prefer-const
export const ContactRule = async (item, value, setRerender) => {
  // eslint-disable-next-line no-constant-condition

  /// ////////////////////////////////////////////////////////////////////////////////
  if (item.field.id === 'lease_lead_owner') isAdvance = true;
  if (item.field.id === 'lead_owner') isAdvance = true;
  // When Delete This Tow Line The Api Of Get contacts Will Ba Always Send (Is advance) == False In All Form Builder Contact Rule
  /// ////////////////////////////////////////////////////////////////////////////////

  if (!item.data.searchKey) return;
  if (item.data.searchKey !== 'contact') return;
  if (item.value === '') return;
  if (value === '') return;
  if (timer !== null) clearTimeout(timer);

  if (oldvalue === value) return;
  oldvalue = value;

  timer = setTimeout(async () => {
    let rs = null;
    let classIndex = -1;
    item.data.enum = [];
    if (item.data.dependOn) classIndex = ClassificationsContactEnum.findIndex((f) => item.data.dependOn.toLowerCase() === f.name.toLowerCase());
    if (item.data.dependOn === 'company')
    rs = await GetCorporateContacts({ pageIndex: 0, pageSize: 10, search: value });
    else if (classIndex !== -1) {
      rs = await GetContacts({
        pageIndex: 0, pageSize: 10, search: value, classificationId: ClassificationsContactEnum[classIndex].Id
      });
    } else {
 rs = await GetContacts({
 pageIndex: 0, pageSize: 50, search: value, isAdvance: false
});
}

    if (!rs || !rs.result) return;
    rs.result.map((element) => {
      if (!element.contact) return;
      if (element.contact.contact_type_id !== 2) {
        item.data.enum.push({
          id: element.contactsId,
          name: `${element.contact.first_name} ${element.contact.last_name}`,
          type: element.contact.contact_type_id,
          mobile: (element.contact.mobile && element.contact.mobile.phone) || null,
          email_address: (element.contact.email_address && element.contact.email_address.email) || null,
          contact_image: (element.contact.contact_image) || null,
          high_profile_management : (element.contact.high_profile_management)  ,
          angry_bird :(element.contact.angry_bird)  ,
        });
      } else if (+(element.contact.contact_type_id) === 2) {
        item.data.enum.push({
          id: element.contactsId,
          name: element.contact.company_name,
          type: element.contact.contact_type_id,
          mobile: (element.contact.landline_number && element.contact.landline_number.phone) || null,
          email_address: (element.contact.general_email && element.contact.general_email.email) || null,
          contact_image: element.contact.company_logoimage ?
           (element.contact.company_logoimage['Company Logo'] ?
           (element.contact.company_logoimage['Company Logo'] && element.contact.company_logoimage['Company Logo'].length && element.contact.company_logoimage['Company Logo'][0]) : (element.contact.company_logoimage['Company Images'] && element.contact.company_logoimage['Company Images'].length && element.contact.company_logoimage['Company Images'][0])) :
           null ,
           high_profile_management : (element.contact.high_profile_management)  ,
           angry_bird  :(element.contact.angry_bird)  ,

        });
      }
    });

    setRerender(Math.random());
  }, 500);
};
export const ContactDefaultRule = async (item, setRerender) => {
  if (item.data.searchKey !== 'contact') return;
  if (item.data.enum) return;

  if (timer !== null) clearTimeout(timer);
  let rs = null;

  let classIndex = -1;
  timer = setTimeout(async () => {
  if (item.data.dependOn) classIndex = ClassificationsContactEnum.findIndex((f) => item.data.dependOn.toLowerCase() === f.name.toLowerCase());
  if (item.data.dependOn === 'company') rs = await GetCorporateContacts({ pageIndex: 0, pageSize: 50 });
  else if (classIndex !== -1) rs = await GetContacts({ pageIndex: 0, pageSize: 50, classificationId: ClassificationsContactEnum[classIndex].Id });
  else rs = await GetContacts({ pageIndex: 0, pageSize: 50, isAdvance: false });

  item.data.enum = [];
  if (!rs || !rs.result) return;
  rs.result.map((value) => {
    if (value.contact.contact_type_id !== 2) {
      item.data.enum.push({
        id: value.contactsId,
        name: `${value.contact.first_name} ${value.contact.last_name}`,
        phone: (value.contact.mobile && value.contact.mobile.phone),
        type: value.contact.contact_type_id,
        high_profile_management : (value.contact.high_profile_management)  ,

      });
    } else {
      item.data.enum.push({
        id: value.contactsId,
        name: value.contact.company_name,
        phone: (value.contact.landline_number && value.contact.landline_number.phone),
        type: value.contact.contact_type_id,
        high_profile_management : (value.contact.high_profile_management)  ,

      });
    }
  });
    setRerender(Math.random());
  }, 500);
};

export const AutoSelectContactRule = async (item, setRerender, contactId, setData, index, setNewValue) => {
  if (item.data.searchKey !== 'contact') return;
  if (item.data.enum) return;
  if (item.field.id === 'contact_name') {
  const contactData = JSON.parse(localStorage.getItem('parentContact'));
    if (contactData.contact_type_id !== 2) {
      const obj = {
        id: contactData.id,
        name: `${contactData.first_name} ${contactData.last_name}`,
        type: contactData.contact_type_id,
      };
      setData(index, obj);
      setNewValue(obj);
    } else {
      const company =
      {
        id: contactData.id,
        name: contactData.company_name,
        type: contactData.contact_type_id,
      };
      setData(index, company);
      setNewValue(company);
    }
}
  setRerender(Math.random());
};
export async function OnAddnewContactRule(
  item,
  itemList,
  setData,
  itemsDialogValue,
) {
  const fieldkey = ContactsFieldsId.filter((x) => x === item.field.id);
  if (itemsDialogValue && itemsDialogValue.contactId !== null) {
    const i1Index = itemList.findIndex((f) => f.field.id.toLowerCase() === fieldkey[0]);
    if (i1Index && itemsDialogValue && itemsDialogValue.contactId) {
      setData(fieldkey[0], {
        id: itemsDialogValue && itemsDialogValue.contactId,
        name: itemsDialogValue && itemsDialogValue.contactsTypeId === 1 ? `${itemsDialogValue.firstName} ${itemsDialogValue.lastName}` : itemsDialogValue.companyName,
        phone: itemsDialogValue && itemsDialogValue.contactsTypeId === 1 ? itemsDialogValue.mobile : itemsDialogValue.landLineNumber,
        type: itemsDialogValue && itemsDialogValue.contactsTypeId,
      });
    }
  }
}
