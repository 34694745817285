import React, { useState } from "react";
import { TabsComponent } from "../../../../../../Components";
import { KYCTabsData } from "./Tabs/KYCTabsData";

export const KycViewComponent = () => {
  const [activeTab, setActiveTab] = useState(0);

  const onTabChanged = (_, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <>
      <TabsComponent
        wrapperClasses='w-100'
        data={KYCTabsData}
        labelInput='label'
        themeClasses='theme-curved'
        currentTab={activeTab}
        onTabChanged={onTabChanged}
        dynamicComponentProps={{
        }}
      />
    </>
  );
};
