export const KYCFormHTMLTemplate = `
<html>

<head>
<link rel="stylesheet" href="styles.css"/>
<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet"/>

<style>
body{font-family:"Poppins",sans-serif;}
.KycForm{width:92%;margin:auto;}
.Header_Button,tfoot{display:none;}
fieldset{border:unset;}
.form_actions{display:flex;justify-content:center;}
.Form_Table{width:100%;table-layout:fixed;border-collapse:collapse;}
th,td{text-align:left;}
.KycForm .Nested_Table,.KycForm .Dynamic_Table{width:100%;table-layout:fixed;border-collapse:collapse;}
.KycForm .Nested_Table.tow-col-table{table-layout:auto;}
.KycForm .Nested_Table.tow-col-table td:nth-of-type(2){width:65%;}
.KycForm th,.KycForm td{border:1px solid #333;}
.KycForm td{padding:8px;font-size:12px;}
.KycForm .Spaced_Table_Header th{display:flex;justify-content:space-between;align-items:end;padding:4px 18px;background-color:#363f72 !important;color:white;margin-top:25px;}
.KycForm .Spaced_Table_Header .Header_Title{display:block;font-size:18px;}
.KycForm .Spaced_Table_Header .Header_Sub_Title{display:block;font-size:14px;font-weight:300;text-decoration:underline;font-style:italic;}
.KycForm .Space_After::after{content:"";display:block;height:15px;background-color:transparent;}
.KycForm .Row_Text{border:unset;padding:15px 0px;}
.KycForm .Dynamic_Table th{padding:8px;font-size:12px;}
.KycForm .Cell_Value{background-color:rgba(179,184,219,0.25);padding:4px 8px;min-height:26px;}
.signature_input canvas{border:unset; width: 100%;}
.Cell_Value .MuiFormControlLabel-root.form-control-label{align-items:center;display:flex;}
input[type="checkbox"]{height: 25px;cursor:pointer;appearance:none;-webkit-appearance:none;display:flex;justify-content:center;margin:12px;padding:2px;padding-top:0px;border:1px solid gray;background:#dfe2f7;}
input[type="checkbox"]::before{content:"";width:1.1rem;height:1.1rem;transform:scale(0);background-color:#363f72;clip-path:polygon(20% 50%,40% 70%,80% 30%,90% 40%,40% 85%,10% 55%);}
input[type="checkbox"]:checked::before{transform:scale(1);}
.checkbox-group-wrapper{display:grid;grid-template-columns:1fr 1fr 1fr;grid-template-rows:1fr 1fr;}
.single_checkbox{display:flex;}
.single_checkbox label{padding-top: 8px;}
.form_actions{display:flex;justify-content:center;margin-block:2rem;}
.form_actions button{width:100px;border-radius:8px;margin-inline:8px;display:flex;padding:10px 16px;justify-content:center;align-items:center;grid-gap:4px;gap:4px;font-weight:600;box-shadow:0px 1px 2px 0px rgba(16,24,40,0.05);}
.form_actions button.solid{border:1px solid #363f72;background:#363f72;color:#FFF;}
.form_actions button.outlined{border:1px solid #D0D5DD;background:#FFF;color:#344054;}
.signature_button{display:block;margin:auto;right:0px;width:fit-content;margin-block:8px;border-radius:8px;padding:4px 6px;box-shadow:0px 1px 2px 0px rgba(16,24,40,0.05);background-color:#363f72;color:#fff;font-weight:400;font-size:10px;line-height:1;border:1px solid #363f72; cursor: pointer}
.signature_input{border: 0.717px dashed #000;}
input[type=text]{width:100%;height:25px;border:0px transparent;background:transparent;outline:unset;}
.radio-btn-group{display:flex;}
.radio-btn-group label{margin-top:4px; margin-right:12px;cursor:pointer;}
.radio-btn-group input{accent-color: #363f72;cursor:pointer;}
.signature_text{border-bottom: 1px solid gray !important;margin-top: 12px;}
.signature_placeholder{display:flex;height:120px;padding:3.584px 7.167px;align-items:flex-start;gap:3.584px;align-self:stretch;border:0.717px dashed #000;color:#8c8c8c;}
@page { margin: 0; } 
@media print {body{-webkit-print-color-adjust:exact; margin: 0; padding: 0;  }}
</style>

</head>

<body>
<div>
{{{innerHTML}}}
{{#showActionButtons}}
<div class="form_actions">
<button id="reject-button" class="outlined" tabindex="0" type="button">Reject<span class="MuiTouchRipple-root"></span></button>
<button id="approve-button" class="solid" tabindex="0" type="button">Approve<span class="MuiTouchRipple-root"></span></button>
</div>
{{/showActionButtons}}
</div>
</div>
</body>

</html>
`;
