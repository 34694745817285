
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Spinner, Tables  } from '../../../../../Components';
import { TableActions } from '../../../../../Enums';
import { PortalAccountManagementDialog } from '../PortalAccountManagement/PortalAccountManagementDialog' ; 
import { PortalAccountDetailsDialog } from  '../PortalAccountDetails' ; 

export const PortalAccountsTableView = ({
   parentTranslationPath,
   translationPath,
   filter,
   data,
   isLoading,
   reloadData,
   activeItem ,
   setActiveItem ,
   onSave , 
   setFilter , 
   onPageIndexChanged,
   onPageSizeChanged,
   isReloadPortalAccountData , 
   setIsReloadPortalAccountData , 
   portalAccout , 
   setPortalAccout , 

}) => {

  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isOpenDetailsDialog, setIsOpenDetailsDialog] = useState(false);


  const { t } = useTranslation([parentTranslationPath, 'Shared']);

  const tableActionClicked = useCallback((actionEnum, item) => {
    setActiveItem(item);
      if (actionEnum === TableActions.apiDetails.key)
      setIsOpenDetailsDialog(true) ; 
       else if (actionEnum === TableActions.editText.key)
         setIsOpenEditDialog(true) ; 
  }, []);

  const getActionTableWithPermissions = () => {
    const list = [];
    
      list.push({ enum: TableActions.editText.key , title: t(`${translationPath}Shared:edit`)});
      list.push({ enum: TableActions.apiDetails.key, title: t(`${translationPath}apiDetails`)});


    return list;
  };

  const repeated = (list, filed) => list && list.map((item, index) => (
    <span key={index}>
      {' '}
      {item[filed]}
      {' '}
      {list.length - 1 != index &&
        <span> , </span>}
    </span>
  ));

return (
<div className='PortalAccountsTabelView'>
      <Spinner isActive={(isLoading.allPortalsAccountsData)} isAbsolute />
      <div className='filter-section-item' />
      <div className='w-100 px-2'>
        <Tables
          data={(data && data.result) || []}
          headerData={[
            {
              id: 1,
              input: 'portalAccountName',
              label: t(`${translationPath}accountName`),

            },
            {
              id: 2,
              input: 'portalName',
              label: t(`${translationPath}portalName`),

            },
            {
              id: 3,
              label: t(`${translationPath}portal`),
              component: (item) => <span>{(item && item.portalDto && item.portalDto.portalName) || ''}</span>


            },
            {
              id: 3,
              input: 'status',
              label: t(`${translationPath}status`),
              cellClasses: 'py-0',
              component: (item, index) => (
                (item.status && <div className='globel-open-style' index={index}>{t(`${translationPath}active`)}</div>) || <div className='globel-closed-style' index={index}>{t(`${translationPath}inactive`)}</div>
              ),

            },
            {
              id: 4,
              label: t(`${translationPath}branches`),
              component: (item) => repeated(item.listOfPortalAccountBranches, 'branchName'),

            },

            {
              id: 5,
              input: 'createdBy',
              label: t(`${translationPath}createdBy`),

            },
            {
              id: 6,
              input: 'createdOn',
              label: t(`${translationPath}createdOn`),
              isDate: true,
              dateFormat: 'MMM, DD, YYYY hh:mm a',

            }, 
          ]}
          defaultActions={getActionTableWithPermissions()}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          totalItems={(data && data.totalCount) || 0}
          onPageIndexChanged ={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      </div>
      {
        isOpenEditDialog && 
        ( 
          <PortalAccountManagementDialog 
             parentTranslationPath={parentTranslationPath}
             translationPath={translationPath}
             isOpen={isOpenEditDialog}
             onClose={()=> {
              setIsOpenEditDialog(false) ;
              setActiveItem(null) ;
              setPortalAccout(null)  ; 
             }} 
             onSave={()=> 
             {
              setIsOpenEditDialog(false) ; 
              setIsReloadPortalAccountData(!isReloadPortalAccountData);
              setActiveItem(null) ; 
              setPortalAccout(null)  ; 

             }}
             isLoading={isLoading}
             editPortalAccount={portalAccout}
          />
        ) 
      }
      {
        isOpenDetailsDialog && 
        (
          <PortalAccountDetailsDialog
           parentTranslationPath={parentTranslationPath}
           translationPath={translationPath}
           isOpen={isOpenDetailsDialog}
           onClose={()=> {
            setIsOpenDetailsDialog(false) ;
             setActiveItem(null) ;
           }}
          portalAccountId={(activeItem && activeItem.portalAccountId)} 
        />
        )  
      }
    </div>
  );
};

PortalAccountsTableView.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  data:PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.instanceOf(Object).isRequired,
  onPageIndexChanged :  PropTypes.func.isRequired, 
  onPageSizeChanged: PropTypes.func.isRequired,  

};
