import React, { useState } from 'react';
import { PlansView } from './PlansView';
import { InstallmentView } from './InstallmentView';

const PlanViewsEnum = {
  PLANS: 'plans',
  INSTALLMENTS: 'installment',
};

export const PropertiesPaymetPlanComponent = (props) => {
  const [view, setView] = useState(PlanViewsEnum.PLANS);
  const [planId, setPlanId] = useState(null);
  const handleChangeView = (view, selectedPlanId) => {
    setView(view);
    setPlanId(selectedPlanId ?? null);
  };
  return view === PlanViewsEnum.PLANS ? (
    <PlansView
      {...props}
      handleChangeView={(planId) =>
        handleChangeView(PlanViewsEnum.INSTALLMENTS, planId)
      }
    />
  ) : (
    <InstallmentView
      {...props}
      handleChangeView={() => handleChangeView(PlanViewsEnum.PLANS)}
      planId={planId}
    />
  );
};
