import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import clsx from "clsx";

import {
  useIsDesktop,
  useMainContainerPadding,
  useQuery,
  useSelectedTheme,
  useTitle,
  useTranslate,
} from "../../../../Hooks";
import {
  CustomButton,
  PageTitle,
  CustomTabs,
  CustomBreadCrumb,
  CustomBadge,
  ContactProfileOverviewSkeleton,
  SocialProfilesSkeleton,
  AddDNCRAndConsentModal,
} from "../../../../Components";
import {
  AssignedAgents,
  AssociatedContacts,
  ContactActivities,
  ContactDetails,
  ContactDocuments,
  ContactDuplicates,
  ContactLeads,
  ContactOverviewSummary,
  ContactProfileOverview,
  ContactTasks,
  ContactUnits,
  SocialProfiles,
  TransactionHistory,
  ContactDNCR,
} from "./UI";
import { ContactLayoutContext } from "../../../../Layouts/Home/NewContactsCrmLayout/ContactLayoutContext";
import { useVerticalNav } from "../../../../Contexts/VerticalNavContext";
import { GetAdvanceSearchContacts } from "../../../../Services";
import { ContactsMapper } from "../../ContactsView";

// Styles
import useStyles from "./styles";

// Icons
import { ClockIcon, EditIcon, PlusIcon } from "../../../../assets/icons";

function ContactOverview() {
  const { isExpanded } = useVerticalNav();

  const associatedContactsRef = useRef();
  const { setActionableItems } = useContext(ContactLayoutContext);
  const history = useHistory();

  const styles = useStyles();

  const [tabValue, setTabValue] = useState(0);

  const [contactDetails, setContactDetails] = useState({
    result: {},
    totalCount: 0,
  });
  const [DNCRAndConsentModalType, setDNCRAndConsentModalType] = useState(false);
  const [refetchDNCRAndConsent, setRefetchDNCRAndConsent] = useState(0);
  const [isContactDetailsLoading, setIsContactDetailsLoading] = useState(true);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);

    setActionableItems({
      selectedIds: [],
      action: null,
    });
  };

  const query = useQuery();
  const contactId = query.get("id");
  const formTypeId = query.get("formType");

  const { translate } = useTranslate("NewContactsView");

  const {
    theme: { palette },
  } = useSelectedTheme();

  useTitle(translate("contact-overview-page-title"));

  const { isDesktop } = useIsDesktop();

  useMainContainerPadding("16px 32px", "32px");

  const editActionButton = {
    label: translate("edit"),
    handler: () => {
      history.push(
        `/home/Contacts-CRM/contact-profile-edit?formType=${formTypeId}&id=${contactId}`
      );
    },
    startIcon: (
      <EditIcon width="20" height="20" fill={palette.button.primary_fg} />
    ),
  };

  const contactOverviewTabs = [
    {
      title: translate("contact_overview"),
      subTitle: translate("contactOverview-subTitle"),
      label: translate("overview"),
      component: (
        <ContactOverviewSummary
          setTabValue={setTabValue}
          contactId={contactId}
        />
      ),
      actionButton: editActionButton,
    },
    {
      title: translate("contact-details"),
      subTitle: translate("contactOverview-subTitle-details"),
      label: translate("contact-details"),
      component: <ContactDetails details={contactDetails?.result} />,
      actionButton: editActionButton,
    },
    {
      title: translate("dncr"),
      subTitle: translate("contactOverview-subTitle-dncr"),
      label: translate("dncr-tab"),
      component: (
        <ContactDNCR
          contactId={Number(contactId)}
          setDNCRAndConsentModalType={setDNCRAndConsentModalType}
          refetchDNCRAndConsent={refetchDNCRAndConsent}
        />
      ),
      actionButton: null,
    },
    {
      title: translate("associated-contacts"),
      subTitle: translate("contactOverview-subTitle-associated-contacts"),
      label: translate("associated-contacts"),
      component: (
        <AssociatedContacts
          contactId={Number(contactId)}
          ref={associatedContactsRef}
        />
      ),
      actionButton: {
        label: "Add Associated contacts",
        handler: () => {
          if (associatedContactsRef.current) {
            associatedContactsRef.current.handleAddAssociatedContacts();
          }
        },
        startIcon: (
          <PlusIcon width="20" height="20" fill={palette.button.primary_fg} />
        ),
      },
    },
    {
      title: translate("leads"),
      subTitle: translate("contactOverview-subTitle-leads"),
      label: translate("leads"),
      component: <ContactLeads contactId={Number(contactId)} />,
      actionButton: {
        label: "Add Lead",
        handler: () => {},
        startIcon: (
          <PlusIcon width="20" height="20" fill={palette.button.primary_fg} />
        ),
      },
    },
    {
      title: translate("units"),
      subTitle: translate("contactOverview-subTitle-units"),
      label: translate("units"),
      component: <ContactUnits contactId={Number(contactId)} />,
      actionButton: null,
    },
    {
      title: translate("activities"),
      subTitle: translate("contactOverview-subTitle-activities"),
      label: translate("activities"),
      component: <ContactActivities contactId={Number(contactId)} />,
      actionButton: {
        label: "Add New Activity",
        handler: () => {},
        startIcon: (
          <PlusIcon width="20" height="20" fill={palette.button.primary_fg} />
        ),
      },
    },
    {
      title: translate("tasks"),
      subTitle: translate("contactOverview-subTitle-tasks"),
      label: translate("tasks"),
      component: <ContactTasks contactId={Number(contactId)}/>,
      actionButton: {
        label: "Add new task",
        handler: () => {},
        startIcon: (
          <PlusIcon width="20" height="20" fill={palette.button.primary_fg} />
        ),
      },
    },
    {
      title: translate("transactions-history"),
      subTitle: translate("contactOverview-subTitle-transactions-history"),
      label: translate("transactions-history"),
      component: <TransactionHistory contactId={Number(contactId)} />,
      actionButton: null,
    },
    {
      title: translate("assigned-agents"),
      subTitle: translate("contactOverview-subTitle-assigned-agents"),
      label: translate("assigned-agents"),
      component: <AssignedAgents contactId={Number(contactId)} />,
      actionButton: null,
    },
    {
      title: translate("documents"),
      subTitle: translate("contactOverview-subTitle-documents"),
      label: translate("documents"),
      component: <ContactDocuments />,
      actionButton: {
        label: "Add New document",
        handler: () => {},
        startIcon: (
          <PlusIcon width="20" height="20" fill={palette.button.primary_fg} />
        ),
      },
    },
    {
      title: translate("duplicates"),
      subTitle: translate("contactOverview-subTitle-duplicates"),
      label: translate("duplicates"),
      component: <ContactDuplicates />,
      actionButton: null,
    },
  ];

  const getContactDetails = useCallback(async () => {
    setIsContactDetailsLoading(true);

    try {
      const isForLog = true;

      let body = {
        criteria: {
          Ids: [{ searchType: 1, value: contactId }],
        },
        filterBy: "createdOn",
        orderBy: 2,
      };

      const res = await GetAdvanceSearchContacts(
        { pageIndex: 0, pageSize: 2, isForLog },
        body
      );

      if (!(res && res.status && res.status !== 200)) {
        if (res && res.totalCount > 0) {
          if (
            res &&
            res.result &&
            res.result.length &&
            res.result[0].contactJson
          ) {
            setContactDetails({
              result: ((res && res.result) || []).map(
                (item) =>
                  item.contactJson &&
                  ContactsMapper(item, JSON.parse(item.contactJson).contact)
              )?.[0],
              totalCount: (res && res.totalCount) || 0,
            });
          }
        }
      }
    } catch (err) {
      setContactDetails({
        result: {},
        totalCount: 0,
      });
      console.error("Failed to fetch contact:", err);
    } finally {
      setIsContactDetailsLoading(false);
    }
  }, [contactId]);

  useEffect(() => {
    if (contactId) {
      getContactDetails();
    }

    return () => {
      setTabValue(0);
    };
  }, [contactId]);

  return (
    <>
      <CustomBreadCrumb
        breadcrumbs={[
          { label: translate("crm"), nonClickable: true },
          { label: translate("contact"), link: "/home/Contacts-CRM/view" },
        ]}
        containerClasess={styles.breadCrumbContainer}
      >
        <CustomBadge
          Style={{
            padding: "4px 8px",
            borderRadius: "6px",
            lineHeight: "20px",
          }}
          label={`${
            contactDetails?.result?.company_name
              ? `${contactDetails?.result?.company_name}`
              : `${contactDetails?.result?.first_name || ""} 
            ${contactDetails?.result?.last_name || ""}`
          } #${contactId ?? ""}`}
          BackgroundColor={palette.breadcrumbs.brand_bg_hover}
          BorderColor={palette.breadcrumbs.brand_bg_hover}
          Color={palette.breadcrumbs.brand_fg_hover}
        />
      </CustomBreadCrumb>

      <PageTitle
        title={contactOverviewTabs[tabValue].title}
        subTitle={contactOverviewTabs[tabValue].subTitle}
        hideDivider
        showBackIcon
      >
        {!!contactOverviewTabs[tabValue].actionButton && (
          <CustomButton
            boxShadow="xs"
            size="lg"
            variant="outlined"
            color="primary"
            onClick={contactOverviewTabs[tabValue].actionButton?.handler}
            startIcon={
              contactOverviewTabs[tabValue].actionButton?.startIcon ?? null
            }
          >
            {contactOverviewTabs[tabValue].actionButton?.label}
          </CustomButton>
        )}
      </PageTitle>

      <CustomButton
        boxShadow="none"
        variant="text"
        size="md"
        color="tertiary"
        startIcon={
          <ClockIcon width="20" height="20" fill={palette.button.tertiary_fg} />
        }
        style={{ marginTop: "10px", marginInlineStart: "36px" }}
      >
        {translate("history")}
      </CustomButton>

      {DNCRAndConsentModalType && (
        <AddDNCRAndConsentModal
          contactId={contactId}
          variant={DNCRAndConsentModalType}
          onClose={() => {
            setDNCRAndConsentModalType(undefined);
          }}
          setRefetchDNCRAndConsent={setRefetchDNCRAndConsent}
        />
      )}

      <Box
        className={clsx(styles.overviewWrapper, {
          [styles.overviewWrapperExpanded]: isExpanded && isDesktop,
        })}
      >
        <Box
          className={clsx(styles.profileSide, {
            [styles.profileSideExpanded]: isExpanded && isDesktop,
          })}
        >
          {isContactDetailsLoading && <ContactProfileOverviewSkeleton />}
          {!isContactDetailsLoading && !!contactDetails?.result && (
            <ContactProfileOverview details={contactDetails.result} />
          )}

          {isDesktop && (
            <>
              {isContactDetailsLoading ? (
                <SocialProfilesSkeleton numberOfCards={6} />
              ) : (
                <SocialProfiles contactDetails={contactDetails.result} />
              )}
            </>
          )}
        </Box>

        <Box
          className={clsx(styles.tabsSide, {
            [styles.tabsSideExpanded]: isExpanded && isDesktop,
          })}
        >
          <CustomTabs
            tabValue={tabValue}
            onTabChange={handleTabChange}
            tabs={contactOverviewTabs}
            variant="overview"
          />
        </Box>
      </Box>
    </>
  );
}

export default ContactOverview;
