
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import i18next from 'i18next';
import { ButtonBase, Fab, Tooltip } from '@material-ui/core';
import {
  CheckboxesComponent,
  LoadableImageComponant,
  ProgressComponet,
  Spinner,
  PermissionsComponent
} from '../../../../../../Components';
import {
  ActionsEnum,
  ContactTypeEnum,
  LeadsClassTypesEnum,
  LeadsPriorityEnum,
  LeadsQualificationEnum,
  LeadsStatusEnum,
  LeadTypeIdEnum,
  LoadableImageEnum,
} from '../../../../../../Enums';
import {
  getDownloadableLink, showError, showinfo, showSuccess, GetParams, GlobalHistory
} from '../../../../../../Helper';
import { CloneLead } from '../../../../../../Services';
import { CopyToClipboardComponents } from '../../../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';
import { CloneLeadView } from '../../../../../Share/SharedActionsDFM/CloneLeadView/CloneLeadView';

export const LeadsCardsComponent = ({
  data,
  activeCard,
  isExpanded,
  onCardClicked,
  onFooterActionsClicked,
  parentTranslationPath,
  translationPath,
  notExpandedMax,
  withCheckbox,
  onCardCheckboxClick,
  selectedDetailsLeadItem,
  onActionClicked,
  isCheckBoxDisabled,
  selectedCards,
  relodedata,
  withCheckboxMatching,
  activeSelectedAction,
  checkedCards,
  onViewMoreClicked,
  handleRemoveFavorite,
  folderIdValue
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const pathName = window.location.pathname.split('/home/')[1].split('/')[0];
  const [isOpen, setIsOpen] = useState(false);
  const [isActiveIteam, setIsActiveIteam] = useState(false);
  // const [// actionsAttachedWith,setActionsMenuAttachedWith] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [firsetUserType, setFirsetUserType] = useState(false);
  const [buttonWidth, setButtonWidth] = useState('w-30');
  // const loginResponse = useSelector((state) => state.login.loginResponse);
  const getDefaultLeadImage = useCallback(
    (leadType) => ContactTypeEnum[leadType] && ContactTypeEnum[leadType].defaultImg,
    []
  );
  const getIsSelectedCard = useCallback(
    (row) => selectedCards && selectedCards.findIndex((item) => item.id === row.id) !== -1,
    [selectedCards]
  );
  useEffect(() => {
    if (checkedCards && checkedCards.length) setFirsetUserType(checkedCards[0].leadClass || '');
    else setFirsetUserType(false);
  }, [checkedCards]);


  const onViewMoreHandler = useCallback(
    (item, index) => () => {
      if (onViewMoreClicked) onViewMoreClicked(item, index);
      if (item.leadTypeId === LeadsClassTypesEnum.seller.key || item.leadTypeId === LeadsClassTypesEnum.buyer.key) {
        GlobalHistory.push(
          `/home/lead-sales/lead-profile-edit?formType=${item.leadTypeId}&id=${item.id}`
        );
      } else if (item.leadTypeId === LeadsClassTypesEnum.tenant.key || item.leadTypeId === LeadsClassTypesEnum.landlord.key) {
        GlobalHistory.push(
          `/home/lead-lease/lead-profile-edit?formType=${item.leadTypeId}&id=${item.id}`
        );
      }
      else {
        GlobalHistory.push(
          `/home/leads/lead-profile-edit?formType=${item.leadTypeId}&id=${item.id}`
        );
      }
    },
    [onViewMoreClicked, pathName]
  );



  const cloneHandler = async (leadIds) => {
    setIsLoading(true);
    const result = await CloneLead(leadIds);
    if (!(result && result.status && result.status !== 200)) {
      showSuccess(t(`${translationPath}leads-cloned-successfully`)); relodedata();
      setIsLoading(false);
    } else { showError(t(`${translationPath}leads-clone-failed`)); setIsLoading(false); }
  };

  return (
    <div className='leads-cards-wrapper childs-wrapper'>
      <Spinner isActive={isLoading} />
      {data.result &&
        data.result.map((item, index) => (
          <>
            {item && (
              <div
                className={`leads-card-wrapper${isExpanded ? ' is-expanded' : ''}${(((activeCard && activeCard.id === item.id) ||
                  (selectedDetailsLeadItem && selectedDetailsLeadItem.id === item.id)) &&
                  ' is-open') ||
                  ''
                  }`}
                key={`leadsCardItemRef${index + 1}`}
              >
                {(withCheckbox || withCheckboxMatching) && (
                  <div className='card-checkbox-wrapper'>
                    <CheckboxesComponent
                      idRef={`leadsCheckboxItemRef${index + 1}`}
                      singleChecked={getIsSelectedCard(item)}
                      onSelectedCheckboxClicked={(event) => {
                        event.preventDefault();
                        onCardCheckboxClick(index, item);
                      }}
                      isDisabled={
                        activeSelectedAction === 'reassign-leads' &&
                        (firsetUserType && firsetUserType !== item.leadClass || item.status.lookupItemName === 'Closed' || item.leadClass === 'Landlord' || item.leadClass === 'Seller')
                      }
                    />
                  </div>
                )}
                <a onClick={onCardClicked && onCardClicked(item, index)} className='cards-wrapper'>
                {
                  item && item.psi_opportunity === 'Yes' && (
                    <div className="lauxary-banner">
                    <p>{t(`${translationPath}PSI-opportunity`)}</p>
                  </div>
                  )
                }
                  <div className='cards-body-wrapper'>
                    <div className='card-body-section'>
                      <LoadableImageComponant
                        classes='cover-image'
                        type={LoadableImageEnum.div.key}
                        alt={t(`${translationPath}lead-image`)}
                        src={
                          (item && item.contact_name && item.contact_name.contact_image && item.contact_name.contact_image.uuid && getDownloadableLink(item.contact_name.contact_image && item.contact_name.contact_image.uuid)) ||
                          getDefaultLeadImage(item.type)
                        }
                      />
                      <div className='actions-wrapper'>
                        {(item && item.contact_name && item.contact_name.email_address && (
                          <ButtonBase
                            className={ActionsEnum.emailSolid.buttonClasses}
                            onClick={onActionClicked(ActionsEnum.emailSolid.key, item)}
                          >
                            <span className={ActionsEnum.emailSolid.icon} />
                          </ButtonBase>
                        )) || ''}
                        {item && item.contact_name && item.contact_name.mobile && (
                          <ButtonBase
                            className={ActionsEnum.whatsappSolid.buttonClasses}
                            onClick={onActionClicked(ActionsEnum.whatsapp.key, item)}
                          >
                            <span className={ActionsEnum.whatsappSolid.icon} />
                          </ButtonBase>
                        )}
                        {item && item.contact_name && item.contact_name.mobile && (
                          <ButtonBase
                            className={ActionsEnum.smsSolid.buttonClasses}
                            onClick={onActionClicked(ActionsEnum.smsSolid.key, item)}
                          >
                            <span className={ActionsEnum.smsSolid.icon} />
                          </ButtonBase>
                        )}
                      </div>
                    </div>
                    <div className={`card-body-section${isExpanded ? ' is-expanded' : ''}`}>
                      <div className='body-title-wrapper'>
                        <span className='body-title'>{item && item.name}</span>
                        {item && item.isFavorite && (
                          <div
                            className={
                              folderIdValue
                                ? "is-Fav-heart lead"
                                : "is-Fav-heart lead"
                            }
                            onClick={() => handleRemoveFavorite(item)}
                          >
                            <span className="mdi mdi-heart"></span>
                          </div>
                        )}
                        <div className='lead-id'>
                          <span className='mb-1'>
                            {t(
                              `${translationPath}${item && item.leadClass && item.leadClass.toLowerCase()
                              }`
                            )}
                          </span>
                          <div className='id'>
                            <span className='details-text'>
                              {t(`${translationPath}ID`)}
                              :
                            </span>

                            <Tooltip title={t(`${translationPath}copy`)}>
                              <CopyToClipboardComponents
                                data={item && item.id}
                                childrenData={item && item.id}
                              />
                            </Tooltip>


                          </div>
                        </div>

                        <div className='details-item-wrapper'>
                          <span className='details-icon mdi mdi-calendar mdi-18px' />
                          <span>
                            <span className='details-text'>
                              {t(`${translationPath}created`)}
                              :
                            </span>
                            <span className='px-1'>
                              {(item.createdOn &&
                                moment(item.createdOn)
                                  .locale(i18next.language)
                                  .format('DD/MM/YYYY')) ||
                                'N/A'}
                            </span>
                          </span>
                        </div>
                        <div className='body-card-tooltip-wrapper'>
                          <span
                            className={`card-tooltip mx-0${(((item && item.status &&
                              item.status.lookupItemName &&
                              LeadsStatusEnum[item.status.lookupItemName] &&
                              LeadsStatusEnum[item.status.lookupItemName].value) ||
                              'N/A') === 'closed' &&
                              ' bg-danger c-white') ||
                              ''
                              }`}
                          >

                            <span>
                              {t(
                                `${translationPath}${(item.status && item.status.lookupItemName) || (item.status) ||
                                'N/A'
                                }`
                              )}
                            </span>
                          </span>
                          {(((item.status &&
                            item.status.lookupItemName === LeadsStatusEnum.Closed.key) && item && item.lead_type_id === LeadTypeIdEnum.Seeker.leadTypeId && (

                              <div className='Clone-bbt mx-3'>
                                <Tooltip title={t(`${translationPath}clone`)}>
                                  <Fab
                                    size='small'
                                    aria-label='clone' id='clone-id-ref'
                                    label='clone'
                                    onClick={() => { setIsOpen(true); setIsActiveIteam(item) }}
                                  >
                                    <span className='mdi mdi-animation-outline mdi-18px' />
                                  </Fab>
                                </Tooltip>
                              </div>
                            )) ||
                            '')}
                          {(item?.rating?.lookupItemName && (
                            <>
                              <span className={LeadsPriorityEnum[item.rating.lookupItemName] && `card-tooltip mx-2 ${(item.rating.lookupItemName ||
                                '')}`}>
                                <span
                                  className={LeadsPriorityEnum[item.rating.lookupItemName] && LeadsPriorityEnum[item.rating.lookupItemName].icon}
                                />
                                <span className='px-1'>
                                  {t(
                                    `${translationPath}${item.rating.lookupItemName}`
                                  )}
                                </span>
                              </span>
                            </>
                          )) || <span />}
                          {(item?.qualification?.lookupItemName && (
                            <>
                              <span className={`${LeadsQualificationEnum[item.qualification.lookupItemName]?.classes|| ''} mx-1` || ''}>
                                {t(
                                  `${translationPath}${LeadsQualificationEnum[item.qualification.lookupItemName]?.name|| ''}`
                                )}

                              </span>
                            </>
                          )) || <span />}
                        </div>
                        <span className='details-text'>
                          {/* <span className='details-icon mdi mdi-minus mdi-18px' /> */}
                          {t(`${translationPath}referred-to`)}
                          :
                          {` ${item.referredto ? item.referredto.name : 'N/A'} `}
                        </span>
                        <Tooltip title={`${t(`${translationPath}property_name`)} : ${item.property_name && item.property_name.name ?
                          item.property_name.name :
                          'N/A'
                          }`}
                        >
                          <span className='details-text property_name'>
                            {/* <span className='details-icon mdi mdi-minus mdi-18px' /> */}
                            {t(`${translationPath}property_name`)}
                            :
                            {` ${item.property_name && item.property_name.name ?
                              item.property_name.name :
                              'N/A'
                              }`}
                          </span>
                        </Tooltip>
                        {item && item.unit_ref_number ? item && item.unit_ref_number && item.unit_ref_number.name && (
                          <span className='details-text mt-1 hide-textarea '>
                            {t(`${translationPath}ref-no`)}
                            :
                            {` ${item && item.unit_ref_number ? item && item.unit_ref_number && item.unit_ref_number.name : 'N/A'} `}




                            <Tooltip title={t(`${translationPath}copy`)}>
                              <CopyToClipboardComponents
                                data={item && item.unit_ref_number && item.unit_ref_number.name}
                                childrenData={item && item.unit_ref_number && item.unit_ref_number.name}
                              />
                            </Tooltip>

                          </span>
                        ) : ''}
                        <span className='details-text mt-1'>
                          {t(`${translationPath}unit-type`)}
                          :
                          {` ${item && item.unitType || 'N/A'} `}
                        </span>
                      </div>
                      {item.flatContent && item.flatContent.length > 0 && (
                        <div className='flat-contents-wrapper'>
                          {item.flatContent
                            .filter(
                              (filterItem, filterIndex) =>
                                (!isExpanded && filterIndex < notExpandedMax) || isExpanded
                            )
                            .map((subItem, subIndex) => (
                              <div
                                key={`flatContentsItemRef${subIndex + 1}`}
                                className='flat-content-item'
                              >
                                <span className={`flat-content-icon ${subItem.iconClasses} mdi-18px`} />
                                <span className='px-1'>{subItem.value}</span>
                                <span>
                                  {subItem.title && (
                                    <span className='flat-content-text'>
                                      {t(`${translationPath}${subItem.title}`)}
                                    </span>
                                  )}
                                </span>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='cards-progress-wrapper'>
                    <ProgressComponet
                      value={item.progress}
                      progressText={`${item.progress}%`}
                      themeClasses='theme-gradient'
                    />
                  </div>
                  <div className='cards-footer-wrapper'>
                    <ButtonBase
                      className={`btns theme-transparent mx-0 ${buttonWidth}`}
                      onClick={onFooterActionsClicked(ActionsEnum.folder.key, item, index)} id='ActionsClickedfolder'>

                      <span className='mdi mdi-folder-open-outline' />
                      <span className='px-1'>{t('open-file')}</span>
                    </ButtonBase>
                  </div>

                </a>
              </div>
            )}
          </>
        ))}
              {isOpen && <CloneLeadView
        relodedata={() => relodedata()}
        isOpen={isOpen}
        ActiveIteam={isActiveIteam}
        onClose={() => setIsOpen(false)}
      />}
    </div>
  );
};

LeadsCardsComponent.propTypes = {
  data: PropTypes.shape({ result: PropTypes.instanceOf(Array), totalCount: PropTypes.number })
    .isRequired,
  activeCard: PropTypes.instanceOf(Object),
  selectedDetailsLeadItem: PropTypes.instanceOf(Object),
  isExpanded: PropTypes.bool.isRequired,
  withCheckbox: PropTypes.bool.isRequired,
  onCardClicked: PropTypes.func.isRequired,
  relodedata: PropTypes.func.isRequired,
  onFooterActionsClicked: PropTypes.func.isRequired,
  onActionClicked: PropTypes.func.isRequired,
  onCardCheckboxClick: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  notExpandedMax: PropTypes.number,
  checkedDetailedCards: PropTypes.arrayOf(PropTypes.number).isRequired,
  isCheckBoxDisabled: PropTypes.bool,
  selectedCards: PropTypes.instanceOf(Array),
  handleRemoveFavorite: () => {},
  folderIdValue: undefined,
};
LeadsCardsComponent.defaultProps = {
  notExpandedMax: 5,
  selectedDetailsLeadItem: undefined,
  activeCard: undefined,
  isCheckBoxDisabled: false,
  selectedCards: undefined,
  folderIdValue: PropTypes.number,
  handleRemoveFavorite: PropTypes.func,
};
