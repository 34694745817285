import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Divider } from "@material-ui/core";
import { InfoSection, ContactComment } from "..";
import { CustomButton, ContactCommentSkeleton } from "../../..";
import { useVerticalNav } from "../../../../../Contexts/VerticalNavContext";
import { CreateMainEntitiesComment } from "../../../../../Services";
import { useTranslate } from "../../../../../Hooks";

// styles
import useStyles from "./styles";

function ContactProfileComments({
  isCommentsLoading,
  comments,
  setComments,
  activeItem,
}) {
  const styles = useStyles();
  const { setAlertBoxContent } = useVerticalNav();
  const { loginResponse } = useSelector((state) => state.login);
  const { translate } = useTranslate("NewContactsView");

  const handleSubmitComment = async () => {
    const body = {
      dfmType: 1,
      entityId: activeItem.id,
      comment,
    };
    try {
      if (!comment?.trim()) {
        setComment("");
        return;
      }

      const res = await CreateMainEntitiesComment(body);

      setComments([
        {
          createdOn: new Date().toLocaleDateString(),
          creatorFullName: loginResponse.fullName,
          createdBy: loginResponse.userId,
          creatorProfileImg: loginResponse.profileImg ?? null,
          comment: res?.comment,
          mainEntitiesCommentId: res?.mainEntitiesCommentId,
        },
        ...comments,
      ]);

      setAlertBoxContent({
        display: true,
        variant: "success",
        title: "Comment added successfully!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    } catch (error) {
      console.error(error);
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "Something went wrong!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    } finally {
      setComment("");
    }
  };

  const [comment, setComment] = useState("");

  // Sort comments by createdOn date in descending order
  const sortedComments = comments
    .slice()
    .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));

  return (
    <Box>
      <InfoSection
        title={translate("comments")}
        subTitle={translate("join_conversation")}
        fullWidthDivider
      >
        <textarea
          className={styles.textArea}
          placeholder={translate("comments_placeholder")}
          onChange={(event) => setComment(event.target.value)}
          value={comment}
        />

        <Box className={styles.actionsWrapper}>
          <CustomButton
            boxShadow="xs"
            size="lg"
            variant="contained"
            color="primary"
            disabled={!comment}
            onClick={handleSubmitComment}
          >
            {translate("post_comment")}
          </CustomButton>
        </Box>

        {(!!comments.length || isCommentsLoading) && (
          <Divider className={styles.divider} light />
        )}

        {isCommentsLoading &&
          Array.from({ length: 3 }, (_, index) => (
            <ContactCommentSkeleton key={index} />
          ))}

        {sortedComments.map((comment, idx) => {
          return (
            <ContactComment
              key={idx}
              comment={comment}
              setComments={setComments}
              contactId={activeItem.id}
            />
          );
        })}
      </InfoSection>
    </Box>
  );
}

export default ContactProfileComments;
