import React, {useEffect, useState, useCallback} from 'react';
import {BulkAssignActions, ActivitiesTypesFieldsSection , NewValuesSection , BulkSelectedActivitiesTypesFieldsSection } from './Sections';
import { GlobalHistory } from '../../../../Helper';
import { useLocalStorage } from '../../../../Hooks';
import { Spinner } from '../../../../Components';
import { ActivitesTypesFieldsData}  from './ActivitesTypesFieldsData' ; 

const parentTranslationPath = 'BulkAssign';
const translationPath = '';

export const ActivitiesTypesBulkUpdateView = () => {
  
  const [bulkedActivitiesTypes] = useLocalStorage('bulk-assign-ids');
  const [isLoading, setIsLoading] = useState(false);
  const [formFieldIds, setFormFieldIds] = useState([]);
  const [activitiesTypesStatus, setActivitiesTypesStatus] = useState({
    failure: [],
    success: [],
  });
  const [activitiesTypesCards, setActivitiesTypesCards] = useState(bulkedActivitiesTypes);
  const [activitiesTypesDetails, setActivitiesTypesDetails] = useState({});
  const [errors, setErrors] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [state, setState] = useState({
    activityTypeIds : [] , 
    keyValueList : []
  });

  const onActivitiesTypesChange = (value) => {
    setActivitiesTypesCards(value);
  };

  useEffect(() => {
    if (bulkedActivitiesTypes && bulkedActivitiesTypes.length < 1)
      GlobalHistory.goBack();
  }, [bulkedActivitiesTypes]);


  useEffect(() => {
     const itemList = formFieldIds.map((r) => ({
      [r.fieldKey]: r.fieldValues &&  (Array.isArray(r.fieldValues)) ?  (r.fieldValues.map((e) => e.id).toString()) : ( (r &&  r.fieldValues &&   r.fieldValues.id &&  r.fieldValues.id !== false && r.fieldValues.id.toString() ) || (r &&  r.fieldValues &&   r.fieldValues.id === false  && 'false') ) || (r.fieldValues  && r.fieldValues.toString()) || (null )
    })) ;

    let  obj = null;
    itemList.forEach((element) => {
      if(obj !== null)
      obj = { ...obj , ...element  }
    else 
    obj = { ...element  }
      
    });
    setState((item)=> ({...item , activityTypeIds :activitiesTypesCards && activitiesTypesCards.map((e) => e.activityTypeId) , keyValueList : [
      {...obj}
    ] }) ); 

  }, [activitiesTypesCards , activitiesTypesDetails , formFieldIds]);

  return (
    <>
      <div className='px-5 units-bulk-assign-view-wrapper'>
        <Spinner isActive={isLoading} />
        <div className='bulk-section-wrapper'>
          <BulkSelectedActivitiesTypesFieldsSection
            activitiesTypesCards={activitiesTypesCards}
            activitiesTypesStatus={activitiesTypesStatus}
            bulkedActivitiesTypes={bulkedActivitiesTypes}
            translationPath={translationPath}
            onActivitiesTypesChange={onActivitiesTypesChange}
            parentTranslationPath={parentTranslationPath}
          />
        </div>
        <span className='section-seperator mdi mdi-arrow-collapse-right c-gray' />
        <div className='bulk-section-wrapper'>
          <ActivitiesTypesFieldsSection
            formFields={ActivitesTypesFieldsData}
            activitiesTypesDetails={activitiesTypesDetails}
            formFieldIds={formFieldIds}
            setFormFieldIds={setFormFieldIds}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        </div>
        <span className='section-seperator mdi mdi-arrow-collapse-right c-gray' />
        <div className='bulk-section-wrapper'>
          <NewValuesSection
            errors={errors}
            setErrors={setErrors}
            isSubmitted={isSubmitted}
            activitiesTypesDetails={activitiesTypesDetails}
            formFieldIds={formFieldIds}
            setFormFieldIds={setFormFieldIds}
            setActivitiesTypesDetails={setActivitiesTypesDetails}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        </div>
      </div>
      <BulkAssignActions
        state={state}
        formFieldIds={formFieldIds}
        setIsLoading={setIsLoading}
        setIsSubmitted={setIsSubmitted}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
      />
    </>
  );
};
