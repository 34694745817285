export const PropertyRatingScoresFilter = {

  Platinum : {
    key: 'Platinum',
    from : 90 ,
    to : 100
  },
  Gold: {
    key: 'Gold',
    from : 80 ,
    to : 90
  },
  Silver : {
    key: 'Silver',
    from : 70 ,
    to : 80
  },
  Bronze: {
    key: 'Bronze',
    from : 0 ,
    to : 70
  },
 
 
};
