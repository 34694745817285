import { config } from '../config/config';
import { GlobalTranslate, showError, HttpServices } from '../Helper';


const CreateUserSearchCriteria = async (body) => {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/Search/CreateUserSearchCriteria`,
      body
    )
      .then((data) => data)
      .catch((error) => undefined);
    return result;
  };

  const GetAllUserSearchCriteria = async (typeId) => {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/Search/GetAllUserSearchCriteria?typeId=${typeId}`
    )
      .then((data) => data)
      .catch((error) => undefined);
    return result;
  };
  const DeleteListOfSearchCriteria = async (body) => {
    const result = await HttpServices.put(
      `${config.server_address}/CrmDfm/Search/DeleteUserSearchCriteria`,body)
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };

  const UpdateUserSearchCriteria = async (payload) => {
    const result = await HttpServices.put(
      `${config.server_address}/CrmDfm/Search/UpdateUserSearchCriteria`,
      payload
    )
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };

export {
    CreateUserSearchCriteria,
    GetAllUserSearchCriteria,
    DeleteListOfSearchCriteria,
    UpdateUserSearchCriteria
}