import React from "react";
import { Box } from "@material-ui/core";

import { useSelectedTheme } from "../../../Hooks";
import { CustomBadge } from "../CustomBadge";

// Styles
import useStyles from "./styles";

const LeadsPriorityBadges = ({ leadTypeKey }) => {
  const {
    theme: { palette },
  } = useSelectedTheme();

  const styles = useStyles();

  const LeadsPriorityEnum = {
    Cold: {
      key: "Cold",
      value: "low",
      backgroundColor: palette.utility.blue_50,
      borderColor: palette.utility.blue_200,
      color: palette.utility.blue_700,
    },
    Warm: {
      key: "Warm",
      value: "medium",
      backgroundColor: palette.utility.orange_50,
      borderColor: palette.utility.orange_200,
      color: palette.utility.orange_700,
    },
    Hot: {
      key: "Hot",
      value: "high",
      backgroundColor: palette.utility.warning_50,
      borderColor: palette.utility.warning_200,
      color: palette.utility.warning_700,
    },
  };

  const leadType = LeadsPriorityEnum[leadTypeKey];

  return (
    <Box className={styles.badgeSection}>
      <CustomBadge
        key={leadType.key}
        Style={{
          lineHeight: "24px",
          fontSize: "16px",
        }}
        SizeVariant="large"
        label={leadType.key}
        BackgroundColor={leadType.backgroundColor}
        BorderColor={leadType.borderColor}
        Color={leadType.color}
      />
    </Box>
  );
};

export default LeadsPriorityBadges;
