import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useIsAr } from "../../../Hooks";
import {
  Menu,
  Box,
  Typography,
  MenuItem,
} from "@material-ui/core";

// Styles
import useStyles from "./styles";

function CustomMenu({
  menuItems,
  children,
  activeIconClass,
  defaultIconClass,
  customAnchorOrigin,
  customTransformOrigin,
}) {
  const theme = useSelector((store) => store.theme);

  const [anchorEl, setAnchorEl] = useState(null);

  const styles = useStyles({ isMenuExpanded: !!anchorEl });

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { isAr } = useIsAr(theme.themeDirection);

  const anchorOrigin = isAr
    ? { vertical: "bottom", horizontal: "right" }
    : { vertical: "bottom", horizontal: "right" };

  const transformOrigin = isAr
    ? { vertical: "top", horizontal: "right" }
    : { vertical: "top", horizontal: "right" };

  return (
    <>
      {children &&
        React.cloneElement(children, {
          // "aria-controls": "customId",
          "aria-haspopup": "true",
          className: !!anchorEl ? activeIconClass : defaultIconClass,
          onClick: handleMenuOpen,
        })}
      <Menu
        // id={"customId"}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={customAnchorOrigin ?? anchorOrigin}
        transformOrigin={customTransformOrigin ?? transformOrigin}
        getContentAnchorEl={null}
        PaperProps={{
          className: styles.customMenu,
        }}
        MenuListProps={{
          className: styles.customMenuList,
        }}
      >
        <Box>
          {menuItems.map((item, index) => {
            const MenuIcon = item?.icon ?? null;
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  item.handler();
                  handleMenuClose();
                }}
                className={styles.menuItem}
                disableRipple
              >
                {!!MenuIcon && MenuIcon}
                <Typography variant="inherit" className={styles.menuItemText}>
                  {item.text}
                </Typography>
              </MenuItem>
            );
          })}
        </Box>
      </Menu>
    </>
  );
}

export default CustomMenu;
