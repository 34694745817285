import { UsersManagement } from '../UserManagement/UsersManagement';
import { Billing } from './Billing';

export const BillingRoutes = [
  {
    id: 304,
    path: '/billing',
    name: 'billing',
    component: Billing,
    layout: '/home',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'Billing',
        isDisabled: true,
        route: '/home/billing',
      },
    ],
  },

];
