export const ConfigurationHeaderData = [
  {
    id: 1,
    label: 'department',
    input: 'departmentName',
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 2,
    label: 'form',
    input: 'campaignFormName',
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 3,
    label: 'updated-by',
    input: 'createdByName',
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 4,
    label: 'last-update-date',
    input: 'createdOn',
    isHiddenFilter: true,
    isSortable: false,
    isDate: true,
    dateFormat: 'DD/MM/YYYY',
  },
  {
    id: 5,
    label: 'department-approved-by',
    input: 'departmentApprovalUser',
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 6,
    label: 'marketing-approved-by',
    input: 'marketingApprovalUser',
    isHiddenFilter: true,
    isSortable: false,
  },

];
