export const NotificationByEnum = {
  1: {
    key: 1,
    value: 'SuperAdmin',
  },
  7: {
    key: 7,
    value: 'AssignTo',
  },
  8: {
    key: 8,
    value: 'LineManager',
  },
  9: {
    key: 9,
    value: 'CreatedFrom',
  },
  10: {
    key: 10,
    value: 'Client',
  },
  11: {
    key: 11,
    value: 'TeamLead',
  },
  12: {
    key: 12,
    value: 'LeadAuditor',
  },
};
