import { GlobalAdvanceSearchStatus } from './GlobalAdvanceSearchStatus';

const globalAdvanceSearchRequest = (payload) => ({ type: GlobalAdvanceSearchStatus.REQUEST, payload });
const globalAdvanceSearchSuccess = (payload) => ({ type: GlobalAdvanceSearchStatus.SUCCESS, payload });
const globalAdvanceSearchError = (payload) => ({ type: GlobalAdvanceSearchStatus.ERROR, payload });
const globalAdvanceSearchReset = (payload) => ({ type: GlobalAdvanceSearchStatus.RESET, payload });

export const GlobalAdvanceSearchActions = {
  globalAdvanceSearchRequest,
  globalAdvanceSearchSuccess,
  globalAdvanceSearchError,
  globalAdvanceSearchReset,
};
