import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SearchFieldComponent } from './SearchFieldComponent';
import { TextFieldComponent } from './TextFieldComponent';
import { DisplayPerType } from '../../ActivitiesTypeManagementView/DialogManagementViewComponent/DialogManagementConrtolComponent/DisplayPerType';
import { ActivitiesRelatedToActivitiesTypeEnum } from '../../../../../Enums';
import { LookupSelect } from '../../ActivitiesTypeManagementView/DialogManagementViewComponent/DialogManagementConrtolComponent/LookupSelect';
import { StaticLookupsIds } from '../../../../../assets/json/StaticLookupsIds';
import { SelectComponet } from '../../../../../Components';

const LeadCategory = [
  { title: 'Seller', value: 1 },
  { title: 'Landlord', value: 2 },
  { title: 'Buyer', value: 3 },
  { title: 'Tenant', value: 4 },
];
const RelateType = {
  UnitType: 1,
  UnitOperation: 2,
  UnitSaleType: 3,
  Contact: 4,
  Lead: 5,
};

const parse = (item) => JSON.parse(item);
const stringify = (item) => JSON.stringify(item);

export const NewValuesSection = ({
  errors,
  formData,
  setErrors,
  isSubmitted,
  activitiesTypesDetails,
  setFormData,
  formFieldIds,
  setActivitiesTypesDetails,
  translationPath,
  setIntActivitiesTypesDetails,
  parentTranslationPath,
  setFormFieldIds,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [loadings, setLoadings] = useState([]);

  const updateItemValue = (item, newValue) => {
    setActivitiesTypesDetails((items) => {
      items[item.fieldKey] = newValue;
      return { ...items };
    });

    setFormFieldIds((items) => {
      const fieldIndex = items.indexOf(
        items.find((f) => f.fieldKey === item.fieldKey)
      );
      if (fieldIndex !== -1)
        items[fieldIndex] = { ...items[fieldIndex], fieldValues: newValue };
      return [...items];
    });
  };
  const activityTypeRelatesItem = useMemo(
    () =>
      formFieldIds.find((item) => item.fieldTitle === 'activityTypeRelates'),
    [formFieldIds]
  );

  const updateActivityTypeRelatesItem = (newValue, relateType) => {
    let prevValue = [];
    if (activityTypeRelatesItem?.fieldValues) {
      prevValue = parse(activityTypeRelatesItem.fieldValues).filter(
        (item) => item.relateType !== relateType
      );
    }
    updateItemValue(
      activityTypeRelatesItem,
      stringify([...newValue, ...prevValue])
    );
  };

  const getValues = (relateType) => {
    if (activityTypeRelatesItem?.fieldValues) {
      return parse(activityTypeRelatesItem?.fieldValues).filter(
        (item) => item.relateType === relateType
      );
    } else {
      return [];
    }
  };
  return (
    <div>
      <div className='bulk-header-section'>
        {t(`${translationPath}new-values`)}
      </div>
      <div className='bulk-sub-header-section'>
        {t(`${translationPath}add-new-value-to-the-following-fields`)}
      </div>
      <div className='bulked-units-section new-value-section mt-3'>
        {formFieldIds
          ?.flat()
          ?.filter((item) => item && !item.hidden)
          ?.map((item, index) => (
            <div className='mb-3' key={`unitNewValueItemRef${index + 1}`}>
              {((item && item.fieldType === 'search') ||
                (item && item.fieldType === 'select')) && (
                <SearchFieldComponent
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  formFieldIds={formFieldIds}
                  item={item}
                  activitiesTypesDetails={activitiesTypesDetails}
                  onValueChanged={(value) => {
                    updateItemValue(item, value);
                  }}
                />
              )}
              {((item && item.fieldType === 'text') ||
                (item && item.fieldType === 'numbers')) && (
                <TextFieldComponent
                  item={item}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  activitiesTypesDetails={activitiesTypesDetails}
                  onValueChanged={(value) => {
                    updateItemValue(item, value);
                  }}
                  setActivitiesTypesDetails={setActivitiesTypesDetails}
                />
              )}
              {item?.fieldTitle === 'relatedTo' && (
                <div className='px-2'>
                  <DisplayPerType
                    type={ActivitiesRelatedToActivitiesTypeEnum.unit.key}
                    typeList={
                      item.fieldValues?.map((value) => ({
                        relatedToId: value.id,
                      })) ?? []
                    }
                  >
                    <LookupSelect
                      lookupTypeId={StaticLookupsIds.UnitSaleType}
                      id='unitSaleTypeRef'
                      multiple
                      labelValue={'unit-sale-type'}
                      placeholder={'Select-multiple'}
                      parentTranslationPath={'ActivitiesType'}
                      translationPath={translationPath}
                      value={
                        getValues(RelateType.UnitSaleType)?.map(
                          (item) => item.lookupsId
                        ) ?? []
                      }
                      onSelectChanged={(ids) => {
                        updateActivityTypeRelatesItem(
                          ids.map((item) => ({
                            lookupsId: item,
                            relateType: RelateType.UnitSaleType,
                          })),
                          RelateType.UnitSaleType
                        );
                      }}
                    />

                    <LookupSelect
                      lookupTypeId={StaticLookupsIds.UintType}
                      id='unitTypeRef'
                      multiple
                      labelValue={'unit-type'}
                      placeholder={'Select-multiple'}
                      parentTranslationPath={'ActivitiesType'}
                      translationPath={translationPath}
                      value={
                        getValues(RelateType.UnitType)?.map(
                          (item) => item.lookupsId
                        ) ?? []
                      }
                      onSelectChanged={(ids) => {
                        updateActivityTypeRelatesItem(
                          ids.map((item) => ({
                            lookupsId: item,
                            relateType: RelateType.UnitType,
                          })),
                          RelateType.UnitType
                        );
                      }}
                    />

                    <LookupSelect
                      lookupTypeId={StaticLookupsIds.UintOperationType}
                      id='unitOperationTypeRef'
                      multiple
                      labelValue={'unit-operation-type'}
                      placeholder={'Select-multiple'}
                      parentTranslationPath={'ActivitiesType'}
                      translationPath={translationPath}
                      value={
                        getValues(RelateType.UnitOperation)?.map(
                          (item) => item.lookupsId
                        ) ?? []
                      }
                      onSelectChanged={(ids) => {
                        updateActivityTypeRelatesItem(
                          ids.map((item) => ({
                            lookupsId: item,
                            relateType: RelateType.UnitOperation,
                          })),
                          RelateType.UnitOperation
                        );
                      }}
                      excludeLookupByName={'SaleAndRent'}
                    />
                  </DisplayPerType>
                  <DisplayPerType
                    type={ActivitiesRelatedToActivitiesTypeEnum.contact.key}
                    typeList={
                      item.fieldValues?.map((value) => ({
                        relatedToId: value.id,
                      })) ?? []
                    }
                  >
                    <LookupSelect
                      lookupTypeId={StaticLookupsIds.ContactClass}
                      id='contactClassRef'
                      multiple
                      labelValue={'contact-class'}
                      placeholder={'Select-multiple'}
                      parentTranslationPath={'ActivitiesType'}
                      translationPath={translationPath}
                      value={
                        getValues(RelateType.Contact)?.map(
                          (item) => item.lookupsId
                        ) ?? []
                      }
                      onSelectChanged={(ids) => {
                        updateActivityTypeRelatesItem(
                          ids.map((item) => ({
                            lookupsId: item,
                            relateType: RelateType.Contact,
                          })),
                          RelateType.Contact
                        );
                      }}
                    />
                  </DisplayPerType>
                  <DisplayPerType
                    type={ActivitiesRelatedToActivitiesTypeEnum.lead.key}
                    typeList={
                      item.fieldValues?.map((value) => ({
                        relatedToId: value.id,
                      })) ?? []
                    }
                  >
                    <SelectComponet
                      data={LeadCategory}
                      textInput={'title'}
                      valueInput={'value'}
                      idRef='leadTypeRef'
                      multiple
                      labelValue={'lead-type'}
                      placeholder={'Select-multiple'}
                      parentTranslationPath={'ActivitiesType'}
                      translationPath={translationPath}
                      value={
                        getValues(RelateType.Lead)?.map(
                          (item) => item.enumValue
                        ) ?? []
                      }
                      onSelectChanged={(ids) => {
                        updateActivityTypeRelatesItem(
                          ids.map((item) => ({
                            enumValue: item,
                            relateType: RelateType.Lead,
                          })),
                          RelateType.Lead
                        );
                      }}
                    />
                  </DisplayPerType>
                </div>
              )}
            </div>
          ))}
        <div></div>
      </div>
    </div>
  );
};
NewValuesSection.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  setFormData: PropTypes.func.isRequired,
  setUnitDetails: PropTypes.func.isRequired,
  translationPath: PropTypes.string.isRequired,
  errors: PropTypes.instanceOf(Array).isRequired,
  formData: PropTypes.instanceOf(Array).isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  unitDetails: PropTypes.instanceOf(Object).isRequired,
};
