
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ButtonBase } from '@material-ui/core';
import {
  TabsComponent,
  RadiosGroupComponent,
  Spinner
} from '../../../../../Components';
import { GetParams   } from '../../../../../Helper';
import { GetInquiryDetailsByIdService  } from '../../../../../Services';
import { ZeroMatchingtVerticalTabsData } from './Sections' ; 

const parentTranslationPath = 'Inquires';
const translationPath = '';

export const ZeroMatchingProfileManagementView = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [activeInquiryData, setActiveInquiryData] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [viewType, setViewType] = useState(1);
  const activeItem = useSelector((state) => state.ActiveItemReducer);
  const [isCompletInquiry , setCompletInquiry] = useState(false)  ; 
  const [reloadInquiryData , setReloadInquiryData] = useState(null) ;
  const [inquiryEmptyFields , setInquiryEmptyFields]= useState(null) ;



  const [filterBy, setFilterBy] = useState({
    id: null,
    formType: null,
  });

  const getInquiryDetails = useCallback(async (id) => {
    setIsLoading(true);
    const InquiryDetailsRes = await GetInquiryDetailsByIdService(id);
    if (!(InquiryDetailsRes && InquiryDetailsRes.status && InquiryDetailsRes.status !== 200)) {
        setInquiryEmptyFields(InquiryDetailsRes && InquiryDetailsRes.inquiryJson && JSON.parse(InquiryDetailsRes.inquiryJson)); 
        setActiveInquiryData(InquiryDetailsRes);
    } else {
        setActiveInquiryData(null);
        setInquiryEmptyFields(null); 
    }
    setIsLoading(false);
  }, [isCompletInquiry , reloadInquiryData ]);


  useEffect(() => {
    const inquiryId = +GetParams('id');
    if (inquiryId) 
    getInquiryDetails(inquiryId);
  }, [getInquiryDetails]);


  const [formType, setformType] = useState(+GetParams('formType'));

  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };
  const onViewTypeChangedHandler = (event, newValue) => {
    setViewType(+newValue);
  };

  useEffect(() => {
    setFilterBy({
      formType: (GetParams('formType') && +GetParams('formType')) || null,
      id: (GetParams('id') && +GetParams('id')) || null,
    });
  }, []);

  return (
    <div className='contact-profile-wrapper view-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='d-flex-v-center'>

          <ButtonBase
              onClick={() => {} }
              disabled={activeInquiryData && activeInquiryData.inquiryStatus && activeInquiryData.inquiryStatus ===  'Completed'}
              className={'btns theme-solid mb-2 mx-2  px-4'}

            >
              <span className='px-2'>
              { activeInquiryData && activeInquiryData.inquiryStatus}
            </span>
         </ButtonBase>
        <RadiosGroupComponent
          idRef='viewDataRef'
          data={[
            {
              key: 1,
              value: 'all-data',
            },
            {
              key: 2,
              value: 'missing-data',
            },
          ]}
          value={viewType}
          labelValue='view'
          labelInput='value'
          valueInput='key'
          themeClass='theme-line'
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          translationPath={translationPath}
          onSelectedRadioChanged={onViewTypeChangedHandler}
        />
      </div>
      <TabsComponent
        data = {ZeroMatchingtVerticalTabsData}
        labelInput='label'
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        themeClasses='theme-solid'
        currentTab={activeTab}
        onTabChanged={onTabChanged}
        dynamicComponentProps={{
          id: filterBy.id,
          viewType,
          parentTranslationPath,
          translationPath,
          setActiveTab, 
          activeTab,
          setCompletInquiry ,
          activeInquiryData , 
          typeOf:'1' , 
          setReloadInquiryData ,
          inquiryEmptyFields , 
        }}
      />
    </div>
  );
};