import React, { useState, useEffect, useCallback } from "react";
import { Tables } from "../../../../../../Components";
import { SchoolHeaderData } from "./SchoolHeaderData";

export function SchoolDetails({ schoolDetailsTableData }) {

  return (
    <div>
      <Tables
      
        data={schoolDetailsTableData || []}
        headerData={SchoolHeaderData || []}
      />
    </div>
  );
}
