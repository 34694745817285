export const ZeroMatchingLeasePermissions = {
  AddNewLeaseInquiry: {
    permissionsId: "9edfccbc-e8d5-4c66-a5d6-08db04f6cbfc",
    permissionsName: "Add New Lease Inquiry",
    componentsId: null,
    components: null,
  },
  EditInquiry: {
    permissionsId: "e2c471d5-2eba-4a9c-a5d7-08db04f6cbfc",
    permissionsName: "Edit Inquiry",
    componentsId: null,
    components: null,
  },
  DeleteInquiry: {
    permissionsId: "c6aa0b1d-473f-4171-a5d8-08db04f6cbfc",
    permissionsName: "Delete Inquiry",
    componentsId: null,
    components: null,
  },
  ViewInquiry: {
    permissionsId: "d49f2910-22b4-4976-a5d9-08db04f6cbfc",
    permissionsName: "View Inquiry",
    componentsId: null,
    components: null,
  },
  CancelInquiry: {
    permissionsId: "704a5815-6d8a-4e0e-a5e0-08db04f6cbfc",
    permissionsName: "Cancel Inquiry",
    componentsId: null,
    components: null,
  },
  AssignInquiry: {
    permissionsId: "0887a828-6c0c-419f-cb6f-08db7d42a751",
    permissionsName: "Assign Inquiry",
    componentsId: null,
    components: null,
  },
  ViewInquiryAssignmentTracker: {
    permissionsId: "b1617646-6ad1-448f-794c-08dcaf947ea8",
    permissionsName: "View Inquiry Assignment Tracker",
    description: null,
    componentsId: null,
    components: null,
  },
};
