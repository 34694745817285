import {
  Avatar,
  ButtonBase,
  Chip,
  makeStyles,
  Step,
  StepConnector,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { Children, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogComponent } from '../../../../../../Components';
import { GetUserPendingActivationHistory } from '../../../../../../Services';
import { getDownloadableLink, showError } from '../../../../../../Helper';
import clsx from 'clsx';
import { DefaultImagesEnum } from '../../../../../../Enums';
import moment from 'moment';

const StyledStepper = withStyles({
  root: {
    padding: 0,
  },
})(Stepper);
const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    paddingLeft: 6,
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: 'green',
  },
});
const chipStatusColor = makeStyles({
  pending: {
    backgroundColor: '#fff7e3',
    color: '#cf8658',
    fontWeight: 'bold',
  },
  approved: {
    backgroundColor: '#ecfdf3',
    color: '#409872',
    fontWeight: 'bold',
  },
  rejected: {
    backgroundColor: 'red',
    color: '#fff',
    fontWeight: 'bold',
  },
});

function QontoStepIcon({ active }) {
  const classes = useQontoStepIconStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {<div className={classes.circle} />}
    </div>
  );
}
function HistoryDialog({
  translationPath,
  parentTranslationPath,
  userStatus,
  id,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const [isOpen, setIsOpen] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const classes = chipStatusColor();
  const getUserPendingActivationHistory = async () => {
    try {
      const data = await GetUserPendingActivationHistory(id);
      setHistoryData(data);
    } catch (error) {
      showError('something-went-wrong');
    }
  };
  //   DefaultImagesEnum
  return (
    <div>
      <ButtonBase
        className='btns'
        onClick={() => {
          getUserPendingActivationHistory();
          setIsOpen(true);
        }}
      >
        {t(`${translationPath}${userStatus}`)}
      </ButtonBase>
      {isOpen && (
        <DialogComponent
          isOpen
          titleText={'history'}
          onCloseClicked={() => setIsOpen(false)}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          maxWidth={'sm'}
          dialogContent={
            <>
              {Children.toArray(
                historyData.map((item) => (
                  <div className='flex'>
                    <div
                      className='flex flex-col fa-center  '
                      style={{ width: '6rem' }}
                    >
                      <Typography variant='body1' color='textSecondary'>
                        {moment(item.statusDate).format('MMM D')}
                      </Typography>
                      <Typography variant='h6' color='textSecondary'>
                        {moment(item.statusDate).format('YYYY')}
                      </Typography>
                      <Typography variant='body2' color='textSecondary'>
                        {moment(item.statusDate).format('h:mm A')}
                      </Typography>
                    </div>
                    <StyledStepper orientation='vertical' className='w-100'>
                      <Step>
                        <StepLabel StepIconComponent={QontoStepIcon}>
                          <Chip
                            className={classes[item.userStatus?.toLowerCase()]}
                            label={item.userStatus}
                          />
                        </StepLabel>
                        <StepContent>
                          <div className=' d-flex fa-center'>
                            <Avatar
                              src={
                                item.userProfileImage
                                  ? getDownloadableLink(item.userProfileImage)
                                  : DefaultImagesEnum.man.defaultImg
                              }
                              alt={item.userFullName}
                            />
                            <Typography component={'strong'} className=' px-1'>
                              {item.userFullName}
                            </Typography>
                          </div>
                          <br />
                          <Typography color='textPrimary'>
                            <Typography
                              component={'span'}
                              color='textSecondary'
                            >
                              {t(`${translationPath}role`)}{' '}
                            </Typography>
                            {item.role}
                          </Typography>
                        </StepContent>
                      </Step>
                      <StepConnector />
                    </StyledStepper>
                  </div>
                ))
              )}
            </>
          }
        />
      )}
    </div>
  );
}

export default HistoryDialog;
