import React, { useReducer, useEffect, useCallback, useState } from "react";
import { config } from '../../../../../config';
import { useTranslation } from "react-i18next";
import {
  FileInfoCard,
} from "../../../../../SharedComponents";
import { Inputs, AutocompleteComponent } from "../../../../../Components";
import {
  GetAllSystemConfigurationsStatus,
  GetUnitTransactionOfferLetters,
  GetUnitTransactionTenant,
  lookupItemsGetId,
  RunSystemTemplateForUnit,
} from "../../../../../Services";
import { Avatar } from "@material-ui/core";
import {
  GetParams,
  getDownloadableLink,
  getFirstLastNameLetters,
} from "../../../../../Helper";
import { DocListHolder } from "../../Utilities";
import { Tooltip } from "@material-ui/core";
import { ScopeDocumentEnum, UnitsStatusEnum } from "../../../../../Enums";
import { StaticLookupsIds } from "../../../../../assets/json/StaticLookupsIds";

export const OfferLetterSignOff = ({
  unitId,
  unitData,
  haveToResetInitDOCs,
  selected,
  onSelectedChanged,
  parentTranslationPath,
  changeActiveSubmitButton
}) => {
  const translationPath = "OfferLetterSignOff.";
  const { t } = useTranslation(parentTranslationPath);
  const URLPathStep = GetParams('step');

  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  };
  const [isTemplateLoading, setIsTemplateLoading] = useState(false);
  const [localSelected, setLocalSelected] = useReducer(reducer, {
    offerLetterType: null,
    offerTemplateId: null,
    underOfferDuration: null,
    offerLetterContent: null,
  });
  const [data, setData] = useReducer(reducer, {
    offerLetterTypes: [],
    documentCategories: [],
    offerLetterContent: null,
  });

  const generateTemplateName = () => `Leasing Letter Offer - ${localSelected.offerLetterType?.label || selected.offerLetterType?.label || ''} `;

  const getTenantName = () => selected.contacts?.length > 0 ? selected.contacts[0].contactName : '';

  const getTemplateAsHtml = async () => {
    setIsTemplateLoading(true);
    setData({ id: 'offerLetterContent', value: "" });
    const body = {
      templateId: localSelected.offerTemplateId,
      unitId,
    };
    const res = await RunSystemTemplateForUnit(body);
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'offerLetterContent', value: res?.templateContent || "" })
    }
    setIsTemplateLoading(false);
  };

  //todo:: move small helper func to a local helper file 

  const checkIsUnderOffer = () => {
    const isUnderOffer = unitData?.lease_status === UnitsStatusEnum.UnderOffer.name;
    return isUnderOffer;
  }

  const checkIsAvailable = () => {
    const isAvailableStatus = unitData?.lease_status === UnitsStatusEnum.Available.name;;
    return isAvailableStatus;
  }
  const submitButtonChangeHandler = () => {
    const isDocsExist = ((selected.offerLetterDocs?.tenantDocuments?.length > 0) || (selected.offerLetterDocs?.landlordDocuments?.length > 0));

    if (!(checkIsAvailable() || checkIsUnderOffer())) changeActiveSubmitButton("Next");
    if ((checkIsAvailable() || checkIsUnderOffer()) && isDocsExist) changeActiveSubmitButton("Submit");
    if ((checkIsAvailable() || checkIsUnderOffer()) && !isDocsExist) changeActiveSubmitButton("Send");

  }


  const mapTenantsData = (response) => {
    const mappedContact = {
      contactId: response.contactId,
      contactName: response.contactName,
      leadId: response.tenantId,
      email: response.email,
      phone: response.phone,
      ownershipPercentage: response.ownershipPercentage,
    }

    onSelectedChanged({
      id: "contacts",
      value: [mappedContact]
    });
  }

  const getUnitTransactionTenant = async () => {
    const res = await GetUnitTransactionTenant(unitId);
    if (!(res && res.status && res.status !== 200)) {
      if (res) mapTenantsData(res);
    }
  }


  const getDocumentCategories = async () => {
    const result = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.UnitsDocuments,
    });
    if (!(result && result.status && result.status !== 200)) {
      setData({ id: 'documentCategories', value: result })
    }
  };

  const offerTemplateIdHandler = useCallback(() => {

    const selectedOfferType = localSelected.offerLetterType || selected.offerLetterType;
    const isSentForTenantOnly = checkIsAvailable() &&
    selectedOfferType &&
      (selected.offerLetterDocs?.landlordDocuments?.length > 0 && selected.offerLetterDocs?.tenantDocuments?.length == 0) ? true : false;

    const offerType = selectedOfferType?.systemConfigurationName || '';
    const templateId = isSentForTenantOnly
      ? config.TenantOfferLetterTemplateId[offerType]
      : config.OfferLetterTemplateId[offerType];

    setLocalSelected({ id: 'offerTemplateId', value: templateId });
  }, [selected.offerLetterDocs,
  selected.offerLetterType,
  localSelected.offerLetterType,
  unitData,
  ])

  const getOfferLetterTypes = async () => {
    const result = await GetAllSystemConfigurationsStatus();
    if (!(result && result.status && result.status !== 200)) {
      const offerLetterTypes = result && result.filter(item => item.systemConfigurationName === 'ResidentialOfferLetter' ||
        item.systemConfigurationName === 'CommercialOfferLetter'
      ) || [];

      const mappedOfferLetterTypes = offerLetterTypes.map(item => ({
        ...item,
        label: item.systemConfigurationName.split('OfferLetter')[0],
      }))
      setData({ id: 'offerLetterTypes', value: mappedOfferLetterTypes });
    }
  }

  const mapSingleUploadedDoc = ({
    document,
    isTenant = false,
    isLandlord = false,
    }) => {
    const scopeId = unitId;
    const scopeTypeId = ScopeDocumentEnum.Unit.scopeTypeId;
    const categoryId = data.documentCategories.find(item => item.lookupItemName === "Offer Letter")?.lookupItemId;

    return ({
      fileName: document.fileName,
      fileId: document.uuid,
      uuid: document.uuid,
      scopeId,
      categoryId,
      scopeTypeId,
      isTenantOfferLatter: isTenant,
      isLandlordOfferLatter: isLandlord,
      isUploaded: true,
    })
  }

  // const mapSingleRetrievedDoc = (document) => {
  //   return ({
  //     fileName: document.fileName,
  //     uuid: document.fileId,
  //     isRetrieved: true,
  //   })
  // }
  //todo:: add schema

  const DTOChangeHandler = useCallback( async () => {

    const transactionDocuments = [
      ...(selected.contactDocuments || []),
      ...(selected.offerLetterDocs?.tenantDocuments || []),
      ...(selected.offerLetterDocs?.landlordDocuments || []),
    ]

    const offerLetterContent = localSelected.offerTemplateId? 
    (localSelected.offerLetterContent || data.offerLetterContent || "") : null;

    const OfferLetterSendBody = {
      unitId,
      systemTemplateId: localSelected.offerTemplateId || null,
      offerLetterTypeId: localSelected.offerLetterType? +localSelected.offerLetterType?.systemConfigurationId : null,
      offerLetterDuration: localSelected.underOfferDuration,
      unitTransactionContacts: selected.contacts,
      transactionDocuments,
      offerLetterContent,
    }

    const OfferLetterUploadBody = {
      Landlord: {
        unitId,
        documents: (selected.offerLetterDocs?.landlordDocuments || []).filter(item => item.isUploaded),
      },
      Tenant: {
        unitId,
        documents: (selected.offerLetterDocs?.tenantDocuments || []).filter(item => item.isUploaded),
      }

    }



    const offerLetterDTO = {
      ...(selected.transactionDTOs || {}),
      OfferLetterSend: OfferLetterSendBody,
      OfferLetterUpload: OfferLetterUploadBody,
    }

    onSelectedChanged({ id: 'transactionDTOs', value: offerLetterDTO });

  }, [unitId,
    localSelected,
    selected.contacts,
    localSelected.offerLetterContent,
    data.offerLetterContent,
    selected.offerLetterDocs,
    selected.contactDocuments,
  ])

  const checkIsBothDocsUploaded = () => {
    const isNewTenantDocsUploaded = [...(selected.offerLetterDocs?.tenantDocuments || [])].find(item => item.isUploaded);
    const isNewLandlordtDocsUploaded = [...(selected.offerLetterDocs?.landlordDocuments || [])].find(item => item.isUploaded);
    const bothDocsUploaded = isNewTenantDocsUploaded && isNewLandlordtDocsUploaded;

    return bothDocsUploaded;
  }

  const clearOfferTemplateId = () => {

    if (checkIsAvailable() && checkIsBothDocsUploaded()){
      setLocalSelected({ id: 'offerTemplateId', value: null });
    }
  }

  const getIsTemplateCardDisabled = () => {
    return (selected?.autoFilled?.offerLetterContent || !checkIsAvailable());
  }

  const getIsOfferLetterTypeDisabled = () => {
    return !checkIsAvailable() || selected.offerLetterType
  }

  useEffect(() => {
    if (unitData) {
       submitButtonChangeHandler();

      const isContactsRetrieved = selected.contacts?.length > 0;
      if (!checkIsAvailable() && !isContactsRetrieved) getUnitTransactionTenant();
    }
  }, [unitData,
    URLPathStep,
    selected.offerLetterDocs
  ]);


  useEffect(() => {
    DTOChangeHandler();
  }, [DTOChangeHandler])

  useEffect(() => {
    offerTemplateIdHandler();
  }, [offerTemplateIdHandler])


  useEffect(() => {
    getOfferLetterTypes();
    getDocumentCategories();
  }, []);

  useEffect(() => {
     clearOfferTemplateId();
  }, [selected.offerLetterDocs, localSelected.offerLetterType]);

  useEffect(() => {
    if(localSelected.offerTemplateId && checkIsAvailable()) getTemplateAsHtml();
  }, [localSelected.offerTemplateId]);

  return (
    <div className="Offer-Letter-SignOff py-3 my-1">
      <div className="d-flex fj-start mb-3">
        <div className="w-33 mt-1">
          <span className="fw-simi-bold">{t(`${translationPath}Sent-to`)}</span>
        </div>
        <div className="w-75">
          <div className="d-flex-default fas-center">
            <div className="d-flex-default fas-center mr-4">
              <span className="d-flex-default fas-center fw-simi-bold mr-3">
                {t(`Landlord`)}
              </span>
              <Tooltip title={unitData?.lease_lead_owner?.name} placement="bottom">
                <Avatar
                  classes={{ root: `fz-12 team-avatar xs-theme pointer` }}
                  src={''}
                >
                  {getFirstLastNameLetters(unitData?.lease_lead_owner?.name || "")}
                </Avatar>
              </Tooltip>
            </div>
            <div className="d-flex-default fas-center">
              <span className="d-flex-default fas-center fw-simi-bold mr-3">
                {t(`Tenants`)}
              </span>
              <Tooltip title={getTenantName()} placement="bottom">
                <Avatar
                  classes={{ root: `fz-12 team-avatar xs-theme pointer` }}
                  src={''}
                >
                  {getFirstLastNameLetters(getTenantName())}
                </Avatar>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex fj-start mb-3">
        <div className="w-33 mt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}Offer-letter-type*`)}
          </span>
        </div>
        <div className="w-75">
          <AutocompleteComponent
            idRef='offerLetterTypeRef'
            inputPlaceholder="select"
            wrapperClasses="w-50  mt-1"
            isDisabled={getIsOfferLetterTypeDisabled()}
            data={data.offerLetterTypes || []}
            selectedValues={(!checkIsAvailable() ? selected.offerLetterType : localSelected.offerLetterType) || null}
            getOptionSelected={(option, value) => option.systemConfigurationId === value.systemConfigurationId}
            multiple={false}
            displayLabel={(option) => option.label || ''}
            withoutSearchButton
            isWithError
            parentTranslationPath={parentTranslationPath}
            onChange={(_, newValue) => {
              if (newValue) {
                setLocalSelected({ id: 'offerLetterType', value: newValue });
                setLocalSelected({ id: 'underOfferDuration', value: +newValue.systemConfigurationValue || null });

              } else {
                setLocalSelected({
                  id: "edit", value: {
                    ...localSelected,
                    offerLetterType: null,
                    underOfferDuration: null,
                  }
                });
              }
            }}
          />
        </div>
      </div>
      {(checkIsAvailable() && localSelected.offerTemplateId && !checkIsBothDocsUploaded()) || 
      (!checkIsAvailable() && selected?.autoFilled?.offerLetterContent ) ?
        <div className="d-flex fj-start mb-3">
          <div className="w-33 mt-1">
            <span className="fw-simi-bold">
              {t(`${translationPath}Offer-letter`)}
            </span>
          </div>
          <div className="w-75">
            <FileInfoCard
              wrapperClasses="w-50 mt-3"
              templateName={generateTemplateName()}
              templateId={localSelected.offerTemplateId}
              templateContent={selected?.autoFilled?.offerLetterContent || localSelected.offerLetterContent || ''}
              emptyTemplate={data.offerLetterContent || ''}
              isLoading={isTemplateLoading}
              isReadOnly={getIsTemplateCardDisabled()}
              onViewClose={(filledTemplate) => setLocalSelected({ id: 'offerLetterContent', value: filledTemplate })}
            />
          </div>
        </div> : null}
      {(checkIsAvailable() && localSelected.offerTemplateId && !checkIsBothDocsUploaded())
      || (!checkIsAvailable() && selected?.autoFilled?.offerLetterContent) ?
       <div className="d-flex fj-start mb-3">
        <div className="w-33 mt-1">
          <p className="fw-simi-bold m-0">
            {t(`${translationPath}Under-offer-duration`)}
          </p>
        </div>
        <div className="w-75">
          <Inputs
            idRef="Under-offer-durationRef"
            wrapperClasses="w-50  mt-1"
            value={(!checkIsAvailable() ? selected.underOfferDuration : localSelected.underOfferDuration) || 0}
            isDisabled
            type="number"
            min={0}
            endAdornment={
              <span className="px-2 inner-span fz-12 light-text">
                {t(`${translationPath}days`)}
              </span>
            }
          />
          <p className="light-text w-50">
            {t(`${translationPath}Under-offer-duration-desc`)}
          </p>
        </div>
      </div> : null}
      <div className="d-flex-column gap-20">
        <DocListHolder
          documents={selected.offerLetterDocs?.tenantDocuments}
          haveToResetInitDOCs={haveToResetInitDOCs}
          onDocumentsChange={(documents) => {
            onSelectedChanged({ id: "offerLetterDocs", value: {
              ...(selected.offerLetterDocs || []),
              tenantDocuments: documents.map(item => item.isRetrieved ? item : mapSingleUploadedDoc(
                {document: item,
                isTenant : true,
                }))
           }});
          }}
          isDisabled={!(checkIsAvailable() || checkIsUnderOffer())}
          headerText="Tenant-offer-letter"
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
        <DocListHolder
          documents={selected.offerLetterDocs?.landlordDocuments}
          haveToResetInitDOCs={haveToResetInitDOCs}
          onDocumentsChange={(documents) => {
            onSelectedChanged({ id: "offerLetterDocs", value: {
              ...(selected.offerLetterDocs || []),
              landlordDocuments: documents.map(item => item.isRetrieved ? item : mapSingleUploadedDoc(
                {document: item,
                isLandlord : true,
                }))
           }});
          }}
          isDisabled={!(checkIsAvailable() || checkIsUnderOffer())}
          headerText="Landlord-offer-letter"
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
    </div>
  );
};
