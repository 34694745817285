export const LeadOwnerAdminAssignAgentPermissions = 
{
  ViewAdminAssignAgents : {
    permissionsId: "6d19aa0a-32ed-41d3-44fa-08db5f99228b",
    permissionsName: "View Admin Assign Agents",
    description: null,
    componentsId: null,
    components: null
  },
  
  AssignUnAssignAgents :{
    permissionsId: "a2c4fe68-4cb2-4917-44fb-08db5f99228b",
    permissionsName: "Assign/UnAssign Agents",
    description: null,
    componentsId: null,
    components: null
  },
  
  
  
};