import React, { useState, useEffect, useCallback } from "react";
import { Tables } from "../../../../../../Components";
import { SchoolNationalitiesHeaderData } from "./SchoolNationalitiesHeaderData";

export function SchoolNationalitiesDialog({ schoolNationalities }) {
  return (
    <div className="my-4">
      <Tables
        data={schoolNationalities || []}
        headerData={SchoolNationalitiesHeaderData || []}
      />
    </div>
  );
}
