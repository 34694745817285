export const MortgageDetailsRules2 = async (item, value, onValueChanged, itemList, values) => {
  if (item.field.id === 'customer_profile' && item.data.isReadonly)
   return;
  if (item.field.id === 'customer_profile') {
    if (!value) {
      onValueChanged(null, 0, 'confirmed_employee');
      onValueChanged(null, 0, 'allowance');
      onValueChanged(null, 0, 'liability_from_company');
      onValueChanged(null, 0, 'company_name');
      onValueChanged(null, 0, 'monthly_income');
      onValueChanged(null, 0, 'yearly_income');
      onValueChanged(null, 0, 'date_of_establishment');
      onValueChanged(null, 0, 'number_of_partner');
    } else if (value.lookupItemName === 'Salaried' || value.lookupItemId === 60521) {
      const confirmedeEmployeeIndex = itemList.findIndex((f) => f.field.id === 'confirmed_employee');
      const allowanceIndex = itemList.findIndex((f) => f.field.id === 'allowance');
      const liabilityFromCompanyIndex = itemList.findIndex((f) => f.field.id === 'liability_from_company');
      const yearlyIncomeIndex = itemList.findIndex((f) => f.field.id === 'yearly_income');
      const dateOfEstablishmentIndex = itemList.findIndex((f) => f.field.id === 'date_of_establishment');
      const numberOfPartnerIndex = itemList.findIndex((f) => f.field.id === 'number_of_partner');
      const companyNameIndex = itemList.findIndex((f) => f.field.id === 'company_name');
      const monthlyIncomeIndex = itemList.findIndex((f) => f.field.id === 'monthly_income');

       if (confirmedeEmployeeIndex !== -1)
         itemList[confirmedeEmployeeIndex].data.isReadonly = false;

       if (yearlyIncomeIndex !== -1)
         itemList[yearlyIncomeIndex].data.isReadonly = true;

        if (dateOfEstablishmentIndex !== -1)
        itemList[dateOfEstablishmentIndex].data.isReadonly = true;

        if (numberOfPartnerIndex !== -1)
        itemList[numberOfPartnerIndex].data.isReadonly = true;

        if (companyNameIndex !== -1)
        itemList[companyNameIndex].data.isReadonly = true;

        if (dateOfEstablishmentIndex !== -1)
        itemList[dateOfEstablishmentIndex].data.isReadonly = true;

        if (allowanceIndex !== -1)
        itemList[allowanceIndex].data.isReadonly = false;

        if (liabilityFromCompanyIndex !== -1)
        itemList[liabilityFromCompanyIndex].data.isReadonly = false;

        if (monthlyIncomeIndex !== -1)
        itemList[monthlyIncomeIndex].data.isReadonly = false;

      onValueChanged(null, 0, 'yearly_income');
      onValueChanged(null, 0, 'date_of_establishment');
      onValueChanged(null, 0, 'number_of_partner');
      onValueChanged(null, 0, 'company_name');
    } else if (value.lookupItemName === 'Self-Employed' || value.lookupItemId === 60522) {
      const confirmedeEmployeeIndex = itemList.findIndex((f) => f.field.id === 'confirmed_employee');
      const allowanceIndex = itemList.findIndex((f) => f.field.id === 'allowance');
      const liabilityFromCompanyIndex = itemList.findIndex((f) => f.field.id === 'liability_from_company');
      const yearlyIncomeIndex = itemList.findIndex((f) => f.field.id === 'yearly_income');
      const dateOfEstablishmentIndex = itemList.findIndex((f) => f.field.id === 'date_of_establishment');
      const numberOfPartnerIndex = itemList.findIndex((f) => f.field.id === 'number_of_partner');
      const companyNameIndex = itemList.findIndex((f) => f.field.id === 'company_name');
      const monthlyIncomeIndex = itemList.findIndex((f) => f.field.id === 'monthly_income');

      if (confirmedeEmployeeIndex !== -1)
         itemList[confirmedeEmployeeIndex].data.isReadonly = true;

      if (allowanceIndex !== -1)
      itemList[allowanceIndex].data.isReadonly = true;

      if (liabilityFromCompanyIndex !== -1)
      itemList[liabilityFromCompanyIndex].data.isReadonly = true;

      if (yearlyIncomeIndex !== -1)
      itemList[yearlyIncomeIndex].data.isReadonly = false;

       if (dateOfEstablishmentIndex !== -1)
      itemList[dateOfEstablishmentIndex].data.isReadonly = false;

      if (numberOfPartnerIndex !== -1)
      itemList[numberOfPartnerIndex].data.isReadonly = false;

      if (companyNameIndex !== -1)
      itemList[companyNameIndex].data.isReadonly = false;

      if (monthlyIncomeIndex !== -1)
      itemList[monthlyIncomeIndex].data.isReadonly = false;

      onValueChanged(null, 0, 'confirmed_employee');
      onValueChanged(null, 0, 'allowance');
      onValueChanged(null, 0, 'liability_from_company');
    } else if (value.lookupItemName === 'have a different Source of Income' || value.lookupItemId === 60523) {
      const confirmedeEmployeeIndex = itemList.findIndex((f) => f.field.id === 'confirmed_employee');
      const allowanceIndex = itemList.findIndex((f) => f.field.id === 'allowance');
      const liabilityFromCompanyIndex = itemList.findIndex((f) => f.field.id === 'liability_from_company');
      const yearlyIncomeIndex = itemList.findIndex((f) => f.field.id === 'yearly_income');
      const dateOfEstablishmentIndex = itemList.findIndex((f) => f.field.id === 'date_of_establishment');
      const numberOfPartnerIndex = itemList.findIndex((f) => f.field.id === 'number_of_partner');
      const companyNameIndex = itemList.findIndex((f) => f.field.id === 'company_name');
      const monthlyIncomeIndex = itemList.findIndex((f) => f.field.id === 'monthly_income');

       if (confirmedeEmployeeIndex !== -1)
         itemList[confirmedeEmployeeIndex].data.isReadonly = true;

       if (allowanceIndex !== -1)
         itemList[allowanceIndex].data.isReadonly = true;

       if (numberOfPartnerIndex !== -1)
        itemList[numberOfPartnerIndex].data.isReadonly = true;

        if (liabilityFromCompanyIndex !== -1)
        itemList[liabilityFromCompanyIndex].data.isReadonly = true;

         if (yearlyIncomeIndex !== -1)
        itemList[yearlyIncomeIndex].data.isReadonly = true;

         if (dateOfEstablishmentIndex !== -1)
        itemList[dateOfEstablishmentIndex].data.isReadonly = true;

        if (numberOfPartnerIndex !== -1)
        itemList[numberOfPartnerIndex].data.isReadonly = true;

        if (companyNameIndex !== -1)
        itemList[companyNameIndex].data.isReadonly = true;

        if (monthlyIncomeIndex !== -1)
        itemList[monthlyIncomeIndex].data.isReadonly = false;

      onValueChanged(null, 0, 'confirmed_employee');
      onValueChanged(null, 0, 'allowance');
      onValueChanged(null, 0, 'liability_from_company');
      onValueChanged(null, 0, 'company_name');
      onValueChanged(null, 0, 'yearly_income');
      onValueChanged(null, 0, 'date_of_establishment');
      onValueChanged(null, 0, 'number_of_partner');
    } else if (value.lookupItemName === 'TBD-Update Later' || value.lookupItemId === 60524) {
      const confirmedeEmployeeIndex = itemList.findIndex((f) => f.field.id === 'confirmed_employee');
      const allowanceIndex = itemList.findIndex((f) => f.field.id === 'allowance');
      const liabilityFromCompanyIndex = itemList.findIndex((f) => f.field.id === 'liability_from_company');
      const yearlyIncomeIndex = itemList.findIndex((f) => f.field.id === 'yearly_income');
      const dateOfEstablishmentIndex = itemList.findIndex((f) => f.field.id === 'date_of_establishment');
      const numberOfPartnerIndex = itemList.findIndex((f) => f.field.id === 'number_of_partner');
      const companyNameIndex = itemList.findIndex((f) => f.field.id === 'company_name');
      const monthlyIncomeIndex = itemList.findIndex((f) => f.field.id === 'monthly_income');

       if (confirmedeEmployeeIndex !== -1)
         itemList[confirmedeEmployeeIndex].data.isReadonly = true;

       if (allowanceIndex !== -1)
         itemList[allowanceIndex].data.isReadonly = true;

       if (numberOfPartnerIndex !== -1)
        itemList[numberOfPartnerIndex].data.isReadonly = true;

        if (liabilityFromCompanyIndex !== -1)
        itemList[liabilityFromCompanyIndex].data.isReadonly = true;

         if (yearlyIncomeIndex !== -1)
        itemList[yearlyIncomeIndex].data.isReadonly = true;

         if (dateOfEstablishmentIndex !== -1)
        itemList[dateOfEstablishmentIndex].data.isReadonly = true;

        if (numberOfPartnerIndex !== -1)
        itemList[numberOfPartnerIndex].data.isReadonly = true;

        if (companyNameIndex !== -1)
        itemList[companyNameIndex].data.isReadonly = true;

        if (monthlyIncomeIndex !== -1)
        itemList[monthlyIncomeIndex].data.isReadonly = false;

         onValueChanged(null, 0, 'confirmed_employee');
         onValueChanged(null, 0, 'allowance');
         onValueChanged(null, 0, 'liability_from_company');
         onValueChanged(null, 0, 'company_name');
         onValueChanged(null, 0, 'yearly_income');
         onValueChanged(null, 0, 'date_of_establishment');
         onValueChanged(null, 0, 'number_of_partner');
    }
  }
};

export const MortgageDetailsRules1 = async (item, value, setData, itemList, values) => {
  if (item.field.id === 'customer_profile') {
    if (!value) {
      setTimeout(() => {
      setData('confirmed_employee', {});
      setData('allowance', {});
      setData('liability_from-company', null);
      setData('liability_from_company', {});
      setData('company_name', {});
      setData('monthly_income', '  ');
      setData('yearly_income', '  ');
      setData('date_of-establishment', null);
      setData('date_of_establishment', {});
      setData('number_of-partner', null);
      setData('number_of_partner', '  ');
      });
    } else {
      const confirmedeEmployeeIndex = itemList.findIndex((f) => f.field.id === 'confirmed_employee');
      const allowanceIndex = itemList.findIndex((f) => f.field.id === 'allowance');
      const liabilityFromCompanyIndex = itemList.findIndex((f) => f.field.id === 'liability_from-company');
      const companyNameIndex = itemList.findIndex((f) => f.field.id === 'company_name');
      const dateOfEstablishmentIndex = itemList.findIndex((f) => f.field.id === 'date_of-establishment');
      const numberOfPartnerIndex = itemList.findIndex((f) => f.field.id === 'number_of-partner');
      const yearlyIncomeIndex = itemList.findIndex((f) => f.field.id === 'yearly_income');
      const monthlyIncomeIndex = itemList.findIndex((f) => f.field.id === 'monthly_income');

    if (value.lookupItemName === 'Salaried' || value.lookupItemId === 60521) {
       if (confirmedeEmployeeIndex !== -1)
         itemList[confirmedeEmployeeIndex].data.isReadonly = false;

         if (allowanceIndex !== -1)
         itemList[allowanceIndex].data.isReadonly = false;

         if (liabilityFromCompanyIndex !== -1)
         itemList[liabilityFromCompanyIndex].data.isReadonly = false;

       if (yearlyIncomeIndex !== -1)
         itemList[yearlyIncomeIndex].data.isReadonly = true;

        if (dateOfEstablishmentIndex !== -1)
        itemList[dateOfEstablishmentIndex].data.isReadonly = true;

        if (numberOfPartnerIndex !== -1)
        itemList[numberOfPartnerIndex].data.isReadonly = true;

        if (companyNameIndex !== -1)
        itemList[companyNameIndex].data.isReadonly = true;

        if (dateOfEstablishmentIndex !== -1)
        itemList[dateOfEstablishmentIndex].data.isReadonly = true;

        if (monthlyIncomeIndex !== -1)
        itemList[monthlyIncomeIndex].data.isReadonly = false;

        setData('yearly_income', '  ');
        setData('date_of-establishment', null);
        setData('date_of_establishment', {});
        setData('number_of-partner', null);
        setData('number_of_partner', '  ');
        setData('company_name', {});
    } else if (value.lookupItemName === 'Self-Employed' || value.lookupItemId === 60522) {
      if (confirmedeEmployeeIndex !== -1)
         itemList[confirmedeEmployeeIndex].data.isReadonly = true;

      if (allowanceIndex !== -1)
      itemList[allowanceIndex].data.isReadonly = true;

      if (liabilityFromCompanyIndex !== -1)
      itemList[liabilityFromCompanyIndex].data.isReadonly = true;

      if (yearlyIncomeIndex !== -1)
      itemList[yearlyIncomeIndex].data.isReadonly = false;

       if (dateOfEstablishmentIndex !== -1)
      itemList[dateOfEstablishmentIndex].data.isReadonly = false;

      if (numberOfPartnerIndex !== -1)
      itemList[numberOfPartnerIndex].data.isReadonly = false;

      if (companyNameIndex !== -1)
      itemList[companyNameIndex].data.isReadonly = false;

      if (monthlyIncomeIndex !== -1)
      itemList[monthlyIncomeIndex].data.isReadonly = false;

      setData('confirmed_employee', {});
      setData('allowance', {});
      setData('liability_from_company', {});
      setData('liability_from-company', null);
    } else if (value.lookupItemName === 'have a different Source of Income' || value.lookupItemId === 60523) {
       if (confirmedeEmployeeIndex !== -1)
         itemList[confirmedeEmployeeIndex].data.isReadonly = true;

       if (allowanceIndex !== -1)
         itemList[allowanceIndex].data.isReadonly = true;

       if (numberOfPartnerIndex !== -1)
        itemList[numberOfPartnerIndex].data.isReadonly = true;

        if (liabilityFromCompanyIndex !== -1)
        itemList[liabilityFromCompanyIndex].data.isReadonly = true;

         if (yearlyIncomeIndex !== -1)
        itemList[yearlyIncomeIndex].data.isReadonly = true;

         if (dateOfEstablishmentIndex !== -1)
        itemList[dateOfEstablishmentIndex].data.isReadonly = true;

        if (numberOfPartnerIndex !== -1)
        itemList[numberOfPartnerIndex].data.isReadonly = true;

        if (companyNameIndex !== -1)
        itemList[companyNameIndex].data.isReadonly = true;

        if (monthlyIncomeIndex !== -1)
        itemList[monthlyIncomeIndex].data.isReadonly = false;

        setData('confirmed_employee', {});
        setData('allowance', {});
        setData('liability_from-company', null);
        setData('liability_from_company', {});
        setData('company_name', {});
        setData('yearly_income', '  ');
        setData('date_of-establishment', null);
        setData('date_of_establishment', {});
        setData('number_of_partner', '  ');
        setData('number_of-partner', null);
    } else if (value.lookupItemName === 'TBD-Update Later' || value.lookupItemId === 60524) {
       if (confirmedeEmployeeIndex !== -1)
         itemList[confirmedeEmployeeIndex].data.isReadonly = true;

       if (allowanceIndex !== -1)
         itemList[allowanceIndex].data.isReadonly = true;

       if (numberOfPartnerIndex !== -1)
        itemList[numberOfPartnerIndex].data.isReadonly = true;

        if (liabilityFromCompanyIndex !== -1)
        itemList[liabilityFromCompanyIndex].data.isReadonly = true;

         if (yearlyIncomeIndex !== -1)
        itemList[yearlyIncomeIndex].data.isReadonly = true;

         if (dateOfEstablishmentIndex !== -1)
        itemList[dateOfEstablishmentIndex].data.isReadonly = true;

        if (numberOfPartnerIndex !== -1)
        itemList[numberOfPartnerIndex].data.isReadonly = true;

        if (companyNameIndex !== -1)
        itemList[companyNameIndex].data.isReadonly = true;

        if (monthlyIncomeIndex !== -1)
        itemList[monthlyIncomeIndex].data.isReadonly = false;

        setData('confirmed_employee', {});
        setData('allowance', {});
        setData('liability_from-company', null);
        setData('liability_from_company', {});
        setData('company_name', {});
        setData('yearly_income', '  ');
        setData('date_of-establishment', null);
        setData('date_of_establishment', {});
        setData('number_of-partner', null);
        setData('number_of_partner', '  ');
    }
  }
  }
};
