import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tables } from '../../../../../Components';
import { TableActions, TableFilterTypesEnum } from '../../../../../Enums';
import UserSlider from './UserSlider/UserSlider';
import { getIsAllowedPermission } from '../../../../../Helper/Permissions.Helper';
import {
  sideMenuIsOpenUpdate,
  sideMenuComponentUpdate,
} from '../../../../../Helper';
import { UserLoginPermissions } from '../../../../../Permissions';
import { GetUserId } from '../../../../../Services';
import { TableFilterOptionsSelectUserStatusEnum } from '../../../../../Enums/TableFilterOptionsSelectUserStatus.Enum';
import moment from 'moment';

const UserListView = ({
  data,
  filter,
  actionClicked,
  translationPath,
  activePageChanged,
  itemsPerPageChanged,
  setSortBy,
  onFilterValuesChanged,
  isWithCheckboxColumn,
  selectedUsers,
  onDeselectUser,
  onSelectUser,
}) => {
  const { t } = useTranslation('UsersView');
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [currentActions, setCurrentActions] = useState(() => []);
  const getUserActions = (userStatus) => {
    // eslint-disable-next-line prefer-const
    let actionsList = [];
    if (userStatus === 'Pending') {
      actionsList.push({
        enum: TableActions.close.key,
        title: t(`${translationPath}Deactive`),
      });
      if (
        getIsAllowedPermission(
          Object.values(UserLoginPermissions),
          loginResponse,
          UserLoginPermissions.DeleteButtonOnCard.permissionsId
        )
      )
        actionsList.push({
          enum: TableActions.delete.key,
          title: `${translationPath}Delete`,
          isDisabled: true,
        });
    } else if (userStatus === 'Canceled') {
      if (
        getIsAllowedPermission(
          Object.values(UserLoginPermissions),
          loginResponse,
          UserLoginPermissions.DeleteButtonOnCard.permissionsId
        )
      )
        actionsList.push({
          enum: TableActions.delete.key,
          title: `${translationPath}Delete`,
          isDisabled: true,
        });
    } else if (userStatus === 'Active') {
      if (
        getIsAllowedPermission(
          Object.values(UserLoginPermissions),
          loginResponse,
          UserLoginPermissions.EditButtonInUserRecord.permissionsId
        )
      )
        actionsList.push({
          enum: TableActions.edit.key,
          title: `${translationPath}Edit`,
        });
      actionsList.push({
        enum: TableActions.close.key,
        title: `${translationPath}Deactive`,
      });
      if (
        getIsAllowedPermission(
          Object.values(UserLoginPermissions),
          loginResponse,
          UserLoginPermissions.DeleteButtonOnCard.permissionsId
        )
      )
        actionsList.push({
          enum: TableActions.delete.key,
          title: `${translationPath}delete`,
          isDisabled: true,
        });
    }
    return actionsList;
  };

  const DriversSliderClicked = useCallback(async (item) => {
    const res = await GetUserId(item.id);
    item.userId = res;
    sideMenuComponentUpdate(<UserSlider item={item} />);
    sideMenuIsOpenUpdate(true);
  }, []);

  const focusedRowChanged = (activeRow) => {
    const item = data.result[activeRow];
    if (!item) return;
    setCurrentActions(getUserActions(item.userStatus));
    DriversSliderClicked(item);
  };

  const getIsSelected = useCallback(
    (row) => selectedUsers.findIndex((item) => item.id === row.id) !== -1,
    [selectedUsers]
  );

  const onSelectClicked = (row) => {
    if (getIsSelected(row)) {
      onDeselectUser(row);
    } else {
      onSelectUser(row);
    }
  };

  useEffect(
    () => () => {
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );
  const [userDataHeader] = useState([
    {
      id: 1,
      isSortable: true,
      label: t(`${translationPath}name`),
      input: 'fullName',
      isDate: false,
      isHiddenFilter: true,
    },
    {
      id: 2,
      isSortable: true,
      label: t(`${translationPath}username`),
      input: 'userName',
      isDate: false,
      isHiddenFilter: true,
    },
    {
      id: 3,
      isSortable: true,
      label: t(`${translationPath}mobile`),
      input: 'phoneNumber',
      isDate: false,
      isHiddenFilter: true,
    },
    {
      id: 4,
      isSortable: true,
      label: t(`${translationPath}email`),
      input: 'email',
      isDate: false,
      isHiddenFilter: true,
    },
    {
      id: 5,
      isSortable: true,
      label: t(`${translationPath}status`),
      input: 'userStatusId',
      component: (item) =>
        item && item.userStatus == 'Canceled'
          ? 'Deactivated'
          : item.userStatus || 'N/A',
      isDate: false,
      isHiddenFilter: true,
      withSelectFilter: true,
    },
    {
      id: 6,
      isSortable: true,
      label: t(`${translationPath}branch`),
      input: 'branch',
      isDate: false,
      isHiddenFilter: true,
    },
    {
      id: 7,
      label: t(`${translationPath}staffCode`),
      input: 'staffCode',
      isHiddenFilter: true,
      isSortable: true,
    },
    {
      id: 8,
      isSortable: true,
      label: t(`${translationPath}last-login`),
      input: 'lastLogin',
      component: (item) =>
        (item && moment(item.lastLogin).format('lll')) || 'N/A',
      isDate: false,
      isHiddenFilter: true,
      isSortable: true,
    },
    {
      id: 9,
      label: t(`${translationPath}createdOn`),
      input: 'createdOn',
      isDate: true,
      isHiddenFilter: true,
      isSortable: true,
      dateFormat: 'DD/MM/YYYY',
    },
    {
      id: 10,
      label: t(`${translationPath}MobileOperatingSystem`),
      input: 'mobileOperatingSystemId',
      component: (item) => <span>{item.mobileOperatingSystemName || ''}</span>,
      isSortable: true,
      isHiddenFilter: true,
    },
  ]);
  const [tableFilterData, setTableFilterData] = useState([]);
  useEffect(() => {
    setTableFilterData(
      userDataHeader.map((column) => ({
        key: column.key || column.fieldKey || column.id,
        filterType:
          (column.withSelectFilter && TableFilterTypesEnum.selectOption.key) ||
          TableFilterTypesEnum.textInput.key,
        isHiddenFilter: column.isHiddenFilter,
        textInputType: column.textInputType,
        textInputMax: column.textInputMax,
        textInputMin: column.textInputMin,
        displayPath: column.input,
      }))
    );
  }, [userDataHeader]);
  return (
    <div className='w-100 px-3'>
      <Tables
        isWithCheckboxColumn={isWithCheckboxColumn}
        selectAllOptions={
          isWithCheckboxColumn && {
            selectedRows: selectedUsers.map((item) => item.id),
            getIsSelected,
            disabledRows: [],
            getIsDisabled: undefined,
            onSelectClicked,
          }
        }
        HideSellectAll
        headerData={userDataHeader}
        translationPath={translationPath}
        data={data && data.result}
        activePage={filter.pageIndex}
        totalItems={(data && data.totalCount) || 0}
        activePageChanged={activePageChanged}
        FilterDisabledButton
        itemsPerPage={filter.pageSize}
        itemsPerPageChanged={itemsPerPageChanged}
        focusedRowChanged={focusedRowChanged}
        actionsOptions={{
          actions: currentActions,
          classes: '',
          isDisabled: false,
          onActionClicked: actionClicked,
        }}
        setSortBy={setSortBy}
        onFilterValuesChanged={onFilterValuesChanged}
        filterData={tableFilterData}
        // isWithFilter
        optionFilterName={TableFilterOptionsSelectUserStatusEnum}
      />
    </div>
  );
};
export { UserListView };
UserListView.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.instanceOf(Array),
    totalCount: PropTypes.number,
  }).isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  actionClicked: PropTypes.func.isRequired,
  activePageChanged: PropTypes.func.isRequired,
  itemsPerPageChanged: PropTypes.func.isRequired,
  translationPath: PropTypes.string,
  setSortBy: PropTypes.func,
};
UserListView.defaultProps = {
  translationPath: '',
  setSortBy: undefined,
};
