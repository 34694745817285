import React from "react";

function ColumnsThree({ fill, ...restProps }) {
  return (
    <svg
      fill={fill ?? ""}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M5.92 2.041c-.769.051-1.248.164-1.74.41A3.776 3.776 0 0 0 2.124 5.16C2.013 5.729 2 6.455 2 12c0 6.358.006 6.526.245 7.32.332 1.102 1.333 2.103 2.435 2.435.794.239.962.245 7.32.245 6.358 0 6.526-.006 7.32-.245 1.102-.332 2.103-1.333 2.435-2.435.239-.794.245-.962.245-7.32 0-6.358-.006-6.526-.245-7.32-.331-1.099-1.29-2.058-2.435-2.434-.676-.222-.52-.217-6.98-.227-3.278-.005-6.167.005-6.42.022M8 12.005v8.005l-1.11-.021c-.619-.011-1.216-.041-1.35-.068a1.693 1.693 0 0 1-.94-.518c-.297-.299-.414-.513-.512-.931-.063-.269-.068-.785-.068-6.472s.005-6.203.068-6.472c.087-.373.206-.61.432-.861.493-.549.892-.648 2.67-.661L8 4v8.005M14 12v8h-4V4h4v8m4.492-7.902c.393.085.613.207.908.502.296.295.414.509.512.928.063.269.068.785.068 6.472s-.005 6.203-.068 6.472c-.098.419-.216.632-.512.929-.289.289-.571.446-.94.52-.134.027-.73.057-1.35.068L16 20.01V3.988l1.11.025c.743.017 1.2.045 1.382.085"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default ColumnsThree;
