
import React, { useEffect}  from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';

  export const UnitsFacilities = ({
    parentTranslationPath ,
    translationPath ,
    state ,
    setSelectedList  , 
    selectedList , 
    onChangeSelectedItem ,

   } ) => { 

    const { t } = useTranslation(parentTranslationPath);

  
    useEffect(() => {
      setSelectedList((item)=> ({...item , UnitsFacilities : state.updatePropertyRatingLookupInfoDtos.filter((x)=> x.type === 'UnitsFacilities')}));
   }, [state]);



   return (
    <>
      <div className="">
      <span>  </span>
      </div> 
      <div className='w-100 units-finishing'>
       {    selectedList && selectedList.UnitsFacilities &&  selectedList.UnitsFacilities.map((item, index) => 
          (
          <div className="d-flex d-flex-v-center tower-units-finishing-card mt-2 mb-2 b-bottom" key={`UnitsFacilities${index}`}>
          <div>
          <span className='fw-bold'> {item.lookupItemName} </span> 
          </div>
          <div>
          <Rating  
              size="large"
              name={`UnitsFacilities${index}`}
              max={10}
              value={item.score}
              precision={1}
              onChange={(event, newValue) => 
              {
                onChangeSelectedItem(newValue || 0 ,index , selectedList.UnitsFacilities , 'UnitsFacilities');
              }}
              />     
            </div>
         </div>  
        )) } 
      </div>
    </>
  );
  } ; 
  
