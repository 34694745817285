import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../../../Components';
import { DateRangePickerComponent } from '../../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import { bottomBoxComponentUpdate } from '../../../../../Helper/Middleware.Helper';
import { PaginationComponent } from '../../../../../Components/PaginationComponent/PaginationComponent';
import { GetAllImportProcess } from '../../../../../Services/File';
import { ImportDetailsActionsTable } from './ImportDetailsActionsTable';

export const ImportDetailsActions = () => {
    const { t } = useTranslation('ImportDetailsView');
    const translationPath = '';
    const parentTranslationPath = 'ImportDetailsView';
    const [importDetails, setImportDetails] = useState({
        result: [],
        totalCount: 0,
    });
    const [filter, setFilter] = useState({
        pageIndex: 0,
        pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
        createdByUserName: null,
        importProcessTypeId: null,
        fromDate: null,
        toDate: null
    });
    const dateRangeDefault = {
        startDate: null,
        endDate: null,
        key: 'selection',
    };

    const [dateFilter, setDateFilter] = useState(dateRangeDefault);

    const [isLoading, setIsLoading] = useState(false);
    const getProcess = useCallback(async () => {
        setIsLoading(true);
        const res = await GetAllImportProcess({ ...filter, pageIndex: filter.pageIndex + 1 });
        if (!(res && res.status && res.status !== 200)) {
            setImportDetails({
                result: (res && res.result) || [],
                totalCount: (res && res.totalCount) || 0,
            });
        }
        setIsLoading(false);
    }, [filter]);

    const onPageIndexChanged = (pageIndex) => {
        setFilter((item) => ({ ...item, pageIndex }));
    };
    const onPageSizeChanged = (pageSize) => {
        setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    };

    useEffect(() => {
        bottomBoxComponentUpdate(
          <PaginationComponent
            pageIndex={filter.pageIndex}
            pageSize={filter.pageSize}
            totalCount={importDetails.totalCount}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
          />
        );
    });

    useEffect(() => () => { bottomBoxComponentUpdate(null); }, []);

    const onFilterValuesChanged = (newValue) => {
        const localFilterDto = filter || {};

        if (localFilterDto && localFilterDto.createdOn) {
            localFilterDto.toDate = moment(localFilterDto.createdOn).format('YYYY-MM-DDTHH:mm:ss');
            localFilterDto.fromDate = moment(localFilterDto.createdOn).format('YYYY-MM-DDTHH:mm:ss');
        }
        if (newValue) {
            Object.values(newValue)
                .filter((item) => item.searchableKey || item.displayPath)
                .map((item) => {
                    if (localFilterDto[item.displayPath])
                        localFilterDto[item.displayPath] = item.value;
                    else if (item.value)
                        localFilterDto[item.displayPath] = item.value;

                    return undefined;
                });
        }
        setFilter(() => ({ ...localFilterDto }));
    };
    useEffect(() => {
        getProcess();
    }, [filter]);

    useEffect(() => {
        setFilter((item) => ({
            ...item,
            fromDate: !dateFilter.startDate ? dateFilter.startDate : moment(dateFilter.startDate).format('YYYY-MM-DDTHH:mm:ss'),
            toDate: !dateFilter.endDate ? dateFilter.endDate : moment(dateFilter.endDate).format('YYYY-MM-DDTHH:mm:ss'),
        }));
    }, [dateFilter]);

    return (
      <div className='RotationSchema-wrapper view-wrapper'>
        <Spinner isActive={isLoading} isAbsolute />
        <div className='w-100 px-2'>
          <div className='header-section'>
            <div className='header-section '>
              <div className='d-flex-v-center-h-end m-3'>
                <DateRangePickerComponent
                  onClearClicked={() => setDateFilter(dateRangeDefault)}
                  ranges={[dateFilter]}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onDateChanged={(selectedDate) => {
                                    setDateFilter({
                                        startDate: selectedDate.selection && selectedDate.selection.startDate,
                                        endDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                                        key: 'selection',
                                    });
                                }}
                />
              </div>
            </div>
          </div>
          <ImportDetailsActionsTable
            data={importDetails.result}
            filter={filter}
            setFilter={setFilter}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            onFilterValuesChanged={onFilterValuesChanged}
          />

        </div>
      </div>
    );
};

ImportDetailsActions.propTypes = {

};
ImportDetailsActions.defaultProps = {

};
