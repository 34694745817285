/* eslint-disable no-unused-expressions */
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import { ButtonBase } from "@material-ui/core";
import {
  AutocompleteComponent,
  CheckboxesComponent,
  DialogComponent,
  Inputs,
  Spinner,
} from "../../../../../Components";
import {
  GetParams,
  getErrorByName,
  showError,
  showSuccess,
} from "../../../../../Helper";
import { SMSTemplateComponent, TemplatebuilderComponent } from "./Sections";
import {
  CreateSystemTemplateAPI,
  GetAllSystemTemplateFolders,
  GetAllTemplateCategoryServices,
  GetContacts,
  GetlookupTypeItems,
  getProperties,
  GetTamplateDetailsById,
  lookupItemsGet,
  lookupItemsGetId,
  UpdateTemplate,
} from "../../../../../Services";
import OpationTemplateBuilder from "./Sections/TemplatebuilderComponent/OpationTemplateBuilder";
import { TemplatesBodyManagementDialog } from "./TemplatesBodyManagementDialog";
import { UnitsOperationTypeEnum, UnitsStatusEnum } from "../../../../../Enums";
import { StaticLookupsIds } from "../../../../../assets/json/StaticLookupsIds";

export const TemplatesManagementDialog = ({
  activeItem,
  onSave,
  isOpen,
  isOpenChanged,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation([parentTranslationPath, "Shared"]);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const defuletState = {
    SaleSale: {
      checked: true,
      unitStatusId: UnitsStatusEnum.Sale.key,
      unitOperationTypeId: UnitsOperationTypeEnum.sale.key,
    },
    SaleAvailable: {
      checked: true,
      unitStatusId: UnitsStatusEnum.Available.key,
      unitOperationTypeId: UnitsOperationTypeEnum.sale.key,
    },
    SaleReserved: {
      checked: true,
      unitStatusId: UnitsStatusEnum.ReservedSale.key,
      unitOperationTypeId: UnitsOperationTypeEnum.sale.key,
    },
    SaleDraft: {
      checked: true,
      unitStatusId: UnitsStatusEnum.Draft.key,
      unitOperationTypeId: UnitsOperationTypeEnum.sale.key,
    },
    LeasedLeased: {
      checked: true,
      unitStatusId: UnitsStatusEnum.Leased.key,
      unitOperationTypeId: UnitsOperationTypeEnum.rent.key,
    },
    LeaseAvailable: {
      checked: true,
      unitStatusId: UnitsStatusEnum.Available.key,
      unitOperationTypeId: UnitsOperationTypeEnum.rent.key,
    },
    LeaseReserved: {
      checked: true,
      unitStatusId: UnitsStatusEnum.ReservedLeased.key,
      unitOperationTypeId: UnitsOperationTypeEnum.rent.key,
    },
    LeasedDraft: {
      checked: true,
      unitStatusId: UnitsStatusEnum.Draft.key,
      unitOperationTypeId: UnitsOperationTypeEnum.rent.key,
    },
  };
  const [Data, setData] = useState([]);
  const [Main, setMain] = useState([]);
  const [OpationTemplateBuilderState, setOpationTemplateBuilderState] =
    useState(defuletState);
  const [
    TemplatesBodyManagementDialogOpen,
    SetTemplatesBodyManagementDialogOpen,
  ] = useState(false);
  const [isLoadingmasterDeveloper, SetisLoadingmasterDeveloper] =
    useState(false);
  const [Params, setParams] = useState([]);
  const [ShowExtraCheckboxes, SetShowExtraCheckboxes] = useState(false);
  const [selectedtemplateTypeId, setselectedtemplateTypeId] = useState([]);
  const [selectedtemplateFolderId, setselectedtemplateFolderId] = useState([]);
  const [systemTemplateCategoryDto, setSystemTemplateCategoryDto] = useState(
    []
  );
  const [resonse, setResonse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [IsUserTemplte, setIsUserTemplte] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedSaleType, setselectedSaleType] = useState([]);
  const [ExtraCategoryIds, setExtraCategoryIds] = useState([]);
  const [Currenlookup, setCurrenlookup] = useState([]);
  const [DataUser, setDataUser] = useState([]);
  const searchTimer = useRef(null);
  const [DataUserSelected, setDataUserSelected] = useState([]);
  const [propertySelected, setpropertySelected] = useState([]);
  const [AutocompleteData, setAutocompleteData] = useState([]);
  const [UnitSaleTypeAutoCompleteData, setUnitSaleTypeAutoCompleteData] =
    useState([]);
  const [FolderData, setFolderData] = useState([]);
  const [result, setResult] = useState([]);
  const [PropertyData, setPropertyData] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [CheckAllUnitTypes, setCheckAllUnitTypes] = useState(false);
  const [isUnitProposal, setisUnitProposal] = useState(false);
  const [state, setState] = useReducer(reducer, {
    templateName: "",
    unitTypesIds: [],
    templateTypeId: null,
    templateExtraCategoryIds: [],
    templateBody: "",
    templateCategoryId: "",
    templateKeys: "",
    emailSubject: "",
    templateParams: Params,
    systemTemplateFolderId: null,
    systemTemplateUnitStatuses: defuletState || [],
    templateEmailBody: "",
    templateEmailKeys: "",
    isProposal: false,
    masterDeveloperId: null,
    propertyId: null,
    saleTypesIds: null,
  });
   
  const onTemplateEmailKeysChanged = (newValue) => {
    setState({ id: "templateEmailKeys", value: newValue });
  };
  const onTemplateEmailBodyChanged = (newValue) => {
    setState({ id: "templateEmailBody", value: newValue });
  };
  const [loadings, setloadings] = useState(false);
  const getlTemplateCategory = useCallback(async () => {
    setloadings(true);
    const res = await GetAllTemplateCategoryServices(0, 10);
    if (!(res && res.status && res.status !== 200)) {
      if (activeItem) {
        const hasOnlyValue6 = [activeItem].every(
          (item) =>
            Array.isArray(item.templateExtraCategoryId) &&
            item.templateExtraCategoryId.length === 1 &&
            item.templateExtraCategoryId[0] === 6
        );
        const specificUserObject = (res && res.result).find(
          (item) => item.templateCategoryName === "With Specific User"
        );
        if (hasOnlyValue6) setResonse([specificUserObject] || []);
        else setResonse((res && res.result) || []);
      } else setResonse((res && res.result) || []);
    } else setResonse([]);
    setloadings(false);
  }, []);
  const lookupGetCurrencies = useCallback(async () => {
    const lookupresult = await lookupItemsGet({
      pageIndex: 1,
      pageSize: 100,
      lookupTypeName: "Template Type",
    });
    setCurrenlookup(lookupresult && lookupresult.result);
  }, []);

  const onStateChanged = (newValue) => {
    setState({ id: "templateKeys", value: newValue });
  };

  const onContentChanged = (newValue) => {
    setState({ id: "templateBody", value: newValue });
  };

  const getAllUsers = async (search) => {
    SetisLoadingmasterDeveloper(true);
    const res = await GetContacts({
      pageIndex: 0,
      pageSize: 100,
      search,
      isAdvance: false,
    });
    if (!(res && res.status && res.status !== 200))
      setDataUser((res && res.result) || []);
    else setDataUser([]);

    SetisLoadingmasterDeveloper(false);
  };

  const GetAllProperties = useCallback(async (propertySearch) => {
    const res = await getProperties({
      pageSize: 50,
      pageIndex: 0,
      search: propertySearch,
    });
    if (!(res && res.status && res.status !== 200)) {
      const mapped = [];
      res.result.map((item) => {
        mapped.push({
          propertyId: item.propertyId,
          propertyName: item.property && item.property.property_name,
          property_plan:
            item.property &&
            item.property.property_plan &&
            item.property.property_plan.lookupItemName,
        });
      });
      setPropertyData(mapped || []);
    } else setPropertyData([]);
    // else setLookups([]);
  }, []);

  const GetTamplateDetailsByIdAPI = useCallback(async (Id) => {
    setIsLoading(true);
    const response = await GetTamplateDetailsById(Id);
    if (!(response && response.status && response.status !== 200)) {
      setResult(response || []);
      setisUnitProposal((response && response.isProposal) || false);
      setselectedSaleType(
        response &&
          response.saleTypeLookupDto &&
          response.saleTypeLookupDto.map((x) => ({
            lookupItemId: x.saleTypeId,
            lookupItemName: x.saleTypeName,
          }))
      );
      setState({
        id: "saleTypesIds",
        value:
          response &&
          response.saleTypeLookupDto &&
          response.saleTypeLookupDto.map((x) => x.saleTypeId),
      });

      setpropertySelected(
        (response &&
          response.propertyId && {
            propertyId: response && response.propertyId,
            propertyName: response && response.propertyName,
            property_plan: null,
          }) ||
          []
      );
      setDataUserSelected({
        contactsId: response && response.masterDeveloperId,
        contact: {
          first_name: response && response.masterDeveloperName,
          last_name: "",
        },
      });
      const ItemDataValue = {};

      const statusOperationMap = {
        [`${UnitsStatusEnum.Draft.key}_${UnitsOperationTypeEnum.rent.key}`]:
          "LeasedDraft",
        [`${UnitsStatusEnum.Draft.key}_${UnitsOperationTypeEnum.sale.key}`]:
          "SaleDraft",
        [`${UnitsStatusEnum.Available.key}_${UnitsOperationTypeEnum.sale.key}`]:
          "SaleAvailable",
        [`${UnitsStatusEnum.Available.key}_${UnitsOperationTypeEnum.rent.key}`]:
          "LeaseAvailable",
        [`${UnitsStatusEnum.ReservedLeased.key}_${UnitsOperationTypeEnum.rent.key}`]:
          "LeaseReserved",
        [`${UnitsStatusEnum.ReservedSale.key}_${UnitsOperationTypeEnum.sale.key}`]:
          "SaleReserved",
        [`${UnitsStatusEnum.Leased.key}_${UnitsOperationTypeEnum.rent.key}`]:
          "LeasedLeased",
        [`${UnitsStatusEnum.Sale.key}_${UnitsOperationTypeEnum.sale.key}`]:
          "SaleSale",
      };

      response.systemTemplateUnitStatuses.forEach((item) => {
        const key = `${item.unitStatusId}_${item.unitOperationTypeId}`;
        const operation = statusOperationMap[key];
        if (operation) {
          ItemDataValue[operation] = {
            checked: true,
            unitStatusId: item.unitStatusId,
            unitOperationTypeId: item.unitOperationTypeId,
          };
        }
      });
      setOpationTemplateBuilderState(ItemDataValue);
      setState({
        id: "systemTemplateUnitStatuses",
        value: response.systemTemplateUnitStatuses,
      });
    } else setResult([]);
    setIsLoading(false);
  }, []);

  const schema = Joi.object({
    templateName: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}template-name-is-required`),
        "string.empty": t(`${translationPath}template-name-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);
  const saveHandler = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    // if (schema.error) {
    //   showError(t('Shared:please-fix-all-errors'));
    //   return;
    // }
    setIsLoading(true);
    // eslint-disable-next-line prefer-const
    let DataOfsystemTemplateUnitStatuses = [];
    Object.values(Main).map(
      (item) =>
        item.checked === true &&
        DataOfsystemTemplateUnitStatuses.push({
          unitStatusId: item.unitStatusId,
          unitOperationTypeId: item.unitOperationTypeId,
        })
    );
    const res =
      (activeItem &&
        (await UpdateTemplate(activeItem.systemTemplateId, {
          templateName: state.templateName,
          templateBody: state.templateBody,
          templateKeys: JSON.stringify(state.templateKeys),
          templateParams:
            JSON.stringify(Params) || JSON.stringify(state.templateParams),
          unitTypesIds: state.unitTypesIds,
          templateTypeId: state.templateTypeId,
          templateCategoryId: state.templateCategoryId,
          emailSubject: state.emailSubject,
          systemTemplateFolderId: state.systemTemplateFolderId,
          templateExtraCategoryIds: ExtraCategoryIds,
          systemTemplateUnitStatuses:
            state.templateCategoryId === 2
              ? []
              : DataOfsystemTemplateUnitStatuses || [],
          templateEmailBody: (state && state.templateEmailBody) || "",
          templateEmailKeys:
            JSON.stringify(state && state.templateEmailKeys) || "",
          isProposal: isUnitProposal || false,
          masterDeveloperId: state.masterDeveloperId || null,
          propertyId: (state && state.propertyId) || null,
          saleTypesIds:           selectedSaleType &&
          selectedSaleType &&
          selectedSaleType.map((x) => x.lookupItemId)|| state && state.saleTypesIds ||  [],
        }))) ||
      (await CreateSystemTemplateAPI({
        templateName: state.templateName,
        templateBody: state.templateBody,
        unitTypesIds: state.unitTypesIds,
        templateTypeId: state.templateTypeId,
        emailSubject: state.emailSubject,
        systemTemplateFolderId: state.systemTemplateFolderId,
        templateCategoryId: state.templateCategoryId,
        templateKeys: JSON.stringify(state.templateKeys),
        templateParams:
          JSON.stringify(Params) || JSON.stringify(state.templateParams),
        templateExtraCategoryIds: ExtraCategoryIds,
        templateEmailBody: (state && state.templateEmailBody) || "",
        templateEmailKeys:
          JSON.stringify(state && state.templateEmailKeys) || "",
        systemTemplateUnitStatuses:
          state.templateCategoryId === 2
            ? []
            : DataOfsystemTemplateUnitStatuses,
        isProposal: isUnitProposal || false,
        masterDeveloperId: state.masterDeveloperId || null,
        propertyId: state.propertyId || null,
        saleTypesIds: (state && state.saleTypesIds) || [],
      }));
    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) {
      if (activeItem)
        showSuccess(t(`${translationPath}template-updated-successfully`));
      else showSuccess(t(`${translationPath}template-created-successfully`));
      onSave();
    } else if (activeItem)
      showError(t(`${translationPath}template-update-failed`));
    else showError(t(`${translationPath}template-create-failed`));
  };
  const getlookupTypeItems = useCallback(async () => {
    // setLoading(true);
    const res = await GetlookupTypeItems({
      pageIndex: 0,
      pageSize: 50,
      lookupTypeId: 28,
    });
    if (!(res && res.status && res.status !== 200)) {
      setAutocompleteData((res && res.result) || []);

      if (activeItem && activeItem.unitTypesIds) {
        const data = [];
        activeItem &&
          activeItem.unitTypesIds.map((elment) => {
            const districtIndex =
              res &&
              res.result.findIndex((item) => item.lookupItemId === elment);
            if (districtIndex !== -1) {
              data.push(res && res.result[districtIndex]);
              setSelected(data);
            }
          });
      }
    } else setAutocompleteData([]);
    // setLoading(false);
  }, []);

  const GetAllSystemTemplateFoldersAPI = useCallback(async () => {
    // setLoading(true);
    const res = await GetAllSystemTemplateFolders(1, 50);
    if (!(res && res.status && res.status !== 200))
      setFolderData((res && res.result) || []);
    else setFolderData([]);
    // setLoading(false);
  }, []);

  useEffect(() => {
    GetAllSystemTemplateFoldersAPI();
  }, []);

  useEffect(() => {
    if (activeItem)
      setParams(JSON.parse((activeItem && activeItem.templateParams) || []));

    setselectedtemplateFolderId({
      systemTemplateFolderName: GetParams("systemTemplateFolderName") || null,
      systemTemplateFolderId: +GetParams("id") || null,
    });
    setState({
      id: "systemTemplateFolderId",
      value: +GetParams("id") || null || "",
    });
  }, [activeItem]);

  const getIsSelectedCard = useCallback(
    (itemGO) =>
      ExtraCategoryIds.findIndex(
        (item) => item === itemGO.systemTemplateCategoryId
      ) !== -1,
    [ExtraCategoryIds]
  );

  const onSelectAllHandler = (item) => {
    const Mainindex = ExtraCategoryIds.findIndex(
      (element) => item.systemTemplateCategoryId === element
    );
    if (Mainindex !== -1) {
      setData((items) => {
        const index = Data.findIndex(
          (data) =>
            data.systemReportCategoryTypeId === item.systemTemplateCategoryId
        );
        if (index !== -1) items.splice(index, 1);
        return [...items];
      });
    } else {
      item.systemTemplateFormDtos.map((itemssss) =>
        setData((items) => {
          items.push({
            systemReportFormId: 4,
            systemReportCategoryTypeId: item.systemTemplateCategoryId,
            formId: itemssss.formId,
            tableName: itemssss.formName,
            form: itemssss.formName,
          });
          return [...items];
        })
      );
    }

    setExtraCategoryIds((items) => {
      const index = items.findIndex(
        (element) => item.systemTemplateCategoryId === element
      );
      if (index !== -1) items.splice(index, 1);
      else items.push(item.systemTemplateCategoryId);
      return [...items];
    });

    setParams((items) => {
      const index = items.findIndex(
        (element) => item.mainParameter === element
      );
      if (index !== -1) items.splice(index, 1);
      else items.push(item.mainParameter);
      return [...items];
    });
  };

  const ParamsHandler = (item) => {
    setParams((items) => {
      if (item.mainParameter === "UnitId") {
        const index = items.findIndex((element) => element === "ActivityId");
        items.splice(index, 1);
        items.push(item.mainParameter);
      } else if (item.mainParameter === "ActivityId") {
        const index = items.findIndex((element) => element === "UnitId");
        items.splice(index, 1);
        items.push(item.mainParameter);
      }

      return [...items];
    });
  };
  useEffect(() => {
    if (IsUserTemplte) {
      setParams([]);
      setData([]);
      const selectedObject = Currenlookup.find(
        (item) => item.lookupItemName === "Text"
      );
      const specificUserObject = resonse.find(
        (item) => item.templateCategoryName === "With Specific User"
      );
      setState({
        id: "templateTypeId",
        value: (selectedObject && +selectedObject.lookupItemId) || "",
      });
      setResonse([specificUserObject]);
      setState({
        id: "templateCategoryId",
        value: specificUserObject.systemTemplateCategoryId,
      });
      setselectedtemplateTypeId(selectedObject);
      SetShowExtraCheckboxes(true);
    } else getlTemplateCategory();
  }, [IsUserTemplte]);

  useEffect(() => {
    if (activeItem) GetTamplateDetailsByIdAPI(activeItem.systemTemplateId);
  }, [activeItem]);

  useEffect(() => {
    if (activeItem && ExtraCategoryIds.length > 0) SetShowExtraCheckboxes(true);
  }, [activeItem, ExtraCategoryIds]);

  useEffect(() => {
    getlookupTypeItems();
    lookupGetCurrencies();
  }, []);

  useEffect(() => {
    getlTemplateCategory();
  }, []);

  useEffect(() => {
    if (activeItem && resonse.length > 0) {
      setState({
        id: "edit",
        value: {
          templateTypeId: activeItem.templateTypeId || null,
          templateName: activeItem.templateName || null,
          templateBody: (activeItem && activeItem.templateBody) || null,
          emailSubject: (activeItem && activeItem.emailSubject) || "",
          templateCategoryId:
            activeItem.systemTemplateCategoryDto.systemTemplateCategoryId ||
            null,
          templateKeys: JSON.parse(activeItem.templateKeys),
          templateParams: activeItem.templateParams || null,
          templateExtraCategoryIds: activeItem.templateExtraCategoryIds || null,
          systemTemplateUnitStatuses: result.systemTemplateUnitStatuses || null,
          templateEmailBody:
            (activeItem && activeItem.templateEmailBody) ||
            (result && result.templateEmailBody) ||
            "",
          templateEmailKeys:
            (activeItem &&
              activeItem.templateEmailKeys &&
              JSON.parse(activeItem.templateEmailKeys)) ||
            (result &&
              result.templateEmailKeys &&
              JSON.parse(result.templateEmailKeys)) ||
            "",
          systemTemplateFolderId:
            +GetParams("id") || activeItem.systemTemplateFolderId || null,
          isProposal: (result && result.isProposal) || false,
          masterDeveloperId: (result && result.masterDeveloperId) || null,
          propertyId: (result && result.propertyId) || null,
          unitTypesIds:activeItem &&  activeItem.unitTypesIds || [],
        },
      });

      const hasOnlyValue6 = [activeItem].every(
        (item) =>
          Array.isArray(item.templateExtraCategoryId) &&
          item.templateExtraCategoryId.length === 1 &&
          item.templateExtraCategoryId[0] === 6
      );
      if (hasOnlyValue6) {
        setIsUserTemplte(true);
        const selectedObject = Currenlookup.find(
          (item) => item.lookupItemName === "Text"
        );
        setState({
          id: "templateTypeId",
          value: (selectedObject && +selectedObject.lookupItemId) || "",
        });
        setselectedtemplateTypeId(selectedObject);
        SetShowExtraCheckboxes(true);
      }

      if (activeItem.unitTypesIds && activeItem.unitTypesIds[0] === 0) {
        setSelected([]);
        setCheckAllUnitTypes(true);
      }

      setselectedtemplateTypeId({
        lookupItemId:
          activeItem &&
          activeItem.templateTypeLookupDto &&
          activeItem.templateTypeLookupDto.templateTypeId,
        lookupItemName:
          activeItem &&
          activeItem.templateTypeLookupDto &&
          activeItem.templateTypeLookupDto.templateTypeName,
      });
      setSystemTemplateCategoryDto(
        (activeItem && activeItem.systemTemplateCategoryDto) || {}
      );
      setExtraCategoryIds(
        (activeItem && activeItem.templateExtraCategoryId) || []
      );

      const fData = activeItem && activeItem.templateExtraCategoryId;
      if (fData !== null && hasOnlyValue6 === false) {
        const resultrrr =
          resonse &&
          resonse.filter(
            (obj, index, self) =>
              fData.includes(obj.systemTemplateCategoryId) &&
              index ===
                self.findIndex(
                  (innerObj) =>
                    innerObj.systemTemplateCategoryId ===
                    obj.systemTemplateCategoryId
                )
          );
        resultrrr &&
          resultrrr.forEach((MainlEment) => {
            MainlEment.systemTemplateFormDtos.forEach((element) => {
              setData((items) => {
                const exists = items.some(
                  (item) =>
                    item.formId === element.formId &&
                    item.systemReportCategoryTypeId ===
                      MainlEment.systemTemplateCategoryId
                );
                if (!exists) {
                  items.push({
                    systemReportFormId: 4,
                    systemReportCategoryTypeId:
                      MainlEment.systemTemplateCategoryId,
                    formId: element.formId,
                    tableName: element.formName,
                    form: element.formName,
                  });
                }

                return [...items];
              });
            });
          });
      }
    }
  }, [activeItem, resonse]);

  const getLookupItems = async () => {
    const result = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.UnitSaleType,
      lookupParentId: null,
    });
    if (!(result?.status && result.status !== 200)) {
      setUnitSaleTypeAutoCompleteData(result);
    }
  };
  useEffect(() => {
    getLookupItems();
  }, []);

  return (
    <DialogComponent
      titleText={(activeItem && "edit-template") || "add-template"}
      saveText={(activeItem && "edit-template") || "add-template"}
      maxWidth="xl"
      SmothMove
      disableEnforceFocus
      dialogContent={
        <div className="w-100">
          <Spinner isActive={isLoading} isAbsolute />
          <div className="d-flex">
            <div
              className="dialog-item-one-wrper"
              onDrop={(e) => {
                e.preventDefault();
                return false;
              }}
            >
              <div className="dialog-item-one">
                <Inputs
                  idRef="templateNameRef"
                  labelValue="template-title"
                  value={state.templateName || ""}
                  helperText={getErrorByName(schema, "templateName").message}
                  error={getErrorByName(schema, "templateName").error}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(event) => {
                    setState({ id: "templateName", value: event.target.value });
                  }}
                />{" "}
              </div>{" "}
              <div className="dialog-item-one">
                <AutocompleteComponent
                  inputPlaceholder="select-template-Type"
                  idRef="template-Type"
                  labelValue="template-Type"
                  selectedValues={selectedtemplateTypeId || []}
                  isDisabled={IsUserTemplte}
                  multiple={false}
                  data={Currenlookup || []}
                  displayLabel={(option) => option.lookupItemName || ""}
                  getOptionSelected={(option) =>
                    option.lookupItemId === selectedtemplateTypeId.lookupItemId
                  }
                  withoutSearchButton
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setselectedtemplateTypeId({
                        lookupItemName:
                          (newValue && newValue.lookupItemName) || null,
                        lookupItemId:
                          (newValue && newValue.lookupItemId) || null,
                      });
                    } else setselectedtemplateTypeId([]);

                    setState({
                      id: "templateTypeId",
                      value: (newValue && +newValue.lookupItemId) || "",
                    });
                  }}
                />
              </div>
              <div className="dialog-item-one">
                <AutocompleteComponent
                  inputPlaceholder="select-folder"
                  idRef="folder-Type"
                  labelValue="folder"
                  selectedValues={selectedtemplateFolderId || []}
                  multiple={false}
                  data={FolderData || []}
                  displayLabel={(option) =>
                    option.systemTemplateFolderName || ""
                  }
                  getOptionSelected={(option) =>
                    option.systemTemplateFolderId ===
                    selectedtemplateFolderId.systemTemplateFolderId
                  }
                  withoutSearchButton
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setselectedtemplateFolderId({
                        systemTemplateFolderName:
                          (newValue && newValue.systemTemplateFolderName) ||
                          null,
                        systemTemplateFolderId:
                          (newValue && newValue.systemTemplateFolderId) || null,
                      });
                    } else setselectedtemplateFolderId([]);

                    setState({
                      id: "systemTemplateFolderId",
                      value:
                        (newValue && +newValue.systemTemplateFolderId) || "",
                    });
                  }}
                />{" "}
              </div>
              <div className="UnitType-AutocompleteComponent-Templet-Bulider dialog-item-one mb-2">
                {Params && Params.find((option) => option === "UnitId") && (
                  <AutocompleteComponent
                    idRef="UnitTypes-ToRef"
                    labelValue="UnitTypes"
                    selectedValues={selected || []}
                    multiple
                    data={(AutocompleteData && AutocompleteData) || []}
                    chipsLabel={(option) => option.lookupItemName || ""}
                    isDisabled={CheckAllUnitTypes}
                    displayLabel={(option) =>
                      t(`${option.lookupItemName || ""}`)
                    }
                    getOptionSelected={(option) =>
                      (selected &&
                        selected.findIndex(
                          (item) =>
                            item && item.lookupItemId === option.lookupItemId
                        ) !== -1) ||
                      ""
                    }
                    withoutSearchButton
                    inputPlaceholder={t(
                      `${translationPath}Select-multiple-UnitTypes`
                    )}
                    isLoading={loadings}
                    inputEndAdornment={
                      <CheckboxesComponent
                        idRef="CheckboxesComponent"
                        label="all"
                        singleChecked={CheckAllUnitTypes}
                        onSelectedCheckboxChanged={(event) => {
                          setCheckAllUnitTypes(event.target.checked);
                          setSelected([]);
                          if (event.target.checked === false)
                            setState({ id: "unitTypesIds", value: [] });
                          else setState({ id: "unitTypesIds", value: [0] });
                        }}
                        parentTranslationPath={parentTranslationPath}
                        translationPathForData={translationPath}
                      />
                    }
                    isWithError
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                      setState({
                        id: "unitTypesIds",
                        value: newValue && newValue.map((x) => x.lookupItemId),
                      });
                      setSelected(
                        newValue &&
                          newValue.map((x) => ({
                            lookupItemId: x.lookupItemId,
                            lookupItemName: x.lookupItemName,
                          }))
                      );
                    }}
                  />
                )}
              </div>
              {Params && Params.find((option) => option === "UnitId") && (
                <div className="dialog-item-one">
                  <AutocompleteComponent
                    inputPlaceholder="select-masterDeveloper"
                    idRef="masterDeveloper"
                    isLoading={isLoadingmasterDeveloper}
                    labelValue="masterDeveloper"
                    multiple={false}
                    data={DataUser || []}
                    chipsLabel={(option) =>
                      (option.contact &&
                        (option.contact.first_name ||
                          option.contact.last_name) &&
                        `${option.contact.first_name} ${option.contact.last_name}`) ||
                      ""
                    }
                    displayLabel={(option) =>
                      (option.contact &&
                        (option.contact.first_name ||
                          option.contact.last_name) &&
                        `${option.contact.first_name} ${option.contact.last_name}`) ||
                      ""
                    }
                    onInputKeyUp={(event) => {
                      const { value } = event.target;
                      if (searchTimer.current)
                        clearTimeout(searchTimer.current);
                      searchTimer.current = setTimeout(() => {
                        getAllUsers(value);
                      }, 700);
                    }}
                    selectedValues={DataUserSelected || []}
                    getOptionSelected={(option) =>
                      (option.contactsId === DataUserSelected &&
                        DataUserSelected.contactsId) ||
                      ""
                    }
                    withoutSearchButton
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                      if (newValue) setDataUserSelected(newValue || "");
                      else setDataUserSelected([]);
                      setState({
                        id: "masterDeveloperId",
                        value: (newValue && +newValue.contactsId) || "",
                      });
                    }}
                  />
                </div>
              )}
              {Params && Params.find((option) => option === "UnitId") && (
                <div className="dialog-item-one">
                  <AutocompleteComponent
                    inputPlaceholder="select-property"
                    idRef="property-Type"
                    labelValue="property"
                    selectedValues={propertySelected || []}
                    multiple={false}
                    data={PropertyData || []}
                    displayLabel={(option) => option.propertyName || ""}
                    getOptionSelected={(option) =>
                      option.propertyId === propertySelected &&
                      propertySelected.propertyId
                    }
                    withoutSearchButton
                    onInputChange={(e) => {
                      if (e && e.target && e.target.value)
                        GetAllProperties(e.target.value || "");
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                      if (newValue) setpropertySelected(newValue);
                      else setpropertySelected([]);

                      setState({
                        id: "propertyId",
                        value: (newValue && +newValue.propertyId) || "",
                      });
                    }}
                  />{" "}
                </div>
              )}
              <div className="dialog-item-one top-lab">
                {Params && Params.find((option) => option === "UnitId") && (
                  <AutocompleteComponent
                    idRef="unit-sale-type-ToRef"
                    labelValue="unit-sale-type"
                    selectedValues={selectedSaleType || []}
                    multiple
                    data={
                      (UnitSaleTypeAutoCompleteData &&
                        UnitSaleTypeAutoCompleteData) ||
                      []
                    }
                    chipsLabel={(option) => option.lookupItemName || ""}
                    displayLabel={(option) =>
                      t(`${option.lookupItemName || ""}`)
                    }
                    getOptionSelected={(option) =>
                      (selectedSaleType &&
                        selectedSaleType.findIndex(
                          (item) =>
                            item && item.lookupItemId === option.lookupItemId
                        ) !== -1) ||
                      ""
                    }
                    withoutSearchButton
                    inputPlaceholder={t(
                      `${translationPath}Select-multiple-UnitSaleTypes`
                    )}
                    isLoading={loadings}
                    isWithError
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                      setState({
                        id: "saleTypesIds",
                        value: newValue && newValue.map((x) => x.lookupItemId),
                      });
                      setselectedSaleType(
                        newValue &&
                          newValue.map((x) => ({
                            lookupItemId: x.lookupItemId,
                            lookupItemName: x.lookupItemName,
                          }))
                      );
                    }}
                  />
                )}
              </div>
              <div className="d-inline-flex-center">
                {Params && Params.find((option) => option === "UnitId") && (
                  <CheckboxesComponent
                    idRef="qwxesComponent"
                    label="isUnitProposal"
                    singleChecked={isUnitProposal}
                    onSelectedCheckboxChanged={(event) => {
                      setisUnitProposal(event.target.checked);
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPathForData={translationPath}
                  />
                )}
              </div>
            </div>
            <div className="bbt-templates">
              {" "}
              <ButtonBase
                className="btns theme-solid"
                onClick={() => {
                  SetTemplatesBodyManagementDialogOpen(true);
                }}
              >
                <span>{t(`${translationPath}Template-body`)}</span>
              </ButtonBase>
            </div>
          </div>
          <div className="d-flex">
            <div
              className="w-100"
              onDrop={(e) => {
                e.preventDefault();
                return false;
              }}
            >
              <OpationTemplateBuilder
                setIsUserTemplte={setIsUserTemplte}
                IsUserTemplte={IsUserTemplte}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                resonse={resonse}
                activeItem={state}
                OpationState={(activeItem && "edit" && "edit") || "add"}
                activeItemedit={activeItem || false}
                ParamsHandler={ParamsHandler}
                Params={Params}
                OpationTemplateBuilderState={OpationTemplateBuilderState}
                setMain={setMain}
                SetsystemTemplateCategoryId={(item) => {
                  setState({
                    id: "templateCategoryId",
                    value: item.systemTemplateCategoryId,
                  });
                }}
                systemTemplateCategoryDto={systemTemplateCategoryDto}
                SetOpationTemplate={(item, acct) => {
                  const id = item && item.systemTemplateCategoryId;
                  if (acct === "add") {
                    item.systemTemplateFormDtos.map((TheData) =>
                      setData((items) => {
                        items.push({
                          systemReportFormId: 4,
                          systemReportCategoryTypeId: id,
                          categoryType: item.categoryType,
                          formId: TheData.formId,
                          tableName: TheData.formName,
                          form: TheData.formName,
                        });
                        return [...items];
                      })
                    );
                  } else {
                    setData((items) => {
                      const filteredItems = items.filter(
                        (data) => data.categoryType !== "Main"
                      );
                      return filteredItems;
                    });
                  }
                }}
              />
            </div>
            <div
              style={
                !ShowExtraCheckboxes
                  ? {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }
                  : {}
              }
            >
              <div className="ShowExtraCheckboxes-fields-container">
                <ButtonBase
                  className="btns theme-solid"
                  onClick={() =>
                    ShowExtraCheckboxes === false
                      ? SetShowExtraCheckboxes(true)
                      : SetShowExtraCheckboxes(false)
                  }
                >
                  <span>
                    {ShowExtraCheckboxes === true
                      ? t(`${translationPath}Hide-Specific-opaton`)
                      : t(`${translationPath}Show-Specific-opaton`)}
                  </span>
                </ButtonBase>
              </div>
              {ShowExtraCheckboxes && (
                <div
                  className="ExtraCheckboxes"
                  onDrop={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                >
                  {resonse.map(
                    (item, itemIndex) =>
                      (item.categoryType === "Extra" && (
                        <CheckboxesComponent
                          idRef="RotExtraonRef"
                          labelValue={item.templateCategoryName || ""}
                          themeClass="theme-secondary"
                          onSelectedCheckboxClicked={() =>
                            onSelectAllHandler(item, itemIndex)
                          }
                          singleChecked={getIsSelectedCard(item)}
                        />
                      )) ||
                      ""
                  )}
                </div>
              )}{" "}
            </div>
          </div>
          <div
            className="dialog-item"
            onDrop={(e) => {
              e.preventDefault();
              return false;
            }}
          />
          {selectedtemplateTypeId &&
            selectedtemplateTypeId.lookupItemName === "Text" && (
              <SMSTemplateComponent
                state={state}
                schema={schema}
                Data={Data}
                isSubmitted={isSubmitted}
                activeItem={activeItem}
                onStateChanged={onStateChanged}
                onContentChanged={onContentChanged}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            )}
          {selectedtemplateTypeId &&
            selectedtemplateTypeId.lookupItemName === "HTML" && (
              <TemplatebuilderComponent
                state={state}
                activeItem={activeItem}
                schema={schema}
                Data={Data}
                isSubmitted={isSubmitted}
                onStateChanged={onStateChanged}
                onContentChanged={onContentChanged}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            )}
          {TemplatesBodyManagementDialogOpen &&
            TemplatesBodyManagementDialogOpen && (
              <TemplatesBodyManagementDialog
                activeItem={activeItem}
                state={state}
                setState={setState}
                onTemplateEmailBodyChanged={onTemplateEmailBodyChanged}
                onTemplateEmailKeysChanged={onTemplateEmailKeysChanged}
                resonseData={resonse}
                isOpen={TemplatesBodyManagementDialogOpen}
                onSave={() => {
                  // setFilter((item) => ({ ...item, pageIndex: 0 }));
                  // setIsOpenManagementDialog(false);
                  // setActiveItem(null);
                }}
                isOpenChanged={() => {
                  SetTemplatesBodyManagementDialogOpen(false);
                  //   setActiveItem(null);
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            )}
        </div>
      }
      isOpen={isOpen}
      onSubmit={saveHandler}
      onCloseClicked={isOpenChanged}
      onCancelClicked={isOpenChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};
