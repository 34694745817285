import React, {
  useState,
  useCallback,
  useReducer,
  useEffect,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { ButtonBase, DialogActions } from "@material-ui/core";
import { DialogComponent, Inputs, Spinner } from "../../../../Components";
import {
  AddSavedShareUnitsToCriteria,
  CreateSavedShareUnitCriteriaApi,
  DeleteSavedShareUnitCriteria,
  ListSavedShareUnitCriteria,
} from "../../../../Services";
import { showError, showSuccess } from "../../../../Helper";

export const FavouriteShareUnitsDialog = ({
  isOpen,
  closeHandler,
  parentTranslationPath,
  translationPath,
  setSavedShareUnitCriteriaIdValue,
  GetAllUnitsSearch,
  setFiltersavedShareUnitCriteriaId
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [state, setState] = useReducer(reducer, {
    systemUsersId: [],
    contactsId: [],
  });
  const [activeItem, setActiveItem] = useState(null);

  const [CreateSavedShareUnitCriteria, setCreateSavedShareUnitCriteria] =
    useReducer(reducer, {
      name: "",
      tags: "",
    });

  const [selected, setSelected] = useReducer(reducer, {
    systemUsersId: [],
    contactsId: [],
  });

  const [isLoading, setIsLoading] = useReducer(reducer, {
    sending: false,
    users: false,
    contacts: false,
    Create: false,
  });

  const [data, setData] = useReducer(reducer, {
    ListSavedShareUnitCriteria: [],
    contacts: [],
  });

  const getUsersWithMissingEmails = () => {
    const usersList = selected.systemUsersId
      ? selected.systemUsersId.filter((item) => !item.email)
      : [];

    if (usersList.length != 0) {
      const userNamesList = usersList.map((item) => item.fullName);
      const userNamesString = userNamesList.reduce(
        (result, item) => result + item + " , ",
        ""
      );
      return userNamesString;
    }
  };

  const getListSavedShareUnitCriteria = useCallback(async (searchValue) => {
    setIsLoading({ id: "users", value: true });
    const res = await ListSavedShareUnitCriteria({
      search: searchValue || "",
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res) || [];
      if (localValue) {
        setData({
          id: "ListSavedShareUnitCriteria",
          value: localValue,
        });
      } else {
        setData({
          id: "ListSavedShareUnitCriteria",
          value: [],
        });
      }

      setIsLoading({ id: "users", value: false });
    }
  }, []);

  const AddSavedShareUnitsToCriteriaHandler = useCallback(
    async (shareUnitsIds, savedShareUnitCriteriaId) => {
      setIsLoading({ id: "Create", value: true });
      const body = {
        savedShareUnitCriteriaId: savedShareUnitCriteriaId,
        shareUnitsIds: shareUnitsIds.map((item) => item.shareUnitId),
      };
      const res = await AddSavedShareUnitsToCriteria(body);
      if (!(res && res.status && res.status !== 200)) {
        showSuccess(t("Create-sent-successfully"));
        closeHandler();
      } else {
        showError(t("Create-failed"));
      }

      setIsLoading({ id: "Create", value: false });
    },
    []
  );

  const CreateSavedShareUnitCriteriaService = useCallback(
    async (CreateSavedShareUnitCriteria) => {
      setIsLoading({ id: "sending", value: true });
      const body = {
        body: state.systemUsersId,
      };
      const res = await CreateSavedShareUnitCriteriaApi(
        CreateSavedShareUnitCriteria
      );
      if (!(res && res.status && res.status !== 200)) {
        showSuccess(t("Create-successfully"));
        getListSavedShareUnitCriteria();
        setCreateSavedShareUnitCriteria({ id: "name", value: "" });
        setCreateSavedShareUnitCriteria({ id: "tags", value: "" });
      } else {
        showError(t("Create-failed"));
      }

      setIsLoading({ id: "sending", value: false });
    },
    []
  );

  const deleteHandler = async () => {
    const Id = activeItem?.savedShareUnitCriteriaId;
    const res = await DeleteSavedShareUnitCriteria(Id);
    if (!(res && res.status && res.status !== 200)) {
      getListSavedShareUnitCriteria();
      showSuccess(t(`${translationPath}config-deleted-successfully`));
    } else showError(t(`${translationPath}config-delete-failed`));
    setIsDeleteDialogOpen(false);
  };
  useEffect(() => {
    getListSavedShareUnitCriteria();
  }, []);
  return (
    <DialogComponent
      titleText="List-of-Favourite"
      saveText="send"
      maxWidth="sm"
      dialogContent={
        <div className="share-template-dialog">
          <Spinner isActive={isLoading.sending} isAbsolute />
          <div>
            {" "}
            <div className="d-flex w-100 mr-1-reversed">
              <Inputs
                idRef="leadIdFilterRef"
                fieldClasses="inputs theme-solid"
                parentTranslationPath={parentTranslationPath}
                value={CreateSavedShareUnitCriteria.name}
                onInputChanged={(event) => {
                  const { value } = event.target;
                  setCreateSavedShareUnitCriteria({ id: "name", value });
                }}
                inputPlaceholder={t(`${translationPath}name`)}
              />
              <div className="m-2"></div>
              <Inputs
                idRef="leadIdFilterRef"
                fieldClasses="inputs theme-solid"
                parentTranslationPath={parentTranslationPath}
                value={CreateSavedShareUnitCriteria.tags}
                onInputChanged={(event) => {
                  const { value } = event.target;
                  setCreateSavedShareUnitCriteria({ id: "tags", value });
                }}
                inputPlaceholder={t(`${translationPath}tags`)}
              />
              <div className="m-2">
                <ButtonBase
                  className="MuiButtonBase-root btns theme-solid "
                  disabled={
                    CreateSavedShareUnitCriteria.tags === "" ||
                    CreateSavedShareUnitCriteria.name === ""
                  }
                  onClick={() =>
                    CreateSavedShareUnitCriteriaService(
                      CreateSavedShareUnitCriteria
                    )
                  }
                >
                  {t(`${translationPath}save`)}
                  <span className="mdi mdi-content-save-all ml-2"></span>
                </ButtonBase>
              </div>
            </div>
            <div class="data-container Favourite">
              {data &&
                data.ListSavedShareUnitCriteria.map((item) => (
                  <div class="data-item vara d-inline-flex flex-h-between w-100">
                    <span className="heart">
                      <span className="heart mdi mdi-heart"></span>
                    </span>
                    <div class="w-50">
                      {" "}
                      <strong>
                        {" "}
                        {t(`${translationPath}name`)} &nbsp;:&nbsp;
                      </strong>
                      &nbsp;{item?.name || "N/A"}
                    </div>
                    <div class="w-50">
                      {" "}
                      <strong>
                        {t(`${translationPath}tags`)}&nbsp;:&nbsp;
                      </strong>
                      &nbsp;{item?.tags || "N/A"}{" "}
                    </div>
                    <div
                      class="table-actions-wrapper "
                      id="table-actions-wrapper "
                    >
                      {
                        <button
                          class="MuiButtonBase-root MuiButton-root MuiButton-text table-action-btn btns theme-transparent miw-0"
                          type="button"
                          onClick={() => {
                            setSavedShareUnitCriteriaIdValue(
                              item && item.savedShareUnitCriteriaId
                            );
                            GetAllUnitsSearch(
                              item && item.savedShareUnitCriteriaId
                            );
                            setFiltersavedShareUnitCriteriaId(item && item.savedShareUnitCriteriaId||{})
                            closeHandler();
                            // AddSavedShareUnitsToCriteriaHandler(//   checkedDetailed,// )
                          }}
                        >
                          <span class="MuiButton-label">
                            <span class="table-action-icon mdi mdi-eye-outline c-white"></span>
                          </span>
                          <span class="MuiTouchRipple-root"></span>
                        </button>
                      }
                      {/* <button
                        class="MuiButtonBase-root MuiButton-root MuiButton-text table-action-btn btns theme-transparent miw-0"
                        tabindex="0"
                        type="button"
                        id="ActioneditText_null_/home/Reports-Builder/view"
                      >
                        <span class="MuiButton-label">
                          <span class="table-action-icon mdi mdi-lead-pencil c-white"></span>
                        </span>
                        <span class="MuiTouchRipple-root"></span>
                      </button> */}
                      <button
                        class="MuiButtonBase-root MuiButton-root MuiButton-text table-action-btn btns-icon theme-solid bg-danger"
                        type="button"
                        onClick={() => {
                          setIsDeleteDialogOpen(true);
                          setActiveItem(item);
                        }}
                        id="Actiondelete_null_/home/Reports-Builder/view"
                      >
                        <span class="MuiButton-label">
                          <span class="table-action-icon mdi mdi-trash-can"></span>
                        </span>
                        <span class="MuiTouchRipple-root"></span>
                      </button>
                    </div>
                  </div>
                ))}
            </div>
            <DialogComponent
              titleText="confirm-message"
              saveText="confirm"
              saveType="button"
              maxWidth="sm"
              dialogContent={
                <div className="d-flex-column-center">
                  <span className="mdi mdi-close-octagon c-danger mdi-48px" />
                  <span>{`${t(
                    `${translationPath}config-delete-description`
                  )} "${activeItem?.name}"?`}</span>
                </div>
              }
              saveClasses="btns theme-solid bg-danger w-100 mx-2 mb-2"
              isOpen={isDeleteDialogOpen}
              onCancelClicked={() => setIsDeleteDialogOpen(false)}
              onCloseClicked={() => setIsDeleteDialogOpen(false)}
              onSaveClicked={deleteHandler}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          </div>
          <DialogActions>
            <div className="unit-template-action-btns">
              <ButtonBase
                className="MuiButtonBase-root btns bg-cancel"
                onClick={() => closeHandler()}
              >
                {t(`${translationPath}cancel`)}
              </ButtonBase>
            </div>
          </DialogActions>
        </div>
      }
      isOpen={isOpen}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};
