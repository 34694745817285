export const ClassificationsContactEnum = [
    {
        Id: 20712,
        name: 'Real Estate - Developer'
    },
    {
        Id: 20713,
        name: 'Real Estate - Broker'

    },
    {
        Id: 20714,
        name: 'Real Estate - Agent'

    },
    {
        Id: 20715,
        name: 'Project Manager'
    },
    {
        Id: 20716,
        name: 'Site Manager'
    },
    {
        Id: 20717,
        name: 'Site Engineer'
    },
    {
        Id: 20718,
        name: 'Elctro Mechanical'
    },
    {
        Id: 20719,
        name: 'Contractor'

    },
    {
        Id: 20720,
        name: 'Consultant'
    },
    {
        Id: 20721,
        name: 'Architect'
    },
    {

        Id: 20722,
        name: 'Design Consultant'

    },
    {
        Id: 20723,
        name: 'Land Scaping'
    },
    {

        Id: 20724,
        name: 'Maintenance company'
    }
];
