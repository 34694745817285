
import {
    getUnits, GetAllUnitsForLeadOwner
  } from '../../../../../Services';
  
  export async function UnitRule(item, value, setItems , setIsLoading , isOwnerLead ) {
    if (!item.data.searchKey) return;
    if (item.data.searchKey !== 'unit') return;
    setIsLoading(true) ; 
    
    let  rs = null ; 
     if(isOwnerLead)
     rs = await GetAllUnitsForLeadOwner({ pageIndex: 0, pageSize: 10, search:value});
  
    else {
     rs =  await getUnits({ pageIndex: 0, pageSize: 10 , search : value }) ; 
  }
  
  
    item.data.enum = [];
    if(!rs) return ; 
    if (!rs && !rs.result) return;
    rs && rs.result &&  rs.result.map((valueLoop) => {
      item.data.enum.push({
        id: valueLoop.unitId,
        name: (valueLoop && valueLoop.unit && valueLoop.unit.unit_ref_no) || ( valueLoop && valueLoop.unitRefNo)  ,
        type: (valueLoop &&  valueLoop.unit && valueLoop.unit.unit_type_id) || 1 , 
        operationType:(valueLoop && valueLoop.unit && valueLoop.unit.operation_type && valueLoop.unit.operation_type.lookupItemName)  ||  (valueLoop && valueLoop.operationType),
        propertyId: valueLoop && valueLoop.unit && valueLoop.unit.property_name && valueLoop.unit.property_name.id,
  
      });
    });
    setItems(item.data.enum) ; 
    setIsLoading(false) ; 
  }
  
  
  
  
  
  
 
  