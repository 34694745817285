import { GeneralConfiguration } from "./GeneralConfiguration/GeneralConfiguration";
import { NationalityConfiguration } from "./NationalityConfiguration/NationalityConfiguration";

export const RiskConfigFormsTabs = [
  {
    id: 1,
    label: "GeneralConfiguration",
    component: GeneralConfiguration,
  },
  {
    id: 2,
    label: "NationalityConfiguration",
    component: NationalityConfiguration,
  },
];
