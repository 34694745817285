import React, { useReducer, useState, useCallback, useEffect } from "react";

import Joi from "joi";
import { DialogComponent } from "../../DialogComponent/DialogComponent";
import { Spinner } from "../../SpinnerComponent/Spinner";
import { GetParams, showError, showSuccess } from "../../../Helper";
import { AutocompleteComponent, Inputs } from "../../Controls";
import { useTranslation } from "react-i18next";
import {
  GetLookupItemsByLookupTypeName,
  MyLeadUpdateLead,
} from "../../../Services";
import { LeadBathroomsEnum, LeadBedroomsEnum } from "../../../Enums";
import { Button, DialogActions, withStyles } from "@material-ui/core";

const QualifyButton = withStyles(() => ({
  root: {
    color: "white",
    backgroundColor: "#84693f",
    border: "2px solid #84693f",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#6d5734",
    },
  },
}))(Button);

const CancelButton = withStyles(() => ({
  root: {
    color: "#000000",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
}))(Button);

function QualifyLeadDialog({
  isOpen,
  onClose,
  setIsOpenQualifyLeadDialog,
  activityLeadId,
  setActivityQualifyData,
  reload,
  updateLeadStage,
}) {
  const id = GetParams("id");
  const parentTranslationPath = "LeadsStage";
  const translationPath = "";
  const budgetMaxValue = 60000000;
  const { t } = useTranslation("LeadsStage");
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const defaultState = {
    leadTypeId: null,
    purposeId: null,
    propertyUnitTypeIDs: [],
    propertyPlanId: null,
    unitUsageType: null,
    modeOfPaymentId: null,
    numberOfBathrooms: [],
    numberOfBedrooms: [],
    budgetFrom: 0,
    budgetTo: 0,
  };
  const defaultSelected = {
    leadType: null,
    purpose: null,
    propertyTypes: [],
    propertyPlan: null,
    unitUsageType: null,
    modeOfPayment: null,
    numberOfBathrooms: [],
    numberOfBedrooms: [],
    budgetFrom: 0,
    budgetTo: 0,
  };
  const defaultData = {
    leadType: [],
    purpose: [],
    propertyTypes: [],
    propertyPlans: [],
    unitUsageType: [],
    modeOfPayment: [],
  };
  const defaultLoadings = {
    leadType: false,
    purpose: false,
    propertyTypes: false,
    propertyPlans: false,
    unitUsageType: false,
    modeOfPayment: false,
  };
  const [state, setState] = useReducer(reducer, defaultState);
  const [selected, setSelected] = useReducer(reducer, defaultSelected);
  const [data, setData] = useReducer(reducer, defaultData);
  const [isLoading, setIsLoading] = useReducer(reducer, defaultLoadings);
  const [isSaving, setIsSaving] = useState(false);
  const [filter, setFilter] = useReducer(reducer, {
    pageSize: 100,
    pageIndex: 0,
  });

  const getLeadTypeLookups = useCallback(async () => {
    setIsLoading({ id: "leadType", value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "LeadOperationType",
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "leadType", value: res.result || [] });
    } else setData({ id: "leadType", value: [] });
    setIsLoading({ id: "leadType", value: false });
  }, []);

  const getPurposeLookups = useCallback(async () => {
    setIsLoading({ id: "purpose", value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "Purpose",
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "purpose", value: res.result || [] });
    } else setData({ id: "purpose", value: [] });
    setIsLoading({ id: "purpose", value: false });
  }, []);

  const getPropertyPlansLookups = useCallback(async () => {
    setIsLoading({ id: "propertyPlans", value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "Property Plan",
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "propertyPlans", value: res.result || [] });
    } else setData({ id: "propertyPlans", value: [] });
    setIsLoading({ id: "propertyPlans", value: false });
  }, []);

  const getModeOfPaymentLookups = useCallback(async () => {
    setIsLoading({ id: "modeOfPayment", value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "ModeOfPayment",
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "modeOfPayment", value: res.result || [] });
    } else setData({ id: "modeOfPayment", value: [] });
    setIsLoading({ id: "modeOfPayment", value: false });
  }, []);

  const getPropertyTypeLookups = useCallback(async () => {
    setIsLoading({ id: "propertyTypes", value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "UnitType",
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "propertyTypes", value: res.result || [] });
    } else setData({ id: "propertyTypes", value: [] });
    setIsLoading({ id: "propertyTypes", value: false });
  }, []);

  const getUnitUsageLookups = useCallback(async () => {
    setIsLoading({ id: "unitUsageType", value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "Property Usage",
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex,
    });

    if (!(res && res.status && res.status !== 200)) {
      const filteredData = res.result
        ? res.result.filter(
            (item) =>
              item.lookupItemName === "Commercial" ||
              item.lookupItemName === "Residential"
          )
        : [];
      setData({ id: "unitUsageType", value: filteredData });
    } else setData({ id: "unitUsageType", value: [] });
    setIsLoading({ id: "unitUsageType", value: false });
  }, []);

  const schema = Joi.object({
    leadTypeId: Joi.number()
      .required()
      .messages({
        "string.base": t(`${translationPath}leadTypeId`),
      }),
    propertyPlanId: Joi.number()
      .required()
      .messages({
        "string.base": t(`${translationPath}propertyPlanId`),
      }),
    purposeId: Joi.number()
      .required()
      .messages({
        "string.base": t(`${translationPath}purposeId`),
      }),
    modeOfPaymentId: Joi.number()
      .required()
      .messages({
        "string.base": t(`${translationPath}modeOfPaymentId`),
      }),
    unitUsageType: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}modeOfPaymentId`),
      }),
    budgetFrom: Joi.number()
      .custom((value, helpers) => {
        if (value < 0) return helpers.error("state.required");

        return value;
      })
      .messages({
        "state.required": t(`${translationPath}budgetFrom`),
      }),
    budgetTo: Joi.number()
      .custom((value, helpers) => {
        if (value > budgetMaxValue) return helpers.error("state.required");

        return value;
      })
      .messages({
        "state.required": t(`${translationPath}budgetTo`),
      }),
    numberOfBathrooms: Joi.array()
      .custom((value, helpers) => {
        if (value && value.length == 0) return helpers.error("state.required");

        return value;
      })
      .messages({
        "state.required": t(`${translationPath}numberOfBathrooms`),
      }),
    numberOfBedrooms: Joi.array()
      .custom((value, helpers) => {
        if (value && value.length == 0) return helpers.error("state.required");

        return value;
      })
      .messages({
        "state.required": t(`${translationPath}numberOfBedrooms`),
      }),
    propertyUnitTypeIDs: Joi.array()
      .custom((value, helpers) => {
        if (value && value.length == 0) return helpers.error("state.required");

        return value;
      })
      .messages({
        "state.required": t(`${translationPath}propertyUnitTypeIDs`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const budgetSchema = Joi.object({
    budgetFrom: Joi.number()
      .custom((value, helpers) => {
        if (value <= 0) return helpers.error("state.required");
        return value;
      })
      .messages({
        "state.required": t(
          `${translationPath}budgetFrom-should-be-greater-than-zero`
        ),
      }),
    budgetTo: Joi.number()
      .custom((value, helpers) => {
        if (value < state.budgetFrom)
          return helpers.error("state.greater-than-budgetFrom");
        return value;
      })
      .messages({
        "state.greater-than-budgetFrom": t(
          `${translationPath}budgetTo-should-be-greater-than-budgetfrom`
        ),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const updateLead = useCallback(async () => {
    setIsSaving(true);
    const body = {
      ...state,
    };
    const leadId = id;
    const res = await MyLeadUpdateLead({ id: leadId, body });

    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}lead-qualify-success`));
      onCancelClicked();
    } else showError(t(`${translationPath}lead-qualify-fail`));
    setIsSaving(false);
  }, [state, id]);

  const onCancelClicked = () => {
    onClose();
  };

  const onQualifyClicked = async () => {
    if (schema.error) {
      showError(t(`${translationPath}please-fill-all-required-fields`));
      return;
    }
    if (budgetSchema.error) {
      showError(budgetSchema.error?.message);
      return;
    }

    if (activityLeadId) {
      setActivityQualifyData({
        ...state,
      });
      onCancelClicked();
    } else {
      await updateLead();
      updateLeadStage();
      reload();
    }
  };

  useEffect(() => {
    getLeadTypeLookups();
    getPurposeLookups();
    getPropertyPlansLookups();
    getModeOfPaymentLookups();
    getPropertyTypeLookups();
    getUnitUsageLookups();
  }, []);

  return (
    <div>
      <Spinner isActive={isSaving} isAbsolute />
      <DialogComponent
        titleText={t(`${translationPath}qualify-lead`)}
        maxWidth="md"
        isOpen={isOpen}
        dialogContent={
          <React.Fragment>
            <div className="w-100 d-flex p-2">
              <div className="w-50">
                <div className="my-2 mx-2">
                  <AutocompleteComponent
                    idRef="LeadOperationTypeIdRef"
                    labelValue="lead-operation-type"
                    labelClasses="Requierd-Color"
                    selectedValues={selected?.leadType || {}}
                    data={data?.leadType || []}
                    displayLabel={(option) => option?.lookupItemName || ""}
                    renderOption={(option) => option?.lookupItemName || ""}
                    getOptionSelected={(option) =>
                      option?.lookupItemId === state?.leadTypeId
                    }
                    multiple={false}
                    withoutSearchButton
                    isLoading={isLoading?.leadType}
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    onChange={(event, newValue) => {
                      setSelected({ id: "leadType", value: newValue });
                      setState({
                        id: "leadTypeId",
                        value: (newValue && newValue?.lookupItemId) || null,
                      });
                    }}
                  />
                </div>

                <div className="my-2 mx-2">
                  <AutocompleteComponent
                    idRef="purposeIdRef"
                    labelValue="purpose"
                    labelClasses="Requierd-Color"
                    selectedValues={selected?.purpose || []}
                    data={data?.purpose || []}
                    displayLabel={(option) => option?.lookupItemName || ""}
                    renderOption={(option) => option?.lookupItemName || ""}
                    getOptionSelected={(option) =>
                      option.lookupItemId === state.purposeId
                    }
                    multiple={false}
                    withoutSearchButton
                    isLoading={isLoading?.purpose}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                      setSelected({ id: "purpose", value: newValue });
                      setState({
                        id: "purposeId",
                        value: (newValue && newValue?.lookupItemId) || null,
                      });
                    }}
                  />
                </div>

                <div className="my-2 mx-2">
                  <AutocompleteComponent
                    idRef="propertyPlanIdRef"
                    labelValue="propertyPlan"
                    labelClasses="Requierd-Color"
                    selectedValues={selected?.propertyPlan || []}
                    data={data?.propertyPlans}
                    displayLabel={(option) => option?.lookupItemName || ""}
                    renderOption={(option) => option?.lookupItemName || ""}
                    getOptionSelected={(option) =>
                      option?.lookupItemId === state?.propertyPlanId
                    }
                    multiple={false}
                    withoutSearchButton
                    isLoading={isLoading?.propertyPlans}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                      setSelected({ id: "propertyPlan", value: newValue });
                      setState({
                        id: "propertyPlanId",
                        value: (newValue && newValue?.lookupItemId) || null,
                      });
                    }}
                  />
                </div>

                <div className="my-2 mx-2">
                  <AutocompleteComponent
                    idRef="numberOfBathroomsRef"
                    labelValue={t(`${translationPath}numberOfBathrooms`)}
                    labelClasses="Requierd-Color"
                    selectedValues={selected?.numberOfBathrooms || []}
                    multiple={true}
                    data={LeadBathroomsEnum || []}
                    chipsLabel={(option) => option?.value || ""}
                    displayLabel={(option) => option?.value || ""}
                    renderOption={(option) => option?.value || ""}
                    getOptionSelected={(option) =>
                      selected?.numberOfBathrooms?.findIndex(
                        (item) => item?.value === option?.value
                      ) !== -1 || ""
                    }
                    withoutSearchButton
                    onChange={(event, newValue) => {
                      const isANYPrevSelected =
                        selected?.numberOfBathrooms &&
                        selected?.numberOfBathrooms.find(
                          (item) => item?.value === "Any"
                        );
                      const isANYSelected =
                        newValue &&
                        newValue.find((item) => item?.value === "Any");

                      if (
                        isANYPrevSelected &&
                        newValue &&
                        newValue.length !== 0
                      )
                        return;

                      if (isANYSelected) {
                        const filteredANYOption =
                          newValue.filter((item) => item?.value == "Any") || [];
                        const stateANYValue = filteredANYOption
                          ? filteredANYOption.map((item) => item?.value)
                          : [];
                        setSelected({
                          id: "numberOfBathrooms",
                          value: filteredANYOption,
                        });
                        setState({
                          id: "numberOfBathrooms",
                          value: stateANYValue,
                        });
                        return;
                      }

                      setSelected({ id: "numberOfBathrooms", value: newValue });
                      const bathroomsNoValues =
                        newValue && newValue.map((item) => item?.value);
                      setState({
                        id: "numberOfBathrooms",
                        value: bathroomsNoValues,
                      });
                    }}
                  />
                </div>

                <fieldset className="styled-fieldset d-flex mx-2">
                  <legend className="styled-legend">
                    {t(`${translationPath}budget`)}
                  </legend>
                  <div className="w-50 mr-2">
                    <Inputs
                      idRef="budgetFromRef"
                      labelClasses="Requierd-Color"
                      labelValue={t(`${translationPath}from`)}
                      value={state?.budgetFrom || 0}
                      type={"number"}
                      withNumberFormat
                      onInputChanged={(event) => {
                        const { value } = event?.target;
                        if (value <= budgetMaxValue)
                          setState({
                            id: "budgetFrom",
                            value: value,
                          });
                        else
                          setState({
                            id: "budgetFrom",
                            value: 0,
                          });
                      }}
                      min={0}
                    />
                  </div>
                  <div className="w-50">
                    <Inputs
                      idRef="budgetToRef"
                      labelClasses="Requierd-Color"
                      labelValue={t(`${translationPath}to`)}
                      value={state?.budgetTo || 0}
                      type={"number"}
                      withNumberFormat
                      onInputChanged={(event) => {
                        const { value } = event?.target;

                        if (value <= budgetMaxValue)
                          setState({
                            id: "budgetTo",
                            value: value,
                          });
                        else
                          setState({
                            id: "budgetTo",
                            value: budgetMaxValue,
                          });
                      }}
                      min={0}
                    />
                  </div>
                </fieldset>
              </div>
              <div className="w-50">
                <div className="my-2 mx-2">
                  <AutocompleteComponent
                    idRef="modeOfPaymentIdRef"
                    labelValue="modeOfPayment"
                    labelClasses="Requierd-Color"
                    selectedValues={selected?.modeOfPayment || []}
                    multiple={false}
                    data={data?.modeOfPayment}
                    displayLabel={(option) => option?.lookupItemName || ""}
                    renderOption={(option) => option?.lookupItemName || ""}
                    getOptionSelected={(option) =>
                      option?.lookupItemId === state?.modeOfPaymentId
                    }
                    withoutSearchButton
                    isLoading={isLoading?.modeOfPayment}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                      setSelected({ id: "modeOfPayment", value: newValue });
                      setState({
                        id: "modeOfPaymentId",
                        value: (newValue && newValue?.lookupItemId) || null,
                      });
                    }}
                  />
                </div>

                <div className="my-2 mx-2">
                  <AutocompleteComponent
                    idRef="propertyTypesIdRef"
                    labelValue="propertyTypes"
                    labelClasses="Requierd-Color"
                    selectedValues={selected?.propertyTypes || []}
                    multiple
                    data={data?.propertyTypes || []}
                    chipsLabel={(option) => option?.lookupItemName || ""}
                    displayLabel={(option) => option?.lookupItemName || ""}
                    renderOption={(option) => option?.lookupItemName || ""}
                    getOptionSelected={(option) =>
                      selected?.propertyTypes?.findIndex(
                        (item) => item?.lookupItemId === option?.lookupItemId
                      ) !== -1 || ""
                    }
                    withoutSearchButton
                    isLoading={isLoading?.propertyTypes}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                      setSelected({ id: "propertyTypes", value: newValue });
                      const propertyUnitTypeIDs =
                        newValue && newValue?.map((item) => item?.lookupItemId);
                      setState({
                        id: "propertyUnitTypeIDs",
                        value: propertyUnitTypeIDs || null,
                      });
                    }}
                  />
                </div>

                <div className="my-2 mx-2">
                  <AutocompleteComponent
                    idRef="propertyUsageIdRef"
                    labelValue="propertyUsage"
                    labelClasses="Requierd-Color"
                    selectedValues={selected?.unitUsageType || []}
                    multiple={false}
                    data={data?.unitUsageType || []}
                    displayLabel={(option) => option?.lookupItemName || ""}
                    renderOption={(option) => option?.lookupItemName || ""}
                    getOptionSelected={(option) =>
                      option?.lookupItemName === state?.unitUsageType
                    }
                    withoutSearchButton
                    isLoading={isLoading?.unitUsageType}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                      setSelected({ id: "unitUsageType", value: newValue });
                      setState({
                        id: "unitUsageType",
                        value: (newValue && newValue?.lookupItemName) || null,
                      });
                    }}
                  />
                </div>

                <div className="my-2 mx-2">
                  <AutocompleteComponent
                    idRef="numberOfBedroomsRef"
                    labelValue={t(`${translationPath}numberOfBedrooms`)}
                    labelClasses="Requierd-Color"
                    selectedValues={selected?.numberOfBedrooms || []}
                    multiple
                    data={LeadBedroomsEnum}
                    chipsLabel={(option) => option?.value || ""}
                    displayLabel={(option) => option?.value || ""}
                    renderOption={(option) => option?.value || ""}
                    getOptionSelected={(option) =>
                      selected.numberOfBedrooms.findIndex(
                        (item) => item?.value === option?.value
                      ) !== -1 || ""
                    }
                    withoutSearchButton
                    onChange={(event, newValue) => {
                      const isANYPrevSelected =
                        selected?.numberOfBedrooms &&
                        selected?.numberOfBedrooms.find(
                          (item) => item.value === "Any"
                        );
                      const isANYSelected =
                        newValue &&
                        newValue.find((item) => item?.value === "Any");

                      if (
                        isANYPrevSelected &&
                        newValue &&
                        newValue.length !== 0
                      )
                        return;

                      if (isANYSelected) {
                        const filteredANYOption =
                          newValue.filter((item) => item?.value == "Any") || [];
                        const stateANYValue = filteredANYOption
                          ? filteredANYOption.map((item) => item?.value)
                          : [];
                        setSelected({
                          id: "numberOfBedrooms",
                          value: filteredANYOption,
                        });
                        setState({
                          id: "numberOfBedrooms",
                          value: stateANYValue,
                        });
                        return;
                      }

                      setSelected({ id: "numberOfBedrooms", value: newValue });
                      const bedroomsNoValues =
                        newValue && newValue?.map((item) => item?.value);
                      setState({
                        id: "numberOfBedrooms",
                        value: bedroomsNoValues,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <DialogActions className="mt-4">
              <CancelButton
                onClick={(e) => {
                  onCancelClicked();
                }}
              >
                {t("cancel")}
              </CancelButton>
              <QualifyButton
                onClick={(e) => {
                  onQualifyClicked();
                }}
              >
                {t("Qualify")}
              </QualifyButton>
            </DialogActions>
          </React.Fragment>
        }
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
      />
    </div>
  );
}

export default QualifyLeadDialog;
