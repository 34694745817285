import React, { useEffect, useMemo, useState } from 'react';
import { InquiryTypeIdEnum } from '../../../../../Enums';
import { useTranslation } from 'react-i18next';

const InquiryTypes = ({
  isLease,
  isSale,
  onChange,
  parentTranslationPath,
  isZeroMatchingSelected,
  isListingShortageSelected,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);
  const inquiryTypes = {
    zeroMatching: 'zero-matching',
    listingShortage: 'listing-shortage',
  };

  const isDisabled = useMemo(() => !isLease && !isSale, [isSale, isLease]);
  const clickHandler = (e, type) => {
    e.stopPropagation();
    if (selected.includes(type)) {
      setSelected((prev) => prev.filter((item) => item !== type));
    } else {
      setSelected((prev) => prev.concat(type));
    }
  };
  useEffect(() => {
    onChange(data);
  }, [data]);

  useEffect(() => {
    const currentData = [];
    selected.forEach((type) => {
      if (type === inquiryTypes.listingShortage) {
        if (isSale) {
          currentData.push(InquiryTypeIdEnum.ListingShortageSale.InquiryTypeId);
        }
        if (isLease) {
          currentData.push(
            InquiryTypeIdEnum.ListingShortageLease.InquiryTypeId
          );
        }
      } else if (type === inquiryTypes.zeroMatching) {
        if (isSale) {
          currentData.push(InquiryTypeIdEnum.ZeroMatchingSale.InquiryTypeId);
        }
        if (isLease) {
          currentData.push(InquiryTypeIdEnum.ZeroMatchingLease.InquiryTypeId);
        }
      }
    });
    setData(currentData);
  }, [selected, isLease, isSale]);

  useEffect(() => {
    if (isZeroMatchingSelected) {
      setSelected((prev) => prev.concat(inquiryTypes.zeroMatching));
    }
    if (isListingShortageSelected) {
      setSelected((prev) => prev.concat(inquiryTypes.listingShortage));
    }
  }, [isListingShortageSelected, isZeroMatchingSelected]);
  return (
    <div className='inquiry-types'>
      <div
        className={`${
          selected.includes(inquiryTypes.zeroMatching) ? 'selected' : ''
        } 
          ${isDisabled ? 'disabled-card' : ''}
          inquiry-type-card`}
        onClick={(e) => clickHandler(e, inquiryTypes.zeroMatching)}
      >
        <div className='inquiry-type-card__icon'>
          <span class='mdi mdi-puzzle-outline' />
        </div>
        <div>
          <div className='inquiry-type-card__name'>
            <strong>{t('zero_matching')}</strong>
          </div>
          <div className='inquiry-type-card__description'>
            <p>{t('no_properties_found')}</p>
          </div>
        </div>
        <div className='flex'>
          <label class='checkbox-container'>
            <input
              type='checkbox'
              checked={selected.includes(inquiryTypes.zeroMatching)}
              onChange={(e) => clickHandler(e, inquiryTypes.zeroMatching)}
            />
            <span class='checkmark'></span>
          </label>
        </div>
      </div>
      <div
        onClick={(e) => clickHandler(e, inquiryTypes.listingShortage)}
        className={`${
          selected.includes(inquiryTypes.listingShortage) ? 'selected' : ''
        } 
          ${isDisabled ? 'disabled-card' : ''}
          inquiry-type-card`}
      >
        <div className='inquiry-type-card__icon'>
          <span class='mdi mdi-format-list-checkbox' />
        </div>
        <div>
          <div className='inquiry-type-card__name'>
            <strong>{t('listing_shortage')}</strong>
          </div>
          <div className='inquiry-type-card__description'>
            <p>{t('limited_availability')}</p>
          </div>
        </div>
        <div className='flex'>
          <label class='checkbox-container'>
            <input
              type='checkbox'
              checked={selected.includes(inquiryTypes.listingShortage)}
              onChange={(e) => clickHandler(e, inquiryTypes.listingShortage)}
            />
            <span class='checkmark'></span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default InquiryTypes;
