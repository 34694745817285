import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { LoadableImageComponant } from '../../../../../../../Components';
import { ContactTypeEnum, LoadableImageEnum } from '../../../../../../../Enums';
import moment from 'moment';

export const ListingAgentCard = ({
  classes,
  listingAgentName,
  listingDate,
}) => {
  return (
    <Card className={classes.listingAgentCard}>
      <LoadableImageComponant
        type={LoadableImageEnum.image.key}
        classes='user-cover-image m-3'
        // alt={t(`${translationPath}user-image`)}
        src={ContactTypeEnum.man.defaultImg}
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography variant='subtitle1'>{listingAgentName}</Typography>
          {listingDate && (
            <Typography variant='subtitle2' color='textSecondary'>
              Listing Date {moment(listingDate).format('MMM D, YYYY')}
            </Typography>
          )}
        </CardContent>
      </div>
    </Card>
  );
};
