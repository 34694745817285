import { PowerBiEnum } from "../../../../Enums";
import { CEODashboardPermissions } from '../../../../Permissions' ; 
import { CeoDashboard } from "../CeoDashboard/CeoDashboard";

export const CeoDashboardTabs = [
  {
    index: 0,
    label: "Dashboard:sales",
    component: CeoDashboard,
    config: {
      reportId: PowerBiEnum.SalesDirectorplus.reportid,
      groupId: PowerBiEnum.SalesDirectorplus.groupid,
      Url: PowerBiEnum.SalesDirectorplus.url,
    },
    permissionsId : CEODashboardPermissions.SalesDashboard.permissionsId , 
  },
  {
    index: 1,
    label: "Dashboard:lease",
    component: CeoDashboard,
    config: {
      reportId: PowerBiEnum.LeaseDirectorplus.reportid,
      groupId: PowerBiEnum.LeaseDirectorplus.groupid,
      Url: PowerBiEnum.LeaseDirectorplus.url,
    },
  
    permissionsId : CEODashboardPermissions.LeaseDashboard.permissionsId
  },
 
];
