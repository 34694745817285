import React, { memo } from "react";
import { Icon } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Notifications } from "../Header/UI";
import { PropTypes } from "prop-types";

import "./MobileNotificationsPage.scss";

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.foreground.quarterary,
  },
  backTitle: {
    color: theme.palette.text.tertiary,
  },
  mainTitle: {
    color: theme.palette.text.primary,
  },
}));

const MobileNotificationsPage = memo(({ setMobileTabValue }) => {
  const styles = useStyles();
  const { t } = useTranslation("HeaderView");

  return (
    <div className="mobileCalenderWrapper">
      <Link to={"/home/userlogin-dashboard"} target="_self">
        <div className="mobileCalenderContainer">
          <Icon className={clsx("icon-back-arrow", styles.arrow)} />

          <label className={clsx("title", styles.backTitle)}>
            {t(`back-dashboard`)}
          </label>
        </div>
      </Link>
      {/*  */}

      <div className="text">
        <span className={styles.mainTitle}>{t(`notifications`)}</span>
      </div>

      <Notifications setMobileTabValue={setMobileTabValue} />
    </div>
  );
});

MobileNotificationsPage.propTypes = {
  setMobileTabValue: PropTypes.func,
};
MobileNotificationsPage.defaultProps = {
  setMobileTabValue: () => {},
};

export default MobileNotificationsPage;
