export const LeadsPoolLeasePermissions = {
  ViewLeadsPool: {
    permissionsId: '7d6c2bb4-b09f-4713-c4b9-08dc683b0c43',
    permissionsName: 'View Leads Pool',
    description: null,
    componentsId: null,
    components: null,
  },
  AssignToMe: {
    permissionsId: '6d223b10-1d46-4408-c4ba-08dc683b0c43',
    permissionsName: 'Assign To Me',
    description: null,
    componentsId: null,
    components: null,
  },
  ReassignLeads: {
    permissionsId: 'c8572185-6cd8-424c-c4bb-08dc683b0c43',
    permissionsName: 'Reassign Leads',
    description: null,
    componentsId: null,
    components: null,
  },
  SendToRotation: {
    permissionsId: '09bd7838-274e-4618-c4bc-08dc683b0c43',
    permissionsName: 'Send To Rotation',
    description: null,
    componentsId: null,
    components: null,
  },
  ViewLeadName: {
    permissionsId: 'bd240f5b-ec0f-422c-5dd9-08dc7403ebc2',
    permissionsName: 'View Lead Name',
    description: null,
    componentsId: null,
    components: null,
  },
  ViewRemarksColumn: {
    permissionsId: 'd4f7812f-9eee-47a8-5dda-08dc7403ebc2',
    permissionsName: 'View Remarks Column',
    description: null,
    componentsId: null,
    components: null,
  },
  OpenLeadFile: {
    permissionsId: 'f97d788b-80aa-4fde-b501-08dc9a985192',
    permissionsName: 'Open Lead File',
    description: null,
    componentsId: null,
    components: null,
  },
};
