export const FormBuilderPermissions = {

  ViewFormBuilder: {
    components: null,
    componentsId: null,
    permissionsId: '0e060a72-c085-406c-f9e4-08d9689a2271',
    permissionsName: 'View Form Builder'
  }

};
