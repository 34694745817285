export const InquirySendToRotationRule = (item, value, setRerender, itemList, values, setData, formType) => {
  const assignToIndex = itemList && itemList.indexOf(itemList.find((f) => f.field.id === 'assign_to'));
  if (assignToIndex !== -1) {
    if (formType === 1 && value && value === 'Yes')
      setData(itemList[assignToIndex].field.id, {} || []);

    else if (formType && formType === 2 && value && value === 'Yes')
      setData(null, 0, 'assign_to');
  }
};

export const InquirySendToRotationDefaultRule = (item, itemList, values, setData) => {
  if (item.field.id === 'send_to_rotation' && values && !values.send_to_rotation ) {
    if (values && values.assign_to)
      setData('No', 0, 'send_to_rotation');
  }
};
