import React, { useState, useEffect, useRef, useReducer } from "react";
import { useTranslation } from "react-i18next";
import "./TaskConfiguration.scss";
import Joi from "joi";
import { config } from '../../../config';
import { PageHeading } from "../../../SharedComponents";
import {
  TaskConfigurationPriorityEnum,
  TaskConfigurationRelatedTo,
  TaskNotificationReceiverType,
  TaskConfigurationAssignmentType,
  NotificationReceiverNotifyBy,
} from "../../../Enums";
import {
  AutocompleteComponent,
  Inputs,
  RadiosGroupComponent,
  SwitchComponent,
  CheckboxesComponent,
} from "../../../Components";
import {
  GetLookupItemsByLookupTypeName,
  CreateTaskConfig,
  GetAllRoles,
  GetAllBranches,
  GetTaskConfigurationsAsAnOptions,
  GetAllSystemTemplateByCategoryId,
  UpdateTaskConfig,
  GetTaskConfigById,
  SetTaskConfigAsActiveOrInActive
} from "../../../Services";
import { GetParams, GlobalHistory, showError, showSuccess, GlobalTranslate,
  getErrorByName,  } from "../../../Helper";
import { AddTaskDialog } from "./Dialogs";
import { GetForms } from "../../../Services/formbuilder/getForms";
import Button from "@material-ui/core/Button";

export function TaskConfigurationFormView() {
  const parentTranslationPath = "TaskConfiguration";
  const translationPath = "";
  const searchTimer = useRef(null);
  const { t } = useTranslation(parentTranslationPath);
  const taskConfigID = +GetParams("id") || null;

  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  };

  const [isLoading, setIsLoading] = useReducer(reducer, {
    saving: false,
  });

  const [data, setData] = useReducer(reducer, {
    taskCategories: [],
    taskForms: [],
    taskRoles: [],
    taskPrerequisites: [],
    branches: [],
    taskTemplates: [],
  });

  const [selected, setSelected] = useReducer(reducer, {
    name: null,
    taskCategory: null,
    taskForm: null,
    createdByRoles: [],
    assignToRoles: [],
    priority: TaskConfigurationPriorityEnum.Lowest.value,
    taskPrerequisites: [],
    taskModule: [],
    isTaskRequireApproval: false,
    isWithReminder: false,
    isActive: false,
    isWithExpiry: false,
    isImportant: false,
  });

  const [state, setState] = useState(null);
  const [isAddTaskDialogOpen, setIsAddTaskDialogOpen] = useState(false);
  const [configNotifications, setConfigNotifications] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const getTaskCategoriesLookups = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "TaskCategory",
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "taskCategories", value: res.result || [] });
    } else setData({ id: "taskCategories", value: [] });
  };

  const createTaskConfig = async () => {
    setIsLoading({ id: "saving", value: true });
    const body = {
      ...(state || {}),
    };
    const res = await CreateTaskConfig(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}config-created-successfully`));
      GlobalHistory.push('/home/task-configuration/view')
    } else {
      setIsSubmitted(false);
      showError(t(`${translationPath}config-create-failed`));
    }
    setIsLoading({ id: "saving", value: false });
  };

  const updateTaskConfig = async () => {
    setIsLoading({ id: "saving", value: true });
    const body = {
      ...(state || {}),
    };
    const res = await UpdateTaskConfig(taskConfigID, body);
    if (!(res && res.status && res.status !== 200)) {
      setState(null);
      getTaskConfigById();
      showSuccess(t(`${translationPath}config-updated-successfully`));
    } else {
      setIsSubmitted(false);
      showError(t(`${translationPath}config-update-failed`));
    }
    setIsLoading({ id: "saving", value: false });
  };

  const getTaskConfigById = async () => {
    const res = await GetTaskConfigById(taskConfigID);
    if (!(res && res.status && res.status !== 200)) {
      if (res) {
        setData({ id: "config", value: res });
        fillTaskConfigData(res);
      }
    }
  };

  const setTaskConfigAsActiveOrInActive = async (isActive) => {

    const res = await SetTaskConfigAsActiveOrInActive(taskConfigID, isActive);
    if (!(res && res.status && res.status !== 200)) {
        setSelected({ id: "isActive", value: isActive });
        if (isActive) showSuccess(t(`${translationPath}task-configuration-activated`));
        else showSuccess(t(`${translationPath}task-configuration-deactivated`));
      } else {
        if (isActive) showError(t(`${translationPath}task-configuration-activation-failed`));
        else showError(t(`${translationPath}task-configuration-deactivation-failed`));
      }
  };

  const configActivationHandler = (isActive)=>{
    if(taskConfigID) setTaskConfigAsActiveOrInActive(isActive);
    else setSelected({ id: "isActive", value: isActive });
  }


  const fillTaskConfigData = (data) => {
    
    const convertCreatedByRoles = () => {
      const createdByRoles =
        (data?.taskConfigurationRoles &&
          data.taskConfigurationRoles.filter(
            (item) =>
              TaskConfigurationAssignmentType.CreatedBy.key ===
              item.assignmentTypeId
          )) ||
        [];

      return (
        createdByRoles.map((item) => ({
          ...(item || {}),
          assignmentType: item.assignmentTypeName,
        })) || []
      );
    };

    const convertAssignToRoles = () => {
      const assignToRoles =
        (data?.taskConfigurationRoles &&
          data.taskConfigurationRoles.filter(
            (item) =>
              TaskConfigurationAssignmentType.AssignTo.key ===
              item.assignmentTypeId
          )) ||
        [];

      return (
        assignToRoles.map((item) => ({
          ...(item || {}),
          assignmentType: item.assignmentTypeName,
        })) || []
      );
    };

    const convertPrerequisites = () => {
      return (
        (data.taskConfigurationPrerequisites &&
          data.taskConfigurationPrerequisites.map((item) => ({
            taskConfigurationPrerequisiteId:
              item.taskConfigurationPrerequisiteId || 0,
            taskConfigurationId: item.prerequisiteTaskConfigurationId,
            name: item.prerequisiteTaskConfigurationName,
          }))) ||
        []
      );
    };

    const convertTaskModule = () => {
      return (
        data.taskConfigurationRelatedTo &&
        data.taskConfigurationRelatedTo.map((item) => ({
          taskConfigurationRelationId: item.taskConfigurationRelationId || 0,
          key: item.relatedTo,
          value: item.relatedToId,
        }))
      );
    };

    const convertNotificationRoles = () => {
      return (
        (data.taskConfigurationNotifications &&
          data.taskConfigurationNotifications.map((item) => ({
            key: item.receiverTypeName,
            value: item.receiverTypeId,
          }))) ||
        []
      );
    };

    const getNotifyByItem = (notificationItems) => {
      const isSmsChecked =
        notificationItems.findIndex(
          (item) =>
            NotificationReceiverNotifyBy["SMS"]?.value == item.notifyById
        ) !== -1;
      const isEmailChecked =
        notificationItems.findIndex(
          (item) =>
            NotificationReceiverNotifyBy["Email"]?.value == item.notifyById
        ) !== -1;
      const isSystemNotificationChecked =
        notificationItems.findIndex(
          (item) =>
            NotificationReceiverNotifyBy["SystemNotification"]?.value ==
            item.notifyById
        ) !== -1;

      return {
        SMS: isSmsChecked,
        Email: isEmailChecked,
        SystemNotification: isSystemNotificationChecked,
      };
    };

    const convertConfigNotifications = () => {
      let configNotifications = [];
      let notificationsRoles = convertNotificationRoles();
      const notificationRolesList = {};

      notificationsRoles.forEach((item) => {
        notificationRolesList[item.key] = [];
      });

      data.taskConfigurationNotifications &&
        data.taskConfigurationNotifications.forEach((item) => {
          notificationRolesList[item.receiverTypeName] = [
            ...(notificationRolesList[item.receiverTypeName] || []),
            item,
          ];
        });

      configNotifications =
        notificationRolesList &&
        Object.values(notificationRolesList).map((roleNotifications) => {
          return roleNotifications.reduce((finalItem, item) => {
            finalItem = {
              taskConfigurationNotificationId:
                item.taskConfigurationNotificationId || 0, // add logic to handle id
              label:
                item.receiverTypeName &&
                TaskNotificationReceiverType[item.receiverTypeName]?.key,
              details: {
                notifyBy: getNotifyByItem(roleNotifications),
                template: {
                  templateId: item.systemTemplateId,
                  templateName: item.systemTemplateName,
                },
                receiverType: item.receiverTypeId,
              },
            };

            return finalItem;
          }, {});
        });

      return configNotifications;
    };

    let selectedConversion = {};

    selectedConversion = {
      name: data.name,
      taskCategory: {
        lookupItemId: data.taskCategoryId,
        lookupItemName: data.taskCategory,
      },
      taskForm: {
        formsId: data.formId,
        formsName: data.formName,
        categoryName: "Tasks",
      },
      priority: data.priorityId,
      isTaskRequireApproval: data.isTaskRequireApproval,
      isActive: data.isActive,
      isWithExpiry: data.isWithExpiry,
      isWithReminder: data.isWithReminder,
      isImportant: data.isImportant,
      taskBranches: data.taskConfigurationBranches || [],
      isWithNotifications: data.taskConfigurationNotifications?.length !== 0,
      createdByRoles: convertCreatedByRoles(),
      assignToRoles: convertAssignToRoles(),
      taskPrerequisites: convertPrerequisites(),
      taskModule: convertTaskModule(),
      notificationRoles: convertNotificationRoles(),
    };

    setSelected({ id: "edit", value: selectedConversion });

    setConfigNotifications(convertConfigNotifications());
  };

  const saveTaskConfigHandler = () => {
    const convertTaskConfigRoles = () => {
      const allRoles = [
        ...(selected?.createdByRoles || []),
        ...(selected?.assignToRoles || []),
      ];

      return allRoles.map((item) => ({
        taskConfigurationRoleId: item.taskConfigurationRoleId || 0,
        rolesId: item.rolesId,
        assignmentType: item.assignmentType,
      }));
    };

    const convertTaskConfigBranches = () => {
      return (
        (selected?.taskBranches &&
          selected.taskBranches.map((item) => ({
            taskConfigurationBranchId: item.taskConfigurationBranchId || 0,
            branchId: item.branchId,
          }))) ||
        []
      );
    };

    const convertTaskConfigRelations = () => {
      return (
        selected?.taskModule &&
        selected.taskModule.map((item) => ({
          taskConfigurationRelationId: item.taskConfigurationRelationId || 0,
          relatedTo: item.value,
        }))
      );
    };

    const convertTaskConfigPrerequisites = () => {
      return (
        selected?.taskPrerequisites &&
        selected.taskPrerequisites.map((item) => ({
          taskConfigurationPrerequisiteId:
            item.taskConfigurationPrerequisiteId || 0,
          prerequisiteTaskConfigurationId: item.taskConfigurationId,
        }))
      );
    };

    const getConfigNotificationID = () => {};

    const convertTaskConfigNotifications = () => {
      //TODO:: add logic here
      const notificationItems = [];

      configNotifications &&
        configNotifications.forEach((configItem) => {
          for (const [key, value] of Object.entries(
            configItem?.details?.notifyBy
          )) {
            if (value === true) {
              notificationItems.push({
                // taskConfigurationNotificationId: getConfigNotificationID(),
                taskConfigurationNotificationId:
                  configItem.taskConfigurationNotificationId || 0, //TODO:: add function to find the right ID
                systemTemplateId: configItem?.details?.template?.templateId,
                receiverType: configItem?.details?.receiverType,
                notifyBy: NotificationReceiverNotifyBy[key]?.value,
              });
            }
          }
        });

      return notificationItems;
    };

    let stateConversion = {};
    if (selected) {
      stateConversion = {
        taskCategoryId: selected.taskCategory?.lookupItemId,
        name: selected.name,
        formId: selected.taskForm?.formsId,
        priority: selected?.priority,
        isTaskRequireApproval: selected?.isTaskRequireApproval,
        isActive: selected?.isActive,
        isWithExpiry: selected?.isWithExpiry,
        isWithReminder: selected?.isWithReminder,
        isImportant: selected?.isImportant,
        taskConfigurationRoles: convertTaskConfigRoles(),
        taskConfigurationPrerequisites: convertTaskConfigPrerequisites(),
        taskConfigurationNotifications: convertTaskConfigNotifications(),
        taskConfigurationBranches: convertTaskConfigBranches(),
        taskConfigurationRelations: convertTaskConfigRelations(),
      };
      setState(stateConversion);
    }
  };

  const getTaskForms = async (value) => {
    const pageIndex = 0;
    const pageSize = 150;

    const res = await GetForms({ pageIndex, pageSize, searchedItem: value || "" });
    if (!(res && res.status && res.status !== 200)) {
      const taskForms =
        (res?.result &&
          res.result.filter((item) => item.categoryName === "Tasks")) ||
        [];
      setData({ id: "taskForms", value: taskForms });
    } else setData({ id: "taskForms", value: [] });
  };

  const getTemplateByCategoryId = async () => {
    const res = await GetAllSystemTemplateByCategoryId(
      config.TaskNotificationTemplateId
    );
    if (!(res && res.status && res.status !== 200)) {
      const mappedTempatesData =
        res &&
        res.map((item) => ({
          templateName: item.systemTemplateName,
          templateId: item.systemTemplateId,
        }));
      setData({ id: "taskTemplates", value: mappedTempatesData || [] });
    } else setData({ id: "taskTemplates", value: [] });
  };

  const getTaskConfigurationsAsAnOptions = async (searchValue) => {
    const pageIndex = 1;
    const pageSize = 10;

    const res = await GetTaskConfigurationsAsAnOptions({
      pageIndex,
      pageSize,
      search: searchValue || "",
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "taskPrerequisites", value: res.result });
    } else setData({ id: "taskPrerequisites", value: [] });
  };

  const getTaskConfigurationRoles = async (value) => {
    const pageIndex = 1;
    const pageSize = 25;

    const res = await GetAllRoles(pageIndex, pageSize, value);
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "taskRoles", value: res?.result || [] });
    } else setData({ id: "taskRoles", value: [] });
  };

  const getBranches = async (branchName) => {
    const res = await GetAllBranches({
      pageSize: 25,
      pageIndex: 1,
      branchName,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "branches", value: res?.result || [] });
    } else setData({ id: "branches", value: [] });
  };

  const configNotificationsHandler = (notificationsRoles) => {
    if (configNotifications?.length == 0) {
      const mappedConfigNotifications = notificationsRoles?.map((item) => ({
        label: item.key,
        details: {
          notifyBy: {
            SMS: false,
            Email: false,
            SystemNotification: false,
          },
          template: null,
          receiverType: item.value,
        },
      }));

      setConfigNotifications(mappedConfigNotifications);
    } else {
      const mappedConfigNotifications = [];
      notificationsRoles.forEach((item) => {
        const isPrevSelected = configNotifications.findIndex(
          (notification) => notification?.details?.receiverType == item.value
        );

        if (isPrevSelected !== -1) {
          const notificationItem = configNotifications.find(
            (notifyItem) => notifyItem?.details?.receiverType === item.value
          );
          mappedConfigNotifications.push(notificationItem);
        } else
          mappedConfigNotifications.push({
            label: item.key,
            details: {
              notifyBy: {
                SMS: false,
                Email: false,
                SystemNotification: false,
              },
              template: null,
              receiverType: item.value,
            },
          });
      });

      setConfigNotifications(mappedConfigNotifications);
    }
  };

  const notifyByChangeHandler = (
    notifyByType,
    notificationItem,
    notificationIndex
  ) => {
    const localConfigNotifications = [...configNotifications];
    const localConfigItem = {
      ...(notificationItem || {}),
      details: {
        ...(notificationItem?.details || {}),
        notifyBy: {
          ...(notificationItem?.details?.notifyBy || {}),
          [notifyByType]: !notificationItem?.details?.notifyBy[notifyByType],
        },
      },
    };
    localConfigNotifications.splice(notificationIndex, 1, localConfigItem);

    setConfigNotifications([...localConfigNotifications]);
  };

  const submitHandler = () =>{
    setIsSubmitted(true);
    
    if (schema.error) {
      showError(GlobalTranslate.t("Shared:please-fix-all-errors"));
      return;
    }

    if (taskConfigID) updateTaskConfig();
    else createTaskConfig();
  }

  const schema = Joi.object({
    name: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}name-is-required`),
      }),
    taskCategory: Joi.object()
      .required()
      .messages({
        "object.base": t(`${translationPath}taskCategory-is-required`),
      }),
    taskForm: Joi.object()
      .required()
      .messages({
        "object.base": t(`${translationPath}taskForm-is-required`),
      }),
    createdByRoles: Joi.array()
    .min(1)
    .message(t(`${translationPath}createdByRoles-is-required`)),
    assignToRoles: Joi.array()
    .min(1)
    .message(t(`${translationPath}assignToRoles-is-required`)),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(selected);


  useEffect(() => {
    getTemplateByCategoryId();
    if (taskConfigID) getTaskConfigById();
  }, []);

  useEffect(() => {
    if(state) submitHandler();
  }, [state]);

  return (
    <div className="propx-view">
      <div className="d-flex-v-center-h-between">
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          headerTitle={"task-configuration"}
          subTitle={"task-configuration-heading"}
          wrapperClasses={"px-4 pt-2 w-75"}
          headerTitleClasses={"fz-30  mb-3"}
          subTitleClasses={"fz-12 m-0"}
        />
        <div className="w-25 px-4 d-flex-center fj-end">
          <div>
            <SwitchComponent
              idRef="isActiveRef"
              themeClass="thick-theme"
              isChecked={selected.isActive}
              labelValue="Activate-task"
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onChangeHandler={(_, isChecked) => {
                configActivationHandler(isChecked);
              }}
            />
          </div>
          {taskConfigID && <Button
            className="btns theme-propx outlined"
            id="btn-save"
            onClick={() => setIsAddTaskDialogOpen(true)}
          >
            {t(`${translationPath}add-task`)}
          </Button>}
          <Button
            className="btns theme-propx solid"
            id="btn-save"
            disabled={isLoading.saving}
            onClick={() => saveTaskConfigHandler()}
          >
            {t(`${translationPath}save-changes`)}
          </Button>
        </div>
      </div>
      <div className="propx-view-container md">
        <div className="w-50">
          <div className="d-flex fj-start b-bottom">
            <div className="w-33 mt-2 pt-1">
              <span className="fw-simi-bold text-primary">
                {t(`${translationPath}Task-Category`)}
              </span>
            </div>
            <div className="w-75">
              <AutocompleteComponent
                idRef="TaskCategoryRef"
                inputPlaceholder={t(`${translationPath}select`)}
                selectedValues={selected.taskCategory}
                wrapperClasses="mr-2 my-3"
                data={data.taskCategories || []}
                displayLabel={(option) => option.lookupItemName || ""}
                multiple={false}
                isWithError
                isSubmitted={isSubmitted}
                helperText={getErrorByName(schema, "taskCategory").message}
                error={getErrorByName(schema, "taskCategory").error}
                withoutSearchButton
                onChange={(event, newValue) => {
                  setSelected({ id: "taskCategory", value: newValue });
                }}
                onOpen={() => {
                  if (data.taskCategories && data.taskCategories.length == 0)
                    getTaskCategoriesLookups();
                }}
                isLoading={isLoading.taskCategories}
              />
            </div>
          </div>
          <div className="d-flex fj-start b-bottom">
            <div className="w-33 mt-2 pt-1">
              <span className="fw-simi-bold text-primary">
                {t(`${translationPath}Task-name`)}
              </span>
            </div>
            <div className="w-75 mt-2 pt-1">
              <Inputs
                idRef="nameRef"
                value={selected.name}
                inputPlaceholder={`${translationPath}enter`}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                isWithError
                isSubmitted={isSubmitted}
                helperText={getErrorByName(schema, "name").message}
                error={getErrorByName(schema, "name").error}
                onInputChanged={(event) => {
                  setSelected({ id: "name", value: event?.target?.value });
                }}
              />
            </div>
          </div>
          <div className="d-flex fj-start b-bottom">
            <div className="w-33 mt-2 pt-1">
              <span className="fw-simi-bold text-primary">
                {t(`${translationPath}Task-Form`)}
              </span>
            </div>
            <div className="w-75">
              <AutocompleteComponent
                idRef="TaskFormRef"
                inputPlaceholder={t(`${translationPath}select`)}
                selectedValues={selected.taskForm}
                wrapperClasses="mr-2 my-3"
                data={data.taskForms || []}
                displayLabel={(option) => option.formsName || ""}
                multiple={false}
                withoutSearchButton
                isWithError
                isSubmitted={isSubmitted}
                helperText={getErrorByName(schema, "taskForm").message}
                error={getErrorByName(schema, "taskForm").error}
                onChange={(event, newValue) => {
                  setSelected({ id: "taskForm", value: newValue });
                }}
                onOpen={() => {
                  if (data.taskForms && data.taskForms.length == 0)
                    getTaskForms();
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getTaskForms(value);
                  }, 1200);
                }}
                onKeyDown={() => {
                  setSelected({ id: 'taskForms', value: null });
                }}
                isLoading={isLoading.projectCategories}
              />
            </div>
          </div>
          <div className="d-flex fj-start b-bottom">
            <div className="w-33 mt-2 pt-1">
              <span className="fw-simi-bold text-primary">
                {t(`${translationPath}Created-By`)}
              </span>
            </div>
            <div className="w-75">
              <AutocompleteComponent
                idRef="TaskConfigurationRolesRef"
                inputPlaceholder={t(`${translationPath}select`)}
                selectedValues={selected.createdByRoles}
                wrapperClasses="mr-2 my-3"
                data={data.taskRoles || []}
                chipsLabel={(option) => option.rolesName || ""}
                displayLabel={(option) => t(`${option.rolesName || ""}`)}
                withoutSearchButton
                multiple
                isWithError
                isSubmitted={isSubmitted}
                helperText={getErrorByName(schema, "createdByRoles").message}
                error={getErrorByName(schema, "createdByRoles").error}
                getOptionSelected={(option) =>
                  selected?.createdByRoles?.findIndex(
                    (item) => item.rolesId === option.rolesId
                  ) !== -1 || ""
                }
                onChange={(event, newValue) => {
                  const createdByRoles = newValue?.map((item) => ({
                    ...item,
                    assignmentType:
                      TaskConfigurationAssignmentType.CreatedBy.value,
                  }));
                  setSelected({ id: "createdByRoles", value: createdByRoles });
                }}
                onOpen={() => {
                  if (data.taskRoles && data.taskRoles.length == 0)
                    getTaskConfigurationRoles();
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getTaskConfigurationRoles(value);
                  }, 1200);
                }}
                onKeyDown={() => {
                  setSelected({ id: 'createdByRoles', value: null });
                }}
                isLoading={isLoading.taskRoles}
              />
            </div>
          </div>
          <div className="d-flex fj-start b-bottom">
            <div className="w-33 mt-2 pt-1">
              <span className="fw-simi-bold text-primary">
                {t(`${translationPath}Assign-to`)}
              </span>
            </div>
            <div className="w-75">
              <AutocompleteComponent
                idRef="TaskConfigurationRolesRef"
                inputPlaceholder={t(`${translationPath}select`)}
                selectedValues={selected.assignToRoles}
                wrapperClasses="mr-2 my-3"
                data={data.taskRoles || []}
                chipsLabel={(option) => option.rolesName || ""}
                displayLabel={(option) => t(`${option.rolesName || ""}`)}
                withoutSearchButton
                multiple
                isWithError
                isSubmitted={isSubmitted}
                helperText={getErrorByName(schema, "assignToRoles").message}
                error={getErrorByName(schema, "assignToRoles").error}
                getOptionSelected={(option) =>
                  selected?.assignToRoles?.findIndex(
                    (item) => item.rolesId === option.rolesId
                  ) !== -1 || ""
                }
                onChange={(event, newValue) => {
                  const assignToRoles = newValue?.map((item) => ({
                    ...item,
                    assignmentType:
                      TaskConfigurationAssignmentType.AssignTo.value,
                  }));
                  setSelected({ id: "assignToRoles", value: assignToRoles });
                }}
                onOpen={() => {
                  if (data.taskRoles && data.taskRoles.length == 0)
                    getTaskConfigurationRoles();
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getTaskConfigurationRoles(value);
                  }, 1200);
                }}
                onKeyDown={() => {
                  setSelected({ id: 'assignToRoles', value: null });
                }}
                isLoading={isLoading.taskRoles}
              />
            </div>
          </div>
          <div className="d-flex fj-start b-bottom">
            <div className="w-33 mt-2 pt-1">
              <span className="fw-simi-bold text-primary">
                {t(`${translationPath}Priority`)}
              </span>
            </div>
            <div className="w-75">
              <RadiosGroupComponent
                idRef="priorityRef"
                data={Object.values(TaskConfigurationPriorityEnum)}
                value={selected?.priority || 1}
                labelInput="key"
                valueInput="value"
                themeClass="theme-line"
                translationPath={translationPath}
                translationPathForData={translationPath}
                parentTranslationPath={parentTranslationPath}
                onSelectedRadioChanged={(event) => {
                  const selectedValue = +event.target.value;
                  setSelected({ id: "priority", value: selectedValue });
                }}
              />
            </div>
          </div>
          <div className="d-flex fj-start b-bottom">
            <div className="w-33 mt-2 pt-1">
              <span className="fw-simi-bold text-primary">
                {t(`${translationPath}Prerequisites`)}
              </span>
            </div>
            <div className="w-75">
              <AutocompleteComponent
                idRef="TaskPrerequisitesRef"
                inputPlaceholder={t(`${translationPath}select`)}
                selectedValues={selected.taskPrerequisites}
                wrapperClasses="mr-2 my-3"
                data={data.taskPrerequisites || []}
                chipsLabel={(option) => option.name || ""}
                displayLabel={(option) => t(`${option.name || ""}`)}
                withoutSearchButton
                multiple
                getOptionSelected={(option) =>
                  selected?.taskPrerequisites?.findIndex(
                    (item) =>
                      item.taskConfigurationId === option.taskConfigurationId
                  ) !== -1 || ""
                }
                onChange={(event, newValue) => {
                  setSelected({ id: "taskPrerequisites", value: newValue });
                }}
                onOpen={() => {
                  if (
                    data.taskPrerequisites &&
                    data.taskPrerequisites.length == 0
                  )
                    getTaskConfigurationsAsAnOptions();
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getTaskConfigurationsAsAnOptions(value);
                  }, 1200);
                }}
                onKeyDown={() => {
                  setSelected({ id: 'taskPrerequisites', value: null });
                }}
                isLoading={isLoading.taskPrerequisites}
              />
            </div>
          </div>
          <div className="d-flex fj-start b-bottom">
            <div className="w-33 mt-2 pt-1">
              <span className="fw-simi-bold text-primary">
                {t(`${translationPath}Specific-Branch`)}
              </span>
            </div>
            <div className="w-75">
              <AutocompleteComponent
                idRef="branchesRef"
                inputPlaceholder={t(`${translationPath}select`)}
                selectedValues={selected.taskBranches || []}
                wrapperClasses="mr-2 my-3"
                data={data.branches || []}
                chipsLabel={(option) =>
                  (option.branchName && option.branchName) || ""
                }
                displayLabel={(option) =>
                  (option.branchName && option.branchName) || ""
                }
                getOptionSelected={(option) =>
                  selected?.taskBranches?.findIndex(
                    (item) => item.branchId === option.branchId
                  ) !== -1 || ""
                }
                multiple
                withoutSearchButton
                onChange={(event, newValue) => {
                  setSelected({ id: "taskBranches", value: newValue });
                }}
                onOpen={() => {
                  if (data.branches && data.branches.length == 0) getBranches();
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getBranches(value);
                  }, 1200);
                }}
                onKeyDown={() => {
                  setSelected({ id: 'taskBranches', value: null });
                }}
                isLoading={isLoading.projectCategories}
              />
            </div>
          </div>
          <div className="d-flex fj-start b-bottom">
            <div className="w-33 mt-2 pt-1">
              <span className="fw-simi-bold text-primary">
                {t(`${translationPath}Specific-Module`)}
              </span>
            </div>
            <div className="w-75">
              <AutocompleteComponent
                idRef="SpecificModuleRef"
                inputPlaceholder={t(`${translationPath}select`)}
                selectedValues={selected.taskModule}
                wrapperClasses="mr-2 my-3"
                data={Object.values(TaskConfigurationRelatedTo)}
                chipsLabel={(option) => t(`${option.key || ""}`)}
                displayLabel={(option) => t(`${option.key || ""}`)}
                getOptionSelected={(option) =>
                  selected?.taskModule?.findIndex(
                    (item) => item.value === option.value
                  ) !== -1 || ""
                }
                multiple
                withoutSearchButton
                onChange={(event, newValue) => {
                  setSelected({ id: "taskModule", value: newValue });
                }}
              />
            </div>
          </div>
          <div className="d-flex fj-start b-bottom pb-2">
            <div className="w-33 mt-2 pt-1">
              <span className="fw-simi-bold text-primary">
                {t(`${translationPath}Specify`)}
              </span>
            </div>
            <div className="w-75">
              <div>
                <SwitchComponent
                  idRef="With-approvalRef"
                  themeClass="thick-theme"
                  isChecked={selected.isTaskRequireApproval}
                  labelValue="With-approval"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChangeHandler={(event, isChecked) => {
                    setSelected({
                      id: "isTaskRequireApproval",
                      value: isChecked,
                    });
                  }}
                />
              </div>
              <div>
                <SwitchComponent
                  idRef="With-expiryRef"
                  themeClass="thick-theme"
                  isChecked={selected.isWithExpiry}
                  labelValue="With-expiry"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChangeHandler={(event, isChecked) => {
                    setSelected({ id: "isWithExpiry", value: isChecked });

                    if (!isChecked)
                      setSelected({ id: "isWithReminder", value: isChecked });
                  }}
                />
              </div>
              {selected.isWithExpiry && (
                <div>
                  <SwitchComponent
                    idRef="With-reminderRef"
                    themeClass="thick-theme"
                    isChecked={selected.isWithReminder}
                    labelValue="With-reminder"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChangeHandler={(event, isChecked) => {
                      setSelected({ id: "isWithReminder", value: isChecked });
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="d-flex fj-start pb-2">
            <div className="w-33 mt-2 pt-1">
              <span className="fw-simi-bold text-primary">
                {t(`${translationPath}Configure-notifications`)}
              </span>
            </div>
            <div className="w-75">
              <div className="d-flex-v-center-h-between pb-2">
                <SwitchComponent
                  idRef="isWithNotificationsRef"
                  themeClass="thick-theme"
                  isChecked={selected.isWithNotifications || false}
                  labelValue="With-notifications"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChangeHandler={(event, isChecked) => {
                    setSelected({
                      id: "isWithNotifications",
                      value: isChecked,
                    });
                    if (!isChecked) {
                      setConfigNotifications([]);
                      setSelected({ id: "notificationRoles", value: [] });
                    }
                  }}
                />
                <CheckboxesComponent
                  idRef="IsImportantRef"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  label="Important-Notifications"
                  singleChecked={selected.isImportant || false}
                  themeClass="theme-propx solid mt-2 pt-1"
                  onSelectedCheckboxClicked={() => {
                    setSelected({
                      id: "isImportant",
                      value: !selected.isImportant,
                    });
                  }}
                />
              </div>
              {selected.isWithNotifications && (
                <div>
                  <AutocompleteComponent
                    idRef="NotificationRolesRef"
                    inputPlaceholder={t(`${translationPath}select-role`)}
                    selectedValues={selected.notificationRoles}
                    wrapperClasses="mr-2 my-3"
                    data={Object.values(TaskNotificationReceiverType)}
                    chipsLabel={(option) => t(`${option.key || ""}`)}
                    displayLabel={(option) => t(`${option.key || ""}`)}
                    getOptionSelected={(option) =>
                      selected?.notificationRoles?.findIndex(
                        (item) => item.value === option.value
                      ) !== -1 || ""
                    }
                    multiple
                    withoutSearchButton
                    onChange={(event, newValue) => {
                      setSelected({ id: "notificationRoles", value: newValue });
                      configNotificationsHandler(newValue);
                    }}

                  />
                </div>
              )}
            </div>
          </div>
          {selected.isWithNotifications &&
            configNotifications &&
            configNotifications.map((item, index) => (
              <div className="d-flex fj-start">
                <div className="w-33 mt-2 pt-1">
                  <span className="fw-simi-bold text-primary">{item?.label}</span>
                </div>
                <div className="w-75">
                  <div className="d-flex fj-start">
                    <CheckboxesComponent
                      idRef={`SMSRef-${index}`}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      label="SMS"
                      singleChecked={item?.details?.notifyBy?.SMS || false}
                      themeClass="theme-propx solid mt-2 pt-1 mr-3"
                      onSelectedCheckboxClicked={() => {
                        const notifyByType = "SMS";
                        notifyByChangeHandler(notifyByType, item, index);
                      }}
                    />
                    <CheckboxesComponent
                      idRef={`EmailRef-${index}`}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      label="Email"
                      singleChecked={item?.details?.notifyBy?.Email || false}
                      themeClass="theme-propx solid mt-2 pt-1 mr-3"
                      onSelectedCheckboxClicked={() => {
                        const notifyByType = "Email";
                        notifyByChangeHandler(notifyByType, item, index);
                      }}
                    />
                    <CheckboxesComponent
                      idRef={`NotificationRef-${index}`}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      label="Notification"
                      singleChecked={
                        item?.details?.notifyBy?.SystemNotification || false
                      }
                      themeClass="theme-propx solid mt-2 pt-1 mr-3"
                      onSelectedCheckboxClicked={() => {
                        const notifyByType = "SystemNotification";
                        notifyByChangeHandler(notifyByType, item, index);
                      }}
                    />
                  </div>
                  <AutocompleteComponent
                    idRef={`templateRef-${index}`}
                    inputPlaceholder={t(`${translationPath}select-template`)}
                    selectedValues={item?.details?.template}
                    wrapperClasses="mr-2 my-3"
                    data={data.taskTemplates || []}
                    displayLabel={(option) => option.templateName || ""}
                    multiple={false}
                    withoutSearchButton
                    onChange={(event, newValue) => {
                      const localConfigNotifications = [...configNotifications];
                      const localConfigItem = {
                        ...(item || {}),
                        details: {
                          ...(item?.details || {}),
                          template: newValue,
                        },
                      };
                      localConfigNotifications.splice(
                        index,
                        1,
                        localConfigItem
                      );

                      setConfigNotifications([...localConfigNotifications]);
                    }}
                    isLoading={isLoading.projectCategories}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
      {isAddTaskDialogOpen && 
      data?.config?.taskConfigurationId &&
      (
        <AddTaskDialog
          isDialogOpen={isAddTaskDialogOpen}
          onSave={() => {
            // getTaskUploadedData();
            setIsAddTaskDialogOpen(false);
          }}
          onClose={() => {
            setIsAddTaskDialogOpen(false);
          }}
          taskConfigurationId={data.config.taskConfigurationId || null}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
}
