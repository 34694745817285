import React from "react";

function FilterNotContainIcon({ fill, ...restProps }) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M3.187 2.042c-.685.128-1.341.67-1.598 1.32-.156.395-.156.389-.168 2.065l-.012 1.546-.314.32c-.173.176-.337.362-.364.414-.069.129-.069.457 0 .586.027.052.191.238.364.414l.314.32.012 1.546c.012 1.675.012 1.67.167 2.063.24.606.843 1.136 1.477 1.298.271.069.607.082.765.029a.66.66 0 0 0 .446-.63c0-.38-.242-.617-.683-.667-.358-.041-.588-.191-.745-.485l-.061-.114-.014-1.72c-.015-1.992.008-1.833-.31-2.162L2.285 8l.178-.185c.318-.329.295-.17.31-2.162l.014-1.72.079-.143a.856.856 0 0 1 .682-.446c.348-.032.543-.147.672-.396a.717.717 0 0 0 .001-.562c-.174-.334-.503-.444-1.034-.344m9.026-.017c-.305.093-.489.334-.489.642 0 .203.059.338.213.488.114.111.265.166.515.189a.856.856 0 0 1 .682.446l.079.143.014 1.72c.015 1.992-.008 1.833.31 2.162l.178.185-.178.185c-.318.329-.295.17-.31 2.162l-.014 1.72-.061.114c-.157.294-.387.444-.745.485-.441.05-.683.287-.683.667 0 .288.18.542.446.63.158.053.494.04.765-.029.633-.162 1.218-.676 1.473-1.294.159-.384.159-.386.171-2.067l.012-1.546.314-.32c.173-.176.337-.362.364-.414.036-.067.05-.149.05-.293s-.014-.226-.05-.293c-.027-.052-.191-.238-.364-.414l-.314-.32-.012-1.546c-.012-1.676-.012-1.67-.168-2.065-.2-.506-.656-.965-1.184-1.193-.308-.133-.814-.205-1.014-.144M5.78 5.374a.707.707 0 0 0-.361.304c-.061.104-.072.152-.072.322.001.142.015.227.05.293.027.052.41.457.852.9L7.052 8l-.803.807c-.442.443-.823.847-.848.896a.682.682 0 0 0 .271.875c.11.065.156.075.328.075.142-.001.227-.015.293-.05.052-.027.457-.41.9-.852L8 8.948l.807.803c.443.442.847.823.896.848.303.153.698.03.875-.271.065-.11.075-.156.075-.328-.001-.142-.015-.227-.05-.293-.027-.052-.41-.457-.852-.9L8.948 8l.803-.807c.442-.443.825-.848.852-.9.035-.066.049-.151.05-.293 0-.17-.011-.218-.072-.322a.667.667 0 0 0-.81-.301c-.115.039-.229.142-.951.859L8 7.051l-.82-.815c-.722-.717-.836-.82-.951-.859a.678.678 0 0 0-.449-.003"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default FilterNotContainIcon;
