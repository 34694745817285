export const MyLeadsTypesEnum = [
  {
    id: 1,
    value: 'seller',
  },
  {
    id: 2,
    value: 'landlord',
  },
  {
    id: 3,
    value: 'buyer',
  },
  {
    id: 4,
    value: 'tenant',
  },
];
