import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogComponent } from '../../../../../../../../Components';
import { showError, showSuccess } from '../../../../../../../../Helper';
import { DeleteTeamMember } from '../../../../../../../../Services';

export const DeleteDialog = ({
  onCancelClicked,
  onCloseClicked,
  reloadData,
  isDeleteDialogOpen,
  setIsDeleteDialogOpen,
  translationPath,
  parentTranslationPath,
  activeItem,
  setActiveItem,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const deleteHandler = async () => {
    const teamMemberId = activeItem?.id;
    const res = await DeleteTeamMember(teamMemberId);
    if (!(res && res.status && res.status !== 200)) {
      setActiveItem(null);
      reloadData();
      showSuccess(t(`${translationPath}team-member-deleted-successfully`));
    } else showError(t(`${translationPath}team-member-delete-failed`));
    setIsDeleteDialogOpen(false);
  };

  return (
    <DialogComponent
      titleText={t('confirm-message')}
      saveText={t('confirm')}
      saveType='button'
      maxWidth='sm'
      dialogContent={(
        <div className='d-flex-column-center'>
          <span className='mdi mdi-close-octagon c-danger mdi-48px' />
          <span>{`${t(`${translationPath}team-member-delete-description`)} ${activeItem?.name? `( ${activeItem?.name} )` : ''}`}</span>
        </div>
      )}
      saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
      isOpen={isDeleteDialogOpen}
      onCancelClicked={onCancelClicked}
      onCloseClicked={onCloseClicked}
      onSaveClicked={deleteHandler}
    />
  );
};
