
export const GeneralPropertyCriteriaTypesEnum = {
    Ownership: {
      key: 1,
      value: 'Ownership',
    },
    ProjectUsage: {
      key: 2,
      value: 'ProjectUsage',
    },
    PropertyAge: {
      key: 3,
      value: 'PropertyAge',
    },
    ServiceCharge: {
      key: 4,
      value: 'ServiceCharge',
    },
    TwentyFourHourSecurity: {
      key: 5,
      value: 'TwentyFourHourSecurity',
    },
    Neighborhood: 
    {
        key: 6,
        value: 'Retails',
    },
    Retails: 
    {
        key: 7,
        value: 'Retails',
    },
    TowerEntrance: 
    {
        key: 8,
        value: 'TowerEntrance',
    },
    Elevators: {
      key: 9,
      value: 'Elevators',
    },
    TwentyFourHourReception: {
      key: 10,
      value: 'TwentyFourHourReception',
    },
  };
  