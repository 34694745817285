export const LearningUserProfileLocale = {
  en: {
    "user-profile": "User Profile",
    username: "Username",
    "email-address": "Email Address",
    "branch-name": "Branch Name",
    "team-name": "Team Name",
    "line-manager": "Line Manager",
    "performance-dashboard": "Performance Dashboard",
    "number-of-certificates": "No. of Certificates",
    "number-of-courses": "No. of Courses",
    "average-score": "Average Score",
    details: "Details",
    courses: "Courses",
    certificates: "Certificates",
    tests: "Tests",
    "course-name": "Course Name",
    "complete-date": "Complete Date",
    "certificate-link": "Certificate Link",
    progress: "Progress",
    "enrolled-on": "Enrolled On",
    "time-spent": "Time Spent",
    "category-name": "Category Name",
    "issue-date": "Issue Date",
    "expiration-date": "Expiration Date",
    "user-not-exist": "User does not exist",
    "user-not-found": "User not found",
    certificate: "Certificate",
    score: "Score",
  },
  ar: {
    "user-profile": "الملف الشخصي",
    username: "اسم المستخدم",
    "email-address": "البريد الإلكتروني",
    "branch-name": "اسم الفرع",
    "team-name": "اسم الفريق",
    "line-manager": "المدير المباشر",
    "performance-dashboard": "لوحة الأداء",
    "number-of-certificates": "عدد الشهادات",
    "number-of-courses": "عدد الدورات",
    "average-score": "متوسط الدرجة",
    details: "التفاصيل",
    courses: "الدورات",
    certificates: "الشهادات",
    tests: "الاختبارات",
    "course-name": "اسم الدورة",
    "complete-date": "تاريخ الإكمال",
    "certificate-link": "رابط الشهادة",
    progress: "التقدم",
    "enrolled-on": "تم التسجيل في",
    "time-spent": "الوقت المستغرق",
    "category-name": "اسم الفئة",
    "issue-date": "تاريخ الإصدار",
    "expiration-date": "تاريخ الانتهاء",
    "user-not-exist": "المستخدم غير موجود",
    "user-not-found": "المستخدم غير موجود",
    certificate: "شهادة",
    score: "النتيجة",
  },
  ru: {
    "user-profile": "Профиль пользователя",
    username: "Имя пользователя",
    "email-address": "Электронная почта",
    "branch-name": "Название филиала",
    "team-name": "Название команды",
    "line-manager": "Руководитель",
    "performance-dashboard": "Панель производительности",
    "number-of-certificates": "Количество сертификатов",
    "number-of-courses": "Количество курсов",
    "average-score": "Средний балл",
    details: "Детали",
    courses: "Курсы",
    certificates: "Сертификаты",
    tests: "Тесты",
    "course-name": "Название курса",
    "complete-date": "Дата завершения",
    "certificate-link": "Ссылка на сертификат",
    progress: "Прогресс",
    "enrolled-on": "Зарегистрирован на",
    "time-spent": "Время, затраченное",
    "category-name": "Название категории",
    "issue-date": "Дата выдачи",
    "expiration-date": "Дата окончания",
    "user-not-exist": "Пользователь не существует",
    "user-not-found": "Пользователь не найден",
    certificate: "Сертификат",
    score: "счёт",
  },
  cn: {
    "user-profile": "用户资料",
    username: "用户名",
    "email-address": "电子邮件地址",
    "branch-name": "分行名称",
    "team-name": "团队名称",
    "line-manager": "直属经理",
    "performance-dashboard": "绩效仪表板",
    "number-of-certificates": "证书数量",
    "number-of-courses": "课程数量",
    "average-score": "平均分数",
    details: "详情",
    courses: "课程",
    certificates: "证书",
    tests: "测试",
    "course-name": "课程名称",
    "complete-date": "完成日期",
    "certificate-link": "证书链接",
    progress: "进度",
    "enrolled-on": "注册时间",
    "time-spent": "花费时间",
    "category-name": "类别名称",
    "issue-date": "发行日期",
    "expiration-date": "到期日期",
    "user-not-exist": "用户不存在",
    "user-not-found": "未找到用户",
    certificate: "证书",
    score: "分数",
  },
};

export const LearningUserProfileLocalePath = "LearningUserProfile";
