import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  unitCard: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: "12px",
    color: theme.palette.text.primary,
    display: "flex",
    flexDirection: "column",
    width: "314px",
    cursor: "pointer",
  },
  imageWrapper: {
    width: "100%",
    height: "240px",
  },
  unitImage: {
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "12px 12px 0 0",
  },
  emptyImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.disabled,
    height: "100%",
    borderRadius: "12px 12px 0 0",
  },
  details: {
    padding: "20px 16px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  priceSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  priceText: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontSize: "20px",
    fontWeight: 600,
    color: theme.palette.text.brand_tertiary,
    "& span": {
      color: theme.palette.utility.brand_300,
      fontSize: "14px",
      fontWeight: 500,
    },
  },
  unitName: {
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "28px",
  },
  unitInfo: {
    display: "flex",
    gap: "10px",
    "& span": {
      fontSize: "14px",
      fontWeight: 600,
      color: theme.palette.text.brand_secondary,
    },
  },
  location: {
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  body: {
    padding: "12px 0 0",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  badges: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  unitDetails: {
    display: "flex",
    gap: "8px",
    flexWrap: "wrap",
  },
  detailItem: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.text.tertiary,
  },
  divider: {
    margin: "8px 0",
    backgroundColor: theme.palette.background.secondary,
  },
  footer: {
    display: "flex",
    gap: "12px",
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  dateInfo: {
    display: "flex",
    gap: "8px",
    "& label": {
      fontWeight: 500,
    },
    "& span": {
      fontWeight: 400,
      color: theme.palette.text.tertiary,
    },
  },
}));
