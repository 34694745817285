import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import '../RiskRatingConfigurationView.scss'
import { TabsComponent } from "../../../../Components";
import { PageHeading } from "../../../../SharedComponents";
import { RiskConfigFormsTabs } from "./RiskConfigFormsTabs";

export const ConfigurationFormsView = ({}) => {
  const parentTranslationPath = "RiskRating";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const onTabChanged = (_, tabIndex) => {
    setActiveTabIndex(tabIndex);
  };

  return (
    <div className="propx-view propx-bg RiskRatingView">
      <div className="d-flex-v-center-h-between">
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          headerTitle={"Risk-rating"}
          subTitle={"Risk-rating-desc"}
          wrapperClasses={"px-4 pt-4 w-50"}
          headerTitleClasses={"fz-30  mb-3"}
          subTitleClasses={"fz-12 m-0"}
        />
      </div>
      <div className="d-flex">
        <TabsComponent
          wrapperClasses="mx-4 w-fit miw-fit"
          data={RiskConfigFormsTabs}
          orientation="vertical"
          labelInput="label"
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          themeClasses="theme-propx"
          currentTab={activeTabIndex}
          onTabChanged={onTabChanged}
          withDynamicComponents
          dynamicComponentProps={{
                parentTranslationPath,
            }}
        />
      </div>
    </div>
  );
};
