export const UnitRentedRule = async (item, value, setData, formType, values) => {
    if ((item.field.id === 'is_unit_rented' || item.field.id === 'is_unit-rented') && (!value || value === 'No')) {
        if (values && values.rented_value) {
            if (formType === 1)
                setData('rented_value', '  ');

            else if (formType === 2)
                setData(null, 0, 'rented_value');
        }
    }
};
