import React from 'react';
import { Button } from '@material-ui/core';
import { useIsNewLayout, useTranslate } from '../../../Hooks';
import { useDispatch } from 'react-redux';
import { TOGGLE_LAYOUT } from '../../../store/layout/Actions';

// styles
import useStyles from "./styles";

function SwitchLayoutBtn() {
  const { translate } = useTranslate('HeaderView');
  const dispatch = useDispatch();
  const isNewLayout = useIsNewLayout();

  const styles = useStyles();

  const handleNavigate = () => {
    dispatch(TOGGLE_LAYOUT());
  };

  return (
    <Button
      size='small'
      variant='outlined'
      color={isNewLayout ? 'tertiary' : 'primary'}
      className={styles.button}
      onClick={handleNavigate}
    >
      {translate(!isNewLayout ? 'new-layout' : 'old-layout')}
    </Button>
  );
};

export default SwitchLayoutBtn;
