import React from "react";
import PropTypes from "prop-types";
import { CustomButton } from "../CustomButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { useSelectedTheme } from "../../../Hooks";

// Icons
import { CloseXIcon } from "../../../assets/icons";

// styles
import useStyles from "./styles";

function CustomDialog(props) {
  const {
    open,
    onClose,
    onConfirm,
    title,
    subtitle,
    content,
    confirmText,
    cancelText,
    showActions = true,
    width,
    hideActionsBorder,
    isDisabled,
    ...restProps
  } = props;

  const classes = useStyles({ width, hideActionsBorder });

  const {
    theme: { palette },
  } = useSelectedTheme();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      aria-labelledby="custom-dialog-title"
      classes={{ paper: classes.dialogPaper }}
      {...restProps}
    >
      {title && (
        <DialogTitle id="custom-dialog-title">
          <div className={classes.dialogTitle}>
            {title}

            <CloseXIcon
              onClick={onClose}
              style={{ cursor: "pointer" }}
              width="24"
              height="24"
              fill={palette.foreground.quinary}
            />
          </div>
        </DialogTitle>
      )}

      {subtitle && (
        <Typography variant="body2" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}

      <DialogContent className={classes.dialogContent}>
        {typeof content === "string" ? (
          <DialogContentText>{content}</DialogContentText>
        ) : (
          content
        )}
      </DialogContent>
      {showActions && (
        <DialogActions className={classes.dialogActions}>
          <CustomButton
            boxShadow="xs"
            size="lg"
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            {cancelText || "Cancel"}
          </CustomButton>
          <CustomButton
            boxShadow="xs"
            size="lg"
            variant="contained"
            color="primary"
            onClick={onConfirm}
            disabled={isDisabled}
          >
            {confirmText || "Confirm"}
          </CustomButton>
        </DialogActions>
      )}
    </Dialog>
  );
}

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  showActions: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default CustomDialog;
