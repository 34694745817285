import React, { useState, useEffect, useRef, useReducer } from "react";
import { ButtonBase } from "@material-ui/core";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  Inputs,
  AutocompleteComponent,
  DatePickerComponent,
  Spinner,
} from "../../../../../Components";
import { GetParams, showError, showSuccess } from "../../../../../Helper";
import { AgreementDetailsTable } from "./AgreementDetailsTable";
import {
  GetLookupItemsByLookupTypeName,
  CreateAgreementDetailsAPI,
  GetAgreementDetailsByDeveloperContactIdAPI,
  getProperties,
  GetAllBranches,
} from "../../../../../Services";
import { OrganizationUserSearch } from "../../../../../Services";

export const AgreementDetails = ({
  translationPath,
  parentTranslationPath,
  setActiveTab
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const searchTimer = useRef(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
    search: "",
  });
  const [isLoading, setIsLoading] = useState({
    allAgreementDetails: false,
    agreementsTypes: false,
    addAgreementDetails: false,
    branches: false,
    users: false,
    properties: false,
  });

  const [items, setItems] = useState({
    agreementsTypes: [],
    branches: [],
    users: [],
    properties: [],
    allAgreementDetails: {
      result: [],
      totalCount: 0,
    },
  });

  const [defaultState, setDefaultState] = useState({
    developerContactId: +GetParams("id"),
    agreementTypeId: null,
    agreementStartDate: null,
    agreementDuration: null,
    agreementEndDate: null,
    isMarketingBudgetAllocation: false,
    marketingBudgetAmount: null,
    agreementDetailsProjects: [],
    agreementDetailsSigningBranches: [],
    agreementDetailsAuthorizedSignatories: [],
  });

  const [defaultSelected, setDefaultSelected] = useState({
    agreementType: null,
    marketingBudgetAllocation: null,
    agreementDetailsProjects: [],
    agreementDetailsSigningBranches: [],
    agreementDetailsAuthorizedSignatories: [],
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const reducer2 = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultState,
  });

  const [selected, setSelected] = useReducer(reducer2, {
    ...defaultSelected,
  });

  const getLookupItemsByName = async (lookUpName, key) => {
    setIsLoading((item) => ({ ...item, [key]: true }));
    const result = await GetLookupItemsByLookupTypeName({
      lookUpName,
      pageSize: 50,
      pageIndex: 1,
    });
    if (!(result && result.status && result.status !== 200)) {
      setItems((item) => ({ ...item, [key]: (result && result.result) || [] }));
    } else setItems((item) => ({ ...item, [key]: [] }));
    setIsLoading((loading) => ({ ...loading, [key]: false }));
  };

  const getPropertiesAPI = async (search) => {
    let list = [];
    setIsLoading((loading) => ({ ...loading, properties: true }));
    const rs = await getProperties({
      pageSize: 50,
      pageIndex: 0,
      search,
    });
    if ((rs && rs.data && rs.data.ErrorId) || !rs || !rs.result) {
      setIsLoading((item) => ({ ...item, properties: false }));
      setItems((item) => ({ ...item, properties: list || [] }));
      return;
    } else {
      rs.result.map((valueLoop) => {
        list.push({
          id: valueLoop?.propertyId,
          name: valueLoop?.property?.property_name,
        });
      });
      setItems((item) => ({ ...item, properties: list || [] }));
      setIsLoading((item) => ({ ...item, properties: false }));
    }
  };

  const getAllUsersAPI = async (search) => {
    let list = [];
    setIsLoading((item) => ({ ...item, users: true }));
    const rs = await OrganizationUserSearch({
      pageIndex: 1,
      pageSize: 50,
      name: search,
      userStatusId: 2,
    });
    setIsLoading((item) => ({ ...item, users: false }));

    if (!rs || !rs.result) {
      setItems((item) => ({ ...item, users: list || [] }));
      return;
    }
      rs.result.map((element) => {
          list.push({
            id: element.id,
            name: element.fullName,
            branch: element.branch,
            usersId: element.usersId,
          });
    });

    setItems((item) => ({ ...item, users: list || [] }));
  };

  const getAllBranchesAPI = async (search) => {
    let list = [];

    setIsLoading((item) => ({ ...item, branches: true }));
    const rs = await GetAllBranches({
      pageIndex: 1,
      pageSize: 50,
      branchName: search || "",
    });
    setIsLoading((item) => ({ ...item, branches: false }));

    if (!rs || !rs.result) {
      setItems((item) => ({ ...item, branches: list || [] }));
      return;
    }

    setItems((item) => ({ ...item, branches: rs.result || [] }));
  };

  const GetAgreementDetailsByDeveloperContactId = async (search) => {
    setIsLoading((item) => ({ ...item, allAgreementDetails: true }));
    const rs = await GetAgreementDetailsByDeveloperContactIdAPI(
      +GetParams("id"),
      { pageIndex: filter.pageIndex + 1 , pageSize: filter.pageSize, name: filter.search }
    );

    if (!rs) {
      setItems((item) => ({
        ...item,
        allAgreementDetails: { result: [], totalCount: 0 },
      }));
      return;
    } else {
      setItems((item) => ({
        ...item,
        allAgreementDetails: {
          result: rs?.result || [],
          totalCount: rs.totalCount || 0,
        },
      }));
    }
    setIsLoading((item) => ({ ...item, allAgreementDetails: false }));
  };

  const reloadData = () => {
    setState({ id: "edit", value: defaultState });
    setSelected({ id: "edit", value: defaultSelected });
    GetAgreementDetailsByDeveloperContactId();
  };

  const onChangeHandler = (key, values, selectedObj, selectedValues) => {
    if (!selectedObj) {
      setState({ id: [key], value: values });
    } else {
      setState({ id: [key], value: values });
      setSelected({ id: [selectedObj], value: selectedValues });
    }
  };
  const AddAgreementDetailsHandler = async () => {
    setIsLoading((item) => ({ ...item, addAgreementDetails: true }));
    const res = await CreateAgreementDetailsAPI(state);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t`${translationPath}agreement-details-created-successfully`);
      setIsLoading((item) => ({ ...item, addAgreementDetails: false }));

      reloadData();
    } else {
      showError(t`${translationPath}agreement-details-create-failed`);
      setIsLoading((item) => ({ ...item, addAgreementDetails: false }));
    }
  };

   const  DateWithMonthsDelay= (newDate, months)=>  {
    const date = new Date(newDate);
    date.setMonth(date.getMonth() + months);
    return date;
  }

  const calculationAgreementEndDate = (agreementStartDate , agreementDurationValue) =>
    {
       if(agreementDurationValue && agreementStartDate)
        {
          const agreementStartDateNew =  new Date(agreementStartDate) || null ;
          const dateWithMonths =    DateWithMonthsDelay(agreementStartDateNew, agreementDurationValue) ;
          const updateState = {...state  , agreementStartDate : moment(agreementStartDate).format("YYYY-MM-DDTHH:mm:ss") || null ,  agreementDuration : (parseInt(agreementDurationValue)) || null  , agreementEndDate : (dateWithMonths  &&  moment(dateWithMonths).format("YYYY-MM-DDTHH:mm:ss")) || null}
          setState({id : 'edit' , value : updateState  }) ; 
        } 
        else 
          setState({id :'agreementDuration' ,   value : agreementDurationValue }) 
    };
  

  useEffect(() => {
    reloadData();
  }, [filter]);

  return (
    <div className="agreement-details-deveoloper-wrapper childs-wrapper d-flex-column">
      <div className="w-100 agreement-details-add">
        <Spinner isActive={isLoading.allAgreementDetails} isAbsolute />

        <div className="w-100">
          <div className="part1 mt-2 mb-2">
            <div className="px-2 item">
              <AutocompleteComponent
                key={`agreementType`}
                selectedValues={selected.agreementType}
                idRef={`agreementTypeIdRef`}
                multiple={false}
                inputPlaceholder={t("select-agreement-type")}
                data={items && items.agreementsTypes}
                inputClasses="inputs theme-form-builder"
                displayLabel={(option) =>
                  (option && option.lookupItemName) || ""
                }
                onChange={(e, newValue) => {
                  onChangeHandler(
                    "agreementTypeId",
                    newValue && newValue.lookupItemId,
                    "agreementType",
                    newValue
                  );
                }}
                isLoading={isLoading.agreementsTypes}
                withLoader
                withoutSearchButton
                labelValue={"agreement-type"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onOpen={() => {
                  if (items?.agreementsTypes?.length === 0)
                    getLookupItemsByName("AgreementType", "agreementsTypes");
                }}
              />
            </div>

            <div className="px-2 item">
              <DatePickerComponent
                idRef={"agreementStartDateRef"}
                labelValue={"agreement-start-date"}
                value={
                  state &&
                  state.agreementStartDate &&
                  moment(state.agreementStartDate).isValid() &&
                  moment(state.agreementStartDate).format("YYYY-MM-DDTHH:mm:ss")
                }
                onDateChanged={(newValue) => {
                  onChangeHandler("agreementStartDate", (newValue && (moment(newValue).format("YYYY-MM-DDTHH:mm:ss"))) || null);
                  if(newValue && state.agreementDuration && state.agreementDuration !== '')
                  calculationAgreementEndDate(newValue, parseInt(state.agreementDuration))
                }}
                placeholder={"select-agreement-start-date"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item mt-1">
              <Inputs
                isAttachedInput
                idRef={`agreementDurationMonthsIDRef${1}`}
                labelValue={"agreement-duration-months"}
                value={state.agreementDuration || ""}
                type={"number"}
                min={0}
                onInputChanged={(e) => {
                  let  { value } = e.target;
                  if(value !== '0' && value && value !== '' )
                   calculationAgreementEndDate(state.agreementStartDate,(parseInt(value)));
                  else 
                  onChangeHandler("agreementDuration", null);
                }}
                inputPlaceholder={"enter-agreement-duration-months"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
              <DatePickerComponent
                idRef={"AgreementEndDateRef"}
                value={
                  state &&
                  state.agreementEndDate &&
                  moment(state.agreementEndDate).isValid() &&
                  moment(state.agreementEndDate).format("YYYY-MM-DDTHH:mm:ss")
                }
                labelValue={"agreement-end-date"}
                placeholder={"select-agreement-end-date"}
                onDateChanged={(newValue) => {
                  onChangeHandler("agreementEndDate", (newValue && (moment(newValue).format("YYYY-MM-DDTHH:mm:ss"))) || null);
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                isDisabled={state.agreementStartDate && (state.agreementDuration && state.agreementDuration !=='' )}
              />
            </div>
            <div className="px-2 item mt-1">
              <AutocompleteComponent
                key={`project-name`}
                idRef={`ProjectNameRef`}
                multiple
                inputPlaceholder={t("select-properties")}
                data={items?.properties || []}
                selectedValues={selected.agreementDetailsProjects}
                inputClasses="inputs theme-form-builder"
                chipsLabel={(option) => (option && option.name) || ""}
                displayLabel={(option) => (option && option.name) || ""}
                onChange={(e, newValues) => {
                  const agreementDetailsProjectsListIds =
                    (newValues &&
                      newValues.length &&
                      newValues.map((property) => ({
                        propertyId: property?.id,
                      }))) ||
                    [];
                  onChangeHandler(
                    "agreementDetailsProjects",
                    agreementDetailsProjectsListIds,
                    "agreementDetailsProjects",
                    newValues
                  );
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getPropertiesAPI(value);
                  }, 700);
                }}
                isLoading={isLoading.properties}
                withLoader
                withoutSearchButton
                labelValue={"projects-names"}
                filterOptions={(options) => {
                  const isFind = (id) => selected.agreementDetailsProjects?.findIndex((s) => s.propertyId === id) === -1 || false ;
                 return options.filter((w) => isFind(w.id))
                }}
                getOptionSelected={(option) =>
                  (state &&
                    state.agreementDetailsProjects &&
                    state.agreementDetailsProjects.findIndex(
                      (item) => item?.propertyId === option?.id
                    ) !== -1) ||
                  false 
                }
                filterSelectedOptions
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onOpen={() => {
                  if (items && items.properties?.length === 0)
                    getPropertiesAPI();
                }}
              />
            </div>
            <div className="px-2 item">
              <AutocompleteComponent
                key={`signingBranch`}
                idRef={`signingBranchRef`}
                multiple
                inputPlaceholder={t("select-branches")}
                data={items?.branches || []}
                selectedValues={selected.agreementDetailsSigningBranches}
                inputClasses="inputs theme-form-builder"
                chipsLabel={(option) => (option && option.branchName) || ""}
                displayLabel={(option) => (option && option.branchName) || ""}
                onChange={(e, newValues) => {
                  const branchesIds =
                    (newValues &&
                      newValues.length &&
                      newValues.map((branch) => ({
                        branchId: branch.branchId,
                      }))) ||
                    [];
                  onChangeHandler(
                    "agreementDetailsSigningBranches",
                    branchesIds,
                    "agreementDetailsSigningBranches",
                    newValues
                  );
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getAllBranchesAPI(value);
                  }, 700);
                }}
                filterOptions={(options) => {
                  const isFind = (id) => selected.agreementDetailsSigningBranches?.findIndex((w) => w.branchId === id) === -1 || false ;
                 return options.filter((w) => isFind(w.branchId))
                }}
                getOptionSelected={(option) =>
                  (state &&
                    state.agreementDetailsSigningBranches &&
                    state.agreementDetailsSigningBranches.findIndex(
                      (item) => item?.branchId === option?.branchId
                    ) !== -1) ||
                  false 
                }
                filterSelectedOptions
                isLoading={isLoading.branches}
                withLoader
                withoutSearchButton
                labelValue={"signinges-branches"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onOpen={() => {
                  if (items && items.branches?.length === 0)
                    getAllBranchesAPI();
                }}
              />
            </div>

            <div className="px-2 item mt-1">
              <AutocompleteComponent
                key={`users`}
                idRef={`usersRef`}
                multiple
                inputPlaceholder={t("select-users")}
                data={items && items.users}
                selectedValues={selected.agreementDetailsAuthorizedSignatories}
                inputClasses="inputs theme-form-builder"
                displayLabel={(option) => (option && option.name) || ""}
                chipsLabel={(option) => (option && option.name) || ""}
                onChange={(e, newValues) => {
                  const usersIds =
                    (newValues &&
                      newValues.length &&
                      newValues.map((user) => ({usersId : user.id  ,userId: 0  }))) ||
                    [];
                  onChangeHandler(
                    "agreementDetailsAuthorizedSignatories",
                    usersIds,
                    "agreementDetailsAuthorizedSignatories",
                    newValues
                  );
                }}
                filterOptions={(options) => {
                  const isFind = (id) => selected.agreementDetailsAuthorizedSignatories?.findIndex((w) => w.usersId === id) === -1 || false ;
                 return options.filter((w) => isFind(w.id))
                }}
                getOptionSelected={(option) =>
                  (state &&
                    state.agreementDetailsAuthorizedSignatories &&
                    state.agreementDetailsAuthorizedSignatories.findIndex(
                      (item) => item?.usersId === option?.id
                    ) !== -1) ||
                  false 
                }
                filterSelectedOptions
                isLoading={isLoading.users}
                withLoader
                withoutSearchButton
                labelValue={"authorizedes-signatories"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onOpen={() => {
                  if (items && items.users?.length === 0) getAllUsersAPI();
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getAllUsersAPI(value);
                  }, 700);
                }}
              />
            </div>
            <div className="px-2 item mt-1">
              <AutocompleteComponent
                key={`marketingBudgetAllocation`}
                idRef={`marketingBudgetAllocationRef`}
                multiple={false}
                selectedValues={selected.marketingBudgetAllocation}
                inputPlaceholder={t("marketing-budget-allocation")}
                data={[
                  { key: true, name: "yes" },
                  { key: false, name: "no" },
                ]}
                inputClasses="inputs theme-form-builder"
                displayLabel={(option) =>
                  (option && t(`${translationPath}${option.name}`)) || ""
                }
                withoutSearchButton
                labelValue={"marketing-budget-allocation"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onChange={(e, newValue) => {
                  onChangeHandler(
                    "isMarketingBudgetAllocation",
                    newValue === null ? false : newValue.key,
                    "marketingBudgetAllocation",
                    newValue
                  );
                  if (newValue === null || newValue?.key === false)
                    onChangeHandler("marketingBudgetAmount", null);
                }}
              />
            </div>
            <div className="px-2 mt-2 item">
              {state && state.isMarketingBudgetAllocation && (
                <Inputs
                  idRef={`budget-amount`}
                  labelClasses=""
                  key={`budget-amount`}
                  value={state.marketingBudgetAmount || ""}
                  labelValue={"budget-amount"}
                  onInputChanged={(e) => {
                    const { value } = e.target;
                    onChangeHandler("marketingBudgetAmount", value);
                  }}
                  type={"number"}
                  withNumberFormat
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              )}
            </div>
            <div className="mt-3 d-flex-v-center-h-end flex-wrap">
              <ButtonBase
                className="btns theme-solid"
                onClick={AddAgreementDetailsHandler}
                disabled={isLoading.addAgreementDetails || !(selected.agreementType)}
              >
                <span className="mdi mdi-plus" />
                {t(`${translationPath}add-agreement-details`)}
              </ButtonBase>
            </div>
          </div>
        </div>
      </div>
      <AgreementDetailsTable
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        data={items.allAgreementDetails}
        filter={filter}
        setFilter={setFilter}
        reloadData={reloadData}
        setActiveTab={setActiveTab}
      />
    </div>
  );
};
