export const ReleaseProductTypes = [
    {
      key: 1,
      value: 'CRM',
    },
    {
      key: 2,
      value: 'MyLead',
    },
  ];
