import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@material-ui/core";
import {
  DevelopersCreatedOnFilter,
  DevelopersFiltersComponent,
  DevelopersAdvanceSearch,
} from "../DevelopersViewComponent";
import { GlobalHistory } from "../../../../Helper";

export const DevelopersContactsHeaderView = ({
  parentTranslationPath,
  translationPath,
  dateFilter,
  setDateFilter,
  setIsDatePickerChanged,
  isLoading,
  advanceSearch,
  setActiveActionType,
  activeActionType,
  onFilterValuesChanged,
  filterValuesFromAdvanceSearch,
  setOrderBy,
  formId,
  setFilterSearchDto,
  setDevelopersTableFilter,
  setFilterValuesFromAdvanceSearch,
  setActionButtonsKey,
  orderFilter,
  setIsClearFiltersClicked,
  dateRangeDefault,
  setSearchData,
  activeSelectedAction,
  closeTypeDialogHandler,
  selectedOrderBy,
  setSearchedItem,
  setSelectedOrderBy,
  setIsAdvanceSearchActive ,
  isAdvanceSearchActive , 
  setActiveFormType ,
  orderBy ,
  allFormFields ,
  isAllFormFieldsLoading ,
  selectFieldsOnAdvanceSearch , 
  setSelectFieldsOnAdvanceSearch

}) => {
  const { t } = useTranslation(parentTranslationPath);

  return (
    <div className="d-flex-column">
      <div className="header-section">
        <div className="filter-section">
          <div className="section">
            <ButtonBase
              className="btns theme-solid"
              idRef="addnewDevelopersRef"
              id="addnewDevelopers"
              onClick={() => {
                GlobalHistory.push("/home/developers/add");
              }}
            >
              <span>{t(`${translationPath}add-new-developer`)}</span>
            </ButtonBase>
          </div>
          <div className="section autocomplete-section">
            <DevelopersFiltersComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
              dateRangeDefault={dateRangeDefault}
              setIsDatePickerChanged={setIsDatePickerChanged}
              isLoading={isLoading}
              setIsAdvanceSearchActive={setIsAdvanceSearchActive}
            />

            <DevelopersAdvanceSearch
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              advanceSearch={advanceSearch}
              setActiveActionType={setActiveActionType}
              activeActionType={activeActionType}
              onFilterValuesChanged={onFilterValuesChanged}
              filterValuesFromAdvanceSearch={filterValuesFromAdvanceSearch}
              setOrderBy={setOrderBy}
              formId={formId}
              setFilterSearchDto={setFilterSearchDto}
              setDevelopersTableFilter={setDevelopersTableFilter}
              setSelectedOrderBy={setSelectedOrderBy}
              setIsAdvanceSearchActive={setIsAdvanceSearchActive}
              isAdvanceSearchActive={isAdvanceSearchActive}
              setFilterValuesFromAdvanceSearch={
                setFilterValuesFromAdvanceSearch
              }
              setActiveFormType={setActiveFormType}
              orderFilter={orderFilter}
              setActionButtonsKey={setActionButtonsKey}
              setIsClearFiltersClicked={setIsClearFiltersClicked}
              dateRangeDefault={dateRangeDefault}
              setSearchData={setSearchData}
              setSearchedItem={setSearchedItem}
              setDateFilter={setDateFilter}
              activeSelectedAction={activeSelectedAction}
              closeTypeDialogHandler={closeTypeDialogHandler}
              allFormFields={allFormFields}
              isAllFormFieldsLoading={isAllFormFieldsLoading}
              selectFieldsOnAdvanceSearch={selectFieldsOnAdvanceSearch}
              setSelectFieldsOnAdvanceSearch={setSelectFieldsOnAdvanceSearch}
            />
          </div>
        </div>
        <DevelopersCreatedOnFilter
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          orderFilter={orderFilter}
          selectedOrderBy={selectedOrderBy}
          setOrderBy={setOrderBy}
          setSelectedOrderBy={setSelectedOrderBy}
          orderBy={orderBy}
          formId={formId}
        />
      </div>
    </div>
  );
};
