import React from "react";
import { Avatar } from "@material-ui/core";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { getDownloadableLink } from "../../../../Helper";
import { useIsAr } from "../../../../Hooks";

const useStyles = makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    avatar: {
      width: "40px",
      height: "40px",
      [theme.breakpoints.up('md')]: isAr ? { marginLeft: "12px" } : { marginRight: "12px" },
    },
    fullNameAvatar: {
      border: `0.75px solid ${theme.palette.border.avatarContrast}`,
      color: theme.palette.foreground.quarterary,
      background: theme.palette.background.secondary_hover,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "24px",
    },
    userImg: {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      border: `0.75px solid ${theme.palette.border.avatarContrast}`,
    },
  };
});

function UserAvatar() {
  const styles = useStyles();

  const { loginResponse } = useSelector((state) => state.login);

  return (
    <>
      {loginResponse && loginResponse.fullName && !loginResponse.profileImg && (
        <Avatar className={clsx(styles.avatar, styles.fullNameAvatar)}>
          {loginResponse.fullName.match(/\b(\w)/gm).join("")}
        </Avatar>
      )}
      {loginResponse && loginResponse.profileImg && (
        <img
          src={getDownloadableLink(loginResponse.profileImg)}
          alt="User Image"
          className={clsx(styles.userImg, styles.avatar)}
        />
      )}
    </>
  );
}

export default UserAvatar;
