import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./ConsentActionsDialog.scss";
import {
  ButtonBase,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import xCloseIcon from "../../assets/images/icons/x-close-dialog.svg";
import { ConsentRequestDialog } from "../ConsentRequestDialog/ConsentRequestDialog";
import { CheckIsSendConsentAllowed, showWarn } from "../../Helper";

export const ConsentActionsDialog = ({
  onClose,
  open,
  communicationActionHandler,
  activeRecipient,
  recipientType,
}) => {
  const parentTranslationPath = "Shared";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);
  const [isConsentDialogOpen, setIsConsentDialogOpen] = useState(false);
  const [isSendConsentAllowed, setIsSendConsentAllowed] = useState(false);

  const checkIsSendConsentAllowed = async (contactId) =>  {
   const isAllowed = await CheckIsSendConsentAllowed({ contactId })
   setIsSendConsentAllowed(isAllowed);
  };

  useEffect(() => {
    if(activeRecipient?.id) checkIsSendConsentAllowed(activeRecipient.id);
  }, [activeRecipient]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          onClose();
        }}
        className="ConsentActions_Dialog"
      >
        <DialogTitle>
          <div className="w-100">
            <div className="d-flex-v-center-h-between py-2">
              <div className="fw-simi-bold">
                {t(`${translationPath}Client-Communication-Alert`)}
              </div>
              <div className="xCloseIcon pointer" onClick={onClose}>
                <img src={xCloseIcon} />
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="Dialog_Content d-flex-column-center">
            <p className="mt-4 fw-simi-bold fz-14 c-black-light">
              This client has a{" "}
              <span className="pt-3 fw-bold">Blocked DNCR</span> or{" "}
              <span className="pt-3 fw-bold">Unapproved Consent</span> status.
            </p>
            <span className="fz-14 mb-4 fw-bold c-black-light" >What would you like to do?</span>
            <div className="d-flex-column-center gap-16 w-100 mb-5 pb-3">
              <ButtonBase
                className={"btns theme-propx solid fz-12 px-4 py-3 w-60"}
                onClick={communicationActionHandler}
              >
                <span className="mdi mdi-alert  mx-2"></span>
                <span>{t(`${translationPath}Proceed-with-Communication`)}</span>
              </ButtonBase>
              <ButtonBase
                className={"btns theme-propx outlined-colored fz-12 px-4 py-3 w-60"}
                onClick={()=> {
                  if(isSendConsentAllowed) setIsConsentDialogOpen(true);
                  else showWarn(t(`${translationPath}sending-consent-form-is-not-allowed`));
                }}
              >
                <span className="mdi mdi-file-check mx-2"></span>
                <span>{t(`${translationPath}Send-Consent-Form`)}</span>
              </ButtonBase>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {isConsentDialogOpen &&
        <ConsentRequestDialog
          open={isConsentDialogOpen}
          onSave={() => {
            setIsConsentDialogOpen(false);
            onClose();
          }}
          onClose={() => {
            setIsConsentDialogOpen(false)
            onClose();
          }}
          activeRecipient={activeRecipient}
          recipientType={recipientType}
        />
      }
    </div>
  );
};
