import React from "react";

function ArrowNarrowRight({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M13.67 5.061c-.222.081-.42.248-.542.455-.092.156-.108.229-.107.484 0 .212.022.341.074.44.04.077 1.066 1.135 2.279 2.35L17.579 11h-6.843c-5.683 0-6.874.009-7.026.055a1.02 1.02 0 0 0-.582.461c-.091.156-.108.23-.108.484 0 .256.016.328.111.489.125.213.318.375.539.454.123.044 1.387.054 7.03.055l6.879.002-2.205 2.21c-1.213 1.216-2.239 2.273-2.279 2.35-.052.099-.074.228-.074.44-.001.258.015.327.112.492.13.222.369.409.603.473.202.054.53.024.709-.066.185-.092 6.292-6.192 6.428-6.419.091-.152.107-.226.107-.48s-.016-.328-.107-.48c-.061-.103-1.442-1.512-3.22-3.285-2.836-2.828-3.131-3.11-3.31-3.17a1.015 1.015 0 0 0-.673-.004"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default ArrowNarrowRight;
