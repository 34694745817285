import { config } from '../../config/config';
import { HttpServices } from '../../Helper';

export const GetAllClientSegmantationsAPI = async ({pageIndex, pageSize , search}) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/ClientSegmentation/GetAllClientSegmentations?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetClientSigmantationById = async (id) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/ClientSegmentation/GetClientSegmentationById/${id}`
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
}; 


export const CreateClientSigmantationServices = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/ClientSegmentation/CreateClientSegmentation`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateClientSigmantationServices = async (clientSegmentationId , body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/ClientSegmentation/UpdateClientSegmentation?clientSegmentationId=${clientSegmentationId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const DeleteClientSegmentation = async (clientSegmentationId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/ClientSegmentation/DeleteClientSegmentation?clientSegmentationId=${clientSegmentationId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};


