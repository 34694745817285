import React, { memo, useState, useCallback, useEffect } from "react";
import Collapse from "@material-ui/core/Collapse";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Divider,
  Select,
  MenuItem,
  Icon,
} from "@material-ui/core";
import clsx from "clsx";
import { PropTypes } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { useHistory } from "react-router-dom";
import { useIsDesktop } from "../../../Hooks";
import { GlobalHistory } from "../../../Helper";
import { GetMyActivities } from "../../../Services";
import { CalendarV2 } from "../CalendarComponent/CalendarV2";
import { ActivitiesManagementDialog } from "../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog";

import "./EventsComponent.scss";

const translationPath = "eventsView.";
const DropdownIcon = "icon-down-arrow";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const EventsComponent = memo(({ isOpen, top, onClose }) => {
  const { t } = useTranslation("HeaderView");
  const { isDesktop } = useIsDesktop();
  const [date, setDate] = useState(new Date());
  const [openDialog, setOpenDialog] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [activity, setActivity] = useState(null);
  const [events, setEvents] = useState([]);
  const { isDarkMode } = useSelector((state) => state.theme);
  const [month, setMonth] = useState(months[date.getMonth()]);
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const startYear = 2017;
  const endYear = currentYear + 10;
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (val, index) => startYear + index
  );

  const history = useHistory();

  const getMyActivities = useCallback(async () => {
    setIsLoading(true);
    const startDate = moment(
      new Date(
        new Date(currentMonth).getFullYear(),
        new Date(currentMonth).getMonth() - 1,
        1
      )
    ).format();
    const endDate = moment(
      new Date(
        new Date(currentMonth).getFullYear(),
        new Date(currentMonth).getMonth() - 1 + 1,
        0
      )
    ).format();

    const result = await GetMyActivities(startDate, endDate);

    if (!((result && result.data && result.data.ErrorId) || !result)) {
      if (!result) return;
      const newEvents = [];
      const dates = [];
      if (result && result[0]) {
        result.map((item) => {
          dates.push(+moment(item.activityDate).format("D"));
          newEvents.push({
            hour: moment(item.activityDate).format("hh:mm"),
            date: item.activityDate,
            subject: item.subject,
            activity: item,
          });
        });
      }
      setSelectedDates(dates);
      setEvents(newEvents);
    }
    setIsLoading(false);
  }, [currentMonth]);

  useEffect(() => {
    getMyActivities();
  }, [getMyActivities, currentMonth]);
  const onMonthChange = useCallback((value) => {
    setCurrentMonth(value);
  }, []);

  const useStyles = makeStyles((theme) => ({
    mobile: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    menuContainer: {
      width: "328px",
      maxWidth: "450px",
      maxHeight: "640px",
      position: "relative",
    },
    buttonStyle: {
      height: "40px",
      fontFamily: "Inter",
      padding: "10px 14px",
      fontSize: "14px",
      fontWeight: 600,
      borderRadius: "8px",
      lineHeight: "20px",
      color: "#FFF",
    },
    secondaryButtonStyle: {
      borderColor: theme.palette.border.main,
      color: theme.palette.text.secondary,
    },
    actions: {
      display: "flex",
      gap: "12px",
      padding: "16px",
      flexDirection: "row-reverse",
    },
    dropdownIcon: {
      cursor: "pointer",
      color: theme.palette.foreground.quarterary,
      fontSize: "20px",
    },
    divider: { borderColor: theme.palette.border.secondary },
    select: {
      color: theme.palette.text.secondary,
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: 600,
      width: "135px",
      height: "40px",
      padding: "10px 14px",
      borderRadius: "8px",
      border: `1px solid ${theme.palette.border.main}`,
      background: "transparent",
      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    },
    selectsContainer: {
      display: "flex",
      gap: "12px",
      padding: "12px 24px",
      margin: "16px 0",
      justifyContent: "space-between",
      position: "absolute",
      zIndex: "100",
      top: "50px",
    },
    menuPaper: {
      maxHeight: 447,
    },
  }));

  const styles = useStyles();

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  useEffect(() => {
    if (year && month) {
      setDate(
        new Date(
          year,
          months?.findIndex((el) => el === month)
        )
      );
    }
  }, [month, year]);

  return (
    <>
      <div className={!isDesktop ? styles.mobile : ""}>
        <Box
          className={styles.menuContainer}
          style={{
            height:
              events?.filter(
                (item) =>
                  item?.date &&
                  moment(item.date).format("D") === moment(date).format("D")
              )?.length > 0
                ? "620px"
                : "516px",
          }}
        >
          <Collapse
            in={isOpen}
            className="collapses absolute-collapse activity-events-wrapper"
            style={{ top, width: "100%" }}
          >
            <Box className={styles.selectsContainer}>
              {/* <MonthSelect /> */}
              <Select
                MenuProps={{
                  PaperProps: {
                    elevation: 0,
                  },
                }}
                standard="standard"
                labelId="month-select-label"
                value={month}
                onChange={handleMonthChange}
                label="Month"
                className={styles.select}
                IconComponent={() => (
                  <Icon className={clsx(DropdownIcon, styles.dropdownIcon)} />
                )}
              >
                {months.map((month, index) => (
                  <MenuItem key={index} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </Select>

              {/* <YearsSelect /> */}
              <Select
                standard="standard"
                labelId="year-select-label"
                value={year}
                MenuProps={{
                  PaperProps: {
                    elevation: 0,
                  },
                  classes: { paper: styles.menuPaper },
                }}
                onChange={handleYearChange}
                label="Year"
                className={styles.select}
                IconComponent={() => (
                  <Icon className={clsx(DropdownIcon, styles.dropdownIcon)} />
                )}
              >
                {years.map((year, index) => (
                  <MenuItem key={index} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <CalendarV2
              selectedDateChanged={(newDate) => setDate(new Date(newDate))}
              selectedDate={date}
              withNumber
              onMonthChange={onMonthChange}
              activities={events}
              events={{ selectedDays: selectedDates }}
              wrapperClasses="transparent-calender"
            />

            <Divider
              style={{
                margin: "40px 24px 0",
                display:
                  events.filter(
                    (item) =>
                      moment(item.date).format("D") === moment(date).format("D")
                  ).length > 0
                    ? "block"
                    : "none",
              }}
              className={styles.divider}
            />

            <div
              style={{
                display:
                  events.filter(
                    (item) =>
                      moment(item.date).format("D") === moment(date).format("D")
                  ).length > 0
                    ? "block"
                    : "none",
              }}
            >
              <div>
                {events.filter(
                  (item) =>
                    moment(item.date).format("D") === moment(date).format("D")
                ).length > 0 && (
                  <div className="new-events-wrapper">
                    <span>{moment(date).format("DD/MM/YYYY")}</span>

                    {events
                      .filter(
                        (item) =>
                          moment(item.date).format("D") ===
                          moment(date).format("D")
                      )
                      .map((item, index) => (
                        <div className="event-element-container">
                          <div
                            key={`notifications${index + 1}`}
                            className="event-container"
                          >
                            <div className="text">
                              <div className="event-dot"></div>
                              <span>{item.subject}</span>
                            </div>
                            <label>{item.hour}</label>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>

            <Box
              style={{
                marginTop:
                  events.filter(
                    (item) =>
                      moment(item.date).format("D") === moment(date).format("D")
                  ).length > 0
                    ? "0"
                    : "43px",
              }}
            >
              <Divider className={styles.divider} />

              <Box className={styles.actions}>
                <Button
                  className={clsx(styles.buttonStyle)}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push("/home/monthly-calendar-view");
                  }}
                >
                  {t(`${translationPath}view-events`)}
                </Button>
                <Button
                  className={clsx(
                    styles.buttonStyle,
                    styles.secondaryButtonStyle
                  )}
                  variant="outlined"
                  onClick={onClose}
                >
                  {t(`cancel`)}
                </Button>
              </Box>
            </Box>
          </Collapse>
        </Box>

        {openDialog && (
          <ActivitiesManagementDialog
            open={openDialog}
            activeItem={activity && activity}
            onSave={() => {
              setOpenDialog(false);
              setActivity(null);
            }}
            close={() => {
              setOpenDialog(false);
              setActivity(null);
            }}
            parentTranslationPath="ActivitiesView"
            translationPath=""
            isGeneralDialog
          />
        )}
      </div>
    </>
  );
});
EventsComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  top: PropTypes.number.isRequired,
};

export default EventsComponent;
