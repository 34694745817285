import React, { Children, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from "@material-ui/core";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { LoadableImageComponant } from '../../../../../../../Components';
import { ContactTypeEnum, LoadableImageEnum } from '../../../../../../../Enums';
import { ButtonBase, Chip, Button } from '@material-ui/core';
import { CopyToClipboardComponents } from '../../../../../../../ReusableComponents/UtilityComponents';
import {
  AccountBox,
  CalendarToday,
  CalendarViewDay,
  Filter1,
  Minimize,
  MonetizationOn,
  Person,
  Remove,
  Today,
} from '@material-ui/icons';
import { Divider } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    textTransform: 'capitalize'
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export const HistoryStepper = ({ data, t }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [date, setDate] = useState(null);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation='vertical'>
        {Children.toArray(
          data?.map((owner) => (
            <Step>
              <StepLabel>
                <h6>{`${moment(owner.tenantsDetails[0].startLeasingDate).format(
                  'MM/DD/YYYY'
                )} - ${moment(owner.tenantsDetails[0].endLeasingDate).format(
                  'MM/DD/YYYY'
                )}`}</h6>
                <div className={classes.container}>
                  <LoadableImageComponant
                    type={LoadableImageEnum.image.key}
                    classes='user-cover-image m-1'
                    // alt={t(`${translationPath}user-image`)}
                    src={ContactTypeEnum.man.defaultImg}
                  />
                  <strong>{owner.ownerName}</strong>
                  <Chip label={owner.leadType} color='primary' size='small' />
                  <span>
                    {t('contact-id')}:{' '}
                    <CopyToClipboardComponents
                      data={owner.leadId.toString()}
                      childrenData={owner.leadId.toString()}
                    />
                  </span>
                </div>
              </StepLabel>
              <StepContent>
                <div className='stepper-border p-4'>
                  {Children.toArray(
                    owner?.tenantsDetails?.map((tenant) => (
                      <div>
                        <Typography component={'h6'} className='d-flex g-c-1'>
                          <span className='flex'>
                            <CalendarToday fontSize='small' className='mx-1' />
                            <strong className='date my-auto'>
                              {moment(tenant.startLeasingDate).format(
                                'MM/DD/YYYY'
                              )}
                            </strong>
                          </span>
                          <Remove fontSize='small' />
                          <span className='flex'>
                            <Today fontSize='small' className='mx-1' />
                            <strong className='date'>
                              {moment(tenant.endLeasingDate).format(
                                'MM/DD/YYYY'
                              )}
                            </strong>
                          </span>
                          <Chip
                            size='small'
                            color='success'
                            label={tenant.leasingStatus}
                          />
                        </Typography>
                        <div className='d-flex-h-between fa-end g-c-1 p-3'>
                          <Typography>
                            <Typography
                              component={'span'}
                              variant='body2'
                              color='textSecondary'
                              className='flex my-1'
                            >
                              <Person fontSize='small' className='mr-1' />
                              {t('tenant-name')}
                            </Typography>
                            <Typography component={'span'} variant='body1'>
                              {tenant.tenantName}
                            </Typography>
                          </Typography>
                          <Divider
                            orientation='vertical'
                            className='divider-height'
                          />
                          <Typography>
                            <Typography
                              component={'span'}
                              variant='body2'
                              color='textSecondary'
                              className='flex my-1'
                            >
                              <AccountBox fontSize='small' className='mr-1' />
                              {t('lead-id')}
                            </Typography>
                            <Typography component={'span'} variant='body1'>
                              <CopyToClipboardComponents
                                data={tenant.leadId}
                                childrenData={tenant.leadId}
                              />
                            </Typography>
                          </Typography>
                          <Divider
                            orientation='vertical'
                            className='divider-height'
                          />
                          <Typography>
                            <Typography
                              component={'span'}
                              variant='body2'
                              color='textSecondary'
                              className='flex my-1'
                            >
                              <MonetizationOn
                                fontSize='small'
                                className='mr-1'
                              />
                              {t('monthly-rental-price')}
                            </Typography>
                            <Typography component={'span'} variant='body1'>
                              {tenant.rentPerMonth?.toFixed(2)} AED
                            </Typography>
                          </Typography>
                          <Divider
                            orientation='vertical'
                            className='divider-height'
                          />
                          <Typography>
                            <Typography
                              component={'span'}
                              variant='body2'
                              color='textSecondary'
                              className='flex my-1'
                            >
                              <Filter1 fontSize='small' className='mr-1' />
                              {t('yearly-rental-price')}
                            </Typography>
                            <Typography component={'span'} variant='body1'>
                              {tenant.rentPerYear} AED
                            </Typography>
                          </Typography>
                        </div>
                      </div>
                    ))
                  )}
                  <div className={classes.actionsContainer}>
                    <div>
                    <Tooltip
                        title={`${t("previous-owner-tooltip")}`}
                        aria-label="edit"
                        arrow
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          className={classes.button}
                          disabled={activeStep === data.length - 1}
                        >
                          {t("previous-owner")}
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title={`${t("next-owner-tooltip")}`}
                        aria-label="edit"
                        arrow
                      >
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.button}
                        >
                          {t("next-owner")}
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))
        )}
      </Stepper>
    </div>
  );
};
