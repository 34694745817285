import React, { useCallback, useEffect, useRef, useState } from 'react';
import './SalesTransactionsView.scss';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Inputs,
  Spinner,
  Tables,
  PermissionsComponent,
  AutocompleteComponent,
  SelectComponet,
} from '../../../Components';
import {
  AMLStatusEnum,
  TableActions,
  TableFilterTypesEnum,
  UnitsFilterStatusEnum,
} from '../../../Enums';
import { GlobalHistory, returnPropsByPermissions } from '../../../Helper';
import {
  GetAllSaleTransactions,
  lookupItemsGet,
  getProperties,
  GetAllSaleAgentsServices,
  GetAllBranches,
} from '../../../Services';
import { SalesTransactionsPermissions } from '../../../Permissions';
import { GlobalOrderFilterActions } from '../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import { ActiveItemActions } from '../../../store/ActiveItem/ActiveItemActions';
import { useTitle } from '../../../Hooks';

const parentTranslationPath = 'SalesTransactionsView';
const translationPath = '';

export const SalesTransactionsView = () => {
  const searchTimer = useRef(null);
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t(`${translationPath}sales-transactions`));
  const [isLoading, setIsLoading] = useState({
    salesTransactions: false,
    properties: false,
    communitie: false,
    saleAgentsAPI: false,
    branch: false,
  });
  const [searchInput, setSearchInput] = useState('');
  const [sortBy, setSortBy] = useState(null);
  const statusEnum = [
    'Available',
    'ReservedLeased',
    'Leased',
    'LeasedByThirdParty',
    'ReservedSale',
    'Sale',
    'SaleByThirdParty',
    'Blocked',
    'Upcoming',
    'Draft',
    'PendingReservedLease',
    'PendingLeased',
    'PendingReservedSale',
    'PendingSale',
  ].map((value, i) => ({ key: i + 1, value }));
  const [salesTransactions, setSalesTransactions] = useState({
    result: [],
    totalCount: 0,
  });
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const dispatch = useDispatch();
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy:
      (orderFilter.salesTransactionsFilter &&
        orderFilter.salesTransactionsFilter.filterBy) ||
      null,
    orderBy:
      (orderFilter.salesTransactionsFilter &&
        orderFilter.salesTransactionsFilter.orderBy) ||
      null,
  });
  const [properties, setProperties] = useState({ result: [], totalCount: 0 });
  const [communities, setCommunities] = useState({ result: [], totalCount: 0 });
  const [saleAgents, setSaleAgents] = useState({ result: [], totalCount: 0 });
  const [branchList, setBranchList] = useState({
    result: [],
    totalCount: 0,
  });
  const [tableFilterData, setTableFilterData] = useState([]);

  const [orderBy, setOrderBy] = useState(
    selectedOrderBy.filterBy
      ? selectedOrderBy
      : { filterBy: null, orderBy: null }
  );
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    unitRefNumber: '',
    propertyId: null,
    communityId: null,
    buyerAgentId: null,
    sellerAgentId: null,
    tanentAgentId: null,
    landLordAgentId: null,
    orderBy: (orderBy && orderBy.orderBy) || null,
    filterBy: (orderBy && orderBy.filterBy) || null,
    buyerBranchId: null,
    sellerBranchId: null,
  });
  const [selected, setSelected] = useState({
    property: '',
    community: '',
    buyerAgent: '',
    sellerAgent: '',
    tanentAgent: '',
    landLordAgent: '',
    buyerBranch: '',
    sellerBranch: '',
  });
  const headerData = [
    {
      id: 1,
      label: 'ref-no',
      input: 'unitRefNumber',
      component: (item) => <span>{item && item.unitReferenceNo}</span> || '',
    },
    {
      id: 2,
      label: 'property',
      input: 'propertyName',
    },
    {
      id: 3,
      label: 'status',
      input: 'status',
      withSelectFilter: true,
    },
    {
      id: 4,
      label: 'community',
      input: 'communityName',
    },
    {
      id: 5,
      label: 'unit-no',
      input: 'unitNumber',
    },
    {
      id: 6,
      label: 'buyer',
      input: 'buyerName',
      component: (item) => (
        <span>
          {item.buyersNames &&
            item.buyersNames.map((element, index) => (
              <span key={`buyersNamesRef${index + 1}`}>
                {`${element}${
                  (index < item.buyersNames.length - 1 && ', ') || ''
                }`}
              </span>
            ))}
        </span>
      ),
    },
    {
      id: 7,
      label: 'buyer-agent',
      input: 'buyerAgent',
      component: (item) =>
        (
          <span>
            {item &&
              item.buyerAgents &&
              item.buyerAgents.map((item) => `${item.agentName} , `)}
          </span>
        ) || '',
    },
    {
      id: 8,
      label: 'buyer-agent-staff-id',
      input: 'buyerStaffId',
      component: (item) =>
        (
          <span>
            {item &&
              item.buyerAgents &&
              item.buyerAgents.map((item) =>
                item.staffId ? `${item.staffId} ,  ` : 'N/A ,  '
              )}
          </span>
        ) || '',
    },
    {
      id: 9,
      label: 'seller',
      input: 'sellerName',
    },
    {
      id: 10,
      label: 'seller-agent',
      input: 'sellerAgent',
      component: (item) =>
        (
          <span>
            {item &&
              item.sellerAgents &&
              item.sellerAgents.map((item) => `${item.listingAgentName}, `)}
          </span>
        ) || '',
    },
    {
      id: 11,
      label: 'seller-agent-staff-id',
      input: 'sellerStaffId',
      component: (item) =>
        (
          <span>
            {item &&
              item.sellerAgents &&
              item.sellerAgents.map((item) =>
                item.staffId ? `${item.staffId} ,  ` : 'N/A ,  '
              )}
          </span>
        ) || '',
    },
    {
      id: 12,
      isSortable: true,
      label: 'selling-price',
      input: 'sellingPrice',
    },
    {
      id: 13,
      isSortable: true,
      label: 'transaction-date',
      input: 'transactionEntryDate',
      isDate: true,
    },
    {
      id: 14,
      isSortable: true,
      label: 'transaction-id',
      input: 'unitTransactionId',
    },
    {
      id: 15,
      label: 'buyer-branch',
      input: 'buyerBranch',
    },
    {
      id: 16,
      label: 'seller-branch',
      input: 'sellerBranch',
    },
    {
      id:17,
      label: 'notes',
      input: 'invoiceNotes',
    }
  ];

  const onFilterValuesChanged = (newValue) => {
    const localFilterDto = filter || {};

    if (localFilterDto && localFilterDto.createdOn) {
      // localFilterDto.dateTo = moment(localFilterDto.createdOn).format('YYYY-MM-DDTHH:mm:ss');
      // localFilterDto.dateFrom = moment(localFilterDto.createdOn).format('YYYY-MM-DDTHH:mm:ss');
    }
    if (newValue) {
      Object.values(newValue)
        .filter((item) => item.searchableKey || item.displayPath)
        .map((item) => {
          if (localFilterDto[item.displayPath] || item.value) {
            if (item.displayPath === 'status') {
              localFilterDto['statusId'] = item.value;
            } else {
              localFilterDto[item.displayPath] = item.value;
            }
          }
          // else if (item.value) localFilterDto[item.displayPath] = item.value;

          return undefined;
        });
    }
    setFilter(() => ({ ...localFilterDto }));
  };
  
  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      if (actionEnum === TableActions.openFile.key) {
        localStorage.setItem(
          'saleTransactionDetailsId',
          item.saleTransactionDetailsId
        );
        dispatch(ActiveItemActions.activeItemRequest(item));
        GlobalHistory.push(
          `/home/sales-transactions/transaction-profile?unitId=${item.unitId}&unitTransactionId=${item.unitTransactionId}&actionType=2&propertyId=${item.propertyId}`
        );
      } else if (actionEnum === TableActions.editText.key) {
        localStorage.setItem(
          'saleTransactionDetailsId',
          item.saleTransactionDetailsId
        );
        dispatch(ActiveItemActions.activeItemRequest(item));
        GlobalHistory.push(
          `/home/sales-transactions/transaction-profile?unitId=${item.unitId}&unitTransactionId=${item.unitTransactionId}&actionType=1`
        );
      }
    },
    []
  );
  const searchHandler = (search) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({ ...item, pageIndex: 0, unitRefNumber: search }));
    }, 700);
    setSearchInput(search);
  };

  const getAllSalesTransactions = useCallback(async () => {
    if (
      returnPropsByPermissions(
        SalesTransactionsPermissions
          .ViewAndSearchInMainAccountsSalesTransactionsPage.permissionsId
      )
    ) {
      setIsLoading((loading) => ({ ...loading, salesTransactions: true }));
      const res = await GetAllSaleTransactions({
        ...filter,
        pageIndex: filter.pageIndex + 1,
      });
      if (!(res && res.status && res.status !== 200)) {
        setSalesTransactions({
          result: res && res.result,
          totalCount: res && res.totalCount,
        });
      } else {
        setSalesTransactions({
          result: [],
          totalCount: 0,
        });
      }
      setIsLoading((loading) => ({ ...loading, salesTransactions: false }));
    }
  }, [filter]);

  const getBranch = async (branchName) => {
    setIsLoading((loading) => ({ ...loading, branch: true }));

    const res = await GetAllBranches({
      pageSize: 25,
      pageIndex: 1,
      branchName,
    });
    if (!(res && res.status && res.status !== 200)) {
      setBranchList({
        result: res.result,
        totalCount: res.totalCount,
      });
    } else {
      setBranchList({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((loading) => ({ ...loading, branch: false }));
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex, search: searchInput }));
  };
  const onPageSizeChanged = (pageSize) => {
    setSearchInput('');
    setFilter((item) => ({
      ...item,
      pageIndex: 0,
      pageSize,
      search: '',
    }));
  };
  useEffect(() => {
    getAllSalesTransactions();
  }, [filter, getAllSalesTransactions]);
  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
    },
    []
  );

  const GetAllowSalesTransactionDetails = () => {
    if (
      returnPropsByPermissions(
        SalesTransactionsPermissions.ViewTransactionsDetails.permissionsId
      )
    )
      return true;
    if (
      returnPropsByPermissions(
        SalesTransactionsPermissions.ViewContactDetails.permissionsId
      )
    )
      return true;
    if (
      returnPropsByPermissions(
        SalesTransactionsPermissions.ViewReferenceDetails.permissionsId
      )
    )
      return true;
    if (
      returnPropsByPermissions(
        SalesTransactionsPermissions.ViewEarning.permissionsId
      )
    )
      return true;
    if (
      returnPropsByPermissions(
        SalesTransactionsPermissions.ViewInvoicesInSalesTransactions
          .permissionsId
      )
    )
      return true;
    if (
      returnPropsByPermissions(
        SalesTransactionsPermissions.ViewChequeRequestDetails.permissionsId
      )
    )
      return true;
    if (
      returnPropsByPermissions(
        SalesTransactionsPermissions.ViewDecumentInSalesTransactions
          .permissionsId
      )
    )
      return true;
    return false;
  };
  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };
  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        salesTransactionsFilter: {
          filterBy: selectedOrderBy.filterBy,
          orderBy: selectedOrderBy.orderBy,
        },
      })
    );
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
  };

  const getAllProperties = useCallback(async (searchItem) => {
    setIsLoading((loading) => ({ ...loading, properties: true }));
    const res = await getProperties({
      pageSize: 10,
      pageIndex: 0,
      search: searchItem || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      setProperties({
        result: res && res.result,
        totalCount: res && res.totalCount,
      });
    } else {
      setProperties({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((loading) => ({ ...loading, properties: false }));
  }, []);

  const getAllCommunitie = useCallback(async (searchItem) => {
    setIsLoading((loading) => ({ ...loading, communitie: true }));
    const res = await lookupItemsGet({
      pageIndex: 1,
      pageSize: 10,
      lookupTypeId: 19,
      lookupTypeName: 'community',
      searchedItem: searchItem || '',
    });

    if (!(res && res.status && res.status !== 200)) {
      setCommunities({
        result: res && res.result,
        totalCount: res && res.totalCount,
      });
    } else {
      setCommunities({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((loading) => ({ ...loading, communitie: false }));
  }, []);
  const onClearedAllFiltersClick = useCallback(() => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: null, orderBy: null }));
    setOrderBy({ filterBy: null, orderBy: 2 });
    setFilter({
      buyerBranchId: null,
      sellerBranchId: null,
      pageIndex: 0,
      pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
      unitRefNumber: '',
      propertyId: null,
      communityId: null,
      buyerAgentId: null,
      sellerAgentId: null,
      tanentAgentId: null,
      landLordAgentId: null,
    })
    setSelected({
      property: '',
      community: '',
      buyerAgent: '',
      sellerAgent: '',
      tanentAgent: '',
      landLordAgent: '',
      buyerBranch: '',
      sellerBranch: '',
    })
    setSearchInput('')
  });
  const getAllSaleAgentsAPI = useCallback(async (searchItem) => {
    setIsLoading((loading) => ({ ...loading, saleAgentsAPI: true }));
    const res = await GetAllSaleAgentsServices({
      pageSize: 10,
      pageIndex: 0,
      search: searchItem || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      setSaleAgents({
        result: res && res.result,
        totalCount: res && res.totalCount,
      });
    } else {
      setSaleAgents({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((loading) => ({ ...loading, saleAgentsAPI: false }));
  }, []);

  useEffect(() => {
    getAllCommunitie();
    getAllProperties();
    getAllSaleAgentsAPI();
    getBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sortBy)
      setFilter((item) => ({
        ...item,
        filterBy: sortBy.filterBy,
        orderBy: sortBy.orderBy,
      }));
  }, [sortBy]);

  useEffect(() => {
    if (orderBy)
      setFilter((item) => ({
        ...item,
        filterBy: orderBy.filterBy,
        orderBy: orderBy.orderBy,
      }));
  }, [orderBy]);

  useEffect(() => {
    setTableFilterData(
      headerData.map((column) => ({
        key: column.key || column.fieldKey || column.id,
        filterType:
          (column.isDate && TableFilterTypesEnum.datePicker.key) ||
          (column.withSelectFilter && TableFilterTypesEnum.selectOption.key) ||
          TableFilterTypesEnum.textInput.key,
        isHiddenFilter: column.isHiddenFilter,
        textInputType: column.textInputType,
        textInputMax: column.textInputMax,
        textInputMin: column.textInputMin,
        displayPath: column.input,
      }))
    );
  }, []);

  return (
    <div className="view-wrapper">
      <Spinner isActive={isLoading.salesTransactions} />
      <div className="d-flex-column">
        <div className="header-section">
          <div className="filter-section">
            <div className="section" />
            <div className="section autocomplete-section">
              <div className="d-flex-column px-2 w-100 p-relative">
                <PermissionsComponent
                  permissionsList={Object.values(SalesTransactionsPermissions)}
                  permissionsId={
                    SalesTransactionsPermissions
                      .ViewAndSearchInMainAccountsSalesTransactionsPage
                      .permissionsId
                  }
                >
                  <div className="d-flex-column w-100">
                    <Inputs
                      idRef="salesTransactionsSearchRef"
                      value={searchInput}
                      beforeIconClasses="mdi mdi-magnify mdi-24px c-gray-primary"
                      onInputChanged={(e) => {
                        searchHandler(e.target.value);
                      }}
                      inputPlaceholder={t(`${translationPath}seacrh_ref_No`)}
                      translationPath={translationPath}
                      parentTranslationPath={parentTranslationPath}
                    />
                  </div>
                  <div className="d-inline-flex-column pl-4-reversed">
                    <div className="d-flex mb-1">
                      <div className="autocomplete-wrapper mr-1-reversed">
                        <AutocompleteComponent
                          selectedValues={selected.property || ''}
                          idRef="propertyIdRef"
                          isLoading={isLoading.properties}
                          multiple={false}
                          data={(properties && properties.result) || []}
                          displayLabel={(option) => option.property?.property_name || ''}
                          withoutSearchButton
                          inputPlaceholder={t(`${translationPath}property`)}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          onChange={(event, newValue) => {
                            setFilter((item) => ({
                              ...item,
                              propertyId:
                                (newValue && newValue.propertyId) || null,
                              pageIndex: 0,
                            }));
                            setSelected((item)=>({
                              ...item,
                              property: (newValue && newValue) || '',
                            }))
                          }}
                          onInputKeyUp={(e) => {
                            const { value } = e.target;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              getAllProperties(value);
                            }, 700);
                          }}
                        />
                      </div>
                      <AutocompleteComponent
                        selectedValues={selected.community}
                        idRef="communityRef"
                        multiple={false}
                        isLoading={isLoading.communitie}
                        data={communities && communities.result}
                        displayLabel={(option) =>
                          (option && option.lookupItemName) || ''
                        }
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        inputPlaceholder={t(`${translationPath}community`)}
                        onChange={(event, newValue) => {
                          setFilter((item) => ({
                            ...item,
                            communityId:
                              (newValue && newValue.lookupItemId) || null,
                            pageIndex: 0,
                          }));
                          setSelected((item)=>({
                            ...item,
                            community: (newValue && newValue) || '',
                          }))
                        }}
                        onInputKeyUp={(e) => {
                          const { value } = e.target;
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getAllCommunitie(value);
                          }, 700);
                        }}
                      />
                    </div>
                    <div className="d-flex">
                      <div className="autocomplete-wrapper mr-1-reversed">
                        <AutocompleteComponent
                        selectedValues={selected.buyerAgent}
                          idRef="buyerAgentIdRef"
                          multiple={false}
                          isLoading={isLoading.saleAgentsAPI}
                          withoutSearchButton
                          data={(saleAgents && saleAgents.result) || []}
                          chipsLabel={(option) =>
                            (option && option.agentName) || ''
                          }
                          displayLabel={(option) =>
                            (option && option.agentName) || ''
                          }
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          // withoutSearchButton
                          inputPlaceholder={t(`${translationPath}buyer-agent`)}
                          onChange={(event, newValue) => {
                            setFilter((item) => ({
                              ...item,
                              buyerAgentId:
                                (newValue && newValue.agentId) || null,
                              pageIndex: 0,
                            }));
                            setSelected((item) => ({
                              ...item,
                              buyerAgent: (newValue && newValue) || '',
                            }))
                          }}
                          onInputKeyUp={(e) => {
                            const { value } = e.target;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              getAllSaleAgentsAPI(value);
                            }, 700);
                          }}
                        />
                      </div>
                      <AutocompleteComponent
                      selectedValues={selected.sellerAgent}
                        idRef="sellerAgentToRef"
                        multiple={false}
                        isLoading={isLoading.saleAgentsAPI}
                        inputPlaceholder={t(`${translationPath}seller-agent`)}
                        data={(saleAgents && saleAgents.result) || []}
                        chipsLabel={(option) =>
                          (option && option.agentName) || ''
                        }
                        displayLabel={(option) =>
                          (option && option.agentName) || ''
                        }
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          setFilter((item) => ({
                            ...item,
                            sellerAgentId:
                              (newValue && newValue.agentId) || null,
                            pageIndex: 0,
                          }));
                          setSelected((item) => ({
                            ...item,
                            sellerAgent: (newValue && newValue) || '',
                          }))
                        }}
                        onInputKeyUp={(e) => {
                          const { value } = e.target;
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getAllSaleAgentsAPI(value);
                          }, 700);
                        }}
                      />
                      <AutocompleteComponent
                      selectedValues={selected.buyerBranch}
                        idRef="buyerBranchRef"
                        multiple={false}
                        isLoading={isLoading.saleAgentsAPI}
                        inputPlaceholder={t(`${translationPath}buyer-branch`)}
                        data={branchList.result || []}
                        chipsLabel={(option) =>
                          (option && option.branchName) || ''
                        }
                        displayLabel={(option) =>
                          (option && option.branchName) || ''
                        }
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onInputKeyUp={(event) => {
                          const { value } = event.target;
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getBranch(value);
                          }, 700);
                        }}
                        onChange={(event, newValue) => {
                          setFilter((item) => ({
                            ...item,
                            buyerBranchId:
                              (newValue && newValue.branchId) || null,
                          }));
                          setSelected((item)=>({
                            ...item,
                            buyerBranch: (newValue && newValue) || '',
                          }))
                        }}
                      />
                      <AutocompleteComponent
                      selectedValues={selected.sellerBranch}
                        idRef="sellerBranchRef"
                        multiple={false}
                        isLoading={isLoading.saleAgentsAPI}
                        inputPlaceholder={t(`${translationPath}seller-branch`)}
                        data={branchList.result || []}
                        chipsLabel={(option) =>
                          (option && option.branchName) || ''
                        }
                        displayLabel={(option) =>
                          (option && option.branchName) || ''
                        }
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onInputKeyUp={(event) => {
                          const { value } = event.target;
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getBranch(value);
                          }, 700);
                        }}
                        onChange={(event, newValue) => {
                          setFilter((item) => ({
                            ...item,
                            sellerBranchId:
                              (newValue && newValue.branchId) || null,
                          }));
                          setSelected((item)=>({
                            ...item,
                            sellerBranch: (newValue && newValue) || '',
                          }))
                        }}
                      />
                    </div>
                  </div>
                </PermissionsComponent>
              </div>
              <ButtonBase
              onClick={onClearedAllFiltersClick}
              id="onClearedAllFiltersref"
              className="btns theme-solid bg-danger clear-all-btn max-height"
            >
              <span className="mdi mdi-filter-remove m-1" />
              {t(`${translationPath}clear-filters`)}
            </ButtonBase>
            </div>
          </div>
          <PermissionsComponent
            permissionsList={Object.values(SalesTransactionsPermissions)}
            permissionsId={
              SalesTransactionsPermissions
                .ViewAndSearchInMainAccountsSalesTransactionsPage.permissionsId
            }
          >
            <div className="d-flex px-2">
              <span className="mx-2 mt-1">{t(`${translationPath}select`)}</span>
              <span className="separator-v s-primary s-reverse s-h-25px mt-1" />
              <span className="px-2 d-flex">
                <span className="texts-large mt-1">
                  {t(`${translationPath}order-by`)}:
                </span>
                <div className="px-2">
                  <SelectComponet
                    idRef="filterByRef"
                    data={[
                      {
                        id: 'TransactionEntryDate',
                        filterBy: 'transaction-date',
                      },
                    ]}
                    value={selectedOrderBy.filterBy}
                    wrapperClasses="mb-3"
                    isRequired
                    onSelectChanged={filterByChanged}
                    emptyItem={{
                      value: null,
                      text: 'select-filter-by',
                      isDisabled: false,
                    }}
                    valueInput="id"
                    textInput="filterBy"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className="px-2">
                  <SelectComponet
                    idRef="orderByRef"
                    data={[
                      { id: 1, orderBy: 'ascending' },
                      { id: 2, orderBy: 'descending' },
                    ]}
                    emptyItem={{
                      value: null,
                      text: 'select-sort-by',
                      isDisabled: false,
                    }}
                    value={selectedOrderBy.orderBy}
                    onSelectChanged={orderByChanged}
                    wrapperClasses="mb-3"
                    isRequired
                    valueInput="id"
                    textInput="orderBy"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className="mt-1">
                  <ButtonBase
                    className="btns theme-solid"
                    onClick={orderBySubmitted}
                    id="action_apply"
                    disabled={
                      !selectedOrderBy.filterBy || !selectedOrderBy.orderBy
                    }
                  >
                    <span>{t(`${translationPath}apply`)}</span>
                  </ButtonBase>
                </div>
              </span>
            </div>
          </PermissionsComponent>
        </div>

        <div className="w-100 px-3">
          <PermissionsComponent
            permissionsList={Object.values(SalesTransactionsPermissions)}
            permissionsId={
              SalesTransactionsPermissions
                .ViewAndSearchInMainAccountsSalesTransactionsPage.permissionsId
            }
          >
            <Tables
              data={salesTransactions.result || []}
              headerData={headerData}
              defaultActions={
                GetAllowSalesTransactionDetails()
                  ? [
                      {
                        enum: TableActions.openFile.key,
                      },
                      {
                        enum: TableActions.editText.key,
                      },
                    ]
                  : []
              }
              onPageIndexChanged={onPageIndexChanged}
              onPageSizeChanged={onPageSizeChanged}
              actionsOptions={{
                onActionClicked: tableActionClicked,
              }}
              onFilterValuesChanged={onFilterValuesChanged}
              filterData={tableFilterData}
              isWithFilter
              FilterDisabledButton
              itemsPerPage={filter.pageSize}
              activePage={filter.pageIndex}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              totalItems={salesTransactions.totalCount}
              setSortBy={setSortBy}
              optionFilterName={[
                {
                  key: AMLStatusEnum[6].key,
                  value: AMLStatusEnum[6].name,
                },
                {
                  key: AMLStatusEnum[10].key,
                  value: AMLStatusEnum[10].name,
                },
              ]}
            />
          </PermissionsComponent>
        </div>
      </div>
    </div>
  );
};
