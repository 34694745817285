import React, { useCallback, useState } from 'react';
import moment from 'moment';
import i18next from 'i18next';
import './CampaignRequestCard.scss';
import { useTranslation } from 'react-i18next';
import { PopoverComponent } from '../../../../../Components';
import { TableActions, CampaignStatusEnum } from '../../../../../Enums';
import dotsVertical from '../../../../../assets/images/dots-vertical.svg';

export const CampaignRequestCard = ({
  data,
  translationPath,
  parentTranslationPath,
  onCardClicked,
  actionsList,
  actionClickHandler,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);


  const [itemIndex, setItemIndex] = useState(-1);
  const [selectedPopoverAction, setSelectedPopoverAction] = useState(null);
  const [activeItem, setActiveItem] = useState(null);

  const handlePopoverOpen = useCallback((event, item, index) => {
    setItemIndex(index);
    setActiveItem(item);
    event.stopPropagation();
    event.preventDefault();
    setSelectedPopoverAction(event.currentTarget);
  }, []);

  const handlePopoverClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedPopoverAction(null);
    setItemIndex(-1);
  };


  const cardOnClick = (item) => {
    onCardClicked(item);
    setActiveItem(item);
  };

  const getCampaignStatusIcon = (statusName) => {
    const statusEnumKey = statusName && statusName.split(' ').join('')
    let iconClass = '';
    if (statusEnumKey) {
      const unknownStatusIcon = 'mdi mdi-comment-question-outline';
      iconClass = CampaignStatusEnum[statusEnumKey]? CampaignStatusEnum[statusEnumKey].icon : unknownStatusIcon;
    }
    return iconClass;
  };

  return (
    <div className='campaign-req-card-wrapper'>
      {data &&
        data.result &&
        data.result.map((item, index) => (
          <div
            className='campaign-req-card'
            key={`campaign-reqRef${item.id}`}
            onClick={() => cardOnClick(item)}>
            <div className={`cards-wrapper ${activeItem?.id == item.id? 'active-req-card':''}`}>
              <div className='cards-header'>
                <span
                  className='information-icon'
                  onClick={(event) => handlePopoverOpen(event, item, index)}>
                  <img src={dotsVertical} />
                </span>

                <div className='header-part1'>
                  <div className='headerItem1'>
                    <div className='campaign-req-icon-part'>
                      <span className={`${getCampaignStatusIcon(item?.campaignStatus)}`} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='card-header2 card-header3 my-2'>
                {`${item.campaignName || 'N/A'}`}
              </div>

              <div className='cards-body'>
                <div className='body-item'>
                  <p className='card-capsule '>{item.campaignStatus}</p>
                </div>
                <div className='body-item'>
                  <p className='card-capsule '>{item.department}</p>
                </div>
              </div>

              <span className='card-seperator'></span>

              <div className='campaign-req-card-footer'>
                <p>
                  <span>{t(`${translationPath}created-date`)}</span>
                  <span className='px-1' style={{ fontWeight: '400' }}>
                    {(item.createdOn &&
                      moment(item.createdOn).locale(i18next.language).format('DD/MM/YYYY')) ||
                      'N/A'}
                  </span>
                </p>
                <p>
                  <span>{t(`${translationPath}Requester`)}</span>
                  <span style={{ fontWeight: '400' }}>{item.requester || '-'}</span>{' '}
                </p>
                <p>
                  <span>{t(`${translationPath}Next-action-by`)}</span>
                  <span style={{ fontWeight: '400' }}>{item.nextActionBy || '-'}</span>{' '}
                </p>
              </div>
            </div>
            {activeItem && itemIndex === index && (
              <PopoverComponent
                idRef={`popover${item?.id}Ref`}
                attachedWith={selectedPopoverAction}
                popoverClasses='release-note-popover'
                handleClose={handlePopoverClose}
                component={
                  <>
                    {actionsList &&
                      actionsList.map((action) => {
                        const actionInfo = TableActions[action.enum];
                        if (actionInfo) {
                          return (
                            <div className='popover-item' onClick={(event) =>{
                              event.stopPropagation();
                              event.preventDefault();

                              actionClickHandler(action?.enum, item);
                            } 
                            }>
                              <span className={`${actionInfo.icon} mr-2`}></span>
                              <span>{t(`${translationPath}${actionInfo.label}`)}</span>
                            </div>
                          );
                        }
                      })}
                  </>
                }
              />
            )}
          </div>
        ))}
    </div>
  );
};
