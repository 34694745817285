
import React, { useState }  from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';
import { AutocompleteComponent  } from '../../../../../Components' ; 


  export const Luxury = ({
    parentTranslationPath ,
    translationPath ,
    state ,
    setState ,
   } ) => { 

    const { t } = useTranslation(parentTranslationPath);

    return (
        <>
         <div className="d-flex-column">
        <span className="fw-bold"> {t(`${translationPath}luxury`)} </span>
        </div> 
        <div className='w-100'>
      
            <div className="d-flex d-flex-v-center life-style-card b-bottom">
            <div>
            <span className='fw-bold'> {t(`${translationPath}luxury`)} </span> 
            </div>
            <div>
            <Rating  
                size="large"
                name={`LifeStylepropertyRatingLuxuryDto`}
                max={10}
                value={(state && state.propertyRatingLuxuryDto &&  state.propertyRatingLuxuryDto.score)}
                precision={1}
                onChange={(event, newValue) => 
                {
                  let  updateObj =  state.propertyRatingLuxuryDto ? {...state.propertyRatingLuxuryDto} : null ;
                  updateObj.score= (newValue || 0 ) ;
                  updateObj.isLuxury= !newValue ? false : true ;
                 
                  setState({id : 'propertyRatingLuxuryDto' , value : updateObj })
                }}
                />     
              </div>
           </div>  
        </div>
      </>
      );
     }  
