import React, { useState, useRef, useEffect, useCallback } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import { Inputs, DatePickerComponent, PopoverComponent, Spinner, DialogComponent } from '../../Components'
import { ButtonBase, Tooltip } from '@material-ui/core';
import { GlobalAdvanceSearchActions } from '../../store/GlobalAdvanceSearch/GlobalAdvanceSearchActions';
import { TableFilterTypesEnum, TableFilterOperatorsEnum } from '../../Enums';
import { useDispatch, useSelector } from 'react-redux';
import '../../assets/theme-style/master.scss'
import { AddFilter } from './AddFilter'
import { ApplyFilter } from './ApplyFilter'
import { DeleteFilter } from './DeleteFilter'
import { OperatorsComponent } from './OperatorsComponent'
import './AdvanceSearch.scss';

export const AdvanceSearch = ({
    filterData,
    translationPath,
    parentTranslationPath,
    onFilterValuesChanged,
    localStorageKey,
    isAdvanceSearchCleared,
    setIsAdvanceSearchCleared,
    setOrderBy,
    searchCriteriaTypeId,
    setIsAdvanceSearchActive
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('Shared');
    const [filterValue, setFilterValue] = useState(null);
    const advanceSearch = useSelector((state) => state.GlobalAdvanceSearchReducer);
    const [searchValue, setSearchValue] = useState(advanceSearch && advanceSearch[localStorageKey] || null);
    const [activeItem, setActiveItem] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenAddFilter, setIsOpenAddFilter] = useState(false);
    const [isOpenApplyFilter, setIsOpenApplyFilter] = useState(false);
    const [isOpenDeleteFilter, setIsOpenDeleteFilter] = useState(false);
    const searchTimer = useRef({
        initialHit: false,
        onChangeHit: false
    });
    const clearOperator = () => {
        for (const key in searchValue) {
            const element = searchValue[key];
            let elementKey = element.searchableKey || element.displayPath || element.input;
            let activeItemKey = activeItem.searchableKey || activeItem.displayPath || activeItem.input;
            if (elementKey === activeItemKey) delete searchValue[key];

            dispatch(
                GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
                    ...advanceSearch,
                    [localStorageKey]: searchValue && Object.keys(searchValue).length ? searchValue : null
                })
            );
            onFilterValuesChanged(searchValue);
        }
    }

    useEffect(() => {
        if (isAdvanceSearchCleared) {
            dispatch(
                GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
                    ...advanceSearch,
                    [localStorageKey]: null
                })
            );
            setIsAdvanceSearchActive(false);
            setIsAdvanceSearchCleared(false);
        }
    }, [])

    useEffect(() => {
        setFilterValue(advanceSearch && advanceSearch[localStorageKey]);
        setSearchValue(advanceSearch && advanceSearch[localStorageKey]);
        if (searchTimer.current.onChangeHit) clearTimeout(searchTimer.current.onChangeHit);
        if (searchTimer.current.initialHit) {
            searchTimer.current.onChangeHit = setTimeout(() => {
                onFilterValuesChanged(searchValue);
                setOrderBy({ filterBy: "createdOn", orderBy: 2 });
            }, 700);
        }
    }, [advanceSearch])

    useEffect(() => {
        for (const key in searchValue) {
            const element = searchValue[key];
            let isBlank = element.operator === TableFilterOperatorsEnum.isBlank.key
                || element.operator === TableFilterOperatorsEnum.isNotBlank.key;
            if (!element.value && !isBlank) delete searchValue[key]
        }

        dispatch(
            GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
                ...advanceSearch,
                [localStorageKey]:  searchValue && Object.keys(searchValue).length ? searchValue : null
            })
        );
    }, [searchValue]);

    return (
        <>
            <div className='view-wrapper'>
                <div className='advance-search-wrapper'>
                    <Spinner isActive={isLoading} />
                    <div className='body-title-wrapper'>
                        <span className='body-title'>{t(`${translationPath}AdvanceSearch`)}</span>
                    </div>
                    <div className='search-area'>
                        {
                            filterData && filterData.filter(e => e.fieldKey !== 'creationTime').map((item) => {

                                let filterKey = item.searchableKey || item.displayPath || item.input;
                                let operatoerKey = searchValue && searchValue[filterKey] && searchValue[filterKey].operator
                                let isBlank = operatoerKey === TableFilterOperatorsEnum.isBlank.key
                                    || operatoerKey === TableFilterOperatorsEnum.isNotBlank.key;
                                let currentValue = filterValue && filterValue.value;
                                let storedValue = searchValue && searchValue[filterKey] && searchValue[filterKey].value;
                                if ((item.isDate || item.uiWidgetType === 'alt-date')) {
                                    return (
                                        <div className='search-field-wrapper'>
                                            <span className='search-field'>
                                                <DatePickerComponent
                                                    idRef={item.label}
                                                    placeholder={item.label || item.formFieldTitle}
                                                    value={isBlank && currentValue || storedValue || null}
                                                    format='YYYY/MM/DD'
                                                    onDateChanged={(newValue) => {
                                                        if (onFilterValuesChanged) {
                                                            searchTimer.current.initialHit = true
                                                            if (isBlank) operatoerKey = TableFilterTypesEnum.textInput.defaultSelectedOperator

                                                            let displayPath = item.displayPath || item.input;
                                                            let searchableKey = item.searchableKey || item.input;
                                                            const localFilterValues = (filterValue && { ...filterValue }) || {};
                                                            localFilterValues[filterKey] = {
                                                                value: (newValue && moment(newValue).format('YYYY-MM-DD')) || '',
                                                                operator: operatoerKey || TableFilterOperatorsEnum.equalData.key,
                                                                displayPath,
                                                                searchableKey
                                                            };
                                                            setFilterValue(localFilterValues);
                                                            setSearchValue(localFilterValues);
                                                        }
                                                    }}
                                                />
                                            </span>
                                            <OperatorsComponent
                                                selectedValue={searchValue && searchValue[filterKey]}
                                                setActiveItem={setActiveItem}
                                                item={item}
                                                clearOperator={clearOperator}
                                                searchValue={searchValue}
                                                setSearchValue={setSearchValue}
                                                setFilterValue={setFilterValue}
                                                activeItem={activeItem}
                                                translationPath={translationPath}
                                                isBlank={isBlank}
                                                onFilterValuesChanged={onFilterValuesChanged}


                                            />
                                        </div>
                                    )
                                } else {
                                    return (
                                        <>
                                            <div className='search-field-wrapper'>
                                                <span className='search-field'>
                                                    <Inputs
                                                        value={isBlank && currentValue || storedValue || ''}
                                                        defaultValue={storedValue || ''}
                                                        inputPlaceholder={item.label || item.formFieldTitle}
                                                        onInputChanged={(e) => {
                                                            let { value } = e.target;
                                                            if (onFilterValuesChanged) {
                                                                searchTimer.current.initialHit = true
                                                                if (isBlank) operatoerKey = TableFilterTypesEnum.textInput.defaultSelectedOperator

                                                                let displayPath = item.displayPath || item.input;
                                                                let searchableKey = item.searchableKey || item.input;
                                                                const localFilterValues = (filterValue && { ...filterValue }) || {};
                                                                localFilterValues[filterKey] = {
                                                                    value,
                                                                    operator: operatoerKey ||
                                                                        item.isNumber && TableFilterOperatorsEnum.equal.key ||
                                                                        TableFilterOperatorsEnum.contains.key,
                                                                    displayPath,
                                                                    searchableKey
                                                                };
                                                                setFilterValue(localFilterValues);
                                                                setSearchValue(localFilterValues);

                                                            }
                                                        }}
                                                    />
                                                </span>
                                                <OperatorsComponent
                                                    selectedValue={searchValue && searchValue[filterKey]}
                                                    setActiveItem={setActiveItem}
                                                    item={item}
                                                    clearOperator={clearOperator}
                                                    searchValue={searchValue}
                                                    setSearchValue={setSearchValue}
                                                    setFilterValue={setFilterValue}
                                                    activeItem={activeItem}
                                                    translationPath={translationPath}
                                                    onFilterValuesChanged={onFilterValuesChanged}
                                                />
                                            </div>
                                        </>
                                    )
                                }
                            })}

                    </div>

                    <div className='action-wrapper  mt-4 advance-search-btns'>
                        <div className='upper-btns'>
                            <ButtonBase
                                className='btns w-0 '
                                onClick={() => { setIsOpenAddFilter(true); }}
                            >
                                <Tooltip title={t(`${translationPath}add-filter`)}>
                                    <span className='mdi mdi-content-save-outline' />
                                </Tooltip>
                            </ButtonBase>
                            <ButtonBase
                                onClick={() => { setIsOpenApplyFilter(true); }}
                                className='btns  w-0 '
                            >
                                <Tooltip title={t(`${translationPath}apply-filter`)}>
                                    <span className='mdi mdi-filter-menu-outline' />
                                </Tooltip>
                            </ButtonBase>
                            <ButtonBase
                                onClick={() => { setIsOpenDeleteFilter(true); }}
                                className='btns delete'
                            >
                                <Tooltip title={t(`${translationPath}delete-filter`)}>
                                    <span className='mdi mdi-trash-can' />
                                </Tooltip>
                            </ButtonBase>

                        </div>
                        <ButtonBase
                            onClick={() => {
                                onFilterValuesChanged(null);
                                setIsAdvanceSearchActive(false);
                                setIsAdvanceSearchCleared(false);
                                dispatch(
                                    GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
                                        ...advanceSearch,
                                        [localStorageKey]: null
                                    })
                                );

                            }}
                            className='btns theme-solid bg-danger clear-all-btn'
                        >
                            <span className='mdi mdi-filter-remove' />
                            <span className='mx-2'>{t(`${translationPath}clear`)}</span>
                        </ButtonBase>
                    </div>
                </div>
                {isOpenAddFilter && (
                    <AddFilter
                        data={searchValue}
                        isOpen={isOpenAddFilter}
                        setIsOpenAddFilter={setIsOpenAddFilter}
                        setIsLoading={setIsLoading}
                        searchCriteriaTypeId={searchCriteriaTypeId}
                        isLoading={isLoading}
                    />
                )}
                {isOpenApplyFilter && (
                    <ApplyFilter
                        isOpen={isOpenApplyFilter}
                        setIsOpenApplyFilter={setIsOpenApplyFilter}
                        setIsLoading={setIsLoading}
                        searchCriteriaTypeId={searchCriteriaTypeId}
                        isLoading={isLoading}
                        setFilterValue={setFilterValue}
                        setSearchValue={setSearchValue}
                        searchTimer={searchTimer}
                    />
                )}
                {isOpenDeleteFilter && (
                    <DeleteFilter
                        isOpen={isOpenDeleteFilter}
                        setIsOpenDeleteFilter={setIsOpenDeleteFilter}
                        searchCriteriaTypeId={searchCriteriaTypeId}
                    />
                )}
            </div>
        </>
    )
}
