import React, { useReducer, useEffect, useCallback, useState } from "react";
import { config } from "../../../../../config";
import { useTranslation } from "react-i18next";
import { FileInfoCard } from "../../../../../SharedComponents";
import { AutocompleteComponent, Inputs } from "../../../../../Components";
import {
  GetAllSystemConfigurationsStatus,
  GetUnitTransactionTenant,
  lookupItemsGetId,
  RunSystemTemplateForUnit,
} from "../../../../../Services";
import { Avatar } from "@material-ui/core";
import { GetParams, getFirstLastNameLetters } from "../../../../../Helper";
import { DocListHolder } from "../../Utilities";
import { Tooltip } from "@material-ui/core";
import { ScopeDocumentEnum, UnitsStatusEnum } from "../../../../../Enums";
import { StaticLookupsIds } from "../../../../../assets/json/StaticLookupsIds";

export const ContractIssuance = ({
  unitId,
  unitData,
  haveToResetInitDOCs,
  selected,
  onSelectedChanged,
  parentTranslationPath,
  changeActiveSubmitButton,
}) => {
  const translationPath = "ContractIssuance.";
  const { t } = useTranslation(parentTranslationPath);
  const URLPathStep = GetParams("step");

  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  };

  const [isTemplateLoading, setIsTemplateLoading] = useState(false);
  const [localSelected, setLocalSelected] = useReducer(reducer, {
    tenancyContractType: null,
    contractTemplateId: null,
    tenancyContractDuration: null,
    tenancyContractContent: null,
  });
  const [data, setData] = useReducer(reducer, {
    tenancyContractTypes: [],
    documentCategories: [],
  });

  const generateTemplateName = () =>
    `Tenancy contract - ${localSelected.tenancyContractType?.label || ""} `;

  const getTenantName = () =>
    selected.contacts?.length > 0 ? selected.contacts[0].contactName : "";

  const getTemplateAsHtml = async () => {
    setIsTemplateLoading(true);
    setData({ id: 'tenancyContractContent', value: "" });
    const body = {
      templateId: localSelected.contractTemplateId,
      unitId,
    };
    const res = await RunSystemTemplateForUnit(body);
    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "tenancyContractContent",
        value: res?.templateContent || "",
      });
    }
    setIsTemplateLoading(false);
  };
  //todo:: move small helper func to a local helper file

  const checkIsReserved = () => {
    const isReserved =
      unitData?.lease_status === UnitsStatusEnum.ReservedLeased.name;
    return isReserved;
  };

  const checkIsUnderContract = () => {
    const isUnderContract =
      unitData?.lease_status === UnitsStatusEnum.UnderContract.name;
    return isUnderContract;
  };

  const submitButtonChangeHandler = () => {
    const isDocsExist =
      selected.tenancyContractDocs?.tenantDocuments?.length > 0 ||
      selected.tenancyContractDocs?.landlordDocuments?.length > 0;

    if (!(checkIsReserved() || checkIsUnderContract())) changeActiveSubmitButton("Next");
    if ((checkIsReserved() || checkIsUnderContract()) && isDocsExist) changeActiveSubmitButton("Submit");
    if ((checkIsUnderContract()) && !isDocsExist) changeActiveSubmitButton("Submit");
    if ((checkIsReserved()) && !isDocsExist) changeActiveSubmitButton("Send");
  };

  const mapTenantsData = (response) => {
    const mappedContact = {
      contactId: response.contactId,
      contactName: response.contactName,
      leadId: response.tenantId,
      email: response.email,
      phone: response.phone,
      ownershipPercentage: response.ownershipPercentage,
    };

    onSelectedChanged({
      id: "contacts",
      value: [mappedContact],
    });
  };

  const getUnitTransactionTenant = async () => {
    const res = await GetUnitTransactionTenant(unitId);
    if (!(res && res.status && res.status !== 200)) {
      if (res) mapTenantsData(res);
    }
  };

  const getDocumentCategories = async () => {
    const result = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.UnitsDocuments,
    });
    if (!(result && result.status && result.status !== 200)) {
      setData({ id: "documentCategories", value: result });
    }
  };

  const contractTemplateIdHandler = useCallback(() => {
    const selectedContractType = localSelected.tenancyContractType || null;
    const isSentForTenantOnly =
      checkIsReserved() &&
      selectedContractType &&
      selected.tenancyContractDocs?.landlordDocuments?.length > 0 &&
      selected.tenancyContractDocs?.tenantDocuments?.length == 0
        ? true
        : false;

    const contractType = selectedContractType?.systemConfigurationName || "";
    const templateId = isSentForTenantOnly
      ? config.TenantTenancyContractTemplateId[contractType]
      : config.TenancyContractTemplateId[contractType];

    setLocalSelected({ id: "contractTemplateId", value: templateId });
  }, [
    selected.tenancyContractDocs,
    localSelected.tenancyContractType,
    unitData,
  ]);

  const getTenancyContractTypes = async () => {
    const result = await GetAllSystemConfigurationsStatus();
    if (!(result && result.status && result.status !== 200)) {
      const tenancyContractTypes =
        (result &&
          result.filter(
            (item) =>
              item.systemConfigurationName === "ResidentialTenancyContract" ||
              item.systemConfigurationName === "CommercialTenancyContract"
          )) ||
        [];

      const mappedTenancyContractTypes = tenancyContractTypes.map((item) => ({
        ...item,
        label: item.systemConfigurationName.split("TenancyContract")[0],
      }));
      setData({
        id: "tenancyContractTypes",
        value: mappedTenancyContractTypes,
      });
    }
  };

  const mapSingleUploadedDoc = ({document,
    isTenant = false,
    isLandlord = false,
    }) => {
    const scopeId = unitId;
    const scopeTypeId = ScopeDocumentEnum.Unit.scopeTypeId;
    const categoryId = data.documentCategories.find(
      (item) => item.lookupItemName === "Tenancy Contract"
    )?.lookupItemId;

    return {
      fileName: document.fileName,
      fileId: document.uuid,
      uuid: document.uuid,
      scopeId,
      categoryId,
      scopeTypeId,
      isTenantTenancyContract: isTenant,
      isLandlordTenancyContract: isLandlord,
      isUploaded: true,
    };
  };

  const DTOChangeHandler = useCallback(async () => {
    const transactionDocuments = [
      ...(selected.contactDocuments || []),
      ...(selected.tenancyContractDocs?.tenantDocuments || []),
      ...(selected.tenancyContractDocs?.landlordDocuments || []),
    ];

    const tenancyContractContent = localSelected.contractTemplateId ?
      (localSelected.tenancyContractContent || data.tenancyContractContent || "") : null;

    const TenancyContractSendBody = {
      unitId,
      systemTemplateId: localSelected.contractTemplateId || null,
      tenancyContractTypeId: localSelected.tenancyContractType?
        +localSelected.tenancyContractType?.systemConfigurationId : null,
      tenancyContractDuration: localSelected.tenancyContractDuration,
      unitTransactionContacts: selected.contacts,
      transactionDocuments,
      tenancyContractContent,
    };

    const TenancyContractUploadBody = {
      Landlord: {
        unitId,
        documents: (
          selected.tenancyContractDocs?.landlordDocuments || []
        ).filter((item) => item.isUploaded),
      },
      Tenant: {
        unitId,
        documents: (selected.tenancyContractDocs?.tenantDocuments || []).filter(
          (item) => item.isUploaded
        ),
      },
    };

    const TenancyContractDTO = {
      ...(selected.transactionDTOs || {}),
      TenancyContractSend: TenancyContractSendBody,
      TenancyContractUpload: TenancyContractUploadBody,
    };

    onSelectedChanged({ id: "transactionDTOs", value: TenancyContractDTO });
  }, [
    unitId,
    localSelected,
    selected.contacts,
    localSelected.tenancyContractContent,
    data.tenancyContractContent,
    selected.tenancyContractDocs,
    selected.contactDocuments,
  ]);

  const checkIsBothDocsUploaded = () => {
    const isNewTenantDocsUploaded = [
      ...(selected.tenancyContractDocs?.tenantDocuments || []),
    ].find((item) => item.isUploaded);
    const isNewLandlordtDocsUploaded = [
      ...(selected.tenancyContractDocs?.landlordDocuments || []),
    ].find((item) => item.isUploaded);
    const bothDocsUploaded =
      isNewTenantDocsUploaded && isNewLandlordtDocsUploaded;

    return bothDocsUploaded;
  };

  const clearContractTemplateId = () => {

    if (checkIsReserved() && checkIsBothDocsUploaded()){
      setLocalSelected({ id: 'contractTemplateId', value: null });
    }
  }

  const getIsTemplateCardDisabled = () => {
    return (selected?.autoFilled?.tenancyContractContent || !checkIsReserved());
  }

  const handleInitialContractType = () => {
    const contractType = data.tenancyContractTypes.find(
      (item) => item.label === selected.offerLetterType.label
    );
    if (contractType) {
      setLocalSelected({ id: "tenancyContractType", value: contractType });
      setLocalSelected({
        id: "tenancyContractDuration",
        value: +contractType.systemConfigurationValue || null,
      });
    }
  };

  const getIsContractTypeDisabled = () => {
    return (
      !checkIsReserved() ||
      checkIsBothDocsUploaded() ||
      selected.offerLetterType
    );
  };

  useEffect(() => {
    if (unitData) {
      submitButtonChangeHandler();
    }
  }, [unitData, selected.tenancyContractDocs]);

  useEffect(() => {
    DTOChangeHandler();
  }, [DTOChangeHandler]);

  useEffect(() => {
    contractTemplateIdHandler();
  }, [contractTemplateIdHandler]);

  useEffect(() => {
    if (selected.offerLetterType && data.tenancyContractTypes?.length > 0)
      handleInitialContractType();
  }, [selected.offerLetterType, data.tenancyContractTypes]);

  useEffect(() => {
    getUnitTransactionTenant();
    getTenancyContractTypes();
    getDocumentCategories();
  }, []);

  useEffect(() => {
    clearContractTemplateId();
 }, [selected.tenancyContractDocs]);

  useEffect(() => {
    if (localSelected.contractTemplateId && checkIsReserved())
      getTemplateAsHtml();
  }, [localSelected.contractTemplateId]);

  return (
    <div className="Offer-Letter-SignOff py-3 my-1">
      <div className="d-flex fj-start mb-3">
        <div className="w-33 mt-1">
          <span className="fw-simi-bold">{t(`${translationPath}Sent-to`)}</span>
        </div>
        <div className="w-75">
          <div className="d-flex-default fas-center">
            <div className="d-flex-default fas-center mr-4">
              <span className="d-flex-default fas-center fw-simi-bold mr-3">
                {t(`Landlord`)}
              </span>
              <Tooltip
                title={unitData?.lease_lead_owner?.name}
                placement="bottom"
              >
                <Avatar
                  classes={{ root: `fz-12 team-avatar xs-theme pointer` }}
                  src={""}
                >
                  {getFirstLastNameLetters(
                    unitData?.lease_lead_owner?.name || ""
                  )}
                </Avatar>
              </Tooltip>
            </div>
            <div className="d-flex-default fas-center">
              <span className="d-flex-default fas-center fw-simi-bold mr-3">
                {t(`Tenants`)}
              </span>
              <Tooltip title={getTenantName()} placement="bottom">
                <Avatar
                  classes={{ root: `fz-12 team-avatar xs-theme pointer` }}
                  src={""}
                >
                  {getFirstLastNameLetters(getTenantName())}
                </Avatar>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex fj-start mb-3">
        <div className="w-33 mt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}tenancy-contract-type`)}
          </span>
        </div>
        <div className="w-75">
          <AutocompleteComponent
            idRef="tenancyContractTypeRef"
            inputPlaceholder="select"
            wrapperClasses="w-50  mt-1"
            isDisabled={getIsContractTypeDisabled()}
            data={data.tenancyContractTypes || []}
            selectedValues={localSelected.tenancyContractType || null}
            getOptionSelected={(option, value) =>
              option.systemConfigurationId === value.systemConfigurationId
            }
            multiple={false}
            displayLabel={(option) => option.label || ""}
            withoutSearchButton
            isWithError
            parentTranslationPath={parentTranslationPath}
            onChange={(_, newValue) => {
              if (newValue) {
                setLocalSelected({
                  id: "tenancyContractType",
                  value: newValue,
                });
                setLocalSelected({
                  id: "tenancyContractDuration",
                  value: +newValue.systemConfigurationValue || null,
                });
              } else {
                setLocalSelected({
                  id: "edit",
                  value: {
                    ...localSelected,
                    tenancyContractType: null,
                    tenancyContractDuration: null,
                  },
                });
              }
            }}
          />
        </div>
      </div>
      {(checkIsReserved() && localSelected.contractTemplateId && !checkIsBothDocsUploaded()) 
        || (!checkIsReserved() && selected?.autoFilled?.tenancyContractContent ) ?

       (<div className="d-flex fj-start mb-3">
        <div className="w-33 mt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}tenancy-contract`)}
          </span>
        </div>
        <div className="w-75">
            <FileInfoCard
              wrapperClasses="w-50 mt-3"
              templateName={generateTemplateName()}
              templateId={localSelected.contractTemplateId}
              templateContent={
                selected?.autoFilled?.tenancyContractContent ||
                localSelected.tenancyContractContent ||
                ""
              }
              emptyTemplate={data.tenancyContractContent || ''}
              isReadOnly={getIsTemplateCardDisabled()}
              isLoading={isTemplateLoading}
              onViewClose={(filledTemplate) =>
                setLocalSelected({
                  id: "tenancyContractContent",
                  value: filledTemplate,
                })
              }
            />
        </div>
      </div>) : null}
      {(checkIsReserved() && localSelected.contractTemplateId && !checkIsBothDocsUploaded()) 
    || (!checkIsReserved() && selected?.autoFilled?.tenancyContractContent ) ?
        (<div className="d-flex fj-start mb-3">
        <div className="w-33 mt-1">
          <p className="fw-simi-bold m-0">
            {t(`${translationPath}Under-contract-duration`)}
          </p>
        </div>
        <div className="w-75">
          <Inputs
            idRef="Under-contract-durationRef"
            wrapperClasses="w-50  mt-1"
            value={localSelected.tenancyContractDuration || 0}
            isDisabled
            type="number"
            min={0}
            endAdornment={
              <span className="px-2 inner-span fz-12 light-text">
                {t(`${translationPath}days`)}
              </span>
            }
          />
          <p className="light-text w-50">
            {t(`${translationPath}Under-contract-duration-desc`)}
          </p>
        </div>
      </div>) : null}
      <div className="d-flex-column gap-20">
        <DocListHolder
          documents={selected.tenancyContractDocs?.tenantDocuments}
          haveToResetInitDOCs={haveToResetInitDOCs}
          onDocumentsChange={(documents) => {
            onSelectedChanged({
              id: "tenancyContractDocs",
              value: {
                ...(selected.tenancyContractDocs || []),
                tenantDocuments: documents.map((item) =>
                  item.isRetrieved
                    ? item
                    : mapSingleUploadedDoc({
                      document: item,
                      isTenant : true,
                      })
                ),
              },
            });
          }}
          isDisabled={!(checkIsReserved() || checkIsUnderContract())}
          headerText="Tenant-tenancy-contract"
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
        <DocListHolder
          documents={selected.tenancyContractDocs?.landlordDocuments}
          haveToResetInitDOCs={haveToResetInitDOCs}
          onDocumentsChange={(documents) => {
            onSelectedChanged({
              id: "tenancyContractDocs",
              value: {
                ...(selected.tenancyContractDocs || []),
                landlordDocuments: documents.map((item) =>
                  item.isRetrieved
                    ? item
                    : mapSingleUploadedDoc({document: item,
                      isLandlord : true,
                      })
                ),
              },
            });
          }}
          isDisabled={!(checkIsReserved() || checkIsUnderContract())}
          headerText="Landlord-tenancy-contract"
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
    </div>
  );
};
