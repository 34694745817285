import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  CheckboxesComponent,
  AutocompleteComponent,
  RadiosGroupComponent,
} from "../../../../../../../Components";
import { OrganizationUserSearch } from "../../../../../../../Services";
import { AgentRoleEnum } from "../../../../../../../Enums";

export const UnitsSaleListedBy = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
  checkAtLeastOneSelected,
  checkAtLeastOneSelected2,
  setDefaultSelected,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState("primaryAvailable");


  function insertSpaceBeforeCapitalsFull(str) {
    return str.replace(/([A-Z])/g, " $1").trim();
  }
  function insertSpaceBeforeCapitalsSpace(input) {
    let formattedString = input.replace(/captel/g, "Captel");
    formattedString = formattedString.replace(/([A-Z][a-z]*)\s+As\s+.*/, "$1");
    formattedString = formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
    return formattedString;
  }

  function insertSpaceBeforeCapitals(str) {
    const First = str.replace(/([A-Z])/g, " $1").trim();
    let words = First.split(" ");
    words.shift();
    let remainingStr = words.join(" ");
    return remainingStr || "";
  }

  const renderCheckboxes = (section) => {
    const checkboxesData = state?.units?.saleListing[section] || [];
    return (
      <div className="CheckboxesComponent-unitsSaleListingSpecificsUsers">
        {Object.keys(checkboxesData).map((key) => {
          if (Array.isArray(checkboxesData[key])) {
            return null;
          }
          if (key === "showSensitive") {
            return null;
          }
          return (
            <div className="checkboxesItem" key={key}>
              <CheckboxesComponent
                idRef={`unitsSaleListing${key}Ref`}
                  label={
                    (section !== "TransactedAsSaleAgent" &&
                      section !== "TransactedAsListingAgent" &&
                      t(`${translationPath}${insertSpaceBeforeCapitals(key)}`)) ||
                    t(`${translationPath}${insertSpaceBeforeCapitalsSpace(insertSpaceBeforeCapitalsFull((key)))}`)
                  }
                parentTranslationPath={parentTranslationPath}
                translationPathForData={translationPath}
                singleChecked={checkboxesData[key]}
                onSelectedCheckboxClicked={(e) => {
                  const checkIfCanSelected = checkAtLeastOneSelected({
                    ...state.units.saleListing,
                    [key]: e.target.checked,
                  });
                  if (
                    section !== "TransactedAsSaleAgent" &&
                    section !== "TransactedAsListingAgent"
                  ) {
                    handleCheckboxChange(section, key, e.target.checked);
                  } else {
                    handleCheckboxChangeCustom(section, key, e.target.checked);
                  }
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };
  const handleAutocompleteChange = (section, newValue) => {
    let specificUsersKey;

    switch (section) {
      case "primaryAvailable":
        specificUsersKey = "primarySpecificUsersAvailable";
        break;
      case "primaryDraft":
        specificUsersKey = "primarySpecificUsersDraft";
        break;
      case "resaleAvailable":
        specificUsersKey = "resaleSpecificUsersAvailable";
        break;
      case "resaleDraft":
        specificUsersKey = "resaleSpecificUsersDraft";
        break;
      case "emptyAvailable":
        specificUsersKey = "emptyTypeSpecificUsersAvailable";
        break;
      case "TransactedAsSaleAgent":
        specificUsersKey = "TransactedAsSaleAgent";
        break;
      case "TransactedAsListingAgent":
        specificUsersKey = "TransactedAsListingAgent";
        break;
      case "emptyDraft":
        specificUsersKey = "emptyTypeSpecificUsersDraft";
        break;
      default:
        return;
    }

    const updatedSection = {
      ...state.units.saleListing[section],
      [specificUsersKey]: newValue,
    };

    const updatedSaleListing = {
      ...state.units.saleListing,
      [section]: updatedSection,
    };

    setState({
      id: "units",
      value: {
        ...state.units,
        saleListing: updatedSaleListing,
      },
    });
  };

  const renderCheckboxesShow = (section) => {
    const checkboxesData = state?.units?.saleListing?.[section] || {};

    let specificUsersKey;

    if (section.includes("Draft")) {
      specificUsersKey = section.replace("Draft", "SpecificUsersDraft");
    } else if (section.includes("Available")) {
      specificUsersKey = section.replace("Available", "SpecificUsersAvailable");
    }

    const selectedValues =
      state?.units?.saleListing?.[section]?.[specificUsersKey];
    const filteredValues =
      (selectedValues && selectedValues.filter(Boolean)) || [];

    return (
      <div className="">
        {section !== "TransactedAsSaleAgent" &&
          section !== "TransactedAsListingAgent" && (
            <div className="w-100 px-2 mt-2 mb-2">
              <AutocompleteComponent
                idRef={`unitsSaleListingSpecificUsersRef`}
                labelValue={t(`${translationPath}specific_users`)}
                data={allUsers || []}
                selectedValues={filteredValues}
                multiple
                displayLabel={(option) => (option && option.fullName) || ""}
                getOptionSelected={(option) =>
                  (filteredValues &&
                    filteredValues.findIndex(
                      (item) => item.id === option.id
                    ) !== -1) ||
                  ""
                }
                chipsLabel={(option) => (option && option.fullName) || ""}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getAllOrganizationUserSearch(value);
                  }, 700);
                }}
                onChange={(event, newValue) =>
                  handleAutocompleteChange(selectedRadio, newValue)
                }
                isLoading={isLoading}
              />
            </div>
          )}

        {Object.keys(checkboxesData).map((key) => {
          if (Array.isArray(checkboxesData[key])) {
            return null;
          }
          return (
            <div key={key}>
              {key === "showSensitive" ? (
                <>
                  <div className="showSensitive-wrapper">
                    <div className="">
                      {t(
                        `${translationPath}${insertSpaceBeforeCapitalsFull(
                          key
                        )}`
                      )}
                    </div>
                    <div className="showSensitive-unitsSaleListingSpecificsUsers showSensitive-bg">
                      {Object.keys(checkboxesData[key]).map((subKey) => (
                        <div className="checkboxesItem" key={subKey}>
                          <CheckboxesComponent
                            idRef={`unitsSaleListing${subKey}Ref`}
                            label={t(
                              `${translationPath}${insertSpaceBeforeCapitalsFull(
                                subKey
                              )}`
                            )}
                            parentTranslationPath={parentTranslationPath}
                            translationPathForData={translationPath}
                            singleChecked={checkboxesData[key][subKey]}
                            onSelectedCheckboxClicked={(e) => {
                              handleShowSensitiveChange(
                                section,
                                subKey,
                                e.target.checked
                              );
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          );
        })}
      </div>
    );
  };
  const checkboxesData = {
    primaryAvailable: {
      label: "Primary Available:",
      checkboxes: renderCheckboxes("primaryAvailable"),
    },
    primaryDraft: {
      label: "Primary Draft:",
      checkboxes: renderCheckboxes("primaryDraft"),
    },
    resaleAvailable: {
      label: "Resale Available:",
      checkboxes: renderCheckboxes("resaleAvailable"),
    },
    emptyAvailable: {
      label: "Empty Available:",
      checkboxes: renderCheckboxes("emptyAvailable"),
    },
    resaleDraft: {
      label: "Resale Draft:",
      checkboxes: renderCheckboxes("resaleDraft"),
    },
    emptyDraft: {
      label: "Empty Draft:",
      checkboxes: renderCheckboxes("emptyDraft"),
    },
    TransactedAsSaleAgent: {
      label: "Transacted As Sale Agent:",
      checkboxes: renderCheckboxes("TransactedAsSaleAgent"),
    },
    TransactedAsListingAgent: {
      label: "Transacted As Listing Agent:",
      checkboxes: renderCheckboxes("TransactedAsListingAgent"),
    },
  };

  const handleRadioChange = (value) => {
    setSelectedRadio(value);
  };

  const getAllOrganizationUserSearch = useCallback(async (searchValue) => {
    setIsLoading(true);
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchValue,
      userStatusId: 2,
    });
    if (res && res) {
      setAllUsers(
        res.result.map((item) => ({
          id: item.id,
          fullName: item.fullName,
          branch: item.branch || null,
        }))
      );
    } else {
      setAllUsers([]);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getAllOrganizationUserSearch();
  }, [getAllOrganizationUserSearch]);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  const handleCheckboxChange = (section, key, checked) => {
    let updatedSection = {};
    let actualSection = "";
    let allKey = "";

    switch (section) {
      case "primaryAvailable":
      case "primaryDraft":
        actualSection = "primary";
        allKey = `${actualSection}All${
          section.endsWith("Available") ? "Available" : "Draft"
        }`;
        break;
      case "resaleAvailable":
      case "resaleDraft":
        actualSection = "resale";
        allKey = `${actualSection}All${
          section.endsWith("Available") ? "Available" : "Draft"
        }`;
        break;
      case "emptyAvailable":
      case "TransactedAsSaleAgent":
      case "TransactedAsListingAgent":
      case "emptyDraft":
        actualSection = "emptyType";
        allKey = `${actualSection}All${
          section.endsWith("Available") ? "Available" : "Draft"
        }`;
        break;
      default:
        break;
    }

    if (key === allKey && checked) {
      updatedSection = {
        [allKey]: true,
        [`${actualSection}My${
          section.endsWith("Available") ? "Available" : "Draft"
        }`]: false,
        [`${actualSection}MyTeam${
          section.endsWith("Available") ? "Available" : "Draft"
        }`]: false,
        [`${actualSection}MyTeamLead${
          section.endsWith("Available") ? "Available" : "Draft"
        }`]: false,
        [`${actualSection}MyBusinessGroup${
          section.endsWith("Available") ? "Available" : "Draft"
        }`]: false,
        [`${actualSection}MyManage${
          section.endsWith("Available") ? "Available" : "Draft"
        }`]: false,
        [`${actualSection}MyBranch${
          section.endsWith("Available") ? "Available" : "Draft"
        }`]: false,
        [`${actualSection}ListingAgentEmpty${
          section.endsWith("Available") ? "Available" : "Draft"
        }`]: false,
        [`${actualSection}SpecificUsers${
          section.endsWith("Available") ? "Available" : "Draft"
        }`]: [],
        showSensitive: {
          showAll: true,
          showMy: false,
          showTeam: false,
          myBranch: false,
        },
      };
    } else if (key !== allKey) {
      if (
        key ===
          `${actualSection}MyBusinessGroup${
            section.endsWith("Available") ? "Available" : "Draft"
          }` &&
        checked
      ) {
        updatedSection = {
          ...state.units.saleListing[section],
          [key]: checked,
          [`${actualSection}MyTeam${
            section.endsWith("Available") ? "Available" : "Draft"
          }`]: false,
          [`${actualSection}MyTeamLead${
            section.endsWith("Available") ? "Available" : "Draft"
          }`]: false,
          [`${actualSection}My${
            section.endsWith("Available") ? "Available" : "Draft"
          }`]: false,
          showSensitive: {
            showAll: true,
            showMy: false,
            showTeam: false,
            myBranch: false,
          },
        };
      } else if (
        key ===
          `${actualSection}MyBusinessGroup${
            section.endsWith("Available") ? "Available" : "Draft"
          }` ||
        key ===
          `${actualSection}MyBranch${
            section.endsWith("Available") ? "Available" : "Draft"
          }`
      ) {
        updatedSection = {
          ...state.units.saleListing[section],
          [key]: checked,
          [`${actualSection}My${
            section.endsWith("Available") ? "Available" : "Draft"
          }`]: false,
          showSensitive: {
            showAll: true,
            showMy: false,
            showTeam: false,
            myBranch: false,
          },
        };
      } else if (
        key ===
          `${actualSection}My${
            section.endsWith("Available") ? "Available" : "Draft"
          }` &&
        checked
      ) {
        updatedSection = {
          ...state.units.saleListing[section],
          [key]: checked,
          [`${actualSection}MyTeam${
            section.endsWith("Available") ? "Available" : "Draft"
          }`]: false,
          [`${actualSection}MyBusinessGroup${
            section.endsWith("Available") ? "Available" : "Draft"
          }`]: false,
          [`${actualSection}MyBranch${
            section.endsWith("Available") ? "Available" : "Draft"
          }`]: false,
          showSensitive: {
            showAll: true,
            showMy: false,
            showTeam: false,
            myBranch: false,
          },
        };
      } else if (
        key ===
          `${actualSection}MyTeam${
            section.endsWith("Available") ? "Available" : "Draft"
          }` &&
        checked
      ) {
        updatedSection = {
          ...state.units.saleListing[section],
          [key]: checked,
          [`${actualSection}MyBusinessGroup${
            section.endsWith("Available") ? "Available" : "Draft"
          }`]: false,
          [`${actualSection}My${
            section.endsWith("Available") ? "Available" : "Draft"
          }`]: false,
          showSensitive: {
            showAll: true,
            showMy: false,
            showTeam: false,
            myBranch: false,
          },
        };
      } else {
        updatedSection = {
          ...state.units.saleListing[section],
          [key]: checked,
          showSensitive: {
            showAll: true,
            showMy: false,
            showTeam: false,
            myBranch: false,
          },
        };
      }
      // Set the "All" key to false when any other checkbox is checked
      updatedSection[allKey] = false;
    } else {
      updatedSection = {
        ...state.units.saleListing[section],
        [key]: checked,
      };
    }

    const updatedSaleListing = {
      ...state.units.saleListing,
      [section]: updatedSection,
      allUsers: false,
    };

    if (isMounted.current) {
      setState({
        id: "units",
        value: {
          ...state.units,
          saleListing: updatedSaleListing,
        },
      });
    }
  };

  const handleCheckboxChangeCustom = (section, key, checked) => {
    let updatedSection = {};
    let allKey = `All${section}`;

    if (key === allKey && checked) {
      updatedSection = {
        [allKey]: true,
        [`My${section}`]: false,
        [`MyTeam${section}`]: false,
        [`MyTeamLead${section}`]: false,
        [`MyBusinessGroup${section}`]: false,
        [`MyManage${section}`]: false,
        [`MyBranch${section}`]: false,
        showSensitive: {
          showAll: true,
          showMy: false,
          showTeam: false,
          myBranch: false,
        },
      };
    } else if (key !== allKey) {
      if (key === `MyBusinessGroup${section}` && checked) {
        updatedSection = {
          ...state.units.saleListing[section],
          [key]: checked,
          [`MyTeam${section}`]: false,
          [`MyTeamLead${section}`]: false,
          [`My${section}`]: false,
          showSensitive: {
            showAll: true,
            showMy: false,
            showTeam: false,
            myBranch: false,
          },
        };
      } else if (
        key === `MyBusinessGroup${section}` ||
        key === `MyBranch${section}`
      ) {
        updatedSection = {
          ...state.units.saleListing[section],
          [key]: checked,
          [`My${section}`]: false,
          showSensitive: {
            showAll: true,
            showMy: false,
            showTeam: false,
            myBranch: false,
          },
        };
      } else if (key === `My${section}` && checked) {
        updatedSection = {
          ...state.units.saleListing[section],
          [key]: checked,
          [`MyTeam${section}`]: false,
          [`MyBusinessGroup${section}`]: false,
          [`MyBranch${section}`]: false,
          showSensitive: {
            showAll: true,
            showMy: false,
            showTeam: false,
            myBranch: false,
          },
        };
      } else if (key === `MyTeam${section}` && checked) {
        updatedSection = {
          ...state.units.saleListing[section],
          [key]: checked,
          [`MyBusinessGroup${section}`]: false,
          [`My${section}`]: false,
          showSensitive: {
            showAll: true,
            showMy: false,
            showTeam: false,
            myBranch: false,
          },
        };
      } else {
        updatedSection = {
          ...state.units.saleListing[section],
          [key]: checked,
          showSensitive: {
            showAll: true,
            showMy: false,
            showTeam: false,
            myBranch: false,
          },
        };
      }
      // Set the "All" key to false when any other checkbox is checked
      updatedSection[allKey] = false;
    } else {
      updatedSection = {
        ...state.units.saleListing[section],
        [key]: checked,
      };
    }

    const updatedSaleListing = {
      ...state.units.saleListing,
      [section]: updatedSection,
      allUsers: false,
    };

    if (isMounted.current) {
      setState({
        id: "units",
        value: {
          ...state.units,
          saleListing: updatedSaleListing,
        },
      });
    }
  };

  const handleShowSensitiveChange = (section, key, checked) => {
    const updatedShowSensitive = Object.keys(
      state.units.saleListing[section].showSensitive
    ).reduce((acc, currKey) => {
      if (currKey === key) {
        if (state.units.viewSensitive) {
          acc[currKey] = checked;
        } else if (!state.units.viewSensitive && key === "showAll") {
          acc[currKey] = checked;
        } else {
          acc[currKey] = false;
        }
      } else {
        acc[currKey] = false;
      }
      return acc;
    }, {});

    const anyChecked = Object.values(updatedShowSensitive).some(
      (value) => value
    );

    const updatedSection = {
      ...state.units.saleListing[section],
      showSensitive: updatedShowSensitive,
    };

    const updatedSaleListing = {
      ...state.units.saleListing,
      [section]: updatedSection,
    };

    setState({
      id: "units",
      value: {
        ...state.units,
        saleListing: updatedSaleListing,
        viewSensitive: anyChecked,
      },
    });
  };

  return (
    <div className="UnitsSaleListedBy-con">
      <RadiosGroupComponent
        data={[
          { id: "primaryAvailable", value: "Primary Available" },
          { id: "primaryDraft", value: "Primary Draft" },
          { id: "resaleAvailable", value: "Resale Available" },
          { id: "resaleDraft", value: "Resale Draft" },
          { id: "emptyAvailable", value: "Empty Available" },
          { id: "emptyDraft", value: "Empty Draft" },
          { id: "TransactedAsSaleAgent", value: "Transacted As Sale Agent" },
          {
            id: "TransactedAsListingAgent",
            value: "Transacted As Listing Agent",
          },
        ]}
        valueInput="id"
        labelInput="value"
        value={selectedRadio}
        onSelectedRadioChanged={(e) => handleRadioChange(e.target.value)}
      />
      <div className="Radios-unitsSaleListingSpecificsUsers">
        {selectedRadio &&
          checkboxesData[selectedRadio] &&
          checkboxesData[selectedRadio].checkboxes && (
            <div>{checkboxesData[selectedRadio].checkboxes}</div>
          )}
      </div>

      <div className="showSensitive-unitsSaleListingSpecificsUsers ">
        {selectedRadio &&
          checkboxesData[selectedRadio] &&
          checkboxesData[selectedRadio].checkboxes && (
            <div className="w-100">{renderCheckboxesShow(selectedRadio)}</div>
          )}
      </div>
    </div>
  );
};

UnitsSaleListedBy.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  checkAtLeastOneSelected: PropTypes.func.isRequired,
  checkAtLeastOneSelected2: PropTypes.func.isRequired,
  setDefaultSelected: PropTypes.func.isRequired,
};
