import React, { Fragment, useRef, useState } from "react";
import moment from "moment";
import "./AccordionTable.scss";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  TableContainer,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  opnfouces,
  ProtectCopiedFun,
  ProtectCopiedFundelete,
} from "../../../../../Helper";

export const AccordionTable = ({
  headerData,
  data,
  dateFormat,
  wrapperClasses,
  getExpandedRowContent,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const tableRef = useRef(null);
  const slider = document.getElementById('TheScrollFun');
  let mouseDown = false;
  let startX;
  let scrollLeft;
  if (slider) {
    const startDragging = (e) => {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };
    const stopDragging = () => {
      mouseDown = false;
    };

    slider.addEventListener('mousemove', (e) => {
      e.preventDefault();
      if (!mouseDown) return;
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    });

    slider.addEventListener('mousedown', startDragging, false);
    slider.addEventListener('mouseup', stopDragging, false);
    slider.addEventListener('mouseleave', stopDragging, false);
  }
  
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);

  const dataReturn = (dataItem, columnPath) => {
    if (!dataItem) return "";
    if (!columnPath) return (typeof dataItem !== "object" && dataItem) || "";
    if (!columnPath.includes(".")) return dataItem[columnPath];
    let a = dataItem;
    columnPath.split(".").map((item) => {
      if (a) a = a[item];
      return item;
    });
    return a;
  };

  const expandRowHandler = (rowIndex) => {
    if (expandedRowIndex == rowIndex) setExpandedRowIndex(null);
    else setExpandedRowIndex(rowIndex);
  };

  return (
    <div
      className="w-100 table-responsive accordionTable"
      ref={tableRef}
      onMouseEnter={ProtectCopiedFun}
      onMouseLeave={ProtectCopiedFundelete}
      onFocus={opnfouces}
    >
      <TableContainer className="TheScrollFunParent" id="TheScrollFun">
        <Table className={`table-wrapper ${wrapperClasses || ""}`} size="small">
          <TableHead>
            <TableRow>
              {headerData
                .filter((column) => !column.isHidden)
                .map((item, index) => (
                  <TableCell key={`headerCell${index + 1}`}>
                    {t(`${translationPath}${item.label}`)}
                  </TableCell>
                ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, rowIndex) => (
                <Fragment key={`bodyRow${rowIndex + 1}`}>
                  <TableRow>
                    {headerData.length > 0 &&
                      headerData
                        .filter((column) => !column.isHidden)
                        .map((column, columnIndex) => (
                          <TableCell
                            key={`bodyColumn${columnIndex * rowIndex}`}
                          >
                            {(column.isDate &&
                              ((dataReturn(row, column.input) &&
                                moment(dataReturn(row, column.input)).format(
                                  column.dateFormat || dateFormat
                                )) ||
                                "")) ||
                              (column.component &&
                                column.component(
                                  row,
                                  rowIndex,
                                  column,
                                  columnIndex
                                )) ||
                              dataReturn(row, column.input)}
                          </TableCell>
                        ))}
                    <TableCell align="center">
                      <IconButton aria-label="expand row" size="small">
                        {rowIndex === expandedRowIndex ? (
                          <KeyboardArrowUpIcon
                            id="KeyboardArrowUpIcon-ref"
                            onClick={() => expandRowHandler(rowIndex)}
                          />
                        ) : (
                          <KeyboardArrowDownIcon
                            id="KeyboardArrowDownIcon-ref"
                            onClick={() => expandRowHandler(rowIndex)}
                          />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  {rowIndex === expandedRowIndex ? (
                    <TableRow className="accordionTable_Row--expanded">
                      <TableCell
                        className="accordionTable_Cell--expanded"
                        colSpan={headerData.length + 1}
                        align="center"
                      >
                        <div>{getExpandedRowContent(row) || null}</div>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

AccordionTable.propTypes = {
  data: PropTypes.array,
  headerData: PropTypes.array,
  dateFormat: PropTypes.string,
  wrapperClasses: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  getExpandedRowContent: PropTypes.func,
  translationPath: PropTypes.string,
};
AccordionTable.defaultProps = {
  data: [],
  headerData: [],
  dateFormat: "YYYY-MM-DD",
  wrapperClasses: "",
  getExpandedRowContent: ()=>{},
  parentTranslationPath: "",
  translationPath: "",
};
