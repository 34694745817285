import React from 'react';
import { useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { useLayoutDetection } from '../../../Hooks';

function ThemeProviderWrapper({ children }) {
  const { selectedTheme, isDarkMode } = useSelector((store) => store.theme);

  const { isNewLayout, hasNewLayout } = useLayoutDetection();

  const theme = React.useMemo(() => {
    return createTheme(selectedTheme);
  }, [isDarkMode, document.body.getAttribute('dir')]);

  const renderContent = (isNewLayout && hasNewLayout) ? (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  ) : (
    <>{children}</>
  );

  return renderContent;
}

export default ThemeProviderWrapper;
