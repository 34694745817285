

import React , {useState , useCallback , useEffect  } from 'react';
import { useTranslation } from 'react-i18next'; 
import { AutocompleteComponent } from '../../../../../../../../../../../../../Components' ;
import { GetAllPortalAPI   } from '../../../../../../../../../../../../../Services' ; 
import { getErrorByName } from '../../../../../../../../../../../../../Helper' ; 


export const PortalComponent = ({setState , state , translationPath , parentTranslationPath , selected , setSelected , schema  , isSubmitted}) => {
   const { t } = useTranslation(parentTranslationPath);

 
  return (
    <>
        <div className='dialog-content-item mt-2'>
             <AutocompleteComponent
                idRef='portalIdRef'
                labelValue='portal'
                multiple={false}
                value={selected.portal}
                selectedValues={selected.portal}
                data={[]}
                isDisabled
                chipsLabel={(option) => (option && option.portalName) || ''}
                displayLabel={(option) => (option && option.portalName) || ''}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => 
                {
                    setState({ id: 'portalId', value: ( newValue && newValue.portalId)  });
                    setSelected((item) => ({...item ,  portal : newValue }))

                }}
                helperText={getErrorByName(schema, 'portalId').message}
                error={getErrorByName(schema, 'portalId').error}
                isWithError
                isSubmitted={isSubmitted}
             />
         </div>
     </>
  );
};
