import React, { useState, useEffect, useCallback } from 'react';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Spinner, Tables } from '../../../../../../Components';
import { GetAllPaymentPlansByUnitId, GetAllUnitPaymentPlanByUnitId, UpdateUnitPaymentPlanApi } from '../../../../../../Services';
import {
  GetParams, bottomBoxComponentUpdate, showSuccess, showError
} from '../../../../../../Helper';
import { PaymentPlanManagementDialog, PaymentPlanDeleteDialog } from './PaymentPlanViewUtilities';

export const UnitProfilePaymentPlanComponent = ({ parentTranslationPath, translationPath }) => {
  const { t } = useTranslation(parentTranslationPath);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [checkedCardsIds, setCheckedCardsIds] = useState([]);
  const [currentRowClicked, setCurrentRowClicked] = useState(false);
  const [checkedCards, setCheckedCards] = useState([]);
  const [paymentPlans, setPaymentPlans] = useState({
    result: [],
    totalCount: 0,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [filter, setFilter] = useState({
    unitId: +GetParams('id'),
    pageSize: 25,
    pageIndex: 0,
  });
  const [selectedCards, setSelectedCards] = useState([]);
  const onSelectedCheckboxChanged = (event, index, item) => {
    event.preventDefault();
    const updatedSelectedCards = [...selectedCards];
    const isSelected = getIsSelectedCard(item);

    if (isSelected) {
      // Item is selected, so remove it
      const selectedIndex = updatedSelectedCards.findIndex((selectedItem) => selectedItem.propertyPaymentPlanId === item.propertyPaymentPlanId);
      updatedSelectedCards.splice(selectedIndex, 1);
    } else {
      // Item is not selected, so add it
      updatedSelectedCards.push(item);
    }
    setSelectedCards(updatedSelectedCards);
  };

  const getAllPaymentTypes = useCallback(async (id) => {
    setIsLoading(true);
    const res = await GetAllPaymentPlansByUnitId(id);
    if (!(res && res.status && res.status !== 200)) {
      setPaymentPlans({
        result: (res && res) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setPaymentPlans({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, [filter]);
 
  const updateUnitPaymentPlanApi = useCallback(async () => {
    setIsLoading(true);
    const unitPaymentPlansList = [];
      paymentPlans.result.map((item) => {
       const isItemChecked = checkedCardsIds.find((e) => e === item.propertyPaymentPlanId);
      unitPaymentPlansList.push({
        propertyPaymentPlanId: item.propertyPaymentPlanId,
        isAdded: isItemChecked ||  item.isSelected //!!(isItemChecked || item.isSelected)
      });
    });
    
    const filteredUnitPaymentPlansList = unitPaymentPlansList   //.filter((item) => item.isAdded );
    let body;
    if (filteredUnitPaymentPlansList.length > 0) {
      body = {
        unitPaymentPlans: filteredUnitPaymentPlansList
      };
    }
    const res = await UpdateUnitPaymentPlanApi(GetParams('id'), body);
    if (!(res && res.status && res.status !== 200))
      showSuccess(t('payment-plan-saved-successfully'));
    else showError(t('failed-to-save-payment-plan'));
    setIsLoading(false);
  }, [paymentPlans, checkedCardsIds]);


  useEffect(() => {
    getAllPaymentTypes(+GetParams('id'));
  }, [getAllPaymentTypes]);
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };

  const addNewHandler = () => {
    setActiveItem(null);
    setOpenDialog(true);
  };
  const onSelectClicked = useCallback(
    (row, rowIndex) => {
      if (row.isSelected) {
        paymentPlans.result[rowIndex].isSelected = false;
        setCurrentRowClicked(row);
      } else {
        setCheckedCardsIds((items) => {
          const index = items.findIndex((item) => item === row.propertyPaymentPlanId);
          if (index !== -1) items.splice(index, 1);
          else items.push(row.propertyPaymentPlanId);
          return [...items];
        });
        setCheckedCards((items) => {
          const index = items.findIndex((item) => item.propertyPaymentPlanId === row.propertyPaymentPlanId);
          if (index !== -1) items.splice(index, 1);
          else items.push(row);
          return [...items];
        });
      }
    },
    [checkedCards, paymentPlans.result]
  );
  const onSelectAllClicked = () => {
    const cardItmes = [];
    if (!getIsSelectedAll()) {
      paymentPlans.result.map((item) => {
        if (!getIsSelected(item) || item.isSelected) {
          if (item.isSelected) item.isSelected = false;
          checkedCardsIds.push(item.propertyPaymentPlanId);
          cardItmes.push({ ...item });
        }
      });
    } else {
      paymentPlans.result.map((item) => {
        if (getIsSelected(item)) {
          if (item.isSelected) item.isSelected = false;
          const isSelectedIndex = checkedCardsIds.findIndex(
            (element) => element === item.propertyPaymentPlanId
          );
          if (isSelectedIndex !== -1) checkedCardsIds.splice(isSelectedIndex, 1);
        }
      });
    }
    setCheckedCards(cardItmes);
  };
  const getIsSelected = useCallback(
    (row) => checkedCards && checkedCards.findIndex((item) => item.propertyPaymentPlanId === row.propertyPaymentPlanId) !== -1 || row.isSelected,
    [checkedCards, currentRowClicked]
  );

  const getIsSelectedAll = useCallback(
    () => {
      const returnSelect = (checkedCardsIds &&
        paymentPlans.result.findIndex((item) => !checkedCardsIds.includes(item.propertyPaymentPlanId)) === -1) || false;
      return returnSelect;
    }
  );
  const getIsSelectedCard = useCallback(
    (row) => {
      return selectedCards.findIndex((item) => item.propertyPaymentPlanId === row.propertyPaymentPlanId) !== -1;
    },
    [selectedCards]
  );
  useEffect(() => {
    bottomBoxComponentUpdate(
      <div className='d-flex-v-center-h-end flex-wrap'>
        {/* <ButtonBase className='btns theme-transparent mb-2' onClick={}>
             <span>{t('Shared:cancel')}</span>
           </ButtonBase> */}

        <ButtonBase className='btns theme-solid mb-2' onClick={updateUnitPaymentPlanApi}>
          <span>{t('Shared:save')}</span>
        </ButtonBase>
      </div>
    );
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );
  return (
    <div className='view-wrapper activities-view-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='d-flex-column mt-3'>
        <div className='header-section'>
          <div className='activities-filter-wrapper'>
            <div className='cards-filter-section px-2 mb-2'>
              <div className='section px-2'>
                {/* <PermissionsComponent
                  permissionsList={Object.values(UnitsSalesPermissions)}
                  permissionsId={UnitsSalesPermissions.AddPaymentPlanOnUnit.permissionsId}
                >
                  <ButtonBase className='btns theme-solid px-3' onClick={addNewHandler}>
                    <span className='mdi mdi-plus' />
                    {t(`${translationPath}add-new`)}
                  </ButtonBase>
                </PermissionsComponent> */}
              </div>
            </div>
          </div>
        </div>
        <div className='w-100 px-3'>
          <Tables
            data={paymentPlans.result}
            headerData={[ 
              {
                id: 1,
                // isSortable: true,
                label: 'Number',
                component: (item) => (
                  <span>
                    {item && item.propertyPlanStatusId ||
                      'N/A'}
                  </span>
                ),
              },

              {
                id: 2,
                // isSortable: true,
                label: 'planeType',
                component: (item) => (
                  <span>
                    {item && item?.planTypeLookupDto?.planTypeLookupName 
                      ||
                      'N/A'}
                  </span>
                ),
              },
              {
                id: 3,
                // isSortable: true,
                label: 'planStatus',
                component: (item) => (
                  <span>
                    {item && item?.statusName ||
                      'N/A'}
                  </span>
                ),
              },
              {
                id: 4,
                // isSortable: true,
                label: 'description',
                component: (item) => <span>{(item.description && item.description) || 'N/A'}</span>,
              },
            ]}
            defaultActions={[]}
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            translationPathFordata={translationPath}
            totalItems={paymentPlans.totalCount}
            bodyRowId={paymentPlans.propertyPaymentPlanId}
            isWithCheckboxColumn={true}
            isWithFilter={true}
            selectAllOptions={
              {
                selectedRows: checkedCardsIds,
                getIsSelected,
                onSelectClicked,
                disabledRows: [],
              }
            }
            TotalSelect={(checkedCardsIds && checkedCardsIds.length) || 0}
            HideSellectAll
          />
        </div>
      </div>
      {openDialog && (
        <PaymentPlanManagementDialog
          open={openDialog}
          activeItem={activeItem}
          onSave={() => {
            setOpenDialog(false);
            onPageIndexChanged(0);
          }}
          close={() => setOpenDialog(false)}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {openConfirmDialog && (
        <PaymentPlanDeleteDialog
          isOpen={openConfirmDialog}
          activeItem={activeItem}
          reloadData={() => {
            setOpenConfirmDialog(false);
            onPageIndexChanged(0);
            getAllPaymentTypes();
          }}
          isOpenChanged={() => setOpenConfirmDialog(false)}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
};

UnitProfilePaymentPlanComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
// <CheckboxesComponent idRef="nationalityRef"
// parentTranslationPath={parentTranslationPath}
// translationPath={translationPath}
// label="nationality"
// themeClass="theme-secondary"
// singleChecked={criteriaParameters.Individual.nationality}
// onSelectedCheckboxChanged={() => {
//   setCriteriaParameters({
//     id: 'Individual',
//     value: {
//       ...criteriaParameters.Individual,
//       nationality: !criteriaParameters.Individual.nationality
//     }
//   })
// }}
// />