import { config } from '../config';
import { HttpServices } from '../Helper';

export const ListProposalLogs = async ({ body }) => {
    const result = await HttpServices.post(`${config.server_address}/CrmDfm/ProposalLog/ListProposalLogs`,
    body)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};
