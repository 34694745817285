
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  ButtonBase,
} from '@material-ui/core';
import { PROPERTIES, UNITS , CONTACTS } from '../../../../config/pagesName';
import { ContactsInformationComponent } from '../../ContactsView/ContactProfileManagementView/Sections/ContactsInformationComponent/ContactsInformationComponent';
import { UnitInformationComponent } from '../../UnitsSalesView/UnitsSalesProfileManagement/Sections/UnitInformationComponent/UnitInformationComponent';
import { PropertiesInformationComponent } from '../../PropertiesView/PropertiesProfileManagementView/Sections/PropertiesInformationComponent/PropertiesInformationComponent';
import {
  UnitInformationComponent as LeaseUnitInformationComponent,
} from '../../UnitsLeaseView/UnitsLeaseProfileManagementView/Sections/UnitInformationComponent/UnitInformationComponent';
import { GetFieldsNeedingApprovalForChange, GetPendingUserFields } from '../../../../Services';
import { useSelector } from 'react-redux';


const translationPath = 'dialogs.detailsDialog.';
export const DetailsDialog = ({ isOpen, isOpenChanged , isOpenInsideForm , dataInSideViewButton  , sensitiveFieldsForUser   , isLoadingDetailsDialog , isSensitiveLoading , isSensitiveLoading2  , unitOpertaionTypeValue}) => {
  
  const { t } = useTranslation('FormBuilder');
  const [save , onSave]= useState(false);
  const [activeFormType, setActiveFormType] = useState(null);
  const [id, setId] = useState(null);
  const [activeItem  , setActiveItem] = useState(null) ; 
  const [pageName, setPageName] = useState(null);
  const [isDetailsDialog , setIsDetailsDialog] = useState(true);
  const [fieldsNeedsApproval, setFieldsNeedsApproval] = useState([]);
  const [pendingUserFields, setPendingUserFields] = useState(null);
  const [unitId , setUnitId] = useState('')
  const [initialData, setInitialData] = useState({});
  const loginResponse = useSelector((state) => state.login.loginResponse);




  useEffect(() => {
    if (isOpen) {
      const obj = JSON.parse(localStorage.getItem('current'));
      setActiveFormType(obj.type ||  1);
      setId(obj.id);
      setActiveItem(obj) ;
      setPageName(obj.itemId.toLowerCase());
      setUnitId(obj.id);
    }
  }, []);

  const getFieldsNeedingApproval = async () => {
    const userRoleIds = loginResponse?.roles.map((item) => item?.rolesId);

    const res = await GetFieldsNeedingApprovalForChange({ userRoleIds });
    if (!(res && res.status && res.status !== 200)) {
      setFieldsNeedsApproval(res);
    } else setFieldsNeedsApproval([]);

  };
  const getPendingUserFields = async () => {
    const res = await GetPendingUserFields(unitId);
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      setPendingUserFields(res.pendingFields)
      return res;
    }
  };


  useEffect(() => {
    if (pageName === UNITS ) {
      getFieldsNeedingApproval();
      getPendingUserFields();
    }
  }, [pageName]);

  useEffect(() => {
    if (dataInSideViewButton && fieldsNeedsApproval) {
      const { unit } = dataInSideViewButton;
      const details = {
        unit_number: unit?.unit_number,
        unit_type: unit?.unit_type,
        property_name: unit?.property_name,
        bedrooms: unit?.bedrooms,
        bathrooms: unit?.bathrooms,
        sale_type: unit?.sale_type,
        builtup_area_sqft : unit?.builtup_area_sqft,
        builtup_area_sqm : unit?.builtup_area_sqm
      };

      const fieldsNeedsApprovalWithUnderscore = fieldsNeedsApproval.map(
        (field) => field.replaceAll('-', '_')
      );

      const requiredFields = [
        'unit_number',
        'unit_type',
        'property_name',
        'bedrooms',
        'bathrooms',
        'sale_type',
        'builtup_area_sqm',
        'builtup_area_sqft'
      ];

      const filteredFields = fieldsNeedsApprovalWithUnderscore.filter((field) =>
        requiredFields.includes(field)
      );
      const neededFields = {};
      filteredFields.forEach((field) => {
        neededFields[field] = details[field];
      });
      setInitialData(neededFields);
    }
  }, [dataInSideViewButton, fieldsNeedsApproval]);

  return (
    <Dialog className='dialog detailsDialog'
          disableBackdropClick 
          open={isOpen}
          fullWidth 
          maxWidth='lg'>
        <DialogTitle>
          <>
            <ButtonBase
              className='btns-icon theme-solid dialog-btn-close'
              onClick={() => {
                setIsDetailsDialog(false);
                isOpenChanged();
              }}
            >
              <span className='mdi mdi-close' />
            </ButtonBase>
            {t(`${translationPath}view-${pageName}`)}

          </>
        </DialogTitle>
        <DialogContent>
        { pageName === UNITS 
        &&  activeItem && (activeItem.operationType && activeItem.operationType  !== 'Rent') 
        && (
            <UnitInformationComponent
                         viewType ={1} 
                         parentTranslationPath='UnitsProfileManagementView'
                         translationPath=''
                         save={save}
                         isDetailsDialog={isDetailsDialog}
                         isActiveToSave={pageName === UNITS}
                         isOpen={isOpen}
                         isOpenChanged={isOpenChanged}
                         onSave={onSave}
                         isOpenInsideForm={isOpenInsideForm}
                         unitWithPolicy={dataInSideViewButton}
                         sensitiveFieldsForUser={sensitiveFieldsForUser}
                         isLoadingDetailsDialog={isLoadingDetailsDialog}
                         isSensitiveLoading={isSensitiveLoading}
                         unitOpertaionTypeValue={unitOpertaionTypeValue}
                         initialData={initialData}
                         pendingUserFields={pendingUserFields}
                         fieldsNeedsApproval={fieldsNeedsApproval}

              />
         )}
          { pageName === UNITS &&  activeItem && activeItem.operationType  && activeItem.operationType === 'Rent' &&  (
            <LeaseUnitInformationComponent
                         viewType ={1} 
                         parentTranslationPath='UnitsProfileManagementView'
                         translationPath=''
                         save={save}
                         isDetailsDialog={isDetailsDialog}
                         isActiveToSave={pageName === UNITS}
                         isOpen={isOpen}
                         isOpenChanged={isOpenChanged}
                         onSave={onSave}
                         isOpenInsideForm={isOpenInsideForm}
                         unitWithPolicy={dataInSideViewButton}
                         sensitiveFieldsForUser={sensitiveFieldsForUser}
                         isSensitiveLoading={isSensitiveLoading}
                         unitOpertaionTypeValue={unitOpertaionTypeValue}
                         initialData={initialData}
                         pendingUserFields={pendingUserFields}
                         fieldsNeedsApproval={fieldsNeedsApproval}


                         
              />
         )}

           { pageName === PROPERTIES && (
              <PropertiesInformationComponent
               viewType ={1} 
               parentTranslationPath='PropertiesProfileManagementView'
               translationPath=''
               save={save}
               onSave={onSave}
               isDetailsDialog={isDetailsDialog}
               isActiveToSave={pageName === PROPERTIES}
               isOpen={isOpen}
               isOpenChanged={isOpenChanged}
               isOpenInsideForm={isOpenInsideForm}
               propertyWithPolicy={dataInSideViewButton}
               sensitiveFieldsForUser={sensitiveFieldsForUser}
               isSensitiveLoading={isSensitiveLoading}
               />

           )
           }

          { pageName === CONTACTS && (
            <ContactsInformationComponent
                         viewType ={1} 
                         parentTranslationPath='ContactProfileManagementView'
                         translationPath=''
                         save={save}
                         isDetailsDialog={isDetailsDialog}
                         isActiveToSave={pageName === CONTACTS}
                         isOpen={isOpen}
                         isOpenChanged={isOpenChanged}
                         onSave={onSave}
                         isOpenInsideForm={isOpenInsideForm}
                         contactWithPolicy={dataInSideViewButton}
                         sensitiveFieldsForUser={sensitiveFieldsForUser}
                         isSensitiveLoading={isSensitiveLoading}
                         isSensitiveLoading2={isSensitiveLoading2}
                         
              />
         )}
        </DialogContent>
        
        <DialogActions>
          <ButtonBase
            className="btns theme-solid bg-cancel"
            onClick={() => {
              isOpenChanged();
              setIsDetailsDialog(false);
            }}
          >
            {t(`${translationPath}cancel`)}

          </ButtonBase>
          <ButtonBase className="btns theme-solid" onClick={()=> 
          {
            onSave(true) ;
          }} >
            {t(`${translationPath}save`)}
          </ButtonBase>
        </DialogActions>
    </Dialog>
  );
};

DetailsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  isOpenInsideForm : PropTypes.bool.isRequired,
};
