import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { AutocompleteComponent, Spinner } from '../../../../../../../Components';
import { GetlookupTypeItems } from '../../../../../../../Services';

export const MethodOfContact = ({
    parentTranslationPath,
    translationPath,
    onStateChanged,
    state,
    isSubmitted,
    // values,
    // rotationEdit,
    currentSelected,
    setCurrentSelected,
}) => {
    const searchTimer = useRef(null);
    const { t } = useTranslation([parentTranslationPath, 'Shared']);
    const [methodsData, setMethodsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchItem, setSearchItem] = useState('');

    const searchHandler = (event) => {
        const { value } = event.target;
        if (searchTimer.current) clearTimeout(searchTimer.current);
        searchTimer.current = setTimeout(() => {
            setSearchItem(value)

        }, 700);
    };
    const getAllLookups = useCallback(async () => {
        setLoading(true)
        const res = await GetlookupTypeItems({
            lookupTypeId: 35,
            pageSize: 1000,
            pageIndex: 0,
            search: searchItem
        });
        if (!(res && res.status && res.status !== 200)) setMethodsData(res.result); 
        else setMethodsData([]);
        setLoading(false)
    }, [searchItem]);
 
    useEffect(() => {
        getAllLookups();
    }, [getAllLookups]);

    return (
        <div className='dialog-content-item'>
            <AutocompleteComponent
                idRef='methodOfContactsRef'
                labelValue={t(`${translationPath}methodOfContacts`)}
                value={state.rotationSchemaMethodOfContacts || []}
                data={methodsData || []}
                multiple
                displayLabel={(option) => (option && option.methodOfContactName) || (option && option.lookupItemName) || []}
                chipsLabel={(option) => (option && option.methodOfContactName) || (option && option.lookupItemName) || []}
                selectedValues={currentSelected && currentSelected.rotationSchemaMethodOfContacts || []}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputKeyUp={searchHandler}
                filterOptions={(options) => {
                    const isFind = (id) => currentSelected && currentSelected.rotationSchemaMethodOfContacts.findIndex((item) => (item.methodOfContactId === id) || item.lookupItemId === id) === -1;
                    return options.filter((el) => isFind((el.methodOfContactId) || (el.lookupItemId)));
                }}
                onChange={(event, newValue) => {
                    const localNewValue = {
                        id: 'rotationSchemaMethodOfContacts',
                        value: newValue.map((el) => ({ ...el, methodOfContactId: el.lookupItemId || el.methodOfContactId })),
                    };
                    onStateChanged(localNewValue);
                    setCurrentSelected((e) => ({ ...e, rotationSchemaMethodOfContacts: newValue }))
                }}
                isSubmitted={isSubmitted}
                isLoading={loading}
            />
        </div>
    );
};

MethodOfContact.propTypes = {
    parentTranslationPath: PropTypes.string.isRequired,
    translationPath: PropTypes.string.isRequired,
    onStateChanged: PropTypes.func.isRequired,
    isSubmitted: PropTypes.bool.isRequired,
    state: PropTypes.func.isRequired,
};
