export const NotificationReceiverNotifyBy = {
    Email: {
        key: 'Email',
        value: 1,
    },
    SMS: {
        key: 'SMS',
        value: 2,
    },
    SystemNotification: {
        key: 'SystemNotification',
        value: 3,
    },
}