import React, { useState } from 'react';
import { SelectComponet } from '../../../../../../Components';
import { GetLookupItems, lookupItemsGetId } from '../../../../../../Services';
import { StaticLookupsIds } from '../../../../../../assets/json/StaticLookupsIds';
import { useEffect } from 'react';

export const SaleType = ({
  value,
  setValue,
  parentTranslationPath,
  translationPath,
  labelClasses,
  error,
  helperText,
  isSubmitted,
}) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getSaleTypes = async () => {
    setIsLoading(true);
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.UnitSaleType,
    });
    if (!(res && res.status && res.status !== 200)) setData(res);
    setIsLoading(false);
  };

  useEffect(() => {
    getSaleTypes();
  }, []);
  return (
    <SelectComponet
      labelClasses={labelClasses}
      labelValue={'sale-type'}
      data={data}
      textInput='lookupItemName'
      idRef='SaleTypeRef'
      isLoading={isLoading}
      value={value}
      onSelectChanged={setValue}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      error={error}
      isWithError
      isSubmitted={isSubmitted}
      helperText={helperText}
    />
  );
};
