import React, { useEffect, useState } from 'react';
import { lookupItemsGetId } from '../../../Services';
import { AutocompleteComponent } from '../AutocompleteComponent/AutocompleteComponent';

function LookupAutocomplete({
  lookupTypeId,
  lookupParentId,
  parentTranslationPath,
  translationPath,
  selectedValues,
  multiple = false,
  required,
  onChange,
  wrapperClasses,
  label,
  disabled,
}) {
  const [data, setData] = useState([]);
  //   const [selected, setSelected] = useState(selectedValues || []);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getLookupData = async () => {
      setIsLoading(true);
      const res = await lookupItemsGetId({ lookupTypeId, lookupParentId });
      setData(res);
      setIsLoading(false);
    };
    if (!disabled) {
      getLookupData();
    }
  }, [lookupTypeId, lookupParentId]);
  return (
    <div>
      <AutocompleteComponent
        data={data}
        selectedValues={selectedValues}
        labelClasses={required ? 'Requierd-Color' : ''}
        labelValue={label}
        multiple={multiple}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        displayLabel={(option) => option?.lookupItemName ?? ''}
        renderOption={(option) => option?.lookupItemName || ''}
        isLoading={isLoading}
        onChange={onChange}
        wrapperClasses={wrapperClasses}
        withoutSearchButton
        isDisabled={disabled}
      />
    </div>
  );
}

export default LookupAutocomplete;
