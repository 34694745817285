import React, { useCallback, useEffect, useReducer, useState } from "react";
import { AutocompleteComponent, DialogComponent } from "../../../../Components";
import { useTranslation } from "react-i18next";
import {
  GetAllScopeDocuments,
  GetScopeCategoryDocuments,
  UploadTransactionDocuments,
  lookupItemsGetId,
} from "../../../../Services";
import { config } from "../../../../config";
import { MultipleTypesDocumentsUploader } from "../../../../SharedComponents";
import { ScopeDocumentEnum } from "../../../../Enums";
import { StaticLookupsIds } from "../../../../assets/json/StaticLookupsIds";
import { getDownloadableLink, showError } from "../../../../Helper";

function UnitDocumentsFileUploader({
  AMLTransactions,
  unitId,
  isOpen,
  onCloseClicked,
  translationPath,
  parentTranslationPath,
  IDS,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryFiles, setCategoryFiles] = useState([]);
  const [selected, setSelected] = useReducer(reducer, {
    category: {},
    categoryId: null,
    documents: [],
  });

  const getAllUnitCategories = useCallback(async () => {
    setIsLoading(true);
    const result = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds?.UnitsDocuments,
    });
    if (!(result && result.status && result.status !== 200))
      setCategoryFiles(result);
    else setCategoryFiles([]);
    setIsLoading(false);
  }, []);

  const onSaveClicked = async () => {
    setIsLoading(true);

    const mappedFiles = selected?.documents?.map((file) => ({
      fileId: file.uuid,
      fileName: file.fileName,
      documentLink: file.url,
    }));
    const body = {
      unitTransactionId: IDS,
      scopeId: unitId,
      scopeTypeId: ScopeDocumentEnum?.Unit?.scopeTypeId,
      categoryFiles: [
        {
          categoryId: selected.categoryId,
          files: mappedFiles,
        },
      ],
    };

    const res = await UploadTransactionDocuments(body);
    if (!(res && res.status && res.status !== 200)) {
      onCloseClicked();
    } else {
      showError("couldnt-perform-action");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllUnitCategories();
  }, []);

  return (
    <>
      <DialogComponent
        titleText={t("unit-documents-upload")}
        saveText={t("confirm")}
        saveType="button"
        maxWidth="lg"
        onSaveClicked={() => {
          onSaveClicked();
        }}
        onCloseClicked={() => {
          onCloseClicked();
        }}
        onCancelClicked={() => {
          onCloseClicked();
        }}
        isOpen={isOpen}
        dialogContent={
          <>
            <AutocompleteComponent
              idRef="UnitSaleDocuments"
              labelValue="Unit-Documents"
              data={categoryFiles || []}
              displayLabel={(option) =>
                (option && option?.lookupItemName) || ""
              }
              chipsLabel={(option) => (option && option?.lookupItemName) || ""}
              withoutSearchButton
              selectedValues={selected?.category}
              onChange={(event, newValue) => {
                setSelected({ id: "category", value: newValue || null });
                setSelected({
                  id: "categoryId",
                  value: newValue?.lookupItemId || null,
                });
              }}
              multiple={false}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />

            <MultipleTypesDocumentsUploader
              initUploadedFiles={selected?.documents ?? []}
              labelValue="unit-documents"
              uploadedChanged={(files) => {
                setSelected({
                  id: "documents",
                  value: files,
                });
              }}
              setIsUploading={setIsUploading}
              isUploading={isUploading}
              multiple
              isOpenGallery
              idRef="unitDocumentsRef"
              viewUploadedFilesCount={false}
              openGallery
              isDocuments
              chipHandler={(value) => () => {
                const link = document.createElement("a");
                link.setAttribute("download", value.fileName);
                link.href = getDownloadableLink(value.uuid);
                document.body.appendChild(link);
                link.click();
                link.remove();
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </>
        }
      />
    </>
  );
}

export default UnitDocumentsFileUploader;
