import React, { useState, useEffect, useContext, useRef } from "react";
import countryList from "react-select-country-list";
import { Box } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";

import {
  VIEW_MODES,
  CustomSelect,
  CustomBadge,
  CustomInput,
  CustomAutocomplete,
  CustomButton,
  SavedFiltersAutoComplete,
} from "../../../Components";
import { useSelectedTheme } from "../../../Hooks";
import { ContactLayoutContext } from "../../../Layouts/Home/NewContactsCrmLayout/ContactLayoutContext";
import {
  ContactClassTypesEnum,
  FormsIdsEnum,
  LeadsClassTypesEnum,
  NewTableFilterOperatorsEnum,
} from "../../../Enums";

// Icons
import { FilterLinesICon, CloseXIcon } from "../../../assets/icons";

// Styles
import useStyles from "./styles";
import useCustomInputStyles from "../CustomInput/styles";

function ContactsFilterBar({
  viewMode,
  filterItems,
  setFilterItems,
  allTableColumns,
  data,
  columnsDetails,
}) {
  const {
    theme: { palette },
  } = useSelectedTheme();

  const styles = useStyles();

  const [step, setStep] = useState(1); // Step 1: Select field type, Step 2: Select operation, Step 3: Select value
  const [currentId, setCurrentId] = useState(
    filterItems[filterItems?.length - 1]?.id
  );

  const [isSavedFiltersUpdate, setIsSavedFiltersUpdate] = useState(false);
  const {
    savedFiltersCriteria,
    setSavedFiltersCriteria,
    setAdvancedSearchBody,
    advancedSearchBody,
  } = useContext(ContactLayoutContext);

  // Transform the element and dynamically populate displayObj and displayValue
  const transformElement = (element, value) => {
    return {
      id: element?.id,
      label: element?.headerName,
      input: element?.headerName,
      isHiddenFilter: element?.isHiddenFilter || false,
      isDefaultFilterColumn: element?.isDefaultFilterColumn || false,
      fieldType: element?.fieldType || "text",
      isSortable:
        element?.isSortable !== undefined ? element?.isSortable : true,
      data: element?.data || [],
      displayObj: value,
      displayValue: value,
    };
  };

  useEffect(() => {
    const allValues = [];

    const search = Object.entries(advancedSearchBody?.criteria)?.reduce(
      (acc, [key, items]) => {
        // Find corresponding element in allTableColumns
        const element = allTableColumns?.find((item) => item?.field === key);

        if (element) {
          const field = element?.field?.split(".");
          const firstItem = items?.[0];

          if (firstItem) {
            const { searchType, value } = firstItem;

            // Store all element data in allValues
            allValues.push(transformElement(element, value)); // Push into the array with the actual value

            // Modify and accumulate search object as a single object per key
            acc[field[0]] = {
              value: value,
              operator: searchType, // Rename searchType to operator
              displayPath: element?.displayPath,
              searchableKey: element?.searchableKey || field[0],
            };
          }
        }

        return acc;
      },
      {}
    );

    if (allValues.length > 0) {
      setSavedFiltersCriteria((prevData) => ({
        ...prevData,
        filterDataToAdd: {
          allValues,
          search,
        },
      }));
    }
  }, [advancedSearchBody?.criteria]);

  const handleOnDelete = (id) => {
    setStep(1);
    const item = filterItems.find((item) => item?.id === id);

    if (item?.element?.length === 3) {
      setAdvancedSearchBody((prevBody) => {
        if (item?.field === "leadTypes") {
          return {
            ...prevBody,
            LeadClasses: [],
          };
        } else {
          const criteria = { ...prevBody.criteria };

          delete criteria[item?.field];

          return {
            ...prevBody,
            criteria,
          };
        }
      });
    }

    setFilterItems((prevItems) => prevItems.filter((item) => item?.id !== id));
  };

  useEffect(() => {
    const selectedFilter = savedFiltersCriteria?.data?.find(
      (item) => item?.userSearchCriteriaId === savedFiltersCriteria?.selectedId
    );

    if (selectedFilter) {
      const data = JSON.parse(selectedFilter?.searchCriteria);
      const { search } = data;
      if (search) {
        setAdvancedSearchBody((prevBody) => {
          const criteria = {};
          // Iterate over the search object and set the criteria
          Object.keys(search).forEach((key) => {
            const searchObj = search[key];
            criteria[key] = [
              {
                searchType: searchObj.operator,
                value: searchObj.value,
              },
            ];
          });
          return {
            ...prevBody,
            criteria,
            LeadClasses: [],
            contactClasses: [], // TODO: check this
          };
        });
        setIsSavedFiltersUpdate(true);
      }
    } else {
      setFilterItems([]);
      setAdvancedSearchBody({
        criteria: {},
        filterBy: "createdOn",
        orderBy: 2,
        LeadClasses: [],
        contactClasses: [],
      });
    }
  }, [savedFiltersCriteria?.selectedId]);

  return (
    <Box
      className={styles.filtersSection}
      style={
        viewMode === VIEW_MODES.GRID
          ? { marginBottom: "24px", borderRadius: "8px" }
          : {}
      }
    >
      <Box className={styles.flexRow}>
        <FilterLinesICon
          width="20"
          height="20"
          fill={palette.button.secondary_fg}
        />
        <label>Filters</label>

        {filterItems?.length > 0 &&
          filterItems?.map((item) => (
            <div id={item?.id} key={item?.id}>
              <CustomBadge
                label={item?.element}
                BorderColor={palette.utility.brand_600}
                BackgroundColor={palette.utility.brand_50}
                Color={palette.utility.brand_700}
                isFilterBadge
                DeleteIcon={
                  <CloseXIcon
                    width="12"
                    onClick={() => {
                      handleOnDelete(item?.id);
                    }}
                    id={item?.id}
                    height="12"
                    fill={palette.utility.brand_400}
                    style={{ alignSelf: "center" }}
                  />
                }
              />
            </div>
          ))}

        <DynamicFilterSelect
          filterItems={filterItems}
          setFilterItems={setFilterItems}
          allTableColumns={allTableColumns}
          data={data}
          columnsDetails={columnsDetails}
          step={step}
          setStep={setStep}
          isSavedFiltersUpdate={isSavedFiltersUpdate}
          setIsSavedFiltersUpdate={setIsSavedFiltersUpdate}
          currentId={currentId}
          setCurrentId={setCurrentId}
        />
      </Box>

      <Box className={styles.actions}>
        <Box className={styles.flex} marginRight={2}>
          <CustomButton
            size="md"
            variant="text"
            color="brandPrimary"
            onClick={() => {
              setSavedFiltersCriteria((prevData) => ({
                ...prevData,
                isOpen: false,
                selectedValue: undefined,
                selectedId: 0,
              }));

              setAdvancedSearchBody({
                criteria: {},
                filterBy: "createdOn",
                orderBy: 2,
                LeadClasses: [],
                contactClasses: [],
              });
            }}
          >
            Clear
          </CustomButton>

          <CustomButton
            size="md"
            variant="text"
            color="tertiary"
            onClick={() => {
              setSavedFiltersCriteria((prevData) => ({
                ...prevData,
                isOpen: true,
              }));
            }}
          >
            Save filter
          </CustomButton>
        </Box>
        <SavedFiltersAutoComplete />
      </Box>
    </Box>
  );
}

export default ContactsFilterBar;

function DynamicFilterSelect({
  filterItems,
  setFilterItems,
  allTableColumns,
  data,
  columnsDetails,
  step,
  setStep,
  isSavedFiltersUpdate,
  setIsSavedFiltersUpdate,
  currentId,
  setCurrentId,
}) {
  const {
    theme: { palette },
  } = useSelectedTheme();

  const { advancedSearchBody, setAdvancedSearchBody } =
    useContext(ContactLayoutContext);
  const styles = useStyles();

  const [selectedValue, setSelectedValue] = useState("");

  const TableFilterTypesEnum = {
    text: {
      key: 1,
      defaultOperators: [
        { key: "Equal", id: 1 },
        { key: "Contains", id: 2 },
        { key: "Starts With", id: 5 },
        { key: "Ends With", id: 6 },
        { key: "Not Equal", id: 7 },
        { key: "Not Contains", id: 8 },
        { key: "Is Blank", id: 9 },
        { key: "Is Not Blank", id: 10 },
      ],
      defaultSelectedOperator: "Contains",
    },
    datePicker: {
      key: 2,
      defaultOperators: [
        { key: "Equal Date", id: 1 },
        { key: "Greater Than Date", id: 3 },
        { key: "Less Than Date", id: 4 },
        { key: "Not Equal Date", id: 7 },
        { key: "Is Blank", id: 9 },
        { key: "Is Not Blank", id: 10 },
      ],
      defaultSelectedOperator: "Equal Date",
    },
    numberInput: {
      key: 3,
      defaultOperators: [
        { key: "Equal", id: 1 },
        { key: "Greater Than", id: 3 },
        { key: "Less Than", id: 4 },
        { key: "Not Equal", id: 7 },
        { key: "Is Blank", id: 9 },
        { key: "Is Not Blank", id: 10 },
      ],
      defaultSelectedOperator: "Equal",
    },
    selectOption: {
      key: 4,
      defaultOperators: [
        { key: "Equal", id: 1 },
        { key: "Is Blank", id: 7 },
        { key: "Is Not Blank", id: 10 },
      ],
      defaultSelectedOperator: "Equal",
    },
  };

  const newFieldTypes = allTableColumns
    .filter(
      (item) =>
        item?.headerName !== "Progress" &&
        !filterItems?.some((el) => el?.element?.[0] === item?.headerName)
    )
    .map((item) => ({
      id: item?.id || item?.formFieldId,
      name: item?.headerName || "",
      fieldType: item?.isDate ? "datePicker" : item?.fieldType,
    }));

  const [obj, setObj] = useState({
    fieldTypes: newFieldTypes,
    operations: [],
    values: [],
  });

  useEffect(() => {
    if (allTableColumns?.length > 0) {
      setObj((prevObj) => ({
        ...prevObj,
        fieldTypes: newFieldTypes,
      }));
    }
  }, [allTableColumns?.length, filterItems?.length]);

  const handleFieldTypeChange = (newValue) => {
    setSelectedValue(newValue);

    const displayPath = columnsDetails.find(
      (item) => item?.headerName === newValue
    )?.displayPath;

    const element = allTableColumns.find(
      (item) => item?.headerName === newValue
    );

    const newOperations = TableFilterTypesEnum[
      element?.isDate ? "datePicker" : "text"
    ]?.defaultOperators?.map((op, index) => ({
      id: op?.id,
      name: op?.key,
    }));

    let values = [];

    if (
      element?.fieldType === "select" &&
      displayPath !== "nationality.lookupItemName"
    ) {
      values = [
        { id: 1, name: "Yes" },
        { id: 2, name: "No" },
      ];
    } else if (
      displayPath === "leadTypes" ||
      displayPath === "contact_class.lookupItemName" ||
      displayPath === "contactTypeStr" ||
      displayPath === "nationality.lookupItemName"
    ) {
      switch (displayPath) {
        case "leadTypes":
          values = Object.values(LeadsClassTypesEnum);
          break;

        case "contact_class.lookupItemName":
          values = Object.values(ContactClassTypesEnum);
          break;

        case "contactTypeStr":
          values = Object.values(FormsIdsEnum).splice(0, 2);
          break;

        case "nationality.lookupItemName":
          const updatedCountries = countryList()
            ?.getData()
            ?.map((country, index) => ({
              id: index + 1,
              name: country.label,
            }));
          values = updatedCountries;
          break;

        default:
          break;
      }
    } else {
      const uniqueData = data?.filter((item, index, self) => {
        return (
          self.findIndex((t) => t[displayPath] === item[displayPath]) === index
        );
      });

      values =
        uniqueData
          ?.filter((item) => item[displayPath] && item[displayPath] !== "N/A")
          ?.map((item, index) => ({
            id: index + 1,
            name: item[displayPath],
          })) || [];

      if (values?.length === 0) {
        values = [{ id: 1, name: "Empty" }];
      }
    }

    setObj((prevObj) => ({
      ...prevObj,
      operations: newOperations || [],
      values,
    }));

    setFilterItems((prevItems) => {
      const existingItem = prevItems.find(
        (item) => item?.id === currentId || item?.element?.[0] == newValue
      );

      if (existingItem) {
        return prevItems.map((item) =>
          item?.id === currentId ? { ...item, element: [newValue] } : item
        );
      } else {
        return [
          ...prevItems,
          {
            columnDetails: element,
            id: currentId,
            field: element?.fieldKey || element?.field,
            element: [newValue],
          },
        ];
      }
    });

    setStep(2);
  };

  const handleOperationChange = (newValue) => {
    setFilterItems((prevItems) =>
      prevItems.map((item) =>
        item?.name === newValue || item?.id === currentId
          ? { ...item, element: [...item?.element, newValue] }
          : item
      )
    );
    setStep(3);
  };

  const handleValueChange = (newValue) => {
    setStep(0);

    setFilterItems((prevItems) =>
      prevItems.map((item) =>
        item?.id === currentId
          ? { ...item, element: [...item?.element, newValue] }
          : item
      )
    );
  };

  const getOptions = () => {
    switch (step) {
      case 1:
        return obj?.fieldTypes || [];
      case 2:
        return obj?.operations || [];
      case 3:
        return obj?.values || [];
      default:
        return [];
    }
  };

  const handleChange = (newValue) => {
    if (step === 1) {
      handleFieldTypeChange(newValue);
    } else if (step === 2) {
      handleOperationChange(newValue);
    } else if (step === 3) {
      handleValueChange(newValue);
    }
  };

  useEffect(() => {
    const { criteria, LeadClasses } = advancedSearchBody;

    if (criteria) {
      setFilterItems(() => {
        let obj = [];

        Object.keys(criteria).forEach((key) => {
          let headerName = allTableColumns?.find(
            (el) =>
              el?.fieldKey?.toLowerCase() === key?.toLowerCase() ||
              el?.field?.toLowerCase() === key?.toLowerCase() ||
              el?.headerName?.toLowerCase() === key?.toLowerCase() ||
              el?.field?.split(".")?.[0] === key?.toLowerCase()
          )?.headerName;

          if (!headerName && key === "All") {
            // handle the All field
            headerName = "All";
          } else if (!headerName && key === "opportunityContact") {
            // handle the All field
            headerName = "Contact opportunity";
          }

          criteria[key].forEach((searchObj) => {
            const operation = Object.values(
              NewTableFilterOperatorsEnum("")
            )?.find((op) => op.id === searchObj.searchType);

            const existingItemIndex = obj?.findIndex(
              (item) => item?.element[0] === headerName
            );

            if (existingItemIndex !== -1) {
              // If the object already exists, update its value
              obj[existingItemIndex].element[2] = searchObj?.value;
            } else {
              // Otherwise, add a new object
              obj = [
                ...obj,
                {
                  id: uuidv4(), // Unique id considering the previous items and current obj length
                  field: key,
                  element: [headerName, operation?.name, searchObj?.value],
                },
              ];
            }
          });
        });

        if (LeadClasses?.length > 0) {
          // set the found names into the elements variable
          let elements = LeadClasses?.map((key) => {
            const foundName = Object.values(LeadsClassTypesEnum).find(
              (type) => type.key === key
            )?.name;
            return foundName;
          })?.filter(Boolean);

          elements = elements.join(", ");

          obj = [
            ...obj,
            {
              id: uuidv4(),
              field: "leadTypes",
              element: ["Leads", "Equals", elements],
            },
          ];
        }

        return obj;
      });

      setIsSavedFiltersUpdate(false);
    }
  }, [advancedSearchBody?.criteria, advancedSearchBody?.LeadClasses]);

  useEffect(() => {
    if (step === 0) {
      setTimeout(() => {
        setStep(1);
      }, 100);

      const item = filterItems[filterItems?.length - 1];

      const findFilterOperatorIdByName = (item) => {
        return TableFilterTypesEnum[
          item?.columnDetails?.isDate ? "datePicker" : "text"
        ]?.defaultOperators?.find((op) => op.key === item?.element[1])?.id;
      };

      setAdvancedSearchBody((prevBody) => {
        if (item?.field === "leadTypes") {
          const foundKey = Object.values(LeadsClassTypesEnum).find(
            (type) => type.name === item?.element?.[2]
          )?.key;

          return {
            ...prevBody,
            LeadClasses: [...new Set([...prevBody.LeadClasses, foundKey])],
          };
        } else {
          const criteria = { ...prevBody.criteria };

          // Update the existing array if the key exists, otherwise create a new array
          if (criteria[item?.field]) {
            criteria[item?.field] = [
              {
                searchType: findFilterOperatorIdByName(item),
                value: item?.element[2],
              },
            ];
          } else {
            criteria[item?.field] = [
              {
                searchType: findFilterOperatorIdByName(item),
                value: item?.element[2],
              },
            ];
          }

          return {
            ...prevBody,
            criteria,
          };
        }
      });
    }
  }, [step]);

  return (
    <Box style={{ marginLeft: "4px" }}>
      {(step === 3 &&
        (["Contact name", "Email", "Phone"]?.includes(selectedValue) ||
          obj?.values?.[0]?.name === "Empty")) ||
      allTableColumns?.find((item) => item?.headerName === selectedValue)
        ?.isDate ? (
        allTableColumns?.find((item) => item?.headerName === selectedValue)
          ?.isDate ? (
          <CustomDateInput
            isDate={true}
            style={{ width: "150px" }}
            onBlur={(e) => {
              handleValueChange(e.target.value);
            }}
          />
        ) : (
          <CustomInput
            hasNoBorder={true}
            placeholder="Enter value"
            style={{ width: "150px", margin: "0 10px" }}
            hasSearchIcon={false}
            type="text"
            handleOnBlur={(e) => {
              handleValueChange(
                e.target.value.length > 0 ? e.target.value : "Empty value"
              );
            }}
          />
        )
      ) : (
        <CustomAutocomplete
          showCloseIcon={false}
          hideInputValue={true}
          placeholder="Enter property name or value"
          style={{ width: 300 }}
          showDropdownIcon={false}
          hasNoBorder={true}
          open={step > 1}
          onChange={handleChange}
          options={
            step === 3 &&
            allTableColumns?.find((item) => item?.headerName === selectedValue)
              ?.isDate
              ? []
              : getOptions()
          }
        />
      )}
    </Box>
  );
}

const CustomDateInput = ({ style, onBlur, isDate }) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const styles = useCustomInputStyles();

  useEffect(() => {
    if (isDate) {
      const input = inputRef.current;

      const formatDateInput = (e) => {
        const key = e.keyCode || e.which;
        const value = input.value;
        const len = value.length;

        // Allow only numbers and slashes
        if ((key < 48 || key > 57) && key !== 47) {
          e.preventDefault();
          return;
        }

        // Automatically insert slashes
        if (len === 2 || len === 5) {
          input.value += "/";
        }

        // Prevent multiple slashes
        if ((len === 1 || len === 4) && key === 47) {
          e.preventDefault();
        }

        // Update state with formatted value
        setInputValue(input.value);
      };

      const validateDate = () => {
        let value = input.value;
        const datePattern =
          /^(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/(19|20)\d{2}$/;

        // Ensure month and day are valid; replace invalid entries with '00'
        if (value) {
          const [month, day, year] = value
            .split("/")
            .map((part) => part || "00");
          const monthNumber = parseInt(month, 10);
          const dayNumber = parseInt(day, 10);

          // Check and replace invalid month and day
          if (monthNumber < 1 || monthNumber > 12) {
            value = `00/${day}/20${year}`;
          }
          if (dayNumber < 1 || dayNumber > 31) {
            value = `${month}/00/20${year}`;
          }

          input.value = value;

          // Validate the date
          if (datePattern.test(value)) {
            const date = new Date(`20${year}`, monthNumber - 1, dayNumber);
            if (
              date.getDate() !== dayNumber ||
              date.getMonth() !== monthNumber - 1 ||
              date.getFullYear() !== `20${year}`
            ) {
              input.setCustomValidity("Invalid date");
              input.reportValidity();
            } else {
              input.setCustomValidity("");
            }
          } else {
            input.setCustomValidity("Date must be in MM/DD/YYYY format");
            input.reportValidity();
          }
        }
      };

      input.addEventListener("keypress", formatDateInput);
      input.addEventListener("blur", validateDate);

      // Cleanup event listeners on component unmount
      return () => {
        input.removeEventListener("keypress", formatDateInput);
        input.removeEventListener("blur", validateDate);
      };
    }
  }, [isDate]);

  useEffect(() => {
    // Update the input value when the prop changes
    if (inputRef.current) {
      inputRef.current.value = inputValue;
    }
  }, [inputValue]);

  const handleBlur = (e) => {
    setInputValue(e.target.value);
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <div
      style={style}
      className={clsx(
        styles.inputContainer,
        styles.inputContainerNoBorder,
        styles.inputContainerFocused
      )}
    >
      <input
        ref={inputRef}
        placeholder="mm/dd/yyyy"
        style={style}
        type="date"
        maxLength={10}
        onBlur={handleBlur}
      />
    </div>
  );
};
