import React, { useEffect, useRef, useState, useCallback, useReducer } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { KenbanCardsLayout } from './KenbanCardsLayout/KenbanCardsLayout';
import './KenbanBoardView.scss';
import {
  GetTasks, GetAllCampaignDepartments, GetAllCampaignsRequests,
  GetTasksAssignees
} from '../../../../Services';
import { AutocompleteComponent } from '../../../../Components';
import { DateRangePickerComponent } from '../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import { GroupedAvatarComponent } from '../../../../SharedComponents';
import { useTitle } from '../../../../Hooks';
import { bottomBoxComponentUpdate } from '../../../../Helper/Middleware.Helper';

export function KenbanBoardView({ }) {
  const parentTranslationPath = 'Campaign';
  const translationPath = 'kenban-Board.';
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t('Marketing-kenban-board'));
  const searchTimer = useRef(null);
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
    selectedDateType: 1,
  };
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const defaultBoardTasks = {
    toDoTasks: {
      result: [],
      totalCount: 0
    },
    inprogressTasks: {
      result: [],
      totalCount: 0
    },
    reviewTasks: {
      result: [],
      totalCount: 0
    },
    completedTasks: {
      result: [],
      totalCount: 0
    }
  }
  const [boardTasks, setBoardTasks] = useState(defaultBoardTasks);
  const [paginationFilter, setPaginationFilter] = useState({
    pageIndex: 1,
    pageSize: 10,
  });
  const [selected, setSelected] = useReducer(reducer, {
    department: null,
    campaign: null,
    assignedToId: null,
    deliveryDateFrom: null,
    deliveryDateTo: null,
  });
  const [isLoading, setIsLoading] = useReducer(reducer, {
    departments: false,
    campaigns: false,
  });
  const [data, setData] = useReducer(reducer, {
    assignees: [],
    departments: [],
    campaigns: [],
  });
  const [dateFilter, setDateFilter] = useState({
    startDate: null,
    endDate: null,
    key: 'selection',
    selectedDateType: dateRangeDefault,
  });

  const getBoardTasks = useCallback(async () => {
    const body = {
      ...paginationFilter,
      departmentId: selected?.department?.departmentId || null,
      campaignName: selected?.campaign?.campaignName || null,
      deliveryDateFrom: selected?.deliveryDateFrom || null,
      deliveryDateTo: selected?.deliveryDateTo || null,
      assignedToId: selected.assignedToId || null,
    };

    const res = await GetTasks(body);
    if (!(res && res.status && res.status !== 200)) {
      if (paginationFilter.pageIndex == 1) setBoardTasks(res || defaultBoardTasks);
      else addToPreviousData(res);
    } else addToPreviousData(null);
  }, [paginationFilter, selected]);

  const getTasksAssignees = async () => {
    const res = await GetTasksAssignees();
    if (!(res && res.status && res.status !== 200)) {
      const groupedAvatarData = res?.map(item => ({
        applicationUserId: item.assignedToId,
        fullName: item.assignedTo,
        profileImg: item.assignedToProfileImg,
      }))
      setData({ id: 'assignees', value: groupedAvatarData || [] });
    } else setData({ id: 'assignees', value: [] });
  };

  const getAllCampaignDepartments = async (searchValue) => {
    setIsLoading({ id: 'departments', value: true });
    const res = await GetAllCampaignDepartments({
      search: searchValue || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'departments', value: res || [] });
    } else setData({ id: 'departments', value: [] });

    setIsLoading({ id: 'departments', value: false });
  };

  const getAllCampaignsRequests = async (searchValue) => {
    setIsLoading((item) => ({ ...item, campaigns: true }));

    const body = {
      search: searchValue || null,
    };
    const res = await GetAllCampaignsRequests({
      pageIndex: 1,
      pageSize: 10,
      body,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: 'campaigns',
        value: res?.result || [],
      });
    } else {
      setData({
        id: 'campaigns',
        value: [],
      });
    }
    setIsLoading((item) => ({ ...item, campaigns: false }));
  };

  const dateFilterHandler = (selectedDate) => {
    paginationChangeHandler(1);

    setDateFilter((item) => ({
      ...item,
      startDate: selectedDate?.selection?.startDate,
      endDate: selectedDate?.selection?.endDate,
      key: 'selection',
    }));
    setSelected({
      id: 'edit',
      value: {
        ...selected,
        deliveryDateFrom: selectedDate?.selection?.startDate
          ? new Date(moment(selectedDate.selection.startDate).endOf('day'))
          : null,
        deliveryDateTo: selectedDate?.selection?.endDate
          ? new Date(moment(selectedDate.selection.endDate).endOf('day'))
          : null,
      },
    });
  };

  const addToPreviousData = (newData) => {
    if (newData) {
      const mappedBoardTasks = {};

      for (const key of Object.keys(defaultBoardTasks)) {
        const previousResult = boardTasks?.[key]?.result || [];
        const newResult = newData?.[key]?.result || [];

        mappedBoardTasks[key] = ({
          result: [...previousResult, ...newResult],
          totalCount: newData?.[key]?.totalCount || 0,
        })
      }

      setBoardTasks(mappedBoardTasks);
    }
  }

  const paginationChangeHandler = (pageIndex) => {
    setPaginationFilter((prevState) => ({ ...prevState, pageIndex }))
  }

  useEffect(() => {
    bottomBoxComponentUpdate(null);
  }, []);

  useEffect(() => {
    getTasksAssignees();
  }, []);

  useEffect(() => {
    getBoardTasks();
  }, [getBoardTasks]);


  return (
    <div className='marketing-module-view board-overflow d-flex-column-center px-1 mx-4 pb-4'>
      <div className='d-flex-column kenban-filters'>
        <div>
          <h1 className='fw-simi-bold fz-30'>Kanban Board</h1>
          <p className='fz-12 text-tertiary'>This is the committed plan for the team.</p>
        </div>
        <div className='campaign-team-f1 d-flex-v-center-h-between w-100'>
          <div className='d-flex-default w-50'>
            <div className='mr-2 w-50 min-width-unset'>
              <AutocompleteComponent
                idRef='departmentRef'
                inputPlaceholder={t(`department`)}
                selectedValues={selected.department}
                isDisabled={isLoading.departments}
                data={data.departments || []}
                displayLabel={(option) => option.name || ''}
                multiple={false}
                withoutSearchButton
                onOpen={() => {
                  if (data?.departments?.length === 0) getAllCampaignDepartments();
                }}
                onChange={(event, newValue) => {
                  paginationChangeHandler(1);
                  setSelected({
                    id: 'department',
                    value: newValue,
                  });
                }}
                isLoading={isLoading.departments}
              />
            </div>
            <div className='mr-2 w-50 min-width-unset'>
              <AutocompleteComponent
                idRef='campaignRef'
                inputPlaceholder={t(`Campaigns`)}
                selectedValues={selected.campaign}
                isDisabled={isLoading.campaigns}
                data={data.campaigns || []}
                displayLabel={(option) => option.name || option.campaignName || ''}
                multiple={false}
                withoutSearchButton
                onOpen={() => {
                  if (data?.campaigns?.length === 0) getAllCampaignsRequests();
                }}
                onChange={(event, newValue) => {
                  paginationChangeHandler(1);
                  setSelected({
                    id: 'campaign',
                    value: newValue,
                  });
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getAllCampaignsRequests(value);
                  }, 1200);
                }}
                isLoading={isLoading.campaigns}
              />
            </div>
            <DateRangePickerComponent
              onClearClicked={() => {
                setDateFilter(dateRangeDefault);
                dateFilterHandler(dateRangeDefault);
              }}
              ranges={[dateFilter]}
              parentTranslationPath={parentTranslationPath}
              onDateChanged={(selectedDate) => {
                dateFilterHandler(selectedDate);
              }}
              wrapperClasses='d-flex-default'
            />
          </div>
          <GroupedAvatarComponent
            data={data.assignees || []}
            selectedUserId={selected.assignedToId}
            userItemClickHandler={(userId) => {
              paginationChangeHandler(1);
              setSelected({ id: 'assignedToId', value: userId });
            }}
            maxAvatarsNumber={5}
          />
        </div>
      </div>
      {boardTasks &&
        <KenbanCardsLayout
          data={boardTasks || {}}
          paginationChangeHandler={paginationChangeHandler}
          paginationFilter={paginationFilter}
        />}
    </div>
  );
}
