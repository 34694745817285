import { makeStyles, Chip } from '@material-ui/core';
import React from 'react';

const colors = {
  good: 'green',
  neutral: 'yellow',
  negative: 'red',
};

const useStyles = makeStyles({
  positive: {
    backgroundColor: colors.good,
  },
  negative: {
    backgroundColor: colors.negative,
  },
  neutral: {
    backgroundColor: colors.neutral,
    color: 'blue',
  },
  notEvaluated: {
    backgroundColor: 'gray',
  },
});

function EvaluationChip({ sentimentEvaluation, size }) {
  const classes = useStyles();
  return (
    <Chip
      color='primary'
      label={sentimentEvaluation ?? 'Not Evaluated'}
      className={classes[sentimentEvaluation?.toLowerCase() ?? 'notEvaluated']}
      size={size}
    />
  );
}

export default EvaluationChip;
