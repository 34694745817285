import React, { useReducer, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import { DialogActions, DialogContent, DialogTitle, Dialog, ButtonBase } from '@material-ui/core';
import { showError, showSuccess, getErrorByName } from '../../../../../../Helper';
import { AutocompleteComponent, Inputs, Spinner } from '../../../../../../Components';
import {
  GetLookupItemsByLookupTypeName,
  GetQualifiedLeadDetails,
  MyLeadUpdateLead,
} from '../../../../../../Services';
import { LeadBathroomsEnum, LeadBedroomsEnum } from '../../../../../../Enums';

const translationPath = 'LeadsView:utilities.qualifyLeadDialog.';
export const QualifyLeadDialog = ({ isOpen,
   onClose,
   parentTranslationPath,
   checkedLeadsIds,
   activityLeadId,
   setActivityQualifyData,
   clearSelectedLead,
  }) => {
  const { t } = useTranslation('LeadsView');
  const budgetMaxValue = 60000000;
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [filter, setFilter] = useState({
    pageSize: 100,
    pageIndex: 0,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useReducer(reducer, {
    leadType: false,
    purpose: false,
    propertyTypes: false,
    propertyPlans: false,
    unitUsageType: false,
    modeOfPayment: false,
  });
  const [selected, setSelected] = useReducer(reducer, {
    leadType: null,
    purpose: null,
    propertyTypes: [],
    propertyPlan: null,
    unitUsageType: null,
    modeOfPayment: null,
    numberOfBathrooms: [],
    numberOfBedrooms: [],
    budgetFrom: 0,
    budgetTo: 0,
  });
  const [data, setData] = useReducer(reducer, {
    leadType: [],
    purpose: [],
    propertyTypes: [],
    propertyPlans: [],
    unitUsageType: [],
    modeOfPayment: [],
  });

  const [state, setState] = useReducer(reducer, {
    leadTypeId: null,
    purposeId: null,
    propertyUnitTypeIDs: [],
    propertyPlanId: null,
    unitUsageType: null,
    modeOfPaymentId: null,
    numberOfBathrooms: [],
    numberOfBedrooms: [],
    budgetFrom: 0,
    budgetTo: 0,
  });

  const getLeadTypeLookups = useCallback(async () => {
    setIsLoading({ id: 'leadType', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'LeadOperationType',
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'leadType', value: res.result || [] });
    } else setData({ id: 'leadType', value: [] });
    setIsLoading({ id: 'leadType', value: false });
  }, []);

  const getPurposeLookups = useCallback(async () => {
    setIsLoading({ id: 'purpose', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'Purpose',
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'purpose', value: res.result || [] });
    } else setData({ id: 'purpose', value: [] });
    setIsLoading({ id: 'purpose', value: false });
  }, []);

  const getPropertyTypeLookups = useCallback(async () => {
    setIsLoading({ id: 'propertyTypes', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'UnitType',
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'propertyTypes', value: res.result || [] });
    } else setData({ id: 'propertyTypes', value: [] });
    setIsLoading({ id: 'propertyTypes', value: false });
  }, []);

  const getUnitUsageLookups = useCallback(async () => {
    setIsLoading({ id: 'unitUsageType', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'Property Usage',
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex,
    });

    if (!(res && res.status && res.status !== 200)) {
      const filteredData = res.result
        ? res.result.filter(
            (item) => item.lookupItemName === "Commercial"
            || item.lookupItemName === "Residential"
          )
        : [];
      setData({ id: 'unitUsageType', value: filteredData });
    } else setData({ id: 'unitUsageType', value: [] });
    setIsLoading({ id: 'unitUsageType', value: false });
  }, []);

  const getPropertyPlansLookups = useCallback(async () => {
    setIsLoading({ id: 'propertyPlans', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'Property Plan',
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'propertyPlans', value: res.result || [] });
    } else setData({ id: 'propertyPlans', value: [] });
    setIsLoading({ id: 'propertyPlans', value: false });
  }, []);

  const getModeOfPaymentLookups = useCallback(async () => {
    setIsLoading({ id: 'modeOfPayment', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'ModeOfPayment',
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'modeOfPayment', value: res.result || [] });
    } else setData({ id: 'modeOfPayment', value: [] });
    setIsLoading({ id: 'modeOfPayment', value: false });
  }, []);

  const fillInitialQualificationData = (res)=>{

      const propertyUnitTypeIDs = res?.propertyUnitType
        ? res.propertyUnitType.map((item) => item?.lookupsId)
        : [];
      const propertyTypes = res?.propertyUnitType
        ? res.propertyUnitType.map((item) => ({
            lookupItemName: item.lookupItemName,
            lookupItemId: item.lookupsId,
          }))
        : [];

      const filteredNumberOfBathrooms = res?.numberOfBathrooms
      ? res.numberOfBathrooms.filter(value=> value)
      : [];
      const filteredNumberOfBedrooms = res?.numberOfBedrooms
      ? res.numberOfBedrooms.filter(value=> value)
      : [];
      const numberOfBathrooms = filteredNumberOfBathrooms.map((item) =>
            LeadBathroomsEnum.find((enumItem) => enumItem.value == item)
          );
      const numberOfBedrooms = filteredNumberOfBedrooms.map((item) =>
            LeadBedroomsEnum.find((enumItem) => enumItem.value == item)
          );

      setState({
        id: 'edit',
        value: {
          leadTypeId: res?.leadType?.lookupsId || null,
          purposeId: res?.purpose?.lookupsId || null,
          propertyPlanId: res?.propertyStatus?.lookupsId || null,
          unitUsageType: res?.propertyUsage || null,
          modeOfPaymentId: res?.modeOfPayment?.lookupsId || null,
          numberOfBathrooms: res?.numberOfBathrooms || [],
          numberOfBedrooms: res?.numberOfBedrooms || [],
          budgetFrom: ((res?.budget && res.budget.length) > 0 && res.budget[0]) || 0,
          budgetTo: ((res?.budget && res.budget.length) > 0 && res.budget[1]) || 0,
          propertyUnitTypeIDs,
        },
      });

      setSelected({
        id: 'edit',
        value: {
          leadType: res?.leadType
            ? {
                lookupItemName: res.leadType.lookupItemName,
                lookupItemId: res.leadType.lookupsId,
              }
            : null,
          purpose: res?.purpose
            ? {
                lookupItemName: res.purpose.lookupItemName,
                lookupItemId: res.purpose.lookupsId,
              }
            : null,
          propertyPlan: res?.propertyStatus
            ? {
                lookupItemName: res.propertyStatus.lookupItemName,
                lookupItemId: res.propertyStatus.lookupsId,
              }
            : null,
          unitUsageType: res?.propertyUsage
            ? {
                lookupItemName: res.propertyUsage,
                lookupItemId: null,
              }
            : null,
          modeOfPayment: res?.modeOfPayment
            ? {
                lookupItemName: res.modeOfPayment.lookupItemName,
                lookupItemId: res.modeOfPayment.lookupsId,
              }
            : null,
          numberOfBathrooms,
          numberOfBedrooms,
          budgetFrom: ((res?.budget && res.budget.length) > 0 && res.budget[0]) || 0,
          budgetTo: ((res?.budget && res.budget.length) > 0 && res.budget[1]) || 0,
          propertyTypes,
        },
      });
  }

  const getQualifiedLeadDetails = useCallback(async () => {
    const leadId = checkedLeadsIds? checkedLeadsIds[0] : activityLeadId;

    const res = await GetQualifiedLeadDetails(leadId);

    if (!(res && res.status && res.status !== 200)) {
      fillInitialQualificationData(res);
    }
  }, [checkedLeadsIds, activityLeadId]);


  const schema = Joi.object({
    leadTypeId: Joi.number()
      .required()
      .messages({
        'string.base': t(`${translationPath}leadTypeId`),
      }),
    propertyPlanId: Joi.number()
      .required()
      .messages({
        'string.base': t(`${translationPath}propertyPlanId`),
      }),
    purposeId: Joi.number()
      .required()
      .messages({
        'string.base': t(`${translationPath}purposeId`),
      }),
    modeOfPaymentId: Joi.number()
      .required()
      .messages({
        'string.base': t(`${translationPath}modeOfPaymentId`),
      }),
    unitUsageType: Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}modeOfPaymentId`),
      }),
    budgetFrom: Joi.number()
      .custom((value, helpers) => {
        if (value < 0) return helpers.error('state.required');

        return value;
      })
      .messages({
        'state.required': t(`${translationPath}budgetFrom`),
      }),
    budgetTo: Joi.number()
      .custom((value, helpers) => {
        if (value > budgetMaxValue) return helpers.error('state.required');

        return value;
      })
      .messages({
        'state.required': t(`${translationPath}budgetTo`),
      }),
    numberOfBathrooms: Joi.array()
      .custom((value, helpers) => {
        if (value && value.length == 0) return helpers.error('state.required');

        return value;
      })
      .messages({
        'state.required': t(`${translationPath}numberOfBathrooms`),
      }),
    numberOfBedrooms: Joi.array()
      .custom((value, helpers) => {
        if (value && value.length == 0) return helpers.error('state.required');

        return value;
      })
      .messages({
        'state.required': t(`${translationPath}numberOfBedrooms`),
      }),
    propertyUnitTypeIDs: Joi.array()
      .custom((value, helpers) => {
        if (value && value.length == 0) return helpers.error('state.required');

        return value;
      })
      .messages({
        'state.required': t(`${translationPath}propertyUnitTypeIDs`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);


  const budgetSchema = Joi.object({
    budgetFrom: Joi.number()
      .custom((value, helpers) => {
        if (value <= 0) return helpers.error('state.required');
        return value;
      })
      .messages({
        'state.required': t(`${translationPath}budgetFrom-should-be-greater-than-zero`),
      }),
    budgetTo: Joi.number()
      .custom((value, helpers) => {
        if (value < state.budgetFrom) return helpers.error('state.greater-than-budgetFrom');
        return value;
      })
      .messages({
        'state.greater-than-budgetFrom': t(`${translationPath}budgetTo-should-be-greater-than-budgetfrom`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const updateLead = useCallback(async () => {
    setIsSaving(true);
    const body = {
      ...state,
    };
    const leadId = checkedLeadsIds[0];
    const res = await MyLeadUpdateLead({ id: leadId, body });

    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}lead-qualify-success`));
      onClose();
    } else showError(t(`${translationPath}lead-qualify-fail`));
    setIsSaving(false);
  }, [state, checkedLeadsIds]);

  const qualifyLeadClickHandler = () => {
    if (schema.error) {
      showError(t(`${translationPath}please-fill-all-required-fields`));
      return;
    }
    if (budgetSchema.error) {
      showError(budgetSchema.error?.message);
      return;
    }

    if(activityLeadId){
      setActivityQualifyData({
        ...state,
      });
      onClose();
    } else updateLead();

  };

  useEffect(() => {
    getLeadTypeLookups();
    getPurposeLookups();
    getPropertyTypeLookups();
    getUnitUsageLookups();
    getPropertyPlansLookups();
    getModeOfPaymentLookups();
    getQualifiedLeadDetails();
  }, []);

  return (
    <div>
      <Dialog
        open={isOpen}
        maxWidth='sm'
        className='activities-management-dialog-wrapper'
        disableBackdropClick>
        <DialogTitle id='qualify-lead-dialog'>{t(`${translationPath}qualify-lead`)}</DialogTitle>
        <DialogContent>
          <div>
            <Spinner isActive={isSaving} isAbsolute />
            <div className='w-100 d-flex p-2'>
              <div className='w-50'>
                <div className='my-2 mx-2'>
                  <AutocompleteComponent
                    idRef='LeadOperationTypeIdRef'
                    labelValue='lead-operation-type'
                    labelClasses='Requierd-Color'
                    selectedValues={selected.leadType || []}
                    multiple={false}
                    data={data.leadType}
                    displayLabel={(option) => option.lookupItemName || ''}
                    renderOption={(option) => option.lookupItemName || ''}
                    getOptionSelected={(option) => option.lookupItemId === state.leadTypeId}
                    withoutSearchButton
                    isLoading={isLoading.leadType}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                      setSelected({ id: 'leadType', value: newValue });
                      setState({
                        id: 'leadTypeId',
                        value: (newValue && newValue.lookupItemId) || null,
                      });
                    }}
                  />
                </div>

                <div className='my-2 mx-2'>
                  <AutocompleteComponent
                    idRef='purposeIdRef'
                    labelValue='purpose'
                    labelClasses='Requierd-Color'
                    selectedValues={selected.purpose || []}
                    multiple={false}
                    data={data.purpose}
                    displayLabel={(option) => option.lookupItemName || ''}
                    renderOption={(option) => option.lookupItemName || ''}
                    getOptionSelected={(option) => option.lookupItemId === state.purposeId}
                    withoutSearchButton
                    isLoading={isLoading.purpose}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                      setSelected({ id: 'purpose', value: newValue });
                      setState({
                        id: 'purposeId',
                        value: (newValue && newValue.lookupItemId) || null,
                      });
                    }}
                  />
                </div>

                <div className='my-2 mx-2'>
                  <AutocompleteComponent
                    idRef='propertyPlanIdRef'
                    labelValue='propertyPlan'
                    labelClasses='Requierd-Color'
                    selectedValues={selected.propertyPlan || []}
                    multiple={false}
                    data={data.propertyPlans}
                    displayLabel={(option) => option.lookupItemName || ''}
                    renderOption={(option) => option.lookupItemName || ''}
                    getOptionSelected={(option) => option.lookupItemId === state.propertyPlanId}
                    withoutSearchButton
                    isLoading={isLoading.propertyPlans}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                      setSelected({ id: 'propertyPlan', value: newValue });
                      setState({
                        id: 'propertyPlanId',
                        value: (newValue && newValue.lookupItemId) || null,
                      });
                    }}
                  />
                </div>

                <div className='my-2 mx-2'>
                  <AutocompleteComponent
                    idRef='numberOfBathroomsRef'
                    labelValue={t(`${translationPath}numberOfBathrooms`)}
                    labelClasses='Requierd-Color'
                    selectedValues={selected.numberOfBathrooms || []}
                    multiple
                    data={LeadBathroomsEnum}
                    chipsLabel={(option) => option.value || ''}
                    displayLabel={(option) => option.value || ''}
                    renderOption={(option) => option.value || ''}
                    getOptionSelected={(option) =>
                      selected.numberOfBathrooms.findIndex(
                        (item) => item.value === option.value
                      ) !== -1 || ''
                    }
                    withoutSearchButton
                    onChange={(event, newValue) => {
                      const isANYPrevSelected =
                        selected.numberOfBathrooms &&
                        selected.numberOfBathrooms.find((item) => item.value === 'Any');
                      const isANYSelected =
                        newValue && newValue.find((item) => item.value === 'Any');

                      if (isANYPrevSelected && newValue && newValue.length !== 0) return;

                      if (isANYSelected) {
                        const filteredANYOption =
                          newValue.filter((item) => item.value == 'Any') || [];
                        const stateANYValue = filteredANYOption
                          ? filteredANYOption.map((item) => item.value)
                          : [];
                        setSelected({ id: 'numberOfBathrooms', value: filteredANYOption });
                        setState({
                          id: 'numberOfBathrooms',
                          value: stateANYValue,
                        });
                        return;
                      }

                      setSelected({ id: 'numberOfBathrooms', value: newValue });
                      const bathroomsNoValues = newValue && newValue.map((item) => item.value);
                      setState({
                        id: 'numberOfBathrooms',
                        value: bathroomsNoValues,
                      });
                    }}
                  />
                </div>

                <fieldset className='styled-fieldset d-flex mx-2'>
                  <legend className='styled-legend'>{t(`${translationPath}budget`)}</legend>
                  <div className='w-50 mr-2'>
                    <Inputs
                      idRef='budgetFromRef'
                      labelClasses='Requierd-Color'
                      labelValue={t(`${translationPath}from`)}
                      value={state.budgetFrom || 0}
                      type={'number'}
                      withNumberFormat
                      onInputChanged={(event) => {
                        const { value } = event.target;
                        if(value <= budgetMaxValue) 
                        setState({
                          id: 'budgetFrom',
                          value: value,
                        });
                        else setState({
                          id: 'budgetFrom',
                          value: 0,
                        });
                      }}
                      min={0}
                    />
                  </div>
                  <div className='w-50'>
                    <Inputs
                      idRef='budgetToRef'
                      labelClasses='Requierd-Color'
                      labelValue={t(`${translationPath}to`)}
                      value={state.budgetTo || 0}
                      type={'number'}
                      withNumberFormat
                      onInputChanged={(event) => {
                        const { value } = event.target;

                        if(value <= budgetMaxValue) 
                        setState({
                          id: 'budgetTo',
                          value: value,
                        });
                        else setState({
                          id: 'budgetTo',
                          value: budgetMaxValue,
                        });
                      }}
                      min={0}
                    />
                  </div>
                </fieldset>
              </div>

              <div className='w-50'>
                <div className='my-2 mx-2'>
                  <AutocompleteComponent
                    idRef='modeOfPaymentIdRef'
                    labelValue='modeOfPayment'
                    labelClasses='Requierd-Color'
                    selectedValues={selected.modeOfPayment || []}
                    multiple={false}
                    data={data.modeOfPayment}
                    displayLabel={(option) => option.lookupItemName || ''}
                    renderOption={(option) => option.lookupItemName || ''}
                    getOptionSelected={(option) => option.lookupItemId === state.modeOfPaymentId}
                    withoutSearchButton
                    isLoading={isLoading.modeOfPayment}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                      setSelected({ id: 'modeOfPayment', value: newValue });
                      setState({
                        id: 'modeOfPaymentId',
                        value: (newValue && newValue.lookupItemId) || null,
                      });
                    }}
                  />
                </div>

                <div className='my-2 mx-2'>
                  <AutocompleteComponent
                    idRef='propertyTypesIdRef'
                    labelValue='propertyTypes'
                    labelClasses='Requierd-Color'
                    selectedValues={selected.propertyTypes || []}
                    multiple
                    data={data.propertyTypes}
                    chipsLabel={(option) => option.lookupItemName || ''}
                    displayLabel={(option) => option.lookupItemName || ''}
                    renderOption={(option) => option.lookupItemName || ''}
                    getOptionSelected={(option) =>
                      selected.propertyTypes.findIndex(
                        (item) => item.lookupItemId === option.lookupItemId
                      ) !== -1 || ''
                    }
                    withoutSearchButton
                    isLoading={isLoading.propertyTypes}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                      setSelected({ id: 'propertyTypes', value: newValue });
                      const propertyUnitTypeIDs =
                        newValue && newValue.map((item) => item.lookupItemId);
                      setState({
                        id: 'propertyUnitTypeIDs',
                        value: propertyUnitTypeIDs || null,
                      });
                    }}
                  />
                </div>

                <div className='my-2 mx-2'>
                  <AutocompleteComponent
                    idRef='propertyUsageIdRef'
                    labelValue='propertyUsage'
                    labelClasses='Requierd-Color'
                    selectedValues={selected.unitUsageType || []}
                    multiple={false}
                    data={data.unitUsageType}
                    displayLabel={(option) => option.lookupItemName || ''}
                    renderOption={(option) => option.lookupItemName || ''}
                    getOptionSelected={(option) => option.lookupItemName === state.unitUsageType}
                    withoutSearchButton
                    isLoading={isLoading.unitUsageType}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                      setSelected({ id: 'unitUsageType', value: newValue });
                      setState({
                        id: 'unitUsageType',
                        value: (newValue && newValue.lookupItemName) || null,
                      });
                    }}
                  />
                </div>

                <div className='my-2 mx-2'>
                  <AutocompleteComponent
                    idRef='numberOfBedroomsRef'
                    labelValue={t(`${translationPath}numberOfBedrooms`)}
                    labelClasses='Requierd-Color'
                    selectedValues={selected.numberOfBedrooms || []}
                    multiple
                    data={LeadBedroomsEnum}
                    chipsLabel={(option) => option.value || ''}
                    displayLabel={(option) => option.value || ''}
                    renderOption={(option) => option.value || ''}
                    getOptionSelected={(option) =>
                      selected.numberOfBedrooms.findIndex((item) => item.value === option.value) !==
                        -1 || ''
                    }
                    withoutSearchButton
                    onChange={(event, newValue) => {
                      const isANYPrevSelected =
                        selected.numberOfBedrooms &&
                        selected.numberOfBedrooms.find((item) => item.value === 'Any');
                      const isANYSelected =
                        newValue && newValue.find((item) => item.value === 'Any');

                      if (isANYPrevSelected && newValue && newValue.length !== 0) return;

                      if (isANYSelected) {
                        const filteredANYOption =
                          newValue.filter((item) => item.value == 'Any') || [];
                        const stateANYValue = filteredANYOption
                          ? filteredANYOption.map((item) => item.value)
                          : [];
                        setSelected({ id: 'numberOfBedrooms', value: filteredANYOption });
                        setState({
                          id: 'numberOfBedrooms',
                          value: stateANYValue,
                        });
                        return;
                      }

                      setSelected({ id: 'numberOfBedrooms', value: newValue });
                      const bedroomsNoValues = newValue && newValue.map((item) => item.value);
                      setState({
                        id: 'numberOfBedrooms',
                        value: bedroomsNoValues,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className='form-builder-wrapper'>
            <div className='form-builder-footer-wrapper is-dialog w-100 MuiGrid-align-items-xs-center MuiGrid-justify-xs-space-between'>
              <div className='MuiDialogActions-root dialog-footer-wrapper  MuiDialogActions-spacing'>
                <div className='save-cancel-wrapper d-flex-v-center-h-end flex-wrap p-2'>
                  <div className='cancel-wrapper d-inline-flex-center'>
                    <ButtonBase
                      className='MuiButtonBase-root MuiButton-root MuiButton-text cancel-btn-wrapper btns theme-transparent c-primary'
                      tabIndex='0'
                      id='cancel-ref-action'
                      onClick={() => {
                        if(activityLeadId){
                          clearSelectedLead();
                          onClose();
                        } else onClose();
                      }}>
                      <span className='MuiButton-label'>
                        <span>{t(`${translationPath}cancel`)}</span>
                      </span>
                      <span className='MuiTouchRipple-root' />
                    </ButtonBase>
                  </div>
                  <div className='save-wrapper d-inline-flex-center'>
                    <ButtonBase
                      className='MuiButtonBase-root MuiButton-root MuiButton-text save-btn-wrapper btns theme-solid bg-primary w-100 mx-2 mb-2'
                      tabIndex='0'
                      onClick={qualifyLeadClickHandler}>
                      <span className='MuiButton-label'>
                        <span>{t(`${translationPath}${activityLeadId?'save':'qualify'}`)}</span>
                      </span>
                      <span className='MuiTouchRipple-root' />
                    </ButtonBase>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

QualifyLeadDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};
