import React from "react";

function HomeLine({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M11.48 1.344a2.388 2.388 0 0 0-.48.214c-.143.085-2.014 1.52-4.158 3.189-4.235 3.296-4.213 3.276-4.524 3.912-.319.651-.298.241-.298 5.781v4.94l.091.355c.114.449.268.77.539 1.123.388.505.944.861 1.615 1.031l.355.091h7.36c7.033 0 7.374-.003 7.68-.074.799-.186 1.381-.579 1.826-1.235.199-.293.321-.587.42-1.011.07-.303.074-.585.074-5.24 0-4.59-.005-4.937-.071-5.18a3.161 3.161 0 0 0-.94-1.511c-.497-.428-7.686-5.996-7.949-6.156a2.367 2.367 0 0 0-.518-.232 2.304 2.304 0 0 0-1.022.003m4.22 4.801a552.79 552.79 0 0 1 3.891 3.043c.117.099.252.255.3.346l.089.166v4.74c0 3.797-.01 4.775-.052 4.914-.065.213-.337.495-.552.571-.233.082-14.519.082-14.752 0-.215-.076-.487-.358-.552-.571-.042-.139-.052-1.117-.052-4.914V9.7l.089-.166a1.49 1.49 0 0 1 .3-.346c.274-.234 7.565-5.906 7.591-5.906.011 0 1.676 1.288 3.7 2.863m-8.005 9.912a1.04 1.04 0 0 0-.567.459c-.091.156-.108.23-.108.484 0 .256.016.328.111.489.125.213.318.375.539.454.222.079 8.438.079 8.66 0 .221-.079.414-.241.539-.454.095-.161.111-.233.111-.489 0-.254-.017-.328-.108-.484a1.02 1.02 0 0 0-.582-.461c-.261-.079-8.344-.076-8.595.002"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default HomeLine;
