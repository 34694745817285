
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; 
import { Inputs } from '../../../../../../../Components' ;  
import { getErrorByName } from '../../../../../../../Helper' ; 

export const UnitsModelsFieldsFirstSectionComponent = ({ setState , state , translationPath , parentTranslationPath , schema , isSubmitted , error
  , setError}) => {
    const { t } = useTranslation(parentTranslationPath);

  
  return (
    <>
      <div className='dialog-content-item'>
                <Inputs
                  idRef='nameRef'
                  labelValue='name'
                  labelClasses='Requierd-Color'
                  value={state.propertyUnitModelName}
                  onInputChanged={(event) => {
                    setState({ id: 'propertyUnitModelName', value: (event.target.value) || null });
                  }}
                  isSubmitted={isSubmitted}
                  helperText={
                    getErrorByName(schema, 'propertyUnitModelName').message
                  }
                  error={getErrorByName(schema, 'propertyUnitModelName').error}
                  isWithError
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath} 
                  
               />
         </div>
        <div className='dialog-content-item'>
                <Inputs
                  idRef='areaRef'
                  labelValue='Area'
                  labelClasses='Requierd-Color'
                  type='number'
                  value={state.area}
                  min={0}
                  onInputChanged={(event) => {
                    const { value }= event.target ; 
                    if(value < 0 )
                    return ;

                    setState({ id: 'area', value: +(value)});
                  }}
                  isSubmitted={isSubmitted}
                  helperText={
                    getErrorByName(schema, 'area').message
                  }
                  error={getErrorByName(schema, 'area').error}
                  isWithError
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath} 
               />
         </div>
         <div className='dialog-content-item'>
                <Inputs
                  idRef='bedroomNoRef'
                  labelValue='bedroomNo'
                  labelClasses='Requierd-Color'
                  type='number'
                  min={0}
                  max={100}
                  value={state.bedroomNo}
                  onInputChanged={(event) => {

                    const { value }= event.target ; 
                    if(value < 0  ||  value > 99)
                    return ;

                    const  numberRegex = /^\d+$/;
                    const bedroomNoError =  !(numberRegex.test(value)) ;
                     setError((item) => ({ ...item, bathroomsNo  : bedroomNoError})); 

                    setState({ id: 'bedroomNo', value:(value)});

                  }}
                  isSubmitted={isSubmitted}
                  error={error && error.bathroomsNo}
                  helperText={t(`${translationPath}Choose-correct-number`)}
                  isWithError
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath} 
               />
          </div>
          <div className='dialog-content-item'>
                <Inputs
                  idRef='bathroomNoRef'
                  labelValue='bathroomNo'
                  labelClasses='Requierd-Color'
                  type='number'
                  min={0}
                  max={100}
                  value={state.bathroomNo}
                  onInputChanged={(event) => {

                    const { value }= event.target ; 
                    if(value < 0 || value > 99  )
                    return ;

                    const  numberRegex = /^\d+$/;
                    const bathroomNoError =  !(numberRegex.test(value)) ;
                     setError((item) => ({ ...item, bedroomNo  : bathroomNoError})); 
                     
                    setState({ id: 'bathroomNo', value:(value) });
                  }}
                  error={error && error.bedroomNo}
                  helperText={t(`${translationPath}Choose-correct-number`)}
                  isSubmitted={isSubmitted}
                  isWithError
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath} 
               />
         </div>
         <div className='dialog-content-item'>
                <Inputs
                  idRef='rentPerYearFromRef'
                  labelValue='rentPerYearFrom'
                  type='number'
                  min={0}
                  value={state.rentPerYearFrom}
                  onInputChanged={(event) => {
                    const { value }= event.target ; 
                    if(value < 0 )
                    return ;

                    setState({ id: 'rentPerYearFrom', value: (value) });
                  }}
                  withNumberFormat
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath} 
               />
          </div>
          <div className='dialog-content-item'>
                <Inputs
                  idRef='rentPerYearToRef'
                  labelValue='rentPerYearTo'
                  value={state.rentPerYearTo}
                  type='number'
                  min={0}
                  withNumberFormat
                  onInputChanged={(event) => {
                    const { value }= event.target ; 
                    if(value < 0 )
                    return ;
                  
                    setState({ id: 'rentPerYearTo', value:  (value)  });
                  }}
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath} 
               />
          </div>
     </>
  );
};
