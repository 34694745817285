import React from 'react';

function ArrowLeftIcon({ fill, ...restProps }) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill={fill ?? ''}
      xmlns='http://www.w3.org/2000/svg'
      {...restProps}
    >
      <path
        d='M11.64 4.068c-.156.053-.662.542-3.793 3.667-2.076 2.071-3.658 3.682-3.72 3.785-.091.152-.107.226-.107.48s.016.328.107.48c.136.227 7.243 7.327 7.428 7.419.455.229 1.046.045 1.312-.407.097-.165.113-.234.112-.492 0-.212-.022-.341-.074-.44-.04-.077-1.291-1.359-2.779-2.85L7.421 13l5.879-.002c4.808-.001 5.907-.011 6.03-.055.221-.079.414-.241.539-.454.095-.161.111-.233.111-.489 0-.254-.017-.328-.108-.484a1.02 1.02 0 0 0-.582-.461c-.151-.046-1.191-.055-6.026-.055H7.421l2.705-2.71c1.488-1.49 2.739-2.773 2.779-2.85.052-.099.074-.228.074-.44.001-.255-.015-.328-.107-.484a1.016 1.016 0 0 0-1.232-.448'
        fill-rule='evenodd'
        fill={fill ?? ''}
      />
    </svg>
  );
}

export default ArrowLeftIcon;
