import React, {
    useEffect, useCallback, useState, useRef
} from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../Hooks';
import { LeadAuditorComponent } from './LeadAuditorComponent'
import { AgentsComponent } from './AgentsComponent'
import {
    Spinner,
    PaginationComponent,
} from '../../../Components';
import { GetAllLeadAuditors, GetAllLeadAuditorAgents, RemoveAgentsFromLeadAuditor, GetTeam, GetAllAgentsServices } from '../../../Services';
import { GetAllTeamLeadUsers } from '../../../Services/userServices';
import { bottomBoxComponentUpdate } from '../../../Helper';
import './AssignAgentComponent.scss'

export const AssignAgentComponent = () => {
    const parentTranslationPath = 'leadOwnerView';
    const translationPath = '';
    const { t } = useTranslation(parentTranslationPath);
    const searchTimer = useRef();
    useTitle(t(`${translationPath}AdminAssign`));
    const loginResponse = useSelector((state) => state.login.loginResponse);
    const [isLoading, setIsLoading] = useState({
        all: false,
        getAllLeadAuditor: false,
        getAllAgents: false,
        getAllTeamLeads: false,
        getAllTeam: false,
        getAllAgentResult: false
    });
    const [leadAuditorId, setLeadAuditorId] = useState(null);
    const [filterByAgent, setFilterByAgent] = useState(null);
    const [filterByTeams, setFilterByTeams] = useState(null)
    const [allTeams, setAllTeams] = useState(null)
    const [allTeamLeads, setAllTeamLeads] = useState(null)
    const [filterByTeamLead, setFilterByTeamLead] = useState(null);
    const [allAgents, setAllAgents] = useState(null);
    const [isAssignAgent, setIsAssignAgent] = useState(null);
    const [teamSearch, setTeamSearch] = useState('');
    const [teamLeadSearch, setTeamLeadSearch] = useState('');
    const [agentSearch, setAgentSearch] = useState('');
    const [filter, setFilter] = useState({
        pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
        pageIndex: 0,
    });
    const [userLeadAuditor, setUserLeadAuditor] = useState(null);
    const [allLeadAuditorAgents, setAllLeadAuditorAgents] = useState({
        result: [],
        totalCount: 0
    });

    const searchHandler = (event) => {
        const { value } = event.target;
        if (searchTimer.current) clearTimeout(searchTimer.current);

        if (event.target.id === 'TeamLeadFilterRef') searchTimer.current = setTimeout(() => {
            setTeamLeadSearch(value)
        }, 700);
        if (event.target.id === 'TeamFilterRef') searchTimer.current = setTimeout(() => {
            setTeamSearch(value)
        }, 700);
        if (event.target.id === 'agentFilterRef') searchTimer.current = setTimeout(() => {
            setAgentSearch(value)
        }, 700);
    };

    const onPageIndexChanged = (pageIndex) => {
        setFilter((item) => ({ ...item, pageIndex }));
    };
    const onPageSizeChanged = (pageSize) => {
        setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    };
    const getAllLeadAuditors = useCallback(async () => {
        setIsLoading(((loading) => ({ ...loading, all: true })));
        const body = {
            usersId: loginResponse.userId,
        }
        const res = await GetAllLeadAuditors(body, filter.pageIndex + 1, 25)
        if (!(res && res.status && res.status !== 200)) {
            setUserLeadAuditor(res.result[0]);
            setLeadAuditorId(res.result[0] && res.result[0].leadAuditorId)
        }
        else {
            setUserLeadAuditor(null);
            setLeadAuditorId(null);
        }
        setIsLoading(((loading) => ({ ...loading, all: false })));
    }, []);

    const getAllLeadAuditorAgents = useCallback(async () => {
        setIsLoading(((loading) => ({ ...loading, getAllAgentResult: true })));
        const body = {
            leadAuditorId: leadAuditorId,
            agentId: filterByAgent && filterByAgent.agentId,
            teamId: filterByTeams && filterByTeams.teamsId,
            teamLeadId: filterByTeamLead && filterByTeamLead.usersId,
            isAssignAgent: isAssignAgent
        }
        const res = await GetAllLeadAuditorAgents(body, filter.pageIndex + 1, filter.pageSize)
        if (!(res && res.status && res.status !== 200)) {
            setAllLeadAuditorAgents({
                result: res.result,
                totalCount: res.totalCount
            })
        }
        else {
            setAllLeadAuditorAgents({
                result: [],
                totalCount: 0
            })
        }
        setIsLoading(((loading) => ({ ...loading, getAllAgentResult: false })));
    }, [leadAuditorId, filter, filterByAgent, filterByTeams, filterByTeamLead, isAssignAgent]);

    const getTeam = useCallback(async () => {
        setIsLoading(((loading) => ({ ...loading, getAllTeam: true })));
        const res = await GetTeam(1, 25, teamSearch);
        if (!(res && res.status && res.status !== 200)) setAllTeams(res.result)
        else setAllTeams([])
        setIsLoading(((loading) => ({ ...loading, getAllTeam: false })));
    }, [teamSearch]);

    const getAllTeamLeadUsers = useCallback(async () => {
        setIsLoading(((loading) => ({ ...loading, getAllTeamLeads: true })));

        const res = await GetAllTeamLeadUsers({ pageIndex: 1, pageSize: 25, search: teamLeadSearch });
        if (!(res && res.status && res.status !== 200)) setAllTeamLeads(res.result)
        else setAllTeamLeads([])
        setIsLoading(((loading) => ({ ...loading, getAllTeamLeads: false })));
    }, [teamLeadSearch]);

    const getAllAgents = useCallback(async () => {
        setIsLoading(((loading) => ({ ...loading, getAllAgents: true })));
        const res = await GetAllAgentsServices({ pageIndex: 1, pageSize: 25, agentName: agentSearch });
        if (!(res && res.status && res.status !== 200)) setAllAgents(res.result)
        else setAllAgents([])
        setIsLoading(((loading) => ({ ...loading, getAllAgents: false })));
    }, [agentSearch]);


    useEffect(() => {
        bottomBoxComponentUpdate(
            <PaginationComponent
                pageIndex={filter.pageIndex}
                pageSize={filter.pageSize}
                totalCount={(allLeadAuditorAgents && allLeadAuditorAgents.totalCount) || 0}
                onPageIndexChanged={onPageIndexChanged}
                onPageSizeChanged={onPageSizeChanged}
            />
        );

    });

    useEffect(() => {
        getTeam();
    }, [getTeam])
    useEffect(() => {
        getAllTeamLeadUsers();
    }, [getAllTeamLeadUsers])
    useEffect(() => {
        if (leadAuditorId) getAllLeadAuditorAgents()
    }, [getAllLeadAuditorAgents]);
    useEffect(() => {
        getAllAgents()
    }, [getAllAgents]);
    useEffect(() => {
        getAllLeadAuditors()
    }, [getAllLeadAuditors]);


    return (

        <div className='view-wrapper'>
            <Spinner isActive={isLoading.all || isLoading.getAllAgentResult} />
            <div className='header-section'></div>

            <div className='assignment-crads-wrapper d-flex m-5'>
                <LeadAuditorComponent
                    userLeadAuditor={userLeadAuditor}
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                />
                <AgentsComponent
                    allLeadAuditorAgents={allLeadAuditorAgents}
                    filterByAgent={filterByAgent}
                    setFilterByAgent={setFilterByAgent}
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    filterByTeams={filterByTeams}
                    setFilterByTeams={setFilterByTeams}
                    searchHandler={searchHandler}
                    allTeams={allTeams}
                    filterByTeamLead={filterByTeamLead}
                    setFilterByTeamLead={setFilterByTeamLead}
                    allTeamLeads={allTeamLeads}
                    allAgents={allAgents}
                    isLoading={isLoading}
                />
            </div>
        </div>

    );
};
