import moment from "moment";
import { GlobalTranslate, NumberWithCommas } from "../../../../Helper";

export const InquiriesMapper = (item, inquiry) => {
  return {
    inquiryId: item.inquiryId,
    leadId: item.leadId,
    inquiryTypeId: item.inquiryTypeId,
    inquiryTypeName: item.inquiryTypeName,
    closeReasonId: item.closeReasonId,
    closeReason: item.closeReason,
    assignedToId: item.assignedToId,
    assignedTo: item.assignedTo,
    inquiryStatus: item.inquiryStatus,
    createdOn:
      (item.createdOn &&
        moment(item.createdOn).isValid() &&
        moment(item.createdOn).format("YYYY-MM-DD")) ||
      null,
    updateOn:
      (item.updateOn &&
        moment(item.updateOn).isValid() &&
        moment(item.updateOn).format("YYYY-MM-DD")) ||
      null,
    createdByName: item.createdByName,
    updatedByName: item.updatedByName,
    isDeleted: item.isDeleted,
    remarks: item.remarks,
    inquiryAgents : (item.inquiryAgents ) || [] , 
    ...inquiry,
  };
};
