import React, {
  useState, useRef, useCallback, useEffect
} from 'react';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LoadableImageEnum, DefaultImagesEnum } from '../../../Enums';
import {
  LoadableImageComponant, DialogComponent, Inputs, AutocompleteComponent
} from '../../../Components';
import { UpdateLeadAuditorCapacity } from '../../../Services';
import { getDownloadableLink, showError, showSuccess } from '../../../Helper';

export const LeadAuditorComponent = ({
  allLeadAuditor,
  setLeadAuditorId,
  leadAuditorId,
  setCheckedCards,
  setCheckedCardsIds,
  reloadPage,
  currPage,
  setCurrPage,
  parentTranslationPath,
  translationPath,
  setLastList,
  isLoading,
  isAssignAgent,
  setIsAssignAgent,
  getAllLeadAuditors,
  searchHandler,
  leadAuditorOptions
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const listInnerRef = useRef();
  const [isOpenEditLeadCap, setIsOpenEditLeadCap] = useState(false);
  const [isSaveIsDisabled, setIsSaveIsDisabled] = useState(true);
  const [searchValue, setSearchValue] = useState(null);
  const [filterByLeadAuditor, setFilterByLeadAuditor] = useState(null);
  const [selectedLeadAuditorId, setSelectedLeadAuditorId] = useState(null);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        allLeadAuditor.result.length < allLeadAuditor.totalCount &&
        scrollTop > lastScrollTop &&
        !isLoading.all
      ) {
        setCurrPage(currPage + 10);
        setLastList(false);
      } else setLastList(true);

      scrollTop <= 0 ? setLastScrollTop(0) : setLastScrollTop(listInnerRef.current.scrollTop);
    }
  };

  const updateLeadAuditorCapacity = useCallback(async (e) => {
    e.preventDefault();
    const body = {
      leadAuditorCapacity: searchValue,
      leadAuditorId: selectedLeadAuditorId || leadAuditorId
    };
    const res = await UpdateLeadAuditorCapacity(body);
    if (!(res && res.status && res.status !== 200)) showSuccess(t(`${translationPath}change-lead-cap-Successfully`));

    else showError(t(`${translationPath}faild-to-change-lead-cap`));

    reloadPage();
    setSearchValue('');
    setIsOpenEditLeadCap(false);
  }, [searchValue, selectedLeadAuditorId, leadAuditorId]);

  return (
    <div
      className='Lead-auditor-scroll-wrapper'
      onScroll={onScroll}
      ref={listInnerRef}
    >
      <div className='Lead-auditor-wrapper'>
        <div className='Lead-auditor-title'>
          <span>
            {t(`${translationPath}lead-owners`)}
            {' '}
          </span>
          <span>
            {allLeadAuditor && allLeadAuditor.totalCount || 0}
            {' '}
          </span>
        </div>
        <AutocompleteComponent
          idRef='LeadAuditorRef'
          selectedValues={filterByLeadAuditor || null}
          wrapperClasses='lead-auditor-search'
          multiple={false}
          data={leadAuditorOptions && leadAuditorOptions.result || []}
          displayLabel={(option) => (option && option.leadAuditorName) || ''}
          getOptionSelected={(option) => (option && option.leadAuditorName) || ''}
          withoutSearchButton
          inputPlaceholder={t(`${translationPath}filter-by-lead-owner`)}
          isWithError
          isLoading={isLoading.getAllLeadAuditor}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputKeyUp={searchHandler}
          onChange={(event, newValue) => {
            getAllLeadAuditors(newValue);
            setFilterByLeadAuditor(newValue);
          }}
        />
        {allLeadAuditor && allLeadAuditor.totalCount && allLeadAuditor && allLeadAuditor.result.map((item) => (
          <a>
            {' '}

            <div
              onClick={() => {
                setLeadAuditorId && setLeadAuditorId(item.leadAuditorId);
                if (leadAuditorId === item.leadAuditorId) setLeadAuditorId(null);
                setCheckedCardsIds([]);
                setCheckedCards([]);
                if (!isAssignAgent) setIsAssignAgent(null);
              }}
              className={`lead-auditor-cards-wrapper ${leadAuditorId === item.leadAuditorId ? 'selectedAudito' : null}`}
            >
              <div className='w-100 d-flex'>
                <LoadableImageComponant
                  type={LoadableImageEnum.image.key}
                  classes='lead-auditor-image'
                  alt='user-image'
                  src={item.profileImg && getDownloadableLink(item.profileImg) || DefaultImagesEnum.man.defaultImg}
                />
                <div className='w-100'>
                  <div className='title-wrapper'>
                    <span>{item.leadAuditorName || 'N/A'}</span>
                    {/* <span>{t(`${translationPath}supervisor`)}</span> */}
                  </div>
                </div>

              </div>
              <div className='leads-row'>
                <div className='leads-numbers'>
                  <span className='hot lead-number'>{item.numberHotLead && item.numberHotLead || '0'}</span>
                  <span className='warm lead-number'>{item.numberWarmLead && item.numberWarmLead || '0'}</span>
                  <span className='cold lead-number'>{item.numberColdLead && item.numberColdLead || '0'}</span>
                  <span className='hot lead-number'>{item.percentageOfHotLead && `${item.percentageOfHotLead}%` || '0'}</span>
                  <span className='warm lead-number'>{item.percentageOfWarmLead && `${item.percentageOfWarmLead}%` || '0'}</span>
                  <span className='cold lead-number'>{item.percentageOfColdLead && `${item.percentageOfColdLead}%` || '0'}</span>
                </div>
                <ButtonBase
                  className='edit-btn'
                  onClick={() => {
                    setSelectedLeadAuditorId(leadAuditorId);
                    setIsOpenEditLeadCap(true);
                  }}
                >
                  {t(`${translationPath}edit`)}
                  {' '}

                </ButtonBase>
              </div>
              <div className='agent-row d-flex'>
                <div>
                  <span>{t(`${translationPath}agent`)}</span>
                  <span>{item.numberOfAgentAssigned || '0'}</span>
                </div>
                <div>
                  <span>{t(`${translationPath}cap`)}</span>
                  <span className={`${item.leadAuditorCapacity < item.numberOfAgentAssigned ? 'red-lead-cap' : 'green-lead-cap'}`}>{item.leadAuditorCapacity || '0'}</span>
                </div>
                <div>
                  <span>{t(`${translationPath}active`)}</span>
                  <span>{item.numberOfOpenLead || '0'}</span>
                </div>
                <div>
                  <span>{t(`${translationPath}inactive`)}</span>
                  <span>{item.numberOfClosedLead || '0'}</span>
                </div>

              </div>
            </div>
          </a>
        )) || null}
        {isOpenEditLeadCap && (
          <DialogComponent
            isOpen={isOpenEditLeadCap}
            titleText='edit-lead-cap'
            maxWidth='sm'
            onSaveClicked={updateLeadAuditorCapacity}
            saveText='Confirm'
            onCancelClicked={() => { setIsOpenEditLeadCap(false); }}
            disableBackdropClick
            saveIsDisabled={isSaveIsDisabled}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            dialogContent={(
              <>
                <Inputs
                  idRef='LeadCapRef'
                  type='number'
                  inputPlaceholder='leadcap'
                  value={searchValue}
                  withSearchIcon
                  min={0}
                  // max={0}
                  wrapperClasses='lead-auditor-search'
                  onInputChanged={(e) => {
                    const { value } = e.target;
                    if (value) {
                      setIsSaveIsDisabled(false);
                      setSearchValue(parseInt(value));
                    } else

                      setSearchValue(parseInt(0));
                  }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
              </>
            )}
          />
        )}
      </div>
    </div>
  );
};
