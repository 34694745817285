import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  DialogComponent,
  PermissionsComponent,
  AutocompleteComponent,
  Spinner,
  ActionsButtonsComponent,
  TabsComponent,
} from '../../../Components';
import { useTitle } from '../../../Hooks';
import { GlobalOrderFilterActions } from '../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import { ActivityTypePermissions } from '../../../Permissions';
import { ActionsButtonsEnum } from '../../../Enums';
import { ActivitiesTypeTabelView } from './ActivitiesTypeTabelView/ActivitiesTypeTabel.View';
import { generateUniqueKey, GlobalHistory, returnPropsByPermissions } from '../../../Helper';
import {
  GetlookupTypeItems,
  GetAllActivityTypesNewAPI,
  UpdateActivityTypeArchived,
} from '../../../Services';
import { ActivityTypeManagementDialogV2 } from './ActivitiesTypeManagementView/DialogManagementViewComponent/ActivityTypeManagementDialogV2';
import { DateRangePickerComponent } from '../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import moment from 'moment';

const parentTranslationPath = 'ActivitiesType';
const translationPath = '';

export const ActivitiesTypeView = () => {
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t('Shared:Activity-type'));
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const dispatch = useDispatch();
  const [actionButtonsKey, setActionButtonsKey] = useState(generateUniqueKey());

  const [openDialog, setOpenDialog] = useState(false);
  const [openArchivedDialog, setOpenArchivedDialog] = useState(false);
  const searchTimer = useRef(null);
  const [search, setSearch] = useState('');
  const [reloading, setReloading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingActivityTypes, setIsLoadingActivityTypes] = useState(false);
  const [checkedActivitiesTypes, setCheckedActivitiesTypes] = useState([]);
  const [checkedActivitiesTypesIds, setCheckedActivitiesTypesIds] = useState(
    []
  );
  const [checkedDetailed, setCheckedDetailed] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [categoryId, setCategoryId] = useState(null);
  const [category, setCategory] = useState({
    result: [],
    totalCount: 0,
  });
  const [allActivityTypes, setAllActivityTypes] = useState({
    result: [],
    totalCount: 0,
  });
  const [activeItem, setActiveItem] = useState(null);
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
  };
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const dateFilterHandler = (selectedDate) => {
    
    setDateFilter((item) => ({
      ...item,
      startDate: selectedDate?.selection?.startDate,
      endDate: selectedDate?.selection?.endDate,
      key: 'selection',
    }));
    setFilter((prevFilter) => ({
      ...prevFilter,
      fromLastUsedDate: selectedDate?.selection?.startDate
        ? new Date(
            moment(selectedDate.selection.startDate).endOf('day')
          ).toISOString()
        : null,
      toLastUsedDate: selectedDate?.selection?.endDate
        ? new Date(
            moment(selectedDate.selection.endDate).endOf('day')
          ).toISOString()
        : null,
    }));
  };

  const [activeSelectedAction, setActiveSelectedAction] = useState('');
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize:
      (orderFilter &&
        orderFilter.activityType &&
        orderFilter.activityType.pageSize) ||
      25,
    search:
      (orderFilter &&
        orderFilter.activityType &&
        orderFilter.activityType.search) ||
      '',
    categoryId:
      (orderFilter &&
        orderFilter.activityType &&
        orderFilter.activityType.categoryId) ||
      null,
    category:
      (orderFilter &&
        orderFilter.activityType &&
        orderFilter.activityType.category) ||
      null,
    expiredPeriod: null,
    isForMobile:
      (orderFilter &&
        orderFilter.activityType &&
        orderFilter.activityType.isForMobile) ||
      null,
    activityTypeName: null,
    organizationId: null,
    categoryName: null,
    isRelatedToUnit: null,
    isRelatedToLead: null,
    isRelatedToContact: null,
    isRelatedToWorkOrder: null,
    isRelatedToPortfolio: null,
    isRelatedToMaintinanceContract: null,
    slaExpirationPeriodFreqency: null,
    slaExpirationPeriodComparisonDate: null,
    slaAppliedFor: null,
    leadQualificationName: null,
    isArchived: false,
    toLastUsedDate:orderFilter?.activityType?.toLastUsedDate ?? null,
    fromLastUsedDate:   orderFilter?.activityType?.fromLastUsedDate ?? null,
    LastUsedDate : orderFilter?.activityType?.LastUsedDate ?? null
  });

  const [searchedItem, setSearchedItem] = useState(
    (orderFilter &&
      orderFilter.activityType &&
      orderFilter.activityType.search) ||
      ''
  );

  const searchHandler = (event) => {
    const { value } = event.target;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setSearch(value);
      setSearchedItem(value);
      dispatch(
        GlobalOrderFilterActions.globalOrderFilterRequest({
          ...orderFilter,
          activityType: {
            ...orderFilter.activityType,
            search: value,
          },
        })
      );
      setFilter((item) => ({ ...item, search: value || '' }));
    }, 700);
  };

  const onActionsButtonClicked = useCallback((activeAction) => {
    if (activeAction === ActionsButtonsEnum[1].id) {
      setOpenDialog(true);
      setReloading(true);
    }
    if (activeAction === ActionsButtonsEnum[4].id)
      GlobalHistory.push('/home/activity-type-bulk-update');
    if (
      activeAction === ActionsButtonsEnum[27].id ||
      activeAction === ActionsButtonsEnum[28].id
    ) {
      setOpenArchivedDialog(true);
      setReloading(true);
    }
  }, []);

  const onActionButtonChanged = (activeAction) => {
    setActiveSelectedAction(activeAction);
    setCheckedActivitiesTypes([]);
    setCheckedActivitiesTypesIds([]);
  };

  const resetActivityEngineDateFilter = () => {
    setDateFilter(dateRangeDefault);
  };

  const checkDisable = (enums) => {
    if (
      enums === ActionsButtonsEnum[4].id ||
      enums === ActionsButtonsEnum[27].id ||
      enums === ActionsButtonsEnum[28].id
    )
      return !(checkedActivitiesTypes && checkedActivitiesTypes.length >= 1);

    return false;
  };

  const onSelectClicked = useCallback(
    (row, rowIndex) => {
      setCheckedDetailed((items) => {
        const index = items.findIndex((item) => item === rowIndex);
        if (index !== -1) items.splice(index, 1);
        else items.push(rowIndex);
        return [...items];
      });

      setCheckedActivitiesTypes((items) => {
        const index = items.findIndex(
          (item) => item.activityTypeId === row.activityTypeId
        );
        if (index !== -1) items.splice(index, 1);
        else items.push(row);
        return [...items];
      });
      setCheckedActivitiesTypesIds((items) => {
        const index = items.findIndex(
          (item) => item.activityTypeId === row.activityTypeId
        );
        if (index !== -1) items.splice(index, 1);
        else items.push(row.activityTypeId);
        return [...items];
      });
    },
    [checkedActivitiesTypes]
  );

  const getIsSelected = useCallback(
    (row) =>
      checkedActivitiesTypes &&
      checkedActivitiesTypes.findIndex(
        (item) => (item && item.activityTypeId) === (row && row.activityTypeId)
      ) !== -1,
    [checkedActivitiesTypes]
  );

  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
    },
    []
  );
  const getAllCategories = useCallback(async (searchByName) => {
    setIsLoading(true);
    const res = await GetlookupTypeItems({
      pageIndex: 0,
      pageSize: 99999,
      lookupTypeId: 1201,
      search: searchByName || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      setCategory({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setCategory({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllActivityTypes = useCallback(async () => {
    setIsLoadingActivityTypes(true);
    const requestJson = {
      ...filter,
      pageIndex: filter.pageIndex + 1,
      pageSize: filter.pageSize,
    };
    const result = await GetAllActivityTypesNewAPI({ ...requestJson });
    if (!(result && result.status && result.status !== 200)) {
      setAllActivityTypes({
        result: (result && result.result) || [],
        totalCount: (result && result.totalCount) || 0,
      });
    } else {
      setAllActivityTypes({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoadingActivityTypes(false);
  });

  const getIsSelectedAll = useCallback(() => {
    const returnSelect =
      (checkedActivitiesTypes &&
        allActivityTypes.result.findIndex(
          (item) => !checkedActivitiesTypes.includes(item.activityTypeId)
        ) === -1) ||
      false;
    return returnSelect;
  });

  const onSelectAllClicked = () => {
    const cardItems = [];
    const selectedActivityTypeIds = [];
  
    if (!getIsSelectedAll()) {
      allActivityTypes.result.forEach((item) => {
        if (!getIsSelected(item)) {
          checkedActivitiesTypes.push(item.activityTypeId);
          cardItems.push({ ...item });
          selectedActivityTypeIds.push(item.activityTypeId);
        }
      });
    } else {
      allActivityTypes.result.forEach((item) => {
        if (getIsSelected(item)) {
          const isSelectedIndex = checkedActivitiesTypes.findIndex(
            (element) => element === item.activityTypeId
          );
          if (isSelectedIndex !== -1) {
            checkedActivitiesTypes.splice(isSelectedIndex, 1);
          }
        }
      });
    }
  
    setCheckedActivitiesTypes(cardItems);
    setCheckedActivitiesTypesIds(selectedActivityTypeIds);
  };
  

  useEffect(() => {
    getAllActivityTypes();
  }, [filter]);

  useEffect(
    () => {
      getAllCategories();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    localStorage.setItem(
      'bulk-assign-ids',
      JSON.stringify(checkedActivitiesTypes)
    );
  }, [checkedActivitiesTypes]);

  const handleToArchived = async () => {
    setIsLoading(true);

    const body = {
      isArchived: activeTab === 0,
      activityTypeIds: checkedActivitiesTypesIds,
    };
    const result = await UpdateActivityTypeArchived(body);
    if (!(result && result.status && result.status !== 200)) {
      setOpenArchivedDialog(false);
      setIsLoading(false);
      getAllActivityTypes();
      setCheckedActivitiesTypesIds([]);
      setCheckedActivitiesTypes([]);
    }
    setIsLoading(false);
  };

  const onTabChanged = (e, newTap) => {
    onActionButtonChanged(ActionsButtonsEnum[1].id);
    setActionButtonsKey(generateUniqueKey());
    setActiveSelectedAction(null);
    setActiveTab(newTap);
  };

  useEffect(() => {
    setFilter((prevState) => ({
      ...prevState,
      isArchived: activeTab !== 0,
    }));
  }, [activeTab]);

  return (
    <div className='ActivitiesType-View view-wrapper'>
      <div className='w-100 px-2'>
        <TabsComponent
          data={[
            { label: 'Primary Activities' },
            {
              label: 'Archived',
              permissionsList: [...Object.values(ActivityTypePermissions)],
              permissionsId:
                ActivityTypePermissions.ViewArchivedActivities.permissionsId,
            },
          ]}
          labelInput='label'
          wrapperClasses='is-with-line tab-flex-start'
          themeClasses='theme-solid'
          currentTab={activeTab}
          onTabChanged={onTabChanged}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />

        <Spinner isActive={isLoadingActivityTypes || isLoading} isAbsolute />
        <div className='header-section'>
          <div className='filter-section'>
            <div className='section'>
              <ActionsButtonsComponent
                key={actionButtonsKey}
                permissionsList={Object.values(ActivityTypePermissions)}
                addPermissionsId={
                  ActivityTypePermissions.AddNewActivityType.permissionsId
                }
                selectPermissionsId={[
                  ActivityTypePermissions.AddNewActivityType.permissionsId,
                ]}
                checkDisable={checkDisable}
                onActionsButtonClicked={onActionsButtonClicked}
                onActionButtonChanged={onActionButtonChanged}
                enableBulk
                enableArchived={
                  activeTab === 0 &&
                  returnPropsByPermissions(
                    ActivityTypePermissions.ArchivedActivities.permissionsId
                  )
                }
                enableUnArchived={
                  activeTab === 1 &&
                  returnPropsByPermissions(
                    ActivityTypePermissions.UnarchivedActivities.permissionsId
                  )
                }
              />
            </div>
            <div className='section autocomplete-section'>
              <div className='d-flex-column px-2 w-100 p-relative'>
                <div className='d-flex gap-1 pl-4-reversed'>
                  <AutocompleteComponent
                    idRef='categoryRef'
                    multiple={false}
                    data={category.result || []}
                    value={filter && filter.category}
                    selectedValues={filter && filter.category}
                    chipsLabel={(option) => option.lookupItemName || ''}
                    displayLabel={(option) => option.lookupItemName || ''}
                    withoutSearchButton
                    isLoading={isLoading}
                    inputPlaceholder={t(`${translationPath}category`)}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                      setCategoryId(
                        (newValue && newValue.lookupItemId) || null
                      );
                      setFilter((item) => ({
                        ...item,
                        category: newValue,
                        categoryId: (newValue && newValue.lookupItemId) || null,
                      }));
                      dispatch(
                        GlobalOrderFilterActions.globalOrderFilterRequest({
                          ...orderFilter,
                          activityType: {
                            ...orderFilter.activityType,
                            categoryId: newValue && newValue.lookupItemId,
                            category: newValue,
                          },
                        })
                      );
                    }}
                    onInputKeyUp={(e) => {
                      const { value } = e.target;
                      if (searchTimer.current)
                        clearTimeout(searchTimer.current);
                      searchTimer.current = setTimeout(() => {
                        getAllCategories(value);
                      }, 700);
                    }}
                  />
                  <DateRangePickerComponent
                    idRef='dateRangeRef'
                    ranges={[dateFilter]}
                    onClearClicked={() => {
                      setDateFilter(dateRangeDefault);
                      dateFilterHandler(dateRangeDefault);
                    }}
                    onDateChanged={(selectedDate) => {
                      dateFilterHandler(selectedDate);
                    }}
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PermissionsComponent
          permissionsList={Object.values(ActivityTypePermissions)}
          permissionsId={
            ActivityTypePermissions.ViewActivityTypes.permissionsId
          }
        >
          <ActivitiesTypeTabelView
            filter={filter}
            setFilter={setFilter}
            reloading={reloading}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            setSearchedItem={setSearchedItem}
            allActivityTypes={allActivityTypes}
            activeSelectedAction={activeSelectedAction}
            checkedCardsIds={checkedActivitiesTypes}
            setCheckedCards={setCheckedActivitiesTypes}
            onSelectClicked={onSelectClicked}
            getIsSelected={getIsSelected}
            onSelectAllClicked={onSelectAllClicked}
            getIsSelectedAll={getIsSelectedAll}
            detailsActivityTypesList={
              allActivityTypes && allActivityTypes.result
            }
            setActiveItem={setActiveItem}
            setOpenDialog={setOpenDialog}
            activeItem={activeItem}
            resetActivityEngineDateFilter={resetActivityEngineDateFilter}
          />
        </PermissionsComponent>
      </div>

      <DialogComponent
        isOpen={openDialog}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        titleText='Add-New-Activity'
        onCloseClicked={() => {
          setReloading(false);
          setOpenDialog(false);
          setActiveItem(null);
        }}
        titleClasses='DialogComponent-ActivitiesType'
        wrapperClasses='wrapperClasses-ActivitiesType'
        maxWidth='sm'
        dialogContent={
          <>
            <ActivityTypeManagementDialogV2
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onCancelClicked={() => {
                setReloading(false);
                setOpenDialog(false);
                setFilter((item) => ({ ...item, pageIndex: 0 }));
                setActiveItem(null);
              }}
              setReloading={setReloading}
              activeItem={activeItem}
            />
          </>
        }
      />

      <DialogComponent
        isOpen={openArchivedDialog}
        titleText={!activeTab ? 'add-to-archived' : 'add-to-un-archived'}
        maxWidth='sm'
        onCloseClicked={() => {
          setOpenArchivedDialog(false);
        }}
        saveType='button'
        saveClasses='btns theme-solid w-100 mx-2 mb-2'
        onSaveClicked={handleToArchived}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        dialogContent={
          <>
            {!activeTab ? (
              <span>{t('archived-confirmation')}</span>
            ) : (
              <span>{t('un-archived-confirmation')}</span>
            )}
          </>
        }
      />
    </div>
  );
};
