export const CampaignRequestsPermissions = {

  ViewCampaignRequests: {
    permissionsId: '737c04ee-3373-4e55-db08-08dbf16cf01d',
    permissionsName: 'View Campaign Requests',
    componentsId: null,
    components: null
  },
  CampaignRequestDashboard: {
    permissionsId: '75fea4d6-0baa-4f69-c4cf-08dc683b0c43',
    permissionsName: 'Campaign Request Dashboard',
    componentsId: null,
    components: null
  },
  
};
