import React from 'react';
import PropTypes from 'prop-types';
import MUICard from '@material-ui/core/Card';
import clsx from 'clsx';

// styles
import useStyles from './styles';

function CustomCard({ children, classes, boxShadow, borderRadius, borderColor, ...restProps }) {

  let elevation = 0;
  switch (boxShadow) {
    case 'xs': elevation = 1; break;
    case 'sm': elevation = 2; break;
    case 'md': elevation = 3; break;
    case 'lg': elevation = 4; break;
    case 'xl': elevation = 5; break;
    case '2xl': elevation = 6; break;
    case '3xl': elevation = 7; break;
    default: elevation = 0; break;
  }

  let br = 0;
  switch (borderRadius) {
    case 'none': br = 0; break;
    case 'xxs': br = 1; break;
    case 'xs': br = 2; break;
    case 'sm': br = 3; break;
    case 'md': br = 4; break;
    case 'lg': br = 5; break;
    case 'xl': br = 6; break;
    case '2xl': br = 7; break;
    case '3xl': br = 8; break;
    case '4xl': br = 9; break;
    case 'full': br = 10; break;
    default: br = 0; break;
  }

  const styles = useStyles({ borderRadius: br, borderColor });

  return (
    <MUICard 
      className={clsx(styles.root, classes)} 
      elevation={elevation}
      {...restProps}
    >
      {children}
    </MUICard>
  );
}

CustomCard.propTypes = {
  children: PropTypes.node,
};

export default CustomCard;
