import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  TabsComponent,
  RadiosGroupComponent,
  CompletedDataComponent,
} from '../../../../Components';

import { GetParams } from '../../../../Helper';
import { LeadTypeIdEnum } from '../../../../Enums';
import { LeadsVerticalTabsData } from '../../Common/OpenFileView/OpenFileUtilities/OpenFileData';
const parentTranslationPath = 'LeadsProfileManagementView';
const translationPath = '';

export const LeadsProfileManagementView = () => {
  const [pathName, setPathName] = useState('');
  const [list, setList] = useState([]);
  const activeItem = useSelector((state) => state.ActiveItemReducer);
  const [activeTab, setActiveTab] = useState(0);
  const localActiveItem = localStorage.getItem('activeItem');
  const [contactLead, setContactLead] = useState(false);
  const [viewType, setViewType] = useState(1);
  const [filter, setFilterBy] = useState({
    id: null,
    formType: null,
  });

  const [leadEmptyFields , setLeadEmptyFields] = useState(null) ; 

  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };
  const onViewTypeChangedHandler = (event, newValue) => {
    setViewType(+newValue);
  };




  useEffect(() => {
  
     if (pathName === 'lead-owner-lead/lead-profile-edit') 
     {
      if (GetParams('formType') == LeadTypeIdEnum.Owner.leadTypeId)
         setList([...LeadsVerticalTabsData.leadOwnerLeads && LeadsVerticalTabsData.leadOwnerLeads.filter((tab) => tab.label !== 'matching' && tab.label !== 'assign-agents-tracking')]);
      else 
         setList([...LeadsVerticalTabsData.leadOwnerLeads]);
     }
      
  }, [pathName]);


  useEffect(() => {

    setFilterBy({
      formType: GetParams('formType'),
      id: GetParams('id'),
    });

    setPathName(window.location.pathname.split('/home/')[1].split('/view')[0]);
  }, []);



  return (
    <div className='leads-profile-wrapper view-wrapper'>
    
          <div className='d-flex'>

            <RadiosGroupComponent
              idRef='viewDataRef'
              data={[
                {
                  key: 1,
                  value: 'all-data',
                },
                {
                  key: 2,
                  value: 'missing-data',
                },
              ]}
              value={viewType}
              labelValue='view'
              labelInput='value'
              valueInput='key'
              themeClass='theme-line'
              parentTranslationPath={parentTranslationPath}
              translationPathForData={translationPath}
              translationPath={translationPath}
              onSelectedRadioChanged={onViewTypeChangedHandler}
            />
            <CompletedDataComponent
              completedData={
                activeItem && activeItem.progress ?
                  activeItem.progress :
                  JSON.parse(localActiveItem) && JSON.parse(localActiveItem).progress
              }
              typeId='leadOwnerLeads'
              formType={filter.formType}
              activeItem={activeItem}
            />
          </div>

            <div className='m3'>
             <TabsComponent
               data={list}
               labelInput='label'
               parentTranslationPath={parentTranslationPath}
               translationPath={translationPath}
               themeClasses='theme-solid'
               currentTab={activeTab}
               onTabChanged={onTabChanged}
               dynamicComponentProps={{
               viewType: (viewType || 1),
               parentTranslationPath,
               translationPath,
               leadEmptyFields , 
               setLeadEmptyFields , 
               taskRelatedTo: 'Lead',

               }}
            />
           </div>
         
            <div className='tabs-content-wrapper' />
        
    </div>
  );
};
