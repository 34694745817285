import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogComponent } from '../../../Components';
import { showError, showSuccess } from '../../../Helper';
import { DeletePropertyCampaign } from '../../../Services';

export const DeleteDialog = ({
  onCancelClicked,
  onCloseClicked,
  reloadData,
  isDeleteDialogOpen,
  setIsDeleteDialogOpen,
  translationPath,
  parentTranslationPath,
  activeItem,
  setActiveItem,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const deleteHandler = async () => {
    const campaignId = activeItem.propertyCampaignId;
    const res = await DeletePropertyCampaign({ campaignId });
    if (!(res && res.status && res.status !== 200)) {
      setActiveItem(null);
      reloadData();
      showSuccess(t(`${translationPath}campaign-deleted-successfully`));
    } else showError(t(`${translationPath}campaign-delete-failed`));
    setIsDeleteDialogOpen(false);
  };

  return (
    <DialogComponent
      titleText='confirm-message'
      saveText='confirm'
      saveType='button'
      maxWidth='sm'
      dialogContent={(
        <div className='d-flex-column-center'>
          <span className='mdi mdi-close-octagon c-danger mdi-48px' />
          <span>{`${t(`${translationPath}campaign-delete-description`)}`}</span>
        </div>
      )}
      saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
      isOpen={isDeleteDialogOpen}
      onCancelClicked={onCancelClicked}
      onCloseClicked={onCloseClicked}
      onSaveClicked={deleteHandler}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};
