export function CompletionDateRule(id, itemList, value, setData, formType) {
  if (id === "completion_date") {
    const propertyAgeIndex = itemList.indexOf(
      itemList.find((f) => f.field.id.toLowerCase() === "property_age")
    );
    let selectedItem = null;
    if (propertyAgeIndex !== -1) {
      if (value) {
        const currentTime = new Date().getTime();
        const completionDateTime = new Date(value).getTime();
        const difference = currentTime - completionDateTime;
        const ageInYears = parseFloat(difference / (1000 * 60 * 60 * 24 * 365));
        itemList[propertyAgeIndex].data.enum &&
          itemList[propertyAgeIndex].data.enum.map((element, key) => {
            if (element.lookupItemName === "and above") {
            } else {
              const fromYear = parseInt(
                element.lookupItemName.split("To")[0].trim()
              );

              const toYear = parseInt(
                element.lookupItemName
                  .split("To")[1]
                  .replace(/[^0-9]/g, "")
                  .trim()
              );

              if (ageInYears < 0) selectedItem = null;
              else if (ageInYears > 0 && ageInYears < 1) {
                selectedItem =
                  itemList[propertyAgeIndex].data.enum &&
                  itemList[propertyAgeIndex].data.enum[0];
              } else if (ageInYears > 60) {
                selectedItem = itemList[propertyAgeIndex].data.enum.find(
                  (x) => x.lookupItemName.toLowerCase() === "and above"
                );
              } else if (
                ageInYears > 0 &&
                ageInYears >= fromYear &&
                ageInYears < toYear
              ) {
                selectedItem = { ...element };
              }
            }
          });
      }

      itemList[propertyAgeIndex].data.isReadonly = value ? true : false;
      if (formType === 2) setData(selectedItem, 0, "property_age");
      else if (formType === 1) setData("property_age", selectedItem || {});
    }
  }
}
