import React from "react";

function ShareIcon({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M11.098 2.635a1.72 1.72 0 0 0-.685.418 1.74 1.74 0 0 0-.373.785c-.024.126-.04 1.065-.04 2.299v2.085l-.49.152a10.7 10.7 0 0 0-6.312 5.282A10.817 10.817 0 0 0 2.043 17.6c-.061.653-.053 1.703.014 1.89a1 1 0 0 0 1.268.609c.177-.058.264-.124.495-.375 1.228-1.334 2.314-2.143 3.733-2.781.657-.295 1.645-.605 2.297-.72l.15-.027v1.877c0 1.093.017 1.965.04 2.089.094.493.354.858.769 1.08.215.115.299.136.591.15.412.019.672-.064 1.038-.333.317-.234 9.137-7.795 9.358-8.022.343-.355.5-.846.409-1.284a1.557 1.557 0 0 0-.373-.754c-.132-.141-8.905-7.674-9.302-7.987-.356-.282-.614-.385-.986-.398a2.282 2.282 0 0 0-.446.021m4.953 6.017 3.912 3.351-3.972 3.404-3.971 3.405-.02-2.016c-.02-1.983-.021-2.019-.108-2.181a1.038 1.038 0 0 0-.872-.534c-.836-.004-2.47.335-3.567.741-.873.322-2.207 1.033-2.905 1.548-.149.11-.28.192-.29.181-.011-.01.027-.177.083-.37a8.55 8.55 0 0 1 2.362-3.897A8.051 8.051 0 0 1 8.62 10.91c.743-.398 1.335-.613 2.298-.835.524-.121.713-.216.877-.437.202-.273.205-.307.205-2.448 0-1.89.004-1.997.069-1.945.038.03 1.83 1.563 3.982 3.407"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default ShareIcon;
