import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    transactionWrapper: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      gap: "12px",
    },
    contactOverViewCard: {
      padding: "24px",
    },
    activityCardTitleWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.border.secondary}`,
      paddingBottom: "20px",
    },
    titleContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "8px ",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        minWidth: "60px",
      },
    },
    transactionContainer: {
      marginTop: "20px",
      display: "flex",
      flexDirection: "column",
      gap: "24px",
    },
    infoSection: {
      display: "flex",
      flexDirection: "column",
      gap: "2px",
    },
    dateSection: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      [theme.breakpoints.down("xs")]: {
        alignItems: "flex-start",
      },
    },
    infoItem: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    propertyDetailsContainer: {
      display: "flex",
      gap: "20px",
      flexWrap: "wrap",
      [theme.breakpoints.down('xs')]: {
        gap: "12px",
      }
    },
    propertyInfoWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "2px",
    },
    propertyItemWrapper: {
      display: "flex",
      gap: "10px",
    },
  };
});
