import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import './RiskRatingConfigurationView.scss';
import { PageHeading } from "../../../SharedComponents";
import Button from "@material-ui/core/Button";
import { GlobalHistory } from "../../../Helper";
import { Tables } from "../../../Components";
import { GetAllKycConfigration } from "../../../Services";
import { RiskRatingConfigEnum } from "../../../Enums";
import { useTitle } from "../../../Hooks";

export const RiskRatingConfigurationView = () => {
  const parentTranslationPath = "RiskRating";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);

  useTitle(t(`${translationPath}Risk-rating-config`));

  const getRatingStyleClass = (ratingName) =>
    Object.values(RiskRatingConfigEnum).find(
      (item) => item.name === ratingName
    )?.class || "";

  const [KYCConfigration, setKYCConfigration] = useState([]);

  const getAllKYCConfigration = async () => {
    const res = await GetAllKycConfigration();
    if (!(res && res.status && res.status !== 200))
      setKYCConfigration(res || []);
    else setKYCConfigration([]);
  };

  useEffect(() => {
    getAllKYCConfigration();
  }, []);

  return (
    <div className="propx-view propx-bg RiskRatingView">
      <div className="d-flex-v-center-h-between">
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          headerTitle={"Risk-rating"}
          subTitle={"Risk-rating-desc"}
          wrapperClasses={"px-4 pt-4 w-50"}
          headerTitleClasses={"fz-30  mb-3"}
          subTitleClasses={"fz-12 m-0"}
        />
        <div className="w-25 px-4 d-flex-v-center-h-between">
          <div className="w-100 d-flex-column mr-3"></div>
          <Button
            className="btns theme-propx solid px-4"
            id="btn-configure"
            onClick={() => GlobalHistory.push('/home/risk-rating-configuration/configuration-form')}
          >
            <span className="mdi mdi-pencil pr-1" />
            <span>{t(`${translationPath}configure`)}</span>
          </Button>
        </div>
      </div>
      <div className="propx-view-container sm m-3">
        <Tables
          data={KYCConfigration}
          headerData={[
            {
              id: 1,
              label: "customerType",
              input: "customerType",
              component: (item) => <div className="d-flex fa-center gap-12">
                <span className={`RateDot ${getRatingStyleClass(item.customerTypeRating)}`}></span>
                <span className="fw-simi-bold">{item.customerType || "-"}</span></div>,
            },
            {
              id: 2,
              label: "residenceStatus",
              input: "residenceStatus",
              component: (item) =>  <div className="d-flex fa-center gap-12">
                <span className={`RateDot ${getRatingStyleClass(item.residenceStatusRating)}`}></span>
                <span  className="fw-simi-bold">{item.residenceStatus || "-"}</span></div>,
            },
            {
              id: 3,
              label: "customerSourceOfFunds",
              input: "customerSourceOfFunds",
              component: (item) =>  <div className="d-flex fa-center gap-12">
                <span className={`RateDot ${getRatingStyleClass(item.customerSourceOfFundsRating)}`}></span>
                <span  className="fw-simi-bold">{item.customerSourceOfFunds || "-"}</span></div>,
            },
            {
              id: 4,
              label: "natureOfTransactions",
              input: "natureOfTransactions",
              component: (item) =>  <div className="d-flex fa-center gap-12">
                <span className={`RateDot ${getRatingStyleClass(item.natureOfTransactionsRating)}`}></span>
                <span  className="fw-simi-bold">{item.natureOfTransactions || "-"}</span></div>,
            },
            {
              id: 5,
              label: "jurisdictionRisk",
              input: "jurisdictionRisk",
              component: (item) =>  <div className="d-flex fa-center gap-12">
                <span className={`RateDot ${getRatingStyleClass(item.jurisdictionRiskRating)}`}></span>
                <span  className="fw-simi-bold">{item.jurisdictionRisk || "-"}</span></div>,
            },
          ]}
          defaultActions={[]}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
    </div>
  );
};
