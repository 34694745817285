import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { GetAllSystemTemplates } from "../../../Services";
import { showError, showSuccess } from "../../../Helper";
import {
  AutocompleteComponent,
  Inputs,
  CheckboxesComponent,
  Spinner,
} from "../../../Components";
import { PageHeading } from "../../../SharedComponents";
import {
  GetAutoCorrespondenceConfig,
  SaveAutoCorrespondenceConfig,
} from "../../../Services/AutoCorrespondenceServices";
import { AutoCorrespondenceTypesEnum } from "../../../Enums";
import { StaticLookupsIds } from "../../../assets/json/StaticLookupsIds";
const parentTranslationPath = "AutoCorrespondence";
const translationPath = "";

export const AutoCorrespondenceView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const [AutoCorrespondenceConfigData, setAutoCorrespondenceConfigDatat] =
    useState([]);
  const [CheckboxesStates, setCheckboxesStates] = useState({
    Contact: false,
    WlcBackClosedWon: false,
    WlcBackClosedLost: false,
    SellerLeadAssigned: false,
    BuyerLeadAssigned: false,
    LandlordLeadAssigned: false,
    TenantLeadAssigned: false,
    ClosedWon: false,
  });
  const [DaysStates, setDaysStates] = useState({
    ContactDays: null,
    WelcomebackDays: null,
  });

  const [SelectedTemplte, setSelectedTemplte] = useState({
    Contact: [],
    WlcBackClosedWon: [],
    WlcBackClosedLost: [],
    SellerLeadAssigned: [],
    BuyerLeadAssigned: [],
    LandlordLeadAssigned: [],
    TenantLeadAssigned: [],
    ClosedWon: [],
  });

  const [Datatemplte, setDatatemplte] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const typingTimer = useRef(null);
  const [state, setState] = useState([]);
  const getAllTemplates = useCallback(async (searchValue) => {
    const res = await GetAllSystemTemplates({
      search: searchValue,
      pageIndex: 1,
      pageSize: 25,
      filterBy: "CreatedOn",
      orderBy: 2,
      SystemTemplateCategoryId: StaticLookupsIds.AutoCorrespondence,
    });
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      setDatatemplte(res.result || []);
    } else {
      setDatatemplte([]);
    }
  }, []);

  const getSearchCriteria = useCallback(async () => {
    setisLoading(true);
    const res = await GetAutoCorrespondenceConfig();
    if (!(res && res.status && res.status !== 200)) {
      setAutoCorrespondenceConfigDatat(res);
    } else {
      setAutoCorrespondenceConfigDatat([]);
    }
    setisLoading(false);
  }, []);

  useEffect(() => {
    getSearchCriteria();
  }, [getSearchCriteria]);

  const saveHandler = useCallback(async () => {
    setisLoading(true);
    try {
      const stateWithIds = Object.entries(AutoCorrespondenceTypesEnum).map(
        ([key, value]) => {
          // Find the corresponding object in the state with matching typeId
          const correspondingConfig = state.find(
            (config) => config.typeId === value
          );
          // Retrieve autoCorrespondenceConfigId from the found object or default to 0 if not found
          const autoCorrespondenceConfigId = correspondingConfig
            ? correspondingConfig.autoCorrespondenceConfigId
            : 0;

          // Retrieve templateId and templateName from SelectedTemplte object based on typeId
          const templateInfo = SelectedTemplte[key] || {};
          const templateId = templateInfo.systemTemplateId || null;
          const templateName = templateInfo.templateName || null;

          // Determine days based on typeId
          let days = 0;
          if (value === AutoCorrespondenceTypesEnum.Contact) {
            days = DaysStates.ContactDays || 0;
          } else if (
            value === AutoCorrespondenceTypesEnum.WlcBackClosedWon ||
            value === AutoCorrespondenceTypesEnum.WlcBackClosedLost
          ) {
            days = DaysStates.WelcomebackDays || 0;
          }
          return {
            autoCorrespondenceConfigId: autoCorrespondenceConfigId,
            typeId: value,
            templateId: templateId,
            templateName: templateName,
            isActivated: CheckboxesStates[key],
            days: days,
          };
        }
      );
      const result = await SaveAutoCorrespondenceConfig(stateWithIds);
      if (!(result && result.status && result.status !== 200)) {
        showSuccess(t(`${translationPath}Update-successfully`));
      } else {
        showError(t(`${translationPath}Update-failed`));
      }
    } catch (error) {
      console.error("Error saving auto correspondence configuration:", error);
      showError(`${translationPath}send-failed`);
    } finally {
      setisLoading(false);
    }
  }, [
    state,
    SelectedTemplte,
    CheckboxesStates,
    DaysStates,
    AutoCorrespondenceTypesEnum,
  ]);

  useEffect(() => {
    if (
      AutoCorrespondenceConfigData &&
      AutoCorrespondenceConfigData.length > 1 &&
      AutoCorrespondenceConfigData.length < 8
    ) {
      setState(
        Object.entries(AutoCorrespondenceTypesEnum).map(([key, value]) => ({
          autoCorrespondenceConfigId: 0,
          typeId: value || 0,
          templateId: SelectedTemplte[key]?.systemTemplateId || null,
          templateName: SelectedTemplte[key]?.templateName || null,
          isActivated: CheckboxesStates[key],
          days: DaysStates.ContactDays || 0,
        }))
      );
    } else {
      setState(AutoCorrespondenceConfigData || []);
    }
  }, [
    AutoCorrespondenceConfigData,
    AutoCorrespondenceTypesEnum,
    SelectedTemplte,
    CheckboxesStates,
    DaysStates,
  ]);

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };
  useEffect(() => {
    if (AutoCorrespondenceConfigData.length > 0) {
      const newCheckboxesStates = { ...CheckboxesStates };
      const newSelectedTemplate = { ...SelectedTemplte };
      const newDaysStates = { ...DaysStates };

      AutoCorrespondenceConfigData.forEach((config) => {
        const { typeId, isActivated, templateId } = config;
        const enumKey = getKeyByValue(AutoCorrespondenceTypesEnum, typeId);
        if (enumKey) {
          newCheckboxesStates[enumKey] = isActivated;
          newSelectedTemplate[enumKey] = {
            systemTemplateId: templateId,
            templateName: config.templateName,
          };
          if (typeId === AutoCorrespondenceTypesEnum.Contact) {
            newDaysStates.ContactDays = config.days || 0;
          } else if (
            typeId === AutoCorrespondenceTypesEnum.WlcBackClosedWon ||
            typeId === AutoCorrespondenceTypesEnum.WlcBackClosedLost
          ) {
            newDaysStates.WelcomebackDays = config.days || 0;
          }
        }
      });

      setCheckboxesStates(newCheckboxesStates);
      setSelectedTemplte(newSelectedTemplate);
      setDaysStates(newDaysStates);
    }
  }, [AutoCorrespondenceConfigData]);

  useEffect(() => {
    getAllTemplates();
  }, []);
  return (
    <div className="AutoCorrespondence-wrapper">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="w-100 mt-1">
        {/* <Spinner isActive={isLoading} isAbsolute /> */}
        <div className="d-flex-v-center-h-between">
          <PageHeading
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            headerTitle={"Auto-Correspondence"}
            subTitle={
              "Manage-automated-messaging-workflows-and-settings-to-streamline-communication-with-your-contacts"
            }
            wrapperClasses={"px-4 pt-2 w-75"}
            headerTitleClasses={"fz-30"}
            subTitleClasses={"fz-12 m-0"}
          />
          <div className="w-25 px-4 d-flex-center fj-end">
            <Button
              className="btns theme-propx solid"
              id="btn-save"
              onClick={() => saveHandler()}
            >
              {t(`${translationPath}save-changes`)}
            </Button>
          </div>
        </div>

        <div className="border-head">
          <div className="main-page-title">
            <div className="main-page-title-text-1">
              {t(`${translationPath}Auto-correspondence-Settings`)}
            </div>
            <div className="main-page-title-text-2">
              {t(
                `${translationPath}Customize-your-automated-communications-to-match-your-business-needs`
              )}
            </div>
          </div>
          <div className="line"></div>
          <div>
            <div className="Seaction1-main-tilte">
              {t(
                `${translationPath}use-these-settings-to-control-the-email-to-contact-if-no-lead-is-entered`
              )}
            </div>

            <div className="Seaction1">
              <div className="title-box">
                <div> {t(`${translationPath}send-email-to-the-contact`)} </div>
              </div>
              <div className="action-box">
                <div className="form-item-top">
                  <CheckboxesComponent
                    label="send-email"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    singleChecked={CheckboxesStates.Contact || false}
                    onSelectedCheckboxClicked={(e) => {
                      setCheckboxesStates((item) => ({
                        ...item,
                        Contact: e.target.checked,
                      }));
                    }}
                  />
                </div>
                <div className="form-item mt-3" style={{ maxWidth: "36rem" }}>
                  <AutocompleteComponent
                    idRef="templteRef"
                    labelValue={t(`${translationPath}Template`)}
                    inputPlaceholder={t(`${translationPath}Slect-Template`)}
                    selectedValues={SelectedTemplte.Contact}
                    data={Datatemplte || []}
                    getOptionSelected={(option) =>
                      option.systemTemplateId ===
                      SelectedTemplte?.Contact?.systemTemplateId
                    }
                    chipsLabel={(option) =>
                      (option.templateName && option.templateName) || ""
                    }
                    displayLabel={(option) =>
                      (option.templateName && option.templateName) || ""
                    }
                    renderOption={(option) =>
                      (option.templateName && option.templateName) || ""
                    }
                    multiple={false}
                    withoutSearchButton
                    onInputKeyUp={(e) => {
                      const { value } = e.target;
                      if (typingTimer.current)
                        clearTimeout(typingTimer.current);
                      typingTimer.current = setTimeout(() => {
                        getAllTemplates(value);
                      }, 700);
                    }}
                    onChange={(event, newValue) => {
                      setSelectedTemplte((item) => ({
                        ...item,
                        Contact: newValue || [],
                      }));
                    }}
                  />
                </div>
                <div
                  className="form-item"
                  title={t(`${translationPath}ex5dayes`)}
                >
                  <Inputs
                    idRef="ex5dayesrRef"
                    translationPath={translationPath}
                    inputPlaceholder="ex5dayes"
                    parentTranslationPath={parentTranslationPath}
                    type="number"
                    min={0}
                    value={
                      (DaysStates.ContactDays === 0 && null) ||
                      DaysStates.ContactDays
                    }
                    onInputChanged={(event) => {
                      setDaysStates((item) => ({
                        ...item,
                        ContactDays: +event.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="line"></div>
          <div className="Seaction2">
            <div className="title-box">
              <div>
                {t(
                  `${translationPath}Auto-correspondence-Settings-Customize-your-automated-communications-to-match-your-business-needs`
                )}
              </div>

              <div className="mt-5">
                {t(`${translationPath}Send-email-to-lead-when`)}
              </div>
            </div>
            <div className="action-box">
              <div
                className="form-item"
                title={t(`${translationPath}ex5dayes`)}
              >
                <Inputs
                  idRef="ex5dayesrRef"
                  translationPath={translationPath}
                  inputPlaceholder="ex5dayes"
                  parentTranslationPath={parentTranslationPath}
                  type="number"
                  min={0}
                  value={DaysStates.WelcomebackDays}
                  onInputChanged={(event) => {
                    setDaysStates((item) => ({
                      ...item,
                      WelcomebackDays: +event.target.value,
                    }));
                  }}
                />
              </div>
              <div className="form-item">
                <CheckboxesComponent
                  label="email-when-lead-is-colsed-(won)"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  singleChecked={CheckboxesStates.WlcBackClosedWon || false}
                  onSelectedCheckboxClicked={(e) => {
                    setCheckboxesStates((item) => ({
                      ...item,
                      WlcBackClosedWon: e.target.checked,
                    }));
                  }}
                />
              </div>
              <div className="form-item mt-3" style={{ maxWidth: "36rem" }}>
                <AutocompleteComponent
                  idRef="templteRef"
                  labelValue={t(`${translationPath}Template`)}
                  inputPlaceholder={t(`${translationPath}Slect-Template`)}
                  selectedValues={SelectedTemplte.WlcBackClosedWon}
                  data={Datatemplte || []}
                  getOptionSelected={(option) =>
                    option.systemTemplateId ===
                    SelectedTemplte?.WlcBackClosedWon?.systemTemplateId
                  }
                  chipsLabel={(option) =>
                    (option.templateName && option.templateName) || ""
                  }
                  displayLabel={(option) =>
                    (option.templateName && option.templateName) || ""
                  }
                  renderOption={(option) =>
                    (option.templateName && option.templateName) || ""
                  }
                  multiple={false}
                  withoutSearchButton
                  onInputKeyUp={(e) => {
                    const { value } = e.target;
                    if (typingTimer.current) clearTimeout(typingTimer.current);
                    typingTimer.current = setTimeout(() => {
                      getAllTemplates(value);
                    }, 700);
                  }}
                  onChange={(event, newValue) => {
                    setSelectedTemplte((item) => ({
                      ...item,
                      WlcBackClosedWon: newValue || [],
                    }));
                  }}
                />
              </div>

              <div className="form-item">
                <CheckboxesComponent
                  label="email-when-lead-is-colsed-(Lost)"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  singleChecked={CheckboxesStates.WlcBackClosedLost || false}
                  onSelectedCheckboxClicked={(e) => {
                    setCheckboxesStates((item) => ({
                      ...item,
                      WlcBackClosedLost: e.target.checked,
                    }));
                  }}
                />
              </div>
              <div className="form-item mt-3" style={{ maxWidth: "36rem" }}>
                <AutocompleteComponent
                  idRef="templteRef"
                  labelValue={t(`${translationPath}Template`)}
                  inputPlaceholder={t(`${translationPath}Slect-Template`)}
                  selectedValues={SelectedTemplte.WlcBackClosedLost}
                  data={Datatemplte || []}
                  getOptionSelected={(option) =>
                    option.systemTemplateId ===
                    SelectedTemplte?.WlcBackClosedLost?.systemTemplateId
                  }
                  chipsLabel={(option) =>
                    (option.templateName && option.templateName) || ""
                  }
                  displayLabel={(option) =>
                    (option.templateName && option.templateName) || ""
                  }
                  renderOption={(option) =>
                    (option.templateName && option.templateName) || ""
                  }
                  multiple={false}
                  withoutSearchButton
                  onInputKeyUp={(e) => {
                    const { value } = e.target;
                    if (typingTimer.current) clearTimeout(typingTimer.current);
                    typingTimer.current = setTimeout(() => {
                      getAllTemplates(value);
                    }, 700);
                  }}
                  onChange={(event, newValue) => {
                    setSelectedTemplte((item) => ({
                      ...item,
                      WlcBackClosedLost: newValue || [],
                    }));
                  }}
                />
              </div>
            </div>
          </div>
          <div className="line"></div>
          <div className="Seaction3">
            <div className="title-box">
              <div>{t(`${translationPath}Send-email-to-agent-when`)} </div>
            </div>
            <div className="min">
              <div>
                <div className="action-box-main">
                  <div className="action-box">
                    <div>
                      <div className="form-item">
                        <CheckboxesComponent
                          label="send-emailSeller"
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          singleChecked={
                            CheckboxesStates.SellerLeadAssigned || false
                          }
                          onSelectedCheckboxClicked={(e) => {
                            setCheckboxesStates((item) => ({
                              ...item,
                              SellerLeadAssigned: e.target.checked,
                            }));
                          }}
                        />
                      </div>
                      <div
                        className="form-item mt-3"
                        style={{ maxWidth: "36rem" }}
                      >
                        <AutocompleteComponent
                          idRef="templteRef"
                          labelValue={t(`${translationPath}Template`)}
                          inputPlaceholder={t(
                            `${translationPath}Slect-Template`
                          )}
                          selectedValues={SelectedTemplte.SellerLeadAssigned}
                          data={Datatemplte || []}
                          getOptionSelected={(option) =>
                            option.systemTemplateId ===
                            SelectedTemplte?.SellerLeadAssigned
                              ?.systemTemplateId
                          }
                          chipsLabel={(option) =>
                            (option.templateName && option.templateName) || ""
                          }
                          displayLabel={(option) =>
                            (option.templateName && option.templateName) || ""
                          }
                          renderOption={(option) =>
                            (option.templateName && option.templateName) || ""
                          }
                          multiple={false}
                          withoutSearchButton
                          onInputKeyUp={(e) => {
                            const { value } = e.target;
                            if (typingTimer.current)
                              clearTimeout(typingTimer.current);
                            typingTimer.current = setTimeout(() => {
                              getAllTemplates(value);
                            }, 700);
                          }}
                          onChange={(event, newValue) => {
                            setSelectedTemplte((item) => ({
                              ...item,
                              SellerLeadAssigned: newValue || [],
                            }));
                          }}
                        />
                      </div>
                      <div className="Line-v" />
                    </div>
                  </div>
                  <div>
                    <div className="form-item">
                      <CheckboxesComponent
                        label="send-emailBuyer"
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        singleChecked={
                          CheckboxesStates.BuyerLeadAssigned || false
                        }
                        onSelectedCheckboxClicked={(e) => {
                          setCheckboxesStates((item) => ({
                            ...item,
                            BuyerLeadAssigned: e.target.checked,
                          }));
                        }}
                      />
                    </div>
                    <div
                      className="form-item mt-3"
                      style={{ maxWidth: "36rem" }}
                    >
                      <AutocompleteComponent
                        idRef="templteRef"
                        labelValue={t(`${translationPath}Template`)}
                        inputPlaceholder={t(`${translationPath}Slect-Template`)}
                        selectedValues={SelectedTemplte.BuyerLeadAssigned}
                        data={Datatemplte || []}
                        getOptionSelected={(option) =>
                          option.systemTemplateId ===
                          SelectedTemplte?.BuyerLeadAssigned?.systemTemplateId
                        }
                        chipsLabel={(option) =>
                          (option.templateName && option.templateName) || ""
                        }
                        displayLabel={(option) =>
                          (option.templateName && option.templateName) || ""
                        }
                        renderOption={(option) =>
                          (option.templateName && option.templateName) || ""
                        }
                        multiple={false}
                        withoutSearchButton
                        onInputKeyUp={(e) => {
                          const { value } = e.target;
                          if (typingTimer.current)
                            clearTimeout(typingTimer.current);
                          typingTimer.current = setTimeout(() => {
                            getAllTemplates(value);
                          }, 700);
                        }}
                        onChange={(event, newValue) => {
                          setSelectedTemplte((item) => ({
                            ...item,
                            BuyerLeadAssigned: newValue || [],
                          }));
                        }}
                      />
                    </div>
                    <div className="Line-v" />
                  </div>
                </div>
              </div>

              <div>
                <div className="action-box">
                  <div>
                    <div className="form-item">
                      <CheckboxesComponent
                        label="send-emaillandlord"
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        singleChecked={
                          CheckboxesStates.LandlordLeadAssigned || false
                        }
                        onSelectedCheckboxClicked={(e) => {
                          setCheckboxesStates((item) => ({
                            ...item,
                            LandlordLeadAssigned: e.target.checked,
                          }));
                        }}
                      />
                    </div>
                    <div
                      className="form-item mt-3"
                      style={{ maxWidth: "36rem" }}
                    >
                      <AutocompleteComponent
                        idRef="templteRef"
                        labelValue={t(`${translationPath}Template`)}
                        inputPlaceholder={t(`${translationPath}Slect-Template`)}
                        selectedValues={SelectedTemplte.LandlordLeadAssigned}
                        data={Datatemplte || []}
                        getOptionSelected={(option) =>
                          option.systemTemplateId ===
                          SelectedTemplte?.LandlordLeadAssigned
                            ?.systemTemplateId
                        }
                        chipsLabel={(option) =>
                          (option.templateName && option.templateName) || ""
                        }
                        displayLabel={(option) =>
                          (option.templateName && option.templateName) || ""
                        }
                        renderOption={(option) =>
                          (option.templateName && option.templateName) || ""
                        }
                        multiple={false}
                        withoutSearchButton
                        onInputKeyUp={(e) => {
                          const { value } = e.target;
                          if (typingTimer.current)
                            clearTimeout(typingTimer.current);
                          typingTimer.current = setTimeout(() => {
                            getAllTemplates(value);
                          }, 700);
                        }}
                        onChange={(event, newValue) => {
                          setSelectedTemplte((item) => ({
                            ...item,
                            LandlordLeadAssigned: newValue || [],
                          }));
                        }}
                      />
                    </div>
                    <div className="Line-v" />
                  </div>
                </div>
              </div>

              <div>
                <div className="action-box">
                  <div>
                    <div className="form-item">
                      <CheckboxesComponent
                        label="send-emailtenantr"
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        singleChecked={
                          CheckboxesStates.TenantLeadAssigned || false
                        }
                        onSelectedCheckboxClicked={(e) => {
                          setCheckboxesStates((item) => ({
                            ...item,
                            TenantLeadAssigned: e.target.checked,
                          }));
                        }}
                      />
                    </div>
                    <div
                      className="form-item mt-3"
                      style={{ maxWidth: "36rem" }}
                    >
                      <AutocompleteComponent
                        idRef="templteRef"
                        labelValue={t(`${translationPath}Template`)}
                        inputPlaceholder={t(`${translationPath}Slect-Template`)}
                        selectedValues={SelectedTemplte.TenantLeadAssigned}
                        data={Datatemplte || []}
                        getOptionSelected={(option) =>
                          option.systemTemplateId ===
                          SelectedTemplte?.TenantLeadAssigned?.systemTemplateId
                        }
                        chipsLabel={(option) =>
                          (option.templateName && option.templateName) || ""
                        }
                        displayLabel={(option) =>
                          (option.templateName && option.templateName) || ""
                        }
                        renderOption={(option) =>
                          (option.templateName && option.templateName) || ""
                        }
                        multiple={false}
                        withoutSearchButton
                        onInputKeyUp={(e) => {
                          const { value } = e.target;
                          if (typingTimer.current)
                            clearTimeout(typingTimer.current);
                          typingTimer.current = setTimeout(() => {
                            getAllTemplates(value);
                          }, 700);
                        }}
                        onChange={(event, newValue) => {
                          setSelectedTemplte((item) => ({
                            ...item,
                            TenantLeadAssigned: newValue || [],
                          }));
                        }}
                      />
                    </div>
                    <div className="Line-v" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="line"></div>
          <div className="Seaction4">
            <div className="title-box">
              <div>
                {t(`${translationPath}send-email-to-the-Lead-closed-won`)}
              </div>
            </div>
            <div className="min">
              <div className="form-item">
                <CheckboxesComponent
                  label="send-email"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  singleChecked={CheckboxesStates.ClosedWon || false}
                  onSelectedCheckboxClicked={(e) => {
                    setCheckboxesStates((item) => ({
                      ...item,
                      ClosedWon: e.target.checked,
                    }));
                  }}
                />
              </div>
              <div className="form-item mt-3" style={{ maxWidth: "36rem" }}>
                <AutocompleteComponent
                  idRef="templteRef"
                  labelValue={t(`${translationPath}Template`)}
                  inputPlaceholder={t(`${translationPath}Slect-Template`)}
                  selectedValues={SelectedTemplte.ClosedWon}
                  data={Datatemplte || []}
                  getOptionSelected={(option) =>
                    option.systemTemplateId ===
                    SelectedTemplte?.ClosedWon?.systemTemplateId
                  }
                  chipsLabel={(option) =>
                    (option.templateName && option.templateName) || ""
                  }
                  displayLabel={(option) =>
                    (option.templateName && option.templateName) || ""
                  }
                  renderOption={(option) =>
                    (option.templateName && option.templateName) || ""
                  }
                  multiple={false}
                  withoutSearchButton
                  onInputKeyUp={(e) => {
                    const { value } = e.target;
                    if (typingTimer.current) clearTimeout(typingTimer.current);
                    typingTimer.current = setTimeout(() => {
                      getAllTemplates(value);
                    }, 700);
                  }}
                  onChange={(event, newValue) => {
                    setSelectedTemplte((item) => ({
                      ...item,
                      ClosedWon: newValue || [],
                    }));
                  }}
                />{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
