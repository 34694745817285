import React, { useCallback, useEffect, useState } from "react";
import { CustomPagination, NoDataFound, TransactionHistoryItemSkeleton } from "../../../../../../Components";
import { GetAllContactUnitTransactions } from "../../../../../../Services";
import { Box, Typography } from "@material-ui/core";
import { TransactionHistoryItem } from "../TransactionHistoryItem";
import { useTranslate } from "../../../../../../Hooks";

// Styles
import useStyles from "./styles";

function TransactionHistory({ contactId }) {
  const styles = useStyles();
  const { translate } = useTranslate("NewContactsView");

  const [pagination, setPagination] = useState({
    pageSize: 25,
    pageIndex: 0,
  });
  const [inputValue, setInputValue] = useState(pagination.pageIndex);
  const [unitTransactions, setUnitTransactions] = useState({
    result: [],
    totalCount: 0,
  });
  const [loadingUnitTransaction, setLoadingUnitTransaction] = useState(true);

  const getAllContactUnitTransactions = useCallback(async () => {
    setLoadingUnitTransaction(true);
    try {
      const res = await GetAllContactUnitTransactions(pagination, contactId);
      if (!(res && res.status && res.status !== 200)) {
        setUnitTransactions({
          result: res.result,
          totalCount: res.totalCount,
        });
      } else {
        setUnitTransactions({
          result: [],
          totalCount: 0,
        });
      }
    } catch (error) {
      console.error(error, "faild to fetch unit Transactions");
    } finally {
      setLoadingUnitTransaction(false);
    }
  });
  useEffect(() => {
    getAllContactUnitTransactions();
  }, []);

  return (
    <Box className={styles.containerTransactionHistory}>
      <Box className={styles.containerCardsTransaction}>
        {!loadingUnitTransaction ? (
          unitTransactions?.result?.map((transactionItem, index) => (
            <TransactionHistoryItem
              key={index}
              detailsTransaction={transactionItem}
              hideBorder={false}
              hideUnitType
            />
          ))
        ) : (
          <TransactionHistoryItemSkeleton
            numberOfCards={5}
            hideBorder={false}
            hideUnitType
          />
        )}
      </Box>
      <CustomPagination
        hideInMobile
        totalItems={unitTransactions.totalCount}
        itemsPerPage={pagination.pageSize}
        currentPage={pagination.pageIndex}
        inputValue={inputValue}
        setInputValue={setInputValue}
        onPageChange={(page) =>
          setPagination((prev) => ({ ...prev, pageIndex: page }))
        }
        onItemsPerPageChange={(items) =>
          setPagination((prev) => ({ ...prev, pageSize: items }))
        }
        isLoading={loadingUnitTransaction}
      />
      {unitTransactions?.result.length === 0 &&
        unitTransactions.totalCount === 0 &&
        !loadingUnitTransaction && (
          <NoDataFound title={'transaction'}/>
        )}
    </Box>
  );
}
export default TransactionHistory;