import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { showError, showSuccess, GetParams } from '../../../../../../Helper';
import { DialogComponent, Spinner } from '../../../../../../Components';
import { RemoveAgentToRotationSchemeServices } from '../../../../../../Services/RotaionSchemaService/RotationSchemaService'

const DeleteAssignedAgent = ({
    agentId,
    isOpen,
    isClose,
    reloadData,
    parentTranslationPath,
    translationPath,
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const [isLoading, setIsLoading] = useState(false);
    const deleteHandler = async () => {
        setIsLoading(true);
        const res = await RemoveAgentToRotationSchemeServices(GetParams('id'), agentId)
        if (!(res && res.status && res.status !== 200)) {
            reloadData();
            showSuccess(t(`${translationPath}assigned-agent-deleted-successfully`));
        } else showError(t(`${translationPath}assigned-agent-deleted-failed`));
        setIsLoading(false);
        isClose();
    };
    return (
        <DialogComponent
            titleText='confirm-message'
            saveText='confirm'
            saveType='button'
            maxWidth='sm'
            dialogContent={(
                <div className='d-flex-column-center'>
                    <Spinner isActive={isLoading} isAbsolute />
                    <span className='mdi mdi-close-octagon c-danger mdi-48px' />
                    <span>{`${t(`${translationPath}delete-assigned-agent`)}`}</span>
                </div>
            )}
            SmothMove
            saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
            isOpen={isOpen}
            onSaveClicked={deleteHandler}
            onCloseClicked={isClose}
            onCancelClicked={isClose}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
        />
    );
};
export { DeleteAssignedAgent };
DeleteAssignedAgent.propTypes = {
    activeItem: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    isClose: PropTypes.func.isRequired,
    reloadData: PropTypes.func.isRequired,
    parentTranslationPath: PropTypes.string.isRequired,
    translationPath: PropTypes.string.isRequired,
};
