import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
  AutocompleteComponent,
  Inputs,
  SelectComponet as SelectComponent,
  Spinner,
} from '../../../../../../Components';
import {
  CreatePropertyPlanInstallment,
  GetLookupItemsByLookupTypeName,
  UpdatePropertyPlanInstallment,
  lookupItemsGetId,
} from '../../../../../../Services';
import { PropertyPaymentPlan } from '../../../../../../Enums';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import {
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {
  getErrorByName,
  showError,
  showSuccess,
} from '../../../../../../Helper';
import { StaticLookupsIds } from '../../../../../../assets/json/StaticLookupsIds';

export const InstallmentManagementDialog = ({
  activeItem,
  isDialogOpen,
  onClose,
  planId,
  parentTranslationPath,
  translationPath,
  installments,
  reload,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const filter = {
    pageSize: 100,
    pageIndex: 0,
  };
  const [data, setData] = useReducer(reducer, {
    paymentTypes: [],
    planTypes: [],
    amountNature: [],
    frequency: [],
    contactTypes: [],
  });
  const [isLoading, setIsLoading] = useReducer(reducer, {
    paymentType: false,
    planType: false,
    amountNature: false,
    frequency: false,
    contactTypes: false,
  });
  const [selected, setSelected] = useReducer(reducer, {
    paymentType: null,
    amountNature: null,
    frequency: null,
    contactType: null,
  });
  const initialState = {
    propertyPaymentPlanId: planId,
    paymentTypeId: null,
    amountNatureId: null,
    frequencyId: null,
    installmentRate: null,
    installmentAmount: null,
    installmentNumber: (installments?.length ?? 0) + 1,
    instalmentDate: null,
    contactTypesLookupId: null,
    adjustmentInstallmentNumberId: null,
    adjustmentTypeId: null,
  };
  const [state, setState] = useReducer(reducer, initialState);
  const schema = Joi.object({
    paymentTypeId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}is-required`, {
          field: t('payment-type'),
        }),
        'number.empty': t(`${translationPath}is-required`),
      }),
    frequencyId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}is-required`, {
          field: t('frequency'),
        }),
        'number.empty': t(`${translationPath}is-required`, {
          field: t('frequency'),
        }),
      }),
    amountNatureId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}is-required`, {
          field: t('amount-nature'),
        }),
        'number.empty': t(`${translationPath}is-required`, {
          field: t('amount-nature'),
        }),
      }),
    contactTypesLookupId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}is-required`, {
          field: t('contact-type'),
        }),
        'number.empty': t(`${translationPath}is-required`, {
          field: t('contact-type'),
        }),
      }),
    installmentRate: Joi.number().when('amountNatureId', {
      is: 60736,
      then: Joi.number()
        .min(1)
        .required()
        .messages({
          'number.base': t(`${translationPath}is-required`, {
            field: t('installment-rate'),
          }),
          'number.empty': t(`${translationPath}is-required`, {
            field: t('installment-rate'),
          }),
          'number.min': t(`${translationPath}should-be-greater-than`, {
            field: t('installment-rate'),
            value: 1,
          }),
        }),
      otherwise: Joi.number()
        .min(0)
        .max(1)
        .required()
        .messages({
          'number.base': t(`${translationPath}is-required`, {
            field: t('installment-rate'),
          }),
          'number.empty': t(`${translationPath}is-required`, {
            field: t('installment-rate'),
          }),
          'number.min': t(`${translationPath}should-be-between`, {
            field: t('installment-rate'),
            min: 0,
            max: 1,
          }),
          'number.max': t(`${translationPath}should-be-between`, {
            field: t('installment-rate'),
            min: 0,
            max: 1,
          }),
        }),
    }),
    instalmentDate: Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}is-required`, {
          field: t('installment-date'),
        }),
        'string.empty': t(`${translationPath}is-required`, {
          field: t('installment-date'),
        }),
      }),
    adjustmentTypeId: Joi.number().allow(null),
    adjustmentInstallmentNumberId: Joi.number().when('adjustmentTypeId', {
      is: Joi.any().not(null),
      then: Joi.number()
        .required()
        .messages({
          'number.base': t(`${translationPath}is-required`, {
            field: t('installment-no'),
          }),
          'number.empty': t(`${translationPath}is-required`, {
            field: t('installment-no'),
          }),
          'number.required': t(`${translationPath}is-required`, {
            field: t('installment-no'),
          }),
        }),
      otherwise: Joi.number().allow(null),
    }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const saveHandler = async (event) => {
    event.preventDefault();
    setIsSaving(true);
    setIsSubmitted(true);
    if (schema.error) {
      showError(t('please-fill-all-Required-fields'));
      setIsSaving(false);
      return;
    }
    if (activeItem) {
      const res = await UpdatePropertyPlanInstallment(state);
      if (!(res?.status && res.status !== 200)) {
        showSuccess(t(`${translationPath}${`update-success`}`));
      } else {
        showError(t(`${translationPath}${`update-failed`}`));
        return;
      }
    } else {
      const res = await CreatePropertyPlanInstallment(state);
      if (!(res?.status && res.status !== 200)) {
        showSuccess(t(`${translationPath}${`create-success`}`));
      } else {
        showError(t(`${translationPath}${`create-failed`}`));
        return;
      }
    }
    onClose();
    setState({ id: 'edit', value: initialState });
    reload();
  };

  const getPaymentTypeLookups = useCallback(async () => {
    setIsLoading({ id: 'paymentType', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: PropertyPaymentPlan.PaymentType.lookupTypeName,
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex,
    });

    if (!(res?.status && res.status !== 200)) {
      setData({ id: 'paymentTypes', value: res.result || [] });
    }
    setIsLoading({ id: 'paymentType', value: false });
  }, []);
  const getAmountNatureLookups = useCallback(async () => {
    setIsLoading({ id: 'amountNature', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: PropertyPaymentPlan.AmountNature.lookupTypeName,
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex,
    });

    if (!(res?.status && res.status !== 200)) {
      setData({ id: 'amountNature', value: res.result || [] });
    }
    setIsLoading({ id: 'amountNature', value: false });
  }, []);
  const getFrequencyLookups = useCallback(async () => {
    setIsLoading({ id: 'frequency', value: true });

    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: PropertyPaymentPlan.Frequency.lookupTypeName,
      pageSize: 100,
      pageIndex: filter.pageIndex,
    });
    if (!(res?.status && res.status !== 200)) {
      setData({ id: 'frequency', value: res.result || [] });
    }
    setIsLoading({ id: 'frequency', value: false });
  }, []);
  const getContactTypes = async () => {
    setIsLoading({ id: 'contactTypes', value: true });

    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.ContactTypes,
    });
    if (!(res?.status && res.status !== 200)) {
      setData({ id: 'contactTypes', value: res.slice(0, 2) });
    }
    setIsLoading({ id: 'contactTypes', value: false });
  };
  useEffect(() => {
    if (activeItem) {
      setState({
        id: 'edit',
        value: {
          propertyPaymentPlanId: activeItem.propertyPaymentPlanId,
          paymentTypeId: activeItem.paymentTypeLookupDto.paymentTypeLookupId,
          amountNatureId: activeItem.amountNatureLookupDto.amountNatureLookupId,
          frequencyId: activeItem.frequencyLookupDto.frequencyLookupId,
          installmentRate: activeItem.installmentRate,
          installmentNumber: activeItem.orderInstallmentNumber,
          instalmentDate: activeItem.instalmentDate,
          contactTypesLookupId: activeItem.contactTypesLookupId,
          adjustmentInstallmentNumberId:
            activeItem.adjustmentInstallmentNumberId,
          adjustmentTypeId: activeItem.adjustmentTypeId,
          description: activeItem.description ?? '',
          id: activeItem.paymentPlanInstallmentId,
        },
      });
      setSelected({
        id: 'edit',
        value: {
          paymentType: {
            lookupItemId: activeItem.paymentTypeLookupDto.paymentTypeLookupId,
            lookupItemName:
              activeItem.paymentTypeLookupDto.paymentTypeLookupName,
          },
          amountNature: {
            lookupItemId: activeItem.amountNatureLookupDto.amountNatureLookupId,
            lookupItemName:
              activeItem.amountNatureLookupDto.amountNatureLookupName,
          },
          frequency: {
            lookupItemId: activeItem.frequencyLookupDto.frequencyLookupId,
            lookupItemName: activeItem.frequencyLookupDto.frequencyLookupName,
          },
          contactType: {
            lookupItemId: activeItem.contactTypesLookupId,
            lookupItemName: activeItem.contactTypesLookupName,
          },
        },
      });
    } else setState({ id: 'edit', value: initialState });
  }, [activeItem]);

  const dateDateType = {
    Date: 'date',
    Completion: 'text',
  };

  useEffect(() => {
    getPaymentTypeLookups();
    getAmountNatureLookups();
    getFrequencyLookups();
    getContactTypes();
  }, []);
  return (
    <Dialog
      open={isDialogOpen}
      onClose={onClose}
      className='activities-management-dialog-wrapper'
    >
      <Spinner isActive={isSaving} isAbsolute />
      <form noValidate onSubmit={saveHandler}>
        <DialogTitle id='alert-dialog-slide-title'>
          {t(
            `${translationPath}${
              (activeItem && 'edit-installment-plan') ||
              'add-new-installment-plan'
            }`
          )}
        </DialogTitle>
        <DialogContent>
          <div className='d-flex p-3'>
            <div className='w-50 mx-2'>
              <div className='my-1'></div>
              <div className='my-1'>
                <AutocompleteComponent
                  idRef='paymentTypeIdRef'
                  labelValue='payment-type'
                  labelClasses='Requierd-Color'
                  selectedValues={selected.paymentType}
                  multiple={false}
                  data={data.paymentTypes}
                  displayLabel={(option) => option.lookupItemName || ''}
                  renderOption={(option) => option.lookupItemName || ''}
                  getOptionSelected={(option) =>
                    option.lookupItemId === state.paymentTypeId
                  }
                  withoutSearchButton
                  isLoading={isLoading.paymentType}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    setSelected({ id: 'paymentType', value: newValue });
                    setState({
                      id: 'paymentTypeId',
                      value: (newValue && newValue.lookupItemId) || null,
                    });
                  }}
                  isWithError
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, 'paymentTypeId').message}
                  error={getErrorByName(schema, 'paymentTypeId').error}
                />
              </div>
              <div className='my-3'>
                <AutocompleteComponent
                  idRef='amountNatureIdRef'
                  labelValue='amount-nature'
                  labelClasses='Requierd-Color'
                  selectedValues={selected.amountNature}
                  multiple={false}
                  data={data.amountNature}
                  displayLabel={(option) => option.lookupItemName || ''}
                  renderOption={(option) => option.lookupItemName || ''}
                  getOptionSelected={(option) =>
                    option.lookupItemId === state.amountNatureId
                  }
                  withoutSearchButton
                  isLoading={isLoading.amountNature}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    setSelected({ id: 'amountNature', value: newValue });
                    setState({
                      id: 'amountNatureId',
                      value: (newValue && newValue.lookupItemId) || null,
                    });
                  }}
                  isWithError
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, 'amountNatureId').message}
                  error={getErrorByName(schema, 'amountNatureId').error}
                />
              </div>
              {state.amountNatureId && (
                <div className='my-3'>
                  {state.amountNatureId === 60736 ? (
                    <Inputs
                      idRef='installmentAmountRef'
                      labelValue='installment-amount'
                      labelClasses='Requierd-Color'
                      value={state.installmentRate || ''}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      type={'number'}
                      onInputChanged={(event) => {
                        setState({
                          id: 'installmentRate',
                          value: +event.target.value,
                        });
                      }}
                      isWithError
                      isSubmitted={isSubmitted}
                      helperText={
                        getErrorByName(schema, 'installmentRate').message
                      }
                      error={getErrorByName(schema, 'installmentRate').error}
                    />
                  ) : (
                    <Inputs
                      idRef='installmentRateRef'
                      labelValue='installment-rate'
                      labelClasses='Requierd-Color'
                      value={state.installmentRate || ''}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onInputChanged={(event) => {
                        setState({
                          id: 'installmentRate',
                          value: +event.target.value,
                        });
                      }}
                      type={'number'}
                      min={0.01}
                      max={0.99}
                      step={0.01}
                      isWithError
                      isSubmitted={isSubmitted}
                      helperText={
                        getErrorByName(schema, 'installmentRate').message
                      }
                      error={getErrorByName(schema, 'installmentRate').error}
                    />
                  )}
                </div>
              )}
              <div className='my-3'>
                <Inputs
                  idRef='descriptionIdRef'
                  labelValue='description'
                  value={state.description || ''}
                  multiline
                  rows={4}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(event) => {
                    setState({ id: 'description', value: event.target.value });
                  }}
                />
              </div>
            </div>

            <div className='w-50 mx-2'>
              {((!activeItem && installments?.length > 0) ||
                (activeItem && installments.length > 1)) && (
                <div className='d-flex'>
                  <div className='w-50 mr-2'>
                    <SelectComponent
                      idRef='adjustmentRef'
                      labelValue={t(`${translationPath}adjustment`)}
                      value={state.adjustmentTypeId ?? ''}
                      data={[
                        { name: 'plus', value: 1 },
                        { name: 'minus', value: 2 },
                      ]}
                      onSelectChanged={(value) =>
                        setState({
                          id: 'adjustmentTypeId',
                          value: value,
                        })
                      }
                      valueInput={'value'}
                      textInput={'name'}
                    />
                  </div>

                  <div className='w-50 ml-2'>
                    <SelectComponent
                      idRef='adjustmentInstallmentNumberRef'
                      labelValue={t(`${translationPath}installment-no`)}
                      value={state.adjustmentInstallmentNumberId ?? ''}
                      data={installments?.map((item, i) => ({
                        value: item.paymentPlanInstallmentId,
                        name: i + 1,
                      }))}
                      onSelectChanged={(value) =>
                        setState({
                          id: 'adjustmentInstallmentNumberId',
                          value,
                        })
                      }
                      valueInput={'value'}
                      textInput={'name'}
                      isWithError
                      isSubmitted={isSubmitted}
                      helperText={
                        getErrorByName(schema, 'adjustmentInstallmentNumberId')
                          .message
                      }
                      error={
                        getErrorByName(schema, 'adjustmentInstallmentNumberId')
                          .error
                      }
                    />
                  </div>
                </div>
              )}
              <div className='my-2'>
                <AutocompleteComponent
                  idRef='frequencyIdRef'
                  labelValue='frequency'
                  labelClasses='Requierd-Color'
                  selectedValues={selected.frequency}
                  multiple={false}
                  data={data.frequency}
                  displayLabel={(option) => option?.lookupItemName ?? ''}
                  renderOption={(option) => option?.lookupItemName ?? ''}
                  getOptionSelected={(option) =>
                    option.lookupItemId === state.frequencyId
                  }
                  withoutSearchButton
                  isLoading={isLoading.frequency}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    setSelected({ id: 'frequency', value: newValue });
                    setState({
                      id: 'frequencyId',
                      value: newValue?.lookupItemId,
                    });
                  }}
                  isWithError
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, 'frequencyId').message}
                  error={getErrorByName(schema, 'frequencyId').error}
                />
              </div>
              {state.frequencyId && (
                <div className='my-3'>
                  <Inputs
                    idRef='dueDateRef'
                    labelValue={t(`${translationPath}due-date`)}
                    labelClasses='Requierd-Color'
                    value={state.instalmentDate}
                    type={
                      dateDateType[selected.frequency.lookupItemName] ??
                      'number'
                    }
                    onInputChanged={(e) =>
                      setState({ id: 'instalmentDate', value: e.target.value })
                    }
                    isWithError
                    isSubmitted={isSubmitted}
                    helperText={
                      getErrorByName(schema, 'instalmentDate').message
                    }
                    error={getErrorByName(schema, 'instalmentDate').error}
                  />
                </div>
              )}
              <div className='my-2'>
                <SelectComponent
                  value={state.contactTypesLookupId}
                  isLoading={isLoading.contactTypes}
                  labelValue={t(`${translationPath}contact-type`)}
                  data={data.contactTypes}
                  labelClasses='Requierd-Color'
                  valueInput={'lookupItemId'}
                  textInput={'lookupItemName'}
                  onSelectChanged={(value) =>
                    setState({
                      id: 'contactTypesLookupId',
                      value,
                    })
                  }
                  isWithError
                  isSubmitted={isSubmitted}
                  helperText={
                    getErrorByName(schema, 'contactTypesLookupId').message
                  }
                  error={getErrorByName(schema, 'contactTypesLookupId').error}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonBase onClick={onClose} className='btns theme-solid bg-cancel'>
            {t(`${translationPath}cancel`)}
          </ButtonBase>
          <ButtonBase
            className='btns theme-solid'
            onClick={saveHandler}
            type='submit'
          >
            {t(`${translationPath}save`)}
          </ButtonBase>
        </DialogActions>
      </form>
    </Dialog>
  );
};
