import React from "react";

function ShieldIcon({ fill, ...restProps }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M9.55.964c-.405.12-5.311 1.979-5.634 2.135-.676.327-1.119.861-1.326 1.599-.069.245-.071.324-.083 2.869-.008 1.743.001 2.744.025 3 .205 2.133 1.341 4.08 3.518 6.03a17.225 17.225 0 0 0 2.322 1.746c.901.573 1.176.69 1.628.69.421 0 .66-.091 1.358-.522 1.206-.742 2.153-1.471 3.088-2.378 1.681-1.628 2.636-3.256 2.959-5.05.078-.432.078-.438.078-3.283-.001-2.814-.001-2.853-.072-3.102-.21-.74-.652-1.273-1.325-1.598-.344-.167-5.263-2.027-5.652-2.138a1.71 1.71 0 0 0-.884.002m2.948 2.522c1.349.506 2.571.971 2.717 1.034.294.127.422.23.53.43.071.133.072.143.082 2.717.011 2.734.003 2.946-.143 3.533-.501 2.014-2.311 4.09-5.08 5.825-.3.188-.572.342-.604.342-.073 0-1.025-.595-1.537-.96-2.242-1.601-3.707-3.441-4.147-5.207-.146-.587-.154-.799-.143-3.533.01-2.574.011-2.584.082-2.717a.854.854 0 0 1 .428-.389c.288-.13 5.23-1.988 5.298-1.991.036-.002 1.168.41 2.517.916"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default ShieldIcon;
