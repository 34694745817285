import React, { useState, useEffect } from 'react';
import { TabsComponent } from '../../../../Components';
import { GlobalTranslate } from '../../../../Helper';
import { tabsPermissionsHelper } from '../../../../Helper/ExceptionPermissions.Helper';
import { useTitle } from '../../../../Hooks';
import { SaleTeamsDashboardTabs } from '../SaleTeamsDashboardTabs/SaleTeamsDashboardTabs';

export const SaleTeamsDashboardTabCmp = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [dashboardTabsList, setDashboardTabsList] = useState([]);
  useTitle(GlobalTranslate.t('Dashboard:salesteamlead-dashboard'));
  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };

  useEffect(() => {
    const list = tabsPermissionsHelper(SaleTeamsDashboardTabs).map((field, fieldIndex) => ({
      ...field,
      index: fieldIndex,
    }));
    setDashboardTabsList(list);
  }, [SaleTeamsDashboardTabs]);

  return (
    <div>
      <TabsComponent
        data={dashboardTabsList}
        labelInput='label'
        themeClasses='theme-curvedpowerbi'
        currentTab={activeTab}
        onTabChanged={onTabChanged}
        dynamicComponentProps={{
          setActiveTab,
          dynamicComponentSaleTeamsProps: Object.values(dashboardTabsList).find(
            (element) => element.index === activeTab
          ),
        }}
      />
    </div>
  );
};
