import React from 'react';
import { useTranslation } from 'react-i18next';

export const WithFieldset = ({
  translationPath,
  parentTranslationPath,
  legend,
  children,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  return (
    <fieldset className='input-fieldset my-3'>
      <legend className='input-fieldset-legend'>
        {t(`${translationPath}${legend}`)}
      </legend>
      {children}
    </fieldset>
  );
};
