

import React from 'react';
import { useTranslation } from 'react-i18next'; 
import { Inputs } from '../../../../../../../Components' ;  

export const UnitsModelsFieldsSecondSectionComponent = ({setState , state , translationPath , parentTranslationPath , error ,
  setError , }) => {

    const { t } = useTranslation(parentTranslationPath);


  return (
    <>
        <div className='dialog-content-item'>
                <Inputs
                  idRef='sellingPriceFromRef'
                  labelValue='sellingPriceFrom'
                  type='number'
                  value={state.sellingPriceFrom}
                  onInputChanged={(event) => {
                    const { value } =  event.target ; 
                    if( value < 0 )
                    return ;

                    setState({ id: 'sellingPriceFrom', value: (value)  });
                  }}
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath}
                  withNumberFormat 
               />
        </div>
        <div className='dialog-content-item'>
                <Inputs
                  idRef='sellingPriceToRef'
                  labelValue='sellingPriceTo'
                  type='number'
                  value={state.sellingPriceTo}
                  onInputChanged={(event) => {
                    const { value } =  event.target ; 
                    if( value < 0 )
                    return ;

                    setState({ id: 'sellingPriceTo', value:  (value)});
                  }}
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath}
                  withNumberFormat
               />
         </div>
          <div className='dialog-content-item'>
                <Inputs
                  idRef='serviceChargRef'
                  labelValue='serviceCharg'
                  type='number'
                  value={state.serviceCharge}
                  onInputChanged={(event) => {

                    const { value } =  event.target ; 
                    if( value < 0 )
                    return ; 
                  
                    setState({ id: 'serviceCharge', value:  (value)});
                  }}
                  withNumberFormat
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath} 
               />
           </div>
         <div className='dialog-content-item'>
                <Inputs
                  idRef='parkingNoRef'
                  labelValue='parkingNo'
                  type='number'
                  min={0}
                  max={100}
                  value={state.parkingNo}
                  onInputChanged={(event) => {
                    
                    const { value } =  event.target ; 
                    if( value < 0 ||  value > 99  )
                    return ; 
                  
                    setState({ id: 'parkingNo',  value: (value !== ''?  Math.trunc(value) :  '')});
                  }}
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath} 
               />
        </div>
        <div className='dialog-content-item'>
                <Inputs
                  idRef='maidsRoomNoRef'
                  labelValue='maidsRoomNo'
                  type='number'
                  min={0}
                  max={100}
                  value={state.maidsRoomNo}
                  onInputChanged={(event) => {
                    const { value } =  event.target ; 
                    if( value < 0 ||  value > 99 )
                    return ; 
                    // const  numberRegex = /^\d+$/;
                    // const maidsRoomNoError =  !(numberRegex.test(event.target.value)) ;
                    //  setError((item) => ({ ...item, maidsRoomNo  : maidsRoomNoError})); 

                    setState({ id: 'maidsRoomNo', value: (value !== ''?  Math.trunc(value) :  '')});
                  }}
                  // error={error.maidsRoomNo}
                  // isSubmitted={isSubmitted}
                  // helperText={t(`${translationPath}Choose-correct-number`)}
                  // isWithError
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath}
               />
         </div>
         <div className='dialog-content-item'>
                <Inputs
                  idRef='storeRoomNoRef'
                  labelValue='storeRoomNo'
                  value={state.storeRoomNo}
                  type='number'
                  min={0}
                  max={100}
                  onInputChanged={(event) => {
                    const { value } =  event.target ; 
                    if( value < 0  ||  value > 99)
                    return ; 
                    // const  numberRegex = /^\d+$/;
                    // const storeRoomNoError =  !(numberRegex.test(event.target.value)) ;
                    //  setError((item) => ({ ...item, storeRoomNo  : storeRoomNoError })); 

                    setState({ id: 'storeRoomNo', value:(value !== ''?  Math.trunc(value) :  '' )});
                  }}
                  // isSubmitted={isSubmitted}
                  // helperText={t(`${translationPath}Choose-correct-number`)}
                  // isWithError
                  // error={error.storeRoomNo}
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath}
               />
         </div>
     </>
  );
};
