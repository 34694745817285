import { config } from '../config/config';

const freshdeskWSaddress = `https://widget.freshworks.com/widgets/${config.freshdesk_ws_id}.js`;
const FunctionWebsiteFreshdeskWs = `window.fwSettings = {widget_id: ${config.freshdesk_ws_id},};!(function () {if ('function' != typeof window.FreshworksWidget) {var n = function () {n.q.push(arguments);};(n.q = []), (window.FreshworksWidget = n);}})();`;
export const addScript = (src, type, id) => {
  const script = document.createElement('script');
  script.setAttribute('src', src);
  script.setAttribute('type', type);
  script.setAttribute('id', id);
  document.body.appendChild(script);
};

export const FreshdeskHelper = () => {
  addScript(freshdeskWSaddress, 'text/javascript', 'main');
  const scriptWebsite = document.createElement('script');
  scriptWebsite.innerHTML = FunctionWebsiteFreshdeskWs;
  scriptWebsite.setAttribute('id', 'windowfwSettings');
  document.body.appendChild(scriptWebsite);
};

export const removeScript = (id) => {
  const script = document.getElementById(id);
  if (script !== null)
    script.remove();
};

export const FreshdeskHelperRemve = () => {
  removeScript('main');
  removeScript('windowfwSettings');
  removeScript('freshworks-container');
  removeScript('freshworks-frame');
};
// Refactoring Done 2023
