import { getProperties } from '../Services';

let oldvalue = '';
let timer = null;

export async function PropertyRule(item, value, setNewValue) {
  if (oldvalue === value) return;
  oldvalue = value;
  if (!item.data.searchKey) return;
  if (item.data.searchKey !== 'property') return;
  if (value === '') return;
  if (timer !== null) clearTimeout(timer);

  if (value.length < 1) return;

  timer = setTimeout(async () => {
    const rs = await getProperties({ pageIndex: 0, pageSize: 10, search: value });
    item.data.enum = [];
    if ((rs && rs.data && rs.data.ErrorId) || !rs || !rs.result) return;
    rs.result.map((valueLoop) => {
      item.data.enum.push({
        id: valueLoop.propertyId,
        name: valueLoop.property.property_name,
        property_plan: valueLoop && valueLoop.property && valueLoop.property.property_plan && valueLoop.property.property_plan.lookupItemName || undefined,
        city: (valueLoop.property.city && valueLoop.property.city.lookupItemName) || '',
        community: (valueLoop.property && valueLoop.property.community),
        subCommunity : (valueLoop && valueLoop.property && valueLoop.property.sub_community)
      });
    });

    setNewValue(Math.random());
  }, 500);
}

export async function PropertyDefaultRule(item, setNewValue) {
  if (item.data.searchKey !== 'property') return;
  if (item.data.enum) return;

  const rs = await getProperties({ pageIndex: 0, pageSize: 10 });
  

  item.data.enum = [];
  if (!rs || !rs.result) return;
  rs.result.map((value) => {
    item.data.enum.push(
      {
        id: value.propertyId,
        name: value.property.property_name,
        city: (value.property.city && value.property.city.lookupItemName) || '',
        community: (value.property && value.property.community),
        subCommunity: (value && value.property && value.property.sub_community),
      }
);
  });

  setNewValue(Math.random());
}

export async function OnAddnewPropertyRule(
  item,
  itemList,
  setData,
  itemsDialogValue,
) {
  //  This is function When User Add New Property //
  let fieldkey;
  if (item && item.field.id === 'property_name')
    fieldkey = 'property_name';

  if (itemsDialogValue && itemsDialogValue.propertyId !== null) {
    const i1Index = itemList.findIndex((f) => f.field.id.toLowerCase() === fieldkey);
    const FinalJSON = JSON.parse(itemsDialogValue.propertyJson);
    if (i1Index && itemsDialogValue && itemsDialogValue.propertyId) {
      setData(i1Index, {
        id: itemsDialogValue && itemsDialogValue.propertyId,
        name: FinalJSON && FinalJSON.property && FinalJSON.property.property_name,
        city: FinalJSON && FinalJSON.property && FinalJSON.property.city.lookupItemName,

      });
    }
  }
}


export async function PropertyOwnerDependingOnRule(item, value, itemList, setNewValue , setData)
{
  const propertyOwnerIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'property_owner'));
  timer = setTimeout(async () => {
    if (propertyOwnerIndex !== -1) {
      if (value && (value.lookupItemName === 'Off-Plan Primary' || value.lookupItemName === 'Off-Plan'))
      {
        itemList[propertyOwnerIndex].field.Required = 'true';
        itemList[propertyOwnerIndex].data.title = 'Property Owner *';
        setData(itemList[propertyOwnerIndex].field.id, null);
      } else if((value && (value.lookupItemName !== 'Off-Plan Primary' || value.lookupItemName !== 'Off-Plan')))
      {
        itemList[propertyOwnerIndex].field.Required = 'false';
        itemList[propertyOwnerIndex].data.title = 'Property Owner';
        setData(itemList[propertyOwnerIndex].field.id, null);

      }
      else if(!value)
      {
        itemList[propertyOwnerIndex].field.Required = 'false';
        itemList[propertyOwnerIndex].data.title = 'Property Owner';
        setData(itemList[propertyOwnerIndex].field.id, null);
      }
      
      setNewValue(Math.random());
    }
  }, 200);
  
}


export async function PropertyOwnerDependingOnRule2(value, itemList)
{
  const propertyOwnerIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'property_owner'));
    if (propertyOwnerIndex !== -1) {
      if (value && (value.lookupItemName === 'Off-Plan Primary' || value.lookupItemName === 'Off-Plan'))
      {
        itemList[propertyOwnerIndex].field.Required = 'true';
        itemList[propertyOwnerIndex].data.title = 'Property Owner *';
      } else if((value && (value.lookupItemName !== 'Off-Plan Primary' || value.lookupItemName !== 'Off-Plan')) || !value)
      {
        itemList[propertyOwnerIndex].field.Required = 'false';
        itemList[propertyOwnerIndex].data.title = 'Property Owner';
      }
    }
  
}

export async function PropertyOwnerRule(value, itemList , formType , setDate)
{
  
    if ( formType === 1 ) 
      setDate('master_developer' , (value ? value : {}));
 
   else  if ( formType === 2 ) 
      setDate(value ,0 , 'master_developer');
     
}

