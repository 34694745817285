import i18next from "i18next";
import React from "react";
export const headerData = [
  {
    id: 1,
    label: 'unit-type',
    input: 'unitType',
  },
  {
    id: 2,
    label: 'avg-area',
    input: 'size',
  },
  {
    id: 3,
    label: 'numberOfBedrooms',
    input: 'numberOfBedrooms',
    isNumber: true,
  },
  {
    id: 4,
    label: 'numberOfBathrooms',
    input: 'numberOfBathrooms',
    isNumber: true,
  },
  {
    id: 5,
    label: 'maid-room',
    input: 'maidRoom',
  },
  {
    id: 6,
    label: 'agency-name',
    input: 'agency',
  },
  {
    id: 9,
    label: 'avg-market-price',
    input: 'avgMarketPrice',
  },
  {
    id: 10,
    label: 'advertisement-link',
    input: 'advertisementLink',
    component: (row) => (
      <a href={row.advertisementLink} target='_blank'>
        {i18next.t('Visit', { ns: 'UnitsProfileManagementView' })}
      </a>
    ),
  },
];
