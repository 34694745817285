export const ActivityTypePermissions = {
  ViewActivityTypes: {
    permissionsId: '3872af4f-2246-4417-72e1-08d96894ec62',
    permissionsName: 'View Activity Types',
    componentsId: null,
    components: null
  },
  EditActivityType:
  {
    permissionsId: '80ec4a31-e5e1-40fe-72e2-08d96894ec62',
    permissionsName: 'Edit Activity Type',
    componentsId: null,
    components: null
  },
  AddNewActivityType:
  {
    permissionsId: 'c2978939-ec4f-471f-72e3-08d96894ec62',
    permissionsName: 'Add New Activity Type',
    componentsId: null,
    components: null
  },
  DeleteActivityType:
  {
    permissionsId: '00baec10-9428-4c40-72e4-08d96894ec62',
    permissionsName: 'delete Activity Type',
    componentsId: null,
    components: null
  },
  ArchivedActivities: {
    permissionsId: "f268f64e-b0b2-4be8-7937-08dcaf947ea8",
    permissionsName: "Archived activities",
    description: null,
    componentsId: null,
    components: null
},
UnarchivedActivities :{
    permissionsId: "d2875eb3-bae0-45d0-7938-08dcaf947ea8",
    permissionsName: "Unarchived activities",
    description: null,
    componentsId: null,
    components: null
},
ViewArchivedActivities:{
  permissionsId: "18053aee-28c7-4fa4-7936-08dcaf947ea8",
  permissionsName: " View Archived Activities",
  description: null,
  componentsId: null,
  components: null
},

};
