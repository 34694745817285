import { config } from '../config/config';
import { HttpServices } from '../Helper';

const TokenMicrosoftAccount = async () => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/ExternalApis/GetToken`,

    );
    return result;
  } catch (e) {
    return undefined;
  }
};
const TokenReport = async (reportid, groupid) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/Identity/Account/GetPowerPiToken?reports=${reportid}&groups=${groupid}`,

    );
    return result;
  } catch (e) {
    return undefined;
  }
};

const RefreshTokenPbi = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/Identity/Account/RefreshTokenPowerBi`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const MicrosoftToken = async (isExpired) => {
  const result = await HttpServices.get(`${config.server_address}/Identity/Account/GenerateMicrosoftToken?isExpired=${isExpired}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UsersIdsForPolicy = async (UserId) => {
  const result = await HttpServices.get(`${config.server_address}/CrmDfm/Policies/GetAllUsersIdsForPolicy?userId=${UserId}&mainCategory=leads`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const UsersIdsForPolicyUnits = async (UserId) => {
  const result = await HttpServices.get(`${config.server_address}/CrmDfm/Policies/GetAllUsersIdsForPolicy?userId=${UserId}&mainCategory=units`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetBranchInfoById = async (branchId) => {
  const result = await HttpServices.get(`${config.server_address}/Identity/Branch/GetBranchInfoById/${branchId}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};


const GetUserManagedById = async (UserId) => {
  const result = await HttpServices.get(`${config.server_address}/CrmDfm/Team/GetUserManagedTeamsInfo?userId=${UserId}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};




export {
  GetBranchInfoById,
  TokenMicrosoftAccount,
  TokenReport,
  RefreshTokenPbi,
  MicrosoftToken,
  UsersIdsForPolicy,
  UsersIdsForPolicyUnits,
  GetUserManagedById,
};
