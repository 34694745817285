

import React, { useState ,  useEffect , useReducer  } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Joi from 'joi';
import {
  DialogActions, DialogContent, DialogTitle, Dialog
} from '@material-ui/core';
import {
  Spinner, 
} from '../../../../../Components';
import { showSuccess ,  showError } from '../../../../../Helper' ; 
import { CreatePortalAPI  , UpdatePortalAPI    } from '../../../../../Services' ; 
import { PortalConfigurationContentDialog  } from '../PortalConfigurationManagement/PortalConfigurationManagementDialog/PortalConfigurationContentDialog' ; 
import { PortalConfigurationFooterDialog  } from '../PortalConfigurationManagement/PortalConfigurationManagementDialog/PortalConfigurationFooterDialog' ; 




export const PortalConfigurationManagementDialog = ({
   parentTranslationPath ,
   translationPath ,
   isOpen ,
   onClose ,
   onSave  , 
   editPortalConfiguration ,  
   isLoading ,   
  }) => {

  const { t } = useTranslation(parentTranslationPath);

  const [isSubmitted , setIsSubmitted] = useState(false) ;
  
  const [saveIsLoading, setSaveIsLoading] = useState(false) ; 

  const reducer = (state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    if (action.id === 'edit') {
      return {
        ...action.value,
      };
    }
    return undefined;
  }

  const [state, setState] = useReducer(reducer,
  {
      portalName : null , 
      apiUrl : null , 
      apiKey : null ,
      operationType : null   , 
      portalConfigrationDto : null , 
  
   });

   const [selected , setSelected] = useState({
    operationType : null 
   })


  const schema = Joi.object({
    portalName: Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}portal-name-is-required`),
        'string.empty': t(`${translationPath}portal-name-is-required`),
      }),
    portalConfigrationDto: Joi.object()
      .required()
      .messages({
        'object.base': t(`${translationPath}portal-is-required`),
        'object.empty': t(`${translationPath}portal-is-required`),
      }),
      operationType : Joi.object()
       .required()
       .messages({
         'object.base': t(`${translationPath}operationType-is-required`),
         'object.empty': t(`${translationPath}operationType-is-required`),

       }),
       
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);


  const saveHandler = async () => {
    setIsSubmitted(true);
    setSaveIsLoading(true) ; 
    if(schema.error)
    {
      showError(t('please-fill-all-Required-field'));
      setSaveIsLoading(false) ; 
      return ; 
    }
   
    const res =  !editPortalConfiguration ?  await CreatePortalAPI(state) :
                await UpdatePortalAPI( state.portalId ,state);
    if (!(res && res.status && res.status !== 200)) {
      if(!editPortalConfiguration )
      showSuccess(t(`${translationPath}add-portal-configuration-successfully`));
      else 
      showSuccess(t(`${translationPath}edit-portal-configuration-successfully`));
      onSave() ;
    } 
    else 
    {
      if(!editPortalConfiguration)
         showError(t(`${translationPath}add-portal-configuration-failed`));
       else
         showError(t(`${translationPath}edit-portal-configuration-failed`));


    }
    setSaveIsLoading(false) ; 
  };


  useEffect(() => {
    if (editPortalConfiguration === undefined || editPortalConfiguration === null) return;
    let  currntState  = null 
    if(editPortalConfiguration)
    {
       currntState = {
        portalId  : editPortalConfiguration.portalId , 
         apiUrl	: editPortalConfiguration.apiUrl , 
         apiKey	:editPortalConfiguration.apiKey , 
         operationType :editPortalConfiguration.operationType , 
         portalName : editPortalConfiguration.portalName   , 
         portalConfigrationDto  : editPortalConfiguration.portalConfigrationDto,
        }
    }
     setState({ id: 'edit', value: currntState });
  }, [editPortalConfiguration]);




  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => {
          onClose();
        }}
        className='dialog-wrapper'
        maxWidth='sm' 
        disableBackdropClick
      >
      <Spinner isActive={((isLoading && isLoading.portalConfiguration) || saveIsLoading )} isAbsolute />
          <DialogTitle id='alert-dialog-slide-title'>
            <span>
              {!editPortalConfiguration ?
                t(`${translationPath}add-portal-configuration`) :
                t(`${translationPath}edit-portal-configuration`)}
            </span>
          </DialogTitle>
          <DialogContent>
             <PortalConfigurationContentDialog
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              state={state} 
              setState={setState}
              schema={schema}
              isSubmitted={isSubmitted}
              editPortalConfiguration={editPortalConfiguration}
            /> 
          </DialogContent>
          <DialogActions>
            <PortalConfigurationFooterDialog
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onClose={onClose}
              onSave ={saveHandler}
            />  
          </DialogActions>
      </Dialog>
    </div>
  );

};

PortalConfigurationManagementDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  editPortalConfiguration: PropTypes.instanceOf(Object),
  isLoading : PropTypes.instanceOf(Object), 
};
PortalConfigurationManagementDialog.defaultProps = {
  editPortalConfiguration: undefined ,
  isLoading : undefined , 

};

