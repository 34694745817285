export const CustomFiledsClientsSegmentation = [
  {
    formFieldId: 1,
    formFieldName: 'FullName',
    formFieldKey: 'FullName',
    searchableKey: 'FullName',
    displayPath: 'FullName',
    uiWidgetType: null,
    formFieldTitle: 'Full Name',
    formsId: 1,
  },
  {
    formFieldId: 2,
    formFieldName: 'Email',
    formFieldKey: 'Email',
    searchableKey: 'Email',
    displayPath: 'Email',
    uiWidgetType: null,
    formFieldTitle: 'Email',
    formsId: 1,
  },
  {
    formFieldId: 3,
    formFieldName: 'Mobile',
    formFieldKey: 'Mobile',
    searchableKey: 'mobile',
    displayPath: 'mobile.phone',
    uiWidgetType: null,
    formFieldTitle: 'Mobile',
    formsId: 1,
  },
  {
    formFieldId: 4,
    formFieldName: 'createdOn',
    formFieldKey: 'createdOn',
    displayPath: 'createdOn',
    uiWidgetType: 'alt-date',
    formFieldTitle: 'Created On',
    formsId: 1,
    isRequired: false,
    searchableKey: 'createdOn',
    searchable: false,
    isNumber: false
  },
  {
    formFieldId: 5,
    formFieldName: 'createdBy',
    formFieldKey: 'CreatedByName',
    searchableKey: 'CreatedByName ',
    displayPath: 'CreatedByName',
    uiWidgetType: 'searchField',
    formFieldTitle: 'Created By',
    formsId: 1,
    isRequired: false,
    isSearchable: true
  },
];
