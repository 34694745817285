export const MovingMonthEnum = [
  {
    key: 1,
    value: 'Moving In 1 Month',
  },
  {
    key: 2,
    value: 'Moving In 3 Month',
  },
  {
    key: 3,
    value: 'Moving In 6 Month',
  },
  {
    key: 4,
    value: 'Moving In 1 Year',
  },
];
