/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState, useEffect, useCallback, useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  AutocompleteComponent, CheckboxesComponent, Inputs, SelectComponet
} from '../../../../Components';
import { GetAllRoles, SearchUsersByName } from '../../../../Services';
import { GetParams } from '../../../../Helper';

export const ReportSavingView = ({
  translationPath,
  parentTranslationPath,
  ReportCategory,
  systemReportTypeId,
  setBody,
  Settingbody,
  setSelectedData,
  selectedData,
  AcationStateIsEdit
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const defaultValues = {
    systemReportName: [],
    withLogo: false,
    isEditableByOthers: false,
    isUsersPermission: false,
    isRolesPermission: false,
    description: '',
    ownerId: null,
    privacy: 0,
    order: 0,
    systemReportTypeId,
    systemReportRoles: [],
    systemReportUsers: [],
  };
  const [UsersPermission, setAllUsersPermission] = useState(null);
  const [allUsers, setAllUsers] = useState(null);
  const searchTimer = useRef(null);
  const ActiveItemReducer = useSelector((state) => state.ActiveItemReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUsersPermission, setselectedUsersPermission] = useState([]);
  const [isLoadingUsersPermission, setisLoadingUsersPermission] = useState(false);
  const [Roles, setRoles] = useState({});
  const [loadingsRoles, setloadingsRoles] = useState(false);
  const [selectedRoles, setselectedRoles] = useState([]);
  const [state, setState] = useState(defaultValues);
  const [selected, setSelected] = useState({
    Owner: null,
    Users: null,
    Roles: null,
  });

  const getAllUsers = useCallback(async (name) => {
    setIsLoading(true);
    const res = await SearchUsersByName(name || '', 0, 10);
    if (!(res && res.status && res.status !== 200)) {
      setAllUsers({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setAllUsers({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, []);

  const getAllUserAllUsersPermission = useCallback(async (name) => {
    setisLoadingUsersPermission(true);
    const res = await SearchUsersByName(
      name || '',
      0,
      10,
    );

    if (!(res && res.status && res.status !== 200)) {
      setAllUsersPermission({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setAllUsersPermission({
        result: [],
        totalCount: 0,
      });
    }
    setisLoadingUsersPermission(false);
  }, []);
  const getRoles = useCallback(async (value) => {
    setloadingsRoles(true);
    const response = await GetAllRoles(1, 25, value);
    setRoles({
      result: (response && response.result) || [],
      totalCount: (response && response.totalCount) || 0,
    });
    setloadingsRoles(false);
  }, []);

  useEffect(() => {
    if (state.isUsersPermission === true)
      getAllUserAllUsersPermission();
    else if (state.isUsersPermission === false) {
      setState({ ...state, systemReportUsers: [] });
      setselectedUsersPermission([]);
      setSelected({ ...selected, Users: [] });
    }
  }, [state.isUsersPermission]);

  useEffect(() => {
    if (state.isRolesPermission === true)
      getRoles();
    else if (state.isRolesPermission === false) {
      setState({ ...state, systemReportRoles: [] });
      setselectedRoles([]);
      setSelected({ ...selected, Roles: [] });
    }
  }, [state.isRolesPermission]);

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    setBody(state);
  }, [state]);

  useEffect(() => {
    setState(Settingbody);
  }, []);

  useEffect(() => {
    setSelected(selectedData);
    setselectedRoles(
      (selectedData &&
        selectedData && selectedData.Roles) || []
    );
    setselectedUsersPermission(
      (selectedData &&
        selectedData && selectedData.Users) || []
    );
  }, []);

  useEffect(() => {
    setSelectedData(selected);
  }, [selected]);

  useEffect(() => {
    if (state && state.privacy === 0) {
      setState((item) => ({
        ...item,
        systemReportRoles: [],
        systemReportUsers: [],
        isUsersPermission: false,
        isRolesPermission: false,
      }));
      setselectedRoles([]);
      setselectedUsersPermission([]);
    }
  }, [state.privacy]);
  useEffect(() => {
    if (AcationStateIsEdit) {
      setState({
        ...state,
        systemReportName: ActiveItemReducer.systemReportName,
        withLogo: ActiveItemReducer.withLogo,
        isEditableByOthers: ActiveItemReducer.isEditableByOthers,
        isUsersPermission: ActiveItemReducer.isUsersPermission,
        isRolesPermission: ActiveItemReducer.isRolesPermission,
        description: ActiveItemReducer.description,
        privacy: ActiveItemReducer.privacy,
        order: ActiveItemReducer.order,
        systemReportUsers: ActiveItemReducer.systemReportUsers,
        ownerId: ActiveItemReducer.ownerId,
        systemReportTypeId: +GetParams('id'),
        systemReportRoles: ActiveItemReducer.systemReportRoles

      });
      setselectedUsersPermission(
        ActiveItemReducer && ActiveItemReducer &&
        ActiveItemReducer.systemReportUsers.map((x) => ({
          applicationUserId: x.userId,
          userName: x.user,
        }))
      );
      setselectedRoles(
        ActiveItemReducer && ActiveItemReducer &&
        ActiveItemReducer.systemReportRoles.map((x) => ({
          rolesId: x.roleId,
          rolesName: x.role,
        }))
      );
      setSelected({
        ...selected,
        Users: ActiveItemReducer && ActiveItemReducer &&
          ActiveItemReducer.systemReportUsers.map((x) => ({
            applicationUserId: x.userId,
            userName: x.user,
          })),
        Roles: ActiveItemReducer && ActiveItemReducer &&
          ActiveItemReducer.systemReportRoles.map((x) => ({
            rolesId: x.roleId,
            rolesName: x.role,
          })),
        Owner:
          { userName: ActiveItemReducer.owner, applicationUserId: ActiveItemReducer.ownerId }

      });
    }
  }, [AcationStateIsEdit]);
  return (
    <div className='ReportSettingsView-view-wrapers'>
      <div className='main-view-wrapers'>
        <div className='d-flex w-100 '>
          <div className='px-2 w-100'>
            <Inputs
              idRef='NamentRef'
              labelValue='Report-Name'
              labelClasses='Requierd-Color'
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              value={state.systemReportName}
              onInputChanged={(event) =>
                setState({ ...state, systemReportName: event.target.value })}
            />
          </div>
          <div className='px-2  w-100'>
            <Inputs
              idRef='orderentRef'
              labelValue='order'
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              value={+state.order}
              withNumberPersantageFormat
              type='number'
              onInputChanged={(event) =>
                setState({ ...state, order: +event.target.value })}
            />

          </div>
          <div className='px-2  w-100'>
            <Inputs
              idRef='Report9TypeRef'
              labelValue='Report-Type'
              disabled
              inputPlaceholder={t(`${translationPath}Report-Type`)}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              value={ReportCategory || ''}
            />
          </div>
        </div>
        <div className=''>
          <Inputs
            idRef='DescriptionmentRef'
            labelValue='Description'
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            value={state.description}
            multiline
            rows={6}
            onInputChanged={(event) =>
              setState({ ...state, description: event.target.value })}
          />
        </div>
        <div className=''>
          <div className='sc-opation'>
            <div className='px-2  w-100'>
              <SelectComponet
                idRef='remdsssWayRef'
                data={[
                  { valueId: 0, value: t(`${translationPath}Public`) },
                  { valueId: 1, value: t(`${translationPath}Private`) },
                ] || []}
                value={state.privacy}
                labelValue={t(`${translationPath}Report-Folder`)}
                valueInput='valueId'
                textInput='value'
                onSelectChanged={(value) => {
                  setState((item) => ({ ...item, privacy: value }));
                }}
                translationPathForData={translationPath}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            </div>
            <div className='px-2  w-100'>
              <AutocompleteComponent
                idRef='Report-OwnerRef'
                labelValue='Report-Owner'
                labelClasses='Requierd-Color'
                multiple={false}
                data={(allUsers && allUsers.result) || []}
                chipsLabel={(option) => option.userName || ''}
                displayLabel={(option) => option.userName || ''}
                isLoading={isLoading}
                withoutSearchButton
                selectedValues={selected.Owner || []}
                // eslint-disable-next-line max-len
                getOptionSelected={(option) => option.applicationUserId === (selected.Owner && selected.Owner.ownerId) || null}
                inputPlaceholder={t(`${translationPath}Report-Owner`)}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputKeyUp={(e) => {
                  const newValue = e.target.value;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getAllUsers(newValue);
                  }, 700);
                }}
                onChange={(event, newValue) => {
                  // eslint-disable-next-line max-len
                  setSelected((items) => ({ ...items, Owner: { userName: newValue && newValue.userName, ownerId: newValue && newValue.applicationUserId } }));
                  // eslint-disable-next-line max-len
                  setState((item) => ({ ...item, ownerId: (newValue && newValue.applicationUserId) }));
                }}
              />
            </div>
          </div>
          <div className='check-box-wraper-repert'>
            {((state && state.privacy === 1) && (
              <>
                <CheckboxesComponent
                  idRef='RotationRef'
                  label={t(`${translationPath}WithRolesPermission`)}
                  themeClass='theme-secondary'
                  singleChecked={state.isRolesPermission}
                  onSelectedCheckboxClicked={() =>
                    setState({ ...state, isRolesPermission: !state.isRolesPermission })}
                />
                <CheckboxesComponent
                  idRef='RotationRef'
                  label={t(`${translationPath}WithUsersPermission`)}
                  themeClass='theme-secondary'
                  singleChecked={state.isUsersPermission}
                  onSelectedCheckboxClicked={() =>
                    setState({ ...state, isUsersPermission: !state.isUsersPermission })}
                />
              </>
            )
            ) || ''}
            <CheckboxesComponent
              idRef='RotationRef'
              label={t(`${translationPath}Allow-others-to-edit`)}
              themeClass='theme-secondary'
              singleChecked={state.isEditableByOthers}
              onSelectedCheckboxClicked={() =>
                setState({ ...state, isEditableByOthers: !state.isEditableByOthers })}
            />
            <CheckboxesComponent
              idRef='RotationRef'
              label={t(`${translationPath}Include-Logo`)}
              themeClass='theme-secondary'
              singleChecked={state.withLogo}
              onSelectedCheckboxClicked={() =>
                setState({ ...state, withLogo: !state.withLogo })}
            />
            {/*
            <CheckboxesComponent
              idRef='RotationRef'
              label={t(`${translationPath}Summary-Report`)}
              themeClass='theme-secondary'
              singleChecked={state.SummaryReport}
              onSelectedCheckboxClicked={() =>
                setState({ ...state, SummaryReport: !state.SummaryReport })}
            /> */}
          </div>
          <hr />
          <div className='sc-opation'>
            {state.isUsersPermission && (
              <div className='px-2  w-100'>
                <AutocompleteComponent
                  idRef='referredToRef'
                  data={(UsersPermission && UsersPermission.result) || []}
                  chipsLabel={(option) => option.userName || ''}
                  displayLabel={(option) => option.userName || ''}
                  isLoading={isLoadingUsersPermission}
                  withoutSearchButton
                  selectedValues={selectedUsersPermission || []}
                  multiple
                  getOptionSelected={(option) => selectedUsersPermission.findIndex((item) => item.applicationUserId === option.applicationUserId) !== -1 || ''}
                  inputPlaceholder={t(`${translationPath}systemReportUsers`)}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputKeyUp={(e) => {
                    const newValue = e.target.value;
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      getAllUserAllUsersPermission(newValue);
                    }, 700);
                  }}
                  onChange={(event, newValue) => {
                    setSelected({
                      ...selected,
                      Users: newValue &&
                        newValue.map((x) => ({
                          applicationUserId: x.applicationUserId,
                          userName: x.userName,
                        }))
                    });
                    setselectedUsersPermission(
                      newValue &&
                      newValue.map((x) => ({
                        applicationUserId: x.applicationUserId,
                        userName: x.userName,
                      }))
                    );
                    setState({
                      ...state,
                      systemReportUsers: newValue &&
                        newValue.map((x) => ({
                          userId: x.applicationUserId,
                        }))
                    });
                  }}
                />
                {' '}
              </div>
            )}
            {state.isRolesPermission && (
              <div className='px-2  w-100'>
                <AutocompleteComponent
                  idRef='rolesNameRef'
                  data={(Roles && Roles.result) || []}
                  chipsLabel={(option) => option.rolesName || ''}
                  displayLabel={(option) => option.rolesName || ''}
                  isLoading={loadingsRoles}
                  withoutSearchButton
                  selectedValues={selectedRoles || []}
                  multiple
                  getOptionSelected={(option) =>
                    selectedRoles.findIndex((item) => item.rolesId === option.rolesId) !== -1 || ''}
                  inputPlaceholder={t(`${translationPath}systemReportroles`)}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputKeyUp={(e) => {
                    const newValue = e.target.value;
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      getRoles(newValue);
                    }, 700);
                  }}
                  onChange={(event, newValue) => {
                    setState({
                      ...state,
                      systemReportRoles: newValue &&
                        newValue.map((x) => ({
                          roleId: x.rolesId,
                        }))
                    });
                    setSelected({
                      ...selected,
                      Roles: newValue &&
                        newValue.map((x) => ({
                          rolesId: x.rolesId,
                          rolesName: x.rolesName,
                        }))
                    });

                    setselectedRoles(
                      newValue &&
                      newValue.map((x) => ({
                        rolesId: x.rolesId,
                        rolesName: x.rolesName,
                      }))
                    );
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
ReportSavingView.propTypes = {
  translationPath: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  AcationStateIsEdit: PropTypes.bool,
  ReportCategory: PropTypes.string,
  setBody: PropTypes.func,
  setSelectedData: PropTypes.func,
  selectedData: PropTypes.instanceOf(Array),
  systemReportTypeId: PropTypes.number,
  Settingbody: PropTypes.instanceOf(Array),
};

ReportSavingView.defaultProps = {
  translationPath: '',
  parentTranslationPath: '',
  selectedData: [],
  Settingbody: [],
  AcationStateIsEdit: false,
  ReportCategory: '',
  setBody: () => { },
  setSelectedData: () => { },
  systemReportTypeId: 0
};
