import React, { useCallback , useState , useEffect   } from "react";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { ViewTypes } from "../../../../Components";
import { ViewTypesEnum  , ActionsButtonsEnum , SearchCriteriaEnum  } from "../../../../Enums";
import { AdvanceSearchSecondVersion } from "../../../../SharedComponents/AdvanceSearchSecondVersion";
import { DevelopersTableHeaderData } from "../DevelopersUtilities";
import { GlobalAdvanceSearchActions } from "../../../../store/GlobalAdvanceSearch/GlobalAdvanceSearchActions";
import { GlobalOrderFilterActions } from '../../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import { generateUniqueKey, sideMenuComponentUpdate  , sideMenuIsOpenUpdate } from "../../../../Helper";

export const DevelopersAdvanceSearch = ({
  translationPath,
  parentTranslationPath,
  advanceSearch,
  setActiveActionType,
  activeActionType,
  onFilterValuesChanged,
  filterValuesFromAdvanceSearch,
  setOrderBy,
  formId,
  setFilterSearchDto,
  setDevelopersTableFilter,
  setSelectedOrderBy , 
  setIsAdvanceSearchActive ,
  isAdvanceSearchActive  ,
  setFilterValuesFromAdvanceSearch , 
  setActiveFormType , 
  orderFilter ,
  setActionButtonsKey ,
  setIsClearFiltersClicked ,
  dateRangeDefault ,
  setSearchData ,
  setSearchedItem , 
  setDateFilter , 
  activeSelectedAction , 
  closeTypeDialogHandler ,
  allFormFields , 
  isAllFormFieldsLoading ,
  selectFieldsOnAdvanceSearch ,
  setSelectFieldsOnAdvanceSearch ,
  
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(parentTranslationPath);
  const [advanceSearchBtn, setAdvanceSearchBtn] = useState(true);
  const [isAdvanceSearchCleared, setIsAdvanceSearchCleared] = useState(false);

  

  const onTypeChanged = useCallback(
    (activeType) => {
      let ViewTypeData = JSON.parse(localStorage.getItem("ViewType"));

      if (ViewTypeData) {
        ViewTypeData.developers = activeType;
        localStorage.setItem("ViewType", JSON.stringify(ViewTypeData));
      } else
        localStorage.setItem(
          "ViewType",
          JSON.stringify({ developers: activeType })
        );

      setActiveActionType(activeType);
    },
    [setActiveActionType]
  );

  const searchAdvanceSearch = (newFilter) => {
    const searchObj = (newFilter && { ...newFilter }) || null;
    setDevelopersTableFilter(searchObj);
    if (!(searchObj && searchObj.length)) {
      localStorage.removeItem("DevelopersFilter");
    }
    dispatch(
      GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
        ...advanceSearch,
        DevelopersFilter: searchObj || null,
      })
    );
  };

  const changeActiveFormType = (value) => {
    if (value === 0 && activeSelectedAction === ActionsButtonsEnum[4].id) {
      closeTypeDialogHandler();
    }
    setActiveFormType(value);
    setSearchedItem("");
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        activeFormType: value,
      })
    );
  };

  const onClearedAllFiltersClick = useCallback(() => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: null, orderBy: null }));
    setOrderBy({ filterBy: "createdOn", orderBy: 2 });
    setSearchData([]);
    setDateFilter(dateRangeDefault);
    changeActiveFormType(0);
    setActiveFormType(0);
    setSearchedItem("");
    setIsClearFiltersClicked(true);
    setIsAdvanceSearchCleared(true);
    setDevelopersTableFilter(null);
    setFilterSearchDto(null);
    sideMenuIsOpenUpdate(false);
    setIsAdvanceSearchActive(false);
    setFilterValuesFromAdvanceSearch([]);
    setSelectFieldsOnAdvanceSearch([]);
    setActionButtonsKey(generateUniqueKey());
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        activeFormType: 0,
        DevelopersFilter: {
          filterBy: null,
          orderBy: null,
        },
      })
    );
    dispatch(
      GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
        ...advanceSearch,
        DevelopersFilter: null,
      })
    );
  }, []);


  const onAdvanceSearchClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      if (advanceSearchBtn) {
        sideMenuIsOpenUpdate(true);
        setAdvanceSearchBtn(false);
      } else {
        sideMenuIsOpenUpdate(false);
        setAdvanceSearchBtn(true);
      }
      sideMenuComponentUpdate(
        <AdvanceSearchSecondVersion
          filterData={[...DevelopersTableHeaderData, ...allFormFields]}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onFilterValuesChanged={onFilterValuesChanged}
          localStorageKey="DevelopersFilter"
          isAdvanceSearchCleared={isAdvanceSearchCleared}
          setIsAdvanceSearchCleared={setIsAdvanceSearchCleared}
          setOrderBy={setOrderBy}
          searchCriteriaTypeId={SearchCriteriaEnum.Contact.typeId}
          setIsAdvanceSearchActive={setIsAdvanceSearchActive}
          fields={[...DevelopersTableHeaderData, ...allFormFields]}
          selectFieldsOnAdvanceSearch={selectFieldsOnAdvanceSearch}
          setSelectFieldsOnAdvanceSearch={setSelectFieldsOnAdvanceSearch}
          setFilterValuesFromAdvanceSearch={setFilterValuesFromAdvanceSearch}
          filterValuesFromAdvanceSearch={filterValuesFromAdvanceSearch}
          searchAdvanceSearch={searchAdvanceSearch}
        />
      );
    },
    [
      isAllFormFieldsLoading,
      allFormFields,
      advanceSearchBtn,
      isAdvanceSearchCleared,
      selectFieldsOnAdvanceSearch,
    ]
  );


  return (
    <div className="view-search-wrapper">
      <ViewTypes
        onTypeChanged={onTypeChanged}
        initialActiveType={activeActionType}
        activeTypes={[ViewTypesEnum.tableView.key, ViewTypesEnum.cards.key]}
        className="mb-3"
      />
      <span className="advance-search">
        <ButtonBase
          onClick={onAdvanceSearchClick}
          id="AdvanceSearch_bbt"
          disabled={isAllFormFieldsLoading}
          className={`btns theme-solid ${
            isAdvanceSearchActive &&
            !isAllFormFieldsLoading &&
            "is-active-filter"
          }`}
        >
          <span
            className={`${
              advanceSearchBtn
                ? "mdi mdi-arrow-expand-left"
                : "mdi mdi-arrow-expand-right"
            } mr-1`}
          />
          {t(`${translationPath}advance-search`)}
        </ButtonBase>
      </span>
      <ButtonBase
        onClick={onClearedAllFiltersClick}
        id="onClearedAllFiltersref"
        disabled={isAllFormFieldsLoading}
        className="btns theme-solid bg-danger clear-all-btn"
      >
        <span className="mdi mdi-filter-remove m-1" />
        {t(`${translationPath}clear-filters`)}
      </ButtonBase>
    </div>
  );
};
