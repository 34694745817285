export const ApprovalsConfigurationPermissions = {
  ViewPublishUnitConfiguration: {
    permissionsId: '7ee8a4af-d6c5-425f-5de0-08dc7403ebc2',
    permissionsName: 'View Publish Unit Configuration',
    description: null,
    componentsId: null,
    components: null,
  },
  ViewUnitDetailsConfiguration: {
    permissionsId: 'a95dcc44-65e4-4359-795f-08dcaf947ea8',
    permissionsName: 'View Unit Details Configuration',
    description: null,
    componentsId: null,
    components: null,
  },
};
