import React from "react";

function Calendar({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M7.695 1.057a1.04 1.04 0 0 0-.567.459c-.103.176-.109.212-.121.829l-.014.646-.626.027c-1.077.047-1.656.163-2.207.442a3.783 3.783 0 0 0-2.036 2.7C2.013 6.729 2 7.455 2 13c0 6.358.006 6.526.245 7.32.332 1.102 1.333 2.103 2.435 2.435.794.239.962.245 7.32.245 6.358 0 6.526-.006 7.32-.245 1.102-.332 2.103-1.333 2.435-2.435.239-.794.245-.962.245-7.32 0-5.545-.013-6.271-.124-6.84a3.767 3.767 0 0 0-1.589-2.435c-.714-.484-1.333-.649-2.654-.707l-.626-.027-.014-.646c-.012-.619-.017-.652-.122-.831a1.01 1.01 0 0 0-1.742 0c-.105.179-.11.212-.122.836l-.014.65H9.007l-.014-.65c-.012-.622-.018-.658-.121-.834a1.005 1.005 0 0 0-1.177-.459m-.688 4.596c.013.647.014.655.134.85a.998.998 0 0 0 1.718 0c.12-.195.121-.203.134-.85L9.007 5h5.986l.014.653c.013.647.014.655.134.85a.998.998 0 0 0 1.718 0c.12-.195.121-.202.135-.855l.013-.658.607.028c1.041.048 1.354.15 1.786.582.487.486.552.74.587 2.29L20.012 9H3.988l.025-1.11c.034-1.481.099-1.77.507-2.223.446-.498.902-.637 2.167-.661L6.993 5l.014.653m12.982 9.457c-.017 4.454-.014 4.383-.237 4.842-.126.259-.562.686-.834.816-.452.217-.291.212-6.918.212-6.617 0-6.462.005-6.918-.209-.27-.128-.705-.554-.834-.819-.223-.459-.22-.388-.237-4.842L3.995 11h16.01l-.016 4.11"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default Calendar;
