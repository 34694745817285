import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    actionsWrapper: {
      display: "flex",
      justifyContent: "flex-end",
    },
    divider: {
      backgroundColor: theme.palette.border.secondary,
      marginTop: "8px",
      marginBottom: "8px",
    },
    textArea: {
      minHeight: "75px",
      borderColor: theme.palette.border.primary,
      background: theme.palette.background.primary,
      borderRadius: "8px",
      padding: "12px 14px",
      fontSize: "16px",
      lineHeight: "24px",
    }
  };
});
