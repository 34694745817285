import React from "react";
import "./SidePopupActions.scss";
import { Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export const SidePopupActions = ({ buttonsData, translationPath, parentTranslationPath }) => {
    const { t } = useTranslation(parentTranslationPath);

    return (
        <div className="Dialog_SidePopupActions">
            {buttonsData
                ? Object.values(buttonsData).map((item) => (
                    <Tooltip title={t(`${translationPath}${item.label || ""}`)} key={item.key}>
                        <div
                            className={`SidePopupActions_Button ${item.isActive ? "active" : "primary"
                                }`}
                            onClick={() => item.onClickHandler(item, buttonsData)}
                        >
                            <span className={`mdi ${item.mdiIconClass}`}></span>
                        </div>
                    </Tooltip>
                ))
                : null}
        </div>
    );
};
SidePopupActions.propTypes = {
    buttonsData: PropTypes.object,
    parentTranslationPath: PropTypes.string,
    translationPath: PropTypes.string,
  };
  SidePopupActions.defaultProps = {
    buttonsData: {},
    parentTranslationPath: '',
    translationPath: '',
  };
  