/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import i18next from 'i18next';
import { ButtonBase, Tooltip } from '@material-ui/core';
import {
  CheckboxesComponent,
  LoadableImageComponant,
  SelectComponet,

} from '../../../../Components';
import {
  LoadableImageEnum, DefaultImagesEnum,
  UnitsOperationTypeEnum,
  UnitsStatusEnum
} from '../../../../Enums';
import { getDownloadableLink, GetParams } from '../../../../Helper';
import { formatCommasForPriceUnit } from '../../../../Helper/formatCommas.Helper';
import { CopyToClipboardComponents } from '../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';
import { GetAllTemplatesByUnitId, GetSuitableTemplateIdForUnit } from '../../../../Services';
import { UnitTemplateDialogPreviewDialog } from '../../UnitsView/UnitsProfileManagementView/Dialogs/UnitTemplateDialog/UnitTemplateDialogPreviewDialog';
import { useSelector } from 'react-redux';
import { showSuccess,showWarn } from '../../../../Helper';
export const AssignUnitCards = ({
  data,
  activeCard,
  isExpanded,
  parentTranslationPath,
  translationPath,
  notExpandedMax,
  withCheckbox,
  checkedDetailedCards,
  selectedDetailsUnitItem,
  isSale,
  unAssignUnitHandler,
  inquiryStatus,

}) => {
  const { t } = useTranslation([parentTranslationPath]);
  // const [buttonWidth, setButtonWidth] = useState('w-30');
  const [filter] = useState({
    pageIndex: 0,
    pageSize: 9999,
    formType: GetParams('formType'),
    id: +GetParams('id'),
  });
  const activeItem = useSelector((state) => state.ActiveItemReducer);
  const [templatesProposal, setTemplatesProposal] = useState([]);
  const [activeTemplateId, setActiveTemplateId] = useState(-1);
  const [templates, setTemplates] = useState([]);
  const [activeTemplateName, setActiveTemplateName] = useState(null);
  const [isOpenTemplateDialog, setIsOpenTemplateDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [unitData, setUnitData] = useState(null);
  const getDefaultUnitImage = useCallback(
    () =>
      DefaultImagesEnum.buildings.defaultImg
  );
  const GetSuitableTemplateIdForUnitApi = useCallback(async (unitId) => {
    setIsLoading(
      true
    )
    let res;
    if (isSale) {
      res = await GetSuitableTemplateIdForUnit(
        unitId,
        1,
        10000,
        UnitsOperationTypeEnum.sale.key
      );
    } else {
      res = await GetSuitableTemplateIdForUnit(
        unitId,
        1,
        10000,
        UnitsOperationTypeEnum.rent.key
      );
    }
    if (!((res && res.data && res.data.ErrorId) || !res))
      setTemplatesProposal((res && res.result) || []);
    else setTemplatesProposal([]);
    setIsLoading(false);
  }, []);

  const getAllTemplatesByCategoriesId = useCallback(async () => {
    let res
    if (isSale) {
      res = await GetAllTemplatesByUnitId(
        filter.id,
        UnitsOperationTypeEnum.sale.key
      );
    } else {
      res = await GetAllTemplatesByUnitId(
        filter.id,
        UnitsOperationTypeEnum.rent.key
      );
    }
    if (!((res && res.data && res.data.ErrorId) || !res))
      setTemplates(res || []);
    else setTemplates([]);
  }, [activeItem, filter]);
  const getIsSelectedCard = useCallback(
    (itemIndex) => checkedDetailedCards.findIndex((item) => item === itemIndex) !== -1,
    [checkedDetailedCards]
  );


  useEffect(() => {
    if (
      activeItem &&
      activeItem.unitStatusDetails &&
      activeItem.unitStatusDetails.key &&
      Object.values(UnitsStatusEnum).filter(
        (item) =>
          item.key === activeItem.unitStatusDetails.key &&
          (item.category || item.saleCategory)
      ).length > 0
    )
      getAllTemplatesByCategoriesId();
  }, [activeItem, getAllTemplatesByCategoriesId]);
  return (
    <div className='units-cards-wrapper childs-wrapper'>
      {data.result &&
        data.result.map((item, index) => (
          <div
            className={`units-card-wrapper${isExpanded ? ' is-expanded' : ''}${(((activeCard && activeCard.id === item.id) ||
              (selectedDetailsUnitItem && selectedDetailsUnitItem.id === item.id)) &&
              ' is-open') ||
              ''
              }`}
            key={`unitsCardItemRef${index + 1}`}
          >
            {item && item.isPublishUnitSale && (
              <Tooltip title={(item.lastSalePublishDate && moment(item.lastSalePublishDate).format('DD/MM/YYYY HH:mm A')) || `${t('Shared:not-available')}`}>
                <div className='ribbon green'>Published</div>
              </Tooltip>
            )}
            {withCheckbox && (
              <div className='card-checkbox-wrapper'>
                <CheckboxesComponent
                  idRef={`unitsCheckboxItemRef${index + 1}`}
                  singleChecked={getIsSelectedCard(index)}
                  onSelectedCheckboxClicked={(event) => {
                    event.preventDefault();
                    // onCardCheckboxClick(index, item);
                  }}
                />
              </div>
            )}
            {item && (
              <div className='container-wrapper'>
                <a className='cards-wrapper'>
                  <div className='cards-body-wrapper'>
                    <div className='card-body-section'>
                      <div className='body-item-wrapper'>
                        <div className={`flex-wrapper ${!isExpanded ? 'w-100' : ''} mb-4`}>
                          <div className='body-images-wrapper mb-2'>
                            <div className='body-image-item-wrapper'>
                              <div className='body-image-item'>
                                <LoadableImageComponant
                                  classes='cover-image'
                                  type={LoadableImageEnum.div.key}
                                  alt={t(`${translationPath}unit-image`)}
                                  src={
                                    (item.allunitImages && getDownloadableLink(item.allunitImages.fileId)) ||
                                    (getDefaultUnitImage())
                                  }
                                />
                              </div>
                            </div>
                            <div className='body-image-item-wrapper'>
                              <div className='body-title-wrapper'>
                                  <span className='body-title w-50'>{item.name}</span>
                              
                                <div className='body-status'>
                                  <div className='body-status-type'>
                                    {t(`${translationPath}status`)}{' : '}{' '}
                                    {(item.unitStatus && item.unitStatus && item.unitStatus.name) || 'N/A'}
                                  </div>
                                </div>
                                <div className='hidden-select-component-wrapper ml-0'>
                                  <SelectComponet
                                    className='hidden-select-component'
                                    data={templatesProposal}
                                    keyLoopBy='systemTemplateId'
                                    valueInput='systemTemplateId'
                                    textInput='systemTemplateName'
                                    value={activeTemplateId}
                                    keyValue='actionsbuttons'
                                    idRef='contactsActionsRef'
                                    onSelectChanged={(value) => {
                                      setActiveTemplateId(value);
                                      if (value !== -1) {
                                        const itemIndex = templates.findIndex(
                                          (item) => item.systemTemplateId === value
                                        );
                                        if (itemIndex !== -1) {
                                          setActiveTemplateName(
                                            templates[itemIndex].systemTemplateName
                                          );
                                        }
                                        setIsOpenTemplateDialog(true);
                                      }
                                    }}
                                    themeClass='theme-action-buttons'
                                    translationPath={translationPath}
                                    translationPathForData={translationPath}
                                    // wrapperClasses='bg-secondary c-white mx-2'
                                    parentTranslationPath={parentTranslationPath}
                                    emptyItem={{
                                      value: -1,
                                      text: 'templateProposal',
                                      isHiddenOnOpen: true,
                                    }}
                                    onOpen={() => {
                                      setTemplatesProposal([])
                                      GetSuitableTemplateIdForUnitApi(item.id)
                                    }}
                                  isLoading={isLoading}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {isExpanded && item && item.details && (
                            <div className='card-body-section'>
                              {item && item.details.map((subItem) =>
                              (
                                <div
                                  key={`unitsCardItemRef${+1}`}
                                  className='details-item-wrapper'
                                >
                                  {/* Add your icon rendering here if needed */}
                                  <div className='details-item'>
                                    <div className='wr-fl d-flex'>
                                      <div className='details-text '>
                                        {t(`${translationPath}${subItem && subItem.title || ''}`)}
                                        :
                                        {'  '}
                                      </div>
                                      <div className='details-text '>
                                        {t(`${translationPath}${subItem && subItem.value || ''}`)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                          {isOpenTemplateDialog && (
                            <UnitTemplateDialogPreviewDialog
                              activeItem={activeTemplateId}
                              isOpen={isOpenTemplateDialog}
                              unitID={item.id}
                              maintitleText={activeTemplateName}
                              unitOwner={item?.lead_owner}
                              leaseUnitOwner={item?.lease_lead_owner}
                              isOpenChanged={() => {
                                setIsOpenTemplateDialog(false);
                                setActiveTemplateId(-1);
                              }}
                              templateName={activeTemplateName}
                              parentTranslationPath={parentTranslationPath}
                              translationPath={translationPath}
                            />
                          )}
                        </div>
                        <div className='price-wrapper'>
                          <div className={`for-lable ${item.unitOperationType}`}>
                            {t(`${translationPath}for`)}
                          </div>
                          <div className={item.unitOperationType}>
                            {`  ${item.unitOperationType}`}
                            :
                          </div>
                          <div className='unit-price'>
                            {
                              isSale && (
                                (item.selling_price_agency_fee && item.selling_price_agency_fee !== 'N/A') ? `${formatCommasForPriceUnit(item.selling_price_agency_fee)} AED` : 'N/A'
                              )

                            }
                            {!isSale && (
                              (item.rent_price_fees && item.rent_price_fees !== 'N/A') ? `${formatCommasForPriceUnit(item.rent_price_fees)} AED` : 'N/A'
                            )}
                          </div>
                        </div>
                        <div className='flat-contents-wrapper'>
                          {item.flatContent
                            .filter(
                              (filterItem, filterIndex) =>
                                (!isExpanded && filterIndex < notExpandedMax) || isExpanded
                            )
                            .map((subItem, subIndex) => (
                              <div
                                key={`flatContentsItemRef${subIndex + 1}`}
                                className='flat-content-item'
                              >
                                <span className={`flat-content-icon ${subItem.iconClasses} mdi-18px`} />
                                <span className='px-1'>{subItem.value}</span>
                                <span>
                                  {subItem.title && (
                                    <span className='flat-content-text'>
                                      {t(`${translationPath}${subItem.title}`)}
                                    </span>
                                  )}
                                </span>
                              </div>
                            ))}
                        </div>
                        <div className='id-date-wrapper pl-2 pr-2'>
                          <div className='created-on'>
                            <span className='details-icon mdi mdi-calendar mdi-16px' />
                            <span>
                              <span className='details-text'>
                                {t(`${translationPath}created`)}
                                :
                              </span>
                              <span className='px-1'>
                                {(item.createdOn &&
                                  moment(item.createdOn)
                                    .locale(i18next.language)
                                    .format('DD/MM/YYYY')) ||
                                  'N/A'}
                              </span>
                            </span>
                          </div>
                          <div className='contact-id-wrapper'>
                            {t(`${translationPath}ref-#`)}
                            :
                            <div className='contact-id' />

                            <Tooltip title={t(`${translationPath}copy`)}>
                              <CopyToClipboardComponents
                                data={item.refNo}
                                childrenData={item.refNo}
                              />
                            </Tooltip>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='flex-section'>
                      <div className={`card-body-section${isExpanded ? ' is-expanded' : ''}`}>
                        {item.details
                          .filter(
                            (filterItem, filterIndex) =>
                              (!isExpanded && filterIndex < notExpandedMax) || isExpanded
                          )
                          .map(
                            (subItem, subIndex) =>
                              subIndex < notExpandedMax && (
                                <div
                                  key={`unitsCardItemRef${subIndex + 1}`}
                                  className='details-item-wrapper'
                                >
                                  {/* <span className={`details-icon ${subItem.iconClasses}`} /> */}
                                  <span className='details-item'>
                                    <span className='details-text'>
                                      {/* <span className='details-icon mdi mdi-minus mdi-18px' /> */}
                                      {t(`${translationPath}${subItem.title}`)}
                                      :
                                    </span>
                                    <span className='px-1' title={subItem.value}>
                                      {subItem.value}
                                    </span>
                                  </span>
                                </div>
                              )
                          )}
                        <div
                          key='unitsCardItemRef'
                          className='details-item-wrapper'
                        >
                          <span className='details-item'>
                            <span className='details-text'>
                              {t(`${translationPath}${'assignedBy'}`)}
                              {' '}
                              :
                            </span>
                            <span className='px-1' title='assignedBy'>
                              {(item && item.assignedBy) || 'N/A'}
                            </span>
                          </span>
                        </div>

                      </div>
                    </div>
                  </div>

                  {!inquiryStatus && (
                    <div className='cards-footer-wrapper'>
                      <div className='d-flex-center  w-100'>
                        <ButtonBase
                          className='btns theme-transparent w-100'
                          onClick={() => unAssignUnitHandler(item)}
                          disabled={inquiryStatus}
                        >
                          <span className='mdi mdi-delete-outline' />
                          <span className='px-1'>{t('UnAssign-unit')}</span>
                        </ButtonBase>
                      </div>
                    </div>

                  )}

                </a>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

AssignUnitCards.propTypes = {
  data: PropTypes.shape({ result: PropTypes.instanceOf(Array), totalCount: PropTypes.number })
    .isRequired,
  activeCard: PropTypes.instanceOf(Object),
  selectedDetailsUnitItem: PropTypes.instanceOf(Object),
  isExpanded: PropTypes.bool.isRequired,
  withCheckbox: PropTypes.bool.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  notExpandedMax: PropTypes.number,
  isSale: PropTypes.bool,
  unAssignUnitHandler: PropTypes.func,
  inquiryStatus: PropTypes.bool,
};
AssignUnitCards.defaultProps = {
  notExpandedMax: 4,
  selectedDetailsUnitItem: undefined,
  activeCard: undefined,
  inquiryStatus: false,
  isSale: false,

};
