export const BillingPermissions = {
  ViewPreview: {
    permissionsId: '9a4e8924-d764-416c-f465-08dc3da1d8ed',
    permissionsName: 'View Billing Preview',
    description: null,
    componentsId: null,
    components: null,
  },
  ViewHistory: {
    permissionsId: '069a54e9-39f0-4d6a-f466-08dc3da1d8ed',
    permissionsName: 'View Billing History',
    description: null,
    componentsId: null,
    components: null,
  },
  ShareInvoice: {
    permissionsId: '538db356-7695-4a3d-f467-08dc3da1d8ed',
    permissionsName: 'Share Invoice',
    description: null,
    componentsId: null,
    components: null,
  },
  PrintInvoice: {
    permissionsId: '2570a196-5c7b-44c0-f468-08dc3da1d8ed',
    permissionsName: 'Print Invoice',
    description: null,
    componentsId: null,
    components: null,
  },
};
