export const LeadScoreOptions = {
  Add: {
    id: 1,
    value: "add",
    text: "add",
  },
  Subtract: {
    id: 2,
    value: "subtract",
    text: "subtract",
  },
};
