import React from "react";
import { Box } from "@material-ui/core";

import makeStyles from "./styles";
import { CustomCard } from "../../CustomCard";
import { Skeleton } from "@material-ui/lab";
const AssociatedContactCardSkeleton = ({ numberOfCardsSkeleton }) => {
  //styles
  const styles = makeStyles();
  const skeletonCards = Array.from({ length: numberOfCardsSkeleton }, (_, index) => (
      <CustomCard
        key={index}
        classes={styles.container_Associated_Contact_Card}
        borderRadius={"xl"}
        borderColor="secondary"
      >
        <Box className={styles.container_Info}>
          <Box className={styles.container_Personal_info}>
            <Box className={styles.container_Avatar_moreIcon}>
              <Skeleton
                className={styles.avatar}
                variant="circle"
                width={72}
                height={72}
              />
              <Box className={styles.conatainer_MoreIcon}>
                <Skeleton
                  className={styles.dot}
                  variant="circle"
                  width={4}
                  height={4}
                />
                <Skeleton
                  className={styles.dot}
                  variant="circle"
                  width={4}
                  height={4}
                />
                <Skeleton
                  className={styles.dot}
                  variant="circle"
                  width={4}
                  height={4}
                />
              </Box>
            </Box>
            <Box className={styles.container_personal_Details}>
              <Skeleton
                animation="wave"
                width="60px"
                height="28px"
                variant="text"
              />
              <Box className={styles.container_ArrowUpRight_Name}>
                <Skeleton
                  animation="wave"
                  width="100px"
                  height="28px"
                  variant="text"
                />
                <Skeleton
                  animation="wave"
                  width="10px"
                  height="28px"
                  variant="text"
                />
              </Box>
              <Box className={styles.containerId}>
                <Skeleton
                  animation="wave"
                  width="80px"
                  height="28px"
                  variant="text"
                />
                <Skeleton
                  animation="wave"
                  width="10px"
                  height="28px"
                  variant="text"
                />
              </Box>
            </Box>
          </Box>
          <Box className={styles.container_contact_details}>
            <Box className={styles.container_Email_phone}>
              <Skeleton
                animation="wave"
                width="125px"
                height="28px"
                variant="text"
              />
              <Skeleton
                animation="wave"
                width="5px"
                height="5px"
                variant="text"
                className={styles.containerDash}
              />
              <Skeleton
                animation="wave"
                width="125px"
                height="28px"
                variant="text"
              />
            </Box>
            <Box className={styles.container_Address}>
              <Skeleton
                animation="wave"
                width="140px"
                height="28px"
                variant="text"
              />
            </Box>
          </Box>
        </Box>
        <Box className={styles.container_CreatedOn}>
          <Skeleton
            animation="wave"
            width="120px"
            height="28px"
            variant="text"
          />
          <Skeleton
            animation="wave"
            width="120px"
            height="28px"
            variant="text"
          />
        </Box>
      </CustomCard>
    )
  );

  return <>{skeletonCards}</>;
};

export default AssociatedContactCardSkeleton;
