export const ProtectCopiedFun = () => {
  const style = document.createElement('style');
  style.setAttribute('id', 'styleProtectCopiedFun');
  style.textContent = `
    body {
      -webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;-o-user-select: none;user-select: none;
    }
  `;
  document.head.appendChild(style);
  window.getSelection().removeAllRanges();
};
export const ProtectCopiedFundelete = () => {
  const script = document.getElementById('styleProtectCopiedFun');
  if (script !== null)
    script.remove();
};

export const opnfouces = () => false;
