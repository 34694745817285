import {
  LeadsLeaseView,
  LeadsLeaseManagementView,
  LeadsLeaseProfileManagementView,
  LeadsLeaseMergeView,
} from '../../Views/Home';

export const LeadsleaseRoutes = [
  {
    path: '/add',
    name: 'homeLayout.leadsManagementView.leads-management-view',
    component: LeadsLeaseManagementView,
    layout: '/home/lead-lease',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'LeadsView:leads',
        isDisabled: false,
        route: '/home/lead-lease/view',
        groupName: 'leasing',
      },
      {
        name: 'homeLayout.leadsManagementView.leads-management-view',
        isDisabled: true,
        route: '/home/lead-lease/add',
      },
    ],
  },
  {
    path: '/edit',
    name: 'homeLayout.leadsManagementView.leads-management-view',
    component: LeadsLeaseManagementView,
    layout: '/home/lead-lease',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'LeadsView:leads',
        isDisabled: false,
        route: '/home/lead-lease/view',
        groupName: 'leasing',
      },
      {
        name: 'homeLayout.leadsManagementView.leads-management-view',
        isDisabled: true,
        route: '/home/lead-lease/edit',
      },
    ],
  },
  {
    path: '/lead-profile-edit',
    name: 'LeadsProfileManagementView:lead-profile-edit',
    component: LeadsLeaseProfileManagementView,
    layout: '/home/lead-lease',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'LeadsView:leads',
        isDisabled: false,
        route: '/home/lead-lease/view',
        groupName: 'leasing',
      },
      {
        name: 'LeadsProfileManagementView:lead-profile-edit',
        isDisabled: true,
        route: '/home/lead-lease/lead-profile-edit',
      },
    ],
  },
  {
    path: '/view',
    name: 'LeadsView:leads',
    component: LeadsLeaseView,
    layout: '/home/lead-lease',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'LeadsView:leads',
        isDisabled: false,
        route: '/home/lead-lease/view',
        groupName: 'leasing',
      },
    ],
  },
  {
    path: '/merge',
    name: 'homeLayout.leadsManagementView.leads-management-view',
    component: LeadsLeaseMergeView,
    layout: '/home/lead-lease',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'LeadsView:leads',
        isDisabled: false,
        route: '/home/lead-lease/view',
        groupName: 'leasing',
      },
      {
        name: 'homeLayout.leadsManagementView.merge-leads',
        isDisabled: true,
        route: '/home/lead-lease/merge',
      },
    ],
  },
];
