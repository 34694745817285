import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { PageHeading } from "../../../../../../SharedComponents";
import { Avatar, ButtonBase } from "@material-ui/core";
import { AutocompleteComponent, Inputs, SelectComponet, Tables } from "../../../../../../Components";
import {
  getDownloadableLink,
  getFirstLastNameLetters,
  GlobalHistory,
} from "../../../../../../Helper";
import { DateRangePickerComponent } from "../../../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import { OrganizationUserSearch } from "../../../../../../Services";
import { UpdatedUserStatus } from "../../../../../../Enums";

export const ActiveUsersView = ({
  parentTranslationPath,
  translationPath,
  usersData,
  totalCount,
  setFilter,
  filter,
  onPageSizeChanged,
  onPageIndexChanged,
  setSelected,
  selected,
  setIsDatePickerChanged,
  dateRangeDefault,
  initialFilter,
  onTabChanged
}) => {
  const { t } = useTranslation(parentTranslationPath);
    const typingTimer = useRef(null);
  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  };


  const [isLoading, setIsLoading] = useState(false);
  const filterDateChanged = (selectedDate) => {
    setSelected({
      id: "dateFilter",
      value: {
        startDate: selectedDate?.selection?.startDate,
        endDate: selectedDate?.selection?.endDate,
        key: 'selection',
        selectedDateType: selectedDate.selection?.selectedDateType,
      },
    });
    setIsDatePickerChanged(false)
  };
  const [data, setData] = useReducer(reducer, {
    systemUsers: [],
    branches: [],
  });
  const getTableActionsWithPermissions = (rowData) => {
    const actionsList = [];

    const viewInformationAction = (
      <span
        className="fw-simi-bold pointer c-propx"
        onClick={() =>
          rowData.id
            ? GlobalHistory.push(`/home/Users/edit?id=${rowData.id}`)
            : null
        }
      >
        {t(`${translationPath}show-details`)}
      </span>
    );
    actionsList.push(viewInformationAction);

    return actionsList;
  };
    const getSystemUsers = async ({name ,userName}) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: name,
      userName:userName,
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        setData({ id: "systemUsers", value: localValue });
      } else setData({ id: "systemUsers", value: [] });
    }
  };
  const onClearedAllFiltersClick = useCallback(() => {
    setFilter((currentFilter) => ({
      ...initialFilter,             
      status: currentFilter.status,
    }));
    setSelected({
      id: "edit",
      value: {
        lineManager: null,
        branch: null,
        userName:null,
        dateFilter: dateRangeDefault
      },
    });
  }, []);
  useEffect(()=>{
    setFilter((items) => ({
    ...items,
    status: UpdatedUserStatus.ActiveUsers.value || 1,
  }));
  },[])
  return (
    <div className="w-80">
      <div className="d-flex-column">
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          headerTitle={"ActiveUsers"}
          subTitle={"Active-Users-heading"}
          wrapperClasses={"px-4 w-50"}
          headerTitleClasses={"fz-16 mb-1"}
          subTitleClasses={"fz-14 m-0 ml-0"}
        />
        <div className="d-flex">
          <div className="w-75 px-4 d-flex flex-wrap">
            <div className="w-100 d-flex-center fa-center">
              <Inputs
                idRef="fullNameRef"
                labelClasses="mt-4 w-100"
                wrapperClasses="w-100 mt-2 mr-3"
                value={filter.userName || ''}
                onInputChanged={(event) => {
                  const userName = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    userName: userName || null,
                    userId: null,
                  }));
                }}
                inputPlaceholder={t(`${translationPath}full-Name`)}
              />
              <Inputs
                idRef="mobileNumber-ref"
                labelClasses="mt-4 w-100"
                wrapperClasses="w-100 mt-2 mr-3"
                value={filter.mobileNumber || ''}
                onInputChanged={(event) => {
                  const mobileNumber = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    mobileNumber: mobileNumber || null,
                  }));
                }}
                inputPlaceholder={t(`${translationPath}mobile-Number`)}
              />
              <Inputs
                idRef="Createdby-ref"
                labelClasses="mt-4 w-100"
                wrapperClasses="w-100 mt-2 mr-3"
                value={filter.createdBy || ''}
                onInputChanged={(event) => {
                  const createdBy = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    createdBy: createdBy || null,
                  }));
                }}
                inputPlaceholder={t(`${translationPath}created-by`)}
              />
              <div className=" d-flex fa-start">
                <AutocompleteComponent
                  idRef="user-NameRef"
                  labelClasses="Requierd-Color"
                  inputPlaceholder={t(`${translationPath}user-Name`)}
                  selectedValues={selected.userName}
                  wrapperClasses="w-100 mr-2"
                  data={data.systemUsers || []}
                  withoutSearchButton
                  multiple={false}
                  displayLabel={(option) => option?.userName || ""}
                  onChange={(event, newValue) => {
                    setSelected({
                      id: "userName",
                      value: newValue,
                    });
                    setFilter((items) => ({
                      ...items,
                      userUserName: newValue?.userName || null,
                    }));
                  }}
                  onOpen={() => {
                    if (data.systemUsers && data.systemUsers.length == 0)
                      getSystemUsers({});
                  }}
                  onInputKeyUp={(e) => {
                    const { value } = e.target;
                    if (typingTimer) clearTimeout(typingTimer.current);
                    typingTimer.current = setTimeout(() => {
                      getSystemUsers({ userName: value });
                    }, 1200);
                  }}
                />
              </div>
            </div>
            <div className="w-100 d-flex fa-center">
              <Inputs
                idRef="email-ref"
                labelClasses="mt-4"
                wrapperClasses="w-100 mt-2 mr-3"
                value={filter.email || ''}
                onInputChanged={(event) => {
                  const email = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    email: email || null,
                  }));
                }}
                inputPlaceholder={t(`${translationPath}email`)}
              />
              <Inputs
                idRef="branch-NameRef"
                labelClasses="mt-4"
                wrapperClasses="w-100 mt-2 mr-3"
                value={filter.branchName || ''}
                onInputChanged={(event) => {
                  const branchName = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    branchName: branchName || null,
                  }));
                }}
                inputPlaceholder={t(`${translationPath}branch-Name`)}
              />
              <Inputs
                idRef="Createdby-ref"
                labelClasses="mt-4"
                wrapperClasses="w-100 mt-2 mr-3"
                value={filter.staffId || ''}
                onInputChanged={(event) => {
                  const staffId = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    staffId: staffId || null,
                  }));
                }}
                inputPlaceholder={t(`${translationPath}staff-ID`)}
              />
              <div className='d-inline-flex'>
                <div className='item-filter' >
                  <SelectComponet
                    data={[
                      { key: 1, value: t(`${translationPath}activation-Date`) }
                    ]}
                    defaultValue={{
                      key: 1,
                      value: t(`${translationPath}activation-Date`),
                    }}
                    value={selected.dateFilter.selectedDateType || 1}
                    valueInput='key'
                    textInput='value'
                    isDisabled={isLoading}
                    onSelectChanged={(value) => {
                      setIsDatePickerChanged(true);
                      setSelected((f) => ({
                        ...f,
                        selectedDateType: value,
                      }));
                    }}
                    wrapperClasses='w-auto'
                    themeClass='theme-transparent'
                    idRef='Date_Select'

                  />
                </div>
                <DateRangePickerComponent
                  onClearClicked={() => {
                    setSelected({
                      id: "dataFilter",
                      value: dateRangeDefault,
                    });
                    filterDateChanged(dateRangeDefault);
                    setFilter((f) => ({
                      ...f,
                      startDate: null,
                      endDate: null
                    }));
                  }}
                  onDialogClose={() => setIsDatePickerChanged(true)}
                  ranges={[selected.dateFilter]}
                  // parentTranslationPath={parentTranslationPath}
                  // translationPath={translationPath}
                  onDateChanged={(selectedDate) => {
                    filterDateChanged(selectedDate)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="mt-2">
            <ButtonBase
              onClick={onClearedAllFiltersClick}
              id="onClearedAllFiltersref"
              // disabled={isAllFormFieldsLoading}
              className="btns theme-solid bg-danger clear-all-btn px-4"
            >
              <span className="mdi mdi-filter-remove m-1" />
              {t(`${translationPath}clear-filters`)}
            </ButtonBase>
          </div>
        </div>

      </div>

      <div className="propx-view-container sm m-3">
        <Tables
          data={usersData || []}
          headerData={[
            {
              id: 1,
              label: "user",
              component: (item) =>
                (item?.name ? (
                  <div className="d-flex-v-center">
                    <div className="campaign-team-f1">
                      <Avatar
                        className="avatars-wrapper team-avatar fz-12 sm-theme"
                        src={
                          item?.profileImage
                            ? getDownloadableLink(item?.profileImage)
                            : ""
                        }
                      >
                        {getFirstLastNameLetters(item?.name || "")}
                      </Avatar>
                    </div>
                    <div className="d-flex-column">
                      <span className="fz-12 fw-simi-bold">{item?.name || "-"}</span>
                      <span className="fz-10 text-tertiary">
                        {item?.email || "-"}
                      </span>
                    </div>
                  </div>
                ) : (
                  "-"
                )) || "",
            },
            {
              id: 2,
              label: "application",
              input: "application",
            },
            {
              id: 3,
              label: "activation-date",
              input: "activationDate",
              component: (item) =>
                (
                  <span>
                    {item.activationDate
                      ? moment(item.activationDate).format("LL")
                      : null}
                  </span>
                ) || "",
            },
            {
              id: 4,
              label: "last-activity-date",
              input: "lastActivityDate",
              component: (item) =>
                (
                  <span>
                    {item.lastActivityDate
                      ? moment(item.lastActivityDate).format("LL")
                      : null}
                  </span>
                ) || "",
            },
            {
              id: 5,
              label: "actions",
              component: (item) => getTableActionsWithPermissions(item) || "",
            },
          ]}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          totalItems={totalCount ?? 0}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          defaultActions={[]}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
    </div>
  );
};
