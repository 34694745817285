import React from 'react';
import { useTranslation } from 'react-i18next';
import Fab from '@material-ui/core/Fab';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { PermissionsComponent } from '../../../../Components';
import Reportdefault from '../../../../assets/images/defaults/Reportdefault.png';
import Reportdefault2 from '../../../../assets/images/defaults/Reportdefault2.png';
import { GetParams, GlobalHistory } from '../../../../Helper';
import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';
import { ReportBuilderPermissions } from '../../../../Permissions';
import {CopyToClipboardComponents} from '../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';

export const CardReportsBuilder = ({
  isOpenChanged, parentTranslationPath, translationPath, reportDto, setActiveItem

}) => {
  const { t } = useTranslation(parentTranslationPath);
  const dispatch = useDispatch();

  const handleClickComponent = (DATAres) => {
    localStorage.setItem('conditions', JSON.stringify(
      DATAres && DATAres.systemReportConditions.map((itemData) => ({
        operator: itemData.operatorId,
        searchKey: itemData.conditionField,
        value: itemData.filterValue,
        category: itemData.fieldDisplayPath,
        operand: itemData.logicalOperator
      }))
    ));
    const filter = DATAres && DATAres.systemReportFields.map((data) => (JSON.parse(data.systemReportFieldJson)));
    localStorage.setItem('ColumusResultTemp', JSON.stringify(
      filter.map((data) => ({
        name: data.name,
        displayPath: data.searchKey,
        category: data.category,
      }
      ))
    ));
  };

  return (
    <div className='CardReportsBuilder-wraper'>

      {reportDto && reportDto &&
        reportDto.map((item, index) => (
          <div index={index} className='a-box'>
            <div className={` img-container  ${item && item.privacy === 0 ? ' isClose' : ''}`}>
              <div className='img-inner'>
                <div className='inner-skew'>
                  {((item && item.privacy === 1) && <img src={Reportdefault} alt='arrowImage' className='isClose' />) ||
                    <img src={Reportdefault2} alt='arrowImage' />}
                </div>
              </div>
            </div>
            <div className='text-container'>
              {(item && item.isEditable === true) && (
              <PermissionsComponent
                permissionsList={Object.values(ReportBuilderPermissions)}
                permissionsId={ReportBuilderPermissions.EditSystemReports.permissionsId}
              >
                <div className='opation-1'>
                  <Tooltip
                    size='small'
                    placement='right-end'
                    title={t(`${translationPath}edit`)}
                    id='edit-pencil-ref'
                  >
                    <Fab onClick={() => { dispatch(ActiveItemActions.activeItemRequest(item)); GlobalHistory.push(`/home/Reports-Builder/edit?id=${item.systemReportCategoryTypeId}&Report=${item.systemReportCategoryType}&spName=${item.spName}&systemReportCategoryTypeId=${item.systemReportCategoryTypeId}`); }} size='small' color='primary' aria-label='add'>
                      <span className='table-action-icon mdi mdi-lead-pencil c-white' />
                    </Fab>
                  </Tooltip>

                </div>
              </PermissionsComponent>
              )}
              <PermissionsComponent
                permissionsList={Object.values(ReportBuilderPermissions)}
                permissionsId={ReportBuilderPermissions.RunSystemReport.permissionsId}
              >
                <div className='opation-2'>
                  <Tooltip
                    size='small'
                    placement='right-end'
                    title={t(`${translationPath}Run`)}
                  >
                    <Fab
                      onClick={() => {
                       handleClickComponent(item); setActiveItem(item); window.open(`/share/ReportRunView?spName=${item.spName}&Report=${item.systemReportCategoryType}&ReportId=${item.systemReportId}&withLogo=${item.withLogo}`, '', 'width=1400,height=1000');
                    }}
                      size='small'
                      color='secondary'
                      aria-label='add'
                    >
                      <span className='table-action-icon  mdi mdi-webhook c-white' />
                    </Fab>
                  </Tooltip>
                </div>
              </PermissionsComponent>
              {(item && item.isDeletable === true) && (
                <div className='opation-3'>
                  <Tooltip
                    size='small'
                    placement='right-end'
                    title={t(`${translationPath}delete`)}
                  >
                    <Fab onClick={() => { isOpenChanged(); setActiveItem(item); }} size='small' color='secondary' aria-label='add'>
                      <span className='table-action-icon mdi mdi-trash-can' />
                    </Fab>
                  </Tooltip>
                </div>
              )}
              <Tooltip
                size='small'
                placement='right-end'
                title={item.systemReportName}
              >
                <h3>{item.systemReportName}</h3>
              </Tooltip>
              <div className='d-flex'>
                <span className='title-l'>{t(`${translationPath}Description`)}</span>
                {' '}
                {' '}
                :
                {' '}
                <Tooltip
                  size='small'
                  placement='right-end'
                  title={item.description}
                >
                  <div className='title-des'>{item.description}</div>
                </Tooltip>

              </div>
              <div className='d-flex'>
                <span className='title-l'>{t(`${translationPath}ReportId`)}</span>
                {' '}
                {' '}
                :
                {' '}
                <CopyToClipboardComponents
                  data={(item && item.systemReportId) || 'N/A'}
                  childrenData={(item && item.systemReportId) || 'N/A'}
                />

              </div>
              <div>
                <span className='title-l'>{t(`${translationPath}LastModified`)}</span>
                {' '}
                {' '}
                :
                {' '}
                {moment(item.updateOn).format('DD/MM/YYYY')}
              </div>
              <div>
                <span className='title-l'>{t(`${translationPath}LastModifiedBy`)}</span>
                {' '}
                {' '}
                :
                {' '}

                {item.updatedBy}
              </div>
              <div>
                <span className='title-l'>{t(`${translationPath}createdOn`)}</span>
                {' '}
                {' '}
                :
                {' '}
                {moment(item.createdOn).format('DD/MM/YYYY')}
              </div>
              <div>
                <span className='title-l'>{t(`${translationPath}ReportOwner`)}</span>
                {' '}
                {' '}
                :
                {' '}

                {item.owner}
              </div>
              <div>
                <span className='title-l'>{t(`${translationPath}ReportCreatedBy`)}</span>
                {' '}
                {' '}
                :
                {' '}
                {item.createdBy}
              </div>
              {/* <ButtonBase
                        className=''
                      >
                        <span className='con-text'> Contact Phone List</span>
                      </ButtonBase> */}
            </div>
          </div>
        ))}
      {' '}
    </div>
  );
};
CardReportsBuilder.propTypes = {
  translationPath: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  reportDto: PropTypes.instanceOf(Array),
  setActiveItem: PropTypes.func,
  isOpenChanged: PropTypes.func,
};

CardReportsBuilder.defaultProps = {
  translationPath: '',
  parentTranslationPath: '',
  reportDto: [],
  isOpenChanged: () => { },
  setActiveItem: () => { },
};
