
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactClassIdEnum  } from '../../../../../../../../../../Enums';
import { lookupItemsGetId } from '../../../../../../../../../../Services/LookupsServices';
import { getErrorByName } from '../../../../../../../../../../Helper';
import {
  AutocompleteComponent,
} from '../../../../../../../../../../Components';

export const ContactClassComponent = ({
  parentTranslationPath,
  translationPath,
  selected,
  setSelected,
  isSubmitted,
  setIsLoading,
  schema 
}) => {

  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [allContactClass, setAllContactClass] = useState([]);

  const getAllContactClass = useCallback(async () => {
    setIsLoading(true);

    const res = await lookupItemsGetId({
      lookupTypeId: ContactClassIdEnum.lookupTypeId,
    });

    if (!(res && res.status && res.status !== 200)) 
        setAllContactClass(res) ; 
    else
     setAllContactClass([]);

    setIsLoading(false);

  }, []);

  useEffect(() => {
    getAllContactClass();
  }, [getAllContactClass]);


  return (
    <div>
      <AutocompleteComponent
        labelClasses='Requierd-Color'
        idRef='contactClassRef'
        labelValue={t(`${translationPath}contact-class`)}
        selectedValues={(selected && selected.ContactClass) || []}
        data={allContactClass}
        displayLabel={(option) => (option && option.lookupItemName) || ''}
        multiple={false}
        withoutSearchButton
        onChange={(event, newValue) => 
        {
          setSelected(newValue) ; 

        }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        isWithError
        isSubmitted={isSubmitted}
        helperText={getErrorByName(schema, 'contactClassId').message}
        error={getErrorByName(schema, 'contactClassId').error}
      />
    </div>
  );
};
