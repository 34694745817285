
import React  , { useState }  from 'react';
import {
  TabsComponent,
} from '../../../../Components';
import { PeopleAndLocationsVerticalTabsData} from './PeopleAndLocations/PeopleAndLocationsVerticalTabsData' ; 

  export const PeopleAndLocationsView = ({ parentTranslationPath , translationPath  }) => { 
    
    const [activeTab, setActiveTab] = useState(0);
    const onTabChanged = (e, newTap) => {
      setActiveTab(newTap);
    };
    

    return (
      <div className='mt-3 developer-profile-wrapper view-wrapper'>
        <TabsComponent
          data ={PeopleAndLocationsVerticalTabsData}
          labelInput='label'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          themeClasses='theme-curved'
          currentTab={activeTab}
          onTabChanged={onTabChanged}
          dynamicComponentProps={{
            parentTranslationPath,
            translationPath,
            setActiveTab, 
            activeTab,
          }}
        />
      </div>
    );
  } ; 
  