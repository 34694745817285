import { RatingPercentageView ,
         GeographicCriteriaView ,
         KeyNearbyView ,
         DeveloperView ,
         GeneralPropertyCriteriaView ,
         TowerUnitsFinishingView , 
         ParkingDistributionView ,
         PropertyViewsView , 
         FacilitiesAndAmenitiesView  , 
         LifeStyleView  , 
         SurroundingsView 
        } from './PropertyRatingTabsSections' ; 

export const PropertyRatingTabsData = [
  {
    label: 'rating-percentage',
    component: RatingPercentageView ,
    value : 'rating-percentage'
  },
  {
    label: 'geographic-criteria',
    component: GeographicCriteriaView ,
    value : 'Geographic criteria'
  },
  {
     label: 'key-nearby',
     component: KeyNearbyView , 
     value : 'Key Nearby'
  },
  {
    label: 'developer',
    component: DeveloperView , 
    value : 'Developer'
  },
  {
    label: 'general-property-criteria',
    component: GeneralPropertyCriteriaView , 
    value : 'general-property-criteria'
  },
  {
    label: 'tower-units-finishing',
    component: TowerUnitsFinishingView , 
    value : 'Tower units && finishing'
  },
  {
    label: 'parking-distribution',
    component: ParkingDistributionView , 
    value : 'Parking distribution'
  },
  {
    label: 'property-views',
    component: PropertyViewsView , 
    value : 'Property Views'
  },
  {
    label: 'facilities-amenities',
    component: FacilitiesAndAmenitiesView , 
    value : 'Facilities & Amenities'
  },
  {
    label: 'surroundings',
    component: SurroundingsView , 
    value : 'Surroundings'
  },

  {
    label: 'life-style',
    component: LifeStyleView , 
    value : 'Lifestyle'
  },
   
];
