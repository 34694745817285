
export const ProjectTaskPermissions = {
  ProjectTaskPermissions: 
  {
    permissionsId:'ead99dea-fffd-46b6-c4ac-08dc683b0c43',
    permissionsName:'Project Task' , 
    componentsId:null,
    components:null
  }
  } ;
  