import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Box } from "@material-ui/core";

import useStyles from "./styles";

const ContactCardSkeleton = ({ index }) => {
  const styles = useStyles();

  return (
    <Box
      key={`CardSkeletonLoader-${index}`}
      className={styles.ContactCardWrapper}
    >
      <Box className={styles.cardHeader}>
        <Skeleton
          animation="wave"
          height="20px"
          variant="rectangular"
          width="20px"
        />

        <Skeleton
          className={styles.avatar}
          animation="wave"
          width="72px"
          variant="circular"
          height="72px"
        />

        <Skeleton
          animation="wave"
          height="20px"
          variant="rectangular"
          width="20px"
        />
      </Box>

      <Box className={styles.detailsWrapper}>
        <Box className={styles.details}>
          <Skeleton
            animation="wave"
            width="72px"
            height="24px"
            variant="text"
          />

          <Skeleton
            animation="wave"
            width="37px"
            height="24px"
            variant="text"
          />

          <Skeleton
            animation="wave"
            width="30px"
            height="24px"
            variant="text"
          />
        </Box>
        <Box className={styles.nameContainer}>
          <Skeleton
            animation="wave"
            width="160px"
            height="50px"
            variant="text"
          />
          <Skeleton
            animation="wave"
            height="20px"
            variant="rectangular"
            width="20px"
          />
        </Box>
        <Box className={styles.idSection}>
          <Skeleton
            animation="wave"
            width="60px"
            height="30px"
            variant="text"
          />

          <Skeleton
            animation="wave"
            height="20px"
            variant="rectangular"
            width="20px"
          />

          <Skeleton
            className={styles.rounded}
            animation="wave"
            width="42px"
            variant="circular"
            height="20px"
          />

          <Skeleton
            animation="wave"
            height="20px"
            variant="rectangular"
            width="20px"
          />
        </Box>
      </Box>

      <Box className={styles.flexRow}>
        <Skeleton
          className={styles.rounded}
          animation="wave"
          width="44px"
          variant="circular"
          height="24px"
        />

        <Skeleton
          className={styles.rounded}
          animation="wave"
          width="44px"
          variant="circular"
          height="24px"
        />

        <Skeleton
          className={styles.rounded}
          animation="wave"
          width="44px"
          variant="circular"
          height="24px"
        />

        <Skeleton
          className={styles.rounded}
          animation="wave"
          width="44px"
          variant="circular"
          height="24px"
        />
      </Box>

      <Box className={styles.flexRow}>
        <Skeleton
          className={styles.rounded}
          animation="wave"
          width="20px"
          variant="circular"
          height="20px"
        />

        <Skeleton
          className={styles.rounded}
          animation="wave"
          width="20px"
          variant="circular"
          height="20px"
        />

        <Skeleton
          className={styles.rounded}
          animation="wave"
          width="20px"
          variant="circular"
          height="20px"
        />

        <Skeleton
          className={styles.rounded}
          animation="wave"
          width="20px"
          variant="circular"
          height="20px"
        />
      </Box>

      <Box className={styles.flexRow}>
        <Skeleton animation="wave" width="45px" height="20px" variant="text" />

        <Skeleton animation="wave" width="45px" height="20px" variant="text" />
      </Box>

      <Box className={styles.flexRow}>
        <Box className={styles.flexRow}>
          <Skeleton
            animation="wave"
            width="100px"
            height="28px"
            variant="text"
          />

          <Skeleton
            className={styles.rounded}
            animation="wave"
            width="30px"
            variant="circular"
            height="18px"
          />
        </Box>

        <Skeleton animation="wave" width="10px" height="2px" variant="text" />
        <Box className={styles.flexRow}>
          <Skeleton
            animation="wave"
            width="100px"
            height="28px"
            variant="text"
          />
          <Skeleton
            className={styles.rounded}
            animation="wave"
            width="30px"
            variant="circular"
            height="18px"
          />
        </Box>
      </Box>

      <Box className={styles.divider} />

      <Box className={styles.dateContainer}>
        <Skeleton animation="wave" width="58px" height="20px" variant="text" />

        <Box className={styles.dateText}>
          <Skeleton
            animation="wave"
            width="30px"
            height="20px"
            variant="text"
          />

          <Skeleton
            animation="wave"
            width="120px"
            height="20px"
            variant="text"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ContactCardSkeleton;
