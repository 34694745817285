export const AmountPaidBy = {
  Landlord: {
    name: 'Landlord',
    value: 1,
  },
  Management: {
    name: 'Management',
    value: 2,
  },
};
