import { PropertyRatingView } from  '../../Views/Home';

export const PropertyRatingRoutes = [
  {
    path: "/view",
    name: "PropertyRating:property-rating",
    component: PropertyRatingView,
    layout: "/home/property-rating-configuration",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "PropertyRating:property-rating",
        isDisabled: true,
        route: "/home/property-rating-configuration/view",
        groupName: "system-configuration",

      },
    ],
  },

];
