import { MarketingCampaignsStatus } from './Status';

const initialState = localStorage.getItem('MarketingCampaignsInfo') ?
  JSON.parse(localStorage.getItem('MarketingCampaignsInfo')) :
  {};

export const MarketingCampaignsReducer = (state = initialState, action) => {

  switch (action.type) {
    case MarketingCampaignsStatus.ERROR:
    case MarketingCampaignsStatus.REQUEST:
      return state;

    case MarketingCampaignsStatus.SUCCESS:
      return action.payload;

    case MarketingCampaignsStatus.RESET:
      return initialState;

    default:
      return state;
  }
};
