import React, { useEffect, useRef } from "react";
import { Box, InputBase, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from '@material-ui/core/styles';
import { useIsAr } from "../../../../Hooks";

const useStyles = makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);
  
  return {
    search: {
      display: 'flex',
      alignItems: 'center',
      padding: '2px 4px',
      backgroundColor: theme.palette.background.primary,
      border: "none",
      width: '100%',
      maxWidth: 400,
    },
    input: {
      border: "none",
      flex: 1,
    },
    iconButton: {
      padding: 10,
      ...(isAr ? {
        rotate: "180deg",
      } : {})
    },
    searchIcon: {
      color: theme.palette.foreground.secondary,
      fontSize: '1.6rem',
    }
  }
});

function SearchBar() {
  const styles = useStyles();
  const inputRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === '/') {
        event.preventDefault();
        inputRef.current.focus();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Box className={styles.search}>
      <IconButton className={styles.iconButton}>
        <SearchIcon className={styles.searchIcon} />
      </IconButton>
      <InputBase
        className={styles.input}
        placeholder="Search (Ctrl+/)"
        inputRef={inputRef}
      />
    </Box>
  );
}

export default SearchBar;
