export const LeadsClassTypesEnum = {
  seller: {
    key: 1,
    name:'Seller' , 
    value: 'seller',
  },
  landlord: {
    key: 2,
    name:'Landlord' , 
    value: 'landlord',
  },
  buyer: {
    key: 3,
    name:'Buyer' , 
    value: 'buyer',
  },
  tenant: {
    key: 4,
    name:'Tenant' , 
    value: 'tenant',
  },
};
