import {MainInformationStep, ArtworkSuggestions, FinanceStep} from '../FormSteps'
export const ViewRequestTabsData = [
  {
    label: 'Main-Information',
    component: MainInformationStep,
    permissionsList: [],
    permissionsId: null,
  },
  {
    label: 'Artwork-Suggestions',
    component: ArtworkSuggestions,
    permissionsList: [],
    permissionsId: null,
  },
  {
    label: 'Finance',
    component: FinanceStep,
    permissionsList: [],
    permissionsId: null,
  },
];
