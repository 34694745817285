import React from "react";

export const TransactionDocsHeaderData = [
  {
    id: 1,
    label: "Document",
    input: "documentName",
    component: (item) =>
      <span className="fw-simi-bold">{item.documentName || "-"}</span> || "",
  },
  {
    id: 2,
    label: "Category",
    input: "categoryName",
  },
  {
    id: 3,
    label: "Required",
    input: "isMandatory",
    component: (item) =>
      item.isMandatory ? (
        <span className="c-success">Yes</span>
      ) : (
        <span className="c-danger">No</span>
      ),
  },
  {
    id: 4,
    label: "Status",
    input: "isExist",
    component: (item) =>
        item.isExist ? (
          <span className="info-capsule green">Uploaded</span>
        ) : (
          <span className="info-capsule red">Missing</span>
        ),
  },
  {
    id: 5,
    label: "Number of documents",
    input: "numberOfDocuments",
    component: (item) =>
        <span className="fw-simi-bold light-text">{item.numberOfDocuments || "-"}</span> || "",
  },
];
