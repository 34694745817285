import React, {
  useState, useRef, useEffect, useCallback, useReducer
} from 'react';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import {
  Inputs, Spinner, PermissionsComponent, SelectComponet, ViewTypesActivities, AutocompleteComponent, DataFileAutocompleteComponent
} from '../../../Components';
import { DateRangePickerComponent } from '../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import { useTitle } from '../../../Hooks';
import { ViewTypes2Enum, UnitsOperationTypeEnum, ActivitiesRelationTypesEnum } from '../../../Enums';
import {
  OrganizationUserSearch,
  GetLeads,
  getUnits,
  GetAllActivities,
  GetAllActivityTypesAPI,
} from '../../../Services';
import { UnitMapper } from '../UnitsView/UnitMapper';
import { ActivitiesLeasePermissions } from '../../../Permissions/Lease/ActivitiesLeasePermissions';
import { ActivitiesManagementDialog } from '../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog';
import { ActivitiesLeaseTableView } from './ActivitiesLeaseViewUtilities/ActivitiesLeaseTableView';
import { GlobalOrderFilterActions } from '../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import { returnPropsByPermissions } from '../../../Helper';
import { StaticLookupsIds } from '../../../assets/json/StaticLookupsIds';

const parentTranslationPath = 'ActivitiesView';
const translationPath = '';

export const ActivitiesLeaseView = () => {
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
  };
  const { t } = useTranslation(parentTranslationPath);
  const [activeActionType, setActiveActionType] = useState(ViewTypes2Enum.tableRelatedView.key);
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [searchItem, setSearchItem] = useState(null);
  const [agentId, setAgentId] = useState(null);
  const [isLoadingActivities, setIsLoadingActivities] = useState(false);
  const [isLoading, setIsLoading] = useState({
    agents: false,
    units: false,
    leads: false,
    activityTypes: false
  });
  const [searchedItem, setSearchedItem] = useState('');
  const [activities, setActivities] = useState({
    result: [],
    totalCount: 0,
  });
  const [activityTypes, setActivityTypes] = useState({
    result: [],
    totalCount: 0
  });

  const [agents, setAllAgent] = useState({
    result: [],
    totalCount: 0,
  });
  const [openDialog, setOpenDialog] = useState(false);  
  
  const advanceSearch = useSelector((state) => state.GlobalAdvanceSearchReducer);
  const [activitiesTableFilter, setActivitiesTableFilter] = useState(advanceSearch && advanceSearch['activitiesLease'] || null);
  const [isFirst , setIsFirst] = useState(false);

  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const dispatch = useDispatch();
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: (orderFilter.activitesLeaseFilter && orderFilter.activitesLeaseFilter.filterBy) || null,
    orderBy: (orderFilter.activitesLeaseFilter && orderFilter.activitesLeaseFilter.orderBy) || null,
  });

  const [orderBy, setOrderBy] = useState(selectedOrderBy.filterBy ? selectedOrderBy : { filterBy: null, orderBy: null });
  const [sortBy, setSortBy] = useState(null);
  const searchTimer = useRef(null);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
    search: '',
    guidAgentId: null,
    activityTypeId: null,
    filterBy: orderBy.filterBy || null,
    orderBy: orderBy.orderBy || null,
    relatedUnitId: null,
    relatedLeadId: null,
  });
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [selected, setSelected] = useReducer(reducer, {
    activityAssign: null,
    activityType: null,
    unit: null,
    activeFormType: 0,
    relationType: null,
    relatedUnit: null,
    relatedLead: null,
    reminderPersons: [],
  });
  const [data, setData] = useReducer(reducer, {
    activityAssignments: [],
    activityTypes: [],
    units: [],
    relatedLeads: [],
    relatedUnits: [],
  });
  const changeActiveFormType = (newValue) => {
    setSelected({
      id: 'edit',
      value: {
        ...selected,
        activeFormType: +newValue,
        relatedUnit: null,
        relatedLead: null,
      },
    });
    if (filter.relatedLeadId)
      setFilter((item) => ({ ...item, relatedLeadId: null }));
    if (filter.relatedUnitId)
      setFilter((item) => ({ ...item, relatedUnitId: null }));
  };

  useTitle(t(`${translationPath}activities`));

  const getAllActivities = useCallback(async () => {
    if(!isFirst)
    return ; 

   if (returnPropsByPermissions(ActivitiesLeasePermissions.ViewAndSearchInLeaseActivities.permissionsId)) {
     setIsLoadingActivities(true);

    let activitiesDate = { fromDate: null, toDate: null };
    if (dateFilter && dateFilter.startDate && dateFilter.endDate) {
      activitiesDate = {
        fromDate: moment(dateFilter.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        toDate: moment(dateFilter.endDate).format('YYYY-MM-DDTHH:mm:ss')
      };
    }
    const res = activeActionType === ViewTypes2Enum.tableRelatedView.key ? await GetAllActivities({
      ...filter,
      isWithoutRelatedActivities: false,
      unitOperationType: UnitsOperationTypeEnum.rent.key,
      pageIndex: filter.pageIndex + 1,
      fromDate: activitiesDate.fromDate,
      toDate: activitiesDate.toDate,
      pageSize: filter.pageSize,
      relationType: selected.relationType,
    }) : await GetAllActivities({
      ...filter,
isWithoutRelatedActivities: true,
      unitOperationType: UnitsOperationTypeEnum.rent.key,
      pageIndex: filter.pageIndex + 1,
      fromDate: activitiesDate.fromDate,
      toDate: activitiesDate.toDate,
      pageSize: filter.pageSize,
      relationType: selected.relationType,
    });
    if (!(res && res.status && res.status !== 200)) {
      setActivities({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setActivities({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoadingActivities(false);
   }
  }, [filter, activeActionType, dateFilter, orderBy, selected.relationType]);
  

  const onFilterValuesChanged = (newValue) => {
    setIsFirst(true); 
    const localFilterDto = filter || {};
    if(isFirst){
    if (newValue) {
      Object.values(newValue)
        .filter((item) => item.searchableKey || item.displayPath)
        .map((item) => {
          
          if (localFilterDto[item.displayPath])
            item.value ? localFilterDto[item.displayPath] = item.value : localFilterDto[item.displayPath] = null;
           else if (item.value)
            localFilterDto[item.displayPath] = item.value;

          return undefined;
        });
      }
     setFilter(() => ({ ...localFilterDto    }));
    }
    else {
      if (activitiesTableFilter) {
       Object.values(activitiesTableFilter)
        .filter((item) => item.searchableKey || item.displayPath)
        .map((item) => {
          
          if (localFilterDto[item.displayPath])
            item.value ? localFilterDto[item.displayPath] = item.value : localFilterDto[item.displayPath] = null;
           else if (item.value)
            localFilterDto[item.displayPath] = item.value;

          return undefined;
        });
    }
    setFilter(() => ({ ...localFilterDto    }));
  }
  };

  const getActivityType = useCallback(async (activityTypeSearch) => {
    setIsLoading((loading) => ({ ...loading, activityTypes: true }));
    const res = await GetAllActivityTypesAPI({ pageSize: 25, pageIndex: 0, search: activityTypeSearch });
    if (!(res && res.status && res.status !== 200)) {
      setActivityTypes({
        result: res.result || [],
        totalCount: res.totalCount || 0
      });
    } else {
      setActivityTypes({
        result: [],
        totalCount: 0
      });
    }
    setIsLoading((loading) => ({ ...loading, activityTypes: false }));
  });
  const getAllAgents = useCallback(async () => {
    setIsLoading((loading) => ({ ...loading, agents: true }));

    const res = await OrganizationUserSearch({});
    if (!(res && res.status && res.status !== 200)) {
      setAllAgent({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setAllAgent({
        result: [],
        totalCount: 0,
      });
    }

    setIsLoading((loading) => ({ ...loading, agents: false }));
  }, []);
  useEffect(() => {
    getAllAgents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getActivityType();
  }, []);
  const searchHandler = (event) => {
    const { value } = event.target;
    setSearchItem(value);
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({
        ...item,
        search: value,
        pageIndex: 0,
        fromDate: null,
        toDate: null,
        guidAgentId: agentId || null,
        activityTypeId: null,
        relatedUnitId: null,
        relatedLeadId: null,
      }));
    }, 700);
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({
      ...item,
      pageIndex,
      search: '',
      fromDate: null,
      toDate: null,
      guidAgentId: null,
      activityTypeId: null,
      relatedUnitId: null,
      relatedLeadId: null,
    }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const addNewHandler = () => {
    setOpenDialog(true);
  };

  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };
  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        activitesLeaseFilter: {
          filterBy: selectedOrderBy.filterBy,
          orderBy: selectedOrderBy.orderBy,
        },
      })
    );
    setFilter((item) => ({ ...item, orderBy: selectedOrderBy.orderBy, filterBy: selectedOrderBy.filterBy }));
  };

  const onTypeChanged = useCallback(
    (activeType) => {
      setActiveActionType(activeType);
    },
    [setActiveActionType]
  );
  const getAllRelatedUnits = useCallback(
    async (value) => {
      setIsLoading((loading) => ({ ...loading, units: true }));

      const response = await getUnits({ search: value, operationType: UnitsOperationTypeEnum.rent.key });
      if (!(response && response.status && response.status !== 200)) {
        const unitMapped = ((response && response.result) || []).map((item) => UnitMapper(item));
        setData({
          id: 'relatedUnits',
          value: unitMapped || [],
        });
      } else setData({ id: 'relatedUnits', value: [] });

      setIsLoading((loading) => ({ ...loading, units: false }));
    },
    []
  );
  const getAllRelatedLeads = useCallback(
    async (value) => {
      setIsLoading((loading) => ({ ...loading, leads: true }));

      const response = await GetLeads({ search: value });
      if (!(response && response.status && response.status !== 200))
        setData({ id: 'relatedLeads', value: (response && response.result) || [] });
      else setData({ id: 'relatedLeads', value: [] });

      setIsLoading((loading) => ({ ...loading, leads: false }));
    }, []
  );
  useEffect(() => {
    if (selected.activeFormType === 0) {
        setSelected({
          id: 'relationType',
          value: null
        });
      } else if (selected.activeFormType === 1) {
        getAllRelatedUnits();
        setSelected({
          id: 'relationType',
          value: ActivitiesRelationTypesEnum.unit.key,
        });
      } else if (selected.activeFormType === 2) {
        getAllRelatedLeads();
        setSelected({
          id: 'relationType',
          value: ActivitiesRelationTypesEnum.lead.key,
        });
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.activeFormType]);

 useEffect(() => {
  getAllActivities();
}, [filter]);

  useEffect(() => {
    if (sortBy) {
      setFilter((item) => ({
        ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy, pageIndex: 0
     }));
    }
  }, [sortBy]);

  return (
    <div className='view-wrapper'>
      <Spinner isActive={isLoadingActivities} />
      <div className='d-flex-column'>
        <div className='header-section'>
          <div className='filter-section'>
            <div className='section px-3'>
              <PermissionsComponent
                permissionsList={Object.values(ActivitiesLeasePermissions)}
                permissionsId={ActivitiesLeasePermissions.AddNewActivity.permissionsId}
              >
                <ButtonBase id='action_add-new_plus' className='btns theme-solid' onClick={addNewHandler}>
                  <span className='mdi mdi-plus' />
                  {t(`${translationPath}add-new`)}
                </ButtonBase>
              </PermissionsComponent>
            </div>
            <PermissionsComponent
              permissionsList={Object.values(ActivitiesLeasePermissions)}
              permissionsId={ActivitiesLeasePermissions.ViewAndSearchInLeaseActivities.permissionsId}
            >
              <div className='section autocomplete-section'>
                <div className='d-flex-column px-2 w-100 p-relative'>
                  <div className='d-flex-column w-100'>
                    <Inputs
                      value={searchedItem}
                      onKeyUp={searchHandler}
                      idRef='activitiesSearchRef'
                      label={t(`${translationPath}search-activity`)}
                      onInputChanged={(e) => setSearchedItem(e.target.value)}
                      inputPlaceholder={t(`${translationPath}search-activity`)}
                      beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                    />
                  </div>
                  <div className='d-inline-flex pl-4-reversed'>
                    <div className='w-100 mr-1-reversed'>
                      <AutocompleteComponent
                        idRef='referredToRef'
                        isLoading={isLoading.activityTypes}
                        multiple={false}
                        data={activityTypes.result || []}
                        value={filter.activityTypeId}
                        chipsLabel={(option) => option.activityTypeName || ''}
                        displayLabel={(option) => option.activityTypeName || ''}
                        withoutSearchButton
                        inputPlaceholder={t(`${translationPath}activity-type`)}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onInputKeyUp={(event) => {
                            const { value } = event.target;
                            if (searchTimer.current) clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              getActivityType(value);
                            }, 700);
                          }}
                        onChange={(event, newValue) => {
                            if (!newValue) getActivityType();
                            setFilter((item) => ({
                              ...item,
                              filterBy: null,
                              orderBy: null,
                              search: searchItem || '',
                              fromDate: null,
                              toDate: null,
                              relatedUnitId: null,
                              relatedLeadId: null,
                              pageIndex: 0,
                              guidAgentId: null,
                              activityTypeId: newValue && newValue.activityTypeId || null
                            }));
                          }}
                      />
                    </div>
                    <div className='w-100'>
                      <AutocompleteComponent
                        idRef='referredToRef'
                        isLoading={isLoading.agents}
                        multiple={false}
                        data={agents.result || []}
                        value={filter.guidAgentId}
                        chipsLabel={(option) => option.fullName || ''}
                        displayLabel={(option) => option.fullName || ''}
                        withoutSearchButton
                        inputPlaceholder={t(`${translationPath}Agent`)}
                          // onInputKeyUp={(e) => setFilter(e)}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                          // selectedValues={filter.referredTo}
                        onChange={(event, newValue) => {
                            setAgentId((newValue && newValue.id) || null);
                            setFilter((item) => ({
                              ...item,
                              guidAgentId: (newValue && newValue.id) || null,
                              pageIndex: 0,
                              pageSize: 25,
                              search: searchItem || '',
                              relatedUnitId: null,
                              relatedLeadId: null,
                            }));
                          }}
                      />

                    </div>
                  </div>
                  <div className='d-inline-flex pl-4-reversed mt-2'>
                    <div className='w-100 mr-1-reversed'>
                      <DataFileAutocompleteComponent
                        idRef='RelatedToRef'
                        isDisabled={(selected.activeFormType === 0)}
                        isLoading={(isLoading.units || isLoading.leads)}
                        labelClasses='Requierd-Color'
                        multiple={false}
                        selectedValues={
                            (selected.activeFormType === 1 && selected.relatedUnit) ||
                            (selected.activeFormType === 2 && selected.relatedLead)
                          }
                        data={(selected.activeFormType === 1 && data.relatedUnits) || (selected.activeFormType === 2 && data.relatedLeads) || []}
                        displayLabel={
                            (selected.activeFormType === 1 && ((option) => option.unitRefNo || '')) ||
                            (selected.activeFormType === 2 && ((option) =>
                            (option.lead && option.lead.contact_name && option.lead.contact_name.name) ||
                            (option.lead && option.lead.company_name && option.lead.company_name.name) ||
                              '')) || ((option) => '')
                          }
                        renderFor={(selected.activeFormType === 1 && 'unit') || (selected.activeFormType === 2 && 'lead')}
                        getOptionSelected={
                            (selected.activeFormType === 1 &&
                              ((option) => option.id === filter.relatedUnitId)) ||
                            ((option) => option.leadId === filter.relatedLeadId)
                          }
                        filterOptions={(options) => options}
                        onChange={(event, newValue) => {
                            if (selected.activeFormType === 1) {
                              if (!newValue) getAllRelatedUnits(newValue);
                              setSelected({ id: 'relatedUnit', value: newValue });
                              setFilter((item) => ({
                                ...item,
                                relatedUnitId: (newValue && newValue.id) || null,
                                pageIndex: 0,
                                guidAgentId: null,
                                pageSize: 25,
                                search: '',
                                relatedLeadId: null,
                                filterBy: null,
                                orderBy: null
                              }));
                            } else {
                              if (!newValue) getAllRelatedLeads(newValue);
                              setSelected({ id: 'relatedLead', value: newValue });
                              setFilter((item) => ({
                                ...item,
                                relatedLeadId: (newValue && newValue.leadId) || null,
                                guidAgentId: null,
                                pageIndex: 0,
                                relatedUnitId: null,
                                search: '',
                                filterBy: null,
                                orderBy: null
                              }));
                            }
                          }}
                        onInputKeyUp={(e) => {
                            const { value } = e.target;
                            if (searchTimer.current) clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              if (selected.activeFormType === 1) getAllRelatedUnits(value);
                              else getAllRelatedLeads(value);
                            }, 700);
                          }}
                        withoutSearchButton
                        inputStartAdornment={(
                          <SelectComponet
                            data={[
                                {
                                  key: 0,
                                  value: 'all',
                                },
                                {
                                  key: 1,
                                  value: 'unit',
                                },
                                {
                                  key: 2,
                                  value: 'lead',
                                },
                              ]}
                            value={selected.activeFormType}
                            inputPlaceholder={t(`${translationPath}Agent`)}
                            valueInput='key'
                            textInput='value'
                            onSelectChanged={changeActiveFormType}
                            wrapperClasses='over-input-select w-auto'
                            idRef='relatedToTypeRef'
                            parentTranslationPath={parentTranslationPath}
                            translationPath={translationPath}
                            translationPathForData={translationPath}
                          />
                          )}
                        isWithError
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                      />

                    </div>
                    <div className='w-100 filter-section-date-picker'>
                      <DateRangePickerComponent
                        onClearClicked={() => setDateFilter(dateRangeDefault)}
                        ranges={[dateFilter]}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onDateChanged={(selectedDate) => {
                            setDateFilter({
                              startDate: selectedDate.selection && selectedDate.selection.startDate,
                              endDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                              key: 'selection',
                            });
                            setFilter((item) => ({
                              ...item,
                              pageIndex: 0,
                              relatedLeadId: null,
                              guidAgentId: null,
                              relatedUnitId: null,
                              search: '',
                              filterBy: null,
                              orderBy: null
                            }));
                          }}
                      />
                    </div>
                  </div>
                </div>
                <ViewTypesActivities onTypeChanged={onTypeChanged} className='mb-3' />
              </div>
            </PermissionsComponent>
          </div>
          <PermissionsComponent
            permissionsList={Object.values(ActivitiesLeasePermissions)}
            permissionsId={ActivitiesLeasePermissions.ViewAndSearchInLeaseActivities.permissionsId}
          >
            <div className='d-flex px-2'>
              <span className='mx-2 mt-1'>{t(`${translationPath}select`)}</span>
              <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
              <span className='px-2 d-flex'>
                <span className='texts-large mt-1'>
                  {t(`${translationPath}order-by`)}
                  :
                </span>
                <div className='px-2'>
                  <SelectComponet
                    idRef='ActivityDatefilterByRef'
                    data={[
                      { id: 'CreatedOn', filterBy: 'created-date' },
                      { id: 'UpdateOn', filterBy: 'Updated-date' },
                      { id: 'ActivityDate', filterBy: 'activitie-date' },
                    ]}
                    wrapperClasses='mb-3'
                    isRequired
                    value={selectedOrderBy.filterBy}
                    onSelectChanged={filterByChanged}
                    emptyItem={{
                      value: null,
                      text: 'select-filter-by',
                      isDisabled: false,
                    }}
                    valueInput='id'
                    textInput='filterBy'
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />

                </div>
                <div className='px-2'>
                  <SelectComponet
                    idRef='orderByRef'
                    data={[
                      { id: 1, orderBy: 'ascending' },
                      { id: 2, orderBy: 'descending' },
                    ]}
                    emptyItem={{
                      value: null,
                      text: 'select-sort-by',
                      isDisabled: false,
                    }}
                    value={selectedOrderBy.orderBy}
                    onSelectChanged={orderByChanged}
                    wrapperClasses='mb-3'
                    isRequired
                    valueInput='id'
                    textInput='orderBy'
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='mt-1'>
                  <ButtonBase
                    className='btns theme-solid'
                    onClick={orderBySubmitted}
                    disabled={!selectedOrderBy.filterBy || !selectedOrderBy.orderBy}
                  >
                    <span>{t(`${translationPath}apply`)}</span>
                  </ButtonBase>
                </div>
              </span>
            </div>
          </PermissionsComponent>
        </div>

        {activeActionType === ViewTypes2Enum.tableRelatedView.key && (
          <PermissionsComponent
            permissionsList={Object.values(ActivitiesLeasePermissions)}
            permissionsId={ActivitiesLeasePermissions.ViewAndSearchInLeaseActivities.permissionsId}
          >
            <ActivitiesLeaseTableView
              data={activities.result || []}
              activitiesCount={activities.totalCount || 0}
              parentTranslationPath={parentTranslationPath}
              filter={filter}
              translationPath={translationPath}
              onPageIndexChanged={onPageIndexChanged}
              onPageSizeChanged={onPageSizeChanged}
              isLoading={isLoadingActivities}
              setIsLoading={setIsLoadingActivities}
              isTableRelatedView
              setSortBy={setSortBy}
              activeActionType={activeActionType}
              onFilterValuesChanged={onFilterValuesChanged}
              localStorageKey={'activitiesLease'}


            />
          </PermissionsComponent>
        )}
        {activeActionType === ViewTypes2Enum.tableView.key && (
          <PermissionsComponent
            permissionsList={Object.values(ActivitiesLeasePermissions)}
            permissionsId={ActivitiesLeasePermissions.ViewAndSearchInLeaseActivities.permissionsId}
          >
            <ActivitiesLeaseTableView
              data={activities.result || []}
              activitiesCount={activities.totalCount || 0}
              parentTranslationPath={parentTranslationPath}
              filter={filter}
              translationPath={translationPath}
              onPageIndexChanged={onPageIndexChanged}
              onPageSizeChanged={onPageSizeChanged}
              isLoading={isLoadingActivities}
              setIsLoading={setIsLoadingActivities}
              setSortBy={setSortBy}
              activeActionType={activeActionType}
              onFilterValuesChanged={onFilterValuesChanged}
              localStorageKey={'activitiesLease'}
            />
          </PermissionsComponent>
        )}
      </div>
      {
        openDialog && (
          <ActivitiesManagementDialog
            open={openDialog}
            onSave={() => {
              setOpenDialog(false);
              onPageIndexChanged(0);
            }}
            close={(v, reason) => {
              if (reason && reason === 'backdropClick')
                return;
              setOpenDialog(false);
            }}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            unitOperationId={StaticLookupsIds.Rent}
          />
        )
      }
    </div>
  );
};
