export const productReleaseTemplate = `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link
      href="https://fonts.googleapis.com/css2?family=Londrina+Shadow&family=Montserrat:wght@700&family=Neonderthaw&family=Pacifico&family=Poppins:wght@400;600&family=Rubik+Puddles&display=swap"
      rel="stylesheet"
    />
    <style>

    * {
      padding: 0;
      margin: 0;
    }
    
    .release-note-view-wrapper {
      font-family: 'Poppins', sans-serif;
      max-width: 2460px;
      word-break: break-all;
      overflow-wrap: break-word; 
      }

      .release-note-content {
      height: 100%;
      max-width: 2460px;
      padding-right: 0.5rem;
      padding-left: 2rem;
      overflow-y: auto;
      overflow-wrap: break-word; 
      word-break: break-all;
      }
      iframe, figure, video, image {  
        max-width: 95%;
        padding: 0 !important;
      }
      .release-details-wrapper {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      word-break: break-all;
      overflow-wrap: break-word; 
      }
      .release-details-wrapper .version-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #e0592a;
      }
      .release-details-wrapper .x-large-text {
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: -0.02em;
      color: #101828;
      margin-top: 0.75rem;
      margin-bottom: 1rem;
      }
      .release-details-wrapper .description {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #475467;
      margin-bottom: 2rem;
      }
      .release-details-wrapper .alert-box {
      height: fit-content;
      padding: 1rem;
      margin-bottom: 2rem;
      background: #f9fafb;
      border-radius: 12px;
      color: #475467;
      }
      .release-details-wrapper .box-item-1 {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      }
      .release-details-wrapper .box-item-2 {
      margin-top: 1rem;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #30374f;
      text-transform: uppercase;
      word-break: break-word;
      }
      .release-details-wrapper .box-item-3 {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #364152;
      word-wrap: break-word;
      }
      .accordion-component-wrapper {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      }
      .accordion-component-wrapper .accordion-header {
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      text-transform: uppercase;
      }
      .accordion-title-container {
      display: flex;
      justify-content: space-between;
      padding-top: 1rem;
      padding-bottom: 1rem;
      }
      .accordion-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      color: #e15626;
      text-shadow: 0 0 black;
      }
      
      .sun-editor .se-wrapper .se-wrapper-wysiwyg {
        min-height: fit-content !important;
      }
    
      .sun-editor .se-wrapper .se-wrapper-code {
        min-height: fit-content !important;
      }
      
    </style>
  </head>
  <body>
    <div class="release-note-view-wrapper">
      <div class="release-note-content">
        <div class="release-details-wrapper">
          <p class="version-title">
            Version {{{versionNumber}}}, {{#productTypeDto}} {{{productTypeDto.productTypeName}}}
            {{/productTypeDto}} Release
          </p>
          <p class="x-large-text">
          {{#dateFormatter}}{{releaseDate}}{{/dateFormatter}} - {{#productTypeDto}} {{{productTypeDto.productTypeName}}}
            {{/productTypeDto}} version {{{versionNumber}}}
          </p>
          <p class="description">{{{summary}}}</p>
          <div class="alert-box">
            <p class="box-item-1">
              {{#dateFormatter}}{{releaseDate}}{{/dateFormatter}}
            </p>
            <p class="box-item-2">{{{versionName}}}</p>
            <p class="box-item-3">{{{description}}}</p>
          </div>
        </div>

        {{#releaseNotes}}
        <div class="accordion-component-wrapper">
          <p class="accordion-header">{{{categoryName}}}</p>
          {{#categoryReleaseNotes}}
          <div class="accordion-item">
            <div class="accordion-title-container">
              <div class="accordion-title">{{{title}}}</div>
            </div>
            <div class="accordion-content">{{{content}}}</div>
            {{/categoryReleaseNotes}}
          </div>
          {{/releaseNotes}}
        </div>
      </div>
    </div>
  </body>
</html>

`;
