import React, { useCallback, useEffect, useState } from "react";
import { VicinitiesTable } from "./VicinitiesTable";
import { useTitle } from "../../../../../../Hooks";
import { GetAllPropertyVicinitiesAPI } from "../../../../../../Services";
import { GetParams } from "../../../../../../Helper";
import { Spinner , PermissionsComponent  } from "../../../../../../Components";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@material-ui/core";
import { VicinitiesManagementDialog } from "./VicinitiesManagementDialog";
import { TableActions } from "../../../../../../Enums";
import { DeleteDialog } from "./DeleteDialog";
import { PropertiesPermissionsCRM  } from '../../../../../../Permissions' ; 

const parentTranslationPath = "PropertiesProfileManagementView";
const translationPath = "";

export const VicinitiesViewComponent = () => {
  const { t } = useTranslation(parentTranslationPath);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [vicinities, setVicinities] = useState({
    result: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState({
    pageSize: 25,
    pageIndex: 0,
  });
  const [activeItem, setActiveItem] = useState(null);

  useTitle(t(`${translationPath}vicinities`));
  const getAllVicinities = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllPropertyVicinitiesAPI({
      propertyId: +GetParams("id"),
      pageIndex: filter.pageIndex + 1,
      pageSize: filter.pageSize,
    });
    if (!(res && res.status && res.status !== 200)) {
      setVicinities({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setVicinities({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, []);
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageSize }));
  };
  const reloadData = () => {
    getAllVicinities();
  };
  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      if (actionEnum === TableActions.deleteText.key) {
        setIsDeleteDialogOpen(true);
        setActiveItem(item);
      } else if (actionEnum === TableActions.editText.key) {
        setIsDialogOpen(true);
        setActiveItem(item);
      }
    },
    []
  );
  const onClose = () => {
    setIsDialogOpen(false);
    setActiveItem(null);
    setIsDeleteDialogOpen(false);
  };
  useEffect(() => {
    getAllVicinities();
  }, [getAllVicinities]);
  const addNewHandler = () => {
    setIsDialogOpen(true);
  };
  return (
    <div className="units-Properties-wrapper childs-wrapper">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="title-section">
        <span>{t(`${translationPath}vicinities`)}</span>
      </div>
      <div className="d-flex-column">
        <div className="header-section">
          <div className="filter-section px-2">

            <div className="section">
            <PermissionsComponent
              permissionsList={Object.values(PropertiesPermissionsCRM)}
              permissionsId={PropertiesPermissionsCRM.AddPropertyVicinity.permissionsId}
             >
              <ButtonBase
                className="btns theme-solid px-3"
                onClick={addNewHandler}
              >
                <span className="mdi mdi-plus" />
                {t(`${translationPath}add-new-vicinity`)}
              </ButtonBase>
              </PermissionsComponent>
            </div>
          </div>
        </div>
      </div>
      <div className="px-2 mt-2">
        <VicinitiesTable
          parentTranslationPath={parentTranslationPath}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          translationPath={translationPath}
          vicinities={vicinities}
          filter={filter}
          tableActionClicked={tableActionClicked}
        />
      </div>
      {isDialogOpen && (
        <VicinitiesManagementDialog
          isDialogOpen={isDialogOpen}
          onClose={onClose}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          activeItem={activeItem}
          setVicinities={setVicinities}
          reloadData={reloadData}
          // onSave={}
        />
      )}
      {isDeleteDialogOpen && (
        <DeleteDialog
          open={isDeleteDialogOpen}
          onClose={onClose}
          reloadData={reloadData}
          activeItem={activeItem}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
};
