import { config } from '../config';
import { HttpServices } from '../Helper';

export const GetAllAMLTransactions = async (unitTransactionId, body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/UnitTransaction/GetAllAMLTransactions/${unitTransactionId}`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const TransactionManualApprove = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/UnitTransaction/TransactionManualApprove`, body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const TransactionComplete = async (unitTransactionId) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/UnitTransaction/TransactionComplete/${unitTransactionId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const TransactionManualCancel = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/UnitTransaction/TransactionManualCancel`, body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllTrackedTransactions = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/UnitTransaction/GetAllTrackedTransactions`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllBranchTrackedTransactions = async (userBranchId, body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/UnitTransaction/GetAllBranchTrackedTransactions?branchId=${userBranchId}`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllMyTrackedTransactions = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/UnitTransaction/GetAllMyTrackedTransactions`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllMyAMLTransactions = async (unitTransactionId, body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/UnitTransaction/GetAllMyAMLTransactions/${unitTransactionId}`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateAMLTransactionStatus = async (contactId, isApproved) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/UnitTransaction/UpdateAMLTransactionStatus?contactId=${contactId}1&isApproved=${isApproved}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const AddAMLRevision = async (amlTransactionId, comment) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/UnitTransaction/AddAMLRevision/${amlTransactionId}?comment=${comment}`)
    .then((data) => data)
    .catch((error) => {
      console.error('Error during API request:', error);
      return error.response;
    });
  return result;
};

export const TransactionReassignedToRequester = async (body) => {
  const result = await HttpServices.put(`${config.server_address}/CrmDfm/UnitTransaction/TransactionReassignedToRequester`, body)
    .then((data) => data)
    .catch((error) => {
      console.error('Error during API request:', error);
      return error.response;
    });
  return result;
};

export const TransactionWithdraw = async (body) => {
  const result = await HttpServices.put(`${config.server_address}/CrmDfm/UnitTransaction/TransactionWithdraw`, body)
    .then((data) => data)
    .catch((error) => {
      console.error('Error during API request:', error);
      return error.response;
    });
  return result;
};

export const GetTrackedTransactionDocuments = async (amlTransactionId) => {
  const result = await HttpServices.get(`${config.server_address}/CrmDfm/UnitTransaction/GetTrackedTransactionDocuments/${amlTransactionId}`)
    .then((data) => data)
    .catch((error) => {
      console.error('Error during API request:', error);
      return error.response;
    });
  return result;
};

export const GetAMLTrackedTransactionDocuments = async (amlTransactionId) => {
  const result = await HttpServices.get(`${config.server_address}/CrmDfm/UnitTransaction/GetAMLTransactionDocuments/${amlTransactionId}`)
    .then((data) => data)
    .catch((error) => {
      console.error('Error during API request:', error);
      return error.response;
    });
  return result;
};


export const UploadTransactionDocuments = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/UnitTransaction/UploadTransactionDocuments`, body)
    .then((data) => data)
    .catch((error) => {
      console.error('Error during API request:', error);
      return error.response;
    });
  return result;
};

export const UploadAMLTransactionDocuments = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/UnitTransaction/UploadAMLTransactionDocuments`, body)
    .then((data) => data)
    .catch((error) => {
      console.error('Error during API request:', error);
      return error.response;
    });
  return result;
};


export const TransactionResubmit = async (unitTransactionId) => {
  const result = await HttpServices.put(`${config.server_address}/CrmDfm/UnitTransaction/TransactionResubmit/${unitTransactionId}`)
    .then((data) => data)
    .catch((error) => {
      console.error('Error during API request:', error);
      return error.response;
    });
  return result;
};

export const DeleteAMlTransactionDocument = async (unitTransactionId) => {
  const result = await HttpServices.delete(`${config.server_address}/CrmDfm/UnitTransaction/DeleteAMlTransactionDocument/${unitTransactionId}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};


export const GetAmlTransactionHistory = async (unitTransactionId) => {
  const result = await HttpServices.get(`${config.server_address}/CrmDfm/UnitTransaction/GetAmlTransactionHistory/${unitTransactionId}`)
    .then((data) => data)
    .catch((error) => {
      console.error('Error during API request:', error);
      return error.response;
    });
  return result;
};


export const ExportAMLHistory = async (unitTransactionId, isSaleTransaction) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/UnitTransaction/ExportAMLHistory?unitTransactionId=${unitTransactionId}&isSaleTransaction=${isSaleTransaction}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

