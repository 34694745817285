
import React, { useState, useCallback, useEffect , useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Inputs, AutocompleteComponent, SwitchComponent } from '../../../../../../../Components';
import { getErrorByName } from '../../../../../../../Helper';
import { GetAllPortalAPI, GetAllBranches } from '../../../../../../../Services';
import { DeleteBranchesDialog } from './DeleteBranchesDialog'

export const PortalAccountContentDialog = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
  schema,
  isSubmitted,
  selected,
  setSelected,
  editPortalAccount
}) => {


  const { t } = useTranslation(parentTranslationPath);
  const [allBranches, setAllBranches] = useState({
    result: [],
    totalCount: 0,
  });

  const searchTimer = useRef(null);
  const [isBranchDeleted, setIsBranchDeleted] = useState(false)
  const [isOpenDeleteBranch, setIsOpenDeleteBranch] = useState(false)
  const [newBranches, setNewBranches] = useState([])
  const [deletedBranchId, setDeletedBranchId] = useState(null)
  const [allPortals, setAllPortals] = useState({
    result: [],
    totalCount: 0,
  });
  const [isLoadingData, setIsLoadingData] = useState
    ({
      branches: false,
      portals: false,
    });

  const GetAllPortal = useCallback(async () => {
    setIsLoadingData((item) => ({ ...item, portals: true }));
    const res = await GetAllPortalAPI({ pageSize: 1000, pageIndex: 1 });
    if (!(res && res.status && res.status !== 200))
      setAllPortals({
        result: res.result,
        totalCount: res.totalCount
      });
    else setAllPortals({
      result: [],
      totalCount: 0
    });

    setIsLoadingData((item) => ({ ...item, portals: false }))
  }, []);

  const GetAllBranchesAPI = useCallback(async (branchName) => {
    setIsLoadingData((item) => ({ ...item, branches: true }));
    const res = await GetAllBranches({ pageSize: 1000, pageIndex: 1  , branchName: branchName  });
    if (!(res && res.status && res.status !== 200)) setAllBranches({
      result: res.result,
      totalCount: res.totalCount
    });
    else setAllBranches({
      result: [],
      totalCount: 0
    });

    setIsLoadingData((item) => ({ ...item, branches: false }))
  }, []);

  useEffect(() => {
    GetAllPortal();
    GetAllBranchesAPI();
  }, [GetAllBranchesAPI ,GetAllPortal]);

  useEffect(() => {
    if (isBranchDeleted) {
      setSelected((item) => ({ ...item, listOfPortalAccountBranches: newBranches }));
      const branchIds = (newBranches && newBranches.map((e) => e.branchId));
      setState({ id: 'branchIds', value: (branchIds || []) });


    }
    setIsBranchDeleted(false)
  }, [isBranchDeleted, newBranches]);

  return (
    <div className='dialog-content-wrapper'>
      <div className='dialog-content-item mt-2 w-100'>
        <Inputs
          labelClasses='Requierd-Color'
          idRef='accountNameRef'
          labelValue={t(`${translationPath}accountName`)}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          value={state && state.accountName}
          onInputChanged={(e) => {
            setState({ id: 'accountName', value: e.target.value })
          }}
          helperText={getErrorByName(schema, 'accountName').message}
          error={getErrorByName(schema, 'accountName').error}
          isWithError
          isSubmitted={isSubmitted}
        />
      </div>
      <div className='dialog-content-item mt-2 w-100'>
        <AutocompleteComponent
          idRef='portalRef'
          labelValue='portalName'
          labelClasses='Requierd-Color'
          isDisabled={(editPortalAccount && true) || false}
          multiple={false}
          value={selected.portal}
          selectedValues={selected.portal}
          data={(allPortals && allPortals.result) || []}
          chipsLabel={(option) => (option && option.portalName) || ''}
          displayLabel={(option) => (option && option.portalName) || ''}
          withoutSearchButton
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            setState({ id: 'portalId', value: (newValue && newValue.portalId) });
            setSelected((item) => ({ ...item, portal: newValue }))
          }}
          withLoader
          isLoading={isLoadingData.portals}
          isWithError
          helperText={getErrorByName(schema, 'portalId').message}
          error={getErrorByName(schema, 'portalId').error}
          isSubmitted={isSubmitted}
        />
      </div>
      <div className='dialog-content-item mt-2 w-100'>
        
      
        <AutocompleteComponent
              idRef='branchesRef'
              labelValue='branches'
              data={(allBranches && allBranches.result) || []}
              selectedValues={(selected && selected.listOfPortalAccountBranches) || []}
              multiple
              displayLabel={(option) =>
                (option && option.branchName)|| ''}
              chipsLabel={(option) =>  (option && option.branchName) || ''}
              withoutSearchButton
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                 if (searchTimer.current) clearTimeout(searchTimer.current);
                   searchTimer.current = setTimeout(() => {
                    GetAllBranchesAPI(value) ;  
                }, 700); 
                }}
                onChange={(event, newValue) => {
                  const branchIds = (newValue && newValue.map((e) => e.branchId));
                  if (editPortalAccount && selected?.listOfPortalAccountBranches?.length > newValue.length) {
                    let deletedItem = selected.listOfPortalAccountBranches.filter(item => !newValue.includes(item));
                    setDeletedBranchId(deletedItem[0]?.branchId)
                    setNewBranches(newValue);
                    setIsOpenDeleteBranch(true);
                  }
                  else {
                    setSelected((item) => ({ ...item, listOfPortalAccountBranches: newValue }))
                    setState({ id: 'branchIds', value: (branchIds || []) });
                  }
                }}
             isLoading={(isLoadingData && isLoadingData.branches)} 
             withLoader

             filterOptions={(options) => {
              const isFind = (id) => selected.listOfPortalAccountBranches?.findIndex((w) => w.branchId === id) === -1;
              if (editPortalAccount) return options.filter((w) => isFind(w.branchId))
              else return options
            }}
         /> 
      </div>
      <div className='dialog-content- mt-2 w-100'>
        <SwitchComponent
          idRef='statusRef'
          isChecked={state.status}
          themeClass='theme-line'
          labelValue={(state.status && 'active') || 'inactive'}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChangeHandler={(event, isChecked) =>
            setState({ id: 'status', value: isChecked })}
        />
      </div>
      <DeleteBranchesDialog
        isOpenDeleteBranch={isOpenDeleteBranch}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        setIsOpenDeleteBranch={setIsOpenDeleteBranch}
        portalAccountId={editPortalAccount && editPortalAccount.portalAccountId}
        branchId={deletedBranchId}
        setIsBranchDeleted={setIsBranchDeleted}

      />
    </div>
  );
};

PortalAccountContentDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  selected: PropTypes.instanceOf(Object).isRequired,
  setSelected: PropTypes.func.isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  isSubmitted: PropTypes.bool.isRequired,

};



