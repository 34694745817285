import React, {
 useCallback, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DialogComponent, AutocompleteComponent, Spinner } from '../../../Components';
import { showError, showSuccess } from '../../../Helper';
import { AssignLeadAuditorToAgents } from '../../../Services';

export const OpenAssignDialog = ({
    isOpen,
    setIsOpenAssignDialog,
    data,
    checkedCardsIds,
    reloadPage,
    parentTranslationPath,
    translationPath,
    searchHandler,
    isLoading
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const [selectedLeadAuditor, setSelectedLeadAuditor] = useState(null);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [isSaveIsDisabled, setIsSaveIsDisabled] = useState(true);

    const saveHandler = useCallback(async (event) => {
        event.preventDefault();
        setIsLoadingSave(true);
        const body = {
            leadAuditorId: selectedLeadAuditor.leadAuditorId,
            agentsIds: checkedCardsIds
        };
        const res = await AssignLeadAuditorToAgents(body);
        if (!(res && res.status && res.status !== 200)) {
            showSuccess(t(`${translationPath}assign-to-lead-owner-Successfully`));
            reloadPage();
        } else {
            showError(t(`${translationPath}faild-to-assign-to-lead-owner`));
            reloadPage();
        }
        setIsOpenAssignDialog(false);
        setIsLoadingSave(false);
    });
    return (
      <>
        <Spinner isActive={isLoadingSave} />
        <DialogComponent
          isOpen={isOpen}
          titleText='assign-to-lead-owner'
          maxWidth='sm'
          onSaveClicked={saveHandler}
          saveText='Confirm'
          onCancelClicked={() => { setIsOpenAssignDialog(false); }}
          disableBackdropClick
          saveIsDisabled={isLoading || isSaveIsDisabled}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          dialogContent={(
            <>
              <AutocompleteComponent
                idRef='LeadAuditorRef'
                multiple={false}
                data={data && data.result || []}
                chipsLabel={(option) => option.leadAuditorName || ''}
                displayLabel={(option) => option.leadAuditorName || ''}
                onInputKeyUp={searchHandler}
                withoutSearchButton
                labelValue='lead-owner'
                inputPlaceholder='select'
                isLoading={isLoading}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(e, newValue) => {
                                setIsSaveIsDisabled(false);
                                setSelectedLeadAuditor(newValue);
                            }}
              />
            </>
                )}
        />
      </>
    );
};
