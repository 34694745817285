import React from 'react';
export const ReceiptsHeaderData = [
  {
    id: 2,
    label: 'net-amount',
    input: 'netAmount',
    isNumber: true,
  },
  {
    id: 3,
    label: 'contact-type',
    input: 'receiptContactTypeName',
    component: (item) =>
      <span>{item && item.receiptContactTypeName}</span> || '',
  },
  {
    id: 4,
    label: 'date',
    input: 'date',
    isDate: true,
  },
  {
    id: 5,
    label: 'payment-mode',
    input: 'paymentModeName',
    component: (item) => <span>{item && item.paymentModeName}</span> || '',
  },
  {
    id: 6,
    label: 'bank',
    input: 'bankName',
    component: (item) => <span>{item && item.bankName}</span> || '',
  },
  {
    id: 7,
    label: 'branch',
    input: 'branch',
    component: (item) => <span>{item && item.branch}</span> || '',
  },
  {
    id: 8,
    label: 'bank-transfer-no',
    input: 'bankTransferNo',
    component: (item) => <span>{(item && item.bankTransferNo) || 'N/A'}</span>,
  },
  {
    id: 9,
    label: 'receipt-documents',
    component: (item) => <span>{item?.receiptDocuments?.map((file)=>file.fileName)}</span>,
  },
];
