import React from 'react';
import PropTypes from 'prop-types';
import { Tables } from '../../../../Components';
import { LearningUserProfileLocalePath } from '../i18n/LearningUserProfileLocale';
import moment from 'moment';
import { isDate } from 'lodash';

function CertificatesTable({ certificates }) {
  return (
    <div className='pt-3'>
      <Tables
        headerData={[
          { label: 'course-name', input: 'courseName' },
          {
            label: 'issue-date',
            input: 'issueDate',
            component: (row) =>
              moment(row.issueDate, 'DD/MM/YYYY, HH:mm:ss').format(
                'YYYY-MM-DD'
              ),
          },
          {
            label: 'expiration-date',
            input: 'expirationDate',
            component: (row) =>
              isDate(row.expirationDate)
                ? moment(row.expirationDate, 'DD/MM/YYYY, HH:mm:ss').format(
                    'YYYY-MM-DD'
                  )
                : row.expirationDate,
          },
        ]}
        data={certificates}
        activePage={0}
        parentTranslationPath={LearningUserProfileLocalePath}
        itemsPerPage={certificates?.length}
        defaultActions={[]}
      />
    </div>
  );
}

CertificatesTable.propTypes = {
  certificates: PropTypes.array.isRequired,
};

export default CertificatesTable;
