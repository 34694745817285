import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useTitle } from '../../../Hooks';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../Components';
import { ActiveUserLogTableView } from './ActiveUserLogTableView/ActiveUserLogTableView';

const parentTranslationPath = 'ActiveUserLog';

export const ActiveUserLogView = () => {
  useTitle('ActiveUserLog');
  const { t } = useTranslation(parentTranslationPath);

  return (
    <div className='Active-user-log  view-wrapper'>
      <div className='w-100 px-2'>
        <div className='header-section-ActiveUser'></div>

        <div className='w-100 px2'>
          <ActiveUserLogTableView
            parentTranslationPath={parentTranslationPath}
          />
        </div>
      </div>
    </div>
  );
};
