import React, { useEffect, useState } from 'react';
import { Inputs } from '../../../../../../Components';
import { useTranslation } from 'react-i18next';
import './UnitsBulkStatusUpdateDialog.scss';
import { CopyToClipboardComponents } from '../../../../../../ReusableComponents/UtilityComponents';

function UnitBulkPublishDialog({
  checkedCards,
  unitData,
  setUnitData,
  isSubmitted,
  isLease,
  translationPath,
  parentTranslationPath,
  schema2,
  schemaErrors,
  fieldsNeedsApproval,
}) {
  const { t } = useTranslation(parentTranslationPath);

  const [isPriceDisabled, setIsPriceDisabled] = useState(false);
  const handleInputChange = (event, unitId) => {
    const { value } = event.target;
    setUnitData((prevUnitData) => {
      return prevUnitData.map((unit) => {
        if (unit.unitId === unitId && isLease === false) {
          return {
            ...unit,
            sellingPrice: +value,
            rentPerYearPrice: 0,
          };
        } else if (unit.unitId === unitId && isLease === true) {
          return {
            ...unit,
            sellingPrice: 0,
            rentPerYearPrice: +value,
          };
        }
        return unit;
      });
    });
  };

  const CheckPropertyName = (item) => {
    const ProbInfo = {
      refNo: checkedCards.find((card) => card.id === item.unitId)?.refNo,
      propertyName: checkedCards.find((card) => card.id === item.unitId)
        ?.propertyName,
    };
    return (
      <div className='flex-column'>
        <p>
          <b> {ProbInfo.propertyName} </b>
        </p>

        <div className='w-50'>
          <CopyToClipboardComponents
            data={ProbInfo.refNo}
            childrenData={ProbInfo.refNo}
            CustomizationClassName='block-container'
          />
        </div>
      </div>
    );
  };

  const getError = (index) => schemaErrors.find((item) => item.index === index);

  useEffect(() => {
    if (fieldsNeedsApproval?.length) {
      let keysToCheck = '';

      if (isLease === false) {
        keysToCheck = 'selling_price_agency_fee';
      } else if (isLease === true) {
        keysToCheck = 'rent_price_fees';
      }

      const hasRequiredKeys = fieldsNeedsApproval.includes(keysToCheck);

      setIsPriceDisabled(hasRequiredKeys);
    }
  }, [fieldsNeedsApproval, isLease]);

  return (
    <>
      <div className='publish-dialog-data-and-inputs-container'>
        {unitData.map((item, index) => (
          <>
            <div className='d-flex'>
              <div className='w-25'>
                <CheckPropertyName {...item} />
              </div>
              <div className='d-flex-v-center'>
                <Inputs
                  key={index}
                  isDisabled={isPriceDisabled}
                  wrapperClasses='w-33'
                  idRef={`newPriceRef_${index}`}
                  labelValue={t(`${translationPath}enter-new-price`)}
                  labelClasses='Requierd-Color'
                  value={isLease ? item.rentPerYearPrice : item.sellingPrice}
                  onInputChanged={(event) =>
                    handleInputChange(event, item.unitId)
                  }
                  type={'number'}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  isSubmitted={isSubmitted}
                  helperText={
                    getError(index)?.error ||
                    (isPriceDisabled &&
                      t('price-changed-approval'))
                  }
                  error={!!getError(index)}
                />
                {isLease === true && (
                  <>
                    <h3> /{t('year')}</h3>
                  </>
                )}
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
}

export default UnitBulkPublishDialog;
