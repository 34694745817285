

export const  AgentsRotationLogPermissions  = {
    AgentsRotationLog: {
      permissionsId:'5ce34746-fe14-4128-a891-08da6e2b177d',
      permissionsName:'Get All Agents In Rotation Log',
      componentsId:null,
      components:null
    }
  };
  