import React, {
  useRef, useState, useEffect, useCallback
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { uploadFile } from '../../Services';
import { InputThemeComponent } from '../../Components/UploaderComponent/UploaderThemes/InputThemeComponent/InputThemeComponent';
import { LoadableVideoComponant, Spinner } from '../../Components';
import { getDownloadableLink, showError, showSuccess } from '../../Helper';
import { getMimeTypeHandler } from '../../Utils';
import image from '../../assets/images/defaults/loader.gif';


export const VideoUploader = ({
  wrapperClasses,
  uploaderClasses,
  counterClasses,
  inputClasses,
  translationPath,
  parentTranslationPath,
  translationPathShared,
  multiple,
  initUploadedFiles,
  uploadedChanged,
  isDisabled,
  chipsDisabled,
  chipHandler,
  viewUploadedFilesCount,
  setIsUploading,
  isUploading
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const uploadRef = useRef(null);
  const [allVideoFiles, setAllVideoFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);

  const uploadHandler = (files, index) => {
    setIsUploading(true);
    Promise.all(files.length && files.map(async (item) => {
      await uploadFile({ file: item.file }).then((response) => {
        uploadedFiles.push(response);
        const localUploadedFiles = uploadedFiles || [{ ...response }];
        setUploadedFiles(localUploadedFiles);
        uploadedChanged(localUploadedFiles);
        setAllVideoFiles((items) => {

          const fileIndex = items.findIndex((element) => element.id === item.id);
          if (fileIndex !== -1) {
            items[fileIndex].uuid = response.uuid;
            items[fileIndex].status = 'success';
          }
          return [...items];
        });
        showSuccess(t(`${translationPathShared}video-upload-succeeded`));
      })
        .catch(() => {
          setAllVideoFiles((items) => {
            const fileIndex = items.findIndex((element) => element.id === item.id);

            if (fileIndex !== -1) items[fileIndex].status = 'failed';

            items.pop();
            return [...items];
          });
          showError(t(`${translationPathShared}upload-video-with-this-size-failed`));
        })
    })).then(()=>setIsUploading(false))
  };

  const dropHandler = (event) => {

    event.preventDefault();
    if (isDisabled) return;
    if (!event.dataTransfer.files) return;

    setIsDragOver(false);
    let files = [];

    Object.values(event.dataTransfer.files).forEach(async (file) => {
      if (file.type && file.type.includes('video')) {

        files.push({
          id: allVideoFiles.length + files.length,
          uuid: null,
          fileName: file.name,
          size: file.size,
          type: file.type,
          file,
          status: 'uploading',
        });

      } else showError(t(`${translationPathShared}files-that-are-not-videos-cannot-be-uploaded`));
    });
    setAllVideoFiles((items) => [...items, ...files]);
    uploadHandler(files);
    if (!files.length) setIsUploading(false)

  };

  const fileDeleted = useCallback(
    (item, index) => () => {
      const uploadedFilesIndex = uploadedFiles.findIndex((element) => element.uuid === item.uuid);
      if (uploadedFilesIndex !== -1) {
        const localFiles = [...uploadedFiles];
        localFiles.splice(uploadedFilesIndex, 1);
        uploadedChanged(localFiles);
        setUploadedFiles(localFiles);
      }
      const localFiles = [...uploadedFiles];
      localFiles.splice(uploadedFilesIndex, 1);
      setUploadedFiles(localFiles);
      setAllVideoFiles((items) => {
        items.splice(index, 1);
        return [...items];
      });
    },
    [uploadedChanged, uploadedFiles]
  );

  const inputChanged = (event) => {
    if (!event.target.value) return;
    let files = [];

    Object.values(event.target.files).forEach((file) => {
      if (file.type && file.type.includes('video')) {

        files.push({
          id: allVideoFiles.length + files.length,
          uuid: null,
          fileName: file.name,
          size: file.size,
          type: file.type,
          file,
          status: 'uploading',
        });

      } else showError(t(`${translationPathShared}files-that-are-not-videos-cannot-be-uploaded`));
    });
    uploadHandler(files);
    setAllVideoFiles((items) => [...items, ...files]);
    event.target.value = null;
    if (!files.length) setIsUploading(false)
  };

  const removeVideoClicked = (item) => {

    const removedFileIndex = uploadedFiles.findIndex((element) => element.uuid === item.uuid);
    if (removedFileIndex !== -1) {
      const localUploadedFiles = [...uploadedFiles];
      localUploadedFiles.splice(removedFileIndex, 1);
      uploadedChanged(localUploadedFiles);
      setUploadedFiles(localUploadedFiles);
    }
    const localUploadedFiles = [...uploadedFiles];
    localUploadedFiles.splice(removedFileIndex, 1);
    setUploadedFiles(localUploadedFiles);

    const localAllVideoFiles = allVideoFiles.filter((element) => element.uuid !== item.uuid);
    setAllVideoFiles(localAllVideoFiles);
  };



  useEffect(() => {
    // if(allVideoFiles && allVideoFiles.length == 0){
      if (initUploadedFiles && initUploadedFiles.length > 0) {
        setUploadedFiles(initUploadedFiles);
        const initUploadedVideoFiles = initUploadedFiles.filter(item => {
          const isVideoFile = item.fileName && getMimeTypeHandler(item.fileName).isVideo === true
          return isVideoFile
        })
        setAllVideoFiles(initUploadedVideoFiles);
      } else {
        setAllVideoFiles([]);
        setUploadedFiles([]);
      }
    // }
  }, [initUploadedFiles]);


  return (
    <div className={wrapperClasses}>
      <input
        ref={uploadRef}
        type='file'
        className={inputClasses}
        multiple={multiple}
        accept={'video/*'}
        onChange={inputChanged}
        disabled={isDisabled || isUploading}
      />
      <div
        className={uploaderClasses}
        onDragOver={(event) => {
          event.preventDefault();
          if (isDisabled) return;
          if (!isDragOver) setIsDragOver(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setIsDragOver(false);
        }}
        onDrop={dropHandler}
      >
        <InputThemeComponent
          allFiles={allVideoFiles}
          isDragOver={isDragOver}
          translationPathShared={translationPathShared}
          fileDeleted={fileDeleted}
          chipClicked={chipHandler}
          uploadRef={uploadRef}
          multiple={multiple}
          accept={'video/*'}
          isDisabled={isDisabled}
          chipsDisabled={chipsDisabled}
        />

        {viewUploadedFilesCount && (
          <span className={counterClasses}>
            {`${allVideoFiles.length} ${t(
              `${translationPathShared}videos-uploaded`)
              }`}
          </span>
        )}
      </div>
      <div className='GalleryComponent'>
        <Spinner isActive={isUploading} />
        {allVideoFiles.length > 0 &&
          allVideoFiles.map((item) => (
            item.uuid&&(<span className='All-documents document-card'>
              <LoadableVideoComponant
                classes='cover-image'
                hideVideoControls
                src={(item.uuid && getDownloadableLink(item.uuid))}
                alt={t(`${translationPath}cover-image`)}
                width={150}
                // fileId={item.uuid && getDownloadableLink(item.uuid)}
                height={100}
                setIsUploading={setIsUploading}
                isUploading={isUploading}
              />
              <div className='file-icons-container'>
                <span
                  className='mdi mdi-delete-circle-outline c-warning delete-button'
                  onClick={() => removeVideoClicked(item)}
                />
              </div>
            </span>)
          ))}
        {(isUploading &&
          <span className='All-documents document-card'>

            <img
              src={image}
              alt={t(`${translationPath}cover-image`)}
            // className={`${classes} ${!state ? ' show' : ' hidden'}`}
            />
          </span>
        )}

      </div>
    </div>
  );
};
VideoUploader.propTypes = {
  initUploadedFiles: PropTypes.instanceOf(Array),
  wrapperClasses: PropTypes.string,
  uploaderClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  translationPath: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  translationPathShared: PropTypes.string,
  multiple: PropTypes.bool,
  chipsDisabled: PropTypes.func,
  chipHandler: PropTypes.func,
  Disabledimg: PropTypes.func,
  uploadedChanged: PropTypes.func,
  isDisabled: PropTypes.bool,
  dropHereText: PropTypes.string,
};
VideoUploader.defaultProps = {
  initUploadedFiles: [],
  counterClasses: 'counter-text',
  wrapperClasses: 'uploader-wrapper',
  uploaderClasses: 'uploader-container',
  inputClasses: 'file-input',
  translationPath: '',
  parentTranslationPath: '',
  translationPathShared: 'Shared:uploaderComponent.',
  multiple: false,
  chipsDisabled: () => false,
  chipHandler: undefined,
  Disabledimg: () => false,
  uploadedChanged: undefined,
  isDisabled: false,
  dropHereText: 'drop-here',
  setIsUploading: () => { },
  isUploading: false
};

