export const ProjectTaskStatus = {
    toDoTasks: {
      id: 1,
      key: "toDoTasks",
      label: 'To-Do',
      classes: 'bg-col-blue',
    },
    completedTasks: {
      id: 2,
      key: "completedTasks",
      label: 'Completed',
      classes: 'bg-col-green',
    },
    canceledTasks: {
      id: 3,
      key: "canceledTasks",
      label: 'Canceled',
      classes: 'bg-col-red',
    },
  };
  
