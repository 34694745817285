export const AMLReleaseType = {
  1: {
    name: 'AutoSystemRelease ',
    value: 1,
  },
  2: {
    name: 'ManualSystemRelease ',
    value: 2,
  },
  3: {
    name: 'ManualReleaseBy  ',
    value: 3
  },
};
