export const PropertiesProfileSpecificationTabsData = [
  { tab: 'apartment' },
  { tab: 'villa' },
  { tab: 'retail-unit' },
  { tab: 'office' },
  { tab: 'labor-camp' },
  { tab: 'warehouse' },
  { tab: 'studio' },
  { tab: 'townhouse' },
  { tab: 'penthouse' },
];
