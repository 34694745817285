import { PowerBiEnum } from "../../../../Enums";
import { MainSaleAgentTabDashboards } from "../MainSaleAgentTabDashboards/MainSaleAgentTabDashboards";
import { SaleAgentDashboardPermissions } from '../../../../Permissions' ; 

export const SaleAgentDashboardTabs = [
  {
    index: 0,
    label: "Dashboard:saleagent",
    component: MainSaleAgentTabDashboards,
    config: {
      reportId: PowerBiEnum.agentToken.reportid,
      groupId: PowerBiEnum.agentToken.groupid,
      Url: PowerBiEnum.agentToken.url,
    },
    permissionsId : SaleAgentDashboardPermissions.SaleAgentDashboard.permissionsId, 
  },
  {
    index: 1,
    label: "Dashboard:saleagent+",
    component: MainSaleAgentTabDashboards,
    config: {
      reportId: PowerBiEnum.SalesAgentplus.reportid,
      groupId: PowerBiEnum.SalesAgentplus.groupid,
      Url: PowerBiEnum.SalesAgentplus.url,
    },
  
    permissionsId : SaleAgentDashboardPermissions.SalesAgentPlusDashboard.permissionsId, 
  },
 
];
