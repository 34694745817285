import React, { useEffect, useState } from "react";
import { DialogComponent, Spinner } from "../../../../../Components";
import { GetAmlTransactionHistory } from "../../../../../Services";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@material-ui/lab";
import { makeStyles, Typography } from "@material-ui/core";
import { CopyToClipboardComponents } from "../../../../../ReusableComponents/UtilityComponents";
import moment from "moment";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { showError } from "../../../../../Helper";

const useStyles = makeStyles({
  timeLine: {
    padding: 0,
  },
  timeLineLeftSection: {
    padding: 0,
  },
  connector: {
    minHeight: "10px",
    color: "red",
    backgroundColor: "#cfd4dc",
  },
  timeLineDot: {
    backgroundColor: "#3b72d9",
    border: "medium solid #88aae8",
    position: "absolute",
    top: "16px",
    left: "0px",
  },
  infoContainer: {
    paddingRight: "10px",
  },
  clipBoard: {
    color: "blue",
  },
  leftSideDaysAndMonths: {
    margin: 0,
    color: "#99999e",
    fontSize: "18px",
    fontWeight: "500",
    maxWidth: "100px",
    textAlign: "center",
  },
  leftSideYears: {
    margin: 0,
    color: "#99999e",
    fontSize: "26px",
    fontWeight: "800",
    textAlign: "center",
  },
  leftSideAmPm: {
    margin: 0,
    color: "#99999e",
    fontWeight: "500",
    maxWidth: "100px",
    whiteSpace: "nowrap",
    textAlign: "center",
  },
  separator: {
    position: "relative",
    paddingRight: "6px",
    paddingLeft: "6px",
  },
  oppositeContent: {
    maxWidth: "100px",
  },
  timeLineItem: {},
  activityStatus: {
    color: "#3b72d9",
    margin: 0,
  },
  activityAction: {
    color: "#000",
    fontSize: "17px",
    fontWeight: "500",
  },
  arrowIcon: {
    width: "20px",
    padding: "10px 0px 0px 0px",
    height: "20px",
  },
  hr: {
    border: 0,
    borderTop: "1px solid #eee",
    margin: "18px 0px 10px 0px",
  },
  reason: {
    color: "#878b93",
    fontSize: "12px",
    fontWeight: "500",
  },
});

const ReadMoreText = ({ t, text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 100;

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const truncatedText =
    text.length > maxLength ? text.substring(0, maxLength) : text;

  return (
    <span>
      {isExpanded ? text : truncatedText}
      {text.length > maxLength && (
        <span
          onClick={toggleReadMore}
          style={{ color: "blue", cursor: "pointer" }}
        >
          {isExpanded ? ` ${t("less")}` : `...${t("more")}`}
        </span>
      )}
    </span>
  );
};

function AMLHistoryDialog({
  isOpen,
  isAmlHistoryDialogOpen,
  setIsAmlHistoryDialogOpen,
  IDS,
  t,
  translationPath,
  parentTranslationPath,
}) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const classes = useStyles();

  const GetAmlTransactionHistoryAPICall = async () => {
    setIsLoading(true);
    const res = await GetAmlTransactionHistory(IDS);
    if (!(res && res.status && res.status !== 200)) {
      setData(res);
      setCurrentStatus(res?.transactionHistory[0]?.action || null);
    } else {
      showError(t("error-fetching-transaction-history"));
    }
    setIsLoading(false);
  };

  const confirmHandler = () => {
    setIsAmlHistoryDialogOpen(false);
  };

  useEffect(() => {
    GetAmlTransactionHistoryAPICall();
  }, []);

  return (
    <div>
      <DialogComponent
        titleText={t("history")}
        saveText={t("confirm")}
        saveType="button"
        maxWidth="md"
        isOpen={isOpen}
        onSaveClicked={() => {
          confirmHandler();
        }}
        onCloseClicked={() => {
          confirmHandler();
        }}
        dialogContent={
          <React.Fragment>
            <Spinner isActive={isLoading} />
            <div className="mt-1 mb-4">
              <span className={classes?.infoContainer}>
                {t("transacted-by")}: <b>{data?.transactionBy ?? "N/A"}</b>
              </span>
              <span className={classes?.infoContainer}>
                {t("transacted-type")}: <b>{data?.transactionType ?? "N/A"}</b>
              </span>
              <span className={classes?.infoContainer}>
                {t("transaction-id")}:
                <CopyToClipboardComponents
                  data={data?.unitTransactionId}
                  childrenData={`#${data?.unitTransactionId ?? "N/A"}`}
                  CustomizationClassName={classes?.clipBoard}
                />
              </span>
              <span className={classes?.infoContainer}>
                {t("current-status")}: <b>{t(currentStatus) ?? "N/A"}</b>
              </span>
            </div>
            <div>
              <Timeline align="left" className={classes?.timeLine}>
                {data?.transactionHistory?.map((activity, index) => (
                  <React.Fragment>
                    <TimelineItem key={index} className={classes?.timeLineItem}>
                      <TimelineOppositeContent
                        className={classes?.oppositeContent}
                      >
                        <Typography className={classes?.timeLineLeftSection}>
                          <p className={classes?.leftSideDaysAndMonths}>
                            {activity?.createdOn
                              ? moment(activity?.createdOn).format("MMM D")
                              : "N/A"}
                          </p>
                          <p className={classes?.leftSideYears}>
                            {activity?.createdOn
                              ? moment(activity?.createdOn).format("YYYY")
                              : "N/A"}
                          </p>
                          <p className={classes?.leftSideAmPm}>
                            {activity?.createdOn
                              ? moment(activity?.createdOn).format("h:mm a")
                              : "N/A"}
                          </p>
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator className={classes?.separator}>
                        <TimelineDot className={classes?.timeLineDot} />
                        <TimelineConnector className={classes?.connector} />
                      </TimelineSeparator>
                      <TimelineContent>
                        <div>
                          <fieldset className="input-fieldset my-3">
                            <div className="mx-2">

                              <p className={classes?.activityAction}>
                                {activity?.action?.toLowerCase() === "pass"
                                  ? t("approve")
                                  : activity?.action?.toLowerCase() === "fail"
                                  ? t("fail")
                                  : activity?.action?.toLowerCase() ===
                                    "pending"
                                  ? t("pending")
                                  : activity?.action?.toLowerCase() ===
                                    "need revision"
                                  ? t("need-revision")
                                  : activity?.action?.toLowerCase() ===
                                    "withdraw transaction"
                                  ? t("withdraw-transaction")
                                  : activity?.action?.toLowerCase() ===
                                    "auto withdraw"
                                  ? t("auto-withdraw")
                                  : activity?.action?.toLowerCase() ===
                                    "resubmitted"
                                  ? t("resubmitted")
                                  : activity?.action?.toLowerCase() ===
                                    "approved"
                                  ? t("approved")
                                  : activity?.action?.toLowerCase() ===
                                    "rejected"
                                  ? t("rejected")
                                  : activity?.action?.toLowerCase() ===
                                    "reassigned to requester"
                                  ? t("reassigned-to-requester")
                                  : null}
                              </p>

                              <p className="d-flex-column-v-between">
                                {t("action-created-by")}:{" "}
                                <b>{activity?.actionCreatedBy || "N/A"}</b>{" "}
                                <ArrowForwardIcon
                                  className={classes?.arrowIcon}
                                />{" "}
                                {t("next-action-by")}:{" "}
                                <b>{activity?.nextActionBy || "N/A"}</b>
                              </p>

                              <p>
                                <b>{t("reason")}:</b>{" "}
                                {activity?.reason || "N/A"}
                              </p>
                            </div>
                            <div className="mx-2">
                              <hr className={classes?.hr}></hr>
                              <p>
                                <b>{t("remarks")}:</b>{" "}
                              </p>
                              <p className={classes?.note}>
                                <ReadMoreText
                                  t={t}
                                  text={activity?.note ?? "N/A"}
                                />
                              </p>
                            </div>
                          </fieldset>
                        </div>
                      </TimelineContent>
                    </TimelineItem>
                  </React.Fragment>
                ))}
              </Timeline>
            </div>
          </React.Fragment>
        }
      />
    </div>
  );
}

export default AMLHistoryDialog;
