import React from "react";

function PhoneXIcon({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M14.67 2.061c-.222.081-.42.248-.542.455-.092.156-.108.229-.107.484 0 .213.022.341.074.44.041.077.616.685 1.279 1.35L16.578 6l-1.204 1.21c-.663.665-1.236 1.271-1.273 1.345a1.192 1.192 0 0 0-.089.34.99.99 0 0 0 1.313 1.044c.198-.065.314-.168 1.446-1.293l1.23-1.223 1.21 1.204c.665.662 1.27 1.235 1.344 1.272.364.183.84.099 1.141-.201.287-.287.369-.648.238-1.041-.058-.173-.213-.345-1.288-1.427L19.424 6l1.222-1.23c1.075-1.082 1.23-1.254 1.288-1.427.132-.394.049-.754-.24-1.043-.28-.28-.65-.363-1.037-.234-.173.058-.345.213-1.427 1.288L18 4.576l-1.23-1.224c-1.16-1.153-1.242-1.227-1.447-1.288-.26-.077-.45-.078-.653-.003m-9.65.522c-.641.149-.96.288-1.36.589-.379.286-1.858 1.809-2.072 2.134-.333.505-.54 1.092-.631 1.789-.09.683.049 1.388.583 2.969.976 2.89 2.562 5.435 4.76 7.638 2.208 2.213 4.527 3.694 7.46 4.765.577.21 1.813.568 2.193.634a4.019 4.019 0 0 0 2.993-.716c.336-.251 1.355-1.237 1.779-1.722.334-.381.527-.712.68-1.163.083-.245.095-.354.094-.86 0-.45-.017-.634-.075-.82a3.527 3.527 0 0 0-.43-.889c-.162-.238-1.364-1.465-1.714-1.75a2.87 2.87 0 0 0-1.88-.665c-.879-.001-1.479.258-2.197.947-.216.207-.414.377-.439.377-.127 0-1.003-.494-1.744-.983a13.598 13.598 0 0 1-4.546-5.072l-.242-.475.237-.245c.411-.426.601-.66.743-.916a2.984 2.984 0 0 0-.055-2.999c-.192-.323-1.326-1.509-1.837-1.921a3.118 3.118 0 0 0-1.1-.572c-.231-.063-1.034-.112-1.2-.074m.82 2.06c.217.099 1.525 1.377 1.644 1.607a.94.94 0 0 1 .059.726c-.058.199-.12.284-.495.676-.236.246-.476.529-.534.628-.258.441-.346 1.098-.211 1.563.082.281.646 1.375.997 1.934a16.049 16.049 0 0 0 3.584 4.008c.792.638 2.286 1.556 3.08 1.894.33.14.734.202 1.044.16.536-.074.818-.228 1.364-.745.529-.501.665-.58 1.006-.581.407-.003.517.071 1.307.872.764.774.836.881.834 1.25-.001.391-.083.506-.972 1.388-.87.863-.982.942-1.51 1.072-.452.112-.707.077-1.777-.241-3.859-1.146-7.351-3.726-9.707-7.174a18.131 18.131 0 0 1-2.499-5.5c-.161-.624-.134-1.081.092-1.566.187-.402 1.672-1.905 1.979-2.003.221-.07.518-.057.715.032"
        fill-rule="evenodd"
        fill={fill ?? "none"}
      />
    </svg>
  );
}

export default PhoneXIcon;
