import React from "react";

function PlusIcon({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M11.695 4.057a1.04 1.04 0 0 0-.567.459l-.108.184-.011 3.15-.011 3.15H7.945c-3.312 0-3.27-.003-3.563.221-.08.061-.195.194-.254.295-.091.156-.108.23-.108.484 0 .256.016.328.111.489.125.213.318.375.539.454.12.043.763.054 3.239.055l3.089.002.011 3.153.011 3.153.121.197a.998.998 0 0 0 1.718 0l.121-.197.011-3.153.011-3.153 3.089-.002c2.476-.001 3.119-.012 3.239-.055.221-.079.414-.241.539-.454.095-.161.111-.233.111-.489 0-.254-.017-.328-.108-.484a1.209 1.209 0 0 0-.254-.295c-.293-.224-.251-.221-3.563-.221h-3.053l-.011-3.15-.011-3.15-.108-.184a1.005 1.005 0 0 0-1.177-.459"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default PlusIcon;
