import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tables } from '../../../../../Components';
import { ImportProcceseTypeEnum, TableFilterTypesEnum } from '../../../../../Enums';

export const ImportDetailsActionsTable = ({
    data,
    filter,
    setFilter,
    parentTranslationPath,
    translationPath,
    onFilterValuesChanged,
    onPageIndexChanged,
    onPageSizeChanged
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const [sortBy, setSortBy] = useState(null);
    const [tableFilterData, setTableFilterData] = useState([]);

    const headerData = [
        {
            id: 1,
            label: 'createdBy',
            input: 'createdByUserName',
            isHiddenFilter: false,
            isDefaultFilterColumn: true,
            isSortable: true,
        },
        {
            id: 2,
            label: 'createdOn',
            isDate: true,
            input: 'createdOn',
            isHiddenFilter: true,
            isSortable: true
        },
        {
            id: 3,
            label: 'updateOn',
            input: 'updateOn',
            isDate: true,
            isHiddenFilter: true,
            isSortable: true
        },
        {
            id: 4,
            label: 'importProcceseType',
            input: 'importProcessTypeId',
            isHiddenFilter: false,
            withSelectFilter: true,
            isSortable: true,
            component: (item) => <span>{
                ImportProcceseTypeEnum.map((el) => {
                    if (el.name === item.importProcceseTypeName) return el.value
                })
            }</span> || ''
        },
        {
            id: 5,
            label: 'fileName',
            input: 'fileName',
            isDefaultFilterColumn: true,
            isHiddenFilter: true,
            isSortable: true
        },
        {
            id: 6,
            label: 'importProcceseStatus',
            input:'importProcceseStatus',
            isSortable: true,
            component: (item) => (
                <span>
                    {((item && item.importProcceseStatus &&
                        (item.importProcceseStatus === 1 && <div className='Reading'>{t(`${translationPath}Processing`)}</div>) ||
                        (item.importProcceseStatus === 2 && <div className='ReadingFileFailure'>{t(`${translationPath}Faild`)}</div>) ||
                        (item.importProcceseStatus === 3 && <div className='FinishRead'>{t(`${translationPath}FinishRead`)}</div>) ||
                        (item.importProcceseStatus === 4 && <div className='FileImported'>{t(`${translationPath}Done`)}</div>)) ||
                        '')}
                </span>
            ),
            isHiddenFilter: true,
        }
    ];

    useEffect(() => {
        setTableFilterData(
            headerData.map((column) => ({
                key: column.key || column.fieldKey || column.id,
                filterType: (column.isDate && TableFilterTypesEnum.datePicker.key) || (column.withSelectFilter && TableFilterTypesEnum.selectOption.key) || TableFilterTypesEnum.textInput.key,
                isHiddenFilter: column.isHiddenFilter,
                textInputType: column.textInputType,
                textInputMax: column.textInputMax,
                textInputMin: column.textInputMin,
                displayPath: column.input
            }))
        );
    }, []);
    useEffect(() => {
        if (sortBy) {
            setFilter((item) => ({
                ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy, search: '', pageIndex: 0
            }));
        }
    }, [sortBy]);

    return (
        <>
            <Tables
                data={data || []}
                headerData={headerData}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                totalItems={(data && data.totalCount) || 0}
                defaultActions={[]}
                itemsPerPage={filter.pageSize}
                activePage={filter.pageIndex + 1}
                onFilterValuesChanged={onFilterValuesChanged}
                filterData={tableFilterData}
                onPageIndexChanged={onPageIndexChanged}
                onPageSizeChanged={onPageSizeChanged}
                isWithFilter
                setSortBy={setSortBy}
                FilterDisabledButton
                optionFilterName={ImportProcceseTypeEnum}
            />

        </>
    );
};
