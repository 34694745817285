export const CommissionTypes = 
{
    forAllProjects  : {
        key : 1 , 
        name : "for-all-projects"
    } , 

    specificProjects  : {
        key : 2 , 
        name : "specific-projects"
    } , 
};
  