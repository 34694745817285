import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';
import excellogo from '../../../../../assets/images/defaults/microsoft_office_excel_logo.png';

export const SearchAndAddComponent = ({
    parentTranslationPath,
    translationPath,
    state,
    schema,
    ifHaveAnyErrors,
    searchHandler,
    setIsOpenSaveDialog,
    exportDataToFormats,
 }) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);

  return (
    <div className=''>
      <div className='save-wrapper d-inline-flex-center'>
        {/* <ButtonBase
            className='MuiButtonBase-root MuiButton-root MuiButton-text cancel-btn-wrapper btns theme-transparent c-primary w-100 mx-2 mb-2'
            tabIndex='0'
            onClick={() => searchHandler()}
            disabled={!state.conditions.length  || (schema.error) || ifHaveAnyErrors  }
          >
            <span className='MuiButton-label'>
              <span>
              {t(`${translationPath}search`)}
              </span>
            </span>
            <span className='MuiTouchRipple-root' />
          </ButtonBase> */}
        {/* <ButtonBase
            className='MuiButtonBase-root MuiButton-root MuiButton-text save-btn-wrapper btns theme-solid bg-primary w-100 mx-2 mb-2'
            tabIndex='0'
            onClick={() => setIsOpenSaveDialog(true)}
            disabled={!state.conditions.length  || (schema.error) || ifHaveAnyErrors  }
          >
            <span className='MuiButton-label'>
              <span>
                 {t(`${translationPath}save`)}
              </span>
            </span>
            <span className='MuiTouchRipple-root' />
          </ButtonBase> */}
        <div className='excelPart'>
          {' '}
          <ButtonBase
            className='MuiButtonBase-root MuiButton-root MuiButton-text table-action-btn btns miw-0'
            tabIndex='0'
            type='button'
            id='export-ref'
            onClick={() => exportDataToFormats('xls', 'ReportView')}
          >
            <span className='MuiButton-label'>
              <div className=''>
                <img src={excellogo} alt='arrowImage' className='excelImage1' />
              </div>
              <span className='px-1 info fw-normal'>
                {t(`${translationPath}export-excel`)}
              </span>
            </span>
            <span className='MuiTouchRipple-root' />
          </ButtonBase>
        </div>
      </div>
    </div>

  );
};

SearchAndAddComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  searchHandler: PropTypes.func.isRequired,
  setIsOpenSaveDialog: PropTypes.func.isRequired,
  ifHaveAnyErrors: PropTypes.bool.isRequired,
  exportDataToFormats: PropTypes.func.isRequired,
};
