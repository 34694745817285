import { UnitsOperationTypeEnum } from './UnitsOperationTypeEnum';

export const UnitsFilterStatusEnum = {
  sale: {
    Available: {
      key: 1,
      value: 'Available',
      title: 'available',
      display:'Available',
      showInSelect: true,
      classes: 'c-success-light',
    },
    Reserved: {
      key: 2,
      value: 'ReservedSale',
      display:'Reserved Sale',
      title: 'reserved',
      showInSelect: true,
      effectedOperationType: UnitsOperationTypeEnum.rent.key,
      classes: 'c-primary',
    },
    sale: {
      key: 3,
      value: 'Sale',
      title: 'sold',
      showInSelect: true,
      effectedOperationType: UnitsOperationTypeEnum.rent.key,
      display:'Sold',
      classes: 'c-primary',
    },
    Draft: {
      key: 10,
      value: 'Draft',
      title: 'draft',
      classes: 'c-black-light',
      display:'Draft',
      showInSelect: true,
    },
    PendingReserveSale: {
      key: 13,
      value: 'PendingReserveSale',
      title: 'PendingReserveSale',
      classes: 'c-black-light',
      display:'Pending Reserve Sale',
      showInSelect: true,
    },
    PendingSale: {
      key: 14,
      value: 'PendingSale',
      title: 'PendingSale',
      classes: 'c-black-light',
      display:'Pending Sale',
      showInSelect: true,
    },

  },
  lease: {
    Available: {
      key: 1,
      value: 'Available',
      title: 'available',
      showInSelect: true,
      display:'Available',
      classes: 'c-success-light',
    },
    Reserved: {
      key: 2,
      value: 'ReservedLeased',
      title: 'reserved',
      display:'Reserved Lease',
      showInSelect: true,
      effectedOperationType: UnitsOperationTypeEnum.rent.key,
      classes: 'c-primary',
    },
    Leased: {
      key: 3,
      value: 'Leased',
      title: 'leased',
      display:'Leased',
      showInSelect: true,
      effectedOperationType: UnitsOperationTypeEnum.rent.key,
      classes: 'c-primary',
    },
    Draft: {
      key: 10,
      value: 'Draft',
      display:'Draft',
      title: 'Draft',
      classes: 'c-black-light',
      showInSelect: true,
    },
    PendingReservedLease: {
      key: 11,
      value: 'pendingreservelease',
      title: 'pendingreservelease',
      display:'Pending Reserve Lease',
      classes: 'c-black-light',
      showInSelect: true,
    },
    PendingLeased: {
      key: 12,
      value: 'pendinglease',
      title: 'pendinglease',
      display:'Pending Lease',
      classes: 'c-black-light',
      showInSelect: true,
    },
    UnderOffer: {
      key: 15,
      value: 'UnderOffer',
      title: 'UnderOffer',
      display:'Under Offer',
      classes: 'c-black-light',
      showInSelect: true,
    },
    OfferSigned: {
      key: 16,
      value: 'OfferSigned',
      title: 'OfferSigned',
      display:'Offer Signed',
      classes: 'c-black-light',
      showInSelect: true,
    },
    UnderContract: {
      key: 16,
      value: 'UnderContract',
      title: 'UnderContract',
      display:'Under Contract',
      classes: 'c-black-light',
      showInSelect: true,
    },
    ContractSigned: {
      key: 16,
      value: 'ContractSigned',
      title: 'ContractSigned',
      display:'Contract Signed',
      classes: 'c-black-light',
      showInSelect: true,
    },
  },
};
