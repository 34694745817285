
import React, { useCallback, useState } from 'react';
import { DialogComponent, Spinner  } from '../../../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { showError, showSuccess } from '../../../../../../../../../Helper';
import { DeletePublishedPortalByPublishedPortalId } from '../../../../../../../../../Services';



export const DeletePublishedPortalDialog = ({ isOpen, translationPath, parentTranslationPath, onClose, editUnitPublishedPortal ,reloadData}) => {
    const { t } = useTranslation(parentTranslationPath);
    const [isLoading, setIsLoading] = useState(false);


    const saveHandler = useCallback(async () => {
        setIsLoading(true);
        const res = await DeletePublishedPortalByPublishedPortalId(editUnitPublishedPortal.publishedPortalId   )
        if (!(res && res.status && res.status !== 200)) {
            showSuccess(t(`${translationPath}published-units-deleted-sucessfuully`))
        } else showError(t(`${translationPath}failed-to-delete-published-units`))

        setIsLoading(false);
        onClose();
        reloadData();
    });

    return (
        <div>
            <Spinner isActive={isLoading} />
            {isOpen && (
                <DialogComponent
                    isOpen={isOpen}
                    titleText={'confirmation-message'}
                    maxWidth={'sm'}
                    SmothMove
                    dialogContent={
                        <div className='d-flex-column-center confirmation-message mb-5'>
                            <span className='mdi mdi-help-circle' />
                            <span className='confirm-msg mt-2'>{t(`${translationPath}confirm-delete-published-units`)}</span>
                        </div>
                    }
                    saveType='button'
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onCancelClicked={onClose}
                    onSaveClicked={saveHandler}
                    saveText={'confirm'}
                />)}
        </div>
    );
};
