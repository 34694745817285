// Define Shadow Levels
export const shadows = {
  none: 'none',
  xs: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  sm: '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
  md: '0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
  lg: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
  xl: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
  '2xl': '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
  '3xl': '0px 32px 64px -12px rgba(16, 24, 40, 0.14)',
};

// Define Rign Levels 
const rings = { 
  brand: '0px 0px 0px 4px rgba(140, 115, 75, 0.24)', 
  gray: '0px 0px 0px 4px rgba(152, 162, 179, 0.14)', 
  gray_secondary: '0px 0px 0px 4px rgba(152, 162, 179, 0.20)', 
  error: '0px 0px 0px 4px rgba(240, 68, 56, 0.24)', 
  brand_shadow_xs: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(140, 115, 75, 0.24)', 
  brand_shadow_sm: '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 0px 0px 4px rgba(158, 119, 237, 0.24)', 
  gray_shadow_xs: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(152, 162, 179, 0.14)', 
  gray_shadow_sm: '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 0px 0px 4px rgba(152, 162, 179, 0.14)', 
  error_shadow_xs: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(240, 68, 56, 0.24)', 
}

// Define Border Radius Levels 
const borderRadius = { 
  none: 0, 
  xxs: '2px', 
  xs: '4px', 
  sm: '6px', 
  md: '8px', 
  lg: '10px', 
  xl: '12px', 
  '2xl': '16px', 
  '3xl': '20px', 
  '4xl': '24px', 
  full: '50%', 
}; 

export const generalThemeSettings = {
  typography: {
    fontFamily: 'Inter !important',
    button: {
      textTransform: 'none',
      direction: 'ltr',
    },
  },
  overrides: {
    MuiChip: {
      root: {
        direction: 'ltr',
      },
    },
  },
  shadows: [
    shadows.none, // 0: no shadow
    shadows.xs, // 1
    shadows.sm, // 2
    shadows.md, // 3
    shadows.lg, // 4
    shadows.xl, // 5
    shadows['2xl'], // 6
    shadows['3xl'], // 7

    // Focus Rings
    rings.brand, // 8: ring-brand
    rings.gray, // 9: ring-gray
    rings.gray_secondary, // 10: ring-gray-secondary
    rings.error, // 11: ring-error
    rings.brand_shadow_xs, // 12: ring-brand-shadow-xs
    rings.brand_shadow_sm, // 13: ring-brand-shadow-sm
    rings.gray_shadow_xs, // 14: ring-gray-shadow-xs
    rings.gray_shadow_sm, // 15: ring-gray-shadow-sm
    rings.error_shadow_xs, // 16: ring-error-shadow-xs
  ],
  borderRadius: [ 
    borderRadius.none, // 0: no border radius 
    borderRadius.xxs, // 1 
    borderRadius.xs, // 2 
    borderRadius.sm, // 3 
    borderRadius.md, // 4 
    borderRadius.lg, // 5 
    borderRadius.xl, // 6 
    borderRadius['2xl'], // 7 
    borderRadius['3xl'], // 8 
    borderRadius['4xl'], // 9 
    borderRadius.full, // 10: full border radius 
  ],
};
