const DeactiveUserLogHeader = [
  {
    id: 1,
    label: 'user-staff-id',
    input: 'userStaffId',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
  {
    id: 2,
    label: 'user-name',
    input: 'userName',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
  {
    id: 3,
    label: 'user-status',
    input: 'userStatus',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
  {
    id: 4,
    label: 'user-branch',
    input: 'userBranch',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
  {
    id: 5,
    label: 'platform-access',
    input: 'platformAccess',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
  {
    id: 6,
    label: 'roles',
    input: 'roles',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
  {
    id: 7,
    label: 'policy',
    input: 'userPolicy',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
  {
    id: 8,
    label: 'group',
    input: 'userGroup',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
  {
    id: 9,
    label: 'deactivated-by',
    input: 'deactivatedBy',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
  {
    id: 10,
    label: 'request-date',
    input: 'requestDate',
    isSortable: true,
    isHiddenFilter: true,
    isDate: true,
  },
  {
    id: 11,
    label: 'line-manager',
    input: 'lineManager',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
  {
    id: 12,
    label: 'next-actionBy',
    input: 'nextActionBy',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
  {
    id: 13,
    label: 'reAssign-status',
    input: 'reAssignStatus',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
  {
    id: 14,
    label: 'reassigning-date',
    input: 'reassigningDate',
    isSortable: true,
    isHiddenFilter: true,
    isDate: true,
  },
];

export default DeactiveUserLogHeader;
