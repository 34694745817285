import { UnitListingView } from "./UnitListingView/UnitListingView";
import { LeadsReassignView } from "./LeadsReassignView/LeadsReassignView";
import { PendingDeactivationView } from "./PendingDeactivationView/PendingDeactivationView";


export const PendingDeactivationTabs = {
  PendingDeactivation: {
    label: "PendingDeactivation",
    key: 1,
    component: PendingDeactivationView,
  },
  UnitListing: {
    label: "Units",
    key: 2,
    component: UnitListingView,
  },
  LeadsReassigning: {
    label: "Leads",
    key: 3,
    component: LeadsReassignView,
  },
};
