export const userTrainingInsightsSearchFields = [
  {
    label: 'username',
    key: 'userName',
  },
  {
    label: 'email',
    key: 'email',
  },
  {
    label: 'staff_id',
    key: 'staffID',
  },
  {
    label: 'branch_name',
    key: 'branchName',
  },
];
