import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogComponent, Spinner, AutocompleteComponent , Inputs  } from '../../../../Components' ; 
import { GetLookupItemsByLookupTypeName } from '../../../../Services' ; 
import { showSuccess, showError } from '../../../../Helper';

export const StatusReasonDialog = ({
   isOpen,
   onClose,
   onSave ,
   translationPath ,
   parentTranslationPath ,
   setAgentStatusReason ,
   agentStatusReason
}) => {

  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [allAllStatusReason , setAllStatusReason] = useState([]) ;

  const saveHandler = async () => 
  {
    if(!(agentStatusReason && agentStatusReason.reason))
    {
      showError(t`${translationPath}please-select-off-status-reason`);
      return ; 

    }
    else onSave() ; 
   
  };
  const getAllStatusReason = useCallback(async () => 
    {
       setIsLoading(true);
        const res = await GetLookupItemsByLookupTypeName({ lookUpName : 'OffStatusReason' , pageIndex :1 , pageSize :150});
        if (!((res && res.data && res.data.ErrorId) || !res)) {
            setAllStatusReason(res?.result || []);
        } 
        else 
        setAllStatusReason([]);
        setIsLoading(false) ; 
    });


  useEffect(() => {
    getAllStatusReason();
  }, []);

  return (
    <DialogComponent
      titleText={t(`${translationPath}add-status-reason`)}
      saveText={t(`${translationPath}confirm`)}
      saveType='button'
      maxWidth='sm'
      dialogContent={(
        <div className='d-flex-column-center'>
          <Spinner isActive={isLoading} isAbsolute />
          
          <AutocompleteComponent
            idRef='reasonRef'
            labelClasses='Requierd-Color'
            labelValue={t(`${translationPath}reason`)}
            data={allAllStatusReason}
            multiple={false}
            displayLabel={(option) => (option && option.lookupItemName) || ''}
            chipsLabel={(option) => (option && option.lookupItemName) || ''}
            withoutSearchButton
            onChange={(e, newValue) =>
            {
              setAgentStatusReason((item) => ({ ...item, reason : newValue  }));

            }}
            isLoading={isLoading}
            withLoader
            helperText={t(`${translationPath}please-select-off-status-reason`)}
            error={!(agentStatusReason?.reason)}
            isWithError
          />
           <div className='w-100 mt-2 mb-2'> 
              <Inputs
                    idRef='remarkRef'
                    labelValue='remarks'
                    multiline
                    rows={4}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onInputChanged={(event) => {
                      const {value} = event.target ; 
                      setAgentStatusReason((item) => ({ ...item, notes: value}))
                    }}
                  /> 
            </div>
        </div>
      )}
      saveClasses='btns theme-solid bg-parimary  w-100 mx-2 mb-2'
      isOpen={isOpen}
      onSaveClicked={() => saveHandler()}
      onCloseClicked={() => onClose()}
      onCancelClicked={() => onClose()}
    />

  );
};


