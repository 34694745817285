import React, { useState, useCallback, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { UnitProfileImagesCardActionEnum } from '../../../../../../../../Enums';
import { VideosGalleryCardComponent } from '../../../../../../ImagesGallery/Sections/VideosGalleryComponent/VideosGalleryCardComponent/VideosGalleryCardComponent';
import { GetAllUnitVideosCategoryByUnitId } from '../../../../../../../../Services';
import { GetParams } from '../../../../../../../../Helper';
import { Spinner } from '../../../../../../../../Components';
import { UnitVideoCategory } from '../../../../../../../../assets/json/StaticLookupsNames.json';
import { UnitsUploaderDialog } from '../../../../Common/UnitsUploaderDialog/UnitsUploaderDialog'
export const UnitVideosTabComponent = ({
    parentTranslationPath,
    translationPath,
    propertyId,
    activeTab
}) => {

    const [isOpenUnitsUplodartDialog, setIsOpenUnitsUplodartDialog] = useState(false);
    const [isData, setIsdata] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const [result, setResult] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [hideIcon, setHideIcon] = useState(false);
    const { t } = useTranslation(parentTranslationPath);
    const location = useLocation();
    const [selected, setSelected] = useState({ lookupItemName: '', lookupItemId: '' });

    const getAllUnitVideosCategoryByUnitId = useCallback(async () => {
        setIsLoading(true);
        let unitVideoTypeId = 1
        const response = await GetAllUnitVideosCategoryByUnitId(+GetParams('id'), unitVideoTypeId);
        if (!(response && response.status && response.status !== 200))
            setResult(response || []);
        else setResult([]);
        setHideIcon(true);

        setIsLoading(false);
    }, []);

    useEffect(() => {
        getAllUnitVideosCategoryByUnitId();
    }, [getAllUnitVideosCategoryByUnitId, location]);
    
    const onAddNewclick = useCallback((item) => {
        setActiveItem(item);
        setIsOpenUnitsUplodartDialog(true);
    });

    return (
        <div className='LocationImagesTabComponent'>
            <Spinner isActive={isLoading} />
            <div className='LocationImagesTabComponent'>
                <div className={result && result.length > 0 ? 'Add-with-data' : 'Add'}>
                <Button className='btns mx-3 mb-2 theme-solid'  onClick={() => onAddNewclick()} >
            <span className='mdi mdi-plus' />
            {t(`${translationPath}AddNew`)}
          </Button>
                </div>
            </div>
            {result && result.length !== 0 && (
                <VideosGalleryCardComponent
                    hideIcon={hideIcon}
                    hidePhotosTooltip
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    data={result}
                    WithUnitDetails
                    reloadData={getAllUnitVideosCategoryByUnitId}
                    setdata={setResult}
                    Display={UnitProfileImagesCardActionEnum.Show}
                    unitsUploader={true}
                    activeTab={activeTab}
                    setActiveItem={setActiveItem}
                    activeItem={activeItem}
                    selected={selected}
                    setSelected={setSelected}
                />
            ) || (
                    <div className='EmptyPage' />
                )}
            {isOpenUnitsUplodartDialog && (
                <UnitsUploaderDialog
                    data={result}
                    reloadData={getAllUnitVideosCategoryByUnitId}
                    propertyId={propertyId}
                    setIsdata={setIsdata}
                    unit={UnitProfileImagesCardActionEnum.Show}
                    activeItem={activeItem}
                    activeTab={activeTab}
                    isOpen={isOpenUnitsUplodartDialog}
                    setActiveItem={setActiveItem}
                    selected={selected}
                    setSelected={setSelected}
                    isOpenChanged={() => {
                        setIsOpenUnitsUplodartDialog(false);
                    }}
                    onSave={() => {
                        setActiveItem(null);
                        setIsOpenUnitsUplodartDialog(false);
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                />
            )}
        </div>
    );
};

UnitVideosTabComponent.propTypes = {
    uploader: PropTypes.bool,
    parentTranslationPath: PropTypes.string.isRequired,
    translationPath: PropTypes.string.isRequired,
    propertyId: PropTypes.number.isRequired,
};
UnitVideosTabComponent.defaultProps = {
    uploader: false,
};
