import { KenbanBoardView, TaskDetailsView } from '../../Views/Home/MarketingCampaignRequests';

export const KenbanBoardRoutes = [
    {
      path: '/view',
      name: 'Campaign:kenban-board',
      component: KenbanBoardView,
      layout: '/home/kenban-board',
      default: true,
      isRoute: true,
      authorize: true,
      roles: [],
      isDisabled: false,
      isExact: true,
      breadcrumbs: [
        {
            name: 'Campaign:kenban-board',
            isDisabled: false,
            route: '/home/kenban-board/view',
            groupName: 'marketing',
        },
      ],
    },
    {
      path: '/task-details',
      name: 'Campaign:task-details',
      component: TaskDetailsView,
      layout: '/home/kenban-board',
      default: true,
      isRoute: true,
      authorize: true,
      roles: [],
      isDisabled: false,
      isExact: true,
      breadcrumbs: [
        {
            name: 'Campaign:kenban-board',
            isDisabled: false,
            route: '/home/kenban-board/view',
            groupName: 'marketing',
        },
        {
            name: 'Campaign:task-details',
            isDisabled: false,
            route: '/home/kenban-board/task-details',
        },
      ],
    },
];
