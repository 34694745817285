import React from "react";
import { RadiosGroupComponent } from "../../../../../../Components";
import { useTranslation } from "react-i18next";
import { RiskRatingConfigEnum } from "../../../../../../Enums";

export const RatingCriteria = ({
  parentTranslationPath,
  translationPath,
  ControlProps,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const criteriaConfigChangeHandler = (
    newValue,
    categoryIndex,
    criteriaIndex
  ) => {
    //Local Copies :
    const localConfigData = [...(ControlProps.selected.criteriaConfig || [])];
    const localCategoryItem = localConfigData.find(
      (_, index) => index === categoryIndex
    );
    const localCriteriaItem = localCategoryItem.riskRatingFormValues.find(
      (_, index) => index === criteriaIndex
    );

    //Local Copies With Changes :
    const criteriaWithNewValue = {
      ...(localCriteriaItem || {}),
      riskRatingDurationType: newValue,
    };
    localCategoryItem.riskRatingFormValues[criteriaIndex] =
      criteriaWithNewValue;
    localConfigData[categoryIndex] = localCategoryItem;

    ControlProps.onSelectedChange("criteriaConfig", localConfigData);
  };

  return (
    <div className="d-flex-column gap-40 mt-5">
      {ControlProps.selected.criteriaConfig
        ? ControlProps.selected.criteriaConfig.map(
            (category, categoryIndex) => (
              <div key={category.lookupTypeName}>
                <h5 className="fw-simi-bold m-0 mb-1 fz-14 mb-1 pb-3 b-bottom">
                  {t(`${translationPath}${category.lookupTypeName || "-"}`)}
                </h5>
                {category.riskRatingFormValues
                  ? category.riskRatingFormValues.map(
                      (criteria, criteriaIndex) => (
                        <div
                          className="d-flex fj-start b-bottom"
                          key={
                            criteria?.riskRatingLookupDto?.riskRatingLookupName
                          }
                        >
                          <div className="w-25 mt-2 pt-1">
                            <span className="fw-simi-bold">
                              {criteria?.riskRatingLookupDto
                                ?.riskRatingLookupName || ""}
                            </span>
                          </div>
                          <div className="w-75 mt-2 mb-1 pt-1">
                            <RadiosGroupComponent
                              idRef="DefaultBranchUser"
                              data={Object.values(RiskRatingConfigEnum)}
                              onSelectedRadioChanged={(event) => {
                                const newValue = +event.target.value;
                                criteriaConfigChangeHandler(
                                  newValue,
                                  categoryIndex,
                                  criteriaIndex
                                );
                              }}
                              value={criteria.riskRatingDurationType}
                              labelInput="name"
                              valueInput="value"
                              themeClass="theme-propx"
                            />
                          </div>
                        </div>
                      )
                    )
                  : null}
              </div>
            )
          )
        : null}
    </div>
  );
};
