import React, { useEffect, useState } from "react";
import { config } from "../../../config";
import { Inputs, Spinner } from "../../../Components";
import { RunSystemTemplateForUnit } from "../../../Services";

const ShareViaWhatsAppForm = ({
  selected,
  setSelected,
  parentTranslationPath,
  translationPath,
}) => {

  const [isTemplateLoading, setIsTemplateLoading] = useState(false);

  const getWhatsappMsgTemplate = async () => {
    setIsTemplateLoading(true);

    const body = {
      templateId: config.WhatsAppConsentTemplate,
    };
    const res = await RunSystemTemplateForUnit(body);
    if (!(res && res.status && res.status !== 200)) {
      setSelected({
        id: "whatsappMsgTemplate",
        value: res?.templateContent || "",
      });
    }
    setIsTemplateLoading(false);
  };


  useEffect(() => {
    getWhatsappMsgTemplate();
  }, []);

  return (
    <div className="dialog-content-wrapper">
      <Spinner isActive={isTemplateLoading} />
      <div className="dialog-content-item  d-flex mx-2">
        <Inputs
          idRef="whatsappMsgBodyRef"
          labelValue="message-body"
          value={selected.whatsappMsgTemplate || ""}
          multiline
          rows={20}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          inputPlaceholder={`Dear Customer.\n\nWe hope you're doing well.\n\nYour consent is important to us as it allows us to keep you informed about the latest updates and opportunities.\n\n Please click the link below to complete the form . `}
          translationPath={translationPath}
        />
      </div>
    </div>
  );
};

export default ShareViaWhatsAppForm;
