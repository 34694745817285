import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteComponent } from "../../../../Components";
import { OrganizationUserSearch } from "../../../../Services";

const parentTranslationPath = "UnitsView";
const translationPath = "";

export const ListingAgentFilter = ({
  resaleChangeFilters,
  selected , 
  setSelected
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [allListingAgent, setAllListingAgent] = useState([]);
  const searchTimer = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const getAllListingAgent = async (search) => {
    setIsLoading(true);
    const filter = {
      pageSize: 25,
      pageIndex: 1,
      name: search || "",
      userName: null,
      phoneNumber: null,
      email: null,
      userTypeId: null,
    };
    const res = await OrganizationUserSearch(filter);
    if (!(res && res.status && res.status !== 200)) {
      setAllListingAgent(res.result || []);
    } else setAllListingAgent([]);
    setIsLoading(false);
  };

  return (
    <div className="w-50 m-1">
      <AutocompleteComponent
        idRef="listing-agentRef"
        multiple={false}
        selectedValues={selected.listingAgent}
        wrapperClasses="w-min-unset m-2 mr-1"
        inputPlaceholder={t(`${translationPath}select`)}
        labelValue={t(`${translationPath}listing-agent`)}
        data={allListingAgent || []}
        displayLabel={(option) => (option && option.fullName) || ""}
        chipsLabel={(option) => option.fullName || ""}
        renderOption={(option) => (
          <span>
            {`${option.fullName} ${(option.branch && "-") || ""} ${
              option.branch || ""
            }`}
          </span>
        )}
        withoutSearchButton
        onChange={(e, newValue) => {
          resaleChangeFilters(
            "listing_agent",
            (newValue && newValue.fullName) || ""
          );
          setSelected({id :'listingAgent'  , value : newValue })
        }}
        onOpen={() => {
          if (allListingAgent && allListingAgent.length == 0)
            getAllListingAgent();
        }}
        onInputKeyUp={(e) => {
          const { value } = e.target;
          if (searchTimer.current) clearTimeout(searchTimer.current);
          searchTimer.current = setTimeout(() => {
            getAllListingAgent(value);
          }, 700);
        }}
        isLoading={isLoading}
        withLoader
      />
    </div>
  );
};
