import React, { useState } from 'react';
import { showError, showSuccess } from '../../../../../Helper';
import { DialogComponent, Spinner } from '../../../../../Components';
import { useTranslation } from 'react-i18next';

export const RejectedDialog = ({
  open,
  close,
  onSave,
  activeItem,
  reloadData,
  translationPath,
  parentTranslationPath,
  approveOrRejectRequest,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);

  const deleteHandler = async () => {
    setIsLoading(true);
  await  approveOrRejectRequest(false, activeItem?.unitRequestManagementId);
    onSave();
    setIsLoading(false);
  };

  return (
    <DialogComponent
      titleText={t(`${translationPath}reject-requests`)}
      saveText={t('confirm')}
      saveType='button'
      maxWidth='sm'
      dialogContent={
        <div className='d-flex-column-center'>
          <Spinner isActive={isLoading} />
          <span className='mdi mdi-close-octagon c-danger mdi-48px' />
          <div className='w-100' style={{ display: 'block' }}>
            {' '}
            <span>
              {`${t(
                `${translationPath}are-you-sure-you-want-to-Delete-request`
              )}`}{' '}
              :
            </span>{' '}
            <div style={{ fontWeight: 600, width: '130px' }}>
              <p
                style={{
                  fontWeight: 600,
                  width: '30rem',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {' '}
                {`${activeItem && activeItem.requesterName}`}{' '}
              </p>{' '}
            </div>
          </div>
        </div>
      }
      saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
      isOpen={open}
      onSaveClicked={deleteHandler}
      onCloseClicked={close}
      onCancelClicked={close}
      translationPath={translationPath.confirm}
    />
  );
};
