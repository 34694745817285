import React, { Children, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckboxesComponent,
  Inputs,
  LoadableImageComponant,
} from '../../../../../../../Components';
import { ContactTypeEnum, LoadableImageEnum } from '../../../../../../../Enums';
import { getDownloadableLink } from '../../../../../../../Helper';

function UsersViewer({
  parentTranslationPath,
  users,
  userStatus,
  selectedUsers,
  selectUserHandler,
  deselectUserHandler,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const [searchedItemId, setSearchedItemId] = useState('');
  const searchTimer = useRef(null);
  const searchedItemRef = useRef(null);

  const getIsChecked = useCallback(
    (item) => selectedUsers.findIndex((user) => user.id === item.id) !== -1,
    [selectedUsers]
  );

  const changeSelectionHandler = (user) => {
    if (selectedUsers.find((item) => item.id === user.id)) {
      deselectUserHandler(user);
    } else {
      selectUserHandler(user);
    }
  };
  const executeScroll = () =>
    searchedItemRef?.current?.scrollIntoView({
      block: 'end',
      behavior: 'smooth',
    });
  const searchHandler = (value) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setSearchedItemId(value);
      executeScroll();
    }, 500);
  };
  return (
    <div>
      <div className='bulk-header-section'>{t(`selected-users`)}</div>
      <div className='bulk-sub-header-section'>
        {t(`you-can-deselect-user-before-continue`)}
      </div>
      <div className='mt-2'>
        <Inputs
          translationPath={''}
          parentTranslationPath={parentTranslationPath}
          idRef='usersSearchRef'
          inputPlaceholder='search-user'
          onKeyUp={(e) => searchHandler(e.target.value)}
          startAdornment={<span className='mdi mdi-magnify mdi-24px c-gray' />}
        />
      </div>
      <div className='bulked-units-section mt-2'>
        {Children.toArray(
          users?.map((item, index) => (
            <div
              onClick={(e) => {
                e.preventDefault();
                if (userStatus.success.findIndex((el) => el === item.id) === -1)
                  changeSelectionHandler(item);
              }}
              ref={
                searchedItemId.length > 0 &&
                item.fullName
                  ?.toLowerCase()
                  .includes(searchedItemId?.toLowerCase())
                  ? searchedItemRef
                  : null
              }
              className={`bulked-unit-item ${
                userStatus.success.findIndex((el) => el === item.id) !== -1 &&
                'success-bulked-unit-item'
              }
            ${
              userStatus.failure.findIndex((el) => el === item.id) !== -1 &&
              'failed-bulked-unit-item'
            }
            mb-3 ${
              searchedItemId.length > 0 &&
              item.fullName
                ?.toLowerCase()
                .includes(searchedItemId?.toLowerCase())
                ? 'is-search'
                : ''
            }`}
            >
              <div className='card-checkbox-wrapper'>
                <CheckboxesComponent
                  isDisabled={
                    userStatus.success.findIndex((el) => el === item.id) !== -1
                  }
                  singleChecked={getIsChecked(item)}
                  idRef={`usersCheckboxItemRef`}
                />
              </div>
              <div className='body-item-wrapper'>
                <div className='body-image-item'>
                  <LoadableImageComponant
                    classes='cover-image'
                    type={LoadableImageEnum.div.key}
                    alt={t(`user-image`)}
                    src={
                      item.profileImg
                        ? getDownloadableLink(item.profileImg)
                        : ContactTypeEnum.man.defaultImg
                    }
                  />
                </div>
              </div>
              <div>
                <div className='item-ref-no pl-2 pr-2 mt-1'>
                  {item.fullName}
                </div>
                <div className='item-name pl-2 pr-2 mt-2'>{item.userName}</div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default UsersViewer;
