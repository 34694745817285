import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonBase, DialogContentText } from '@material-ui/core';
import {
  AutocompleteComponent,
  DatePickerComponent,
  DialogComponent,
  Spinner,
  Tables,
} from '../../../../../../../Components';
import { InvoiceDeleteDialog, InvoiceManagementDialog } from '../../../Dialogs';
import {
  TableActions,
  UnitsOperationTypeEnum,
} from '../../../../../../../Enums';
import {
  CancelInvoice,
  CreateInvoicesFromPaymentPlanInstallment,
  GetAllPaymentPlanByPropertyId,
  GetInvoices,
  GetInvoicesById,
  GetReservationInvoices,
} from '../../../../../../../Services';
import {
  generateUniqueKey,
  GlobalHistory,
  showError,
  showSuccess,
} from '../../../../../../../Helper';
import InvoiceStatusEnum from '../../../../../../../Enums/InvoiceStatus.Enum';

export const InvoiceSaleRelatedComponent = ({
  state,
  selected,
  onSelectedChanged,
  unitTransactionId,
  unitData,
  onStateChanged,
  parentTranslationPath,
  translationPath,
  cantEditReservedSaleTransaction,
  disabled,
  propertyId,
}) => {
  const headerData = [
    {
      id: 1,
      label: 'payment-no',
      component: (item, index) => <span>{index + 1}</span>,
    },
    {
      id: 2,
      label: 'amount-due',
      input: 'amountDue',
    },
    {
      id: 3,
      label: 'type',
      input: 'paymentType.lookupItemName',
    },
    {
      id: 4,
      label: 'mode',
      input: 'paymentMode.lookupItemName',
    },
    {
      id: 4,
      label: 'ref-no',
      component: () => <span>{unitData && unitData.refNo}</span>,
    },
    {
      id: 5,
      label: 'status',
      component: (item) => (
        <span>{InvoiceStatusEnum[item.invoiceStateId] ?? '-'}</span>
      ),
    },
    {
      id: 6,
      label: 'net-amount',
      input: 'amountDue',
    },
    {
      id: 7,
      label: 'balance',
      input: 'amountDue',
    },
  ];
  const baseActionsList = [
    {
      enum: TableActions.editText.key,
    },
  ];
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isOpenInvoiceDialog, setIsOpenInvoiceDialog] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [focusedRow, setFocusedRow] = useState([]);
  const [paymentPlans, setPaymentPlans] = useState([]);
  const [propertyPaymentPlanId, setPropertyPaymentPlanId] = useState(null);
  const [actionsList, setActionsList] = useState(baseActionsList);
  const [invoiceForCancelId, setInvoiceForCancelId] = useState(null);
  const [displayCanceledInvoices, setDisplayCanceledInvoices] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [insertInvoiceKey, setInsertInvoiceKey] = useState(generateUniqueKey());
  const [filter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const tableActionClicked = useCallback(
    async (actionEnum, item, focusedRow) => {
      const invoice = await GetInvoicesById(item.invoiceId);
      setFocusedRow(focusedRow);
      if (actionEnum === TableActions.editText.key) {
        setActiveItem(item);
        setIsOpenInvoiceDialog(true);
      } else if (actionEnum === TableActions.deleteText.key) {
        setActiveItem(item);
        setIsOpenConfirm(true);
      } else if (actionEnum === TableActions.close.key) {
        setInvoiceForCancelId(item.invoiceId);
      } else if (actionEnum === TableActions.openFile.key) {
        GlobalHistory.push({
          pathname: `/home/invoices/view`,
          state: { activeItem: invoice, invoiceId: item.invoiceId },
        });
      }
    },
    []
  );

  const basicInvoices = useMemo(
    () => selected.invoices?.filter((item) => item.invoiceStateId !== 4) ?? [],
    [selected.invoices]
  );
  const canceledInvoices = useMemo(
    () => selected.invoices?.filter((item) => item.invoiceStateId === 4) ?? [],
    [selected.invoices]
  );

  const getAllInvoices = useCallback(async () => {
    setIsLoading(true);
    setIsFirstLoad(false);
    const res = await GetReservationInvoices(unitTransactionId);
    if (!(res && res.status && res.status !== 200)) {
      if (onSelectedChanged) {
        onSelectedChanged({
          id: 'invoices',
          value:
            res.map((item) => ({
              ...item,
              paymentMode: {
                lookupItemId: item.paymentModeId,
                lookupItemName: item.paymentModeName || 'N/A',
              },
              paymentType: {
                lookupItemId: item.paymentTypeId,
                lookupItemName: item.paymentTypeName || 'N/A',
              },
            })) || [],
        });
      }
    }
    setIsLoading(false);
  }, [onSelectedChanged, unitTransactionId]);

  const getAllPaymentPlans = async () => {
    const res = await GetAllPaymentPlanByPropertyId({
      pageIndex: 1,
      pageSize: 1000,
      propertyId,
    });
    if (!(res && res.status && res.status !== 200)) {
      setPaymentPlans(res?.result);
    }
  };

  const handleCreateInvoices = async () => {
    const body = {
      unitTransactionId,
      propertyPaymentPlanId,
      startDate,
    };
    const res = await CreateInvoicesFromPaymentPlanInstallment(body);
    if (!(res?.status && res.status !== 200)) {
      if (unitTransactionId) {
        getAllInvoices();
        setStartDate('');
        setPropertyPaymentPlanId('');
        setInsertInvoiceKey(generateUniqueKey());
      }
    }
  };

  const cancelHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await CancelInvoice(invoiceForCancelId);
      if (!(res?.status && res.status !== 200)) {
        getAllInvoices();
        setInvoiceForCancelId(null);
        showSuccess(t('closed-successfully'));
      } else {
        throw res;
      }
    } catch (error) {
      showError(t('something-went-wrong'));
    }
  };

  useEffect(() => {
    if (propertyId) {
      getAllPaymentPlans();
    }
    getAllInvoices();
  }, [propertyId]);

  useEffect(() => {
    if (
      unitTransactionId &&
      state.invoicesIds.length > 0 &&
      isFirstLoad &&
      (!selected.invoices || selected.invoices.length === 0)
    )
      getAllInvoices();
  }, [
    unitTransactionId,
    getAllInvoices,
    selected.invoices,
    state.invoicesIds,
    isFirstLoad,
  ]);

  const focusedRowChanged = (rowIndex, item) => {
    if (rowIndex === -1) return;
    if (InvoiceStatusEnum[item?.invoiceStateId] === InvoiceStatusEnum[2]) {
      setActionsList(
        baseActionsList.concat([
          { enum: TableActions.close.key, title: 'close' },
          // {
          //   enum: TableActions.deleteText.key,
          // },
          { enum: TableActions.openFile.key },
        ])
      );
    } else if (
      InvoiceStatusEnum[item?.invoiceStateId] === InvoiceStatusEnum[3]
    ) {
      setActionsList([{ enum: TableActions.openFile.key }]);
    } else {
      setActionsList([]);
    }
  };
  return (
    <div className='unit-status-contact-info-wapper childs-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div
        className='header-view-sale d-flex flex-v-end gap-1 mb-3'
        key={insertInvoiceKey}
      >
        <div className='payment-plans-component'>
          <AutocompleteComponent
            idRef='paymentPlansIdRef'
            labelValue={t('payment-plans', {
              ns: 'UnitsProfileManagementView',
            })}
            data={paymentPlans || []}
            multiple={false}
            displayLabel={(option) => option.paymentPlanName || ''}
            getOptionSelected={(option) => option.paymentPlanName || ''}
            onChange={(e, newValue) => {
              setPropertyPaymentPlanId(newValue?.propertyPaymentPlanId);
            }}
            withoutSearchButton
            selectedValues={paymentPlans?.find(
              (item) => item.propertyPaymentPlanId === propertyPaymentPlanId
            )}
            // parentTranslationPath={parentTranslationPath}
            // translationPath={translationPath}
          />
        </div>
        <div className='start-date-component'>
          <DatePickerComponent
            idRef='paymentPlansDateIdRef'
            labelValue='start-date'
            placeholder='DD/MM/YYYY'
            clearable={true}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onDateChanged={(event) => {
              setStartDate(event ? new Date(event?._d) : null);
            }}
            value={startDate}
          />
        </div>
        <div className='insert-button'>
          <ButtonBase
            onClick={handleCreateInvoices}
            className='btns theme-solid'
          >
            <span className='mdi mdi-plus' />
            <span className='px-1'>
              {t(`${translationPath}insert`, {
                ns: 'UnitsProfileManagementView',
              })}
            </span>
          </ButtonBase>
        </div>
      </div>
      <div className='d-flex mb-3'>
        <ButtonBase
          className='btns theme-solid mx-2'
          onClick={() => setIsOpenInvoiceDialog(true)}
          disabled={cantEditReservedSaleTransaction && !disabled}
        >
          <span className='mdi mdi-plus' />
          <span className='px-1'>{t(`${translationPath}add-invoice`)}</span>
        </ButtonBase>
        <ButtonBase
          className='btns theme-outline mx-2'
          onClick={() => setDisplayCanceledInvoices(true)}
          disabled={cantEditReservedSaleTransaction && !disabled}
        >
          <span class='mdi mdi-close-circle' />
          <span className='px-1'>
            {t(`${translationPath}show-canceled-invoices`)}
          </span>
        </ButtonBase>
      </div>
      <Tables
        data={basicInvoices}
        headerData={headerData}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        defaultActions={
          !(cantEditReservedSaleTransaction && !disabled) ? actionsList : []
        }
        activePage={filter.pageIndex}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        totalItems={(selected.invoices && selected.invoices.length) || 0}
        focusedRowChanged={focusedRowChanged}
        itemsPerPage={basicInvoices.length}
      />
      {displayCanceledInvoices && (
        <div className='py-5'>
          <h3>{t('canceled-invoices')}</h3>
          {canceledInvoices.length > 0 ? (
            <Tables
              data={canceledInvoices ?? []}
              headerData={headerData}
              defaultActions={[]}
              activePage={filter.pageIndex}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              totalItems={canceledInvoices?.length ?? 0}
              itemsPerPage={canceledInvoices.length}
            />
          ) : (
            <h6>{t('no-invoices')}</h6>
          )}
        </div>
      )}
      {isOpenInvoiceDialog && (
        <InvoiceManagementDialog
          currentOperationType={UnitsOperationTypeEnum.sale.key}
          effectedByNumber={state.sellingPrice}
          effectedByName='selling-price'
          totalInvoicesLength={(selected && selected.invoices.length) || 0}
          activeItem={activeItem}
          isOpen={isOpenInvoiceDialog}
          focusedRow={focusedRow}
          reloadData={(savedItem) => {
            const localInvoices =
              (selected.invoices && [...selected.invoices]) || [];
            const index = localInvoices.findIndex(
              (item) => item.invoiceId === savedItem.invoiceId
            );
            if (index !== -1) {
              localInvoices.splice(index, 1);
              localInvoices.push(savedItem);
            } else localInvoices.push(savedItem);
            if (onSelectedChanged)
              onSelectedChanged({ id: 'invoices', value: localInvoices });
            let localStatuses = [];
            if (state && state.invoicesIds)
              localStatuses = state.invoicesIds.filter(
                (item) => item !== savedItem.invoiceId
              );

            localStatuses.push(savedItem.invoiceId);
            onStateChanged({ id: 'invoicesIds', value: localStatuses });
            setIsOpenInvoiceDialog(false);
            setActiveItem(null);
          }}
          isOpenChanged={() => {
            setIsOpenInvoiceDialog(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          unitTransactionId={unitTransactionId}
        />
      )}
      {activeItem && (
        <InvoiceDeleteDialog
          activeItem={activeItem}
          isOpen={isOpenConfirm}
          isOpenChanged={() => {
            setIsOpenConfirm(false);
            setActiveItem(null);
          }}
          reloadData={() => {
            // setFilter((item) => ({ ...item, pageIndex: 0 }));
            setActiveItem(null);
            setIsOpenConfirm(false);
          }}
          invoicesIds={state?.invoicesIds || []}
          onStateChanged={onStateChanged}
          selectedInvoices={selected?.invoices || []}
          onSelectedChanged={onSelectedChanged}
          focusedRow={focusedRow + 1}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {invoiceForCancelId && (
        <DialogComponent
          isOpen
          titleText={'close-invoice'}
          dialogContent={
            <DialogContentText>{t('close-text')}</DialogContentText>
          }
          maxWidth={'sm'}
          onCloseClicked={() => setInvoiceForCancelId(null)}
          onCancelClicked={() => setInvoiceForCancelId(null)}
          onSaveClicked={cancelHandler}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          saveText={'close-invoice'}
        />
      )}
    </div>
  );
};

InvoiceSaleRelatedComponent.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.instanceOf(Object).isRequired,
  unitTransactionId: PropTypes.number,
  unitData: PropTypes.instanceOf(Object).isRequired,
  //   schema: PropTypes.instanceOf(Object).isRequired,
  onStateChanged: PropTypes.func.isRequired,
  onSelectedChanged: PropTypes.func.isRequired,
  //   isSubmitted: PropTypes.bool.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
InvoiceSaleRelatedComponent.defaultProps = {
  unitTransactionId: undefined,
};
