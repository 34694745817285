
const FirstLettersExp = /\b(\w)/gm;

export const getFirstLastNameLetters = (fullName) => {
    if(fullName){
    const fullNameSegments = fullName.split(' ');
    const firsName = fullNameSegments[0];
    const lastNameIndex = fullNameSegments.length - 1;
    const lastName = fullNameSegments[lastNameIndex];
    const firstLastName = `${firsName} ${lastName}`;
    const capitalizedName = firstLastName.toUpperCase();
    return capitalizedName?.match(FirstLettersExp)?.join('');
   }
   return '';
};
