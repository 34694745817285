export const DocumentSourceTypeEnum = {
    LandlordOfferLetter: {
      key : 1,
      name: "LandlordOfferLetter",
    },
    TenantOfferLetter : {
      key : 2,
      name: "TenantOfferLetter",
    },
    LandlordPassport : {
      key : 3,
      name: "LandlordPassport",
    },
    LandlordUAEId : {
      key : 4,
      name: "LandlordUAEId",
    },
    LandlordLicenseCopyAndPOA : {
      key : 5,
      name: "LandlordLicenseCopyAndPOA",
    },
    UnitSPACopy : {
      key : 6,
      name: "UnitSPACopy",
    },
    LandlordTenancyContract : {
      key : 7,
      name: "LandlordTenancyContract",
    },
    TenantTenancyContract : {
      key : 8,
      name: "TenantTenancyContract",
    },
    TawtheeqContract : {
      key : 9,
      name: "TawtheeqContract",
    },
    DistrictCoolingClearance : {
      key : 10,
      name: "DistrictCoolingClearance",
    },
    TenantPassport : {
      key : 11,
      name: "TenantPassport",
    },
    TenantUAEId : {
      key : 12,
      name: "TenantUAEId",
    },
    TenantAuthorizedSignatoryVisaPage : {
      key : 13,
      name: "TenantAuthorizedSignatoryVisaPage",
    },
    TenantLicenseCopyAndPOA : {
      key : 14,
      name: "TenantLicenseCopyAndPOA",
    },
    LandlordGoogleReview : {
      key : 15,
      name: "LandlordGoogleReview",
    },
    TenantGoogleReview : {
      key : 16,
      name: "TenantGoogleReview",
    },
    RentPerYearReceipts : {
      key : 17,
      name: "RentPerYearReceipts",
    },
    SecurityDepositReceipts : {
      key : 18,
      name: "SecurityDepositReceipts",
    },
    PaymentRecipientAcknowledgment : {
      key : 19,
      name: "PaymentRecipientAcknowledgment",
    },
    PSIReceiptVoucherAndReceipt : {
      key : 20,
      name: "PSIReceiptVoucherAndReceipt",
    }, 
}