import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Tables } from '../../../TablesComponent/Tables';
import {
    GetAllDFMTransactionForAgentInRotationScheme,
} from '../../../../Services/AuditTrailServices/AuditTrailServices';
import { PaginationComponent } from '../../../PaginationComponent/PaginationComponent';
import { Spinner } from '../../../SpinnerComponent/Spinner';
import {  RequestTypeEnum } from '../../../../Enums';

export const ManageAgentsHistoryComponent = ({
  parentTranslationPath,
  translationPath,
  typeId,
  rotationSchemeId , 
}) => {
  
  const { t } = useTranslation(parentTranslationPath);
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState({
    result: [],
    totalCount: 0,
  });
  const [hideUpdatedValuesList , setHideUpdatedValuesList] = useState([
    {key : 'Agent'} , 
    {key : 'RotationScheme'} ,
    {key : 'CreatedBy'} , 
    {key : 'CreatedOn'} , 
    {key : 'UpdateOn'} , 
    {key : 'UpdatedBy'} , 
    {key : 'AgentId'} , 
    {key : 'Id'} , 
  ]) ; 
  const [changeFields, setChangeFields] = useState([]);

  const activeItem = useSelector((state) => state.ActiveItemReducer);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
  });
  const pathName = window.location.pathname
    .split('/home/')[1]
    .split('/view')[0]
    .split('/')[0];
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  const getAllDFMTransaction = useCallback(async () => {
    setIsLoading(true);
    let  result = await GetAllDFMTransactionForAgentInRotationScheme(filter.pageIndex ,(filter.pageSize) ,rotationSchemeId );
    if (!(result && result.status && result.status !== 200)) {
     setTransactions({
        result: result && result.result,
        totalCount: result && result.totalCount,
      });
  } else setTransactions({ result: [], totalCount: 0 });
    setIsLoading(false);
  }, [filter, pathName]);



  useEffect(() => {
    getAllDFMTransaction();
  }, [getAllDFMTransaction]);

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    setChangeFields([]);
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
    setChangeFields([]);
  };

  const getChangeListUpdated = (responseBefore , responseAfter , element  ) => 
  {
    let list = [] ; 
    const  output1 = Object.entries(responseBefore).map(([key, value]) => ({key,value}));
    const  output2 = Object.entries(responseAfter).map(([key, value]) => ({key,value}));
     if(output1)
        {
          output1.map((o)=> 
          {
              output2.map((o2)=> 
              {

               if(!(hideUpdatedValuesList.some(k => k.key === o.key )) && o.key === o2.key &&  (JSON.stringify(o.value)) !== (JSON.stringify(o2.value)))
                  list.push({fieldName : o.key , changedFrom : o.value , changedTo : o2.value , agentName : (element && element.Agent && element.Agent.AgentName)})

                  else if(o.key === 'Agent'  &&  o.key === o2.key && ((JSON.stringify(o.value)) !== (JSON.stringify(o2.value))))
                  list.push({fieldName : o.key , changedFrom : (o.value && o.value.AgentName) , changedTo : (o2.value && o2.value.AgentName) , agentName : ''})

                })
 
              })
          }

         return list  ; 
      };


  const objectIfIsArray = (list) =>
    list &&
    list.length &&  list.map((item, index) => (
      <span>
        {
         (item && item.name )|| item 
        }
        {list.length - 1 != index && <span> , </span>}
      </span>
    ));

  const rotationManageAgentsFieldChangeMapper = useCallback(
    (itemValue ,  actionType) => {
      setIsLoading(true);
       let  changes = [];
       // this response have more details we handling from frontend Temporarily in 16/10/2023   Ayat Alhamad
       if(actionType === 2 )
       {
        let beforeChangesArr = itemValue &&  JSON.parse(itemValue.response); 
        let afterChangesArr  = itemValue &&  itemValue.responseAfterChanged  &&  JSON.parse(itemValue.responseAfterChanged) ;
 
        if(beforeChangesArr &&  typeof beforeChangesArr  === "string" )
          beforeChangesArr= JSON.parse(beforeChangesArr); 
 
        if(afterChangesArr &&  typeof afterChangesArr  === "string" )
         afterChangesArr= JSON.parse(afterChangesArr); 
 
        if(afterChangesArr  &&  Array.isArray(afterChangesArr))
        { 
          beforeChangesArr.map((element , index)=> 
          {
           afterChangesArr.map((element2 , index2 )=> 
           {
             if( element && element2  && element.Id === element2.Id)
             { 
               changes = getChangeListUpdated(element , element2 , element ) ; 
 
             }  
           });
          });
        }
        else if (afterChangesArr && typeof afterChangesArr === 'object'  )
            changes = getChangeListUpdated(beforeChangesArr , afterChangesArr , beforeChangesArr ) ; 

       }
       else if( itemValue.response && (actionType === 1 ||  actionType === 4 )) 
       {
        let beforeChangesArr = itemValue &&  JSON.parse(itemValue.response);

         if(beforeChangesArr && beforeChangesArr.Agent  )
            changes.push({fieldName : 'Agent Name' , agentName :(beforeChangesArr.Agent &&  beforeChangesArr.Agent.AgentName)})

       }
       else if( itemValue.response && (actionType === 9 )) 
       {
          let beforeChangesArr = itemValue &&  JSON.parse(itemValue.response);

         if(beforeChangesArr && beforeChangesArr.Agent  )
            changes.push({fieldName : 'Agent Name' , agentName :(beforeChangesArr.Agent &&  beforeChangesArr.Agent.AgentName)})

       }
       else if( itemValue.response && (actionType === 10 )) 
       {
          let beforeChangesArr = itemValue &&  JSON.parse(itemValue.response);

         if(beforeChangesArr && beforeChangesArr.Agent  )
            changes.push({fieldName : 'Agent Name' , agentName :(beforeChangesArr.Agent &&  beforeChangesArr.Agent.AgentName)})

       }
      setChangeFields(() => ((changes.length > 0 && changes) || []));
      setIsLoading(false);
    },
     [pathName]
  );

  const findRequestType = (requestType) => {
    const requestTypeName = RequestTypeEnum.find((item) => item.key === requestType);

    if (requestTypeName)
        return requestTypeName.value;
    return 'Read';
  };



  return (
    <div className='history-component-content-wrapper w-100 mt-2'>
      <div className='history-table-conent-wrapper history-dialog-wrapper d-flex-column-center w-100 transaction-history-wrapper'>
        <Spinner isActive={isLoading}  />
        <div className='w-100 history-table-header'>
          <span>{t(`${translationPath}date-and-time-viewed`)}</span>
          <span>{t(`${translationPath}user`)}</span>
          <span>{t(`${translationPath}type`)}</span>
        </div>
        {transactions &&
          transactions.result &&
          transactions.result.map((item, index) => (
            <Accordion
              key={`${index + 1}-history`}
              className={`expand-history-icon ${
                index % 2 === 0 ? 'is-gray' : ''
              }`}
              expanded={
                expanded === item.dfMTransactionId &&
                (item.requestType && findRequestType(item.requestType)) !== 'Read'
              }
              onChange={handleChange(item.dfMTransactionId)}
            >
              <AccordionSummary
               key={`${index + 1}-historyAgent`}
                onClick={() =>
                 item &&  rotationManageAgentsFieldChangeMapper(item , item.requestType)}
              >
                <div
                  className={`history-table-content w-100 ${
                    index % 2 === 0 ? 'is-gray' : ''
                  }`}
                >
                  <div
                    className={`history-expand-icon ${
                      (item.requestType && findRequestType(item.requestType)) !== 'Read' ? '' : 'is-gray'
                    } `}
                  >
                    <span
                      className={`mdi mdi-chevron-${
                        expanded === item.dfMTransactionId &&
                        (item.requestType && findRequestType(item.requestType)) !== 'Read' ?
                          'up' :
                          'down'
                      }`}
                    />
                  </div>
                  <span>
                    {(item.createdOn &&
                      moment(item.createdOn).format('DD/MM/YYYY - hh:mm A')) ||
                      'N/A'}
                  </span>
                  <span>
                    {(item.createdByName && item.createdByName) || 'N/A'}
                  </span>
                  <span>
                    {(item.requestType && findRequestType(item.requestType)) || 'N/A'}
                  </span>
                </div>
              </AccordionSummary>
              {item  &&
                changeFields
                && (
                  <AccordionDetails
                   key={`${index + 1}-historyChangeFields`}
                  >
                    <Tables
                      data={changeFields || []}
                      headerData={ item.requestType === 2 ?  [
                        {
                          id: 1,
                          label: 'agentName',
                          input: 'agentName',
                        }, 
                        
                        {
                          id: 2,
                          label: 'field-name',
                          input: 'fieldName',
                        },
                        {
                          id: 3,
                          label: 'changed-from',
                          component: (el) => (
                            <span>
                              {(el.changedFrom &&
                                (typeof el.changedFrom === 'string' || typeof el.changedFrom === 'number') &&
                                el.changedFrom) ||
                                (typeof el.changedFrom === 'object' && el.changedFrom !== null ? (Array.isArray(el.changedFrom) ?
                                el.changedFrom.length &&  objectIfIsArray(el.changedFrom) :
                                JSON.stringify(el.changedFrom)) : ''
                                )}
                            </span>
                          ),
                        },
                        {
                          id: 4,
                          label: 'changed-to',
                          component: (el) => (
                            <span>
                              {(el.changedTo &&
                               (typeof el.changedTo === 'string' || typeof el.changedTo === 'number') &&
                                el.changedTo) ||
                                (typeof el.changedTo === 'object' && el.changedTo !== null ? (Array.isArray(el.changedTo) ?
                                el.changedTo.length &&   objectIfIsArray(el.changedTo)
                                : JSON.stringify(el.changedTo)) : ''
                                 )
                              }  
                            </span>
                          ),
                        },
                      ]:[
                        {
                          id: 1,
                          label: 'agentName',
                          input: 'agentName',
                        }, 
                         
                      ]}
                      activePage={0}
                      itemsPerPage={changeFields.length || 0}
                      defaultActions={[]}
                      translationPath={translationPath}
                      parentTranslationPath={parentTranslationPath}
                      totalItems={changeFields.length || 0}
                      insideForm
                    />
                  </AccordionDetails>
                )}
            </Accordion>
          ))}
      </div>
      <div className='pagination-history-wrapper'>
        <PaginationComponent
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={transactions.totalCount || 0}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      </div>
    </div>
  );
};

ManageAgentsHistoryComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
