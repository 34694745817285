import React from "react";

import {
  FilterBlankIcon,
  FilterContainIcon,
  FilterEndWithIcon,
  FilterEqualIcon,
  FilterGreaterThan,
  FilterLessThan,
  FilterNotBlankIcon,
  FilterNotContainIcon,
  FilterNotEqualIcon,
  FilterStartWithIcon,
} from "../assets/icons";

export const NewTableFilterOperatorsEnum = (text) => ({
  equal: {
    id: 1,
    name: "Equal",
    fieldType: "text",
    icon: <FilterEqualIcon fill={text.secondary} height="16px" width="16px" />,
  },
  contains: {
    id: 2,
    name: "Contains",
    fieldType: "text",
    icon: (
      <FilterContainIcon fill={text.secondary} height="16px" width="16px" />
    ),
  },
  greaterThan: {
    id: 3,
    name: "Greater Than Or Equal",
    fieldType: "text",
    icon: (
      <FilterGreaterThan fill={text.secondary} height="16px" width="16px" />
    ),
  },
  lessThan: {
    id: 4,
    name: "Less Than Or Equal",
    fieldType: "text",
    icon: <FilterLessThan fill={text.secondary} height="16px" width="16px" />,
  },

  startWith: {
    id: 5,
    name: "Start With",
    fieldType: "text",
    icon: (
      <FilterStartWithIcon fill={text.secondary} height="16px" width="16px" />
    ),
  },
  endWith: {
    id: 6,
    name: "End With",
    fieldType: "text",
    icon: (
      <FilterEndWithIcon fill={text.secondary} height="16px" width="16px" />
    ),
  },

  notEqual: {
    id: 7,
    name: "Not Equal",
    fieldType: "text",
    icon: (
      <FilterNotEqualIcon fill={text.secondary} height="16px" width="16px" />
    ),
  },
  notContains: {
    id: 8,
    name: "Not Contains",
    fieldType: "datepicker",
    icon: (
      <FilterNotContainIcon fill={text.secondary} height="16px" width="16px" />
    ),
  },
  isBlank: {
    id: 9,
    name: "Blank",
    fieldType: "datepicker",
    icon: <FilterBlankIcon fill={text.secondary} height="16px" width="16px" />,
  },
  isNotBlank: {
    id: 10,
    name: "Not Blank",
    fieldType: "datepicker",
    icon: (
      <FilterNotBlankIcon fill={text.secondary} height="16px" width="16px" />
    ),
  },
});
