import { UnitsOperationTypeEnum } from '../Enums';
import { getUnits } from '../Services';

export const SekeerLeadRule = async (itemList, values) => {
  if (values && values.contact_name && values.property_name) {
    const countryIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'country');
    const cityIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'city');
    const districtIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'district');
    const communityIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'community');
    const subCommunityIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'sub_community');

    if (countryIndex !== -1)
      itemList[countryIndex].data.isReadonly = true;

    if (cityIndex !== -1)
      itemList[cityIndex].data.isReadonly = true;

    if (districtIndex !== -1)
      itemList[districtIndex].data.isReadonly = true;

    if (communityIndex !== -1)
      itemList[communityIndex].data.isReadonly = true;

    if (subCommunityIndex !== -1)
      itemList[subCommunityIndex].data.isReadonly = true;
  }
};

export async function OnChangeOperationTypeForSeekerLead(
  item,
  v,
  itemList,
  setData,
  setNewValue,
  values,
  formType
) {
  if (item.field.id !== 'operation_type') return;
  
  const unitRefNumber1Index = itemList.findIndex((f) => f.field.id.toLowerCase() === 'unit_ref-number');
  const unitRefNumber2Index = itemList.findIndex((f) => f.field.id.toLowerCase() === 'unit_ref_number');
  const contactNameIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'contact_name');
  const propertyNameIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'property_name');
  const isMortgageIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'is_mortgage');
   const isSaleTypeIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'sale_type');

  if (unitRefNumber1Index !== -1 && formType === 1 && isMortgageIndex !== -1) {
    itemList[unitRefNumber1Index].data.enum = [];
    setData('unit_ref-number', {});
    setData('unit_ref_number', {});
    setData('referredto', {});

    if ((v && v.lookupItemName === 'Rent') || !v) {
      setData('is_mortgage', {});
      setData('mode_of_payment', {});
      setData('mode_of-payment', null);
      setData('sale_type', {});
    }
  }

  if (unitRefNumber2Index !== -1 && formType === 2 && isMortgageIndex !== -1) {
    setData(null, 0, 'unit_ref_number');
    if (v && v.lookupItemName === 'Rent') {
      setData(null, 0, 'is_mortgage');
      setData(null, 0, 'mode_of_payment');
      setData(null, 0, 'sale_type');

    }
    setData(null , 0 ,  'referredto');
    itemList[unitRefNumber2Index].data.enum = [];
  }

  if (item.field.id === 'operation_type' && contactNameIndex !== -1 && propertyNameIndex !== -1) {
    if (!v) {
      if (unitRefNumber1Index !== -1 && formType === 1) {
        setData('unit_ref-number', {});
        setData('unit_ref_number', {});
        itemList[unitRefNumber1Index].data.enum = [];
      }
      if (unitRefNumber2Index !== -1 && formType === 2) {
        setData(null, 0, 'unit_ref_number');
        itemList[unitRefNumber2Index].data.enum = [];
      }

      return;
    }
    setTimeout(() => {

    }, 200);
    const unitType = v ? (v.lookupItemName === 'Buy' ? UnitsOperationTypeEnum.sale.key : UnitsOperationTypeEnum.rent.key) : null;
    const units = await getUnits({
      pageIndex: 0, pageSize: 10, operationType: unitType, propertyId: (values && values.property_name && values.property_name.id)
    });

    if (!units && !units.result) return;

    setTimeout(() => {
      if (unitRefNumber1Index !== -1) {
        units.result.map((valueLoop) => {
          itemList[unitRefNumber1Index].data.enum.push({
            id: valueLoop.unitId,
            name: (valueLoop && valueLoop.unit && valueLoop.unit.unit_ref_no) || (valueLoop && valueLoop.unitRefNo),
            type: (valueLoop && valueLoop.unit && valueLoop.unit.unit_type_id) || 1,
            operationType: (valueLoop && valueLoop.unit && valueLoop.unit.operation_type && valueLoop.unit.operation_type.lookupItemName) || (valueLoop && valueLoop.operationType),
            propertyId: valueLoop && valueLoop.unit && valueLoop.unit.property_name && valueLoop.unit.property_name.id,

          });
        });
      } else if (unitRefNumber2Index !== -1) {
        units.result.map((valueLoop) => {
          itemList[unitRefNumber2Index].data.enum.push({
            id: valueLoop.unitId,
            name: (valueLoop && valueLoop.unit && valueLoop.unit.unit_ref_no) || (valueLoop && valueLoop.unitRefNo),
            type: (valueLoop && valueLoop.unit && valueLoop.unit.unit_type_id) || 1,
            operationType: (valueLoop && valueLoop.unit && valueLoop.unit.operation_type && valueLoop.unit.operation_type.lookupItemName) || (valueLoop && valueLoop.operationType),
            propertyId: valueLoop && valueLoop.unit && valueLoop.unit.property_name && valueLoop.unit.property_name.id,

          });
        });
      }
    });
    setNewValue(Math.random());
  }
}

export const onChangeOperationTypeForTenantLead = async (
  v,
  itemList,
  setData,
) => {
  const isMortgageIndex = itemList.findIndex((f) => f.field.id === 'is_mortgage');
  if (v && v.lookupItemName === 'Rent' && isMortgageIndex !== -1) {
    setData('is_mortgage', {});
    itemList[isMortgageIndex].data.isReadonly = true;
  } else if (isMortgageIndex !== -1 && (v && v.lookupItemName === 'Buy'))
    itemList[isMortgageIndex].data.isReadonly = false;
};
