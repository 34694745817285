import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ButtonBase,
} from '@material-ui/core';
import { useTranslate } from '../../../../Hooks';
import {
  GetDuplicatedCriteriaUnits,
  GetLookupItemsByLookupTypeName,
  GetUnitsDuplicationCriteria,
} from '../../../../Services';
import {
  AutocompleteComponent,
  Inputs,
  PaginationComponent,
  Spinner,
  Tables,
} from '../../../../Components';
import { DuplicatedUnitsTableHeaderData } from './DuplicatedUnitsTableHeaderData';
import { bottomBoxComponentUpdate } from '../../../../Helper';
import './DuplicatedUnitsStyles.scss';
import { UnitsFilterStatusEnum } from '../../../../Enums';

export const DuplicatedUnitsView = () => {
  const parentTranslationPath = 'UnitsView';
  const translationPath = '';
  const { t } = useTranslation(parentTranslationPath);
  const [data, setData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [duplicatedUnits, setDuplicatedUnits] = useState([]);
  const [saleTypes, setSaleTypes] = useState([]);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
  });
  const [duplicatedUnitsCriteria, setDuplicatedUnitsCriteria] = useState({
    result: [],
    totalCount: 0,
  });
  const [activeStep, setActiveStep] = useState(null);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const searchTimer = useRef(null);
  const [searchedItem, setSearchedItem] = useState(null);
  const [unitTypes, setUnitTypes] = useState([]);
  const [criteria, setCriteria] = useReducer(reducer, {
    propertyName: null,
    untiNumber: null,
    unitRefNumber: null,
    unitType: {},
    saleStatus: {},
    leaseStatus: {},
  });

  const APIGetUnitsDuplicationCriteria = useCallback(async () => {
    setIsLoading(true);

    const body = {
      propertyName: criteria?.propertyName,
      unitNumber: criteria?.untiNumber,
      unitRefNo: criteria?.unitRefNumber,
      unitType: criteria?.unitType?.lookupItemName,
      saleStatus: criteria?.saleStatus?.key,
      leaseStatus: criteria?.leaseStatus?.key,
      pageIndex: filter?.pageIndex,
      pageSize: filter?.pageSize,
    };

    const result = await GetUnitsDuplicationCriteria(body);

    if (!(result && result.status && result.status !== 200)) {
      setDuplicatedUnitsCriteria(result);
    } else {
      setDuplicatedUnitsCriteria({ result: [], totalCount: 0 });
    }

    setIsLoading(false);
  });

  const APIGetDuplicatedCriteriaUnits = useCallback(
    async (unitDuplicationCriteriaId) => {
      try {
        const result = await GetDuplicatedCriteriaUnits(
          unitDuplicationCriteriaId
        );

        if (!(result && result.status && result.status !== 200)) {
          setDuplicatedUnits(result);
        } else {
          setDuplicatedUnits({ result: [], totalCount: 0 });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    [setDuplicatedUnits]
  );

  const getAllUnitSaleTypes = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'unitSaleType',
      pageSize: 100,
      pageIndex: 1,
    });

    if (!(res && res.status && res.status !== 200)) {
      setSaleTypes({ id: 'unitSaleTypes', value: res.result || [] });
    } else setSaleTypes({ id: 'unitSaleTypes', value: [] });
  };

  const getAllUnitTypes = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'unitType',
      pageSize: 100,
      pageIndex: 1,
    });

    if (!(res && res.status && res.status !== 200)) {
      setUnitTypes(res.result || []);
    } else setUnitTypes([]);
  };

  useEffect(() => {
    APIGetUnitsDuplicationCriteria();
  }, [filter]);

  useEffect(() => {
    getAllUnitSaleTypes();
    getAllUnitTypes();
  }, []);

  const handleShowClick = async (unitDuplicationCriteriaId) => {
    setIsLoading(true);
    try {
      await APIGetDuplicatedCriteriaUnits(unitDuplicationCriteriaId);
    } catch (error) {
      console.error('Error handling show click:', error);
    } finally {
      setActiveStep(
        unitDuplicationCriteriaId === activeStep
          ? null
          : unitDuplicationCriteriaId
      );
      setIsLoading(false);
    }
  };

  const clearFilterHandler = () => {
    const initialState = {
      propertyName: null,
      untiNumber: null,
      unitRefNumber: null,
      unitType: {},
      saleStatus: {},
      leaseStatus: {},
    };
    setCriteria({ id: 'edit', value: initialState });
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  useEffect(() => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      APIGetUnitsDuplicationCriteria();
    }, 700);
  }, [criteria]);

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={duplicatedUnitsCriteria.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });

  const Row = (props) => {
    const { row } = props;

    return (
      <>
        <TableRow
          className={`TableRow-TableBody-aml ${
            data.index === props.index ? 'isselect' : ''
          }`}
          index={props.index}
        >
          <TableCell align='right'>{row.propertyName}</TableCell>
          <TableCell align='right'>{row.unitNumber}</TableCell>
          <TableCell align='right'>{row.buildingNumber}</TableCell>
          <TableCell align='right'>{row.phaseNumber}</TableCell>
          <TableCell align='right'>
            <IconButton aria-label='expand row' size='small'>
              {activeStep !== null ? (
                <KeyboardArrowUpIcon
                  id='KeyboardArrowUpIcon-ref'
                  onClick={() => handleShowClick(row.unitDuplicationCriteriaId)}
                />
              ) : (
                <KeyboardArrowDownIcon
                  id='KeyboardArrowDownIcon-ref'
                  onClick={() => handleShowClick(row.unitDuplicationCriteriaId)}
                />
              )}
            </IconButton>
          </TableCell>
        </TableRow>
        {activeStep === row.unitDuplicationCriteriaId && (
          <TableRow>
            <TableCell colSpan={6} style={{ maxWidth: '1557px' }}>
              <div>
                <Tables
                  headerData={DuplicatedUnitsTableHeaderData || null}
                  data={duplicatedUnits || []}
                  itemsPerPage={25}
                  isLoading={isLoading}
                  defaultActions={[]}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  function createData(
    unitDuplicationCriteriaId,
    propertyName,
    unitNumber,
    buildingNumber,
    phaseNumber
  ) {
    return {
      unitDuplicationCriteriaId,
      propertyName,
      unitNumber,
      buildingNumber,
      phaseNumber,
    };
  }

  const rows =
    duplicatedUnitsCriteria && duplicatedUnitsCriteria.result
      ? duplicatedUnitsCriteria.result.map((row) =>
          createData(
            row.unitDuplicationCriteriaId,
            row.propertyName,
            row.unitNumber,
            row.buildingNumber,
            row.phaseNumber
          )
        )
      : [];

  return (
    <>
      <Spinner isActive={isLoading} isAbsolute />

      <div className='search-filters-container d-flex-column mx-4'>
        <div className='filters-container-rows d-flex'>
          <div className='search-name-holder w-100 mr-1-reversed mx-2'>
            <Inputs
              idRef='propertyNameSearchInputRef'
              inputPlaceholder={t(`${translationPath}property-name`)}
              value={criteria?.propertyName || ''}
              onInputChanged={(event) => {
                const newPropertyName = event.target.value;
                setCriteria({ id: 'propertyName', value: newPropertyName });
              }}
            />
          </div>
          <div className='search-criteria-holder w-100 mr-1-reversed mx-2'>
            <Inputs
              idRef='unitNumberSearchInputRef'
              inputPlaceholder={t(`${translationPath}unit-number`)}
              value={criteria?.untiNumber || ''}
              onInputChanged={(event) => {
                setCriteria({ id: 'untiNumber', value: event.target.value });
              }}
            />
          </div>
          <div className='search-criteria-holder w-100 mr-1-reversed mx-2'>
            <Inputs
              idRef='unitRefNumberSearchInputRef'
              inputPlaceholder={t(`${translationPath}unit-ref-number`)}
              value={criteria?.unitRefNumber || ''}
              onInputChanged={(event) => {
                setCriteria({ id: 'unitRefNumber', value: event.target.value });
              }}
            />
          </div>

          <div className='clear-search-criteria-filters-button-container'>
            <ButtonBase
              className='clear-search-criteria-filters-button'
              onClick={clearFilterHandler}
            >
              <span className='mdi mdi-filter-remove m-1' />
              {t(`${translationPath}clear-filter`)}
            </ButtonBase>
          </div>
        </div>

        <div className='filters-container-rows d-flex'>
          <div className='search-criteria-holder w-100 mr-1-reversed mx-2'>
            <AutocompleteComponent
              idRef='selectUnitTypeRef'
              data={unitTypes || []}
              inputPlaceholder={t('unit-type')}
              selectedValues={criteria.unitType || null}
              displayLabel={(option) => option?.lookupItemName}
              renderOption={(option) => option?.lookupItemName || ''}
              onChange={(event, newValue) => {
                setCriteria({
                  id: 'unitType',
                  value: newValue || {},
                });
              }}
              onInputKeyUp={(event) => {
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  APIGetUnitsDuplicationCriteria();
                }, 700);
              }}
              withoutSearchButton={true}
              multiple={false}
              isLoading={isLoading}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
          <div className='filters-container-rows w-100 mr-1-reversed mx-2'>
            <AutocompleteComponent
              idRef='selectUnitSaleStatusRef'
              data={Object.values(UnitsFilterStatusEnum.sale) || []}
              inputPlaceholder={t('sale-status')}
              selectedValues={criteria?.saleStatus || null}
              displayLabel={(option) => option?.value}
              renderOption={(option) => option?.value || ''}
              onChange={(event, newValue) => {
                setCriteria({
                  id: 'saleStatus',
                  value: newValue || {},
                });
              }}
              onInputKeyUp={(event) => {
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  APIGetUnitsDuplicationCriteria();
                }, 700);
              }}
              withoutSearchButton={true}
              multiple={false}
              isLoading={isLoading}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
          <div className='filters-container-rows w-100 mr-1-reversed mx-2'>
            <AutocompleteComponent
              idRef='selectUnitLeaseStatusRef'
              data={Object.values(UnitsFilterStatusEnum.lease) || []}
              inputPlaceholder={t('lease-status')}
              selectedValues={criteria?.leaseStatus || null}
              displayLabel={(option) => option?.value}
              renderOption={(option) => option?.value || ''}
              onChange={(event, newValue) => {
                setCriteria({
                  id: 'leaseStatus',
                  value: newValue || {},
                });
              }}
              onInputKeyUp={(event) => {
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  APIGetUnitsDuplicationCriteria();
                }, 700);
              }}
              withoutSearchButton={true}
              multiple={false}
              isLoading={isLoading}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
        </div>
      </div>

      <div className='AML-TABEL d-flex-column '>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center'>
                  {t(`${translationPath}property-name`)}
                </TableCell>
                <TableCell align='center'>
                  {t(`${translationPath}unit-number`)}
                </TableCell>
                <TableCell align='center'>
                  {t(`${translationPath}building-number`)}
                </TableCell>
                <TableCell align='center'>
                  {t(`${translationPath}phase-number`)}
                </TableCell>
                <TableCell align='center'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <Row
                  key={row.unitDuplicationCriteriaId}
                  row={row}
                  index={rowIndex}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
