import React, { Children, useEffect, useState } from 'react';
import { DialogComponent, Inputs, Tables } from '../../../Components';
import { TableActions } from '../../../Enums';
import {
  GetAllTenantLMSUsersDetails,
  GetUserLMSDetails,
} from '../../../Services';
import { generateUniqueKey, GlobalHistory, showError } from '../../../Helper';
import { urlExpression } from '../../../Utils';
import { ButtonBase, Link } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { UserTrainingInsightsLocalePath } from './i18n/UserTrainingInsightsLocale';
import { useDebouncedAction } from '../../../Hooks/DebouncedAction';
import { userTrainingInsightsSearchFields } from './SearchFields';

const initialFilter = {
  branchName: '',
  staffID: '',
  email: '',
  userName: '',
};
function UserTrainingInsights() {
  const { t } = useTranslation(UserTrainingInsightsLocalePath);
  const [data, setData] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState(initialFilter);
  const [filterContainerKey, setFilterContainerKey] =
    useState(generateUniqueKey);
  const getAllTenantLMSUsersDetails = async (filter) => {
    setIsLoading(true);
    try {
      const result = await GetAllTenantLMSUsersDetails(filter);
      setData(result);
    } catch (error) {
      showError(t('error_message'));
    } finally {
      setIsLoading(false);
    }
  };
  const debouncedGetAllTenantLMSUsersDetails = useDebouncedAction(
    getAllTenantLMSUsersDetails
  );
  const getUserLMSDetails = async (email) => {
    setIsLoading(true);
    try {
      const result = await GetUserLMSDetails(email);
      setActiveItem(result.courseDetails);
    } catch (error) {
      showError(t('error_message'));
    } finally {
      setIsLoading(false);
    }
  };

  const onActionClicked = (enumKey, item) => {
    if (enumKey === TableActions.view.key) {
      getUserLMSDetails(item.email);
    } else if (enumKey === TableActions.openFile.key) {
      GlobalHistory.push(`/home/profile-and-achievements?email=${item.email}`);
    }
  };
  const onSearchFieldChangeHandler = (field, value) => {
    setFilter((prevFilter) => ({ ...prevFilter, [field]: value }));
  };
  useEffect(() => {
    debouncedGetAllTenantLMSUsersDetails(filter);
  }, [filter]);
  useEffect(() => {
    getAllTenantLMSUsersDetails();
  }, []);
  return (
    <div>
      <div className='flex fj-end fa-end p-3 gap-1'>
        <div className='flex gap-1' key={filterContainerKey}>
          {Children.toArray(
            userTrainingInsightsSearchFields.map((field) => (
              <Inputs
                label={field.label}
                onInputChanged={(e) =>
                  onSearchFieldChangeHandler(field.key, e.target.value)
                }
                wrapperClasses={'m-0'}
                parentTranslationPath={UserTrainingInsightsLocalePath}
              />
            ))
          )}
        </div>
        <div>
          <ButtonBase
            onClick={() => {
              getAllTenantLMSUsersDetails(initialFilter);
              setFilterContainerKey(generateUniqueKey());
            }}
            className='btns theme-solid bg-danger clear-all-btn'
          >
            <span className='mdi mdi-filter-remove m-1' />
            {t(`clear_filters`)}
          </ButtonBase>
        </div>
      </div>
      <Tables
        isLoading={isLoading}
        headerData={[
          { label: 'username', input: 'userName' },
          { label: 'staff-id', input: 'staffID' },
          { label: 'email', input: 'email' },
          { label: 'branch-name', input: 'branchName' },
          {
            label: 'completed-courses',
            input: 'completed-courses',
            isHidden: true,
          },
          {
            label: 'number-of-certificates',
            input: 'number-of-certificates',
            isHidden: true,
          },
        ]}
        data={data}
        parentTranslationPath={UserTrainingInsightsLocalePath}
        itemsPerPage={data.length}
        activePage={1}
        defaultActions={[
          { enum: TableActions.view.key },
          { enum: TableActions.openFile.key },
        ]}
        actionsOptions={{ onActionClicked }}
      />
      {activeItem && (
        <DialogComponent
          isOpen
          titleText={'more-user-details'}
          onCloseClicked={() => setActiveItem(null)}
          maxWidth={'lg'}
          dialogContent={
            <Tables
              defaultActions={[]}
              headerData={[
                { label: 'course-name', input: 'courseName' },
                { label: 'category', input: 'categoryName' },
                {
                  label: 'completed-date',
                  input: 'completionDate',
                  component: (row) =>
                    moment(row.completionDate, 'DD/MM/YYYY, HH:mm:ss').format(
                      'YYYY-MM-DD'
                    ),
                },
                {
                  label: 'score',
                  input: 'score',
                  // isHidden: true,
                },
                {
                  label: 'certificate-link',
                  input: 'certificate-link',
                  component: (row) =>
                    urlExpression.test(row.certificateLink) ? (
                      <Link href={row.certificateLink} target='_blank'>
                        {t('certificate')}
                      </Link>
                    ) : (
                      row.certificateLink
                    ),
                },
                {
                  label: 'progress',
                  input: 'progress',
                },
              ]}
              data={activeItem}
              activePage={1}
              parentTranslationPath={UserTrainingInsightsLocalePath}
              bodyRowId={'more-details'}
            />
          }
          parentTranslationPath={UserTrainingInsightsLocalePath}
        />
      )}
    </div>
  );
}

export default UserTrainingInsights;
