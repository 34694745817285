import React from 'react';
import { SwitchRoute } from '../../../Components/Route/SwitchRoute';
import { NewContactsCrmRoutes } from '../../../routes/HomeRoutes/NewContactCrmRoutes';
import { AdvancedSearchProvider } from './ContactLayoutContext';

export const NewContactsCrmLayout = () => {
  return (
    <AdvancedSearchProvider>
      <SwitchRoute routes={NewContactsCrmRoutes} />
    </AdvancedSearchProvider>
  );
};
