export const UploaderThemesEnum = {
  input: {
    key: 'input', // must be unique
  },
  circle: {
    key: 'circle',
  },
  box: {
    key: 'box',
  },
  GalleryShow: {
    key: 'GalleryShow',
  },
};
