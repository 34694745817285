

export const DeactiveUserLogPermission  = {
  ViewDeactiveUserLogs: {
      permissionsId:'6ef81f34-5246-4b8e-c4d3-08dc683b0c43',
      permissionsName:'Get Deactive User Log',
      componentsId:null,
      components:null
    }
  };
  