import { config } from '../../config/config';
import { HttpServices } from '../../Helper';

export const GetAllActivityTypeRelatedToLead = async (params) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/ActivityTypes/GetAllActivityTypeRelatedToLead`,
      { params }
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const GetAllActivityTypeLeadStage = async (params) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/ActivityTypes/GetAllActivityTypeLeadStage`,
      { params }
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
export const CreateLeadStageActivityType = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/ActivityTypes/CreateLeadStageActivityType`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
export const RemoveLeadStageActivityType = async (id) => {
  try {
    const result = await HttpServices.delete(
      `${config.server_address}/CrmDfm/ActivityTypes/RemoveLeadStageActivityType`,
      { params: { leadStageActivityTypeId: id } }
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
export const UpdateLeadStageActivityType = async (id) => {
  try {
    const result = await HttpServices.put(
      `${config.server_address}/CrmDfm/ActivityTypes/UpdateLeadStageActivityType?leadStageActivityTypeId=${id}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
