import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from "@material-ui/lab";
import LoadingSpinner, { LoadingSpinnerSize, LoadingSpinnerStyle } from "../../../LoadingSpinner";
import { CustomButton, CustomCopyToClipboard } from "../../..";
import { useIsAr, useSelectedTheme, useTranslate } from "../../../../../Hooks";

// Styles
import useStyles from "./styles";

function InfoItem({ label, value, date }) {
  const styles = useStyles();

  const theme = useSelector((store) => store.theme);
  const { isAr } = useIsAr(theme.themeDirection);

  // Format date and time using moment
  const formattedDate = date ? moment(date).locale(isAr ? "ar" : "en").format("D MMM YYYY") : null;
  const formattedTime = date ? moment(date).locale(isAr ? "ar" : "en").format("h:mm a") : null;

  return (
    <Box className={styles.infoItemWrapper}>
      <Typography className={styles.label}>{label}</Typography>
      <Typography className={styles.value}>{value}</Typography>
      {date && <Typography className={styles.date}>{formattedDate}</Typography>}
      {date && (
        <Typography className={clsx(styles.date, styles.time)}>
          {formattedTime}
        </Typography>
      )}
    </Box>
  );
}

function ContactProfileActivity({ activeItem, activities, isActivityLoading }) {
  const styles = useStyles();
  const history = useHistory();
  const { translate } = useTranslate("NewContactsView");

  const {
    theme: { palette },
  } = useSelectedTheme();


  if (isActivityLoading) {
    return (
      <Box style={{ position: "relative", minHeight: "200px" }}>
        <LoadingSpinner
          sizeVariant={LoadingSpinnerSize.medium}
          styleVariant={LoadingSpinnerStyle.primary}
        />
      </Box>
    );
  }

  return (
    <Box>
      <Timeline align="left" className={styles.timeLine}>
        {activities?.result?.map((activity, index) => (
          <TimelineItem key={index} className={styles.missingOppositeContent}>
            <TimelineSeparator className={styles.timelineSeparator}>
              <TimelineDot className={styles.timelineDot} />
              <TimelineConnector className={styles.timelineConnector} />
            </TimelineSeparator>
            <TimelineContent className={styles.timelineContent}>
              <Box className={styles.headingWrapper}>
                <Typography variant="body1" className={styles.headingTitle}>
                  {activity.activityTypeName}
                </Typography>
                <Typography
                  variant="body2"
                  className={clsx({
                    [styles.open]: activity.isOpen,
                    [styles.closed]: !activity.isOpen,
                  })}
                >
                  {activity.isOpen ? translate("OPEN") : translate("CLOSED")}
                </Typography>
              </Box>
              <Box className={styles.activityInfo}>
                {!!activity?.relatedLeadFullName &&
                !!activity?.relatedLeadNumberId ? (
                  <>
                    <InfoItem
                      label={translate("lead_name")}
                      value={activity.relatedLeadFullName}
                    />
                    <InfoItem
                      label={translate("lead_id")}
                      value={
                        <CustomCopyToClipboard
                          data={activity.relatedLeadNumberId} // copy
                          childrenData={`#${activity.relatedLeadNumberId}`} // render
                          copyDoneShowTime={1000}
                        />
                      }
                    />
                  </>
                ) : null}
                <InfoItem
                  label={translate("created_by")}
                  value={activity.createdByName}
                  date={activity.activityDate}
                />
                <InfoItem 
                  label={translate("assign_to")}
                  value={activity.assignedTo} 
                />
              </Box>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      {!!activities.totalCount ?
        <Box className={styles.actions}>
          <CustomButton
            boxShadow="xs"
            size="sm"
            variant="contained"
            color="primary"
            onClick={() => {
              history.push({
                pathname: `/home/Contacts-CRM/contact-profile-edit`,
                search: `?formType=1&id=${activeItem.id}`,
                state: { isActivityTab: true },
              });
            }}
          >
            {translate('SHOW_ACTIVITY')}
          </CustomButton>
        </Box>
        :
        <Box>
          <Typography 
            variant="body1" 
            className={styles.headingTitle}
            style={{ paddingInlineStart: "8px" }}
          >
            {translate('NO_ACTIVITIES')}
          </Typography>
        </Box>
      }
    </Box>
  );
}

export default ContactProfileActivity;
