import React, { useState } from "react";
import { DialogComponent, Spinner } from "../../../../../../Components";
import { DeletePropertyVicinity } from "../../../../../../Services";
import { useTranslation } from "react-i18next";

export const DeleteDialog = ({
  parentTranslationPath,
  translationPath,
  onClose,
  activeItem,
  reloadData,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const deleteHandler = async () => {
    try {
      await DeletePropertyVicinity(activeItem.propertyVicinityId);
    } finally {
      setIsLoading(false);
      onClose();
      reloadData();
    }
  };
  return (
    <>
      <Spinner isActive={isLoading} isAbsolute />
      <DialogComponent
        titleText="confirm-message"
        saveText={t(`${translationPath}confirm`)}
        SmothMove
        saveType="button"
        maxWidth="sm"
        dialogContent={
          <div className="d-flex-column-center">
            <Spinner isActive={isLoading} isAbsolute />
            <span className="mdi mdi-close-octagon c-danger mdi-48px" />
            <span className="fz-18px fw-bold">
              `${t(`${translationPath}delete-vicinity-message-text`)}`
              <span className="d-flex-center  fz-22px fw-bold mt-2">
                {" "}
                ( {activeItem?.vicinityName ?? ""} ){" "}
              </span>
            </span>
          </div>
        }
        saveClasses="btns theme-solid bg-danger w-100 mx-2 mb-2"
        isOpen={true}
        onSaveClicked={deleteHandler}
        onCloseClicked={onClose}
        onCancelClicked={onClose}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
    </>
  );
};
