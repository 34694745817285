
import React, { useCallback, useState } from 'react';
import { DialogComponent, Spinner } from '../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { DeletePublishedPortalByPortalAccountId } from '../../../../../../../Services';
import { showError, showSuccess } from '../../../../../../../Helper';



export const DeleteBranchesDialog = ({ isOpenDeleteBranch, translationPath, parentTranslationPath, setIsOpenDeleteBranch, portalAccountId, branchId, setIsBranchDeleted }) => {
    const { t } = useTranslation(parentTranslationPath);
    const [isLoading, setIsLoading] = useState(false);


    const saveHandler = useCallback(async () => {
        setIsLoading(true);
        const res = await DeletePublishedPortalByPortalAccountId(portalAccountId, branchId)
        if (!(res && res.status && res.status !== 200)) {
            setIsBranchDeleted(true)
            showSuccess(t(`${translationPath}branch-deleted-sucessfuully`))
        } else showError(t(`${translationPath}failed-to-delete-branch`))

        setIsLoading(false);
        setIsOpenDeleteBranch(false);
    });

    return (
        <div>
            <Spinner isActive={isLoading} />
            {isOpenDeleteBranch && (
                <DialogComponent
                    isOpen={isOpenDeleteBranch}
                    titleText={'confirmation-message'}
                    maxWidth={'sm'}
                    SmothMove
                    dialogContent={
                        <div className='d-flex-column-center confirmation-message mb-5'>
                            <span className='mdi mdi-help-circle' />

                            <span className='confirm-msg mt-2'>{t(`${translationPath}confirm-delete-branch`)}</span>
                            <span className='confirm-msg-details mt-2'>{t(`${translationPath}all-publish-units-for-this-publish-portal-branch-will-removed`)}</span>
                        </div>
                    }
                    saveType='button'
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onCancelClicked={() => setIsOpenDeleteBranch(false)}
                    onSaveClicked={saveHandler}
                    saveText={'confirm'}
                />)}
        </div>
    );
};
