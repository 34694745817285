import { makeStyles } from "@material-ui/core/styles";
import { useIsAr } from "../../../Hooks";

export default makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    root: (props) => {
      const isOutlined = props.variant === 'outlined';
      const isText = props.variant === 'text';
      
      return {
        display: 'flex',
        alignItems: 'center',
  
        boxShadow: theme.shadows[props.elevation],
        padding: !isText ? props.sizeStyles.padding : '0px',
        fontSize: props.sizeStyles.fontSize,
        minHeight: !isText ? props.sizeStyles.minHeight: 'fit-content',
        lineHeight: props.sizeStyles.lineHeight,
        width: props.sizeStyles.width || 'auto',

        fontWeight: 600,
        borderRadius: '8px',

        ...(!isText ? {
          background: theme.palette.button?.[`${props.color}_bg`],
        } : {}),

        color: theme.palette.button?.[`${props.color}_fg`],

        ...(isOutlined ? {
          border: `1px solid ${theme.palette.button?.[`${props.color}_border`]}`,
        } : {}),
  
        '& .MuiButton-label': {
          gap: props.sizeStyles.gap,
          ...(isAr
            ? { flexDirection: 'row-reverse' }
            : {  }
          ),
        },
  
        // Targeting the start icon within the button
        '& .MuiButton-startIcon': {
          margin: '0px',
        },
  
        // Targeting the end icon within the button
        '& .MuiButton-endIcon': {
          margin: '0px',
        },
  
        '&:hover': {
          ...(!isText ? {
            background: `${theme.palette.button[`${props.color}_bg_hover`]}`,
            color: `${theme.palette.button[`${props.color}_fg_hover`]}`,
            '& svg, & svg path': {
              fill: `${theme.palette.button[`${props.color}_fg_hover`]}`,
            },
          } : {
            background: 'transparent',
            color: `${theme.palette.button[`${props.color}_fg_hover`]}`,
            '& svg, & svg path': {
              fill: `${theme.palette.button[`${props.color}_fg_hover`]}`,
            },
          }),
          boxShadow: "none",
        },
  
        '&:focus': {
          ...(props.color === 'primary' ? {
            boxShadow: theme.shadows[12],
          } : {}),

          ...(props.color === 'secondary' ? {
            boxShadow: theme.shadows[14],
          } : {}),

          ...(props.color === 'secondaryColor' ? {
            boxShadow: theme.shadows[12],
          } : {}),

          ...(props.color === 'destructive' ? {
            boxShadow: theme.shadows[16],
          } : {}),
        },
        
        '&:disabled': {
          ...(props.color === 'primary' ? {
            background: theme.palette.background.disabled,
          } : {}),

          ...(props.color === 'secondary' ? {
            background: theme.palette.background.primary,
          } : {}),

          color: theme.palette.foreground.disabled,

          '& svg, & svg path': {
            fill: theme.palette.foreground.disabled,
          },

          ...(!isText ? {
            border: `1px solid ${theme.palette.border.disabled_subtle}`,
          } : {}),
        },
      }
    },
    tooltip: {
      padding: "8px 12px !important",
      borderRadius: "8px !important",
      backgroundColor: `${theme.palette.background.primary_solid} !important`,
      color: `${theme.palette.text.white} !important`,
      fontSize: "12px !important",
      fontWeight: "600 !important",
    },
    endIconSpan: {
      height: "20px",
    },
  }
});
