import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import {
  AutocompleteComponent,
  DatePickerComponent,
  Inputs,
  RadiosGroupComponent,
  Spinner,
} from '../../../../../../../Components';
import { StaticLookupsIds } from '../../../../../../../assets/json/StaticLookupsIds';
import { getErrorByName } from '../../../../../../../Helper';
import {
  lookupItemsGetId,
  OrganizationUserSearch,
} from '../../../../../../../Services';
import { GetApplicationUserById } from '../../../../../../../Services/userServices';

export const DetailsRentRelatedComponent = ({
  state,
  selected,
  unitLeaseDetails,
  onSelectedChanged,
  schema,
  unitTransactionId,
  isSubmitted,
  onStateChanged,
  parentTranslationPath,
  translationPath,
  cantEditReservedLeaseTransaction,
}) => {
  const searchTimer = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadings, setLoadings] = useState({
    users: false,
    periodsOfStay: false,
  });
  const [users, setUsers] = useState([]);
  const [periodsOfStay, setPeriodsOfStay] = useState([]);
  const [leasingTypes, setLeasingTypes] = useState([]);
  const [filter] = useState({
    pageIndex: 0,
    pageSize: 25,
  });
  const getAllUsers = useCallback(
    async (value) => {
      setLoadings((items) => ({ ...items, users: true }));
      const res = await OrganizationUserSearch({
        ...filter,
        name: value,
        userStatusId: 2,
      });
      if (!(res && res.status && res.status !== 200)) setUsers((res && res.result) || []);
      else setUsers([]);
      setLoadings((items) => ({ ...items, users: false }));
    },
    [filter]
  );
  const getAllLeasingTypes = useCallback(async () => {
    setLoadings((items) => ({ ...items, leasingTypes: true }));
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.LeasingType,
    });
    if (!(res && res.status && res.status !== 200)) setLeasingTypes(res || []);
    else setLeasingTypes([]);
    setLoadings((items) => ({ ...items, leasingTypes: false }));
  }, []);
  const getAllPeriodsOfStay = useCallback(async () => {
    setLoadings((items) => ({ ...items, periodsOfStay: true }));
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.PeriodOfStay,
    });
    if (!(res && res.status && res.status !== 200)) setPeriodsOfStay(res || []);
    else setPeriodsOfStay([]);
    setLoadings((items) => ({ ...items, periodsOfStay: false }));
  }, []);
  const getUserById = useCallback(async (id) => {
    setIsLoading(true);
    const res = await GetApplicationUserById(id);
    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) return res;
    return null;
  }, []);
  const getEditInit = useCallback(async () => {
    if (state.contractIssuedById && !selected.user && users.length > 0) {
      const transactedByIndex = users.findIndex((item) => item.id === state.contractIssuedById);
      if (transactedByIndex !== -1) selected.user = users[transactedByIndex];
      else {
        const res = await getUserById(state.contractIssuedById);
        if (res) {
          selected.user = res;

          setUsers((items) => {
            items.push(res);
            return [...items];
          });
        }
      }
      if (onSelectedChanged) onSelectedChanged({ id: 'edit', value: selected });
    }
    if (state.leasingTypeId && !selected.leasingType && leasingTypes.length > 0) {
      const leasingTypeIndex = leasingTypes.findIndex(
        (item) => item.lookupItemId === state.leasingTypeId
      );
      if (leasingTypeIndex !== -1) {
        selected.leasingType = leasingTypes[leasingTypeIndex];
        if (onSelectedChanged) onSelectedChanged({ id: 'edit', value: selected });
      }
    }
    if (state.periodOfStayId && !selected.periodOfStay && periodsOfStay.length > 0) {
      const periodOfStayIndex = periodsOfStay.findIndex(
        (item) => item.lookupItemId === state.periodOfStayId
      );
      if (periodOfStayIndex !== -1) {
        selected.periodOfStay = periodsOfStay[periodOfStayIndex];
        if (onSelectedChanged) onSelectedChanged({ id: 'edit', value: selected });
      }
    }
  }, [
    getUserById,
    leasingTypes,
    onSelectedChanged,
    periodsOfStay,
    selected,
    state.contractIssuedById,
    state.leasingTypeId,
    state.periodOfStayId,
    users,
  ]);

  // useEffect(() => {
  //   if (state.rentPerYear === '') {
  //     const getleaseTransactionDetails = localStorage.getItem('leaseTransactionDetails');
  //     if (getleaseTransactionDetails) {
  //       const leaseTransactionDetails = JSON.parse(getleaseTransactionDetails);
  //       onStateChanged({
  //         id: 'edit',
  //         value: {
  //           ...state,
  //           rentPerYear: (leaseTransactionDetails && leaseTransactionDetails.rentPerYear ? leaseTransactionDetails.rentPerYear : 0),
  //           contractRent: (leaseTransactionDetails && leaseTransactionDetails.contractRent ? leaseTransactionDetails.contractRent : 0),
  //           securityDeposit: (leaseTransactionDetails && leaseTransactionDetails.securityDeposit ? leaseTransactionDetails.securityDeposit : 0),
  //           renewalFee: (leaseTransactionDetails && leaseTransactionDetails.renewalFee ? leaseTransactionDetails.renewalFee : 0),
  //           agencyFee: (leaseTransactionDetails && leaseTransactionDetails.agencyFee ? leaseTransactionDetails.agencyFee : 0),
  //           contractIssuedDate: (leaseTransactionDetails && leaseTransactionDetails.contractIssuedDate ? leaseTransactionDetails.contractIssuedDate : null),
  //           receiptNo: (leaseTransactionDetails && leaseTransactionDetails.receiptNo ? leaseTransactionDetails.receiptNo : null),
  //         },
  //       });
  //     }
  //   }
  // }, []);

  useEffect(() => {
    getAllUsers();
    getAllLeasingTypes();
    getAllPeriodsOfStay();
  }, [getAllUsers, getAllLeasingTypes, getAllPeriodsOfStay]);
  useEffect(() => {
    if (unitTransactionId) getEditInit();
  }, [getEditInit, unitTransactionId]);
  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
    },
    []
  );

  useEffect(()=>{
    onStateChanged({id: 'edit', value: {
      ...state,
      ...(unitLeaseDetails || {}),
      agencyFee: unitLeaseDetails.agencyFeeLandlord,
    }})
  }, [unitLeaseDetails])

  return (
    <div className='details-rent-related-wrapper childs-wrapper p-relative'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='form-item'>
        <Inputs
          withNumberFormat
          idRef='rentPerYearRef'
          labelValue='rent-per-year'
          labelClasses='Requierd-Color'
          value={state.rentPerYear !== null ? state.rentPerYear : ''}
          helperText={getErrorByName(schema, 'rentPerYear').message}
          error={getErrorByName(schema, 'rentPerYear').error}
          endAdornment={<span className='px-2'>AED</span>}
          type='number'
          min={0}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(e) => {
            const value = e && e.target && e.target.value ? (e.target.value) : 0;
            onStateChanged({ id: 'rentPerYear', value });
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item'>
        <Inputs
          withNumberFormat
          idRef='contractRentRef'
          labelValue='contract-rent'
          value={state.contractRent || 0}
          helperText={getErrorByName(schema, 'contractRent').message}
          error={getErrorByName(schema, 'contractRent').error}
          endAdornment={<span className='px-2'>AED</span>}
          type='number'
          min={0}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(e) => {
            const value = e && e.target && e.target.value ? (e.target.value) : 0;
            onStateChanged({ id: 'contractRent', value });
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item'>
        <Inputs
          withNumberFormat
          idRef='securityDepositRef'
          labelValue='security-deposit'
          value={state.securityDeposit || 0}
          helperText={getErrorByName(schema, 'securityDeposit').message}
          error={getErrorByName(schema, 'securityDeposit').error}
          endAdornment={<span className='px-2'>AED</span>}
          type='number'
          min={0}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(e) => {
            const value = e && e.target && e.target.value ? (e.target.value) : 0;
            onStateChanged({ id: 'securityDeposit', value });
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item'>
        <Inputs
          withNumberFormat
          idRef='renewalFeeRef'
          labelValue='renewal-fee'
          value={state.renewalFee || 0}
          helperText={getErrorByName(schema, 'renewalFee').message}
          error={getErrorByName(schema, 'renewalFee').error}
          endAdornment={<span className='px-2'>AED</span>}
          type='number'
          min={0}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(e) => {
            const value = e && e.target && e.target.value ? (e.target.value) : 0;
            onStateChanged({ id: 'renewalFee', value });
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item form-item-wrapper'>
              <Inputs
                isAttachedInput
                withNumberFormat
                idRef='agencyFeeTenantRef'
                labelValue='agency-fees-tenant-description'
                labelClasses='has-inside-label'
                value={state.agencyFeeTenant || 0}
                helperText={getErrorByName(schema, 'agencyFeeTenant').message}
                error={getErrorByName(schema, 'agencyFeeTenant').error}
                endAdornment={(
                  <span className='px-2 inner-span'>AED</span>
                )}
                type='number'
                min={0}
                max={(state.rentPerYear)}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onKeyUp={(e) => {
                  const value = e && e.target && e.target.value ? (e.target.value) : 0;
                  if (Number.isNaN(value))
                    value = 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  let parsedValue = fixed ? parseFloat(fixed) : 0;

                  const effectedByValue = (state.rentPerYear);
                  if (parsedValue > effectedByValue) parsedValue = effectedByValue;
                  let effectedValue = effectedByValue ? ((parsedValue / effectedByValue) * 100) : 0;

                  const netAmountTenant= parsedValue - state.externalAgencyFeeTenant;
                  
                  onStateChanged({
                    id: 'edit',
                    value: {
                      ...state,
                      agencyFeeTenant: parsedValue,
                      agencyFeeTenantPercentage: effectedValue,
                      netAmountTenant
                    },
                  });
                }}
              />
              <div className='input-container'>
                <Inputs
                  isAttachedInput
                  withNumberFormat
                  idRef='agencyFeeTenantPercentageRef'
                  labelValue='rent/year'
                  value={state.agencyFeeTenantPercentage || 0}
                  helperText={
                    getErrorByName(schema, 'agencyFeeTenantPercentage').message
                  }
                  error={getErrorByName(schema, 'agencyFeeTenantPercentage').error}
                  endAdornment={<span className='px-2'>%</span>}
                  type='number'
                  labelClasses='inside-input-label'
                  wrapperClasses='mb-0'
                  min={0}
                  max={100}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onKeyUp={(e) => {
                    const value = e && e.target && e.target.value ? (e.target.value) : 0;
                    if (Number.isNaN(value))
                      value = 0;
                    const fixed = (value && value.replace(/,/g, ''));
                    let parsedValue = fixed ? parseFloat(fixed) : 0;

                    if (parsedValue > 100) parsedValue = 100;

                    let effectedValue = ((state.rentPerYear) / 100) * parsedValue;

                    if (effectedValue < state.externalAgencyFeeTenant) effectedValue = 0.0;

                    const netAmountTenant= effectedValue -state.externalAgencyFeeTenant

                    onStateChanged({
                      id: 'edit',
                      value: {
                        ...state,
                        agencyFeeTenantPercentage: parsedValue,
                        agencyFeeTenant: effectedValue,
                        netAmountTenant
                      },
                    });
                  }}
                />
              </div>
            </div>
          <div className='form-item form-item-wrapper'>
            <Inputs
                isAttachedInput
                withNumberFormat
                idRef='externalAgencyFeeTenantRef'
                labelValue='External-Agency-Fee-(Tenant)'
                labelClasses='has-inside-label'
                value={state.externalAgencyFeeTenant || 0}
                helperText={getErrorByName(schema, 'externalAgencyFeeTenant').message}
                error={getErrorByName(schema, 'externalAgencyFeeTenant').error}
                endAdornment={(
                  <span className='px-2 inner-span'>AED</span>
                )}
                type='number'
                min={0}
                max={(state.rentPerYear)}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onKeyUp={(e) => {
                  const value = e && e.target && e.target.value ? (e.target.value) : 0;
                  if (Number.isNaN(value))
                    value = 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  let parsedValue = fixed ? parseFloat(fixed) : 0;

                  const effectedByValue = (state.rentPerYear);
                  if (parsedValue > effectedByValue) parsedValue = effectedByValue;
                  let effectedValue = effectedByValue ? ((parsedValue / effectedByValue) * 100) : 0;

                  const netAmountTenant= state.agencyFeeTenant - parsedValue;

                  onStateChanged({
                    id: 'edit',
                    value: {
                      ...state,
                      externalAgencyFeeTenant: parsedValue,
                      externalAgencyFeeTenantPercentage: effectedValue,
                      netAmountTenant
                    },
                  });
                }}
              />
          <div className='input-container'>
            <Inputs
              isAttachedInpu
              withNumberFormat
              idRef='externalAgencyFeeTenantPercentageRef'
              labelValue='rent/year'
              value={state.externalAgencyFeeTenantPercentage || 0}
              helperText={
                getErrorByName(schema, 'externalAgencyFeeTenantPercentage').message
              }
              error={getErrorByName(schema, 'externalAgencyFeeTenantPercentage').error}
              endAdornment={<span className='px-2'>%</span>}
              type='number'
              labelClasses='inside-input-label'
              wrapperClasses='mb-0'
              min={0}
              max={100}
              isWithError
              isSubmitted={isSubmitted}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onKeyUp={(e) => {
                const value = e && e.target && e.target.value ? (e.target.value) : 0;
                if (Number.isNaN(value))
                  value = 0;
                const fixed = (value && value.replace(/,/g, ''));
                let parsedValue = fixed ? parseFloat(fixed) : 0;

                if (parsedValue > 100) parsedValue = 100;
                const effectedValue = (
                  ((state.rentPerYear) / 100) * parsedValue);

                const netAmountTenant= state.agencyFeeTenant - effectedValue;

                onStateChanged({
                  id: 'edit',
                  value: {
                    ...state,
                    externalAgencyFeeTenantPercentage: parsedValue,
                    externalAgencyFeeTenant: effectedValue,
                    netAmountTenant
                  },
                });
              }}
            />
          </div>
        </div>
        <div className='form-item'>
        <Inputs
          idRef='netAmountTenantRef'
          labelValue='Net-Amount-(Tenant)'
          value={state.netAmountTenant || 0}
          endAdornment={<span className='px-2'>AED</span>}
          type='number'
          min={0}
          isSubmitted={isSubmitted}
          isDisabled
          withNumberFormat
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className='form-item form-item-wrapper'>
              <Inputs
                isAttachedInput
                withNumberFormat
                idRef='agencyFeesRef'
                labelValue='agency-fees-landlord-description'
                labelClasses='has-inside-label'
                value={state.agencyFee || 0}
                helperText={getErrorByName(schema, 'agencyFees').message}
                error={getErrorByName(schema, 'agencyFees').error}
                endAdornment={(
                  <span className='px-2 inner-span'>AED</span>
                )}
                type='number'
                min={0}
                max={(state.rentPerYear)}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onKeyUp={(e) => {
                  const value = e && e.target && e.target.value ? (e.target.value) : 0;
                  if (Number.isNaN(value))
                    value = 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  let parsedValue = fixed ? parseFloat(fixed) : 0;

                  if (parsedValue > state.rentPerYear) parsedValue = state.rentPerYear;
                  
                  let effectedValue = state.rentPerYear ? ((parsedValue / state.rentPerYear) * 100) : 0;

                  const netAmountLandLord= parsedValue - state.externalAgencyFeeLandlord;

                  onStateChanged({
                    id: 'edit',
                    value: {
                      ...state,
                      agencyFee: state.rentPerYear ? parsedValue : 0,
                      agencyFeePercentage: effectedValue,
                      netAmountLandLord
                    },
                  });
                }}
              />
              <div className='input-container'>
                <Inputs
                  isAttachedInput
                  withNumberFormat

                  idRef='agencyFeePercentageRef'
                  labelValue='rent/year'
                  type='number'
                  value={state.agencyFeePercentage || 0}
                  helperText={
                    getErrorByName(schema, 'agencyFeePercentage')
                      .message
                  }
                  error={
                    getErrorByName(schema, 'agencyFeePercentage')
                      .error
                  }
                  endAdornment={<span className='px-2'>%</span>}
                  labelClasses='inside-input-label'
                  wrapperClasses='mb-0'
                  min={0}
                  max={100}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onKeyUp={(e) => {
                    const value = e && e.target && e.target.value ? (e.target.value) : 0;
                    if (Number.isNaN(value))
                      value = 0;
                    const fixed = (value && value.replace(/,/g, ''));
                    let parsedValue = fixed ? parseFloat(fixed) : 0;
                    if (parsedValue > 100) parsedValue = 100;

                    let effectedValue = state.rentPerYear ? (state.rentPerYear / 100) * parsedValue : 0;
                    if (effectedValue < state.externalAgencyFeeLandlord) effectedValue = 0.0;
                
                    const netAmountLandLord= effectedValue - state.externalAgencyFeeLandlord;
                    
                    onStateChanged({
                      id: 'edit',
                      value: {
                        ...state,
                        agencyFeePercentage: state.rentPerYear ? parsedValue : 0,
                        agencyFee: effectedValue,
                        netAmountLandLord
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className='form-item form-item-wrapper'>
              <Inputs
                isAttachedInput
                withNumberFormat
                idRef='externalAgencyFeeLandlordRef'
                labelValue='External-Agency-Fee-(Landlord)'
                labelClasses='has-inside-label'
                value={state.externalAgencyFeeLandlord || 0}
                helperText={getErrorByName(schema, 'externalAgencyFeeLandlord').message}
                error={getErrorByName(schema, 'externalAgencyFeeLandlord').error}
                endAdornment={(
                  <span className='px-2 inner-span'>AED</span>
                )}
                type='number'
                min={0}
                max={(state.rentPerYear)}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onKeyUp={(e) => {
                  const value = e && e.target && e.target.value ? (e.target.value) : 0;
                  if (Number.isNaN(value))
                    value = 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  let parsedValue = fixed ? parseFloat(fixed) : 0;
               
                  if (parsedValue > state.rentPerYear) parsedValue = state.rentPerYear;
                  const effectedValue = state.rentPerYear ? ((parsedValue / state.rentPerYear) * 100) : 0;

                  const netAmountLandLord= state.agencyFee - parsedValue;

                  onStateChanged({
                    id: 'edit',
                    value: {
                      ...state,
                      externalAgencyFeeLandlord: state.rentPerYear ? parsedValue : 0,
                      externalAgencyFeeLandlordPercentage: effectedValue,
                      netAmountLandLord
                    },
                  });
                }}
              />
              <div className='input-container'>
                <Inputs
                  isAttachedInput
                  withNumberFormat

                  idRef='externalAgencyFeeLandlordPercentageRef'
                  labelValue='rent/year'
                  type='number'
                  value={state.externalAgencyFeeLandlordPercentage || 0}
                  helperText={
                    getErrorByName(schema, 'externalAgencyFeeLandlordPercentage')
                      .message
                  }
                  error={
                    getErrorByName(schema, 'externalAgencyFeeLandlordPercentage')
                      .error
                  }
                  endAdornment={<span className='px-2'>%</span>}
                  labelClasses='inside-input-label'
                  wrapperClasses='mb-0'
                  min={0}
                  max={100}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onKeyUp={(e) => {
                    const value = e && e.target && e.target.value ? (e.target.value) : 0;
                    if (Number.isNaN(value))
                      value = 0;
                    const fixed = (value && value.replace(/,/g, ''));
                    let parsedValue = fixed ? parseFloat(fixed) : 0;

                    if (parsedValue > 100) parsedValue = 100;
                    const effectedValue = state.rentPerYear ? (state.rentPerYear / 100) * parsedValue : 0;

                    const netAmountLandLord= state.agencyFee - effectedValue;

                    onStateChanged({
                      id: 'edit',
                      value: {
                        ...state,
                        externalAgencyFeeLandlordPercentage: state.rentPerYear ? parsedValue : 0,
                        externalAgencyFeeLandlord: effectedValue,
                        netAmountLandLord
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className='form-item'>
            <Inputs
              idRef='netAmountLandLordRef'
              labelValue='Net-Amount-(Landlord)'
              value={state.netAmountLandLord || 0}
              endAdornment={<span className='px-2'>AED</span>}
              isSubmitted={isSubmitted}
              type='number'
              min={0}
              isDisabled
              withNumberFormat
              isWithError
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          </div>
      <div className='form-item'>
        <RadiosGroupComponent
          idRef='tenancyContractIssuedRef'
          labelValue='is-tenancy-contract-issued'
          data={[
            {
              key: true,
              value: 'yes',
            },
            {
              key: false,
              value: 'no',
            },
          ]}
          value={state.tenancyContractIssued}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          translationPath={translationPath}
          labelInput='value'
          valueInput='key'
          onSelectedRadioChanged={(e, newValue) =>
            onStateChanged({ id: 'tenancyContractIssued', value: newValue === 'true' })}
          isDisabled={cantEditReservedLeaseTransaction}

        />
      </div>
      <div className='form-item'>
        <DatePickerComponent
          idRef='contractIssuedDateRef'
          labelValue='contract-issued-date'
          placeholder='DD/MM/YYYY'
          value={state.contractIssuedDate}
          helperText={getErrorByName(schema, 'contractIssuedDate').message}
          error={getErrorByName(schema, 'contractIssuedDate').error}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onDateChanged={(newValue) => {
            onStateChanged({ id: 'contractIssuedDate', value: newValue });
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='contractIssuedByIdRef'
          labelValue='contract-issued-by'
          selectedValues={selected.user}
          multiple={false}
          data={users}
          displayLabel={(option) => option.fullName || ''}
          renderOption={(option) =>
            ((option.userName || option.fullName) && `${option.fullName} (${option.userName})`) ||
            ''}
          getOptionSelected={(option) => option.id === state.contractIssuedById}
          withoutSearchButton
          helperText={getErrorByName(schema, 'contractIssuedById').message}
          error={getErrorByName(schema, 'contractIssuedById').error}
          isLoading={loadings.users}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getAllUsers(value);
            }, 700);
          }}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onSelectedChanged({ id: 'user', value: newValue });
            onStateChanged({
              id: 'contractIssuedById',
              value: (newValue && newValue.id) || null,
            });
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='receiptNoRef'
          labelValue='receipt-number-description'
          value={state.receiptNo || ''}
          helperText={getErrorByName(schema, 'receiptNo').message}
          error={getErrorByName(schema, 'receiptNo').error}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'receiptNo', value: event.target.value });
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item'>
        <RadiosGroupComponent
          idRef='paymentToExternalAgencyRef'
          labelValue='is-payment-to-external-agency'
          data={[
            {
              key: true,
              value: 'yes',
            },
            {
              key: false,
              value: 'no',
            },
          ]}
          value={state.paymentToExternalAgency}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          translationPath={translationPath}
          labelInput='value'
          valueInput='key'
          isDisabled={cantEditReservedLeaseTransaction}
          onSelectedRadioChanged={(e, newValue) =>
            onStateChanged({ id: 'paymentToExternalAgency', value: newValue === 'true' })}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='leasingTypeIdRef'
          labelValue='leasing-type'
          selectedValues={selected.leasingType}
          multiple={false}
          data={leasingTypes}
          displayLabel={(option) => option.lookupItemName || ''}
          withoutSearchButton
          helperText={getErrorByName(schema, 'leasingTypeId').message}
          error={getErrorByName(schema, 'leasingTypeId').error}
          isWithError
          isLoading={loadings.leasingTypes}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onSelectedChanged({ id: 'leasingType', value: newValue });
            onStateChanged({
              id: 'leasingTypeId',
              value: (newValue && newValue.lookupItemId) || null,
            });
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='periodOfStayIdRef'
          labelValue='period-of-stay-months'
          selectedValues={selected.periodOfStay}
          multiple={false}
          data={periodsOfStay}
          displayLabel={(option) => option.lookupItemName || ''}
          getOptionSelected={(option) => option.lookupItemId === state.periodOfStayId}
          withoutSearchButton
          helperText={getErrorByName(schema, 'periodOfStayId').message}
          error={getErrorByName(schema, 'periodOfStayId').error}
          isWithError
          isLoading={loadings.periodsOfStay}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onSelectedChanged({ id: 'periodOfStay', value: newValue });
            onStateChanged({
              id: 'periodOfStayId',
              value: (newValue && newValue.lookupItemId) || null,
            });
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item'>
        <RadiosGroupComponent
          idRef='contractRenewableRef'
          labelValue='is-contract-renewable'
          data={[
            {
              key: true,
              value: 'yes',
            },
            {
              key: false,
              value: 'no',
            },
          ]}
          isDisabled={cantEditReservedLeaseTransaction}
          value={state.contractRenewable}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          translationPath={translationPath}
          labelInput='value'
          valueInput='key'
          onSelectedRadioChanged={(e, newValue) =>
            onStateChanged({ id: 'contractRenewable', value: newValue === 'true' })}
        />
      </div>
      <div className='form-item'>
        <RadiosGroupComponent
          idRef='printContractOnRef'
          labelValue='print-contract-on'
          data={[
            {
              key: true,
              value: 'tenant',
            },
            {
              key: false,
              value: 'company',
            },
          ]}
          isDisabled={cantEditReservedLeaseTransaction}
          value={state.printContractOn}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          translationPath={translationPath}
          labelInput='value'
          valueInput='key'
          onSelectedRadioChanged={(e, newValue) =>
            onStateChanged({ id: 'printContractOn', value: newValue === 'true' })}
        />
      </div>
      {selected.agents && selected.agents.map((item, index) => (
        <div className='form-item'>
          <Inputs
            withNumberFormat
            idRef={`agent-commission-${index})`}
            labelValue={`${item.agentType} Agent Commission (${item.fullName})`}
            value={state.agents && state.agents.length > 0 && state.agents[index].agentCommission || 0}
            endAdornment={<span className='px-2'>%</span>}
            type='number'
            min={0}
            max={100}
            isWithError
            isSubmitted={isSubmitted}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputChanged={(event) => {
              const { value } = event.target;
              const stateAgents = [...state.agents];
              const changedAgentIndex = stateAgents.findIndex((agent) => agent.userId === item.id);
              const changedAgent = stateAgents[changedAgentIndex];
              stateAgents[changedAgentIndex] = {
                ...changedAgent,
                agentCommission: value,
              };
              onStateChanged({ id: 'agents', value: stateAgents });
            }}
            isDisabled={cantEditReservedLeaseTransaction}
          />
        </div>
))}
      {selected.listingAgents && selected.listingAgents.map((item, index) => (
        <div className='form-item'>
          <Inputs
            withNumberFormat
            idRef={`listing-agent-commission-${index})`}
            labelValue={`${item.listingAgentType} Agent Commission (${item.fullName})`}
            value={state.listingAgents && state.listingAgents.length > 0 && state.listingAgents[index] && state.listingAgents[index].listingAgentCommission || 0}
            endAdornment={<span className='px-2'>%</span>}
            type='number'
            min={0}
            max={100}
            isWithError
            isSubmitted={isSubmitted}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputChanged={(event) => {
              const { value } = event.target;
              const stateAgents = [...state.listingAgents];
              const changedAgentIndex = stateAgents.findIndex((agent) => agent.userId === item.id);
              const changedAgent = stateAgents[changedAgentIndex];
              stateAgents[changedAgentIndex] = {
                ...changedAgent,
                listingAgentCommission: value,
              };
              onStateChanged({ id: 'listingAgents', value: stateAgents });
            }}
            isDisabled={cantEditReservedLeaseTransaction}
          />
        </div>
))}
    </div>
  );
};

DetailsRentRelatedComponent.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.instanceOf(Object).isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  unitTransactionId: PropTypes.number,
  onStateChanged: PropTypes.func.isRequired,
  onSelectedChanged: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
DetailsRentRelatedComponent.defaultProps = {
  unitTransactionId: undefined,
};
