export const RiskRatingConfigEnum = {
    High: {
      value: 1,
      name: 'High',
      class: "RateDot_red",
    },
    Medium: {
      value: 2,
      name: 'Medium',
      class: "RateDot_orange",
    },
    Low: {
      value: 3,
      name: 'Low',
      class: "RateDot_green",
    },
  };
  