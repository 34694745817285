export const ViewTypesEnum = {
  tableView: {
    key: 'tableView',
    classes: 'mdi mdi-menu',
  },
  cards: { key: 'cards', classes: 'mdi mdi-view-grid' },
  cardsExpanded: { key: 'cardsExpanded', classes: 'mdi mdi-view-stream' },
  map: { key: 'mapView', classes: 'mdi mdi-map', name: 'Map' },
};

export const ViewTypes2Enum = {
  tableRelatedView: {
    key: 'tableRelatedView',
    classes: 'mdi mdi-wrap',
    name: 'Related-Activities'
  },
  tableView: { key: 'tableView', classes: 'mdi mdi-menu', name: 'All-Activities' },
};

export const ViewTypesTeamANDBusinessGroups = {
  family_tree: { key: 'tree', classes: 'mdi mdi-family-tree', name: 'tree' },
  file_tree: { key: 'filetree', classes: 'mdi mdi-file-tree-outline', name: 'filetree' }
};
