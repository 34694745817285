import React, { useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import copyImage from "../../../../assets/images/copy-01@2x.png";
import editIcon from "../../../../assets/images/icons/edit-pen.png";
import CancelInquiry from "../../../../assets/images/icons/x-close.png";
import DeleteInquiry from "../../../../assets/images/icons/trash-01.png";
import { returnPropsByPermissions3 } from "../../../../Helper";
import { ActiveItemActions } from "../../../../store/ActiveItem/ActiveItemActions";
import { GlobalHistory, showinfo } from "../../../../Helper";
import { PermissionsComponent, PopoverComponent } from "../../../../Components";
import { InquiryTypeIdEnum } from "../../../../Enums";
import {
  ZeroMatchingSalePermissions,
  ZeroMatchingLeasePermissions,
  ListingShortagePermissions,
} from "../../../../Permissions";
import { DeleteInquiryDialog } from "../DeleteInquiry/DeleteInquiryDialog";
import { CancelInquiryDialog } from "../CancelInquiry/CancelInquiryDialog";
import dotsVertical from "../../../../assets/images/dots-vertical.svg";
import dotsVertical1 from "../../../../assets/images/icons/dots-vertical1.svg";
import SaleZeroMatching from "../../../../assets/images/icons/InquiriesIcons/SaleZeroMatching.svg";
import LeaseZeroMatching from "../../../../assets/images/icons/InquiriesIcons/LeaseZeroMatching.svg";
import SaleListingShortage from "../../../../assets/images/icons/InquiriesIcons/SaleListingShortage.svg";
import LeaseListingShortage from "../../../../assets/images/icons/InquiriesIcons/LeaseListingShortage.svg";
import { CopyToClipboardComponents } from "../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents";

export const InquiresCardComponent = ({
  data,
  translationPath,
  parentTranslationPath,
  setFilter,
  isLoading,
  inquiryType,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [itemIndex, setItemIndex] = useState(-1);

  const [isOpenCancelInquiryDialog, setIsOpenCancelInquiryDialog] =
    useState(false);
  const dispatch = useDispatch();
  const [inquiryTypeIdValues, setInquiryTypeIdValues] = useState(
    Object.values(InquiryTypeIdEnum)
  );

  const [selectedPopoverAction, setSelectedPopoverAction] = useState(null);

  const [permission, setPrmission] = useState({
    permissionEditId: null,
    permissionDeleteId: null,
    permissionCancelId: null,
  });

  const [activeItem, setActiveItem] = useState(null);

  const handlePopoverOpen = useCallback((event, item, index) => {
    setItemIndex(index);
    setActiveItem(item);
    dispatch(ActiveItemActions.activeItemRequest(item));
    event.stopPropagation();
    event.preventDefault();
    setSelectedPopoverAction(event.currentTarget);
  }, []);

  const handlePopoverClose = () => {
    setSelectedPopoverAction(null);
    setItemIndex(-1);
  };

  const displayWithPermissions = () => {
    if (inquiryType === "Zero Matching Sale")
      return returnPropsByPermissions3(
        ZeroMatchingSalePermissions.EditInquiry.permissionsId,
        ZeroMatchingSalePermissions.DeleteInquiry.permissionsId,
        ZeroMatchingSalePermissions.CancelInquiry.permissionsId
      );
    else if (inquiryType === "Zero Matching Lease")
      return returnPropsByPermissions3(
        ZeroMatchingLeasePermissions.EditInquiry.permissionsId,
        ZeroMatchingLeasePermissions.DeleteInquiry.permissionsId,
        ZeroMatchingLeasePermissions.CancelInquiry.permissionsId
      );
    else if (inquiryType === "Listing Shortage")
      return returnPropsByPermissions3(
        ListingShortagePermissions.EditInquiry.permissionsId,
        ListingShortagePermissions.DeleteInquiry.permissionsId,
        ListingShortagePermissions.CancelInquiry.permissionsId
      );
  };
  const repeated = (list, field) =>
    list &&
    list.map((item, index) => (
      <span>
        {" "}
        {(item && field && item[field]) || item}{" "}
        {list.length - 1 != index && <span> , </span>}
      </span>
    ));

  const editClickHandler = () => {
    if (inquiryType === "Zero Matching Sale")
      GlobalHistory.push(
        `/home/zero-matching-sale/zero-matching-sale-profile-edit?formType=${44}&id=${
          activeItem && activeItem.inquiryId
        }&activeFormType=${1}`
      );
    else if (inquiryType === "Zero Matching Lease")
      GlobalHistory.push(
        `/home/zero-matching-lease/zero-matching-lease-profile-edit?formType=${47}&id=${
          activeItem && activeItem.inquiryId
        }&activeFormType=${2}`
      );
    else if (inquiryType === "Listing Shortage") {
      const inquiryTypeId = activeItem.inquiryTypeId === 3 ? 1 : 2;
      GlobalHistory.push(
        `/home/listing-shortage/listing-shortage-profile-edit?formType=${45}&id=${
          activeItem && activeItem.inquiryId
        }&activeFormType=${inquiryTypeId}`
      );
    }
    localStorage.setItem(
      "inquiryStatus",
      JSON.stringify(activeItem.inquiryStatus)
    );
    handlePopoverClose();
  };

  const deleteClickHandler = () => {
    handlePopoverClose();
    setIsOpenDeleteDialog(true);
  };

  const cardOnClick = (item) => {
    setActiveItem(item);
  };

  const cancelClickHandler = () => {
    handlePopoverClose();
    setIsOpenCancelInquiryDialog(true);
  };

  const getInquiryTypeImage = (inquiryTypeId) => {
    if (inquiryTypeId === InquiryTypeIdEnum.ZeroMatchingSale.InquiryTypeId)
      return SaleZeroMatching;
    else if (
      inquiryTypeId === InquiryTypeIdEnum.ZeroMatchingLease.InquiryTypeId
    )
      return LeaseZeroMatching;
    else if (
      inquiryTypeId === InquiryTypeIdEnum.ListingShortageSale.InquiryTypeId
    )
      return SaleListingShortage;
    else if (
      inquiryTypeId === InquiryTypeIdEnum.ListingShortageLease.InquiryTypeId
    )
      return LeaseListingShortage;

    return SaleZeroMatching;
  };

  useEffect(() => {
    if (inquiryType && inquiryType === "Zero Matching Sale") {
      setPrmission({
        permissionEditId: ZeroMatchingSalePermissions.EditInquiry,
        permissionDeleteId:
          ZeroMatchingSalePermissions.DeleteInquiry,
        permissionCancelId:
          ZeroMatchingSalePermissions.CancelInquiry,
      });
    } else if (inquiryType && inquiryType === "Zero Matching Lease") {
      setPrmission({
        permissionEditId:
          ZeroMatchingLeasePermissions.EditInquiry,
        permissionDeleteId:
          ZeroMatchingLeasePermissions.DeleteInquiry,
        permissionCancelId:
          ZeroMatchingLeasePermissions.CancelInquiry,
      });
    } else if (inquiryType && inquiryType === "Listing Shortage") {
      setPrmission({
        permissionEditId: ListingShortagePermissions.EditInquiry,
        permissionDeleteId:
          ListingShortagePermissions.DeleteInquiry,
        permissionCancelId:
          ListingShortagePermissions.CancelInquiry,
      });
    }
  }, [inquiryType]);

  return (
    <div className="Inquiries-card-component-wrapper">
      {data &&
        data.map((item, index) => (
          <div
            className="Inquiries-card-wrapper"
            key={`inquiryCardRef${index + 1}`}
            onClick={() => {
              if (selectedPopoverAction == null) cardOnClick(item);
            }}
          >
            <div
              className={`cards-wrapper  childs-wrapper ${
                inquiryType === "Listing Shortage" ? "Listing-Shortage" : ""
              } `}
            >
              <div className="cards-header">
                <span
                  className="information-icon"
                  style={{ position: "absolute" , cursor: 'pointer' }}
                  onClick={(event) => handlePopoverOpen(event, item, index)}
                >
                  {selectedPopoverAction && itemIndex === index && (
                    <img key={index} src={dotsVertical} />
                  )}
                  {itemIndex !== index && (
                    <img src={dotsVertical1} key={index} />
                  )}
                </span>

                <div className="header-part1">
                  <div className="headerItem1">
                    <div
                      className={`inquiry-icon-part ${
                        inquiryType === "Listing Shortage"
                          ? "Listing-Shortage"
                          : ""
                      } `}
                    >
                      <img
                        src={
                          item.inquiryTypeId &&
                          getInquiryTypeImage(item.inquiryTypeId)
                        }
                        key={index}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex fj-around card-header2 mt-2">
                <div className="fw-bold">
                  <span>
                    {t(`${translationPath}inquiryId`)}
                    {"  "}: &nbsp;
                    {item.inquiryId}
                  </span>
                </div>
                {item && item.leadId && (
                  <div className="fw-bold">
                    <span className="d-inline-flex">
                      {t(`${translationPath}leadId`)}
                      {":"}
                      <span className="leadId-part">
                        {(item && item.leadId && (
                          <CopyToClipboardComponents
                            data={item.leadId}
                            childrenData={item.leadId}
                            CustomizationClassName="block-container low-space"
                          />
                        )) ||
                          "N/A"}
                      </span>
                    </span>
                  </div>
                )}
              </div>
              <div className="d-flex fj-center card-header2">
                <div className="fw-bold">
                  <span
                    className={`status${item.inquiryStatus === "Canceled"
                      ? " cancel"
                      : item.inquiryStatus === "Open"
                        ? " open"
                        : item.inquiryStatus === "Assigned"
                          ? " assigned"
                          : " completed"
                      }`}
                  >
                    {item.inquiryStatus}{" "}
                  </span>
                </div>
              </div>
              <div
                className={`cards-body ${
                  inquiryType === "Listing Shortage"
                    ? "listing-shortage-body"
                    : ""
                }`}
              >
                <div className="body-section">
                  <div className="body-item">
                    <span className="fw-bold">
                      {t(`${translationPath}inquiry-type-card`)} : {"  "}
                      &nbsp;
                    </span>
                    <span
                      className={`item-value ${
                        inquiryType === "Listing Shortage"
                          ? "Listing-Shortage"
                          : ""
                      }`}
                    >
                      <span
                        className={`item-value ${
                          inquiryType === "Listing Shortage"
                            ? "Listing-Shortage"
                            : ""
                        }`}
                      >
                        {item.inquiryTypeId &&
                          inquiryTypeIdValues.map((i) => {
                            if (item.inquiryTypeId === i.InquiryTypeId)
                              return i.type;
                          })}
                      </span>
                    </span>
                  </div>
                  <div className="body-item">
                    <span className="mb-1 fw-bold">
                      {t(`${translationPath}community`)}: {"  "}
                      &nbsp;
                    </span>
                    <span className="mb-1">
                      {" "}
                      {(item.community && item.community.lookupItemName) ||
                        "N/A"}{" "}
                    </span>
                  </div>
                  <div className="body-item">
                    <span className="mb-1 fw-bold">
                      {t(`${translationPath}property`)}: {"  "}
                      &nbsp;
                    </span>
                    <span className="mb-1">
                      {" "}
                      {(item.property && item.property.name) || "N/A"}
                    </span>
                  </div>
                  <div className="d-flex-v-center-h-start">
                    <span className="mb-1 fw-bold">
                      {t(`${translationPath}unit-types`)}: {"  "}
                      &nbsp;
                    </span>
                    <span className="mb-1">
                      {" "}
                      {(item &&
                        item.unit_type &&
                        (Array.isArray(item.unit_type)
                          ? repeated(item.unit_type, "lookupItemName")
                          : item.unit_type.lookupItemName)) ||
                        "N/A"}
                    </span>
                  </div>
                  <div className="d-flex-v-center-h-start">
                    <span className="mb-1 fw-bold">
                      {t(`${translationPath}bedrooms`)}: {"  "}
                      &nbsp;
                    </span>
                    <span className="mb-1">
                      {" "}
                      {(item &&
                        item.bedroom &&
                        (Array.isArray(item.bedroom)
                          ? repeated(item.bedroom)
                          : item.bedroom)) ||
                        "N/A"}
                    </span>
                  </div>
                  <div className="body-item">
                    <span className="mb-1 fw-bold">
                      {t(`${translationPath}budget`)}: {"  "}
                      &nbsp;
                    </span>

                    <span className="mb-1 mdi mdi-cash-multiple">
                      {item.client_budget_from
                        ? ` ${item.client_budget_from}-${
                            item.client_budget_to || ""
                          }`
                        : item.listing_price_from
                        ? ` ${item.listing_price_from}-${
                            item.listing_price_to || ""
                          }`
                        : " N/A"}
                    </span>
                  </div>
                  <div className="body-item">
                    <span className="mb-1 fw-bold">
                      {t(`${translationPath}size`)}: {"  "}
                      &nbsp;
                    </span>
                    <span className="mb-1">
                      {item.area_size_fromsqft || item.area_size_tosqft
                        ? `[${item.area_size_fromsqft || "N/A"}-${
                            item.area_size_tosqft || "N/A"
                          }]`
                        : "N/A"}
                    </span>
                  </div>
                </div>

                {displayWithPermissions() && (
                  <PopoverComponent
                    idRef={`productRelease${item?.inquiryId}Ref`}
                    attachedWith={selectedPopoverAction}
                    popoverClasses="release-note-popover"
                    handleClose={() => handlePopoverClose()}
                    component={
                      <>
                        <PermissionsComponent
                          permissionsList={[permission.permissionEditId]}
                          permissionsId={permission.permissionEditId.permissionsId}
                        >
                          <div
                            className="popover-item"
                            onClick={() => editClickHandler()}
                          >
                            <img src={editIcon} />
                            <span> {t(`${translationPath}edit`)}</span>
                          </div>
                        </PermissionsComponent>

                        <PermissionsComponent
                          permissionsList={[permission.permissionDeleteId]}
                          permissionsId={permission.permissionDeleteId.permissionsId}
                        >
                          <div
                            className="popover-item"
                            onClick={() => deleteClickHandler()}
                          >
                            <img src={DeleteInquiry} />
                            <span> {t(`${translationPath}delete`)} </span>
                          </div>
                        </PermissionsComponent>
                        {activeItem &&
                          activeItem.inquiryStatus &&
                          activeItem.inquiryStatus !== "Canceled" && (
                            <PermissionsComponent
                            permissionsList={[permission.permissionCancelId]}
                            permissionsId={permission.permissionCancelId.permissionsId}
                            >
                              <div
                                className="popover-item"
                                onClick={() => cancelClickHandler()}
                              >
                                <img src={CancelInquiry} />
                                <span> {t(`${translationPath}cancel`)} </span>
                              </div>
                            </PermissionsComponent>
                          )}
                      </>
                    }
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      {isOpenDeleteDialog && (
        <DeleteInquiryDialog
          open={isOpenDeleteDialog}
          close={() => {
            setIsOpenDeleteDialog(false);
            setActiveItem(null);
          }}
          onSave={() => {
            setIsOpenDeleteDialog(false);
            setFilter();
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          activeItem={activeItem}
        />
      )}

      {isOpenCancelInquiryDialog && (
        <CancelInquiryDialog
          open={isOpenCancelInquiryDialog}
          close={() => {
            setIsOpenCancelInquiryDialog(false);
            setActiveItem(null);
          }}
          onSave={() => {
            setIsOpenCancelInquiryDialog(false);
            setFilter();
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          activeItem={activeItem}
        />
      )}
    </div>
  );
};
