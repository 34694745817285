import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  AutocompleteComponent,
  DialogComponent,
} from '../../../../../../Components';
import { OrganizationUserSearch, lookupItemsGetId } from '../../../../../../Services';
import { GlobalTranslate } from '../../../../../../Helper';
import { AgentRoleEnum } from '../../../../../../Enums';

const parentTranslationPath = '';
const translationPath = 'LeadsView:utilities.cloneleadsDialog.';
export const CloneLeadsDialog = ({
  isOpen, onClose, onSave, totalCloneLeads, checkedCardsIds, checkedCards
}) => {
  const { t } = useTranslation('LeadsView');
  const onSubmit = async () => {
    onSave({
      cloneLeadsData: checkedCardsIds.map((leadId) => ({
        leadId,
        ...Dto,
      })),
    });
  };
  const [data, setData] = useState({
    campaignLanguages: [],
    mediaTypes: [],
    mediaDetails: [],
    users: [],
    referredBy: [],
    referredTo: [],
    methodOfContact: [],
    campaignTypes: [],
  });
  const searchTimer = useRef(null);
  const [Dto, setDto] = useState({
    mediaDetailsId: '',
    methodOfContactId: '',
    referredBy: '',
    referredTo: ''
  });
  const [SelectedValue, setSelectedValue] = useState([]);
  const getAllMediaDetails = useCallback(async (mediaTypeId) => {
    const res = await lookupItemsGetId({
      lookupTypeId: 1240,
      lookupParentId: mediaTypeId
    });
    if (!(res && res.status && res.status !== 200))
      setData((item) => ({ ...item, mediaDetails: res }));
    else
      setData((item) => ({ ...item, mediaDetails: [] }));
  }, []);

  const getAllMethodOfContact = async () => {
    const res = await lookupItemsGetId({ lookupTypeId: 35 });
    if (!(res && res.status && res.status !== 200))
      setData((item) => ({ ...item, methodOfContact: res }));
    else setData((item) => ({ ...item, methodOfContact: [] }));
  };

  const getAllReferred = useCallback(async (searchValue) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchValue,
      userStatusId: 2,
      userTypeId: (checkedCards && checkedCards[0] && checkedCards[0].leadClass === 'Buyer') ? AgentRoleEnum.SaleAgent.value :
        (checkedCards && checkedCards[0] && checkedCards[0].leadClass === 'Tenant' && (AgentRoleEnum.LeaseAgent.value)) || ''
    });
    if (!(res && res.status && res.status !== 200))
      setData((item) => ({ ...item, referredTo: (res && res.result) }));
    else
      setData((item) => ({ ...item, referredTo: [] }));
  }, []);

  const getAllReferrby = useCallback(async (searchValue) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchValue,
      userStatusId: 2
    });

    if (!(res && res.status && res.status !== 200))
      setData((item) => ({ ...item, referredBy: (res && res.result) }));
    else
      setData((item) => ({ ...item, referredBy: [] }));
  }, []);

  function findObjectInArraySales(array) {
    return array.find((item) => (
      item.lookupItemName === 'Existing Client - Sales' ||
      item.lookupItemId === 57269
    ));
  }

  function findObjectInArrayLeasing(array) {
    return array.find((item) => (
      item.lookupItemName === 'Existing Client - Leasing' ||
      item.lookupItemId === 57270
    ));
  }

  useEffect(() => { getAllMediaDetails(); getAllMethodOfContact(); getAllReferred(); getAllReferrby(); }, []);

  useEffect(() => {
    if (data && data.mediaDetails && data.mediaDetails.length > 0) {
      if (checkedCards && checkedCards[0] && checkedCards[0].leadClass === 'Buyer' || checkedCards && checkedCards[0] && checkedCards[0].leadClass === 'Saller') {
        const result = findObjectInArraySales(data.mediaDetails);
        setSelectedValue(result);
        setDto((item) => ({ ...item, mediaDetailsId: (result && +result.lookupItemId) || '' }));
      } else if (checkedCards && checkedCards[0] && checkedCards[0].leadClass === 'Tenant' || checkedCards && checkedCards[0] && checkedCards[0].leadClass === 'Landlord') {
        const result = findObjectInArrayLeasing(data.mediaDetails);
        setSelectedValue(result);
        setDto((item) => ({ ...item, mediaDetailsId: (result && +result.lookupItemId) || '' }));
      }
    }
  }, [data]);

  return (
    <DialogComponent
      titleText={GlobalTranslate.t('Shared:clone-leads')}
      saveText={GlobalTranslate.t('Shared:confirm')}
      saveType='button'
      maxWidth='sm'
      dialogContent={(
        <div className='d-flex-column-center'>
          <span>
            {' '}
            {GlobalTranslate.t('Shared:Are-you-sure-to-clone-this-leads-leads-total')}
            {' '}
            :
            {' '}

            {totalCloneLeads}
          </span>
          <div className='w-100 mr-1-reversed mt-3'>
            <AutocompleteComponent
              idRef='mediaDetailseRef'
              labelValue={GlobalTranslate.t('Shared:mediaDetails')}
              multiple={false}
              selectedValues={SelectedValue}
              getOptionSelected={(option) => option.lookupItemId === SelectedValue.lookupItemId}
              labelClasses='Requierd-Color'
              data={data.mediaDetails || []}
              displayLabel={(option) => t(`${option.lookupItemName || ''}`)}
              withoutSearchButton
              inputPlaceholder={GlobalTranslate.t('Shared:SelectmediaDetails')}
              isWithError
              onChange={(event, newValue) => {
                setSelectedValue(newValue);
                setDto((item) => ({ ...item, mediaDetailsId: (newValue && +newValue.lookupItemId) || '' }));
              }}
            />
            {' '}

          </div>
          <div className='w-100 mr-1-reversed'>
            <AutocompleteComponent
              idRef='methodOfContactRef'
              labelValue={GlobalTranslate.t('Shared:method-Of-Contact')}
              inputPlaceholder={GlobalTranslate.t('Shared:Selectmethod-Of-Contact')}
              multiple={false}
              labelClasses='Requierd-Color'
              data={data.methodOfContact}
              displayLabel={(option) => option.lookupItemName}
              renderOption={(option) => option.lookupItemName || ''}
              withoutSearchButton
              isWithError
              onChange={(event, newValue) => {
                setDto((item) => ({ ...item, methodOfContactId: (newValue && +newValue.lookupItemId) || '' }));
              }}
            />
          </div>
          <div className='w-100 mr-1-reversed'>
            <AutocompleteComponent
              idRef='ReferredByRef'
              labelValue={GlobalTranslate.t('Shared:ReferredBy')}
              // isLoading={isLoading.referred}
              inputPlaceholder={GlobalTranslate.t('Shared:SelectReferredBy')}
              labelClasses='Requierd-Color'
              data={(data.referredBy && data.referredBy) || []}
              onInputKeyUp={(e) => {
                const newValue = e.target.value;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  getAllReferrby(newValue || '')();
                }, 700);
              }}
              multiple={false}
              displayLabel={(option) =>
                (option && option.fullName) || ''}
              chipsLabel={(option) => (option && option.fullName) || ''}
              withoutSearchButton
              onChange={(event, newValue) => {
                setDto((item) => ({ ...item, referredBy: newValue && newValue.id || '' }));
              }}
            />

          </div>
          <div className='w-100 mr-1-reversed'>
            <AutocompleteComponent
              idRef='ReferredToRef'
              labelValue={GlobalTranslate.t('Shared:ReferredTo')}
              inputPlaceholder={GlobalTranslate.t('Shared:ReferredTo')}
              data={(data.referredTo && data.referredTo) || []}
              onInputKeyUp={(e) => {
                const newValue = e.target.value;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  getAllReferred(newValue || '');
                }, 700);
              }}
              multiple={false}
              displayLabel={(option) => (option && option.fullName) || ''}
              chipsLabel={(option) => (option && option.fullName) || ''}
              withoutSearchButton
              onChange={(event, newValue) => {
                setDto((item) => ({ ...item, referredTo: newValue && newValue.id || '' }));
              }}
            />
          </div>
        </div>
      )}
      saveClasses='btns theme-solid w-100 mx-2 mb-2'
      isOpen={isOpen}
      saveIsDisabled={!(Dto.methodOfContactId !== '' && Dto.referredBy !== '' && Dto.mediaDetailsId !== '')}
      onSaveClicked={() => onSubmit()}
      onCancelClicked={onClose}
      translationPath={translationPath.confirm}
    />

  );
};

CloneLeadsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,

};
