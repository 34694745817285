export const ActivitesTypesFieldsData = [
  {
    fieldKey: 'ActivityTypeName',
    fieldTitle: 'activityTypeName',
    isRequired: true,
    fieldValues: null,
    fieldType: 'text',
  },
  {
    fieldKey: 'CategoryId',
    fieldTitle: 'category',
    fieldValues: null,
    isRequired: true,
    fieldType: 'search',
    lookup: 1201,
  },
  {
    fieldKey: 'ActivityRate',
    fieldTitle: 'activityRate',
    fieldValues: null,
    isRequired: true,
    fieldType: 'search',
    lookup: 1212,
  },
  {
    fieldKey: 'ActivityTypePrerequisites',
    fieldTitle: 'activityTypePrerequisites',
    fieldValues: null,
    isRequired: true,
    fieldType: 'search',
    searchBy: 'prerequisites',
  },

  {
    fieldKey: 'WithDateTime',
    fieldTitle: 'withDateTime',
    fieldValues: null,
    isRequired: false,
    fieldType: 'select',
    enum: [
      { id: true, name: 'Yes' },
      { id: false, name: 'No' },
    ],
  },
  // {
  //   fieldKey: 'WithReminder',
  //   fieldTitle: 'withReminder',
  //   fieldValues: false,
  //   fieldType: 'select',
  //   enum: [
  //     { id: true, name: 'Yes' },
  //     { id: false, name: 'No' },
  //   ],
  // },

  {
    fieldKey: 'WithDuration',
    fieldTitle: 'withDuration',
    fieldValues: null,
    isRequired: false,
    fieldType: 'select',
    enum: [
      { id: true, name: 'Yes' },
      { id: false, name: 'No' },
    ],
  },
  {
    fieldKey: 'FollowUpRequired',
    fieldTitle: 'followUpRequired',
    fieldValues: null,
    isRequired: false,
    fieldType: 'select',
    enum: [
      { id: true, name: 'Yes' },
      { id: false, name: 'No' },
    ],
  },
  {
    fieldKey: 'IsForMobile',
    fieldTitle: 'isForMobile',
    fieldValues: null,
    isRequired: false,
    fieldType: 'select',
    enum: [
      { id: true, name: 'Yes' },
      { id: false, name: 'No' },
    ],
  },

  //  {
  //   fieldKey : 'slaExpirationPeriod' ,
  //   fieldTitle :'slaExpirationPeriod'  ,
  //   fieldValues : null ,
  //   isRequired :false ,
  //   fieldType : 'select' ,
  //   enum :  [{id : true ,  name  : 'Yes'} ,{id : false ,  name : 'No'}] ,
  //  } ,
  //  {
  //   fieldKey : 'slaExpirationPeriodComparisonDate' ,
  //   fieldTitle :'slaExpirationPeriodComparisonDate'  ,
  //   fieldValues : null ,
  //   isRequired :false ,
  //   fieldType : 'select' ,
  //   enum :  [{id : true ,  name  : 'Yes'} ,{id : false ,  name : 'No'}] ,
  //   } ,
  //  {
  //   fieldKey : 'slaAppliedFor' ,
  //   fieldTitle :'slaAppliedFor'  ,
  //   fieldValues : null ,
  //   isRequired :false ,
  //   fieldType : 'select' ,
  //   enum :  [{id : 1 ,  name : 'Only For Bulk Leads'} ,{id  : 2,  name : 'Only For Fresh Leads'},
  //   {id : 3,  name : 'For Both Bulk And Fresh Leads'}] ,
  //   } ,
  //  {
  //   fieldKey : 'expiredPeriod' ,
  //   fieldTitle :'expiredPeriod'  ,
  //   fieldValues : null ,
  //   isRequired :false ,
  //   fieldType :'numbers' ,
  //   } ,
  {
    fieldKey: 'RelatedTo',
    fieldTitle: 'relatedTo',
    fieldValues: null,
    isRequired: false,
    fieldType: 'select',
    searchBy: 'relatedTo',
    multiple: true,
  },
  {
    fieldKey: 'ActivityTypeRelates',
    fieldTitle: 'activityTypeRelates',
    fieldValues: null,
    isRequired: false,
    fieldType: 'select',
    searchBy: 'activityTypeRelates',
    multiple: true,
    hidden: true,
  },
  {
    fieldKey: 'ActivityTypeCreateRoles',
    fieldTitle: 'activityTypeCreateRoles',
    fieldValues: null,
    isRequired: true,
    fieldType: 'search',
    searchBy: 'roles',
    multiple: true,
  },
  {
    fieldKey: 'ActivityTypeBranches',
    fieldType: 'search',
    fieldTitle: 'activityTypeBranches',
    fieldValues: null,
    isRequired: false,
    searchBy: 'branches',
    multiple: true,
  },
  {
    fieldKey: 'AssignedToActivityTypeRoles',
    fieldTitle: 'assignedToActivityTypeRoles',
    fieldValues: null,
    isRequired: true,
    fieldType: 'search',
    searchBy: 'roles',
    multiple: true,
  },
];

export const fieldTypesEnum = {
  text: 'text',
  search: 'search',
  select: 'select',
  date: 'date',
  number: 'number'
};
