import React, { useEffect } from "react";
import { SwitchRoute } from "../../../Components/Route/SwitchRoute";
import { PropertiesCrmRoutes } from "../../../routes";

export const PropertiesCrmLayout = () => {
  useEffect(() => {
    return () => {
      localStorage.removeItem("propertiesCrmCurrentPageIndex");
    };
  }, []);

  return <SwitchRoute routes={PropertiesCrmRoutes} />;
};
