import React, {
  useState, useEffect, useCallback,
} from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../Hooks';
import { bottomBoxComponentUpdate } from '../../../Helper';
import { MergeLogsTable } from './MergeLogsTable';
import {
  PaginationComponent,
  Spinner,
} from '../../../Components';
import { TableFilterTypesEnum, ViewTypesEnum } from '../../../Enums';
import { GetMergeContactLog } from '../../../Services';
import { DateRangePickerComponent } from '../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';

const parentTranslationPath = 'MergeLogs';
const translationPath = '';

export const MergeLogsView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const MergeLogsViewheaderData = [
    {
      id: 1,
      isSortable: true,
      input: 'mergedBy',
      label: t(`${translationPath}mergedBy`),
      component: (item) => (
        <div>
          {item && item.mergedBy}
        </div>
      ),
    },
    {
      id: 2,
      isSortable: true,
      input: 'dateOfMerge',
      isDate: true,
      label: t(`${translationPath}dateOfMerge`),
      component: (item) => (
        <div>
          {item && item.dateOfMerge}
        </div>
      ),
    },
    {
      id: 3,
      isSortable: true,
      input: 'originalContactId',
      label: t(`${translationPath}originalContactId`),
      isDate: false,
      component: (item) => (
        <div className='globel-open-style'>
          {item && item.originalContactId}
        </div>
      ),
    },
    {
      id: 4,
      isSortable: true,
      input: 'originalContactName',
      label: t(`${translationPath}originalContactName`),
      isDate: false,
      component: (item) => (
        <div>
          {item && item.originalContactName}
        </div>
      ),
    },
    {
      id: 5,
      isSortable: true,
      input: 'obsoleteContactsNames',
      label: t(`${translationPath}obsoleteContactsNames`),
      isDate: false,
      component: (item) => (
        <div className=''>
          {item && item.obsoleteContactsNames}
        </div>
      ),
    },
    {
      id: 6,
      isSortable: true,
      input: 'obsoleteContactsid',
      label: t(`${translationPath}obsoleteContactsid`),
      isDate: false,
      component: (item) => (
        <div className='globel-closed-style'>
          <div>
            {item && item.obsoleteContacts.map((el) => `${el.obsoleteContactId}, `)}
          </div>
        </div>
      ),
    },

  ];

  useTitle(t(`${translationPath}merge-logs`));
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
  };
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [sortBy, setSortBy] = useState(null);
  const [TableFilterData, setTableFilterData] = useState(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    filterBy: sortBy && sortBy.filterBy || null,
    orderBy: sortBy && sortBy.orderBy || null,
    fromDate: null,
    toDate: null,
    dateOfMerge: null,
    obsoleteContactsid: null,
    obsoleteContactsNames: null,
  });
  const [isLoading, setIsLoading] = useState({
    mergeLogs: false,
  });
  const [allMergeContactLog, setAllMergeContactLog] = useState({
    result: [],
    totalCount: 0,
  });
  const viewTypes = ViewTypesEnum.tableView.key;

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const onFilterValuesChanged = (newValue) => {
    const localFilterDto = filter || {};
    if (newValue) {
      Object.values(newValue)
        .filter((item) => item.searchableKey || item.displayPath)
        .map((item) => {
          if (localFilterDto[item.displayPath])
            localFilterDto[item.displayPath] = item.value;
          else if (item.value)

            localFilterDto[item.displayPath] = item.value;
          return undefined;
        });
    }
    setFilter(() => ({ ...localFilterDto }));
  };

  const mergeLogRepeated = (list, index) => {
    const obsoleteContacts = [];
    if (list && list.length > 0) {
      list.forEach((element) => {
        if (element && element.obsoleteContact && element.obsoleteContact.contact) {
          if (element && element.obsoleteContact && element.obsoleteContact.contact.contact_type_id === 1) {
            obsoleteContacts.push(
              element.obsoleteContact.contact && `${element.obsoleteContact.contact.first_name}  ${element.obsoleteContact.contact.last_name || ''}`
            );
          } else if (element && element.obsoleteContact && element.obsoleteContact.contact.contact_type_id === 2) {
            obsoleteContacts.push(
              element.obsoleteContact.contact && element.obsoleteContact.contact.company_name
            );
          }
        }
      });
      return obsoleteContacts.toString();
    }
  };

  const mergeContactLogMapper = (item, index) => {
    const originalContact = item.originalContact ? JSON.parse(JSON.parse(item.originalContact)) : null;
    const mergeLog = { ...item, originalContact };
    const obsoleteContactsList = [];

    mergeLog.originalContactName = originalContact && originalContact.contact &&
      ((originalContact.contact.company_name) || (originalContact.contact.first_name && (`${originalContact.contact.first_name} ${originalContact.contact.last_name || ''}`)));
    item.obsoleteContacts && item.obsoleteContacts.map((obsoleteContactItem) => {
      const contact = obsoleteContactItem.obsoleteContact && obsoleteContactItem.obsoleteContact.length ? (JSON.parse(obsoleteContactItem.obsoleteContact)) : null;
      obsoleteContactsList.push({
        obsoleteContactId: obsoleteContactItem.obsoleteContactId,
        obsoleteContact: contact
      });
    });
    mergeLog.obsoleteContacts = obsoleteContactsList || [];
    mergeLog.obsoleteContactsNames = obsoleteContactsList && obsoleteContactsList.length ? mergeLogRepeated(obsoleteContactsList, index) : '';
    return mergeLog;
  };

  const getAllMergeContactLog = useCallback(async () => {
    setIsLoading((item) => ({ ...item, mergeLogs: true }));
    const fromDate = dateFilter.startDate ? moment(dateFilter.startDate).format('YYYY-MM-DDTHH:mm:ss') : false;
    const toDate = dateFilter.endDate ? moment(dateFilter.endDate).format('YYYY-MM-DDTHH:mm:ss') : false;
    const res = await GetMergeContactLog(
      filter.pageSize,
      filter.pageIndex + 1,
      filter.mergedBy || false,
      filter.originalContactName || false,
      filter.originalContactId || false,
      filter.filterBy || false,
      filter.orderBy || false,
      filter.obsoleteContactsid || false,
      filter.obsoleteContactsNames || false,
      fromDate || false,
      toDate || false,
      filter.dateOfMerge || false,
    );
    if (!(res && res.status && res.status !== 200)) {
      setAllMergeContactLog({
        result: ((res && res.result) || []).map((item, index) => mergeContactLogMapper(item, index)),
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setAllMergeContactLog({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((item) => ({ ...item, mergeLogs: false }));
  }, [filter]);
  useEffect(() => {
    if (sortBy !== null) {
      setFilter((item) => ({
        ...item,
        filterBy: sortBy && sortBy.filterBy || null,
        orderBy: sortBy && sortBy.orderBy || null,
      }));
    }
  }, [sortBy]);

  useEffect(() => {
    getAllMergeContactLog();
  }, [dateFilter, filter.dateOfMerge, filter.filterBy, filter.mergedBy, filter.obsoleteContactsNames, filter.obsoleteContactsid, filter.orderBy, filter.originalContactId, filter.originalContactName, filter.pageIndex, filter.pageSize]);

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={(allMergeContactLog && allMergeContactLog.totalCount)}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
        translationPath=''
        parentTranslationPath='Shared'
      />
    );
  }, [allMergeContactLog, filter.pageIndex, filter.pageSize]);

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  useEffect(() => {
    setTableFilterData(
      MergeLogsViewheaderData.map((column) => ({
        key: column.key || column.fieldKey || column.id,
        filterType: (column.isDate && TableFilterTypesEnum.datePicker.key) ||
          (column.withSelectFilter && TableFilterTypesEnum.selectOption.key) ||
          (column.isNumber && TableFilterTypesEnum.numberInput.key) ||
          TableFilterTypesEnum.textInput.key,
        isHiddenFilter: column.isHiddenFilter,
        textInputType: column.textInputType,
        textInputMax: column.textInputMax,
        textInputMin: column.textInputMin,
        displayPath: column.input
      }))
    );
  }, []);

  return (
    <>
      <div className='MergeContactLog'>
        <Spinner isActive={isLoading && isLoading.mergeLogs} isAbsolute />
        <div className='view-wrapper d-flex-column'>
          <div className='header-section'>

            <div className='filter-section p-4 d-flex-row-reverse'>

              <DateRangePickerComponent
                labelValue={t(`${translationPath}select-filter-by-date`)}
                onClearClicked={() => {
                  setDateFilter(dateRangeDefault);
                  setFilter((item) => ({
                    ...item,
                    fromDate: null,
                    toDate: null
                  }));
                }}
                ranges={[dateFilter]}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onDateChanged={(selectedDate) => {
                  setDateFilter({
                    startDate: selectedDate.selection && selectedDate.selection.startDate,
                    endDate: (selectedDate.selection && selectedDate.selection.endDate),
                    key: 'selection',
                  });
                  setFilter((item) => ({
                    ...item,
                    fromDate: (moment(selectedDate.selection && selectedDate.selection.startDate).add(24, 'hours')),
                    toDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                  }));
                }}
              />
            </div>
          </div>
          {' '}
        </div>
      </div>
      <>
        {viewTypes === ViewTypesEnum.tableView.key && (
          <>
            <div className='view-wrapers'>
              <MergeLogsTable
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                filter={filter}
                TableFilterData={TableFilterData}
                data={allMergeContactLog}
                onFilterValuesChanged={onFilterValuesChanged}
                MergeLogsViewheaderData={MergeLogsViewheaderData}
                setSortBy={setSortBy}
                isLoading={isLoading}
                setFilter={setFilter}
                onPageIndexChanged={onPageIndexChanged}
                onPageSizeChanged={onPageSizeChanged}
              />
            </div>
          </>
        )}
      </>
    </>
  );
};
