export const UserTrainingInsightsLocale = {
  en: {
    username: 'Username',
    'staff-id': 'Staff ID',
    email: 'Email',
    'branch-name': 'Branch Name',
    'completed-courses': 'Completed Courses',
    'number-of-certificates': 'No. of Certificates',
    'course-name': 'Course Name',
    category: 'Category',
    'course-link': 'Course Link',
    description: 'Description',
    'completed-date': 'Completed Date',
    score: 'Score',
    'certificate-link': 'Certificate Link',
    progress: 'Progress',
    'more-user-details': 'More User Details',
    certificate: 'Certificate',
    error_message: 'Something went wrong, please try again',
    staff_id: 'Staff ID',
    branch_name: 'Branch Name',
    clear_filters: 'Clear Filters',
  },
  ar: {
    username: 'اسم المستخدم',
    'staff-id': 'رقم الموظف',
    email: 'البريد الإلكتروني',
    'branch-name': 'اسم الفرع',
    'completed-courses': 'الدورات المكتملة',
    'number-of-certificates': 'عدد الشهادات',
    'course-name': 'اسم الدورة',
    category: 'الفئة',
    'course-link': 'رابط الدورة',
    description: 'الوصف',
    'completed-date': 'تاريخ الإكمال',
    score: 'الدرجة',
    'certificate-link': 'رابط الشهادة',
    progress: 'التقدم',
    'more-user-details': 'المزيد من تفاصيل المستخدم',
    certificate: 'شهادة',
    error_message: 'حدث خطأ ما، يرجى المحاولة مرة أخرى',
    staff_id: 'معرف الموظف',
    branch_name: 'اسم الفرع',
    clear_filters: 'مسح الفلاتر',
  },
  ru: {
    username: 'Имя пользователя',
    'staff-id': 'ID сотрудника',
    email: 'Эл. почта',
    'branch-name': 'Название филиала',
    'completed-courses': 'Завершенные курсы',
    'number-of-certificates': 'Количество сертификатов',
    'course-name': 'Название курса',
    category: 'Категория',
    'course-link': 'Ссылка на курс',
    description: 'Описание',
    'completed-date': 'Дата завершения',
    score: 'Оценка',
    'certificate-link': 'Ссылка на сертификат',
    progress: 'Прогресс',
    'more-user-details': 'Подробнее о пользователе',
    certificate: 'Сертификат',
    error_message: 'Что-то пошло не так, пожалуйста, попробуйте снова',
    staff_id: 'ID сотрудника',
    branch_name: 'Название филиала',
    clear_filters: 'Очистить фильтры',
  },
  cn: {
    username: '用户名',
    'staff-id': '员工ID',
    email: '电子邮件',
    'branch-name': '分行名称',
    'completed-courses': '完成的课程',
    'number-of-certificates': '证书数量',
    'course-name': '课程名称',
    category: '类别',
    'course-link': '课程链接',
    description: '描述',
    'completed-date': '完成日期',
    score: '分数',
    'certificate-link': '证书链接',
    progress: '进度',
    'more-user-details': '更多用户详情',
    certificate: '证书',
    error_message: '出现问题，请重试',
    staff_id: '员工 ID',
    branch_name: '分行名称',
    clear_filters: '清除筛选',
  },
};

export const UserTrainingInsightsLocalePath = 'UserTrainingInsights';
