
import React, { useState    , useEffect}  from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';
import { GeneralPropertyCriteriaTypesEnum } from '../../../../../../Enums' ;
import { showError } from '../../../../../../Helper';


  export const TowerEntranceVenisonCriteria = ({
    parentTranslationPath ,
    translationPath ,
    state ,
    setState ,
   } ) => { 

    const { t } = useTranslation(parentTranslationPath);
    const [selectedValue, setSelectedValue] = useState(null);


    const onChangeSelectedItem = (key , value , index) => 
    {
      const newListUpdated = [...state.generalPropertyLookupInfo] ;
        const newSelectedArray =  [...selectedValue.generalPropertyLookupDtos];
         if(key === 'lookupsId' &&  selectedValue.generalPropertyLookupDtos.some((s)=>s.lookupsId === value?.lookupsId))
           {
               showError('this-tower-entrance-venison-was-selected-please-select-another')
                return ; 
           }
          newSelectedArray[index] =  key  === 'lookupsId' ? 
              {  
                  ...newSelectedArray[index] ,
                 lookupItemName : (value && value.lookupItemName) , 
                 lookupsId: (value && value.lookupsId)
               }: 
                {  
                  ...newSelectedArray[index] ,
                  score : (value || 0) , 
                }
                if(key === 'lookupsId' && !value )
                {
                   newSelectedArray[index] = 
                  {  
                      ...newSelectedArray[index] ,
                     score : 0 
                   } ;  
                 }
            const generalPropertyInfoIndex = newListUpdated.findIndex((f)=> f.generalPropertyCriteriaType === (selectedValue.generalPropertyCriteriaType));
            let newSelectedObject = { generalPropertyCriteriaType : (selectedValue?.generalPropertyCriteriaType)   , generalPropertyLookupDtos  : newSelectedArray };
            setSelectedValue(newSelectedObject);

            if(generalPropertyInfoIndex !== -1 )
            {
              newListUpdated[generalPropertyInfoIndex] =  newSelectedObject ; 
              setState({ id: 'generalPropertyLookupInfo', value: newListUpdated});

            }
           else 
           {
            newListUpdated.push({...newSelectedObject});
            setState({ id: 'generalPropertyLookupInfo', value: newListUpdated});
           }
     };


     useEffect(() => {
      if(!(state && state.generalPropertyLookupInfo && state.generalPropertyLookupInfo.find((f)=>f.generalPropertyCriteriaType === GeneralPropertyCriteriaTypesEnum.TowerEntrance.key )))
      setSelectedValue({generalPropertyCriteriaType : GeneralPropertyCriteriaTypesEnum.TowerEntrance.key  ,
        generalPropertyLookupDtos : [
        {
          lookupItemName :null ,
          lookupsId :null ,
          propertyRatingGeneralPropertyCriteriaScoreId :null  , 
          score :0
        }
      ] });
      else 
      setSelectedValue(state && state.generalPropertyLookupInfo && state.generalPropertyLookupInfo.find((f)=>f.generalPropertyCriteriaType === GeneralPropertyCriteriaTypesEnum.TowerEntrance.key))
  
   }, [state]);

    
  
    return (
      <div className="w-75">
        <div className='general-property-section'>
          <div>
            {/* <span className='fw-bold'> {t(`${translationPath}tower-entrance-venison`)} </span> */}
          </div>
          <div className='w-100  mt-2 mb-2'>
          {
                selectedValue  &&  selectedValue.generalPropertyLookupDtos && 
                selectedValue.generalPropertyLookupDtos.map((item, index) => 
                ( 
                  <div className='d-flex d-flex-v-center general-property-card mt-2 mb-2 b-bottom' key={`TowerEntranceVenison${index + 1}`}>
                  <div className='mb-2'>
                   <span className='fw-bold'> {item.lookupItemName} </span>
                      </div>
                      <div className='px-4'>
                       <Rating  
                            name={`towerentrancevenisonRef${index+1}`}
                            max={10}
                            precision={1}
                            value={item.score}
                            onChange={(event, newValue) => 
                            {
                              onChangeSelectedItem('score' ,  newValue || 0 , index);
      
                            }}
                         />  
                         </div>
                      </div>
                   ))}
            </div>  
          </div>
        </div>
       );
     } ; 
  
  
  