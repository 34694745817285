import React, {
  useCallback, useState, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogActions, DialogContent, DialogTitle, Dialog, ButtonBase, Button
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Spinner, Tables } from '../../../../../Components';

import { GetAllUserLogins } from '../../../../../Services/userServices';

const translationPath = 'UsersManagementView.';
export const UserLoginDialog = ({
  open,
  close,
  onSave,
  userId,
  parentTranslationPath
}) => {
  const { t } = useTranslation('UsersView');
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState({
    result: [],
    totalCount: 0
  });

  const ClickCancel = () => {
    close();
  };

  const getAllRelatedLogin = useCallback(
    async () => {
      const response = await GetAllUserLogins(userId);
      if (!(response && response.status && response.status !== 200))
        setResponse({ result: response.result, totalCount: response.totalCount });
       else
        setResponse({ result: [], totalCount: 0 });
    },
    []
  );

  useEffect(() => {
    if (userId)
      getAllRelatedLogin();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return (
    <div>
      <Spinner isActive={isLoading} isAbsolute />
      <Dialog
        disableBackdropClick
        open={open}
        maxWidth='sm'
        onClose={() => {
          close();
        }}
        className='dialog-wrapper'
      >
        <form noValidate>
          <DialogTitle id='alert-dialog-slide-title '>
            <div className='dialog-header userLoginTitle'>
              {t('UsersManagementView.UserLogin')}
              {close && (
                <Button
                  className='close-btn-wrapper  btns-icon theme-solid bg-danger'
                  onClick={close}
                // disabled={closeIsDisabled}
                >
                  <span className='mdi mdi-close' />
                </Button>
              )}
            </div>
          </DialogTitle>
          <DialogContent>
            <div className='account-dialog-section'>
              {userId && (
                <div>
                  <div className='dialog-content-wrapper'>
                    <div className='dialog-content-item'>
                      <Tables
                        data={(response && response.result) || []}
                        headerData={[
                          {
                            id: 1,
                            label: 'Login Date',
                            input: 'loginDate',
                            isDate: true,
                            dateFormat: 'MMM, DD, YYYY hh:mm a',
                          },
                          {
                            id: 2,
                            label: 'source',
                            input: 'loginPlatform',
                          },
                        ]}
                        parentTranslationPath={parentTranslationPath}
                        activePage={0}
                        itemsPerPage={response && response.totalCount}
                        defaultActions={[]}
                        totalItems={(response && response.totalCount)}
                      />
                    </div>
                  </div>

                </div>
              )}
            </div>

          </DialogContent>
          <DialogActions>
            <div className='form-builder-footer-wrapper is-dialog w-100 MuiGrid-align-items-xs-center MuiGrid-justify-xs-space-between'>
              <div className='MuiDialogActions-root dialog-footer-wrapper  MuiDialogActions-spacing'>
                <div className='save-cancel-wrapper d-flex-v-center-h-end flex-wrap p-2'>
                  <div className='cancel-wrapper d-inline-flex-center'>
                    <ButtonBase
                      className='MuiButtonBase-root MuiButton-root MuiButton-text cancel-btn-wrapper btns theme-transparent c-primary'
                      tabIndex='0'
                      id='cancel-ref-action'
                      onClick={ClickCancel}
                    >
                      <span className='MuiButton-label'>
                        {t('UsersManagementView.Cancel')}
                      </span>
                      <span className='MuiTouchRipple-root' />
                    </ButtonBase>
                  </div>
                </div>
              </div>
            </div>

          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
UserLoginDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

/// Identity/Account/GetUserLoginHistory
