import React from 'react';
import { useTitle } from '../../../Hooks';
import { useTranslation } from 'react-i18next';
import { DeactiveUserLogTableView } from '../DeactiveUserLog/DeactiveUserLogTable/DeactiveUserLogTableView';

const parentTranslationPath = 'DeactiveUserLog'
export const DeactiveUserLogView = () => {
  useTitle('DeactiveUser');

  const { t } = useTranslation(parentTranslationPath);

  return (
    <div className='Deactive-user-log view-wrapper'>
      <div className='w-100 px-2'>
        <div className='header-section-ActiveUser'></div>

        <div className='w-100 px2'>
          <DeactiveUserLogTableView
            parentTranslationPath={parentTranslationPath}
          />
        </div>
      </div>
    </div>
  );
};
