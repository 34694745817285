import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useReducer,
} from 'react';
import { ButtonBase } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Inputs,
  Spinner,
  PermissionsComponent,
  SelectComponet,
  AutocompleteComponent,
} from '../../../Components';
import { InvoiceViewTable } from './InvoicesViewUtilities';
import { useTitle } from '../../../Hooks';
import { returnPropsByPermissions } from '../../../Helper';
import { GetInvoices, GetAllBranches } from '../../../Services';
import { InvoicesManagementDialog } from './InvoicesViewUtilities/Dialogs/InvoicesManagementDialog';
import { InvoicesPermissions } from '../../../Permissions/Accounts/Invoices.Permissions';
import { GlobalOrderFilterActions } from '../../../store/GlobalOrderFilter/GlobalOrderFilterActions';

const parentTranslationPath = 'InvoicesView';
const translationPath = '';
export const InvoicesComponent = ({
  handleChangeView,
  setTotalAmountPaid,
  totalAmountPaid,
  setOpenDialog,
  openDialog,
  setSelected,
  selected,
  setIsLoading,
  isLoading,
  onStateChanged,
  setState,
  state,
  defaultSelected,
  defaultState,
  setActiveItem,
  activeItem,
  onSelectedChanged,
  isFlagTrue,
}) => {
  
  const [sortBy, setSortBy] = useState(null);
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const [branchList, setBranchList] = useState({
    result: [],
    totalCount: 0,
  });
  const orderFilter = useSelector((select) => select.GlobalOrderFilterReducer);
  const dispatch = useDispatch();
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy:
      (orderFilter.invoicesFilter && orderFilter.invoicesFilter.filterBy) ||
      null,
    orderBy:
      (orderFilter.invoicesFilter && orderFilter.invoicesFilter.orderBy) ||
      null,
  });
  const [orderBy, setOrderBy] = useState(
    selectedOrderBy.filterBy
      ? selectedOrderBy
      : { filterBy: null, orderBy: null }
  );
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    search: '',
    orderBy: (orderBy && orderBy.orderBy) || null,
    filterBy: (orderBy && orderBy.filterBy) || null,
    branchId: null,
    invoiceStatus: '',
  });

  const onFilterChange = (value) => {
    setFilter(value);
  };
  const [invoices, setInvoices] = useState({
    result: [],
    totalCount: 0,
  });
  useTitle(t(`${translationPath}invoices`));
  const searchHandler = (value) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({ ...item, pageIndex: 0, search: value }));
    }, 700);
  };

  const getAllInvoices = useCallback(async () => {
    if (
      returnPropsByPermissions(
        InvoicesPermissions.ViewAndSearchInMainAccountsInvoicesPage
          .permissionsId
      )
    ) {
      setIsLoading((loading) => ({ ...loading, allInvoices: true }));
      const res = await GetInvoices(filter);
      if (!(res && res.status && res.status !== 200)) {
        setInvoices({
          result: (res && res.result) || [],
          totalCount: (res && res.totalCount) || 0,
        });
      } else {
        setInvoices({
          result: [],
          totalCount: 0,
        });
      }
      setIsLoading((loading) => ({ ...loading, allInvoices: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const getBranch = async () => {
    setIsLoading((loading) => ({ ...loading, branch: true }));
    const res = await GetAllBranches({ pageSize: 25, pageIndex: 1 });
    if (!(res && res.status && res.status !== 200)) {
      setBranchList({
        result: res.result,
        totalCount: res.totalCount,
      });
    } else {
      setBranchList({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((loading) => ({ ...loading, branch: false }));
  };

  useEffect(() => {
    getAllInvoices();
  }, [filter, getAllInvoices]);
  useEffect(() => {
    getBranch();
  }, []);
  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
    },
    []
  );

  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };

  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }

    setSortBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });

    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        invoicesFilter: {
          filterBy: selectedOrderBy.filterBy,
          orderBy: selectedOrderBy.orderBy,
        },
      })
    );
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
  };

  useEffect(() => {
    if (sortBy)
      setFilter((item) => ({
        ...item,
        filterBy: sortBy.filterBy,
        orderBy: sortBy.orderBy,
      }));
  }, [sortBy]);

  const onFilterValuesChanged = (newValue) => {
    const localFilterDto = filter || {};

    if (localFilterDto && localFilterDto.createdOn) {
      // localFilterDto.dateTo = moment(localFilterDto.createdOn).format('YYYY-MM-DDTHH:mm:ss');
      // localFilterDto.dateFrom = moment(localFilterDto.createdOn).format('YYYY-MM-DDTHH:mm:ss');
    }
    if (newValue) {
      Object.values(newValue)
        .filter((item) => item.searchableKey || item.displayPath)
        .map((item) => {
          if (localFilterDto[item.displayPath] || item.value)
            localFilterDto[item.displayPath] = item.value;

          return undefined;
        });
    }
    setFilter(() => ({ ...localFilterDto }));
  };

  const reload = () => {
    getAllInvoices();
  };

  return (
    <div className='px-3 view-wrapper'>
      <Spinner isActive={isLoading.allInvoices} />
      <div className='d-flex-column'>
        <div className='header-section'>
          <div className='filter-section'>
            <div className='section'>
              <PermissionsComponent
                permissionsList={Object.values(InvoicesPermissions)}
                permissionsId={InvoicesPermissions.AddNewReceipt.permissionsId}
              >
                <ButtonBase
                  className='btns theme-solid px-3'
                  onClick={() => setOpenDialog(true)}
                >
                  <span className='mdi mdi-plus' />
                  {t(`${translationPath}add-new-receipt`)}
                </ButtonBase>
              </PermissionsComponent>
            </div>
            <div className='section autocomplete-section'>
              <div className='d-flex-column flex-v-end px-2 w-100 p-relative'>
                <PermissionsComponent
                  permissionsList={Object.values(InvoicesPermissions)}
                  permissionsId={
                    InvoicesPermissions.ViewAndSearchInMainAccountsInvoicesPage
                      .permissionsId
                  }
                >
                  <div className='d-flex-column w-100'>
                    <Inputs
                      idRef='invoicesSearchRef'
                      label='filter'
                      beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                      onInputChanged={(e) => searchHandler(e.target.value)}
                      inputPlaceholder='search-by-unit-ref-#'
                      translationPath={translationPath}
                      parentTranslationPath={parentTranslationPath}
                    />
                  </div>
                  <div className=' w-50'>
                    <AutocompleteComponent
                      idRef='branchRef'
                      multiple={false}
                      isLoading={isLoading.branch}
                      inputPlaceholder={t(`${translationPath}branch`)}
                      data={branchList.result || []}
                      chipsLabel={(option) =>
                        (option && option.branchName) || ''
                      }
                      displayLabel={(option) =>
                        (option && option.branchName) || ''
                      }
                      withoutSearchButton
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onChange={(event, newValue) => {
                        setFilter((item) => ({
                          ...item,
                          branchId: (newValue && newValue.branchId) || null,
                        }));
                      }}
                    />
                  </div>
                </PermissionsComponent>
              </div>
            </div>
          </div>
          <PermissionsComponent
            permissionsList={Object.values(InvoicesPermissions)}
            permissionsId={
              InvoicesPermissions.ViewAndSearchInMainAccountsInvoicesPage
                .permissionsId
            }
          >
            <div className='d-flex px-2'>
              <span className='mx-2 mt-1'>{t(`${translationPath}select`)}</span>
              <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
              <span className='px-2 d-flex'>
                <span className='texts-large mt-1'>
                  {t(`${translationPath}order-by`)}:
                </span>
                <div className='px-2'>
                  <SelectComponet
                    idRef='filterByRef'
                    data={[{ id: 'DueOn', filterBy: 'transaction-date' }]}
                    value={selectedOrderBy.filterBy}
                    wrapperClasses='mb-3'
                    isRequired
                    onSelectChanged={filterByChanged}
                    emptyItem={{
                      value: null,
                      text: 'select-filter-by',
                      isDisabled: false,
                    }}
                    valueInput='id'
                    textInput='filterBy'
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='px-2'>
                  <SelectComponet
                    idRef='orderByRef'
                    data={[
                      { id: 1, orderBy: 'ascending' },
                      { id: 2, orderBy: 'descending' },
                    ]}
                    emptyItem={{
                      value: null,
                      text: 'select-sort-by',
                      isDisabled: false,
                    }}
                    value={selectedOrderBy.orderBy}
                    onSelectChanged={orderByChanged}
                    wrapperClasses='mb-3'
                    isRequired
                    valueInput='id'
                    textInput='orderBy'
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='px-2'>
                  <SelectComponet
                    idRef='invoiceStatusRef'
                    data={[
                      { id: 'paid', invoiceStatus: 'Paid' },
                      { id: 'unpaid', invoiceStatus: 'Unpaid' },
                      { id: 'canceled', invoiceStatus: 'Canceled' },
                      { id: 'partially paid', invoiceStatus: 'Partially paid' },
                    ]}
                    value={
                      !!filter.invoiceStatus
                        ? invoices.result[0]?.invoiceStateName?.toLowerCase()
                        : ''
                    }
                    onSelectChanged={(e) => {
                      setFilter((item) => ({
                        ...item,
                        invoiceStatus: e ?? null,
                      }));
                    }}
                    wrapperClasses='mb-3'
                    isRequired
                    emptyItem={{
                      value: '',
                      text: 'select-invoice-status',
                      isDisabled: false,
                    }}
                    valueInput='id'
                    textInput='invoiceStatus'
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='mt-1'>
                  <ButtonBase
                    className='btns theme-solid'
                    onClick={orderBySubmitted}
                    id='action_apply'
                    disabled={
                      !selectedOrderBy.filterBy || !selectedOrderBy.orderBy
                    }
                  >
                    <span>{t(`${translationPath}apply`)}</span>
                  </ButtonBase>
                </div>
              </span>
            </div>
          </PermissionsComponent>
        </div>

        <div className='w-100 px-3'>
          <PermissionsComponent
            permissionsList={Object.values(InvoicesPermissions)}
            permissionsId={
              InvoicesPermissions.ViewAndSearchInMainAccountsInvoicesPage
                .permissionsId
            }
          >
            <InvoiceViewTable
              handleChangeView={handleChangeView}
              t={t}
              state={state}
              filter={filter}
              selected={selected}
              invoices={invoices}
              setOpenDialog={setOpenDialog}
              onStateChanged={onStateChanged}
              onFilterChange={onFilterChange}
              totalAmountPaid={totalAmountPaid}
              translationPath={translationPath}
              onSelectedChanged={onSelectedChanged}
              setTotalAmountPaid={setTotalAmountPaid}
              setState={setState}
              setSelected={setSelected}
              defaultState={defaultState}
              defaultSelected={defaultSelected}
              setSortBy={setSortBy}
              onFilterValuesChanged={onFilterValuesChanged}
              setActiveItem={setActiveItem}
              activeItem={activeItem}
              reload={reload}
              isFlagTrue={isFlagTrue}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              // isWithCheckboxColumn
            />
          </PermissionsComponent>
        </div>
      </div>
      {openDialog && (
        <InvoicesManagementDialog
          state={state}
          open={openDialog}
          selected={selected}
          onSave={() => {
            setOpenDialog(false);
            setState({ id: 'edit', value: defaultState });
            setSelected({ id: 'edit', value: defaultSelected });
            setTotalAmountPaid([]);
          }}
          close={() => {
            setOpenDialog(false);
            setState({ id: 'edit', value: defaultState });
            setSelected({ id: 'edit', value: defaultSelected });
            setTotalAmountPaid([]);
          }}
          onStateChanged={onStateChanged}
          translationPath={translationPath}
          onSelectedChanged={onSelectedChanged}
          setTotalAmountPaid={setTotalAmountPaid}
          parentTranslationPath={parentTranslationPath}
          setState={setState}
        />
      )}
    </div>
  );
};
