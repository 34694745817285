
import React, {
    useState,
    useEffect 
  } from "react";
  import { useTranslation } from "react-i18next";
  import moment from "moment";
  import {  SelectComponet  } from "../../../../Components";
  import { DateRangePickerComponent } from "../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";

  export const CreatedDateFilter = ({  
     setResaleFilters  , 
     resaleFilters ,
     resaleChangeFilters , 
     parentTranslationPath , 
     translationPath  , 
     dateFilter ,
     setDateFilter ,
     }) => {
    
    const { t } = useTranslation(parentTranslationPath);

      const dateRangeDefault = {
        startDate: null,
        endDate: null,
        key: "selection",
        selectedDateType: 1,
      };

      const primaryDateFilter = [
        {
          key: 1,
          value: `${translationPath}created-date`,
          filterKeyFrom: null,
          filterKeyTo: null,
        },
        {
          key: 11,
          value: `${translationPath}handover-date`,
          filterKeyFrom: "handover_date_from",
          filterKeyTo: "handover_date_to",
        },
      ];

    return (
        <div className="w-50 resale-date">
           <div className="w-100 customDatePicker mt-3">
          <div className="date-type-select">
            <SelectComponet
              data={primaryDateFilter}
              defaultValue={{ key: 1, value: `${translationPath}created-date` }}
              value={dateFilter.selectedDateType || 1}
              valueInput="key"
              textInput="value"
              onSelectChanged={(value) => {
                setDateFilter((f) => ({ ...f, selectedDateType: value }));             

              }}
              wrapperClasses="w-auto"
              themeClass="theme-transparent"
              idRef="Date_Select"
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              translationPathForData={translationPath}
            />
          </div>
          <DateRangePickerComponent
            onClearClicked={() => {
              setDateFilter(dateRangeDefault);
            }}
            ranges={[dateFilter]}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onDateChanged={(selectedDate) => {
              setDateFilter((item) => ({
                ...item,
                startDate:
                  selectedDate.selection && selectedDate.selection.startDate,
                endDate: new Date(
                  moment(
                    selectedDate.selection && selectedDate.selection.endDate
                  ).endOf("day")
                ),
                key: "selection",
              }));
            }}
          />
        </div>
         </div>
    );
  };
  