import React from "react";

function ChevronLeft({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M14.64 5.068c-.155.053-.604.485-3.293 3.167-1.777 1.773-3.159 3.182-3.22 3.285-.091.152-.107.226-.107.48s.016.328.107.48c.136.227 6.243 6.327 6.428 6.419.455.229 1.046.045 1.312-.407.097-.165.113-.234.112-.492 0-.212-.022-.341-.074-.44-.04-.077-1.291-1.36-2.779-2.85L10.421 12l2.705-2.71c1.488-1.49 2.739-2.773 2.779-2.85.052-.099.074-.228.074-.44.001-.255-.015-.328-.107-.484a1.016 1.016 0 0 0-1.232-.448"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default ChevronLeft;
