import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Inputs } from '../../../../../../Components';

export const Bathrooms = ({
  parentTranslationPath,
  translationPath,
  value,
  setvalue,
  helperText,
  isSubmitted,
  errors ,
  checkValues
}) => {
  const { t } = useTranslation(parentTranslationPath);
  return(
  <>
  <div>
    <Inputs
      idRef='BathroomsRef'
      labelValue='Bathrooms'
      value={value}
      isSubmitted={isSubmitted}
      helperText={(helperText  &&  helperText !=='' &&  t(`${translationPath}please-enter-values-two-digit-numbers-only`))}
      error={errors  && errors.bathrooms }  
      isWithError
      type='string'
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      onInputChanged={(event) => {
        const { value } =  event.target ; 
        checkValues('bathrooms' , value);
        setvalue(value);

      }}
    />
  </div>
  </>
   );
};
Bathrooms.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  isSubmitted: PropTypes.string.isRequired,
  setvalue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
