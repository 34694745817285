import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  dropdownMenu: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    overflowY: "scroll",
    maxHeight: "235px",
    margin: "4px 0",
  },
  isManageToggle: {
    padding: "9px 10px",
    margin: "0 !important",
    cursor: "pointer",

    // Hover effect
    "&:hover": {
      background: "var(--Colors-Background-bg-secondary_hover, #F2F4F7)",
      borderRadius: "var(--radius-sm, 6px)", // Optional: Add border-radius if needed
    },
  },
  item: {
    maxHeight: "38px",
    borderRadius: "var(--radius-sm, 6px)",
    margin: "0 6px 2px 6px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    margin: "0 16px",
  },
  text: {
    color: "var(--Colors-Text-text-secondary_hover, #182230)",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  customMenu: {
    borderRadius: "var(--radius-md, 8px)",
    border: "1px solid var(--Colors-Border-border-secondary, #EAECF0)",
    background: "var(--Colors-Background-bg-primary, #FFF)",
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    width: "340px",
    maxHeight: "320px",
  },
  customMenuList: {
    padding: 0,
  },
  action: {
    borderTop: "1px solid var(--Colors-Border-border-secondary, #EAECF0)",
    background: "var(--Colors-Background-bg-primary, #FFF)",
    padding: "var(--spacing-xl, 16px)",
    justifyContent: "center",
    alignItems: "center",
  },
  actions: {
    display: "flex",
    gap: "12px",
    borderTop: "1px solid var(--Colors-Border-border-secondary, #EAECF0)",
    background: "var(--Colors-Background-bg-primary, #FFF)",
    padding: "var(--spacing-xl, 16px)",
  },
  inputField: {
    padding: "9px 0",
    width: "270px",
    border: "none",
    boxShadow: "none",
    borderRadius: 0,

    "& input": {
      fontSize: "14px",
      fontWeight: 500,

      "&:focus, &:active": {
        borderBottom:
          "1px solid var(--Colors-Border-border-brand-solid, #7F6944)",
      },
    },
  },
  isManageMode: {
    padding: "9px 0",
  },
}));
