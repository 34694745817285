
import React  , { useEffect , useState }from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';
import { SwitchComponent  } from '../../../../../../Components' ; 
import { GeneralPropertyCriteriaTypesEnum } from '../../../../../../Enums' ; 


  export const Elevators = ({
    parentTranslationPath ,
    translationPath ,
    state ,
    setState ,
   } ) => { 

    const { t } = useTranslation(parentTranslationPath);
    const [selectedValue, setSelectedValue] = useState(null);

    const onChangeSelectedItem = (key , value , index) => 
      {
        const newListUpdated = [...state.generalPropertyLookupInfo] ;
          const newSelectedArray =  [...selectedValue.generalPropertyLookupDtos];
            newSelectedArray[index] =
                  {  
                    ...newSelectedArray[index] ,
                    score : (value || 0) , 
                  };
              const generalPropertyInfoIndex = newListUpdated.findIndex((f)=> f.generalPropertyCriteriaType === (selectedValue.generalPropertyCriteriaType));
              let newSelectedObject = { generalPropertyCriteriaType : (selectedValue?.generalPropertyCriteriaType)   , generalPropertyLookupDtos  : newSelectedArray };
              setSelectedValue(newSelectedObject);
  
              if(generalPropertyInfoIndex !== -1 )
              {
                newListUpdated[generalPropertyInfoIndex] =  newSelectedObject ; 
                setState({ id: 'generalPropertyLookupInfo', value: newListUpdated});
  
              }
             else 
             {
              newListUpdated.push({...newSelectedObject});
              setState({ id: 'generalPropertyLookupInfo', value: newListUpdated});
             }
       };

    useEffect(() => {
      if(!(state && state.generalPropertyLookupInfo && state.generalPropertyLookupInfo.find((f)=>f.generalPropertyCriteriaType === GeneralPropertyCriteriaTypesEnum.Elevators.key )))
      setSelectedValue({generalPropertyCriteriaType : GeneralPropertyCriteriaTypesEnum.Elevators.key  ,
        generalPropertyLookupDtos : [
        {
          lookupItemName :null ,
          lookupsId :null ,
          propertyRatingGeneralPropertyCriteriaScoreId :null  , 
          score :0
        }
      ] });
      else 
      setSelectedValue(state && state.generalPropertyLookupInfo && state.generalPropertyLookupInfo.find((f)=>f.generalPropertyCriteriaType === GeneralPropertyCriteriaTypesEnum.Elevators.key))
  
   }, [state]);

   return (
    <div className="w-75">
      <div className='general-property-section'>
        <div>
          {/* <span className='fw-bold'> {t(`${translationPath}elevators`)} </span> */}
        </div>
        <div className='w-100  mt-2 mb-2'>
        {
              selectedValue  &&  selectedValue.generalPropertyLookupDtos && 
              selectedValue.generalPropertyLookupDtos.map((item, index) => 
              ( 
                <div className='d-flex d-flex-v-center general-property-card mt-2 mb-2 b-bottom' key={`elevators${index + 1}`}>
                <div className='mb-2'>
                 <span className='fw-bold'> {item.lookupItemName} </span>
                    </div>
                    <div className='px-4'>
                     <Rating  
                          name={`slevators${index+1}`}
                          max={10}
                          precision={1}
                          value={item.score}
                          onChange={(event, newValue) => 
                          {
                            onChangeSelectedItem('score' ,  newValue || 0 , index);
    
                          }}
                       />  
                       </div>
                    </div>
                 ))}
          </div>  
        </div>
      </div>
     );
  } ;   
  