import React, { useState, useEffect, useCallback } from 'react';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Spinner, Tables  , AutocompleteComponent, DialogComponent } from '../../../../../../Components';
import { useTitle } from '../../../../../../Hooks';
import { TableActions } from '../../../../../../Enums';
import { GetParams } from '../../../../../../Helper';
import { GetAllPropertyUnitModelsAPI  , GetAllPropertyUnitTypeAPI  } from '../../../../../../Services';
import { UnitsModelsManangementDialog } from './UnitsModelsManangementDialog' ; 
import { PropertyUnitTypeComponent }  from './PropertyUnitTypeComponent' ; 
import { DeleteDialog } from './DeleteDialog';
import { AddPropertyUnitType } from './PropertyUnitType/AddPropertyUnitType' ; 
import { UnitsModelsTable } from './UnitsModelsTable' ; 


const parentTranslationPath = 'PropertiesProfileManagementView';
const translationPath = '';

export const UnitsModelsViewComponent = () => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isUnitModelDialogOpen, setIsUnitModelDialogOpen] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [unitsModels, setUnitsModels] = useState({
    result: [],
    totalCount: 0,
  });
  const [isAddPropertyUnitTypeDialogOpen , setIsAddPropertyUnitTypeDialogOpen ]= useState(false); 
  
  const [propertyUnitTypes, setPropertyUnitTypes] = useState({
    result: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState({
    pageSize: 25,
    pageIndex: 0,
  });

  useTitle(t(`${translationPath}units-models`));

  const getAllUnitsModels = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllPropertyUnitModelsAPI({
      propertyId: +GetParams('id'),
      pageIndex: filter.pageIndex + 1,
      pageSize: filter.pageSize,
    });
    if (!(res && res.status && res.status !== 200)) {
      setUnitsModels({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setUnitsModels({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, [filter]);


  const GetAllPropertyUnitType = useCallback(async () => {
    const res = await GetAllPropertyUnitTypeAPI(+GetParams('id'));
    if (!(res && res.status && res.status !== 200)) {
        setPropertyUnitTypes({
         result: (res && res[0]&& res[0].propertyUnitType) || [],
         totalCount: (res && res.totalCount) || 0,
      });
    } else {
     setPropertyUnitTypes({
        result: [],
        totalCount: 0,
      });
    }
  }, []);



  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageSize }));
  };
  const addNewHandler = () => {
    setIsUnitModelDialogOpen(true);
  };
  const reloadData = () => {
    getAllUnitsModels();
  };

  const reloadPropertyUnitTypeData = () => {
    GetAllPropertyUnitType();
  };

  const getActionTableWithPermissions = () => {
    const list = [];
    if (true) 
      list.push({
        enum: TableActions.editText.key,
        isDisabled: false,
        externalComponent: null,
      });
    if (true) 
       list.push({
        enum: TableActions.deleteText.key,
        isDisabled: false,
        externalComponent: null,
      });
    return list;
  };

  const tableActionClicked = useCallback((actionEnum, item, focusedRow, event) => {
    event.stopPropagation();
    event.preventDefault();
    if (actionEnum === TableActions.deleteText.key) {
      setIsDeleteDialogOpen(true);
      setActiveItem(item);
    } else if (actionEnum === TableActions.editText.key) {
      setIsUnitModelDialogOpen(true);
      setActiveItem(item);
    }
  }, []);

  useEffect(() => {
    getAllUnitsModels();
    GetAllPropertyUnitType();
  }, [getAllUnitsModels]);

  const handlerCreateUnitModel = (e) => {
    e.preventDefault();
    setIsUnitModelDialogOpen(true);
    setIsConfirmDialogOpen(false);
  };


  return (
    <div className='units-Properties-wrapper childs-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='title-section'>
        <span>{t(`${translationPath}units-models`)}</span>
      </div>
      <div className='d-flex-column'>
        <div className='header-section'>
          <div className='filter-section px-2'>
            <div className='section'>
                <ButtonBase className='btns theme-solid px-3' onClick={addNewHandler}>
                  <span className='mdi mdi-plus' />
                  {t(`${translationPath}add-new-units-models`)}
                </ButtonBase>
             
               <div className='roles-wrapper'>
                          <ButtonBase
                            className='btns theme-outline roles-button'
                            onClick={()=>  setIsAddPropertyUnitTypeDialogOpen(true)}         
                            id='add-unit-Types-in-property-ref'>
                            <span className='mdi mdi-plus' />
                            <span>{t(`${translationPath}add-unit-Types-in-property`)}</span>
                            <span className='mdi mdi-menu-swap' />
                          </ButtonBase>
                      </div>
                      <div className='roles-content px-2'>
                          {propertyUnitTypes && propertyUnitTypes.result && 
                            propertyUnitTypes.result.map((item, index) => (
                              <span key={`propertyUnitTypesRef${index + 1}`} className='px-1'>
                                <span>{item.lookupItemName}</span>
                                {index < propertyUnitTypes.result.length - 1 && <span>,</span>}
                              </span>
                         ))}
                   </div>
               </div>
          </div>
        </div>
      </div>
       <div className='px-2 mt-2'>
         <UnitsModelsTable
           unitsModels={unitsModels}
           parentTranslationPath= {parentTranslationPath} 
           translationPath={translationPath}
           tableActionClicked={tableActionClicked}
           onPageSizeChanged={onPageSizeChanged}
           onPageIndexChanged={onPageIndexChanged}
           filter={filter}
          />
        </div>
      {isDeleteDialogOpen && (
        <DeleteDialog
          activeItem={activeItem}
          isDeleteDialogOpen={isDeleteDialogOpen}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          reloadData={reloadData}
          onCloseClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          onCancelClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}

      { isUnitModelDialogOpen && (
        <UnitsModelsManangementDialog
          isDialogOpen={isUnitModelDialogOpen}
          activeItem={activeItem}
          onSave={() => {
            reloadData();
            setIsUnitModelDialogOpen(false);
            setActiveItem(null);
          }}
          onClose={() => {
            setActiveItem(null);
            setIsUnitModelDialogOpen(false);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          propertyUnitTypes={propertyUnitTypes}
        />
      )}

      {isAddPropertyUnitTypeDialogOpen && (
         <AddPropertyUnitType
          isDialogOpen={isAddPropertyUnitTypeDialogOpen}
          onSave={() => {
            setIsAddPropertyUnitTypeDialogOpen(false);
            reloadPropertyUnitTypeData();
            setIsConfirmDialogOpen(true);
          }}
          onClose={() => {
            setIsAddPropertyUnitTypeDialogOpen(false);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          propertyUnitTypes={propertyUnitTypes}
        />
      )}
      {isConfirmDialogOpen && (
        <DialogComponent
          titleText={t(`${translationPath}${'add-unit-Types-in-property'}`)}
          saveText={t(`${translationPath}Create-Unit-Model`)}
          cancelText={t(`${translationPath}Skip-Unit-Model`)}
          onSaveClicked={handlerCreateUnitModel}
          saveType='submit'
          maxWidth='sm'
          disableBackdropClick
          isOpen
          dialogContent={
            <h6>do you want to create unit model or skip this step?</h6>
          }
          onCancelClicked={() => setIsConfirmDialogOpen(false)}
          onCloseClicked={() => setIsConfirmDialogOpen(false)}
        />
      )}
    </div>
  );
};
