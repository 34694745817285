import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DialogComponent, Tables } from '../../../../Components';
import { TableActions, TableFilterTypesEnum } from '../../../../Enums';
import { TableFilterOptionsSelectSystemNotificationsEnum } from '../../../../Enums/TableFilterOptionsSelectSystemNotifications.Enum';
import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';
import { getDownloadableLink } from '../../../../Helper';

export const SystemNotificationsTableView = ({
    data,
    translationPath,
    parentTranslationPath,
    filter,
    setFilter,
    onPageIndexChanged,
    onPageSizeChanged,
    onFilterValuesChanged,
    systemNotificationsTableFilter
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const [tableFilterData, setTableFilterData] = useState([]);
    const [tableActions, setTableActions] = useState([]);
    const [bodyOfnotification, setBodyOfnotification] = useState('');
    const dispatch = useDispatch();
    const [sortBy, setSortBy] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    const HeaderSystemNotificationsTable = [
        {
            id: 1,
            label: t(`${translationPath}title`),
            input: 'title',
            isSortable: true,
            isHiddenFilter: false
        },
        {
            id: 2,
            label: t(`${translationPath}name`),
            input: 'user',
            isSortable: true,
            isHiddenFilter: false,
            component: (item) => <span>{item && item.fullName}</span> || ''
        },
        {
            id: 6,
            label: t(`${translationPath}reminderType`),
            input: 'ReminderType',
            isHiddenFilter: false,
            withSelectFilter: true,
            isSortable: true,
            component: (item) => <span>{item && item.notificationType}</span> || ''
        },
        {
            id: 7,
            label: t(`${translationPath}createdOn`),
            input: 'createdOn',
            isSortable: true,
            isHiddenFilter: true,
            isDate: true
        },
        {
            id: 8,
            label: t(`${translationPath}time`),
            input: 'createdOn',
            isSortable: false,
            isHiddenFilter: true,
            isDate: true,
            dateFormat: 'hh:mm'
        },
        {
            id: 9,
            label: t(`${translationPath}related-to-id`),
            input: 'sourceId',
            isSortable: true,
            isHiddenFilter: false,
        },
        {
            id: 10,
            label: t(`${translationPath}related-to-name`),
            input: 'sourceName',
            isSortable: true,
            isHiddenFilter: false,
        }  ,      {
            id: 11,
            label: t(`${translationPath}isImportant`),
            input: 'isImportant',
            isHiddenFilter: false,
            isSortable: true,
            component: (item) => <>{item && item.isImportant && <div>{t(`${translationPath}yes`)}</div> || <div>{t(`${translationPath}no`)}</div>}</> || ''
        },
    ];
    const focusedRowChanged = (rowIndex, item) => {
        if (item?.body && item?.sourceName === 'ExportReportJob') {
            setTableActions([{
                enum: TableActions.downloadText.key,
                isDisabled: false,
                externalComponent: null,
            }]);
        } else {
            setTableActions([{
                enum: TableActions.viewDetails.key,
            }]);
        }
    };
    const tableActionClicked = useCallback(
        (actionEnum, item) => {
            dispatch(ActiveItemActions.activeItemRequest(item));
            if (actionEnum === TableActions.viewDetails.key) {
                setBodyOfnotification(item && item.body);
                setOpenDialog(true);
            } else if (actionEnum === TableActions.downloadText.key) {
                const link = document.createElement('a');
                link.setAttribute('download', item.body);
                link.href = getDownloadableLink(item.body);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        },
        [dispatch]
    );

    useEffect(() => {
        if (sortBy) {
            setFilter((item) => ({
                ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy, pageIndex: 0
            }));
        }
    }, [sortBy]);

    useEffect(() => {
        setTableFilterData(
            HeaderSystemNotificationsTable.map((column) => ({
                key: column.key || column.fieldKey || column.id,
                filterType: (column.isDate && TableFilterTypesEnum.datePicker.key) || (column.withSelectFilter && TableFilterTypesEnum.selectOption.key) || TableFilterTypesEnum.textInput.key,
                isHiddenFilter: column.isHiddenFilter,
                textInputType: column.textInputType,
                textInputMax: column.textInputMax,
                textInputMin: column.textInputMin,
                displayPath: column.input
            }))
        );
    }, []);

    return (
      <>
        <Tables
          data={(data && data.result) || []}
          headerData={HeaderSystemNotificationsTable}
          filterValues={systemNotificationsTableFilter}
          onFilterValuesChanged={onFilterValuesChanged}
          filterData={tableFilterData}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          totalItems={(data && data.totalCount) || 0}
          actionsOptions={{
                    onActionClicked: tableActionClicked,
                }}
          defaultActions={tableActions}
          focusedRowChanged={focusedRowChanged}
          isWithFilter
          FilterDisabledButton
          setSortBy={setSortBy}
          optionFilterName={TableFilterOptionsSelectSystemNotificationsEnum}
        />
        <DialogComponent
          isOpen={openDialog}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          totalItems={data.totalCount}
          titleClasses='DialogComponent-WorkingHoursDialogView'
          wrapperClasses='wrapperClasses-WorkingHoursDialogView'
          titleText='Reminder'
          onCloseClicked={() => setOpenDialog(false)}
          maxWidth='sm'
          closeIsDisabled={false}
          dialogContent={(
            <div className='DialogSystemContainer'><p className='DialogSystemNotificationContent' dangerouslySetInnerHTML={{ __html: bodyOfnotification || '<div></div>' }} /></div>
                )}
        />

      </>
    );
};
