
import {  ZeroMatchingLeaseView  } from '../../Views/Home/Inquires' ;
import  { ZeroMatchingLeaseManagementView   } from '../../Views/Home/Inquires/ZeroMatchingLease/ZeroMatchingLeaseUtilities' ; 
import {  ZeroMatchingLeaseProfileManagementView } from '../../Views/Home/Inquires/ZeroMatchingLease/ZeroMatchingLeaseUtilities/ZeroMatchingLeaseProfileManagementView' ; 


export const ZeroMatchingLeaseInquiresRoutes = [
  {
    path: '/view',
    name: 'Inquires:zero-matching-lease',
    component: ZeroMatchingLeaseView,
    layout: '/home/zero-matching-lease',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'Inquires:zero-matching-lease',
        isDisabled: false,
        route: '/home/zero-matching-lease/view',
        groupName: 'inquiries',
      },
    ],
  },
  {
    path: '/add',
    name : 'Inquires:zero-matching-lease' , 
    component: ZeroMatchingLeaseManagementView ,
    layout: '/home/zero-matching-lease',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name : 'Inquires:zero-matching-lease' , 
        isDisabled: false,
        route: '/home/zero-matching-lease/view',
        groupName: 'inquiries',
      },
      {
        name: 'Inquires:zero-matching-lease-profile-add',
        isDisabled: true,
        route: '/home/zero-matching-lease/add',
      },
    ],
  },
  {
    path: '/zero-matching-lease-profile-edit',
    name : 'Inquires:zero-matching-lease' , 
    component: ZeroMatchingLeaseProfileManagementView ,
    layout: '/home/zero-matching-lease',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name : 'Inquires:zero-matching-lease' , 
        isDisabled: false,
        route: '/home/zero-matching-lease/view',
        groupName: 'inquiries',
      },
      {
        name: 'Inquires:zero-matching-lease-profile-edit',
        isDisabled: true,
        route: '/home/zero-matching-lease/zero-matching-lease-profile-edit',
      },
    ],
  },
];

