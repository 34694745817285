import React, { useState, useEffect, useCallback } from 'react'
import { DialogComponent, AutocompleteComponent, Inputs, Spinner } from "../../Components";
import { GetAllUserSearchCriteria } from '../../Services';
import { useTranslation } from 'react-i18next';

export const ApplyFilter = ({
    isOpen,
    setIsLoading,
    searchCriteriaTypeId,
    isLoading,
    setIsOpenApplyFilter,
    setFilterValue,
    setSearchValue,
    searchTimer,
    setSelectAll  , 
}) => {
    const { t } = useTranslation('Shared');
    const [searchResult, setSearchResult] = useState([]);
    const [selectedSearch, setSelectedSearch] = useState(null);
    const [saveIsDisabled, setSaveIsDisabled] = useState(true);

    const getSearchCriteria = useCallback(async () => {
        setIsLoading(true);
        const res = await GetAllUserSearchCriteria(searchCriteriaTypeId);
        if (!(res && res.status && res.status !== 200)) {
            setSearchResult(res);
        } else {
            setSearchResult([]);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        getSearchCriteria();
    }, [getSearchCriteria]);

    return (
        <>
            <div>
                <DialogComponent
                    isOpen={isOpen}
                    titleText={t('apply-filter')}
                    maxWidth='sm'
                    onSaveClicked={() => {
                        if(selectedSearch && selectedSearch.allValues)
                         setSelectAll(selectedSearch.allValues) ; 
                         else
                         setSelectAll([]) ;  

                          if(selectedSearch && selectedSearch.search)
                          {
                             setSearchValue(selectedSearch.search) ; 
                             setFilterValue(selectedSearch.search); 
                           }
                            else 
                            {
                                setSearchValue(null) ; 
                                setFilterValue(null);
                            } 
         
                        setIsOpenApplyFilter(false);
                        searchTimer.current.initialHit = true
                    }}
                    saveText='apply'
                    onCancelClicked={() => { setIsOpenApplyFilter(false); }}
                    disableBackdropClick
                    saveIsDisabled={isLoading || saveIsDisabled}
                    dialogContent={(
                        <>
                                <AutocompleteComponent
                                    idRef='addFilterRef'
                                    multiple={false}
                                    data={searchResult || []}
                                    chipsLabel={(option) => option.searchCriteriaName || ''}
                                    displayLabel={(option) => option.searchCriteriaName || ''}
                                    withoutSearchButton
                                    inputPlaceholder={t('select-filter')}
                                    onChange={(e, newValue) => {
                                        const newCriteria = newValue && newValue.searchCriteria && JSON.parse(newValue.searchCriteria);
                                        setSelectedSearch(newCriteria);

                                        setSaveIsDisabled(false);
                                    }}
                                />
                        </>
                    )}
                />
            </div>
        </>
    );
}
