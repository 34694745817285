import { LeadTypeIdEnum } from '../Enums';
import { GetParams } from '../Helper';

export const TabRule = async (
  item,
  setJsonForm,
  jsonForm,
  v,
  setSteps,
  steps,
  itemList,
  setitemList , 
  setData , 
) => {
  if (item && item.data && item.data.specialKey !== 'UnitOperationType') return;
  if (v === undefined || v === null) return;
  const rentTap = item.data.hasEffectOn;
  const salesTap = item.data.hasEffectedFrom;

  let unitForm = localStorage.getItem('unit_form') ?
    JSON.parse(localStorage.getItem('unit_form')) :
    undefined;

  let unitsteps = localStorage.getItem('unit_step') ?
    JSON.parse(localStorage.getItem('unit_step')) :
    undefined;

  if (!unitForm) {
    localStorage.setItem('unit_form', JSON.stringify(jsonForm));
    unitForm = JSON.parse(localStorage.getItem('unit_form'));
  }

  if (!unitsteps) {
    localStorage.setItem('unit_step', JSON.stringify(steps));
    unitsteps = JSON.parse(localStorage.getItem('unit_step'));
  }

  const salesTapIndex = unitForm.indexOf(unitForm.find((f) => f[0].data.description === salesTap));
  const rentTapIndex = unitForm.indexOf(unitForm.find((f) => f[0].data.description === rentTap));
  const salesListingTapIndex = unitForm.indexOf(unitForm.find((f) => f[0].data.description === 'Lease Listing Details'));
  const rentListingTapIndex = unitForm.indexOf(unitForm.find((f) => f[0].data.description === 'Sale Listing Details'));
  const saleValue = 430;
  const rcentValue = 431;

  const getItemList = (unit) => {
    const list = [];
    unit.map((value) => {
      value.map((sub) => {
        list.push(sub);
      });
    });
    return list;
  };

  if (v && v.lookupItemId === saleValue) {
    unitsteps.splice(salesTapIndex, 1);
    unitForm.splice(salesTapIndex, 1);
    unitsteps.splice(salesListingTapIndex, 1);
    unitForm.splice(salesListingTapIndex, 1);
    setJsonForm([...unitForm]);
    setSteps([...unitsteps]);
    setitemList([...getItemList(unitForm)]);
  } else if (v && v.lookupItemId === rcentValue) {
    unitForm.splice(rentTapIndex, 1);
    unitsteps.splice(rentTapIndex, 1);
    unitForm.splice(rentListingTapIndex, 1);
    unitsteps.splice(rentListingTapIndex, 1);
    setJsonForm([...unitForm]);
    setSteps([...unitsteps]);

    setitemList([...getItemList(unitForm)]);
  } else {
    setJsonForm([...unitForm]);
    setSteps([...unitsteps]);

    setitemList([...getItemList(unitForm)]);
  }
  if(setData)
  {
    setData('property_name' , {} ) ; 
    setData('unit_type' ,  {}) ; 
  }
};

export const TabForLeadRule = async (
  item,
  setJsonForm,
  jsonForm,
  v,
  setSteps,
  steps,
  itemList,
  setitemList,
  values,
  setData,
) => {
  const modeOfPaymentIndex = itemList.findIndex((f) => f.field.id === 'mode_of-payment');
  const isMortgageIndex = itemList.findIndex((f) => f.field.id === 'is_mortgage');

  if (+GetParams('formType') === LeadTypeIdEnum.Owner.leadTypeId && isMortgageIndex !== -1) {
    if (item && item.field && item.field.id === 'is_mortgage') {
      let leadForm = localStorage.getItem('lead_form_owner') ?
        JSON.parse(localStorage.getItem('lead_form_owner')) :
        undefined;

      let leadsteps = localStorage.getItem('lead_step_owner') ?
        JSON.parse(localStorage.getItem('lead_step_owner')) :
        undefined;

      if (!leadForm) {
        localStorage.setItem('lead_form_owner', JSON.stringify(jsonForm));
        leadForm = JSON.parse(localStorage.getItem('lead_form_owner'));
      }

      if (!leadsteps) {
        localStorage.setItem('lead_step_owner', JSON.stringify(steps));
        leadsteps = JSON.parse(localStorage.getItem('lead_step_owner'));
      }

      const mortgageDetailsTapIndex = leadForm.indexOf(leadForm.find((f) => f[0].data.description === 'Mortgage Details'));
      const financialStatusDetailsTapIndex = leadForm.indexOf(leadForm.find((f) => f[0].data.description === 'Financial Status Details'));

      const getItemList = (lead) => {
        const list = [];
        lead.map((value) => {
          value.map((sub) => {
            list.push(sub);
          });
        });
        return list;
      };

      if ((v && v === 'No') || !v) {
        leadsteps.splice(mortgageDetailsTapIndex, 1);
        leadForm.splice(mortgageDetailsTapIndex, 1);
        leadsteps.splice(financialStatusDetailsTapIndex - 1, 1);
        leadForm.splice(financialStatusDetailsTapIndex - 1, 1);
        setJsonForm([...leadForm]);
        setSteps([...leadsteps]);
        setitemList([...getItemList(leadForm)]);
      } else {
        setJsonForm([...leadForm]);
        setSteps([...leadsteps]);
        setitemList([...getItemList(leadForm)]);
      }
    }
  } else if (+GetParams('formType') === LeadTypeIdEnum.Seeker.leadTypeId) {
    if (item && item.field && item.field.id === 'is_mortgage') {
      let leadForm = localStorage.getItem('lead_form_seeker') ?
        JSON.parse(localStorage.getItem('lead_form_seeker')) :
        undefined;

      let leadsteps = localStorage.getItem('lead_step_seeker') ?
        JSON.parse(localStorage.getItem('lead_step_seeker')) :
        undefined;

      if (!leadForm) {
        localStorage.setItem('lead_form_seeker', JSON.stringify(jsonForm));
        leadForm = JSON.parse(localStorage.getItem('lead_form_seeker'));
      }

      if (!leadsteps) {
        localStorage.setItem('lead_step_seeker', JSON.stringify(steps));
        leadsteps = JSON.parse(localStorage.getItem('lead_step_seeker'));
      }

      const mortgageDetailsTapIndex = leadForm.indexOf(leadForm.find((f) => f[0].data.description === 'Mortgage Details'));
      const financialStatusDetailsTapIndex = leadForm.indexOf(leadForm.find((f) => f[0].data.description === 'Financial Status Details'));

      const getItemList = (lead) => {
        const list = [];
        lead.map((value) => {
          value.map((sub) => {
            list.push(sub);
          });
        });
        return list;
      };

      if ((v && v === 'No') || !v) {
        leadsteps.splice(financialStatusDetailsTapIndex, 1);
        leadForm.splice(financialStatusDetailsTapIndex, 1);
        leadsteps.splice(mortgageDetailsTapIndex, 1);
        leadForm.splice(mortgageDetailsTapIndex, 1);

        setJsonForm([...leadForm]);
        setSteps([...leadsteps]);
        setitemList([...getItemList(leadForm)]);
      } else {
        setJsonForm([...leadForm]);
        setSteps([...leadsteps]);
        setitemList([...getItemList(leadForm)]);
      }
    } else if (item && item.field && item.field.id === 'operation_type' && item.data.specialKey === 'seeker_lead' && isMortgageIndex !== -1) {
      let leadForm = localStorage.getItem('lead_form_seeker') ?
        JSON.parse(localStorage.getItem('lead_form_seeker')) :
        undefined;

      let leadsteps = localStorage.getItem('lead_step_seeker') ?
        JSON.parse(localStorage.getItem('lead_step_seeker')) :
        undefined;

      if (!leadForm) {
        localStorage.setItem('lead_form_seeker', JSON.stringify(jsonForm));
        leadForm = JSON.parse(localStorage.getItem('lead_form_seeker'));
      }

      if (!leadsteps) {
        localStorage.setItem('lead_step_seeker', JSON.stringify(steps));
        leadsteps = JSON.parse(localStorage.getItem('lead_step_seeker'));
      }

      const mortgageDetailsTapIndex = leadForm.indexOf(leadForm.find((f) => f[0].data.description === 'Mortgage Details'));
      const financialStatusDetailsTapIndex = leadForm.indexOf(leadForm.find((f) => f[0].data.description === 'Financial Status Details'));

      const getItemList = (lead) => {
        const list = [];
        lead.map((value) => {
          value.map((sub) => {
            list.push(sub);
          });
        });
        return list;
      };
      if ((v && v.lookupItemName === 'Rent')) {
        itemList[isMortgageIndex].data.isReadonly = true;
        setData('is_mortgage', {});

        if (modeOfPaymentIndex !== -1)
          itemList[modeOfPaymentIndex].data.isReadonly = true;

        setData('mode_of_payment', {});
        setData('mode_of-payment', null);

        leadsteps.splice(financialStatusDetailsTapIndex, 1);
        leadForm.splice(financialStatusDetailsTapIndex, 1);
        leadsteps.splice(mortgageDetailsTapIndex, 1);
        leadForm.splice(mortgageDetailsTapIndex, 1);

        setJsonForm([...leadForm]);
        setSteps([...leadsteps]);
        setitemList([...getItemList(leadForm)]);
      }
    }
  }
};

export const TabForLeadRuleModeOfPayment = async (
  item,
  setJsonForm,
  jsonForm,
  v,
  setSteps,
  steps,
  itemList,
  setitemList,
) => {
  const modeOfPaymentIndex = itemList.findIndex((f) => f.field.id === 'mode_of-payment');

  if (modeOfPaymentIndex !== -1 && item && item.field && item.field.id === 'mode_of-payment') {
    let leadForm = localStorage.getItem('lead_form_seeker') ?
      JSON.parse(localStorage.getItem('lead_form_seeker')) :
      undefined;

    let leadsteps = localStorage.getItem('lead_step_seeker') ?
      JSON.parse(localStorage.getItem('lead_step_seeker')) :
      undefined;

    if (!leadForm) {
      localStorage.setItem('lead_form_seeker', JSON.stringify(jsonForm));
      leadForm = JSON.parse(localStorage.getItem('lead_form_seeker'));
    }

    if (!leadsteps) {
      localStorage.setItem('lead_step_seeker', JSON.stringify(steps));
      leadsteps = JSON.parse(localStorage.getItem('lead_step_seeker'));
    }

    const mortgageDetailsTapIndex = leadForm.indexOf(leadForm.find((f) => f[0].data.description === 'Mortgage Details'));
    const financialStatusDetailsTapIndex = leadForm.indexOf(leadForm.find((f) => f[0].data.description === 'Financial Status Details'));

    const getItemList = (lead) => {
      const list = [];
      lead.map((value) => {
        value.map((sub) => {
          list.push(sub);
        });
      });
      return list;
    };

    if ((v && v.lookupItemName !== 'Mortgage') || !v) {
      leadsteps.splice(financialStatusDetailsTapIndex, 1);
      leadForm.splice(financialStatusDetailsTapIndex, 1);
      leadsteps.splice(mortgageDetailsTapIndex, 1);
      leadForm.splice(mortgageDetailsTapIndex, 1);

      setJsonForm([...leadForm]);
      setSteps([...leadsteps]);
      setitemList([...getItemList(leadForm)]);
    } else {
      setJsonForm([...leadForm]);
      setSteps([...leadsteps]);
      setitemList([...getItemList(leadForm)]);
    }
  }
};
