

import React , { useState , useEffect , useRef , useCallback   } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CheckboxesComponent , AutocompleteComponent  } from '../../../../../../../Components' ; 
import { OrganizationUserSearch } from '../../../../../../../Services' ;  


    
export const ActivitiesCreatedBy  = ({
  parentTranslationPath,
  translationPath,
  state,
  setState , 
  checkAtLeastOneSelected, 
  checkAtLeastOneSelected2 ,
  setDefaultSelected ,
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const searchTimer = useRef(null);
    let timer = null; 

    const [allUsers , setAllUsers] =  useState([]) ;
    const [isLoading ,   setIsLoading ] = useState(false) ;  

    const getAllOrganizationUserSearch = useCallback(async (searchValue) => {
      setIsLoading(true);
      const res = await OrganizationUserSearch({ pageIndex: 0, pageSize: 10, name: searchValue  , userStatusId : 2 });
      if (!(res && res.status && res.status !== 200)) 
      setAllUsers(res.result.map((item) => ({
        id :  item.id , 
        fullName : item.fullName  , 
        branch : item.branch  || null  , 
      })));
       else 
       setAllUsers([]);  

       setIsLoading(false);

    }, []); 


    useEffect(() => {
      getAllOrganizationUserSearch() ;
    }, []); 
 

 
  return (
    <div className='content'>
       <div className='w-100 px-2 mt-2 checkBoxsPart'>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='ActivitiesCreatedByViewAllActivitiesRef'
          label={t(`${translationPath}view-all-activities`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.activities.createdBy.allUsers}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.activities.createdBy ,  allUsers : e.target.checked } );
            if(checkAtLeastOneSelected2(state.activities.createdBy.specificUsers));
             else if(!checkIfCanSelected)
               return ; 

            const activitiesCreatedBy =  { ...state.activities.createdBy ,
               allUsers : (e.target.checked)   , 
               myUser  : false  , 
               myTeamUsers : false , 
               myBusinessGroupUsers : false , 
               myBranchUsers :false , 
               myManageUsers : false ,
               specificUsers :[] , 
               myTeamLeadUsers : false , 
             } ;
          setState({id : 'activities' , value : { ...state.activities , createdBy :   activitiesCreatedBy  } });
         }}
         />
         </div>
         <div className='checkboxesItem'>
          <CheckboxesComponent
           idRef='ActivitiesCreatedByViewMyActivitiesRef'
           label={t(`${translationPath}view-my-activities`)}
           parentTranslationPath={parentTranslationPath}
           translationPathForData={translationPath}
           singleChecked={state.activities.createdBy.myUser}
           onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.activities.createdBy ,  myUser : e.target.checked });

              if(checkAtLeastOneSelected2(state.activities.createdBy.specificUsers));
              else if(!checkIfCanSelected)
                return ; 
                
              const activitiesCreatedBy =  { ...state.activities.createdBy ,
                 allUsers : false  , 
                 myUser  : (e.target.checked)  , 
                 myTeamUsers : false , 
                 myBusinessGroupUsers : false ,
               } ;

          setState({id : 'activities' , value : { ...state.activities , createdBy :   activitiesCreatedBy  } });
         }}
         />
         </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
           idRef='ActivitiesCreatedByviewMyTeamActivitiesRef'
           label={t(`${translationPath}view-my-team-activities`)}
           parentTranslationPath={parentTranslationPath}
           translationPathForData={translationPath}
           singleChecked={state.activities.createdBy.myTeamUsers}
           onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.activities.createdBy ,  myTeamUsers : e.target.checked } );
             
            if(checkAtLeastOneSelected2(state.activities.createdBy.specificUsers));
            else if(!checkIfCanSelected)
              return ;

           const activitiesCreatedBy =  { ...state.activities.createdBy ,
              allUsers : false  , 
              myUser  :false  , 
              myTeamUsers :  (e.target.checked) , 
              myBusinessGroupUsers : false , 

            } ;
          setState({id : 'activities' , value : { ...state.activities , createdBy :   activitiesCreatedBy  } });
         }}
         />
         </div>
         <div className='checkboxesItem'>
          <CheckboxesComponent
            idRef='ActivitiesCreatedByViewMyBusinessGroupActivitiesRef'
            label={t(`${translationPath}view-my-business-group-activities`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={state.activities.createdBy.myBusinessGroupUsers}
            onSelectedCheckboxClicked={(e)=> {

              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.activities.createdBy ,  myBusinessGroupUsers : e.target.checked } );
      
             if(checkAtLeastOneSelected2(state.activities.createdBy.specificUsers));
             else if(!checkIfCanSelected)
               return ;

            const activitiesCreatedBy =  { ...state.activities.createdBy ,
               allUsers : false  , 
               myUser  :false  , 
               myTeamUsers :  false, 
               myBusinessGroupUsers : (e.target.checked) , 
             } ;

            setState({id : 'activities' , value : { ...state.activities , createdBy :   activitiesCreatedBy  } });
           }}
         />
         </div>
         <div className='checkboxesItem'>
           <CheckboxesComponent
             idRef='ActivitiesCreatedByViewMyBranchActivitiesRef'
             label={t(`${translationPath}view-my-branch-activities`)}
             parentTranslationPath={parentTranslationPath}
             translationPathForData={translationPath}
             singleChecked={state.activities.createdBy.myBranchUsers}
             onSelectedCheckboxClicked={(e)=> {

               const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.activities.createdBy ,  myBranchUsers : e.target.checked } );
              
               if(checkAtLeastOneSelected2(state.activities.createdBy.specificUsers));
              else if(!checkIfCanSelected)
                return ;
 
               const activitiesCreatedBy =  { ...state.activities.createdBy ,
                  allUsers : false  , 
                  myBranchUsers : (e.target.checked)
                } ;
            setState({id : 'activities' , value : { ...state.activities , createdBy :   activitiesCreatedBy  } });
           }}
         />
         </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='ActivitiesCreatedByViewMyManagedTeamActivitiesRef'
          label={t(`${translationPath}view-my-managed-team-activities`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.activities.createdBy.myManageUsers}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.activities.createdBy ,  myManageUsers : e.target.checked } );
             
              if(checkAtLeastOneSelected2(state.activities.createdBy.specificUsers));
             else if(!checkIfCanSelected)
                    return ;
       
            const activitiesCreatedBy =  { ...state.activities.createdBy ,
               allUsers : false   , 
               myManageUsers  : (e.target.checked)  ,
             } ;
          setState({id : 'activities' , value : { ...state.activities , createdBy :   activitiesCreatedBy  } });
         }}
         />
         </div>
         <div className='checkboxesItem'>
          <CheckboxesComponent
            idRef='ActivitiesCreatedByViewTeamLeadUsersRef'
            label={t(`${translationPath}view-my-team-lead-users`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={state.activities.createdBy.myTeamLeadUsers}
            onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.activities.createdBy ,  myTeamLeadUsers : e.target.checked } );
             
              if(checkAtLeastOneSelected2(state.activities.createdBy.specificUsers));
             else if(!checkIfCanSelected)
                    return ;
                
              const activitiesCreatedBy =  { ...state.activities.createdBy ,
                 allUsers : false  , 
                 myTeamLeadUsers : (e.target.checked)
               } ;
            setState({id : 'activities' , value : { ...state.activities , createdBy :   activitiesCreatedBy  } });
           }}
          />
         </div>
        
         <div className='w-100 px-2 mt-2 mb-2'>
          <AutocompleteComponent
              idRef='activitiesCreatedBySpecificsUsersRef'
              labelValue={t(`${translationPath}specific_users`)}
              data={ allUsers || []}
              selectedValues={state.activities.createdBy.specificUsers}
              multiple
              displayLabel={(option) =>
              (option && option.fullName) || ''}
              chipsLabel={(option) => (option && option.fullName) || ''}
              withoutSearchButton
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                 if (searchTimer.current) clearTimeout(searchTimer.current);
                   searchTimer.current = setTimeout(() => {
                    getAllOrganizationUserSearch(value) ;  
                }, 700); 
                }}
                renderOption={
                  ((option) =>
                  <div className='d-flex-column'>
                   <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                    {option.fullName}
                   </div>
                   <span className='c-gray-secondary'> {option.branch} </span>
                 </div>
               )}
              value={state.activities.createdBy.specificUsers}
              onChange={(e, newValues) => 
                {
                  let activitiesCreatedBy = { ...state.activities.createdBy  , specificUsers : newValues };
                  if( !newValues.length && setDefaultSelected({ ...state.activities.createdBy , specificUsers :false }))
                  activitiesCreatedBy = { ...state.activities.createdBy  , specificUsers : newValues , allUsers : true }
                    
                  setState({ id: 'activities', value: { ...state.activities , createdBy  : activitiesCreatedBy } })
                }}
             isLoading={isLoading} 
             withLoader
             filterOptions={(options) => {
              const isFind = (id) => state.activities.createdBy.specificUsers.findIndex((w) => w.id === id) === -1;
             return options.filter((w) => isFind(w.id));
            }}
            isDisabled={state.activities.createdBy.allUsers}
         /> 
        </div>
    </div>
    </div>
  );
};

ActivitiesCreatedBy.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state:PropTypes.instanceOf(Object).isRequired,
  setState : PropTypes.func.isRequired,
  checkAtLeastOneSelected :PropTypes.func.isRequired, 
  checkAtLeastOneSelected2  :PropTypes.func.isRequired,
  setDefaultSelected :PropTypes.func.isRequired,

};

