


import React , { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TabsComponent } from '../../../../../../../Components' ; 
import { ActivitiesPoliciesTabs } from './ActivitiesPoliciesTabs' ; 

    
export const ActivitiesPolicies  = ({
  parentTranslationPath,
  translationPath,
  state,
  setState , 
  checkAtLeastOneSelected, 
  checkAtLeastOneSelected2 ,
  setDefaultSelected , 
}) => {
    const { t } = useTranslation(parentTranslationPath);
    
    const [activeTab, setActiveTab] = useState(0);

    const onTabChanged = (e, newTap) => {
      setActiveTab(newTap);
    }

    return (
      <div className='childs-wrapper b-0 part'>
           <TabsComponent
            data={ActivitiesPoliciesTabs}
            labelInput='label'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            themeClasses='theme-curved'
            dynamicComponentProps={{
            parentTranslationPath,
            translationPath,
            state,
            setState , 
            checkAtLeastOneSelected, 
            checkAtLeastOneSelected2 ,
            setDefaultSelected ,
          }}
          currentTab={activeTab}
          onTabChanged={onTabChanged}
        /> 
      </div> 

);
};

ActivitiesPolicies.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state:PropTypes.instanceOf(Object).isRequired,
  setState : PropTypes.func.isRequired,
  checkAtLeastOneSelected :  PropTypes.func.isRequired, 
  checkAtLeastOneSelected2 :  PropTypes.func.isRequired, 
  setDefaultSelected : PropTypes.func.isRequired, 
};

