import React from "react";
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { GlobalOrderFilterActions } from '../../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import { useSelector, useDispatch } from 'react-redux';
import { SelectComponet  } from "../../../../Components";

export const DevelopersCreatedOnFilter = ({
  translationPath,
  parentTranslationPath,
  orderFilter,
  selectedOrderBy , 
  setOrderBy  , 
  setSelectedOrderBy , 
  orderBy ,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const dispatch = useDispatch();

  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };
  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        zeroMatchingSale: {
          ...orderFilter.zeroMatchingSale,
          filterBy: selectedOrderBy.filterBy,
          orderBy: selectedOrderBy.orderBy,
        },
      })
    );
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
  };

  return (
    <>
        <div className='d-flex px-2'>
                <span className='mx-2 mt-1'>
                  {t(`${translationPath}developers`)}
                </span>
                <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
                <span className='px-2 d-flex'>
                  <span className='texts-large mt-1'>
                    {t(`${translationPath}order-by`)}:
                  </span>
                  <div className='px-2'>
                    <SelectComponet
                      idRef='select-filter-byfilterByRef'
                      data={[
                        { id: 'CreatedOn', filterBy: 'created-on' },
                        { id: 'UpdateOn', filterBy: 'last-updated' },
                      ]}
                      value={selectedOrderBy.filterBy}
                      onSelectChanged={filterByChanged}
                      wrapperClasses='mb-3'
                      isRequired
                      valueInput='id'
                      textInput='filterBy'
                      emptyItem={{
                        value: null,
                        text: 'select-filter-by',
                        isDisabled: false,
                      }}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                    />
                  </div>
                  <div className='px-2'>
                    <SelectComponet
                      idRef='orderByRef'
                      data={[
                        { id: 1, orderBy: 'ascending' },
                        { id: 2, orderBy: 'descending' },
                      ]}
                      value={selectedOrderBy.orderBy}
                      onSelectChanged={orderByChanged}
                      wrapperClasses='mb-3'
                      isRequired
                      valueInput='id'
                      textInput='orderBy'
                      emptyItem={{
                        value: null,
                        text: 'select-sort-by',
                        isDisabled: false,
                      }}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                    />
                  </div>
                  <div className='mt-1'>
                    <ButtonBase
                      className='btns theme-solid'
                      id='action_apply'
                      onClick={orderBySubmitted}
                      disabled={
                        !selectedOrderBy.filterBy || !selectedOrderBy.orderBy
                      }
                    >
                      <span>{t(`${translationPath}apply`)}</span>
                    </ButtonBase>
                  </div>
                </span>
              </div>
    </>
  );
};
