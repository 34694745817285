import ChecklistIcon from '../../../../assets/images/Propxicon/trans-documentation.svg';
import SummaryIcon from '../../../../assets/images/Propxicon/Document-Checklist.svg';
import ExcutionIcon from '../../../../assets/images/Propxicon/trans-excution.svg';
import contractIssuanceIcon from '../../../../assets/images/Propxicon/trans-ontract-issuance.svg';
import PaymentIcon from '../../../../assets/images/Propxicon/trans-Payment.svg';
import ReservationsIcon from '../../../../assets/images/Propxicon/trans-reservations.svg';
import OfferDispatchIcon from '../../../../assets/images/Propxicon/trans-Offer-dispatch.svg';
import TenantSelectionIcon from '../../../../assets/images/Propxicon/trans-Tenant-Selection.svg';
import { UnitsStatusEnum } from "../../../../Enums";
import { TenantSelection } from "./TenantSelection/TenantSelection";
import { OfferLetterSignOff } from "./OfferLetterSignOff/OfferLetterSignOff";
import { UnitReservationView } from "./UnitReservation/UnitReservationView";
import { PaymentDetailsView } from "./PaymentDetails/PaymentDetailsView";
import { TransactionExecutionView } from "./TransactionExecution/TransactionExecutionView";
import { ContractIssuance } from './ContractIssuance/ContractIssuance';
import { TransactionSummary } from './TransactionSummary/TransactionSummary';
import { DocumentsChecklist } from './DocumentsChecklist/DocumentsChecklist';

export const TransactionStepsData = {
  TenantSelection: {
    id: 1,
    key: 'TenantSelection',
    title: 'tenant-selection',
    subTitle: 'tenant-selection-heading',
    description: '',
    icon: TenantSelectionIcon,
    component: TenantSelection,
    submitButton: 'Next',
    isPassed: false,
    unitStatus: [UnitsStatusEnum.Available.name],
  },
  OfferLetterDispatch: {
    id: 2,
    key: 'OfferLetterDispatch',
    title: 'Offer-letter-dispatch',
    subTitle: 'Offer-letter-dispatch-heading',
    description: 'Offer-letter-dispatch-description',
    icon: OfferDispatchIcon,
    component: OfferLetterSignOff,
    submitButton: 'Next',
    isPassed: false,
    unitStatus: [UnitsStatusEnum.UnderOffer.name],
  },
  UnitReservations: {
    id: 3,
    key: 'UnitReservations',
    title: 'Unit-reservations',
    subTitle: 'Unit-reservations-heading',
    description: 'Unit-reservations-description',
    icon: ReservationsIcon,
    component: UnitReservationView,
    submitButton: 'Next',
    isPassed: false,
    unitStatus: [UnitsStatusEnum.OfferSigned.name, UnitsStatusEnum.PendingReservedLease.name, UnitsStatusEnum.PendingReserveLease.name],
  },
  PaymentDetails: {
    id: 4,
    key: 'PaymentDetails',
    title: 'Payment-Details',
    subTitle: 'Payment-Details-heading',
    description: 'Payment-Details-description',
    icon: PaymentIcon,
    component: PaymentDetailsView,
    submitButton: 'Next',
    isPassed: false,
    unitStatus: [UnitsStatusEnum.ReservedLeased.name],
  },
  ContractIssuance: {
    id: 5,
    key: 'ContractIssuance',
    title: 'Contract-Issuance',
    subTitle: 'Contract-Issuance-heading',
    description: 'Contract-Issuance-description',
    icon: contractIssuanceIcon,
    component: ContractIssuance,
    submitButton: 'Next',
    isPassed: false,
    unitStatus: [UnitsStatusEnum.UnderContract.name, UnitsStatusEnum.ReservedLeased.name],
  },
  TransactionExecution : {
    id: 6,
    key: 'TransactionExecution',
    title: 'Transaction-Execution',
    subTitle: 'Transaction-Execution-heading',
    description: 'Transaction-Execution-description',
    icon: ExcutionIcon,
    component: TransactionExecutionView,
    submitButton: 'Next',
    isPassed: false,
    unitStatus: [UnitsStatusEnum.ContractSigned.name, UnitsStatusEnum.PendingLeased.name,  UnitsStatusEnum.PendingLease.name],
  },
  TransactionSummary: {
    id: 7,
    key: 'TransactionSummary',
    title: 'Transaction-summary',
    subTitle: 'Transaction-summary-heading',
    description: 'Transaction-summary-description',
    icon: SummaryIcon,
    component: TransactionSummary,
    submitButton: 'Next',
    isPassed: false,
    unitStatus: [UnitsStatusEnum.Leased.name],
  },
  DocumentChecklist: {
    id: 8,
    key: 'DocumentChecklist',
    title: 'Document-Checklist',
    subTitle: 'Document-Checklist-heading',
    description: 'Document-Checklist-description',
    icon: ChecklistIcon,
    component: DocumentsChecklist,
    submitButton: 'Finish',
    isPassed: false,
    unitStatus: [UnitsStatusEnum.Leased.name],
  },
}