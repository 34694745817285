import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    scheduleContainer: {
      display: "flex",
      justifyContent: "center",
      minHeight: "200px",
      position: "relative",
    },
    heading: {
      marginBottom: "12px", 
      display: "flex", 
      gap: "4px", 
      alignItems: "center",
    },
    eventContent: {
      fontSize: "14px",
      lineHeight: "20px",
      color: theme.palette.text.secondary,
    },
    eventContentWrapper: {
      border: `1px solid ${theme.palette.border.secondary}`,
      borderRadius: theme.borderRadius[4],
      boxShadow: theme.shadows[3],
      padding: "12px",
      marginBottom: "8px",
      display: "flex",
      flexDirection: "column",
      gap: "4px",
    },
    eventTitle: {
      fontWeight: 500,
    },
    date: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "20px",
      color: theme.palette.foreground.quarterary,
    }
  }
});
