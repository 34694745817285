import React, { useCallback, useEffect, useState } from 'react';
import { PermissionsComponent, Spinner, Tables } from '../../../../Components';
import { ButtonBase } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import {
  GetInvoicesById,
  GetReceiptsByInvoiceId,
  GetRemainingBalanceByInvoiceId,
} from '../../../../Services';
import { ReceiptsHeaderData } from './ReceiptsHeaderData';
import { InvoicesManagementDialog } from './Dialogs/InvoicesManagementDialog';
import { useTranslation } from 'react-i18next';
import { InvoicesPermissions } from '../../../../Permissions';
import './ReceiptsView.scss';
import { CopyToClipboardComponents } from '../../../../ReusableComponents/UtilityComponents';
import { useLocation, useHistory } from 'react-router-dom';
import { TableActions } from '../../../../Enums';
import { MediaPreviewDialog } from '../../TemplatesView/Dialogs';
import { GetParams, GlobalHistory } from '../../../../Helper';

export const ReceiptsPerInvoiceView = ({
  handleChangeView,
  state,
  activeItem,
  setSelected,
  selected,
  setState,
  setTotalAmountPaid,
  onStateChanged,
  parentTranslationPath,
  translationPath,
  onSelectedChanged,
  defaultSelected,
  defaultState,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [allReceipts, setAllReceipts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false);
  const [activeItem2, setActiveItem2] = useState(null);
  const invoiceId = GetParams('invoiceId') ?? activeItem?.invoiceId;
  const [remainingBalance, setRemainingBalance] = useState(null);
  const [invoiceState, setInvoiceState] = useState(null);
  let location = useLocation();
  const history = useHistory();

  const isRedirected = location?.state?.invoiceId;

  const getAllReceipts = async () => {
    setIsLoading(true);
    const res = await GetReceiptsByInvoiceId({
      invoiceId,
    });
    if (!(res && res.status && res.status !== 200)) {
      setAllReceipts(res);
    }
    setIsLoading(false);
  };

  const getRemainingBalance = async () => {
    setIsLoading(true);
    const res = await GetRemainingBalanceByInvoiceId({
      invoiceId,
    });
    if (!(res && res.status && res.status !== 200)) {
      setRemainingBalance(res);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllReceipts();
    getRemainingBalance();
    getInvoicesById();
  }, []);

  const reload = () => {
    getAllReceipts();
    getRemainingBalance();
    getInvoicesById();
  };

  const handleBackNavigation = () => {
    // history.push('/home/sales-transactions/view');
    GlobalHistory.goBack();
  };

  const getInvoicesById = async () => {
    setIsLoading(true);
    const res = await GetInvoicesById(invoiceId);
    if (!(res && res.status && res.status !== 200)) {
      setInvoiceState(res.invoiceStateName);
      setIsLoading(false);
    }
  };

  const tableActionClicked = useCallback((actionKey, item) => {
    if (actionKey === TableActions.preview.key) {
      setActiveItem2({
        templateFileId: item?.receiptDocuments?.map((file) => file?.fileId)[0],
        templateFileName: item?.receiptDocuments?.map(
          (file) => file?.fileName
        )[0],
      });
      setIsOpenPreviewDialog(true);
    }
  }, []);

  return (
    <div className='px-3 receipts-view-wrapper'>
      <Spinner isActive={isLoading} />
      <div className='title-section'>
        <ButtonBase
          onClick={isRedirected ? handleBackNavigation : handleChangeView}
          className='btns mx-2 theme-solid bg-cancel'
        >
          <ArrowBackIos fontSize='small' />
          {'back'}
        </ButtonBase>
        <span>{`invoices`}</span>
      </div>
      <div className='section'>
        <PermissionsComponent
          permissionsList={Object.values(InvoicesPermissions)}
          permissionsId={InvoicesPermissions.AddNewReceipt.permissionsId}
        >
          <ButtonBase
            className='btns theme-solid px-3'
            onClick={() => setOpenDialog(true)}
          >
            <span className='mdi mdi-plus' />
            {t(`${translationPath}add-new-receipt`)}
          </ButtonBase>
        </PermissionsComponent>
      </div>
      <div className='invoice-container'>
        <div className='section1-invoice'>
          <div className='header-item'>
            <span className='header-title'>bank</span>
            <span className='header-title'>Invoice #</span>
            <span className='header-title'>Unit Ref</span>
            <span className='header-title'>invoice State</span>
          </div>
          <div className='value-item'>
            <span className='value-name'>{activeItem?.bank}</span>

            <span className='value-name'>
              <CopyToClipboardComponents
                data={invoiceId}
                childrenData={invoiceId}
              />
            </span>
            <span className='value-name'>{activeItem?.unitRefNo}</span>
            <span className='value-name'>{invoiceState}</span>
          </div>
        </div>
        <div className='section2-invoice'>
          <div className='header-item'>
            <span className='header-title'>Payment Type</span>
            <span className='header-title'>Payment Mode</span>
            <span className='header-title'>Due On</span>
            <span className='header-title'>Remarks</span>
            <span className='header-title'>Net Amount AED</span>
          </div>
          <div className='value-item'>
            <span className='value-name'>{activeItem?.paymentTypeName}</span>
            <span className='value-name'>{activeItem?.paymentModeName}</span>
            <span className='value-name'>{activeItem?.dueOn}</span>
            <span className='value-name'>{activeItem?.remarks}</span>
            <span className='value-name'>{activeItem?.amountDue}</span>
          </div>
        </div>
      </div>

      <div className='remaining-balance'>
        <span className='title'>Remaining Balance : {remainingBalance}</span>
      </div>
      <div className='tables-wrapper'>
        <Tables
          data={allReceipts || []}
          headerData={ReceiptsHeaderData}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          defaultActions={[
            {
              enum: TableActions.preview.key,
            },
          ]}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          itemsPerPage={50}
          FilterDisabledButton
          activePage={0}
        />
      </div>

      {openDialog && (
        <InvoicesManagementDialog
          state={state}
          open={openDialog}
          setState={setState}
          selected={selected}
          onSave={() => {
            setOpenDialog(false);
            setState({
              id: 'edit',
              value: {
                ...defaultState,
                invoicesIds: state?.invoicesIds,
              },
            });
            setSelected({
              id: 'edit',
              value: {
                ...defaultSelected,
                invoicesIds: selected?.invoicesIds,
              },
            });
            setTotalAmountPaid([]);
          }}
          close={() => {
            setOpenDialog(false);
            setState({
              id: 'edit',
              value: {
                ...defaultState,
                invoicesIds: state?.invoicesIds,
              },
            });
            setSelected({
              id: 'edit',
              value: {
                ...defaultSelected,
                invoicesIds: selected?.invoicesIds,
              },
            });
            setTotalAmountPaid([]);
          }}
          onStateChanged={onStateChanged}
          translationPath={translationPath}
          onSelectedChanged={onSelectedChanged}
          setTotalAmountPaid={setTotalAmountPaid}
          parentTranslationPath={parentTranslationPath}
          reload={reload}
          activeItem={activeItem}
        />
      )}
      {isOpenPreviewDialog && (
        <MediaPreviewDialog
          activeItem={activeItem2}
          maintitleText='FILE-VIEW'
          isOpen={isOpenPreviewDialog}
          isOpenChanged={() => {
            setIsOpenPreviewDialog(false);
            setActiveItem2(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </div>
  );
};
