import { config } from "../config";
import { HttpServices } from "../Helper";

export const CreateFavoriteFolders = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/FavoriteFolders/CreateFavoriteFolder`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetAllFavoriteFolders = async ({ favoriteFolderType, search }) => {
  const queryList = [];
  if (search) queryList.push(`search=${search}`);

  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/FavoriteFolders/GetAllFavoriteFolders/${favoriteFolderType}?${queryList.join(
      "&"
    )}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const DeleteFavoriteFolder = async (favoriteFolderId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/FavoriteFolders/DeleteFavoriteFolder/${favoriteFolderId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const StoreFavoriteUnits = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/FavoriteFolders/StoreFavoriteUnits`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const StoreFavoriteLeads = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/FavoriteFolders/StoreFavoriteLeads`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const UpdateFavoriteFolder = async (body) => {
  const result = await HttpServices.patch(
    `${config.server_address}/CrmDfm/FavoriteFolders/UpdateFavoriteFolder`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const RemoveLeadsOrUnitsFromFolder = async (type, ids, folderId) => {
  const endpoint = type === 'LEAD' ? 'RemoveLeadsFromFolder' : 'RemoveUnitsFromFolder';
  const body = type === 'LEAD' 
    ? { leadIds: ids, folderId: folderId }
    : { unitIds: ids, folderId: folderId };

  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/FavoriteFolders/${endpoint}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  
  return result;
};