import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import {
    GetParams, showError, showSuccess,
} from '../../../../Helper';
import { TableActions } from '../../../../Enums';
import { GetConvoloRotationAgentsAPI  , UpdateAgentStatusInConvoloRotationAPI  } from '../../../../Services';
import { Spinner, Tables, SwitchComponent } from '../../../../Components';
import { AssignConvoloAgentsDialog } from './AssignConvoloAgentsDialog' ; 
import { DeleteAgentFromRotationSchemeDialog } from './DeleteAgentFromRotationSchemeDialog' ; 

export const AssignConvoloAgentTable = () => {
    const parentTranslationPath = 'Agents';
    const translationPath = '';
    const { t } = useTranslation(parentTranslationPath);
    const [convoloRotationSchemeId , setConvoloRotationSchemeId] = useState(+GetParams('id'))
    const [activeItem, setActiveItem] = useState(null);
    const [isOpenAssignConvoloAgentsDialog , setIsOpenAssignConvoloAgentsDialog] = useState(false) ; 
    const [isOpenDeleteConvoloAgentsDialog , setIsOpenDeleteConvoloAgentsDialog] = useState(false) ; 
    const [timer, setTimer] = useState(null);
    const [searchItem, setSearchItem] = useState(null);
    const [convoloAgents, setConvoloAgents] = useState([]);
   
    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState({
        id: GetParams('id'),
        pageSize: 50,
        pageIndex: 0,
        agentName: null,
    });

    const onPageIndexChanged = (pageIndex) => {
        setFilter((item) => ({ ...item, pageIndex }));
    };
    const onPageSizeChanged = (pageSize) => {
        setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    };

    const getActionTableWithPermissions  = () => {
        let list = [];
          list.push({
            enum: TableActions.deleteText.key,
            title: t(`${translationPath}Shared:delete`),
          });
        return list;
      };

      const tableActionClicked = useCallback((actionEnum, item) => {
        setActiveItem(item);
         if (actionEnum === TableActions.deleteText.key) {
            setIsOpenDeleteConvoloAgentsDialog(true);
    
        }
      }, []);

    const getAllAgent = useCallback(async () => {
        setIsLoading(true);
        const res = await GetConvoloRotationAgentsAPI(filter.id);
        if (!(res && res.status && res.status !== 200))
            setConvoloAgents(res);
        else setConvoloAgents([]);

        setIsLoading(false);
    }, [filter]);


    const UpdateAgentStatusInConvoloRotation = useCallback(async (agentId) => {
        setIsLoading(true);
        const res = await UpdateAgentStatusInConvoloRotationAPI(convoloRotationSchemeId ,agentId );
        if (!(res && res.status && res.status !== 200))
        {
            showSuccess(t('update-agent-status-in-convolo-rotation-sucessfully'));
            setFilter((item)=> ({...item , pageIndex : 0 }));
        }
        else  
        showError(t('update-agent-status-in-convolo-rotation-failed'));
        setIsLoading(false);
    });

    useEffect(() => {
        getAllAgent();
    }, [getAllAgent]);

    return (

      <div className='assign-agent-wrapper m-3'>
        <Spinner isActive={isLoading} />
            <div className='d-flex-h-between'>
                <ButtonBase
                    className='btns theme-solid px-3 m-3'
                    onClick={() => { setIsOpenAssignConvoloAgentsDialog(true); }}
                    id='bbt-new-assign-agent'
                >
                    <span className='mdi mdi-plus' />
                    <span>{t(`${translationPath}new-assign-agent`)}</span>
                </ButtonBase>
            </div>    
         <Tables
          data={convoloAgents || []}
          headerData={[
                   {
                     id: 1,
                     label: 'agentId',
                     input: 'agentId',
                    },
                    {
                        id: 2,
                        label: 'agentName',
                        input: 'agentName',
                    },
                    {
                        id: 3,
                        label: 'createdBy',
                        input: 'addedBy',
                    },
                    {
                        id: 4,
                        label: `${t('agent-status')}`,
                        input: 'isActive',
                        component: (item, index) => (
                          <>
                            <SwitchComponent
                              idRef={`isActiveRef${index + 1}`}
                              isChecked={item.isActive || false}
                              labelClasses='px-0'
                              onChangeHandler={(e, checkedValue) => {
                                    UpdateAgentStatusInConvoloRotation(item.agentId);
                                    }}
                              themeClass='theme-line'
                              labelValue={(item.isActive && `${t('Active')}`) || `${t('Deactive')}`}
                              parentTranslationPath={parentTranslationPath}
                              translationPath={translationPath}
                            />

                          </>
                        )
                    },
                    {
                        id: 5,
                        label: 'agentEmail',
                        input: 'agentEmail',
                    },
                    {
                        id: 6,
                        label: 'agentMobile',
                        input: 'agentMobile',
                    },
                    {
                        id: 7,
                        label: 'callsNumber',
                        input: 'callsNumber',
                    },

                ]}
                defaultActions={getActionTableWithPermissions()}
                actionsOptions={{
                onActionClicked: tableActionClicked}}
                onPageIndexChanged={onPageIndexChanged}
                onPageSizeChanged={onPageSizeChanged}
                itemsPerPage={filter.pageSize}
                activePage={filter.pageIndex}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                totalItems={(convoloAgents && convoloAgents.length) || 0}
             />

     {isOpenAssignConvoloAgentsDialog && (
        <AssignConvoloAgentsDialog
          isOpen={isOpenAssignConvoloAgentsDialog}
          onClose={()=> {
            setIsOpenAssignConvoloAgentsDialog(false) ; 
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onSave={()=> {
            setIsOpenAssignConvoloAgentsDialog(false) ; 
            getAllAgent();
          }}
          convoloRotationSchemeId={convoloRotationSchemeId}


        />
      )}

     {isOpenDeleteConvoloAgentsDialog && (
        <DeleteAgentFromRotationSchemeDialog
          isOpen={isOpenDeleteConvoloAgentsDialog}
          onClose={()=> {
            setIsOpenDeleteConvoloAgentsDialog(false) ; 
            setActiveItem(null)  ;

          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onSave={()=> {
            setIsOpenDeleteConvoloAgentsDialog(false) ;
            setActiveItem(null)  ;
            getAllAgent();
          }}
          activeItem={activeItem}


        />
      )}
      </div>
    );
};
