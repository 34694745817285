import React, { useCallback, useEffect, useReducer, useState } from "react";
import { GetAMLTrackedTransactionDocuments } from "../../../../Services";
import {
  DialogComponent,
  PermissionsComponent,
  Tables,
} from "../../../../Components";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ButtonBase } from "@material-ui/core";
import { TableActions } from "../../../../Enums";
import { AMLViewPermissions } from "../../../../Permissions";
import {
  getDownloadableLink,
  returnPropsByPermissions,
} from "../../../../Helper";
import AMLDocumentsUploader from "./AMLDocumentsUploader";
import ConfirmAMLDocumentDeleteDialog from "./ConfirmAMLDocumentDeleteDialog";

function AMLDocumentsDialog({
  IDS,
  isOpen,
  translationPath,
  parentTranslationPath,
  isAMLDocumentsDialogOpen,
  setIsAMLDocumentsDialogOpen,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [AMLDocuments, setAMLDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeItem, setActiveItem] = useState({});
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openAMLDocumentUploaderDialog, setOpenAMLDocumentUploaderDialog] =
    useState(false);

  const GetAMLTrackedTransactionDocumentsAPICall = async (IDS) => {
    setIsLoading(true);
    const res = await GetAMLTrackedTransactionDocuments(IDS);
    if (!(res && res.status && res.status !== 200)) {
      setAMLDocuments(res);
    } else {
      setAMLDocuments([]);
    }
    setIsLoading(false);
  };

  const tableActionClicked = useCallback((actionEnum, item) => {
    if (actionEnum === TableActions?.downloadText?.key) {
      try {
        const e = getDownloadableLink(item.fileId);
        window.open(e);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    } else if (actionEnum === TableActions.delete.key) {
      setOpenConfirmDialog(true);
      setActiveItem(item);
    }
  }, []);

  const getTableActionsWithPermissions = () => {
    const actionsList = [];
    if (
      returnPropsByPermissions(
        AMLViewPermissions?.DownloadDocument?.permissionsId
      )
    )
      actionsList.push({
        enum: TableActions.delete.key,
      });
    if (
      returnPropsByPermissions(
        AMLViewPermissions?.DownloadDocument?.permissionsId
      )
    )
      actionsList.push({
        enum: TableActions.downloadText.key,
      });
    
    return actionsList;
  };

  const openAMLDocumentsUploader = () => {
    setOpenAMLDocumentUploaderDialog(true);
  };

  const onSaveClicked = () => {
    setIsAMLDocumentsDialogOpen(false);
  };
  const onCloseClicked = () => {
    setIsAMLDocumentsDialogOpen(false);
  };

  useEffect(() => {
    GetAMLTrackedTransactionDocumentsAPICall(IDS);
  }, [openAMLDocumentUploaderDialog, setOpenAMLDocumentUploaderDialog , openConfirmDialog, setOpenConfirmDialog]);

  return (
    <>
      <DialogComponent
        titleText={t("aml-documents")}
        saveText={t("confirm")}
        saveType="button"
        maxWidth="md"
        isOpen={isOpen}
        onSaveClicked={() => {
          onSaveClicked();
        }}
        onCloseClicked={() => {
          onCloseClicked();
        }}
        onCancelClicked={() => {
          onCloseClicked();
        }}
        dialogContent={
          <>
            <div className="mt-2">
              <div className="w-50">
                <div className="d-flex-column mt-2">
                  <h4 className="mb-2">
                    <b>{t("AML")}</b>
                  </h4>
                  <PermissionsComponent
                    permissionsList={Object.values(AMLViewPermissions)}
                    permissionsId={
                      AMLViewPermissions?.UploadDocument?.permissionsId
                    }
                  >
                    <ButtonBase
                      id="uploadAMLDocumentsButtonRef"
                      className="MuiButtonBase-root btns theme-solid  w-50 mb-2"
                      onClick={() => {
                        openAMLDocumentsUploader();
                      }}
                    >
                      <span>+ {t("upload-documents")}</span>
                    </ButtonBase>
                  </PermissionsComponent>
                </div>
              </div>

              <div>
                <Tables
                  data={AMLDocuments || []}
                  headerData={[
                    {
                      id: 1,
                      label: "category",
                      component: (item) => {
                        return (
                          <>
                            <div className="flex-column">
                              <h6>
                                <b>{item?.category}</b>
                              </h6>
                              <p>{item?.fileName}</p>
                            </div>
                          </>
                        );
                      },
                    },
                    {
                      id: 2,
                      label: "create-date",
                      component: (item) => {
                        return (
                          <>
                            <div className="flex-column">
                              <h6>
                                <b>
                                  {(item.createdOn &&
                                    moment(item?.createdOn).format(
                                      "DD/MM/YYYY - hh:mm A"
                                    )) ||
                                    "N/A"}
                                </b>
                              </h6>
                              <p>{item?.createdBy}</p>
                            </div>
                          </>
                        );
                      },
                    },
                  ]}
                  defaultActions={getTableActionsWithPermissions()}
                  actionsOptions={{
                    onActionClicked: tableActionClicked,
                  }}
                  activePage={0}
                  itemsPerPage={150}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
              {openAMLDocumentUploaderDialog && (
                <AMLDocumentsUploader
                  IDS={IDS}
                  isOpen={openAMLDocumentUploaderDialog}
                  onCloseClicked={() => setOpenAMLDocumentUploaderDialog(false)}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              )}
              {openConfirmDialog && (
                <ConfirmAMLDocumentDeleteDialog
                  isOpen={openConfirmDialog}
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                  setOpenConfirmDialog={setOpenConfirmDialog}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              )}
            </div>
          </>
        }
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
      />
    </>
  );
}

export default AMLDocumentsDialog;
