import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    containerCard_Assign_AgentSkeleton:{
        width:'314px',
        display:'flex',
        flexDirection:'row',
        gap:'16px',
        padding:'20px 16px',
    },container_Avatar_PersonalInfo:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        gap:'16px'
    },containerPersonalInfo:{
        display:'flex',
        flexDirection:'column',
    }
  }
})