
export const PaymentDetailsTabsData = {
  PaymentInvoices: {
    id: 1,
    key: "PaymentInvoices",
    title: "",
    form: ""
  },
  InvoiceDetails: {
    id: 2,
    key: "InvoiceDetails",
    title: "Invoice",
    form: ""
  },
  InvoiceReceipt: {
    id: 3,
    key: "InvoiceReceipt",
    title: "Receipt",
    form: ""
  },
};
