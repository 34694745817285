export const UserOperationTypeEnum = {
  Sale: {
    id: 1,
    title: 'Sale',
  },
  Listing: {
    id: 2,
    title: 'Listing',
  },
  Leasing: {
    id: 3,
    title: 'Leasing',
  },
};
