import { SystemNotificationsView } from "../../Views/Home/SystemNotifications/SystemNotificationsView";


export const SystemNotificationsViewRoutes = [
    {
        path: '/view',
        name: 'SystemNotifications',
        component: SystemNotificationsView,
        layout: '/home/system-notifications',
        default: true,
        isRoute: true,
        authorize: true,
        roles: [],
        isDisabled: false,
        isExact: true,
        breadcrumbs: [
          {
            name: 'system-notifications',
            isDisabled: true,
            route: '/home/system-notifications',
            groupName: 'system-notifications',
          },
        ],
      } , 
  
    
    ];