import React from "react";
import moment from "moment";
import { CopyToClipboardComponents } from "../../../../ReusableComponents/UtilityComponents";

export const ConsentLogHeadData = [
  {
    id: 1,
    label: "log-id",
    input: "logId",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 2,
    label: "consent-channel",
    input: "consentChannel",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 3,
    label: "sent-by",
    input: "senderName",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 4,
    label: "sender-branch-name",
    input: "senderBranchName",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 5,
    label: "receiver-name",
    input: "receiverName",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 6,
    label: "receiver-email",
    input: "receiverEmail",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 7,
    label: "receiver-mobile-number",
    input: "receiverMobileNumber",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 8,
    label: "receiver-whatsapp-number",
    input: "receiverWhatsAppNumber",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 9,
    label: "consent-sent-date",
    input: "consentSentDate",
    isHiddenFilter: true,
    isSortable: false,
    isDate: true,
  },
  {
    id: 10,
    label: "consent-sent-time",
    isHiddenFilter: true,
    component: (item) => (
      <span>
        {item?.consentSentDate ? moment(item.consentSentDate).format("LT") : ""}
      </span>
    ),
  },
  {
    id: 11,
    label: "consent-response-date",
    input: "consentResponseDate",
    isHiddenFilter: true,
    isSortable: false,
    isDate: true,
  },
  {
    id: 12,
    label: "response-received",
    input: "responseReceived",
    isHiddenFilter: true,
    isSortable: false,
  },
];
