import { Button, Fab, Tooltip } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector , useDispatch  } from 'react-redux';
import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';
import {
  AutocompleteComponent, DatePickerComponent, Inputs, SelectComponet
} from '../../../../Components';
import { TableFilterOperatorsEnum } from '../../../../Enums';
import { GetAllFormFieldsByFormId } from '../../../../Services';
import {
  DateTableFilterOperatorsEnum,
  listTree,
  listTree2,
  TextTableFilterOperatorsEnum,
  NumberTableFilterOperatorsEnum,
  listTreeProperties,
  listTreContacts
} from '../ReportsBuilderEnums/ReportsBuilderEnums';
import { GetParams } from '../../../../Helper';

export const FiltersDialogView = ({
  translationPath,
  parentTranslationPath,
  Data,
  setBodyFilter,
  BodyFilter,
  setSelectDataTemp,
  SelectDataTemp,
  AcationStateIsEdit,
  onCancelClicked,
  RunClearFilter,
  onSaveClicked,
  HaveChange
}) => {

  const { t } = useTranslation(parentTranslationPath);
  const ActiveItemReducer = useSelector((state) => state.ActiveItemReducer);
  const dispatch = useDispatch();
  const [isLoadingProgress, setIsLoadingProgress] = useState(false);
  const [stateForm, setStateForm] = useState([]);
  const [valtionForm, setValtionForm] = useState([]);
  const [formWithAngryBrid ,  setFormWithAngryBrid] = useState(null) ;
  const [inputList, setInputList] = useState([
    {
      type: null,
      formId: null,
      operator: null,
      searchKey: null,
      value: '' || null,
      category: null,
      operand: null
    },
  ]);
  const [selectData, setSelectData] = useState([{
    operator: [],
    searchKey: [],
    systemReportForms: []
  }
  ]);
  const changeStatusType = (value, i) => {
    const list = [...inputList];
    list[i].category = (value && value.tableName) || null;
   // list[i].formId =  (GetParams('Report') === 'Contacts' && formWithAngryBrid && formWithAngryBrid.formId) ||(value && value.formId);
   list[i].formId =  (value && value.formId);
    list[i].operand = null;
    list[i].operator = null;
    // list[i].value = null;
    setInputList(list);
    const select = [...selectData];
    select[i].systemReportForms = [value];
    select[i].searchKey = [];
    select[i].operator = [];
    setSelectData(select);
  };

  const getAllFormFieldsByFormIdAPI = useCallback(async (id, name) => {
    setIsLoadingProgress(true);
    // eslint-disable-next-line prefer-const
    let result = await GetAllFormFieldsByFormId(id);
    if (!(result && result.status && result.status !== 200)) {
      listTree.map((data) => result.push({
        formFieldId: id,
        formFieldName: data.formFieldName,
        formFieldKey: data.formFieldKey,
        searchableKey: data.searchableKey,
        displayPath: data.displayPath,
        uiWidgetType: data.uiWidgetType,
        formFieldTitle: `${data.formFieldTitle} ${name || ''}`,

      }));

      if (id === 1 || 2) {
        listTreContacts.map((data) => result.push({
          formFieldId: id,
          formFieldName: data.formFieldName,
          formFieldKey: data.formFieldKey,
          searchableKey: data.searchableKey,
          displayPath: data.displayPath,
          uiWidgetType: data.uiWidgetType,
          formFieldTitle: `${data.formFieldTitle} ${name || ''}`,

        }));
      }

      if (id === 3) {
        listTreeProperties.map((data) => result.push({
          formFieldId: id,
          formFieldName: data.formFieldName,
          formFieldKey: data.formFieldKey,
          searchableKey: data.searchableKey,
          displayPath: data.displayPath,
          uiWidgetType: data.uiWidgetType,
          formFieldTitle: `${data.formFieldTitle} ${name || ''}`,

        }));
      }

      if (id === 7 || id === 6) {
        listTree2.map((data) => result.push({
          formFieldId: id,
          formFieldName: data.formFieldName,
          formFieldKey: data.formFieldKey,
          searchableKey: data.searchableKey,
          displayPath: data.displayPath,
          uiWidgetType: data.uiWidgetType,
          formFieldTitle: `${data.formFieldTitle} ${name || ''}`,

        }));
      }

      setStateForm((items) => {
        items.push({ id, name, result });
        return [...items];
      });
    } else
      setStateForm([]);
    setIsLoadingProgress(false);
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getOperatorValue = useCallback(
    (keys) =>
      (Object.values(TableFilterOperatorsEnum).find((element) => element.key === keys) &&
        Object.values(TableFilterOperatorsEnum).find((element) => element.key === keys).value) ||
      ''
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getsystemReportFormsValue = useCallback(
    (keys) =>

      // eslint-disable-next-line max-len
      (Data && Data.systemReportForms && Data.systemReportForms.find((element) => element.formId === keys) &&
        // eslint-disable-next-line max-len
        Data && Data.systemReportForms && Data.systemReportForms.find((element) => element.formId === keys).form) ||
      ''
  );

  const handleFormAdd = (value, item, index) => {
    const select = [...selectData];
    select[index].searchKey = [value];
    select[index].operator = [];
    setSelectData(select);
    const list = [...inputList];
    list[index].operand = null;
    // list[index].value = null;
    list[index].operator = null;
    list[index].searchKey = (value && value.searchableKey) || (value && value.displayPath) || null;
    list[index].type = (value && value.uiWidgetType === 'alt-date') && 'alt-date' ||
      (value && value.propertyJson && JSON.parse(value && value.propertyJson).schema) &&
      (value && value.propertyJson && JSON.parse(value && value.propertyJson).schema.specialKey) ||
      null;
    setInputList(list);
  };
  const handleDateRangePickerComponent = (value, index) => {
    const list = [...inputList];
    list[index].value = value;
    setInputList(list);
  };

  const changeoperand = (value, i) => {
    const list = [...inputList];
    list[i].operand = value;
    setInputList(list);
  };
  const handleFilterOperators = (value, item, index) => {
    const list = [...inputList];
    list[index].operator = (value && value.key) || null;
    setInputList(list);

    const select = [...selectData];
    select[index].operator = [value];
    setSelectData(select);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);

    const select = [...selectData];
    select.splice(index, 1);
    setSelectData(select);
  };
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        operator: 0,
        searchKey: '',
        type: null,
        value: '',
        formId: null,
        category: null,
        operand: null
      }
    ]);
    setSelectData([
      ...selectData,
      {
        operator: [],
        systemReportForms: [],
        searchKey: []
      }
    ]);
  };
  const handleaddValue = (value, item, index) => {
    const list = [...inputList];
    list[index].value = value;
    setInputList(list);
  };


  useEffect(() => {
    if (Data && Data && Data.systemReportForms !== undefined)
      // eslint-disable-next-line no-unused-expressions
      Data && Data.systemReportForms.map((item) => {(
        getAllFormFieldsByFormIdAPI(item.formId, item.form)) ;
        if(GetParams('Report') === 'Contacts' && item &&  item.formId && item.form && item.form.includes('Individual Contact'))  
         setFormWithAngryBrid({form : item.form , formId : item.formId}) ; 
      } 
       ); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Data]);

  useEffect(() => {
    if (SelectDataTemp && SelectDataTemp.length !== 0)
      setSelectData(SelectDataTemp);
  }, []);

  useEffect(() => {
    if (BodyFilter && BodyFilter.length !== 0)
      setInputList(BodyFilter);
  }, []);
  useEffect(() => {
    if (AcationStateIsEdit && ActiveItemReducer && ActiveItemReducer) {
      if (ActiveItemReducer && ActiveItemReducer &&
        ActiveItemReducer.systemReportConditions &&
        ActiveItemReducer.systemReportConditions.length === 0) {
        // setInputList([{
        //   type: null,
        //   operator: null,
        //   searchKey: null,
        //   value: null,
        //   formId: null,
        //   category: null,
        //   operand: null
        // }]);
      } else if (!HaveChange) {
        setInputList(
          ActiveItemReducer && ActiveItemReducer && ActiveItemReducer.systemReportConditions &&
          ActiveItemReducer.systemReportConditions.map((itemData) => ({
            type: (itemData && ((itemData && itemData.operatorId === TableFilterOperatorsEnum.greaterThanDate.key ||
              itemData && itemData.operatorId === TableFilterOperatorsEnum.lessThanDate.key ||
              itemData && itemData.operatorId === TableFilterOperatorsEnum.equalData.key
            )) && 'alt-date') || '',
            operator: itemData.operatorId,
            searchKey: itemData.conditionField,
            value: itemData.filterValue,
            formId:(GetParams('Report') === 'Contacts' && formWithAngryBrid && formWithAngryBrid.formId) ||(itemData.formId) ,
            category: itemData.fieldDisplayPath,
            operand: (itemData && itemData.logicalOperator === 1 && 'AND') ||
              (itemData && itemData.logicalOperator === 2 && 'OR') || null,
          }))
        );

        setSelectData(
          ActiveItemReducer && ActiveItemReducer && ActiveItemReducer.systemReportConditions &&
          ActiveItemReducer.systemReportConditions.map((itemData) => ({
            operator: [
              {
                key: itemData.operatorId,
                value: getOperatorValue(itemData.operatorId)
              }
            ],
            searchKey: [
              {
                formFieldId: itemData.conditionField,
                formFieldTitle: itemData.conditionField
              }
            ],
            systemReportForms: [
              {
                form: getsystemReportFormsValue(itemData.formId) ||  (GetParams('Report') === 'Contacts' && formWithAngryBrid && formWithAngryBrid.form),
                formId: (itemData && itemData.formId) || (GetParams('Report') === 'Contacts' && formWithAngryBrid && formWithAngryBrid.formId),
              }
            ],
          }))
        );
      } else {
        setInputList(
          BodyFilter &&
          BodyFilter.map((itemData) => ({
            operator: itemData.operator,
            formId: (itemData && itemData.formId) || (GetParams('Report') === 'Contacts'  && formWithAngryBrid && formWithAngryBrid.formId),
            value: itemData.value,
            operand: itemData && itemData.operand,
            searchKey: itemData.searchKey,
            category: itemData.category,
          }))
        );

        setSelectData(
          BodyFilter && BodyFilter.map((itemData) => ({
            operator: [
              {
                key: itemData.operator,
                value: getOperatorValue(itemData.operator)
              }
            ],
            searchKey: [
              {
                formFieldId: itemData.searchKey,
                formFieldTitle: itemData.searchKey
              }
            ],
            systemReportForms: [
              {
                form: getsystemReportFormsValue(itemData.formId) || (GetParams('Report') === 'Contacts' && formWithAngryBrid && formWithAngryBrid.formId),
                formId: (itemData.formId) || (GetParams('Report') === 'Contacts' && formWithAngryBrid && formWithAngryBrid.form),
              }
            ],
          }))
        );
      }
    }
  }, [ActiveItemReducer, HaveChange]);

  const validateArray = (arr) => {
    const isValid = arr.every((item) => (
      item.operator !== null &&
      item.searchKey !== null &&
      item.category !== null &&
      item.formId !== null &&
      (item.value !== '' || item.value !== null)
    ));
    setValtionForm(!isValid);
  };

  useEffect(() => {
    validateArray(inputList);
  }, [inputList]);

  useEffect(() => {
    if(GetParams('Report') === 'Contacts'  && formWithAngryBrid && formWithAngryBrid.formId)
    {
      const angryBirdIndex = inputList && inputList.findIndex((x)=> x.searchKey === 'angry_bird') ;
      const highProfilemManagementIndex = inputList && inputList.findIndex((x)=> x.searchKey === 'high_profile_management') ;

      let newList = [...inputList] ;
      if(angryBirdIndex !== -1 && highProfilemManagementIndex !== -1 )
      {
       newList[angryBirdIndex] = { ...newList[angryBirdIndex], formId:formWithAngryBrid.formId} 
       newList[highProfilemManagementIndex] = { ...newList[highProfilemManagementIndex], formId: formWithAngryBrid.formId }
      }
      setInputList(newList) ; 
    }
    }
  , [formWithAngryBrid]);

  return (
    <div className='FilterSectionView-view-wrapers'>
      <div className='Filter-title'>
        {' '}
        {t(`${translationPath}Time-Frame`)}
        {' '}
        :
      </div>
      <div className='mian-FilterSectionView'>
        {inputList && inputList.map((item, i) => (
          <div index={i} className='header-section-FilterSectionView'>
            <div className='filter-section-AgentRotation'>
              <div className='section' />
              <div className='search-agentRotation select-55'>
                <AutocompleteComponent
                  idRef={`systemReportFormsleoRef${i}`}
                  multiple={false}
                  isDisabled={item.searchKey === 'high_profile_management' || item.searchKey === 'angry_bird' }
                  data={(Data && Data.systemReportForms &&  Data.systemReportForms.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                   (t.formId) === (value.formId)))) || []) }
                  chipsLabel={(option) => option.form || ''}
                  displayLabel={(option) => option.form || ''}
                  withoutSearchButton
                  selectedValues={(selectData && selectData[i] && selectData[i].systemReportForms[0] )|| []}
                  getOptionSelected={(option) =>
                    selectData && selectData[i] && selectData[i].systemReportForms.findIndex((items) => items && items.formId === option.formId) !== -1 || ''}
                  inputPlaceholder={t(`${translationPath}formFieldTitle`)}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    changeStatusType(newValue && newValue, i);
                  }}
                />

              </div>
              <div className='search-agentRotation'>
                <AutocompleteComponent
                  idRef={`rformFieldTitleoRef${i}`}
                  multiple={false}
                  data={
                    (stateForm.find((element) => element.id === item.formId) &&
                      stateForm.find((element) => element.id === item.formId).result) ||
                    []
                  }
                  isDisabled={item.searchKey === 'high_profile_management' || item.searchKey === 'angry_bird' }
                  chipsLabel={(option) => option.formFieldTitle || ''}
                  displayLabel={(option) => option.formFieldTitle || ''}
                  withoutSearchButton
                  selectedValues={selectData && selectData[i] && selectData[i].searchKey[0] || []}
                  getOptionSelected={(option) =>
                    selectData && selectData[i] && selectData[i].searchKey.findIndex((items) => items && items.formFieldId === option.formFieldId) !== -1 || ''}
                  inputPlaceholder={t(`${translationPath}formFieldTitle`)}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    handleFormAdd(newValue && newValue, item, i);
                  }}
                />
              </div>
              <div className='search-agentRotation SMALL'>
                <AutocompleteComponent
                  idRef='refTableFilterOperatorsEnumRef'
                  multiple={false}
                  isDisabled={item.searchKey === 'high_profile_management' || item.searchKey === 'angry_bird' }
                  data={Object.values(item.type === 'alt-date' && DateTableFilterOperatorsEnum || item.type === ('size' || 'currency') && NumberTableFilterOperatorsEnum ||
                    item.type === 'decimal' && NumberTableFilterOperatorsEnum ||
                    TextTableFilterOperatorsEnum) || []}
                  chipsLabel={(option) => t(`Shared:${(option && option.value) || ''}`)}
                  displayLabel={(option) => t(`Shared:${(option && option.value) || ''}`)}
                  withoutSearchButton
                  selectedValues={(selectData && selectData[i] && selectData[i].operator[0]) || []}
                  getOptionSelected={(option) => (selectData && selectData[i] && selectData[i].operator).findIndex((items) => items && items.value === option.value) !== -1 || ''}
                  inputPlaceholder={t(`${translationPath}FilterOperators`)}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    handleFilterOperators(newValue && newValue, item, i);
                  }}
                />
              </div>
              <div className='datePickerComponentAge'>
                {((item.type === 'alt-date') &&
                  (
                    <DatePickerComponent
                      idRef='DatePickerCsubjevaluectRefomponentRef'
                      placeholder='DD/MM/YYYY'
                      isDisabled={item.searchKey === 'high_profile_management' || item.searchKey === 'angry_bird' }
                      value={(item && item.value) || null}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onDateChanged={(newValue) => {
                        handleDateRangePickerComponent(
                          (newValue && moment(newValue).format()), i
                        );
                      }}
                    />

                  )) || (
                    <Inputs
                      idRef='subjevaluectRef'
                      value={item.value}
                      isDisabled={item.searchKey === 'high_profile_management' || item.searchKey === 'angry_bird' }
                      type={(item.type === ('size' || 'currency') && ('number')) || (item.type === 'decimal' && ('number')) || ''}
                      inputPlaceholder={t(`${translationPath}value`)}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onInputChanged={(event) => {
                        handleaddValue(event.target.value, item, i);
                      }}
                    />
                  )}
              </div>
              <div className='select-55'>
                <SelectComponet
                  data={[
                    { value: 1, name: 'AND' },
                    { value: 2, name: 'OR' },
                  ]}
                  emptyItem={{ value: null, text: 'select-operand', isDisabled: false }}
                  value={item.operand}
                  valueInput='name'
                  textInput='name'
                  onSelectChanged={(value) => { changeoperand(value, i); }}
                  wrapperClasses='w-auto'
                  themeClass='theme-transparent'
                  idRef={`Refoperand${i * Math.random()}`}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                  isDisabled={item.searchKey === 'high_profile_management' || item.searchKey === 'angry_bird' }
                />
              </div>
            </div>

            {inputList.length !== 1 && (
              <div className='opation-tool mx-1'>
                <Tooltip
                  size='small'
                  title={t(`${translationPath}delete`)}
                  onClick={() => handleRemoveClick(i)}
                >
                  <Fab >
                    <DeleteIcon disabled={item.searchKey === 'high_profile_management' || item.searchKey === 'angry_bird'} fontSize='small' />
                  </Fab>
                </Tooltip>
              </div>
            )}
            {/*
            {AcationStateIsEdit && inputList.length === 1 && (
              <div className='opation-tool mx-1'>
                <Tooltip
                  size='small'
                  title={t(`${translationPath}delete`)}
                  onClick={() => handleRemoveClick(i)}
                >
                  <Fab>
                    <DeleteIcon fontSize='small' />
                  </Fab>
                </Tooltip>
              </div>
            )} */}

            {' '}
            {inputList.length - 1 === i && (
              <div className='opation-tool add mx-1'>
                <Tooltip
                  size='small'
                  title={t(`${translationPath}add`)}
                  aria-label='add'
                  disabled={item.formId === null ||
                    item.operator === null ||
                    item.searchKey === null ||
                    item.value === null ||
                    item.operand === null ||
                    item.category === null}
                  onClick={handleAddClick}
                >
                  <Fab
                    className={
                      item.fileName === '' ? 'addIcondisactive' : 'addIconactive'
                    }
                  >
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className='save-cancel-wrapper d-flex-v-center-h-end flex-wrap p-2'>
        <div className='cancel-wrapper d-inline-flex-center'>
          <Button
            className='cancel-btn-wrapper btns theme-transparent c-primary'
            onClick={onCancelClicked}
          >
            <span className='MuiButton-label'><span>{t(`${translationPath}cancel`)}</span></span>
          </Button>
        </div>
          <div className='save-wrapper d-inline-flex-center'>
            <Button
              className=' save-btn-wrapper btns theme-solid bg-danger '
              onClick={() => {
                setSelectData([]);
                onCancelClicked();
                RunClearFilter();
                setInputList([]);
                setBodyFilter([]);
                setSelectDataTemp([]);
                dispatch(ActiveItemActions.activeItemRequest({...ActiveItemReducer , systemReportConditions : [] }))
              }}
            >
              <span className='MuiButton-label'><span>{t(`${translationPath}Clear-all-filter`)}</span></span>
            </Button>
          </div>
        <div className='save-wrapper d-inline-flex-center'>
          <Button
            className=' save-btn-wrapper btns theme-solid bg-primary'
            disabled={valtionForm}
            onClick={() => {
              setBodyFilter(inputList);
              setSelectDataTemp(selectData);
              onSaveClicked(inputList);
            }}
          >
            <span className='MuiButton-label'><span>{t(`${translationPath}confirm`)}</span></span>
          </Button>
        </div>
      </div>
    </div>
  );
};
FiltersDialogView.propTypes = {
  setSelectDataTemp: PropTypes.func,
  SelectDataTemp: PropTypes.func,
  BodyFilter: PropTypes.instanceOf(Array),
  setBodyFilter: PropTypes.func,
  translationPath: PropTypes.string,

  Data: PropTypes.instanceOf(Array),
  parentTranslationPath: PropTypes.string,
};

FiltersDialogView.defaultProps = {
  setBodyFilter: () => { },
  setSelectDataTemp: () => { },
  SelectDataTemp: () => { },
  translationPath: '',
  parentTranslationPath: '',
  Data: [],
  BodyFilter: [],
};
