import { PowerBiEnum } from "../../../../Enums";
import { PortalsDashboardPermissions } from '../../../../Permissions' ; 
import { MainPortalDashboards } from "../MainPortalDashboards";

export const PortalDashboardTabs = [
  {
    index: 0,
    label: "Dashboard:propertyFinder",
    component: MainPortalDashboards,
    config: {
      reportId: PowerBiEnum.PropertyFinder.reportid,
      groupId: PowerBiEnum.PropertyFinder.groupid,
      Url: PowerBiEnum.PropertyFinder.url,
    },
    permissionsId : PortalsDashboardPermissions.PropertyFinderDashboard.permissionsId , 
  },
  {
    index: 1,
    label: "Dashboard:bayut",
    component: MainPortalDashboards,
    config: {
      reportId: PowerBiEnum.Bayut.reportid,
      groupId: PowerBiEnum.Bayut.groupid,
      Url: PowerBiEnum.Bayut.url,
    },
  
    permissionsId : PortalsDashboardPermissions.BayutDashboard.permissionsId , 
  },
 
];
