import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  Button,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ButtonBase,
} from '@material-ui/core';
import { PaginationComponent, Spinner, Tables } from '../../../../Components';
import { bottomBoxComponentUpdate } from '../../../../Helper';
import { DuplicatedPropertirsTableHeaderData } from './DuplicatedPropertirsTableHeaderData';
import {
  GetDuplicateCriteriaProperties,
  GetPropertiesDuplicationCriteria,
} from '../../../../Services';

const DuplicatedPropertiesView = () => {
  const parentTranslationPath = 'PropertiesView';
  const translationPath = '';
  const { t } = useTranslation(parentTranslationPath);
  const [data, setData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [duplicatedProperties, setDuplicatedProperties] = useState([]);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
  });
  const [duplicatedPropertiesCriteria, setDuplicatedPropertiesCriteria] =
    useState({
      result: [],
      totalCount: 0,
    });
  const [activeStep, setActiveStep] = useState(null);

  const APIGetPropertiesDuplicationCriteria = useCallback(async () => {
    setIsLoading(true);

    const result = await GetPropertiesDuplicationCriteria({
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex + 1,
    });

    if (!(result && result.status && result.status !== 200)) {
      setDuplicatedPropertiesCriteria(result);
    } else {
      setDuplicatedPropertiesCriteria({ result: [], totalCount: 0 });
    }

    setIsLoading(false);
  });

  const APIGetDuplicatedCriteriaProperties = useCallback(
    async (propertyDuplicationCriteriaId) => {
      try {
        const result = await GetDuplicateCriteriaProperties(
          propertyDuplicationCriteriaId
        );

        if (!(result && result.status && result.status !== 200)) {
          setDuplicatedProperties(result);
        } else {
          setDuplicatedProperties({ result: [], totalCount: 0 });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    [setDuplicatedProperties]
  );

  useEffect(() => {
    APIGetPropertiesDuplicationCriteria();
  }, [filter]);

  const handleShowClick = async (propertyDuplicationCriteriaId) => {
    setIsLoading(true);
    try {
      await APIGetDuplicatedCriteriaProperties(propertyDuplicationCriteriaId);
    } catch (error) {
      console.error('Error handling show click:', error);
    } finally {
      setActiveStep(
        propertyDuplicationCriteriaId === activeStep
          ? null
          : propertyDuplicationCriteriaId
      );
      setIsLoading(false);
    }
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={duplicatedPropertiesCriteria.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });

  const Row = (props) => {
    const { row } = props;

    return (
      <>
        <TableRow
          className={`TableRow-TableBody-aml ${
            data.index === props.index ? 'isselect' : ''
          }`}
          index={props.index}
        >
          <TableCell align='right'>{row.propertyName}</TableCell>
          <TableCell align='right'>{row.propertyType}</TableCell>
          <TableCell align='right'>{row.subCommunity}</TableCell>
          <TableCell align='right'>
            <IconButton aria-label='expand row' size='small'>
              {activeStep !== null ? (
                <KeyboardArrowUpIcon
                  id='KeyboardArrowUpIcon-ref'
                  onClick={() =>
                    handleShowClick(row.propertyDuplicationCriteriaId)
                  }
                />
              ) : (
                <KeyboardArrowDownIcon
                  id='KeyboardArrowDownIcon-ref'
                  onClick={() =>
                    handleShowClick(row.propertyDuplicationCriteriaId)
                  }
                />
              )}
            </IconButton>
          </TableCell>
        </TableRow>
        {activeStep === row.propertyDuplicationCriteriaId && (
          <TableRow>
            <TableCell colSpan={6} style={{ maxWidth: '1557px' }}>
              <div>
                <Tables
                  headerData={DuplicatedPropertirsTableHeaderData || null}
                  data={duplicatedProperties || []}
                  itemsPerPage={25}
                  isLoading={isLoading}
                  defaultActions={[]}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  function createData(
    propertyDuplicationCriteriaId,
    propertyName,
    propertyType,
    subCommunity
  ) {
    return {
      propertyDuplicationCriteriaId,
      propertyName,
      propertyType,
      subCommunity,
    };
  }

  const rows =
    duplicatedPropertiesCriteria && duplicatedPropertiesCriteria.result
      ? duplicatedPropertiesCriteria.result.map((row) =>
          createData(
            row.propertyDuplicationCriteriaId,
            row.propertyName,
            row.propertyType,
            row.subCommunity
          )
        )
      : [];

  return (
    <>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='AML-TABEL d-flex-column'>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center'>
                  {t(`${translationPath}property-name`)}
                </TableCell>
                <TableCell align='center'>
                  {t(`${translationPath}property-type`)}
                </TableCell>
                <TableCell align='center'>
                  {t(`${translationPath}sub-community`)}
                </TableCell>
                <TableCell align='center'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <Row
                  key={row.propertyDuplicationCriteriaId}
                  row={row}
                  index={rowIndex}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default DuplicatedPropertiesView;
