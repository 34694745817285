import { config } from '../config';
import { HttpServices } from '../Helper';


export const GetAllProductReleases = async ({pageIndex, pageSize}) => {
  
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/ReleaseNote/GetAllProductReleases/${pageIndex}/${pageSize}`
    ).then((data) => data)
    .catch((error) => error.response);
    return result;
  };
  
  export const AddProductRelease  = async (body) => {
    
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/ReleaseNote/AddProductRelease`, body
    ).then((data) => data)
      .catch((error) => error.response);
    return result;
  };

  export const UpdateProductRelease  = async (body, productReleaseId) => {
    const result = await HttpServices.put(
      `${config.server_address}/CrmDfm/ReleaseNote/UpdateProductRelease/${productReleaseId}`, body
    ).then((data) => data)
      .catch((error) => error.response);
    return result;
  };

  
export const DeleteProductRelease  = async (productReleaseId) => {
  
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/ReleaseNote/DeleteProductRelease/${productReleaseId}`
  ).then((data) => data)
    .catch((error) => error.response);
  return result;
};

  
export const GetAllReleaseNotesByProductReleaseId  = async ({pageIndex, pageSize, productReleaseId}) => {
  
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/ReleaseNote/GetAllReleaseNotesByProductReleaseId/${pageIndex}/${pageSize}/${productReleaseId}`
  ).then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const AddReleaseNote  = async (body) => {
  
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/ReleaseNote/AddReleaseNote`, body
  ).then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateReleaseNote  = async (body, releaseNoteId) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/ReleaseNote/UpdateReleaseNote/${releaseNoteId}`, body
  ).then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const DeleteReleaseNote  = async (releaseNoteId) => {
  
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/ReleaseNote/DeleteReleaseNote/${releaseNoteId}`
  ).then((data) => data)
    .catch((error) => error.response);
  return result;
};


export const ReorderReleasesNote  = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/ReleaseNote/ReorderReleasesNote`, body
  ).then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const ExportReleaseNotePdf  = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/ReleaseNote/ExportReleaseNotePdf` , body
  ).then((data) => data)
    .catch((error) => error.response);
  return result;
};
