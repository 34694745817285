import { config } from '../config';
import { HttpServices } from '../Helper';

export const CreateUnitMarketing = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/UnitMarketing`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const UpdateUnitMarketing = async (unitMarketingId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/UnitMarketing/UpdateTemplate/${unitMarketingId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetUnitMarketingByUnitId = async (unitId, isForlease) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/UnitMarketing/GetUnitMarketingByUnitId/${unitId}?isForLease=${isForlease}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const IsNeedApprovalMessageInMarketing = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/UnitApproval/IsNeedApprovalMessageInMarketing`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const CreateUnitRequestToPublishOrUnPublish = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/UnitApproval/CreateUnitRequestToPublishOrUnPublish`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const CheckPendingRequestForUser = async (userId, unitId) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/UnitApproval/CheckPendingRequestForUser/${userId}/${unitId}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
