import React from "react";

function ClockIcon({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="m11.28 1.023-.56.06A10.685 10.685 0 0 0 7.18 2.12c-1.135.551-1.987 1.165-2.942 2.119-.961.959-1.595 1.841-2.141 2.981C.301 10.968.728 15.286 3.226 18.633c.475.636 1.527 1.683 2.174 2.164 3.964 2.948 9.266 2.937 13.237-.027.609-.454 1.679-1.524 2.133-2.133 2.974-3.985 2.974-9.289 0-13.274-.454-.608-1.523-1.677-2.13-2.128-1.595-1.186-3.275-1.875-5.217-2.139C13 1.038 11.574.99 11.28 1.023m2.04 2.078c2.563.387 4.804 1.83 6.24 4.019.303.463.743 1.359.932 1.9.346.993.485 1.845.485 2.98 0 1.493-.257 2.621-.897 3.94-.705 1.454-1.769 2.667-3.153 3.592-.789.528-2.051 1.056-3.019 1.265a9.053 9.053 0 0 1-7.538-1.778c-1.513-1.212-2.648-2.99-3.103-4.859-.186-.763-.244-1.272-.244-2.16 0-1.493.257-2.621.897-3.94a8.983 8.983 0 0 1 5.24-4.594c.705-.233 1.272-.348 2.18-.442.322-.033 1.571.015 1.98.077m-1.625 1.956a1.04 1.04 0 0 0-.567.459l-.108.184-.011 3.217c-.012 3.632-.024 3.472.288 3.779.245.241 4.315 2.265 4.611 2.294.439.042.887-.241 1.026-.65a.949.949 0 0 0 .001-.676c-.125-.366-.209-.421-2.144-1.389l-1.789-.894-.011-2.841-.011-2.84-.108-.184a1.005 1.005 0 0 0-1.177-.459"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default ClockIcon;
