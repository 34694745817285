import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  AutocompleteComponent,
  DialogComponent,
  Spinner
} from '../../../../../../../Components';
import { DocumentsUploader } from '../../../../../../../SharedComponents/DocumentsUploader/DocumentsUploader'
import Joi from 'joi';

import { GetParams, getErrorByName } from '../../../../../../../Helper';
import { GetAllPropertyVideos, lookupItemsGetId, GetLookupItemsByLookupTypeName } from '../../../../../../../Services';
import { PropertyVideoCategory } from '../../../../../../../assets/json/StaticLookupsNames.json';

export const VideoDialogsProperties = ({
  parentTranslationPath,
  translationPath,
  data,
  activeItem,
  isCategoryExist,
  setIsCategoryExist,
  activeTab,
  setActiveItem,
  isOpenDialog,
  submitHandler,
  isOpen,
  setIsOpenDialog,
  setState,
  state,
  selected,
  setSelected,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const { t } = useTranslation(parentTranslationPath);
  const [lookupsData, setLookupsData] = useState([]);
  // const isCategoryUsed = (categoryId) => data && data.find(el => el.categoryId === categoryId);

  const schema = Joi.object({
    category: (state && state.categoryId === null && Joi.required()
      .messages({
        'any.required': t(`${translationPath}category-is-required`),
      }) || Joi.any()),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const getAllTitle = useCallback(async () => {
    setIsLoading(true);
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: PropertyVideoCategory,
      pageSize: 100,
      pageIndex: 1
    });
    if (!(res && res.status && res.status !== 200)) setLookupsData(res.result || []);
    else setLookupsData([]);
    setIsLoading(false);
  }, [activeItem]);

  const getAllVideoHandler = useCallback(async (categoryId) => {
    setIsLoading(true);
    const isCategoryUsed = data && data.find(el => el.categoryId === categoryId)
    const res = await GetAllPropertyVideos({
      categoryId: categoryId,
      propertyId: +GetParams('id'),
      pageIndex: 1,
      PageSize: 50,
    });
    if (!(res && res.status && res.status !== 200)) {
      const lookupIndex = lookupsData.findIndex((item) => item.lookupItemId === categoryId);
      if (lookupIndex !== -1) setSelected(lookupsData[lookupIndex]);
      if (res.result.length) {
        setIsCategoryExist(true);

        setState({
          id: 'files',
          value:
            (res.result &&
              res.result.map((file) => ({
                fileId: (file.uuid || file.fileId || null),
                fileName: file.fileName,
                documentLink: file.videoLink || ''
              }))) ||
            [],
        })
        setState({
          id: 'categoryId',
          value: categoryId
        })
      } else if (isCategoryUsed) {
        setIsCategoryExist(true);
        setState({
          id: 'categoryId',
          value: isCategoryUsed && isCategoryUsed.categoryId
        })
      } else {
        setState({
          id: 'files',
          value: [],
        });
        setActiveItem(null)
        setIsCategoryExist(false);
      }
    } else {
      setState({
        id: 'files',
        value: [],
      });
      setActiveItem(null)
    }
    setIsLoading(false);
  },
    [activeItem, lookupsData]
  );

  useEffect(() => {
    getAllTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllTitle]);



  useEffect(() => {
    if (activeItem) getAllVideoHandler(activeItem.categoryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllVideoHandler]);


  return (
    <>
      <DialogComponent
        titleText={activeItem || isCategoryExist ? 'edit-video' : 'Add-new-video'}
        saveText='save'
        saveType='button'
        saveIsDisabled={
          ((state && state.categoryId === null) || isUploading || (!activeItem && !isCategoryExist && !state.files.length ))
        }
        maxWidth='md'
        disableBackdropClick
        dialogContent={(
          <>
            <Spinner isActive={isUploading} isAbsolute />
            <div
              className={
                state && state.files && state.files.length >= 1 ? 'inter-wraper' : 'inter-wraper-empty'
              }
            >
              <div className=''>
                <AutocompleteComponent
                  idRef='TitleRef'
                  labelValue='Title'
                  isLoading={isLoading}
                  selectedValues={selected || []}
                  getOptionSelected={(option) => option.lookupItemName === selected.lookupItemName}
                  helperText={
                    getErrorByName(schema, 'category').message
                  }
                  isDisabled={activeItem ? true : false}
                  error={getErrorByName(schema, 'category').error}
                  multiple={false}
                  data={lookupsData || []}
                  displayLabel={(option) => t(`${option.lookupItemName || ''}`)}
                  withoutSearchButton
                  isWithError
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    getAllVideoHandler(newValue && +newValue.lookupItemId);
                    setSelected({
                      lookupItemName: newValue && newValue.lookupItemName,
                      lookupItemId: newValue && +newValue.lookupItemId,
                    });
                    setState({
                      id: 'categoryId',
                      value: newValue && +newValue.lookupItemId
                    })
                  }}
                />
              </div>
              <div className='UploaderComponent-wrapered pt-2'>

                <DocumentsUploader
                  initUploaded={(state && state.files &&
                    state.files.length > 0 &&
                    state.files.map((item) => ({
                      uuid: item.uuid || item.fileId,
                      fileName: item.fileName,
                      documentLink: item.documentLink || item.videoLink|| ''
                    }))) ||
                    []}
                  disableBackdropClick
                  docTypes={{ videos: 0, links: 1 }}
                  setIsUploading={setIsUploading}
                  isUploading={isUploading}
                  uploadedChanged={(files) => {
                    setState({
                      id: 'files',
                      value:
                        (files &&
                          files.map((file) => ({
                            fileId: (file.uuid || file.fileId || null),
                            fileName: file.fileName || null,
                            videoLink: file.documentLink || ''
                          }))) ||
                        [],
                    })
                  }}
                  open
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
            </div>
          </>
        )}
        saveClasses='btns theme-solid  w-100 mx-2 mb-2'
        isOpen={isOpenDialog}
        onSaveClicked={() => {
          submitHandler();
          setActiveItem(null);
          setIsCategoryExist(false);
          setIsOpenDialog(false);
          setState({
            id: 'files',
            value: [],
          })
          setState({
            id: 'categoryId',
            value: null
          })
          setSelected({
            lookupItemName: '',
            lookupItemId: '',
          });
        }}
        onCloseClicked={() => {
          setIsOpenDialog(false);
          setActiveItem(null);
          setIsCategoryExist(false);
          setState({
            id: 'files',
            value: [],
          });
          setState({
            id: 'categoryId',
            value: null
          });
          setSelected({
            lookupItemName: '',
            lookupItemId: '',
          });
        }}
        onCancelClicked={() => {
          setIsOpenDialog(false);
          setActiveItem(null);
          setIsCategoryExist(false);
          setState({
            id: 'files',
            value: [],
          });
          setState({
            id: 'categoryId',
            value: null
          });
          setSelected({
            lookupItemName: '',
            lookupItemId: '',
          });
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
    </>
  );
};

VideoDialogsProperties.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  size: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  activeItem: PropTypes.instanceOf(Array).isRequired,
};
