import React, { useEffect, useRef, useState } from 'react';
import { Inputs, TabsComponent } from '../../../../Components';
import { GetAllContactOpportunity } from '../../../../Services';
import { generateUniqueKey, showError } from '../../../../Helper';
import ContactsOpportunityTable from './ContactsOpportunityTable';
import { useTranslation } from 'react-i18next';
import headerData from './headerData';
import StatusSelector from './components/StatusSelector';
import { ButtonBase } from '@material-ui/core';

const parentTranslationPath = 'ContactOpportunity';

function ContactsOpportunity() {
  const { t } = useTranslation(parentTranslationPath);

  const [filter, setFilter] = useState({
    pageIndex: 1,
    pageSize: localStorage.getItem('Pagination') || 25,
    contactsTypeId: 1,
  });
  const [data, setData] = useState({ totalCount: 0, result: [] });
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [filterContainerKey, setFilterContainerKey] = useState(
    generateUniqueKey()
  );
  const timerRef = useRef();

  const changeTabHandler = (_e, newValue) => {
    setCurrentTab(newValue);
    setFilter((prev) => ({ ...prev, contactsTypeId: newValue + 1 }));
  };
  const getData = async () => {
    setLoading(true);
    try {
      const res = await GetAllContactOpportunity(filter);
      setData(res);
    } catch (error) {
      showError(t('error-message'));
    } finally {
      setLoading(false);
    }
  };

  const searchHandler = async (field, value) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(
      () => setFilter((prev) => ({ ...prev, [field]: value })),
      700
    );
  };

  const onClearFilter = () => {
    setFilter(() => ({
      contactsTypeId: currentTab + 1,
      pageSize: 25,
      pageIndex: 1,
    }));
    setFilterContainerKey(generateUniqueKey());
  };
  useEffect(() => {
    getData();
  }, [filter]);

  return (
    <div>
      <TabsComponent
        wrapperClasses='w-100 mb-3'
        data={[{ label: 'individual' }, { label: 'corporate' }]}
        labelInput={'label'}
        themeClasses='theme-curved'
        onTabChanged={changeTabHandler}
        currentTab={currentTab}
        parentTranslationPath={parentTranslationPath}
        translationPath={''}
      />
      <div
        className='d-flex gap-1 p-3 fj-center d-flex-v-center flex-wrap fa-end'
        key={filterContainerKey}
      >
        <StatusSelector
          label='status'
          className='w-25'
          parentTranslationPath={parentTranslationPath}
          onChange={(status) =>
            setFilter((prev) => ({
              ...prev,
              contactOpportunityStatusType: status,
            }))
          }
          value={filter.contactOpportunityStatusType}
        />
        <Inputs
          labelValue={!currentTab ? 'contact-name' : 'company-name'}
          wrapperClasses={'w-25 m-0'}
          parentTranslationPath={parentTranslationPath}
          translationPath={''}
          onInputChanged={(e) => searchHandler('contactName', e.target.value)}
        />
        <Inputs
          labelValue={'contact-id'}
          parentTranslationPath={parentTranslationPath}
          translationPath={''}
          wrapperClasses={'w-25 m-0'}
          onInputChanged={(e) => searchHandler('contactId', e.target.value)}
        />
        <div>
          <ButtonBase
            onClick={onClearFilter}
            id='onClearedAllFiltersRef'
            className='btns theme-solid bg-danger clear-all-btn'
          >
            <span className='mdi mdi-filter-remove m-1' />
            {t(`clear-filters`)}
          </ButtonBase>
        </div>
      </div>
      <ContactsOpportunityTable
        data={data}
        headerData={headerData[currentTab]}
        filter={filter}
        setFilter={setFilter}
        loading={loading}
        reload={getData}
        parentTranslationPath={parentTranslationPath}
      />
    </div>
  );
}

export default ContactsOpportunity;
