
export const CorporateContactDuplicatesCriteriaEnum = {
    companyName: {
        displayPath: 'company_name',
    },
    email: {
        displayPath: 'general_email.email',
    },
    mobile: {
        displayPath: 'landline_number.phone',
    },
}
