import React, { createContext, useState, useContext } from 'react';

// Create a context with default values
const SuccessDialogContext = createContext({
    isOpen: false,
    message: '',
    entity: {
      name: '',
      id: '',
      customId: '',
      isSale: false,
      type:''
    },
    completeBtn : false , 
    showDialog: () => {},
    hideDialog: () => {},
});

// Custom hook to use the context
const useSuccessDialog = () => useContext(SuccessDialogContext);

// Provider component that encapsulates the dialog state logic
const SuccessDialogProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isShowCompleteBtn, setIsShowCompleteBtn] = useState(false);
    const [message, setMessage] = useState('');
    const [entity, setEntity] = useState({
      name: '',
      id: '',
      customId: '',
      isSale: false,
      type :''
    });
    
    // Method to show the dialog
    const showDialog = (msg, entity , isCompleteBtn) => {
        setMessage(msg);
        setEntity(entity);
        setIsOpen(true);
        setIsShowCompleteBtn(isCompleteBtn);
    };

    // Method to hide the dialog
    const hideDialog = () => {
        setIsOpen(false);
        setMessage('');
    };

    return (
        <SuccessDialogContext.Provider 
          value={{
            isOpen,
            message,
            entity,
            showDialog,
            hideDialog,
            isShowCompleteBtn : isShowCompleteBtn
          }}
        >
          {children}
        </SuccessDialogContext.Provider>
    );
};

export {
    useSuccessDialog,
    SuccessDialogProvider,
}
