
import { OrganizationUserSearch } from '../../../Services';


const getName = (item , element) => 
{ 
  if(item && (item.key === 'referredby'))
    return element.fullName  ; 
  
    else return (element.firstName + ' ' + (element.lastName)) ; 
};

export const UserRule = async (item,value , setItems , setIsLoading ) => {
  if (!item.data.searchKey) return;
  if (item.data.searchKey !== 'User') return;
 
  const filter = {
    pageSize: 25,
    pageIndex: 1,
    name: value,
    userName: null,
    phoneNumber: null,
    email: null,
    userTypeId : null , 
  };
    setIsLoading(true);
    const rs = await OrganizationUserSearch({ ...filter });
    item.data.enum = [];
    if (!rs || !rs.result) return;
    rs.result.map((element) => {
      item.data.enum.push({
        id: element.id,
        name:  getName(item , element) ,  
        phone: element.phoneNumber,
        email: element.email,
        userName: element.userName,
        branch:element.branch , 
      });
    });
    setItems(item.data.enum);
    setIsLoading(false);
};