import React, { useCallback, useEffect, useReducer, useRef, useState } from "react"
import { DialogActions, DialogContent, DialogTitle, Dialog, ButtonBase } from "@material-ui/core"
import { useTranslate } from "../../../../../../../../Hooks"
import { useTranslation } from "react-i18next"
import { Button } from "react-bootstrap/lib/InputGroup"
import Transition from 'react-transition-group/Transition';
import { AutocompleteComponent, Spinner } from "../../../../../../../../Components"
import { CheckboxesComponent } from "../../../../../../../../Components"
import { GetContacts, UpdateUnitTenant } from "../../../../../../../../Services"
import { DatePickerComponent } from "../../../../../../../../Components"
import { getErrorByName, GetParams } from "../../../../../../../../Helper"
import moment from "moment"
import { CreateUnitTenant } from "../../../../../../../../Services"
import { showSuccess, showError } from "../../../../../../../../Helper"
import Joi from "joi";
import { set } from "core-js/core/dict"

export const SelectContactDialog = ({
    isOpen,
    onClose,
    translationPath,
    parentTranslationPath,
    onSave,
    activeItem,
    isEditingView
}) => {
    const { t } = useTranslation(parentTranslationPath)
    const searchTimer = useRef(null);
    const reducer = useCallback((state, action) => {
        if (action.id !== "edit") return { ...state, [action.id]: action.value };
        return {
            ...action.value,
        };
    }, []);
    const [loadings, setLoadings] = useReducer(reducer, {
        Contact: false,
        Loading: false
    });
    const [data, setData] = useReducer(reducer, {
        Contact: []
    });
    const [state, setState] = useReducer(reducer, {
        contractStartDate: '',
        contractEndDate: '',
        isCurrentTenant: false,
        contactId: null,
        unitId: +GetParams('id') || 0
    });
    const [selected, setSelected] = useReducer(reducer, {
        contractStartDate: null,
        contractEndDate: null,
        isCurrentTenant: false,
        contact: null,
    });
    const [isSubmitted, setIsSubmitted] = useState(false);

    const schema = Joi.object({
        contractStartDate: Joi.string()
            .required()
            .messages({
                "string.base": t(`${translationPath}contract-Start-Date-is-required`),
            })
        ,
        contractEndDate: Joi.string()
            .required()
            .messages({
                "string.base": t(`${translationPath}contract-End-Date-is-required`),
            }),

        contactId: Joi.number()
            .required()
            .messages({
                "number.base": t(`${translationPath}contact-is-required`),
            }),
    }).options({
        abortEarly: false,
        allowUnknown: true,
    })
    .validate(state);

    const getContactApi = useCallback(async (search) => {
        setLoadings({ id: 'Contact', value: true })
        const res = await GetContacts({
            pageIndex: 0, pageSize: 100, search, isAdvance: false
        });
        if (!(res && res.status && res.status !== 200)) {
            setData({ id: 'Contact', value: res.result })
        } else {
            setData({ id: 'Contact', value: [] })
        }
        setLoadings({ id: 'Contact', value: false })
    })

    const saveHandler = useCallback(async () => {
        setIsSubmitted(true)
        if(schema?.error?.message){
            showError(t('please-fill-all-required-field'));
            return;
        }
        setLoadings({ id: 'Loading', value: true })
        const res = await CreateUnitTenant(state);
        if (!(res && res.status && res.status !== 200)) {
            showSuccess(t`${translationPath}contact-selected-successfully`);
            onClose()
            onSave()
        } else {
            showError(t`${translationPath}contact-Failed_selected`);
        }
        setLoadings({ id: 'Loading', value: false })
    })

    const updateUnitTenant = useCallback(async () => {
        if(schema?.error?.message){
            showError(t('please-fill-all-required-field'));
            return;
        }
        setLoadings({ id: 'Loading', value: true })
        const res = await UpdateUnitTenant(activeItem?.unitTenantId, state);
        if (!(res && res.status && res.status !== 200)) {
            showSuccess(t`${translationPath}contact-edit-successfully`);
            onSave()
        } else {
            showError(t`${translationPath}Filed_Editing-contact`);
        }
        setLoadings({ id: 'Loading', value: false })
    })
    const autoFillFiled = () => {
        const contactObj = activeItem.tenantName ? {
            contact: activeItem.tenantName,
            id: activeItem.contactId,
        } : null;

        setState({
            id: "edit",
            value: {
                ...state,
                contractStartDate: activeItem?.contractStartDate || '',
                contractEndDate: activeItem?.contractEndDate || '',
                isCurrentTenant: activeItem?.isCurrentTenant || false,
                contactId: activeItem?.contactId || null,
            },
        });
        setSelected({
            id: "edit",
            value: {
                ...selected,
                contractStartDate: activeItem?.contractStartDate || null,
                contractEndDate: activeItem?.contractEndDate || null,
                isCurrentTenant: activeItem?.isCurrentTenant || false,
                contact: contactObj || null,
            },
        });
    }

    useEffect(() => {
        if (activeItem && isEditingView) {
            autoFillFiled()
        }
    }, [])
    return (
        <>
            <Spinner isActive={loadings.Loading} isAbsolute />
            <Dialog
                open={isOpen}
                onClose={onClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                TransitionComponent={Transition}
                keepMounted={false}
                fullWidth
                maxWidth='md'
            >
                <DialogTitle className="text-primary"> Select Contact </DialogTitle>
                <DialogContent>
                    <div className="containerBodyDialog my-4">
                        <div className="d-flex gap-3">
                            <div className="form-item w-100">
                                <DatePickerComponent
                                    idRef="contractStartDateRef"
                                    labelValue="Contract-start-date"
                                    placeholder="DD/MM/YYYY"
                                    value={selected.contractStartDate || null}
                                    onDateChanged={(newValue) => {
                                        setState({
                                            id: "contractStartDate",
                                            value:
                                                (newValue &&
                                                    moment(newValue).format("YYYY-MM-DDTHH:mm:ss")) ||
                                                null,
                                        });
                                        setSelected({
                                            id: "contractStartDate",
                                            value:
                                                (newValue &&
                                                    moment(newValue).format("YYYY-MM-DDTHH:mm:ss")) ||
                                                null,
                                        });
                                    }}
                                    helperText={
                                        getErrorByName(schema, "contractStartDate").message
                                    }
                                    error={getErrorByName(schema, "contractStartDate").error}
                                    isSubmitted={isSubmitted}
                                    parentTranslationPath={parentTranslationPath}
                                    translationPath={translationPath}
                                />
                            </div>
                            <div className="form-item w-100 ml-3">
                                <DatePickerComponent
                                    idRef="contractEndDateRef"
                                    labelValue="Contract-end-date"
                                    placeholder="DD/MM/YYYY"
                                    value={selected.contractEndDate || null}
                                    onDateChanged={(newValue) => {
                                        setState({
                                            id: "contractEndDate",
                                            value:
                                                (newValue &&
                                                    moment(newValue).format("YYYY-MM-DDTHH:mm:ss")) ||
                                                null,
                                        });
                                        setSelected({
                                            id: "contractEndDate",
                                            value:
                                                (newValue &&
                                                    moment(newValue).format("YYYY-MM-DDTHH:mm:ss")) ||
                                                null,
                                        });
                                    }}
                                    helperText={
                                        getErrorByName(schema, "contractEndDate").message
                                    }
                                    error={getErrorByName(schema, "contractEndDate").error}
                                    isSubmitted={isSubmitted}
                                    parentTranslationPath={parentTranslationPath}
                                    translationPath={translationPath}
                                />
                            </div>
                        </div>
                        <div className='my-2 mx-1'>
                            <AutocompleteComponent
                                idRef='AgentRef'
                                isLoading={loadings.Contact}
                                multiple={false}
                                inputPlaceholder={t(`${translationPath}select-a-contact`)}
                                data={data.Contact || []}
                                // chipsLabel={(option) => `${option?.contact?.first_name || ''} ${option?.contact?.last_name || ''}`}
                                displayLabel={(option) => {
                                    if (option?.contact?.first_name || option?.contact?.last_name) {
                                      return `${option.contact.first_name || ''} ${option.contact.last_name || ''}`;
                                    } else if (option?.contact) {
                                      return `${option.contact}`;
                                    }
                                    return '';
                                  }}                               
                                selectedValues={selected.contact || null}
                                withoutSearchButton
                                parentTranslationPath={parentTranslationPath}
                                translationPath={translationPath}
                                onChange={(event, newValue) => {
                                    setState({ id: 'contactId', value: newValue && newValue.contactsId });
                                    setSelected({ id: 'contact', value: newValue });
                                }}
                                onInputKeyUp={(e, newValue) => {
                                    const { value } = e.target;
                                    if (searchTimer.current) clearTimeout(searchTimer.current);
                                    searchTimer.current = setTimeout(() => {
                                        getContactApi(value)
                                    }, 600);
                                }}
                                helperText={getErrorByName(schema, "contactId").message}
                                error={getErrorByName(schema, "contactId").error}
                                isWithError
                                isSubmitted={isSubmitted}
                            />
                        </div>

                        <div className='activity-dialog-item'>
                            <CheckboxesComponent
                                idRef="CurrentOwnerRef"
                                parentTranslationPath={parentTranslationPath}
                                translationPath={translationPath}
                                label="Current-Owner"
                                singleChecked={selected.isCurrentTenant || false}
                                themeClass="theme-secondary"
                                onSelectedCheckboxClicked={() => {
                                    setState({
                                        id: 'isCurrentTenant',
                                        value: !state.isCurrentTenant
                                    });
                                    setSelected({
                                        id: 'isCurrentTenant',
                                        value: !selected.isCurrentTenant
                                    });
                                }}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className="d-flex-center fj-end py-0 pt-3 mr-3">
                        <ButtonBase
                            onClick={onClose}
                            className="MuiButtonBase-root btns bg-cancel"
                        >
                            {t(`${translationPath}cancel`)}
                        </ButtonBase>
                        {
                            isEditingView ? (
                                <ButtonBase
                                    className="btns theme-solid  mr-0"
                                    onClick={() => {
                                        updateUnitTenant()
                                    }}
                                >
                                    {t(`${translationPath}Edit`)}
                                </ButtonBase>
                            ) : (
                                <ButtonBase
                                    className="btns theme-solid  mr-0"
                                    onClick={() => {
                                        saveHandler()
                                    }}
                                >
                                    {t(`${translationPath}create`)}
                                </ButtonBase>
                            )
                        }

                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}