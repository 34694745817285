import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  commentContainer: {
    display: 'flex',
    gap: '12px',
    minHeight: '46px',
  },
  commentDetails: {
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
  },
  defaultAvatar: {
    alignSelf: 'flex-end',
    backgroundColor: '#F2F4F7', // avatar background
    border: `0.75px solid ${theme.palette.border.secondary}`,
    height: '40px',
    width: '40px',
    '& img': {
      height: '25px',
      width: '25px',
    },
  },
}));
