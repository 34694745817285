import React from 'react';
const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
const regex = new RegExp(expression);
export const MarketingCampaignsHeaderData = [
  {
    id: 1,
    label: 'Campaign ID',
    input: 'propertyCampaignId',
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 2,
    label: 'Name',
    input: 'name',
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 3,
    label: 'Campaign Manager',
    input: 'campaignManagerName',
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 4,
    label: 'Start Date',
    input: 'campaignStartDate',
    isHiddenFilter: true,
    isSortable: false,
    isDate: true,
    dateFormat: 'DD/MM/YYYY',
  },
  {
    id: 5,
    label: 'End Date',
    input: 'campaignEndDate',
    isHiddenFilter: true,
    isSortable: false,
    isDate: true,
    dateFormat: 'DD/MM/YYYY',
  },

  {
    id: 6,
    label: 'Campaign Languages',
    input: 'propertyCampaignLanguages',
    isHiddenFilter: true,
    isSortable: false,
    component: (data) => <span>{data?.propertyCampaignLanguages.reduce((result, item) => `${result + item.languageName}, `, ``)} </span> || <span />
  },
  {
    id: 7,
    label: 'Media',
    input: 'mediaTypeName',
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 8,
    label: 'Media Details',
    input: 'mediaDetailsName',
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 9,
    label: 'Media Link',
    input: 'mediaUrl',
    isHiddenFilter: true,
    isSortable: false,
    component: (data) => <span>
      {((data && data.mediaUrl || '')).match(regex) && <a href={data.mediaUrl} target="_blank">{data.mediaUrl} </a>
        || <><span  >{data && data.mediaUrl} </span></>
      }
    </span> || <span />
  },
  {
    id: 10,
    label: 'Campaign status',
    isHiddenFilter: true,
    isSortable: false,
    component: (item) => (<span>{item.campaignStatus ? "Active" : "In-Active"}</span> || <span />)
  },
  {
    id: 11,
    label: 'Exceeded Budget',
    isHiddenFilter: true,
    isSortable: false,
    component: (item) => (<div>{item.isExceedLimit ? <span className='c-danger'>Yes</span> : <span className='c-success'>No</span>}</div> || <span />)
  },
  {
    id: 12,
    label: 'branches',
    input: 'propertyCampaignBranches',
    isHiddenFilter: true,
    isSortable: false,
    component: (data) => <span>{data && data.propertyCampaignBranches.map((el)=>`${el.branchName} , `)} </span> || <span />
  },
];
