export const LeadTypeIdEnum = {
  Owner: {
    key: 'Owner',
    leadTypeId: 1,
    name: 'owner_lead'
  },
  Seeker: {
    key: 'Seeker',
    leadTypeId: 2,
    name: 'seeker_lead'
  },
};
