import React, { useCallback, useEffect, useState } from 'react';
import { ButtonBase } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import {
  ActionsEnum,
  ImagesGalleryFilterEnum,
  UnitProfileImagesCardActionEnum,
} from '../../../../../../Enums';
import {
  AlbumsBodyComponent,
  AlbumsFooterComponent,
  AlbumsHeaderComponent,
  DeleteVideoDialog
} from './Presentational';
import { VideoPreviewDialog, Spinner, PermissionsComponent } from '../../../../../../Components';
import { VideosGalleryDialog } from '../../../Dialogs';
import { UnitsUploaderDialog } from '../../../../UnitsSalesView/UnitsSalesProfileManagement/Common/UnitsUploaderDialog/UnitsUploaderDialog'
import { VideoDialogsProperties } from '../../../../PropertiesView/PropertiesProfileManagementView/Sections/PropertiesProfileGalleryComponent/PropertiesVideos/VideoDialogsProperties'
import { GalleryAlbumDeleteDialog } from '../../ImagesGalleryAlbumsComponent/Sections/ImagesGalleryAlbumsCardComponent/Dialogs';

export const VideosGalleryCardComponent = ({
  hideIcon,
  Display,
  fromPage,
  data,
  activeTab,
  onPageIndexChanged,
  parentTranslationPath,
  translationPath,
  setFilter,
  propertyId,
  reloadData,
  unitsUploader,
  propertyUploader,
  setState,
  submitHandler,
  isCategoryExist,
  setIsCategoryExist,
  state,
  activeItem,
  setActiveItem,
  selected,
  setSelected,
  Gallery
}) => {
  const [activeCategory, setActiveCategory] = useState(null);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [previewVideos, setPreviewVideos] = useState([]);
  const [clickedActiveVideoIndex, setClickedActiveVideoIndex] = useState(null);
  const [isOpenUnitUploaderDialog, setIsOpenUnitUploaderDialog] = useState(false)
  const [isOpenVideoUploaderDialog, setIsOpenVideoUploaderDialog] = useState(false)
  const [isOpenPropertyUploaderDialog, setIsOpenPropertyUploaderDialog] = useState(false)

  const onFooterActionsClicked = useCallback((key, item) => {
    if (key === ActionsEnum.edit.key) {
      setActiveItem(item);
      if (unitsUploader) setIsOpenUnitUploaderDialog(true)
      else if (propertyUploader) setIsOpenPropertyUploaderDialog(true)
      else setIsOpenVideoUploaderDialog(true)
    } else if (key === ActionsEnum.delete.key) {
      setActiveCategory(item);
      setIsOpenDeleteDialog(true);
    }
  }, []);

  const activeVideoHandler = useCallback((activeVideoList, index, item) => async () => {
    setClickedActiveVideoIndex(index);
    setPreviewVideos(activeVideoList || []);
  });

  return (
    <div className='images-gallery-albums-card-wrapper shared-wrapper'>
      {data && data ? (
        data.map((item, index) => (
          <div className='album-card-wrapper' key={`albumCardItemRef${index + 1}`}>
            <div className='cards-wrapper'>
              <div className='header-body-wrapper'>
                <AlbumsHeaderComponent
                  data={item}
                  fromPage={fromPage}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
                {Display === UnitProfileImagesCardActionEnum.Show && (
                  <ButtonBase
                    className='btns-icon theme-transparent mx-1'
                    onClick={() => onFooterActionsClicked(ActionsEnum.edit.key, item)}
                  >
                    <span className={`${ActionsEnum.edit.icon} c-black-light`} />
                  </ButtonBase>)}
              </div>
              <div className='cards-body-wrapper'>
                <AlbumsBodyComponent
                  data={item}
                  fromPage={fromPage}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
              </div>
              <div className='footer-body-wrapper'> 
                <AlbumsFooterComponent
                  hideIcon={hideIcon}
                  Display={Display}
                  allCtegoryVideo={item.videos}
                  activeCategory={item}
                  activeVideoHandler={activeVideoHandler}
                  onFooterActionsClicked={onFooterActionsClicked}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className='EmptyPage'> </div>
      )}
      {
        previewVideos && previewVideos.length > 0 && (
          <VideoPreviewDialog
            data={previewVideos || []}
            isOpen={(previewVideos && previewVideos.length > 0) || false}
            clickedActiveVideoIndex={clickedActiveVideoIndex}
            videoInput='fileId'
            altInput='fileName'
            titleText='preview-video'
            translationPath=''
            parentTranslationPath='Shared'
            nextHandle={(parent, operation, currntIndex) => async () => {
              parent(operation)();
            }}
            backHandle={(parent, operation, currntIndex) => () => {
              parent(operation)();
            }}
            onOpenChanged={() => {
              setClickedActiveVideoIndex(null);
              setPreviewVideos([]);
            }}
          />
        )
      }
      {isOpenVideoUploaderDialog && (
        <VideosGalleryDialog
          activeItem={activeItem}
          activeTab={activeTab}
          isOpen={isOpenVideoUploaderDialog}
          fromPage={fromPage}
          onSave={() => {
            setFilter((item) => ({ ...item, pageIndex: 0, PageSize: 25 }));
            setIsOpenVideoUploaderDialog(false);
            setActiveItem(null);
          }}
          isOpenChanged={() => {
            setIsOpenVideoUploaderDialog(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {isOpenUnitUploaderDialog && (
        <UnitsUploaderDialog
          reloadData={reloadData}
          propertyId={propertyId}
          unit={UnitProfileImagesCardActionEnum.Show}
          activeItem={activeItem}
          activeTab={activeTab}
          setActiveItem={setActiveItem}
          isOpen={isOpenUnitUploaderDialog}
          selected={selected}
          setSelected={setSelected}
          isOpenChanged={() => {
            setIsOpenUnitUploaderDialog(false);
          }}
          onSave={() => {
            setActiveItem(null);
            setIsOpenUnitUploaderDialog(false);
            setSelected({
              lookupItemName: '',
              lookupItemId: '',
            });
          }}
          data={data}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {isOpenPropertyUploaderDialog && (
        <VideoDialogsProperties
          state={state}
          setState={setState}
          activeItem={activeItem}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isCategoryExist={isCategoryExist}
          setIsCategoryExist={setIsCategoryExist}
          activeTab={activeTab}
          setActiveItem={setActiveItem}
          isOpenDialog={isOpenPropertyUploaderDialog}
          setIsOpenDialog={setIsOpenPropertyUploaderDialog}
          submitHandler={submitHandler}
          selected={selected}
          setSelected={setSelected}
          data={data}
        />
      )}
      {isOpenDeleteDialog && propertyUploader && (
        <DeleteVideoDialog
          categoryId={activeCategory && activeCategory.categoryId}
          isOpen={isOpenDeleteDialog}
          onCloseClicked={() => {
            setIsOpenDeleteDialog(false);
            setActiveCategory(null);
          }}
          deleteType={'property'}
          reloadData={reloadData}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {isOpenDeleteDialog && !propertyUploader && (
        <DeleteVideoDialog
          categoryId={activeCategory && activeCategory.categoryId}
          isOpen={isOpenDeleteDialog}
          onCloseClicked={() => {
            setIsOpenDeleteDialog(false);
            setActiveCategory(null);
          }}

          reloadData={reloadData}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {isOpenDeleteDialog && Gallery && (
        <GalleryAlbumDeleteDialog
          albumId={activeCategory && activeCategory.videoAlbumId}
          isOpen={isOpenDeleteDialog}
          isOpenChanged={() => {
            setIsOpenDeleteDialog(false);
            setActiveItem(null);
          }}
          reloadData={() => {
            onPageIndexChanged(0);
            setActiveItem(null);
            setIsOpenDeleteDialog(false);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </div>
  );
};
VideosGalleryCardComponent.propTypes = {
  ImageCategoryLookup: PropTypes.instanceOf(Object).isRequired,
  property: PropTypes.bool,
  setdata: PropTypes.func.isRequired,
  hideIcon: PropTypes.bool,
  onIconImageClick: PropTypes.bool,
  uploader: PropTypes.bool,
  propertyId: PropTypes.number.isRequired,
  Display: PropTypes.instanceOf(UnitProfileImagesCardActionEnum).isRequired,
  data: PropTypes.instanceOf(Array),
  fromPage: PropTypes.oneOf(Object.values(ImagesGalleryFilterEnum).map((item) => item.key))
    .isRequired,
  onPageIndexChanged: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  HideEdit: PropTypes.bool,
  isEdit: PropTypes.bool,
  EdititemData: PropTypes.instanceOf(Array).isRequired,
  onClickEdit: PropTypes.func,
  updateData: PropTypes.func,
  WithUnitDetails: PropTypes.bool,
  hidePhotosTooltip: PropTypes.bool,
  canEditImage: PropTypes.bool,
};
VideosGalleryCardComponent.defaultProps = {
  data: [],
  onIconImageClick: false,
  uploader: false,
  hideIcon: false,
  property: false,
  WithUnitDetails: false,
  HideEdit: true,
  isEdit: false,
  hidePhotosTooltip: false,
  updateData: undefined,
  onClickEdit: undefined,
  canEditImage: false,
};
