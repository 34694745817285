
import React   from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';

  export const UnitLayout = ({
    parentTranslationPath ,
    translationPath ,
    state ,
    setState
   } ) => { 

    const { t } = useTranslation(parentTranslationPath);
  
    return (
      <>
        <div className="">
        <span className="fw-bold"> {t(`${translationPath}unit-layout`)}</span>
        </div> 
        <div className='w-100 units-finishing'>
         {    state && state.updatePropertyRatingUnitLayouts &&  state.updatePropertyRatingUnitLayouts.map((item, index) => 
            (
            <div className="d-flex d-flex-v-center tower-units-finishing-card mt-2 mb-2 b-bottom"  key={`UnitLayout${index}`}>
            <div>
            {/* <span className='fw-bold'> {item.isMultipleUnitModel ? t(`${translationPath}yes`) : t(`${translationPath}no`)   } </span>  */}
            </div>
            <div>
            <Rating  
                size="large"
                name={`UnitLayout${index}`}
                max={10}
                value={item.score}
                precision={1}
                onChange={(event, newValue) => 
                {
                  const newList = [...state.updatePropertyRatingUnitLayouts] ; 
                  newList[index].score = newValue  ? newValue  : 0  ; 
                  setState({id : 'updatePropertyRatingUnitLayouts' , value :[...newList] })
                }}
                />     
              </div>
           </div>  
          )) } 
        </div>
      </>
    );
  } ; 
  

