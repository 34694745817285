import { HttpServices } from '../../Helper';
import { config } from '../../config';

export const GetPropertyMPI = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/Properties/GetPropertyMPI`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const GetSummaryPropertyMPIData = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/Properties/GetSummaryPropertyMPIData`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
