
import React , { useRef} from 'react';
import { Inputs } from '../../../../Components' ; 

import { useTranslation } from 'react-i18next';


export const AgentsFilter  = ({
    translationPath,
    parentTranslationPath,
    filter,
    setFilter , 
    selected , 
    setSelected , 
}) => {

    const { t } = useTranslation(parentTranslationPath);
    const searchTimer = useRef(null);



    return (
      <>
        <div className='d-inline-flex'>
          <div className='item-filter mr-2 ml-2'>
             <Inputs
               idRef='AgentNameRef'
               inputPlaceholder={t('agentName')}
               value={(selected && selected.agentName)}
               parentTranslationPath={parentTranslationPath}
               translationPath={translationPath}
               onInputChanged={(e)=> 
                {
                  setSelected({ id: 'agentName', value: e.target.value }); 

               }}
               onKeyUp={(e)=> {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                  setFilter((item) => ({ ...item, agentName: value }));
                 }, 700);
               }}
            />  
            </div>
            <div className='item-filter mr-2 ml-2'>
              <Inputs
                idRef='agentIdRef'
                inputPlaceholder={t('agentId')}
                value={(selected && selected.agentId)}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                type={'number'}
                onKeyUp={(e)=> {
                  const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                  setFilter((item) => ({ ...item, agentId: value }));
                 }, 700);
               }}
               onInputChanged={(e)=> 
                {
                  setSelected({ id: 'agentId', value: e.target.value }); 

                }}
              />  
              </div>
            </div>
         </>
    );
};
