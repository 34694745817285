import React from 'react'
import PropTypes from 'prop-types'
import './StepCard.scss'
import { CheckboxesComponent } from "../../../Components";
import { useTranslation } from "react-i18next";

export const StepCard = ({
  stepData,
  activeStep,
  onStepClick,
  parentTranslationPath,
  translationPath
}) => {
  const { t } = useTranslation(parentTranslationPath);
  return (
    <div className={`step-card pointer ${activeStep?.id == stepData.id ? 'active' : ''}`} onClick={() => onStepClick(stepData)}>
      <div className="d-flex-default fj-between w-100">
        <p className="fz-16 fw-simi-bold m-0">
          {t(`${translationPath}${stepData.title || ''}`)}
        </p>
        <CheckboxesComponent
          idRef="IsImportantRef"
          wrapperClasses="card-check mx-1 rounded"
          singleChecked={stepData.isPassed}
          themeClass="theme-propx"
          isDisabled
        />
      </div>
      <div>{stepData.content 
      ? '' 
      : <p className='my-1'>{t(`${translationPath}${stepData.description || ''}`)}</p>}</div>
      <div>
        {stepData.icon ? <img src={stepData.icon} /> : null}
      </div>
    </div>
  )
}

StepCard.propTypes = {
  stepData: PropTypes.object,
  activeStep: PropTypes.object,
  onStepClick: PropTypes.func,
}
StepCard.defaultProps = {
  stepData: {},
  onStepClick: ()=>{},
};