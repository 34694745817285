const DeActiveUserLogHistoryHeader = [
  {
    id: 1,
    label: 'line-manager-action-status',
    input: 'lineManagerActionStatus',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
  {
    id: 2,
    label: 'line-manager-action-date',
    input: 'lineManagerActionDate',
    isSortable: true,
    isHiddenFilter: true,
    isDate: true,
  },
  {
    id: 3,
    label: 'line-manager-action-by',
    input: 'lineManagerActionBy',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
  {
    id: 4,
    label: 'lead-owner-action-status',
    input: 'leadOwnerActionStatus',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
  {
    id: 5,
    label: 'lead-owner-action-date',
    input: 'leadOwnerActionDate',
    isSortable: true,
    isHiddenFilter: true,
    isDate: true,
  },
  {
    id: 6,
    label: 'lead-owner-action-by',
    input: 'leadOwnerActionBy',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
];

export default DeActiveUserLogHistoryHeader;
