import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { PageHeading } from "../../../../../SharedComponents";
import { Spinner } from "../../../../../Components";
import { showError, showSuccess , bottomBoxComponentUpdate  } from "../../../../../Helper";
import { PropertyRatingLocationTypeScoreEnum } from "../../../../../Enums";
import {
  GetGeographicCriteriaWithScores,
  UpdateGeographicCriteriaWithScore,
  GetAllLocationTypeWithScores,
} from "../../../../../Services";
import { GeographicCriteriacContentView } from "./GeographicCriteriacContentView";

export const GeographicCriteriaView = ({
  parentTranslationPath,
  translationPath,
  ratingPercentage,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGeographicCriteria, setIsLoadingGeographicCriteria] =
    useState(false);
  const [
    geographicCriteriaRatingPercentage,
    setGeographicCriteriaRatingPercentage,
  ] = useState(0) ; 
   
  const [locationValues, setLocationValues] = useState([]);
  const [
    cityGeographicCriteriaWithScores,
    setCityGeographicCriteriaWithScores,
  ] = useState([]);
  const [
    districtGeographicCriteriaWithScores,
    setDistrictGeographicCriteriaWithScores,
  ] = useState([]);
  const [
    communityGeographicCriteriaWithScores,
    setCommunityGeographicCriteriaWithScores,
  ] = useState([]);
  const [
    subCommunityGeographicCriteriaWithScores,
    setSubCommunityGeographicCriteriaWithScores,
  ] = useState([]);

  const GetGeographicCriteriaWithScoresAPI = useCallback(async (search) => {
    setIsLoadingGeographicCriteria(true);
    const res = await GetGeographicCriteriaWithScores({
      parentLookupItemId: search || null,
    });
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      const cities =
        (
          res &&
          res.locationTypeScores &&
          res.locationTypeScores.length > 0 &&
          res.locationTypeScores[0] &&
          res.locationTypeScores[0].geographicCriteriaScores
        ).map((el) => ({
          ...el,
          propertyRatingLocationTypeScoreId:
            PropertyRatingLocationTypeScoreEnum.City
              .propertyRatingLocationTypeScoreId,
        })) || [];
      setCityGeographicCriteriaWithScores(cities);
    } else setCityGeographicCriteriaWithScores([]);

    setIsLoadingGeographicCriteria(false);
  });

  const GetAllLocationTypeWithScoresAPI = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllLocationTypeWithScores();
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      setLocationValues([...res]);
    } else setLocationValues([]);
    setIsLoading(false);
  });

  const reloadData = () => {
    GetAllLocationTypeWithScoresAPI();
    GetGeographicCriteriaWithScoresAPI();
  };

  const saveHandler = async () => {
    setIsLoading(true);

    const listOfLookupWithScore = [
      ...cityGeographicCriteriaWithScores,
      ...districtGeographicCriteriaWithScores,
      ...communityGeographicCriteriaWithScores,
      ...subCommunityGeographicCriteriaWithScores,
    ].map((l) => ({ ...l, score: parseFloat(l.score) }));

    const res = await UpdateGeographicCriteriaWithScore({
      listOfLookupWithScoreDto: listOfLookupWithScore,
      listOfUpdateLocationTypeScoreDto: locationValues,
    });
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      showSuccess(
        t`${translationPath}geographic-criteria-updated-successfully`
      );
      reloadData();
    } else showError(t`${translationPath}geographic-criteria-updated-failed`);

    setIsLoading(false);
  };

  useEffect(() => {
    reloadData();
    if(ratingPercentage)
    {
      const rating = ratingPercentage.find((f) => f.propertyRatingTabName === "Geographic criteria");
      setGeographicCriteriaRatingPercentage((rating && rating.score) || 0 ) ; 
    }
      
  }, [ratingPercentage]);

  useEffect( () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  return (
    <div className="w-100 mt-4">
      <Spinner isActive={isLoading || isLoadingGeographicCriteria} isAbsolute />
      <div className="d-flex-v-center-h-between b-bottom">
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          headerTitle={"geographic-criteria"}
          wrapperClasses={"px-4 pt-2 w-75"}
          headerTitleClasses={""}
          subTitleClasses={"fz-12 m-0"}
          subTitle={""}
          titleWithPercentage={`${geographicCriteriaRatingPercentage}%`}
        />

        <div className="w-25 px-4 d-flex-center fj-end">
          <Button
            className="btns theme-propx outlined"
            id="btn-cancel"
            onClick={() => {
              reloadData();
            }}
          >
            {t(`${translationPath}cancel`)}
          </Button>
          <Button
            className="btns theme-propx solid"
            id="btn-save"
            onClick={() => saveHandler()}
          >
            {t(`${translationPath}save-changes`)}
          </Button>
        </div>
      </div>
      <div className="property-rating-container px-4">
        <div className="geographic-criteria-view mt-2 mb-2">
          <GeographicCriteriacContentView
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            locationValues={locationValues}
            setLocationValues={setLocationValues}
            cityGeographicCriteriaWithScores={cityGeographicCriteriaWithScores}
            setCityGeographicCriteriaWithScores={
              setCityGeographicCriteriaWithScores
            }
            districtGeographicCriteriaWithScores={
              districtGeographicCriteriaWithScores
            }
            setDistrictGeographicCriteriaWithScores={
              setDistrictGeographicCriteriaWithScores
            }
            communityGeographicCriteriaWithScores={
              communityGeographicCriteriaWithScores
            }
            setCommunityGeographicCriteriaWithScores={
              setCommunityGeographicCriteriaWithScores
            }
            subCommunityGeographicCriteriaWithScores={
              subCommunityGeographicCriteriaWithScores
            }
            setSubCommunityGeographicCriteriaWithScores={
              setSubCommunityGeographicCriteriaWithScores
            }
          />
        </div>
      </div>
    </div>
  );
};
