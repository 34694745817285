import React from "react";
import PropTypes from "prop-types";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";
import { AlertBox } from "../AlertBox";

// styles
import useStyles from "./styles";

function LayoutContent({ children }) {
  const { mainContainerPadding, alertBoxContent } = useVerticalNav();
  const styles = useStyles({ mainContainerPadding });

  return (
    <>
      {alertBoxContent?.display && alertBoxContent?.title && <AlertBox />}
      <main className={styles.root}>
        {children}
      </main>
    </>
  );
}

LayoutContent.propTypes = {
  children: PropTypes.node,
};

LayoutContent.defaultProps = {
  children: null,
};

export default LayoutContent;
