export const ReminderToEnum = [
  {
    value: 0,
    text: "Agent",
    isDisabled: false,
  },
  {
    value: 1,
    text: "Client",
    isDisabled: false,
  },
  {
    value: 2,
    text: "Creator",
    isDisabled: false,
  },
  {
    value: 3,
    text: "TeamLead",
    isDisabled: false,
  },
];
