

import React , {useState , useCallback , useEffect  } from 'react';
import { useTranslation } from 'react-i18next'; 
import { AutocompleteComponent } from '../../../../../../../../../../../../../Components' ;
import {  GetPortalAccountByBranchId    } from '../../../../../../../../../../../../../Services' ; 
import { getErrorByName ,  GetParams } from '../../../../../../../../../../../../../Helper' ; 
import {  UnitsOperationTypeEnum } from '../../../../../../../../../../../../../Enums' ; 
import { AddressPropertyFinderDialog } from '../../PropertyFinderDialog/PropertyFinderDialog'; 



export const PortalAccountComponent = ({
   setState ,
   state ,
   translationPath ,
   parentTranslationPath , 
   selected ,
   setSelected , 
   schema , 
   isSubmitted , 
   propertyFinderDetails , 
   setPropertyFinderDetails , 

  }) => {
   const { t } = useTranslation(parentTranslationPath);
   const [isLoading , setIsLoading ] = useState(false);
   const [isPropertyFinderDialog , setIsPropertyFinderDialog ] = useState(false);

   const pathName = window.location.pathname.split('/home/')[1].split('/view')[0];

   const [allPortalAccounts, setAllPortalAccounts] = useState({
      result: [],
      totalCount: 0,
    });

    const getOperationType = () => 
    {

      if(pathName.includes('units-sales/unit-profile-edit') || (pathName.includes('lead-owner-units-sale/unit-profile-edit')))
       return  +(UnitsOperationTypeEnum.sale.key) ; 

       else if(pathName.includes('units-lease/unit-profile-edit') || (pathName.includes('lead-owner-units-lease/unit-profile-edit')))
       return  +(UnitsOperationTypeEnum.rent.key) ;

       return  +(UnitsOperationTypeEnum.sale.key) ;
    };


    const GetAllPortalAccounts = useCallback(async () => {
      setIsLoading(true) ; 
      const res = await GetPortalAccountByBranchId(+state.branchId , getOperationType());
        if (!(res && res.status && res.status !== 200)) 
        setAllPortalAccounts({
          result: (res && res.filter((item)=> item.status)),
          totalCount: res && res.length , 
          });
        else 
        setAllPortalAccounts({
            result: [],
            totalCount: 0 , 
          });

          setIsLoading(false) ; 
         }, [selected.branch]);

          
  useEffect(() => {

    if(selected.branch)
    GetAllPortalAccounts(); 
   else{
    setAllPortalAccounts({
      result: [],
      totalCount: 0
    });
   } 
   }, [selected.branch]);
  
  return (
    <>
        <div className='dialog-content-item mt-2'>
             <AutocompleteComponent
                idRef='portalAccountIdRef'
                labelValue='portalAccount'
                multiple={false}
                value={selected.portalAccount}
                selectedValues={selected.portalAccount}
                data={(allPortalAccounts && allPortalAccounts.result) || []}
                chipsLabel={(option) => (option && option.portalAccountName) || ''}
                displayLabel={(option) => (option && option.portalAccountName) || ''}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => 
                { 
                    const updateState = 
                      {...state ,
                      portalAccountId : (newValue && newValue.portalAccountId) , 
                      portalId : (newValue && newValue.portalDto && newValue.portalDto.portalId) , 
                      createPublishPortalExtraDetail : null , 
                    }
                    setState({ id: 'edit', value: updateState});
                    setSelected((item) => ({...item ,  portalAccount : newValue , portal : (newValue && newValue.portalDto ) }));
                    setPropertyFinderDetails(null); 
                
                    if(newValue && newValue.portalDto &&  newValue.portalDto.portalName && newValue.portalDto.portalName.toLowerCase().includes('property finder'))
                      setIsPropertyFinderDialog(true) ; 
                }}
                withLoader 
                isLoading={isLoading}
                helperText={getErrorByName(schema, 'portalAccountId').message}
                error={getErrorByName(schema, 'portalAccountId').error}
                isWithError
                isSubmitted={isSubmitted}
                isDisabled={!state.branchId}  
             />
              {
            isPropertyFinderDialog && (
            <AddressPropertyFinderDialog
               parentTranslationPath={parentTranslationPath}
               translationPath={translationPath}
               isOpenDialog={isPropertyFinderDialog}
               onClose={()=> {
                 setIsPropertyFinderDialog(false);
                }}
               onSave={()=> {
                if((selected && selected.portal && selected.portal.portalName && selected.portal.portalName.toLowerCase().includes('property finder')) ||
                (selected && selected.portalAccount && selected.portalAccount.portalDto  && selected.portalAccount.portalDto.portalName && 
                selected.portalAccount.portalDto.portalName.toLowerCase().includes('property finder')))
                {
                  setState({ id: 'createPublishPortalExtraDetail', value: propertyFinderDetails  }); 
                }
                 else 
                 {
                  setState({ id: 'createPublishPortalExtraDetail', value: null  });  
                 }
                 setIsPropertyFinderDialog(false) ; 
                 }}
               propertyFinderDetails ={propertyFinderDetails}
               setPropertyFinderDetails={setPropertyFinderDetails}
           />
            )
         } 
         </div>
     </>
  );
};
