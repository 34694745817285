

import { ContactsPermissions } from '../Permissions';
import { returnPropsByPermissions } from './ReturnPropsByPermissions.Helper';

export const PermissionsInSideFormsTabs = (formTabs) => 
{
    if (formTabs && formTabs['Source File'] && !returnPropsByPermissions(ContactsPermissions.ViewSourceFileTab.permissionsId) )
    delete formTabs['Source File'] ;

return  formTabs ; 

}





