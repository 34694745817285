import { HttpServices } from '../../Helper';
import { config } from '../../config/config';

export const GetPendingRequests = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/UnitApproval/GetPendingRequests`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
export const ApproveOrRejectRequest = async (body) => {
  try {
    const result = await HttpServices.put(
      `${config.server_address}/CrmDfm/UnitApproval/ApproveOrRejectRequest`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
export const SendApprovalNotification = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/UnitApproval/SendApprovalNotification`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const GetRequestDetailsById = async (requestId) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/UnitApproval/GetRequestDetailsById/${requestId}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
