import React from "react";

function ArrowNarrowLeft({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M9.64 5.068c-.155.053-.604.485-3.293 3.167-1.777 1.773-3.159 3.182-3.22 3.285-.091.152-.107.226-.107.48s.016.328.107.48c.136.227 6.243 6.327 6.428 6.419.455.229 1.046.045 1.312-.407.097-.165.113-.234.112-.492 0-.212-.022-.341-.074-.44-.04-.077-1.066-1.135-2.279-2.35L6.421 13l6.879-.002c5.643-.001 6.907-.011 7.03-.055.221-.079.414-.241.539-.454.095-.161.111-.233.111-.489 0-.254-.017-.328-.108-.484a1.02 1.02 0 0 0-.582-.461c-.152-.046-1.343-.055-7.026-.055H6.421l2.205-2.21c1.213-1.215 2.239-2.273 2.279-2.35.052-.099.074-.228.074-.44.001-.255-.015-.328-.107-.484a1.016 1.016 0 0 0-1.232-.448"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default ArrowNarrowLeft;
