import { DeactiveUserLogView } from "../../Views/Home/DeactiveUserLog";

export const DeactiveUserLogRoutes = [
  {
    path: "/view",
    name: "AgentRotation",
    component: DeactiveUserLogView,
    layout: "/home/deactive-user",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "agent-rotation-log",
        isDisabled: true,
        route: "/home/deactive-user/view",
        groupName: "agent-management",
      },
    ],
  },
];
