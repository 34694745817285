export const LeaseAgentDashboardPermissions = {
  LeaseAgentDashboard: 
    {
      permissionsId:'16d9cc07-e9a9-4d21-0d28-08da4ddf3638',
      permissionsName :'Lease Agent Dashboard',
      componentsId:null,
      components:null , 
    } , 
    LeaseAgentPlusDashboard : {
      permissionsId: "2fc8fba8-1f4f-40c3-7f32-08dbbb5bf6df",
      permissionsName: "Lease Agent + Dashboard",
      description: null,
      componentsId: null,
      components: null
  }
} ;
