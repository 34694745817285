import React from "react";
import "./KenbanBoardCard.scss";
import moment from "moment";
import Avatar from "@material-ui/core/Avatar";
import {
  getDownloadableLink,
  getFirstLastNameLetters,
} from "../../../../../../Helper";

export function KenbanBoardCard({ cardData, cardClickHandler }) {

  const getTaskRelatedTo  = () => {

    const relatedToList = [];
    
    if(cardData.relatedContactName) relatedToList.push(cardData.relatedContactName);
    if(cardData.relatedLeadName) relatedToList.push(cardData.relatedLeadName);
    if(cardData.relatedPropertyName) relatedToList.push(cardData.relatedPropertyName);
    if(cardData.relatedUnitName) relatedToList.push(cardData.relatedUnitName);

    const mappedRelatedToList = relatedToList.map((item) => (
      <>
        <span className="fz-10 text-tertiary fw-simi-bold">{item}</span>
        {relatedToList.length > 1 && (
        <span className="mr-1">,</span>
        )}
       </>
    ));
  
    return mappedRelatedToList;
  }

  return (
    <div
      className={`task-kenban-card border-gray-secondary d-flex-column 
       fj-start radius-xl p-3 mb-2`}
      onClick={cardClickHandler}
    >
      <div className="w-100 d-flex-v-center-h-between d-flex-default">
        <p className="fw-simi-bold">{cardData?.taskName}</p>
        <div className="d-flex-v-center d-flex-default">
          <div className="d-flex-center">
            <div className="campaign-team-f1">
              <Avatar
                className="avatars-wrapper team-avatar fz-12 sm-theme"
                src={
                  cardData?.creatorProfileImage
                    ? getDownloadableLink(cardData.creatorProfileImage)
                    : ""
                }
              >
                {getFirstLastNameLetters(cardData.creator || "")}
              </Avatar>
            </div>
            <div className="d-flex-column">
              <span className="fz-12 fw-simi-bold mr-2">Creator</span>
              <span className="fz-10 text-tertiary">{cardData?.creator}</span>
            </div>
          </div>
        </div>
      </div>
      {cardData?.isWithExpiration && (
        <div>
          <span>Expiration time:</span>
          <span className="fw-simi-bold pl-2">
            {cardData?.expirationTime
              ? moment(cardData.expirationTime).format("LL")
              : ""}
          </span>
        </div>
      )}
      <div className="w-100 d-flex-center fj-start d-flex-default">
        <span className="mr-2">Assigned to:</span>
        <div className="d-flex-center d-flex-default">
          {cardData?.projectTaskAssignTo &&
            cardData?.projectTaskAssignTo.map((item) => (
              <>
                <span className="fz-10 text-tertiary fw-simi-bold">{item.user}</span>
                {cardData?.projectTaskAssignTo.length > 1 && (
                  <span className="mr-1">,</span>
                )}
              </>
            ))}
        </div>
      </div>
      {(getTaskRelatedTo().length > 0) && 
      (<div className="w-100 d-flex-center fj-start d-flex-default">
        <span className="mr-2">Related to:</span>
        <div className="d-flex-center d-flex-default">
          {getTaskRelatedTo()}
        </div>
      </div>)}
      <div
        className={`approval-capsul ${
          cardData?.isWithApproval ? "success" : "fail"
        }`}
      >
        {cardData?.isWithApproval ? (
          <p><span className="mdi mdi-check"></span><span>{"With Approval"}</span></p>
        ) : (
          <p><span className="mdi mdi-close"></span><span>{"Without Approval"}</span></p>
        )}
      </div>
    </div>
  );
}
