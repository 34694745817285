import { makeStyles } from "@material-ui/core/styles";

const identityDetailsStyles = makeStyles((theme) => ({
  container: {
    padding: "24px",
    display: "flex",
    justifyContent: "space-between",
    gap: "10px",
    [theme.breakpoints.down('730')]: {
      flexDirection: "column",
    },
  },
  heading: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "28px",
    color: theme.palette.text.primary,
  },
  divider: {
    marginTop: '20px',
    marginBottom: '12px',
    backgroundColor: theme.palette.border.secondary,
    minWidth: '332px',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  infoItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    [theme.breakpoints.down('730')]: {
      flexDirection: "column",
      gap: '0px',
      alignItems: "flex-start",
    },
  },
  infoLabel: {
    color: theme.palette.text.secondary,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  infoValue: {
    color: theme.palette.text.secondary,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
  },
  attachmentWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: "8px",
  }
}));

const visaDetailsStyles = makeStyles((theme) => ({
  container: {
    padding: "24px",
  },
  heading: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "28px",
    color: theme.palette.text.primary,
    marginBottom: '4px',
  },
  subHeading: {
    color: theme.palette.text.tertiary,
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
  },
  divider: {
    marginTop: '20px',
    marginBottom: '16px',
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    [theme.breakpoints.down('730')]: {
      flexDirection: "column",
    },
  },
  infoItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    [theme.breakpoints.down('730')]: {
      flexDirection: "column",
      gap: '0px',
      alignItems: "flex-start",
    },
  },
  infoLabel: {
    color: theme.palette.text.secondary,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  infoValue: {
    color: theme.palette.text.secondary,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
  },
  dualPane: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  customFlag: {
    width: '22px',
    height: '22px',
    borderRadius: '50%',
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: (props) => props.countryCode ? `url(https://flagcdn.com/w320/${props?.countryCode?.toLowerCase()}.png)` : 'none',
    marginInlineEnd: '8px',
  }
}));

const generalInformationStyles = makeStyles((theme) => ({
  container: {
    padding: "24px",
  },
  heading: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "28px",
    color: theme.palette.text.primary,
    marginBottom: '4px',
  },
  subHeading: {
    color: theme.palette.text.tertiary,
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
  },
  divider: {
    marginTop: '20px',
    marginBottom: '16px',
  },
  secondDivider: {
    marginTop: '16px',
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    [theme.breakpoints.down('730')]: {
      flexDirection: "column",
    },
  },
  dualPane: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  infoItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    [theme.breakpoints.down('730')]: {
      flexDirection: "column",
      gap: '0px',
      alignItems: "flex-start",
    },
  },
  infoLabel: {
    color: theme.palette.text.secondary,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  infoValue: {
    color: theme.palette.text.secondary,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
  },
  subInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  avatarWrapper: {
    border: `0.5px solid ${theme.palette.border.avatarContrast}`,
    borderRadius: "50%",
    marginInlineEnd: "10px",
  },
  avatar: {
    width: "22px",
    height: "22px",
    borderRadius: "50%",
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
}));

const contactClassificationsStyles = makeStyles((theme) => ({
  container: {
    padding: "24px",
    display: "flex",
    justifyContent: "space-between",
    gap: "10px",
    flexDirection: 'column'
  },
  heading: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "28px",
    color: theme.palette.text.primary,
  },
  divider: {
    marginTop: '20px',
    marginBottom: '12px',
    backgroundColor: theme.palette.border.secondary,
    minWidth: '332px',
  },
  classificationsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    flexWrap:'wrap'
  },
  classificationslable: {
    color: theme.palette.text.secondary,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
  }
}));

const sharedDetailsStyles = makeStyles((theme) => ({
  container: {
    padding: "24px",
  },
  heading: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "28px",
    color: theme.palette.text.primary,    
  },
  divider: {
    marginTop: '20px',
    marginBottom: '16px',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  infoItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    [theme.breakpoints.down('730')]: {
      flexDirection: "column",
      gap: '0px',
      alignItems: "flex-start",
    },
  },
  infoLabel: {
    color: theme.palette.text.secondary,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  infoValue: {
    color: theme.palette.text.secondary,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
  },
}));

export {
  identityDetailsStyles,
  visaDetailsStyles,
  generalInformationStyles,
  contactClassificationsStyles,
  sharedDetailsStyles,
};
