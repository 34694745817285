import React, { memo } from 'react';
import moment from 'moment';
import {TicketsStatusEnum, TicketsPriorityEnum} from '../../../Enums'

export const SupportTicketsCards = memo(({ data }) => {

    const getStatusColor = (ticket) =>{

        let statusDetails = null;
        if(ticket) statusDetails = Object.values(TicketsStatusEnum).find(item=> item.key === ticket.status);

        return statusDetails?.iconColor || TicketsStatusEnum.InProgress.iconColor;
    }


    const getPriorityIcon = (ticket) =>{

        let priorityDetails = null;
        if(ticket) priorityDetails = Object.values(TicketsPriorityEnum).find(item=> item.key === ticket.priority);

        return priorityDetails?.iconClass || TicketsPriorityEnum.Medium.iconClass;
    }
    
    return (
        <div className='tickets-container'>
            {data && data.map(item => (<div class='ticket-card'>
                <div class='ticket-preview'>
                    <div className='mt-2 ticket-type'>
                        <span>{item.type}</span>
                    </div>
                    <div className='mt-2'>
                        <span className={`mdi mdi-circle mx-1 preview-icon ticket-status ${getStatusColor(item)}`}></span>
                        <span className='progress-text'>{item.statusName}</span>
                    </div>
                    <div className='mt-2'>
                        <span className={`mx-1 preview-icon ${getPriorityIcon(item)}`}></span>
                        <span className='progress-text'>{item.priorityName}</span>
                    </div>
                </div>
                <div className='ticket-info'>
                    <div className='upper-info py-2 px-3'>
                        <h2>
                            <span className='mr-2'>{`#${item.id}`}</span>{`${item.subject}`}
                        </h2>
                        <p>{`${item.description_text}`}</p>
                    </div>
                    <div
                        className='card-bottom'>
                        {item.created_at && <p className='mx-3'>
                            <span className='mr-1'>Created</span>
                            <span>{` ${moment(item.created_at).format('ll')}, at ${moment(item.created_at).format('LT')}`}</span>
                        </p>}
                        {item.updated_at && <p className='mx-3'>
                            <span className='mr-1'>Updated</span>
                            <span>{` ${moment(item.updated_at).format('ll')}, at ${moment(item.updated_at).format('LT')}`}</span>
                        </p>}
                    </div>
                </div>
            </div>))}
        </div>)
})
