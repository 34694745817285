import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import {
  AutocompleteComponent,
  DatePickerComponent,
  DialogComponent,
  Spinner,
  Tables,
} from '../../../../../Components';
import {
  CancelInvoice,
  CreateInvoicesFromPaymentPlanInstallment,
  GetAllPaymentPlanByPropertyId,
  GetAllPaymentPlans,
  GetInvoicesById,
  GetReservationInvoices,
} from '../../../../../Services';
import { TemplateViewDialog } from '../../../../Share/TemplateViewDialog/TemplateViewDialog';
import { TableActions, UnitsOperationTypeEnum } from '../../../../../Enums';
import { StaticLookupsIds } from '../../../../../assets/json/StaticLookupsIds';
import { ButtonBase, DialogContentText } from '@material-ui/core';
import './UnitInvoicePaymentDue.scss';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { InvoiceManagementDialog } from '../../UnitsStatusManagementView/Dialogs';
import { useDispatch } from 'react-redux';
import { showError, showSuccess } from '../../../../../Helper';
import InvoiceStatusEnum from '../../../../../Enums/InvoiceStatus.Enum';

const parentTranslationPath = 'UnitsProfileManagementView';
const translationPath = '';

export const UnitInvoicePaymentDue = ({
  unitTransactionId,
  propertyId,
  transactionActiveItem,
  state,
}) => {
  const headerData = [
    { id: 1, label: 'payment-no', input: 'paymentNo' },
    { id: 2, label: 'amount-due', input: 'amountDue' },
    { id: 3, label: 'type', input: 'paymentTypeName' },
    { id: 4, label: 'mode', input: 'paymentModeName' },
    { id: 4, label: 'ref-no', input: 'unitReferenceNo' },
    {
      id: 5,
      label: 'status',
      input: 'invoiceStatus',
      component: (item) => {
        let statusLabel = '';
        switch (item.invoiceStateId) {
          case 1:
            statusLabel = 'Paid';
            break;
          case 2:
            statusLabel = 'Unpaid';
            break;
          case 3:
            statusLabel = 'Partially paid';
            break;
          case 4:
            statusLabel = 'Canceled';
            break;
          default:
            statusLabel = '';
        }
        return <span>{statusLabel}</span>;
      },
    },
    { id: 6, label: 'net-amount', input: 'amountDue' },
  ];
  const { t } = useTranslation(parentTranslationPath);
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenTemplateDialog, setIsOpenTemplateDialog] = useState(false);
  const [templateId, settemplateId] = useState(null);
  const [paymentPlans, setPaymentPlans] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [isViewSale, setIsViewSale] = useState(false);
  const [propertyPaymentPlanId, setPropertyPaymentPlanId] = useState(null);
  const [isOpenInvoiceDialog, setIsOpenInvoiceDialog] = useState(false);
  const [displayCanceledInvoices, setDisplayCanceledInvoices] = useState(false);
  const [invoiceForCancelId, setInvoiceForCancelId] = useState(null);
  const [actionsList, setActionsList] = useState([]);

  const [list, setList] = useState([
    {
      enum: TableActions.addPrimaryText.key,
    },
    {
      enum: TableActions.edit.key,
    },
    {
      enum: TableActions.transactionText.key,
    },
    {
      enum: TableActions.openFile.key,
    },
  ]);
  const [unitId, setUnitId] = useState(null);
  const [invoicePayments, setInvoicePayments] = useState([]);

  const [activeItem, setActiveItem] = useState({});

  const [filter] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const basicInvoices = useMemo(
    () => invoicePayments?.filter((item) => item.invoiceStateId !== 4) ?? [],
    [invoicePayments]
  );
  const canceledInvoices = useMemo(
    () => invoicePayments?.filter((item) => item.invoiceStateId === 4) ?? [],
    [invoicePayments]
  );

  const getAllInvoices = useCallback(async () => {
    setIsLoading(true);
    const res = await GetReservationInvoices(unitTransactionId);
    if (!((res && res.data && res.data.ErrorId) || !res))
      setInvoicePayments(res);
    else setInvoicePayments([]);
    setIsLoading(false);
  }, [unitTransactionId]);

  const GetInvoicesIdAPI = useCallback(async (InvoicesId) => {
    const res = await GetInvoicesById(InvoicesId);
    if (!(res && res.status && res.status !== 200)) {
      setActiveItem(res);
      return res;
    } else {
      setActiveItem(null);
      return null;
    }
  }, []);

  const tableActionClicked = useCallback(
    async (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      const invoiceData = await GetInvoicesIdAPI(item.invoiceId);
      if (actionEnum === TableActions.PrintInvoice.key) {
        setIsOpenTemplateDialog(true);
        setUnitId(item.invoiceId || null);
        settemplateId(StaticLookupsIds.invoiceId);
      } else if (actionEnum === TableActions.PrintReceipt.key) {
        setIsOpenTemplateDialog(true);
        setUnitId(item.receiptId || null);
        settemplateId(StaticLookupsIds.receiptId);
      } else if (actionEnum === TableActions.openFile.key) {
        if (invoiceData) {
          history.push({
            pathname: '/home/invoices/view',
            state: { invoiceId: item.invoiceId, activeItem: invoiceData },
          });
        }
      } else if (actionEnum === TableActions.close.key) {
        setInvoiceForCancelId(item.invoiceId);
      }
    },
    []
  );

  const focusedRowChanged = (rowIndex, item) => {
    if (rowIndex === -1) return;
    const printReceiptAction = {
      enum: TableActions.PrintReceipt.key,
      title: t(`${translationPath}Shared:PrintReceipt`),
    };
    const printInvoiceAction = {
      enum: TableActions.PrintInvoice.key,
      title: t(`${translationPath}Shared:PrintInvoice`),
    };
    // if (rowIndex !== -1) {
    //   if (item && item.invoiceStatus === true) {
    //     list.splice(0, list.length);
    //     list.push(printReceiptAction, printInvoiceAction, {
    //       enum: TableActions.openFile.key,
    //     });
    //   } else if (item && item.invoiceStatus === false) {
    //     list.splice(0, list.length);
    //     list.push(printInvoiceAction, {
    //       enum: TableActions.openFile.key,
    //     });
    //   }
    // }
    if (InvoiceStatusEnum[item?.invoiceStateId] === InvoiceStatusEnum[2]) {
      setActionsList([
        { enum: TableActions.close.key, title: 'close' },
        {
          enum: TableActions.deleteText.key,
        },
        { enum: TableActions.openFile.key },
        printInvoiceAction,
      ]);
    } else if (
      InvoiceStatusEnum[item?.invoiceStateId] === InvoiceStatusEnum[3] ||
      InvoiceStatusEnum[item?.invoiceStateId] === InvoiceStatusEnum[1]
    ) {
      setActionsList([
        { enum: TableActions.openFile.key },
        printInvoiceAction,
        printReceiptAction,
      ]);
    } else {
      setActionsList([]);
    }
  };

  const cancelHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await CancelInvoice(invoiceForCancelId);
      if (!(res?.status && res.status !== 200)) {
        getAllInvoices();
        setInvoiceForCancelId(null);
        showSuccess(t('closed-successfully'));
      } else {
        throw res;
      }
    } catch (error) {
      showError(t('something-went-wrong'));
    }
  };

  const handleCreateInvoices = async () => {
    const body = {
      unitTransactionId,
      propertyPaymentPlanId,
      startDate,
    };
    const res = await CreateInvoicesFromPaymentPlanInstallment(body);
    if (!(res && res.status && res.status !== 200)) {
      if (unitTransactionId) {
        getAllInvoices();
        setStartDate('');
        setPropertyPaymentPlanId('');
      }
    }
  };

  const getAllPaymentPlans = async () => {
    const res = await GetAllPaymentPlanByPropertyId({
      pageIndex: 1,
      pageSize: 1000,
      propertyId: propertyId,
    });
    if (!(res && res.status && res.status !== 200)) {
      setPaymentPlans(res?.result);
    }
  };
  useEffect(() => {
    if (unitTransactionId) getAllInvoices();
  }, [getAllInvoices, unitTransactionId]);

  useEffect(() => {
    if (location.pathname.includes('sales')) setIsViewSale(true);
    getAllPaymentPlans();
  }, [location.pathname]);

  return (
    <div className='view-wrapper unit-Invoice-wrapper'>
      <Spinner isActive={isLoading} />
      <div className='header-view'>
        {isViewSale && !state && (
          <div className='header-view-sale'>
            <div className='payment-plans-component'>
              <AutocompleteComponent
                idRef='paymentPlansIdRef'
                labelValue='payment-plans'
                data={paymentPlans || []}
                multiple={false}
                displayLabel={(option) => option.paymentPlanName || ''}
                onChange={(e, newValue) => {
                  setPropertyPaymentPlanId(newValue?.propertyPaymentPlanId);
                }}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                selectedValues={paymentPlans?.find(
                  (item) => item.paymentPlans === propertyPaymentPlanId
                )}
              />
            </div>
            <div className='start-date-component'>
              <DatePickerComponent
                idRef='paymentPlansDateIdRef'
                labelValue='start-date'
                placeholder='DD/MM/YYYY'
                clearable={true}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onDateChanged={(event) => {
                  setStartDate(event ? new Date(event?._d) : null);
                }}
                value={startDate}
              />
            </div>
            <div className='insert-button'>
              <ButtonBase
                onClick={handleCreateInvoices}
                className='btns theme-solid'
              >
                <span className='mdi mdi-plus' />
                <span className='px-1'>{t(`${translationPath}insert`)}</span>
              </ButtonBase>
            </div>
          </div>
        )}

        <div className='d-flex mb-3'>
          <ButtonBase
            className='btns theme-solid mx-2'
            onClick={() => setIsOpenInvoiceDialog(true)}
          >
            <span className='mdi mdi-plus' />
            <span className='px-1'>{t(`${translationPath}add-invoice`)}</span>
          </ButtonBase>
          <ButtonBase
            className='btns theme-outline mx-2'
            onClick={() => setDisplayCanceledInvoices(true)}
          >
            <span class='mdi mdi-close-circle' />
            <span className='px-1'>
              {t(`${translationPath}show-canceled-invoices`, {
                ns: 'UnitsStatusManagementView',
              })}
            </span>
          </ButtonBase>
        </div>
      </div>

      <div className='d-flex-column invoice-payments-table'>
        <div className='w-100 px-3'>
          <div className='mb-2'>
            <Tables
              data={basicInvoices}
              headerData={headerData}
              defaultActions={actionsList}
              actionsOptions={{ onActionClicked: tableActionClicked }}
              activePage={filter.pageIndex}
              parentTranslationPath={parentTranslationPath}
              focusedRowChanged={focusedRowChanged}
              translationPath={translationPath}
              itemsPerPage={basicInvoices.length}
            />
          </div>
        </div>
        {displayCanceledInvoices && (
          <div className='py-5'>
            <h3>
              {t('canceled-invoices', { ns: 'UnitsStatusManagementView' })}
            </h3>
            {canceledInvoices.length > 0 ? (
              <Tables
                data={canceledInvoices ?? []}
                headerData={headerData}
                actionsOptions={{
                  onActionClicked: tableActionClicked,
                }}
                defaultActions={[]}
                activePage={filter.pageIndex}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                itemsPerPage={canceledInvoices?.length ?? 0}
              />
            ) : (
              <h6>{t('no-invoices', { ns: 'UnitsStatusManagementView' })}</h6>
            )}
          </div>
        )}
      </div>
      {isOpenTemplateDialog && (
        <TemplateViewDialog
          templateName='activeTemplateName'
          isOpen={isOpenTemplateDialog}
          unitId={unitId}
          templateId={templateId}
          isOpenChanged={() => setIsOpenTemplateDialog(false)}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {isOpenInvoiceDialog && (
        <InvoiceManagementDialog
          currentOperationType={
            isViewSale
              ? UnitsOperationTypeEnum.sale.key
              : UnitsOperationTypeEnum.rent.key
          }
          effectedByNumber={
            state
              ? isViewSale
                ? state?.sellingPrice
                : state?.rentPerYear
              : isViewSale
              ? transactionActiveItem?.sellingPrice
              : transactionActiveItem?.rentPerYear
          }
          totalInvoicesLength={invoicePayments?.length || 0}
          effectedByName={isViewSale ? 'selling-price' : 'rent-per-year'}
          activeItem={null}
          isOpen={isOpenInvoiceDialog}
          reloadData={() => {
            getAllInvoices();
            setIsOpenInvoiceDialog(false);
            setActiveItem(null);
          }}
          isOpenChanged={() => {
            setIsOpenInvoiceDialog(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          unitID={transactionActiveItem?.unitId}
          unitTransactionId={unitTransactionId}
        />
      )}
      {invoiceForCancelId && (
        <DialogComponent
          isOpen
          titleText={t('close-invoice', {
            ns: 'UnitsStatusManagementView',
          })}
          dialogContent={
            <DialogContentText>
              {t('close-text', {
                ns: 'UnitsStatusManagementView',
              })}
            </DialogContentText>
          }
          maxWidth={'sm'}
          onCloseClicked={() => setInvoiceForCancelId(null)}
          onCancelClicked={() => setInvoiceForCancelId(null)}
          onSaveClicked={cancelHandler}
          parentTranslationPath={'UnitsStatusManagementView'}
          translationPath={''}
          saveText={'close-invoice'}
        />
      )}
    </div>
  );
};
UnitInvoicePaymentDue.propTypes = {
  unitTransactionId: PropTypes.number,
};
UnitInvoicePaymentDue.defaultProps = {
  unitTransactionId: null,
};
