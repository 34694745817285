import React, { Children, useCallback, useRef, useState } from 'react';
import {
  CheckboxesComponent,
  Inputs,
  LoadableImageComponant,
} from '../../../../../Components';
import { ContactTypeEnum, LoadableImageEnum } from '../../../../../Enums';
import { getDownloadableLink } from '../../../../../Helper';
import { useTranslation } from 'react-i18next';

export const ContactCardViewer = ({
  cards,
  selectedItems,
  onChangeHandler,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const searchedItemRef = useRef(null);
  const [searchedItemId, setSearchedItemId] = useState('');
  const getDefaultContactImage = useCallback(
    (contactType) => ContactTypeEnum[contactType]?.defaultImg,
    []
  );
  const executeScroll = () =>
    searchedItemRef?.current?.scrollIntoView({
      block: 'end',
      behavior: 'smooth',
    });
  const searchHandler = (value) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setSearchedItemId(value);
      executeScroll();
    }, 500);
  };

  const inSearch = (card) =>
    searchedItemId.length > 0 &&
    [card.company_name, card.first_name, card.last_name].some((item) =>
      item?.toLowerCase().includes(searchedItemId?.toLowerCase())
    );
  const getRef = (card) => {
    if (inSearch(card)) {
      return searchedItemRef;
    } else return null;
  };

  const getIsSearchClass = (card) => (inSearch(card) ? 'is-search' : '');

  return (
    <div>
      <div className='contact-cards-container'>
        <div className='bulk-header-section'>
          {t(`${translationPath}selected-contacts`)}
        </div>

        <div className='bulk-sub-header-section'>
          {t(`${translationPath}you-can-deselect-contact-before-continue`)}
        </div>
        <div className='mt-2'>
          <Inputs
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            idRef='unitsSearchRef'
            inputPlaceholder='search-contacts'
            onKeyUp={(e) => searchHandler(e.target.value)}
            startAdornment={
              <span className='mdi mdi-magnify mdi-24px c-gray' />
            }
          />
        </div>
        {Children.toArray(
          cards?.map((card) => (
            <div
              className={`${getIsSearchClass(card)} contact-card`}
              ref={getRef(card)}
            >
              <div className='d-flex gap-1'>
                <LoadableImageComponant
                  classes='avatar-image'
                  type={LoadableImageEnum.image.key}
                  // alt={t(`${translationPath}contact-image`)}
                  src={
                    !!card.imagePath
                      ? getDownloadableLink(card.imagePath)
                      : getDefaultContactImage(card.type)
                  }
                />
                <p>
                  <h6>{card.name}</h6>
                  <strong>{card.id}</strong>
                </p>
              </div>
              <CheckboxesComponent
                singleChecked={
                  !!selectedItems?.find((item) => item.id === card.id)
                }
                onSelectedCheckboxChanged={() => onChangeHandler(card.id)}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ContactCardViewer;
