import { ActiveUsersView, PendingActivationView, DeactivatedUsersView,
   PendingDeactivationManagement } from "./UsersTabsViews";
import { UsersManagementPermissions, UserLoginPermissions } from '../../../../Permissions';

export const UsersManagementTabsData = [
  {
    label: "ActiveUsers",
    statusId: 1,
    component: ActiveUsersView,
    permissionsList: Object.values(UsersManagementPermissions),
    permissionsId: UsersManagementPermissions.ViewActiveUsers.permissionsId,
  },
  {
    label: "PendingActivation",
    statusId: 2,
    component: PendingActivationView,
    permissionsList: Object.values(UsersManagementPermissions),
    permissionsId: UsersManagementPermissions.ViewPendingActivationUsers.permissionsId,
  },
  {
    label: "DeactivatedUsers",
    statusId: 3,
    component: DeactivatedUsersView,
    permissionsList: Object.values(UsersManagementPermissions),
    permissionsId: UsersManagementPermissions.ViewDeactivatedUsers.permissionsId,
  },
  {
    label: "PendingDeactivation",
    statusId: 4,
    component: PendingDeactivationManagement,
    permissionsList: Object.values(UsersManagementPermissions),
    permissionsId: UsersManagementPermissions.ViewPendingDeactivationUsers.permissionsId,
  },
  {
    label: "All",
    statusId: null,
    component: null,
    permissionsList: Object.values(UserLoginPermissions),
    permissionsId: UserLoginPermissions.ViewUsers.permissionsId,
  },
];
