import React from "react";
import { CreateInvoiceForm } from "./CreateInvoiceForm";
import { PaymentInvoicesTable } from "./PaymentInvoicesTable";

export const PaymentInvoicesView = ({
  unitTransactionId,
  activeInvoiceHandler,
  activeViewHandler,
  translationPath,
  parentTranslationPath,
  paymentInvoices,
  reloadInvoices
}) => {


  return (
    <div>
      <CreateInvoiceForm
        unitTransactionId={unitTransactionId}
        reloadInvoices={reloadInvoices}
        numberOfPaymentInvoices={paymentInvoices?.length || 0}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
      <PaymentInvoicesTable
        paymentInvoices={paymentInvoices}
        activeInvoiceHandler={activeInvoiceHandler}
        activeViewHandler={activeViewHandler}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
    </div>
  );
};
