import { config } from "../../config";
import { HttpServices } from "../../Helper";

export const GetAllSystemNotifications = async (body) => {
    const result = await HttpServices.post(`${config.server_address}/CrmDfm/ExternalApis/GetAllSystemNotifications`, body)
        .then(data => data)
        .catch(error => error.response);
    return result;
}
export const GetMyNotifications = async (body) => {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/Activities/GetMyNotifications`, body
    )
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };
