import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    container_Associated_Contact_Card: {
      padding: "20px 16px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "314.2px",
    },
    container_Info: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    container_Personal_info: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    container_Avatar_moreIcon: {
      display: "flex",
      position: "relative",
      justifyContent: "center",
    },
    conatainer_MoreIcon: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      gap: "2px",
      position: "absolute",
      top: "0px",
      right: "10px",
    },
    container_personal_Details: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      gap: "4px",
      marginBottom:'14px'
    },
    container_ArrowUpRight_Name: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "60px",
      gap: "6px",
    },
    containerId: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "6px",
    },container_contact_details:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'

    },container_Email_phone:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        gap:'3px',
        justifyContent:'center'
    },containerDash:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },container_CreatedOn:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        gap:'3px',
        justifyContent:'center'
    }
  };
});
