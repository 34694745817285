import { config } from '../config/config';
import { HttpServices } from '../Helper';


export const GetAllExternalLinks = async (body, isAdmin) => {
  const queryList = [];
  if (isAdmin) queryList.push(`isAdmin=${isAdmin}`);

  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Externals/GetAllExternalLinks?${queryList.join(
      '&'
    )}`, body
  ).then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const CreateExternalLink = async (body) => {

  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Externals/CreateExternalLink`, body
  ).then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateExternalLink = async (body, externalLinkId ) => {

  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Externals/UpdateExternalLink/${externalLinkId}`, body
  ).then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const DeleteExternalLink = async (externalLinkId) => {

  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/Externals/DeleteExternalLink/${externalLinkId}`
  ).then((data) => data)
    .catch((error) => error.response);
  return result;
};
