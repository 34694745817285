import React, { useCallback, useReducer, useRef, useState } from "react";
import { DialogActions, DialogContent, ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { showSuccess, getErrorByName } from "../../../../../Helper";
import { LeadsClassTypesEnum, AgentRoleEnum } from "../../../../../Enums";
import {
  UpdateUnitsListingAgent,
  ReAssignDeActiveUserLeads,
} from "../../../../../Services/userServices";
import { OrganizationUserSearch } from "../../../../../Services";
import {
  DialogComponent,
  AutocompleteComponent,
  SwitchComponent,
  Spinner
} from "../../../../../Components";
import xCloseIcon from "../../../../../assets/images/icons/x-close-dialog.svg";

export const ReassignListingAgentDialog = ({
  isDialogOpen,
  onClose,
  onSave,
  parentTranslationPath,
  translationPath,
  unitReassignProps,
  leadReassignProps,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useReducer(reducer, {
    referredTo: null,
    isCopyTo: false,
  });
  const [data, setData] = useReducer(reducer, {
    systemUsers: [],
  });

  const getUserRoleForLead = () => {
    let userRole = null;

    if (leadReassignProps.leadType === LeadsClassTypesEnum.buyer.name)
      userRole = AgentRoleEnum.SaleAgent.value;
    if (leadReassignProps.leadType === LeadsClassTypesEnum.tenant.name)
      userRole = AgentRoleEnum.LeaseAgent.value;
    if (leadReassignProps.leadType === LeadsClassTypesEnum.seller.name)
      userRole = AgentRoleEnum.SaleListingAgent.value;
    if (leadReassignProps.leadType === LeadsClassTypesEnum.landlord.name)
      userRole = AgentRoleEnum.LeaseListingAgent.value;

    return userRole;
  };

  const getUserRoleForUnit = () => {
    let userRole = null;

    if (unitReassignProps.isSaleListing) userRole = AgentRoleEnum.SaleListingAgent.value;
    else userRole = AgentRoleEnum.LeaseListingAgent.value;

    return userRole;
  }

  const getSystemUsers = async (searchedItem) => {

    const userTypeId = leadReassignProps? getUserRoleForLead() : getUserRoleForUnit();

    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchedItem || "",
      userStatusId: 2,
      userTypeId,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "systemUsers", value: res?.result });
    else setData({ id: "systemUsers", value: [] });
  };

  const getUnitsListingBody = () => {
    const body = {
      ...unitReassignProps,
      isCopyTo: selected.isCopyTo || false,
      newListingAgentId: selected.referredTo?.id || null,
    };

    return body;
  };

  const getLeadReassignBody = () => {
    const body = {
      ...leadReassignProps,
      isCopyTo: selected.isCopyTo || false,
      referredToId: selected.referredTo?.id || null,
    };

    return body;
  };

  const updateUnitsListingAgent = async () => {
    setIsLoading(true);

    const body = getUnitsListingBody();

    const res = await UpdateUnitsListingAgent(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}reassign-proccess-notifications`));
      onSave();
    }
    setIsLoading(false);

  };

  const reAssignDeActiveUserLeads = async () => {
    setIsLoading(true);

    const body = getLeadReassignBody();

    const res = await ReAssignDeActiveUserLeads(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}reassign-proccess-notifications`));
      onSave();
    }
    setIsLoading(false);

  };

  const saveHandler = () => {
    // if (schema.error) {
    //   showError(t("Shared:please-fix-all-errors"));
    //   return;
    // }
    if (unitReassignProps) updateUnitsListingAgent();
    if (leadReassignProps) reAssignDeActiveUserLeads();
  };
  return (
    <div>
      <DialogComponent
        isOpen={isDialogOpen}
        maxWidth="sm"
        dialogTitle={
          <div className="user-groups-view add-task-dialog pb-3 w-100">
            <div className="d-flex-v-center-h-between">
              <div className="fw-simi-bold">
                {t(`${translationPath}Reassign-listing-agent`)}
              </div>
              <div className="xCloseIcon pointer" onClick={onClose}>
                <img src={xCloseIcon} />
              </div>
            </div>
          </div>
        }
        dialogContent={
          <div className="user-groups-view ">
            <DialogContent>
              <>
              <Spinner isActive={isLoading} />
                <div className="d-flex fj-start b-bottom">
                  <div className="w-33 mt-2 pt-1">
                    <span className="fw-simi-bold">
                      {t(`${translationPath}referred-to`)}
                    </span>
                  </div>
                  <div className="w-75 mt-2 mb-1 pt-1">
                    <AutocompleteComponent
                      idRef="referred-toRef"
                      inputPlaceholder={t(`${translationPath}select`)}
                      wrapperClasses="w-100 mb-2"
                      selectedValues={selected.referredTo || []}
                      data={data.systemUsers || []}
                      displayLabel={(option) =>
                        (option && option.fullName) ||
                        (option && option.firstName) ||
                        ""
                      }
                      multiple={false}
                      withoutSearchButton
                      onOpen={() => {
                        if (data.systemUsers && data.systemUsers.length == 0)
                          getSystemUsers();
                      }}
                      onChange={(_, newValue) => {
                        setSelected({ id: "referredTo", value: newValue });
                      }}
                      onInputKeyUp={(e) => {
                        const { value } = e.target;
                        if (searchTimer) clearTimeout(searchTimer.current);
                        searchTimer.current = setTimeout(() => {
                          getSystemUsers(value);
                        }, 1200);
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex-center b-bottom pt-2 pb-3">
                  <SwitchComponent
                    idRef="is-copyRef"
                    themeClass="thick-theme"
                    isChecked={selected.isCopyTo || false}
                    labelValue={t(`${translationPath}Copy-all-activities`)}
                    onChangeHandler={(_, isChecked) => {
                      setSelected({ id: "isCopyTo", value: isChecked });
                    }}
                  />
                </div>
              </>
            </DialogContent>
            <DialogActions>
              <div className="d-flex-center fj-end py-0 pt-3">
                <ButtonBase
                  onClick={onClose}
                  className="btns theme-propx outlined"
                >
                  {t(`${translationPath}cancel`)}
                </ButtonBase>

                <ButtonBase
                  className="btns theme-propx solid mr-0"
                  onClick={saveHandler}
                  disabled={isLoading}
                >
                  {(unitReassignProps?.isCompleteAction || 
                     leadReassignProps?.isCompleteAction)? t(`${translationPath}complete`) : t(`${translationPath}Reassign`)}
                </ButtonBase>
              </div>
            </DialogActions>
          </div>
        }
      />
    </div>
  );
};
