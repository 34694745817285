import React, { useState, useEffect, useCallback } from 'react';
import {
  CityTypeIdEnum,
  NationalityEnum,
} from '../../../../../../../../../../Enums';
import { lookupItemsGetId } from '../../../../../../../../../../Services/LookupsServices';
import { AutocompleteComponent } from '../../../../../../../../../../Components';

export const CountryComponent = ({
  parentTranslationPath,
  translationPath,
  setCountryId,
  countryId,
  selected,
  setSelected,
  setCityList,
  addressLoadings ,
  setAddressLoadings ,
}) => {
  // const { t } = useTranslation([parentTranslationPath, 'Shared']);
    // eslint-disable-next-line no-unused-vars
 
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    setCityList(cities);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cities]);

  const GetCountries = useCallback(async () => {
    setAddressLoadings((item) => ({ ...item, country:true }));

    const res = await lookupItemsGetId({
      lookupTypeId: NationalityEnum.lookupTypeId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setCountries(res);
      
     
    } else
      setCountries([]);
      setAddressLoadings((item) => ({ ...item, country:false }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetCities = useCallback(async (countryId) => {
    setAddressLoadings((item) => ({ ...item, city:true }));
    const res = await lookupItemsGetId({
      lookupTypeId: CityTypeIdEnum.lookupTypeId,
      lookupParentId: countryId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setCities(res);
    } else
      setCities([]);

    setAddressLoadings((item) => ({ ...item, city:false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GetCountries('');
  }, [GetCountries]);

  useEffect(() => {
    if (countryId && !selected.Country && countries.length > 0) {
      const countryIndex = countries.findIndex((item) => item.lookupItemId === countryId);

      if (countryIndex !== -1) setSelected(countries[countryIndex]);
      else setCountryId(null);
    }
  }, [countries, countryId, selected.Country, setSelected, setCountryId]);

  return (
    <div>
      {countries && (
        <AutocompleteComponent
          idRef='countryRef'
          labelValue='Country'
          isLoading={addressLoadings.country}
          selectedValues={selected.Country}
          data={countries || []}
          displayLabel={(option) => option.lookupItemName || ''}
          getOptionSelected={(option) => option.lookupItemId === selected.Country.lookupItemId}
          multiple={false}
          withoutSearchButton
          onChange={(event, newValue) => {
            setSelected(newValue);
            setCountryId(newValue ? newValue.lookupItemId : '');
            if (newValue)
              GetCities(newValue.lookupItemId);
            else {
              setCities(null);
              setSelected(null);
            }
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </div>
  );
};
