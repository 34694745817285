import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { useDropzone } from "react-dropzone";

import { useSelectedTheme, useTranslate } from "../../../Hooks";
import { uploadFile, importFile } from "../../../Services";
import { UploadCloudIcon } from "../../../assets/icons";

import useStyles from "./styles";

// TODO: handle multiple file upload
const DropzoneComponent = ({
  accept,
  title,
  onFilesAccepted,
  setUploadedFileId, // Send the uploaded fileId to the parent component
}) => {
  const styles = useStyles();
  const { theme } = useSelectedTheme();
  const { translate } = useTranslate("NewContactsView");
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: accept || undefined,
    onDrop: (acceptedFiles) => {
      setAcceptedFiles(acceptedFiles);
      onFilesAccepted(acceptedFiles); // Send the files to the parent component
    },
  });

  const uploadHandler = () => {
    if (acceptedFiles[0]) {
      uploadFile({ file: acceptedFiles[0] })
        .then((response) => {
          setUploadedFileId(response?.uuid);
        })
        .catch((e) => {
          console.log("error", e);
        });
    }
  };

  useEffect(() => {
    uploadHandler();
  }, [acceptedFiles]);

  return (
    <Box className={styles.dropzoneContainer}>
      <Box {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <UploadCloudIcon
          style={{ marginBottom: "10px" }}
          width="20"
          height="20"
          fill={theme.palette.button.secondary_fg}
        />
        <Box>
          <span className={styles.clickText}>Click to upload&nbsp;</span>
          <span className={styles.dragText}>or drag and drop</span>
        </Box>
        <span>{title}</span>
      </Box>
    </Box>
  );
};

DropzoneComponent.defaultProps = {
  accept: undefined,
};

DropzoneComponent.propTypes = {
  title: PropTypes.string.isRequired,
  accept: PropTypes.string,
  onFilesAccepted: PropTypes.func.isRequired,
};

export default DropzoneComponent;
