import React, { useEffect, useState, useCallback, useReducer, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from "react-i18next";
import { AutocompleteComponent } from '../../../../../../../Components';
import { lookupItemsGetId, OrganizationUserSearch } from '../../../../../../../Services';
import { StaticLookupsIds } from '../../../../../../../assets/json/StaticLookupsIds';
import { getErrorByName } from '../../../../../../../Helper';

export const OtherDetailsSection = ({
  schema,
  isSubmitted,
  isReadOnly,
  selected,
  onSelectedChanged,
  parentTranslationPath,
  translationPath
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [data, setData] = useReducer(reducer, {
    reservationTypes: [],
    rentFreePeriods: [],
    systemUsers: {
      transactedBy: [],
    },
  });

  const getAllReservationTypes = async () => {
    // setLoadings((items) => ({ ...items, reservationTypes: true }));
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.ReservationType,
    });
    if (!(res && res.status && res.status !== 200)) setData({ id: "reservationTypes", value: res || [] });
    else setData({ id: "reservationTypes", value: [] });

    // setLoadings((items) => ({ ...items, reservationTypes: false }));
  };

  const getAllRentFreePeriods = async () => {
    // setLoadings((items) => ({ ...items, rentFreePeriods: true }));
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.RentFreePeriod,
    });
    if (!(res && res.status && res.status !== 200)) setData({ id: "rentFreePeriods", value: res || [] });
    else setData({ id: "rentFreePeriods", value: [] });
    // setLoadings((items) => ({ ...items, rentFreePeriods: false }));
  };

  const getSystemUsers = async (userType, searchedItem) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchedItem || "",
      userStatusId: 2,
    });
    if (!(res && res.status && res.status !== 200))
      setData({
        id: "systemUsers", value: {
          ...(data.systemUsers || []),
          [userType]: res?.result
        }
      });
    else setData({ id: "systemUsers", value: [] });
  };


  return (
    <>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}Reservation-type`)}
          </span>
        </div>
        <div className="w-50 mt-2 mb-3 pt-1">
          <AutocompleteComponent
            idRef='reservationTypeIdRef'
            inputPlaceholder='select'
            selectedValues={selected.reservationType}
            multiple={false}
            data={data.reservationTypes || []}
            displayLabel={(option) => option.lookupItemName || ''}
            // getOptionSelected={(option) => option.lookupItemId === state.reservationTypeId}
            withoutSearchButton
            helperText={getErrorByName(schema, 'reservationTypeId').message}
            error={getErrorByName(schema, 'reservationTypeId').error}
            isWithError
            // isLoading={loadings.reservationTypes}
            isSubmitted={isSubmitted}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onOpen={() => {
              if (data.reservationTypes && data.reservationTypes.length == 0)
                getAllReservationTypes();
            }}
            onChange={(_, newValue) => {
              onSelectedChanged({ id: 'reservationType', value: newValue });
              // onStateChanged({
              //   id: 'reservationTypeId',
              //   value: (newValue && newValue.lookupItemId) || null,
              // });
            }}
          isDisabled={isReadOnly}
          />
        </div>
      </div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}Rent-free-period`)}
          </span>
        </div>
        <div className="w-50 mt-2 mb-3 pt-1">
          <AutocompleteComponent
            idRef='rentFreePeriodIdRef'
            inputPlaceholder='select'
            selectedValues={selected.rentFreePeriod}
            multiple={false}
            data={data.rentFreePeriods || []}
            displayLabel={(option) => option.lookupItemName || ''}
            // getOptionSelected={(option) => option.lookupItemId === state.rentFreePeriod}
            withoutSearchButton
            isWithError
            // isLoading={loadings.rentFreePeriods}
            // isSubmitted={isSubmitted}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onOpen={() => {
              if (data.rentFreePeriods && data.rentFreePeriods.length == 0)
                getAllRentFreePeriods();
            }}
            onChange={(_, newValue) => {
              onSelectedChanged({ id: 'rentFreePeriod', value: newValue });
              // onStateChanged({
              //   id: 'rentFreePeriodId',
              //   value: (newValue && newValue.lookupItemId) || null,
              // });
            }}
          isDisabled={isReadOnly}
          />
        </div>
      </div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}Transacted-by`)}
          </span>
        </div>
        <div className="w-50 mt-2 mb-3 pt-1">
          <AutocompleteComponent
            idRef='transactedByIdRef'
            inputPlaceholder='select'
            selectedValues={selected.transactedBy}
            multiple={false}
            data={data.systemUsers?.transactedBy || []}
            displayLabel={(option) => option.fullName || ''}
            renderOption={(option) =>
              ((option.userName || option.fullName) &&
                `${option.fullName} (${option.userName})`) ||
              ""
            }
            // getOptionSelected={(option) => option.id === state.transactedById}
            withoutSearchButton
            isWithError
            // isSubmitted={isSubmitted}
            // isLoading={loadings.transactedBy}
            onOpen={() => {
              const userType = "transactedBy";
              if (data.systemUsers?.transactedBy && data.systemUsers.transactedBy.length == 0)
                getSystemUsers(userType);
            }}
            onInputKeyUp={(e) => {
              const { value } = e.target;
              const userType = "transactedBy";

              if (searchTimer) clearTimeout(searchTimer.current);

              searchTimer.current = setTimeout(() => {
                getSystemUsers(userType, value);
              }, 1200);
            }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onChange={(_, newValue) => {
              onSelectedChanged({ id: 'transactedBy', value: newValue });
              // onStateChanged({
              //   id: 'transactedById',
              //   value: (newValue && newValue.id) || null,
              // });
            }}
          //isDisabled={isReadOnly}
          isDisabled

          />
        </div>
      </div>
    </>
  )
}

OtherDetailsSection.propTypes = {
  schema: PropTypes.object,
  isSubmitted: PropTypes.bool,
}
OtherDetailsSection.defaultProps = {
  schema: {},
  isSubmitted: false,
}