export const Status = {
  InActive: {
    name: 'InActive',
    value: 1,
  },
  Active: {
    name: 'Active',
    value: 2,
  },
  Pending: {
    name: 'Pending',
    value: 3,
  },
};
