import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => {
  return {
    
    container_assignedAgentsWrapper:{
      display:'flex',
      flex:'1 1 100%',
      flexWrap:'wrap',
      gap:'18px',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center'
      },
    }
  };
});
