export const UnitProfileImagesCardActionEnum = {
  Show: {
    name: 'Show',
    value: 1,
  },
  Hide: {
    name: 'Hide',
    value: 2,
  },
};
