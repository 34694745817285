import React, { useState, useEffect, useCallback } from 'react';
import { CommunityTypeIdEnum } from '../../../../../../../../../../Enums';
import { AutocompleteComponent } from '../../../../../../../../../../Components';
import { lookupItemsGetId } from '../../../../../../../../../../Services/LookupsServices';

export const DistrictComponent = ({
  parentTranslationPath,
  translationPath,
  districtId,
  setDistrictId,
  selected,
  setSelected,
  districtList,
  setCommunityList,
  addressLoadings ,
  setAddressLoadings 
}) => {
  const [community, setCommunity] = useState([]);

  useEffect(() => {
    setCommunityList(community);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community]);

  const GetCommunity = useCallback(async (districtId) => {
    setAddressLoadings((item) => ({ ...item, community :true }));
    const res = await lookupItemsGetId({
      lookupTypeId: CommunityTypeIdEnum.lookupTypeId,
      lookupParentId: districtId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setCommunity(res);
     
    } else
      setCommunity([]);
      setAddressLoadings((item) => ({ ...item, community :false }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (districtId && !selected.District && districtList.length > 0) {
      const districtIndex = districtList.findIndex(
        (item) => item.lookupItemId === districtId
      );
      if (districtIndex !== -1) setSelected(districtList[districtIndex]);
      else setDistrictId(null);
    }
  }, [districtList, districtId, selected.District, setSelected, setDistrictId]);

  return (
    <div>
      <AutocompleteComponent
        idRef='districtRef'
        labelValue='district'
        multiple={false}
        isLoading={addressLoadings.district}
        selectedValues={selected.District}
        data={districtList && districtList ? districtList : []}
        displayLabel={(option) => option.lookupItemName || ''}
        getOptionSelected={(option) => option.lookupItemId === selected.District.lookupItemId}
        wrapperClasses='mx-1'
        withoutSearchButton
        onChange={(event, newValue) => {
          setSelected(newValue);
          setDistrictId(newValue && newValue.lookupItemId);
          if (newValue)
            GetCommunity(newValue.lookupItemId);
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
    </div>
  );
};
