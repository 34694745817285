import { MortgageActivitiesView } from '../../Views/Home/MortgageActivitiesView/MortgageActivitiesView' ;

export const ActivitiesMortgageRoutes = [
    {
        path: '/view',
        name: 'ActivitiesView:activities',
        component: MortgageActivitiesView,
        layout: '/home/activities-mortgage',
        default: true,
        isRoute: true,
        authorize: true,
        roles: [],
        isDisabled: false,
        isExact: true,
        breadcrumbs: [
          {
            name: 'ActivitiesView:activities',
            isDisabled: true,
            route: '/home/activities-mortgage/view',
            groupName: 'mortgage',
          },
        ],
    }
  ];