import React, { useState, useCallback, useReducer, useEffect } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  ButtonBase,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import {
  showError,
  showSuccess,
  GetParams,
  getErrorByName,
} from '../../../../../../Helper';
import {
  Spinner,
  Inputs,
  DatePickerComponent,
  AutocompleteComponent,
  SelectComponet,
} from '../../../../../../Components';
import {
  PropertyPaymentPlan,
  PaymentPlanStatus,
} from '../../../../../../Enums';

import {
  GetLookupItemsByLookupTypeName,
  CreatePaymentPlan,
  UpdatePaymentPlan,
} from '../../../../../../Services';
import { Tabs, Tab, makeStyles, InputAdornment } from '@material-ui/core';

export const PaymentPlanManangementDialog = ({
  parentTranslationPath,
  translationPath,
  activeItem,
  isDialogOpen,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: '0px',
      paddingBottom: '5px',
      textTransform: 'initial',
    },
    indicator: {
      backgroundColor: 'var(--c-primary)',
    },
    labelIcon: {
      minHeight: 'unset',
    },
    wrapper: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'row',
    },
    fixed: {
      width: 'fit-content',
      margin: 'auto',
    },
    scroller: {
      flex: 'unset',
    },
  }));

  const classes = useStyles();
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const [filter, setFilter] = useState({
    pageSize: 100,
    pageIndex: 0,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useReducer(reducer, {
    planType: false,
  });
  const [selected, setSelected] = useReducer(reducer, {
    planType: null,
  });
  const [data, setData] = useReducer(reducer, {
    planTypes: [],
  });

  const [state, setState] = useReducer(reducer, {
    propertyId: +GetParams('id'),
    propertyPaymentPlanId: activeItem?.propertyPaymentPlanId ?? null,
    paymentPlanName: null,
    planTypeId: null,
    propertyPlanStatusId: null,
    description: '',
  });

  const schema = Joi.object({
    planTypeId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}plan-type-is-required`),
        'number.empty': t(`${translationPath}plan-type-is-required`),
      }),
    paymentPlanName: Joi.string()
      .required()
      .messages({
        'number.base': t(`${translationPath}paymentType-is-required`),
        'number.empty': t(`${translationPath}paymentType-is-required`),
      }),
    propertyPlanStatusId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}plan-status-is-required`),
        'number.empty': t(`${translationPath}plan-status-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const getPlanTypeLookups = useCallback(async () => {
    setIsLoading({ id: 'planType', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: PropertyPaymentPlan.PlanType.lookupTypeName,
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'planTypes', value: res.result || [] });
    } else setData({ id: 'planTypes', value: [] });
    setIsLoading({ id: 'planType', value: false });
  }, []);

  const saveHandler = async (event) => {
    event.preventDefault();

    setIsSaving(true);
    setIsSubmitted(true);

    if (schema.error) {
      showError(t('please-fill-all-Required-fields'));
      setIsSubmitted(false);
      setIsSaving(false);
      return;
    }
    const body = { ...state };
    const res = activeItem
      ? await UpdatePaymentPlan({
          paymentPlanId: activeItem.propertyPaymentPlanId,
          body,
        })
      : await CreatePaymentPlan(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(
        t(
          `${translationPath}${
            (activeItem && `payment-plan-updated-successfully`) ||
            `payment-plan-created-successfully`
          }`
        )
      );
      if (onSave) onSave();
    } else {
      showError(
        t(
          `${translationPath}${
            (activeItem && `payment-plan-updated-failed`) ||
            `payment-plan-created-failed`
          }`
        )
      );
    }
    setIsSubmitted(false);
    setIsSaving(false);
  };

  useEffect(() => {
    getPlanTypeLookups();
  }, []);

  useEffect(() => {
    if (activeItem) {
      setState({
        id: 'edit',
        value: {
          paymentPlanName: activeItem.paymentPlanName,
          propertyId: activeItem.propertyId,
          planTypeId: activeItem.planTypeLookupDto?.planTypeLookupId,
          propertyPlanStatusId: activeItem.propertyPlanStatusId,
          description: activeItem.description || '',
        },
      });

      if (activeItem.planTypeLookupDto) {
        setSelected({
          id: 'planType',
          value: {
            lookupItemId: activeItem.planTypeLookupDto.planTypeLookupId,
            lookupItemName: activeItem.planTypeLookupDto.planTypeLookupName,
          },
        });
      }
    }
  }, [activeItem, data]);

  return (
    <div>
      <Dialog
        open={isDialogOpen}
        onClose={onClose}
        className='activities-management-dialog-wrapper'
      >
        <Spinner isActive={isSaving} isAbsolute />
        
        <form noValidate onSubmit={saveHandler}>
          <DialogTitle id='alert-dialog-slide-title'>
            {t(

              `${translationPath}${
                activeItem ? 'edit-payment-plan' : 'add-new-payment-plan'
              }`
            )}
          </DialogTitle>
          <DialogContent>
            <div className='d-flex p-3'>
              <div className='w-50 mx-2'>
                <div className='my-1'></div>
                <div className='my-2'>
                  <Inputs
                    idRef='planNameRef'
                    labelValue={'plan-name'}
                    labelClasses='Requierd-Color'
                    value={state.paymentPlanName ?? ''}
                    onInputChanged={(e) =>
                      setState({ id: 'paymentPlanName', value: e.target.value })
                    }
                    isWithError
                    isSubmitted={isSubmitted}
                    helperText={
                      getErrorByName(schema, 'paymentPlanName').message
                    }
                    error={getErrorByName(schema, 'paymentPlanName').error}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />
                </div>
                <div className='my-2'>
                  <AutocompleteComponent
                    idRef='planTypeIdRef'
                    labelValue='plan-type'
                    labelClasses='Requierd-Color'
                    selectedValues={selected.planType}
                    multiple={false}
                    data={data.planTypes}
                    displayLabel={(option) => option.lookupItemName || ''}
                    renderOption={(option) => option.lookupItemName || ''}
                    getOptionSelected={(option) =>
                      option.lookupItemId === state.planTypeId
                    }
                    withoutSearchButton
                    isLoading={isLoading.planType}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                      setSelected({ id: 'planType', value: newValue });
                      setState({
                        id: 'planTypeId',
                        value: (newValue && newValue.lookupItemId) || null,
                      });
                    }}
                    isWithError
                    isSubmitted={isSubmitted}
                    helperText={getErrorByName(schema, 'planTypeId').message}
                    error={getErrorByName(schema, 'planTypeId').error}
                  />
                </div>
              </div>

              <div className='w-50 mx-2'>
                <div className='my-2'>
                  <SelectComponet
                    idRef='planStatusIdRef'
                    labelValue='plan-status'
                    labelClasses='Requierd-Color'
                    data={Object.values(PaymentPlanStatus)}
                    value={state.propertyPlanStatusId}
                    valueInput='key'
                    textInput='value'
                    onSelectChanged={(value) =>
                      setState({ id: 'propertyPlanStatusId', value })
                    }
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                    isWithError
                    isSubmitted={isSubmitted}
                    helperText={
                      getErrorByName(schema, 'propertyPlanStatusId').message
                    }
                    error={getErrorByName(schema, 'propertyPlanStatusId').error}
                  />
                </div>
                <div className='my-3'>
                  <Inputs
                    idRef='descriptionIdRef'
                    labelValue='description'
                    value={state.description || ''}
                    multiline
                    rows={4}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onInputChanged={(event) => {
                      setState({
                        id: 'description',
                        value: event.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonBase
              onClick={onClose}
              className='btns theme-solid bg-cancel'
            >
              {t(`${translationPath}cancel`)}
            </ButtonBase>
            <ButtonBase
              className='btns theme-solid'
              // onClick={saveHandler}
              type='submit'
            >
              {t(`${translationPath}save`)}
            </ButtonBase>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
