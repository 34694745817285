export const ContactsFieldsId = [
  'lead_owner',
  'contact_name',
  'inventory_list-from',
  'representative_contact',
  'works_company-name',
  'spouse_contact',
  'property_owner',
  'owner_representative-person',
  'property_managed-by',
  'master_developer',
  'assigned_real-estate-agent',
  'secondary_developer',
  'project_manager-company',
  'consultant_company',
  'main_contractor',
  'land_scaping-contractor',
  'electro_mechanical-contractor',
  'site_engineer',
  'site_manager',
  'design_consultant'
];
