import React, {
    useCallback, useState, useReducer, useEffect, useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { AutocompleteComponent, DialogComponent, Inputs } from '../../../../../../Components';
import { GetParams, showError, showSuccess } from '../../../../../../Helper';

import { GetAllLeaseOrSaleAgentsServices } from '../../../../../../Services/AgentsServices';
import { AssignAgentToRotationScheme, CreateAgentRotationScheme, GetSuitableAgentsForRotationScheme, } from '../../../../../../Services/RotaionSchemaService/RotationSchemaService';
import './AssignAgent.scss';
import { useSelector } from 'react-redux';
import { calculateCapacities } from '../../../AgentsDialogsView/capacityUtils';

export const AssignAgentDialog = ({
    parentTranslationPath,
    translationPath,
    isOpen,
    isClose,
    reloadData,
    activeItem,
    currentAssignedAgent,
    data
}) => {
    const { t } = useTranslation([parentTranslationPath]);


    const activeItemSelector = useSelector((state) => state.ActiveItemReducer);
    console.log("🚀 ~ activeItemSelector:", activeItemSelector)
    const initialState = {
        agent: null,
        dailyCapacity: 0,
        weeklyCapacity: 0,
        monthlyCapacity: 0,
        agentLeadCapacityInRotation: 0,
    };
    const reducer = useCallback((state, action) => {
        if (action.id === 'capacities') return {agent : state.agent , ...action.value}
        if (action.id !== 'edit') return { ...state, [action.id]: action.value };
        return {
            ...action.value,
        };
    }, []);
    const reducer2 = useCallback((state, action) => {
        if (action.id !== 'edit') return { ...state, [action.id]: action.value };
        return {
            ...action.value,
        };
    }, []);
    const [state, setState] = useReducer(reducer, initialState);
    const [timer, setTimer] = useState('');
    const [saveIsDisabled, setSaveIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [allAgents, setAllAgents] = useState([]);
    const [selectedValue, setSelectedValue] = useReducer(reducer2, {
        agent: null,
        agentMultiple: []
    });


    const getAllAgent = useCallback(async (search, teamIds, branchIds, courseFilters) => {
        setIsLoading(true);
        const res = await GetSuitableAgentsForRotationScheme(1, 100000, { teamIds: teamIds, branchIds: branchIds, search , courseFilters });
        if (!(res && res.status && res.status !== 200)) {
            const isFind = (name) => currentAssignedAgent.findIndex(el => el.agentName === name) === -1;
            setAllAgents(res.result.filter(e => isFind(e.agentName)));
        } else setAllAgents([]);

        setIsLoading(false);
    }, []);


    useEffect(() => {
        if (activeItem)
            getAllAgent(null, activeItem?.teamIds, activeItem?.branchIds);
        else {
            getAllAgent(
              null,
              activeItemSelector?.rotationSchemeTeams?.map(
                (item) => item.teamId
              ),
              activeItemSelector.rotationSchemeBranches?.map(
                (item) => item.branchId
              ),
              activeItemSelector.rotationSchemeCourses
            );
        }

    }, [getAllAgent, activeItem, data]);

    useEffect(() => {
        if (activeItem) {
            setSaveIsDisabled(false);
            const agentNameSelected = currentAssignedAgent.find(
                (item) => item.agentName === activeItem.agentName
            );
            setSelectedValue({ id: 'agent', value: agentNameSelected || null });
            setState({ id: 'dailyCapacity', value: activeItem.dailyCapacity });
            setState({ id: 'weeklyCapacity', value: activeItem.weeklyCapacity });
            setState({ id: 'monthlyCapacity', value: activeItem.monthlyCapacity });
            setState({ id: 'agentLeadCapacityInRotation', value: activeItem.agentLeadCapacityInRotation });
        }
    }, [activeItem, allAgents]);

    const saveHandler = useCallback(async () => {
        setIsLoading(true);
        let editBody = {};
        let createBody = {};
        let res = null;
        const filtraCurrentAssied = activeItem && currentAssignedAgent.filter((e) => e.agentId !== activeItem.agentId) || currentAssignedAgent;

        try {
            if (!activeItem) {
                createBody = {
                    "agentIds": state.agent.map((item) => (item.agentId) || []),
                    "agentLeadCapacityInRotation": state.agentLeadCapacityInRotation,
                    "dailyCapacity": state.dailyCapacity,
                    "monthlyCapacity": state.monthlyCapacity,
                    "weeklyCapacity": state.weeklyCapacity
                }
                res = await CreateAgentRotationScheme(GetParams('id'), createBody);
            } else {
                editBody = {
                    agents: [...filtraCurrentAssied,
                    {
                        agentId: activeItem && selectedValue.agent.agentId || state.agent.agentId,
                        monthlyCapacity: state.monthlyCapacity,
                        weeklyCapacity: state.weeklyCapacity,
                        dailyCapacity: state.dailyCapacity,
                        agentLeadCapacityInRotation: state.agentLeadCapacityInRotation
                    }
                    ]
                }
                res = await AssignAgentToRotationScheme(GetParams('id'), editBody, false);
            }

            if (!(res && res.status && res.status !== 200)) {
                showSuccess(t(`${translationPath}assign-new-agent-successfully`));
            } else {
                showError(t(`${translationPath}assign-new-agent-failed`));
            }
        } catch (error) {
            console.error("Error:", error);
        }

        setIsLoading(false);
        reloadData();
    });

    const updateCapacity = (type , value)=>{
        const capacities = calculateCapacities(type, value)
        setState({id : 'capacities' , value : capacities})
    }
    return (
        <>
            <DialogComponent
                titleText={(activeItem ? 'edit-assig-agent' : 'new-assign-agent')}
                saveText='save'
                cancelText='cancel'
                saveType='button'
                maxWidth='md'
                subTitle=''
                subTitleClass='sub-title-wrapper'
                wrapperClasses='assign-agent-diaog'
                contentClasses='content-wrapper'
                dialogContent={(
                    <>
                        {activeItem && <AutocompleteComponent
                            idRef='agantNameRef'
                            labelValue={t(`${translationPath}agentName`)}
                            data={allAgents || []}
                            selectedValues={selectedValue.agent}
                            multiple={false}
                            displayLabel={(option) => (option && option.agentName) + ` ( ${(option && option.agentStatus ||'') } )`|| ''}
                            chipsLabel={(option) => (option && option.agentName) +   ` ( ${(option && option.agentStatus ||'') } )`|| ''}
                            isLoading={isLoading}
                            withLoader
                            withoutSearchButton
                            wrapperClasses='assignAgentName'
                            parentTranslationPath={parentTranslationPath}
                            translationPath={translationPath}
                            onChange={(e, newValues) => {
                                if (newValues) setSaveIsDisabled(false);
                                else setSaveIsDisabled(true);
                                setState({ id: 'agent', value: newValues || null });
                                setSelectedValue({ id: 'agent', value: newValues || null });
                            }}
                        />}
                        {!activeItem && <AutocompleteComponent
                            idRef='agantNameRef'
                            labelValue={t(`${translationPath}agentName`)}
                            data={allAgents || []}
                            selectedValues={selectedValue.agentMultiple}
                            multiple
                            displayLabel={(option) => (option && option.agentName) + ` ( ${(option && option.agentStatus ||'') } )`|| ''}
                            chipsLabel={(option) => (option && option.agentName) +   ` ( ${(option && option.agentStatus ||'') } )`|| ''}
                            isLoading={isLoading}
                            withLoader
                            withoutSearchButton
                            wrapperClasses='assignAgentName'
                            parentTranslationPath={parentTranslationPath}
                            translationPath={translationPath}
                            onChange={(e, newValues) => {
                                if (newValues) setSaveIsDisabled(false);
                                else setSaveIsDisabled(true);
                                setState({ id: 'agent', value: newValues || null });
                                setSelectedValue({ id: 'agentMultiple', value: newValues || null });
                            }}
                        />}
                        <div className='capacity-inputs'>
                            <div>
                                <Inputs
                                    idRef='leadCapacityRef'
                                    labelValue='overAll-schema-capacity'
                                    value={state.agentLeadCapacityInRotation}
                                    min={0}
                                    type='number'
                                    parentTranslationPath={parentTranslationPath}
                                    translationPath={translationPath}
                                    onInputChanged={(e)=> updateCapacity('agentLeadCapacityInRotation', parseInt(e.target.value)|| 0)}
                                />

                                <Inputs
                                    idRef='weeklyCapacityRef'
                                    labelValue='lead-capacity/weekly'
                                    value={state.weeklyCapacity}
                                    min={0}
                                    type='number'
                                    parentTranslationPath={parentTranslationPath}
                                    translationPath={translationPath}
                                    onInputChanged={(e)=>updateCapacity('weeklyCapacity' ,  parseInt(e.target.value) || 0)}
                                />
                            </div>
                            <div>
                                <Inputs
                                    idRef='monthlyCapacityRef'
                                    labelValue='lead-capacity/monthly'
                                    value={state.monthlyCapacity}
                                    min={0}
                                    type='number'
                                    parentTranslationPath={parentTranslationPath}
                                    translationPath={translationPath}
                                    onInputChanged={(e)=>updateCapacity('monthlyCapacity' ,  parseInt(e.target.value) || 0)}
                                />
                                <Inputs
                                    idRef='dailyCapacityRef'
                                    labelValue='lead-capacity/daily'
                                    value={state.dailyCapacity}
                                    min={0}
                                    type='number'
                                    parentTranslationPath={parentTranslationPath}
                                    translationPath={translationPath}
                                    onInputChanged={(e)=>updateCapacity('dailyCapacity' ,  parseInt(e.target.value) || 0)}
                                />
                            </div>
                        </div>

                    </>
                )}
                saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
                cancelClasses='btns cancel-btn-wrapper'
                isOpen={isOpen}
                onSaveClicked={saveHandler}
                onCloseClicked={isClose}
                onCancelClicked={isClose}
                saveIsDisabled={saveIsDisabled}
                SmothMove
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                disableBackdropClick
            />

        </>
    );
};
AssignAgentDialog.propTypes = {
    parentTranslationPath: PropTypes.string.isRequired,
    translationPath: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    rotationEdit: PropTypes.instanceOf(Object),
    isEdit: PropTypes.bool,
};
AssignAgentDialog.defaultProps = {
    rotationEdit: null,
    isEdit: false,
    setRotationEdit: () => { },
};
