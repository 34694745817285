import React, { useCallback, useEffect, useState } from 'react';
import './UserApprovals.scss';
import { useTitle } from '../../../../Hooks';
import { useTranslation } from 'react-i18next';
import { DatePickerComponent, Spinner, Tables } from '../../../../Components';
import { PageHeading } from '../../../../SharedComponents';
import {
  ApproveOrRejectRequest,
  GetPendingRequests,
  GetRequestDetailsById,
} from '../../../../Services/UsersServices/UserApprovalServices';

import { useSelector } from 'react-redux';
import { pendingRequestsHeaderData } from './UserApprovalsUtilties/pendingRequestsHeaderData';
import { TableActions } from '../../../../Enums';
import { NotifyDialog } from './UserApprovalsUtilties/NotifyDialog';
import { showError, showSuccess } from '../../../../Helper';
import { PreviewDialog } from './UserApprovalsUtilties/PreviewDialog';
import { RejectedDialog } from './UserApprovalsUtilties/RejectedDialog';
import { GetAllRolesByUserId } from '../../../../Services';
const defaultActions = [
  {
    enum: TableActions.preview.key,
    isDisabled: false,
    externalComponent: null,
  },
  {
    enum: TableActions.approvedCheck.key,
    isDisabled: false,
    externalComponent: null,
  },
  {
    enum: TableActions.rejectedText.key,
    isDisabled: false,
    externalComponent: null,
  },
];

export const UserApprovalsView = () => {
  const parentTranslationPath = 'UsersView';
  const translationPath = 'UserApprovals.';

  const [pendingRequestsData, setPendingRequestsData] = useState({
    result: [],
    totalCount: 0,
  });
  const [activeItem, setActiveItem] = useState(null);
  const [viewDetailsRequest, setViewDetailsRequest] = useState({});
  const [notifyDialogOpen, setNotifyDialogOpen] = useState(false);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [rejectedDialogOpen, setRejectedDialogOpen] = useState(false);
  const [list, setList] = useState(defaultActions);
  const [isLoading, setIsLoading] = useState(false);
  const [savedRoles, setSavedRoles] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [orderBy, setOrderBy] = useState({ filterBy: null, orderBy: null });
  const [filter, setFilter] = useState({
    pageIndex: 1,
    pageSize: 25,
    orderBy: orderBy?.orderBy || null,
    filterBy: orderBy?.filterBy || null,
    requestStatusId: null,
    requesterName: null,
    actionByName: null,
    unitId: null,
    requestDate: null,
    typeOfRequestId : null
  });

  const { t } = useTranslation(parentTranslationPath);

  useTitle(t(`${translationPath}user-approvals`));
  const loginResponse = useSelector((state) => state.login.loginResponse);

  const getPendingRequests = useCallback(async () => {
    setIsLoading(true);
    const res = await GetPendingRequests({
      ...filter,
      userId: loginResponse.userId,
      userRoles: loginResponse?.roles?.map((role) => role.rolesId),
    });
    if (!(res && res.status && res.status !== 200)) {
      setPendingRequestsData(res);
    }
    setIsLoading(false);
  }, [filter, loginResponse.userId, loginResponse?.roles]);

  const approveOrRejectRequest = async (isApprove, unitRequestManagementId) => {
    setIsLoading(true);
    const userRoles = savedRoles?.map((item) => item.rolesId);
    const res = await ApproveOrRejectRequest({
      isApprove: isApprove,
      userId: loginResponse.userId,
      requestId: unitRequestManagementId,
      userRoles,
    });
    if (!(res && res.status && res.status !== 200)) {
      if (isApprove === false) {
        showSuccess(t(`${translationPath}rejected-request-successfully`));
      }
      if (isApprove === true) {
        setNotifyDialogOpen(true);
      }
      getPendingRequests();
      return res;
    } else if (res?.data?.Message) {
      const errorMessage =
        res?.data?.Message.split(' : ')[1] || res?.data?.Message;
      showError(t(`${translationPath}${errorMessage}`));
    }
    setIsLoading(false);
  };

  const getRequestDetailsById = async (requestId) => {
    setIsLoading(true);
    const res = await GetRequestDetailsById(requestId);
    if (!(res && res.status && res.status !== 200)) {
      setViewDetailsRequest(res);
    }
    setIsLoading(false);
  };

  const actionClickHandler = async (actionEnum, item, focusedRow, event) => {
    event.stopPropagation();
    event.preventDefault();

    if (actionEnum === TableActions.rejectedText.key) {
      setActiveItem(item);
      setRejectedDialogOpen(true);
    } else if (actionEnum === TableActions.approvedCheck.key) {
      setActiveItem(item);
      await approveOrRejectRequest(true, item?.unitRequestManagementId);
    } else if (actionEnum === TableActions.preview.key) {
      getRequestDetailsById(item?.unitRequestManagementId);
      setPreviewDialogOpen(true);
    }
  };

  const focusedRowChanged = useCallback((rowIndex, item) => {
    let updatedList = [];
    if (
      item?.requestStatusId === 2 ||
      item?.requestStatusId === 3 ||
      item?.requestStatusId === 4
    ) {
      updatedList.push({
        enum: TableActions.preview.key,
        isDisabled: false,
        externalComponent: null,
      });
    } else {
      updatedList = defaultActions;
    }
    setList(updatedList);
  }, []);

  const reloadData = () => {
    getPendingRequests();
  };
  useEffect(() => {
    getPendingRequests();
  }, [getPendingRequests]);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex: pageIndex + 1 }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 1, pageSize }));
  };

  const onFilterValuesChanged = (e) => {
    if (e && !!Object.values(e).length) {
      const { value, searchableKey } = Object.values(e)[0];
      setFilter((filter) => ({
        pageIndex: 1,
        pageSize: 25,
        [searchableKey]: value,
      }));
    } else {
      setFilter((filter) => {
        const updatedFilter = Object.entries(filter).filter(
          ([_key, value]) => !!value
        );
        return Object.fromEntries(updatedFilter);
      });
    }
  };

  const getActiveUserRoles = async () => {
    setIsLoading(true);
    const response = await GetAllRolesByUserId(loginResponse.userId, 1, 30);
    if (!(response?.status && response.status !== 200)) {
      setSavedRoles(response?.result);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!sortBy) return;

    const filterMapping = {
      RequesterName: 'RequestDate',
      ActionByName: 'ActionDate',
    };

    const newFilterBy = filterMapping[sortBy.filterBy] || sortBy.filterBy;

    setFilter((item) => ({
      ...item,
      filterBy: newFilterBy,
      orderBy: sortBy.orderBy,
    }));
  }, [sortBy]);

  useEffect(() => {
    if (loginResponse) {
      getActiveUserRoles();
    }
  }, [loginResponse]);
  return (
    <div className='propx-view propx-bg user-groups-view'>
      <Spinner isActive={isLoading} isAbsolute />
      <PageHeading
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        headerTitle={'user-approvals'}
        wrapperClasses={'px-4 pt-4 w-25'}
        headerTitleClasses={'fz-30 mb-3'}
      />

      <div className='w-25 pb-4 pl-3'>
        <DatePickerComponent
          idRef='requestDateRef'
          labelValue='request-date'
          value={filter.requestDate}
          placeholder='DD/MM/YYYY'
          onDateChanged={(e) =>
            setFilter((filter) => ({
              ...filter,
              requestDate: (e && e?._d) || null,
            }))
          }
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          clearable={true}
        />
      </div>
      <div className='approvals-user-table w-100 px-3'>
        <Tables
          data={pendingRequestsData?.result || []}
          headerData={pendingRequestsHeaderData || []}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          actionsOptions={{
            onActionClicked: actionClickHandler,
          }}
          defaultActions={list}
          focusedRowChanged={focusedRowChanged}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex - 1}
          totalItems={pendingRequestsData?.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          isWithFilter
          FilterDisabledButton
          filterData={pendingRequestsHeaderData.map((item) => ({
            key: item.key,
            id: item.id,
            filterType: item.filterType,
            searchableKey: item.searchableKey ?? item.input,
            isHiddenFilter: item.isHiddenFilter,
            optionFilterList: item.optionFilterList,
            filterBy: item.filterBy,
          }))}
          onFilterValuesChanged={onFilterValuesChanged}
          setSortBy={setSortBy}
        />
      </div>
      {notifyDialogOpen && (
        <NotifyDialog
          notifyDialogOpen={notifyDialogOpen}
          activeItem={activeItem}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          setNotifyDialogOpen={setNotifyDialogOpen}
          approveOrRejectRequest={approveOrRejectRequest}
          onSave={() => {
            setNotifyDialogOpen(false);
            setActiveItem(null);
            reloadData();
          }}
          isOpenChanged={() => {
            setNotifyDialogOpen(false);
            setActiveItem(null);
          }}
        />
      )}
      {previewDialogOpen && (
        <PreviewDialog
          previewDialogOpen={previewDialogOpen}
          setPreviewDialogOpen={setPreviewDialogOpen}
          activeItem={activeItem}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          viewDetailsRequest={viewDetailsRequest}
          onSave={() => {
            setPreviewDialogOpen(false);
          }}
          isOpenChanged={() => {
            setPreviewDialogOpen(false);
          }}
        />
      )}

      {rejectedDialogOpen && (
        <RejectedDialog
          activeItem={activeItem}
          open={rejectedDialogOpen}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          close={() => {
            setRejectedDialogOpen(false);
            setActiveItem(null);
          }}
          onSave={() => {
            setRejectedDialogOpen(false);
            setActiveItem(null);
            reloadData();
          }}
          approveOrRejectRequest={approveOrRejectRequest}
        />
      )}
    </div>
  );
};
