import React from "react";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
  useIsArabicLayout,
  useSelectedTheme,
  useIsDesktop,
} from "../../../Hooks";

// Icons
import { HomeLine, ChevronLeft, ChevronRight } from "../../../assets/icons";

// styles
import useStyles from "./styles";

function CustomBreadCrumb({ breadcrumbs, children, containerClasess }) {
  const {
    theme: { palette },
  } = useSelectedTheme();

  const { isArabicLayout } = useIsArabicLayout();

  const { isDesktop } = useIsDesktop();

  const styles = useStyles();

  if (!isDesktop) {
    return null;
  }

  return (
    <section
      className={clsx(styles.breadcrumbContainer, {
        [containerClasess]:
          !!containerClasess && typeof containerClasess === "string",
      })}
    >
      <NavLink
        exact
        to="/home"
        className={styles.iconLink}
        activeClassName={styles.activeLink}
      >
        <HomeLine width="20" height="20" fill={palette.breadcrumbs.iconFG} />
      </NavLink>
      {breadcrumbs.map((crumb, index) => (
        <BreadcrumbItem
          key={index}
          crumb={crumb}
          isArabicLayout={isArabicLayout}
          palette={palette}
          styles={styles}
        />
      ))}
      {children && (
        <div className={styles.breadcrumbItem}>
          {isArabicLayout ? (
            <ChevronLeft
              width="16"
              height="16"
              fill={palette.foreground.senary}
            />
          ) : (
            <ChevronRight
              width="16"
              height="16"
              fill={palette.foreground.senary}
            />
          )}
          {children}
        </div>
      )}
    </section>
  );
}

CustomBreadCrumb.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string,
      nonClickable: PropTypes.bool,
    })
  ).isRequired,
  children: PropTypes.node,
  containerClasess: PropTypes.string,
};

CustomBreadCrumb.defaultProps = {
  breadcrumbs: [],
  containerClasess: null,
  children: null,
};

export default CustomBreadCrumb;

function BreadcrumbItem({ crumb, isArabicLayout, palette, styles }) {
  return (
    <div className={styles.breadcrumbItem}>
      {isArabicLayout ? (
        <ChevronLeft width="16" height="16" fill={palette.foreground.senary} />
      ) : (
        <ChevronRight width="16" height="16" fill={palette.foreground.senary} />
      )}
      {crumb.nonClickable ? (
        <span className={styles.nonClickableText}>{crumb.label}</span>
      ) : (
        <NavLink
          to={crumb.link}
          className={styles.breadcrumbLink}
          activeClassName={styles.activeLink}
        >
          {crumb.label}
        </NavLink>
      )}
    </div>
  );
}

BreadcrumbItem.propTypes = {
  crumb: PropTypes.shape({
    label: PropTypes.string.isRequired,
    link: PropTypes.string,
    nonClickable: PropTypes.bool,
  }).isRequired,
  isArabicLayout: PropTypes.bool.isRequired,
  palette: PropTypes.object.isRequired,
  styles: PropTypes.object.isRequired,
};
