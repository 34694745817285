export const LeadConvertSizeSqftToSqmRule2 = (item, value, allItemsValues, onValueChanged, fromORTo, itemList) => {
    if (item.field.id === 'size_sqft') {
        const sm = [];
        const sizeSqmIndex = itemList.findIndex((f) => f.field.id === 'size_sqm');

        if (fromORTo && fromORTo === 'from' && sizeSqmIndex !== -1) {
            if (value) {
                sm[0] = (parseFloat(value) * 0.092903);
                sm[1] = (allItemsValues && allItemsValues.size_sqm && allItemsValues.size_sqm.length === 2 && allItemsValues.size_sqm[1]) || (itemList[sizeSqmIndex].data.maxNumber);
            }
        } else if (fromORTo && fromORTo === 'to' && sizeSqmIndex !== -1) {
            if (value) {
                sm[0] = (allItemsValues.size_sqm && allItemsValues.size_sqm.length && allItemsValues.size_sqm[0]) || (itemList[sizeSqmIndex].data.minNumber);
                sm[1] = parseFloat(value) * 0.092903;
            }
        }
        onValueChanged(sm, 0, 'size_sqm');
    }
};

export const LeadConvertSizeSqmToSqftRule2 = (item, value, allItemsValues, onValueChanged, fromORTo, itemList) => {
    if (item.field.id === 'size_sqm') {
        const sizeSqftValues = [];
        const sizeSqftIndex = itemList.findIndex((f) => f.field.id === 'size_sqft');
        if (fromORTo && fromORTo === 'from' && sizeSqftIndex !== -1) {
            if (value) {
                sizeSqftValues[0] =  Number(Math.round(parseFloat(value) * 10.76391042));
                sizeSqftValues[1] = (allItemsValues && allItemsValues.size_sqft && allItemsValues.size_sqft.length === 2 &&  Number(Math.round(allItemsValues.size_sqft[1]))) || (itemList[sizeSqftIndex].data.maxNumber);
            }
        } else if (fromORTo && fromORTo === 'to' && sizeSqftIndex !== -1) {
            if (value) {
                sizeSqftValues[0] = allItemsValues.size_sqft && allItemsValues.size_sqft.length &&  Number(Math.round(allItemsValues.size_sqft[0])) || (itemList[sizeSqftIndex].data.minNumber);
                sizeSqftValues[1] = Number(Math.round((parseFloat(value) * 10.76391042)));
            }
        }

        onValueChanged(sizeSqftValues, 0, 'size_sqft');
    }
};

export const LeadConvertSizeSqftToSqmRule1 = (item, value, allValues, setData, fromORTo, itemList, setRerender) => {
    if (item.field.id === 'size_sqft') {
        const sm = [];
        const sizeSqmIndex = itemList.findIndex((f) => f.field.id === 'size_sqm');

        if (fromORTo && fromORTo === 'from' && sizeSqmIndex !== -1) {
            if (value) {
                sm[0] = (parseFloat(value) * 0.092903);
                sm[1] = (allValues && allValues.size_sqm && allValues.size_sqm.length === 2 && allValues.size_sqm[1]) || (itemList[sizeSqmIndex].data.maxNumber);
            }
        } else if (fromORTo && fromORTo === 'to' && sizeSqmIndex !== -1) {
            if (value) {
                sm[0] = (allValues.size_sqm && allValues.size_sqm.length && allValues.size_sqm[0]) || (itemList[sizeSqmIndex].data.minNumber);
                sm[1] = parseFloat(value) * 0.092903;
            }
        }
        setData('size_sqm', sm);
    }
    setRerender(Math.random());
};

export const LeadConvertSizeSqmToSqftRule1 = (item, value, allItemsValues, setData, fromORTo, itemList, setRerender) => {
    if (item.field.id === 'size_sqm') {
        const sqftValues = [];
        const sizeSqftIndex = itemList.findIndex((f) => f.field.id === 'size_sqft');
        if (fromORTo && fromORTo === 'from' && sizeSqftIndex !== -1 && itemList) {
            if (value) {
                sqftValues[0] = value !== parseFloat(item.data.minNumber) ?  Number(Math.round(parseFloat(value) * 10.76391042)) : +(itemList[sizeSqftIndex].data.minNumber);
                sqftValues[1] = (allItemsValues && allItemsValues.size_sqft && allItemsValues.size_sqft.length === 2 &&  Number(Math.round(allItemsValues.size_sqft[1]))) || +(itemList[sizeSqftIndex].data.maxNumber);
            }
        } else if (fromORTo && fromORTo === 'to' && sizeSqftIndex !== -1) {
            if (value) {
                sqftValues[0] = (allItemsValues.size_sqft && allItemsValues.size_sqft.length &&  Number(Math.round(allItemsValues.size_sqft[0]))) || (itemList[sizeSqftIndex].data.minNumber);
                sqftValues[1] = value !== parseFloat(item.data.maxNumber) ?  Number(Math.round(parseFloat(value) * 10.76391042 )): +(itemList[sizeSqftIndex].data.maxNumber);
            }
        }
        setData('size_sqft', sqftValues);
        setRerender(Math.random());
    }
};

export const LeadConvertSizeSqftToSqmDefaultRule = (item, allItemsValues, onValueChanged, itemList) => {
    if (item.field.id === 'size_sqft') {
        const sm = [];
        const sizeSqmIndex = itemList.findIndex((f) => f.field.id === 'size_sqm');
        if (sizeSqmIndex !== -1 && allItemsValues && allItemsValues.size_sqft) {
            sm[0] = allItemsValues.size_sqft[0] ? parseFloat(allItemsValues.size_sqft[0]) * 0.092903 : parseFloat(itemList[sizeSqmIndex].data.minNumber);
            sm[1] = allItemsValues.size_sqft[1] ? parseFloat(allItemsValues.size_sqft[1]) * 0.092903 : parseFloat(itemList[sizeSqmIndex].data.maxNumber);
        }

        onValueChanged(sm, 0, 'size_sqm');
    }
};
