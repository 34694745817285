import React from "react";

function FilterNotBlankIcon({ fill, ...restProps }) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M7.413 1.359c-1.202.121-2.275.512-3.226 1.178C1.825 4.191.785 7.172 1.615 9.91c.33 1.09.87 1.99 1.678 2.797a6.503 6.503 0 0 0 2.222 1.478c2.241.889 4.694.535 6.618-.955a8.485 8.485 0 0 0 1.097-1.097c.94-1.214 1.436-2.642 1.436-4.133 0-3.045-2.105-5.742-5.046-6.464-.656-.162-1.602-.237-2.207-.177"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default FilterNotBlankIcon;
