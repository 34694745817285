export const BranchCard = {
    Number: {
      key: 1,
      icon: 'icons b-icon i-number-sign px-2',
    },
    City: {
      key: 2,
      icon: 'icons b-icon i-city px-2',
    },
    Users: {
      key: 3,
      icon: 'icons b-icon i-users px-2',
    },
    Country: {
      key: 4,
      icon: 'icons b-icon i-country px-2',
    },
    CreatedOn : {
      key : 5 , 
      icon : 'icons b-icon  i-oclock px-2'
    }
  };
