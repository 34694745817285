import { TableActions } from '../../../../Enums';
import { returnPropsByPermissions } from '../../../../Helper';
import { BillingPermissions } from '../../../../Permissions';

export const getActionTableWithPermissions = () => {
  const list = [{ enum: TableActions.account.key, },{
    enum: TableActions.users.key,
  },];
  if (
    returnPropsByPermissions(BillingPermissions.PrintInvoice.permissionsId) ||
    returnPropsByPermissions(BillingPermissions.ShareInvoice.permissionsId)
  ) {
    list.push({ enum: TableActions.PrintInvoice.key });
  }
  return list;
};
