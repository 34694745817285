
import React , { useState , useCallback , useEffect , useRef    } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { AutocompleteComponent , Spinner   } from '../../../../../../../Components' ; 
import {  AddressEnum  } from '../../../../../../../Enums' ; 
import {  lookupItemsGet , getProperties  } from  '../../../../../../../Services' ; 
import { showError } from '../../../../../../../Helper' ; 

export const LeadsAddress  = ({
  parentTranslationPath,
  translationPath,
  state,
  setState

}) => {
    const { t } = useTranslation(parentTranslationPath);

    const [locationList , setLocationList]  = useState({
      allCities : [] , 
      allDistricts: [] , 
      allCommunities : [] , 
      allSubCommunities : [] , 
    });
    const [allProperties , setAllProperties]  = useState([]) ;

    const [isLoading , setIsLoading ] = useState({
      locations  : false , 
      properties :  false  , 
      city : false  ,
      community : false , 
      district : false , 
      subCommunity :  false , 
    }) ; 
 
    const searchTimer = useRef(null);

    const getAllLookups = useCallback(async (lookupItem , searchValue , loader) => {
      setIsLoading((item) => ({ ...item, [loader] : true  }));
        const res = await lookupItemsGet({ pageIndex : 1, pageSize : 10, lookupTypeId :( lookupItem && lookupItem.lookupTypeId)  || lookupItem  ,  searchedItem :searchValue });
        if (!(res && res.status && res.status !== 200)) 
        {
            if(lookupItem.lookupTypeName === 'city' ||  lookupItem  === AddressEnum.city.lookupTypeId ) 
                  setLocationList((item) => ({ ...item,  allCities : (res.result.map((item) => ({
                    lookupItemName: item.lookupItemName,
                    lookupItemId : item.lookupItemId,
               })))}));

             else if(lookupItem.lookupTypeName === 'district' ||  lookupItem  === AddressEnum.district.lookupTypeId ) 
                setLocationList((item) => ({ ...item,  allDistricts : (res.result.map((item) => ({
                    lookupItemName: item.lookupItemName,
                    lookupItemId : item.lookupItemId,
               })))}));

             else if(lookupItem.lookupTypeName === 'community' ||  lookupItem  === AddressEnum.community.lookupTypeId) 

                setLocationList((item) => ({ ...item,  allCommunities : (res.result.map((item) => ({
                    lookupItemName: item.lookupItemName,
                    lookupItemId : item.lookupItemId,
               })))}));
        
             else if(lookupItem.lookupTypeName === 'subCommunity' ||  lookupItem  === AddressEnum.subCommunity.lookupTypeId) 
                setLocationList((item) => ({ ...item,  allSubCommunities : (res.result.map((item) => ({
                    lookupItemName: item.lookupItemName,
                    lookupItemId : item.lookupItemId,
               })))}));
        }
        else {

             if(lookupItem.lookupTypeName === 'city' || lookupItem  === AddressEnum.city.lookupTypeId ) 
               setLocationList((item) => ({ ...item,  allCities : [] }));

             else if(lookupItem.lookupTypeName === 'district' || lookupItem  === AddressEnum.district.lookupTypeId) 
               setLocationList((item) => ({ ...item,  allDistricts : []}));
             
             else if(lookupItem.lookupTypeName === 'community' || lookupItem  === AddressEnum.community.lookupTypeId) 
              setLocationList((item) => ({ ...item,  allCommunities : []}));

             else if(lookupItem.lookupTypeName === 'subCommunity' || lookupItem  === AddressEnum.subCommunity.lookupTypeId) 
              setLocationList((item) => ({ ...item,  allSubCommunities : []}));
         }
        setIsLoading((item) => ({ ...item, [loader] : false   }));
      }, []);


      const getAllProperties = useCallback(async (searchValue) => {
        setIsLoading((item) => ({ ...item, properties : true  }));
        const res = await getProperties({ pageIndex: 0, pageSize: 10, search: searchValue });
        if (!(res && res.status && res.status !== 200)) 
         setAllProperties(res.result.map((item) => ({
          propertyName: item.property && item.property.property_name,
          propertyId : item.propertyId,
        })));
         else 
          setAllProperties([]);  

        setIsLoading((item) => ({ ...item, properties : false   }));

      }, []);

    
      useEffect(() => {
        Object.values(AddressEnum).map((item)=> {
            getAllLookups(item , '' , item.lookupTypeName);
        });
       
      }, [AddressEnum]);


      useEffect(() => {
        getAllProperties() ;
      }, []); 

  return (
    <div className='content'>
       <div className='w-100 px-2 mt-2'>
       <Spinner isActive={isLoading.city} isAbsolute  />
          <div className='address'>
          <div className='addressPart'>
            <AutocompleteComponent
              idRef='LeadsCitiesRef'
              labelValue={t(`${translationPath}city`)}
              data={locationList.allCities ||  []}
              selectedValues={state.leads.address.city}
              multiple
              displayLabel={(option) =>
              (option && option.lookupItemName) || ''}
              chipsLabel={(option) => (option && option.lookupItemName) || ''}
              withoutSearchButton
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                 searchTimer.current = setTimeout(() => {
                getAllLookups(AddressEnum.city.lookupTypeId , value  , 'city' );
                 }, 700);
                }}
              value={state.leads.address.city}
              onChange={(e, newValues) => 
              {
                const sumAll =  (newValues.length)  +
                (state.leads.address.district.length) + 
                (state.leads.address.community.length) + 
                (state.leads.address.subCommunity.length) + 
                 (state.leads.address.property.length)  ; 
                if(sumAll > 5 )
                {
                  showError(t(`${translationPath}please-Select-five-Item-Only`));
                  return ; 
                }

                const leadsAddress = { ...state.leads.address, city: newValues };
                setState({ id: 'leads', value: { ...state.leads, address: leadsAddress } })
              }}
            isLoading={isLoading.city} 
            withLoader
         /> 
        </div>     
       <div className='addressPart'>
            <AutocompleteComponent
              idRef='leadsDistrictRef'
              labelValue={t(`${translationPath}district`)}
              data={locationList.allDistricts  ||  []}
              onInputKeyUp={(e) => {
               const { value } = e.target;
               if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                  getAllLookups(AddressEnum.district.lookupTypeId , value  , 'district');
               }, 700);
               }}
               multiple
               displayLabel={(option) =>
                (option && option.lookupItemName) || ''}
                chipsLabel={(option) => (option && option.lookupItemName) || ''}
               withoutSearchButton
               parentTranslationPath={parentTranslationPath}
               translationPath={translationPath}
               value={state.leads.address.district}
               selectedValues={state.leads.address.district}
               onChange={(e, newValues) => 
               {
                const sumAll =  (newValues.length)  +
                (state.leads.address.city.length) + 
                (state.leads.address.community.length) + 
                (state.leads.address.subCommunity.length) + 
                 (state.leads.address.property.length)  ; 

                if(sumAll > 5 )
                {
                  showError(t(`${translationPath}please-Select-five-Item-Only`));
                  return ; 
                }

                const leadsAddress = { ...state.leads.address, district: newValues };
                setState({ id: 'leads', value: { ...state.leads, address: leadsAddress } })
               }}
              filterOptions={(options) => {
                const isFind = (id) => state.leads.address.district.findIndex((w) => w.lookupItemId === id) === -1;
               return options.filter((w) => isFind(w.lookupItemId));
              }}
              isLoading={isLoading.district} 
              withLoader
             />       
          </div>  
       </div>  
       <div className='address'>
            <div className='addressPart'>
              <AutocompleteComponent
                idRef='leadsCommunityRef'
                labelValue={t(`${translationPath}community`)}
                data={locationList.allCommunities}
                onInputKeyUp={(e) => {
                 const { value } = e.target;
                 if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                   getAllLookups(AddressEnum.community.lookupTypeId , value  , 'community' );
                 }, 700);
                }}
               multiple
               displayLabel={(option) =>
                (option && option.lookupItemName) || ''}
               chipsLabel={(option) => (option && option.lookupItemName) || ''}
               withoutSearchButton
               parentTranslationPath={parentTranslationPath}
               translationPath={translationPath}
               value={state.leads.address.community}
               selectedValues={state.leads.address.community}
               onChange={(e, newValues) => {
                const sumAll =  (newValues.length)  +
                (state.leads.address.city.length) + 
                (state.leads.address.district.length) + 
                (state.leads.address.subCommunity.length) + 
                (state.leads.address.property.length)  ; 

                if(sumAll > 5 )
                 {
                  showError(t(`${translationPath}please-Select-five-Item-Only`));
                  return ; 
                 }
                 const leadsAddress = { ...state.leads.address, community: newValues };
                setState({ id: 'leads', value: { ...state.leads, address: leadsAddress } })
               }}
               filterOptions={(options) => {
                const isFind = (id) => state.leads.address.community.findIndex((w) => w.lookupItemId === id) === -1;
               return options.filter((w) => isFind(w.lookupItemId));
              }}
              isLoading={isLoading.community} 
              withLoader
           />       
        </div>  
       <div className='addressPart'>
            <AutocompleteComponent
               idRef='leadsSubCommunityRef'
               labelValue={t(`${translationPath}subCommunity`)}
               data={locationList.allSubCommunities || []}
               multiple
               displayLabel={(option) =>
               (option && option.lookupItemName) || ''}
               chipsLabel={(option) => (option && option.lookupItemName) || ''}
               withoutSearchButton
               parentTranslationPath={parentTranslationPath}
               translationPath={translationPath} 
               onInputKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                    getAllLookups(AddressEnum.subCommunity.lookupTypeId , value , 'subCommunity');
                }, 700);
              }}
              selectedValues={state.leads.address.subCommunity}
              value={state.leads.address.subCommunity}
              onChange={(e, newValues) => {
                const sumAll =  (newValues.length)  +
                (state.leads.address.city.length) + 
                (state.leads.address.district.length) + 
                (state.leads.address.community.length) + 
                (state.leads.address.property.length)  ; 

                if(sumAll > 5 )
                 {
                  showError(t(`${translationPath}please-Select-five-Item-Only`));
                  return ; 
                 }
                const leadsAddress = { ...state.leads.address, subCommunity: newValues };
                setState({ id: 'leads', value: { ...state.leads, address: leadsAddress } })
              }}
              filterOptions={(options) => {
                const isFind = (id) => state.leads.address.subCommunity.findIndex((w) => w.lookupItemId === id) === -1;
               return options.filter((w) => isFind(w.lookupItemId));
              }}
             isLoading={isLoading.subCommunity} 
             withLoader
        />       
       </div>
      </div>
       <div className='address'>
         <div className='addressPart'>
            <AutocompleteComponent
              idRef='leadsAddressPropertiesRef'
              labelValue={t(`${translationPath}properties`)}
              data={ allProperties || []}
              multiple
              displayLabel={(option) =>(option && option.propertyName) || ''}
              chipsLabel={(option) => (option && option.propertyName) || ''}
              withoutSearchButton
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                getAllProperties(value);
                }, 700);
              }}
              value={state.leads.address.property}
              selectedValues={state.leads.address.property}
              onChange={(e, newValues) => {

                const sumAll =  (newValues.length)  +
                (state.leads.address.city.length) + 
                (state.leads.address.district.length) + 
                (state.leads.address.community.length) + 
                (state.leads.address.subCommunity.length)  ; 

                if(sumAll > 5 )
                 {
                  showError(t(`${translationPath}please-Select-five-Item-Only`));
                  return ; 
                 }
                const leadsAddress = { ...state.leads.address, property: newValues };
                setState({ id: 'leads', value: { ...state.leads, address: leadsAddress } })
              }}
              filterOptions={(options) => {
                const isFind = (id) => state.leads.address.property.findIndex((w) => w.propertyId === id) === -1;
               return options.filter((w) => isFind(w.propertyId));
              }}
              isLoading={isLoading.properties} 
              withLoader
            />
          </div>
        </div>  
        </div>

    </div>
   
  );
};

LeadsAddress.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state:PropTypes.instanceOf(Object).isRequired,
  setState : PropTypes.func.isRequired,
};


