import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { DataFileAutocompleteComponent } from "../../../../../Components";
import {
  GetLeads,
  getUnits,
  GetAdvanceSearchContacts,
  getProperties,
} from "../../../../../Services";
import { UnitMapper } from "../../../UnitsView/UnitMapper/UnitMapper";
import { ContactsMapper } from "../../../ContactsView/ContactsUtilities/ContactsMapper";
import {
  LeadsStatusEnum,
  TaskConfigurationRelatedTo,
} from "../../../../../Enums";

export const RelatedToComponent = ({
  parentTranslationPath,
  translationPath,
  configData,
  setTaskRelatedToList,
  taskRelatedToList,
}) => {
  const { t } = useTranslation([parentTranslationPath, "Shared"]);
  const searchTimer = useRef(null);

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [data, setData] = useReducer(reducer, {
    relatedUnit: [],
    relatedProperty: [],
    relatedContact: [],
    relatedContact: [],
  });
  const [filter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [isLoading, setIsLoading] = useReducer(reducer, {
    relatedUnit: false,
    relatedLead: false,
    relatedContact: false,
    relatedProperty: false,
  });

  const getAllRelatedUnits = async (value) => {
    setIsLoading({ id: "relatedUnit", value: true });

    const response = await getUnits({
      ...filter,
      search: value,
    });
    if (!(response && response.status && response.status !== 200)) {
      const unitMapped =
        (response && response.result.map((item) => UnitMapper(item))) || [];
      const unitFilterd = unitMapped.filter((item) => item);
      setData({
        id: "relatedUnit",
        value: unitFilterd || [],
      });
    } else setData({ id: "relatedUnit", value: [] });

    setIsLoading({ id: "relatedUnit", value: false });
  };

  const getAllRelatedLeads = async (value) => {
    setIsLoading({ id: "relatedLead", value: true });
    const response = await GetLeads({
      ...filter,
      search: value,
      leadStatus: LeadsStatusEnum.Open.status,
    });
    if (!(response && response.status && response.status !== 200))
      setData({
        id: "relatedLead",
        value: (response && response.result) || [],
      });
    else setData({ id: "relatedLead", value: [] });

    setIsLoading({ id: "relatedLead", value: false });
  };

  const getAllRelatedProperties = async (searchValue) => {
    setIsLoading({ id: "relatedProperty", value: true });

    const res = await getProperties({
      pageSize: 10,
      pageIndex: 0,
      search: searchValue || "",
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "relatedProperty", value: res?.result || [] });
    else setData({ id: "relatedProperty", value: [] });
    setIsLoading({ id: "relatedProperty", value: false });
  };

  const getAllRelatedContacts = useCallback(async (searchValue) => {
    setIsLoading({ id: "relatedContact", value: true });

    var emailValidRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    let body = {
      criteria: {},
      filterBy: "createdOn",
    };

    if (searchValue && searchValue.match(emailValidRegex)) {
      body = {
        ...body,
        criteria: {
          Email: [{ searchType: 2, value: searchValue }],
        },
        filterBy: "createdOn",
      };
    } else if (searchValue && !isNaN(searchValue)) {
      body = {
        ...body,
        criteria: {
          Ids: [{ searchType: 2, value: searchValue }],
        },
        filterBy: "createdOn",
      };
    } else if (searchValue) {
      body = {
        ...body,
        criteria: {
          FullName: [
            {
              searchType: 2,
              value: searchValue,
            },
          ],
        },
      };
    }
    const res = await GetAdvanceSearchContacts(filter, body);
    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "relatedContact",
        value: ((res && res.result) || []).map(
          (item) =>
            item.contactJson &&
            ContactsMapper(item, JSON.parse(item.contactJson).contact)
        ),
      });
    } else {
      setData({
        id: "relatedContact",
        value: [],
      });
    }
    setIsLoading({ id: "relatedContact", value: false });
  }, []);

  const getContactDisplayLabel = (option) => {
    let displayLabel = "";

    if (option?.contact?.company_name) {
      displayLabel = option.contact.company_name || "";
      return displayLabel;
    }

    if (option?.contact?.first_name) {
      displayLabel = `${option.contact.first_name || ""} ${
        option.contact.last_name || ""
      }`;
      return displayLabel;
    }

    if (option?.company_name) {
      displayLabel = option.company_name || "";
      return displayLabel;
    }

    if (option?.first_name) {
      displayLabel = `${option.first_name || ""} ${option.last_name || ""}`;
      return displayLabel;
    }

    return displayLabel;
  };

  const getRelatedToData = (relatedToItem, searchValue) => {
    if (relatedToItem.relatedTo === "Unit") getAllRelatedUnits(searchValue);
    if (relatedToItem.relatedTo === "Lead") getAllRelatedLeads(searchValue);
    if (relatedToItem.relatedTo === "Contact")
      getAllRelatedContacts(searchValue);
    if (relatedToItem.relatedTo === "Property")
      getAllRelatedProperties(searchValue);
  };

  const handleRelatedToChange = (item, newValue) => {
    setTaskRelatedToList({
      id:
        (item.relatedTo === "Unit" && "relatedUnit") ||
        (item.relatedTo === "Lead" && "relatedLead") ||
        (item.relatedTo === "Contact" && "relatedContact") ||
        (item.relatedTo === "Property" && "relatedProperty") ||
        "",
      value: newValue,
    });
  };

  useEffect(() => {
    if (
      configData?.taskConfigurationRelatedTo &&
      configData.taskConfigurationRelatedTo.length
    ) {
      configData.taskConfigurationRelatedTo.forEach((item) => {
        getRelatedToData(item);
      });
    }
  }, [configData.taskConfigurationRelatedTo]);

  return (
    <div className="dialog-content-item related-to-wrapper">
      <div>
        {configData?.taskConfigurationRelatedTo &&
          configData.taskConfigurationRelatedTo.map((item, index) => (
            <div className="mr-2 my-3">
              <DataFileAutocompleteComponent
                labelValue={
                  TaskConfigurationRelatedTo[item.relatedTo]?.labelValue
                }
                wrapperClasses="mr-2 my-3"
                idRef={`relatedToRef-${index}`}
                inputPlaceholder={t(`${translationPath}select`)}
                selectedValues={
                  (item.relatedTo === "Unit" && taskRelatedToList.relatedUnit) ||
                  (item.relatedTo === "Lead" && taskRelatedToList.relatedLead) ||
                  (item.relatedTo === "Contact" && taskRelatedToList.relatedContact) ||
                  (item.relatedTo === "Property" && taskRelatedToList.relatedProperty)
                }
                multiple={false}
                data={
                  (item.relatedTo === "Unit" && data.relatedUnit) ||
                  (item.relatedTo === "Lead" && data.relatedLead) ||
                  (item.relatedTo === "Contact" && data.relatedContact) ||
                  (item.relatedTo === "Property" && data.relatedProperty) ||
                  []
                }
                filterOptions={(options) => options}
                displayLabel={(option) => {
                  switch (item.relatedTo) {
                    case "Unit":
                      return option?.unitRefNo || "";
                    case "Lead":
                      return (
                        (option.lead &&
                          option.lead.contact_name &&
                          option.lead.contact_name.name) ||
                        ""
                      );
                    case "Contact":
                      return getContactDisplayLabel(option);
                    case "Property":
                      return (
                        option?.property?.property_name || option?.name || ""
                      );
                  }
                }}
                renderFor={item.relatedTo && item.relatedTo.toLowerCase()}
                withoutSearchButton
                isLoading={
                  (item.relatedTo === "Unit" && isLoading.relatedUnit) ||
                  (item.relatedTo === "Lead" && isLoading.relatedLead) ||
                  (item.relatedTo === "Contact" && isLoading.relatedContact) ||
                  (item.relatedTo === "Property" &&
                    isLoading.relatedProperty) ||
                  false
                }
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getRelatedToData(item, value);
                  }, 1200);
                }}
                onKeyDown={() => {
                  const newValue = null;
                  handleRelatedToChange(item, newValue);
                }}
                isWithError
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  handleRelatedToChange(item, newValue);
                }}
              />
            </div>
          ))}
      </div>
    </div>
  );
};
