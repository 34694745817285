import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Spinner, Tables, SwitchComponent } from '../../../../../Components';
import { TableActions, LeadsClassTypesEnum } from '../../../../../Enums';
import {
  GlobalHistory, returnPropsByPermissions, showSuccess, showError
} from '../../../../../Helper';
import { GetRotationSchemeByIdServices, UpdateRotationScheme } from '../../../../../Services/RotaionSchemaService/RotationSchemaService';
import { RotationCriteriaDialog } from '../Dialogs/RotationCriteriaDialog/RotationCriteriaDialog';
import { DeleteRotationCriteriaDialog } from '../Dialogs/DeleteRotationCriteriaDialog/DeleteRotationCriteriaDialog';
import { RotationSchemaPermissions } from '../../../../../Permissions';
import { ActiveItemActions } from '../../../../../store/ActiveItem/ActiveItemActions';

export const RotationSchemaTabelView = ({
  parentTranslationPath,
  translationPath,
  filter,
  data,
  isLoading,
  onPageSizeChanged,
  onPageIndexChanged,
  reloadData,
  setSortBy
}) => {
  const dispatch = useDispatch();
  const [activeItem, setActiveItem] = useState(null);
  const [rotationEdit, setRotationEdit] = useState(null);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isDeactive, setIsDeactive] = useState(null);
  const tableActionClicked = useCallback((actionEnum, item) => {
    setActiveItem(item);
    if (actionEnum === TableActions.manageAgents.key)
      GlobalHistory.push(`/home/rotation-criteria/manage?id=${item.rotationSchemeId}`);
    dispatch(ActiveItemActions.activeItemRequest(item));
    if (actionEnum === TableActions.editIconAndLabel.key)
      setOpenEditDialog(true);
    if (actionEnum === TableActions.deleteIconAndLabel.key)
      setOpenDeleteDialog(true);
    if (actionEnum === TableActions.viewSchemaTabelView.key) {
      dispatch(ActiveItemActions.activeItemRequest(item));
      GlobalHistory.push(`/home/rotation-criteria/View-details?id=${item.rotationSchemeId}`);
    }
  }, []);

  const { t } = useTranslation([parentTranslationPath, 'Shared']);

  const leadTypeComp = (item) => (
    <div>
      {item && item.leadType === LeadsClassTypesEnum.tenant.value && (
        <div className='leadType tenant'>{t(`${translationPath}Tenant`)}</div>
      )}
      {item && item.leadType === LeadsClassTypesEnum.seller.value && (
        <div className='leadType seller'>{t(`${translationPath}Seller`)}</div>
      )}
      {item && item.leadType === LeadsClassTypesEnum.buyer.value && (
        <div className='leadType buyer'>{t(`${translationPath}Buyer`)}</div>
      )}
      {item && item.leadType === LeadsClassTypesEnum.landlord.value && (
        <div className='leadType landlorb'>
          <span>{t(`${translationPath}Landlorb`)}</span>
        </div>
      )}
    </div>
  );
  const repeated = (list, filed) => list && list.map((item, index) => (
    <span>
      {' '}
      {item[filed]}
      {' '}
      {list.length - 1 != index &&
        <span> , </span>}
    </span>
  ));

  const priceRangesRepeated = (list) => list && list.map((item, index) => (
    <div>
      <span>
        {' '}
        {t(`${translationPath}From`)}
        {' '}
        :
        {' '}
        {item.startValue}
        {' '}
      </span>
      <span>
        {' '}
        {t(`${translationPath}To`)}
        {' '}
        :
        {' '}
        {item.endValue}
        {' '}
      </span>
      {list.length - 1 != index && <span> , </span>}
    </div>
  ));

  const GetRotationCriteria = useCallback(async () => {
    const res = await GetRotationSchemeByIdServices(activeItem && activeItem.rotationSchemeId);
    if (!(res && res.status && res.status !== 200))
      setRotationEdit(res);
  }, [activeItem]);
  const getTableActionsWithPermissions = () => {
    // eslint-disable-next-line prefer-const
    let list = [];
    list.push({
      enum: TableActions.viewSchemaTabelView.key,
      title: t(`${translationPath}Shared:view`),
    });
    if (returnPropsByPermissions(RotationSchemaPermissions.ViewRotationSchemaAssignNewAgent.permissionsId)) {
      list.push({
        enum: TableActions.manageAgents.key,
        title: t(`${translationPath}Shared:manage-agents`),
      });
    }
    if (returnPropsByPermissions(RotationSchemaPermissions.EditRotations.permissionsId)) {
      list.push({
        enum: TableActions.editIconAndLabel.key,
        title: t(`${translationPath}Shared:edit`),
      });
    }
    if (returnPropsByPermissions(RotationSchemaPermissions.DeleteRotations.permissionsId)) {
      list.push({
        enum: TableActions.deleteIconAndLabel.key,
        title: t(`${translationPath}Shared:delete`),
      });
    }
    return list;
  };

  useEffect(() => {
    if (activeItem && activeItem.rotationSchemeId)
      GetRotationCriteria();

    setRotationEdit(null);
  }, [activeItem]);

  const saveHandler = useCallback(async () => {
    setIsEditLoading(true);
    const { rotationSchemeId } = rotationEdit;
    const currntState = {
      rotationSchemeId: null,
      label: '',
      order: 0,
      rotationPreferredLanguages: [],
      rotationSchemeCountries: [],
      rotationSchemeCities: [],
      rotationSchemeCommunities: [],
      rotationSchemeDistricts: [],
      rotationSchemeSubCommunities: [],
      rotationSchemeUnitTypes: [],
      rotationSchemeRanges: [],
      rotationSchemaContactCLasses: [],
      rotationSchemeMedias: [],
      rotationSchemaDeveloperIds: [],
      rotationSchemaReferredBys: [],
      rotationSchemeProperties: [],
      rotationSchemaLeadsType: [],
      rotationSchemaMethodOfContacts: [],
      propertyCampaignIds: [],
      isActive: false,
      isBulk: false,
      rotationSchemeLeadDistributionType: 3,
      isOverrideLeadCapacitySettingEnabled: false,
      sla:null,
      rotationSchemeTeams: [],
      rotationSchemeBranches: [],
    };
    currntState.rotationSchemeId = rotationEdit.rotationSchemeId;
    rotationEdit.languages.map((item) => {
      currntState.rotationPreferredLanguages.push({
        languageName: item.lookupItemName,
        languageId: item.lookupsId,
      });
    });
    rotationEdit.countries.map((item) => {
      currntState.rotationSchemeCountries.push({
        countryName: item.lookupItemName,
        countryId: item.lookupsId,
      });
    });
    rotationEdit.cities.map((item) => {
      currntState.rotationSchemeCities.push({
        cityName: item.lookupItemName,
        cityId: item.lookupsId,
      });
    });
    rotationEdit.districts.map((item) => {
      currntState.rotationSchemeDistricts.push({
        districtName: item.lookupItemName,
        districtId: item.lookupsId,
      });
    });
    rotationEdit.communities.map((item) => {
      currntState.rotationSchemeCommunities.push({
        communityName: item.lookupItemName,
        communityId: item.lookupsId,
      });
    });
    rotationEdit.subCommunities.map((item) => {
      currntState.rotationSchemeSubCommunities.push({
        subCommunityName: item.lookupItemName,
        subCommunityId: item.lookupsId,
      });
    });
    rotationEdit.leadClasses.map((item) => {
      currntState.rotationSchemaContactCLasses.push({
        contactClassId: item.lookupsId,
        contactClassName: item.lookupItemName
      });
    });
    rotationEdit.unitTypes.map((item) => {
      currntState.rotationSchemeUnitTypes.push({
        unitTypeName: item.lookupItemName,
        unitTypeId: item.lookupsId,
      });
    });
    rotationEdit.developers.map((item) => {
      currntState.rotationSchemaDeveloperIds.push({
        developerName: item.developerName,
        developerId: item.developerId,
      });
    });

    rotationEdit.properties.map((item) => {
      currntState.rotationSchemeProperties.push({
        propertyName: item.propertyName,
        propertyId: item.propertyId,
      });
    });

    rotationEdit.referredBys.map((item) => {
      currntState.rotationSchemaReferredBys.push({
        userId: item.userId,
        fullName: item.fullName
      });
    });
    rotationEdit.medias.map((item) => {
      currntState.rotationSchemeMedias.push({
        mediaName: item.mediaName,
        mediaNameId: item.mediaId === 0 ? null : item.mediaId,
        mediaDetailsName: item.mediaDetails,
        mediaDetailsId: item.mediaDetailsId === 0 ? null : item.mediaDetailsId,
      });
    });
    rotationEdit.rotationSchemeRanges.map((item) => {
      currntState.rotationSchemeRanges.push({
        ...item,
      });
    });
    rotationEdit.methodOfContact.map((item) => {
      currntState.rotationSchemaMethodOfContacts.push({
        methodOfContactName: item.methodOfContactName,
        methodOfContactId: item.methodOfContactId
      });
    });
    rotationEdit.leadsType.map((item) => {
      currntState.rotationSchemaLeadsType.push({
        leadClass: item.leadClass.toLowerCase()
      });
    });
    rotationEdit.rotationSchemeCampaigns.map((item) => {
      currntState.propertyCampaignIds.push(item.propertyCampaignId);
    });
    rotationEdit.rotationSchemeTeams.map((item) => {
      currntState.rotationSchemeTeams.push({
        teamId: item.teamId,
        teamName: item.teamName
      });
    });

    rotationEdit.rotationSchemeBranches.map((item) => {
      currntState.rotationSchemeBranches.push({
        branchId: item.branchId,
        branchName: item.branchName
      });
    });
    currntState.label = rotationEdit.label;
    currntState.isActive = !rotationEdit.isActive;
    currntState.order = rotationEdit.order;
   currntState.sla=rotationEdit.sla;
    currntState.isBulk = rotationEdit.isBulk;
    currntState.isOverrideLeadCapacitySettingEnabled = rotationEdit.isOverrideLeadCapacitySettingEnabled;
    currntState.rotationSchemeLeadDistributionType = rotationEdit.rotationSchemeLeadDistributionType;

    const res = await UpdateRotationScheme(rotationSchemeId, currntState);

    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t`${translationPath}Edit-Rotation-Scheme-status-successfully`);
      setIsDeactive(null);
      setActiveItem(null);
      reloadData();
    } else
      showError(t('Shared:Server-Error'));

    setIsEditLoading(false);
  }, [isDeactive, rotationEdit]);

  useEffect(() => {
    if (rotationEdit && isDeactive)
      saveHandler();
  }, [isDeactive, rotationEdit]);

  return (
    <div className='RotationSchemaTabelView '>
      <Spinner isActive={isLoading || isEditLoading} isAbsolute />
      <div className='filter-section-item' />
      <div className='w-100 px-2'>
        <Tables
          data={(data && data.result) || []}
          headerData={[
            {
              id: 1,
              isSortable: true,
              input: 'label',
              label: t(`${translationPath}label`),
              component: (item) => (
                <div>
                  {item && item.label}
                </div>
              ),
            },
            {
              id: 2,
              isSortable: true,
              input: 'order',
              label: 'order',
            },
            {
              id: 3,
              // isSortable: true,
              label: 'isBulk',
              component: (item) => <span>{((item && item.isBulk === true) && 'Yes') || ((item && item.isBulk === false && 'No')) || ''}</span> || ''
            },
            {
              id: 4,
              label: t(`${translationPath}agents`),
              component: (item) => (
                <span
                  className='c-primary'
                >
                  {(item && item.numberOfAgents) || 0}
                </span>
              ),
            },
            {
              id: 5,
              label: `${t('status')}`,
              input: 'isActive',
              component: (item, index) => (
                <SwitchComponent
                  idRef={`isOpenRef${index + 1}`}
                  isChecked={item.isActive || false}
                  isDisabled={!(returnPropsByPermissions(RotationSchemaPermissions.SetARotationAsActiveDeactivated.permissionsId))}
                  labelClasses='px-0'
                  onChangeHandler={() => {
                    setActiveItem(item);
                    setIsDeactive(item);
                  }}
                  themeClass='theme-line'
                  labelValue={(item.isActive && `${t('Active')}`) || `${t('Deactive')}`}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
              )
            },
            {
              id: 6,
              label: t(`${translationPath}number-of-assigned-leads`),
              component: (item) => (
                <span
                  className='c-primary'
                >
                  {(item?.numberOfLeadsAssigned) || 0}
                </span>
              ),
            },
            {
              id: 6,
              label: t(`${translationPath}language`),
              component: (item) => repeated(item.languages, 'lookupItemName'),
            },
            {
              id: 7,
              label: t(`${translationPath}country`),
              component: (item) => repeated(item.countries, 'lookupItemName'),
            },
            {
              id: 8,
              label: t(`${translationPath}city`),
              component: (item) => repeated(item.cities, 'lookupItemName'),
            },
            {
              id: 9,
              label: t(`${translationPath}district`),
              component: (item) => repeated(item.districts, 'lookupItemName'),
            },
            {
              id: 10,
              label: t(`${translationPath}community`),
              component: (item) => repeated(item.communities, 'lookupItemName'),
            },
            {
              id: 11,
              label: t(`${translationPath}subCommunity`),
              component: (item) => repeated(item.subCommunities, 'lookupItemName'),
            },
            {
              id: 12,
              label: t(`${translationPath}properties`),
              component: (item) => repeated(item.properties, 'propertyName'),
            },
            {
              id: 13,
              label: t(`${translationPath}developer`),
              component: (item) => repeated(item.developers, 'developerName')
            },
            {
              id: 14,
              label: t(`${translationPath}unitType`),
              component: (item) => repeated(item.unitTypes, 'lookupItemName'),
            },
            {
              id: 15,
              label: t(`${translationPath}priceRange`),
              component: (item) => priceRangesRepeated(item.priceRanges)
            },
            {
              id: 16,
              label: t(`${translationPath}createdOn`),
              component: (item) => (
                <div>
                  {moment(item.createdOn).format('DD/MM/YYYY')}
                </div>
              )
            },
            {
              id: 17,
              label: 'Cap-Based-On',
              component: (item) => <span>{item && item.isOverrideLeadCapacitySettingEnabled ? 'Current Schema' : 'Overall System'}</span> || ''
            },
            {
              id: 18,
              label: 'count-based-on',
              input: 'rotationSchemeLeadDistributionTypeName'
            },
          ]}
          defaultActions={
            getTableActionsWithPermissions()
          }
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          setSortBy={setSortBy}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          totalItems={(data && data.totalCount) || 0}
        />
      </div>
      {openEditDialog && (
        <RotationCriteriaDialog
          rotationEdit={rotationEdit}
          open={openEditDialog}
          close={() => {
            setOpenEditDialog(false);
            const currentActive = {};
            setActiveItem(currentActive);
          }}
          onSave={() => {
            reloadData();
            const currentActive = {};
            setActiveItem(currentActive);
          }}
          setRotationEdit={setRotationEdit}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {openDeleteDialog && (
        <DeleteRotationCriteriaDialog
          rotationCriteria={activeItem}
          open={openDeleteDialog}
          close={() => {
            setOpenDeleteDialog(false);
            const currentActive = {};
            setActiveItem(currentActive);
          }}
          onSave={() => {
            setOpenDeleteDialog(false);
            reloadData();
            const currentActive = {};
            setActiveItem(currentActive);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}

    </div>
  );
};
const convertJsonValueShape = PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.number]);

RotationSchemaTabelView.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  filter: PropTypes.number.isRequired,
  data: PropTypes.objectOf(convertJsonValueShape).isRequired,
  onPageIndexChanged: PropTypes.func.isRequired,
  onPageSizeChanged: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  reloadData: PropTypes.func.isRequired,
  setSortBy: PropTypes.func.isRequired,
};
