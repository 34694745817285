import React, { useState, useEffect } from 'react';
import { SupportHeader } from '../../../SharedComponents/SupportHeader/SupportHeader';
import { SupportFooter } from '../../../SharedComponents/SupportFooter/SupportFooter';
import { SupportTicketsCards } from './SupportTicketsCards';
import animatedBlob from '../../../assets/images/icons/animatedBlob.svg';
import freshdeskLogo from '../../../assets/images/freshdesk-logo.webp';
import { GetAllTicketListFromFreshDisk } from '../../../Services';
import { useTitle } from '../../../Hooks';

export const MySupportTicketsView = () => {
  useTitle('My Support Tickets');

  const [ticketsList, setTicketsList] = useState([]);

  const getFreshDiskTickets = async () => {
    const res = await GetAllTicketListFromFreshDisk({
      pageIndex: 1,
      pageSize: 100,
    });
    if (!(res && res.status && res.status !== 200) && !(res && res.result && res.result.errors)) {
      setTicketsList(res?.result || []);
    } else {
      setTicketsList([]);
    }
  };

  useEffect(() => {
    getFreshDiskTickets();
  }, []);

  return (
    <div className='support-view-wrapper'>
      <SupportHeader />

      <div className='support-tickets-wrapper'>
        <h1 className='mx-5 my-4'>Freshdesk Support Tickets</h1>

        <SupportTicketsCards data={ticketsList} />

        <div className='bg-container'>
          <img className='bg-logo' src={freshdeskLogo} />
          <img
            src={animatedBlob}
            style={{
              opacity: '.2',
              width: '99vw',
              height: '90vh',
            }}
          />
        </div>
      </div>
      <SupportFooter />
    </div>
  );
};
