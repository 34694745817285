import React, {
    useCallback, useEffect, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { AutocompleteComponent, RadiosGroupComponent } from '../../../../../../Components';
import { getErrorByName } from '../../../../../../Helper';
import { GetAllCampaigns } from '../../../../../../Services';

export const LeadCampaignComponent = ({
    parentTranslationPath,
    translationPath,
    onStateChanged,
    state,
    schema,
    isSubmitted,
    values,
    rotationEdit,
    currentSelected,
    setCurrentSelected
}) => {
    const { t } = useTranslation([parentTranslationPath, 'Shared']);
    const searchTimer = useRef(null);
    const [searchItem, setSearchItem] = useState('');
    const [ViewComponent, setViewComponent] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [allCampaigns, setAllCampaigns] = useState({
        result: [],
        totalCount: 0
    });

    const searchHandler = (event) => {
        const { value } = event.target;
        if (searchTimer.current) clearTimeout(searchTimer.current);
        searchTimer.current = setTimeout(() => {
            setSearchItem(value);
        }, 700);
    };

    const getAllCampaigns = useCallback(async () => {
        setIsLoading(true);
        const res = await GetAllCampaigns({
            pageIndex: 1,
            pageSize: 50,
            propertyCampaignName: searchItem || '',
          });
        if (!(res && res.status && res.status !== 200)) {
            setAllCampaigns({
                result: res.result,
                totalCount: res.totalCount,
            });
        } else {
            setAllCampaigns({
                result: [],
                totalCount: 0
            });
        }
        setIsLoading(false);
    }, [searchItem]);

    useEffect(() => {
        getAllCampaigns();
    }, [getAllCampaigns, searchItem]);

    useEffect(() => {
        if(state?.propertyCampaignIds?.length > 0) 
        {
          setViewComponent(2)
        }
        else{
            setViewComponent(1)
          }
      }, [state]);
    return (
      <div className='dialog-content-item'>
        <RadiosGroupComponent
          idRef='campaignDetails2'
          isDisabled={state?.propertyCampaignIds?.length > 0 || false}
          wrapperClasses='wrapperClasses-cusyy'
          themeClass='theme-default'
          data={[
                    {
                        key: 1,
                        label:  t(`${translationPath}Campaigns-all-for-General`) ,
                        value: false,
                    },
                    {
                        key: 2,
                        label:  t(`${translationPath}Specific-Campaign`) ,
                        value: true,
                    },
                ]}
          onSelectedRadioChanged={(event) =>
                    setViewComponent(
                        +event.target.value || false,
                    )}
          value={(ViewComponent) || null}
          labelValue={t(`${translationPath}Campaigns`)}
          labelInput='label'
          valueInput='key'
        />
        {ViewComponent === 2 && (
        <AutocompleteComponent
          idRef='LeadCampaignRef'
          labelValue={t(`${translationPath}lead-campaign`)}
          value={state.propertyCampaignIds || []}
          data={allCampaigns.result || []}
          multiple
          isLoading={isLoading}
          displayLabel={(option) => (option && option.name) || ''}
          chipsLabel={(option) => (option && option.name) || (option.campaignName) || ''}
          withoutSearchButton
          onInputKeyUp={searchHandler}
          selectedValues={currentSelected && currentSelected.propertyCampaignIds || []}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
                        const localNewValue = {
                            id: 'propertyCampaignIds',
                            value: newValue.map((el) => (el.propertyCampaignId)),
                        };
                        onStateChanged(localNewValue);
                        setCurrentSelected((e) => ({ ...e, propertyCampaignIds: newValue }));
                    }}
          filterOptions={(options) => {
                        const isFind = (id) => currentSelected && currentSelected.propertyCampaignIds.findIndex((item) => item.propertyCampaignId === id) === -1;
                        return options.filter((el) => isFind(el.propertyCampaignId));
                    }}
          isSubmitted={isSubmitted}
          helperText={getErrorByName(schema, 'rotationSchemeLeadType').message}
          error={getErrorByName(schema, 'rotationSchemeLeadType').error}
        />
            )}
      </div>
    );
};

LeadCampaignComponent.propTypes = {
    parentTranslationPath: PropTypes.string.isRequired,
    translationPath: PropTypes.string.isRequired,
    onStateChanged: PropTypes.func.isRequired,
    state: PropTypes.instanceOf(Object).isRequired,
    schema: PropTypes.instanceOf(Object).isRequired,
    isSubmitted: PropTypes.bool.isRequired,
};
