import React from 'react';

export const PropertiesTableHeaderData = [
  {
    id: 1,
    label: 'Name',
    input: 'property_name',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    fieldType: 'text' ,

  },
  {
    id: 2,
    label: 'Created By',
    input: 'createdBy',
    fieldKey: 'createdBy',
    fieldType: 'searchField' , 
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    data:  {
      type: "string",
      title: "Created By",
      description: "General Information",
      searchKey: "User",
      iconField: "",
      dependOn: "",
      multi: "false",
      regExp: "^(?:[a-zA-Z\\s]+|\\*{8})$",
      errorMsg: "Fill name in a correct way",
      default: "",
      isSearchable: false,
      searchableKey: "createdBy",
      displayPath: "createdBy"
   }
  },
  {
    id: 3,
    label: 'Created On',
    input: 'createdOn',
    fieldKey: 'createdOn',
    fieldType: 'alt-date' , 
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isDate: true,
    dateFormat: 'DD/MM/YYYY',
    isSortable: true,
  },

  {
    id: 4,
    label: 'Updated By',
    input: 'updatedBy',
    fieldKey: 'updatedBy',
    fieldType: 'searchField' , 
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    data:  {
      type: "string",
      title: "Updated By",
      description: "General Information",
      searchKey: "User",
      iconField: "",
      dependOn: "",
      multi: "false",
      regExp: "^(?:[a-zA-Z\\s]+|\\*{8})$",
      errorMsg: "Fill name in a correct way",
      default: "",
      isSearchable: false,
      searchableKey: "updatedBy",
      displayPath: "updatedBy"
  }
  },
  {
    id: 5,
    label: 'Progress',
    input: 'data_completed',
    textInputType: 'number',
    isDefaultFilterColumn: true,
    fieldKey: 'data_completed',
    fieldType: 'number' , 
    textInputMax: 100,
    textInputMin: 0,
    isHiddenFilter: true,
    isSortable: true,
    isNumber: true,   
    component: (item) => <span>{item && item.progressWithPercentage} </span> || <span />
  },
  {
    id: 6,
    label: 'Property Owner',
    isDefaultFilterColumn: true,
    fieldType: 'searchField' , 
    fieldKey: 'property_owner',
    input: 'property_owner.name',
    isSortable: true,
    data:  {
      type: "string",
      title: "Property Owner",
      description: "General Information",
      searchKey: "contact",
      enum :[] , 
      iconField: "",
      dependOn: "",
      multi: "false",
      regExp: "^(?:[a-zA-Z\\s]+|\\*{8})$",
      errorMsg: "Fill name in a correct way",
      default: "",
      isSearchable: false,
      searchableKey: "property_owner",
      displayPath: "property_owner"
  }, 
    component: (item) => <span>{item && item.property_owner} </span> || <span />
  },
  // {
  //   id: 7,
  //   label: 'City',
  //   isDefaultFilterColumn: true,
  //   fieldKey: 'city',
  //   input: 'city.lookupItemName',
  //   isSortable: true,
  //   component: (item) => <span>{item && item.city} </span> || <span />
  // },


  // {
  //   id: 8,
  //   label: 'Community',
  //   isDefaultFilterColumn: true,
  //   fieldKey: 'community',
  //   input: 'community.lookupItemName',
  //   isSortable: true,
  //   component: (item) => <span>{item && item.community} </span> || <span />
  // },
  {
    id: 7,
    label: 'Property Usage',
    isDefaultFilterColumn: true,
    fieldKey: 'property_usage',
    input: 'property_usage.lookupItemName',
    isSortable: true,
    lookup : 50 , 
    fieldType: 'select' , 
    component: (item) => <span>{item && item.property_usage} </span> || <span />

  },
  {
    id: 8,
    label: 'isBulkUpload',
    input: 'isBulkUpload',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    data : { enum :['Yes' , 'No'] } ,
    fieldType: 'select' , 
    component: (item) => <div>{item?.isBulkUpload? 'true': 'false'}</div>
  } , 
  {
    id: 9,
    label: 'IsPublished',
    input: 'webPublished',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    data : { enum :['true' , 'false'] } ,
    fieldType: 'select' , 
    component: (item) => <div>{item?.webPublished? 'true': 'false'}</div>
  },
  {
    id: 10,
    label: 'Property Rating From',
    input: 'fromRating',
    textInputType: 'number',
    isDefaultFilterColumn: true,
    fieldKey: 'fromRating',
    fieldType: 'number' , 
    textInputMax: 100,
    textInputMin: 0,
    isHiddenFilter: false,
    isSortable: true,
    isNumber: true,
    component: (item) => <span>{item && item.propertyRating} </span> || <span />
  },
  {
    id: 11,
    label: 'Property Rating To',
    input: 'toRating',
    textInputType: 'number',
    isDefaultFilterColumn: true,
    fieldKey: 'toRating',
    fieldType: 'number' , 
    textInputMax: 100,
    textInputMin: 0,
    isHiddenFilter: false,
    isSortable: true,
    isNumber: true,
    component: (item) => <span>{item && item.propertyRating} </span> || <span />
  },
  {
    id: 12,
    label: 'Published Date',
    input: 'webPublishedDate',
    fieldKey: 'createdOn',
    fieldType: 'alt-date' , 
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isDate: true,
    dateFormat: 'YYYY-MM-DDTHH:mm:ss',
    isSortable: true,
  } , 
];
