import React, { useState, useEffect, useCallback } from 'react'
import { DialogComponent, CheckboxesComponent, Spinner } from "../../Components";
import { GetAllUserSearchCriteria, DeleteListOfSearchCriteria } from '../../Services';
import { showError, showSuccess } from '../../Helper';
import { useTranslation } from 'react-i18next';
import './AdvanceSearch.scss';
import NoSearchResult from '../../assets//images/defaults/NoContent.png';

export const DeleteFilter = ({
    isOpen,
    setIsOpenDeleteFilter,
    searchCriteriaTypeId,
}) => {
    const { t } = useTranslation('Shared');
    const [searchResult, setSearchResult] = useState([]);
    const [checkedFilterIndexs, setCheckedFilterIndexs] = useState([]);
    const [checkedFilterIds, setCheckedFilterIds] = useState([]);
    const [saveIsDisabled, setSaveIsDisabled] = useState(true);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getIsSelectedFilter = useCallback(
        (itemIndex) =>
            checkedFilterIndexs.findIndex((item) => item === itemIndex) !== -1
        , [checkedFilterIndexs]
    );
    const filterCheckboxClicked = useCallback((e, itemIndex) => {
        setCheckedFilterIds((items) => {

            const index = items.findIndex((item) => item === e.userSearchCriteriaId);
            if (index !== -1) items.splice(index, 1);
            else items.push(e.userSearchCriteriaId);
            if (items.length) setSaveIsDisabled(false);
            else setSaveIsDisabled(true);
            return [...items];
        });
        setCheckedFilterIndexs((items) => {
            const index = items.findIndex((item) => item === itemIndex);
            if (index !== -1) items.splice(index, 1);
            else items.push(itemIndex);
            return [...items];
        });

    }, []);

    const getSearchCriteria = useCallback(async () => {
        setIsLoading(true);
        const res = await GetAllUserSearchCriteria(searchCriteriaTypeId);
        if (!(res && res.status && res.status !== 200)) {
            setSearchResult(res);
        } else {
            setSearchResult([]);
        }
        setIsLoading(false);
    }, [])

    const deleteListOfSearchCriteria = async () => {
        setIsLoading(true);
        let res = await DeleteListOfSearchCriteria(checkedFilterIds);
        if (!(res && res.status && res.status !== 200)) {
            showSuccess(t(`delete-filters-successfully`));
        } else {
            showError(t(`failed-to-delete-filter`));
        }
        setIsLoading(false);
        setIsOpenDeleteFilter(false);
    }

    useEffect(() => {
        getSearchCriteria();
    }, []);

    useEffect(() => {
        if (searchResult.length > 0 &&
            checkedFilterIds.length === searchResult.length) {
            setIsAllChecked(true);
        }
        if (searchResult.length > 0 &&
            checkedFilterIds.length < searchResult.length) {
            setIsAllChecked(false);
        }
    }, [checkedFilterIds]);

    return (
        <>
            <DialogComponent
                isOpen={isOpen}
                titleText={t('delete-filter')}
                maxWidth='sm'
                onSaveClicked={deleteListOfSearchCriteria}
                saveText={t('delete')}
                saveClasses={'btns theme-solid bg-danger'}
                onCancelClicked={() => {
                    setIsOpenDeleteFilter(false);
                }}
                formWrapperClasses={'advance-delete-dialog-Classes'}
                disableBackdropClick
                saveIsDisabled={isLoading || saveIsDisabled}
                dialogContent={
                    <>
                        <Spinner isActive={isLoading} isAbsolute />
                        {searchResult.length && (
                            <div className='delete-wrapper-content'>

                                <div className='select-all'>
                                    <CheckboxesComponent
                                        idRef={`unitsCheckboxItemRef`}
                                        singleChecked={
                                            isAllChecked
                                        }
                                        singleIndeterminate={
                                            checkedFilterIds.length > 0 &&
                                            checkedFilterIds.length < searchResult.length
                                        }

                                        onSelectedCheckboxClicked={(event) => {
                                            event.preventDefault();
                                            setSaveIsDisabled(false);
                                            setIsAllChecked(true);
                                            let list = [];
                                            let indexs = [];
                                            searchResult.forEach((item, indx) => {
                                                list.push(item.userSearchCriteriaId)
                                                indexs.push(indx)
                                            })
                                            setCheckedFilterIndexs(indexs)
                                            setCheckedFilterIds(list)
                                            if (checkedFilterIds.length === searchResult.length) {
                                                setCheckedFilterIds([]);
                                                setCheckedFilterIndexs([])
                                                setSaveIsDisabled(true);
                                                setIsAllChecked(false);
                                            }
                                        }}
                                    />
                                    <span>{t('select-all')}</span>
                                </div>
                                {searchResult && searchResult.map((item, index) => {
                                    return (
                                        <>
                                            <div className='item-wrapper'>
                                                <CheckboxesComponent
                                                    idRef={`unitsCheckboxItemRef${index + 1}`}
                                                    singleChecked={getIsSelectedFilter(index) || isAllChecked}
                                                    onSelectedCheckboxClicked={(event) => {
                                                        event.preventDefault();
                                                        filterCheckboxClicked(item, index);
                                                    }}
                                                />
                                                <span className='item'>{item.searchCriteriaName}</span>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        ) || (!isLoading &&
                            <>
                                <div className='no-content-wrapper'>
                                    <span className='no-content-title'>{t(`no-filter-saved`)}</span>
                                    <img
                                        src={NoSearchResult}
                                        alt={t(`No-Content`)}
                                        className="no-content-img"
                                    />
                                </div>
                            </>
                            )}
                    </>
                }
            />
        </>
    );
}
