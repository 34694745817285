import { ConvoloAgents   } from "../../Views/Home/AgentsView/ConvoloAgentsView/ConvoloAgents";
import { AssignConvoloAgentTable } from '../../Views/Home/AgentsView/ConvoloAgentsView/AssignConvoloAgentTable';
export const ConvoloAgentsRoutes = [
  {
    path: "/view",
    name: 'Agents:convolo-agents',
    component: ConvoloAgents,
    layout: "/home/convolo-agents",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'Agents:convolo-agents',
        isDisabled: true,
        route: "/home/convolo-agents/view",
        groupName: "agent-management",
      },
    ],
  },
  {
    path: '/manage',
    name: 'Agents:manage-agents',
    component:AssignConvoloAgentTable ,
    layout: '/home/convolo-agents',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'Agents:convolo-agents',
        isDisabled: false,
        route: '/home/convolo-agents/view',
        groupName: 'agent-management',
      },
      {
        name: 'Agents:assign-agents',
        isDisabled: true,
        route: '/home/convolo-agents/manage',
      },
    ],
  },
];
