import {
  WorkOrdersView, WorkOrdersManagementView, WorkOrderAddOnlyView, TransactionConfigurationView, UnitTransactionConfigFields
} from '../../Views/Home';

export const TransactionConfigurationRoutes = [
  {
    path: '/view',
    name: 'TransactionConfigurationView:TransactionConfigurationView',
    component: TransactionConfigurationView,
    layout: '/home/TransactionConfigurationView',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'TransactionConfigurationView:TransactionConfigurationView',
        isDisabled: true,
        route: '/home/TransactionConfigurationView',
        groupName: 'system-configuration',
      },
    ],
  },
  {
    path: '/edit',
    name: 'TransactionConfigurationView:edit',
    component: UnitTransactionConfigFields,
    layout: '/home/TransactionConfigurationView',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'TransactionConfigurationView:TransactionConfigurationView',
        isDisabled: false,
        route: '/home/TransactionConfigurationView/view',
        groupName: 'system-configuration',
      },
      {
        name: 'TransactionConfigurationView:TransactionConfigurationViewedit',
        isDisabled: true,
        route: '/home/TransactionConfigurationView/edit',
      },
    ],
  },
  {
    path: '/add',
    name: 'TransactionConfigurationView:add',
    component: UnitTransactionConfigFields,
    layout: '/home/TransactionConfigurationView',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'TransactionConfigurationView:TransactionConfigurationView',
        isDisabled: false,
        route: '/home/TransactionConfigurationView/view',
        groupName: 'system-configuration',
      },
      {
        name: 'TransactionConfigurationView:TransactionConfigurationViewadd',
        isDisabled: true,
        route: '/home/TransactionConfigurationView/add',
      },
    ],
  },
];
