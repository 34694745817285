export const LandmarksPermissions = {
  
  ViewAllLandmarks : {
     permissionsId: "d47d2472-bdb8-4c67-886b-08dc0129f7d6",
     permissionsName: "View All Landmarks",
     description: null,
     componentsId: null,
     components: null
    },

   CreateLandmarks :{
    permissionsId: "479a36b6-0b7b-4dff-886c-08dc0129f7d6",
    permissionsName: "Create Landmarks",
    description: null,
    componentsId: null,
    components: null
  },
  EditLandmarks : {
    permissionsId: "73bbd8a2-4f3d-4a72-886d-08dc0129f7d6",
    permissionsName: "Edit Landmarks",
    description: null,
    componentsId: null,
    components: null
  }

};
