import { makeStyles } from "@material-ui/core/styles";

const leadsCardStyles = makeStyles((theme) => ({
  leadsContainer: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  leadWrapper: {},
  statusSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "12px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "4px",
    },
  },
  statusInfoWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  status: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
  },
  open: {
    color: theme.palette.text.success_primary,
  },
  close: {
    color: theme.palette.utility.error_500,
  },
  statusInfo: {
    color: theme.palette.text.text_quarterary,
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
  },
  infoSection: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    color: theme.palette.text.primary,
  },
  value: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 600,
  },
  f14: {
    fontSize: "14px",
    lineHeight: "20px",
  },
  referredToImg: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  referredToImgWrapper: {
    border: `0.5px solid ${theme.palette.border.avatarContrast}`,
    borderRadius: "50%",
  },
  emptyState: {
    fontSize: "18px",
  },
}));

const commentsCardStyles = makeStyles((theme) => ({
  contactOverViewCard: {
    padding: "24px",
  },
  cardTitleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "none",
    paddingBottom: "20px",
  },
  titleContainer: {
    display: "flex",
    gap: "4px",
    alignItems: "flex-start",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "28px",
  },
  subTitle: {
    lineHeight: "24px",
    fontSize: "16px",
    fontWeight: 400,
    color: theme.palette.text.tertiary,
  },
  textArea: {
    minHeight: "75px",
    borderColor: theme.palette.border.primary,
    background: theme.palette.background.primary,
    borderRadius: "8px",
    padding: "12px 14px",
    fontSize: "16px",
    lineHeight: "24px",
    width: "100%",
    marginBottom: "20px",
  },
  actionsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  divider: {
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: theme.palette.border.secondary,
  },
  commentsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
}));

const transactionCardStyles = makeStyles((theme) => ({
  transactionContainer: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  transactionWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "12px",
  },
  infoSection: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
  infoItem: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  infoValue: {
    fontWeight: 600,
  },
  f16: {
    fontSize: "16px",
  },
  propertyDetailsContainer: {
    marginTop: "10px",
    display: "flex",
    gap: "20px",
    flexWrap: "wrap",
    [theme.breakpoints.down('xs')]: {
      gap: "12px",
    }
  },
  propertyItemWrapper: {
    display: "flex",
    gap: "10px",
  },
  propertyInfoWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
  dateSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    [theme.breakpoints.down('xs')]: {
      alignItems: "flex-start",
    }
  },
  dateAndTime: {
    display: "flex",
    gap: "10px",
    color: theme.palette.text.text_quarterary,
  },
  date: {
    fontSize: "14px",
    fontWeight: 500,
  },
  emptyState: {
    fontSize: '18px',
  }
}));

const unitCardStyles = makeStyles((theme) => ({
  unitCardDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    paddingTop: '16px',
  },
  unitCardDetails: {
    display: 'flex',
    gap: '12px',
    width: '100%',
    justifyContent: 'space-between',
  },
  unitNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    width: '100%',
  },
  nameAndPriceWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '4px',
  },
  UnitName: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    maxWidth: '218px',
    minHeight: '20px',
  },
  unitePrice: {
    color: theme.palette.utility.brand_500,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    alignSelf: 'flex-start',
  },
  uniteLocation: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  badgesContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  unitStatus: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    whiteSpace: 'nowrap',
  },
  subDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    flexWrap: 'wrap',
  },
  iconDetailsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  textDetails: {
    color: theme.palette.text.tertiary,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    whiteSpace: 'nowrap',
  },
  emptyState: {
    fontSize: '18px',
  }
}));

export {
  leadsCardStyles,
  commentsCardStyles,
  transactionCardStyles,
  unitCardStyles,
};
