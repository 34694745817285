import React, { useState, useEffect, useRef, useCallback } from 'react';
import { KenbanBoardCard } from '../../Utilities';
import { ProjectTaskStatus } from '../../../../../../Enums';
import { sideMenuComponentUpdate, sideMenuIsOpenUpdate } from '../../../../../../Helper';
import './KenbanCardsLayout.scss';
import { useTranslation } from 'react-i18next';
import {TaskDetailsDialog} from '../../../Dialogs'

export function KenbanCardsLayout({ data, reloadKanban, paginationChangeHandler, paginationFilter }) {
  const parentTranslationPath = 'Campaign';
  const translationPath = 'kenban-Board.';

  const { t } = useTranslation(parentTranslationPath);
  const kenbanBoardCols = useRef({});
  const [isTaskDetailsDialogOpen, setIsTaskDetailsDialogOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const checkIsScrollReachEnd = () => {
    const scrollTopCeil = Math.ceil(kenbanBoardCols.current.scrollTop);
    const scrollTopFloor = Math.floor(kenbanBoardCols.current.scrollTop);

    const scrollHeight = kenbanBoardCols.current.scrollHeight;
    const offsetHeight = kenbanBoardCols.current.offsetHeight;
    const heightDiff = scrollHeight - offsetHeight;

    const isScrollReachEnd = (scrollTopCeil === heightDiff) ||
      (scrollTopFloor === heightDiff) ||
      (scrollTopCeil + 1 === heightDiff);

    return isScrollReachEnd;
  };

  const resetScrollPosition = () => { kenbanBoardCols.current.scrollTop = 0 }

  const boardScrollHandler = () => {

    if (checkIsScrollReachEnd()) {
      const newPageIndex = paginationFilter?.pageIndex + 1;
      paginationChangeHandler(newPageIndex)
    }
  }

  const cardClickHandler = useCallback((event, item, index) => {
    event.stopPropagation();
    event.preventDefault();
    setActiveItem(item);
    setIsTaskDetailsDialogOpen(true);
  }, [])

  useEffect(() => {
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  }, []);

  useEffect(() => {
    if (checkIsScrollReachEnd() && paginationFilter.pageIndex === 1)
      resetScrollPosition()
  }, [paginationFilter])


  return (
    <div className="my-task-kenban-cards-layout board-overflow">
      <div className="kenban-cols-headrs mt-4">
        {Object.values(ProjectTaskStatus).map((item) => (
          <div
            className={`kenban-col-header d-flex-v-center-h-between 
           d-flex-default border-gray-tertiary radius-xl p-3 mb-3 ${item.classes}`}
          >
            <span className="text-primary fw-simi-bold fz-16">
              {t(`${translationPath}${item.label}`)}
            </span>
            {data && data[item.key] && data[item.key].totalCount ? (
              <div className="col-count px-2 bg-col-gray-light border-gray-secondary radius-full px-1 ml-1">
                <span className="text-tertiary fz-12 d-block px-1">
                  {data[item.key].totalCount}
                </span>
              </div>
            ) : null}
          </div>
        ))}
      </div>
      <div
        ref={kenbanBoardCols}
        className="kenban-cols"
        onScroll={boardScrollHandler}
      >
        {data &&
          Object.values(data).map((column, index) => (
            <div
              ref={kenbanBoardCols}
              key={`column-number-${index}`}
              className="kenban-column p-2 radius-md"
            >
              {column.result.map((item, index) => (
                <KenbanBoardCard
                  key={`task-${item.taskName}-${index}`}
                  cardData={item}
                  cardClickHandler={(event) =>
                    cardClickHandler(event, item, index)
                  }
                />
              ))}
            </div>
          ))}
      </div>
      {isTaskDetailsDialogOpen &&
       activeItem && (
        <TaskDetailsDialog
          isDialogOpen={isTaskDetailsDialogOpen}
          onClose={() => {
            setIsTaskDetailsDialogOpen(false);
          }}
          activeTask={activeItem || null}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          isKanbanView
          reloadKanban={reloadKanban}
        />
      )}
    </div>
  );
}
