import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  container: (props) => ({
    alignItems: "center",
    textAlign: "center",
    backgroundImage: props.isDarkMode
      ? 'url("/images/contacts/darkNoContactsFoundIcon.svg")'
      : 'url("/images/contacts/noContactsFoundIcon.svg")',
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    display: "flex",
    flexDirection: "column-reverse",
    gap: "4px",
    height: "480px",
    width: "480px",
    paddingBottom: "108px",

    "& span": {
      color: theme.palette.text.tertiary,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      margin: 0,
    },
    "& h4": {
      color: theme.palette.text.primary,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "24px",
      margin: 0,
    },
  }),
}));
