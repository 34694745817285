import React from "react";

const FilterLinesICon = ({ fill, ...restProps }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M2.268 4.206c-.268.077-.507.337-.574.624-.096.417.185.865.609.969.104.026 2.36.034 7.78.027 7.299-.009 7.639-.012 7.755-.07.157-.078.341-.263.417-.418.08-.163.08-.513 0-.676a1.061 1.061 0 0 0-.417-.418c-.116-.058-.453-.061-7.788-.066-5.005-.004-7.707.006-7.782.028m2.5 5c-.268.077-.507.337-.574.624-.096.417.185.865.609.969.104.026 1.656.034 5.28.027 4.883-.009 5.14-.012 5.255-.07.157-.078.341-.263.417-.418.08-.163.08-.513 0-.676a1.061 1.061 0 0 0-.417-.418c-.116-.058-.369-.061-5.288-.066-3.353-.004-5.207.006-5.282.028m2.5 4.999a.882.882 0 0 0-.574.625c-.096.417.185.864.609.969.102.026.952.034 2.78.027 2.942-.01 2.74.006 2.995-.248a.716.716 0 0 0 .237-.578.716.716 0 0 0-.237-.578c-.255-.255-.048-.238-3.028-.244-1.702-.004-2.708.006-2.782.027"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
};

export default FilterLinesICon;
