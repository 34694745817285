export const AmountType = {
  FixedAmount: {
    name: 'FixedAmount',
    value: 1,
  },
  PercentageAmount: {
    name: 'PercentageAmount',
    value: 2,
  },
};
