import React from "react";

function DeleteIcon({ fill, ...restProps }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M8.163.868a2.556 2.556 0 0 0-.996.271c-.23.113-.341.196-.586.442-.466.465-.647.881-.715 1.636a9.605 9.605 0 0 0-.032.657l-.001.29-1.775.01c-1.743.009-1.777.011-1.908.081-.307.164-.464.417-.464.745s.158.582.464.745c.116.062.2.072.657.082l.523.012.014 5.106c.015 5.283.015 5.282.157 5.837.268 1.043 1.17 1.946 2.214 2.216.569.147.697.152 4.285.152 3.587 0 3.715-.005 4.285-.151 1.037-.268 1.95-1.181 2.214-2.217.142-.556.142-.556.157-5.837l.014-5.106.523-.012c.457-.01.541-.02.657-.082.306-.163.464-.417.464-.745s-.157-.581-.464-.745c-.131-.07-.165-.072-1.908-.081l-1.775-.01-.001-.29c0-.404-.055-.985-.116-1.224a3.15 3.15 0 0 0-.189-.483c-.113-.23-.196-.34-.442-.586-.465-.466-.881-.647-1.636-.715C11.36.829 8.59.83 8.163.868m3.87 1.719a.925.925 0 0 1 .24.167c.168.179.202.318.22.888l.016.525H7.491l.016-.525c.018-.57.052-.71.22-.887a.727.727 0 0 1 .436-.222c.085-.015.956-.025 1.937-.022 1.742.006 1.787.007 1.933.076m2.957 8.371c-.008 3.771-.022 5.169-.051 5.292a1.63 1.63 0 0 1-1.222 1.192c-.289.06-7.145.06-7.434 0a1.629 1.629 0 0 1-1.222-1.192c-.029-.123-.043-1.512-.051-5.292l-.012-5.125h10.004l-.012 5.125M7.983 8.845a.792.792 0 0 0-.432.494c-.077.259-.077 4.396 0 4.655.226.762 1.339.755 1.567-.008.074-.251.072-4.394-.003-4.647a.81.81 0 0 0-.782-.572.816.816 0 0 0-.35.078m3.333 0a.788.788 0 0 0-.431.494c-.077.259-.077 4.396 0 4.655.224.757 1.339.757 1.564 0 .077-.259.077-4.396 0-4.655a.824.824 0 0 0-1.133-.494"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default DeleteIcon;
