/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {
 Button, DialogTitle, DialogContent, DialogActions, Dialog
} from '@material-ui/core';
import { AutocompleteComponent } from '../../../../../../Components/Controls';
import { showSuccess } from '../../../../../../Helper';
import { GetBusinessGroups, EditTeamServices, OrganizationUserSearch,
   GetTeamsByBusinessGroupsId } from '../../../../../../Services';
import { Spinner } from '../../../../../../Components';
import { Inputs } from '../../../../../../Components/Controls/Inputs/Inputs';

const EditTeamDialog = (props) => {
  const { t } = useTranslation('TeamView');
  const [loading, setloading] = useState(false);
  const [response, setResponse] = useState([]);
  const [usersData, setUsersData] = React.useState([]);
  const [selectedTeamManager, setSelectedTeamManager] = React.useState(null);
  const [isLoading, setIsLoading] = useState({users: false});
  const searchTimer = useRef(null);

  const GetMyGroups = async (pageIndex, PageSize, searchedItem) => {
    const res = await GetBusinessGroups(pageIndex, PageSize, searchedItem);
    if (res) setResponse(res.result);
  };
  const searchHandler = (value) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      GetMyGroups(1, 30, value);
    }, 700);
  };

  const getUsers = async (name) =>{
    setIsLoading((loading) => ({ ...loading, users: true }));
    const res = await OrganizationUserSearch({ name });
    if (res && res.result) {
      setUsersData(res.result);
      setIsLoading((loading) => ({ ...loading, users: false }));
    }
  }

  const getTeamsByBusinessGroupsId = async () =>{

    const res = await GetTeamsByBusinessGroupsId({ 
      businessGroupsId: props.businessGroupsId,
      pageIndex: 1,
      pageSize: 25,
     });
    if (res && res.result) {
      const teamData = res.result.find(item=>item.teamsId===props.editId)

      if(teamData){
        setSelectedTeamManager({
          id: teamData.managerUserId,
          fullName: teamData.managerFullName
        });
      }
    }
  }

  useEffect(()=>{
    getTeamsByBusinessGroupsId();
    getUsers('');
    searchHandler('')
  },[])

  return (
    <Dialog
      className='add-team-dialog-wrapper'
      open={props.open}
      onClose={props.close}
      keepMounted
      aria-labelledby='form-dialog-title'
    >
      <Spinner isActive={loading} />
      <DialogTitle className='DialogTitle' id='form-dialog-title'>
        {t('EditTeamDialog.EditTeam')}
        {' '}
      </DialogTitle>
      <DialogContent className='mb-3'>
        <Inputs
          value={props.name}
          idRef='teamName'
          labelValue={t('EditTeamDialog.Team')}
          onInputChanged={(e) => props.setName(e.target.value)}
        />
        <div htmlFor='lookupTypeName' className='form-name mt-3'>
          {t('EditTeamDialog.BusinessGroup')}
        </div>
        <AutocompleteComponent
          idRef='teamRef'
          inputPlaceholder={t('AddTeamDialog.BusinessGroup')}
          data={response || []}
          multiple={false}
          withoutSearchButton
          getOptionSelected={(option) => props.businessGroupsId === option.businessGroupsId}
          selectedValues={
            props.businessGroupsId &&
            props.response &&
            props.response.find((item) => item.businessGroupsId === props.businessGroupsId)
          }
          displayLabel={(option) => (option.businessGroupsName && option.businessGroupsName) || ''}
          onChange={(e, value) =>
            value && value.businessGroupsId && props.setbusinessGroupsId(value.businessGroupsId)}
          onInputKeyUp={(e) => searchHandler(e.target.value)}
        />
            <div className='team-manager-wrapper'>
            <div htmlFor='lookupTypeName' className='form-name mt-3'>
            {t('EditTeamDialog.TeamManager')}
        </div>
            <AutocompleteComponent
            isLoading={isLoading.users}
            inputPlaceholder={t('EditTeamDialog.TeamManager')}
            multiple={false}
            data={usersData || []}
            displayLabel={(option) => option.fullName || ''}
            withoutSearchButton
            onInputKeyUp={(e) => {
               const newValue = e.target.value;
               if (searchTimer.current) clearTimeout(searchTimer.current);
               searchTimer.current = setTimeout(() => {
               getUsers(newValue)
              }, 700)
            }}
            selectedValues={selectedTeamManager}
            onChange={(event, newValue) => {
              setSelectedTeamManager(newValue)
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button className='btns theme-solid bg-cancel' onClick={props.close}>
          {t('EditTeamDialog.Cancel')}
        </Button>
        <Button
          className='btns theme-solid'
          onClick={async () => {
            setloading(true);
            const result = await EditTeamServices(props.editId, {
              teamsName: props.name,
              businessGroupsId: props.businessGroupsId,
              managerId: selectedTeamManager&&selectedTeamManager.id,
            });
            if (result) {
              props.close();
              props.reloadData();
              showSuccess(t('EditTeamDialog.NotificationEditTeam'));
            }
            setloading(false);
          }}
        >
          {t('EditTeamDialog.Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default EditTeamDialog;
