import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteComponent } from '../../../Components';
import { ClassificationsContactEnum } from '../../../Enums';
import { GetLookupItemsByLookupTypeName, GetContacts } from '../../../Services';
import { MoreFiltersPrimaryUnitsDialog } from './PrimaryUnitsComponents/MoreFiltersPrimaryUnitsDialog.jsx';
import { CustomButton } from '../ResaleUnitsView/ResaleUnitsFilters/utilities.js';
import { BedsAndBathFilter } from '../ResaleUnitsView/ResaleUnitsFilters/BedsAndBathFilter.jsx';

export const PrimaryUnitsFilterComponent = ({
  getUnitsData,
  isClearFiltersClicked,
  setFilterCriteria,
  filterCriteria,
  setDateFilter,
  dateFilter,
  setData,
  data,
  setSelected,
  selected,
  dateRangeDefault,
  filterCriteriaChangeHandler,
  restBedAndBath,
  filterBadsAndBaths,
  RangeSliderFilter,
  detailsUnitsList,
}) => {
  const parentTranslationPath = 'UnitsView';
  const translationPath = '';

  const { t } = useTranslation(parentTranslationPath);

  const [openFiltersDialog, setOpenFiltersDialog] = useState(false);

  const searchTimer = useRef(null);

  const getUnitTypeLookups = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'UnitType',
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'unitTypes', value: res.result || [] });
    } else setData({ id: 'unitTypes', value: [] });
  };

  const getPropertyPlanLookups = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'Property Plan',
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'propertyPlans', value: res.result || [] });
    } else setData({ id: 'propertyPlans', value: [] });
  };
  const getDeveloperOptions = async (searchValue) => {
    const res = await GetContacts({
      pageIndex: 0,
      pageSize: 10,
      search: searchValue || '',
      classificationId: ClassificationsContactEnum[0].Id,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'developers', value: res?.result || [] });
    else setData({ id: 'developers', value: [] });
  };

  const handleClick = () => {
    setOpenFiltersDialog(true);
  };

  useEffect(() => {
    if (isClearFiltersClicked) {
      setFilterCriteria({});
      setSelected({ id: 'edit', value: {} });
      setDateFilter(dateRangeDefault);
    }
  }, [isClearFiltersClicked]);

  useEffect(() => {
    getUnitsData(filterCriteria, dateFilter);
  }, [filterCriteria, dateFilter]);

  return (
    <div className='more-filter-wrapper'>
      <AutocompleteComponent
        idRef='developerRef'
        inputPlaceholder={t(`${translationPath}developer`)}
        labelValue={t(`${translationPath}developer`)}
        selectedValues={selected.developer || null}
        wrapperClasses='w-min-unset m-2 mr-1'
        data={data.developers || []}
        displayLabel={(option) =>
          option.contact.company_name ||
          (option.contact &&
            (option.contact.first_name || option.contact.last_name) &&
            `${option.contact.first_name} ${option.contact.last_name}`) ||
          ''
        }
        multiple={false}
        withoutSearchButton
        onChange={(_, newValue) => {
          setSelected({
            id: 'developer',
            value: {
              contactsId: newValue?.contactsId,
              contact: {
                first_name: newValue?.contact && newValue?.contact.first_name,
                last_name: newValue?.contact && newValue?.contact.last_name,
                company_name:
                  newValue?.contact && newValue?.contact.company_name,
              },
            },
          });

          const developerKey = 'DeveloperName';
          const developerValue =
            newValue?.contact?.company_name ||
            (newValue?.contact &&
              (newValue?.contact?.first_name || newValue?.contact?.last_name) &&
              `${newValue?.contact?.first_name} ${newValue?.contact?.last_name}`) ||
            '';
          const searchType = 2;

          filterCriteriaChangeHandler(developerKey, developerValue, searchType);
        }}
        onOpen={() => {
          if (data.developers && data.developers.length == 0)
            getDeveloperOptions();
        }}
        onInputKeyUp={(e) => {
          const { value } = e.target;
          if (searchTimer.current) clearTimeout(searchTimer.current);
          searchTimer.current = setTimeout(() => {
            getDeveloperOptions(value);
          }, 600);
        }}
      />
      <AutocompleteComponent
        idRef='propertyPlanRef'
        inputPlaceholder={t(`${translationPath}property-Plan`)}
        labelValue={t(`${translationPath}property-Plan`)}
        selectedValues={selected.propertyPlan || null}
        wrapperClasses='w-min-unset m-2 mr-1'
        data={data.propertyPlans || []}
        displayLabel={(option) => option.lookupItemName || ''}
        multiple={false}
        withoutSearchButton
        onChange={(_, newValue) => {
          setSelected({ id: 'propertyPlan', value: newValue });
          const propertyPlanValue = newValue?.lookupItemId || null;
          const propertyPlanKey = 'PropertyPlanId';
          const searchType = 1;

          filterCriteriaChangeHandler(
            propertyPlanKey,
            propertyPlanValue,
            searchType
          );
        }}
        onOpen={() => {
          if (data.propertyPlans && data.propertyPlans.length == 0)
            getPropertyPlanLookups();
        }}
      />
      <AutocompleteComponent
        idRef='unitTypeRef'
        wrapperClasses='w-min-unset m-2 mr-1'
        inputPlaceholder={t(`${translationPath}unit-Type`)}
        labelValue={t(`${translationPath}unit-Type`)}
        selectedValues={selected.unitType || null}
        data={data.unitTypes || []}
        displayLabel={(option) => option.lookupItemName || ''}
        multiple={false}
        withoutSearchButton
        onChange={(_, newValue) => {
          setSelected({ id: 'unitType', value: newValue });
          const unitTypeKey = 'unit_type';
          const unitTypeValue = newValue?.lookupItemName || null;
          const searchType = 1;

          filterCriteriaChangeHandler(unitTypeKey, unitTypeValue, searchType);
        }}
        onOpen={() => {
          if (data.unitTypes && data.unitTypes.length == 0)
            getUnitTypeLookups();
        }}
      />

      <div className='buttons-wrapper'>
        <BedsAndBathFilter
          restBedAndBath={restBedAndBath}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          selected={selected}
          setSelected={setSelected}
          filterBadsAndBaths={filterBadsAndBaths}
        />
        <CustomButton className='custom-filter-button' onClick={handleClick}>
          <span className='mdi mdi-filter-outline' />
          <span className='more-filters'>
            {t(`${translationPath}more-filters`)}
          </span>
        </CustomButton>
      </div>
      {openFiltersDialog && (
        <MoreFiltersPrimaryUnitsDialog
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          selected={selected}
          setSelected={setSelected}
          data={data}
          setData={setData}
          filterCriteriaChangeHandler={filterCriteriaChangeHandler}
          RangeSliderFilter={RangeSliderFilter}
          isOpen={openFiltersDialog}
          detailsUnitsList={detailsUnitsList}
          setFilterCriteria={setFilterCriteria}
          setDateFilter={setDateFilter}
          dateRangeDefault={dateRangeDefault}
          getUnitsData={getUnitsData}
          isClose={() => setOpenFiltersDialog(false)}
        />
      )}
    </div>
  );
};
