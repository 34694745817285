import {
  DescriptionsView,
  // WebPortal,
  PublishedImages
} from '../Sections';
import { WebPortal } from '../../../../../UnitsSalesView/UnitsSalesProfileManagement/Sections/Marketing/Sections/WebPortal';
import { LeadOwnerUnitLeasePermissions } from '../../../../../../../Permissions' ; 

export const UnitProfileMarketingTabsData = [
  { label: 'descriptions', component: DescriptionsView },
  {
    label: 'web-portal',
    component: WebPortal  , 
    permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
    permissionsId: LeadOwnerUnitLeasePermissions.ShowWebPortals.permissionsId,

  },
  { label: 'published-images', component: PublishedImages },
];
