
import React, { useState, useCallback, useReducer, useEffect } from 'react';
import { DialogActions, DialogContent, DialogTitle, Dialog, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next'; 
import Joi from 'joi';
import { showError } from '../../../../../../../../../../../../../Helper' ;
import { Spinner  } from '../../../../../../../../../../../../../Components' ;
import { AddressPropertyFinderContent } from './AddressPropertyFinderContent' ; 
import '../PropertyFinderDialog/PropertyFinderStyle.scss' ; 



export const AddressPropertyFinderDialog = ({
  parentTranslationPath,
  translationPath,
  isOpenDialog,
  onClose,
  onSave,
  propertyFinderDetails , 
  setPropertyFinderDetails
}) => {
  const { t } = useTranslation(parentTranslationPath);


  const [isSubmitted, setIsSubmitted] = useState(false);
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [state, setState] = useReducer(reducer, {
    city : null , 
    community  : null , 
    subCommunity: null ,
    propertyName : null , 
    paymentTypes : null ,  

  });

  const [selected, setSelected] = useReducer(reducer, {
    city : null , 
    community  : null , 
    subCommunity : null ,
    propertyName : null ,  
  });


  const schema = Joi.object({
    city : Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}city-is-required`),
        'string.empty': t`${translationPath}city-is-required`,
      }),
      community: Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}community-is-required`),
        'string.empty': t`${translationPath}community-is-required`,
      }),  
      paymentTypes :Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}paymentMethod-is-required`),
        'number.empty': t`${translationPath}paymentMethod-is-required`,
      }),  
    })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(propertyFinderDetails);

  const saveHandler = async (event) => {
    event.preventDefault();
    setIsSubmitted(true); 
    if(schema.error)
    {
      showError(t('please-fill-all-Required-field'));
      return ; 

    }
  onSave() ; 
 };
 
  return (
    <div>
      <Dialog
        disableBackdropClick
        maxWidth='sm'
        open={isOpenDialog}
        onClose={onClose}
        className='propertyFinder-dialog-wrapper'
        >
        <form noValidate onSubmit={saveHandler}>
          <DialogTitle id='alert-dialog-slide-title'>
            {t(
              `${translationPath}${'add-property-finder-details'}`
            )}
          </DialogTitle>

          <DialogContent>
            <AddressPropertyFinderContent
              translationPath={translationPath}
              parentTranslationPath ={parentTranslationPath}
              state={state}
              setState={setState}
              schema={schema}
              isSubmitted={isSubmitted}
              selected={selected}
              setSelected={setSelected}
              propertyFinderDetails ={propertyFinderDetails}
              setPropertyFinderDetails={setPropertyFinderDetails}
            />

          </DialogContent>
          <DialogActions>
            <ButtonBase onClick={onClose} className='btns theme-solid bg-cancel'>
              {t(`${translationPath}cancel`)}
            </ButtonBase>
            <ButtonBase className='btns theme-solid' onClick={saveHandler} type='submit'>
              {t(`${translationPath}save`)}
            </ButtonBase>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
