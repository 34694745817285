
export const LeadBathroomsEnum = [
    {
        key: 1,
        value: 'Any',
    },
    {
        key: 2,
        value: '1',
    },
    {
        key: 3,
        value: '2',
    },
    {
        key: 4,
        value: '3',
    },
    {
        key: 5,
        value: '4',
    },
    {
        key: 6,
        value: '5',
    },
    {
        key: 7,
        value: '6',
    },
    {
        key: 8,
        value: '7',
    },
    {
        key: 9,
        value: '8',
    },
]
