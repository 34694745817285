import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DialogComponent, AutocompleteComponent, Spinner } from '../../../../../../../Components';
// import { ButtonBase } from '@material-ui/core';
import { DeleteAssociatedContact } from '../../../../../../../Services';
import { showError, showSuccess, GetParams } from '../../../../../../../Helper';

export const DeleteAssociatedDialog = ({
    openDeleteDialog,
    t,
    translationPath,
    parentTranslationPath,
    setOpenDeleteDialog,
    activeItem,
    reloadData
}) => {
    const [loading, setLoading] = useState(false);

    const deleteHandler = useCallback(async () => {
        setLoading(true);
        let res = await DeleteAssociatedContact(activeItem && activeItem.associatedContactId);
        if (!(res && res.status && res.status !== 200)) showSuccess(t(`${translationPath}delete-associated-successfully`));
        else showError(t(`${translationPath}delete-associated-failed`));
        reloadData();
        setLoading(false);
        setOpenDeleteDialog(false);
    });

    return (
        <>
            <Spinner isActive={loading} />
            <div className='add-view-wrapper d-flex'>
                <DialogComponent
                    titleText='confirm-message'
                    saveText='confirm'
                    saveType='button'
                    maxWidth='sm'
                    dialogContent={(
                        <div className='d-flex-column-center'>
                            <span className='mdi mdi-close-octagon c-danger mdi-48px' />
                            <span>{`${t(`${translationPath}associated-delete-description`)}`}</span>
                        </div>
                    )}
                    saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
                    isOpen={openDeleteDialog}
                    onSaveClicked={deleteHandler}
                    onCancelClicked={() => setOpenDeleteDialog(false)}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                />
            </div>
        </>
    )
}
DeleteAssociatedDialog.prototype = {
    parentTranslationPath: PropTypes.string.isRequired,
    translationPath: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    setOpenDeleteDialog: PropTypes.func.isRequired,
    openDeleteDialog: PropTypes.bool.isRequired,
};
