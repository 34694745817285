
import React  from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Spinner  , ActionsButtonsComponent , ViewTypes  , AutocompleteComponent , Inputs , SelectComponet      } from '../../../../Components' ; 
import { DateRangePickerComponent } from '../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';




  export const DevelopersFiltersComponent = ({translationPath , parentTranslationPath , dateFilter  , setDateFilter , setIsDatePickerChanged ,isLoading ,dateRangeDefault  ,setIsAdvanceSearchActive }) => { 

    const { t } = useTranslation(parentTranslationPath);


    return (
      <div className='d-flex-column px-2 w-100 p-relative'>
      <div className='mb-1'>
        {/* <AutocompleteComponent
          data={
            // searchableFormFields &&
            // searchableFormFields.map((item) => ({
            //   key: item.key,
            //   title: item.title,
            // }))
            []
          }
          wrapperClasses='autocomplete-with-btn'
          selectedValues={searchData}
          parentTranslationPath='ContactsView'
          // displayLabel={displayedLabel}
          // disabledOptions={disabledOptions}
          // onChange={filterOnChange}
          // searchClicked={searchClicked}
          // chipsLabel={chipsLabel}
          // getOptionSelected={(option) =>
          //   searchData.findIndex(
          //     (item) =>
          //       item.key === option.key &&
          //       item.value === searchInputValue
          //   ) !== -1
          // }
          tagValues={searchData}
       //   inputValue={searchInputValue}
        //  onInputChange={inputValueChanged}
          inputLabel='filter'
          inputPlaceholder='search-contacts'
        /> */}
      </div>
      <div className='d-flex-v-center-h-between pl-5-reversed'>
        <div className='mr-1-reversed'>
          {/* <SelectComponet
            data={Object.values(FormsIdsEnum).filter(
              (item) => item.page === 'contacts'
            )}
            emptyItem={{
              value: 0,
              text: 'select-type',
              isDisabled: false,
            }}
            value={activeFormType}
            valueInput='id'
            textInput='name'
            onSelectChanged={changeActiveFormType}
            wrapperClasses='w-auto'
            themeClass='theme-default'
            idRef='Contact_Type'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            translationPathForData={translationPath}
          /> */}
        </div>
        <div className='w-100 mt-2'>
        </div>
        <div className='w-100 customDatePicker'>
          <div className='date-type-select'>
            <SelectComponet
              data={[
                { key: 1, value: `${translationPath}created-date` },
                {
                  key: 2,
                  value: `${translationPath}update-on-date`,
                },
              ]}
              defaultValue={{
                key: 1,
                value: `${translationPath}created-date`,
              }}
              value={dateFilter.selectedDateType || 1}
              valueInput='key'
              textInput='value'
              isDisabled={isLoading}
              onSelectChanged={(value) => {
                setIsDatePickerChanged(true);
                setDateFilter((f) => ({
                  ...f,
                  selectedDateType: value,
                }));
              }}
              wrapperClasses='w-auto'
              themeClass='theme-transparent'
              idRef='Date_Select'
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              translationPathForData={translationPath}
            />
          </div>
          <DateRangePickerComponent
            onClearClicked={() => {
              setDateFilter(dateRangeDefault);
              setIsDatePickerChanged(true);
            }}
            onDialogClose={() => setIsDatePickerChanged(true)}
            ranges={[dateFilter]}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onDateChanged={(selectedDate) => {
              setDateFilter((item) => ({
                ...item,
                startDate: selectedDate?.selection?.startDate,
                endDate: new Date(
                  moment(selectedDate?.selection?.endDate).endOf(
                    'day'
                  )
                ),
                key: 'selection',
              }));
            }}
          />
        </div>
      </div>
    </div>
    );
  } ; 
  