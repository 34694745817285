export const UnitLocationEnum = [
  {
    key: 1,
    value: 'single-row',
  },
  {
    key: 2,
    value: 'double-row',
  },
  {
    key: 3,
    value: 'low-floor',
  },
  {
    key: 4,
    value: 'high-floor',
  },
  {
    key: 5,
    value: 'corner',
  },
  {
    key: 6,
    value: 'mid-floor',
  },
];
