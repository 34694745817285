import React, { useEffect, useState , useRef  } from "react";
import { useTranslation } from "react-i18next";
import xCloseIcon from "../../../../../assets/images/icons/x-close-dialog.svg";
import { ReactComponent as XCloseIcon } from "../../../../../assets/images/icons/x-close-dialog.svg";
import { Slider } from "@material-ui/core";
import { DialogActions, DialogContent, ButtonBase } from "@material-ui/core";
import { showError, showSuccess, GlobalTranslate } from "../../../../../Helper";
import { Inputs, DialogComponent } from "../../../../../Components";

export const Radios = ({
  parentTranslationPath,
  translationPath,
  isDialogOpen,
  onSave,
  onClose,
  state,
  setState,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  return (
    <div className="">
      <DialogComponent
        isOpen={isDialogOpen}
        maxWidth="sm"
        dialogTitle={
          <div className="w-100">
            <div className="d-flex-v-center-h-between">
              <div className="fw-simi-bold">{t(`${translationPath}add-radios`)}</div>
              <div className="xCloseIcon pointer" onClick={onClose}>
                <XCloseIcon />
              </div>
            </div>
          </div>
        }
        dialogContent={
          <div className="">
            <DialogContent>
              <>
                <div className="d-flex-column-center">
                  <div className="w-100  mt-2 mb-1 pt-1 px-4 ">
                    <Slider
                      style={{ color: "#7f6944" }}
                      className="sliders"
                      aria-labelledby="range-slider"
                      valueLabelDisplay="auto"
                      value={[
                        (state.propertyRatingKeyNearbyRadiosDto &&
                          state.propertyRatingKeyNearbyRadiosDto.from) ||
                          0,
                        (state.propertyRatingKeyNearbyRadiosDto &&
                          state.propertyRatingKeyNearbyRadiosDto.to) ||
                          0,
                      ]}
                      onChange={(event, value) => {
                        const updateRadios = {
                          ...state.propertyRatingKeyNearbyRadiosDto,
                        };
                        updateRadios.from = value[0];
                        updateRadios.to = value[1];

                        setState({
                          id: "propertyRatingKeyNearbyRadiosDto",
                          value: updateRadios,
                        });
                      }}
                      step={1}
                      marks={[
                        {
                          value: 1,
                          label: "1 KM",
                        },
                        {
                          value: 50,
                          label: "50 KM",
                        },
                      ]}
                      min={1}
                      max={50}
                    />
                  </div>
                  <div className="w-100  mt-2 mb-1 pt-1">
                    <div className="d-flex  px-2">
                      <div className="w-50 px-2">
                        <Inputs
                          className="px-4"
                          idRef={"from"}
                          labelValue={"min"}
                          value={(state.propertyRatingKeyNearbyRadiosDto.from)}
                          type="number"
                          withNumberFormat
                          onKeyUp={(event) => {
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            const newValue = event.target.value;
                            searchTimer.current = setTimeout(() => 
                              {
                                if(newValue > 50 || newValue < 0)
                                  {
                                      showError(t(`${translationPath}please-enter-number-between-1km-to-50-km`));
                                      return ;
                                    }  
                                    const updateRadios = {
                                      ...state.propertyRatingKeyNearbyRadiosDto,
                                      from:   parseInt(newValue) || 1
                                    };
        
                                    setState({
                                      id: "propertyRatingKeyNearbyRadiosDto",
                                      value: updateRadios,
                                    });
                             
                            }, 300);
                          }}
                          min={1}
                          max={50}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                        />
                      </div>
                      <div className="w-50 px-2">
                        <Inputs
                          idRef={"to"}
                          className="px-4"
                          labelValue={"max"}
                          type="number"
                          min={1}
                          max={50}
                          value={(state.propertyRatingKeyNearbyRadiosDto.to)}
                          withNumberFormat
                          onKeyUp={(event) => {
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            const newValue = event.target.value;
                            searchTimer.current = setTimeout(() => 
                              {
                                if(newValue > 50 || newValue < 0)
                                  {
                                      showError(t(`${translationPath}please-enter-number-between-1km-to-50-km`));
                                      return ;
                                  }  
                                    const updateRadios = {
                                      ...state.propertyRatingKeyNearbyRadiosDto,
                                      to:   parseInt(newValue) || 50 
                                    };
        
                                    setState({
                                      id: "propertyRatingKeyNearbyRadiosDto",
                                      value: updateRadios,
                                    });
                             
                            }, 300);
                          }}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </DialogContent>
            <DialogActions>
              <div className="d-flex-center fj-end py-0 pt-3">
                <ButtonBase
                  className="btns theme-propx solid mr-0"
                  onClick={() => onClose()}
                >
                  {t(`${translationPath}save`)}
                </ButtonBase>
              </div>
            </DialogActions>
          </div>
        }
      />
    </div>
  );
};
