import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { GetLostInquiry } from "../../../Services";
import { Inputs, SelectComponet, Spinner, Tables } from "../../../Components";
import { DateRangePickerComponent } from "../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../../Hooks";
import { TableActions, TableFilterTypesEnum } from "../../../Enums";
import { LostInquiryAgentDialog } from "./LostInquiryAgentDialog";
import moment from "moment";

export const LostInquiryLogView = () => {
  const parentTranslationPath = "LostInquiryLogView";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t(`${translationPath}lost-inquiry-log-view`));

  const searchTimer = useRef(null);
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: "selection",
  };
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);

  const [lostInquiryData, setLostInquiryData] = useState({
    result: [],
    totalCount: 0,
  });
  const [openAgentDialog, setOpenAgentDialog] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [tableActions, setTableActions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [DatePickerType, setDatePickerType] = useState(1);

  const [state, setState] = useState({
    rotationName: null,
    agentName: null,
  });

  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
    rotationName: null,
    agentName: null,
    endAssignedDate: null,
    startAssignedDate: null,
    creationToDate: null,
    endCreatedDate: null,
    inquiryId: null,
  });

  const focusedRowChanged = (rowIndex, item) => {
    const localTableActions = [];
    if (item) localTableActions.push({ enum: TableActions.viewAgents.key });

    setTableActions(localTableActions);
  };

  const getLostInquiry = useCallback(async () => {
    setIsLoading(true);
    const res = await GetLostInquiry({
      ...filter,
      pageIndex: filter.pageIndex + 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setLostInquiryData({
        result: res?.result || [],
        totalCount: res?.totalCount || 0,
      });
    } else {
      setLostInquiryData({ result: [], totalCount: 0 });
    }
    setIsLoading(false);
  }, [filter]);

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      if (actionEnum === TableActions.viewAgents.key) {
        setOpenAgentDialog(true);
        setActiveItem(item);
      }
    },
    []
  );

  const headerData = [
    {
      id: 1,
      label: "inquiry-id",
      input: "inquiryId",
      filterType: TableFilterTypesEnum.textInput.key,
    },
    {
      id: 2,
      isSortable: true,
      label: "assigned-date",
      input: "assignedDate",
      isDate: true,
      dateFormat: "DD/MM/YYYY",
    },
    {
      id: 3,
      isSortable: true,
      label: "lost-date",
      input: "lostDate",
      isDate: true,
      dateFormat: "DD/MM/YYYY",
    },
    {
      id: 4,
      isSortable: true,
      label: "number-of-assigned-agents",
      input: "numberAssignedAgent",
    },
    {
      id: 5,
      isSortable: true,
      label: "number-of-lost-by-agent",
      input: "numberLostByAgent",
    },
    {
      id: 6,
      isSortable: true,
      label: "last-status",
      input: "lastStatusName",
    },
  ];

  const onFilterValuesChanged = (e) => {
    if (e && !!Object.values(e).length) {
      const { value, searchableKey } = Object.values(e)[0];

      setFilter((filter) => {
        if (value === "" || value === null || value === undefined) {
          const { [searchableKey]: removed, ...rest } = filter;
          return rest;
        } else {
          return {
            ...filter,
            [searchableKey]: +value,
          };
        }
      });
    }
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({
      ...item,
      pageIndex: 0,
      pageSize,
    }));
  };

  useEffect(() => {
    getLostInquiry();
  }, [filter, getLostInquiry]);

  useMemo(() => {
    if (DatePickerType === 1) {
      setFilter((item) => ({
        ...item,
        startCreatedDate: dateFilter.startDate,
        endCreatedDate: dateFilter.endDate,
        startAssignedDate: null,
        endAssignedDate: null,
      }));
    } else if (DatePickerType === 2) {
      setFilter((item) => ({
        ...item,
        startAssignedDate: dateFilter.startDate,
        endAssignedDate: dateFilter.endDate,
        startCreatedDate: null,
        endCreatedDate: null,
      }));
    }
  }, [dateFilter]);
  return (
    <div className="view-wrapper">
      <Spinner isActive={isLoading} />
      <div className="flex-v-end d-flex-column mx-5 mb-5">
        <div className="d-flex w-50">
          <div className="w-50 mr-1-reversed">
            <Inputs
              inputPlaceholder={"byAgentName"}
              idRef="agentNameIdRef"
              fieldClasses="inputs theme-solid"
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              value={state.agentName}
              onInputChanged={(event) => {
                const { value } = event.target;
                setState({
                  ...state,
                  agentName: value || null,
                });
              }}
              onKeyUp={(event) => {
                if (searchTimer.current) clearTimeout(searchTimer.current);
                const newValue = event.target.value;
                searchTimer.current = setTimeout(() => {
                  setFilter({
                    ...filter,
                    agentName: newValue || null,
                  });
                }, 700);
              }}
            />
          </div>
          <div className="w-50 mr-2">
            <Inputs
              inputPlaceholder={"byRotationName"}
              idRef="rotationNameIdRef"
              fieldClasses="inputs theme-solid"
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              value={state.rotationName}
              onInputChanged={(event) => {
                const { value } = event.target;
                setState({
                  ...state,
                  rotationName: value || null,
                });
              }}
              onKeyUp={(event) => {
                if (searchTimer.current) clearTimeout(searchTimer.current);
                const newValue = event.target.value;
                searchTimer.current = setTimeout(() => {
                  setFilter({
                    ...filter,
                    rotationName: newValue || null,
                  });
                }, 700);
              }}
            />
          </div>
        </div>
        <div className="d-flex w-50">
          <div className="w-50 mr-1-reversed">
            <SelectComponet
              data={[
                { key: 1, value: `${translationPath}created-date` },
                {
                  key: 2,
                  value: `${translationPath}assigned-date`,
                },
              ]}
              defaultValue={{
                key: 1,
                value: `${translationPath}created-date`,
              }}
              value={DatePickerType || 1}
              valueInput="key"
              textInput="value"
              isDisabled={isLoading}
              onSelectChanged={(value) => {
                setDatePickerType(value);
              }}
              idRef="Date_Select"
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              translationPathForData={translationPath}
            />
          </div>
          <div className="w-100 filter-section-date-picker">
            <DateRangePickerComponent
              onClearClicked={() => {
                setDateFilter(dateRangeDefault);
              }}
              ranges={[dateFilter]}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onDateChanged={(selectedDate) =>
                setDateFilter((item) => ({
                  ...item,
                  startDate:
                    selectedDate.selection && selectedDate.selection.startDate,
                  endDate: new Date(
                    moment(
                      selectedDate.selection && selectedDate.selection.endDate
                    ).endOf("day")
                  ),
                  key: "selection",
                }))
              }
              isDisabled={isLoading}
            />
          </div>
        </div>
      </div>
      <div className="d-flex-column">
        <div className="w-100 px-3">
          <Tables
            data={lostInquiryData?.result || []}
            headerData={headerData || []}
            defaultActions={tableActions || []}
            focusedRowChanged={focusedRowChanged}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            totalItems={lostInquiryData.totalCount}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            isWithFilter
            FilterDisabledButton
            filterData={headerData.map((item) => ({
              key: item.key,
              id: item.id,
              filterType: item.filterType,
              searchableKey: item.searchableKey ?? item.input,
              isHiddenFilter: item.isHiddenFilter,
              optionFilterList: item.optionFilterList,
              filterBy: item.filterBy,
            }))}
            onFilterValuesChanged={onFilterValuesChanged}
          />
        </div>
      </div>
      {openAgentDialog && (
        <LostInquiryAgentDialog
          open={openAgentDialog}
          logId={activeItem?.inquiryId}
          close={() => {
            setOpenAgentDialog(false);
            setActiveItem(null);
          }}
          inquiryStatusId={activeItem.inquiryStatusId}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
};
