import React, { useState, useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { PageHeading } from "../../../../../SharedComponents";
import { Spinner } from "../../../../../Components";
import FilterIcon from "../../../../../assets/images/temps/Filter.svg";
import {
  showError,
  showSuccess,
  bottomBoxComponentUpdate,
} from "../../../../../Helper";
import { GetAllKeysNearby, UpdateKeysNearby } from "../../../../../Services";
import { KeysNearbyScore, KeyNearbyNumber, Radios } from "../KeyNearby";

export const KeyNearbyView = ({
  parentTranslationPath,
  translationPath,
  ratingPercentage,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenRadiosDialog, setIsOpenRadiosDialog] = useState(false);
  const [keyNearbyViewRatingPercentage, setKeyNearbyViewRatingPercentage] =
    useState(0);

  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [state, setState] = useReducer(reducer, {
    listOfPropertyRatingKeysNearbiesDto: [],
    listOfPropertyRatingKeysNearbyScoreDto: [],
    propertyRatingKeyNearbyRadiosDto: null,
  });

  const GetAllKeysNearbyAPI = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllKeysNearby();
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      setState({ id: "edit", value: res });
    } else setState({ id: "edit", value: null });

    setIsLoading(false);
  });

  const checkIfListOfPropertyRatingKeysNearbiesHaveZeroRanges = () => {
    let listOfPropertyRatingKeysNearbiesDto = [
      ...state.listOfPropertyRatingKeysNearbiesDto,
    ];

    listOfPropertyRatingKeysNearbiesDto.forEach((landmarkItem, index) => {
      let updateLandmarkItem = { ...landmarkItem };

      landmarkItem.propertyRatingKeyNearbyRangesDto.forEach(
        (rangeItem, index2) => {

          if (rangeItem.form === 0 && rangeItem.to === 0) {
            updateLandmarkItem = {
              ...updateLandmarkItem,
              propertyRatingKeyNearbyRangesDto:
                updateLandmarkItem.propertyRatingKeyNearbyRangesDto.filter(
                  (c) => c.from !== 0
                ),
            };
          } else if (rangeItem.from === 0) {
            updateLandmarkItem = {
              ...updateLandmarkItem,
              propertyRatingKeyNearbyRangesDto: [
                ...updateLandmarkItem.propertyRatingKeyNearbyRangesDto.filter(
                  (c) => c.from !== 0
                ),
              ],
            };
          } else if (rangeItem.to === 0) {
            updateLandmarkItem = {
              ...updateLandmarkItem,
              propertyRatingKeyNearbyRangesDto: [
                ...updateLandmarkItem.propertyRatingKeyNearbyRangesDto.filter(
                  (c) => c.to !== 0
                ),
              ],
            };
          } else {
            updateLandmarkItem = {
              ...updateLandmarkItem,
              propertyRatingKeyNearbyRangesDto:
                landmarkItem.propertyRatingKeyNearbyRangesDto,
            };
          }
          listOfPropertyRatingKeysNearbiesDto[index] = {
            ...updateLandmarkItem,
          };
        }
      );
    });
    return listOfPropertyRatingKeysNearbiesDto;
  };

  const reloadData = () => {
    GetAllKeysNearbyAPI();
  };

  const saveHandler = async () => {
    setIsLoading(true);
    const listOfPropertyRatingKeysNearbiesDto =
      checkIfListOfPropertyRatingKeysNearbiesHaveZeroRanges();
    const res = await UpdateKeysNearby({
      ...state,
      listOfPropertyRatingKeysNearbiesDto,
    });
    if (!((res && res.StatusCode) || !res)) {
      showSuccess(t`${translationPath}keys-nearby-updated-successfully`);
      reloadData();
    } else showError(t`${translationPath}keys-nearby-updated-failed`);

    setIsLoading(false);
  };

  useEffect(() => {
    reloadData();
    if (ratingPercentage) {
      const rating = ratingPercentage.find(
        (f) => f.propertyRatingTabName === "Key nearby"
      );
      setKeyNearbyViewRatingPercentage((rating && rating.score) || 0);
    }
  }, [ratingPercentage]);

  useEffect(() => {
    bottomBoxComponentUpdate(null);
  }, []);

  return (
    <div className="w-100 mt-4 key-nearby-view">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="d-flex-v-center-h-between">
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          headerTitle={"key-nearby"}
          subTitle={
            "anage-your-team-members-and-their-account-permissions-here"
          }
          wrapperClasses={"px-4 pt-2 w-75"}
          headerTitleClasses={"fz-30"}
          subTitleClasses={"fz-12 m-0"}
          titleWithPercentage={`${keyNearbyViewRatingPercentage}%`}
        />

        <div className="w-25 px-4 d-flex-center fj-end">
          <Button
            className="btns theme-propx outlined"
            id="btn-cancel"
            onClick={() => {
              reloadData();
            }}
          >
            {t(`${translationPath}cancel`)}
          </Button>
          <Button
            className="btns theme-propx outlined"
            id="btn-radios"
            onClick={() => {
              setIsOpenRadiosDialog(true);
            }}
          >
            <img
              style={{ maxHeight: "14px", width: "16px", margin: "0 .2rem" }}
              className=""
              alt="dots"
              src={FilterIcon}
            />

            {t(`${translationPath}radios`)}
          </Button>
          <Button
            className="btns theme-propx solid"
            id="btn-save"
            onClick={() => saveHandler()}
          >
            {t(`${translationPath}save-changes`)}
          </Button>
        </div>
      </div>
      <div className="property-rating-container key-nearby-view">
        <KeysNearbyScore
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          state={state}
          setState={setState}
        />
        <hr className="hr-line" />
        <KeyNearbyNumber
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          state={state}
          setState={setState}
        />
      </div>
      {isOpenRadiosDialog && (
        <Radios
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isDialogOpen={isOpenRadiosDialog}
          state={state}
          setState={setState}
          onClose={() => setIsOpenRadiosDialog(false)}
        />
      )}
    </div>
  );
};
