import React, { useReducer } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { StepperComponent, UploaderComponent } from '../../../../../../Components';
import { DocumentsUploader } from '../../../../../../SharedComponents/DocumentsUploader/DocumentsUploader';

const parentTranslationPath = 'FormBuilder';
const translationPath = 'dialogs.uploadDialog.';
const translationPathShared = 'uploaderComponent.';
export const DocumentUploadDialog = ({
  initialState,
  open,
  closeDialog,
  onChange,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
const reducer = (state, action) => ({ ...state, [action.id]: action.value });
  const [state, setState] = useReducer(reducer, initialState);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed] = React.useState({});
  const [isUploading, setIsUploading] = React.useState(false);
  const totalSteps = () => initialState.selected.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted() ?
        initialState.selected.findIndex((step, i) => !(i in completed)) :
        activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  return (
    <Dialog className='dialog' open={open} fullWidth maxWidth='md'>
      <DialogTitle>{t(`${translationPath}upload-documents`)}</DialogTitle>
      <DialogContent>
        <>
          <div className='w-100 mb-2'>
            <StepperComponent
              steps={initialState.selected}
              labelInput='lookupItemName'
              onStepperClick={(index) => {
                handleStep(index);
              }}
              activeStep={activeStep}
            />
          </div>
          <div>
            {initialState.selected.map((item) =>
              initialState.selected[activeStep].lookupItemName ===
              item.lookupItemName && (
                <DocumentsUploader
                  initUploaded={state[item.lookupItemName] || []}
                  uploadedChanged={(files) => {
                    setState({ id: item.lookupItemName, value: files });
                  }}
                  docTypes={{
 images: 0, videos: 1, docs: 2, links: 3
}}
                  isUploading={isUploading}
                  setIsUploading={setIsUploading}
                  open={open}
                  translationPath={translationPath}
                  translationPathShared={translationPathShared}
                  parentTranslationPath={parentTranslationPath}
                />
              ))}
          </div>
        </>
      </DialogContent>
      <DialogActions>
        {activeStep !== 0 && (
          <Button onClick={() => handleBack()} className='btns theme-solid bg-warning'>
            <span className='mdi mdi-chevron-double-left' />
            {t('Shared:back')}
          </Button>
        )}
        {!isLastStep() && (
          <Button
            onClick={() => handleNext()}
            id={t('Shared:next')}
            className='btns theme-solid bg-secondary'
          >
            {t('Shared:next')}
            <span className='mdi mdi-chevron-double-right' />
          </Button>
        )}
        <Button
          className='btns theme-solid bg-cancel'
          onClick={() => {
            Object.keys(state).map((el) => el !== 'selected' && setState({ id: el, value: [] }));
            closeDialog();
          }}
        >
          {t('Shared:cancel')}
        </Button>
        {isLastStep() && (
          <Button
            className='btns theme-solid'
            onClick={(event) => {
              event.preventDefault();
              onChange(state);
              closeDialog();
            }}
            disabled={isUploading}
          >
            {t('Shared:save')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
