export const UnqualifiedLeadsCallCenterPermissions = {
  ViewUnqualifiedLeads: {
    permissionsId: '9decd63a-6d90-4374-f949-08dc0f48c1d2',
    permissionsName: 'View Unqualified Leads',
    description: null,
    componentsId: null,
    components: null,
  },
  SendToTheLeadPool: {
    permissionsId: 'fa1b10e8-3053-4f80-c4c4-08dc683b0c43',
    permissionsName: 'Send To The Leads Pool',
    description: null,
    componentsId: null,
    components: null,
  },
};
