import React from 'react';
import moment from 'moment';

export const ReleaseDetailsComponent = ({ activeItem }) => {

    return (
        <div className='release-details-wrapper'>
            {activeItem && (
                <>
                    <p className='version-title'>{`Version ${activeItem.versionNumber}, ${activeItem.productTypeDto?.productTypeName} Release`}</p>
                    <p className='x-large-text'>{`${moment(activeItem.releaseDate).format('ll')} - ${activeItem.productTypeDto?.productTypeName} version ${activeItem.versionNumber}`}</p>
                    <p className='description'>{activeItem.summary}</p>
                    <div className='alert-box'>
                        <p className='box-item-1'>{`${moment(activeItem.releaseDate).format('ll')}`}</p>
                        <p className='box-item-2'>{activeItem.versionName}</p>
                        <p className='box-item-3' dangerouslySetInnerHTML={{ __html: activeItem.description }}></p>
                    </div>
                </>
            )}
        </div>
    )
}
