import { ZeroMatchingInformationComponent } from './ZeroMatchingInformationComponent' ; 
import { AssignInquiryToUnitComponent  } from '../../../ListingShortage/ListingShortageUtilities/Sections' ; 
import { InquiryAssignmentTrack } from '../../../../../../Layouts';
import { ZeroMatchingSalePermissions } from '../../../../../../Permissions' ; 



export const ZeroMatchingtVerticalTabsData = [
    {
      label: 'Inquiries-information',
      component: ZeroMatchingInformationComponent,
      permissionsList: Object.values(ZeroMatchingSalePermissions),
      permissionsId: ZeroMatchingSalePermissions.ViewInquiry.permissionsId,
    },
    {
      label: 'Assigned-Units',
      component: AssignInquiryToUnitComponent ,
      permissionsList: Object.values(ZeroMatchingSalePermissions),
      permissionsId: ZeroMatchingSalePermissions.ViewInquiry.permissionsId,
      
    },
    {
      label: 'inquiry_assignment',
      component: InquiryAssignmentTrack,
      permissionsList: Object.values(ZeroMatchingSalePermissions),
      permissionsId: ZeroMatchingSalePermissions.ViewInquiryAssignmentTracker.permissionsId,
      
    }
  
  ];