
export const CEODashboardPermissions = 
{

  SalesDashboard : {
    permissionsId: "dd909373-fbef-4ad8-7f34-08dbbb5bf6df",
    permissionsName: "Sales Dashboard",
    description: null,
    componentsId: null,
    components: null
},
LeaseDashboard :{
    permissionsId: "6f357923-afb9-4958-7f35-08dbbb5bf6df",
    permissionsName: "Lease Dashboard",
    description: null,
    componentsId: null,
    components: null
}   
} ;
  