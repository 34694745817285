import React from "react";
import "./DocListHolder.scss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { MultipleTypesDocumentsUploader } from "../../../../../SharedComponents";
import { CheckboxesComponent } from "../../../../../Components";

export const DocListHolder = ({
  documents,
  onDocumentsChange,
  isDisabled,
  isWithoutCheckbox,
  headerText,
  haveToResetInitDOCs,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const checkIsSignedOffChecked = () => documents?.length > 0;

  return (
    <div className="sign-off-container gap-20">
      <div className="sign-off-header w-100">
        <p className="fz-18 fw-simi-bold m-0">
          {t(`${translationPath}${headerText}`)}
        </p>
        {!isWithoutCheckbox ? (
          <div className="sign-off-check">
            <CheckboxesComponent
              idRef="IsImportantRef"
              wrapperClasses="rounded"
              isDisabled
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              label="Signed-off"
              singleChecked={checkIsSignedOffChecked()}
              themeClass="theme-propx"
            />
          </div>
        ) : null}
      </div>
      <div className="w-100">
        <MultipleTypesDocumentsUploader
          idRef="importFileRef"
          initUploadedFiles={documents || []}
          labelValue=""
          isDisabled={isDisabled}
          uploadedChanged={(documents) => onDocumentsChange(documents)}
          multiple
          theme="propx"
          isOpenGallery
          viewUploadedFilesCount={false}
          openGallery
          isDocuments
          haveToResetInitDOCs={haveToResetInitDOCs}
          checkIsRemoveHidden={(file) => !file?.type && !file.isUploaded}
        />
      </div>
    </div>
  );
};

DocListHolder.propTypes = {
  documents: PropTypes.array,
  onDocumentsChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isWithoutCheckbox: PropTypes.bool,
  headerText: PropTypes.string,
  translationPath: PropTypes.string,
  parentTranslationPath: PropTypes.string,
};

DocListHolder.defaultProps = {
  documents: [],
  onDocumentsChange: () => { },
  isDisabled: false,
  isWithoutCheckbox: false,
  headerText: "",
  translationPath: "",
  parentTranslationPath: "",
};
