import { put, takeEvery } from 'redux-saga/effects';
import { GlobalAdvanceSearchActions } from './GlobalAdvanceSearchActions';
import { GlobalAdvanceSearchStatus } from './GlobalAdvanceSearchStatus';

function* fetchGlobalAdvanceSearch(action) {
  try {
    const results = action.payload;
    localStorage.setItem('GlobalAdvanceSearch', JSON.stringify(results));
    yield put(GlobalAdvanceSearchActions.globalAdvanceSearchSuccess(results));
  } catch (err) {
    yield put(GlobalAdvanceSearchActions.globalAdvanceSearchError(err));
  }
}
export function* watchGlobalAdvanceSearch() {
  yield takeEvery(GlobalAdvanceSearchStatus.REQUEST, fetchGlobalAdvanceSearch);
}
