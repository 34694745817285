import React, { useEffect } from 'react';
import { SwitchRoute } from '../../../Components/Route/SwitchRoute';
import { LeadsleaseRoutes } from '../../../routes';

export const LeadsLeaseLayout = () => {
    useEffect(() => {
        return () => {
            localStorage.removeItem("leadsLeaseCurrentPageIndex");
        }
    }, []);
    return (
        <SwitchRoute routes={LeadsleaseRoutes} />
    );
};
