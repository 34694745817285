import React from 'react';
import PropTypes from 'prop-types';

function UserLearningContainer({ children, title }) {
  return (
    <div className='learning-container'>
      <h3 className='learning-title'>{title}</h3>
      {children}
    </div>
  );
}

UserLearningContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
};

export default UserLearningContainer;
