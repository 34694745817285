import { config } from '../../config';
import { HttpServices } from '../../Helper';

export const GetUnitRequestLogs = async (body) => {
  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/UnitApproval/GetUnitRequestLogs?pageIndex=${
      body.pageIndex + 1
    }&pageSize=${body.pageSize}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
