import { config } from '../config';
import { HttpServices } from '../Helper';

export const SaveAutoCorrespondenceConfig = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/AutoCorrespondence/SaveAutoCorrespondenceConfig`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAutoCorrespondenceConfig = async () => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/AutoCorrespondence/GetAutoCorrespondenceConfig`,
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
