import React from 'react';
import { styled } from '@material-ui/core/styles';
import { ButtonBase, Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const CustomButton = styled(ButtonBase)({
  display: 'flex',
  width: '150px',
  height: '32px',
  padding: '10px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',
  borderRadius: '8px',
  border: '1px solid #D0D5DD',
  background: '#FFF',
  whiteSpace: 'nowrap',
  '& .more-filters': {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '15px',
    color: '#344054',
  },
});

export const StyledButton = withStyles({
  root: {
    display: 'flex',
    minWidth: '150px',
    height: '32px',
    padding: '10px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    background: '#FFF',
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
    '& .bed-bath-button': {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '15px',
      color: '#344054',
    },
  },
})(ButtonBase);

export const DropdownIconWrapper = styled('span')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#8e8e93',
  fontSize: '22px',
});

export const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export const StyledMenuItem = withStyles(() => ({
  root: {
    padding: '5px',
  },
}))(MenuItem);
