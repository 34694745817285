export const FinancialStatusDetails2 = (item, value, itemList, values, onValueChanged) => {
    if (item.field.id === 'has_auto_loan' ||
        item.field.id === 'has_personal_loan' ||
        item.field.id === 'has_home_loan' ||
        item.field.id === 'has_other_liabilities' ||
        item.field.id === 'has_credit_card'
    ) {
        const liabilityAmount = values.liability_amount || 0;
        if (item.field.id === 'has_auto_loan') {
            if (!value || value === 'No') {
                const autoLoanMonthlyInstallment = values.auto_loan_monthly_installment && values.auto_loan_monthly_installment !== ' ' ? values.auto_loan_monthly_installment : 0;
                const liabilityAmountSub = liabilityAmount - autoLoanMonthlyInstallment;
                onValueChanged(0, 0, 'auto_loan_outstanding_balance');
                onValueChanged(0, 0, 'auto_loan_monthly_installment');
                onValueChanged(liabilityAmountSub, 0, 'liability_amount');
            }
        } else if (item.field.id === 'has_personal_loan') {
            if (!value || value === 'No') {
                const personalLoanMonthlyInstallment = values.personal_loan_monthly_installment && values.personal_loan_monthly_installment !== ' ' ? values.personal_loan_monthly_installment : 0;
                const liabilityAmountSub = liabilityAmount - personalLoanMonthlyInstallment;
                onValueChanged(0, 0, 'personal_loan_outstanding_balance');
                onValueChanged(0, 0, 'personal_loan_monthly_installment');
                onValueChanged(liabilityAmountSub, 0, 'liability_amount');
            }
        } else if (item.field.id === 'has_home_loan') {
            if (!value || value === 'No') {
                const homeLoanMonthlyInstallment = values.home_loan_monthly_installment && values.home_loan_monthly_installment !== ' ' ? values.home_loan_monthly_installment : 0;
                const liabilityAmountSub = liabilityAmount - homeLoanMonthlyInstallment;
                onValueChanged(0, 0, 'home_loan_outstanding_balance');
                onValueChanged(0, 0, 'home_loan_monthly_installment');
                onValueChanged(liabilityAmountSub, 0, 'liability_amount');
            }
        } else if (item.field.id === 'has_other_liabilities') {
            if (!value || value === 'No') {
                const otherLiabilitiesMonthlyInstallment = values.other_liabilities_monthly_installment && values.other_liabilities_monthly_installment !== ' ' ? values.other_liabilities_monthly_installment : 0;
                const liabilityAmountSub = liabilityAmount - otherLiabilitiesMonthlyInstallment;
                onValueChanged(0, 0, 'other_liabilities_outstanding_balance');
                onValueChanged(0, 0, 'other_liabilities_monthly_installment');
                onValueChanged(liabilityAmountSub, 0, 'liability_amount');
            }
        } else if (item.field.id === 'has_credit_card') {
            if (!value || value === 'No') {
                const creditCardMonthlyInstallment = values.credit_card_monthly_installment && values.credit_card_monthly_installment !== ' ' ? values.credit_card_monthly_installment : 0;
                const liabilityAmountSub = liabilityAmount - creditCardMonthlyInstallment;
                onValueChanged(0, 0, 'credit_card_outstanding_balance');
                onValueChanged(0, 0, 'credit_card_monthly_installment');
                onValueChanged(liabilityAmountSub, 0, 'liability_amount');
            }
        }
    }
};

export const FinancialStatusDetails1 = (item, value, itemList, values, setDate) => {
    if (item.field.id === 'has_auto-loan' ||
        item.field.id === 'has_personal-loan' ||
        item.field.id === 'has_home-loan' ||
        item.field.id === 'has_other-liabilities' ||
        item.field.id === 'has_credit-card'
    ) {
        const liabilityAmount = values.liability_amount || 0;

        if (item.field.id === 'has_auto-loan') {
            if (!value || value === 'No') {
                const autoLoanMonthlyInstallment = values.auto_loan_monthly_installment && values.auto_loan_monthly_installment !== ' ' ? values.auto_loan_monthly_installment : 0;
                const liabilityAmountSub = liabilityAmount - autoLoanMonthlyInstallment;
                setDate('auto_loan-outstanding-balance', null);
                setDate('auto_loan-monthly-installment', null);
                setDate('auto_loan_outstanding_balance', '  ');
                setDate('auto_loan_monthly_installment', '  ');
                setDate('liability_amount', liabilityAmountSub || '  ');
            }
        } else if (item.field.id === 'has_personal-loan') {
            if (!value || value === 'No') {
                const personalLoanMonthlyInstallment = values.personal_loan_monthly_installment && values.personal_loan_monthly_installment !== ' ' ? values.personal_loan_monthly_installment : 0;
                const liabilityAmountSub = liabilityAmount - personalLoanMonthlyInstallment;
                setDate('personal_loan_outstanding_balance', '  ');
                setDate('personal_loan_monthly_installment', '  ');
                setDate('personal_loan-outstanding-balance', null);
                setDate('personal_loan-monthly-installment', null);
                setDate('liability_amount', liabilityAmountSub || '  ');
            }
        } else if (item.field.id === 'has_home-loan') {
            if (!value || value === 'No') {
                const homeLoanMonthlyInstallment = values.home_loan_monthly_installment && values.home_loan_monthly_installment !== ' ' ? values.home_loan_monthly_installment : 0;
                const liabilityAmountSub = liabilityAmount - homeLoanMonthlyInstallment;
                setDate('home_loan_outstanding_balance', '  ');
                setDate('home_loan_monthly_installment', '  ');
                setDate('home_loan-outstanding-balance', null);
                setDate('home_loan-monthly-installment', null);
                setDate('liability_amount', liabilityAmountSub || '  ');
            }
        } else if (item.field.id === 'has_other-liabilities') {
            if (!value || value === 'No') {
                const otherLiabilitiesMonthlyInstallment = values.other_liabilities_monthly_installment && values.other_liabilities_monthly_installment !== ' ' ? values.other_liabilities_monthly_installment : 0;
                const liabilityAmountSub = liabilityAmount - otherLiabilitiesMonthlyInstallment;
                setDate('other_liabilities_outstanding_balance', '  ');
                setDate('other_liabilities_monthly_installment', '  ');
                setDate('other_liabilities-outstanding-balance', null);
                setDate('other_liabilities-monthly-installment', null);
                setDate('liability_amount', liabilityAmountSub || '  ');
            }
        } else if (item.field.id === 'has_credit-card') {
            if (!value || value === 'No') {
                const creditCardMonthlyInstallment = values.credit_card_monthly_installment && values.credit_card_monthly_installment !== ' ' ? values.credit_card_monthly_installment : 0;
                const liabilityAmountSub = liabilityAmount - creditCardMonthlyInstallment;
                setDate('credit_card_outstanding_balance', '  ');
                setDate('credit_card_monthly_installment', '  ');
                setDate('credit_card-outstanding-balance', null);
                setDate('credit_card-monthly-installment', null);
                setDate('liability_amount', liabilityAmountSub || '  ');
            }
        }
    }
};

export const LiabilityAmount2 = (item, value, itemList, values, onValueChanged) => {
    if (item.field.id === 'auto_loan_monthly_installment' ||
        item.field.id === 'personal_loan_monthly_installment' ||
        item.field.id === 'home_loan_monthly_installment' ||
        item.field.id === 'credit_card_monthly_installment' ||
        item.field.id === 'other_liabilities_monthly_installment') {
        let sumLiabilityAmount = 0;
        if (item.field.id === 'auto_loan_monthly_installment') {
            sumLiabilityAmount = Number(value) +
                (values.personal_loan_monthly_installment && (values.personal_loan_monthly_installment !== ' ') ? values.personal_loan_monthly_installment : 0) +
                (values.home_loan_monthly_installment && (values.home_loan_monthly_installment !== ' ') ? values.home_loan_monthly_installment : 0) +
                (values.credit_card_monthly_installment && (values.credit_card_monthly_installment !== ' ') ? values.credit_card_monthly_installment : 0) +
                (values.other_liabilities_monthly_installment && (values.other_liabilities_monthly_installment !== ' ') ? values.other_liabilities_monthly_installment : 0);
            onValueChanged(sumLiabilityAmount, 0, 'liability_amount');
        } else if (item.field.id === 'personal_loan_monthly_installment') {
            sumLiabilityAmount = Number(value) +
                (values.auto_loan_monthly_installment && (values.auto_loan_monthly_installment !== ' ') ? values.auto_loan_monthly_installment : 0) +
                (values.home_loan_monthly_installment && (values.home_loan_monthly_installment !== ' ') ? values.home_loan_monthly_installment : 0) +
                (values.credit_card_monthly_installment && (values.credit_card_monthly_installment !== ' ') ? values.credit_card_monthly_installment : 0) +
                (values.other_liabilities_monthly_installment && (values.other_liabilities_monthly_installment !== ' ') ? values.other_liabilities_monthly_installment : 0);
            onValueChanged(sumLiabilityAmount, 0, 'liability_amount');
        } else if (item.field.id === 'home_loan_monthly_installment') {
            sumLiabilityAmount = Number(value) +
                (values.auto_loan_monthly_installment && (values.auto_loan_monthly_installment !== ' ') ? values.auto_loan_monthly_installment : 0) +
                (values.personal_loan_monthly_installment && (values.personal_loan_monthly_installment !== ' ') ? values.personal_loan_monthly_installment : 0) +
                (values.credit_card_monthly_installment && (values.credit_card_monthly_installment !== ' ') ? values.credit_card_monthly_installment : 0) +
                (values.other_liabilities_monthly_installment && (values.other_liabilities_monthly_installment !== ' ') ? values.other_liabilities_monthly_installment : 0);
            onValueChanged(sumLiabilityAmount, 0, 'liability_amount');
        } else if (item.field.id === 'credit_card_monthly_installment') {
            sumLiabilityAmount = Number(value) +
                (values.auto_loan_monthly_installment && (values.auto_loan_monthly_installment !== ' ') ? values.auto_loan_monthly_installment : 0) +
                (values.personal_loan_monthly_installment && (values.personal_loan_monthly_installment !== ' ') ? values.personal_loan_monthly_installment : 0) +
                (values.home_loan_monthly_installment && (values.home_loan_monthly_installment !== ' ') ? values.home_loan_monthly_installment : 0) +
                (values.other_liabilities_monthly_installment && (values.other_liabilities_monthly_installment !== ' ') ? values.other_liabilities_monthly_installment : 0);
            onValueChanged(sumLiabilityAmount, 0, 'liability_amount');
        } else if (item.field.id === 'other_liabilities_monthly_installment') {
            sumLiabilityAmount = Number(value) +
                (values.auto_loan_monthly_installment && (values.auto_loan_monthly_installment !== ' ') ? values.auto_loan_monthly_installment : 0) +
                (values.personal_loan_monthly_installment && (values.personal_loan_monthly_installment !== ' ') ? values.personal_loan_monthly_installment : 0) +
                (values.home_loan_monthly_installment && (values.home_loan_monthly_installment !== ' ') ? values.home_loan_monthly_installment : 0) +
                (values.credit_card_monthly_installment && (values.credit_card_monthly_installment !== ' ') ? values.credit_card_monthly_installment : 0);
            onValueChanged(sumLiabilityAmount, 0, 'liability_amount');
        }
    }
};

export const LiabilityAmount1 = (item, value, itemList, values, seData) => {
    if (item.field.id === 'auto_loan-monthly-installment' ||
        item.field.id === 'personal_loan-monthly-installment' ||
        item.field.id === 'home_loan-monthly-installment' ||
        item.field.id === 'credit_card-monthly-installment' ||
        item.field.id === 'other_liabilities-monthly-installment') {
        let sumLiabilityAmount = 0;
        if (item.field.id === 'auto_loan-monthly-installment') {
            sumLiabilityAmount = Number(value) +
                (values.personal_loan_monthly_installment && (values.personal_loan_monthly_installment !== ' ') ? values.personal_loan_monthly_installment : 0) +
                (values.home_loan_monthly_installment && (values.home_loan_monthly_installment !== ' ') ? values.home_loan_monthly_installment : 0) +
                (values.credit_card_monthly_installment && (values.credit_card_monthly_installment !== ' ') ? values.credit_card_monthly_installment : 0) +
                (values.other_liabilities_monthly_installment && (values.other_liabilities_monthly_installment !== ' ') ? values.other_liabilities_monthly_installment : 0);
            seData('liability_amount', sumLiabilityAmount || '0');
        } else if (item.field.id === 'personal_loan-monthly-installment') {
            sumLiabilityAmount = Number(value) +
                (values.auto_loan_monthly_installment && (values.auto_loan_monthly_installment !== ' ') ? values.auto_loan_monthly_installment : 0) +
                (values.home_loan_monthly_installment && (values.home_loan_monthly_installment !== ' ') ? values.home_loan_monthly_installment : 0) +
                (values.credit_card_monthly_installment && (values.credit_card_monthly_installment !== ' ') ? values.credit_card_monthly_installment : 0) +
                (values.other_liabilities_monthly_installment && (values.other_liabilities_monthly_installment !== ' ') ? values.other_liabilities_monthly_installment : 0);
            seData('liability_amount', sumLiabilityAmount || '0');
        } else if (item.field.id === 'home_loan-monthly-installment') {
            sumLiabilityAmount = Number(value) +
                (values.auto_loan_monthly_installment && (values.auto_loan_monthly_installment !== ' ') ? values.auto_loan_monthly_installment : 0) +
                (values.personal_loan_monthly_installment && (values.personal_loan_monthly_installment !== ' ') ? values.personal_loan_monthly_installment : 0) +
                (values.credit_card_monthly_installment && (values.credit_card_monthly_installment !== ' ') ? values.credit_card_monthly_installment : 0) +
                (values.other_liabilities_monthly_installment && (values.other_liabilities_monthly_installment !== ' ') ? values.other_liabilities_monthly_installment : 0);
            seData('liability_amount', sumLiabilityAmount || '0');
        } else if (item.field.id === 'credit_card-monthly-installment') {
            sumLiabilityAmount = Number(value) +
                (values.auto_loan_monthly_installment && (values.auto_loan_monthly_installment !== ' ') ? values.auto_loan_monthly_installment : 0) +
                (values.personal_loan_monthly_installment && (values.personal_loan_monthly_installment !== ' ') ? values.personal_loan_monthly_installment : 0) +
                (values.home_loan_monthly_installment && (values.home_loan_monthly_installment !== ' ') ? values.home_loan_monthly_installment : 0) +
                (values.other_liabilities_monthly_installment && (values.other_liabilities_monthly_installment !== ' ') ? values.other_liabilities_monthly_installment : 0);
            seData('liability_amount', sumLiabilityAmount || '0');
        } else if (item.field.id === 'other_liabilities-monthly-installment') {
            sumLiabilityAmount = Number(value) +
                (values.auto_loan_monthly_installment && (values.auto_loan_monthly_installment !== ' ') ? values.auto_loan_monthly_installment : 0) +
                (values.personal_loan_monthly_installment && (values.personal_loan_monthly_installment !== ' ') ? values.personal_loan_monthly_installment : 0) +
                (values.home_loan_monthly_installment && (values.home_loan_monthly_installment !== ' ') ? values.home_loan_monthly_installment : 0) +
                (values.credit_card_monthly_installment && (values.credit_card_monthly_installment !== ' ') ? values.credit_card_monthly_installment : 0);
            seData('liability_amount', sumLiabilityAmount || '0');
        }
    }
};
