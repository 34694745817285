export const LoginFormsEnum = {
  SignIn : {
    label: "SignIn",
    key: 1,
  },
  ResetPasswordRequest : {
    label: "ResetPasswordRequest",
    key: 2,
  },
  UserVerification : {
    label: "UserVerification",
    key: 3,
  },
  OTPVerification : {
    label: "OTPVerification",
    key: 4,
  },
  ChangePassword : {
    label: "ChangePassword",
    key: 5,
  },
}