/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useCallback, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ButtonBase, Tooltip } from '@material-ui/core';
import moment from 'moment-timezone';
import {
  CheckboxesComponent,
  LoadableImageComponant,
} from '../../../../../../../Components';
import {
  ActionsEnum,
  ContactTypeEnum,
  LoadableImageEnum,
  TableActions,
} from '../../../../../../../Enums';
import {
  getDownloadableLink, bottomBoxComponentUpdate, GlobalHistory
} from '../../../../../../../Helper';
import {CopyToClipboardComponents} from '../../../../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';

export const AssociatedCardsComponent = ({
  data,
  activeCard,
  isExpanded,
  onCardClicked,
  onFooterActionsClicked,
  parentTranslationPath,
  translationPath,
  withCheckbox,
  onCardCheckboxClick,
  selectedDetailsContactItem,
  isCheckBoxDisabled,
  selectedCards,
  activeTab,
  getAllAssociatedContact,
  onActionClicked
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const getIsSelectedCard = useCallback(
    (row) => selectedCards && selectedCards.findIndex((item) => item.relatedAssociatedContactsId === row.relatedAssociatedContactsId) !== -1,
    [selectedCards]
  );

  const cancelHandler = () => {
    GlobalHistory.goBack();
  };
  const getDefaultContactImage = useCallback(
    (contactType) => ContactTypeEnum[contactType] && ContactTypeEnum[contactType].defaultImg,
    []
  );
  const saveHandler = async () => { };
  useEffect(() => {
    bottomBoxComponentUpdate(
      <div className='d-flex-v-center-h-end flex-wrap'>
        <ButtonBase className='btns theme-transparent mb-2' onClick={cancelHandler}>
          <span>{t('Shared:back')}</span>
        </ButtonBase>

      </div>
    );
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  return (
    <div className='contacts-cards-wrapper childs-wrapper'>
      {data.map((item, index) => (
        <div
          className={`contacts-card-wrapper${isExpanded ? ' is-expanded' : ''}${(((activeCard && activeCard.id === item.relatedAssociatedContactsId) ||
            (selectedDetailsContactItem && selectedDetailsContactItem.id === item.relatedAssociatedContactsId)) &&
            ' is-open') ||
            ''
            }`}
          key={`contactsCardItemRef${index + 1}`}
        >
          {withCheckbox && (
            <div className='card-checkbox-wrapper'>
              <CheckboxesComponent
                idRef={`contactsCheckboxItemRef${index + 1}`}
                singleChecked={getIsSelectedCard(item)}
                onSelectedCheckboxChanged={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  onCardCheckboxClick(index, item);
                }}
                isDisabled={
                  (isCheckBoxDisabled && !getIsSelectedCard(item)) ||
                  (selectedCards &&
                    selectedCards[0] &&
                    selectedCards[0].userTypeId !== item.userTypeId)
                }
              />
            </div>
          )}
          <a onClick={onCardClicked && onCardClicked(item, index)} className='cards-wrapper'>
            <div className='cards-body-wrapper'>
              <div className='card-body-section'>
                <div className='merge-wrapper'>
                  <LoadableImageComponant
                    classes='cover-image'
                    type={LoadableImageEnum.div.key}
                    alt={t(`${translationPath}contact-image`)}
                    src={
                      (item.relatedContactImageFileId &&
                        getDownloadableLink(item.relatedContactImageFileId)) || getDefaultContactImage('man')
                    }
                  />
                  {item && item.mergedWithContactId && (
                    <Tooltip title={t(`${translationPath}merged`)}>
                      <div className='merged-contact'>
                        <span className='mdi mdi-arrow-collapse-all' />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className={`card-body-section${isExpanded ? ' is-expanded' : ''}`}>
                <div className='body-title-wrapper'>
                  <div className='associated'>
                    <span className='body-title'>{item.relatedContactName}</span>
                    <Tooltip title={t(`${translationPath}delete`)}>
                      <span className='details-icon mdi mdi-delete mdi-20px iconDelete' onClick={onActionClicked(TableActions.deleteText.key, item, index)} />
                    </Tooltip>
                  </div>
                  <div className='hedar-card'>
                    <div className='created-on'>
                      <span className='details-icon mdi mdi-calendar mdi-16px' />
                      <span>
                        <span className='details-text'>
                          {t(`${translationPath}created`)}
                          :
                        </span>
                        <span className='px-1'>
                          {(item.createdOn &&
                            moment(item.createdOn)
                              .locale(i18next.language)
                              .format('DD/MM/YYYY')) ||
                            'N/A'}
                        </span>
                      </span>
                    </div>
                    <div className='contact-id-wrapper'>
                      <span className='idName'>
                        {t(`${translationPath}id`)}
                      </span>
                      {' '}
                      :
                      <div className='contact-id'>
                        <span className='itemid' />
                      </div>
                      <Tooltip title={t(`${translationPath}copy`)}>
                        <CopyToClipboardComponents
                          data={item && item.relatedAssociatedContactsId}
                          childrenData={item && item.relatedAssociatedContactsId}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  {item.contactRelationshipName && (
                    <div className='w-100'>
                      <span>{t(`${translationPath}${item.contactRelationshipName}`)}</span>
                    </div>
                  )}
                </div>
                <div className='details-item-wrapper'>
                  <span className='details-text contacts-text-wrap'>
                    {t(`${translationPath}email`)}
                  </span>
                  :
                  <span className='px-1 contacts-card-text'>
                    {item.relatedContactEmail}
                  </span>
                </div>
                <div className='details-item-wrapper'>
                  <span className='details-text contacts-text-wrap'>
                    {t(`${translationPath}mobile`)}
                  </span>
                  :
                  <span className='px-1 contacts-card-text'>
                    {item.relatedContactMobile}
                  </span>
                </div>
                <div className='details-item-wrapper'>
                  <span className='details-text contacts-text-wrap'>
                    {t(`${translationPath}nationality`)}
                  </span>
                  :
                  <span className='px-1 contacts-card-text'>
                    {item.relatedContactNationality}
                  </span>
                </div>
                <div className='details-item-wrapper'>
                  <span className='details-text contacts-text-wrap'>
                    {t(`${translationPath}language`)}
                  </span>
                  :
                  <span className='px-1 contacts-card-text'>
                    {item.relatedContactLanguage}
                  </span>
                </div>

              </div>
            </div>
            <div className='cards-footer-wrapper'>
              <ButtonBase
                className='btns theme-transparent w-50 mx-0'
                onClick={onFooterActionsClicked(ActionsEnum.folder.key, item, index)}
                id='ActionsClickedfolder'
              >
                <span className='mdi mdi-folder-outline' />
                <span className='px-1'>{t('open-file')}</span>
              </ButtonBase>
              <ButtonBase
                id='edit-file-ref'
                className='btns theme-transparent w-50 mx-0'
                onClick={onFooterActionsClicked(ActionsEnum.reportEdit.key, item, index)}
              >
                <span className='mdi mdi-pencil-outline' />
                <span className='px-1'>{t('edit')}</span>
                <Tooltip title={moment(item.updateOn).format('DD/MM/YYYY HH:mm A') || 'N/A'}>
                  <span className='edit-power-icon mdi mdi-clock-time-four-outline c-warning mdi-16px' />
                </Tooltip>
              </ButtonBase>
            </div>
          </a>
        </div>
      ))}
    </div>
  );
};

AssociatedCardsComponent.propTypes = {
  data: PropTypes.shape({ result: PropTypes.instanceOf(Array), totalCount: PropTypes.number })
    .isRequired,
  onFooterActionsClicked: PropTypes.func.isRequired,
  activeCard: PropTypes.instanceOf(Object),
  selectedDetailsContactItem: PropTypes.instanceOf(Object),
  isExpanded: PropTypes.bool,
  withCheckbox: PropTypes.bool,
  onCardClicked: PropTypes.func,
  onCardCheckboxClick: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  isCheckBoxDisabled: PropTypes.bool,
  selectedCards: PropTypes.instanceOf(Array),
};
AssociatedCardsComponent.defaultProps = {
  isExpanded: false,
  withCheckbox: false,
  onCardClicked: undefined,
  selectedDetailsContactItem: undefined,
  activeCard: undefined,
  isCheckBoxDisabled: false,
  selectedCards: undefined,
};
