import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useReducer,
} from "react";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  Inputs,
  SelectComponet,
  Spinner,
  ViewTypes,
  NoContentComponent,
  DialogComponent,
  AutocompleteComponent,
  DatePickerComponent,
} from "../../../Components";
import { ViewTypesEnum } from "../../../Enums/ViewTypes.Enum";
import { ShareUnitDialog } from "./ShareUnitUtitities/ShareUnitDialog";
import {
  GetAllListingAgentsServices,
  GetLookupItemsByLookupTypeName,
  getProperties,
  lookupItemsGetId,
  ReassignLeads,
  ShareUnitsSearchAPI,
  RemoveSavedShareUnitsFromCriteria,
} from "../../../Services";
import { ShareUnitTableComponent } from "./ShareUnitUtitities/ShareUnitTableComponent";
import { ActiveItemActions } from "../../../store/ActiveItem/ActiveItemActions";
import { ActionsEnum } from "../../../Enums/Actions.Enum";
import {
  GlobalHistory,
  returnPropsByPermissions,
  sideMenuComponentUpdate,
  sideMenuIsOpenUpdate,
  showError,
  showSuccess,
} from "../../../Helper";
import { FavouriteShareUnitsDialog } from "./ShareUnitUtitities/FavouriteShareUnitsDialog";
import { AddToFavouriteShareUnitsDialog } from "./ShareUnitUtitities/AddToFavouriteShareUnitsDialog";
import { bottomBoxComponentUpdate } from "../../../Helper/Middleware.Helper";
import { PaginationComponent } from "../../../Components/PaginationComponent/PaginationComponent";
import { QACallCenterPermissions } from "../../../Permissions";
import { GlobalOrderFilterActions } from "../../../store/GlobalOrderFilter/GlobalOrderFilterActions";
import { useTitle } from "../../../Hooks";
import { SharetUnitsCardsComponent } from "./ShareUnitUtitities/SharetUnitsCardsComponent";
import { isAdminInquiries } from "../../../Helper/isAdmin.Helper";
import { PauseShareUnitsComponent } from "./ShareUnitUtitities/PauseShareUnitsComponent";
import { LocationFieldsComponent } from "../../../SharedComponents";
import { StaticLookupsIds } from "../../../assets/json/StaticLookupsIds";
import { TableFilterOperatorsEnum } from "../../../Enums";
const parentTranslationPath = "ShareUnit";
const translationPath = "";

export const ShareUnitView = () => {
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t(`${translationPath}share-unit`));

  const dateRangeDefault = {
    startDate: new Date(moment().subtract(1, "days")),
    endDate: new Date(moment(moment().format()).endOf("day")),
    key: "selection",
  };
  const searchTimer = useRef(null);
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy:
      (orderFilter.ShareUnitFilter && orderFilter.ShareUnitFilter.filterBy) ||
      null,
    orderBy:
      (orderFilter.ShareUnitFilter && orderFilter.ShareUnitFilter.orderBy) ||
      null,
  });
  const [shareTableFilter, setShareTableFilter] = useState({});
  const [isClearFiltersClicked, setIsClearFiltersClicked] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [activeActionType, setActiveActionType] = useState(
    ViewTypesEnum.cards.key
  );
  const reducer = useCallback((state, action) => {
    switch (action.type) {
      case "edit":
        return {
          ...action.value,
        };
      case "reset":
        const resetState = {};
        Object.keys(state).forEach((key) => {
          resetState[key] = "";
        });
        return resetState;
      default:
        return {
          ...state,
          [action.id]: action.value ?? "",
        };
    }
  }, []);

  const [state, setState] = useReducer(reducer, {
    city: null,
    district: null,
    community: null,
    subCommunity: null,
    PropertyName: null,
    unitType: null,
    saleType: null,
    operationType: null,
    completionStatus: null,
    Pricefrom: null,
    Priceto: null,
    Sizefrom: null,
    Sizeto: null,
    NumberOfBedroomsfrom: null,
    NumberOfBedroomsto: null,
    BranchName: null,
    listingAgent: null,
    Datefilter: null,
  });
  const [selected, setSelected] = useReducer(reducer, {});
  const [data, setData] = useReducer(reducer, {
    properties: [],
    unitTypes: [],
    unitRef: [],
    saleType: [],
    operationType: [],
    listingAagent: [],
    completionStatus: [],
  });

  const [filterCriteria, setFilterCriteria] = useState({});
  const [isloading, setLoading] = useState({
    properties: false,
    unitTypes: false,
    unitRef: false,
    saleType: false,
    operationType: false,
    ListingAgent: false,
    completionStatus: false,
  });
  const filterCriteriaChangeHandler = (filterKey, filterValue, searchType) => {
    if (filterValue) {
      const newFilterItem = {
        [filterKey]: [
          {
            searchType,
            value: filterValue,
          },
        ],
      };
      const newFilterCriteria = {
        ...(filterCriteria || {}),
        ...newFilterItem,
      };
      setFilterCriteria(newFilterCriteria);
    } else {
      const newFilterCriteria = {
        ...(filterCriteria || {}),
      };
      delete newFilterCriteria[filterKey];
      setFilterCriteria(newFilterCriteria);
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingReassign, setIsLoadingReassign] = useState(false);
  const [activeSelectedAction, setActiveSelectedAction] = useState(false);
  const [AddToFavouriteDialog, setAddToFavouriteDialog] = useState(false);
  const [checkedDetailed, setCheckedDetailed] = useState([]);
  const [isOpenFavouriteShareUnitsDialog, setisOpenFavouriteShareUnitsDialog] =
    useState(false);
  const [SavedShareUnitCriteriaIdValue, setSavedShareUnitCriteriaIdValue] =
    useState("");
  const dispatch = useDispatch();
  const [qaUsers, setQaUsers] = useState();
  const [activeCard, setActiveCard] = useState(null);
  const [detailsUnitsList, setDetailsUnitsList] = useState(() => ({
    result: [],
    totalCount: 0,
  }));

  const isAdminInquiriesValue = isAdminInquiries();
  const defultFilterValues = {
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
    criteria: {},
    filterBy: (selectedOrderBy && selectedOrderBy.filterBy) || null,
    orderBy: (selectedOrderBy && selectedOrderBy.orderBy) || null,
    operationType: null,
    isAdmin: isAdminInquiriesValue,
  }
  const [searchValue, setSearchValue] = useState("");
  const [searchValueProprety, setsearchValueProprety] = useState("");
  const [searchsharedby, setsearchsharedby] = useState("");
  const [filter, setFilter] = useState(defultFilterValues);
  const [FiltersavedShareUnitCriteriaId, setFiltersavedShareUnitCriteriaId] =
    useState({});
  const [Singalid, setSingalid] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [
    isDialogOpenPauseShareUnitsComponent,
    SetisDialogOpenPauseShareUnitsComponent,
  ] = useState(false);

  const [ShareUnit, setShareUnit] = useState({
    result: [],
    totalCount: 0,
  });
  const [isOpenReassign, setIsOpenReassign] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const getPropertiesOptions = async (searchValue) => {
    const res = await getProperties({
      pageSize: 10,
      pageIndex: 0,
      search: searchValue || " ",
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "properties", value: res?.result || [] });
    else setData({ id: "properties", value: [] });
  };

  const getUnitTypeLookups = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "UnitType",
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "unitTypes", value: res.result || [] });
    } else setData({ id: "unitTypes", value: [] });
  };
  const getSaleType = useCallback(async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.UnitSaleType,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "saleType", value: res || [] });
    else setData({ id: "saleType", value: [] });
    // setIsLoading(false);
  }, []);
  const getAllAgents = useCallback(async (search) => {
    setLoading({ id: "ListingAgent", value: true });
    const res = await GetAllListingAgentsServices({
      pageIndex: 0,
      pageSize: 50,
      search: search || "",
    });

    if (!(res && res.status && res.status !== 200))
      setData({
        id: "listingAagent",
        value: res.result,
      });
    else
      setData({
        id: "listingAagent",
        value: [],
      });

    setLoading({ id: "ListingAgent", value: false });
  }, []);
  const getOperationType = async () => {
    const FilterOprationType=[]
    // setLoading({ id: 'operationType', value: true });
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.UintOperationType,
    });
    if (!(res && res.status && res.status !== 200)){
      res.forEach((item)=>{
        if(item.lookupItemId !== 20984){
          FilterOprationType.push(item)
        }
        })
        setData({ id: "operationType", value: FilterOprationType });
    }
    else {
      setData({ id: "operationType", value: [] })
    }
    // setLoading({ id: 'operationType', value: false });
  };
  const getUnitTypeLookupsPropertyPlan = async () => {
    setLoading({
      id: "completionStatus",
      value: true,
    });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "Property Plan",
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "completionStatus",
        value: res.result,
      });
    } else
      setData({
        id: "completionStatus",
        value: [],
      });
    setLoading({
      id: "completionStatus",
      value: false,
    });
  };
  const changeActivitystatus = (value) => {
    setFilter((item) => ({ ...item, isOpen: value }));
  };
  const changeQaUsers = (value) => {
    setFilter((item) => ({ ...item, userId: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };
  const searchHandler = (event) => {
    const { value } = event.target;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({ ...item, search: value }));
    }, 700);
  };

  const GetAllShareUnitsSearchAPI = useCallback(
    async (SavedShareUnitCriteriaIdValue) => {
      const params = {
        ...filter,
        criteria: {}
      };
      delete params.pageIndex;
      delete params.pageSize;

      if (searchValue) {
        params.criteria.unit_ref_no = [{ searchType: 2, value: searchValue }];
      } else {
        delete params.criteria.unit_ref_no;
      }
      if (filterCriteria) {
        params.criteria.city = filterCriteria.city
          ? [{ searchType: 2, value: filterCriteria.city[0]?.value ?? "" }]
          : undefined;
        params.criteria.country = filterCriteria.country
          ? [{ searchType: 2, value: filterCriteria.country[0]?.value ?? "" }]
          : undefined;
        params.criteria.district = filterCriteria.district
          ? [{ searchType: 2, value: filterCriteria.district[0]?.value ?? "" }]
          : undefined;
        params.criteria.community = filterCriteria.community
          ? [{ searchType: 2, value: filterCriteria.community[0]?.value ?? "" }]
          : undefined;
        params.criteria.subCommunity = filterCriteria.subCommunity
          ? [
            {
              searchType: 2,
              value: filterCriteria.subCommunity[0]?.value ?? "",
            },
          ]
          : undefined;
        params.criteria.property_name = filterCriteria.property_name
          ? [
            {
              searchType: 2,
              value: filterCriteria.property_name[0]?.value || "",
            },
          ]
          : undefined;
        params.criteria.unit_type = filterCriteria.unit_type
          ? [{ searchType: 2, value: filterCriteria.unit_type[0]?.value ?? "" }]
          : undefined;
        params.criteria.sale_type = filterCriteria.sale_type
          ? [{ searchType: 2, value: filterCriteria.sale_type[0]?.value ?? "" }]
          : undefined;
        params.criteria.operation_type = filterCriteria.operation_type
          ? [
            {
              searchType: 2,
              value: filterCriteria.operation_type[0]?.value ?? "",
            },
          ]
          : undefined;
        params.criteria.CompletionStatus = filterCriteria.CompletionStatus
          ? [
            {
              searchType: 2,
              value: filterCriteria.CompletionStatus[0]?.value ?? "",
            },
          ]
          : undefined;
        params.criteria.listing_agent = filterCriteria.listing_agent
          ? [
            {
              searchType: 2,
              value: filterCriteria.listing_agent[0]?.value ?? "",
            },
          ]
          : undefined;
        params.criteria.fromSellingPrice = filterCriteria.fromSellingPrice
          ? [
            {
              searchType: 2,
              value: filterCriteria.fromSellingPrice[0]?.value ?? "",
            },
          ]
          : undefined;
        params.criteria.toSellingPrice = filterCriteria.toSellingPrice
          ? [
            {
              searchType: 2,
              value: filterCriteria.toSellingPrice[0]?.value ?? "",
            },
          ]
          : undefined;
        params.criteria.fromSize = filterCriteria.fromSize
          ? [{ searchType: 2, value: filterCriteria.fromSize[0]?.value ?? "" }]
          : undefined;
        params.criteria.toSize = filterCriteria.toSize
          ? [{ searchType: 2, value: filterCriteria.toSize[0]?.value ?? "" }]
          : undefined;
        params.criteria.fromBedroom = filterCriteria.fromBedroom
          ? [
            {
              searchType: 2,
              value: filterCriteria.fromBedroom[0]?.value ?? "",
            },
          ]
          : undefined;
        params.criteria.toBedroom = filterCriteria.toBedroom
          ? [{ searchType: 2, value: filterCriteria.toBedroom[0]?.value ?? "" }]
          : undefined;
        params.criteria.fromBedroom = filterCriteria.fromBedroom
          ? [
            {
              searchType: 2,
              value: filterCriteria.fromBedroom[0]?.value ?? "",
            },
          ]
          : undefined;
        params.criteria.fromBedroom = filterCriteria.fromBedroom
          ? [
            {
              searchType: 2,
              value: filterCriteria.fromBedroom[0]?.value ?? "",
            },
          ]
          : undefined;
        params.criteria.sharedBy = filterCriteria.sharedBy
          ? [
            {
              searchType: 2,
              value: filterCriteria.sharedBy[0]?.value ?? "",
            },
          ]
          : undefined;
          params.criteria.sharedOn = filterCriteria.sharedOn
          ? [
            {
              searchType: 10,
              value: filterCriteria.sharedOn[0]?.value ?? "",
            },
          ]
          : undefined;
      } else {
        delete params.criteria.city;
        delete params.criteria.country;
        delete params.criteria.district;
        delete params.criteria.community;
        delete params.criteria.subCommunity;
        delete params.criteria.property_name;
        delete params.criteria.unit_type;
        delete params.criteria.sale_type;
        delete params.criteria.operation_type;
        delete params.criteria.listing_agent;
        delete params.criteria.CompletionStatus;
        delete params.criteria.fromSellingPrice;
        delete params.criteria.toSellingPrice;
        delete params.criteria.fromSize;
        delete params.criteria.toSize;
        delete params.criteria.fromBedroom;
        delete params.criteria.toBedroom;
        delete params.criteria.sharedBy;
        delete params.criteria.sharedOn;
      }

      if (shareTableFilter) {
        Object.values(shareTableFilter)
          .filter((item) => item?.searchableKey || item?.displayPath)
          .map((item) => {
            if (params.criteria[item.searchableKey || item.displayPath]) {
              params.criteria[item.searchableKey || item.displayPath].push({
                searchType: item.operator,
                value: item.value,
              });
            } else if (item?.value) {
              params.criteria[item.searchableKey || item.displayPath] = [
                {
                  searchType: item.operator,
                  value: item.value,
                },
              ];
            } else if (
              !item.value &&
              (item.operator === TableFilterOperatorsEnum.isNotBlank.key ||
                item.operator === TableFilterOperatorsEnum.isBlank.key)
            ) {
              params.criteria[item.searchableKey || item.displayPath] = [
                {
                  searchType: item.operator,
                  value: null,
                },
              ];
            } else if (item?.value === "") {
              delete params.criteria[item.searchableKey || item.displayPath]
            }
            return undefined;
          });
      }
      if (SavedShareUnitCriteriaIdValue) {
        params.criteria.savedShareUnitCriteriaId = [
          {
            searchType: 2,
            value:
              SavedShareUnitCriteriaIdValue.savedShareUnitCriteriaId ||
              SavedShareUnitCriteriaIdValue,
          },
        ];
      } else {
        delete params.criteria.savedShareUnitCriteriaId;
      }

      setIsLoading(true);
      const result = await ShareUnitsSearchAPI(
        {
          pageSize: filter.pageSize,
          pageIndex: filter.pageIndex,
        },
        params
      );

      const updatedResult = {
        result: result.result,
        totalCount: result.totalCount || 0,
      };

      setDetailsUnitsList(updatedResult);

      if (result && result.status && result.status !== 200) {
        setShareUnit({ result: [], totalCount: 0 });
      } else {
        setShareUnit(updatedResult);
      }
      setIsClearFiltersClicked(false);
      setIsLoading(false);
    },
    [filter, searchValue, filterCriteria, shareTableFilter]
  );
  const onFilterValuesChanged = (newValue) => {
    setShareTableFilter(newValue)
  };


  useEffect(() => {
    GetAllShareUnitsSearchAPI();
  }, [filterCriteria, shareTableFilter, filter]);


  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) return;
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        ShareUnitFilter: {
          filterBy: selectedOrderBy.filterBy,
          orderBy: selectedOrderBy.orderBy,
        },
      })
    );
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
  };

  const detailedCardSideActionClicked = useCallback(
    (actionEnum, activeData) => async (event) => {
      dispatch(ActiveItemActions.activeItemRequest(activeData));
      event.stopPropagation();
      if (actionEnum === ActionsEnum.folder.key) {
        setIsOpenReassign(true);
        setActiveItem(activeData);
        setActiveCard(activeData);
      } else if (actionEnum === ActionsEnum.delete.key) {
        SetisDialogOpenPauseShareUnitsComponent(true);
        setActiveItem(activeData);
        setActiveCard(activeData);
      }
    },
    [dispatch]
  );

  const reest = async () => {
    setCheckedDetailed([]);
    setActiveSelectedAction(false);
    setisOpenFavouriteShareUnitsDialog(false);
    setAddToFavouriteDialog(false);
    setActiveItem(null);
  };
  const onClearedAllFiltersClick = useCallback(() => {
    dispatch(GlobalOrderFilterActions.globalOrderFilterRequest({}));
    setOrderBy({ filterBy: null, orderBy: null });
    setFilterCriteria({});
    setSelected({ type: "reset" });
    setSelectedOrderBy({
      filterBy: null,
      orderBy: null,
    });
    setSearchValue("");
    setsearchValueProprety("");
    setsearchsharedby("")
    setShareTableFilter({})
    setIsClearFiltersClicked(true);
    setSavedShareUnitCriteriaIdValue("")
  }, [dispatch ]);


  const onTypeChanged = useCallback(
    (activeType) => {
      setActiveActionType(activeType);
    },
    [setActiveActionType]
  );
  const reassignHandler = async (reassignItem) => {
    setFilter((item) => ({ ...item, pageIndex: 0, leadStatus: null }));
    setIsOpenReassign(false);
    showSuccess(t(`${translationPath}leads-reassign-notification`));
    const obj = {
      leadIds: [activeItem && activeItem.leadId],
      referredToId: reassignItem.referredToId,
      isCopyTo: reassignItem.isCopyTo,
    };
    await ReassignLeads(obj);
  };

  useEffect(() => {
    if (orderBy)
      setFilter((item) => ({
        ...item,
        filterBy: orderBy.filterBy,
        orderBy: orderBy.orderBy,
      }));
  }, [orderBy]);
  useEffect(() => {
    if (sortBy)
      setFilter((item) => ({
        ...item,
        filterBy: sortBy.filterBy,
        orderBy: sortBy.orderBy,
      }));
  }, [sortBy]);

  useEffect(() => {
    if (
      returnPropsByPermissions(
        QACallCenterPermissions.ViewQaActivitiesWithLeadInfo.permissionsId
      )
    ) {
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={ShareUnit.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
    }
  });

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );
  const getSystemProperties = async (searchValue) => {
    const res = await getProperties({
      pageSize: 10,
      pageIndex: 0,
      search: searchValue || "",
    });
    if (!(res && res.status && res.status !== 200)) {
      return res?.result || [];
    } else return [];
  };
  const DeleteSavedShareUnits = useCallback(
    async (
      items,
      SavedShareUnitCriteriaIdValue,
      FiltersavedShareUnitCriteriaId,
      Singalid
    ) => {
      const res = await RemoveSavedShareUnitsFromCriteria({
        savedShareUnitCriteriaId: SavedShareUnitCriteriaIdValue,
        shareUnitsIds:
          (Singalid && [Singalid.shareUnitId]) ||
          items.map((item) => item.shareUnitId),
      });
      if (!(res && res.status && res.status !== 200)) {
        GetAllShareUnitsSearchAPI(FiltersavedShareUnitCriteriaId);
        setCheckedDetailed([]);
        setActiveSelectedAction(false);
        setisOpenFavouriteShareUnitsDialog(false);
        setAddToFavouriteDialog(false);
        setActiveItem(null);
        setIsDeleteDialogOpen(false);
        showSuccess(t("Deleted-Successfully"));
      } else {
        showError(t("Deleting-Has-Failed"));
      }
    },
    []
  );

  const onCardClick = useCallback(
    (item, selectedIndex) => (event) => {
      event.stopPropagation();
      event.preventDefault();
      setActiveCard(item);
      setActiveItem(item);
      // sideMenuComponentUpdate(
      //   <CardDetailsComponent
      //     activeData={detailsUnitsList.result[selectedIndex]}
      //     cardDetailsActionClicked={detailedCardSideActionClicked}
      //     parentTranslationPath={parentTranslationPath}
      //     translationPath={translationPath}
      //   />
      // );
      // sideMenuIsOpenUpdate(true);
    },
    [detailedCardSideActionClicked, detailsUnitsList.result]
  );

  const cardCheckboxClicked = useCallback((itemIndex, element) => {
    setCheckedDetailed((items) => {
      const index = items.findIndex(
        (item) => item.shareUnitId === element.shareUnitId
      );
      if (index !== -1) items.splice(index, 1);
      else items.push(element);
      return [...items];
    });
  }, []);
  const cardCheckboxClickedTabel = useCallback((element, itemIndex) => {
    setCheckedDetailed((items) => {
      const index = items.findIndex(
        (item) => item.shareUnitId === element.shareUnitId
      );
      if (index !== -1) items.splice(index, 1);
      else items.push(element);
      return [...items];
    });
  }, []);

  const getIsSelectedCard = useCallback(
    (itemIndex) =>
      checkedDetailed.findIndex(
        (item) =>
          item.shareUnitId === itemIndex.shareUnitId || item === itemIndex
      ) !== -1,
    [checkedDetailed]
  );

  return (
    <div className="view-wrapper QA-view-wrapper">
      <Spinner isActive={isLoading} />
      <div className="d-flex-column">
        <div className="header-section w-100">
          <div className="filter-section w-100">
            <div className="section autocomplete-section w-100">
              <div className="d-flex-column px-2 w-100 p-relative">
                <div className="d-flex ">

                  <div className="d-flex ml-2">
                    <Inputs
                      idRef="userNssssameFilterRef"
                      label="filter-by-unit-ref"
                      fieldClasses="inputs theme-solid"
                      parentTranslationPath={parentTranslationPath}
                      value={searchValue}
                      onInputChanged={(e) => {
                        setSearchValue(e?.target?.value || "");
                      }}
                      onKeyUp={(event) => {
                        if (searchTimer.current)
                          clearTimeout(searchTimer.current);
                        const newValue = event.target.value;
                        searchTimer.current = setTimeout(() => {
                          setFilter({
                            ...filter,
                            id: "contactId",
                            value: newValue,
                          });
                        }, 700);
                      }}
                    />
                  </div>
        
                  <div className="d-flex ml-2">
                    <Inputs
                      idRef="sharedbyRef"
                      label="shared-by"
                      fieldClasses="inputs theme-solid"
                      parentTranslationPath={parentTranslationPath}
                      value={searchsharedby}
                      onInputChanged={(e) => {
                        setsearchsharedby(e?.target?.value || "");
                      }}
                      onKeyUp={(event) => {
                        if (searchTimer.current)
                          clearTimeout(searchTimer.current);
                        const newValue = event.target.value;
                        // searchTimer.current = setTimeout(() => {
                        //   const sharedbyKey = "sharedby";
                        //   const sharedbyValue = newValue || null;
                        //   const searchType = 1;
                        //   filterCriteriaChangeHandler(
                        //     sharedbyKey,
                        //     sharedbyValue,
                        //     searchType
                        //   );
                        // }, 600);
                        searchTimer.current = setTimeout(() => {
                          const sharedbyKey = "sharedBy";
                          const sharedbyValue = newValue || null;
                          const searchType = 1;
                          filterCriteriaChangeHandler(
                            sharedbyKey,
                            sharedbyValue,
                            searchType
                          );
                        }, 600);
                      }}
                    />
                  </div>
                  <div className="d-flex ml-2 mb-4">
                  <DatePickerComponent
                    idRef="DateOfBirthRef"
                    labelValue="Shared Date"
                    placeholder="DD/MM/YYYY"
                    value={selected.sharedOn || null}
                    onDateChanged={(newValue) => {
                      setSelected({ id: "sharedOn", value: newValue });
                      const sharedOnKey = "sharedOn";
                      const sharedOnValue = (newValue &&
                        moment(newValue).format('YYYY-MM-DD')) ||
                        null;
                      const searchType = 1;
                      filterCriteriaChangeHandler(
                        sharedOnKey,
                        sharedOnValue,
                        searchType
                      );
                    }}
                  />
                </div>
                </div>
                <div className="">
                  <div className="d-flex">
                    <div className="mr-0-reversed mx-2">
                      <AutocompleteComponent
                        idRef="unitTypeRef"
                        wrapperClasses="w-min-unset m-2 mr-1"
                        inputPlaceholder={t(`${translationPath}select`)}
                        labelValue={t(`${translationPath}unit-Type`)}
                        selectedValues={selected.unitType || " "}
                        data={data.unitTypes || []}
                        displayLabel={(option) => option.lookupItemName || ""}
                        multiple={false}
                        withoutSearchButton
                        onChange={(_, newValue) => {
                          setSelected({ id: "unitType", value: newValue });
                          const unitTypeKey = "unit_type";
                          const unitTypeValue =
                            newValue?.lookupItemName || null;
                          const searchType = 1;

                          filterCriteriaChangeHandler(
                            unitTypeKey,
                            unitTypeValue,
                            searchType
                          );
                        }}
                        onOpen={() => {
                          if (data.unitTypes && data.unitTypes.length == 0)
                            getUnitTypeLookups();
                        }}
                      />
                    </div>
                    <div className="mr-0-reversed mx-2">
                      <AutocompleteComponent
                        wrapperClasses="w-min-unset m-2 mr-1"
                        inputPlaceholder="select"
                        data={data.saleType}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        withoutSearchButton
                        selectedValues={selected.saleType || " "}
                        onOpen={() => {
                          if (data.saleType && data.saleType.length === 0)
                            getSaleType(StaticLookupsIds.UnitSaleType);
                        }}
                        labelValue={t(`${translationPath}sale-Type`)}
                        displayLabel={(option) => option.lookupItemName || ""}
                        chipsLabel={(option) => option.lookupItemName || ""}
                        multiple={false}
                        onChange={(_, newValue) => {
                          setSelected({ id: "saleType", value: newValue });
                          const saleTypeKey = "sale_type";
                          const saleTypeValue =
                            newValue?.lookupItemName || null;
                          const searchType = 1;

                          filterCriteriaChangeHandler(
                            saleTypeKey,
                            saleTypeValue,
                            searchType
                          );
                        }}
                      />
                    </div>
                    <div className="mr-0-reversed mx-2">
                      <AutocompleteComponent
                        wrapperClasses="w-min-unset m-2 mr-1"
                        inputPlaceholder="select"
                        data={data.operationType}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        withoutSearchButton
                        selectedValues={selected.operationType || " "}
                        onOpen={() => {
                          if (
                            data.operationType &&
                            data.operationType.length === 0
                          )
                            getOperationType();
                        }}
                        labelValue={t(`${translationPath}Operation-type`)}
                        displayLabel={(option) => option.lookupItemName || ""}
                        chipsLabel={(option) => option.lookupItemName || ""}
                        multiple={false}
                        onChange={(_, newValue) => {
                          setSelected({ id: "operationType", value: newValue });
                          const operationTypeKey = "operation_type";
                          const operationTypeValue =
                            newValue?.lookupItemName || null;
                          const searchType = 1;

                          filterCriteriaChangeHandler(
                            operationTypeKey,
                            operationTypeValue,
                            searchType
                          );
                        }}
                      />
                    </div>
                    <div className="mr-0-reversed mx-2">
                      <AutocompleteComponent
                        idRef="OperationTypeRef"
                        labelValue={t(`${translationPath}listing-agent`)}
                        wrapperClasses="w-min-unset m-2 mr-1"
                        inputPlaceholder="select"
                        selectedValues={selected.listingAagent || ""}
                        data={data.listingAagent}
                        onOpen={() => {
                          if (
                            data.listingAagent &&
                            data.listingAagent.length === 0
                          )
                            getAllAgents();
                        }}
                        chipsLabel={(option) => option.agentName || ""}
                        displayLabel={(option) => option.agentName || ""}
                        multiple={false}
                        withoutSearchButton
                        onInputKeyUp={(event) => {
                          const { value } = event.target;
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getAllAgents(value);
                          }, 700);
                        }}
                        onChange={(_, newValue) => {
                          setSelected({ id: "listingAagent", value: newValue });
                          const listingAagentKey = "listing_agent";
                          const listingAagentValue =
                            newValue?.agentName || null;
                          const searchType = 1;

                          filterCriteriaChangeHandler(
                            listingAagentKey,
                            listingAagentValue,
                            searchType
                          );
                        }}
                      />
                    </div>
                    <div className="mr-0-reversed mx-2">
                      <AutocompleteComponent
                        isLoading={isloading.completionStatus}
                        idRef="OperationTypeRef"
                        labelValue={t(`${translationPath}completionStatus`)}
                        wrapperClasses="w-min-unset m-2 mr-1"
                        inputPlaceholder="select"
                        selectedValues={selected.completionStatus || " "}
                        data={data.completionStatus}
                        onOpen={() => {
                          if (
                            data.completionStatus &&
                            data.completionStatus.length === 0
                          )
                            getUnitTypeLookupsPropertyPlan();
                        }}
                        chipsLabel={(option) => option.lookupItemName || ""}
                        displayLabel={(option) => option.lookupItemName || ""}
                        multiple={false}
                        withoutSearchButton
                        onChange={(_, newValue) => {
                          setSelected({
                            id: "completionStatus",
                            value: newValue,
                          });
                          const completionStatusKey = "CompletionStatus";
                          const completionStatusValue =
                            newValue?.lookupItemName || null;
                          const searchType = 10;
                          filterCriteriaChangeHandler(
                            completionStatusKey,
                            completionStatusValue,
                            searchType
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <fieldset className="styled-fieldset d-flex mx-2">
                      <legend className="styled-legend">
                        {t(`${translationPath}price`)}
                      </legend>
                      <div className="w-50 m-1">
                        <Inputs
                          value={selected.priceFrom || ""}
                          idRef="priceFromRef"
                          labelValue={t(`${translationPath}from`)}
                          type={"number"}
                          withNumberFormat
                          onKeyUp={(e) => {
                            const { value } = e.target;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              const saleFromKey = "fromSellingPrice";
                              const saleFromValue =
                                value || value == 0
                                  ? value.replace(/,/g, "")
                                  : null;
                              const searchType = 1;
                              filterCriteriaChangeHandler(
                                saleFromKey,
                                saleFromValue,
                                searchType
                              );
                              setSelected({
                                id: "priceFrom",
                                value: value,
                              });
                            }, 600);
                          }}
                          min={0}
                        />
                      </div>
                      <div className="w-50 m-1">
                        <Inputs
                          idRef="pricetoRef"
                          value={selected.priceTo || ""}
                          labelValue={t(`${translationPath}to`)}
                          type={"number"}
                          withNumberFormat
                          onKeyUp={(e) => {
                            const { value } = e.target;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              const saletoeKey = "toSellingPrice";
                              const saletoValue =
                                value || value == 0
                                  ? value.replace(/,/g, "")
                                  : null;
                              const searchType = 1;

                              filterCriteriaChangeHandler(
                                saletoeKey,
                                saletoValue,
                                searchType
                              );
                              setSelected({
                                id: "priceTo",
                                value: value,
                              });
                            }, 600);
                          }}
                          min={0}
                        />
                      </div>
                    </fieldset>
                    <fieldset className="styled-fieldset d-flex mx-2">
                      <legend className="styled-legend">
                        {t(`${translationPath}Size`)}
                      </legend>
                      <div className="w-50 m-1">
                        <Inputs
                          idRef="sizeFromRef"
                          value={selected.sizeFrom || ""}
                          labelValue={t(`${translationPath}from`)}
                          type={"number"}
                          withNumberFormat
                          onKeyUp={(e) => {
                            const { value } = e.target;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              const sizefromKey = "fromSize";
                              const sizefromValue =
                                value || value == 0
                                  ? value.replace(/,/g, "")
                                  : null;
                              const searchType = 1;

                              filterCriteriaChangeHandler(
                                sizefromKey,
                                sizefromValue,
                                searchType
                              );
                              setSelected({
                                id: "sizeFrom",
                                value: value,
                              });
                            }, 600);
                          }}
                          min={0}
                        />
                      </div>
                      <div className="w-50 m-1">
                        <Inputs
                          idRef="sizeToRef"
                          value={selected.sizeTo || ""}
                          labelValue={t(`${translationPath}to`)}
                          type={"number"}
                          withNumberFormat
                          onKeyUp={(e) => {
                            const { value } = e.target;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              const sizetoeKey = "toSize";
                              const sizefromValue =
                                value || value == 0
                                  ? value.replace(/,/g, "")
                                  : null;
                              const searchType = 4;

                              filterCriteriaChangeHandler(
                                sizetoeKey,
                                sizefromValue,
                                searchType
                              );
                              setSelected({
                                id: "sizeTo",
                                value: value,
                              });
                            }, 600);
                          }}
                          min={0}
                        />
                      </div>
                    </fieldset>
                    <fieldset className="styled-fieldset d-flex mx-2">
                      <legend className="styled-legend">
                        {t(`${translationPath}Number-of-bedrooms`)}
                      </legend>
                      <div className="w-50 m-1">
                        <Inputs
                          idRef="number-beadromfromRef"
                          value={selected.numberBedroomFrom || ""}
                          labelValue={t(`${translationPath}from`)}
                          type={"number"}
                          withNumberFormat
                          onKeyUp={(e) => {
                            const { value } = e.target;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              const frombedRomFromKey = "fromBedroom";
                              const frombedRomValue =
                                value || value == 0
                                  ? value.replace(/,/g, "")
                                  : null;
                              const searchType = 3;

                              filterCriteriaChangeHandler(
                                frombedRomFromKey,
                                frombedRomValue,
                                searchType
                              );
                              setSelected({
                                id: "numberBedroomFrom",
                                value: value,
                              });
                            }, 600);
                          }}
                          min={0}
                        />
                      </div>
                      <div className="w-50 m-1">
                        <Inputs
                          idRef="number-beadromtoRef"
                          value={selected.numberBedroomTo || ""}
                          labelValue={t(`${translationPath}to`)}
                          type={"number"}
                          withNumberFormat
                          onKeyUp={(e) => {
                            const { value } = e.target;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              const toBedroomKey = "toBedroom";
                              const toBedRoomValue =
                                value || value == 0
                                  ? value.replace(/,/g, "")
                                  : null;
                              const searchType = 4;

                              filterCriteriaChangeHandler(
                                toBedroomKey,
                                toBedRoomValue,
                                searchType
                              );
                              setSelected({
                                id: "numberBedroomTo",
                                value: value,
                              });
                            }, 600);
                          }}
                          min={0}
                        />
                      </div>
                    </fieldset>
                  </div>
                  <div className="d-flex">
                    <div className="w-100 mr-2">
                      <fieldset className="styled-fieldset d-flex mx-2">
                        <legend className="styled-legend">
                          {t(`${translationPath}location`)}
                        </legend>
                        <LocationFieldsComponent
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          isClearFiltersClicked={isClearFiltersClicked}
                          onChangeHandlers={{
                            countryHandler: (newValue) => {
                              const countryKey = "country";
                              const countryValue =
                                (newValue && newValue?.lookupItemName) || null;
                              const searchType = 1;

                              filterCriteriaChangeHandler(
                                countryKey,
                                countryValue,
                                searchType
                              );
                              setSelected({
                                id: "country",
                                value: newValue,
                              });
                            },
                            cityHandler: (newValue) => {
                              const cityKey = "city";
                              const cityValue =
                                (newValue && newValue?.lookupItemName) || null;
                              const searchType = 1;

                              filterCriteriaChangeHandler(
                                cityKey,
                                cityValue,
                                searchType
                              );
                              setSelected({
                                id: "city",
                                value: newValue,
                              });
                            },
                            districtHandler: (newValue) => {
                              const districtKey = "district";
                              const districtValue =
                                (newValue && newValue?.lookupItemName) || null;
                              const searchType = 1;

                              filterCriteriaChangeHandler(
                                districtKey,
                                districtValue,
                                searchType
                              );
                              setSelected({
                                id: "district",
                                value: newValue,
                              });
                            },
                            communityHandler: (newValue) => {
                              const communityKey = "community";
                              const communityValue =
                                (newValue && newValue?.lookupItemName) || null;
                              const searchType = 1;

                              filterCriteriaChangeHandler(
                                communityKey,
                                communityValue,
                                searchType
                              );
                              setSelected({
                                id: "community",
                                value: newValue,
                              });
                            },

                            subCommunityHandler: (newValue) => {
                              const subCommunityKey = "subCommunity";
                              const subCommunityValue =
                                (newValue && newValue?.lookupItemName) || null;
                              const searchType = 1;

                              filterCriteriaChangeHandler(
                                subCommunityKey,
                                subCommunityValue,
                                searchType
                              );
                              setSelected({
                                id: "subCommunity",
                                value: newValue,
                              });
                            },
                          }}
                        />
                      </fieldset>
                    </div>
                    {/* <div className='mr-2'>
                        <SelectComponet
                          data={[{ lookupItemId: true, lookupItemName: t('open') }, { lookupItemId: false, lookupItemName: t('close') }]}
                          defaultValue
                          emptyItem={{
                        value: null,
                        text: t('Activities-Status'),
                        isDisabled: false,
                        // isHiddenOnOpen: true,
                      }}
                          value={filter.isOpen}
                          className='px-2'
                          valueInput='lookupItemId'
                          textInput='lookupItemName'
                          wrapperClasses='w-auto'
                          idRef='Activities_Status'
                          onSelectChanged={changeActivitystatus}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      </div>
                      <div className='mr-2'>
                        <SelectComponet
                          data={(activtiesRate && activtiesRate) || []}
                          defaultValue={null}
                          emptyItem={{
                        value: null,
                        text: t('Select-Activities-rate'),
                        isDisabled: false,
                        // isHiddenOnOpen: true,
                      }}
                          value={filter.activityRate || null}
                          className='px-2'
                          valueInput='lookupItemId'
                          textInput='lookupItemName'
                          wrapperClasses='w-auto'
                          idRef='Select_Activities_Rate'
                          onSelectChanged={changeActivityRate}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      </div>
                      <div className='mr-2'>
                        <SelectComponet
                          data={(qaUsers && qaUsers) || []}
                          defaultValue={null}
                          emptyItem={{
                        value: null,
                        text: t('Select-QA-Users'),
                        isDisabled: false,
                        // isHiddenOnOpen: true,
                      }}
                          value={filter.userId || null}
                          onSelectChanged={changeQaUsers}
                          className='px-2'
                          valueInput='usersId'
                          textInput='fullName'
                          wrapperClasses='w-auto'
                          idRef='Select_QA_Users'
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      </div>
                    <div className='buttons mt-1'>
                        <ButtonBase
                          className='btns theme-solid reset'
                          onClick={() => {
                          setDateFilter({
                            startDate: null,
                            endDate: null,
                            key: 'selection',
                          });
                          setFilter(() => ({
                            pageIndex: 0,
                            pageSize: 25,
                            filterBy: null,
                            orderBy: null,
                            activityRate: null,
                            fromDate: null,
                            toDate: null,
                            userId: null,
                            search: '',
                            isOpen: null,
                            leadId: null,
                            contactName: null,
                            contactId: null,
                          }));
                          setState({
                            leadId: '',
                            contactName: '',
                            contactId: '',
                          });
                        }}
                        >
                          <span>{t(`${translationPath}reset`)}</span>
                        </ButtonBase>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="view-search-wrapper gap-3">
                <ViewTypes
                  onTypeChanged={onTypeChanged}
                  activeTypes={[
                    ViewTypesEnum.tableView.key,
                    ViewTypesEnum.cards.key,
                  ]}
                  className="mb-3"
                />
                <ButtonBase
                  onClick={onClearedAllFiltersClick}
                  id="onClearedAllFiltersref"
                  // disabled={isAllFormFieldsLoading}
                  className="btns theme-solid bg-danger clear-all-btn"
                >
                  <span className="mdi mdi-filter-remove m-1" />
                  {t(`${translationPath}clear-filters`)}
                </ButtonBase>
              </div>
            </div>
          </div>
          <div className="d-flex px-2">
            <span className="mx-2 mt-1">{t("select")}</span>
            <span className="separator-v s-primary s-reverse s-h-25px mt-1" />
            <span className="px-2 d-flex">
              <span className="texts-large mt-1">{t("order-by")}:</span>
              <div className="px-2">
                <SelectComponet
                  idRef="filterByRef"
                  data={[
                    { id: "CreationDate", filterBy: "created-date" },
                    // { id: "UpdateOn", filterBy: "Updated-date" },
                  ]}
                  value={selectedOrderBy.filterBy}
                  onSelectChanged={filterByChanged}
                  wrapperClasses="mb-3"
                  isRequired
                  valueInput="id"
                  textInput="filterBy"
                  emptyItem={{
                    value: null,
                    text: "select-filter-by",
                    isDisabled: false,
                    isHiddenOnOpen: false,
                  }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
              <div className="px-2">
                <SelectComponet
                  idRef="orderByRef"
                  data={[
                    { id: 1, orderBy: "ascending" },
                    { id: 2, orderBy: "descending" },
                  ]}
                  emptyItem={{
                    value: null,
                    text: "select-sort-by",
                    isDisabled: false,
                    isHiddenOnOpen: false,
                  }}
                  value={selectedOrderBy.orderBy}
                  onSelectChanged={orderByChanged}
                  wrapperClasses="mb-3"
                  isRequired
                  valueInput="id"
                  textInput="orderBy"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
              <div className="mt-1">
                <ButtonBase
                  className="btns theme-solid"
                  onClick={orderBySubmitted}
                  id="action_apply"
                  disabled={
                    !selectedOrderBy.filterBy || !selectedOrderBy.orderBy
                  }
                >
                  <span>{t(`${translationPath}apply`)}</span>
                </ButtonBase>
              </div>
              <div className="mt-1">
                <ButtonBase
                  onClick={() => {
                    (!activeSelectedAction &&
                      setActiveSelectedAction(!activeSelectedAction)) ||
                      (activeSelectedAction &&
                        SetisDialogOpenPauseShareUnitsComponent(true));
                  }}
                  disabled={
                    activeSelectedAction &&
                    checkedDetailed &&
                    checkedDetailed.length === 0
                  }
                  className="btns theme-solid bg-danger"
                >
                  {(!activeSelectedAction && (
                    <span>
                      <span class="mdi mdi-trash-can-outline"></span>{" "}
                      {t(`${translationPath}Disabled-fom-shere`)}
                    </span>
                  )) || <span>{t(`${translationPath}Remove-From-Shere`)}</span>}
                </ButtonBase>
              </div>
            </span>
            {SavedShareUnitCriteriaIdValue && (
              <div className="buttons mt-1">
                <ButtonBase
                  onClick={() => {
                    setSavedShareUnitCriteriaIdValue("");
                    GetAllShareUnitsSearchAPI();
                  }}
                  className="btns theme-solid bg-danger"
                >
                  <span>
                    {t(`${translationPath}Clear-filter-of-Favourite`)}
                  </span>
                </ButtonBase>
              </div>
            )}
            {activeSelectedAction && (
              <div className="buttons mt-1">
                <ButtonBase
                  onClick={() => {
                    setActiveSelectedAction(false);
                    setCheckedDetailed([]);
                  }}
                  className="btns theme-solid bg-danger"
                >
                  <span>{t(`${translationPath}Cansel-select`)}</span>
                </ButtonBase>
              </div>
            )}
            <div className="buttons mt-1">
              <ButtonBase
                onClick={() => {
                  (!activeSelectedAction &&
                    setActiveSelectedAction(!activeSelectedAction)) ||
                    (activeSelectedAction && setAddToFavouriteDialog(true));
                }}
                disabled={
                  activeSelectedAction &&
                  checkedDetailed &&
                  checkedDetailed.length === 0
                }
                className="btns theme-solid"
              >
                {(!activeSelectedAction && (
                  <span className="mdi mdi-vector-point ">
                    {" "}
                    &nbsp;{t(`${translationPath}Select-Favourite-ADD-DEL`)}{" "}
                  </span>
                )) || <span>{t(`${translationPath}add-to-Favourite`)}</span>}
              </ButtonBase>
            </div>
            {activeSelectedAction && SavedShareUnitCriteriaIdValue && (
              <div className="buttons mt-1">
                <ButtonBase
                  onClick={() => {
                    setIsDeleteDialogOpen(true);
                  }}
                  disabled={
                    activeSelectedAction &&
                    checkedDetailed &&
                    checkedDetailed.length === 0
                  }
                  className="btns theme-solid bg-danger"
                >
                  {(!activeSelectedAction && (
                    <span className="mdi mdi-vector-point ">
                      {" "}
                      &nbsp;{t(`${translationPath}Select-Favourite`)}{" "}
                    </span>
                  )) || (
                      <span>{t(`${translationPath}Remove-from-Favourite`)}</span>
                    )}
                </ButtonBase>
              </div>
            )}

            <div className="buttons mt-1">
              <ButtonBase
                onClick={() => {
                  setisOpenFavouriteShareUnitsDialog(true);
                }}
                className="btns theme-solid reset"
              >
                <span className="mdi mdi-heart-box-outline ml-1">
                  &nbsp;{t(`${translationPath}View-Favourite`)}
                </span>
              </ButtonBase>
            </div>
          </div>
        </div>
        <div className="w-100 px-3">
          {activeActionType === ViewTypesEnum.tableView.key && (
            <ShareUnitTableComponent
              filter={filter}
              data={ShareUnit.result || []}
              openFile={detailedCardSideActionClicked}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              setIsOpenReassign={setIsOpenReassign}
              IsFavourite={SavedShareUnitCriteriaIdValue}
              setSingalid={setSingalid}
              setIsDeleteDialogOpen={() => setIsDeleteDialogOpen(true)}
              setActiveItem={setActiveItem}
              activeItem={activeItem}
              getIsSelected={getIsSelectedCard}
              onSelectClicked={cardCheckboxClickedTabel}
              checkedDetailedCards={checkedDetailed}
              withCheckbox={activeSelectedAction}
              shareTableFilter={shareTableFilter}
              setOrderBy={setOrderBy}
              onFilterValuesChanged={onFilterValuesChanged}
              isClearFiltersClicked={isClearFiltersClicked}
              setIsClearFiltersClicked={setIsClearFiltersClicked}
            />
          )}
          {ShareUnit && ShareUnit.result && ShareUnit.result.length != 0 ? (
            activeActionType !== ViewTypesEnum.tableView.key && (
              <SharetUnitsCardsComponent
                data={detailsUnitsList}
                onCardClicked={onCardClick}
                IsFavourite={SavedShareUnitCriteriaIdValue}
                setSingalid={setSingalid}
                setIsDeleteDialogOpen={() => setIsDeleteDialogOpen(true)}
                onFooterActionsClicked={detailedCardSideActionClicked}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                activeCard={activeCard}
                from="shareunit"
                onCardCheckboxClick={cardCheckboxClicked}
                checkedDetailedCards={checkedDetailed}
                activeSelectedAction={activeSelectedAction}
                withCheckbox={activeSelectedAction}
                filterCriteria={filterCriteria}
              />
            )
          ) : (
            <NoContentComponent />
          )}
          {isOpenReassign && (
            <ShareUnitDialog
              isOpen={isOpenReassign}
              activeItem={activeItem && activeItem}
              setIsLoading={isLoadingReassign}
              activeCard={activeCard}
              commonExclusionsExpetitions="targetSystemName"
              commonNOTExclusionsExpetitions="sourceSystemName"
              setIsLoadingReassign={setIsLoadingReassign}
              onSave={() => {
                setActiveItem(null);
              }}
              parentTranslationPath={parentTranslationPath}
              translationPath=""
              onClose={() => {
                setIsOpenReassign(false);
                setActiveItem(null);
              }}
            />
          )}

          {isOpenFavouriteShareUnitsDialog && (
            <FavouriteShareUnitsDialog
              isOpen={isOpenFavouriteShareUnitsDialog}
              checkedDetailed={checkedDetailed && checkedDetailed}
              onSave={() => {
                setActiveItem(null);
              }}
              parentTranslationPath={parentTranslationPath}
              translationPath=""
              setFiltersavedShareUnitCriteriaId={
                setFiltersavedShareUnitCriteriaId
              }
              setSavedShareUnitCriteriaIdValue={
                setSavedShareUnitCriteriaIdValue
              }
              GetAllUnitsSearch={GetAllShareUnitsSearchAPI}
              closeHandler={() => {
                setisOpenFavouriteShareUnitsDialog(false);
                setActiveItem(null);
              }}
            />
          )}
          {AddToFavouriteDialog && (
            <AddToFavouriteShareUnitsDialog
              isOpen={AddToFavouriteDialog}
              checkedDetailed={checkedDetailed && checkedDetailed}
              reest={reest}
              onSave={() => {
                setActiveItem(null);
              }}
              parentTranslationPath={parentTranslationPath}
              translationPath=""
              closeHandler={() => {
                setAddToFavouriteDialog(false);
                setActiveItem(null);
              }}
            />
          )}
          <DialogComponent
            titleTextClasses={"release-confirm-text"}
            titleText=""
            saveText="confirm"
            saveType="button"
            maxWidth="sm"
            dialogContent={
              <div className="d-flex-column-center">
                <span className="mdi mdi-close-octagon c-danger mdi-48px" />
                <span>{t("Are-You-Sure-You-Want-To-Delete-Slected-Item")}</span>
                {checkedDetailed &&
                  checkedDetailed.map((item) => (
                    <div className="fw-bold">
                      {item.unitName || item.property_name || ""}
                    </div>
                  ))}
              </div>
            }
            saveClasses="btns theme-solid bg-danger w-100 mx-2 mb-2"
            isOpen={isDeleteDialogOpen}
            onSaveClicked={() =>
              DeleteSavedShareUnits(
                checkedDetailed,
                SavedShareUnitCriteriaIdValue,
                FiltersavedShareUnitCriteriaId,
                Singalid
              )
            }
            onCloseClicked={() => {
              setIsDeleteDialogOpen(false);
            }}
            onCancelClicked={() => {
              setIsDeleteDialogOpen(false);
            }}
          />
        </div>
        {isDialogOpenPauseShareUnitsComponent && (
          <PauseShareUnitsComponent
            data={checkedDetailed || []}
            isDialogOpen={isDialogOpenPauseShareUnitsComponent}
            setisDialogOpen={() => {
              SetisDialogOpenPauseShareUnitsComponent(false);
              setActiveItem(null);
            }}
            onsave={() => {
              GetAllShareUnitsSearchAPI();
              SetisDialogOpenPauseShareUnitsComponent(false);
            }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}
      </div>
    </div>
  );
};
