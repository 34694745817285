
import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Rating from "@material-ui/lab/Rating";
import PlusIcon from "../../../../../../assets/images/defaults/plus.svg";
import { AutocompleteComponent } from "../../../../../../Components";
import { GetLookupItemsByLookupTypeName } from "../../../../../../Services";
import { GeneralPropertyCriteriaTypesEnum } from "../../../../../../Enums";
import { showError } from "../../../../../../Helper";

export const UnitFinishing = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
  setSelectedList,
  selectedList,
  onChangeSelectedItem,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [allUnitFinishing, setAllUnitFinishing] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);

  const GetLookupItemsByLookupTypeNameAPI = useCallback(async () => {
    setIsLoading(true);
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "UnitsFinishing",
      pageIndex: 1,
      pageSize: 150,
    });
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      setAllUnitFinishing(
        res?.result.map((i) => ({
          lookupsId: i.lookupItemId,
          lookupItemName: i.lookupItemName,
        }))
      );
    } else setAllUnitFinishing([]);
    setIsLoading(false);
  });

  useEffect(() => {
    setSelectedValue([
      ...state.updatePropertyRatingLookupInfoDtos.filter(
        (x) => x.type === "UnitsFinishing"
      ),
    ]);

    setSelectedList((item) => ({
      ...item,
      UnitsFinishing: state.updatePropertyRatingLookupInfoDtos.filter(
        (x) => x.type === "UnitsFinishing"
      ),
    }));
  }, [state]);

  return (
    <>
      <div>
        <span className="fw-bold">
          {" "}
          {t(`${translationPath}unit-finishing`)}
        </span>
      </div>
      <div className="w-100  mt-2 mb-2 units-finishing">
        {selectedValue &&
          Array.isArray(selectedValue) &&
          selectedValue.map((item, index) => (
            <div
              className="d-flex d-flex-v-center tower-units-finishing mt-2 mb-2 b-bottom"
              key={`UnitsFinishingRef${index + 1}`}
            >
              <div className="mb-2">
                <AutocompleteComponent
                  idRef={`UnitsFinishing${index + 1}`}
                  multiple={false}
                  selectedValues={item}
                  data={allUnitFinishing}
                  displayLabel={(option) => option.lookupItemName || ""}
                  renderOption={(option) => option.lookupItemName || ""}
                  withoutSearchButton
                  isLoading={isLoading}
                  withLoader
                  isDisabled={item?.propertyRatingTowerUnitFinishId}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    if (
                      newValue &&
                      selectedValue.some(
                        (x) => x.lookupItemName === newValue.lookupItemName
                      )
                    ) {
                      showError(t(`${translationPath}this-unit-finishing-is-exists-select-another-unit-finishing`))
                      return;
                    } else if (!newValue) {
                      let newList = selectedValue ? [...selectedValue] : [];
                      newList[index].score = 0;
                      setSelectedValue([...newList]);
                    }
                  }}
                  inputPlaceholder={t(`${translationPath}select-units-finishing`)}
                  onOpen={() => {
                    if (
                      allUnitFinishing &&
                      allUnitFinishing.length === 0
                    )
                    GetLookupItemsByLookupTypeNameAPI();
                  }}
                />
              </div>

              <div className="px-2">
                <Rating
                  name={`RatingOwnershipType${index + 1}`}
                  max={10}
                  precision={1}
                  value={item.score}
                  disabled={!item.lookupsId}
                  onChange={(event, newValue) => {
                    onChangeSelectedItem(
                      newValue || 0,
                      index,
                      selectedValue && selectedValue,
                      "UnitsFinishing"
                    );
                  }}
                />
              </div>
            </div>
          ))}

        <div
          className="d-flex d-flex-v-center  mt-2 mb-2"
          onClick={() => {
            let newList = selectedValue ? [...selectedValue] : [];
            newList.push({
              lookupItemName: null,
              lookupsId: null,
              propertyRatingGeneralPropertyCriteriaScoreId: null,
              score: 0,
            });
            setSelectedValue([...newList]);
          }}
        >
          <img
            className="mr-2 ml-2"
            src={PlusIcon}
            alt={t(`${translationPath}permission-denied`)}
          />
          <span>{t(`${translationPath}add-another`)}</span>
        </div>
      </div>
    </>
  );
};
