import React from "react";
import { ViewTypesEnum } from "../../../../Enums";
import { DevelopersCards } from "../DevelopersViewComponent";
import { DevelopersTableComponent } from "../DevelopersUtilities";

export const DevelopersContentView = ({
  parentTranslationPath,
  translationPath,
  activeActionType,
  pathName,
  reloadData,
  checkedCards,
  setCheckedCards,
  checkedCardsIds,
  detailedCardActionClicked,
  developersTableFilter,
  onFilterValuesChanged,
  setCheckedCardsIds,
  setFilter,
  activeSelectedAction,
  filter,
  detailedCardSideActionClicked,
  closeSideMenu,
  onRowClicked,
  isFirst,
  detailsDevelopersList,
  activeCard,
  isExpanded,
  onCardClick,
  setActiveCard,
  dispatch,
  setOrderBy,
  isClearFiltersClicked,
  setIsClearFiltersClicked,
  displayOpenFileButton,
  formId,
  sideMenuIsOpenUpdate,
  loginResponse,
  advanceSearchBtn,
  cardCheckboxClicked ,
  setAdvanceSearchBtn , 
  allFormFields ,
  isAllFormFieldsLoading
}) => {
  return (
    <>
      {(activeActionType === ViewTypesEnum.tableView.key && (
        <>
          <DevelopersTableComponent
            filter={filter}
            pathName={pathName}
            reloadData={reloadData}
            checkedCards={checkedCards}
            setCheckedCards={setCheckedCards}
            checkedCardsIds={checkedCardsIds}
            onActionClicked={detailedCardActionClicked}
            developersTableFilter={developersTableFilter}
            onFilterValuesChanged={onFilterValuesChanged}
            setCheckedCardsIds={setCheckedCardsIds}
            setFilter={setFilter}
            detailsDevelopersList={detailsDevelopersList}
            activeSelectedAction={activeSelectedAction}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            setOrderBy={setOrderBy}
            isClearFiltersClicked={isClearFiltersClicked}
            setIsClearFiltersClicked={setIsClearFiltersClicked}
            displyOpenFileButton={displayOpenFileButton}
            formId={formId}
            setActiveCard={setActiveCard}
            sideMenuIsOpenUpdate={sideMenuIsOpenUpdate}
            loginResponse={loginResponse}
            detailedCardSideActionClicked={detailedCardSideActionClicked}
            closeSideMenu={closeSideMenu}
            onRowClick={onRowClicked}
            isFirst={isFirst}
            activeActionType={activeActionType}
            allFormFields={allFormFields}
            isAllFormFieldsLoading={isAllFormFieldsLoading}
          />
        </>
      )) ||
        (activeActionType === ViewTypesEnum.cards.key && (
          <>
            <div className="view-wrapers">
              <DevelopersCards
                data={detailsDevelopersList}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                activeCard={activeCard}
                isExpanded={isExpanded}
                onCardClicked={onCardClick}
                onFooterActionsClicked={detailedCardSideActionClicked}
                onActionClicked={detailedCardActionClicked}
                onCardCheckboxClick={cardCheckboxClicked}
                selectedCards={checkedCards}
                displyOpenFileButton
                activeAction={activeSelectedAction}
                setActiveCard={setActiveCard}
                reloadData={reloadData}
                setAdvanceSearchBtn={setAdvanceSearchBtn}
                loginResponse={loginResponse}
                formId={formId}
                advanceSearchBtn={advanceSearchBtn}
                closeSideMenu={closeSideMenu}
                displayOpenFileButton
                dispatch={dispatch}
              />
            </div>
          </>
        ))}
    </>
  );
};
