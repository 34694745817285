import React from 'react';
import { BillingPreview } from './Preview/BillingPreview';
import { BillingHistory } from './History/BillingHistory';
import { BillingPermissions } from '../../../Permissions';

export const tabsData = [
  {
    label: 'billing-preview',
    component: BillingPreview,
    permissionsList: Object.values(BillingPermissions),
    permissionsId: BillingPermissions.ViewPreview.permissionsId,
  },
  {
    label: 'billing-history',
    component: BillingHistory,
    permissionsList: Object.values(BillingPermissions),
    permissionsId: BillingPermissions.ViewHistory.permissionsId,
  },
];
