

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tables } from '../../../../../../../../../Components';
import { TableActions } from '../../../../../../../../../Enums';


export const PublishedUnitsPortalTable = ({ publishedUnits, parentTranslationPath, translationPath, tableActionClicked, onPageSizeChanged, onPageIndexChanged, filter, publishedUnit, unitMarketingId }) => {
  const { t } = useTranslation(parentTranslationPath);

  const getActionTableWithPermissions = () => {
    const list = [];
    if (unitMarketingId)
      list.push({
        enum: TableActions.editText.key,
        isDisabled: false,
        externalComponent: null,
      },
        {
          enum: TableActions.deleteText.key,
          isDisabled: false,
          externalComponent: null,
        });
    return list;
  };


  return (

    <div className='w-100 px-3 mt-2'>
      <Tables
        data={(publishedUnits.result) || []}
        className=''
        headerData={[
          {
            id: 1,
            label: 'listingAgent',
            component: (item) => (
              <span>{(item.portalAccountListingAgentDto && item.portalAccountListingAgentDto.fullName) || ''}</span>
            ),
          },
          {
            id: 2,
            label: 'branch',
            component: (item) => <span>{(item.branchPortalAccountDto && item.branchPortalAccountDto.branchName)}</span>,
          },
          {
            id: 3,
            label: 'portalAccount',
            component: (item) => (
              <span>{(item.portalAccountDto && item.portalAccountDto.portalAccountName)}</span>
            ),
          },
          {
            id: 4,
            label: 'portal',
            component: (item) => (
              <span>{(item.portalDto && item.portalDto.portalName)}</span>
            ),
          },
          {
            id: 5,
            input: 'isActive',
            label: t(`${translationPath}status`),
            cellClasses: 'py-0',
            component: (item, index) => (
              (item.isActive && <div className='globel-open-style' index={index}>{t(`${translationPath}active`)}</div>) || <div className='globel-closed-style' index={index}>{t(`${translationPath}inactive`)}</div>
            ),

          },
        ]}
        defaultActions={getActionTableWithPermissions()}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        itemsPerPage={filter.pageSize}
        activePage={filter.pageIndex}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        totalItems={publishedUnits.totalCount}
      />

    </div>
  )
};
