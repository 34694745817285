import * as signalR from "@microsoft/signalr";
import { config } from "../config/config";

const getSessionToken = () => {
  const sessionData = JSON.parse(localStorage.getItem("session"));
  return sessionData ? sessionData.token : "";
};

const url = "/WsFileManager/ImportNotificationHub";
export const ProcessStatusMethod = "ProcceseStatus";
export const ImportFileNotificationHub = (() => {
  let hubConnectionInstance = null;

  return () => {
    if (!hubConnectionInstance) {
      const token = getSessionToken();

      const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(
          `${config.server_address}${url}?authorization=${token}&source=${config.source}`,
          {
            transport: signalR.HttpTransportType.WebSockets,
            withCredentials: false,
          }
        )
        .configureLogging(signalR.LogLevel.None)
        //.withAutomaticReconnect()
        
        .build();

      hubConnection.start().catch((err) => {
        console.error(err.toString());
      });

      hubConnection.on("NotificationListener", () => {
      });

      hubConnectionInstance = hubConnection;
    }

    return hubConnectionInstance;
  };
})();

// Refactoring Done 2023
