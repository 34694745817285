const headerData = [
  {
    id: 1,
    label: 'Users',
    input: 'fullName',
  },
  {
    id: 2,
    label: 'Application',
    input: 'platformName',
  },
  {
    id: 3,
    label: 'Activation Date',
    input: 'activationDate',
    isDate: true

  },
  {
    id: 4,
    label: 'Last Activity Date',
    input: 'lastActivityDate',
    isDate: true
  },
];

export default headerData;
