import React from "react";

function CornerDownLeft({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M19.695 3.057a1.04 1.04 0 0 0-.567.459l-.108.184-.026 2.44c-.031 2.919-.072 3.557-.281 4.4-.281 1.135-1.271 2.321-2.413 2.892-.507.253-1.077.395-1.98.492-.265.028-1.797.052-4.16.063l-3.74.019 1.706-1.713c.938-.942 1.739-1.776 1.779-1.853.052-.099.074-.228.074-.44.001-.255-.015-.328-.107-.484a1.003 1.003 0 0 0-1.215-.451c-.179.059-.44.307-2.81 2.67-1.479 1.475-2.659 2.683-2.72 2.785-.091.152-.107.226-.107.48s.016.328.107.48c.135.226 5.243 5.327 5.428 5.419.455.229 1.046.045 1.312-.407.097-.165.113-.234.112-.492 0-.212-.022-.341-.074-.44-.04-.077-.841-.909-1.779-1.85L6.421 16l3.14-.001c4.126-.001 5.16-.048 6.216-.283 1.213-.27 2.229-.84 3.164-1.775.935-.935 1.505-1.951 1.775-3.164.22-.986.294-2.393.276-5.197L20.98 3.7l-.108-.184a1.005 1.005 0 0 0-1.177-.459"
        fill-rule="evenodd"
        fill={fill ?? "none"}
      />
    </svg>
  );
}

export default CornerDownLeft;
