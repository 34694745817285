import React from 'react';
import { Inputs, SelectComponet } from '../../../../Components';
import { getErrorByName } from '../../../../Helper';

export const UnitApprovalsPeriodInputComponent = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
  schema,
  setSelected,
  selected,
  isSubmitted,
}) => {
  const timeTypes = [
    {
      key: 1,
      value: 'minutes',
    },
    {
      key: 2,
      value: 'hours',
    },
    {
      key: 3,
      value: 'days',
    },
  ];
  return (
    <Inputs
      idRef='ExpiredPeriodRef'
      labelValue='expired-Period'
      labelClasses='Requierd-Color'
      isSubmitted={isSubmitted}
      value={state.expirationPeriod ?? 0}
      isWithError
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      min={0}
      helperText={getErrorByName(schema, 'expirationPeriod').message}
      error={getErrorByName(schema, 'expirationPeriod').error}
      onInputChanged={(event) => {
        let { value } = event?.target;
        setState({
          expirationPeriod: !isNaN(+value) ? +value : 0,
          durationType: state.durationType ?? 1,
        });
        setSelected({ id: 'expirationPeriod', value: value ?? {} });
      }}
      endAdornment={
        <SelectComponet
          idRef='ExpiredPeriodTimeTypeRef'
          data={timeTypes}
          value={state.durationType ?? 1}
          valueInput='key'
          textInput='value'
          onSelectChanged={(value) => {
            setState({
              durationType: +value,
              expirationPeriod: state?.expirationPeriod,
            });
          }}
          wrapperClasses='over-input-select w-auto'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          translationPathForData={translationPath}
        />
      }
    />
  );
};
