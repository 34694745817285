import moment from 'moment';

export function ListingAndRentListingDateRule(item, itemList) {
  if (item && item.field && item.field.id === 'listing_date') {
    const i = itemList.indexOf(
      itemList.find((f) => (f.field.id.toLowerCase() === 'listing_date'))
    );
    if (i !== -1)
      itemList[i].data.maxDate = moment(new Date());
  } else
    if (item && item.field && (item.field.id === 'rent_listing-date' || item.field.id === 'rent_listing_date')) {
      const i = itemList.indexOf(
        itemList.find((f) => (f.field.id.toLowerCase() === 'rent_listing-date') || ((f.field.id.toLowerCase() === 'rent_listing_date')))
      );
      if (i !== -1)
        itemList[i].data.maxDate = moment(new Date());
    }
}
