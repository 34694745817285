import { config } from '../../config';
import { HttpServices } from '../../Helper';

export const CreateOrUpdateCampaignConfig = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/Campaigns/CreateOrUpdateCampaignConfig`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllCampaignsConfig = async ({pageIndex, pageSize}) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Campaigns/GetAllCampaignsConfig/${pageIndex}/${pageSize}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllCampaignDepartments = async ({search}) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Campaigns/GetAllDepartments?search=${search}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllCampaignForms = async ({search}) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Campaigns/GetAllCampaignForms?search=${search}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
