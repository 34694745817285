import React, { useState, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Rating from "@material-ui/lab/Rating";
import { Spinner, Inputs } from "../../../../../Components";
import { GetGeographicCriteriaWithScores } from "../../../../../Services";
import { ReactComponent as Minimize } from "../../../../../assets/images/defaults/minimize.svg";

export const CityGeographicCriteria = ({
  parentTranslationPath,
  translationPath,
  cityGeographicCriteriaWithScores,
  setCityGeographicCriteriaWithScores,
  districtGeographicCriteriaWithScores,
  setDistrictGeographicCriteriaWithScores,
  setActiveCity,
  setActiveCommunity,
  setActiveDistrict,
  propertyRatingLocationTypeScoreId,
  activeCity,
  setActiveSubCommunity,
  displayAllReletedLocation,
  setDisplayAllReletedLocation,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const [isLoading, setIsLoading] = useState(false);
  const searchTimer = useRef(null);
  const [displayAllCityDistrictList, setDisplayAllCityDistrictList] = useState(
    []
  );
  const [filterCitiesByName, setFilterCitiesByName] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const GetGeographicCriteriaWithScoresAPI = useCallback(async (search) => {
    setIsLoading(true);
    const res = await GetGeographicCriteriaWithScores({
      parentLookupItemId: search,
    });
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      const districts =
        (res &&
          res.locationTypeScores &&
          res.locationTypeScores.length > 0 &&
          res.locationTypeScores[0] &&
          res.locationTypeScores[0].geographicCriteriaScores &&
          res.locationTypeScores[0].geographicCriteriaScores.map((el) => ({
            ...el,
            propertyRatingLocationTypeScoreId:
              propertyRatingLocationTypeScoreId,
          }))) ||
        [];
      let newList = [...districtGeographicCriteriaWithScores, ...districts];

      let filterArray =
        newList &&
        newList.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.lookupsId === value.lookupsId)
        );
      setDistrictGeographicCriteriaWithScores([...filterArray]);
    } else {
      setDistrictGeographicCriteriaWithScores([]);
    }
    setIsLoading(false);
  });

  useEffect(() => {
    if (activeCity && activeCity.lookupsId) {
      setActiveDistrict(null);
      setActiveSubCommunity(null);
      setActiveCommunity(null);
      GetGeographicCriteriaWithScoresAPI(activeCity.lookupsId);
      setDisplayAllReletedLocation((e) => ({
        ...e,
        displayAllDistrictCommunities: null,
        displayAllCommunitySubCommunities: null,
      }));
    }
  }, [activeCity]);

  useEffect(() => {
    if (
      displayAllReletedLocation &&
      displayAllReletedLocation.displayAllCityDistricts
    ) {
      const selectedCity = cityGeographicCriteriaWithScores.filter(
        (item) =>
          item.lookupsId ===
          displayAllReletedLocation.displayAllCityDistricts.id
      );
      setDisplayAllCityDistrictList([...selectedCity]);
    } else setDisplayAllCityDistrictList([]);
  }, [displayAllReletedLocation, cityGeographicCriteriaWithScores]);

  useEffect(() => {
    if (searchValue !== "") {
      const newList =
        cityGeographicCriteriaWithScores &&
        cityGeographicCriteriaWithScores.filter((a) =>
          a.lookupItemName
            .toLocaleLowerCase()
            .includes(searchValue && searchValue.toLocaleLowerCase())
        );
        const newList2 =
        displayAllCityDistrictList &&
        displayAllCityDistrictList.filter((a) =>
          a.lookupItemName
            .toLocaleLowerCase()
            .includes(searchValue && searchValue.toLocaleLowerCase())
        );
      setDisplayAllCityDistrictList([...newList2]);
      setFilterCitiesByName(newList);
    } else if (searchValue === "")
      {
        setFilterCitiesByName([]);
        setDisplayAllCityDistrictList([]);
        const displayAllCityDistrict =
                        displayAllReletedLocation &&
                        displayAllReletedLocation.displayAllCityDistricts
                          ? null
                          : null;
                      if (setDisplayAllReletedLocation)
                        setDisplayAllReletedLocation((e) => ({
                          ...e,
                          displayAllCityDistricts: displayAllCityDistrict,
                        }));

      }
  }, [searchValue]);

  return (
    <>
      <Spinner isActive={isLoading} isAbsolute />
      <div className="">
        <span className="fw-bold"> {t(`${translationPath}City`)} </span>
      </div>
      <div className="w-100 sores-part">
        <div className="w-100">
        <div className="search-filter">
        <div className="">{""}</div>
          <div className="">
          <Inputs
            idRef="CityOptionsFilterInputRef"
            wrapperClasses="mt-2"
            inputPlaceholder={`${t("search-for-city")}`}
            onInputBlur={(event) => {
              event.stopPropagation();
            }}
            onKeyUp={(event) => {
              if (searchTimer.current) clearTimeout(searchTimer.current);
              const newValue = event.target.value;
              searchTimer.current = setTimeout(() => {
                setSearchValue(newValue);
              }, 200);
            }}
          />
          </div>
        </div>
        </div>
        <div className="w-100 mt-2 mb-2 city">
        {searchValue === "" &&
          displayAllReletedLocation &&
          !displayAllReletedLocation.displayAllCityDistricts &&
          cityGeographicCriteriaWithScores &&
          cityGeographicCriteriaWithScores.map((item, index) => (
            <div
              className="b-bottom section"
              key={`CityCardItemRef${item.lookupsId + 1}`}
              onClick={() => setActiveCity(item)}
            >
                {" "}
                <div className="address">
                  <span className="fw-bold">{item.lookupItemName}</span>
                </div>
                <div className="address">
                  <Rating
                    size="small"
                    name={`CityItemRef${item.lookupTypeName}-${item.lookupsId}}`}
                    max={10}
                    value={item.score}
                    precision={1}
                    onChange={(event, newValue) => {
                      GetGeographicCriteriaWithScoresAPI(item.lookupsId);
                      let updatelist = [...cityGeographicCriteriaWithScores];
                      const itemIndex = updatelist.indexOf(
                        updatelist.find((f) => f.lookupsId === item.lookupsId)
                      );
                      if (itemIndex !== -1)
                        updatelist[itemIndex] = {
                          ...updatelist[itemIndex],
                          score: newValue || 0,
                        };

                      setCityGeographicCriteriaWithScores([...updatelist]);
                    }}
                  />
                  <span
                   className="px-2"
                    onClick={() => {
                      const displayAllCityDistrict =
                        displayAllReletedLocation &&
                        displayAllReletedLocation.displayAllCityDistricts
                          ? null
                          : { id: item.lookupsId, isSelected: true };
                      if (setDisplayAllReletedLocation)
                        setDisplayAllReletedLocation((e) => ({
                          ...e,
                          displayAllCityDistricts: displayAllCityDistrict,
                        }));
                    }}
                  >
                    <Minimize />
                  </span>
                </div>
              </div>
          ))}
        {searchValue === "" &&
          displayAllReletedLocation &&
          displayAllReletedLocation.displayAllCityDistricts &&
          displayAllReletedLocation.displayAllCityDistricts.isSelected &&
          displayAllCityDistrictList.map((item, index) => (
            <div
              className="b-bottom section"
              key={`CityCardItemRef${item.lookupsId + 1}`}
              onClick={() => setActiveCity(item)}
            >
             
                <div className="address">
                  <span className="fw-bold">{item.lookupItemName}</span>
                </div>
                <div className="address">
                  <Rating
                    size="small"
                    name={`CityItemRef${item.lookupTypeName}-${item.lookupsId}}`}
                    max={10}
                    value={item.score}
                    precision={1}
                    onChange={(event, newValue) => {
                      GetGeographicCriteriaWithScoresAPI(item.lookupsId);
                      let updatelist = [...cityGeographicCriteriaWithScores];
                      const itemIndex = updatelist.indexOf(
                        updatelist.find((f) => f.lookupsId === item.lookupsId)
                      );
                      if (itemIndex !== -1)
                        updatelist[itemIndex] = {
                          ...updatelist[itemIndex],
                          score: newValue || 0,
                        };
                      setCityGeographicCriteriaWithScores([...updatelist]);
                    }}
                  />
                  <span
                    className="px-2"
                    onClick={() => {
                      setDisplayAllReletedLocation((e) => ({
                        ...e,
                        displayAllCityDistricts: null,
                      }));
                    }}
                  >
                    <Minimize />
                  </span>
                </div>
            </div>
          ))}

        {searchValue &&
          searchValue !== "" &&
          displayAllReletedLocation &&
          !displayAllReletedLocation.displayAllCityDistricts &&
          filterCitiesByName &&
          filterCitiesByName.map((item, index) => (
            <div
              className="b-bottom section"
              key={`filter2NameCardItemRef${item.lookupsId + 1}`}
              onClick={() => setActiveCity(item)}
            >
                <div className="address">
                  <span className="fw-bold">{item.lookupItemName}</span>
                </div>
                <div className="address">
                  <Rating
                    size="small"
                    name={`F2CityItemRef${item.lookupTypeName}-${item.lookupsId}}`}
                    max={10}
                    value={item.score}
                    precision={1}
                    onChange={(event, newValue) => {
                      GetGeographicCriteriaWithScoresAPI(item.lookupsId);
                      let updatelist = [...cityGeographicCriteriaWithScores];
                      const itemIndex = updatelist.indexOf(
                        updatelist.find((f) => f.lookupsId === item.lookupsId)
                      );
                      if (itemIndex !== -1)
                        updatelist[itemIndex] = {
                          ...updatelist[itemIndex],
                          score: newValue || 0,
                        };
                      let updatelist2 = [...filterCitiesByName];
                      const itemIndex2 = updatelist2.indexOf(
                        updatelist2.find((f) => f.lookupsId === item.lookupsId)
                      );
                      if (itemIndex2 !== -1)
                        updatelist2[itemIndex2] = {
                          ...updatelist2[itemIndex2],
                          score: newValue || 0,
                        };

                      setFilterCitiesByName([...updatelist2]);
                      setCityGeographicCriteriaWithScores([...updatelist]);
                    }}
                  />
                  <span
                    className="px-2"
                    onClick={() => {
                      const displayAllCityDistrict =
                        displayAllReletedLocation &&
                        displayAllReletedLocation.displayAllCityDistricts
                          ? null
                          : { id: item.lookupsId, isSelected: true };
                      if (setDisplayAllReletedLocation)
                        setDisplayAllReletedLocation((e) => ({
                          ...e,
                          displayAllCityDistricts: displayAllCityDistrict,
                        }));
                    }}
                  >
                    <Minimize />
                  </span>
                </div>
            </div>
          ))}
          {searchValue &&
          searchValue !== "" &&
          displayAllReletedLocation &&
          displayAllReletedLocation.displayAllCityDistricts && displayAllReletedLocation.displayAllCityDistricts.isSelected &&
          displayAllCityDistrictList &&
          displayAllCityDistrictList.map((item, index) => (
    
            <div
              className="b-bottom section"
              key={`filter2NameCardItemRef${1}`}
              onClick={() => setActiveCity(item)}
            >
                <div className="address">
                  <span className="fw-bold">{item.lookupItemName}</span>
                </div>
                <div className="address">
                  <Rating
                    size="small"
                    name={`F3CityItemRef${item.lookupTypeName}-${item.lookupsId}}`}
                    max={10}
                    value={item.score}
                    precision={1}
                    onChange={(event, newValue) => {
                      GetGeographicCriteriaWithScoresAPI(item.lookupsId);
                      let updatelist = [...cityGeographicCriteriaWithScores];
                      const itemIndex = updatelist.indexOf(
                        updatelist.find((f) => f.lookupsId === item.lookupsId)
                      );
                      if (itemIndex !== -1)
                        updatelist[itemIndex] = {
                          ...updatelist[itemIndex],
                          score: newValue || 0,
                        };
                      let updatelist2 = [...filterCitiesByName];
                      const itemIndex2 = updatelist2.indexOf(
                        updatelist2.find((f) => f.lookupsId === item.lookupsId)
                      );
                      if (itemIndex2 !== -1)
                        updatelist2[itemIndex2] = {
                          ...updatelist2[itemIndex2],
                          score: newValue || 0,
                        };

                      setFilterCitiesByName([...updatelist2]);
                      setCityGeographicCriteriaWithScores([...updatelist]);
                    }}
                  />
                  <span
                    className="px-2"
                    onClick={() => {
                      const displayAllCityDistrict =
                        displayAllReletedLocation &&
                        displayAllReletedLocation.displayAllCityDistricts
                          ? null
                          : { id: item.lookupsId, isSelected: true };
                      if (setDisplayAllReletedLocation)
                        setDisplayAllReletedLocation((e) => ({
                          ...e,
                          displayAllCityDistricts: displayAllCityDistrict,
                        }));
                    }}
                  >
                    <Minimize />
                  </span>
                </div>
              </div>    
          ))}
          </div>
      </div>
    </>
  );
};
