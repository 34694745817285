import React, { useCallback, useEffect, useReducer, useState } from "react"
import { Dialog, DialogTitle, DialogContent, DialogActions, ButtonBase } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { DatePickerComponent, Inputs, Spinner } from "../../../../../../../Components"
import { GetParams, setLoading, showError, showSuccess, showWarn } from "../../../../../../../Helper"
import moment from "moment"
import { UpdateSourceFile } from "../../../../../../../Services"
export const EditSourceFileDialog = ({
    onOpen,
    activeItem,
    parentTranslationPath,
    onClose,
    onSave
}) => {
    const { t } = useTranslation(parentTranslationPath)
    const [isLoading, setIsLoading] = useState(false);
    const reducer = useCallback((state, action) => {
        if (action.id !== "edit") return { ...state, [action.id]: action.value };
        return {
            ...action.value,
        };
    }, []);

    const [filter, setFilter] = useReducer(reducer, {
        contactId: +GetParams('id'),
        sourceFileName: "",
        sourceFileYear: "",
        communitySourceFile: "",
        propertySourceFile: "",
        sourceBy: "",
        sourceFileDate: "",
    });
    const [selected, setSelected] = useReducer(reducer, {
        sourceFileName: "",
        sourceFileYear: "",
        communitySourceFile: "",
        propertySourceFile: "",
        sourceBy: "",
        sourceFileDate: "",
    })


    useEffect(() => {
       
            setSelected({
                id: "edit",
                value: {
                    sourceFileName: activeItem?.sourceFileName || "",
                    sourceFileYear: activeItem?.sourceFileYear || "",
                    communitySourceFile: activeItem?.communitySourceFile || "",
                    propertySourceFile: activeItem?.propertySourceFile || "",
                    sourceBy: activeItem?.sourceBy || "",
                    sourceFileDate: activeItem?.sourceFileDate || "",
                },
            });
            setFilter({
                id: "edit",
                value: {
                    contactId: +GetParams('id'),
                    sourceFileName: activeItem?.sourceFileName || "",
                    sourceFileYear: activeItem?.sourceFileYear || "",
                    communitySourceFile: activeItem?.communitySourceFile || "",
                    propertySourceFile: activeItem?.propertySourceFile || "",
                    sourceBy: activeItem?.sourceBy || "",
                    sourceFileDate: activeItem?.sourceFileDate || "",
                },
            });
    }, [])
  
    const saveUpdate = useCallback(async() => {
        setLoading(true)
        const res= await UpdateSourceFile({ sourceFileId:activeItem.sourceFileId , body:filter })
        if (!(res && res.status && res.status !== 200)) {
            showSuccess(t(`updated-contact-file-successfully`));
            onSave()
            onClose()
        }else{
            showError(t(`failed-to-updated-contact-file`));
        }
        setLoading(false)
    })
    return (
        <>
            <Dialog
                onClose={onClose}
                open={onOpen}
                maxWidth='lg'
                fullWidth
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t('edit-contact-file-dialog')}</DialogTitle>
                <DialogContent>
                    <Spinner isActive={isLoading} />
                    <div className='dialog-content-wrapper d-flex gap-2'>
                        <div className="w-100 ml-2">
                            <div className='dialog-content-item'>
                                <div className="mb-2">
                                    <span className="b-400">
                                        {t(`contact-file-name`)}
                                    </span>
                                </div>
                                <div className="mt-2 mb-1 pt-1">
                                    <Inputs
                                        // isDisabled={}
                                        value={selected.sourceFileName || ""}
                                        idRef="SourceFilenameRef"
                                        parentTranslationPath={parentTranslationPath}
                                        // translationPath={translationPath}
                                        onInputChanged={(event) => {
                                            const SourceFilename = event?.target?.value;
                                            setSelected({ id: "sourceFileName", value: SourceFilename })
                                            setFilter({ id: "sourceFileName", value: SourceFilename })
                                      
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='dialog-content-item'>
                                <div className="mb-2">
                                    <span className="b-400">
                                        {t(`contact-file-year`)}
                                    </span>
                                </div>
                                <div className="mt-2 mb-1 pt-1">
                                    <Inputs
                                        value={selected.sourceFileYear || ""}
                                        idRef="SourceFileYearRef"
                                        parentTranslationPath={parentTranslationPath}
                                        // translationPath={translationPath}
                                        isWithError
                                        onInputChanged={(event) => {
                                            const SourceFileYear = event?.target?.value;
                                            setSelected({ id: "sourceFileYear", value: SourceFileYear })
                                            setFilter({ id: "sourceFileYear", value: SourceFileYear })
                                           
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className=" w-100 ml-2">
                            <div className='dialog-content-item'>
                                <div className="mb-2">
                                    <span className="b-400">
                                        {t(`contact-file-date`)}
                                    </span>
                                </div>
                                <div className=" mt-2 mb-1 pt-1">
                                    <DatePickerComponent
                                        // isDisabled={}
                                        idRef="SourceFselectedileDateRef"
                                        placeholder="DD/MM/YYYY"
                                        value={selected.sourceFileDate || null}
                                        onDateChanged={(newValue) => {
                                            const sourceFileDate = (newValue && moment(newValue).format("YYYY-MM-DDTHH:mm:ss")) || null
                                            setSelected({ id: "sourceFileDate", value: sourceFileDate })
                                            setFilter({ id: "sourceFileDate", value: sourceFileDate })
                                           
                                        }
                                        }

                                    />
                                </div>
                            </div>
                            <div className='dialog-content-item mt-3'>
                                <div className="mb-2">
                                    <span className="b-400">
                                        {t(`contact-by`)}
                                    </span>
                                </div>
                                <div className=" mt-2 mb-1 pt-1">
                                    <Inputs
                                        // isDisabled={}
                                        value={selected.sourceBy || ""}
                                        idRef="SourceByRef"
                                        parentTranslationPath={parentTranslationPath}
                                        // translationPath={translationPath}
                                        isWithError
                                        onInputChanged={(event) => {
                                            const SourceBy = event?.target?.value;

                                            setSelected({ id: "sourceBy", value: SourceBy })
                                            setFilter({ id: "sourceBy", value: SourceBy })
                                           
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-100 ml-2">
                            <div className='dialog-content-item'>
                                <div className="mb-2">
                                    <span className="b-400">
                                        {t(`community-contact-file`)}
                                    </span>
                                </div>
                                <div className=" mt-2 mb-1 pt-1">
                                    <Inputs
                                        // isDisabled={}
                                        value={selected.communitySourceFile || ""}
                                        idRef="CommunitySourceFileRef"
                                        parentTranslationPath={parentTranslationPath}
                                        // translationPath={translationPath}
                                        isWithError
                                        onInputChanged={(event) => {
                                            const CommunitySourceFile = event?.target?.value;
                                            setSelected({ id: "communitySourceFile", value: CommunitySourceFile })
                                            setFilter({ id: "communitySourceFile", value: CommunitySourceFile })
                                          
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='dialog-content-item'>
                                <div className="mb-2">
                                    <span className="b-400">
                                        {t(`property-contact-file`)}
                                    </span>
                                </div>
                                <div className=" mt-2 mb-1 pt-1">
                                    <Inputs
                                        // isDisabled={}
                                        value={selected.propertySourceFile || ""}
                                        idRef="PropertySourceFileRef"
                                        parentTranslationPath={parentTranslationPath}
                                        // translationPath={translationPath}
                                        isWithError
                                        onInputChanged={(event) => {
                                            const PropertySourceFile = event?.target?.value;
                                            setSelected({ id: "propertySourceFile", value: PropertySourceFile })
                                            setFilter({ id: "propertySourceFile", value: PropertySourceFile })
                                          
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className="unit-template-action-btns">
                        <ButtonBase
                            className="MuiButtonBase-root btns bg-cancel"
                            onClick={onClose}
                        >
                            {t(`cancel`)}
                        </ButtonBase>
                        <ButtonBase
                            className="MuiButtonBase-root btns theme-solid "
                            onClick={saveUpdate}
                        >
                            {t(`Edit`)}
                        </ButtonBase>
                    </div>
                </DialogActions>

            </Dialog>
        </>
    )
}
