import { GetBranchInfoById } from "../Services";
import { GetApplicationUserById } from "../Services/userServices";

export const GetLeadsReferredByData = async () => {
  const session = JSON.parse(localStorage.getItem("session"));

  const userData =
    (session && (await GetApplicationUserById(session?.userId))) || null;
  if (!(userData && userData.status && userData.status !== 200)) {
    if (userData && userData.branchId) {
      const branchData = await GetBranchInfoById(userData.branchId);
      if (!(branchData && branchData.status && branchData.status !== 200)) {
        if (branchData && branchData.leadsReferredBy) {
          const leadsReferredByData = await GetApplicationUserById(
            branchData.leadsReferredBy
          );
          if (
            !(
              leadsReferredByData &&
              leadsReferredByData.status &&
              leadsReferredByData.status !== 200
            )
          ) {
            if (leadsReferredByData)
              return {
                id:  leadsReferredByData.id  || leadsReferredByData.userId ,
                name: leadsReferredByData.fullName,
                fullName: leadsReferredByData.fullName , 
                phone: leadsReferredByData.phoneNumber,
                email: leadsReferredByData.email,
                userName: leadsReferredByData.userName,
                branch: leadsReferredByData.branch,
              };
          }
        }
      }
    }
  }
  return (
    {

        id: session?.userId,
        name: session?.fullName,
        phone: session?.phoneNumber,
        email: session?.email,
        userName: session?.userName,
        fullName :session.fullName , 
        branch: session?.branch || session?.branchId,
      }
     );
};
