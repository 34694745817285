import i18next from 'i18next';
import React from 'react';

const headerData = [
  {
    id: 1,
    label: 'portal',
    input: 'portal',
  },
  {
    id: 2,
    label: 'agency',
    input: 'agency',
  },
  {
    id: 3,
    label: 'listing-date',
    input: 'listing_Date',
    isDate: true,
  },
  {
    id: 4,
    label: 'listing-price',
    input: 'listing_Price',
    isNumber: true,
  },
  {
    id: 7,
    label: 'link',
    input: 'advertisementLink',
    component: (row) => (
      <a href={row.advertisementLink} target='_blank'>
        {i18next.t('Visit', { ns: 'UnitsProfileManagementView' })}
      </a>
    ),
  },
];

export default headerData;
