import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
  return {
    contactOverViewCard: {
      padding: '24px',
    },
    unitCardTitleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${theme.palette.border.secondary}`,
      paddingBottom: '20px',
    },
    titleContainer: {
      display: 'flex',
      gap: '8px ',
      alignItems: 'center',
    },
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '24px',
      flexDirection: 'column',
    },
  };
});
