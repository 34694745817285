import React from 'react';
import { SwitchRoute } from '../../../Components/Route/SwitchRoute';
import { LeadOwnerActivityRoutes  } from '../../../routes';

export const LeadOwnerActivityLayout = () => {

  return <SwitchRoute routes={LeadOwnerActivityRoutes} />;
  
};

