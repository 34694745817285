import React, { useCallback, useEffect, useReducer, useState } from "react";
import { GetAllShareDocumentLog } from "../../../Services";
import { Spinner, Tables } from "../../../Components";
import ShareDocumentLogHeader from "./sharedDocumentLogHeaderData";
import "./SharedDocumentLog.scss";
import SharedDocumentsLogFilters from "./SharedDocumentsLogFilters";

const parentTranslationPath = "SharedDocumentLogView";
const translationPath = "";

export const SharedDocumentLogView = () => {
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [sharedDocumentData, setSharedDocumentData] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const initialFilter = {
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
    orderBy: 0,
    filterBy: "CreatedOn",
    documentSource: null,
    documentCategory: null,
    documentName: null,
    senderName: null,
    sendVia: null,
    sharedTime: null,
    sharedTimeFrom: null,
    sharedTimeTo: null,
    key: "selection",
    sharedBy: null,
    receiverName: null,
    scopeId: null,
  };

  const [filter, setFilter] = useReducer(reducer,initialFilter );

  const getAllShareDocumentLog = async () => {
    setIsLoading(true);
    const res = await GetAllShareDocumentLog({
      documentSource: filter.documentSource,
      documentCategory: filter.documentCategory,
      documentName: filter.documentName,
      senderName: filter.senderName,
      sendVia: filter.sendVia,
      receiverName: filter.receiverName,
      sharedTimeFrom: filter.startDate,
      sharedTimeTo: filter.endDate,
      pageIndex: filter.pageIndex + 1,
      pageSize: filter?.pageSize || 25,
      filterBy: filter.filterBy,
      orderBy: filter.orderBy,
      sharedBy: filter.sharedBy,
      scopeId: filter.scopeId,
    });
    if (!(res && res.status && res.status !== 200)) {
      setSharedDocumentData(res);
    } else setSharedDocumentData([]);
    setIsLoading(false);
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter({
      id: "pageIndex",
      value: pageIndex,
    });
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter({
      id: "edit",
      value: {
        ...filter,
        pageIndex: 0,
        pageSize: pageSize,
      },
    });
  };

  useEffect(() => {
    getAllShareDocumentLog();
  }, [filter]);
  useEffect(() => {
    if (sortBy)
      setFilter({
        id: "edit",
        value: {
          ...filter,
          filterBy: sortBy.filterBy,
          orderBy: sortBy.orderBy,
        },
      });
  }, [sortBy]);

  return (
    <div className="mt-4">
      <Spinner isAbsolute isActive={isLoading} />
      <div>
        <SharedDocumentsLogFilters
          filter={filter}
          setFilter={setFilter}
          initialFilter={initialFilter}
          setIsLoading={setIsLoading}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      </div>

      <Tables
        data={sharedDocumentData.result || []}
        headerData={ShareDocumentLogHeader || []}
        defaultActions={[]}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
        itemsPerPage={filter.pageSize}
        activePage={filter.pageIndex}
        totalItems={sharedDocumentData.totalCount}
        setSortBy={setSortBy}
      />
    </div>
  );
};
