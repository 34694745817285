export const LoadableImageEnum = {
  div: {
    key: 'div', // must be unique
  },
  image: {
    key: 'image', // must be unique
  },
  video: {
    key: 'video', // must be unique
  },
};
