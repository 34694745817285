export const LeadOwnerActivityPermissions = 
{
  AddNewActivity :{
    permissionsId: "c7a7c58c-db62-4009-8ac2-23f60104ef65",
    permissionsName: "Add new activity",
    description: null,
    componentsId: null,
    components: null
  },

  ViewAndSearchInLeadOwnerActivities : {
    permissionsId: "1580d478-0536-4d93-9f29-98f4c80d2942",
    permissionsName: "View and search in Lead Owner Activities",
    description: null,
    componentsId: null,
    components: null
  },

 ViewActivityDetails : {
    permissionsId: "3833129b-fc77-4d65-9e99-aae74e2229e1",
    permissionsName: "View Activity details",
    description: null,
    componentsId: null,
    components: null
  },
  EditActivity :{
    permissionsId: "02a7d523-de1e-49fa-999a-c65e201b7d3e",
    permissionsName: "Edit Activity",
    description: null,
    componentsId: null,
    components: null
  },
   
};