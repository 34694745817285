import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { showError, showSuccess, GetParams } from '../../../../../../../Helper';
import { DialogComponent, Spinner } from '../../../../../../../Components';
import { DeletePropertyVideosCategory, DeleteUnitVideosCategory } from '../../../../../../../Services';

const DeleteVideoDialog = ({
  isOpen,
  onCloseClicked,
  reloadData,
  parentTranslationPath,
  translationPath,
  categoryId,
  deleteType
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const deleteHandler = async () => {
    setIsLoading(true);
    const res = ((deleteType === 'property') && await DeletePropertyVideosCategory(categoryId, GetParams('id')) || await DeleteUnitVideosCategory(categoryId, GetParams('id')))
    if (!(res && res.status && res.status !== 200)) {
      reloadData(GetParams('id'));
      showSuccess(t(`${translationPath}categry-deleted-successfully`));
    } else {
      const errorMessage = (res && res.data && res.data.Message).split(': ')[1]
      showError(t(`${translationPath}${errorMessage}`));
    }
    setIsLoading(false);
    onCloseClicked();
  };
  return (
    <DialogComponent
      titleText='confirm-message'
      saveText='confirm'
      saveType='button'
      maxWidth='sm'
      dialogContent={(
        <div className='d-flex-column-center'>
          <Spinner isActive={isLoading} isAbsolute />
          <span className='mdi mdi-close-octagon c-danger mdi-48px' />
          <span>{`${t(`${translationPath}album-delete-description`)}`}</span>
        </div>
      )}
      saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
      isOpen={isOpen}
      onSaveClicked={deleteHandler}
      onCloseClicked={onCloseClicked}
      onCancelClicked={onCloseClicked}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};
export { DeleteVideoDialog };
DeleteVideoDialog.propTypes = {
  activeItem: PropTypes.instanceOf(Object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCloseClicked: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
