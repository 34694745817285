import { makeStyles } from "@material-ui/core/styles";
import { useIsAr } from "../../../Hooks";

export default makeStyles((theme) => ({
  modalContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 11,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  text: {
    fontSize: "18px",
    fontWeight: 600,
  },
  modalPaper: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    maxWidth: "600px",
    width: "600px",
    padding: "24px",
    borderRadius: "12px",
    background: theme.palette.background.paper,
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  },
  actions: {
    background: theme.palette.background.paper,
    paddingTop: "16px",
    alignItems: "center",
    gap: "12px",
    display: "flex",
    justifyContent: "flex-end",
  },
  inputField: {
    borderRadius: "8px",
    background: theme.palette.background.paper,

    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      border: `1px solid ${theme.palette.border.primary}`,
    },

    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      border: `1px solid ${theme.palette.border.primary}`,
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      boxShadow:
        "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(140, 115, 75, 0.24)", // selected value
      border: `1px solid ${theme.palette.border.brand}`,
    },
  },
}));
