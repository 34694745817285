import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Tables } from '../../../TablesComponent/Tables';
import { GetParams, setLoading } from '../../../../Helper';
import {
  GetAllDFMTransactionForRotationSchema,
} from '../../../../Services/AuditTrailServices/AuditTrailServices';
import { PaginationComponent } from '../../../PaginationComponent/PaginationComponent';
import { Spinner } from '../../../SpinnerComponent/Spinner';
import { GetAllFormFieldsByFormId } from '../../../../Services';
import { FormsIdsEnum, RequestTypeEnum } from '../../../../Enums';

export const RotationSchemaHistoryComponent = ({
  parentTranslationPath,
  translationPath,
  typeId,
  rotationSchemeId , 
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState({
    result: [],
    totalCount: 0,
  });
  const [changeFields, setChangeFields] = useState([]);
  const [hideUpdatedKeysFromChangsList  ,setHideUpdatedKeysFromChangsList]  =  useState(
     [
     {key : 'updateOn'} ,
     {key:'updatedBy'} ,
     {key:'createdOn'} ,
     {key:'createdBy'} , 
     {key :'rotationSchemeLeadDistributionType'}  , 
     {key : 'isOverrideLeadCapacitySettingEnabled'}
     ]
    );

  const activeItem = useSelector((state) => state.ActiveItemReducer);
  const [formsContent, setFormsContent] = useState([]);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
  });
  const pathName = window.location.pathname
    .split('/home/')[1]
    .split('/view')[0]
    .split('/')[0];
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  const getAllDFMTransaction = useCallback(async () => {
    setIsLoading(true);
    let  result = await GetAllDFMTransactionForRotationSchema(0 ,filter.pageSize ,rotationSchemeId );
    if (!(result && result.status && result.status !== 200)) {
     setTransactions({
        result: result && result.result,
        totalCount: result && result.totalCount,
      });
} else setTransactions({ result: [], totalCount: 0 });
    setIsLoading(false);
  }, [filter, pathName]);


  useEffect(() => {
    getAllDFMTransaction();
  }, [getAllDFMTransaction]);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
    setChangeFields([]);
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    setChangeFields([]);
  };

  const objectIfIsArray = (list) => 
    list &&
    list.length &&  list.map((item, index) => (
      <span>
        {
          (item  && item.SubCommunity && item.SubCommunity.lookupItemName)  || 
          (item  && item.Language && item.Language.lookupItemName) ||
          (item  && item.Country && item.Country.lookupItemName) ||
          (item  &&  item.City && item.City.lookupItemName) ||
          (item && item.Property && item.Property.propertyName ) || 
          (item  && item.Community && item.Community.lookupItemName) ||
          (item  && item.District && item.District.lookupItemName) ||
          (item  && item.SubCommunity && item.SubCommunity.lookupItemName) ||
          (item && item.UnitType && item.UnitType.lookupItemName) ||
          (item &&  item.startValue && ( '{' + item.startValue + ' , ' + item.endValue +' }')) || 
           (item && item.ContactClass && item.ContactClass.lookupItemName) ||
           (item && item.leadClassName) ||
           (item && item.MethodOfContact && item.MethodOfContact.lookupItemName) ||
           (item && item.MediaDetails && item.MediaDetails.lookupItemName) ||
           (item && item.Developer && item.Developer.full_name) ||
           (item && item.PropertyCampaign && item.PropertyCampaign.name) ||
           (item && item.Users && item.Users.fullName)  ||
           (item   && item.Branch  && item.Branch.branchName) ||
           (item && item.teamName)  
           || '' 
        }
        {list.length - 1 != index && <span> , </span>}
      </span>
    ));

  const rotationCriteriaFieldChangeMapper = useCallback(
    (itemValue) => {
      
      setLoading(true);
       let rotationChanges = [] ;  
       let beforeChangeArrObj = itemValue &&  itemValue.responseBefore &&  Object.entries(itemValue.responseBefore).map(([key, value]) => ({key,value}));
       let afterChangeArrObj =itemValue &&  itemValue.responseAfter &&  Object.entries(itemValue.responseAfter).map(([key, value]) => ({key,value}));

       beforeChangeArrObj.forEach(element => 
        {
          afterChangeArrObj.forEach(element2 => 
            {  
              
              if(!(hideUpdatedKeysFromChangsList.some(k=> k.key === element.key)) &&  element.key === element2.key  && (JSON.stringify(element.value)) !== ((JSON.stringify(element2.value))))
              rotationChanges.push({fieldName : element.key , changedFrom : element.value , changedTo : element2.value })
            });
       });


       setChangeFields(() => (rotationChanges.length > 0 && rotationChanges));
       setLoading(false);
    },
     [pathName]
  );


  const findRequestType = (requestType) => {
    const requestTypeName = RequestTypeEnum.find((item) => item.key === requestType);

    if (requestTypeName)
        return requestTypeName.value;
    return 'Read';
  };

  return (
    <div className='history-component-content-wrapper w-100 mt-2'>
      <div className='history-table-conent-wrapper history-dialog-wrapper d-flex-column-center w-100 transaction-history-wrapper'>
        <Spinner isActive={isLoading} />
        <div className='w-100 history-table-header'>
          <span>{t(`${translationPath}date-and-time-viewed`)}</span>
          <span>{t(`${translationPath}user`)}</span>
          <span>{t(`${translationPath}type`)}</span>
        </div>
        {transactions &&
          transactions.result &&
          transactions.result.map((item, index) => (
            <Accordion
              key={`${index + 1}-history`}
              className={`expand-history-icon ${
                index % 2 === 0 ? 'is-gray' : ''
              }`}
              expanded={
                expanded === item.dfMTransactionId &&
                (item.requestType && findRequestType(item.requestType)) === 'Update'
              }
              onChange={handleChange(item.dfMTransactionId)}
            >
              <AccordionSummary
                onClick={() =>
                  item.responseAfter && rotationCriteriaFieldChangeMapper(item)}
              >
                <div
                  className={`history-table-content w-100 ${
                    index % 2 === 0 ? 'is-gray' : ''
                  }`}
                >
                  <div
                    className={`history-expand-icon ${
                      (item.requestType && findRequestType(item.requestType)) === 'Update' ? '' : 'is-gray'
                    } `}
                  >
                    <span
                      className={`mdi mdi-chevron-${
                        expanded === item.dfMTransactionId &&
                        (item.requestType && findRequestType(item.requestType)) === 'Update' ?
                          'up' :
                          'down'
                      }`}
                    />
                  </div>
                  <span>
                    {(item.createdOn &&
                      moment(item.createdOn).format('DD/MM/YYYY - hh:mm A')) ||
                      'N/A'}
                  </span>
                  <span>
                    {(item.createdByName && item.createdByName) || 'N/A'}
                  </span>
                  <span>
                    {(item.requestType && findRequestType(item.requestType)) || 'N/A'}
                  </span>
                </div>
              </AccordionSummary>
              {item.responseAfter &&
                changeFields &&
                changeFields.length > 0 && (
                  <AccordionDetails>
                    <Tables
                      data={changeFields || []}
                      headerData={[
                        {
                          id: 1,
                          label: 'field-name',
                          input: 'fieldName',
                        },
                        {
                          id: 2,
                          label: 'changed-from',
                          component: (el) => (
                            <span>
                              {(el.changedFrom &&
                                (typeof el.changedFrom === 'string' || typeof el.changedFrom === 'number') &&
                                el.changedFrom) || 
                                (typeof el.changedFrom === 'object' && el.changedFrom !== null ? (Array.isArray(el.changedFrom) ?
                                el.changedFrom.length &&  objectIfIsArray(el.changedFrom) :
                                JSON.stringify(el.changedFrom)) : ''
                                ) ||( el.changedFrom !== null  &&  el.changedFrom !==  undefined &&  ( typeof el.changedFrom === 'boolean'  && el.changedFrom === true ? 'Yes'  : ((Array.isArray(el.changedFrom) &&  !el.changedFrom.length )  ?  '':  'No' )))
                              }
                            </span>
                          ),
                        },
                        {
                          id: 3,
                          label: 'changed-to',
                          component: (el) => (
                            <span>
                              {(el.changedTo &&
                                (typeof el.changedTo === 'string' || typeof el.changedTo === 'number') &&
                                el.changedTo) || 
                                (typeof el.changedTo === 'object' && el.changedTo !== null ? (Array.isArray(el.changedTo) ?
                                el.changedTo.length &&  objectIfIsArray(el.changedTo) :
                                JSON.stringify(el.changedFrom)) : ''
                                ) ||( el.changedTo !== null  &&  el.changedTo !==  undefined &&  ( typeof el.changedTo === 'boolean'  && el.changedTo === true ? 'Yes'  : ((Array.isArray(el.changedTo) &&  !el.changedTo.length )  ?  '':  'No' )))
                              }
                            </span>
                          ),
                        },
                      ]}
                      activePage={0}
                      itemsPerPage={changeFields.length || 0}
                      defaultActions={[]}
                      translationPath={translationPath}
                      parentTranslationPath={parentTranslationPath}
                      totalItems={changeFields.length || 0}
                      insideForm
                    />
                  </AccordionDetails>
                )}
            </Accordion>
          ))}
      </div>
      <div className='pagination-history-wrapper'>
        <PaginationComponent
          pageIndex={0}
          pageSize={filter.pageSize}
          totalCount={transactions.totalCount || 0}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      </div>
    </div>
  );
};

RotationSchemaHistoryComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
