
import { ActivityLeadOwner } from '../../Views/Home' ;

export const LeadOwnerActivityRoutes = [
    {
        path: '/view',
        name: 'ActivitiesView:activities',
        component: ActivityLeadOwner,
        layout: '/home/lead-owner-activity',
        default: true,
        isRoute: true,
        authorize: true,
        roles: [],
        isDisabled: false,
        isExact: true,
        breadcrumbs: [
          {
            name: 'ActivitiesView:activities',
            isDisabled: true,
            route: 'home/lead-owner-activity/view',
            groupName: 'lead-owner',
          },
        ],
    }
  ];
