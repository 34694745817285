import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { GetParams, showError, showSuccess } from '../../../../../../../Helper';
import { DialogComponent, Spinner } from '../../../../../../../Components';
import {
    CreatePropertyImage,
    GetAllPropertyImageLocationCategoryByPropertyId,
    UpdatePropertyImage,
    GetAllPropertyVideosLocationCategoryByPropertyId
} from '../../../../../../../Services';
import { VideosGalleryCardComponent } from '../../../../../ImagesGallery/Sections/VideosGalleryComponent/VideosGalleryCardComponent/VideosGalleryCardComponent';
import { UnitProfileImagesCardActionEnum } from '../../../../../../../Enums';
import { DialogsPropertiesLocationimages } from '../Dialogs/DialogsPropertiesLocationimages';

export const LocationVideo = ({ parentTranslationPath, translationPath, HideEdit, canEditImage }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [response, setresponse] = useState([]);
    const { t } = useTranslation(parentTranslationPath);
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const property = true;
    const [DialogComponentSize, setDialogComponentSize] = useState('sm');
    const [state, setstate] = useState({});
    const GetAllPropertyVideoLocationCategory = useCallback(async (propertyId) => {
        setIsLoading(true);
        const result = await GetAllPropertyVideosLocationCategoryByPropertyId(propertyId);


        if (!(result && result.status && result.status !== 200)) setresponse(result || []);
        else setresponse([]);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        const propertyId = +GetParams('id');
        if (propertyId !== null) GetAllPropertyVideoLocationCategory(propertyId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const AddPropertiesLocationImage = useCallback(async () => {
        setIsLoading(true);
        const res =
            (activeItem && (await UpdatePropertyImage(state))) || (await CreatePropertyImage(state));
        if (!(res && res.status && res.status !== 200)) {
            showSuccess(t(`${translationPath}CreatePropertyImage-successfully`));
            setIsOpen(false);
            GetAllPropertyVideoLocationCategory(+GetParams('id'));
        } else showError(t(`${translationPath}Create-PropertyImage-failed`));
        setIsLoading(false);
    }, [GetAllPropertyVideoLocationCategory, activeItem, state, t, translationPath]);
    return (
        <div className='property-imgaes-wrapers'>
            <Spinner isActive={isLoading} isAbsolute />
            <div className='property-cards'>
                <div className='childs-wrapper'>
                    <div
                        className={
                            response.length === 0 ?
                                'section px-2 d-flex-center mt-3 mb-3 ' :
                                'section px-2  mt-3 mb-3'
                        }
                    >
                    </div>
                    {response.length === 0 ? (
                        <div className='EmptyPage' />
                    ) : (
                        <VideosGalleryCardComponent
                            hideIcon={true}
                            hidePhotosTooltip
                            parentTranslationPath={parentTranslationPath}
                            translationPath={translationPath}
                            data={response}
                            WithUnitDetails
                            reloadData={GetAllPropertyVideoLocationCategory}
                            Display={UnitProfileImagesCardActionEnum.Hide}
                            propertyUploader={true}
                        />
                    )}
                </div>
                <DialogComponent
                    titleText={(activeItem && 'edit-image') || 'Add-new-image'}
                    saveText='save'
                    saveType='button'
                    maxWidth={DialogComponentSize}
                    disableBackdropClick
                    saveIsDisabled={!state.categoryId || !state.files.length >= 1}
                    dialogContent={(
                        <>
                            <DialogsPropertiesLocationimages
                                parentTranslationPath={parentTranslationPath}
                                translationPath={translationPath}
                                activeItemcard={activeItem}
                                size={(itemsize) => setDialogComponentSize(itemsize)}
                                data={(data) => setstate(data)}
                            />
                        </>
                    )}
                    saveClasses='btns theme-solid  w-100 mx-2 mb-2'
                    isOpen={isOpen}
                    onSaveClicked={() => {
                        AddPropertiesLocationImage();
                        setActiveItem(null);
                    }}
                    onCloseClicked={() => {
                        setIsOpen(false);
                        setActiveItem(null);
                    }}
                    onCancelClicked={() => {
                        setIsOpen(false);
                        setActiveItem(null);
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                />
            </div>
        </div>
    );
};

LocationVideo.propTypes = {
    HideEdit: PropTypes.bool,
    parentTranslationPath: PropTypes.string.isRequired,
    translationPath: PropTypes.string.isRequired,
    canEditImage: PropTypes.bool,
};
LocationVideo.defaultProps = {
    HideEdit: true,
    canEditImage: false,
};
