import React from 'react';
import { DialogComponent } from '../../../../../Components';
import '../UserApprovals.scss';
import { useTranslation } from 'react-i18next';
import { CopyToClipboardComponents } from '../../../../../ReusableComponents/UtilityComponents';
import { ButtonBase } from '@material-ui/core';
import { ClickableUnitId } from './pendingRequestsHeaderData';

export const PreviewDialog = ({
  translationPath,
  parentTranslationPath,
  setPreviewDialogOpen,
  previewDialogOpen,
  viewDetailsRequest,
  isOpenChanged,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const allowedFields = [
    'rent_price_fees.rentPerMonth',
    'rent_price_fees.rentPerYear',
    'sale_type.lookupItemName',
    'property_name.name',
    'Status.status',
    'unit_number',
    'bedrooms',
    'bathrooms',
    'selling_price_agency_fee.basePrice',
    'selling_price_agency_fee.sellingPrice',
    'selling_price_agency_fee.discount',
    'unit_type.lookupItemName',
    'builtup_area_sqft',
    'builtup_area_sqm',
  ];

  const filteredChangeHistory = viewDetailsRequest?.changeHistory?.filter(
    (filed) => allowedFields.includes(filed.fieldName)
  );
  return (
    <>
      <DialogComponent
        isOpen={previewDialogOpen}
        titleText={'request-details'}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        maxWidth={'sm'}
        onCloseClicked={isOpenChanged}
        dialogContent={
          <div className='dialog-content'>
            <div className='detail-row'>
              <span className='detail-label'>
                {t(`${translationPath}request-id`)}
              </span>
              <span className='detail-value'>
                {viewDetailsRequest?.unitRequestManagementId}
              </span>
            </div>
            <div className='detail-row'>
              <span className='detail-label'>
                {' '}
                {t(`${translationPath}request-date`)}
              </span>
              <span className='detail-value'>
                {new Date(viewDetailsRequest?.requestDate).toLocaleString()}
              </span>
            </div>
            <div className='detail-row'>
              <span className='detail-label'>
                {t(`${translationPath}type-of-request`)}
              </span>
              <span className='detail-value'>
                {viewDetailsRequest?.typeOfRequestName}
              </span>
            </div>
            <div className='detail-row'>
              <span className='detail-label'>
                {t(`${translationPath}unit-id`)}
              </span>
              <ButtonBase
                onClick={() => {
                  ClickableUnitId(viewDetailsRequest);
                }}
              >
                <div class='icon-container mx-1'>
                  <span class='mdi mdi-open-in-new c-primary'></span>
                </div>
                <CopyToClipboardComponents
                  data={viewDetailsRequest?.unitId}
                  childrenData={viewDetailsRequest?.unitId}
                  labelClaass='detail-value'
                />
              </ButtonBase>
            </div>
            <div className='detail-row'>
              <span className='detail-label'>
                {t(`${translationPath}comments`)}
              </span>
              <span className='detail-value'>
                {viewDetailsRequest?.comments}
              </span>
            </div>
            <div className='detail-row'>
              <span className='detail-label'>
                {t(`${translationPath}request-status`)}
              </span>
              <span className='detail-value'>
                {viewDetailsRequest?.requestStatusName}
              </span>
            </div>
            <div className='detail-row'>
              <span className='detail-label'>
                {t(`${translationPath}requester-name`)}
              </span>
              <span className='detail-value'>
                {viewDetailsRequest?.requesterName || 'N/A'}
              </span>
            </div>
            <div className='detail-row'>
              <span className='detail-label'>
                {t(`${translationPath}approver-names`)}
              </span>
              <span className='detail-value'>
                {viewDetailsRequest?.approverNames?.length > 0
                  ? viewDetailsRequest?.approverNames
                      .map((approver) => approver.approverName)
                      .join(', ')
                  : 'N/A'}
              </span>
            </div>

            {filteredChangeHistory?.length > 0 ? (
              <div className='change-history'>
                <h3 className='change-history-title'>
                  {t(`${translationPath}change-history`)}
                </h3>
                {filteredChangeHistory?.map((change, index) => (
                  <div key={index} className='change-row'>
                    <span className='change-field'>
                      {t(
                        `${translationPath}${change?.fieldName.replace(
                          '.',
                          '-'
                        )}`
                      ) || 'N/A'}
                    </span>
                    <span className='change-from'>
                      {(() => {
                        let changeFromValue = '';
                        if (change.changeFrom === '10')
                          changeFromValue = 'Draft';
                        else if (change.changeFrom === '1')
                          changeFromValue = 'Available';
                        else changeFromValue = change.changeFrom;
                        return changeFromValue || 'N/A';
                      })()}
                    </span>
                    <span className='change-to'>
                      {(() => {
                        let changeToValue = '';
                        if (change.changeTo === '10') changeToValue = 'Draft';
                        else if (change.changeTo === '1')
                          changeToValue = 'Available';
                        else changeToValue = change.changeTo;
                        return changeToValue || 'N/A';
                      })()}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              ''
            )}
          </div>
        }
      />
    </>
  );
};
