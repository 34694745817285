import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { GlobalHistory } from "../../../../Helper";
import { CopyToClipboardComponents } from "../../../../ReusableComponents/UtilityComponents";
import { GetlookupTypeItems } from "../../../../Services";

const redirectToInquiryClicked = (item) => {
  if (item?.inquiryTypeId === 1) {
    GlobalHistory.push(
      `/home/zero-matching-sale/zero-matching-sale-profile-edit?formType=${44}&id=${
        item && item.inquiryId
      }&activeFormType=${1}`
    );
  } else if (item?.inquiryTypeId === 2) {
    GlobalHistory.push(
      `/home/zero-matching-lease/zero-matching-lease-profile-edit?formType=${47}&id=${
        item && item.inquiryId
      }&activeFormType=${2}`
    );
  } else if (item?.inquiryTypeId === 3) {
    GlobalHistory.push(
      `/home/listing-shortage/listing-shortage-profile-edit?formType=${45}&id=${
        item && item.inquiryId
      }&activeFormType=${1}`
    );
  } else if (item?.inquiryTypeId === 4) {
    GlobalHistory.push(
      `/home/listing-shortage/listing-shortage-profile-edit?formType=${45}&id=${
        item && item.inquiryId
      }&activeFormType=${2}`
    );
  }
};

export const InquiryLogHeadData = async () => {
  const res = await GetlookupTypeItems({
    lookupTypeId: 3187,
    pageIndex: 0,
    pageSize: 150,
  });

  const templates = res.result ?? [];

  function findReasonByCancelId(item) {
    const res = templates.find(
      (obj) => obj?.lookupItemId === parseInt(item?.cancelReason)
    );
    return res?.lookupItemName;
  }

  return [
    {
      id: 1,
      label: "inquiry-id",
      isHiddenFilter: true,
      isSortable: false,
      component: (item) => (
        <a
          className="c-primary mx-4 px-4"
          style={{ cursor: "pointer" }}
          onClick={(e) => redirectToInquiryClicked(item)}
        >
          <CopyToClipboardComponents
            data={item.inquiryId}
            childrenData={item.inquiryId}
          />
        </a>
      ),
    },
    {
      id: 2,
      label: "inquiry-status",
      isHiddenFilter: true,
      isSortable: false,
      component: (item) => <p>{item.inquiryStatus}</p>,
    },
    {
      id: 3,
      label: "status-date",
      isHiddenFilter: true,
      isSortable: false,
      component: (item) =>
        item && item.statusDate
          ? moment(item?.statusDate).format("DD/MM/YYYY - hh:mm A")
          : null,
    },
    {
      id: 4,
      label: "number-of-units-assigned",
      isHiddenFilter: true,
      isSortable: false,
      component: (item) => <p>{item.unitCount}</p>,
    },
    {
      id: 5,
      label: "assign-date",
      isHiddenFilter: true,
      isSortable: false,
      component: (item) =>
        item && item.assignDate
          ? moment(item?.assignDate).format("DD/MM/YYYY - hh:mm A")
          : null,
    },
    {
      id: 8,
      label: "inquiry-created-date",
      isHiddenFilter: true,
      isSortable: false,
      component: (item) =>
        item && item?.inquiryCreatedDate
          ? moment(item?.inquiryCreatedDate).format("DD/MM/YYYY - hh:mm A")
          : null,
    },
    {
      id: 9,
      label: "inquiry-created-by",
      isHiddenFilter: true,
      isSortable: false,
      component: (item) => <p>{item.inquiryCreatedBy}</p>,
    },
    {
      id: 10,
      label: "lost",
      isHiddenFilter: true,
      isSortable: false,
      component: (item) => (
        <span>{item?.lost === 1 ? "Yes" : item?.lost === 0 ? "No" : null}</span>
      ),
    },
    {
      id: 11,
      label: "lost-date",
      isHiddenFilter: true,
      isSortable: false,
      component: (item) =>
        item && item?.lostDate
          ? moment(item?.lostDate).format("DD/MM/YYYY - hh:mm A")
          : null,
    },
    {
      id: 12,
      label: "next-assign",
      isHiddenFilter: true,
      isSortable: false,
      component: (item) => <p>{item.nextAssign}</p>,
    },
    {
      id: 15,
      label: "cancel-by",
      isHiddenFilter: true,
      isSortable: false,
      component: (item) => <p>{item.cancelBy}</p>,
    },
    {
      id: 16,
      label: "cancel-reason",
      isHiddenFilter: true,
      isSortable: false,
      component: (item) => <p>{findReasonByCancelId(item)}</p>,
    },
    {
      id: 17,
      label: "cancel-remarks",
      input: "cancelRemarks",
      isHiddenFilter: true,
      isSortable: false,
      component: (item) => <p>{item.cancelRemarks}</p>,
    },
    {
      id: 18,
      label: "cancellation-date",
      isHiddenFilter: true,
      isSortable: false,
      component: (item) =>
        item && item?.cancellationDate
          ? moment(item?.cancellationDate).format("DD/MM/YYYY - hh:mm A")
          : null,
    },
    {
      id: 19,
      label: "completed-by",
      isHiddenFilter: true,
      isSortable: false,
      component: (item) => <p>{item.completedBy}</p>,
    },
    {
      id: 20,
      label: "completion-date",
      isHiddenFilter: true,
      isSortable: false,
      component: (item) =>
        item && item?.completionDate
          ? moment(item?.completionDate).format("DD/MM/YYYY - hh:mm A")
          : null,
    },
  ];
};
