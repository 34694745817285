import React from 'react';
import { ButtonBase } from '@material-ui/core';

const PushTOlink = (item) => {
  window.open(
    `/home/Contacts-CRM/contact-profile-edit?formType=${item.formTypeId}&id=${item.contactId}`
  );
};

export const DuplicatedContactsHeaderData = [
  {
    id: 1,
    label: 'Company Name',
    input: 'companyName',
  },
  {
    id: 2,
    label: 'First Name',
    input: 'firstName',
  },
  {
    id: 3,
    label: 'Last Name',
    input: 'lastName',
  },
  {
    id: 4,
    label: 'Contact ID',
    component: (item) => (
      <>
        <ButtonBase
          onClick={() => {
            PushTOlink(item);
          }}
        >
          <div class='icon-container mx-1'>
            <span class='mdi mdi-open-in-new c-primary'></span>
          </div>
          {item.contactId}
        </ButtonBase>
      </>
    ),
  },
  {
    id: 5,
    label: 'Mobile',
    input: 'mobile',
  },
  {
    id: 6,
    label: 'Title',
    input: 'title',
  },
  {
    id: 7,
    label: 'Gender',
    input: 'gender',
  },
  {
    id: 8,
    label: 'Nationality',
    input: 'nationality',
  },
  {
    id: 9,
    label: 'Language',
    input: 'language',
  },
  {
    id: 10,
    label: 'Contact Class',
    input: 'contactClass',
  },
  {
    id: 11,
    label: 'Multi Property Owner',
    input: 'multiPropertyOwner',
  },
  {
    id: 12,
    label: 'Created On',
    input: 'createdOn',
    isDate: true,
    dateFormat: 'DD/MM/YYYY',
  },
  {
    id: 13,
    label: 'Created By',
    input: 'createdBy',
  },
  {
    id: 14,
    label: 'Created By Name',
    input: 'createdByName',
  },
];
