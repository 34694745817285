import React, { useEffect, useRef, useState } from "react";
import { Select, Box, MenuItem, Icon } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";

import { useOutsideClick } from "../../../Hooks";

// Styles
import useStyles from "./styles";

function CustomSelect(props) {
  const {
    options,
    defaultValue,
    IconComponent,
    variant,
    placeholder,
    style,
    onValueChange,
    emptyItem,
    disabled,
    getOptionLabel,
    getOptionValue,
    isMulti,
    Children,
    showDropdownIcon,
    isClearable,
    customSelectClasses,
    ...restProps
  } = props;

  const initialSelectedValue = isMulti
    ? defaultValue || []
    : defaultValue || "";

  const [isOpen, setIsOpen] = useState(!!restProps?.open);
  const [selectedValue, setSelectedValue] = useState(initialSelectedValue);

  const dropdownRef = useRef(null);
  const iconRef = useRef(null);

  const styles = useStyles();

  useEffect(() => {
    setSelectedValue(initialSelectedValue);
  }, [defaultValue]);

  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
    iconRef,
  });

  const handleChange = (event, value) => {
    event.preventDefault();
    event.stopPropagation();

    if (isMulti) {
      const newValues = selectedValue?.includes(value)
        ? selectedValue.filter((val) => val !== value)
        : [...selectedValue, value];

      setSelectedValue(newValues);
      if (onValueChange) {
        onValueChange(newValues);
      }
    } else {
      setSelectedValue(value);
      if (onValueChange) {
        onValueChange(value);
      }
    }
  };

  const handleClearable = () => {
    if (disabled) {
      return;
    }
    if (isMulti && !!selectedValue?.length) {
      setSelectedValue([]);
      if (onValueChange) {
        onValueChange([]);
      }
    }
    if (!isMulti && !!selectedValue) {
      setSelectedValue("");
      if (onValueChange) {
        onValueChange("");
      }
    }
  };

  const handleIconClick = (event) => {
    event.stopPropagation();
    if (disabled) {
      return;
    }
    setIsOpen((prev) => !prev);
  };

  const renderValue = (selected) => {
    if (isMulti) {
      return (
        <Box
          className={clsx(styles.renderValueBox, {
            [styles.placeholder]: !selected.length,
          })}
        >
          <span className={styles.textEllipsis}>
            {!selected.length
              ? placeholder
              : selected
                  .map((val) => {
                    const selectedOption = options.find(
                      (option) => getOptionValue(option) === val
                    );
                    return selectedOption
                      ? getOptionLabel(selectedOption)
                      : val;
                  })
                  .filter(Boolean)
                  .join(", ")}
          </span>
        </Box>
      );
    } else {
      const selectedOption = options.find(
        (option) => getOptionValue(option) === selected
      );
      return (
        <Box
          className={clsx(styles.renderValueBox, {
            [styles.placeholder]: !selected && !defaultValue,
            [styles.customVariant]: variant === "custom",
          })}
        >
          {IconComponent && variant === "icon" && IconComponent}
          {variant === "avatar" && selectedOption && (
            <img
              src={selectedOption.icon}
              alt="avatar"
              className={styles.avatarImg}
            />
          )}
          {variant === "custom" && selectedOption && (
            <div
              key={selectedOption.id}
              style={{ display: "flex", alignSelf: "center" }}
            >
              {selectedOption.icon}
            </div>
          )}
          {variant === "dot" && selected && (
            <Box
              className={styles.dot}
              style={{
                backgroundColor: "green",
              }}
            />
          )}
          {selectedOption
            ? getOptionLabel(selectedOption)
            : emptyItem
            ? getOptionLabel(emptyItem)
            : placeholder}
        </Box>
      );
    }
  };

  const showClearIcon = isMulti ? selectedValue?.length > 0 : !!selectedValue;

  return (
    <Box ref={dropdownRef}>
      <Select
        {...restProps}
        multiple={isMulti}
        value={selectedValue}
        displayEmpty
        open={isOpen}
        className={clsx(styles.select, {
          [styles.defaultSelect]:
            (isMulti && !selectedValue?.length) || (!isMulti && !selectedValue),
          [styles.custom]: variant === "custom",
          [customSelectClasses]: !!customSelectClasses,
        })}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        onChange={(event) => handleChange(event, event.target.value)}
        style={style}
        IconComponent={() => {
          if (!showDropdownIcon) {
            return null;
          }
          if (isClearable && showClearIcon) {
            return (
              <Icon
                className={clsx("icon-x-close", styles.dropdownIcon)}
                onClick={handleClearable}
              />
            );
          }
          return (
            <Icon
              ref={iconRef}
              className={clsx("icon-down-arrow", styles.dropdownIcon)}
              onClick={handleIconClick}
            />
          );
        }}
        renderValue={renderValue}
        MenuProps={{
          classes: { paper: styles.menuPaper },
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
          transformOrigin: { vertical: "top", horizontal: "left" },
          getContentAnchorEl: null,
        }}
        disabled={disabled}
        classes={{
          select: styles.customSelect,
        }}
      >
        {isMulti ? (
          <>
            {options?.map((option) => {
              const optionValue = getOptionValue(option);
              const isSelected = selectedValue?.includes(optionValue);

              return (
                <MenuItem
                  disableRipple
                  key={getOptionValue(option)}
                  value={getOptionValue(option)}
                  onMouseDown={(event) =>
                    handleChange(event, getOptionValue(option))
                  }
                  onTouchStart={(event) =>
                    handleChange(event, getOptionValue(option))
                  }
                  className={clsx({
                    [styles.selectedBg]: isSelected,
                  })}
                >
                  <Box className={styles.optionBox}>
                    <Box>{getOptionLabel(option)}</Box>
                    {isSelected && (
                      <img
                        src="/images/contacts/select-check.svg"
                        alt="check-icon"
                        height={20}
                        width={20}
                      />
                    )}
                  </Box>
                </MenuItem>
              );
            })}
          </>
        ) : (
          <>
            {emptyItem && (
              <MenuItem
                disableRipple
                value={getOptionValue(emptyItem)}
                disabled={!!emptyItem.isDisabled}
                onMouseDown={(event) =>
                  handleChange(event, getOptionValue(emptyItem))
                }
                onTouchStart={(event) =>
                  handleChange(event, getOptionValue(emptyItem))
                }
              >
                {getOptionLabel(emptyItem)}
              </MenuItem>
            )}
            {options?.map((option) => (
              <MenuItem
                disableRipple
                key={getOptionValue(option)}
                value={getOptionValue(option)}
                onMouseDown={(event) =>
                  handleChange(event, getOptionValue(option))
                }
                onTouchStart={(event) =>
                  handleChange(event, getOptionValue(option))
                }
                className={clsx({
                  [styles.selectedBg]: getOptionValue(option) === selectedValue,
                })}
              >
                <Box className={styles.optionBox}>
                  <Box
                    className={clsx({
                      [styles.flexRow]: variant === "custom",
                    })}
                  >
                    {variant === "avatar" && (
                      <img
                        src={option.imageUrl}
                        alt="avatar"
                        className={styles.avatarImg}
                      />
                    )}
                    {variant === "custom" && (
                      <div key={option.id} style={{ display: "flex" }}>
                        {option.icon}
                      </div>
                    )}
                    {getOptionLabel(option)}
                  </Box>
                  {getOptionValue(option) === selectedValue && (
                    <img
                      src="/images/contacts/select-check.svg"
                      alt="check-icon"
                      height={20}
                      width={20}
                    />
                  )}
                </Box>
              </MenuItem>
            ))}
          </>
        )}

        {Children && <Children />}
      </Select>
    </Box>
  );
}

CustomSelect.propTypes = {
  options: PropTypes.array,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  IconComponent: PropTypes.element,
  variant: PropTypes.oneOf(["default", "icon", "avatar", "custom", "dot"]),
  placeholder: PropTypes.string,
  style: PropTypes.object,
  onValueChange: PropTypes.func,
  emptyItem: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
    text: PropTypes.string,
    isDisabled: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  isMulti: PropTypes.bool,
  showDropdownIcon: PropTypes.bool,
  Children: PropTypes.element,
  isClearable: PropTypes.bool,
  customSelectClasses: PropTypes.object,
};

CustomSelect.defaultProps = {
  options: [],
  defaultValue: null,
  IconComponent: null,
  variant: "default",
  placeholder: "",
  style: {},
  onValueChange: null,
  emptyItem: null,
  disabled: false,
  getOptionLabel: (option) => option.name,
  getOptionValue: (option) => option.id,
  isMulti: false,
  showDropdownIcon: true,
  Children: null,
  isClearable: false,
  customSelectClasses: undefined,
};

export default CustomSelect;
