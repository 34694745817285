import React, {
  Children,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import {
  CreateScopeDocument,
  GetAllScopeDocuments,
  GetContacts,
  lookupItemsGetId,
  UpdateContactDetailsForLeadStage,
  UpdateScopeDocument,
} from '../../../Services';
import { Spinner } from '../../SpinnerComponent/Spinner';
import { DocumentsUploader } from '../../../SharedComponents/DocumentsUploader/DocumentsUploader';
import { StaticLookupsIds } from '../../../assets/json/StaticLookupsIds';
import { ScopeDocumentEnum } from '../../../Enums';
import { showError, showSuccess } from '../../../Helper';
import { useTranslation } from 'react-i18next';
import { ButtonBase, DialogActions } from '@material-ui/core';
import { TabsComponent } from '../../Controls';

const documents = [
  'trade license',
  'memorandum of association',
  'shareholder passport',
];

function CorporateDocumentsDialog({
  contactId,
  stageId,
  reload,
  leadId,
  onClose,
}) {
  const { t } = useTranslation('ContactProfileManagementView');
  const [response, setResponse] = useState({
    [documents[0]]: [],
    [documents[1]]: [],
    [documents[2]]: [],
  });
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [lookups, setLookups] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [contactData, setContactData] = useReducer(reducer, {});
  const [currentTab, setCurrentTab] = useState(0);
  const [state, setState] = useReducer(reducer, {
    stageId,
    firstName: null,
    lastName: null,
    companyName: null,
    nationalityId: null,
    visaTypeId: null,
    passportNo: null,
    idCardNo: null,
    mobileNumber: null,
    emailAddress: null,
    documents: {
      scopeId: null,
      scopeTypeId: null,
      categoryFiles: [
        {
          categoryId: null,
          files: [
            {
              fileId: null,
              fileName: null,
              documentLink: null,
            },
          ],
        },
      ],
    },
  });
  const item = useMemo(() => lookups[currentTab], [currentTab, lookups]);
  const getDocumentByFormId = async () => {
    setIsLoading(true);
    const result = await GetAllScopeDocuments({ scopeId: contactId });
    if (!(result?.status && result.status !== 200)) {
      setResponse(() => {
        const data = {};
        const allDocs = result.result.map((doc) => ({
          uuid: doc.uuid ?? doc.documentId,
          fileName: doc.documentName,
          documentLink: doc.documentLink,
          categoryName: doc.categoryName,
          categoryId: doc.categoryId,
        }));
        documents.forEach((document) => {
          data[document] = allDocs.filter(
            (item) => item.categoryName.toLowerCase() === document
          );
        });
        return data;
      });
    }
    setIsLoading(false);
  };
  const getAllLookups = async () => {
    setIsLoading(true);
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.CompanyUploadDocumentsType,
    });
    if (!(res && res.status && res.status !== 200))
      setLookups(
        res.filter((item) =>
          documents.includes(item?.lookupItemName?.toLowerCase())
        )
      );
    else setLookups([]);
    setIsLoading(false);
  };
  const GetContact = async () => {
    const results = await GetContacts({
      pageIndex: 1,
      pageSize: 1,
      search: contactId,
      isAdvance: false,
    });

    if (!(results && results.status && results.status !== 200)) {
      const item = results.result[0];
      setContactData({
        id: 'edit',
        value: item,
      });
    }
  };

  const UpdateStates = () => {
    const newState = {
      ...contactData,
      stageId,
      firstName: contactData?.contact?.first_name,
      lastName: contactData?.contact?.last_name,
      companyName: contactData?.contact?.company_name,
      passportNo: contactData?.contact?.passport_no,
      idCardNo: contactData?.contact?.id_card_no,
      mobileNumber: contactData?.contact?.mobile?.phone,
      emailAddress: contactData?.contact?.email_address?.email,
      documents: state.documents,
    };

    setState({ id: 'edit', value: newState });
  };

  const onSaveClickHandler = async () => {
    const hasEmptyDocs = Object.values(response).some(
      (item) => item.length === 0
    );

    if (hasEmptyDocs) {
      return showError(t('fill-all-required-documents'));
    }

    const body = {
      ...state,
      documents: {
        scopeId: contactId,
        scopeTypeId: ScopeDocumentEnum.Contact.scopeTypeId,
        categoryFiles: Object.values(response).map((item) => ({
          categoryId: item[0].categoryId,
          files: item,
        })),
      },
    };
    const res = await UpdateContactDetailsForLeadStage({ leadId, body });
    if (!(res && res.status && res.status !== 200)) {
      onClose();
      showSuccess(t(`contact-updated-successfully`));
      reload();
    } else {
      showError(t(`failed-to-update-contact`));
    }
  };
  useEffect(() => {
    UpdateStates();
  }, [contactData]);

  useEffect(() => {
    getAllLookups();
    getDocumentByFormId();
    GetContact();
  }, []);

  return (
    <div>
      <Spinner isAbsolute isActive={isLoading} />
      <TabsComponent
        wrapperClasses={'w-100'}
        data={documents.map((item, i, a) => ({
          label: item,
          disabled: response[a[i - 1]]?.length === 0,
        }))}
        labelInput={'label'}
        themeClasses='theme-solid'
        currentTab={currentTab}
        onTabChanged={(_e, second) => setCurrentTab(second)}
      />
      {item && (
        <div className='mb-3'>
          <h6>
            <strong>{item.lookupItemName}</strong>
          </h6>
          <DocumentsUploader
            initUploaded={response[item.lookupItemName.toLowerCase()]?.filter(
              (doc) => doc.categoryId === item.lookupItemId
            )}
            docTypes={{
              images: 0,
              docs: 2,
              links: 3,
            }}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            uploadedChanged={async (files) => {
              const addOrEditDocument = {
                scopeId: +contactId,
                scopeTypeId: ScopeDocumentEnum.Contact.scopeTypeId,
                categoryFiles: [
                  {
                    categoryId: item.lookupItemId,
                    files: [...files].map((file) => ({
                      ...file,
                      categoryName: item.lookupItemName,
                      categoryId: item.lookupItemId,
                    })),
                  },
                ],
              };
              setResponse((prevResponse) => ({
                ...prevResponse,
                [item.lookupItemName.toLowerCase()]: [...files].map((file) => ({
                  ...file,
                  categoryName: item.lookupItemName,
                  categoryId: item.lookupItemId,
                })),
              }));
            }}
            open
            translationPath={''}
            parentTranslationPath={'ContactProfileManagementView'}
          />
        </div>
      )}
      <>
        <DialogActions>
          <ButtonBase
            className='MuiButtonBase-root btns bg-cancel'
            onClick={onClose}
          >
            {t(`${''}Shared:cancel`)}
          </ButtonBase>
          {currentTab === documents.length - 1 && (
            <ButtonBase
              className='MuiButtonBase-root btns theme-solid '
              onClick={onSaveClickHandler}
            >
              {t(`${''}Shared:save`)}
            </ButtonBase>
          )}
          {currentTab < documents.length - 1 && (
            <ButtonBase
              className='MuiButtonBase-root btns theme-solid '
              onClick={() => setCurrentTab((i) => i + 1)}
              disabled={response[documents[currentTab]]?.length === 0}
            >
              {t(`${''}Shared:next`)}
            </ButtonBase>
          )}
        </DialogActions>
      </>
    </div>
  );
}

export default CorporateDocumentsDialog;
