
import React  , {  useState , useEffect , useCallback  } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useTitle } from '../../../Hooks'; 
import { ActionsButtonsEnum , ActionsEnum  , ViewTypesEnum ,DateFilterTypesEnum  , TableFilterTypesEnum  ,TableFilterOperatorsEnum } from '../../../Enums' ; 
import { ActiveItemActions } from '../../../store/ActiveItem/ActiveItemActions';
import { Spinner } from '../../../Components' ; 
import { PaginationComponent } from '../../../Components/PaginationComponent/PaginationComponent';
import { generateUniqueKey , GlobalHistory  , bottomBoxComponentUpdate   , getSideMenuStatus  ,   sideMenuIsOpenUpdate,
  sideMenuComponentUpdate, CheckIsCommunicateAllowed , CheckIsWithinWorkingHours , showError ,
  WhatsAppMessage   } from '../../../Helper' ; 
import { formByIdGet    , MaqsamTokenServices  , GetDeveloperContactsAdvanceSearchAPI  ,GetAllFormFieldsByFormId } from '../../../Services' ; 
import {    CardDetailsComponent   } from './DevelopersViewComponent' ; 
import {  DevelopersMapper      } from './DevelopersUtilities' ; 
import { DevelopersContentView , DevelopersContactsHeaderView } from './DevelopersContact'

 const parentTranslationPath = 'Developers';
 const translationPath = '';
  
export const DevelopersView = () => { 

    const{ t } = useTranslation(parentTranslationPath);
    useTitle(t(`${translationPath}developers`));
    const pathName = window.location.pathname.split('/home/')[1];

     const [isFirst, setFirst] = useState(false);
     const dispatch = useDispatch();
     const advanceSearch = useSelector(
      (state) => state.GlobalAdvanceSearchReducer
    );

    const [isLoading , setIsLoading]  =  useState(false) ; 
    const [allFormFields , setAllFormFields]  =  useState([]) ; 
    const [isAdvanceSearchActive, setIsAdvanceSearchActive] = useState(false);
    const [selectFieldsOnAdvanceSearch, setSelectFieldsOnAdvanceSearch] =  useState([]);
    const [isDatePickerChanged, setIsDatePickerChanged] = useState(false);
    const [isContactTypeDialogOpen, setIsContactDialogOpen] = useState(false);
    const [isAllFormFieldsLoading  , setIsAllFormFieldsLoading ] = useState(false) ; 
    const [isFirst1, setFirst1] = useState(false);
    const [formId , setFormId] = useState(null) ;
    const [searchData, setSearchData] = useState([]);
    const [searchedItem, setSearchedItem] = useState('');
    const [developersTableFilter, setDevelopersTableFilter] = useState(
      (advanceSearch && advanceSearch['DevelopersFilter']) || null
    );

    const [filterValuesFromAdvanceSearch, setFilterValuesFromAdvanceSearch] = useState(null);
    const [detailedCardAction, setDetailedCardAction] = useState(() => ({
      actionEnum: '',
      item: '',
    }));


    const [activeActionType, setActiveActionType] = useState(
      (localStorage.getItem('ViewType') &&
        JSON.parse(localStorage.getItem('ViewType')).developers) ||
        ViewTypesEnum.cards.key
    );
    const [isExpanded, setIsExpanded] = useState(
      activeActionType === ViewTypesEnum.cardsExpanded.key
    );
    const [activeCard, setActiveCard] = useState(null);
    const [checkedCards, setCheckedCards] = useState([]);
    const [checkedCardsIds, setCheckedCardsIds] = useState([]);
    const [displayOpenFileButton, setDisplayOpenFileButton] = useState(true);
    const [isOpenContactsActionDialog, setIsOpenContactsActionDialog] =
    useState(false);
    const [activeSelectedAction, setActiveSelectedAction] = useState('');
    const [isOpenMaqsamIframe, setIsOpenMaqsamIframe] = useState(false);
    const [advanceSearchBtn, setAdvanceSearchBtn] = useState(true);
    const [isClearFiltersClicked, setIsClearFiltersClicked] = useState(false);
    const [actionButtonsKey, setActionButtonsKey] = useState(generateUniqueKey());
    const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);

    const dateRangeDefault = {
      startDate: null,
      endDate: null,
      key: 'selection',
      selectedDateType: 1,
    };
    const [dateFilter, setDateFilter] = useState(
      orderFilter?.ContactsFilterDate?.startDate &&
        orderFilter?.ContactsFilterDate?.endDate !== null
        ? {
            startDate: new Date(
              orderFilter?.ContactsFilterDate?.startDate || null
            ),
            endDate: new Date(orderFilter?.ContactsFilterDate?.endDate || null),
            key: new Date(orderFilter?.ContactsFilterDate?.key || null),
            selectedDateType:
              orderFilter?.ContactsFilterDate?.selectedDateType || 1,
          }
        : dateRangeDefault
    );
    const [filterSearchDto, setFilterSearchDto] = useState(null);
    const [isConsentActionDialogOpen, setIsConsentActionDialogOpen] =
    useState(false);
  const [isWorkingHoursDialogOpen, setIsWorkingHoursDialogOpen] =
     useState(false);

    const [activeFormType, setActiveFormType] = useState(1);
    const loginResponse = useSelector((state) => state.login.loginResponse);
    const [sortBy, setSortBy] = useState(null);
    const [activeItem , setActiveItem ] = useState(null) ; 
    const [detailsDevelopersList , setDetailsDevelopersList ] = useState({result : [] , totalCount : 0 }) ; 
    const [selectedOrderBy, setSelectedOrderBy] = useState({
      filterBy: (orderFilter?.DevelopersFilter?.filterBy) ||  'CreatedOn',
      orderBy: (orderFilter?.DevelopersFilter?.orderBy) || 2 ,
    });

    const [orderBy, setOrderBy] = useState(
      selectedOrderBy.filterBy
        ? selectedOrderBy
        : { filterBy: 'CreatedOn', orderBy: 2 }
    );

    const [filter, setFilter] = useState({
      pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
      pageIndex: 0,
      filterBy: orderBy.filterBy || null,
      orderBy: orderBy.orderBy || null,
      createdBy: null,
    });

    const onActionsButtonClicked = useCallback(
      (activeAction) => {
         if (activeAction === ActionsButtonsEnum[1].id) {
          GlobalHistory.push(
            `/home/developers/add`
          );
      }
      },
      []
    );

    const MaqsamTokenServicesAPI = useCallback(async (mobile) => {
      const result = await MaqsamTokenServices();
      if (result) {
        setIsOpenMaqsamIframe(true);
        var iframe = document.getElementById('maqsamiframe');
        if (iframe !== null) {
          let token = result;
          iframe.setAttribute(
            'src',
            `https://portal.maqsam.com/autologin?auth_token=${token};continue_path=/phone/dialer#autodial=${mobile}`
          );
        }
      }
    }, []);

    const contactActionsHandler = (actionEnum, item) => {
      setIsOpenContactsActionDialog(true);
      if (actionEnum === 'whatsappSolid') {
        const el = document.createElement('a');
        if (item?.mobile?.phone) {
          el.href = WhatsAppMessage(
            item?.whatsapp_mobile?.phone || item.mobile.phone
          );
          el.target = 'blank';
          el.click();
        } else showError(t(`${translationPath}Failure-Open-WhatsApp`));
      }
      if (actionEnum === 'phoneSolid') {
        MaqsamTokenServicesAPI(
          (item && item.mobile && item.mobile.phone) ||
            (item && item.contacts_person && item.contacts_person[0].mobile) ||
            null
        );
      }
    };

    const closeSideMenu = () => {
      sideMenuComponentUpdate(<></>);
      sideMenuIsOpenUpdate(false);
    };

    const detailedCardSideActionClicked = useCallback(
      (actionEnum, activeData) => async (event) => {
        dispatch(ActiveItemActions.activeItemRequest(activeData));
        event.stopPropagation();
        if (actionEnum === ActionsEnum.folder.key) {
          if (pathName === 'developers/view') {
            GlobalHistory.push(
              `/home/developers/developer-profile-edit?form=${formId}&id=${activeData.id}`
            );
          }
        }
        closeSideMenu();
      },
      [dispatch, pathName ,formId]
    );
    


  const detailedCardActionClicked = useCallback(
    (actionEnum, item) => async (event) => {
      event.stopPropagation();
      setDetailedCardAction({
        actionEnum,
        item,
      });
      const isActionAllowed = await checkIsActionAllowed(item?.id);

      if (isActionAllowed) contactActionsHandler(actionEnum, item);
      else unAllowedActionHandler(item?.id);
    },
    []
  );
  const checkIsActionAllowed = async (contactId) => {
    const isWithinWorkingHours = await CheckIsWithinWorkingHours({ contactId });
    const isCommunicateAllowed = await CheckIsCommunicateAllowed({ contactId });
    return (isWithinWorkingHours && isCommunicateAllowed) || false;
  };

  const unAllowedActionHandler = async (contactId) => {
    const isWithinWorkingHours = await CheckIsWithinWorkingHours({ contactId });
    const isCommunicateAllowed = await CheckIsCommunicateAllowed({ contactId });

    if (!isWithinWorkingHours) {
      setIsWorkingHoursDialogOpen(true);
      return;
    }
    if (!isCommunicateAllowed) {
      setIsConsentActionDialogOpen(true);
      return;
    }
  };
  const getDevelopersData = useCallback(
    async (f) => {
      
        setIsLoading(true);
        const localFilterDto = f || filterSearchDto || {} ; 
     
        if (developersTableFilter) {
          Object.values(developersTableFilter)
            .filter((item) => item.searchableKey || item.displayPath)
            .map((item) => {
              if (localFilterDto[item.searchableKey || item.displayPath]) {
                localFilterDto[item.searchableKey || item.displayPath].push({
                  searchType: item && item.operator,
                  value: item && item.value,
                });
              } else if (item.value) {
                localFilterDto[item.searchableKey || item.displayPath] = [
                  {
                    searchType: item.operator,
                    value: item.value,
                  },
                ];
              } else if (
                !item.value &&
                (item.operator === TableFilterOperatorsEnum.isNotBlank.key ||
                  item.operator === TableFilterOperatorsEnum.isBlank.key)
              ) {
                localFilterDto[item.searchableKey || item.displayPath] = [
                  {
                    searchType: item.operator,
                    value: null,
                  },
                ];
              }
              return undefined;
            });
        }
        if (Object.values(localFilterDto).length)
          setIsAdvanceSearchActive(true);
        else setIsAdvanceSearchActive(false);

        let body = {
          criteria: localFilterDto,
          ...orderBy,
        };
        setIsDatePickerChanged(false);

        if (dateFilter && dateFilter.startDate && dateFilter.endDate) {
          const fromDate = moment(dateFilter.startDate)
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss');
          const toDate = moment(dateFilter.endDate).format(
            'YYYY-MM-DDTHH:mm:ss'
          );

          if (
            dateFilter.selectedDateType ===
            DateFilterTypesEnum.CreatedOnDate.key
          ) {
            body.fromDate = fromDate;
            body.toDate = toDate;
          } else if (
            dateFilter.selectedDateType ===
            DateFilterTypesEnum.UpdatedOnDate.key
          ) {
            body.updatedFromDate = fromDate;
            body.updatedToDate = toDate;
          }
        }
        

        const res = await GetDeveloperContactsAdvanceSearchAPI(filter, body);
        if (!(res && res.status && res.status !== 200)) {
          setDetailsDevelopersList({
            result: ((res && res.result) || []).map(
              (item) =>
                item &&  item.developerJson &&
              DevelopersMapper(item, item.developerJson?.developer)
            ),
            totalCount: (res && res.totalCount) || 0,
          });
        } else {
          setDetailsDevelopersList({
            result: [],
            totalCount: 0,
          });
        }
      //  closeSideMenu();
        setIsLoading(false);
    },
    [
      activeFormType,
      filter,
      dateFilter,
      filterSearchDto,
      orderBy,
      developersTableFilter
    ]
  );

  const searchChangedClickedWithoutFilter = async (data) => {
    if (data.length === 0) return;
    const oldFilter = data.reduce((total, item) => {
      if (total[item.key]) {
        total[item.key].push({
          searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
          value: item.value,
        });
      } else {
        total[item.key] = [
          {
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          },
        ];
      }
      return total;
    }, {});
    getDevelopersData(oldFilter);
  };
  
  const reloadData = useCallback(() => {
    setFilter((item) => ({ ...item, pageIndex: 0 }));
    setActiveCard(null);
  }, []);


    const onCardClick = useCallback(
      (item, selectedIndex) => (event) => {
        event.stopPropagation();
        event.preventDefault();
        setActiveCard(item);
        dispatch(ActiveItemActions.activeItemRequest(item));
        if (getSideMenuStatus()) setAdvanceSearchBtn(true);
        sideMenuComponentUpdate(
          <CardDetailsComponent
            activeData={detailsDevelopersList?.result[selectedIndex]}
            cardDetailsActionClicked={detailedCardSideActionClicked}
            loginResponse={loginResponse}
            onActionClicked={detailedCardActionClicked}
            relodedata={reloadData}
            displyOpenFileButton={displayOpenFileButton}
            closeSideMenu={closeSideMenu}
            formId={formId}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        );
        sideMenuIsOpenUpdate(true);
      },
      [
        detailedCardActionClicked,
        detailedCardSideActionClicked,
        detailsDevelopersList.result,
        loginResponse,
        reloadData,
        advanceSearchBtn,
      ]
    );
    
    const onRowClicked = useCallback(
      (item, rowIndex) => {
        if(isFirst){
        setActiveCard(item);
        sideMenuComponentUpdate(
          <CardDetailsComponent
            relodedata={reloadData}
            loginResponse={loginResponse}
            onActionClicked={detailedCardActionClicked}
            activeData={detailsDevelopersList.result[rowIndex]}
            cardDetailsActionClicked={detailedCardSideActionClicked}
            displyOpenFileButton={displayOpenFileButton}
            closeSideMenu={closeSideMenu}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            formId={formId}

          />
        );
        sideMenuIsOpenUpdate(true);}
      },
      [
        detailedCardActionClicked,
        detailedCardSideActionClicked,
        detailsDevelopersList.result,
        loginResponse,
        reloadData,
      ]
    );



    const onFilterValuesChanged = (newValue) => {
      setDevelopersTableFilter(newValue);
      if (!(newValue && newValue.length)) {
        localStorage.removeItem('DevelopersFilter');
      }
    };

    const cardCheckboxClicked = useCallback(
      (itemIndex, element, isContactWithTransaction) => {
        if (
          !isContactWithTransaction ||
          activeSelectedAction === ActionsButtonsEnum[29].id
        ) {
          setCheckedCards((items) => {
            const index = items.findIndex((item) => item.id === element.id);
            if (index !== -1) items.splice(index, 1);
            else items.push(element);
            return [...items];
          });
          setCheckedCardsIds((items) => {
            const index = items.findIndex((item) => item === element.id);
            if (index !== -1) items.splice(index, 1);
            else items.push(element.id);
            return [...items];
          });
        } else
          showError(
            t(`${translationPath}absolete-contacts-shouldnt-have-transactions`)
          );
      },
      [activeSelectedAction]
    );
    
    const getAllFormFieldsByFormId = useCallback(async () => {
      setIsAllFormFieldsLoading(true);
      Promise.all([
        await GetAllFormFieldsByFormId(formId)
      ])
        .then((result) => {
          if (Array.isArray(result[0])) {
            const concantinateFields = result[0]
              .filter(
                (field, index, array) =>
                  array.findIndex(
                    (element) => element.formFieldKey === field.formFieldKey
                  ) === index
              );
            const list = concantinateFields
              .filter(
                (e) =>
                  e.formFieldName !== 'developer_logo' &&
                  e.formFieldName !== 'contact_photo' &&
                  e.formFieldName !== 'map'
              )
              .map((field) => ({
                id: field.formFieldId || null,
                key: field.formFieldKey || null,
                isDate: field.uiWidgetType === 'alt-date' || false,
                label:
                  (field.formFieldTitle &&
                    field.formFieldTitle.replace('*', '')) ||
                  '',
                input: field.displayPath || '',
                isNumber:
                  (field.propertyJson &&
                    JSON.parse(field.propertyJson).schema &&
                    field.propertyJson &&
                    JSON.parse(field.propertyJson).schema.specialKey ===
                      'currency') ||
                  (field.propertyJson &&
                    JSON.parse(field.propertyJson).schema &&
                    field.propertyJson &&
                    JSON.parse(field.propertyJson).schema.specialKey ===
                      'decimal') ||
                  (field.propertyJson &&
                    JSON.parse(field.propertyJson).schema &&
                    field.propertyJson &&
                    JSON.parse(field.propertyJson).schema.specialKey === 'size'),
                isSortable: true,
                searchableKey: field.searchableKey,
                data: JSON.parse(field.propertyJson).schema,
                fieldType:
                  (field.propertyJson &&
                    JSON.parse(field.propertyJson).schema.uiType) ||
                  (field.propertyJson &&
                    JSON.parse(field.propertyJson).uiSchema['ui:widget']) ||
                  (field.uiWidgetType === null && 'text'),
              }));
            setAllFormFields([...list]);
          } else setAllFormFields([]);
          setIsAllFormFieldsLoading(false);
        })
        .catch(() => {
          setAllFormFields([]);
          setIsAllFormFieldsLoading(false);
        });
      setIsAllFormFieldsLoading(false);
    }, [formId]);
    

    const closeTypeDialogHandler = () => {
      setIsContactDialogOpen(false);
      setActionButtonsKey(generateUniqueKey());
      setActiveSelectedAction(ActionsButtonsEnum[1].id);
    };


    const getForm = useCallback(async () => {
      setIsLoading(true);
      const response = await formByIdGet({
        formname:'Developers'
        
      });
      if (!(response && response.status && response.status !== 200)) 
        setFormId(response && response.length && response[0] && response[0].form_id);
      else setFormId(null)  ; 
      setIsLoading(false);
    }, []);

    useEffect(() => {
      getForm();
     },[]);

     useEffect(() => {
      if(formId)
      getAllFormFieldsByFormId();
     },[formId]);

    useEffect(() => {
        bottomBoxComponentUpdate(
          <PaginationComponent
            pageIndex={filter.pageIndex}
            pageSize={filter.pageSize}
            totalCount={detailsDevelopersList.totalCount}
            onPageIndexChanged={(pageIndex)=> setFilter((item) => ({ ...item, pageIndex }))}
            onPageSizeChanged={(pageSize)=> setFilter((item) => ({ ...item, pageIndex: 0, pageSize }))}
          />
        );
    });
  
    
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  )
    
    useEffect(() => {
      const data = localStorage.getItem('DevelopersFilter');
      if (data) {
        setSearchData(JSON.parse(data));
        searchChangedClickedWithoutFilter(JSON.parse(data));
      } else getDevelopersData();
    }, []);
  
    useEffect(() => {
      if (isFirst1) {
        if (searchData && searchData.length === 0) {
          localStorage.removeItem('DevelopersFilter');
  
          setSearchedItem('');
        } else {
          localStorage.setItem('DevelopersFilter', JSON.stringify(searchData));
        }
      } else setFirst1(true);
    }, [searchData]);
  
    useEffect(() => {
      if (!isFirst) setFirst(true);
      else {
        const data = localStorage.getItem('DevelopersFilter');
        if (data) searchChangedClickedWithoutFilter(JSON.parse(data));
        else if (searchData && searchData.length === 0) getDevelopersData();
      }
    }, [
      developersTableFilter,
      filter,
      filterSearchDto,
      orderBy,
      searchData,
    ]);
    
  useEffect(() => {
    if (isFirst && isDatePickerChanged) {
      const searchDataFilter = searchData.reduce((total, item) => {
        if (total[item.key]) {
          total[item.key].push({
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          });
        } else {
          total[item.key] = [
            {
              searchType:
                TableFilterTypesEnum.textInput.defaultSelectedOperator,
              value: item.value,
            },
          ];
        }
        return total;
      }, {});
      getDevelopersData(searchDataFilter);
    }
  }, [dateFilter, isDatePickerChanged, searchData]);


    return (
      <div className="view-wrapper developers-view">
        <Spinner isActive={isLoading} isAbsolute />
        <DevelopersContactsHeaderView
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        setIsDatePickerChanged={setIsDatePickerChanged}
        isLoading={isLoading}
        advanceSearch={advanceSearch}
        setActiveActionType={setActiveActionType}
        activeActionType={activeActionType}
        onFilterValuesChanged={onFilterValuesChanged}
        filterValuesFromAdvanceSearch={filterValuesFromAdvanceSearch}
        setOrderBy={setOrderBy}
        formId={formId}
        setFilterSearchDto={setFilterSearchDto}
        setDevelopersTableFilter={setDevelopersTableFilter}
        setFilterValuesFromAdvanceSearch={setFilterValuesFromAdvanceSearch}
        setActionButtonsKey={setActionButtonsKey}
        orderFilter={orderFilter}
        setIsClearFiltersClicked={setIsClearFiltersClicked}
        dateRangeDefault={dateRangeDefault}
        setSearchData={setSearchData}
        activeSelectedAction={activeSelectedAction}
        closeTypeDialogHandler={closeTypeDialogHandler}
        selectedOrderBy={selectedOrderBy}
        setSearchedItem={setSearchedItem}
        setSelectedOrderBy={setSelectedOrderBy}
        setIsAdvanceSearchActive ={setIsAdvanceSearchActive}
        isAdvanceSearchActive ={isAdvanceSearchActive}
        setActiveFormType={setActiveFormType}
        orderBy={orderBy}
        allFormFields={allFormFields}
        isAllFormFieldsLoading={isAllFormFieldsLoading}
        selectFieldsOnAdvanceSearch={selectFieldsOnAdvanceSearch}
        setSelectFieldsOnAdvanceSearch={setSelectFieldsOnAdvanceSearch}
        
        />
        <DevelopersContentView
         parentTranslationPath={parentTranslationPath}
         translationPath={translationPath}
         activeActionType={activeActionType}
         pathName={pathName}
         reloadData={reloadData}
         checkedCards={checkedCards}
         setCheckedCards={setCheckedCards}
         checkedCardsIds={checkedCardsIds}
         detailedCardActionClicked={detailedCardActionClicked}
         developersTableFilter={developersTableFilter}
         onFilterValuesChanged={onFilterValuesChanged}
         setCheckedCardsIds={setCheckedCardsIds}
         setFilter={setFilter}
         activeSelectedAction={activeSelectedAction}
         filter={filter}
         detailedCardSideActionClicked={detailedCardSideActionClicked}
         closeSideMenu={closeSideMenu}
         onRowClicked={onRowClicked}
         isFirst={isFirst}
         detailsDevelopersList={detailsDevelopersList}
         activeCard={activeCard}
         isExpanded={isExpanded}
         onCardClick={onCardClick}
         setActiveCard={setActiveCard}
         dispatch={dispatch}
         setOrderBy={setOrderBy}
         isClearFiltersClicked={isClearFiltersClicked}
         setIsClearFiltersClicked={setIsClearFiltersClicked}
         displayOpenFileButton={displayOpenFileButton}
         formId={formId}
         sideMenuIsOpenUpdate={sideMenuIsOpenUpdate}
         loginResponse={loginResponse}
         advanceSearchBtn={advanceSearchBtn}
         cardCheckboxClicked={cardCheckboxClicked}
         setAdvanceSearchBtn={setAdvanceSearchBtn}
         allFormFields={allFormFields}
         isAllFormFieldsLoading={isAllFormFieldsLoading}
        />
         
      </div>
    );

    
  } ; 
  