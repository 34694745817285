import React, { useState, useEffect, useCallback } from 'react';
import { DistrictTypeIdEnum } from '../../../../../../../../../Enums';
import { AutocompleteComponent } from '../../../../../../../../../Components';
import { lookupItemsGetId } from '../../../../../../../../../Services/LookupsServices';

export const CityComponent = ({
   parentTranslationPath,
   translationPath,
   addressLoadings ,
   setAddressLoadings ,
   labelClasses , 
   isSubmitted,
   helperText,
   error,
   onUpdateValue , 
   setAddressList , 
   addressList , 
   propertyValues , 
}) => {

  const GetDistrict = useCallback(async (cityId) => {
    setAddressLoadings((item) => ({ ...item, district:true }));
    const res = await lookupItemsGetId({
      lookupTypeId: DistrictTypeIdEnum.lookupTypeId,
      lookupParentId: cityId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setAddressList((item) => ({ ...item, districts: res }));

    } else
      setAddressList((item) => ({ ...item, districts: [] }));
      setAddressLoadings((item) => ({ ...item, district:false }));
  }, []);


  return (
    <div>
      <AutocompleteComponent
          idRef='CityRef'
          labelValue='city'
          selectedValues={propertyValues && propertyValues.city}
          isLoading={addressLoadings.city}
          labelClasses={labelClasses}
          data={addressList && addressList.cities  || []}
          multiple={false}
          displayLabel={(option) => (option && option.lookupItemName) || ''}
          withoutSearchButton
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
           setAddressList((item) => ({ ...item , districts : []  , communities : [] , subCommunities:[] }));
          if(newValue)
          GetDistrict(newValue.lookupItemId) ; 
           onUpdateValue(newValue);
          }}
         isSubmitted={isSubmitted}
         helperText={helperText}
         error={error}
         isWithError
      />
    </div>
  );
};
