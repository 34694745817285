
import { LeadsAdvanceSearchTest, GetAllPropertyUnitModelsAPI , GetAllPropertyUnitTypeAPI  , lookupItemsGetId } from '../Services';
import { UnitTypes  , UnitsOperationTypeEnum} from "../Enums";
let oldvalue = "";
let timer = null;

export const LeadsRule = async (item, value, setRerender, type) => {
  if (!item.data.searchKey) return;
  if (item.data.searchKey !== "Leads") return;
  if (item.value === "") return;
  if (value === "") return;
  if (timer !== null) clearTimeout(timer);
  if (oldvalue === value) return;
  oldvalue = value;
  let isLeadId = false;
  if (value) isLeadId = !isNaN(value);

  const body = {
    criteria: isLeadId
      ? {
          Ids: [{ searchType: 1, value }],
          lead_type_id: [{ searchType: 1, value: 2 }],
          "status.lookupItemName": [{ searchType: 1, value: "open" }],
        }
      : {
          "contact_name.name": [{ searchType: 2, value }],
          lead_type_id: [{ searchType: 1, value: 2 }],
          "status.lookupItemName": [{ searchType: 1, value: "open" }],
        },
    orderBy: 2,
    filterBy: "createdOn",
    OperationType: type === "1" || type === "3" ? "Sale" : "Lease",
  };

  timer = setTimeout(async () => {
    const rs = await LeadsAdvanceSearchTest(
      { pageIndex: 0, pageSize: 10 },
      body
    );
    item.data.enum = [];
    if (!rs || !rs.result) return;

    rs.result.map((itemValue) => {
      const leadJson = JSON.parse(itemValue.leadJson);
      item.data.enum.push({
        leadId: itemValue.leadId,
        name:
          leadJson &&
          leadJson.lead &&
          leadJson.lead.contact_name &&
          leadJson.lead.contact_name.name,
        leadType: itemValue.leadsType,
        leadClass:
          (leadJson && leadJson.lead && leadJson.lead.leadClass) || null,
        budgetFrom:
          (leadJson && leadJson.lead && leadJson.lead.budgetFrom) || null,
        budgetTo: (leadJson && leadJson.lead && leadJson.lead.budgetTo) || null,
        bathrooms:
          (leadJson.lead &&
            leadJson.lead.bathrooms &&
            leadJson.lead.bathrooms.length > 0 &&
            leadJson.lead.bathrooms) ||
          null,
        bedrooms:
          (leadJson.lead &&
            leadJson.lead.bedrooms &&
            leadJson.lead.bedrooms.length > 0 &&
            leadJson.lead.bedrooms) ||
          null,
        sizeAreaFrom: (leadJson.lead && leadJson.lead.sizeAreaFrom) || null,
        sizeAreaTo: (leadJson.lead && leadJson.lead.sizeAreaTo) || null,
        community: (leadJson.lead && leadJson.lead.community) || null,
        property: (leadJson.lead && leadJson.lead.property_name) || null,
        primaryView: (leadJson.lead && leadJson.lead.view) || null,
        unitType: (leadJson.lead && leadJson.lead.propertyunit_type) || null,
        sub_community: (leadJson.lead && leadJson.lead.sub_community) || null,


      });
    });

    setRerender(Math.random());
  }, 500);
};

export const LeadsDefaultRule = async (item, setRerender, type) => {
  if (item.data.searchKey !== "Leads") return;
  if (item.data.enum) return;
  const body = {
    criteria: {
      lead_type_id: [{ searchType: 2, value: 2 }],
      "status.lookupItemName": [{ searchType: 1, value: "open" }],
    },
    orderBy: 2,
    filterBy: "createdOn",
    OperationType: type === "1" || type === "3" ? "Sale" : "Lease",
  };

  const rs = await LeadsAdvanceSearchTest({ pageIndex: 0, pageSize: 10 }, body);

  item.data.enum = [];
  if (!rs || !rs.result) return;
  rs.result.map((value) => {
    const leadJson = JSON.parse(value.leadJson);
    item.data.enum.push({
      leadId: value.leadId,
      name:
        leadJson.lead &&
        leadJson.lead.contact_name &&
        leadJson.lead.contact_name.name,
      leadType: value.leadsType,
      leadClass: leadJson.lead && leadJson.lead.leadClass,
      budgetFrom:
        (leadJson && leadJson.lead && leadJson.lead.budgetFrom) || null,
      budgetTo: (leadJson && leadJson.lead && leadJson.lead.budgetTo) || null,
      bathrooms:
        (leadJson.lead &&
          leadJson.lead.bathrooms &&
          leadJson.lead.bathrooms.length > 0 &&
          leadJson.lead.bathrooms) ||
        null,
      bedrooms:
        (leadJson.lead &&
          leadJson.lead.bedrooms && 
          leadJson.lead.bedrooms.length > 0 &&
          leadJson.lead.bedrooms) ||
        null,
      sizeAreaFrom: (leadJson.lead && leadJson.lead.sizeAreaFrom) || null,
      sizeAreaTo: (leadJson.lead && leadJson.lead.sizeAreaTo) || null,
      community: (leadJson.lead && leadJson.lead.community) || null,
      property: (leadJson.lead && leadJson.lead.property_name) || null,
      sub_community: (leadJson.lead && leadJson.lead.sub_community) || null,
    });
  });
  setRerender(Math.random());
};

export const onChangeLeadsRule = async (item, value, setRerender, itemList, values, setData, formType) => {
  if (item.data.searchKey !== 'Leads') return;
  const pathName = window.location.pathname.split('/home/')[1];
  let operationType = (pathName.includes('sale') || pathName.includes('Sale')) ? UnitsOperationTypeEnum.sale.key :  (pathName.includes('lease') || pathName.includes('Lease')) ? (UnitsOperationTypeEnum.rent.key)  : null ; 

  const unitTypeIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'unit_type'));
  setTimeout(() => {
    if(unitTypeIndex !== -1){
    setData(itemList[unitTypeIndex].field.id, []); 
    }
  }, 50);
  
  if (!value)
    return;
  

  setTimeout(async () => 
  {
  const budgetFromIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'client_budget-from'));
  const budgetToIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'client_budget-to'));
  const bedroomIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'bedroom'));
  const communityIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'community'));
  const propertyIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'property'));
  const inquiryUnitModelIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'inquiry_unit-model'));
  const areaSizeFrom = itemList.indexOf(itemList.find((f) => f.field.id === 'area_size-fromsqft'));
  const areaSizeTo = itemList.indexOf(itemList.find((f) => f.field.id === 'area_size-tosqft'));
  const primaryViewIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'primary_view'));
  const subCommunityIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'sub_community'));



  if (formType === 1) {

    if (primaryViewIndex !== -1)
      setData(
        itemList[primaryViewIndex].field.id,
        (value && value.primaryView) || {}
      );

    if (communityIndex !== -1)
      setData(
        itemList[communityIndex].field.id,
        (value && value.community) || {}
      );
      if (subCommunityIndex !== -1)
        setData(
          itemList[subCommunityIndex].field.id,
          (value && value.sub_community) || {}
        );

    if (propertyIndex !== -1)
      setData('property', ((value && value.property) || {}));

    if (unitTypeIndex !== -1 && !value.property )
      setData('unit_type', ((value && value.unitType) || []));

    if (budgetFromIndex !== -1) {
      setData(
        itemList[budgetFromIndex].field.id,
        (value && value.budgetFrom) || "  "
      );
      setData("client_budget_from", (value && value.budgetFrom) || "  ");
    }
    if (budgetToIndex !== -1) {
      setData(
        itemList[budgetToIndex].field.id,
        (value && value.budgetTo) || "  "
      );
      setData("client_budget_to", (value && value.budgetTo) || "  ");
    }
    if (bedroomIndex !== -1) {
      setData(
        itemList[bedroomIndex].field.id,
        (value && value.bedrooms && value.bedrooms[0] === ""
          ? null
          : value && value.bedrooms) || "  "
      );
      itemList[bedroomIndex].data.enum = [];
    }
    if (areaSizeFrom !== -1) {
      setData(
        itemList[areaSizeFrom].field.id,
        (value && value.sizeAreaFrom) || "  "
      );
      setData("area_size_fromsqft", (value && value.sizeAreaFrom) || "  ");
    }
    if (areaSizeTo !== -1) {
      setData(
        itemList[areaSizeTo].field.id,
        (value && value.sizeAreaTo) || "  "
      );
      setData("area_size_tosqft", (value && value.sizeAreaTo) || "  ");
    }

    if (inquiryUnitModelIndex !== -1) {
      setData(itemList[inquiryUnitModelIndex].field.id, null);
      setData("inquiry_unit_model", {});
      itemList[inquiryUnitModelIndex].data.enum = [];
      if (value && value.property && value.property.id) {
        timer = setTimeout(async () => {
          const rs = await GetAllPropertyUnitModelsAPI({
            propertyId: value.property && value.property.id,
            pageIndex: 1,
            pageSize: 99999,
          });
          if (!rs || !rs.result) return;
          rs.result.map((element) => {
            itemList[inquiryUnitModelIndex].data.enum.push({
              name: `${element.propertyUnitModelName}`,
              area: element.area || null,
              bedroomNo: element.bedroomNo || null,
              bathroomNo: element.bathroomNo || null,
              primaryView: element.primaryData || null,
              rentPerYearFrom: element.rentPerYearFrom || null,
              rentPerYearTo: element.rentPerYearTo || null,
              secondaryView: element.secondaryData || null,
              sellingPriceFrom: element.sellingPriceFrom || null,
              sellingPriceTo: element.sellingPriceTo || null,
            });
          });
          setRerender(Math.random());
        }, 300);
      }
    }
    if(unitTypeIndex !== -1 && (value.property && value.property.id))
    {
      if (communityIndex !== -1)
        itemList[communityIndex].data.isReadonly = true ; 
    
      if (subCommunityIndex !== -1)
        itemList[subCommunityIndex].data.isReadonly = true ;

      itemList[unitTypeIndex].data.enum =  [] ;
      setTimeout(() => {
        setData(itemList[unitTypeIndex].field.id, []); 
      }, 50);
      timer = setTimeout(async () => {
        const res = await GetAllPropertyUnitTypeAPI(
          value.property && value.property.id ,  operationType
        );
        if ((res && res.data && res.data.ErrorId) || !res) return;
        if (unitTypeIndex !== -1) {
          const propertyUnitTypes =
            (res &&
              Array.isArray(res) &&
              res.length &&
              res[0] &&
              res[0].propertyUnitType) ||
            [];
          setTimeout(() => {
            if (propertyUnitTypes.length > 0) {
              propertyUnitTypes.map((ut) => {
                itemList[unitTypeIndex].data.enum.push({
                  lookupItemId: ut?.lookupItemId,
                  lookupItemName: ut?.lookupItemName,
                });
              });
            } else if (propertyUnitTypes.length === 0)
              itemList[unitTypeIndex].data.enum = [];
          }, 500);
          if (formType === 2) itemList[unitTypeIndex].data.isReadonly = false;
        }
        if (bedroomIndex !== -1) {
          const resAvailableBedrooms =
            (res &&
              Array.isArray(res) &&
              res.length &&
              res[0] &&
              res[0].availableBedrooms &&
              res[0].availableBedrooms) ||
            [];
          if (resAvailableBedrooms.length === 0)
            itemList[bedroomIndex].data.enum = [];
          else if (resAvailableBedrooms.length > 0)
            resAvailableBedrooms.map((b) => {
              itemList[bedroomIndex].data.enum.push(b);
            });
        }
        setRerender(Math.random());
      }, 300);
    }
    else if (unitTypeIndex !== -1 && !value.property) 
    {  if (communityIndex !== -1)
      itemList[communityIndex].data.isReadonly = false ; 
  
    if (subCommunityIndex !== -1)
      itemList[subCommunityIndex].data.isReadonly = false ;
    
      timer = setTimeout(async () => {
      const resUnitTyps = await lookupItemsGetId({
        lookupTypeId: UnitTypes.lookupTypeId,
      });
      if ((resUnitTyps && resUnitTyps.data && resUnitTyps.data.ErrorId) || !resUnitTyps) return;
      resUnitTyps &&
      resUnitTyps.map((ut) => {
        itemList[unitTypeIndex].data.enum.push({
            lookupItemId: ut?.lookupItemId,
            lookupItemName: ut?.lookupItemName,
          });
        });
      })
    }
  }
},50);
};
