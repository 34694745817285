import React, { useRef, useEffect, useCallback, useState, useReducer } from "react";
import moment from "moment";
import Button from "@material-ui/core/Button";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { PageHeading } from "../../../../../../SharedComponents";
import { Avatar, ButtonBase } from "@material-ui/core";
import { AutocompleteComponent, Inputs, SelectComponet, Tables } from "../../../../../../Components";
import { UsersManagementPermissions} from '../../../../../../Permissions' ; 
import {
  getDownloadableLink,
  getFirstLastNameLetters,
  sideMenuIsOpenUpdate,
  sideMenuComponentUpdate,
  getIsAllowedPermission
} from "../../../../../../Helper";
import {DeactivatedUserSideDetails} from './DeactivatedUserSideDetails';
import { AddUserDialog } from "../../Dialogs";
import { DateRangePickerComponent } from "../../../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import { OrganizationUserSearch } from "../../../../../../Services";
import { UpdatedUserStatus } from "../../../../../../Enums";

export const DeactivatedUsersView = ({
  parentTranslationPath,
  translationPath,
  usersData,
  totalCount,
  reloadData,
  setFilter,
  filter,
  onPageSizeChanged,
  onPageIndexChanged,
  setSelected,
  selected,
  setIsDatePickerChanged,
  dateRangeDefault,
  initialFilter
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const typingTimer = useRef(null);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [deactivatedUserId, setDeactivatedUserId] = useState(null);
  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  };
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useReducer(reducer, {
    systemUsers: [],
    branches: [],
  });
  const filterDateChanged = (selectedDate) => {
    setSelected({
      id: "dateFilter",
      value: {
        startDate: selectedDate?.selection?.startDate,
        endDate: selectedDate?.selection?.endDate,
        key: 'selection',
        selectedDateType: selectedDate.selection?.selectedDateType,
      },
    });
    setIsDatePickerChanged(false)
  };
  const getSystemUsers = async ({name ,userName}) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: name || "",
      userName:userName,
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        setData({ id: "systemUsers", value: localValue });
      } else setData({ id: "systemUsers", value: [] });
    }
  };

  const getTableActionsWithPermissions = (rowData) => {
    const actionsList = [];
    
   if (getIsAllowedPermission(
      Object.values(UsersManagementPermissions),
      loginResponse,
      UsersManagementPermissions.ReactiveUserButton.permissionsId
    )){
      const reActivateUserAction = (
        <Button className="btns propx-action-btn p-2 theme-propx outlined light-text"
          onClick={() => {
            setDeactivatedUserId(rowData.id);
            setIsAddUserDialogOpen(true);
          }}
        >
          {t(`${translationPath}reactivate`)}
        </Button>);
        actionsList.push(reActivateUserAction);

     }
    const viewInformationAction = (
      <span
        className="fw-simi-bold mx-2 pointer c-propx"
        onClick={() => (rowData.id ? campaignClickHandler(rowData) : null)}
      >
        {t(`${translationPath}show-details`)}
      </span>
    );
    actionsList.push(viewInformationAction);


    return actionsList;
  };
  const onClearedAllFiltersClick = useCallback(() => {
    setFilter((currentFilter) => ({
      ...initialFilter,             
      status: currentFilter.status,
    }));
    setSelected({
      id: "edit",
      value: {
        lineManager: null,
        branch: null,
        userName:null,
        dateFilter: dateRangeDefault
      },
    });
  }, []);
  const campaignClickHandler = useCallback((item) => {
    sideMenuComponentUpdate(
      <DeactivatedUserSideDetails
      taskId={item?.id}
      campaignId={item?.campaignId}
      buttonsGroup={"TasksView"}
      closeSideMenu={() => {
        sideMenuIsOpenUpdate(false);
      }}
      userId={item.id}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      reloadData={() => ''}
    />
    );
    sideMenuIsOpenUpdate(true);
  }, []);

  useEffect(
    () => () => {
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );
  useEffect(()=>{
    setFilter((items) => ({
    ...items,
    status: UpdatedUserStatus.DeactivatedUsers.value || 1,
  }));
  },[])
  return (
    <div className="w-80">
      <div className="d-flex-column">
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          headerTitle={"DeactivatedUsers"}
          subTitle={"dectivated-users-heading"}
          wrapperClasses={"px-4 w-50"}
          headerTitleClasses={"fz-16 mb-1"}
          subTitleClasses={"fz-14 m-0 ml-0"}
        />
        <div className="d-flex mt-3">
          <div className="w-100 px-4 d-flex flex-wrap">
            <div className="w-100 d-flex-center fa-center">
              <Inputs
                idRef="fullNameRef"
                labelClasses="mt-4"
                wrapperClasses="w-100 mt-2 mr-3"
                value={filter.userName || ''}
                onInputChanged={(event) => {
                  const userName = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    userName: userName || null,
                    userId: null,
                  }));
                }}
                inputPlaceholder={t(`${translationPath}full-Name`)}
              />
              <Inputs
                idRef="mobileNumber-ref"
                labelClasses="mt-4"
                wrapperClasses="w-100 mt-2 mr-3"
                value={filter.mobileNumber || ''}
                onInputChanged={(event) => {
                  const mobileNumber = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    mobileNumber: mobileNumber || null,
                  }));
                }}
                inputPlaceholder={t(`${translationPath}mobile-Number`)}
              />
              <div className="w-100 d-flex fa-start">
                <AutocompleteComponent
                  idRef="user-NameRef"
                  labelClasses="Requierd-Color"
                  inputPlaceholder={t(`${translationPath}user-Name`)}
                  selectedValues={selected.userName}
                  wrapperClasses="w-100 mr-2"
                  data={data.systemUsers || []}
                  withoutSearchButton
                  multiple={false}
                  displayLabel={(option) => option?.userName || ""}
                  onChange={(event, newValue) => {
                    setSelected({
                      id: "userName",
                      value: newValue,
                    });
                    setFilter((items) => ({
                      ...items,
                      userUserName: newValue?.userName || null,
                    }));
                  }}
                  onOpen={() => {
                    if (data.systemUsers && data.systemUsers.length == 0)
                      getSystemUsers({});
                  }}
                  onInputKeyUp={(e) => {
                    const { value } = e.target;
                    if (typingTimer) clearTimeout(typingTimer.current);
                    typingTimer.current = setTimeout(() => {
                      getSystemUsers({ userName: value });
                    }, 1200);
                  }}
                />
              </div>
            </div>
            <div className="w-100 d-flex fa-start">
              <Inputs
                idRef="email-ref"
                labelClasses="mt-4"
                wrapperClasses="w-50 mt-2 mr-3"
                value={filter.email || ''}
                onInputChanged={(event) => {
                  const email = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    email: email || null,
                  }));
                }}
                inputPlaceholder={t(`${translationPath}email`)}
              />
              <Inputs
                idRef="branch-NameRef"
                labelClasses="mt-4"
                wrapperClasses="w-50 mt-2 mr-3"
                value={filter.branchName || ''}
                onInputChanged={(event) => {
                  const branchName = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    branchName: branchName || null,
                  }));
                }}
                inputPlaceholder={t(`${translationPath}branch-Name`)}
              />
              <Inputs
                idRef="Createdby-ref"
                labelClasses="mt-4"
                wrapperClasses="w-50 mt-2 mr-2"
                value={filter.staffId || ''}
                onInputChanged={(event) => {
                  const staffId = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    staffId: staffId || null,
                  }));
                }}
                inputPlaceholder={t(`${translationPath}staff-ID`)}
              />
            </div>
            <div className='d-inline-flex'>
              <div className='item-filter' >
                <SelectComponet
                  data={[
                    { key: 1, value: t(`${translationPath}Deactivation-Date`) }
                  ]}
                  defaultValue={{
                    key: 1,
                    value: t(`${translationPath}Deactivation-Date`),
                  }}
                  value={selected.dateFilter?.selectedDateType || 1}
                  valueInput='key'
                  textInput='value'
                  isDisabled={isLoading}
                  onSelectChanged={(value) => {
                    setIsDatePickerChanged(true);
                    setSelected((f) => ({
                      ...f,
                      selectedDateType: value,
                    }));
                  }}
                  wrapperClasses='w-auto'
                  themeClass='theme-transparent'
                  idRef='Date_Select'

                />
              </div>
              <DateRangePickerComponent
                onClearClicked={() => {
                  setSelected({
                    id: "dataFilter",
                    value: dateRangeDefault,
                  });
                  filterDateChanged(dateRangeDefault);
                  setFilter((f) => ({
                    ...f,
                    startDate: null,
                    endDate: null
                  }));
                }}
                onDialogClose={() => setIsDatePickerChanged(true)}
                ranges={[selected.dateFilter]}
                // parentTranslationPath={parentTranslationPath}
                // translationPath={translationPath}
                onDateChanged={(selectedDate) => {
                  filterDateChanged(selectedDate)
                }}
              />
            </div>
          </div>
             <div className="mt-2">
            <ButtonBase
              onClick={onClearedAllFiltersClick}
              id="onClearedAllFiltersref"
              // disabled={isAllFormFieldsLoading}
              className="btns theme-solid bg-danger clear-all-btn px-4"
            >
              <span className="mdi mdi-filter-remove m-1" />
              {t(`${translationPath}clear-filters`)}
            </ButtonBase>
          </div>
        </div>
      </div>

      <div className="propx-view-container sm  m-3">
        <Tables
          data={usersData || []}
          headerData={[
            {
              id: 1,
              label: "user",
              component: (item) =>
                (item?.name ? (
                  <div className="d-flex-v-center">
                    <div className="campaign-team-f1">
                      <Avatar
                        className="avatars-wrapper team-avatar fz-12 sm-theme"
                        src={
                          item?.profileImage
                            ? getDownloadableLink(item?.profileImage)
                            : ""
                        }
                      >
                        {getFirstLastNameLetters(item?.name || "")}
                      </Avatar>
                    </div>
                    <div className="d-flex-column">
                      <span className="fz-12 fw-simi-bold">{item?.name || "-"}</span>
                      <span className="fz-10 text-tertiary">
                        {item?.email || "-"}
                      </span>
                    </div>
                  </div>
                ) : (
                  "-"
                )) || "",
            },
            {
              id: 2,
              label: "branch",
              input: "branch",
            },
            {
              id: 3,
              label: "activation-date",
              input: "activationDate",
              component: (item) =>
                (
                  <span>
                    {item.activationDate
                      ? moment(item.activationDate).format("LL")
                      : null}
                  </span>
                ) || "",
            },
            {
              id: 4,
              label: "deactivation-date",
              input: "deActivationDate",
              component: (item) =>
                (
                  <span>
                    {item.deActivationDate
                      ? moment(item.deActivationDate).format("LL")
                      : null}
                  </span>
                ) || "",
            },
            {
              id: 5,
              label: "actions",
              component: (item) =>
              (<span className="d-flex-center fj-start"> {getTableActionsWithPermissions(item) || ""} </span>)
            },
          ]}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          totalItems={totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          defaultActions={[]}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      {isAddUserDialogOpen && (
        <AddUserDialog
          isDialogOpen={isAddUserDialogOpen}
          reactiveUserId={deactivatedUserId}
          onSave={() => {
            setIsAddUserDialogOpen(false);
            reloadData();
          }}
          onClose={() => {
            setIsAddUserDialogOpen(false);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
};
