import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetAllActivities, GetAllActivitiesByUnitId } from '../../../Services';
import { Spinner } from '../../../Components';
import './style/ActivityDetails.scss';
const translationPath = '';

const ActivityDetailsComponent = ({
  data,
  parentTranslationPath,
  type,
  cardId,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [activities, setActivities] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
    search: '',
    guidAgentId: null,
  });

  const GetAllActivitiesAPI = async () => {
    let activitiesDate = { fromDate: null, toDate: null };
    setisLoading(true);

    const body = {
      ...filter,
      isWithoutRelatedActivities: false,
      pageIndex: filter.pageIndex + 1,
      pageSize: filter.pageSize,
      relatedUnitId: type === 'unit' ? cardId : null,
      relatedLeadId: type === 'lead' ? cardId : null,
      contactId: type === 'contact' ? cardId : null,
      unitId: type === 'unitLeadOwner' ? cardId : null,
    }

    let res;
    if (type === 'unitLeadOwner') {
      res = await GetAllActivitiesByUnitId(body);
    } else {
      res = await GetAllActivities(body);
    }
    if (!(res && res.status && res.status !== 200)) {
      setActivities({
        result: (res && res.result) || [],
        totalcount: (res && res.totalcount) || 0,
      });
    } else {
      setActivities({
        result: [],
        totalcount: 0,
      });
    }
    setisLoading(false);
  };

  useEffect(() => {
    GetAllActivitiesAPI();
  }, [filter, cardId])

  return (
    <div className="container__activity__details">
      <div className="px-3 mb-3 slider-data">
        <Spinner isActive={isLoading} isAbsolute />
        <div className="mb-3">
          <div>
            {activities && activities.result && activities.result.length > 0 ? (
              activities.result.map((element) => (
                <div className="cards-wrapper" key={element.activityId}>
                  <div>
                    {element.isOpen ? (
                      <span className="open-status">
                        {t(`${translationPath}opened`)}
                      </span>
                    ) : (
                      <span className="close-status">
                        {t(`${translationPath}closed`)}
                      </span>
                    )}
                  </div>
                  <div className="mb-3">
                    <span className="mr-1 texts gray-primary-bold">
                      {t(`${translationPath}assigned-to`)}:
                    </span>
                    <span className="texts s-gray-primary">{`${element.assignedTo || element.agentName
                      || ''
                      }`}</span>
                  </div>

                  <div className="date mb-3">
                    <span className="date mr-1 texts gray-primary-bold">
                      <span className="details-icon mdi mdi-calendar mdi-18px mr-1 " />
                    </span>
                    <span className="date texts s-gray-primary">{`${moment(
                      element.activityDate
                    ).format('DD/MM/YYYY')}`}</span>
                    <span className="date mr-1 texts gray-primary-bold">
                      <span className="date mdi mdi-clock-time-four-outline ml-4 mr-1" />
                    </span>
                    <span className="date texts s-gray-primary">{`${moment(
                      element.activityDate
                    ).format('LT')}`}</span>
                  </div>

                  <div className="mb-3">
                    <span className="mr-1 texts gray-primary-bold">
                      {t(`${translationPath}created-by`)}:
                    </span>
                    <span className="texts s-gray-primary">{`${element.createdByName || ''
                      }`}</span>
                  </div>
                  <div className="mb-3">
                    <span className="mr-1 texts gray-primary-bold">
                      {t(`${translationPath}category`)}:
                    </span>
                    <span className="texts s-gray-primary">{`${element.categoryName || element.activityType.
                      categoryName || ''
                      }`}</span>
                  </div>
                  <div className="mb-3">
                    <span className="mr-1 texts gray-primary-bold">
                      {t(`${translationPath}activityName`)}:
                    </span>
                    <span className="texts s-gray-primary">{`${element.activityTypeName || element.subject || ''
                      }`}</span>
                  </div>
                  <div className="mb-3">
                    <span className="mr-1 texts gray-primary-bold">
                      {t(`${translationPath}comments`)}:
                    </span>
                    <span className="texts s-gray-primary">{`${element.comments || ''
                      }`}</span>
                  </div>
                  {/* <div className="mb-3">
                    <span className="mr-1 texts gray-primary-bold">
                      {t(`${translationPath}listing-agent`)}:
                    </span>
                    <span className="texts s-gray-primary">{`${
                      element.comments || ''
                    }`}</span>
                  </div> */}
                </div>
              ))
            ) : (
              <div className="no_item">No activities</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityDetailsComponent;
