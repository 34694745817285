import React from 'react';
import {
  Card, Typography, Grid, IconButton, Tooltip, Fab
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DeleteDialog from '../BusinessGroupsDialogs/DeleteDialog';
import { BusinessGroupsPermissions } from '../../../../../../Permissions';
import { PermissionsComponent } from '../../../../../../Components';

const Genealogy = (props) => {
  const { t } = useTranslation('BusinessGroupsView');
  const [deletedName, setDeletedName] = React.useState('');
  const [deletedId, setDeletedId] = React.useState(0);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(true);
  const TypeOfGenealogy = props.ChartType;

  const getChildByPairantId = (id) => {
    if (props.response && props.response.result)
      return props.response.result.filter((w) => w.businessGroupsParentId === id);
    return [];
  };

  return (
    <>
      {props.isVisible && props.Parent !== null && (
        <li
          className={`team-view-tree-wrapper  BusinessGroupsView ${props.Parent.businessGroupsParentId === null && ('isParent') || ('ischiled')} ${TypeOfGenealogy === 'filetree' && ('filetree') || ('')} `}
        >
          <div
            className={
              `${props.searchNode}` &&
                Array.isArray(props.searchNode) &&
                props.searchNode.find((el) => el.businessGroupsId === props.Parent.businessGroupsId) ?
                'searchedCard member-view-box' :
                'member-view-box'
            }
          >
            <div className='member-card no-bottom'>
              <Card
                className='team-item-detailes cardControl'
                onClick={() =>
                (getChildByPairantId(props.Parent.businessGroupsId).length > 0 ?
                  setIsVisible(!isVisible) :
                  '')}
              >
                <Grid spacing={3} container direction='row' justify='center' alignItems='center'>
                  <Grid item>
                    <Tooltip title={props.Parent.businessGroupsName}>
                      <Typography className='memberDetails truncateLongTexts'>
                        {props.Parent.businessGroupsName}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Card>
              <div className='tree-node-actions'>
                <div className='add-action'>
                  <PermissionsComponent
                    permissionsList={Object.values(BusinessGroupsPermissions)}
                    permissionsId={BusinessGroupsPermissions.AddRecordCard.permissionsId}
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        props.handleSentData(
                          props.Parent.businessGroupsName,
                          props.Parent.businessGroupsParentName,
                          props.Parent.businessGroupsId,
                          false,
                          props.Parent.businessGroupsParentId
                        );
                        props.setTreeGroup(true);
                        props.setopenAddDialog();
                      }}
                    >
                      <span className='mdi mdi-plus icon-arrow' title={t('Add Group')} />
                    </IconButton>
                  </PermissionsComponent>
                </div>
                <div className='add-action Edit'>
                  <PermissionsComponent
                    permissionsList={Object.values(BusinessGroupsPermissions)}
                    permissionsId={BusinessGroupsPermissions.EditRecordCard.permissionsId}
                  >
                    <IconButton
                      id='edit-file-ref'
                      onClick={(e) => {
                        e.stopPropagation();
                        props.handleSentData(
                          props.Parent.businessGroupsName,
                          props.Parent.businessGroupsParentName,
                          props.Parent.businessGroupsId,
                          true,
                          props.Parent.businessGroupsParentId
                        );
                        props.setTreeGroup(true);
                        props.setopenAddDialog();
                      }}
                    >
                      <span
                        className='mdi mdi-pencil-outline Edit'
                        title={t('Edit')}
                      />
                    </IconButton>
                  </PermissionsComponent>
                </div>
                <div className='add-action Deleted'>
                  <PermissionsComponent
                    permissionsList={Object.values(BusinessGroupsPermissions)}
                    permissionsId={BusinessGroupsPermissions.DeleteRecordCard.permissionsId}
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeletedId(props.Parent.businessGroupsId);
                        setDeletedName(props.Parent.businessGroupsName);
                        setDeleteDialog(true);
                      }}
                    >
                      <span
                        className='mdi mdi-trash-can-outline Deleted '
                        title={t('Delete')}
                      />
                    </IconButton>
                  </PermissionsComponent>
                </div>
                {getChildByPairantId(props.Parent.businessGroupsId).length !== 0 && (
                  <div className='expand-action'>
                    <IconButton onClick={() =>
                    (getChildByPairantId(props.Parent.businessGroupsId).length > 0 ?
                      setIsVisible(!isVisible) :
                      '')}
                    >

                      <span className={`mdi mdi-chevron-${isVisible ? 'up' : 'down'}`} />
                    </IconButton>
                  </div>
                ) || ''}

              </div>
            </div>
          </div>

          {isVisible && getChildByPairantId(props.Parent.businessGroupsId).length > 0 && (
            <ul>
              {getChildByPairantId(props.Parent.businessGroupsId).map((item, i) => (
                <Genealogy
                  key={i}
                  searchNode={props.searchNode}
                  isVisible={isVisible}
                  isTree={props.isTree}
                  setTreeGroup={(x) => props.setTreeGroup(x)}
                  reloadData={() => props.reloadData()}
                  Parent={item}
                  response={props.response}
                  handleSentData={props.handleSentData}
                  setopenAddDialog={() => props.setopenAddDialog()}
                />
              ))}
            </ul>
          )}
        </li>
      )}
      <DeleteDialog
        isTree={props.isTree}
        open={deleteDialog}
        close={() => setDeleteDialog(false)}
        deletedId={deletedId}
        name={deletedName}
        reloadData={() => props.reloadData(props.page + 1, props.rowsPerPage, props.searchedItem)}
      />
    </>
  );
};

export default Genealogy;
