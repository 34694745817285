import React, { useCallback, useReducer, useRef, useState } from "react";
import { Inputs, SelectComponet } from "../../../../Components";
import { useTranslation } from "react-i18next";
import { DateRangePickerComponent } from "../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import moment from "moment";
import { ButtonBase } from "@material-ui/core";

function ConsentLogFilters({
  filter,
  setFilter,
  translationPath,
  parentTranslationPath,
}) {
  const searchTimer = useRef(null);
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: "selection",
  };

  const [selected, setSelected] = useReducer(reducer, {});
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);

  const filterByChanged = (value) => {
    setFilter({ id: "filterBy", value: value });
  };
  const orderByChanged = (value) => {
    setFilter({ id: "orderBy", value: value });
  };
  const onConsentChannelChange = (value) => {
    setFilter({ id: "consentChannel", value: value });
  };
  const onResponseReceivedChanged = (value) => {
    setFilter({ id: "responseReceived", value: value });
  };
  const onClearFiltersClicked = () => {
    setFilter({
      id: "edit",
      value: {
        pageIndex: filter.pageIndex,
        pageSize: filter.pageSize,
        filterBy: "createdOn",
        orderBy: 1,
        fromDate: null,
        toDate: null,
        consentChannel: null,
        senderName: null,
        responseReceived: null,
      },
    });
    setDateFilter(dateRangeDefault);
    setSelected({
      id: "edit",
      value: {
        senderName: "",
      },
    });
  };

  const onClearDatePickerClicked = () => {
    setDateFilter(dateRangeDefault);
    setFilter({
      id: "edit",
      value: {
        ...filter,
        fromDate: null,
        toDate: null,
      },
    });
  };

  return (
    <React.Fragment>
      <div>
        <div>
          <div className="px-2">
            <div className="">
              <div className="d-flex">
                <div className="w-25 mx-2">
                  <Inputs
                    idRef="senderNameRef"
                    value={selected?.senderName || ""}
                    inputPlaceholder="sender-name"
                    onInputChanged={(e) => {
                      const { value } = e.target;
                      setSelected({ id: "senderName", value: value });
                    }}
                    onKeyUp={(e) => {
                      const { value } = e.target;
                      if (searchTimer.current)
                        clearTimeout(searchTimer.current);
                      searchTimer.current = setTimeout(() => {
                        setFilter({ id: "senderName", value: value || "" });
                      }, 700);
                    }}
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                  />
                </div>
                <div className="w-25 mx-2">
                  <SelectComponet
                    idRef="consentChannelRef"
                    data={[
                      { id: 1, text: "Email" },
                      { id: 2, text: "SMS" },
                      { id: 3, text: "Whatsapp" },
                    ]}
                    value={filter?.consentChannel}
                    onSelectChanged={(value) => {
                      onConsentChannelChange(value);
                    }}
                    wrapperClasses="mb-3"
                    isRequired
                    valueInput="id"
                    textInput="text"
                    emptyItem={{
                      value: null,
                      text: "select-consent-channel",
                      isDisabled: false,
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className="w-25 mx-2">
                  <SelectComponet
                    idRef="responseReceivedRef"
                    data={[
                      { value: true, text: "yes" },
                      { value: false, text: "no" },
                    ]}
                    value={filter?.responseReceived}
                    onSelectChanged={(value) => {
                      onResponseReceivedChanged(value);
                    }}
                    wrapperClasses="mb-3"
                    isRequired
                    valueInput="value"
                    textInput="text"
                    emptyItem={{
                      value: null,
                      text: "select-response-received",
                      isDisabled: false,
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className="w-25 mx-2">
                  <DateRangePickerComponent
                    ranges={[dateFilter]}
                    onClearClicked={() => onClearDatePickerClicked()}
                    onDateChanged={(selectedDate) => {
                      setDateFilter({
                        startDate:
                          selectedDate.selection &&
                          selectedDate.selection.startDate,
                        endDate: new Date(
                          moment(
                            selectedDate.selection &&
                              selectedDate.selection.endDate
                          ).endOf("day")
                        ),
                        key: "selection",
                      });

                      setFilter({
                        id: "edit",
                        value: {
                          ...filter,
                          fromDate:
                            selectedDate.selection &&
                            selectedDate.selection.startDate,
                          toDate: new Date(
                            moment(
                              selectedDate.selection &&
                                selectedDate.selection.endDate
                            ).endOf("day")
                          ),
                        },
                      });
                    }}
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="w-50 d-flex">
                <div className="w-33  px-2">
                  <SelectComponet
                    idRef="filterByRef"
                    data={[
                      { id: "createdOn", filterBy: "created-on" },
                      { id: "ConsentChannel", filterBy: "consent-channel" },
                      {
                        id: "SenderBranchName",
                        filterBy: "sender-branch-name",
                      },
                      { id: "ResponseReceived", filterBy: "response-received" },
                    ]}
                    value={filter?.filterBy}
                    onSelectChanged={(value) => {
                      filterByChanged(value);
                    }}
                    wrapperClasses="mb-3"
                    isRequired
                    valueInput="id"
                    textInput="filterBy"
                    emptyItem={{
                      value: null,
                      text: "select-filter-by",
                      isDisabled: false,
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className="w-33 px-2">
                  <SelectComponet
                    idRef="orderByRef"
                    data={[
                      { id: 1, orderBy: "ascending" },
                      { id: 2, orderBy: "descending" },
                    ]}
                    value={filter.orderBy}
                    onSelectChanged={(value) => {
                      orderByChanged(value);
                    }}
                    wrapperClasses="mb-3"
                    isRequired
                    valueInput="id"
                    textInput="orderBy"
                    emptyItem={{
                      value: null,
                      text: "select-sort-by",
                      isDisabled: false,
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className="w-33 px-2">
                  <ButtonBase
                    onClick={onClearFiltersClicked}
                    className="btns theme-solid bg-danger clear-all-btn max-height"
                  >
                    <span className="mdi mdi-filter-remove m-1" />
                    {t("clear-filters")}
                  </ButtonBase>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ConsentLogFilters;
