import React  from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';

  export const SurroundingsContent = ({
    parentTranslationPath ,
    translationPath ,
    state ,
    setState ,
   } ) => { 

    const { t } = useTranslation(parentTranslationPath);

    return (
      <>
        <div className="d-flex-column">
        <span className="fw-bold"> {t(`${translationPath}Surroundings`)} </span>
        <span className="mt-2"> {t(`${translationPath}landmark-within-1KM`)} </span>
        </div> 
        <div className='w-100'>
         {    state && state.updatePropertyRatingSurroundingDtos &&  state.updatePropertyRatingSurroundingDtos.map((item, index) => 
            (
            <div className="d-flex d-flex-v-center surroundings-card  b-bottom" key={`surroundings${index}`}>
            <div>
            <span className='fw-bold'> {item.lookupItemName} </span> 
            </div>
            <div>
            <Rating  
                size="large"
                name={`surroundings${index}`}
                max={10}
                value={item.score}
                precision={1}
                onChange={(event, newValue) => 
                {
                  const updateList = [...state.updatePropertyRatingSurroundingDtos];
                  updateList[index].score= newValue || 0 ;
                  setState({id : 'updatePropertyRatingSurroundingDtos' , value : updateList })
                }}
                />     
              </div>
           </div>  
          )) } 
        </div>
      </>
    );
     } ; 
  

