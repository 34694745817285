import React, { useState, useRef } from 'react';
import SunEditor from 'suneditor-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { showError, showSuccess, GetParams, GlobalHistory } from '../../../../../../../../Helper';
import { RejectCampaignRequest } from '../../../../../../../../Services';
import { DialogComponent, Spinner } from '../../../../../../../../Components';
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
  blockquote,
  video,
  audio,
  math,
  imageGallery,
} from 'suneditor/src/plugins';

export const CampaignRequestRejectDialog = ({ isOpen, close }) => {
  const parentTranslationPath = 'Campaign';
  const translationPath = '';
  const marketingCampaignsInfo = useSelector((state) => state.MarketingCampaignsReducer);

  const { t } = useTranslation(parentTranslationPath);
  const editor = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [statusNote, setStatusNote] = useState('');
  const [content, setContent] = useState('');

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  const onContentChanged = (newValue) => {
    setStatusNote(newValue);
  };

  const isSaveDisabled = () => {
    return statusNote && statusNote !== '<p><br></p>' && !isLoading ? false : true;
  };

  const rejectCampaignRequest = async () => {
    setIsLoading(true);

    const body = {
      id: marketingCampaignsInfo?.campaignRequestId,
      statusNote,
    };
    const res = await RejectCampaignRequest(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}campaign-req-reject-successfully`));
      close();
      GlobalHistory.push(`/home/campaign-requests`);
    } else {
      showError(t(`${translationPath}campaign-req-reject-failed`));
      GlobalHistory.push(`/home/campaign-requests`);
    }
    setIsLoading(false);
  };

  const saveHandler = async (event) => {
    event.preventDefault();
    rejectCampaignRequest();
  };

  return (
    <DialogComponent
      titleText='Request-Rejection-reason'
      saveText='save'
      saveIsDisabled={isSaveDisabled()}
      maxWidth='sm'
      dialogContent={
        <div className='unit-status-draft-dialog-wrapper view-wrapper'>
          <Spinner isActive={isLoading} isAbsolute />
          <div className='release-note-sunEditor my-2' onMouseLeave={() => onContentChanged(content)}>
            <SunEditor
              getSunEditorInstance={getSunEditorInstance}
              idRef='refs'
              setOptions={{
                showPathLabel: false,
                minHeight: '180px',
                maxHeight: '180px',
                placeholder: 'Enter text',
                plugins: [
                  align,
                  font,
                  fontColor,
                  fontSize,
                  formatBlock,
                  hiliteColor,
                  horizontalRule,
                  lineHeight,
                  list,
                  paragraphStyle,
                  table,
                  template,
                  textStyle,
                  image,
                  link,
                  blockquote,
                  video,
                  audio,
                  math,
                  imageGallery,
                ],
                buttonList: [['bold', 'italic', 'link', 'align', 'list']],
              }}
              setContents={setContent}
              onChange={(event) => {
                setContent(event);
              }}
            />
          </div>
        </div>
      }
      isOpen={isOpen}
      onSubmit={saveHandler}
      onCancelClicked={close}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};
