import { ButtonBase } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TableColumnsFilterComponent, Tables } from '../../../../../Components';
import {
  ContactPreferenceEnum,
  TableActions,
  FormsIdsEnum,
  ColumnsFilterPagesEnum,
  TableFilterTypesEnum,
  HighlightRowKeys,
  ActionsButtonsEnum,
} from '../../../../../Enums';
import {
  GlobalHistory, GlobalTranslate, showError, WhatsAppMessage, returnPropsByPermissions,
  showWarn,
  CheckIsCommunicateAllowed,
  CheckIsWithinWorkingHours,
  sideMenuComponentUpdate
} from '../../../../../Helper';
import { ActiveItemActions } from '../../../../../store/ActiveItem/ActiveItemActions';
import { DevelopersTableHeaderData } from './DevelopersTableHeaderData';
import { TableColumnsFilterActions } from '../../../../../store/TableColumnsFilter/TableColumnsFilterActions';
import { ContactsActionDialogsComponent } from '../../../ContactsView/ContactsUtilities/ContactsActionDialogsComponent/ContactsActionDialogsComponent';
import { ActivitiesManagementDialog } from '../../../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog';
import { useTranslation } from 'react-i18next';
import { GetAllFormFieldsByFormId } from '../../../../../Services'
import { ConsentActionsDialog, WorkingHoursConfirmDialog } from "../../../../../SharedComponents";
import {   CardDetailsComponent } from '../../DevelopersViewComponent'

export const DevelopersTableComponent = ({
  filter,
  pathName,
  isOneSelect,
  checkedCards,
  setCheckedCards,
  checkedCardsIds,
  setCheckedCardsIds,
  detailsDevelopersList,
  activeSelectedAction,
  developerTableFilter ,
  onFilterValuesChanged,
  parentTranslationPath,
  translationPath,
  setActiveTab,
  isWithCheckboxColumn,
  setOrderBy,
  isClearFiltersClicked,
  setIsClearFiltersClicked,
  closeSideMenu , 
  formId  ,
  setFilter ,
  setActiveCard ,
  sideMenuIsOpenUpdate  , 
  reloadData ,
  loginResponse ,
  onActionClicked ,
  detailedCardSideActionClicked , 
  DevelopersTableComponent ,
  onRowClick   ,
  isFirst , 
  activeActionType , 
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(parentTranslationPath);
  const [tableColumns, setTableColumns] = useState([]);
  const [allFormFields, setAllFormFields] = useState([]);
  const [isAllFormFieldsLoading ,setIsAllFormFieldsLoading] = useState(false) ; 

  const [isOpenContactsActionDialog, setisOpenContactsActionDialog] = useState(false);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [addActivity, setAddActivity] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
   const [detailedCardAction, setdetailedCardAction] = useState(() => ({
    actionEnum: '',
    item: '',
  }));
  const [selectedTableFilterColumns, setSelectedTableFilterColumns] = useState(
    DevelopersTableHeaderData.filter((item) => item.isDefaultFilterColumn).map((column) => column.id)
  );
  const [isConsentActionDialogOpen, setIsConsentActionDialogOpen] = useState(false);
  const [isWorkingHoursDialogOpen, setIsWorkingHoursDialogOpen] = useState(false);
  // const loginResponse = useSelector((state) => state.login.loginResponse);
  const tableColumnsFilterResponse = useSelector((state) => state.TableColumnsFilterReducer);
  const [defaultPrefernces, setDefaultPrefernces] = useState([
    {
      enum: TableActions.openFile.key,
    },
    {
      enum: TableActions.addActivity.key,
    },
  ]);
  const [activePrefernces, setActiveprefernces] = useState(defaultPrefernces);

  const contactActionsHandler = (actionEnum, item) => {

    if (actionEnum === TableActions.whatsappSolid.key) {
      const el = document.createElement('a');
      if (item?.mobile?.phone) {
        el.href = WhatsAppMessage(item?.whatsapp_mobile?.phone || item.mobile.phone);
        el.target = 'blank';
        el.click();
      } else showError(GlobalTranslate.t('Shared:Failure-Open-WhatsApp'));
    } else if (actionEnum === TableActions.smsSolid.key || actionEnum === TableActions.emailSolid.key) {
      setisOpenContactsActionDialog(true);
    }
  }

    
  const checkIsActionAllowed = async (contactId) => {
    const isWithinWorkingHours = await CheckIsWithinWorkingHours({ contactId });
    const isCommunicateAllowed = await CheckIsCommunicateAllowed({ contactId });
    return (isWithinWorkingHours && isCommunicateAllowed) || false;
  };

  const unAllowedActionHandler = async (contactId) => {
    const isWithinWorkingHours = await CheckIsWithinWorkingHours({ contactId });
    const isCommunicateAllowed = await CheckIsCommunicateAllowed({ contactId });

    if (!isWithinWorkingHours) {
      setIsWorkingHoursDialogOpen(true);
      return;
    }
    if (!isCommunicateAllowed) {
      setIsConsentActionDialogOpen(true);
      return;
    }
  };


  const tableActionClicked = useCallback(
    async (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      setActiveItem(item);
      const isActionAllowed = await checkIsActionAllowed(item?.id); 

      dispatch(ActiveItemActions.activeItemRequest(item));
      if((actionEnum === TableActions.smsSolid.key ||
         actionEnum === TableActions.emailSolid.key ||
         actionEnum === TableActions.whatsappSolid.key)) setdetailedCardAction({
        actionEnum,
        item,
      }); else setdetailedCardAction({
        actionEnum: '',
        item: '',
      });
     if (actionEnum === TableActions.openFile.key) {
      if (pathName === 'developers/view') {
        GlobalHistory.push(
          `/home/developers/developer-profile-edit?form=${formId}&id=${item.id}`
        );
        if(closeSideMenu)
        closeSideMenu() ; 
       }
    } else if (actionEnum === TableActions.addActivity.key) {
      setAddActivity(true);
    } else if(isActionAllowed) contactActionsHandler(actionEnum, item);
    else if(!isActionAllowed)  unAllowedActionHandler(item?.id);

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, pathName ,formId]
  );
  const getIsSelected = useCallback(
    (row) => checkedCards && checkedCards.findIndex((item) => item.id === row.id) !== -1,
    [checkedCards]
  );
  const getIsDisabled = useCallback(
    (row) =>
      (isOneSelect && checkedCardsIds && checkedCardsIds.length === 9 && !getIsSelected(row)) ||
      (checkedCardsIds && checkedCardsIds.length > 9 && !getIsSelected(row)) ||
      (checkedCards && checkedCards[0] && checkedCards[0].userTypeId !== row?.userTypeId),
    [checkedCards, checkedCardsIds, getIsSelected, isOneSelect]
  );
  const onSelectClicked = useCallback(
    (row) => {
      const isObsoleteContact = checkedCardsIds && checkedCardsIds.length !== 0;
      const isContactWithTransaction = (isObsoleteContact && row.isWithTransaction === 1 && !getIsSelected(row))

      const itemIndex = checkedCardsIds ? checkedCardsIds.findIndex((item) => item === row.id) : -1;
      if (itemIndex !== -1) {
        checkedCardsIds.splice(itemIndex, 1);
        setCheckedCards((items) => {
          const elementIndex = items.findIndex((item) => item.id === row.id);
          if (elementIndex !== -1) items.splice(elementIndex, 1);
          return [...items];
        });
      } else if (!isContactWithTransaction) {
        checkedCardsIds.push(row.id);
        setCheckedCards((items) => {
          items.push(row);
          return [...items];
        });
      } else if (isContactWithTransaction) showError(`Absolete Contacts Can't Have Transactions`);

      setCheckedCardsIds(checkedCardsIds);
    },
    [checkedCardsIds, setCheckedCards, setCheckedCardsIds]
  );

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));

  };

  const focusedRowChanged = useCallback(
    (rowIndex, item) => {
      if (rowIndex !== -1 && onRowClick && isFirst) onRowClick(item, rowIndex);
      const currentActiveActions = defaultPrefernces;


      // item && item.contactPreference.map((el) => {
      //   const index = Object.values(ContactPreferenceEnum).findIndex(
      //     (subItem) => (subItem.key === el.lookupItemId)
      //   );
      //   const actionEnumKey = Object.values(ContactPreferenceEnum)[index].actionEnumKey;
      //   const actionValue = Object.values(ContactPreferenceEnum)[index].value;

      //   const isItemExist = currentActiveActions.find((e) => e.enum === actionEnumKey)
      //   if (index !== -1 && !isItemExist && checkIfSensitiveField && checkIfSensitiveField(actionValue, item, item.contact_type_id)) {
      //     currentActiveActions.push({
      //       enum: actionEnumKey,
      //     });
      //   }
      // });

      // if (currentActiveActions.length > 5) {
      //   const whatsAppIndex = currentActiveActions.findIndex(
      //     (subItem) => subItem.enum === 'phoneSolid'
      //   );
      //   if (whatsAppIndex !== -1) {
      //     currentActiveActions.splice(whatsAppIndex, 1);
      //     currentActiveActions.push({
      //       enum: 'dotsHorizontal',
      //     });
      //   }
      // }
      setActiveprefernces(currentActiveActions);
    },
    [defaultPrefernces, onRowClick]
  );

  
  const getAllFormFieldsByFormId = useCallback(async () => {
    setIsAllFormFieldsLoading(true);
    Promise.all([
      await GetAllFormFieldsByFormId(formId),
    ])
      .then((result) => {
        if (Array.isArray(result[0])) {
          const concantinateFields = result[0]
            .filter(
              (field, index, array) =>
                array.findIndex((element) => element.formFieldKey === field.formFieldKey) === index
            );
          const list = concantinateFields.filter(e =>
            e.formFieldName !== 'developer_logo' &&
            e.formFieldName !== 'contact_photo' &&
            e.formFieldName !== 'map'
          ).map((field) => ({
            ...field,
            id: field.formFieldId || null,
            key: field.formFieldKey || null,
            isDate: field.uiWidgetType === 'alt-date' || false,
            label: (field.formFieldTitle && field.formFieldTitle.replace('*', '')) || '',
            input: field.displayPath || '',
            isNumber:
              field.propertyJson && JSON.parse(field.propertyJson).schema &&
              field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'currency' ||
              field.propertyJson && JSON.parse(field.propertyJson).schema &&
              field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'decimal' ||
              field.propertyJson && JSON.parse(field.propertyJson).schema &&
              field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'size'
            ,
            isSortable: true,
            searchableKey: field.searchableKey,
          }));
          setAllFormFields(list);
        } else setAllFormFields([]);
        setIsAllFormFieldsLoading(false);
      })
      .catch(() => {
        setAllFormFields([]);
        setIsAllFormFieldsLoading(false);
      });
  }, [formId]);

  useEffect(() => {
    if(formId)
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId]);

  useEffect(() => {
    if (
      tableColumnsFilterResponse &&
      tableColumnsFilterResponse[ColumnsFilterPagesEnum[17].key]
    ) {
      setSelectedTableFilterColumns(
        tableColumnsFilterResponse[ColumnsFilterPagesEnum[17].key]
      );
    }
  }, [tableColumnsFilterResponse]);

  useEffect(() => {
    setTableColumns([
      ...DevelopersTableHeaderData.filter(
        (item) => selectedTableFilterColumns.findIndex((element) => element === item.id) !== -1
      ),
       ... allFormFields
        .filter(
          (item) =>
            selectedTableFilterColumns.findIndex((element) => element === item.formFieldId) !== -1
        )
        .map((field) => ({
          id: field.formFieldId || null,
          key: field.formFieldKey || null,
          isDate: field.uiWidgetType === 'alt-date' || false,
          label: (field.formFieldTitle && field.formFieldTitle.replace('*', '')) || '',
          input: field.displayPath || '',
          isNumber:
            field.propertyJson && JSON.parse(field.propertyJson).schema &&
            field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'currency' ||
            field.propertyJson && JSON.parse(field.propertyJson).schema &&
            field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'decimal' ||
            field.propertyJson && JSON.parse(field.propertyJson).schema &&
            field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'size'
          ,
          isSortable: true,
          searchableKey: field.searchableKey,
        })),
    ]);

  }, [allFormFields, selectedTableFilterColumns, developerTableFilter]);

  useEffect(() => {
    setTableFilterData(
      tableColumns.map((column) => ({
        key: column.key || column.fieldKey || column.id,
        filterType:
          (column.isDate && TableFilterTypesEnum.datePicker.key) ||
          (column.isNumber && TableFilterTypesEnum.numberInput.key) ||
          TableFilterTypesEnum.textInput.key,
        isHiddenFilter: column.isHiddenFilter,
        isHidden: column.isHidden,
        textInputType: column.textInputType,
        textInputMax: column.textInputMax,
        textInputMin: column.textInputMin,
        searchableKey: column.searchableKey || column.input,
        displayPath:
          (column.key && column.input) ||
          (column.fieldKey &&
            allFormFields &&
            allFormFields.findIndex((item) => item.formFieldKey === column.fieldKey) !== -1 &&
            allFormFields.find((item) => item.formFieldKey === column.fieldKey).displayPath)
          || undefined,
      }))
    );
  }, [allFormFields, tableColumns]);


  useEffect(() => {
    if (sortBy)
      setOrderBy((item) => ({ ...item, filterBy: sortBy.filterBy && sortBy.filterBy[0].toLowerCase() + sortBy.filterBy.slice(1), orderBy: sortBy.orderBy, fieldType: sortBy.fieldType }));
  }, [sortBy]);




  return (
    <div className='w-100 px-3'>

      <TableColumnsFilterComponent
        columns={DevelopersTableHeaderData.filter(item => !item.isHidden ).concat(
          allFormFields &&  allFormFields.filter(
            (item) =>
              DevelopersTableHeaderData.findIndex(
                (element) => element.fieldKey === item.formFieldKey
              ) === -1
          )
        ).map((item) => ({
          key: item.formFieldId || item.id,
          value: (item.formFieldTitle && item.formFieldTitle.replace('*', '')) || item.label,
        }))}
        isLoading={isAllFormFieldsLoading}
        selectedColumns={selectedTableFilterColumns}
        onSelectedColumnsChanged={(newValue) => {
          setSelectedTableFilterColumns(newValue);
          let localTableColumnsFilterResponse = tableColumnsFilterResponse;
          if (localTableColumnsFilterResponse)
            localTableColumnsFilterResponse[ColumnsFilterPagesEnum[17].key] = newValue;
          else {
            localTableColumnsFilterResponse = {
              [ColumnsFilterPagesEnum[17].key]: newValue,
            };
          }
          dispatch(
            TableColumnsFilterActions.TableColumnsFilterRequest(localTableColumnsFilterResponse)
          );
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath=''
      />
      <Tables
        isWithCheckboxColumn={[
          'merge',
          ActionsButtonsEnum[4].id,
          ActionsButtonsEnum[29].id,
        ].includes(activeSelectedAction)}
        data={detailsDevelopersList.result}
        HideSellectAll
        TotalSelect={(checkedCardsIds && checkedCardsIds.length) || 0}
        selectAllOptions={
          (["merge", ActionsButtonsEnum[4].id, ActionsButtonsEnum[29].id].includes(activeSelectedAction) && {
            selectedRows: checkedCardsIds,
            getIsSelected,
            disabledRows: [],
            getIsDisabled,
            onSelectClicked,
          }) ||
          undefined
        }
        headerData={tableColumns}
        defaultActions={activePrefernces}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        itemsPerPage={filter.pageSize}
        activePage={filter.pageIndex}
        parentTranslationPath={parentTranslationPath}
        focusedRowChanged={focusedRowChanged}
        totalItems={detailsDevelopersList ? detailsDevelopersList.totalCount : 0}
        filterValues={developerTableFilter}
        onFilterValuesChanged={onFilterValuesChanged}
        filterData={tableFilterData}
        isWithFilter
        setSortBy={setSortBy}
        externalPopoverComponent={(
          <ButtonBase
            className={TableActions.whatsappSolid.buttonClasses}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <span className={TableActions.whatsappSolid.icon} />
          </ButtonBase>
        )}
        setIsClearFiltersClicked={setIsClearFiltersClicked}
        isClearFiltersClicked={isClearFiltersClicked}
        isLoading={isAllFormFieldsLoading}
        localStorageKey='DevelopersFilter'
        highlightedRowKey={HighlightRowKeys.Contacts.key}
      />

      <ContactsActionDialogsComponent
        isOpen={isOpenContactsActionDialog}
        isOpenChanged={() => setisOpenContactsActionDialog(false)}
        actionEnum={detailedCardAction.actionEnum}
        item={detailedCardAction.item}
        translationPath=''
        parentTranslationPath='ContactsView'
      />
      {
        addActivity && (
          <ActivitiesManagementDialog
            open={addActivity}
            onSave={() => {
              setAddActivity(false);
            }}
            close={() => {
              setAddActivity(false);

            }}
            actionItemId={activeItem?.id}
            translationPath={''}
            parentTranslationPath={'ContactProfileManagementView'}
            contactClassificationId={activeItem?.developer_class?.lookupItemId}
            contactId={activeItem.id}
          />
        )
      }
      {isConsentActionDialogOpen &&
        <ConsentActionsDialog
          open={isConsentActionDialogOpen}
          communicationActionHandler={() => {
            setIsConsentActionDialogOpen(false);

            const contactItem = detailedCardAction?.item;
            const actionEnum = detailedCardAction?.actionEnum;
            contactActionsHandler(actionEnum, contactItem);
          }}
          onClose={() => {
            setIsConsentActionDialogOpen(false);
            closeSideMenu();
          }}
           activeRecipient={detailedCardAction?.item}
          recipientType={"Contact"}
        />
      }
      {isWorkingHoursDialogOpen &&
        <WorkingHoursConfirmDialog
          open={isWorkingHoursDialogOpen}
          communicationActionHandler={() => {
            setIsWorkingHoursDialogOpen(false);

            const contactItem = detailedCardAction?.item;
            const actionEnum = detailedCardAction?.actionEnum;
            contactActionsHandler(actionEnum, contactItem);
          }}
          onClose={() => {
            setIsWorkingHoursDialogOpen(false)
            closeSideMenu();
          }}
           activeRecipient={detailedCardAction?.item}
          recipientType={"Contact"}
        />
      }
    </div>
  );
};
DevelopersTableComponent.propTypes = {
  isWithCheckboxColumn: PropTypes.bool,
  isOneSelect: PropTypes.bool,
  pathName: PropTypes.string.isRequired,
  setCheckedCards: PropTypes.func.isRequired,
  onPageSizeChanged: PropTypes.func.isRequired,
  setCheckedCardsIds: PropTypes.func.isRequired,
  onPageIndexChanged: PropTypes.func.isRequired,
  onFilterValuesChanged: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  checkedCards: PropTypes.instanceOf(Object).isRequired,
  checkedCardsIds: PropTypes.instanceOf(Object).isRequired,
  detailsDevelopersList: PropTypes.instanceOf(Object).isRequired,
  activeSelectedAction: PropTypes.instanceOf(Object).isRequired,
  developerTableFilter: PropTypes.instanceOf(Object),
  setActiveTab: PropTypes.func.isRequired,
  setIsClearFiltersClicked: PropTypes.bool,
  isClearFiltersClicked: PropTypes.bool,
  displyOpenFileButton: PropTypes.bool,
};
DevelopersTableComponent.defaultProps = {
  isWithCheckboxColumn: false,
  developerTableFilter: undefined,
  isOneSelect: false,
  displyOpenFileButton: false,
  closeSideMenu: ()=>{},
};
