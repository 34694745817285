import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import "./ConsentRequestDialog.scss";
import {
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { SendConsentFromContact, SendConsentFromLead } from "../../Services";
import { ReloadConsentDetails, showError, showSuccess } from "../../Helper";
import btnLoader from "../../assets/images/GIF_images/btn-loader.gif";
import { ShareMethodsDataEnum } from "../../Enums";
import ShareViaWhatsAppForm from "./Utilities/ShareViaWhatsAppForm";

export const ConsentRequestDialog = ({
  onClose,
  open,
  onSave,
  activeRecipient,
  recipientType,
}) => {
  const parentTranslationPath = "Shared";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);
  const [recipientMappedData, setRecipientMappedData] = useState({
    Email: null,
    SMS: null,
    WhatsApp: null,
  });
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [selected, setSelected] = useReducer(reducer, {
    shareVia: null,
    whatsappMsgTemplate: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const isCardDisabled = (cardInfo) => !recipientMappedData[cardInfo?.key];

  const getCardStatusClass = (cardInfo) => {
    let cardClass = "primary";

    if (isCardDisabled(cardInfo)) cardClass = "disabled";
    else if (cardInfo?.id == selected.shareVia?.id) cardClass = "active";
    return cardClass;
  };

  const cardClickHandler = (cardInfo) => {
    if (isCardDisabled(cardInfo)) setSelected({ id: "shareVia", value: null });
    else  setSelected({ id: "shareVia", value: cardInfo });
  };

  const sendConsentFromContact = async () => {
    setIsLoading(true);
    const res = await SendConsentFromContact({
      contactId: activeRecipient?.id,
      consentSentBy: selected.shareVia?.id,
    });
    if (res === true) {
      await reloadConsentDetails();
      showSuccess(t(`${translationPath}sent-Succssfuly`));
      if(selected.shareVia?.key !== ShareMethodsDataEnum.WhatsApp.key) onSave();
    }
    else showError(t(`${translationPath}sending-failed`));
    setIsLoading(false);
  };

  const sendConsentFromLead = async () => {
    setIsLoading(true);
    const res = await SendConsentFromLead({
      leadId: activeRecipient?.id,
      consentSentBy: selected.shareVia?.id,
    });
    if (res === true) {
      await reloadConsentDetails();
      showSuccess(t(`${translationPath}sent-Succssfuly`));
      if(selected.shareVia?.key !== ShareMethodsDataEnum.WhatsApp.key) onSave();
    }
    else showError(t(`${translationPath}sending-failed`));
    setIsLoading(false);
  };

  const mapActiveRecipientData = () => {
    if (recipientType === 'Contact') {
      setRecipientMappedData({
        Email: activeRecipient.email_address || null,
        SMS: activeRecipient.mobile?.phone || null,
        WhatsApp: activeRecipient?.whatsapp_mobile?.phone || activeRecipient?.mobile?.phone || null,
      });
    } else if (recipientType === 'Lead') {
      setRecipientMappedData({
        Email: activeRecipient?.contact_name?.email_address || null,
        SMS: activeRecipient?.contact_name?.mobile || null,
        WhatsApp: activeRecipient?.whatsAppMobile || activeRecipient?.contact_name?.mobile || null,
      }); 
    }
  };

  const reloadConsentDetails = async () => {
    await ReloadConsentDetails({ contactId: activeRecipient?.id })
  }
  
  const shareViaWhatsAppHandler = async () => {
    const WhatsAppMsg = selected.whatsappMsgTemplate || '';
    const UrlEncodedMessage =  encodeURIComponent(WhatsAppMsg);
    const whatsAppNumber = recipientMappedData?.WhatsApp || null;
    const UrlToWhatsApp = `https://wa.me/${whatsAppNumber}?text=${UrlEncodedMessage}`

    window.open(UrlToWhatsApp);
  }

  const saveHandler = async () => {
    if (recipientType === 'Contact') {
      if(selected.shareVia?.key === ShareMethodsDataEnum.WhatsApp.key) await shareViaWhatsAppHandler();
      sendConsentFromContact();
    }
    if (recipientType === 'Lead') {
      if(selected.shareVia?.key === ShareMethodsDataEnum.WhatsApp.key) await shareViaWhatsAppHandler();
      sendConsentFromLead();
    }
  };

  useEffect(() => {
    if (activeRecipient) mapActiveRecipientData();
  }, [activeRecipient]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          onClose();
        }}
        className="ConsentRequestDialog"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <span>{t(`${translationPath}Ask-For-Consent`)}</span>
          <span className="fz-14">{`${
            selected.shareVia ? ` (Via ${selected.shareVia?.key} )` : ""
          }`}</span>
        </DialogTitle>
        <DialogContent>
          {selected.shareVia?.key !== ShareMethodsDataEnum.WhatsApp.key ? (
            <div className="MethodBanner gap-16 p-3">
              {Object.values(ShareMethodsDataEnum).map((item) => (
                <div
                  className={`MethodBanner_card ${getCardStatusClass(item)}`}
                  key={item.key}
                  id={`MethodBanner_card-${item.id}`}
                  onClick={() => cardClickHandler(item)}
                >
                  <span className={`mdi ${item.mdiIconClass || ""}`}></span>
                  <span className="fw-simi-bold fz-16">
                    {t(`${translationPath}${item.title}`)}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <ShareViaWhatsAppForm
            selected={selected}
            setSelected={setSelected}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            />
          )}
        </DialogContent>
        <DialogActions>
          <ButtonBase
            onClick={() => onClose()}
            className={"btns theme-solid bg-cancel"}
          >
            {t(`${translationPath}cancel`)}
          </ButtonBase>
          <ButtonBase
            className={"btns theme-solid px-3"}
            onClick={saveHandler}
            disabled={!selected.shareVia || isLoading}
          >
            {isLoading ? (
              <img
                src={btnLoader}
                className="ml-1 dark-loader"
                width="12"
                height="12"
              />
            ) : null}
            {t(`${translationPath}send-request`)}
          </ButtonBase>
        </DialogActions>
      </Dialog>
    </div>
  );
};
