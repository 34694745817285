import React, {
  useState,
  useCallback,
  useReducer,
  useRef,
} from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { AutocompleteComponent } from "../../../../../../../Components";
import { lookupItemsGetId } from "../../../../../../../Services";
import { StaticLookupsIds } from "../../../../../../../assets/json/StaticLookupsIds";
import { getErrorByName } from "../../../../../../../Helper";

export const OtherDetailsSection = ({
  isSubmitted,
  schema,
  isReadOnly,
  selected,
  onSelectedChanged,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [data, setData] = useReducer(reducer, {
    leasingTypes: [],
    periodsOfStay: [],
  });

  const getAllLeasingTypes = async () => {
    // setLoadings((items) => ({ ...items, leasingTypes: true }));
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.LeasingType,
    });
    if (!(res && res.status && res.status !== 200)) setData({ id: "leasingTypes", value: res || [] });
    else setData({ id: "leasingTypes", value: [] });
    // setLoadings((items) => ({ ...items, leasingTypes: false }));
  };

  const getAllPeriodsOfStay = async () => {
    // setLoadings((items) => ({ ...items, periodsOfStay: true }));
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.PeriodOfStay,
    });
    if (!(res && res.status && res.status !== 200)) setData({ id: "periodsOfStay", value: res || [] });
    else setData({ id: "periodsOfStay", value: [] });
    // setLoadings((items) => ({ ...items, periodsOfStay: false }));
  };


  return (
    <>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">{t(`${translationPath}Leasing-type`)}</span>
        </div>
        <div className="w-50 mt-2 mb-3 pt-1">
          <AutocompleteComponent
            idRef='leasingTypeIdRef'
            inputPlaceholder='select'
            selectedValues={selected.leasingType}
            multiple={false}
            data={data.leasingTypes || []}
            displayLabel={(option) => option.lookupItemName || ''}
            withoutSearchButton
            helperText={getErrorByName(schema, 'leasingTypeId').message}
            error={getErrorByName(schema, 'leasingTypeId').error}
            isWithError
            // isLoading={loadings.leasingTypes}
            isSubmitted={isSubmitted}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onOpen={() => {
              if (data.leasingTypes && data.leasingTypes.length == 0)
                getAllLeasingTypes();
            }}
            onChange={(_, newValue) => {
              onSelectedChanged({ id: 'leasingType', value: newValue });
              // onStateChanged({
              //   id: 'leasingTypeId',
              //   value: (newValue && newValue.lookupItemId) || null,
              // });
            }}
          isDisabled={isReadOnly}
          />
        </div>
      </div>

      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}Period-of-stay(months)`)}
          </span>
        </div>
        <div className="w-50 mt-2 mb-3 pt-1">
          <AutocompleteComponent
            idRef='periodOfStayIdRef'
            inputPlaceholder='select'
            selectedValues={selected.periodOfStay}
            multiple={false}
            data={data.periodsOfStay || []}
            displayLabel={(option) => option.lookupItemName || ''}
            // getOptionSelected={(option) => option.lookupItemId === state.periodOfStayId}
            withoutSearchButton
            isWithError
            // isLoading={loadings.periodsOfStay}
            // isSubmitted={isSubmitted}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onOpen={() => {
              if (data.periodsOfStay && data.periodsOfStay.length == 0)
                getAllPeriodsOfStay();
            }}
            onChange={(_, newValue) => {
              onSelectedChanged({ id: 'periodOfStay', value: newValue });
              // onStateChanged({
              //   id: 'periodOfStayId',
              //   value: (newValue && newValue.lookupItemId) || null,
              // });
            }}
            isDisabled={isReadOnly}
          />
        </div>
      </div>
    </>
  );
};

OtherDetailsSection.propTypes = {
  schema: PropTypes.object,
  isSubmitted: PropTypes.bool,
}
OtherDetailsSection.defaultProps = {
  schema: {},
  isSubmitted: false,
}