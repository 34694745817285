import React from 'react'; 
import { useTranslation } from 'react-i18next';

export const SLAExpirationPeriodSection = (props) => {
  const { t } = useTranslation(props.parentTranslationPath);

  return (
    <fieldset className='input-fieldset my-3'>
      <legend className='input-fieldset-legend'>
        {t(`${props.translationPath}SLA-expiration-period`)}
      </legend>
      {props.children}
    </fieldset>
  )
}

