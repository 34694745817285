import React from "react";
import PropTypes from "prop-types";
import "./ReelStepper.scss";
import { StepCard } from "./StepCard/StepCard";

export const ReelStepper = ({
  steps,
  activeStep,
  onStepClick,
  orientation,
  parentTranslationPath,
  translationPath,
}) => {
  return (
    <div className={`Reel-Stepper ${orientation} pb-3 mt-3`}>
      {steps &&
        steps.map((item) => (
          <StepCard
            key={item.id}
            stepData={item}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            activeStep={activeStep}
            onStepClick={onStepClick}
          />
        ))}
    </div>
  );
};

ReelStepper.propTypes = {
  orientation: PropTypes.string,
  onStepClick: PropTypes.func,
  steps: PropTypes.array,
  activeStep: PropTypes.object,
};
ReelStepper.defaultProps = {
  orientation: "vertical",
  onStepClick: () => {},
};
