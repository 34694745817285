export const IndividualContactDuplicatesCriteriaEnum = {
    email: {
        displayPath: 'email_address.email',
    },
    firstName: {
        displayPath: 'first_name',
    },
    idNumber: {
        displayPath: 'id_card_no',
    },
    lastName: {
        displayPath: 'last_name',
    },
    mobile: {
        displayPath: 'mobile.phone',
    },
    nationality: {
        displayPath: 'nationality.lookupItemName',
    },
    passportNumber: {
        displayPath: 'passport_no',
    },

};
