import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ContactClassIdEnum } from '../../../../../../Enums';
import { RotationManagementLookupsAutocomplete } from '../Controls/RotationManagementLookupsAutocomplete';
import { RadiosGroupComponent } from '../../../../../../Components';

export const LeadClassComponent = ({
  parentTranslationPath,
  translationPath,
  state,
  onStateChanged,
  schema,
  schemaKey,
  isSubmitted,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [ViewComponent, setViewComponent] = useState(1);


  useEffect(() => {
    if(state?.rotationSchemaContactCLasses?.length > 0) 
    {
      setViewComponent(2)
    }
    else{
      setViewComponent(1)
    }
  }, [state]);
  return (
    <div className='w-50 dialog-content-item'>
      <RadiosGroupComponent
        idRef='LeadClass'
        isDisabled={state?.rotationSchemaContactCLasses?.length > 0 || false}
        wrapperClasses='wrapperClasses-cusyy'
        themeClass='theme-default'
        data={[
          {
            key: 1,
            label: t(`${translationPath}General-for-all-Lead-Class`),
            value: false,
          },
          {
            key: 2,
            label: t(`${translationPath}Specific-Lead-Class`),
            value: true,
          },
        ]}
        onSelectedRadioChanged={(event) =>
          setViewComponent(
            +event.target.value || false,
          )}
        value={(ViewComponent) || null}
        labelValue={t(`${translationPath}Lead-Class`)}
        labelInput='label'
        valueInput='key'
      />
      {ViewComponent === 2 && (
        <RotationManagementLookupsAutocomplete
          labelClasses='Requierd-Color'
          idRef='LeasClassRef'
          lookupTypeId={ContactClassIdEnum.lookupTypeId}
          labelValue={t(`${translationPath}leadClass`)}
          value={state.rotationSchemaContactCLasses}
          mapedData={{ id: 'contactClassId', name: 'contactClassName' }}
          onStateChanged={(newValue) => {
            const localNewValue = {
              id: 'rotationSchemaContactCLasses',
              value: [...newValue],
            };
            onStateChanged(localNewValue);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          validation
          schema={schema}
          schemaKey={schemaKey}
          isSubmitted={isSubmitted}
        />
      )}
    </div>
  );
};
const convertJsonValueShape = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.array,
  PropTypes.array,
  PropTypes.array,
]);
LeadClassComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state: PropTypes.objectOf(convertJsonValueShape).isRequired,
  onStateChanged: PropTypes.func.isRequired,
};
