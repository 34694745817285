import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteComponent } from "../../../../../Components";

export const LocationGeographicCriteria = ({
  parentTranslationPath,
  translationPath,
  locationValues,
  setLocationValues,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [locationsRatingList, setLocationsRatingList] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9" ,
    "10",
  ]);

  return (
    <>
      <div className="d-flex-column">
        <span className="fw-bold">{t(`${translationPath}location`)}</span>
        <span> {t(`${translationPath}these-are-the-location-weights`)}</span>
      </div>
      <div className="w-100 location-type mb-2">
        {locationValues &&
          locationValues.map((item, index) => (
            <div className="px-2 mb-2">
              <AutocompleteComponent
                labelValue={t(`${translationPath}${item.lookupTypeName}`)}
                selectedValues={item.score.toString()}
                idRef={`locationsRating${index}`}
                inputPlaceholder={"00  /10"}
                multiple={false}
                data={locationsRatingList || []}
                displayLabel={(option) => option || ""}
                renderOption={(option) => option || ""}
                withoutSearchButton
                isWithError
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  let updatelist = [...locationValues];
                  updatelist[index] = {
                    ...updatelist[index],
                    score: newValue || 0,
                  };
                  setLocationValues([...updatelist]);
                }}
              />
            </div>
          ))}
      </div>
    </>
  );
};
