import { makeStyles } from "@material-ui/core/styles";
import { useIsAr } from "../../../../../Hooks";

export default makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    tabButtonContainer: {
      display: "flex",
      gap: "8px",
      padding: "0 4px 10px"
    },
    notificationCounter: {
      fontFamily: "Inter",
      alignItems: "center",
      alignSelf: "center",
      background: theme.palette.background.brand_50,
      border: `1px solid ${theme.palette.border.brand_200}`,
      borderRadius: "50%",
      color: theme.palette.text.brand_700,
      display: "flex",
      fontSize: "12px",
      height: "22px",
      justifyContent: "center",
      width: "24px",
    },
    inactiveNotificationCounter: {
      fontFamily: "Inter",
      alignItems: "center",
      alignSelf: "center",
      background: theme.palette.background.gray_50,
      border: `1px solid ${theme.palette.border.gray_200}`,
      borderRadius: "50%",
      color: theme.palette.text.gray_700,
      display: "flex",
      fontSize: "12px",
      height: "22px",
      justifyContent: "center",
      width: "24px",
    },
    tabButton: {
      opacity: 1,
      color: theme.palette.text.text_quarterary, // not selected tab button color
      lineHeight: "20px",
      fontFamily: "Inter",
      display: "flex !important",
      flexDirection: "row !important",
      gap: "5px !important",
      width: "fit-content !important",
      minWidth: "fit-content !important",
      minHeight: "fit-content",
      margin: 0,
      padding: 0,
      fontWeight: 600,
      textTransform: "none",
      "& .MuiTab-wrapper": {
        alignItems: "flex-start !important",
      }
    },
    tabs: {
      borderBottom: `1px solid ${theme.palette.border.secondary}`,
      minHeight: "fit-content",
      marginBottom: "24px",
    },
    flexContainer: {
      gap: "8px",
    },
    tab: {
      color: theme.palette.text.brand_secondary, // selected tab button color
      lineHeight: "20px",
      fontFamily: "Inter",
      fontWeight: 600,
      borderBottom: `2px solid ${theme.palette.border.brand_primary_alt}`,
    },
    tabLabel: {
      textTransform: "none",
    },
    font14: {
      fontFamily: "Inter",
      fontSize: "14px",
    },
  };
});
