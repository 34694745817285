
import { ActivitiesCommunicationLogsView } from '../../Views/Home/ActivitiesCommunicationLogsView' ; 
export const ActivitiesCommunicationLogsRoutes = [
  {
    path: '/activities-communication-logs',
    name: 'ActivitiesCommunicationLogs:activities-communication-logs',
    component: ActivitiesCommunicationLogsView   ,
    layout: '/home/system-log',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'ActivitiesCommunicationLogs:activities-communication-logs',
        isDisabled: true,
        route: '/home/system-log/activities-communication-logs',
        groupName: 'system-log',
      },
    ],
  },
];
