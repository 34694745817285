import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import btnLoader from "../../../../../assets/images/GIF_images/btn-loader.gif";
import { PageHeading } from "../../../../../SharedComponents";
import { AutocompleteComponent, Inputs, Spinner } from "../../../../../Components";
import { RiskRatingConfigEnum } from "../../../../../Enums";
import {
  GetLookupItemsByLookupTypeName,
  GetNationalityConfigurations,
  UpdateNationalityConfigration,
} from "../../../../../Services";
import { GlobalHistory, showError, showSuccess } from "../../../../../Helper";

export const NationalityConfiguration = ({ parentTranslationPath }) => {
  const translationPath = "NationalityConfigurationForm.";
  const { t } = useTranslation(parentTranslationPath);

  const searchTimer = useRef(null);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [isLoading, setIsLoading] = useReducer(reducer,{
    save: false,
    configData: false,
  });
  const [filter, setFilter] = useState('');

  const [nationalityConfigurations, setNationalityConfigurations] = useState([]);
  const [jurisdictionRiskOptions, setJurisdictionRiskOptions] = useState([]);

  const getJurisdictionRiskLookups = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "JurisdictionRisk(Nationality)",
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setJurisdictionRiskOptions(res.result || []);
    } else setJurisdictionRiskOptions([]);
  };

  const getNationalityConfigurations = async (searchValue) => {
    setIsLoading({ id: "configData", value: true });

    const res = await GetNationalityConfigurations({ searchValue: searchValue || ''});
    if (!(res && res.status && res.status !== 200)) {
      setNationalityConfigurations(res);
    }
    setIsLoading({ id: "configData", value: false });
  };

  const updateNationalityConfigration = async () => {
    setIsLoading({ id: "save", value: true });
    const body = [
      ...(nationalityConfigurations || {}),
    ]
    const res = await UpdateNationalityConfigration(body);
    if ( res === true) {
      showSuccess(t(`${translationPath}Updated-successfully`));
      reloadData();
    } else showError(t(`${translationPath}updating-has-failed`))
    setIsLoading({ id: "save", value: false });

  };

  const nationalityConfigChangeHandler = (
    newValue,
    changedItem,
    changedItemIndex
  ) => {
    const localDurationConfig = [
      ...(nationalityConfigurations || []),
    ];

    const itemWithNewValue = {
      ...(changedItem || {}),
    };
    itemWithNewValue.nationalityDetails.jurisdictionRisk = newValue? {
      jurisdictionRiskId: newValue.lookupItemId || null,
      jurisdictionRiskName: newValue.lookupItemName || null,
    } : null;

    localDurationConfig[changedItemIndex] = itemWithNewValue;
    setNationalityConfigurations(localDurationConfig);
  };

  //todo:: add gradual scroll

  const reloadData = () => {
    getNationalityConfigurations(filter);
  };

  const saveHandler = () => {
    updateNationalityConfigration();
  };

  const discardClickHandler = () =>  GlobalHistory.push('/home/risk-rating-configuration/view');

  useEffect(() => {
    getNationalityConfigurations();
  }, []);

  return (
    <div className="w-80">
      <div className="d-flex-v-center-h-between">
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          headerTitle={"NationalityConfiguration"}
          subTitle={"NationalityConfiguration-desc"}
          wrapperClasses={"px-3 w-100"}
          headerTitleClasses={"fz-16 mb-1"}
          subTitleClasses={"fz-14 m-0 ml-0"}
        />
        <div className="w-fit px-4 d-flex fj-end">
          <ButtonBase
            onClick={discardClickHandler}
            className="btns theme-propx outlined"
          >
            {t(`${translationPath}discard`)}
          </ButtonBase>

          <ButtonBase
            className="btns theme-propx solid mr-0"
            onClick={saveHandler}
            disabled={isLoading.save}
          >
            {t(`${translationPath}save`)}
            {isLoading.save ? (
              <img src={btnLoader} className="ml-1" width="12" height="12" />
            ) : null}
          </ButtonBase>
        </div>
      </div>

      <div className="propx-view-container sm m-3 d-flex-column">
        <Inputs
          value={filter || ''}
          inputPlaceholder={t(`${translationPath}search-for-nationality`)}
          wrapperClasses="mb-4 w-33"
          onInputChanged={(event) => {
            setFilter(event?.target?.value);
          }}
          onKeyUp={(event) => {
            const searchValue = event?.target?.value;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getNationalityConfigurations(searchValue);
            }, 500);
          }}
        />
        <Spinner isActive={isLoading.configData} isAbsolute/>
        {nationalityConfigurations
          ? nationalityConfigurations.map((item, index) => (
              <div
                className="d-flex-default fj-start b-bottom"
                key={item.nationalityDetails?.nationalityName}
              >
                <div className="w-25 mt-2 pt-1">
                  <div className="d-flex fa-center gap-12">
                    <span className="fw-simi-bold">
                      {item.nationalityDetails?.nationalityName || "-"}
                    </span>
                  </div>
                </div>
                <div className="w-75 mt-2 mb-1 pt-1">
                  <AutocompleteComponent
                    idRef="jurisdictionRiskRef"
                    selectedValues={
                      item.nationalityDetails?.jurisdictionRisk || null
                    }
                    inputPlaceholder={t(`${translationPath}select`)}
                    wrapperClasses="w-50 mb-2"
                    data={jurisdictionRiskOptions || []}
                    displayLabel={(option) =>
                      option.lookupItemName || option.jurisdictionRiskName || ""
                    }
                    multiple={false}
                    withoutSearchButton
                    isWithError
                    onOpen={() => {
                      if (
                        jurisdictionRiskOptions &&
                        jurisdictionRiskOptions.length == 0
                      )
                        getJurisdictionRiskLookups();
                    }}
                    onChange={(_, newValue) => nationalityConfigChangeHandler(newValue, item, index)}
                  />
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};
