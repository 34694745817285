import { PolicyDependsOnOtherPolicy } from './PolicyDependsOnOtherPolicy' ; 
import { ContactCreatedBy } from './ContactCreatedBy' ; 


export const ContactPoliciesTabs = [
     {   
      label: 'createdBy',
      component: ContactCreatedBy  ,
     } , 
     {   
        label: 'based-on-other-policy',
        component: PolicyDependsOnOtherPolicy  ,
     } ,
]
  

