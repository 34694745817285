import { PowerBiEnum } from "../../../../Enums";
import { LeaseAgentDashboardPermissions } from '../../../../Permissions' ; 
import { MainLeaseAgentTabDashboards } from "../MainLeaseAgentTabDashboards/MainLeaseAgentTabDashboards";

export const LeaseAgentDashboardTabs = [
  {
    index: 0,
    label: "Dashboard:leaseagent",
    component: MainLeaseAgentTabDashboards,
    config: {
      reportId: PowerBiEnum.leaseAgentToken.reportid,
      groupId: PowerBiEnum.leaseAgentToken.groupid,
      Url: PowerBiEnum.leaseAgentToken.url,
    },
    permissionsId : LeaseAgentDashboardPermissions.LeaseAgentDashboard.permissionsId, 
  },
  {
    index: 1,
    label: "Dashboard:leaseagent+",
    component: MainLeaseAgentTabDashboards,
    config: {
      reportId: PowerBiEnum.LeaseAgentplus.reportid,
      groupId: PowerBiEnum.LeaseAgentplus.groupid,
      Url: PowerBiEnum.LeaseAgentplus.url,
    },
  
    permissionsId : LeaseAgentDashboardPermissions.LeaseAgentPlusDashboard.permissionsId, 
  },
 
];
