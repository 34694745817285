import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  ColumnsFilterPagesEnum,
  FormsIdsEnum,
  TableActions,
  TableFilterTypesEnum,
  HighlightRowKeys
} from '../../../../../Enums';
import { TableColumnsFilterComponent, Tables } from '../../../../../Components';
import { GetAllFormFieldsByFormId } from '../../../../../Services';
import { PropertiesTableHeaderData } from './PropertiesTableHeaderData';
import { TableColumnsFilterActions } from '../../../../../store/TableColumnsFilter/TableColumnsFilterActions';

export const PropertiesTableComponent = ({
  detailsPropertiesList,
  tableActionClicked,
  onPageIndexChanged,
  onPageSizeChanged,
  filter,
  parentTranslationPath,
  focusedRowChanged,
  propertiesTableFilter,
  onFilterValuesChanged,
  setOrderBy,
  isClearFiltersClicked,
  setIsClearFiltersClicked,
  displyOpenFileButton,
  localStorageKey,
  activeSelectedAction,
  checkedCardsIds,
  onSelectClicked,
  getIsSelected,
  setCheckedCards
}) => {
  const dispatch = useDispatch();
  const [sortBy, setSortBy] = useState(null);
  const [allFormFields, setAllFormFields] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTableFilterColumns, setSelectedTableFilterColumns] = useState(
    PropertiesTableHeaderData.filter((item) => item.isDefaultFilterColumn).map(
      (column) => column.id
    )
  );


  const [tableActionsWithPermissins, setTableActionsWithPermissins] = useState([]);
  const tableColumnsFilterResponse = useSelector((state) => state.TableColumnsFilterReducer);
  const getAllFormFieldsByFormId = useCallback(async () => {
    setIsLoading(true);
    const result = await GetAllFormFieldsByFormId(FormsIdsEnum.properties.id);
    if (!((result && result.data && result.data.ErrorId) || !result) && Array.isArray(result)) {
      const list = result.filter(e =>
        e.formFieldName !== 'amenities' &&
        e.formFieldName !== 'map' &&
        e.formFieldName !== 'facilities' &&
        e.formFieldName !== 'property_name' &&
        e.formFieldName !== 'property_owner' &&
        e.formFieldName !== 'unit_types_in_property' ,
        
      ).map((field) => ({
        ...field , 
        id: field.formFieldId || null,
        key: field.formFieldKey || null,
        isDate: field.uiWidgetType === 'alt-date' || false,
        label: (field.formFieldTitle && field.formFieldTitle.replace('*', '')) || '',
        input: field.displayPath || '',
        isNumber:
          field.propertyJson && JSON.parse(field.propertyJson).schema &&
          field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'currency' ||
          field.propertyJson && JSON.parse(field.propertyJson).schema &&
          field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'decimal' ||
          field.propertyJson && JSON.parse(field.propertyJson).schema &&
          field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'size'
        ,
        isSortable: true,
        searchableKey: field.searchableKey,
        
      }));
      setAllFormFields(list);

    } else setAllFormFields([]);
    setIsLoading(false);
  }, []);
  useEffect(() => {
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId]);




  useEffect(() => {
    if (
      tableColumnsFilterResponse &&
      tableColumnsFilterResponse[ColumnsFilterPagesEnum.properties.key]
    ) {
      setSelectedTableFilterColumns(
        tableColumnsFilterResponse[ColumnsFilterPagesEnum.properties.key]
      );
    }
  }, [tableColumnsFilterResponse]);
  useEffect(() => {
    setTableColumns([
      ...PropertiesTableHeaderData.filter(
        (item) => selectedTableFilterColumns.findIndex((element) => element === item.id) !== -1
        ),
      ...allFormFields
        .filter(
          (item) =>
            selectedTableFilterColumns.findIndex((element) => element === item.formFieldId) !== -1
        ),
        
    ]);

  }, [allFormFields, selectedTableFilterColumns , propertiesTableFilter ]);
  useEffect(() => {
    setTableFilterData(
      tableColumns.map((column) => ({
        key: column.key || column.fieldKey || column.id,
        filterType:
        (column.isDate && TableFilterTypesEnum.datePicker.key) ||
        (column.isNumber && TableFilterTypesEnum.numberInput.key) ||
        TableFilterTypesEnum.textInput.key,
        isHiddenFilter: column.isHiddenFilter,
        isHidden: column.isHidden,
        textInputType: column.textInputType,
        textInputMax: column.textInputMax,
        textInputMin: column.textInputMin,
        searchableKey: column.searchableKey || column.input,
        displayPath:
          (column.key && column.input) ||
          (column.fieldKey &&
            allFormFields &&
            allFormFields.findIndex((item) => item.formFieldKey === column.fieldKey) !== -1 &&
            allFormFields.find((item) => item.formFieldKey === column.fieldKey).displayPath) ||
          undefined, 
      })) , 
      
    );
  }, [allFormFields, tableColumns]);
  useEffect(() => {
    if (sortBy)
      setOrderBy((item) => ({ ...item, filterBy: sortBy.filterBy && sortBy.filterBy[0].toLowerCase() + sortBy.filterBy.slice(1), orderBy: sortBy.orderBy, fieldType: sortBy.fieldType }));
  }, [sortBy]);

  useEffect(() => {
    let list = [];
    if (displyOpenFileButton)
      list.push({ enum: TableActions.openFile.key });
    setTableActionsWithPermissins(list);
  }, [displyOpenFileButton]);

  const getIsSelectedAll = useCallback(() => {
    const returnSelect =
      (checkedCardsIds &&
        detailsPropertiesList.result.findIndex(
          (item) => !checkedCardsIds.includes(item.id)
        ) === -1) ||
      false;
    return returnSelect;
  });

  const onSelectAllClicked = () =>{
    const cardItems = [];
    if(!getIsSelectedAll()){
      detailsPropertiesList.result.map((item)=>{
        if(!getIsSelected(item)){
          checkedCardsIds.push(item.id);
          cardItems.push({...item});
        }
      });

    } else {
      detailsPropertiesList.result.map((item)=>{
        if(getIsSelected(item)){
          const isSelectedIndex = checkedCardsIds.findIndex(
            (element) => element === item.id
          );
          if (isSelectedIndex !== -1)
            checkedCardsIds.splice(isSelectedIndex, 1);
        }
      });
    }

    setCheckedCards(cardItems)

  };

  return (
    <div className='w-100 px-3'>
      <TableColumnsFilterComponent
        columns={PropertiesTableHeaderData.filter(item => !item.isHidden ).concat(
          allFormFields.filter(
            (item) =>
              PropertiesTableHeaderData.findIndex(
                (element) => element.fieldKey === item.formFieldKey
              ) === -1
          )
        ).map((item) => ({
          key: item.formFieldId || item.id,
          value: (item.formFieldTitle && item.formFieldTitle.replace('*', '')) || item.label,
        }))}
        isLoading={isLoading}
        selectedColumns={selectedTableFilterColumns}
        onSelectedColumnsChanged={(newValue) => {
          setSelectedTableFilterColumns(newValue);
          let localTableColumnsFilterResponse = tableColumnsFilterResponse;
          if (localTableColumnsFilterResponse)
            localTableColumnsFilterResponse[ColumnsFilterPagesEnum.properties.key] = newValue;
          else {
            localTableColumnsFilterResponse = {
              [ColumnsFilterPagesEnum.properties.key]: newValue,
            };
          }
          dispatch(
            TableColumnsFilterActions.TableColumnsFilterRequest(localTableColumnsFilterResponse)
          );
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath=''
      />
      <Tables
        data={detailsPropertiesList.result}
        headerData={tableColumns}
        filterValues={propertiesTableFilter}
        onFilterValuesChanged={onFilterValuesChanged}
        filterData={tableFilterData}
        isWithFilter
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
        setSortBy={setSortBy}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        itemsPerPage={filter.pageSize}
        defaultActions={tableActionsWithPermissins}
        activePage={filter.pageIndex}
        parentTranslationPath={parentTranslationPath}
        focusedRowChanged={focusedRowChanged}
        totalItems={detailsPropertiesList ? detailsPropertiesList.totalCount : 0}
        isClearFiltersClicked={isClearFiltersClicked}
        setIsClearFiltersClicked={setIsClearFiltersClicked}
        isLoading={isLoading}
        localStorageKey={localStorageKey}
        highlightedRowKey={HighlightRowKeys.Properties.key}
        selectAllOptions={((activeSelectedAction === "bulk-update") && {
          selectedRows:checkedCardsIds,
          getIsSelected,
          disabledRows:[],
          withCheckAll:true,
          onSelectAllClicked,
          isSelectAll :getIsSelectedAll(),
          onSelectClicked

        })|| undefined}
      />
    </div>
  );
};

PropertiesTableComponent.propTypes = {
  detailsPropertiesList: PropTypes.instanceOf(Object).isRequired,
  tableActionClicked: PropTypes.func.isRequired,
  onPageIndexChanged: PropTypes.func.isRequired,
  onPageSizeChanged: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  focusedRowChanged: PropTypes.func.isRequired,
  onFilterValuesChanged: PropTypes.func.isRequired,
  propertiesTableFilter: PropTypes.instanceOf(Object),
  displyOpenFileButton: PropTypes.bool,
};
PropertiesTableComponent.defaultProps = {
  propertiesTableFilter: undefined,
  displyOpenFileButton: false,
};
