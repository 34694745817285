import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Rating from "@material-ui/lab/Rating";
import { AutocompleteComponent } from "../../../../../../Components";
import { GeneralPropertyCriteriaTypesEnum } from "../../../../../../Enums";
import { showError, showSuccess } from "../../../../../../Helper";
import {
  GetLookupItemsByLookupTypeName,
  DeleteGeneralPropertyCriteria,
} from "../../../../../../Services";
import { MessageConfirmDialog } from "../../../../../../SharedComponents";
import PlusIcon from "../../../../../../assets/images/defaults/plus.svg";

export const PropertyAgeCriteria = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [allPropertyAgeLookupsTypes, setAllPropertyAgeLookupsTypes] = useState(
    []
  );
  const [isMissingInfoMSGOpen, setIsMissingInfoMSGOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const onChangeSelectedItem = (key, value, index) => {
    let  newListUpdated = [...state.generalPropertyLookupInfo];

    let newSelectedArray = [...selectedValue.generalPropertyLookupDtos];
    if (key === 'delete-lookupsId')
        newSelectedArray = [...newSelectedArray.filter((x)=> x.lookupsId !== (activeItem?.lookupsId))];
      
    if (
      key === "lookupsId" &&
      selectedValue.generalPropertyLookupDtos.some(
        (s) => s.lookupsId === value?.lookupsId
      )
    ) {
      showError(
        t`${translationPath}this-property-age-is-select-please-select-another`
      );
      return;
    }
    if(index !==  undefined)
    newSelectedArray[index] =
      key === "lookupsId"
        ? {
            ...newSelectedArray[index],
            lookupItemName: value && value.lookupItemName,
            lookupsId: value && value.lookupsId,
          }
        : {
            ...newSelectedArray[index],
            score: value || 0,
          };
    if (key === "lookupsId" && !value) {
      newSelectedArray[index] = {
        ...newSelectedArray[index],
        score: 0,
      };
    }
    const generalPropertyInfoIndex = newListUpdated.findIndex(
      (f) =>
        f.generalPropertyCriteriaType ===
        selectedValue.generalPropertyCriteriaType
    );
    let newSelectedObject = {
      generalPropertyCriteriaType: selectedValue?.generalPropertyCriteriaType,
      generalPropertyLookupDtos: newSelectedArray,
    };
    setSelectedValue(newSelectedObject);
    if (generalPropertyInfoIndex !== -1) {
      newListUpdated[generalPropertyInfoIndex] = newSelectedObject;
      setState({ id: "generalPropertyLookupInfo", value: newListUpdated });
    } else {
      newListUpdated.push({ ...newSelectedObject });
      setState({ id: "generalPropertyLookupInfo", value: newListUpdated });
    }
  };

  const DeleteHandler = useCallback(async () => {  
    if(activeItem && !activeItem.propertyRatingGeneralPropertyCriteriaScoreId)
    {
      onChangeSelectedItem("delete-lookupsId");
      setIsMissingInfoMSGOpen(false) ; 
      return ; 

    }
    const res = await DeleteGeneralPropertyCriteria(
      activeItem && activeItem.propertyRatingGeneralPropertyCriteriaScoreId
    );
    if (res && res.ErrorId) {
      showError(t(`${translationPath}failed-to-delete-property-age`));
    } else if (!(res && res.status && res.status !== 200)) {
      setIsMissingInfoMSGOpen(false) ; 
      showSuccess(t(`${translationPath}property-age-deleted-sucessfuully`));
      onChangeSelectedItem("delete-lookupsId");
   
    } else showError(t(`${translationPath}failed-to-delete-property-age`));
  },[state , selectedValue , activeItem]);


  const GetLookupItemsByLookupTypeNameAPI = useCallback(async () => {
    setIsLoading(true);
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "Property Age",
      pageIndex: 1,
      pageSize: 150,
    });
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      setAllPropertyAgeLookupsTypes(
        res?.result.map((i) => ({
          lookupsId: i.lookupItemId,
          lookupItemName: i.lookupItemName,
        }))
      );
    } else setAllPropertyAgeLookupsTypes([]);
    setIsLoading(false);
  });

  useEffect(() => {
    if (
      !(
        state &&
        state.generalPropertyLookupInfo &&
        state.generalPropertyLookupInfo &&
        state.generalPropertyLookupInfo.find(
          (f) =>
            f.generalPropertyCriteriaType ===
            GeneralPropertyCriteriaTypesEnum.PropertyAge.key
        )
      ) ||
      !state.generalPropertyLookupInfo.length
    )
      setSelectedValue({
        generalPropertyCriteriaType:
          GeneralPropertyCriteriaTypesEnum.PropertyAge.key,
        generalPropertyLookupDtos: [
          {
            lookupItemName: null,
            lookupsId: null,
            propertyRatingGeneralPropertyCriteriaScoreId: null,
            score: 0,
          },
        ],
      });
    else
      setSelectedValue(
        state &&
          state.generalPropertyLookupInfo &&
          state.generalPropertyLookupInfo.find(
            (f) =>
              f.generalPropertyCriteriaType ===
              GeneralPropertyCriteriaTypesEnum.PropertyAge.key
          )
      );
  }, [state]);

  return (
    <div className="w-75 mb-2">
      <div className="general-property-section">
        <div>
          <span className="fw-bold">
            {" "}
            {t(`${translationPath}property-age`)}{" "}
          </span>
        </div>
        <div className="w-100  mt-2 mb-2">
          {selectedValue &&
            selectedValue.generalPropertyLookupDtos &&
            selectedValue.generalPropertyLookupDtos.map((item, index) => (
              <div
                className="d-flex d-flex-v-center general-property-card mt-2 mb-2 b-bottom"
                key={`propertyAge${index + 1}`}
              >
                <div className="mb-2">
                  <AutocompleteComponent
                    idRef={`propertyAgeRef${index + 1}`}
                    multiple={false}
                    selectedValues={item}
                    data={allPropertyAgeLookupsTypes}
                    displayLabel={(option) => option.lookupItemName || ""}
                    renderOption={(option) => option.lookupItemName || ""}
                    isDisabled={
                      item?.propertyRatingGeneralPropertyCriteriaScoreId
                    }
                    withoutSearchButton
                    isLoading={isLoading}
                    withLoader
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                      onChangeSelectedItem("lookupsId", newValue, index);
                    }}
                    inputPlaceholder={"select-property-age"}
                    onOpen={() => {
                      if (
                        allPropertyAgeLookupsTypes &&
                        allPropertyAgeLookupsTypes.length === 0
                      )
                        GetLookupItemsByLookupTypeNameAPI();
                    }}
                  />
                </div>

                <div className="d-inline-flex-v-center px-4">
                  <Rating
                    name={`RatingPropertyAge${index + 1}`}
                    max={10}
                    precision={1}
                    disabled={!item.lookupsId}
                    value={item.score}
                    onChange={(event, newValue) => {
                      onChangeSelectedItem("score", newValue || 0, index);
                    }}
                  />
                  <span
                    className="mdi mdi-delete-outline c-warning delete-button"
                    onClick={() => {
                      setIsMissingInfoMSGOpen(true);
                      setActiveItem(item);
                    }}
                  >
                    {t(`${translationPath}`)}
                  </span>
                </div>
              </div>
            ))}

          <div
            className="d-flex d-flex-v-center  mt-2 mb-2"
            onClick={() => {
              let newSelectedObj = { ...selectedValue };
              let newList = selectedValue.generalPropertyLookupDtos
                ? [...selectedValue.generalPropertyLookupDtos]
                : [];
              newList.push({
                lookupItemName: null,
                lookupsId: null,
                propertyRatingGeneralPropertyCriteriaScoreId: null,
                score: 0,
              });
              setSelectedValue({
                ...newSelectedObj,
                generalPropertyLookupDtos: newList,
              });
            }}
          >
            <img
              className="mr-2 ml-2"
              src={PlusIcon}
              alt={t(`${translationPath}permission-denied`)}
            />
            <span>{t(`${translationPath}add-another`)}</span>
          </div>
        </div>
      </div>
      {isMissingInfoMSGOpen  && (
        <MessageConfirmDialog
          isDialogOpen={isMissingInfoMSGOpen}
          theme="warning"
          content={{
            heading: "delete-property-age",
            body :(t(`${translationPath}are-you-sure-you-want-to-delete-the-property-age-If-confirmed-the-score-will-be-deleted`) + ' ' + ((activeItem && activeItem.lookupItemName && t(`${translationPath}property-age`)) || '') + `  ${(activeItem && activeItem.lookupItemName)||''}`)

          }}
          confirmButton={{
            label: (t(`${translationPath}confirm`)) , 
            handler: () => DeleteHandler(),
          }}
          cancelButton={{
            label  :(t(`${translationPath}discard`)),
            handler: () => {
              setIsMissingInfoMSGOpen(false);
            },
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </div>
  );
};
