import {
  FloorPlansImagesTabComponent,
  GeneralImagesTabComponent,
  LocationImagesTabComponent,
  PropertyImagesTabComponent,
  UnitImagesTabComponent,
  UnitVideosTabComponent,
  PropertyVideoTabComponent,
  LocationVideosTabComponent
} from '../Sections/UnitProfileGalleryComponent/Sections';

export const UnitGalleryTabsData = [

  { label: 'Unit-Images', component: UnitImagesTabComponent },
  { label: 'General-Images', component: GeneralImagesTabComponent },
  { label: 'Floor-Plans', component: FloorPlansImagesTabComponent },
  { label: 'video', component: UnitVideosTabComponent },
  { label: 'Property-Images', component: PropertyImagesTabComponent },
  { label: 'Property-video', component: PropertyVideoTabComponent },
  { label: 'Location-Images', component: LocationImagesTabComponent },
  { label: 'Location-video', component: LocationVideosTabComponent },
];
