export const UpdatedUserStatus = {
  ActiveUsers: {
    label: "Active-Users",
    value: 1,
  },
  PendingActivation: {
    label: "Pending-Activation",
    value: 2,
  },
  DeactivatedUsers: {
    label: "Deactivated-Users",
    value: 3,
  },
  PendingDeactivation: {
    label: "Pending-Deactivation",
    value: 4,
  },
};
