
import React, { useCallback, useState, useRef  , useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ButtonBase, Tooltip } from '@material-ui/core';
import moment from 'moment-timezone';
import {
  CheckboxesComponent,
  LoadableImageComponant,
  PopoverComponent,
  ProgressComponet,
} from '../../../../Components';
import {
  ActionsEnum,
  ContactTypeEnum,
  LoadableImageEnum,
  UserAccountTypeEnum,
  ContactPreferenceEnum,
  LeadsClassTypesEnum,
  ActionsButtonsEnum,
} from '../../../../Enums';
import { getDownloadableLink } from '../../../../Helper';
import {   getSideMenuStatus  ,   sideMenuIsOpenUpdate,
  sideMenuComponentUpdate,  
     } from '../../../../Helper' ; 
     import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';
    

import { CopyToClipboardComponents } from '../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';
import { CardDetailsComponent } from './CardDetailsComponent/CardDetailsComponent' ;

export const DevelopersCards = ({
  data,
  activeCard,
  isExpanded,
  onFooterActionsClicked,
  parentTranslationPath,
  translationPath,
  notExpandedMax,
  onCardCheckboxClick,
  selectedDetailsContactItem,
  onActionClicked,
  selectedCards,
  displyOpenFileButton,
  checkIfSensitiveField,
  contactPreferenceFieldThanFour,
  activeAction ,
  setActiveCard , 
  reloadData ,
  setAdvanceSearchBtn ,
  loginResponse ,  
  formId , 
  advanceSearchBtn , 
  activeSelectedAction , 
  closeSideMenu ,
  displayOpenFileButton  , 
  dispatch 
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [actionsAttachedWith, setActionsMenuAttachedWith] = useState(null);
  const [Activeitem, setActiveitem] = useState(null);
  const [withCheckbox , setWithCheckbox] = useState(false) ;
  const [isCheckBoxDisabled , setIsCheckBoxDisabled] = useState(false) ;

  const [otherContactPreference, setOtherContactPreference] = useState([
    { key: 'whatsapp', value: 'WhatsApp' },
  ]);

  const getDefaultContactImage = useCallback(
    () =>
       ContactTypeEnum.corporate.defaultImg,
    [],
  );
  const getIsSelectedCard = useCallback(
    (row) =>
      selectedCards &&
      selectedCards.findIndex((item) => item.id === row.id) !== -1,
    [selectedCards],
  );
  const handleClose = useCallback(() => {
    setActionsMenuAttachedWith(null);
  }, []);
  
  const menuOpenHandler = useCallback((event, index) => {
    setActiveitem(index);
    event.stopPropagation();
    event.preventDefault();
    setActionsMenuAttachedWith(event.currentTarget);
  }, []);


  const openfile = (folderkey, activeData, index, e) => {
    let rightclick;
    if (!e) var e = window.event;
    if (e.which) rightclick = e.which === 3;
    else if (e.button) rightclick = e.button === 2;
    const el = document.createElement('a');
    el.href = `/home/developers/developer-profile-edit?formType=${activeData.userTypeId}&id=${activeData.id}`;
    el.target = '_blank';
    el.click();

  };

  const onCardClicked = useCallback(
    (item, selectedIndex) => (event) => {
      event.stopPropagation();
      event.preventDefault();
      setActiveCard(item);
      dispatch(ActiveItemActions.activeItemRequest(item));
      if (getSideMenuStatus()) setAdvanceSearchBtn(true);
      sideMenuComponentUpdate(
        <CardDetailsComponent
          activeData={data?.result[selectedIndex]}
          cardDetailsActionClicked={onFooterActionsClicked}
          loginResponse={loginResponse}
          onActionClicked={onActionClicked}
          relodedata={reloadData}
          displyOpenFileButton={displayOpenFileButton}
          closeSideMenu={closeSideMenu}
          formId={formId}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      );
      sideMenuIsOpenUpdate(true);
    },
    [
      onActionClicked,
      onFooterActionsClicked,
      data.result,
      loginResponse,
      reloadData,
      advanceSearchBtn,
    ]
  );

  useEffect(() => 
  {
    if(activeAction){
      setWithCheckbox([
        "merge",
        ActionsButtonsEnum[4].id,
        ActionsButtonsEnum[29].id,
      ].includes(activeAction)); 

     setIsCheckBoxDisabled(activeAction === "merge"
      ? selectedCards.length >= 10
      : false) 
    }

  }, [activeAction , selectedCards]);

  return (
    <div className='contacts-cards-wrapper childs-wrapper'>
      {data &&
        data.result &&
        data.result.filter(item => item).map((item, index) => (
          <div
            className={`contacts-card-wrapper${
              isExpanded ? ' is-expanded' : ''
            }${
              (((activeCard && activeCard.id === item && item.id) ||
                (selectedDetailsContactItem &&
                  selectedDetailsContactItem.id === item &&
                  item.id)) &&
                ' is-open') ||
              ''
            }`}
            key={`contactsCardItemRef${index + 1}}`}
            id={(item && item.id)}
          >
            {withCheckbox && (
              <div className='card-checkbox-wrapper'>
                <CheckboxesComponent
                  idRef={`contactsCheckboxItemRef${index + 1}`}
                  singleChecked={getIsSelectedCard(item)}
                  onSelectedCheckboxChanged={(event) => {
                    const isObsoleteContact =
                      selectedCards && selectedCards.length !== 0;
                    const isContactWithTransaction =
                      isObsoleteContact && item && 
                      item.isWithTransaction === 1 &&
                      !getIsSelectedCard(item);

                    onCardCheckboxClick(index, item, isContactWithTransaction);
                  }}
                  isDisabled={
                    (isCheckBoxDisabled && !getIsSelectedCard(item)) ||
                    (activeAction !== ActionsButtonsEnum[29].id && selectedCards &&
                      selectedCards[0] &&
                      selectedCards[0].userTypeId !== (item?.userTypeId))
                  }
                />
              </div>
            )}
            <a
              onClick={onCardClicked(item, index)}
              className='cards-wrapper'
            >
              {item.accountType &&
                UserAccountTypeEnum[item.accountType].curvedImg && (
                  <Tooltip
                    title={t(
                      `${translationPath}${
                        UserAccountTypeEnum[item.accountType].value
                      }`,
                    )}
                    placement='top-end'
                  >
                    <div className='tag-curve-wrapper'>
                      <img
                        src={UserAccountTypeEnum[item.accountType].curvedImg}
                        alt={t(`${translationPath}account-type`)}
                        className='tag-curve-img'
                      />
                    </div>
                  </Tooltip>
                )}
              <div className='cards-body-wrapper'>
                <div className='card-body-section'>
                  <div className='merge-wrapper'>
                    <LoadableImageComponant
                      classes='cover-image'
                      type={LoadableImageEnum.div.key}
                      alt={t(`${translationPath}contact-image`)}
                      src={
                        (item && item.imagePath &&
                          item.imagePath !== '' &&
                          getDownloadableLink(item.imagePath)) ||
                        getDefaultContactImage(item.type)
                      }
                    />
                    {item && item.isMerge && (
                      <Tooltip title={t(`${translationPath}merged`)}>
                        <div className='merged-contact'>
                          <span className='mdi mdi-arrow-collapse-all' />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div
                  className={`card-body-section${
                    isExpanded ? ' is-expanded' : ''
                  }`}
                >
                  <div className='body-title-wrapper'>
                    <span className='body-title'>{(item &&  item.name)}</span>
                    <div className='hedar-card'>
                      <div className='created-on'>
                        <span className='details-icon mdi mdi-calendar mdi-16px' />
                        <span>
                          <span className='details-text'>
                            {t(`${translationPath}created`)}:
                          </span>
                          <span className='px-1'>
                            {(item && item.createdOn &&
                              moment(item.createdOn)
                                .locale(i18next.language)
                                .format('DD/MM/YYYY')) ||
                              'N/A'}
                          </span>
                        </span>
                      </div>
                    </div>
        
                  </div>
                  <div className='details-item-wrapper'>
                    <span className='details-text contacts-text-wrap'>
                      {t(`${translationPath}id`)}
                    </span>{' '}
                    :
                    <Tooltip title={t(`${translationPath}copy`)}>
                      <CopyToClipboardComponents
                        data={item && item.id}
                        childrenData={item && item.id}
                      />
                    </Tooltip>
                  </div>
                  {item &&
                    item.details &&
                    item.details.length > 0 &&
                    item.details
                      .filter(
                        (filterItem, filterIndex) =>
                          (!isExpanded && filterIndex < notExpandedMax) ||
                          isExpanded,
                      )
                      .map((subItem, subIndex) => (
                        <div
                          key={`contactsCardItemRef${subIndex + 1}`}
                          className='details-item-wrapper'
                        >
                          {/* <span className={`details-icon ${subItem.iconClasses}`} /> */}
                          <span className='details-item details-item-flex'>
                            <span className='details-text contacts-text-wrap'>
                              {/* <span className='details-icon mdi mdi-minus mdi-18px' /> */}
                              {t(`${translationPath}${(subItem?.title) || ''}`)}
                              {subItem && subItem.title ? ' :' : ''}
                            </span>
                            <span
                              className='px-1 contacts-card-text'
                              title={typeof subItem.value === 'string'? subItem.value : ""}
                            >
                              {typeof subItem.value === 'string'? subItem.value : ""}
                            </span>
                          </span>
                        </div>
                      ))}
                </div>
              </div>
              <div className='cards-progress-wrapper'>
                <ProgressComponet
                  value={item && item.progress}
                  progressText={`${(item && item.progress) || 0}%`}
                  themeClasses='theme-gradient'
                />
              </div>
              {displyOpenFileButton && (
                <div className='cards-footer-wrapper'>
                  {displyOpenFileButton && (
                    <ButtonBase
                      className={`btns theme-transparent mx-0 ${
                        displyOpenFileButton ? 'w-50 ' : 'w-100'
                      }`}
                      onClick={onFooterActionsClicked(
                        ActionsEnum.folder.key,
                        item,
                        index,
                      )}
                      id='ActionsClickedfolder'
                      onKeyUp={(e) =>
                        openfile(ActionsEnum.folder.key, item, index, e)
                      }
                    >
                      <span className='mdi mdi-folder-outline' />{' '}
                      <span className='px-1'>
                        {t(`${translationPath}open-file`)}

                        </span>
                    </ButtonBase>
                  )}
                </div>
              )}
            </a>
          </div>
        ))}
    </div>
  );
};

DevelopersCards.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.instanceOf(Array),
    totalCount: PropTypes.number,
  }).isRequired,
  onFooterActionsClicked: PropTypes.func.isRequired,
  activeCard: PropTypes.instanceOf(Object),
  selectedDetailsContactItem: PropTypes.instanceOf(Object),
  isExpanded: PropTypes.bool,
  onActionClicked: PropTypes.func,
  onCardCheckboxClick: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  notExpandedMax: PropTypes.number,
  isCheckBoxDisabled: PropTypes.bool,
  selectedCards: PropTypes.instanceOf(Array),
  displyOpenFileButton: PropTypes.bool,
};
DevelopersCards.defaultProps = {
  notExpandedMax: 6,
  isExpanded: false,
  onActionClicked: undefined,
  selectedDetailsContactItem: undefined,
  activeCard: undefined,
  isCheckBoxDisabled: false,
  selectedCards: undefined,
  displyOpenFileButton: false,
};


  