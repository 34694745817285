
import React, { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useTitle } from '../../../Hooks';
import { ClientsSegmentationPermissions } from '../../../Permissions';
import { GlobalOrderFilterActions } from '../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import { GetAllClientSegmantationsAPI , GetClientSigmantationById} from '../../../Services';
import { GlobalHistory , bottomBoxComponentUpdate , returnPropsByPermissions   } from '../../../Helper';
import { ButtonBase } from '@material-ui/core';
import {
  Inputs,
  NoContentComponent,
  NoSearchResultComponent,
  PaginationComponent,
  Spinner, ViewTypes,
  PermissionsComponent,
} from '../../../Components';
import { ViewTypesEnum } from '../../../Enums';
import {  CardClientSegmentation ,TableClientSegmentation  } from './ClientSegmentationManagementView';
const parentTranslationPath = 'ClientsSegmentation';
const translationPath = '';

export const MarketingClientsSegmentationView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const dispatch = useDispatch();
  useTitle(t(`${translationPath}clients-segmentation`));


  const [sortBy, setSortBy] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [activeItem , setActiveItem] = useState(null) ; 
  const [ clientSegmentation ,  setClientSegmentation ] = useState(null) ; 
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: (orderFilter.clientsSegmentationFilter && orderFilter.clientsSegmentationFilter.filterBy) || null,
    orderBy: (orderFilter.clientsSegmentationFilter && orderFilter.clientsSegmentationFilter.orderBy) || null,
  });

  const [filter, setFilter] = useState({
    filterBy: (selectedOrderBy && selectedOrderBy.filterBy) || null,
    orderBy: (selectedOrderBy && selectedOrderBy.orderBy) || null,
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
    search: '',
    owner: null,
    createdBy: null,
    roleId: null,
    fromDate: null,
    toDate: null,
  });

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
  };
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);

  const [allClientSegmentation, setAllClientSegmentation] = useState({
    result: [],
    totalCount: 0,
  });

  const getClientSegmentationyId = useCallback(async () => {
    const res = await GetClientSigmantationById(activeItem && activeItem.clientSegmentationId);
    if (!(res && res.status && res.status !== 200))
       setClientSegmentation(res);
    else setClientSegmentation(null);
  } , [activeItem]);


  useEffect(() => {
    localStorage.setItem('clientSegmentation' , JSON.stringify(clientSegmentation));
  }, [clientSegmentation]) ; 

  useEffect(() => {
    if (activeItem)
       getClientSegmentationyId();
    else setClientSegmentation(null);
  }, [activeItem]);




  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(null);
  const [searchedItem, setSearchedItem] = useState('');
  const [IdSdelected, setIdSdelected] = useState(null);
  const [isOpen, setisOpen] = useState(false);
  const [selected, setSelected] = useState({
    activeFormType: 0,
  });
  const [Roles, setRoles] = useState({});
  const [isOpenOpation, setisisOpenOpation] = useState(false);
  const searchTimer = useRef(null);
  const [viewTypes, setViewTypes] = useState(ViewTypesEnum.cards.key);


  const onTypeChanged = (activeType) => {
    localStorage.setItem('ClientSegmentationView', JSON.stringify(activeType));
    setViewTypes(activeType);
    setFilter((item) => ({ ...item, pageIndex: 0 }));
  };

  const searchHandler = (search) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({ ...item, pageIndex: 1, search }));
    }, 700);
  };
  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };

  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy)
      return;

    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        clientsSegmentationFilter: {
          filterBy: selectedOrderBy.filterBy,
          orderBy: selectedOrderBy.orderBy,
        },
      })
    );
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };



  const reloadData = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };



  const GetAllClientSegmentations = useCallback(async () => {
    setIsLoading(true);
    if(returnPropsByPermissions(ClientsSegmentationPermissions.SearchAndViewClientsSegmentation.permissionsId))
    {
    const res = await GetAllClientSegmantationsAPI({ ...filter, pageIndex: (filter.pageIndex + 1) });
    if (res) {
      setAllClientSegmentation({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setAllClientSegmentation({
        result: [],
        totalCount: 0,
      });
    }
  }
    setIsLoading(false);
  }, [filter]);



  useEffect(() => {
    if (orderBy)
      setFilter((item) => ({ ...item, filterBy: orderBy.filterBy, orderBy: orderBy.orderBy }));
  }, [orderBy]);

  useEffect(() => {
    if (sortBy)
      setFilter((item) => ({ ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy }));
  }, [sortBy]);
  useEffect(() => {
    GetAllClientSegmentations();
  }, [GetAllClientSegmentations]);


  useEffect(() => {
    if(returnPropsByPermissions(ClientsSegmentationPermissions.SearchAndViewClientsSegmentation.permissionsId))
    {
     bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={allClientSegmentation.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
    }
  });

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  return (
    <>
      <div className='ReportsBuilderView-css'>
        <Spinner isActive={isLoading} />
        <div className=' view-wrapper d-flex-column'>
          <div className='header-section'>
            <div className='filter-section'>
              <div className='filter-ButtonBase'>
                <div className='section px-2'>
                  <PermissionsComponent
                    permissionsList={Object.values(ClientsSegmentationPermissions)}
                    permissionsId={ClientsSegmentationPermissions.ClientSegmentationDashboard.permissionsId}
                  >
                    <ButtonBase
                      className='btns theme-solid'
                      onClick={() => {
                        GlobalHistory.push(`/home/clients-segmentation/quick`);

                      }}
                    >
                      <span>{t(`${translationPath}quick-client-segmentation`)}</span>
                    </ButtonBase>
                    </PermissionsComponent>

                    <PermissionsComponent
                      permissionsList={Object.values(ClientsSegmentationPermissions)}
                      permissionsId={ClientsSegmentationPermissions.QuickClientSegmentationBranchDashboard.permissionsId}
                    >
                    <ButtonBase
                      className='btns theme-solid'
                      onClick={() => {
                        GlobalHistory.push(`/home/clients-segmentation/segmentation-branch`);

                      }}
                    >
                      <span>{t(`${translationPath}quick-client-segmentation-branch`)}</span>
                    </ButtonBase>
                  </PermissionsComponent>
                  <PermissionsComponent
                    permissionsList={Object.values(ClientsSegmentationPermissions)}
                    permissionsId={ClientsSegmentationPermissions.AddCustomClientSegmentation.permissionsId}
                  >
                    <ButtonBase
                      className='btns'
                      onClick={() => {
                        GlobalHistory.push(`/home/clients-segmentation/add`);

                      }}
                    >
                      <span className='mdi mdi-plus' />
                      <span>{t(`${translationPath}custom-client-segmentation`)}</span>
                    </ButtonBase>
                  </PermissionsComponent>
                </div>
              </div>

              <div className='section autocomplete-section'>
                <div className='Rep d-flex-column px-2 w-100 p-relative '>
                  <div className='w-100 p-relative'>
                    <div className='d-flex-column w-100'>
                    <PermissionsComponent
                      permissionsList={Object.values(ClientsSegmentationPermissions)}
                      permissionsId={ClientsSegmentationPermissions.SearchAndViewClientsSegmentation.permissionsId}
                   >
                 <Inputs
                  idRef='RotationCriteriaRef'
                  label={t(`${translationPath}search-client-segmentation`)}
                  value={searchedItem}
                  onInputChanged={(e) => setSearchedItem(e.target.value)}
                  inputPlaceholder={t(`${translationPath}search-client-segmentation`)}
                  onKeyUp={() => {
                    setTimer(
                      setTimeout(() => {
                        setFilter((f) => ({ ...f, search: searchedItem }));
                      }, 1000)
                    );
                  }}
                  onKeyDown={() => {
                    if (timer != null) clearTimeout(timer);
                  }}
                  beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                />
              </PermissionsComponent>
                    </div>
                    <div className='sc-search d-flex px-2'>
                      <span className=' mt-1' />

                    </div>

                  </div>
                </div>
                <PermissionsComponent
                      permissionsList={Object.values(ClientsSegmentationPermissions)}
                      permissionsId={ClientsSegmentationPermissions.SearchAndViewClientsSegmentation.permissionsId}
                   >
                <ViewTypes
                  onTypeChanged={onTypeChanged}
                  initialActiveType={viewTypes}
                  activeTypes={[ViewTypesEnum.tableView.key, ViewTypesEnum.cards.key]}
                  className='mb-3'
                />
                </PermissionsComponent>
              </div>
            </div>
          </div>
          {' '}
        </div>
      </div>
      <>
      <PermissionsComponent
                      permissionsList={Object.values(ClientsSegmentationPermissions)}
                      permissionsId={ClientsSegmentationPermissions.SearchAndViewClientsSegmentation.permissionsId}
                   >
        {((allClientSegmentation && allClientSegmentation.result && allClientSegmentation.result.length === 0) || (allClientSegmentation && allClientSegmentation.totalCount === 0)) && (filter.search !== '') ? (
          <NoSearchResultComponent />
        ) : (

          (allClientSegmentation && allClientSegmentation.totalCount === 0 && (
            <NoContentComponent />
          )) || (

            (viewTypes === ViewTypesEnum.tableView.key && (
              <>
                <div className='ReportsBuilder-data-view-wrapers'>
                  <TableClientSegmentation
                      translationPath={translationPath}
                      data={(allClientSegmentation)}
                      filter={filter}
                      parentTranslationPath={parentTranslationPath}
                      setActiveItem={setActiveItem}
                      setFilter={()=>setFilter((item) => ({ ...item, pageIndex: 0 }))}
                      activeItem={activeItem}
                      
                    />

                </div>
              </>
            )) || (
              < >
                <div className='ReportsBuilder-data-view-wrapers'>
                  <CardClientSegmentation
                      translationPath={translationPath}
                      reportDto={allClientSegmentation && allClientSegmentation.result}
                      parentTranslationPath={parentTranslationPath}
                      setActiveItem={setActiveItem}
                      setFilter={()=>setFilter((item) => ({ ...item, pageIndex: 0 }))}
                      activeItem={activeItem }
                    
                    />
                </div>
              </>
            )))}
            </PermissionsComponent>
      </>
    </>
  );
};
