import React from "react";
import { Box } from "@material-ui/core";
import { useSelectedTheme, useTranslate } from "../../../Hooks";
import { CustomBadge } from "../CustomBadge";

// Styles
import useStyles from "./styles";

const LeadTypeBadges = ({ leadTypesList, translateText = true }) => {
  const {
    theme: { palette },
  } = useSelectedTheme();
  
  const styles = useStyles();

  const { translate } = useTranslate("NewContactsView");

  const leadTypes = [
    {
      id: "1",
      name: translateText ? translate("SELLER") : "Seller",
      backgroundColor: palette.utility.gray_blue_50,
      borderColor: "none",
      color: palette.utility.gray_blue_700,
    },
    {
      id: "2",
      name: translateText ? translate("LANDLORD") : "Landlord",
      backgroundColor: palette.utility.indigo_50,
      borderColor: "none",
      color: palette.utility.indigo_700,
    },
    {
      id: "3",
      name: translateText ? translate("BUYER") : "Buyer",
      backgroundColor: palette.utility.pink_50,
      borderColor: "none",
      color: palette.utility.pink_700,
    },
    {
      id: "4",
      name: translateText ? translate("TENANT") : "Tenant",
      backgroundColor: palette.utility.blue_50,
      borderColor: "none",
      color: palette.utility.blue_700,
    },
  ];

  return (
    <Box className={styles.badgeSection}>
      {(leadTypesList.split(",") || []).map((id) => {
        const leadType = leadTypes.find((type) => type.id === id);
        if (!leadType) return null;

        return (
          <CustomBadge
            key={id}
            Style={{
              padding: "2px 10px 2px 8px",
              lineHeight: "24px",
              fontSize: "16px",
            }}
            label={leadType.name}
            BackgroundColor={leadType.backgroundColor}
            BorderColor={leadType.borderColor}
            Color={leadType.color}
          />
        );
      })}
    </Box>
  );
};

export default LeadTypeBadges;
