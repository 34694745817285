import React from "react";

function CopyIcon({ fill, ...restProps }) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M8.163.868a2.556 2.556 0 0 0-.996.271c-.23.113-.341.196-.586.442-.246.246-.329.356-.442.586-.263.537-.305.888-.305 2.541l-.001 1.125-1.125.001c-1.653 0-2.004.042-2.541.305-.23.113-.34.196-.586.442-.466.465-.647.881-.715 1.636-.043.486-.043 8.08 0 8.566.042.471.102.7.273 1.05.113.23.196.34.442.586.465.466.881.647 1.636.715.486.043 8.08.043 8.566 0 .471-.042.7-.102 1.05-.273.23-.113.34-.196.586-.442.246-.246.329-.356.442-.586.263-.537.305-.888.305-2.541l.001-1.125 1.125-.001c1.653 0 2.004-.042 2.541-.305.23-.113.34-.196.586-.442.466-.465.647-.881.715-1.636.043-.486.043-8.08 0-8.566-.042-.471-.102-.7-.273-1.05-.113-.23-.196-.34-.442-.586-.465-.466-.881-.647-1.636-.715-.431-.038-8.188-.036-8.62.002m8.87 1.718a.816.816 0 0 1 .405.436c.067.19.067 8.766 0 8.956a.816.816 0 0 1-.405.436c-.143.066-.214.07-1.508.08l-1.358.011-.001-1.961c0-2.553-.022-2.799-.305-3.377-.113-.23-.196-.34-.442-.586-.246-.246-.356-.329-.586-.442-.578-.283-.824-.305-3.377-.305l-1.961-.001.011-1.358c.01-1.221.017-1.372.071-1.492a.741.741 0 0 1 .586-.45c.085-.014 2.081-.024 4.437-.021 4.265.005 4.284.005 4.433.074m-5 5a.816.816 0 0 1 .405.436c.068.191.067 8.766 0 8.957a.814.814 0 0 1-.432.444c-.148.057-.389.06-4.506.06-4.887 0-4.537.018-4.777-.247-.219-.242-.206.042-.206-4.736 0-4.741-.01-4.491.191-4.721a.71.71 0 0 1 .455-.246c.085-.014 2.081-.024 4.437-.021 4.265.005 4.284.005 4.433.074"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default CopyIcon;
