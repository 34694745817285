import React, { useCallback, useRef, useState } from "react";
import moment from "moment";
import { startOfMonth, subMonths, startOfYear, subYears } from 'date-fns';
import clsx from "clsx";
import PropTypes from "prop-types";
import { createStaticRanges, DateRangePicker } from "react-date-range";
import { ButtonBase, IconButton } from "@material-ui/core";
import { CustomButton, CustomPopover } from "..";
import { useIsDesktop, useSelectedTheme } from "../../../Hooks";

// Styles
import useStyles from './styles';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./CustomDateRangePicker.scss";

// Icons
import { Calendar } from "../../../assets/icons";


function CustomDateRangePicker(props) {
  const { ranges, onDateChanged, minDate, maxDate, popoverIdRef, displayFormat, emptyLabel,
    idRef, isDisabled, onClearClicked, disabledDates, onSave
  } = props;

  const styles = useStyles();

  const {
    theme: { palette },
  } = useSelectedTheme();

  const { isDesktop } = useIsDesktop();
  
  const [attachedWith, setAttachedWith] = useState(null);
  const [isBlurOrChanged, setIsBlurOrChanged] = useState(false);
  
  const [prevDatePickerMonth, setPrevDatePickerMonth] = useState(subMonths(new Date(), 1));

  const displayRef = useRef(null);

  const handleClose = useCallback(() => {
    setAttachedWith(null);
  }, []);

  const openHandler = useCallback(() => {
    if (!isBlurOrChanged) setIsBlurOrChanged(true);
    setAttachedWith(displayRef.current);
  }, [isBlurOrChanged]);

  const staticRanges = createStaticRanges([
    {
      label: "Today",
      range: () => ({
        startDate: new Date(moment().startOf("day").format("YYYY-MM-DD[T00:00:00]")),
        endDate: new Date(moment().endOf("day")),
        key: 'selection',
      }),
    },
    {
      label: "Yesterday",
      range: () => ({ 
        startDate: new Date(moment().startOf("day").subtract(1, "days").add(1, "hours")),
        endDate: new Date(moment().endOf("day").subtract(1, "days")),
        key: 'selection',
      }),
    },
    {
      label: "This Week",
      range: () => ({
        startDate: new Date(moment().clone().startOf("isoWeek").add(1, "hours")),
        endDate: new Date(moment().clone().endOf("isoWeek")),
        key: 'selection',
      }),
    },
    {
      label: "Last Week",
      range: () => ({
        startDate: new Date(moment().subtract(1, "weeks").startOf("isoWeek").add(1, "hours")),
        endDate: new Date(moment().subtract(1, "weeks").endOf("isoWeek")),
        key: 'selection',
      }),
    },
    {
      label: "This Month",
      range: () => ({
        startDate: startOfMonth(new Date()),
        endDate: moment().endOf('month').endOf('day').toDate(),
        key: 'selection',
      }),
    },
    {
      label: "Last Month",
      range: () => ({
        startDate: startOfMonth(subMonths(new Date(), 1)),
        endDate: new Date(moment().subtract(1, "months").endOf("month")),
        key: 'selection',
      }),
    },
    {
      label: "This Year",
      range: () => ({
        startDate: startOfYear(new Date()),
        endDate: new Date(moment().endOf("year")),
        key: 'selection',
      }),
    },
    {
      label: "Last Year",
      range: () => ({
        startDate: startOfYear(subYears(new Date(), 1)),
        endDate: new Date(moment().subtract(1, "years").endOf("year")),
      }),
    },
  ]);

  const isContainedRange = !!(ranges && ranges.length > 0 && ranges[0] && ranges[0].startDate && ranges[0].endDate);

  const getShownDate = () => {
    if (ranges && ranges.length > 0 && ranges[0] && ranges[0].startDate === null) {
      // If "All time" is selected, show the previous month
      return subMonths(new Date(), 1);
    } else if (ranges && ranges.length > 0 && ranges[0] && ranges[0].startDate) {
      // If a specific range is selected, use the start date of that range.
      return ranges[0].startDate;
    } else {
      // Default to the previous month
      return subMonths(new Date(), 1);
    }
  };

  return (
    <>
      <ButtonBase
        id={idRef}
        disabled={isDisabled}
        ref={displayRef}
        onClick={openHandler}
        disableRipple
        className={clsx(styles.dateRangeBtn, {[styles.activeDateRangeBtn]: isContainedRange})}
      >
        <Calendar 
          width={20}
          height={20}
          fill={palette.button.secondary_fg}
        />

        <div className={styles.dateRanges}>
          {(ranges && ranges.length > 0 && ranges[0] && ranges[0].startDate && ranges[0].endDate) ? 
            moment(ranges[0].startDate).format(displayFormat) === moment(ranges[0].endDate).format(displayFormat) ? 
            (<span>{moment(ranges[0].startDate).format(displayFormat)}</span>)
            :
            (<>
                <span>{moment(ranges[0].startDate).format(displayFormat)}</span>
                <span className="px-1">-</span>
                <span>{moment(ranges[0].endDate).format(displayFormat)}</span>
              </>)
            : (<span className={styles.descriptionText}>{emptyLabel}</span>)}
        </div>
        {onClearClicked &&
          ranges && ranges.length > 0 && ranges[0] && ranges[0].startDate && ranges[0].endDate && (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClearClicked();
              }}
            >
              <span className={clsx("mdi mdi-close", styles.closeIcon)} />
            </IconButton>
        )}
      </ButtonBase>

      <CustomPopover
        idRef={popoverIdRef}
        handleClose={handleClose}
        attachedWith={attachedWith}
        customElevation={0}
        component={
          <div className="date-range-picker-container">
            {!isDesktop && 
              <div className="prev-month-name">
                {moment(prevDatePickerMonth).format("MMM yyyy")}
              </div>
            }
            <DateRangePicker
              months={2}
              direction={isDesktop ? "horizontal" : "vertical"}
              moveRangeOnFirstSelection={false}
              ranges={ranges}
              minDate={minDate}
              maxDate={maxDate}
              disabledDates={disabledDates}
              onChange={(selectedDate) => {
                setPrevDatePickerMonth(selectedDate.selection.startDate);
                onDateChanged(selectedDate);
              }}
              staticRanges={staticRanges}
              showDateDisplay={false}
              showMonthAndYearPickers={false}
              inputRanges={[]}
              rangeColors={[palette.background.brand_solid]}
              shownDate={getShownDate()}
              onShownDateChange={(month) => setPrevDatePickerMonth(month)}
            />
            <div className="custom-footer">
              {ranges && ranges.length > 0 && ranges[0] && ranges[0].startDate && ranges[0].endDate ? (
                moment(ranges[0].startDate).format(displayFormat) === moment(ranges[0].endDate).format(displayFormat) ? (
                  <span className={styles.footerDate}>{moment(ranges[0].startDate).format(displayFormat)}</span>
                ) : (
                  <div className={styles.footerDateWrapper}>
                    <span className={styles.footerDate}>
                      {moment(ranges[0].startDate).format(displayFormat)}
                    </span>
                    
                    <span className={clsx("separator", styles.separator)}>-</span>
                    
                    <span className={styles.footerDate}>
                      {moment(ranges[0].endDate).format(displayFormat)}
                    </span>
                  </div>
                )
              ) : (
                <span className={styles.footerDate}>
                  {emptyLabel}
                </span>
              )}

              <div className={clsx("actions", styles.actions)}>
                <CustomButton
                  boxShadow="xs"
                  size="md"
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  boxShadow="xs"
                  size="md"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    onSave(ranges[0]);
                    setAttachedWith(null);
                  }}
                >
                  Apply
                </CustomButton>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

CustomDateRangePicker.propTypes = {
  onClearClicked: PropTypes.func,
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.instanceOf(moment),
        PropTypes.string,
      ]),
      endDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.instanceOf(moment),
        PropTypes.string,
      ]),
      key: PropTypes.string,
    })
  ).isRequired,
  onDateChanged: PropTypes.func.isRequired,
  minDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  disabledDates: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment),
      PropTypes.string,
    ])
  ),
  popoverIdRef: PropTypes.string,
  emptyLabel: PropTypes.string,
  displayFormat: PropTypes.string,
  isDisabled: PropTypes.bool,
  idRef: PropTypes.string,
  onSave: PropTypes.func,
};

CustomDateRangePicker.defaultProps = {
  onClearClicked: () => {},
  popoverIdRef: "dateRangePopoverRef",
  emptyLabel: "all",
  displayFormat: "DD/MM/YYYY",
  minDate: undefined,
  maxDate: undefined,
  idRef: "dateRangePickerRef",
  isDisabled: false,
  disabledDates: undefined,
  onSave: () => {},
};

export default CustomDateRangePicker;
