import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import { getBgProgressColor } from '../../Helper';
import { HistoryTabsComponent } from '../HistoryComponent/HistoryTabsComponent';

export const CompletedDataComponent = ({ completedData , activeItem  , formType , typeId , operationType , isPropertyManagementView }) => {
  const { t } = useTranslation('Shared');
  const [isOpen, setIsOpen] = useState(false);



  return (
    <>
      <div className='completed-data-component-wrapper'>
        <span className='completed-history-wrapper'>
          <ButtonBase onClick={() => setIsOpen(true)} className='btns c-black-light history-button'>
            <span className='mdi mdi-clock-time-four-outline' />
            {t('history')}
          </ButtonBase>
        </span>
        <div
          className={`completed-data-content ${getBgProgressColor(completedData || 0).className}`}
        >
          <span>{`${(completedData && `${completedData}%`) || 'N/A'}`}</span>
        </div>
      </div>
      <HistoryTabsComponent  
        activeItem={activeItem}
        isOpen={isOpen}
        isOpenChanged={() => setIsOpen(false)}
        formType={formType}
        typeId={typeId}
        operationType={operationType}
        isPropertyManagementView={isPropertyManagementView}
       />
    </>
  );
};

CompletedDataComponent.propTypes = {
  completedData: PropTypes.number.isRequired,
  activeItem : PropTypes.objectOf(PropTypes.any)  ,
  formType :PropTypes.string  ,
  typeId :  PropTypes.string ,
  operationType :  PropTypes.string , 
  isPropertyManagementView :  PropTypes.bool

};

CompletedDataComponent.defaultProps = {
  activeItem :undefined , 
  formType : undefined  ,
  typeId : undefined,
  operationType :  undefined, 
  isPropertyManagementView :  false ,
};
