export const UsersManagementPermissions = {
  AddUserButton: {
    permissionsId: "8744a621-a020-4915-f470-08dc3da1d8ed",
    permissionsName: "Add User Button",
    description: null,
    componentsId: null,
    components: null,
  },
  ViewUserInformation: {
    permissionsId: "9916083a-2e22-4627-f471-08dc3da1d8ed",
    permissionsName: "View User Information",
    description: null,
    componentsId: null,
    components: null,
  },
  EditUserInformation: {
    permissionsId: "5ae73e23-5866-4446-f472-08dc3da1d8ed",
    permissionsName: "Edit User Information",
    description: null,
    componentsId: null,
    components: null,
  },
  ViewActiveUsers: {
    permissionsId: "075336dc-2d9c-4903-f473-08dc3da1d8ed",
    permissionsName: "View Active Users",
    description: null,
    componentsId: null,
    components: null,
  },
  ViewPendingActivationUsers: {
    permissionsId: "08e07a16-c5e5-4eda-f474-08dc3da1d8ed",
    permissionsName: "View Pending Activation Users",
    description: null,
    componentsId: null,
    components: null,
  },
  EditGroup: {
    permissionsId: "8f33c794-f6b4-41d0-77ec-08dc5572f9c8",
    permissionsName: "Assign Group To User",
    description: null,
    componentsId: null,
    components: null,
  },
  EditRoles: {
    permissionsId: "84b2c98d-2ae1-4fc3-77ea-08dc5572f9c8",
    permissionsName: "Assign Roles To User",
    description: null,
    componentsId: null,
    components: null,
  },
  EditPolicy: {
    permissionsId: "eb4e4d8e-28b9-4f33-77eb-08dc5572f9c8",
    permissionsName:"Assign Policy To User",
    description: null,
    componentsId: null,
    components: null,
  },
  DeactiveUser :{
     permissionsId: "2ea89af4-49d3-478d-c4cd-08dc683b0c43",
     permissionsName: "Deactive User",
     description: null,
     componentsId: null,
     components: null
   } , 
   ViewPendingDeactivationUsers : {
     permissionsId: "37c4d745-0caa-4278-5dc4-08dc7403ebc2",
     permissionsName: "View Pending Deactivation Users",
     description: null,
     componentsId: null,
     components: null
   },
   ViewDeactivatedUsers :{
     permissionsId: "6310bc7b-2d24-4cf9-5dc5-08dc7403ebc2",
     permissionsName: "View Deactivated Users",
     description: null,
     componentsId: null,
     components: null
   },
   ReactiveUserButton : {
     permissionsId: "12ecdf93-6e4d-4be8-5dc6-08dc7403ebc2",
     permissionsName: "Reactive User Button",
     description: null,
     componentsId: null,
     components: null
    }
};
  