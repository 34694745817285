
export const ClientsSegmentationFieldsEnum = 
{
    Contact :
    [
        {
            field: {
                id: "createdOn",
                FieldType: "alt-date",
                isRequired: false
            },
            data: {
                type: "string",
                format: "date",
                title: "Created On",
                maxDate: "",
                minDate: "",
                hasEffectOn: "",
                iconField: "",
                hasEffectedFrom: "",
                regExp: "",
                errorMsg: "",
                isSearchable: false,
                searchableKey: "createdOn",
                displayPath: "createdOn",
                specialKey:'createdOn_contact' , 
                enum: []
            }
        } , 
        {
            field: {
                id: "createdBy",
                FieldType: "searchField",
                isRequired: false
            },
            data: {
                type: "string",
                title: "Created By",
                searchKey: "User",
                iconField: "",
                dependOn: "",
                multi: "false",
                regExp: "",
                errorMsg: "",
                default: "",
                isSearchable: true,
                searchableKey: "createdBy",
                displayPath: "createdBy",
                DtoName: "",
                specialKey:'createdBy_contact' , 
                enum: []
            }
        } , 
        {
            field: {
                id: "updatedOn",
                FieldType: "alt-date",
                isRequired: false
            },
            data: {
                type: "string",
                format: "date",
                title: "Updated On",
                maxDate: "",
                minDate: "",
                hasEffectOn: "",
                iconField: "",
                hasEffectedFrom: "",
                regExp: "",
                errorMsg: "",
                isSearchable: false,
                searchableKey: "UpdateOn",
                displayPath: "UpdateOn",
                specialKey:'updatedOn_contact' , 
                enum: []
            }
        } , 
        {
            field: {
                id: "updatedBy",
                FieldType: "searchField",
                isRequired: false
            },
            data: {
                type: "string",
                title: "Updated By",
                searchKey: "User",
                iconField: "",
                dependOn: "",
                multi: "false",
                regExp: "",
                errorMsg: "",
                default: "",
                isSearchable: true,
                searchableKey: "updatedBy",
                displayPath: "updatedBy",
                DtoName: "",
                enum: [] , 
                specialKey:'updatedBy_contact' , 

            }
        } , 
    ],
 
    Lead : [
        {
            field: {
                id: "leadClass",
                FieldType: "select",
                isRequired: true
            },
            data: {
                type: "string",
                format: "string",
                title: "Lead Class",
                multi: "false",
                specialKey: "",
                hasEffectOn: "",
                hasEffectedFrom: "",
                api: "",
                default: "",
                enum: [],
                isSearchable: false,
                searchableKey: "leadClass",
                displayPath: "leadClass"
            }
        } , 
        {
            field: {
                id: "createdOn",
                FieldType: "alt-date",
                isRequired: false
            },
            data: {
                type: "string",
                format: "date",
                title: "Created On",
                maxDate: "",
                minDate: "",
                hasEffectOn: "",
                iconField: "",
                hasEffectedFrom: "",
                regExp: "",
                errorMsg: "",
                isSearchable: false,
                searchableKey: "createdOn",
                displayPath: "createdOn",
                enum: [] ,
                specialKey:'createdOn_lead' , 

            }
        } , 
        {
            field: {
                id: "createdBy",
                FieldType: "searchField",
                isRequired: false
            },
            data: {
                type: "string",
                title: "Created By",
                searchKey: "User",
                iconField: "",
                dependOn: "",
                multi: "false",
                regExp: "",
                errorMsg: "",
                default: "",
                isSearchable: true,
                searchableKey: "createdBy",
                displayPath: "createdBy",
                DtoName: "",
                enum: [] , 
                specialKey:'createdBy_lead' , 

            }
        } , 
        {
            field: {
                id: "updatedOn",
                FieldType: "alt-date",
                isRequired: false
            },
            data: {
                type: "string",
                format: "date",
                title: "Updated On",
                maxDate: "",
                minDate: "",
                hasEffectOn: "",
                iconField: "",
                hasEffectedFrom: "",
                regExp: "",
                errorMsg: "",
                isSearchable: false,
                searchableKey: "UpdateOn",
                displayPath: "UpdateOn",
                enum: [] , 
                specialKey:'updatedOn_lead' , 

            }
        } , 
        {
            field: {
                id: "updatedBy",
                FieldType: "searchField",
                isRequired: false
            },
            data: {
                type: "string",
                title: "Updated By",
                searchKey: "User",
                iconField: "",
                dependOn: "",
                multi: "false",
                regExp: "",
                errorMsg: "",
                default: "",
                isSearchable: true,
                searchableKey: "updatedBy",
                displayPath: "updatedBy",
                DtoName: "",
                enum: [] , 
                specialKey:'updatedBy_lead' , 
            }
        } , 
        
    ], 
    Activities :[{
        field: {
            id: "last_assign_date",
            FieldType: "alt-date",
            isRequired: false
        },
        data: {
            type: "string",
            format: "date",
            title: "Last Assign Date",
            maxDate: "",
            minDate: "",
            hasEffectOn: "",
            iconField: "",
            hasEffectedFrom: "",
            regExp: "",
            errorMsg: "",
            isSearchable: false,
            searchableKey: "AssignDate",
            displayPath: "AssignDate",
            enum: []
        }
    } ,  
    {
        field: {
            id: "last_activity_type_name",
            FieldType: "searchField",
            isRequired: false
        },
        data: {
            type: "string",
            title: "Last Activity Type Name",
            searchKey: "activities",
            iconField: "",
            dependOn: "",
            multi: "false",
            regExp: "",
            errorMsg: "",
            default: "",
            isSearchable: true,
            searchableKey: "LastActivityTypeName",
            displayPath: "LastActivityTypeName",
            DtoName: "",
            enum: []
        }
    } , 
    {
        field: {
            id: "last_activity_createdBy",
            FieldType: "textField",
            isRequired: false
        },
        data: {
            type: "string",
            title: "Last Activity Created By",
            searchKey: "User",
            iconField: "",
            dependOn: "",
            multi: "false",
            regExp: "",
            errorMsg: "",
            default: "",
            isSearchable: true,
            searchableKey :'LastActivityCreatedByName' , 
            displayPath: "LastActivityCreatedByName",
            DtoName: "",
            enum: []
        }
    } 
    ]  ,
   OwnerLead : [
    {
        field: {
          id: "country",
          FieldType: "select",
          isRequired: true
        },
        data: {
          type: "string",
          format: "string",
          title: "Country  ",
          description: "Main Information",
          multi: "false",
          specialKey: "country",
          hasEffectOn: "city",
          hasEffectedFrom: "",
          api: "",
          lookup: 16,
          default: "United Arab Emirates",
          iconField: "",
          enum: [
    
          ],
          isSearchable: true,
          searchableKey: "country_com",
          displayPath: "country.lookupItemName",
          DtoName: ""
        }
      },
      {
        field: {
          id: "city",
          FieldType: "select",
          isRequired: true
        },
        data : {
          type: "string",
          format: "string",
          title: "City  ",
          description: "Main Information",
          multi: "false",
          specialKey: "city",
          hasEffectOn: "District",
          hasEffectedFrom: "country",
          api: "",
          lookup: 17,
          default: "",
          iconField: "",
          enum: [
          ],
          isSearchable: true,
          searchableKey: "city_com",
          displayPath: "city.lookupItemName",
          DtoName: ""
        }
      },
      {
        field: {
          id: "district",
          FieldType: "select",
          isRequired: false
        },
        data: {
          type: "string",
          format: "string",
          title: "District",
          description: "Main Information",
          multi: "false",
          specialKey: "district",
          hasEffectOn: "Community",
          hasEffectedFrom: "City",
          api: "",
          lookup: 18,
          default: "",
          iconField: "",
          enum: [
          ],
          isSearchable: true,
          searchableKey: "district_com",
          displayPath: "district.lookupItemName",
          DtoName: ""
        }
      },
      {
        field: {
          id: "community",
          FieldType: "select",
          isRequired: false
        },
        data: {
          type: "string",
          format: "string",
          title: "Community ",
          description: "Main Information",
          multi: "false",
          specialKey: "community",
          hasEffectOn: "sub_community",
          hasEffectedFrom: "district",
          api: "",
          lookup: 19,
          default: "",
          iconField: "",
          enum: [
    
          ],
          isSearchable: true,
          searchableKey: "community_com",
          displayPath: "community.lookupItemName",
          DtoName: "" ,
        }
      },
      {
        field: {
          id: "sub_community",
          FieldType: "select",
          isRequired: false ,
        },
        data: {
          type: "string",
          format: "string",
          title: "Sub Community",
          description: "Main Information",
          multi: "false",
          specialKey: "",
          hasEffectOn: "property_name",
          hasEffectedFrom: "Community",
          api: "",
          lookup: 20,
          default: "",
          iconField: "office-building",
          enum: [
          ],
          isSearchable: true,
          searchableKey: "sub_community",
          displayPath: "subCommunity.lookupItemName",
          DtoName: "" ,
    }
   },
 ]
} ;
   

  