import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { GetAdvanceSearchContacts } from '../../../../../../Services';
import { AutocompleteComponent, RadiosGroupComponent } from '../../../../../../Components';
import { ContactClassificationsEnum } from '../../../../../../Enums/ContactClassifications.Enum';

export const DeveloperComponent = ({
    parentTranslationPath,
    translationPath,
    onStateChanged,
    state,
}) => {
    const { t } = useTranslation([parentTranslationPath, 'Shared']);
    const [contacts, setContacts] = useState([]);
    const [value, setValue] = useState('');
    const [timer, setTimer] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState({
        criteria: {
            contact_classifications: [{ searchType: 1, value: ContactClassificationsEnum.Developer.key }]
        },
        filterBy: 'createdOn',
        orderBy: 2,
        fromDate: null,
        toDate: null,
        LeadClasses: [],
    });

    const GetAllContact = useCallback(async () => {
        setIsLoading(true);
        const res = await GetAdvanceSearchContacts({ pageIndex: 0, pageSize: 50 }, filter);
        if (!(res && res.status && res.status !== 200)) {
            setContacts({
                result: ((res && res.result) || []).map((item) => {
                    const devloper = JSON.parse(item.contactJson).contact;
                    return {
                        developerId: item.contactId,
                        developerName: devloper.company_name ? devloper.company_name : `${devloper.first_name} ${devloper.last_name}`
                    };
                }),
                totalCount: (res && res.totalCount) || 0,
            });
        } else {
 setContacts(({
            result: [],
            totalCount: 0,
        }));
}
        setIsLoading(false);
    }, []);
    useEffect(() => {
        GetAllContact();
    }, [filter]);
    const [ViewComponent, setViewComponent] = useState(1);


    useEffect(() => {
        if(state?.rotationSchemaDeveloperIds?.length > 0) 
        {
          setViewComponent(2)
        }
      }, [state]);
    return (
      <>
        <div className='dialog-content-item'>
          <RadiosGroupComponent
            idRef='DevelopersCCSD'
            isDisabled={state?.rotationSchemaDeveloperIds?.length > 0 || false}
            wrapperClasses='wrapperClasses-cusyy'
            themeClass='theme-default'
            data={[
          {
            key: 1,
            label:  t(`${translationPath}General-for-all-Developers`) ,
            value: false,
          },
          {
            key: 2,
            label:  t(`${translationPath}Specific-Developers`) ,
            value: true,
          },
        ]}
            onSelectedRadioChanged={(event) =>
          setViewComponent(
            +event.target.value || false,
          )}
            value={(ViewComponent) || null}
            labelValue={t(`${translationPath}Developers`)}
            labelInput='label'
            valueInput='key'
          />
          { ViewComponent === 2 && (
          <AutocompleteComponent
            idRef='developerIdRef'
            labelValue={t(`${translationPath}developer`)}
            selectedValues={state.rotationSchemaDeveloperIds}
            withLoader
            data={contacts.result || []}
            multiple
            displayLabel={(option) => (option && option.developerName) || ''}
            chipsLabel={(option) => (option && option.developerName) || ''}
            withoutSearchButton
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            isLoading={isLoading}
            onChange={(event, newValue) => {
                        const localNewValue = {
                            id: 'rotationSchemaDeveloperIds',
                            value: [
                                ...newValue,
                            ],
                        };
                        onStateChanged(localNewValue);
                    }}
            filterOptions={(options) => {
                        const isFind = (id) => state.rotationSchemaDeveloperIds.findIndex((w) => w.developerId === id) === -1;
                        return options.filter((w) => isFind(w.developerId));
                    }}
            textValue={value}
            onInputChange={(e) => {
                        setValue(e.target.value);
                    }}
            onTextKeyDown={() => {
                        if (timer)
                            clearTimeout(timer);
                    }}
            onTextKeyUp={() => {
                        const t = setTimeout(() => {
                            if (value) {
                                const criterias = filter.criteria;
                                criterias.fullName = [];
                                criterias.fullName.push({ searchType: 2, value });
                                setFilter({ ...filter, criteria: criterias });
                            } else {
                                const criterias = filter.criteria;
                                criterias.fullName = [];
                                criterias.fullName.push({ searchType: 2, value: '' });
                                setFilter({ ...filter, criteria: criterias });
                            }
                        }, 1000);
                        setTimer(t);
                    }}
          />
)}
        </div>
      </>
    );
};
const convertJsonValueShape = PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.array,
    PropTypes.array,
    PropTypes.array,
]);
DeveloperComponent.propTypes = {
    parentTranslationPath: PropTypes.string.isRequired,
    state: PropTypes.objectOf(convertJsonValueShape).isRequired,
    translationPath: PropTypes.string.isRequired,
    onStateChanged: PropTypes.func.isRequired,
};
