import React, { useState } from 'react';
import { DialogComponent } from '../../../../../../Components';
import {
  CreateBulkUnitRequestToPublishOrUnPublish,
  CreateUnitRequestToPublishOrUnPublish,
} from '../../../../../../Services';
import { useTranslation } from 'react-i18next';
import { showError, showSuccess } from '../../../../../../Helper';

export const NeedsApprovalDialogBulkUnit = ({
  loginResponse,
  isDialogOpen,
  setIsDialogOpen,
  typeOfRequest,
  unitsIdUpdateArray,
  reloadData,
  parentTranslationPath,
  translationPath,
  checkedCards,
  onUnitsBulkCloseDialogHandler
}) => {
  const isLease = window.location.pathname === '/home/units-lease/view';
  const { t } = useTranslation(parentTranslationPath);
  const createMarketingData = (unit, isLease, typeOfRequest) => {
    let marketingData = {
      unitId: unit.id,
      uspId: null,
      agentsId: null,
      titleEn: null,
      titleAr: null,
      descriptionEn: null,
      descriptionAr: null,
      isFeatureUnit: false,
      isHotDealUnit: false,
      marketingWebPortalIds: [],
      isPublishedForDubizzleSale: false,
      isPublishedForDubizzleLease: false,
      isPublishedForBayoutSale: false,
      isPublishedForPropertyFinderSale: false,
      isPublishedForPrianSale: false,
      isPublishedForHouzaSale: false,
    };
    if (isLease === false) {
      marketingData = {
        ...marketingData,
        isForLease: false,
        isPublishUnitLease: false,
        isPublishUnitSale: typeOfRequest === 1 ? true : false,
      };
    } else if (isLease === true) {
      marketingData = {
        ...marketingData,
        isForLease: true,
        isPublishUnitSale: false,
        isPublishUnitLease: typeOfRequest === 1 ? true : false,
      };
    }
    return marketingData;
  };

  const unitMarketingRequests = checkedCards?.map((unit) => ({
    unitId: unit.id,
    marketingData: createMarketingData(unit, isLease, typeOfRequest),
  }));

  const handleCreateUnitRequestToPublishOrUnPublish = async () => {
    const body = {
      typeOfRequest,
      unitMarketingRequests,
    };
    const res = await CreateBulkUnitRequestToPublishOrUnPublish(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}create-request-massage`));
      reloadData()
      onUnitsBulkCloseDialogHandler(false)
      setIsDialogOpen(false);
      return res;
    }else {
      showError(t(`${translationPath}cannot-set-unit-as-published-without-price`))
      setIsDialogOpen(false);
    }
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <DialogComponent
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        isOpen={isDialogOpen}
        titleText={'Approval Required'}
        maxWidth={'sm'}
        saveType='button'
        saveClasses='btns theme-solid w-100 mx-2 mb-2'
        onSaveClicked={handleCreateUnitRequestToPublishOrUnPublish}
        onCancelClicked={handleCloseDialog}
        onCloseClicked={handleCloseDialog}
        dialogContent={
          <div>
            <p>{t(`${translationPath}approval-message`)}</p>
          </div>
        }
      />
    </>
  );
};
