export const CampaignStatusEnum = {
  Created: {
    id: 1,
    name: 'Created',
    label: 'Created',
    icon: 'mdi mdi-comment-plus-outline',
  },
  DepartmentApproved: {
    id: 2,
    name: 'DepartmentApproved',
    label: 'Department Approved',
    icon: 'mdi mdi-comment-check-outline',
  },
  DepartmentRejected: {
    id: 3,
    name: 'DepartmentRejected',
    label: 'Department Rejected',
    icon: 'mdi mdi-comment-remove-outline',
  },
  Resubmitted: {
    id: 4,
    name: 'Resubmitted',
    label: 'Resubmitted',
    icon: 'mdi mdi-comment-text-multiple-outline',
  },
  MarketingApproved: {
    id: 5,
    name: 'MarketingApproved',
    label: 'Marketing Approved',
    icon: 'mdi mdi-comment-check-outline',
  },
  MarketingRejected: {
    id: 6,
    name: 'MarketingRejected',
    label: 'Marketing Rejected',
    icon: 'mdi mdi-comment-remove-outline',
  },
  MarketingInProgress: {
    id: 7,
    name: 'MarketingInProgress',
    label: 'Marketing In Progress',
    icon: 'mdi mdi-comment-processing-outline',
  },
  PendingLaunch: {
    id: 8,
    name: 'PendingLaunch',
    label: 'Pending Launch',
    icon: 'mdi mdi-message-draw',
  },
  Complete: {
    id: 9,
    name: 'Complete',
    label: 'Complete',
    icon: 'mdi mdi-checkbox-multiple-marked',
  },
};
