import React  from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';
import { Luxury } from './Luxury' ; 


  export const LifeStyleContent = ({
    parentTranslationPath ,
    translationPath ,
    state ,
    setState ,
   } ) => { 

    const { t } = useTranslation(parentTranslationPath);

    return (
      <>
        <div className="d-flex-column">
        <span className="fw-bold"> {t(`${translationPath}life-style`)} </span>
        </div> 
        <div className='w-100'>
         {    state && state.updatePropertyRatingLifeStyleDtos &&  state.updatePropertyRatingLifeStyleDtos.map((item, index) => 
            (
            <div className="d-flex d-flex-v-center life-style-card b-bottom" key={`LifeStyle${index}`}>
            <div>
            <span className='fw-bold'> {item.lookupItemName} </span> 
            </div>
            <div>
            <Rating  
                size="large"
                name={`LifeStyle${index}`}
                max={10}
                value={item.score}
                precision={1}
                onChange={(event, newValue) => 
                {
                  const updateList = [...state.updatePropertyRatingLifeStyleDtos];
                  updateList[index].score= newValue || 0 ;
                  setState({id : 'updatePropertyRatingLifeStyleDtos' , value : updateList })
                }}
                />     
              </div>
           </div>  
          )) } 
        </div>
      </>
    );
     } ; 
  

