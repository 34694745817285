import React, {
    useCallback, useEffect, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
    RadiosGroupComponent, AutocompleteComponent, Inputs
} from '../../../../../Components';
import { GetAllBranches, GetTeam } from '../../../../../Services';

import { Card, CardContent, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';


export const SchemaSetting = ({
    parentTranslationPath,
    translationPath,
    state,
    onStateChanged,
    schema,
    isSubmitted,
    rotationEdit,
    onScemaChanged,
    currentSelected,
    setCurrentSelected
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const [ResponseTeam, SetResponseTeam] = useState([]);
    const [loadings, setloading] = useState(false);


    const [loadingsTeam, setloadingsTeam] = useState(false);
    const filter = {
        pageSize: 25,
        pageIndex: 0,
        branchName: '',
        filterBy: null,
        orderBy: null,

    };
    const searchTimer = useRef(null);
    const [branches, setBranches] = useState({
        result: [],
        totalCount: 0
    });
    const getAllBranches = useCallback(async (value) => {
        setloading(true);
        const res = await GetAllBranches({
            ...filter, pageSize: 25, pageIndex: 1, branchName: value
        });
        if (!(res && res.status && res.status !== 200)) {
            setBranches({
                result: res.result,
                totalCount: res.totalCount
            });
        } else {
            setBranches({
                result: [],
                totalCount: 0
            });
        }

        setloading(false);
    }, [filter]);

    const GetMyTeam = async (searchItem) => {
        setloadingsTeam(true);
        const res = await GetTeam(1, 25, (searchItem || ''));

        if (!(res && res.status && res.status !== 200))
            SetResponseTeam(res);

        else
            SetResponseTeam([]);

        setloadingsTeam(false);
    };
    useEffect(() => {
        getAllBranches();
        GetMyTeam();
    }, []);




    const handleChange = (e) => {
        const localNewValue = {
            id: 'isOverrideLeadCapacitySettingEnabled',
            value: JSON.parse(e.target.value)
        };
        onStateChanged(localNewValue);
    };




    return (
        <>
            <div className='schema-setting-wrapper p-4'>

                <div class="frame-3">
                    <div class="frame-6">
                        <div class="frame-1">
                            <div class="one-by-one-distribution" > {t(`${translationPath}One-By-One-Distribution`)}  </div>
                        </div>
                    </div>
                </div>


                <div >
                    <RadioGroup aria-label="gender" name="gender1" value={JSON.stringify(state.isOverrideLeadCapacitySettingEnabled)} onChange={handleChange}>
                        <div class="the-cap-based-on">{t(`${translationPath}The-Cap-based-On`)} </div>
                        <div className='d-flex mt-1'>
                            <div class="checkbox-group-item">
                                <div class="input">
                                    <FormControlLabel value="false" control={<Radio />} />
                                </div>
                                <div class="content">
                                    <div class="text-and-supporting-text">
                                        <svg
                                            class="monitor-03"
                                            width="50"
                                            height="49"
                                            viewBox="0 0 50 49"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M4.58301 28.5833L45.4163 28.5833M16.833 42.875H33.1663M14.383 36.75H35.6163C39.0467 36.75 40.7618 36.75 42.072 36.0824C43.2245 35.4952 44.1615 34.5582 44.7488 33.4057C45.4163 32.0955 45.4163 30.3803 45.4163 26.95V15.925C45.4163 12.4947 45.4163 10.7795 44.7488 9.46931C44.1615 8.31682 43.2245 7.37981 42.072 6.79259C40.7618 6.125 39.0467 6.125 35.6163 6.125H14.383C10.9527 6.125 9.23753 6.125 7.92732 6.79259C6.77482 7.37981 5.83782 8.31682 5.25059 9.46931C4.58301 10.7795 4.58301 12.4947 4.58301 15.925V26.95C4.58301 30.3803 4.58301 32.0955 5.25059 33.4057C5.83782 34.5582 6.77482 35.4952 7.92732 36.0824C9.23753 36.75 10.9527 36.75 14.383 36.75Z"
                                                stroke="#8E8E93"
                                                stroke-width="3"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                        <div class="text-and-subtext">
                                            <div class="text">{t(`${translationPath}Over-All-System`)}  </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="checkbox-group-item">
                                <div class="input">
                                    <FormControlLabel value="true" control={<Radio />} />
                                </div>


                                <div class="content">
                                    <div class="text-and-supporting-text">
                                        <svg
                                            class="presentation-chart-02"
                                            width="50"
                                            height="49"
                                            viewBox="0 0 50 49"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M24.9987 32.6666V42.875M37.2487 42.875L29.1812 36.1521C27.6933 34.9121 26.9493 34.2922 26.1189 34.0553C25.3867 33.8464 24.6107 33.8464 23.8785 34.0553C23.048 34.2922 22.3041 34.9122 20.8162 36.1521L12.7487 42.875M16.832 22.4583V24.5M24.9987 18.375V24.5M33.1654 14.2916V24.5M45.4154 6.12497H4.58203M6.6237 6.12497H43.3737V22.8666C43.3737 26.297 43.3737 28.0121 42.7061 29.3223C42.1189 30.4748 41.1819 31.4118 40.0294 31.9991C38.7192 32.6666 37.004 32.6666 33.5737 32.6666H16.4237C12.9934 32.6666 11.2782 32.6666 9.96801 31.9991C8.81551 31.4118 7.87851 30.4748 7.29128 29.3223C6.6237 28.0121 6.6237 26.297 6.6237 22.8666V6.12497Z"
                                                // stroke="var(--c-primary)"
                                                stroke="#8E8E93"
                                                stroke-width="3"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>


                                        <div class="text-and-subtext">
                                            <div class="text">{t(`${translationPath}Current-Schema`)} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </RadioGroup>
                    {/* <RadioGroup aria-label="gender" name="gender1" value={JSON.stringify(state.isOverrideLeadCapacitySettingEnabled)} onChange={handleChange}>
                        <div class="the-cap-based-on">  Distribute Based on Agent Location </div>
                        <div className='d-flex mt-1'>
                            <div class="checkbox-group-item">
                                <div class="input">
                                    <FormControlLabel value="No" control={<Radio />} />
                                </div>
                                <div class="content">
                                    <div class="text-and-supporting-text">
                                        <svg
                                            class="x-close"
                                            width="50"
                                            height="49"
                                            viewBox="0 0 50 49"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M37.25 12.25L12.75 36.75M12.75 12.25L37.25 36.75"
                                                stroke="#8E8E93"
                                                stroke-width="3"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>

                                        <div class="text-and-subtext">
                                            <div class="text">No</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="checkbox-group-item">
                                <div class="input">
                                    <FormControlLabel value="Yes" control={<Radio />} />
                                </div>
                                <div class="content">
                                    <div class="text-and-supporting-text">
                                        <svg
                                            class="check"
                                            width="50"
                                            height="49"
                                            viewBox="0 0 50 49"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M41.3337 12.25L18.8753 34.7083L8.66699 24.5"
                                                stroke="#8E8E93"
                                                stroke-width="3"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>

                                        <div class="text-and-subtext">
                                            <div class="text">Yes</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </RadioGroup> */}
                </div>
                <div className="description m-2">   {t(`${translationPath}filter-description`)}          </div>
                <div className='search-bar'> 
                <AutocompleteComponent
                    idRef='branchName-ToRef'
                    labelValue='branchName'
                    selectedValues={currentSelected?.rotationSchemeBranches || []}
                    multiple
                    isDisabled={(currentSelected?.rotationSchemeTeams
                        && currentSelected?.rotationSchemeTeams.length > 0)}
                    data={branches.result || []}
                    chipsLabel={(option) => option.branchName || ''}
                    displayLabel={(option) => t(`${(option.branchName) || ''}`)}
                    getOptionSelected={(option) => (currentSelected?.rotationSchemeBranches &&
                        currentSelected.rotationSchemeBranches.findIndex((item) => item && item.branchId === option.branchId) !== -1) || ''}
                    withoutSearchButton
                    inputPlaceholder={t(`${translationPath}Select-multiple-branchName`)}
                    isLoading={loadings}
                    onInputKeyUp={(e) => {
                        const { value } = e.target;
                        if (searchTimer.current) clearTimeout(searchTimer.current);
                        searchTimer.current = setTimeout(() => {
                            getAllBranches(value);
                        }, 700);
                    }}
                    isWithError
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                        const localNewValue = {
                            id: 'rotationSchemeBranches',
                            value: newValue &&
                                newValue.map((x) => (
                                    { branchId: x.branchId }
                                ))
                        };
                        const localNewSelected = newValue &&
                            newValue.map((x) => ({
                                branchId: x.branchId,
                                branchName: x.branchName,
                            }))
                        onStateChanged(localNewValue);
                        setCurrentSelected(prevState => ({
                            ...prevState,
                            rotationSchemeBranches: localNewSelected,
                        }));

                        if (rotationEdit) onScemaChanged('edit', 'rotationSchemeBranches');
                    }}
                />
                <AutocompleteComponent
                    idRef='teams-ToRef'
                    labelValue='teams'
                    selectedValues={currentSelected?.rotationSchemeTeams || []}
                    multiple
                    data={ResponseTeam.result || []}
                    chipsLabel={(option) => option.teamsName || option.teamName || ''}
                    isDisabled={(currentSelected?.rotationSchemeBranches
                        && currentSelected.rotationSchemeBranches.length > 0)}
                    displayLabel={(option) => t(`${(option.teamsName || option.teamName) || ''}`)}
                    getOptionSelected={(option) =>
                        currentSelected?.rotationSchemeTeams &&
                        currentSelected.rotationSchemeTeams.findIndex((item) => item && ((item.teamsId === option.teamsId)
                            || (item.teamId === option.teamsId))) !== -1 || ''}
                    withoutSearchButton
                    inputPlaceholder={t(`${translationPath}Select-multiple-teams`)}
                    isLoading={loadingsTeam}
                    onInputKeyUp={(e) => {
                        const { value } = e.target;
                        if (searchTimer.current) clearTimeout(searchTimer.current);
                        searchTimer.current = setTimeout(() => {
                            GetMyTeam(value);
                        }, 700);
                    }}
                    isWithError
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                        const localNewValue = {
                            id: 'rotationSchemeTeams',
                            value: newValue &&
                                newValue.map((item) => ({
                                    teamId: item.teamsId || item.teamId,
                                }))
                        };
                        const localNewSelected = newValue &&
                            newValue.map((item) => ({
                                teamId: item.teamsId || item.teamId,
                                teamName: item.teamsName || item.teamName,
                            }));

                        onStateChanged(localNewValue);
                        if (rotationEdit) onScemaChanged('edit', 'rotationSchemeTeams');
                        setCurrentSelected(prevState => ({
                            ...prevState,
                            rotationSchemeTeams: localNewSelected,
                        }));
                    }}
                />
                <div className='mt-1'>
                    <Inputs
                        idRef='slaRef'
                        labelValue={t(`${translationPath}sla`)}
                        value={state.sla || ''}
                        isSubmitted={isSubmitted}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onInputChanged={(event) => {
                            if (isNaN(event.target.value))
                                return;
                            onStateChanged({ id: 'sla', value: event.target.value });
                        }}
                    />
                </div>

                 </div>
            </div>
        </>
    );
};

SchemaSetting.propTypes =
{
    parentTranslationPath: PropTypes.string.isRequired,
    translationPath: PropTypes.string.isRequired,
    state: PropTypes.PropTypes.instanceOf(Object),
    onStateChanged: PropTypes.func.isRequired,
    schema: PropTypes.instanceOf(Object).isRequired,
    isSubmitted: PropTypes.bool.isRequired,
};
