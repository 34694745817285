import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { showError } from '../../../../Helper';
import { MicrosoftToken } from '../../../../Services';
import { SpinnerDashboards } from '../SpinnerDashboards/SpinnerDashboards';

export const MainPortalDashboards = ({ dynamicComponentPortalProps }) => {
  const [report, setReport] = useState();
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setRender(true);
    } else {
      setReport(''); showError('Contact Your Bi Admin For Help');
    }
  };

  useEffect(() => {
    Microsoft(false);
  }, []);

  return (
    <div className='dashboardMain-PowerBIEmbed'>
                        <SpinnerDashboards isActive={!ShowReport} isAbsolute />

      {render && (
        <div className='dashboardMain'>
          <PowerBIEmbed
            embedConfig={{
              type: 'report',
              id: dynamicComponentPortalProps.config.reportId,
              embedUrl: dynamicComponentPortalProps.config.Url,
              accessToken: report,
              settings: {
                customLayout: {
                  displayOption: models.DisplayOption.FitToWidth,
                },
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                background: models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={
              new Map([
                [
                  'loaded',
                  () => {
                    setShowReport(true);

                    console.log('Report loaded');
                  },
                ],
                [
                  'rendered',
                  () => {
                    console.log('Report rendered');
                  },
                ],
                [
                  'error',
                  (event) => {
                    const error = event.detail;
                    if (
                      error.detailedMessage === "Get report failed" ||
                      error.detailedMessage ===
                        "Access token has expired, resubmit with a new access token" ||
                      error.detailedMessage ===
                        "Fail to initialize - Could not resolve cluster"
                    ) {
                      Microsoft(true);
                      setRender(false);
                    } else console.log(error.detailedMessage);
                    showError(error.detailedMessage);
                  },
                ],
              ])
            }
            cssClassName={
              ShowReport
                ? "report-style-class-Show"
                : "report-style-class-hide"
            }            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        </div>
      )}
    </div>
  );
};