import React from 'react';
import { SelectComponet } from '../../../../../../Components';

export const LeadScoreOption = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
}) => {
  return (
    <SelectComponet
      labelValue='lead-score-option'
      data={[
        { title: 'Add', value: 1 },
        { title: 'Subtract', value: 2 },
      ]}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      textInput={'title'}
      valueInput={'value'}
      value={state.leadScoreOption}
      onSelectChanged={(value) => {
        setState({ id: 'leadScoreOption', value });
        if (!value) {
          setState({ id: 'leadScore', value: 0 });
        }
      }}
      emptyItem={{
        value: null,
        text: 'select-lead-score-option',
      }}
    />
  );
};
