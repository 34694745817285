import React, {
 useCallback, useEffect, useMemo, useState
} from 'react';
import { Button } from '@material-ui/core';
import { useTitle, useTranslate } from '../../../Hooks';
import {
 Tables, PaginationComponent, DialogComponent, Spinner
} from '../../../Components';
import { CourseDialog } from './Dialogs/CourseDialog';
import { InstructorDialog } from './Dialogs/InstructorDialog';
import { GetCourses, GetCoursesDetails, GetInstructorDetails } from '../../../Services';
import { bottomBoxComponentUpdate } from '../../../Helper';
import { ACTION_TYPES, PARENT_TRANSLATION_PATH, TRANSLATION_PATH } from './LearningAndDevelopmentView.constant';
import FormattedMessage from '../../../SharedComponents/FormattedComponent/FormattedMessage';

function LearningAndDevelopmentView() {
  const { translate } = useTranslate(PARENT_TRANSLATION_PATH);

  const [courses, setCourses] = useState({
    result: [],
    totalCount: 0,
  });

  const [details, setDetails] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [isDialogLoading, setIsDialogLoading] = useState(false);

  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const [dialogData, setDialogData] = useState(new Map([
    ['isOpen', false],
    ['titleText', ''],
    ['type', null],
  ]));

  const handleCourseDetailsAction = () => {
    const dialogDataMap = new Map([
        ['isOpen', true],
        ['titleText', translate('course-details')],
        ['type', ACTION_TYPES.COURSE],
    ]);
    setDialogData(dialogDataMap);
  };

  const handleInstructionDetailsAction = () => {
    const dialogDataMap = new Map([
        ['isOpen', true],
        ['titleText', translate('instructor-details')],
        ['type', ACTION_TYPES.INSTRUCTION],
    ]);
    setDialogData(dialogDataMap);
  };

  const handleActionClick = (type, courseId) => {
    if (type === ACTION_TYPES.COURSE) {
      getCoursesDetails(courseId);
      handleCourseDetailsAction();
      return;
    }

    if (type === ACTION_TYPES.INSTRUCTION) {
      getInstructorDetails(courseId);
      handleInstructionDetailsAction();
      return;
    }
  };

  const getCourses = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await GetCourses({ ...filter });
      setCourses({
        result: response && response.result || [],
        totalCount: response && response.totalCount || 0,
      });
    } catch (error) {
      console.error('Failed to fetch courses:', error);
    } finally {
      setIsLoading(false);
    }
  }, [filter]);

  const getCoursesDetails = useCallback(async (courseId) => {
    setIsDialogLoading(true);
    try {
      const response = await GetCoursesDetails(courseId);
      setDetails(response);
    } catch (error) {
      console.error('Failed to fetch courses:', error);
    } finally {
      setIsDialogLoading(false);
    }
  }, []);

  const getInstructorDetails = useCallback(async (courseId) => {
    setIsDialogLoading(true);
    try {
      const response = await GetInstructorDetails(courseId);
      setDetails(response);
    } catch (error) {
      console.error('Failed to fetch courses:', error);
    } finally {
      setIsDialogLoading(false);
    }
  }, []);

  const renderDialogContent = () => {
    const type = dialogData.get('type');

    if (!type) return null;
    
    if (type === ACTION_TYPES.COURSE)
        return <CourseDialog data={details} />;

    if (type === ACTION_TYPES.INSTRUCTION)
        return <InstructorDialog data={details} />;

    return null;
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={courses.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });

  useEffect(() => () => {
      bottomBoxComponentUpdate(null);
    }, []);

  useEffect(() => {
    getCourses();
  }, [filter]);

  useTitle(translate('courses'));

  const headerData = useMemo(() => [
    {
      id: 1,
      isSortable: false,
      label: translate('course-id'),
      input: 'courseId',
    },
    {
      id: 2,
      isSortable: false,
      label: translate('course-title'),
      input: 'courseName',
    },
    {
      id: 3,
      isSortable: false,
      label: translate('course-category'),
      input: 'categoryName',
    },
    {
      id: 4,
      label: translate('price'),
      input: 'price',
    },
    {
      id: 5,
      label: translate('course-hours'),
      isSortable: false,
      input: 'courseHours',
    },
    {
      id: 6,
      isSortable: false,
      label: translate('course-language'),
      input: 'courseLanguage',
    },
    {
      id: 7,
      label: translate('action'),
      component: (item) => (
        <div className='btn-gap'>
          <Button
            size='small'
            variant='outlined'
            color='primary'
            onClick={() => { handleActionClick(ACTION_TYPES.COURSE, item.courseId); }}
            style={{
                  textTransform: 'none',
                  fontSize: 11,
                }}
          >
            <FormattedMessage id='course-details' path={PARENT_TRANSLATION_PATH} />
          </Button>
          <Button
            size='small'
            variant='outlined'
            color='primary'
            onClick={() => { handleActionClick(ACTION_TYPES.INSTRUCTION, item.courseId); }}
            style={{
                  textTransform: 'none',
                  fontSize: 11,
                }}
          >
            <FormattedMessage id='instructor-details' path={PARENT_TRANSLATION_PATH} />
          </Button>
        </div>
      ),
    },
  ], [translate]);

  const onCloseDialogHandler = () => {
    const dialogDataMap = new Map(dialogData);
    dialogDataMap.set('isOpen', false);
    setDialogData(dialogDataMap);
  };

  return (
    <main className='courses-container px-2'>
      <Spinner isActive={isLoading} />
      <Tables
        data={courses.result}
        headerData={headerData}
        parentTranslationPath={PARENT_TRANSLATION_PATH}
        translationPath={TRANSLATION_PATH}
        totalItems={0}
        defaultActions={[]}
        itemsPerPage={filter.pageSize}
        activePage={filter.pageIndex}
      />

      {!isDialogLoading ? (
        <DialogComponent
          isOpen={dialogData.get('isOpen')}
          onCloseClicked={onCloseDialogHandler}
          titleText={dialogData.get('titleText')}
          maxWidth='lg'
          dialogContent={renderDialogContent()}
        />
      ) : null}
    </main>
  );
}

export default LearningAndDevelopmentView;
