import React, {useEffect, useReducer} from 'react'
import { ContractSection, FinancialSection, OtherDetailsSection, CommissionSection } from './Utilities';

export const AgreementEssentialsTab = (props) => {


  //change this from useeffect to next handler
  // useEffect(() => {
  //   onSelectedChanged(prevState => ({
  //     ...prevState, 
  //     ...localSelected,
  //   }))
  // }, [localSelected]);

  // useEffect(() => {
  //   //todo:: this should be filled on next button clicked 
  //   //split selected to 2 props : 2 tabs names
  //   onse({id: 'edit' , value: selected})
  // }, [selected]);

  return (
    <div className='w-75'>
      <FinancialSection
        {...props}
      />
      <ContractSection
        {...props}
      />
      <OtherDetailsSection
        {...props}
      />
      <CommissionSection
        {...props}
      />
    </div>
  )
}

