import React from "react";
import { CustomBadge, CustomCard } from "../../../../../../Components";
import { Box, Typography, Avatar, Tooltip, Divider } from "@material-ui/core";
import moment from "moment";
import { useSelectedTheme, useTranslate } from "../../../../../../Hooks";
import clsx from "clsx";
import { getDownloadableLink } from "../../../../../../Helper";

//styles
import useStyles from "./styles";

function ContactTaskCard({ taskDetails, isNeedApproval }) {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const { translate } = useTranslate("NewContactsView");

  const formatedDateTime = (dateTime) => {
    const Date = dateTime
      ? moment(dateTime).locale("en").format("D MMM YYYY")
      : null;
    const Time = dateTime
      ? moment(dateTime).locale("en").format("h:mm a")
      : null;
    return { Date, Time };
  };

  return (
    <CustomCard
      boxShadow="xs"
      borderRadius="sm"
      classes={styles.container_Task_Card}
    >
      <Box className={styles.header_Card_Container}>
        <Box variant="inherit" className={styles.Container_CreatedDate}>
          <Typography className={styles.Date}>
            {" "}
            {taskDetails?.createdOn
              ? formatedDateTime(taskDetails.createdOn).Date
              : ""}
          </Typography>
          <Typography className={styles.Time}>
            {" "}
            {taskDetails?.createdOn
              ? formatedDateTime(taskDetails.createdOn).Time
              : ""}
          </Typography>
        </Box>
        <strong className={styles.Container_Title_Header}>
          {translate("New_lead_added_CRM")}
        </strong>
      </Box>
      <Box className={styles.Status_Task_Container}>
        <CustomBadge
          className={styles.TaskStatus}
          SizeVariant="medium"
          label={taskDetails?.projectTaskStatus}
          BorderColor={
            taskDetails?.projectTaskStatus === "ToDo"
              ? palette.border.secondary
              : taskDetails?.projectTaskStatus === "Completed"
              ? palette.utility.success_200
              : palette.utility.error_200
          }
          Color={
            taskDetails?.projectTaskStatus === "ToDo"
              ? palette.text.gray_700
              : taskDetails?.projectTaskStatus === "Completed"
              ? palette.utility.success_700
              : palette.utility.error_700
          }
          BackgroundColor={
            taskDetails?.projectTaskStatus === "ToDo"
              ? palette.utility.utility_gray_50
              : taskDetails?.projectTaskStatus === "Completed"
              ? palette.utility.success_50
              : palette.utility.error_50
          }
        />
        <Box className={styles.IsWithApproval}>
          {isNeedApproval ? (
            <Typography>{translate("without_Approval")}</Typography>
          ) : (
            <Typography>{translate("with_Approval")}</Typography>
          )}
        </Box>
      </Box>
      <Box className={styles.Details_Task_Container}>
        <Box className={styles.Deatails_Row}>
          <Typography className={styles.TitleDetails}>
            {translate("Assigned_to")}:
          </Typography>
          {taskDetails?.projectTaskAssignTo.length === 1 ? (
            <Box
              className={styles.container_Avatar_AssignTo}
              style={{
                gap: "7px",
              }}
            >
              <Box className={styles.containerItemAvatar}>
                <Avatar
                  className={clsx({
                    [styles.AvatarStyle]:
                      taskDetails?.projectTaskAssignTo[0]?.userProfileImage,
                    [styles.defaultAvatarWithNameStyle]:
                      !taskDetails?.projectTaskAssignTo[0]?.userProfileImage &&
                      taskDetails?.projectTaskAssignTo[0]?.user,
                  })}
                  src={
                    taskDetails?.projectTaskAssignTo[0]?.userProfileImage
                      ? getDownloadableLink(
                          taskDetails?.projectTaskAssignTo[0]?.userProfileImage
                        )
                      : "/images/contacts/MaleAvatar.svg"
                  }
                ></Avatar>
              </Box>
              <strong className={styles.Assigned_toValue}>
                {taskDetails?.projectTaskAssignTo[0]?.user}
              </strong>
            </Box>
          ) : (
            <Box className={styles.container_Avatar_AssignTo}>
              {taskDetails?.projectTaskAssignTo
                ?.slice(0, 3)
                .map((AvatarItem, index) => (
                  <Box className={styles.containerItemAvatar}>
                    <Tooltip
                      title={AvatarItem?.user}
                      placement="top"
                      classes={{
                        tooltip: styles.containerAreaDescription,
                        arrow: styles.customArrow,
                      }}
                    >
                      <Avatar
                        key={index}
                        className={clsx({
                          [styles.AvatarStyle]: AvatarItem?.userProfileImage,
                          [styles.defaultAvatarWithNameStyle]:
                            !AvatarItem?.userProfileImage && AvatarItem?.user,
                        })}
                        src={
                          AvatarItem?.userProfileImage
                            ? getDownloadableLink(AvatarItem?.userProfileImage)
                            : null
                        }
                      >
                        {!AvatarItem?.userProfileImage && AvatarItem?.user
                          ? AvatarItem?.user.slice(0, 2).toUpperCase()
                          : null}
                      </Avatar>
                    </Tooltip>
                  </Box>
                ))}

              {taskDetails?.projectTaskAssignTo?.length > 3 && (
                <Box className={styles.containerItemAvatar}>
                  <Tooltip
                    placement="top"
                    arrow
                    title={taskDetails?.projectTaskAssignTo
                      ?.slice(3)
                      .map((AvatarItem, index) => (
                        <div key={index}>
                          {AvatarItem?.user}
                          {"\n"}
                        </div>
                      ))}
                    classes={{
                      tooltip: styles.containerAreaDescription,
                      arrow: styles.customArrow,
                    }}
                  >
                    <Avatar className={styles.moreAvatar}>
                      +{taskDetails?.projectTaskAssignTo?.length - 3}
                    </Avatar>
                  </Tooltip>
                </Box>
              )}
            </Box>
          )}
        </Box>

        <Box className={styles.Deatails_Row}>
          <Typography className={styles.TitleDetails}>
            {translate("Expiration_time")}:
          </Typography>
          <strong className={styles.ValueDetails}>
            {taskDetails?.expirationDate
              ? formatedDateTime(taskDetails?.expirationDate).Date
              : "N/A"}
          </strong>
        </Box>
      </Box>
      <Divider className={styles.divider} />
      <Box className={styles.Creator_Task_Container}>
        <Typography className={styles.CreatorTitle}>
          {translate("Creator")}:
        </Typography>
        <Typography className={styles.CreatorValue}>
          {taskDetails?.createdByName ? taskDetails?.createdByName : "N/A"}
        </Typography>
      </Box>
    </CustomCard>
  );
}
export default ContactTaskCard;
