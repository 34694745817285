import React, { useState, useEffect, useReducer } from "react";
import { ButtonBase } from "@material-ui/core";
import { Button } from 'react-bootstrap';
import Joi from 'joi';
import { useTranslation } from "react-i18next";
import {
  Inputs,
  Spinner,
} from "../../../../../Components";
import { CommunicationsIds } from '../../../../../Enums' ; 
import { EmailComponent , MobileNumberComponent } from './DeveloperEmployeesComponents' ; 
import { GetParams, showError, showSuccess ,matchLinkedinUrl ,getErrorByName  } from "../../../../../Helper";
import { GetDeveloperEmployeessAPI  , CreateDeveloperEmployeeAPI   } from "../../../../../Services" ; 
import { ActivityFilesUploader } from "../../../../../Components/UploaderActivitieFileComponent/ActivityFilesUploader";
import { DeveloperEmployeesTable } from "./DeveloperEmployeesComponents";


export const DeveloperEmployees = ({
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
    search: "",
  });
  const [communicationsIdsValues, setCommunicationsIdsValues] = useState({
    email :CommunicationsIds.email.value , 
    mobile :CommunicationsIds.mobile.value , 

  
  });
  const [isReloadData, setIsReloadData] = useState(null) ; 
  const [isSubmitted, setIsSubmitted] = useState(false) ; 

  const [isLoading, setIsLoading] = useState({
    allDeveloperEmployees: false,
    addDeveloperEmployee: false,
  });

  const [items, setItems] = useState({
    allDeveloperEmployees: {
      result: [],
      totalCount: 0,
    },
  });

  const [defaultState, setDefaultState] = useState({
    developerContactId: +GetParams("id"),
    employeeName: null,
    jobTitle: null,
    linkedInProfile: null,
    employeePhoto:  null,
    files: null,
  });

  const [defaultSelected, setDefaultSelected] = useState({
    employeePhoto: null ,
    emailAddresses : [] ,
    mobileNumbers :  [] ,
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const reducer2 = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultState,
  });

  const [selected, setSelected] = useReducer(reducer2, {
    ...defaultSelected,
  });

  const schema = Joi.object({
    employeeName: Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}employee-name-is-required`),
        'string.empty': t(`${translationPath}employee-name-is-required`),
        'string.required': t(`${translationPath}employee-name-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const GetDeveloperEmployeesseveloperContactId = async (search) => {
    setIsLoading((item) => ({ ...item, allDeveloperEmployees: true }));
    const rs = await GetDeveloperEmployeessAPI(
      +GetParams("id"),
      {
        pageIndex: filter.pageIndex + 1,
        pageSize: filter.pageSize,
        name: search ,
      }
    );
    if (!rs) {
      setItems((item) => ({
        ...item,
        allDeveloperEmployees: { result: [], totalCount: 0 },
      }));
      return;
    } else {
      setItems((item) => ({
        ...item,
        allDeveloperEmployees: {
          result: rs?.result || [],
          totalCount: rs.totalCount || 0,
        },
      }));
    }
    setIsLoading((item) => ({ ...item, allDeveloperEmployees: false }));
  };

  const reloadData = () => {
    setState({ id: "edit", value: defaultState });
    setSelected({ id: "edit", value: defaultSelected });
    GetDeveloperEmployeesseveloperContactId();
  };

  const checkIsValidValues = (key) => 
  {
      if((key === 'linkedInProfile' || key === 'save' ) && state.linkedInProfile )
      return  !(matchLinkedinUrl(state.linkedInProfile || '')) ; 
    return  false  

  };

  const onChangeHandler = (key, values, selectedObj, selectedValues) => {
    if (!selectedObj) {
      setState({ id: [key], value: values });
    } else {
      setState({ id: [key], value: values });
      setSelected({ id: [selectedObj], value: selectedValues });
    }
  };

  const CreateDeveloperEmployeeHandler = async () => {
    setIsLoading((item) => ({ ...item, addDeveloperEmployee: true }));
    const body = { ...state , developerEmployeeCommunications : [...selected.emailAddresses , ...selected.mobileNumbers ] };
    if (state.files) delete body.files;
    const res = await CreateDeveloperEmployeeAPI(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(
        t`${translationPath}developer-employee-created-successfully`
      );
      setIsLoading((item) => ({ ...item, addDeveloperEmployee: false }));
      setIsReloadData(isReloadData === null || isReloadData === false ? true :  false);
      reloadData();
    } else {
      showError(t`${translationPath}developer-employee-create-failed`);
      setIsLoading((item) => ({ ...item, addDeveloperEmployee: false }));
    }
  };

  useEffect(() => {
    reloadData();
  }, [filter]);

  useEffect(() => {
    if (state && state.files)
      onChangeHandler(
        "employeePhoto",
        (state?.files && state.files.length > 0 && state.files[0].fileId) || null,
        "employeePhoto",
        state.files
      );
  }, [state.files]);


  return (
    <div className="agreement-details-deveoloper-wrapper childs-wrapper d-flex-column">
      <div className="w-100 agreement-details-add">
        <Spinner isActive={isLoading.allDeveloperEmployees || isLoading.addDeveloperEmployee} isAbsolute />
        <div className="w-100">
          <div className="part1 mt-2 mb-2">
            <div className="px-2 item mt-1">
            <Inputs
                isAttachedInput
                idRef={`employeeName${1}`}
                labelValue={"employee-name"}
                value={state.employeeName || ""}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("employeeName", value);
                }}
                inputPlaceholder={"enter-employee-name"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                helperText={getErrorByName(schema, 'employeeName').message}
                error={getErrorByName(schema, 'employeeName').error}
                isWithError
                isSubmitted={isSubmitted}
              />
              
            </div>
            <div className="px-2 item">
            <MobileNumberComponent
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                setNumber={(data)=> 
                  setSelected(({id : 'mobileNumbers', value : data }))
                }
                mobileNumbers={selected.mobileNumbers}
                isReloadData={isReloadData}
                communicationId={communicationsIdsValues.mobile}
              />
            </div>

            <div className="px-2 item">
            <EmailComponent
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                setEmail={(data)=> 
                  setSelected(({id : 'emailAddresses', value : data }))
                }
                emailAddresses={selected.emailAddresses}
                isReloadData={isReloadData}
                communicationId={communicationsIdsValues.email}

              />
            </div>
            
            <div className="px-2 item">
            <Inputs
                isAttachedInput
                idRef={`jobTitle${1}`}
                labelValue={"job-title"}
                value={state.jobTitle || ""}
                onInputChanged={(e) => {
                  onChangeHandler("jobTitle", e.target.value );
                }}
                inputPlaceholder={"enter-job-title"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>

            <div className="px-2 item mt-1">
              <Inputs
                  isAttachedInput
                  idRef={"LinkedInLinkRef"}
                  labelValue={"linkedIn-link"}
                  type={"text"}
                  value= {state.linkedInProfile || ''} 
                  onInputChanged={(e) => 
                  {
                    onChangeHandler('linkedInProfile' ,(e?.target?.value  !== '' ?  e.target.value : null));

                  }}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  endAdornment={(
                    <>
                      <Button
                        disabled={state.linkedInProfile ? (state.linkedInProfile !== "" ? checkIsValidValues('linkedInProfile') :  true ):  true }
                        onClick={() => {
                          try {
                            window.open(state.linkedInProfile || '');
                          } catch (error) { showError(t(`${translationPath}this-link-is-not-valid`)); }
                        }}
                        className='googlemaps-bbt '
                      >
                        <span className='p-1 youtube-wraper'><span className='mdi mdi-web' /></span>
                      </Button>
                    </>
                  )}
                  helperText={t`${translationPath}please-enter-valid-linkedIn-url`}
                  error={(state?.linkedInProfile !== "" ?  checkIsValidValues('linkedInProfile')  : false)} 
                  isWithError
                  isSubmitted={isSubmitted}
                />
            </div>
          <div className="w-100">
            <ActivityFilesUploader
              state={state}
              setState={setState}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              multiple={false}
              accept={"/*"}
            canSelectOneDocument={true}
            />
          </div>
          <div className="d-flex-v-center-h-end flex-wrap">
            <ButtonBase
              className="btns theme-solid"
              onClick={CreateDeveloperEmployeeHandler}
              disabled={isLoading.addDeveloperEmployee || checkIsValidValues('save') ||  state.employeeName === '' || !state.employeeName}
            >
              <span className="mdi mdi-plus" />
            {t(`${translationPath}add-developer-employee`)}
            </ButtonBase>
          </div>
        </div>
      </div>
      </div>
      <DeveloperEmployeesTable
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        data={items.allDeveloperEmployees}
        filter={filter}
        setFilter={setFilter}
        reloadData={reloadData}
        communicationsIds={communicationsIdsValues}

      />
    </div>
   
  );
};
