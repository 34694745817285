import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Tables } from '../../../../../../../Components';
import { TableActions, AgentRoleEnum } from '../../../../../../../Enums';
import { GlobalHistory, showError } from '../../../../../../../Helper';
import { ActiveItemActions } from '../../../../../../../store/ActiveItem/ActiveItemActions';
import { ContactProfileLeadsTableData } from './ContactProfileLeadsTableData';
import { StaticLookupsIds } from '../../../../../../../assets/json/StaticLookupsIds';

export const ContactProfileLeadsTable = ({
  state,
  filter,
  pathName,
  setFilter,
  onStateChange,
  leadsDetailes,
  setActiveItem,
  isOpenCloseLead,
  translationPath,
  setIsOpenCloseLead,
  parentTranslationPath,
  actionType,
  setCheckedCards
}) => {
  const dispatch = useDispatch();
  const [list, setList] = useState([
    {
      enum: TableActions.openFile.key,
    },
  ]);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const onChangeStatusHandler = (item) => (event, isChecked) => {
    if (!isChecked) {
      setActiveItem(item);
      setIsOpenCloseLead(true);
    } else if (isOpenCloseLead) setIsOpenCloseLead(false);
  };
  const tableActionClicked = useCallback((actionEnum, item) => {
    dispatch(ActiveItemActions.activeItemSuccess(item));

    let newPath = '';
    if (pathName === 'contact-sales') {
      newPath = `/home/lead-sales/lead-profile-edit`;
    } else if (pathName === 'contact-lease') {
      newPath = `/home/lead-lease/lead-profile-edit`;
    } else {
      if (item && item.status) {
        localStorage.setItem('leadStatus', JSON.stringify(item.status));
      }
      newPath = `/home/Contacts-CRM/lead-profile-edit`;
    }

    const queryParams = {
      formType: item.leadTypeId,
      id: item.id,
      leadClass: item.leadClass,
    };

    // Construct the URL with query parameters
    const url = `${newPath}?${new URLSearchParams(queryParams).toString()}`;


    GlobalHistory.push(url);
  }, [dispatch, pathName]);

  const getIsSelectedAll = useCallback(
    () =>
      (state &&
        state.leadIds &&
        leadsDetailes && leadsDetailes.result.findIndex(
          (item) => !state.leadIds.includes(item.id) && item?.status?.lookupItemName !== 'Open'
        ) === -1) ||
      false,
    [leadsDetailes, state]
  );
  const getIsSelected = useCallback(
    (row) => state && state.leadIds.findIndex((item) => item === row.id) !== -1,
    [state]
  );
  const onSelectClicked = useCallback(
    (row) => {
      let frist = row.leadClass;
      if (state.leads.length)
        frist = state.leads[0].leadClass;

      if (row.leadClass !== frist && actionType !== 'close-leads') {
        showError('Please Select same lead');
        return;
      }
      const itemIndex = state ? state.leadIds.findIndex((item) => item === row.id) : -1;
      if (itemIndex !== -1) state.leadIds.splice(itemIndex, 1);
      else state.leadIds.push(row.id);
      const itemIndex2 = state ? state.leads.findIndex((item) => item.id === row.id) : -1;
      if (itemIndex2 !== -1) state.leads.splice(itemIndex2, 1);
      else state.leads.push(row);
      onStateChange('leadIds', state.leadIds);
      onStateChange('userType', state.leads.length > 0 ? (frist === 'Buyer' ? AgentRoleEnum.SaleAgent.value : frist === 'Tenant' ? AgentRoleEnum.LeaseAgent.value : frist === 'Seller' ? AgentRoleEnum.SaleListingAgent.value : AgentRoleEnum.LeaseListingAgent.value) : null);
      setCheckedCards(state.leads || []);
      onStateChange('leads', state.leads);
    },
    [onStateChange, state, actionType]
  );
  const onSelectAllClicked = () => {
    if (!getIsSelectedAll()) {
      if (leadsDetailes) {
        leadsDetailes.result.map((item) => {
          if (!getIsSelected(item) && item?.status?.lookupItemName !== 'Open') state.leadIds.push(item.id);
        });
      }
    } else if (leadsDetailes) {
      leadsDetailes.result.map((item) => {
        const isSelectedIndex = state.leadIds.findIndex((element) => element === item.id);
        if (isSelectedIndex !== -1) state.leadIds.splice(isSelectedIndex, 1);
      });
    }
    onStateChange('leadIds', state.leadIds);
  };
  const focusedRowChanged = useCallback(
    (rowIndex, item) => {
      if (rowIndex !== -1) {
        if (item.status &&
          item.status.lookupItemId !== StaticLookupsIds.Closed) {
          setList([{
            enum: TableActions.openFile.key,
            isDisabled: false,
            externalComponent: null,
          },
          ]);
        } else if (item.status &&
          item.status.lookupItemId === StaticLookupsIds.Closed) {
          setList([{
            enum: TableActions.openFile.key,
            externalComponent: null,
          },]);
        }
      }
    },
    [list]
  );
  return (
    <div className='w-100 px-2'>
      <Tables
        HideSellectAll
        TotalSelect={state?.leads?.length || 0}
        selectAllOptions={actionType && {
          getIsSelected,
          onSelectClicked,
          disabledRows: [],
          onSelectAllClicked,
          selectedRows: state.leads || 0,
          //  getIsDisabled,
          withCheckAll: true,
        }}
        data={leadsDetailes ? leadsDetailes.result : []}
        headerData={ContactProfileLeadsTableData(
          onChangeStatusHandler,
          parentTranslationPath,
          translationPath
        )}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
        defaultActions={list || [
          {
            enum: TableActions.openFile.key,
          },
        ]}
        actionsOptions={{
          onActionClicked: tableActionClicked, isDisabled: false
        }}
        itemsPerPage={filter.pageSize}
        activePage={filter.pageIndex}
        focusedRowChanged={focusedRowChanged}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        totalItems={leadsDetailes && leadsDetailes.totalCount ? leadsDetailes.totalCount : 0}
      />
    </div>
  );
};
ContactProfileLeadsTable.propTypes = {
  pathName: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
  onStateChange: PropTypes.func.isRequired,
  setActiveItem: PropTypes.func.isRequired,
  isOpenCloseLead: PropTypes.bool.isRequired,
  translationPath: PropTypes.string.isRequired,
  setIsOpenCloseLead: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Array).isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  leadsDetailes: PropTypes.instanceOf(Object).isRequired,
  actionType: PropTypes.string.isRequired,
};
