import React, { useEffect } from 'react';
import { SwitchRoute } from '../../../Components/Route/SwitchRoute';
import { LeadsSalesRoutes } from '../../../routes';

export const LeadsSalesLayout = () => {
    useEffect(() => {
        return () => {
            localStorage.removeItem("leadSalesCurrentPageIndex"); 
        }
    }, []);

    return (
        <SwitchRoute routes={LeadsSalesRoutes} />
    )
};
