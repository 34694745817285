export const LocationsPermissions = {
  ViewLocationDetails: {
    components: null,
    componentsId: null,
    permissionsId: 'b35c57eb-23b4-4ddf-3432-08d993d2310c',
    permissionsName: 'View Location Details'
  },

  EditLocationDetails: {
    components: null,
    componentsId: null,
    permissionsId: 'cf02af16-d037-4431-3433-08d993d2310c',
    permissionsName: 'Edit Location Details'
  }

};
