
import React, { useState, useEffect, useReducer , useRef  } from "react";
import { ButtonBase } from "@material-ui/core";
import Joi from 'joi';
import { Slider, Button, InputAdornment } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Inputs,
  Spinner,
  AutocompleteComponent
} from "../../../../../Components";
import { GetParams, showError, showSuccess  ,getErrorByName  } from "../../../../../Helper";
import { CommissionTypes } from "../../../../../Enums";

import { CreateCommissionDetailsAPI , GetCommissionDetailsByAgreementIdAPI , getProperties } from '../../../../../Services' ; 
import { CommissionDetailsTable } from './CommissionDetailsComponent/CommissionDetailsTable' ; 

export const CommissionDetailsByAgreementView = ({
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const searchTimer = useRef(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
    search: "",
  });
  const [isReloadData, setIsReloadData] = useState(null) ; 
  const [isSubmitted, setIsSubmitted] = useState(false) ; 

  const [isLoading, setIsLoading] = useState({
    addCommission: false,
    allCommissions : false ,
    properties : false 
  });

  const [items, setItems] = useState({
    allCommissions: {
      result: [],
      totalCount: 0,
    },
    properties:[] , 

  });

  const [defaultState, setDefaultState] = useState({
    developerContactId: +GetParams("id"),
    valueFrom: null,
    valueTo: null,
    commission: null,
    paymentTerms: null ,
    commissionType: null,
    remarks: null ,
    commissionProjects: []
  }
 );

  const [defaultSelected, setDefaultSelected] = useState({
    commissionType: null ,
    commissionProjects : [] ,
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const reducer2 = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultState,
  });

  const [selected, setSelected] = useReducer(reducer2, {
    ...defaultSelected,
  });

  const schema = Joi.object({
    commissionType: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}commission-type-is-required`),
        'number.empty': t(`${translationPath}commission-type-is-required`),
        'number.required': t(`${translationPath}commission-type-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const GetCommissionDetailsByAgreementId = async (search) => {
    setIsLoading((item) => ({ ...item, allCommissions: true }));
    const rs = await GetCommissionDetailsByAgreementIdAPI(
      {
        developerContactId :(+GetParams("id")), 
        pageIndex: filter.pageIndex + 1,
        pageSize: filter.pageSize,
        name: search ,
      }
    );
    if (!rs) {
      setItems((item) => ({
        ...item,
        allCommissions: { result: [], totalCount: 0 },
      }));
      return;
    } else {
      setItems((item) => ({
        ...item,
        allCommissions: {
          result: rs?.result || [],
          totalCount: rs.totalCount || 0,
        },
      }));
    }
    setIsLoading((item) => ({ ...item, allCommissions: false }));
  };
  const getPropertiesAPI = async (search) => {
    let list = [];
    setIsLoading((loading) => ({ ...loading, properties: true }));
    const rs = await getProperties({
      pageSize: 50,
      pageIndex: 0,
      search,
    });
    if ((rs && rs.data && rs.data.ErrorId) || !rs || !rs.result) {
      setIsLoading((item) => ({ ...item, properties: false }));
      setItems((item) => ({ ...item, properties: list || [] }));
      return;
    } else {
      rs.result.map((valueLoop) => {
        list.push({
          id: valueLoop?.propertyId,
          name: valueLoop?.property?.property_name,
        });
      });
      setItems((item) => ({ ...item, properties: list || [] }));
      setIsLoading((item) => ({ ...item, properties: false }));
    }
  };

  const reloadData = () => {
    setState({ id: "edit", value: defaultState });
    setSelected({ id: "edit", value: defaultSelected });
    GetCommissionDetailsByAgreementId();
  };

  const onChangeHandler = (key, values, selectedObj, selectedValues) => {
    if (!selectedObj) {
      setState({ id: [key], value: values });
    } else {
      setState({ id: [key], value: values });
      setSelected({ id: [selectedObj], value: selectedValues });
    }
  };

  const CreateCommissionDetailsHandler = async () => {
    setIsLoading((item) => ({ ...item, addCommission: true }));
    const body = { ...state };

    const res = await CreateCommissionDetailsAPI(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(
        t`${translationPath}commission-details-created-successfully`
      );
      setIsLoading((item) => ({ ...item, addCommission: false }));
      setIsReloadData(isReloadData === null || isReloadData === false ? true :  false);
      reloadData();
    } else {
      showError(t`${translationPath}commission-details-create-failed`);
      setIsLoading((item) => ({ ...item, addCommission: false }));
    }
  };

  useEffect(() => {
    if(state.developerContactId)
    reloadData();
  }, [filter]);





  return (
    <div className="agreement-details-deveoloper-wrapper childs-wrapper d-flex-column">
      <div className="w-100 agreement-details-add">
        <Spinner isActive={isLoading.allCommissions || isLoading.addCommission} isAbsolute />

        <div className="w-100">
          <div className="part1 mt-2 mb-2">
          <div className="px-2 item mt-1">
              <AutocompleteComponent
                key={`commissionType`}
                idRef={`commissionTypeRef`}
                multiple={false}
                selectedValues={selected.commissionType}
                inputPlaceholder={t("select-commission-type")}
                data={Object.values(CommissionTypes)}
                inputClasses="inputs theme-form-builder"
                displayLabel={(option) =>
                  (option && t(`${translationPath}${option.name}`)) || ""
                }
                withoutSearchButton
                labelValue={"commission-type"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onChange={(e, newValue) => {
                  onChangeHandler(
                    "commissionType",
                    newValue === null ? 1 : newValue.key,
                    "commissionType",
                    newValue
                  );
                  if (newValue === null || newValue?.key === 1)
                    onChangeHandler("commissionProjects", [] , 'commissionProjects' , [] );
                }}
              />
            </div>
            <div className="px-2 item mt-1">
              <AutocompleteComponent
                key={`projects-names`}
                idRef={`projectsNamesRef`}
                multiple
                isDisabled={!state.commissionType || state?.commissionType === CommissionTypes.forAllProjects.key}
                inputPlaceholder={t("select-properties")}
                data={items?.properties || []}
                selectedValues={selected.commissionProjects}
                inputClasses="inputs theme-form-builder"
                chipsLabel={(option) => (option && option.name) || ""}
                displayLabel={(option) => (option && option.name) || ""}
                onChange={(e, newValues) => {
                  if(state?.commissionType ===  CommissionTypes.forAllProjects.key)
                    return;
                  
                  const commissionProjectsIds =
                    (newValues &&
                      newValues.length &&
                      newValues.map((property) => ({
                        propertyId: property?.id,
                      }))) ||
                    [];
                  onChangeHandler(
                    "commissionProjects",
                    commissionProjectsIds,
                    "commissionProjects",
                    newValues
                  );
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getPropertiesAPI(value);
                  }, 700);
                }}
                isLoading={isLoading.properties}
                withLoader
                withoutSearchButton
                labelValue={"projects-names"}
                filterOptions={(options) => {
                  const isFind = (id) => selected.commissionProjects?.findIndex((s) => s.propertyId === id) === -1 || false ;
                 return options.filter((w) => isFind(w.id))
                }}
                getOptionSelected={(option) =>
                  (state &&
                    state.commissionProjects &&
                    state.commissionProjects.findIndex(
                      (item) => item?.propertyId === option?.id
                    ) !== -1) ||
                  false 
                }
                filterSelectedOptions
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onOpen={() => {
                  if (items && items.properties?.length === 0)
                   getPropertiesAPI();
                }}
              />
            </div>
        <div className="px-2 item mt-1">
            <Inputs
              idRef={'valueFrom'}
              labelValue={'value-from'}
              inputPlaceholder={t("enter-value-from")}
              value={state.valueFrom || ''}
              withNumberFormat
              onInputBlur={(e) => {
              }}
              onInputChanged={(e) => {
                let  { value } = e.target;
                onChangeHandler("valueFrom", value  || null );
              }}
              onKeyUp={(e) => {
                let  value = e && e.target && e.target.value ? (e.target.value) : 0;
                if (Number.isNaN(value))
                  value = 0;
                const fixed = (value && value.replace(/,/g, ''));
                onChangeHandler('valueFrom' , (fixed && parseFloat(fixed) || null) ) ;
               
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
          <div className="px-2 item mt-1">
            <Inputs
              idRef={'valueTo'}
              labelValue={'value-to'}
              inputPlaceholder={t("enter-value-to")}
              value={state.valueTo || ''}
              withNumberFormat
              onInputBlur={(e) => {
              }}
              onInputChanged={(e) => {
                let  { value } = e.target;
                onChangeHandler("valueTo", value  || null );
              }}
              onKeyUp={(e) => {
                let  value = e && e.target && e.target.value ? (e.target.value) : 0;
                if (Number.isNaN(value))
                  value = 0;
                const fixed = (value && value.replace(/,/g, ''));
                onChangeHandler('valueTo' , (fixed && parseFloat(fixed) || null) ) ;
               
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
          <div className="px-2 item mt-1">
          <Inputs
                    idRef={`commissionRef`}
                    labelValue={'commission'}
                    endAdornment={
                      <InputAdornment position="end" className="px-2">
                        %
                      </InputAdornment>
                    }
                    inputPlaceholder={t("enter-commission")}
                    type="number"
                    min={0}
                    max={100}
                    withNumberFormat
                    isAttachedInput
                    value={state.commission || ''}
                    onInputChanged={(e) => {
                      let  { value } = e.target;
                      if (value > 100 ) value = 100 ; 
                      onChangeHandler("commission", value  || null );
                    }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                  />
          </div>
          <div className="px-2 item mt-1">
              <Inputs
                isAttachedInput
                idRef={`paymentTerms${1}`}
                labelValue={"payment-terms"}
                multiline
                rows={3}
                value={state.paymentTerms || ""}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("paymentTerms", (value !=='' && value) || null );
                }}
                inputPlaceholder={"enter-payment-terms"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            {/* <div className="px-2 item mt-1">
              <Inputs
                isAttachedInput
                idRef={`remarks${1}`}
                labelValue={"remarks"}
                multiline
                rows={3}
                value={state.remarks || ""}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("remarks", (value !=='' && value) || null );
                }}
                inputPlaceholder={"enter-remarks"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div> */}
        </div>
            <div className="mt-3 d-flex-v-center-h-end flex-wrap">
              <ButtonBase
                className="btns theme-solid"
                onClick={CreateCommissionDetailsHandler}
                disabled={isLoading.addCommission || !(selected.commissionType)}
              >
                <span className="mdi mdi-plus" />
                {t(`${translationPath}add-commission`)}
              </ButtonBase>
            </div>
          </div>
        </div>
        <CommissionDetailsTable
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        data={items.allCommissions}
        filter={filter}
        setFilter={setFilter}
        reloadData={reloadData}
      />
      </div>
  );
};
