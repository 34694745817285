

import { LeadOwnerUnitSalePermissions  } from '../../Permissions';

import { UnitsBulkAssignView, UnitsReservationView, ContactProfileManagementView } from '../../Views/Home';
import {
  UnitsSalesView,
  UnitsSalesManagementView,
  UnitsSalesProfileManagementView,
  UnitsSalesStatusManagementView,
  UnitsSaleLeadOwner 
} from '../../Views/Home/UnitsSaleLeadOwnerView';

export const LeadOwnerUnitSaleRoutes = [
  {
    path: '/add',
    name: 'homeLayout.unitsManagementView.units-management-view',
    component: UnitsSalesManagementView,
    layout: '/home/lead-owner-units-sale',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'UnitsView:sale-units',
        isDisabled: false,
        route: '/home/lead-owner-units-sale/view',
        groupName: 'lead-owner',
      },
      {
        name: 'homeLayout.unitsManagementView.units-management-view',
        isDisabled: true,
        route: '/home/lead-owner-units-sale/add',
      },
    ],
  },
  {
    path: '/edit',
    name: 'homeLayout.unitsManagementView.units-management-view',
    component: UnitsSalesManagementView,
    layout: '/home/lead-owner-units-sale',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'UnitsView:sale-units',
        isDisabled: false,
        route: '/home/lead-owner-units-sale/view',
        groupName: 'lead-owner',
      },
      {
        name: 'homeLayout.unitsManagementView.units-management-view',
        isDisabled: true,
        route: '/home/lead-owner-units-sale/edit',
      },
    ],
  },
  {
    path: '/unit-profile-edit',
    name: 'UnitsProfileManagementView:unit-profile-edit',
    component: UnitsSalesProfileManagementView,
    layout: '/home/lead-owner-units-sale',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'unitsView:sale-units',
        isDisabled: false,
        route: '/home/lead-owner-units-sale/view',
        groupName: 'lead-owner',
      },
      {
        name: 'UnitsProfileManagementView:unit-profile-edit',
        isDisabled: true,
        route: '/home/lead-owner-units-sale/unit-profile-edit',
      },
    ],
  },
  {
    path: '/unit-status-management',
    name: 'UnitsStatusManagementView:unit-status-management',
    component: UnitsSalesStatusManagementView,
    layout: '/home/lead-owner-units-sale',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'unitsView:sale-units',
        isDisabled: false,
        route: '/home/lead-owner-units-sale/view',
        groupName: 'lead-owner',
      },
      {
        name: 'UnitsStatusManagementView:unit-status-management',
        isDisabled: true,
        route: '/home/lead-owner-units-sale/unit-status-management',
      },
    ],
  },
  {
    path: '/view',
    name: 'UnitsView:sale-units',
    component: UnitsSaleLeadOwner,
    layout: '/home/lead-owner-units-sale',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'UnitsView:sale-units',
        isDisabled: false,
        route: '/home/lead-owner-units-sale/view',
        groupName: 'lead-owner',
      },
    ],
  },
  {
    path: '/unit-profile-reservation',
    name: 'UnitsProfileManagementView:unit-profile-reservation',
    component: UnitsReservationView,
    layout: '/home/lead-owner-units-sale',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'unitsView:sale-units',
        isDisabled: false,
        route: '/home/lead-owner-units-sale/view',
        groupName: 'lead-owner',
      },
      {
        name: 'UnitsProfileManagementView:unit-profile-reservation',
        isDisabled: true,
        route: '/home/lead-owner-units-sale/unit-profile-reservation',
      },
    ],
  },
  {
    path: '/unit-bulk-update',
    name: 'UnitsProfileManagementView:unit-bulk-update',
    component: UnitsBulkAssignView,
    layout: '/home/lead-owner-units-sale',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'unitsView:sale-units',
        isDisabled: false,
        route: '/home/lead-owner-units-sale/view',
        groupName: 'lead-owner',
      },
      {
        name: 'UnitsProfileManagementView:unit-bulk-update',
        isDisabled: true,
        route: '/home/lead-owner-units-sale/unit-bulk-update',
      },
    ],
  },
  {
    path: '/contact-edit',
    name: 'ContactProfileManagementView:contact-profile-edit',
    component: ContactProfileManagementView,
    layout: '/home/lead-owner-units-sale',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'unitsView:sale-units',
        isDisabled: false,
        route: '/home/lead-owner-units-sale/view',
        groupName: 'lead-owner',
      },
      {
        name: 'ContactProfileManagementView:contact-profile-edit',
        isDisabled: true,
        route: '/home/lead-owner-units-sale/contact-edit',
      },
    ],
  },
];
