export const CampaignReqActionBtns = {
    Request: {
      key: 'request',
      icon: 'mdi mdi-send',
      buttonClasses: 'btns theme-solid mx-2 px-2 py-1 bg-secondary',
    },
    Resubmit: {
      key: 'resubmit',
      icon: 'mdi mdi-send',
      buttonClasses: 'btns theme-solid mx-2 px-2 py-1 bg-secondary',
    },
    Reject: {
      key: 'reject',
      icon: 'mdi mdi-close-circle',
      buttonClasses: 'btns theme-transparent theme-outline mx-2 px-2 py-1',
    },
    Approve: {
      key: 'approve',
      icon: 'mdi mdi-checkbox-marked-circle',
      buttonClasses: 'btns theme-solid mx-2 px-2 py-1',
    },
    MarkAsComplete: {
      key: 'markAsComplete',
      icon: 'mdi mdi-file-check',
      buttonClasses: 'btns theme-solid mx-2 px-2 py-1 bg-secondary',
    },
  };
  