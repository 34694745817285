import React, { useCallback, useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { MicrosoftToken } from "../../../../Services/DashboardService";
import { PowerBiEnum } from "../../../../Enums";
import { GetUserTeamsInfo } from "../../../../Services/userServices";
import { GlobalTranslate, showError } from "../../../../Helper";
import { useTitle } from "../../../../Hooks";
import { SpinnerDashboards } from "../SpinnerDashboards/SpinnerDashboards";
import { PermissionDeniedLayout } from "../../../../Layouts/PermissionDeniedLayout/PermissionDeniedLayout";
import { CellSpinnerBi } from "../CellSpinnerBi/CellSpinnerBi";

export const ActivatedLeads = () => {
  useTitle(GlobalTranslate.t("Dashboard:callcenterteamlead-dashboard"));
  const [reporttoken, setReporttoken] = useState();
  const [teamid, setTeamid] = useState([]);
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);

  const userid = JSON.parse(localStorage.getItem("session")).userId;

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReporttoken(res);
      setRender(true);
    } else {
      setReporttoken("");
      showError("Contact Your Bi Admin For Help");
    }
  };

  const getUserTemasById = useCallback(async () => {
    const res = await GetUserTeamsInfo(userid, true);
    if (!(res && res.status && res.status !== 200)) {
      setTeamid(res && res.map((List) => List.teamsId || null));
    } else {
      setTeamid([]);
    }
  }, [userid]);
  const Filter = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Team Users BusinessGroups",
      column: "TeamsId",
    },
    operator: "In",
    values: teamid,
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };

  useEffect(() => {
    getUserTemasById(userid);
  }, []);

  useEffect(() => {
    Microsoft(false);
  }, []);

  return (
    <div className="dashboardMain-PowerBIEmbed">

      {render && teamid && teamid.length > 0 ? (
        
        <div className="dashboardMain">
                   <CellSpinnerBi isActive={!ShowReport} isAbsolute />

          <PowerBIEmbed
            embedConfig={{
              type: "report",
              id: PowerBiEnum.ActivatedLeadid.reportid,
              embedUrl: PowerBiEnum.ActivatedLeadid.url,
              accessToken: reporttoken,
              filters: [Filter],
              settings: {
                customLayout: {
                  displayOption: models.DisplayOption.FitToWidth,
                },
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                background: models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  () => {
                    setShowReport(true);
                  },
                ],
                ["rendered", () => {}],
                [
                  "error",
                  (event) => {
                    const error = event.detail;

                    if (
                      error.detailedMessage === "Get report failed" ||
                      error.detailedMessage ===
                        "Access token has expired, resubmit with a new access token" ||
                      error.detailedMessage ===
                        "Fail to initialize - Could not resolve cluster"
                    ) {
                      Microsoft(true);
                      setRender(false);
                    } else console.log(error.detailedMessage);
                    showError(error.detailedMessage);
                  },
                ],
              ])
            }
            cssClassName={
              ShowReport ? "report-style-class-Show" : "report-style-class-hide"
            }
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        </div>
      ) : (
        <div>{render && <PermissionDeniedLayout />}</div>
      )}
    </div>
  );
};
