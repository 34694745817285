
import React from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';
import { SwitchComponent  } from '../../../../../../Components' ; 
import { GeneralPropertyCriteriaTypesEnum } from '../../../../../../Enums' ; 


  export const HoursSecurityCriteria = ({
    parentTranslationPath ,
    translationPath ,
    state ,
    setState ,
   } ) => { 

    const { t } = useTranslation(parentTranslationPath);

    const onChangeSelectedItem = (key , value ) => 
    {
      let  updateObject =  state.twentyFourHourSecurityDto ?  { ...state.twentyFourHourSecurityDto} : {
        generalPropertyCriteriaType : GeneralPropertyCriteriaTypesEnum.TwentyFourHourSecurity.key  , 
        isTwentyFourHourSecurity : false , 
        propertyRatingGeneralPropertyCriteriaScoreId : null , 
        score : 0 
       } ;
       let newValueUpdate = { ...updateObject ,  [key] : value  || 0  };
       if(key ===  'isTwentyFourHourSecurity' && !value)
        newValueUpdate = { ...updateObject ,  [key] : value  , score : 0   };

       setState({id:'twentyFourHourSecurityDto' , value :newValueUpdate })
    };

    return (
      <div className="w-75">
        <div className='general-property-section'>
          <div>
            <span className='fw-bold'> {t(`${translationPath}hours-security`)} </span>
          </div>
          <div className='w-100  mt-2 mb-2'>
          <div className='d-flex d-flex-v-center general-property-card mt-2 mb-2'>
          <div className=''>
            {/* <SwitchComponent
                   idRef="hoursSecurityRef"
                   themeClass="thick-theme"
                   isChecked={(state && state.twentyFourHourSecurityDto && state.twentyFourHourSecurityDto.isTwentyFourHourSecurity)}
                   labelValue={(state?.twentyFourHourSecurityDto?.isTwentyFourHourSecurity && 'yes') || 'no'}
                   parentTranslationPath={parentTranslationPath}
                   translationPath={translationPath}
                   onChangeHandler={(event, isChecked) => 
                   {
                    onChangeSelectedItem('isTwentyFourHourSecurity' ,isChecked ); 
                  
                  }}
                /> */}
                </div>
                <div className=''>
                 <Rating  
                    name={`isTwentyFourHourSecurity`}
                    max={10}
                    value={(state && state.twentyFourHourSecurityDto && state.twentyFourHourSecurityDto.score)}
                    precision={1}
                    onChange={(event, newValue) => 
                    {
                      onChangeSelectedItem('score' ,newValue || 0 ); 
                   
                    }}
                   />
                   </div>
               </div>
            </div>  
          </div>
        </div>
       );
  } ;   
  