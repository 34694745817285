import React from "react";
import { Box } from "@material-ui/core";

import SummaryCardSkeleton from "../SummaryCardSkeleton/SummaryCardSkeleton";

// Styles
import useStyles from "./styles";

function SummaryCardListSkeleton({ numberOfCards }) {
  const styles = useStyles();

  const skeletonCards = Array.from({ length: numberOfCards }, (_, index) => (
    <SummaryCardSkeleton key={index} />
  ));

  return <Box className={styles.root}>{skeletonCards}</Box>;
}

export default SummaryCardListSkeleton;
