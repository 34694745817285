import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { GlobalOrderFilterActions } from '../../../../store/GlobalOrderFilter/GlobalOrderFilterActions';

import {
  DialogComponent,
  PaginationComponent,
  Spinner,
  Tables,
  TableColumnsFilterComponent,
} from '../../../../Components';
import { TableColumnsFilterActions } from '../../../../store/TableColumnsFilter/TableColumnsFilterActions';
import {
  TableActions,
  ColumnsFilterPagesEnum,
  TableFilterTypesEnum,
} from '../../../../Enums';
import {
  bottomBoxComponentUpdate,
  showError,
  showSuccess,
  returnPropsByPermissions, // , GlobalHistory, showSuccess
} from '../../../../Helper';
import { ActivityTypeManagementDialog } from '../ActivitiesTypeManagementView/DialogManagementViewComponent/ActivityTypeManagementDialog';
import {
  DeleteActivityType,
  GetActivityTypeById,
  GetAllActivityTypes,
} from '../../../../Services/ActivitiesTypesServices';
import { ActivityTypePermissions } from '../../../../Permissions';
import { ActivitiesTabelHeaderData } from './ActivitiesTabelHeaderData';

export const ActivitiesTypeTabelView = ({
  parentTranslationPath,
  translationPath,
  reloading,
  setFilter,
  filter,
  allActivityTypes,
  activeSelectedAction,
  checkedCardsIds,
  onSelectClicked,
  getIsSelected,
  getIsSelectedAll,
  onSelectAllClicked,
  setActiveItem,
  setOpenDialog,
  activeItem,
  resetActivityEngineDateFilter,
}) => {
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const dispatch = useDispatch();
  const { t } = useTranslation(parentTranslationPath);
  const list = [];
  const [isLoading, setIsLoading] = useState(false);
  // const [activeItem, setActiveItem] = useState(null);
  const [Data, setData] = useState({});
  const [ISedit, setISedit] = useState(false);
  // const [openDialog, setOpenDialog] = useState(false);
  const [OpenDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [tableColumns, setTableColumns] = useState([]);
  const [selectedTableFilterColumns, setSelectedTableFilterColumns] = useState(
    ActivitiesTabelHeaderData.filter((item) => item.isDefaultFilterColumn).map(
      (column) => column.id
    )
  );
  const tableColumnsFilterResponse = useSelector(
    (state) => state.TableColumnsFilterReducer
  );

  const tableActionClicked = useCallback((actionEnum, item) => {
    if (actionEnum === TableActions.editText.key) {
      setActiveItem(item);
      setOpenDialog(true);
    } else if (actionEnum === TableActions.delete.key) {
      setOpenDeleteDialog(true);
      setActiveItem(item);
    }
  }, []);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const DeleteHandler = async () => {
    const res = await DeleteActivityType(activeItem.activityTypeId);
    if (!(res && res.status && res.status !== 200)) {
      setIsLoading(true);
      showSuccess(t(`${translationPath}activity-deleted-successfully`));
      setFilter((item) => ({ ...item, pageIndex: 0 }));
    } else if (
      (res && res.data && res.data.Message).includes(
        'THERE_IS_ACTIVITIES_FROM_THIS_TYPE'
      )
    )
      showError(t(`${translationPath}Can’t-delete-this-activity-type`));
    else showError(t(`${translationPath}activity-delete-failed`));
    setIsLoading(false);
    setOpenDeleteDialog(false);
  };

  // useEffect(() => {
  //   if (!reloading)
  //     GetAllActivityTypesAPI();
  // }, [, reloading, filter]);

  // useEffect(() => {
  //   if (sortBy !== null)
  //     GetAllActivityTypesAPI(sortBy);
  // }, [sortBy]);

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={allActivityTypes.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  const getActionTableWithPermissions = () => {
    // eslint-disable-next-line prefer-const

    if (
      returnPropsByPermissions(
        ActivityTypePermissions.EditActivityType.permissionsId
      )
    ) {
      list.push({
        enum: TableActions.editText.key,
        isDisabled: false,
        externalComponent: null,
      });
    }
    if (
      returnPropsByPermissions(
        ActivityTypePermissions.DeleteActivityType.permissionsId
      )
    ) {
      list.push({
        enum: TableActions.delete.key,
        isDisabled: true,
        externalComponent: null,
      });
    }
    return list;
  };

  const focusedRowChanged = (rowIndex, item) => {
    if (rowIndex !== -1) {
      if (item && item.isStatic === false) {
        list.splice(0, list.length);
        list.push(
          {
            enum: TableActions.delete.key,
            isDisabled: false,
            externalComponent: null,
          },
          {
            enum: TableActions.editText.key,
            isDisabled: false,
            externalComponent: null,
          }
        );
      } else if (item && item.isStatic === true) {
        list.splice(0, list.length);
        list.push(
          {
            enum: TableActions.delete.key,
            isDisabled: true,
            externalComponent: null,
          },
          {
            enum: TableActions.editText.key,
            isDisabled: false,
            externalComponent: null,
          }
        );
      }
    }
  };

  const onFilterValuesChanged = (newValue) => {
    let localFilterDto = filter || {};

    if (localFilterDto && localFilterDto.createdOn) {
      localFilterDto.toDate = moment(localFilterDto.createdOn).format(
        'YYYY-MM-DDTHH:mm:ss'
      );
      localFilterDto.fromDate = moment(localFilterDto.createdOn).format(
        'YYYY-MM-DDTHH:mm:ss'
      );
    }
    if (newValue) {
      Object.values(newValue)
        .filter((item) => item.searchableKey || item.displayPath)
        .map((item) => {
          if (item.displayPath === 'LastUsedDate') {
            localFilterDto.fromLastUsedDate = item && item.value;
            localFilterDto.toLastUsedDate = item && item.value;
            resetActivityEngineDateFilter();
          }
          if (localFilterDto[item.displayPath])
            localFilterDto[item.displayPath] = item && item.value;
          else if (item.value || item.value === false)
            localFilterDto[item.displayPath] = item && item.value;

          return undefined;
        });
      setFilter(() => ({ ...localFilterDto }));
      dispatch(
        GlobalOrderFilterActions.globalOrderFilterRequest({
          ...orderFilter,
          activityType: {
            ...orderFilter.activityType,
            ...localFilterDto,
          },
        })
      );
    }
    if (!(newValue && newValue.length)) {
      localStorage.removeItem('ActivitiesTypeTabel');
    }
  };

  useEffect(() => {
    if (
      tableColumnsFilterResponse &&
      tableColumnsFilterResponse[ColumnsFilterPagesEnum.activityType.key]
    ) {
      setSelectedTableFilterColumns(
        tableColumnsFilterResponse[ColumnsFilterPagesEnum.activityType.key].filter(item=> item)
      );
    }
  }, [tableColumnsFilterResponse]);

  useEffect(() => {
    setTableColumns([
      ...ActivitiesTabelHeaderData.filter(
        (item) =>
          selectedTableFilterColumns.findIndex(
            (element) => element === item.id
          ) !== -1
      ),
    ]);
  }, [selectedTableFilterColumns]);

  useEffect(() => {
    setTableFilterData(
      tableColumns.map((column) => ({
        key: column.key || column.fieldKey || column.id,
        filterType:
          (column.isDate && TableFilterTypesEnum.datePicker.key) ||
          (column.withSelectFilter && TableFilterTypesEnum.selectOption.key) ||
          TableFilterTypesEnum.textInput.key,
        isHiddenFilter: column.isHiddenFilter,
        isHidden: column.isHidden,
        textInputType: column.textInputType,
        textInputMax: column.textInputMax,
        textInputMin: column.textInputMin,
        displayPath: column.input,
        optionFilterList:
          column.withSelectFilter &&
          column.optionFilterList &&
          column.optionFilterList,
        valueSelected: (column.input && filter[column.input]) || null,
      }))
    );
  }, [tableColumns]);

  useEffect(() => {
    if (sortBy) {
      setFilter((item) => ({
        ...item,
        filterBy: sortBy.filterBy,
        orderBy: sortBy.orderBy,
        pageIndex: 0,
      }));
    }
  }, [sortBy]);

  return (
    <div className='ActivitiesType-View childs-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='filter-section-item' />
      <div className='w-100 px-2'>
        <TableColumnsFilterComponent
          columns={ActivitiesTabelHeaderData.filter(item => !item.isHidden ).concat(
            selectedTableFilterColumns.filter(
              (item) => item &&
                ActivitiesTabelHeaderData.findIndex(
                  (element) => element.fieldKey === item.formFieldKey
                ) === -1
            )
          ).map((item) => ({
            key: item.formFieldId || item.id,
            value:
              (item.formFieldTitle && item.formFieldTitle.replace('*', '')) ||
              item.label,
          }))}
          isLoading={isLoading}
          selectedColumns={selectedTableFilterColumns}
          onSelectedColumnsChanged={(newValue) => {
            setSelectedTableFilterColumns(newValue);
            let localTableColumnsFilterResponse = tableColumnsFilterResponse;
            if (localTableColumnsFilterResponse) {
              localTableColumnsFilterResponse[
                ColumnsFilterPagesEnum.activityType.key
              ] = newValue;
            } else {
              localTableColumnsFilterResponse = {
                [ColumnsFilterPagesEnum.activityType.key]: newValue,
              };
            }
            dispatch(
              TableColumnsFilterActions.TableColumnsFilterRequest(
                localTableColumnsFilterResponse
              )
            );
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath=''
        />
        <Tables
          data={(allActivityTypes && allActivityTypes.result) || []}
          headerData={tableColumns}
          defaultActions={getActionTableWithPermissions()}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          totalItems={allActivityTypes && allActivityTypes.totalCount}
          focusedRowChanged={focusedRowChanged}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          isWithFilter
          setSortBy={setSortBy}
          onFilterValuesChanged={onFilterValuesChanged}
          filterData={tableFilterData}
          FilterDisabledButton
          optionFilterName={[
            {
              key: true,
              value: 'Yes',
            },
            {
              key: false,
              value: 'No',
            },
          ]}
          localStorageKey='ActivitiesTypeTabel'
          selectAllOptions={
            ((activeSelectedAction === 'bulk-update' ||
              activeSelectedAction === 'archived' ||
              activeSelectedAction === 'un-archived') && {
              selectedRows: checkedCardsIds,
              getIsSelected,
              disabledRows: [],
              withCheckAll: true,
              onSelectAllClicked,
              isSelectAll: getIsSelectedAll(),
              onSelectClicked,
            }) ||
            undefined
          }
        />
      </div>
      {/* <DialogComponent
        isOpen={openDialog}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        titleText='Edit-Activity'
        titleClasses='DialogComponent-ActivitiesType'
        wrapperClasses='wrapperClasses-ActivitiesType'
        maxWidth='md'
        dialogContent={
          <>
            <ActivityTypeManagementDialog
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              activeItem={activeItem}
              Data={Data}
              edit={ISedit}
              onCancelClicked={() => {
                setOpenDialog(false);
                setISedit(false);
                setData({});
                setActiveItem(null);
                setFilter((item) => ({ ...item, pageIndex: 0 }));
              }}
            />
          </>
        }
      /> */}
      <DialogComponent
        titleText='confirm-message'
        saveText='confirm'
        saveType='button'
        maxWidth='sm'
        dialogContent={
          <div className='d-flex-column-center'>
            <span className='mdi mdi-close-octagon c-danger mdi-48px' />
            <span>{`${t(
              `${translationPath}activity-delete-description`
            )}`}</span>
          </div>
        }
        saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
        isOpen={OpenDeleteDialog}
        onSaveClicked={() => {
          DeleteHandler();
        }}
        onCloseClicked={() => {
          setOpenDeleteDialog(false);
          setActiveItem(null);
        }}
        onCancelClicked={() => {
          setOpenDeleteDialog(false);
          setActiveItem(null);
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
    </div>
  );
};

ActivitiesTypeTabelView.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  reloading: PropTypes.bool.isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  setFilter: PropTypes.func.isRequired,
};
