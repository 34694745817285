import React from 'react';
import { Box } from '@material-ui/core';

// Styles
import useStyles from './styles';
import ContactUnitCardSkeleton from '../ContactUnitCardSkeleton/ContactUnitCardSkeleton';
import { Skeleton } from '@material-ui/lab';
import { CustomCard } from '../../../../Components';

function ContactUnitListCardSkeleton({ numberOfCards }) {
  const styles = useStyles();

  const skeletonCards = Array.from({ length: numberOfCards }, (_, index) => (
    <ContactUnitCardSkeleton key={index} />
  ));

  return (
    <CustomCard
      boxShadow='xs'
      borderRadius='xl'
      borderColor='secondary'
      classes={styles.contactOverViewCard}
    >
      <Box className={styles.unitCardTitleWrapper}>
        <div className={styles.titleContainer}>
          <Skeleton
            variant='text'
            width={50}
            height={32}
            style={{ borderRadius: '4px' }}
          />
          <Skeleton
            variant='circular'
            width={32}
            height={22}
            style={{ marginLeft: '12px', borderRadius: '0.75rem' }}
          />
        </div>

        <div>
          <Skeleton variant='rectangular' width={80} height={36} />
        </div>
      </Box>
      <Box className={styles.root}>{skeletonCards}</Box>
    </CustomCard>
  );
}

export default ContactUnitListCardSkeleton;
