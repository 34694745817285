import moment from 'moment';
import React, {
  useCallback, useEffect , useState, useRef , useReducer
} from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../Components';
import { GetAllCommunicationActivities } from '../../../Services' ;
import { useTitle } from '../../../Hooks';
import { ActivitiesCommunicationLogsTable } from './ActivitiesCommunicationLogsTable' ; 
import {ActivitiesCommunicationLogsFilter } from './ActivitiesCommunicationLogsFilter' ; 

const parentTranslationPath = 'ActivitiesCommunicationLogs';
const translationPath = '';
export const ActivitiesCommunicationLogsView = () => {
  const searchTimer = useRef(null);
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t('activities-communication-logs'));

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
  };
  
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [isLoading, setIsLoading] = useState(false) ; 
  const [allCommunicationActivities , setAllCommunicationActivities]  = useState({ result : [] , totalCount :0 }); 
  const [filter, setFilter] = useState({
     pageIndex: 0,
     pageSize: parseInt(localStorage.getItem('Pagination')) || 25 , 
     activityTypeId :	 null , 
     agentName	:null  , 
     agentId	: null , 
     email	: null , 
     phoneNumber : null , 
     leadName	: null , 
     leadId : null , 
     contactName	: null ,  
     contactId	 : null , 
     activityDateFrom	:null , 
     activityDateTo : null 
  });

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [selected, setSelected] = useReducer(reducer, {
    fromDate: null,
    toDate: null,
    agentName: null,
    agentId: null,
    leadId: null,
    leadName: null,
    contactName : null,
    contactId : null,
    phone : null , 
    email : null , 
  });
  
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };


  const GetAllCommunicationActivitiesAPI  = useCallback(async () => {
       setIsLoading(true) ; 
       let body = 
       {
        activityTypeId :  filter.activityTypeId  , 
        agentName	:  filter.agentName , 
        agentId	: filter.agentId , 
        email	:filter.email  , 
        phoneNumber :filter.phoneNumber  , 
        leadName	: filter.leadName , 
        leadId : filter.leadId , 
        contactName	:filter.contactName  ,  
        contactId	 : filter.contactId , 
        activityDateFrom	: null, 
        activityDateTo : null ,   

       }
       if (dateFilter && dateFilter.startDate && dateFilter.endDate) {
         body.activityDateFrom = moment(dateFilter.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
         body.activityDateTo = moment(dateFilter.endDate).format('YYYY-MM-DDTHH:mm:ss') 

       }
       const res =  await GetAllCommunicationActivities(filter.pageSize , filter.pageIndex ,  body );

        if (!(res && res.status && res.status !== 200))
        {
        setAllCommunicationActivities({
          result: (res && res.result) || [],
          totalCount: (res && res.totalCount) || 0,
        });
       } else {
          setAllCommunicationActivities({
             result: [],
             totalCount: 0,
        });
       }
       setIsLoading(false) ; 
  }, [filter , dateFilter]);

  useEffect(() => {
    GetAllCommunicationActivitiesAPI();
  }, [GetAllCommunicationActivitiesAPI]);



  
  return (
    <div className='SystemNotifications-wrapper view-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='w-100 px-2'>
        <div className='header-section-communication-Logs'>
          <ActivitiesCommunicationLogsFilter
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            filter={filter}
            setFilter={setFilter} 
            selected= {selected}
            setSelected={setSelected}
            setDateFilter={setDateFilter}
            dateFilter={dateFilter}
            dateRangeDefault={dateRangeDefault}
          />
        </div>
     
        <div className='w-100 px-2'>
          <ActivitiesCommunicationLogsTable
            filter={filter}
            setFilter={setFilter}
            data={allCommunicationActivities}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}

          />
        </div>
      </div>
    </div>
  );
};
