import { MergeLogsView } from '../../Views/Home/MergeLogsView/MergeLogsView';

export const MergeLogsRoutes = [
    {
      path: '/merge-log',
      name: 'MergeLogs:mergeLogs',
      component: MergeLogsView,
      layout: '/home/system-log',
      default: true,
      isRoute: true,
      authorize: true,
      roles: [],
      isDisabled: false,
      isExact: true,
      breadcrumbs: [
        {
          name: 'MergeLogs:mergeLogs',
          isDisabled: true,
          route: '/home/system-log/merge-log',
          groupName: 'system-log',
        },
      ],
    },
  ];
