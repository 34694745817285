import React from "react";
import GenricStpeper from "../../../../Components/OLD/dfmAddEditAndDelete/typePicker/DfmAddEditAndDeleteStepper";

export const TaskDynamicForm = ({
    dynamicFormOptions
}) => {
  return (
    <div className="view-wrapper pt-3">
      <div className="d-flex-column">
          <GenricStpeper
            dynamicFormOptions={dynamicFormOptions}
            withoutButtons
          />
      </div>
    </div>
  );
};
