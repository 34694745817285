import React, { useEffect, useState, useCallback, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import { LoadableDocumentComponent } from '../../../../../Components';
import {
  GetlookupTypeItems,
  GetAllScopeDocuments,
  UpdateScopeDocument,
  CreateScopeDocument,
} from '../../../../../Services';
import { MediaPreviewDialog } from '../../../TemplatesView/Dialogs';
import {
  getIsValidURL,
  getIsValidDataURL,
  getDownloadableLink,
  GetParams,
  showError,
  getFirstLastNameLetters,
} from '../../../../../Helper';
import { ScopeDocumentEnum } from '../../../../../Enums';
import { StaticLookupsIds } from '../../../../../assets/json/StaticLookupsIds';
import { DocumentUploadDialog } from '../../../UnitsView/UnitsProfileManagementView/Dialogs/DocumentUploadDialog/DocumentUploadDialog';
import { PageHeading } from '../../../../../SharedComponents/index.jsx';

export const UnitDocumentsComponent = ({
  state,
  selected,
  unitData,
  onSelectedChanged,
  onStateChanged,
}) => {
  const parentTranslationPath = 'UnitsStatusManagementView';
  const translationPath = '';
  
  const { t } = useTranslation([parentTranslationPath, 'Shared']);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [viewedFile, setViewedFile] = useState(null);
  const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false);
  const [isOpenUploadDialog, setIsOpenUploadDialog] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [initialDocuments, setInitialDocuments] = useState(null);
  const [data, setData] = useReducer(reducer, {
    categories: null,
    documents: null,
  });
  const handleDownloadClick = (value) => () => {
    const link = document.createElement('a');
    link.setAttribute('download', value.documentName);
    link.href = getDownloadableLink(value.documentId);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const openLink = (url) => {
    if (!url.match(/^https?:\/\//i)) url = 'http://' + url;
    if (getIsValidURL(url)) {
      window.open(url || '');
    } else if (getIsValidDataURL(url)) {
      let windowObj = window.open();
      const iframeElement = `<iframe src="${url}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`;
      windowObj.document.write(iframeElement);
    }
  };

  const addDocumentsClickHandler = (category) => {
    setActiveCategory(category);
    setInitialDocuments({
      [category.lookupItemName]: [...(data.documents?.[category.lookupItemName] || [])],
      selected: [category],
    });
    setIsOpenUploadDialog(true);
  };

  const categoryDocumentsMapper = (data) => {
    let mappedDocuments = {};
    const newFormatDocs = data.map((item) => ({
      ...item,
      uuid: item.documentId,
      fileName: item.documentName,
      fileId: item.documentId,
      documentLink: item.documentLink,
    }));
    newFormatDocs.forEach((item) => {
      mappedDocuments[item.categoryName || ''] = [
        ...(mappedDocuments[item.categoryName] || []),
        item,
      ];
    });
    return mappedDocuments;
  };

  const getCategories = async () => {

    const res = await GetlookupTypeItems({
      pageIndex: 0, pageSize: 99999, lookupTypeId: StaticLookupsIds.UnitsDocuments
    });
    if (!(res && res.status && res.status !== 200)) {
      let filteredCategories = [];
      const newSaleCategories = [
        "SPA",
        "Brokerage Confirmation",
        "Reservation/Booking Form",
        "Signed PSI KYC",
        "Summary of Commission (if any)"
      ];
      
      const resaleCategories = [
        "MOU",
        "Transfer Assignment/New Title Deed",
        "Signed PSI KYC for both Buyer and Seller",
        "Agency fee receipts copy"
      ];
      
      if (unitData?.sale_type?.lookupItemName === 'New Sale') {
        filteredCategories = res?.result?.filter(item => newSaleCategories.includes(item.lookupItemName));
      } else if (unitData?.sale_type?.lookupItemName === 'Resale') {
        filteredCategories = res?.result?.filter(item => resaleCategories.includes(item.lookupItemName));
      }

      setData({ id: 'categories', value: filteredCategories || null });
      onSelectedChanged({ id: 'categories', value: filteredCategories || null })
    }
  };

  const getUnitDocuments = async () => {
    const UnitId = GetParams('id');
    const res = await GetAllScopeDocuments({ ...{ pageIndex: 0, pageSize: 50 }, scopeId: UnitId , scopeTypeId: ScopeDocumentEnum.Unit.scopeTypeId});
    if (!(res && res.status && res.status !== 200)) {
      const mappedDocs = categoryDocumentsMapper(res?.result || []);
      setData({ id: 'documents', value: mappedDocs });

      const mappedStateDocs = res?.result?.map(item => ({
        fileId: item.documentId,
        documentLink: item.documentLink,
      }))
      const mappedStateTransactionDocuments = res?.result?.map(item => ({
        scopeId:item.scopeDocumentId ,
        fileId: item.documentId,
        fileName: item.documentName,
        categoryId :item.categoryId,
        scopeTypeId:ScopeDocumentEnum.Unit.scopeTypeId
      }))
      onStateChanged({ id: 'unitTransactionDocuments', value: mappedStateDocs });
      onSelectedChanged({ id: 'unitTransactionDocuments', value: mappedStateDocs || [] })
      onStateChanged({ id: 'transactionDocuments', value: mappedStateTransactionDocuments });
      onSelectedChanged({ id: 'transactionDocuments', value: mappedStateTransactionDocuments || [] })
    }
  };
  const documentChangeHandler = useCallback(
    (files) => {
      const mappedFiles =
        files[activeCategory?.lookupItemName]?.map((item) => ({
          documentLink: item.documentLink || item.url,
          fileName: item.documentName || item.fileName,
          fileId: item.documentId || item.uuid,
          categoryName: activeCategory?.lookupItemName,
        })) || [];

      onStateChanged({
        id: 'unitTransactionDocuments',
        value: [...(state.unitTransactionDocuments || []), ...mappedFiles]
      });
      onSelectedChanged({
        id: 'unitTransactionDocuments',
        value: [...(selected.unitTransactionDocuments || []), ...mappedFiles]
      })

      const body = {
        scopeId: GetParams('id'),
        scopeTypeId: ScopeDocumentEnum.Unit.scopeTypeId,
        categoryFiles: [
          {
            categoryId: activeCategory?.lookupItemId,
            files: [...mappedFiles],
          },
        ],
      };

      const initialDocsNumber = initialDocuments?.[activeCategory?.lookupItemName]?.length;
      if (initialDocsNumber === 0) createScopeDocument(body);
      else updateScopeDocument(body);
    },
    [initialDocuments, activeCategory]
  );

  const updateScopeDocument = async (body) => {
    const res = await UpdateScopeDocument(body);

    if (!(res && res.status && res.status !== 200)) {
      reloadDocuments();
    } else showError(t`${translationPath}Create-unit-doucument-successfully`);
  };

  const createScopeDocument = async (body) => {
    const res = await CreateScopeDocument(body);

    if (!(res && res.status && res.status !== 200)) {
      reloadDocuments();
    } else showError(t`${translationPath}Create-unit-doucument-successfully`);
  };

  const reloadDocuments = () => {
    getUnitDocuments();
    setActiveCategory(null);
    setInitialDocuments(null);
  };

  useEffect(() => {
    getCategories();
    getUnitDocuments();
  }, []);

  return (
    <div className='UnitTransactionsView px-4 py-3'>
      <PageHeading
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        headerTitle={'Unit Document'}
        subTitle={'Just a few steps left! To help us finalize your transaction, please upload the required documentation.'}
        headerTitleClasses={"fz-20 mb-3"}

      />
      <div className='m-auto w-75'>
        {data.categories &&
          data.documents &&
          data.categories.map((category) => (
            <div className='d-flex-column-center w-100' key={category.lookupItemName}>
              <div className='devider my-4 w-75'></div>
              <div className='w-50'>
                <div className='d-flex-v-center-h-between'>
                  <p className='fw-simi-bold fz-16 ml-1'>{category.lookupItemName}</p>

                  <div className='w-25  d-flex-default fa-end'>
                    <ButtonBase
                      onClick={() => addDocumentsClickHandler(category)}
                      className='btns theme-solid mx-2'>
                      <span className='mdi mdi-plus'></span>
                      <span className='px-2'>{t(`${translationPath}Add-doc`)}</span>
                    </ButtonBase>
                  </div>
                </div>

                {data.documents[category.lookupItemName] &&
                  data.documents[category.lookupItemName].map((file) =>
                    file.documentLink ? (
                      <div className='link-item-container py-3 px-4 mt-3 radius-md bg-light-gray pointer'>
                        <p className='text-tertiary ecilipse-text'>{file.documentLink}</p>
                        <div className='d-flex-center'>
                          <span
                            className='fw-simi-bold text-primary pointer'
                            onClick={() => {
                              try {
                                openLink(file.documentLink);
                              } catch (error) {
                                showError(t(`${translationPath}this-link-is-not-valid`));
                              }
                            }}>
                            Visit Link
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div
                        key={file.documentId}
                        className='file-item-container py-3 px-4 mt-3 radius-md bg-light-gray pointer'>
                        <div className='file-icon-wrapper d-flex-center'>
                          <span className='mr-1'>
                            <LoadableDocumentComponent
                              classes='file-icon'
                              fileName={file.documentName || ''}
                              alt={`cover-image`}
                            />
                          </span>
                          <span className='d-flex-column'>
                            <span className='file-title'>{file.documentName}</span>
                          </span>
                        </div>
                        <div className='d-flex-center'>
                          <span className='fw-simi-bold pointer' onClick={handleDownloadClick(file)}>
                            Download
                          </span>
                        </div>
                        <div className='d-flex-center'>
                          <span
                            className='fw-simi-bold  text-primary pointer'
                            onClick={() => {
                              setIsOpenPreviewDialog(true);
                              setViewedFile({
                                templateFileId: file.documentId,
                                templateFileName: file.documentName,
                                templateText: '',
                              });
                            }}>
                            View
                          </span>
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          ))}
      </div>
      {isOpenPreviewDialog && (
        <MediaPreviewDialog
          activeItem={viewedFile}
          maintitleText='FILE-VIEW'
          isOpen={isOpenPreviewDialog}
          isOpenChanged={() => {
            setIsOpenPreviewDialog(false);
            setViewedFile(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}

      {(isOpenUploadDialog &&
        initialDocuments &&
        activeCategory) && (
          <DocumentUploadDialog
            open={isOpenUploadDialog}
            onChange={documentChangeHandler}
            initialState={initialDocuments}
            closeDialog={() => {
              setActiveCategory(null);
              setInitialDocuments(null);
              setIsOpenUploadDialog(false);
            }}
            activeItem={activeCategory}
          />
        )}
    </div>
  );
};
