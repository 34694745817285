
import React from 'react';
import { SwitchRoute } from '../../../Components/Route/SwitchRoute';
import { ResaleUnitsRoutes } from '../../../routes';

export const ResaleUnitsLayout = () => (
  <>
    <SwitchRoute routes={ResaleUnitsRoutes} />
  </>
);
