import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    cardStyle: {
      padding: theme.spacing(3),
      flex: 1,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      cursor: "pointer",
    },
    buttonSkeleton: {
      borderRadius: theme.shape.borderRadius,
    },
    contentWrapper: {
      maxHeight: "44px",
    },
    titleHeading: {
      minHeight: "24px",
      marginBottom: "8px",
    }
  };
});
