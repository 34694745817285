import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonBase, DialogActions } from '@material-ui/core';
import {
  DialogComponent,
  PermissionsComponent,
  Spinner,
} from '../../../../Components';
import { GetInvoiceDetails } from '../../../../Services';
import { GetParams, showError } from '../../../../Helper';
import { ShareInvoiceDialog } from './ShareInvoiceDialog';
import { BillingPermissions } from '../../../../Permissions';

export const InvoicePreviewDialog = ({
  isOpen,
  branchId,
  isForHistory,
  onClose,
  parentTranslationPath,
  translationPath,
  mainTitleText,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const mediaPrint =
    '@page {margin: 2mm;}@media print {.page-header,.page-header-space {height: 137px;max-height: 137px;}.page-footer,.page-footer-space {height: 137px;max-height: 137px;}.page-footer {position: fixed;bottom: 0;width: 100%;}.page-header {position: fixed;top: 0mm;width: 100%;}.page {page-break-after: always;}thead {display: table-header-group;}tfoot {display: table-footer-group;}button {display: none;}body {margin: 0;}}';
  const state = {
    branchId,
    isForHistory,
  };
  const [content, setContent] = useState('');
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [withSize, setWithSize] = useState('lg');
  const [IsLoadingRunSystemTemplate, setIsLoadingRunSystemTemplate] =
    useState(false);

  const onSizeClickedFun = useCallback(async (size) => {
    if (size === 'lg') setWithSize('xl');
    else setWithSize('lg');
  }, []);

  const getInvoiceDetails = useCallback(async () => {
    setIsLoadingRunSystemTemplate(true);
    const res = await GetInvoiceDetails(state);
    if (!(res && res.status && res.status !== 200)) {
      setContent(res?.htmlContent);
      const content = document.getElementById('divcontents');
      const pri = document.getElementById('ifmcontentstoprint').contentWindow;
      pri.document.open();
      pri.document.write(content.innerHTML);
    } else showError(t(`${translationPath}invoice-failed`));

    setIsLoadingRunSystemTemplate(false);
  }, []);

  const printPartOfPage = async () => {
    const pri = document.getElementById('ifmcontentstoprint').contentWindow;
    pri.document.close();
    pri.focus();
    pri.print();
  };
  useEffect(() => {
    getInvoiceDetails();
  }, []);

  return (
    <DialogComponent
      titleText={mainTitleText}
      maxWidth={withSize}
      reSize
      dialogContent={
        <div className='templates-preview-dialog'>
          <div style={{ position: 'relative' }}>
            <Spinner isActive={IsLoadingRunSystemTemplate} />
            <div className='PrintorsavePDF'></div>
            <div id='divcontents' style={{ display: 'none' }}>
              <style>{mediaPrint}</style>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: content }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <iframe
              id='ifmcontentstoprint'
              title='ifmcontentstoprint'
              style={{ minHeight: 500 }}
              width='100%'
              height='100%'
            />
          </div>
          <PermissionsComponent
            permissionsList={Object.values(BillingPermissions)}
            permissionsId={BillingPermissions.PrintInvoice.permissionsId}
          >
            <div className='print-icon-wwww'>
              <ButtonBase
                className='print-button'
                onClick={() => {
                  printPartOfPage();
                }}
              >
                <span className='print-icon' />
              </ButtonBase>
            </div>
          </PermissionsComponent>
          <PermissionsComponent
            permissionsList={Object.values(BillingPermissions)}
            permissionsId={BillingPermissions.ShareInvoice.permissionsId}
          >
            <DialogActions>
              <div className='unit-template-action-btns'>
                <ButtonBase
                  className='MuiButtonBase-root btns bg-share'
                  onClick={() => setIsShareDialogOpen(true)}
                >
                  {t(`${translationPath}share-invoice`)}
                  <span className='mdi mdi-share-variant' />
                </ButtonBase>
              </div>
            </DialogActions>
          </PermissionsComponent>

          {isShareDialogOpen && (
            <ShareInvoiceDialog
              isOpen={isShareDialogOpen}
              branchId={branchId}
              isForHistory={isForHistory}
              closeHandler={() => {
                setIsShareDialogOpen(false);
              }}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          )}
        </div>
      }
      isOpen={isOpen}
      onSizeClicked={() => onSizeClickedFun(withSize)}
      onCloseClicked={onClose}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};

InvoicePreviewDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  mainTitleText: PropTypes.string,
  isForHistory: PropTypes.bool,
  branchId: PropTypes.string,
};
InvoicePreviewDialog.defaultProps = {
  activeItem: null,
  mainTitleText: 'preview-invoice',
  unitID: +GetParams('id'),
};
