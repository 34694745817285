
export const TemplateShareLogPermissions = {
    TemplateShareLog: {
      permissionsId: "d96cd6c9-4862-4c19-7975-08dcaf947ea8",
      permissionsName: "View Template Share Log",
      description: null,
      componentsId: null,
      components: null,
    },
  };
  